import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';

import FormModalBody from './FormModalBody';
import SubmitCardModalBody from './SubmitCardModalBody';

export default class ValidateCardModal extends React.Component {
  static propTypes = {
    caseId: PropTypes.string.isRequired,
    caseNumber: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  state = {
    cardInfo: null,
    editing: true,
  };

  handleSubmit = (submitArgs) => {
    this.setState({
      cardInfo: submitArgs,
      editing: false,
    });
  };

  retryEdit = () => {
    this.setState({ editing: true });
  };

  renderModal = ({ closeModal }) =>
    this.state.editing ? (
      <FormModalBody
        cardInfo={this.state.cardInfo}
        closeModal={closeModal}
        handleSubmit={this.handleSubmit}
      />
    ) : (
      <SubmitCardModalBody
        closeModal={closeModal}
        caseId={this.props.caseId}
        caseNumber={this.props.caseNumber}
        cardInfo={this.state.cardInfo}
        retryEdit={this.retryEdit}
      />
    );

  render() {
    return <Modal onClose={this.props.onClose}>{this.renderModal}</Modal>;
  }
}

import React, { useState, useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';

import Loading from 'components/Loading';

import {
  bffAPILink,
  bffCSRFLink,
  sendToLogin,
  sendToAccessDenied,
} from 'utils/bff';

import apolloClient from './apolloClient';
import { CSRF_TOKEN, SESSION_TOKEN } from '../../constants';

const query = 'query { currentUserProfile { user { sessionCookie } } }';

// Get and store the CSRF and Session tokens,
// that are used for API requests
export default function ApolloConnector(props) {
  const [csrfToken, setCSRFToken] = useState();
  const [sessionToken, setSessionToken] = useState();

  useEffect(() => {
    fetch(bffCSRFLink, { credentials: 'include' })
      .then((response) => {
        if (!response.ok) throw response;

        return response.text();
      })
      .then((token) => {
        memoryDB.setItem(CSRF_TOKEN, token);
        setCSRFToken(token);
      })
      .catch((error) => {
        if (error.status === 401) return sendToLogin();
        if (error.status === 403) return sendToAccessDenied();
      });

    return () => memoryDB.removeItem(CSRF_TOKEN);
  }, []);

  useEffect(() => {
    if (!csrfToken) return;

    const headers = {
      'x-csrf-token': csrfToken,
      'content-type': 'application/json',
    };

    fetch(bffAPILink, {
      mode: 'cors',
      body: JSON.stringify({ query }),
      method: 'POST',
      headers,
      credentials: 'include',
    })
      .then((r) => r.json())
      .then(({ data }) => {
        const token = data?.currentUserProfile?.user?.sessionCookie;

        memoryDB.setItem(SESSION_TOKEN, token);
        setSessionToken(token);
      })
      .catch(console.error); // eslint-disable-line no-console

    return () => memoryDB.removeItem(SESSION_TOKEN);
  }, [csrfToken]);

  if (!csrfToken || !sessionToken) return <Loading />;

  return <ApolloProvider client={apolloClient} {...props} />;
}

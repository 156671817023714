import { flow, orderBy } from 'lodash';

import {
  sortNodesFields,
  flattenNodeChanges,
  removeEmptyChanges,
  groupNodesByTimestamp,
  flattenNodesRelatedInfo,
} from 'compositions/CaseActivityPanel/FieldUpdates/withCaseFieldUpdates/utils';

export const removeEmptyRecurrenceChanges = (nodes) =>
  nodes.map((node) => ({
    ...node,
    fields: (node.fields || []).filter(
      ({ field, previousValue, newValue }) =>
        !(field === 'is_recurring' && !previousValue && newValue === 'false'),
    ),
  }));

const sortNodesByTimestamp = (nodes, order = 'desc') =>
  orderBy(nodes, 'timestamp', order);

export const processNodes = flow([
  flattenNodeChanges,
  flattenNodesRelatedInfo,
  removeEmptyChanges,
  removeEmptyRecurrenceChanges,
  sortNodesFields,
  sortNodesByTimestamp,
  groupNodesByTimestamp,
]);

import memoize from 'memoize-one';
import { get, without, isFunction } from 'lodash';

import {
  productTypeIds,
  allRequestLineKeys,
  optionalRequestLineKeys,
  rimReplacementProductType,
} from '../CaseRequestsPanelContext/constants';

import {
  hasCheckedLineForSameLocation,
  requestLineHasGenericOptionsSelected,
} from '../CaseRequestsPanelContext/utils';

export { getRowLocation } from '../CaseRequestsPanelContext/utils';

const allowedDuplicateTirePositions = ['OTHER_POSITION', 'UNAVAILABLE'];

export const getTotalGenericTireOptionSelected = (lines, allLines, props) =>
  lines
    // Ignore lines that are checked, or have another
    // line for the same "location" and that is checked.
    .filter((line) => {
      const isCheckedPath = Object.keys(line).includes('agreed')
        ? 'agreed'
        : 'supplied';

      const isChecked = !!get(line, isCheckedPath);

      return (
        !isChecked &&
        !hasCheckedLineForSameLocation(line, allLines, { isCheckedPath })
      );
    })
    .map((line) => requestLineHasGenericOptionsSelected(line, props))
    .filter(Boolean).length;

const isTirePositionAllowedDuplicate = (tirePosition) =>
  allowedDuplicateTirePositions.includes(tirePosition);

// Returns whether or not all objects have the same value at the given path
const allHaveSameValueAtPath = (path, ...objs) =>
  new Set(objs.map((obj) => get(obj, path))).size === 1;

// Checks if two lines have the same value at the given path while taking
// into account whether or not their product type allows for the duplication
const allowDuplicatedValues = (controlPath, line1 = {}, line2 = {}) => {
  // If we the lines are the same, we allow duplication
  if (line1.id === line2.id) return true;

  const pTypes = new Set([line1.productType, line2.productType]);

  // If the lines have the same product type, or if
  // one is a Rim replacement, we allow duplication.
  if (pTypes.size > 1 && pTypes.has(rimReplacementProductType)) return true;

  // Lastly, we allow duplication if the values
  // at the given control path are not the same.
  return !allHaveSameValueAtPath(controlPath, line1, line2);
};

export const getDisabledAssetsOptions = memoize(
  (allLines = [], curLine = {}) => {
    if (!curLine.tirePosition || !curLine.productType) return [];
    if (isTirePositionAllowedDuplicate(curLine.tirePosition)) return [];

    return allLines
      .filter((line) => !allowDuplicatedValues('tirePosition', line, curLine))
      .map((line) => get(line, 'asset.id'));
  },
);

export const getDisabledTirePositionOptions = memoize(
  (allLines = [], curLine = {}) => {
    if (!get(curLine, 'asset.id') || !curLine.productType) return [];

    return allLines
      .filter((line) => !allowDuplicatedValues('asset.id', line, curLine))
      .map((line) => line.tirePosition);
  },
);

export const getDisabledProductTypeOptions = memoize(
  (allLines = [], curLine = {}) => {
    const curLineAssetId = get(curLine, 'asset.id');
    const nonRimTypes = ['New', 'Retread'];

    if (!curLineAssetId || !curLine.tirePosition) return [];

    // Lines that have the same asset and tire position as the current line
    const equalLines = allLines.filter((line) => {
      const assetId = get(line, 'asset.id');
      const { id, tirePosition } = line;

      if (id === curLine.id) return false;
      if (!assetId || !tirePosition) return false;

      return (
        assetId === curLineAssetId && tirePosition === curLine.tirePosition
      );
    });

    if (!equalLines.length) return [];

    const allTypes = new Set(equalLines.map((line) => line.productType));
    const hasRimLines = allTypes.has(rimReplacementProductType);
    const hasNonRimLines = nonRimTypes.some((t) => allTypes.has(t));

    return [
      ...(hasNonRimLines ? nonRimTypes : []),
      ...(hasRimLines ? [rimReplacementProductType] : []),
    ].filter(Boolean);
  },
);

const dropdownPropsBuildersByType = {
  all: [
    (params, allowGeneric) => {
      const { name, rowData, tableData } = params;
      const { columnConfig, genericTireOptions, rowType } = params;

      const isChecked = !!rowData[rowType];

      const lineHasCheckedLineForSameLocation = hasCheckedLineForSameLocation(
        rowData,
        tableData,
        { isCheckedPath: rowType },
      );

      const isValid =
        isChecked || allowGeneric || lineHasCheckedLineForSameLocation
          ? true
          : !genericTireOptions.includes(`${name}:${rowData[name]}`);

      const runtimeConfig = isFunction(columnConfig.getRuntimeConfig)
        ? columnConfig.getRuntimeConfig(params)
        : {};

      return { isValid, ...runtimeConfig };
    },
  ],

  asset: [
    (params) => {
      const { tableData, rowData, rowType } = params;

      if (rowType !== 'requested') return {};

      const ids = getDisabledAssetsOptions(tableData, rowData);

      return { disabledOptionIds: ids };
    },
  ],

  tirePosition: [
    (params) => {
      const { tableData, rowData, rowType } = params;

      if (rowType !== 'requested') return {};

      const ids = getDisabledTirePositionOptions(tableData, rowData);

      return { disabledOptionIds: ids };
    },
  ],

  productType: [
    (params) => {
      const { tableData, rowData, rowType } = params;

      if (rowType !== 'requested') return {};

      const ids = getDisabledProductTypeOptions(tableData, rowData);

      return { disabledOptionIds: ids };
    },
  ],
};

export const getDropdownProps = (params, allowGenericOptionsSelection) => {
  const builders = [
    ...dropdownPropsBuildersByType.all,
    ...(dropdownPropsBuildersByType[params.name] || []),
  ];

  return builders.reduce(
    (acc, builder) => ({
      ...acc,
      ...(builder(params, allowGenericOptionsSelection) || {}),
    }),
    {},
  );
};

const requestLineHasMissingRequiredValues = (line) =>
  without(
    allRequestLineKeys,
    ...optionalRequestLineKeys,
    productTypeIds[line.productType] === 'Retread' ? 'loadRange' : '',
  ).some((key) => {
    const path = key === 'asset' ? 'asset.id' : key;

    return !get(line, path);
  });

export const isRequestLineValid = (line, allLines, props) =>
  getTotalGenericTireOptionSelected([line], allLines, props) === 0 &&
  !requestLineHasMissingRequiredValues(line);

import moment from 'moment-timezone';
import { t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { get, intersectionBy, map } from 'lodash';

import { toTextList } from 'utils/format';

/**
 * Render the time for an event block in the appropriate format.
 * If the time is on-the-hour, exclude the minute ("3PM"); otherwise
 * include the minute ("11:30AM"). If a `withDateFormat` is specified,
 * it will be used to format the the date value of this time, and the
 * date string will be prepended to the result.
 *
 * @param {Moment|string} time
 * @param {string} [withDateFormat = null] an optional date format to prepend
 * @return {string}
 */
// eslint-disable-next-line import/prefer-default-export
export function formatEventTime(time, withDateFormat = false) {
  const t = moment(time);
  const timeFormat = t.minute() ? 'h:mmA' : 'hA';
  const formatString = `${withDateFormat || ''}${timeFormat}`;

  return t.format(formatString);
}

const daysOfWeek = [
  { label: t`Monday`, dayOfWeek: 'MONDAY' },
  { label: t`Tuesday`, dayOfWeek: 'TUESDAY' },
  { label: t`Wednesday`, dayOfWeek: 'WEDNESDAY' },
  { label: t`Thursday`, dayOfWeek: 'THURSDAY' },
  { label: t`Friday`, dayOfWeek: 'FRIDAY' },
  { label: t`Saturday`, dayOfWeek: 'SATURDAY' },
  { label: t`Sunday`, dayOfWeek: 'SUNDAY' },
];

export function formatRecurrenceDays(event) {
  const recurringDays =
    get(event, 'afterHoursEvent.recurring') || get(event, 'recurring');
  // recurringDays will be an array of objects, each having a `dayOfWeek`
  // key that should match one of the `value` values in `daysOfWeek`
  const labels = intersectionBy(daysOfWeek, recurringDays, 'dayOfWeek');
  const translatedLabels = map(labels, ({ label }) => i18n._(label));

  return toTextList(translatedLabels);
}

/* eslint-disable import/prefer-default-export */

import { USER_ROLES } from '.';

export const userCanCreateNewCase = ({ role, loadingRole, permissions }) => {
  const userRole = USER_ROLES[role];

  return (
    !loadingRole &&
    USER_ROLES.CALL_CENTER.includes(userRole) &&
    permissions.includes('case:manage') &&
    userRole !== USER_ROLES.ONCALL_ADMIN_READ_ONLY
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Text } from 'base-components';
import { Row, Column } from 'styled-components-grid';

import { getUnits } from 'utils/unit';
import { getWeatherCode, getWeatherSummary } from 'utils/weather';

import WeatherBadge from 'compositions/DealerLocator/SearchInput/LocationTimeAndWeather/WeatherBadge';

export default function Weather({ unit, weather }) {
  const { current, today } = weather;
  const { temperature, time } = current;
  const { temperatureUnitLabel } = getUnits(unit);

  return (
    <Row modifiers="middle">
      <Column>
        <WeatherBadge name={getWeatherCode(current, today)} />
      </Column>
      <Column modifiers="padScaleY_0">
        <Row>
          <Column modifiers="padScaleY_0">
            <Text modifiers={['small', 'textLight']}>
              {moment(time * 1000).format('LL')}
            </Text>
          </Column>
        </Row>
        <Row>
          <Column modifiers="padScaleY_0">
            <Text>
              {`${Math.round(temperature)}º`}
              {temperatureUnitLabel}
              &nbsp;&middot;&nbsp;
              {getWeatherSummary(current)}
            </Text>
          </Column>
        </Row>
      </Column>
    </Row>
  );
}

Weather.propTypes = {
  unit: PropTypes.string.isRequired,
  weather: PropTypes.shape({
    current: PropTypes.shape({
      code: PropTypes.string,
      summary: PropTypes.string,
      temperature: PropTypes.number,
      time: PropTypes.number,
      weatherId: PropTypes.number,
    }).isRequired,
    today: PropTypes.shape({
      sunset: PropTypes.number,
      sunrise: PropTypes.number,
    }),
  }).isRequired,
};

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';
import Icon from 'decisiv-iconix-react';

function UnstyledButton({ name, ...rest }) {
  // eslint-disable-next-line no-unused-vars
  const { modifiers, ...buttonProps } = rest;

  return (
    <button type="button" {...buttonProps}>
      <Icon name={name} />
    </button>
  );
}

UnstyledButton.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
};

UnstyledButton.defaultProps = {
  modifiers: [],
};

const styles = ({ theme }) => `
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  height: ${theme.dimensions.leftNavWidthCollapsed};
  justify-content: center;
  padding: 0;
  width: ${theme.dimensions.leftNavWidthCollapsed};

  span {
    opacity: 0.5;
  }

  &:hover {
    span {
      opacity: 1;
    }
  }
`;

const themePropTypes = {
  dimensions: PropTypes.shape({
    leftNavWidthCollapsed: PropTypes.string.isRequired,
  }).isRequired,
};

export default buildStyledComponent(
  'ExpanderButton',
  styled(UnstyledButton),
  styles,
  { themePropTypes },
);

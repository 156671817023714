import gql from 'graphql-tag';

export default gql`
  query dealerFeaturesQuery {
    dealerFeatures {
      id
      code
      dealerFilter
      displayIcon
      type
    }
  }
`;

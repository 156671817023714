/* eslint-disable camelcase */

import React, { useMemo } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { defineMessage, Trans } from '@lingui/macro';

import { px2rem } from 'decisiv-ui-utils';
import { Column, Row } from 'styled-components-grid';
import { Badge, DataTable, Text } from 'base-components';

import { contact, message } from './columns';
import EntryDivider from '../../../EntryDivider';

const SYSTEM_USER_USERNAME = 'Internal OnCall User';

const Emphasis = <Text modifiers="fontWeightMedium" />;

const headerMessages = {
  success: {
    eta: defineMessage({
      message: `Automated call with the ETA information succeeded`,
    }),
    roll_time: defineMessage({
      message: `Automated call with the Roll Time information succeeded`,
    }),

    disregard_eta: defineMessage({
      message: `Automated call invalidating previous ETA information succeeded`,
    }),
    disregard_roll_time: defineMessage({
      message: `Automated call invalidating previous Roll Time information succeeded`,
    }),
  },

  fail: {
    eta: defineMessage({
      message: `Automated call with the ETA information failed`,
    }),
    roll_time: defineMessage({
      message: `Automated call with the Roll Time information failed`,
    }),
    disregard_eta: defineMessage({
      message: `Automated call invalidating previous ETA information failed`,
    }),
    disregard_roll_time: defineMessage({
      message: `Automated call invalidating previous Roll Time information failed`,
    }),
  },

  manualSuccess: {
    eta: defineMessage({
      message: `<0>{author}</0> called a contact with the ETA information`,
    }),
    roll_time: defineMessage({
      message: `<0>{author}</0> called a contact with the Roll Time information`,
    }),
  },
};

function CaseAutomatedCallEntry(props) {
  const { user, recordedAt, callAttributes } = props;
  const { type, status } = callAttributes;

  const author = user?.name?.trim() || user.email;
  const isSuccess = status === 'success';
  const isSystemUser = user.userName === SYSTEM_USER_USERNAME;
  const badgeModifier = isSuccess ? 'success' : 'danger';

  const headerMessage = !isSystemUser
    ? headerMessages.manualSuccess[type]
    : headerMessages[isSuccess ? 'success' : 'fail'][type];

  const columns = useMemo(() => {
    return isSystemUser ? [contact, message] : [contact];
  }, [isSystemUser]);

  return (
    <Row>
      <Column modifiers={['col', 'padScale_0']}>
        <Row>
          <Column
            modifiers={['center', 'height_100', 'padScale_0']}
            style={{ height: px2rem(20), width: px2rem(50) }}
          >
            <EntryDivider />
          </Column>
        </Row>

        <Row modifiers="middle">
          <Column modifiers="padScaleX_2">
            <Badge modifiers={['round', badgeModifier]}>
              <Badge.Icon name={isSystemUser ? 'cog' : 'user'} />
            </Badge>
          </Column>
          <Column>
            <Row>
              <Column modifiers="padScale_0">
                <Text modifiers={['small', 'textLight']}>
                  {moment(recordedAt).tz(moment.tz.guess()).format('h:mm A z')}
                </Text>
              </Column>
            </Row>
            <Row>
              <Column modifiers="padScale_0">
                <Text>
                  <Trans
                    id={headerMessage}
                    values={{ author }}
                    components={[Emphasis, Emphasis]}
                  />
                </Text>
              </Column>
            </Row>
          </Column>
        </Row>
        <Row style={{ flexWrap: 'nowrap' }}>
          <Column
            modifiers={['height_100', 'padScaleY_0']}
            style={{ width: px2rem(50), height: 'auto' }}
          >
            <EntryDivider />
          </Column>
          <Column style={{ flex: '1' }} modifiers={['col', 'padScaleX_0']}>
            <DataTable
              name="AutomatedCallInfo"
              columns={columns}
              modifiers=""
              tableData={[props]}
              rowModifiersGetter={() => ['noHighlightOnHover', 'notFlex']}
              rowsRequireRowIndex={false}
            />
          </Column>
        </Row>
      </Column>
    </Row>
  );
}

CaseAutomatedCallEntry.propTypes = {
  id: PropTypes.string.isRequired,
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
  }).isRequired,
  recordedAt: PropTypes.string.isRequired,
  callAttributes: PropTypes.shape({
    type: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    contactName: PropTypes.string.isRequired,
    contactType: PropTypes.string.isRequired,
    callMessage: PropTypes.string.isRequired,
    contactPhoneNumber: PropTypes.string.isRequired,
    contactPhoneExtension: PropTypes.string,
  }).isRequired,
};

export default CaseAutomatedCallEntry;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { omit } from 'lodash';
import { Row as UnstyledRow } from 'styled-components-grid';

import { buildStyledComponent } from 'decisiv-ui-utils';

const modifierConfig = {
  lined: ({ theme }) => ({
    styles: `border-bottom: solid 1px ${theme.colors.base.chrome200};`,
  }),
  selectableHover: ({ theme }) => ({
    styles: `
      cursor: pointer;
      &:hover {
        background-color: ${theme.colors.base.selectableHover};
        box-shadow: 0 2px 1px 0 ${theme.colors.base.shadowLight};
      }
    `,
  }),
  selected: ({ theme }) => ({
    styles: `
      background-color: ${theme.colors.base.selectableActive};
      box-shadow: 0 2px 1px 0 ${theme.colors.base.shadow};
    `,
  }),
};

const Row = (props) => {
  const componentProps = omit(props, ['modifiers']);
  return <UnstyledRow {...componentProps} />;
};

// This acts as our <tr> element
const styles = () => `
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  transition: all 200ms ease;
  vertical-align: top;
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome200: PropTypes.string.isRequired,
      selectableActive: PropTypes.string.isRequired,
      selectableHover: PropTypes.string.isRequired,
      shadow: PropTypes.string.isRequired,
      shadowLight: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default buildStyledComponent('Row', styled(Row), styles, {
  modifierConfig,
  themePropTypes,
});

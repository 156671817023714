import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const modifierConfig = {
  small: () => `
    font-size: ${px2rem(12)};
  `,

  secondary: ({ theme }) => `
    color: ${theme.colors.base.chrome600} !important;
  `,
};

// !important is generally discouraged. This use case is specifically for
// when the Avatar is wrapped in some type of Link or Button component
// that applies the blue & underline on hover.
const styles = ({ theme }) => `
  color: ${theme.colors.base.chrome000} !important;
  font-size: ${px2rem(26)};
  font-weight: ${theme.fontWeights.medium};
  text-transform: uppercase;
  text-decoration: none !important;
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome600: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  fontWeights: PropTypes.shape({
    medium: PropTypes.number.isRequired,
  }).isRequired,
};

export default buildStyledComponent('Initials', styled.span, styles, {
  modifierConfig,
  themePropTypes,
});

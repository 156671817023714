// TODO: export configureFonts to decisiv-ui-utils and pull it from there instead
// And, after doing so, remove the coverage exclusion of "!src/setup/AppThemeProvider/**"
// from package.json's jest > collectCoverageFrom configuration.

import FontFaceObserver from 'fontfaceobserver';

function configureFonts(appTheme) {
  if (process.env.NODE_ENV === 'test') {
    return null;
  }

  const primaryFonts = appTheme.fonts.primary.split(',');
  const fontObservers = primaryFonts.map((f) => new FontFaceObserver(f, {}));

  function fontLoadSuccess() {
    document.body.classList.add('fontsLoaded');
  }

  function fontLoadFailure() {
    // eslint-disable-next-line no-console
    console.error('Fonts have failed to load');
    document.body.classList.remove('fontsLoaded');
  }

  Promise.all(fontObservers.map((o) => o.load())).then(
    fontLoadSuccess,
    fontLoadFailure,
  );

  return true;
}

export default configureFonts;

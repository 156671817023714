// this is a copy/paste from lib/blocks/Popover/Arrow.js (with some modifiers removed)
// will refactor once common logic is identified

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import { setBackground, setBoxShadow } from '../../utils/arrow';

import constants from './constants';

const { ARROW_BASE, ARROW_HEIGHT, ARROW_OFFSET, BOTTOM_ROTATE } = constants;

const modifierConfig = {
  bottom: ({ theme }) => `
    background: ${setBackground(theme.colors.base.background)};
    box-shadow: ${setBoxShadow('bottom', theme.colors.base.shadowLight)};
    left: 0;
    margin: auto;
    right: 0;
    top: -${px2rem(ARROW_HEIGHT)};
    transform: rotate(${BOTTOM_ROTATE}deg) !important;
  `,

  bottomLeft: ({ theme }) => `
    background: ${setBackground(theme.colors.base.background)};
    box-shadow: ${setBoxShadow('bottom', theme.colors.base.shadowLight)};
    right: ${px2rem(ARROW_OFFSET)};
    top: -${px2rem(ARROW_HEIGHT)};
    transform: rotate(${BOTTOM_ROTATE}deg) !important;
  `,

  bottomRight: ({ theme }) => `
    background: ${setBackground(theme.colors.base.background)};
    box-shadow: ${setBoxShadow('bottom', theme.colors.base.shadowLight)};
    left: ${px2rem(ARROW_OFFSET)};
    top: -${px2rem(ARROW_HEIGHT)};
    transform: rotate(${BOTTOM_ROTATE}deg) !important;
  `,
};

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      background: PropTypes.string.isRequired,
      shadow: PropTypes.string.isRequired,
      shadowLight: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const styles = `
  height: ${px2rem(ARROW_BASE)};
  position: absolute;
  width: ${px2rem(ARROW_BASE)};
`;

export default buildStyledComponent('Dropdown__Arrow', styled.div, styles, {
  modifierConfig,
  themePropTypes,
});

import React from 'react';
import { Query } from '@apollo/client/react/components';
import { get, curry } from 'lodash';

import dealerInfoGQL from './dealerInfoQuery';

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, { data, loading, refetch }) => (
    <WrappedComponent
      {...componentProps}
      dealerInfo={get(data, 'dealer')}
      dealerDetailsLoading={!!loading}
      refetchDealerDetails={refetch}
    />
  ),
);

const withDealerInfo = curry((WrappedComponent, componentProps) => {
  const { dealerId } = componentProps;

  return (
    <Query query={dealerInfoGQL} skip={!dealerId} variables={{ id: dealerId }}>
      {buildQueryChild(WrappedComponent, componentProps)}
    </Query>
  );
});

export default withDealerInfo;

import React, { useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { gql, useQuery, useMutation } from '@apollo/client';

const query = gql`
  query caseSpecialInstructionsQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      specialInstructions
    }
  }
`;

const mutation = gql`
  mutation updateCaseSpecialInstructionsMutation(
    $caseId: ID!
    $specialInstructions: String
  ) {
    updateCase(
      input: {
        id: $caseId
        patch: { specialInstructions: $specialInstructions }
      }
    ) {
      case {
        id
        specialInstructions
      }
    }
  }
`;

const withCaseSpecialInstructions = (Component) => (cProps) => {
  const { caseNumber } = cProps;

  const { data } = useQuery(query, {
    skip: !caseNumber,
    variables: { caseNumber },
  });

  const caseId = data?.case?.id;
  const specialInstructions = data?.case?.specialInstructions;

  const [mutate] = useMutation(mutation);

  const setCaseSpecialInstructions = useCallback(
    (specialInstructions) => {
      if (caseId) return mutate({ variables: { caseId, specialInstructions } });
    },
    [mutate, caseId],
  );

  const debounced = useDebouncedCallback(setCaseSpecialInstructions, 1000);

  return (
    <Component
      {...cProps}
      caseId={caseId}
      specialInstructions={specialInstructions}
      setCaseSpecialInstructions={debounced}
    />
  );
};

export default withCaseSpecialInstructions;

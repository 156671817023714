import URL from 'url-parse';
import Bugsnag from '@bugsnag/browser';
import fetchIntercept from 'fetch-intercept';
import { get } from 'lodash';

import { bffAPILink } from 'utils/bff';
import { parseQueryParams } from 'utils/url';

const { host: apiHost, pathname: apiPath } = new URL(bffAPILink);

/**
 * Sets up logging of any `fetch` requests to the back-end API as part of the breadcrumbs
 * that come over with an error report to Bugsnag.
 */
function init() {
  fetchIntercept.register({
    request: (url, config) => {
      const { host, pathname, query } = new URL(url);
      // note: we don't leave breadcrumbs for requests to the BFF graphql API
      // since those are breadcrumb'd via bugsnagBreadcrumbLink.
      if (host !== apiHost || pathname !== apiPath) {
        Bugsnag.leaveBreadcrumb('Fetch Request', {
          host,
          pathname,
          query,
          method: get(config, 'method'),
          body: get(config, 'body'),
          ...parseQueryParams(query),
        });
      }

      return [url, config];
    },
  });
}

export default { init };

import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import { Row } from 'styled-components-grid';
import { Icon, Tooltip, ButtonIconRectangle } from 'base-components';

import ResetButton from '../ResetButton';

function SuppliedLineActionCell({
  rowData: { id, supplied, agreementLineId },
  resetRequestSuppliedLine,
  removeRequestSuppliedLine,
  requestSuppliedLineHasChanges,
}) {
  if (supplied) return null;

  // This line has an associated agreementLine
  if (agreementLineId) {
    const isDisabled = !requestSuppliedLineHasChanges(id);

    return (
      <ResetButton
        label={<Trans>Reset Row</Trans>}
        onClick={() => resetRequestSuppliedLine(id)}
        disabled={isDisabled}
        modifiers={isDisabled && 'disabled'}
      />
    );
  }

  // This line was manually added by the agent
  return (
    <Row modifiers="center">
      <Tooltip position="left">
        <Tooltip.Target>
          <ButtonIconRectangle
            type="button"
            onClick={() => removeRequestSuppliedLine(id)}
            modifiers="hoverDanger"
          >
            <Icon name="trash" />
          </ButtonIconRectangle>
        </Tooltip.Target>
        <Tooltip.Content>
          <Trans>Remove Row</Trans>
        </Tooltip.Content>
      </Tooltip>
    </Row>
  );
}

SuppliedLineActionCell.propTypes = {
  rowData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    supplied: PropTypes.bool,
    agreementLineId: PropTypes.string,
  }).isRequired,
  resetRequestSuppliedLine: PropTypes.func.isRequired,
  removeRequestSuppliedLine: PropTypes.func.isRequired,
  requestSuppliedLineHasChanges: PropTypes.func.isRequired,
};

export default SuppliedLineActionCell;

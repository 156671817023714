import React from 'react';
import { Query } from '@apollo/client/react/components';
import { curry, get } from 'lodash';

import userRoleGQL from './userRoleQuery';

export const buildQueryChild = curry(
  (WrappedComponent, props, { loading, data }) => {
    const role = get(data, 'currentUserProfile.role.name', '');
    const permissions = get(data, 'currentUserProfile.role.permissions', []);
    const userData = { loadingRole: !!loading, role, permissions };

    return <WrappedComponent {...props} {...userData} />;
  },
);

export function buildWrappedComponentWithQuery(WrappedComponent, props) {
  return (
    <Query query={userRoleGQL} fetchPolicy="cache-first">
      {buildQueryChild(WrappedComponent, props)}
    </Query>
  );
}

const withUserRole = (WrappedComponent) => (props) =>
  buildWrappedComponentWithQuery(WrappedComponent, { ...props });

export default withUserRole;

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { parseQueryParams } from 'utils/url';

import { queryParamsKeys } from './constants';

/**
 * HOC to provide the current search parameter for a dealer search, as
 * found in the current URL query string.
 *
 * The search value is provided to the wrapped component as a
 * prop named `currentSearchParam`.
 */
export default function withSearchUrlParams(WrappedComponent) {
  function WithSearchQuery(props) {
    const { location } = props;
    const params = parseQueryParams(location.search);
    const searchQuery = params[queryParamsKeys.searchQuery] || '';
    const excludeMrtAssociates =
      params[queryParamsKeys.excludeMrtAssociates] === '1';

    return (
      <WrappedComponent
        {...props}
        excludeMrtAssociates={excludeMrtAssociates}
        currentSearchParam={searchQuery}
      />
    );
  }

  WithSearchQuery.propTypes = {
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }).isRequired,
  };

  return withRouter(WithSearchQuery);
}

import React from 'react';
import { curry, get } from 'lodash';
import { Query } from '@apollo/client/react/components';
import gql from 'graphql-tag';

export const NAME = 'caseCallLinkQuery';

const callLinkGQL = gql`
  query caseCallLinkQuery($contactId: String!) {
    amazonConnectContactCase(input: { amazonConnectContactId: $contactId }) {
      case {
        id
        caseNumber
      }
    }
  }
`;

function skipQuery({ contact }) {
  return !contact || !contact.contactId;
}

function buildQueryVariables({ contact }) {
  return { contactId: get(contact, 'contactId') || '' };
}

export const buildQueryChild = curry(
  (WrapperComponent, componentProps, queryProps) => {
    const { loading: callLinkLoading } = queryProps;
    const linkedCase = get(queryProps, 'data.amazonConnectContactCase.case');

    return (
      <WrapperComponent
        {...componentProps}
        callLinkLoading={callLinkLoading}
        linkedCase={linkedCase}
      />
    );
  },
);

const withCallCaseLinkQuery = (WrappedComponent) => (componentProps) => (
  <Query
    query={callLinkGQL}
    skip={skipQuery(componentProps)}
    variables={buildQueryVariables(componentProps)}
  >
    {buildQueryChild(WrappedComponent, componentProps)}
  </Query>
);

export default withCallCaseLinkQuery;

import React from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/client/react/components';
import { Column, Container, Row } from 'styled-components-grid';
import { compose, setDisplayName } from 'recompose';
import { curry, get, isEmpty, map } from 'lodash';

import Panel from 'blocks/Panel';
import Loading from 'components/Loading';

import DealersTable from '../../DealersTable';

import { queryParamsKeys } from '../constants';

import NoSearchMessage from './NoSearchMessage';
import NoResultsMessage from './NoResultsMessage';
import searchDealersGQL from './searchDealersQuery';
import withSearchUrlParams from '../withSearchUrlParams';

function displayNoSearch(props) {
  return skipQuery(props);
}

function displayNoResults(props) {
  return isEmpty(props.dealers) && !skipQuery(props);
}

function displayLoading({ dealersPending }) {
  return dealersPending;
}

export function Dealers(props) {
  // Waiting on new search results.
  if (displayLoading(props)) {
    return (
      <Container modifiers={['height_100']}>
        <Row modifiers={['center', 'height_100', 'middle']}>
          <Column modifiers={['col']}>
            <Loading />
          </Column>
        </Row>
      </Container>
    );
  }

  // No dealers and _have not_ run a query.
  if (displayNoSearch(props)) {
    return <NoSearchMessage />;
  }

  // No dealers and _have_ run a query.
  if (displayNoResults(props)) {
    return <NoResultsMessage dealerSearchValue={props.currentSearchParam} />;
  }

  // Dealers present
  return (
    <Panel
      modifiers={['height_100', 'flex_column', 'padScale_0']}
      style={{ overflowY: 'scroll' }}
    >
      <Row modifiers={['flex_1']}>
        <DealersTable
          dealers={map(props.dealers, (d) => ({
            ...d,
            // add the current search to the dealer detail URL
            extraParams: {
              [queryParamsKeys.searchQuery]: props.currentSearchParam,
              [queryParamsKeys.excludeMrtAssociates]: props.excludeMrtAssociates
                ? '1'
                : '0',
            },
          }))}
        />
      </Row>
    </Panel>
  );
}

Dealers.propTypes = {
  currentSearchParam: PropTypes.string,
  excludeMrtAssociates: PropTypes.bool.isRequired,
  dealers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

Dealers.defaultProps = {
  currentSearchParam: '',
};

// ----- wrap the Dealers component in GraphQL: -----

export function skipQuery({ currentSearchParam: searchString }) {
  return !searchString || searchString.length < 3;
}

export function buildQueryVariables(componentProps) {
  const { currentSearchParam, excludeMrtAssociates } = componentProps;

  return { searchString: currentSearchParam, excludeMrtAssociates };
}

export const buildQueryChild = curry((componentProps, queryProps) => {
  const { loading: dealerSearchLoading } = queryProps;
  const dealers = get(queryProps, ['data', 'searchDealers', 'dealers'], []);

  const querySkipped = skipQuery(componentProps);
  const dealersPending = !querySkipped && dealerSearchLoading;

  return (
    <Dealers
      {...componentProps}
      dealersPending={dealersPending}
      dealers={dealers}
    />
  );
});

function DealersWithQuery(componentProps) {
  return (
    <Query
      skip={skipQuery(componentProps)}
      query={searchDealersGQL}
      variables={buildQueryVariables(componentProps)}
    >
      {buildQueryChild(componentProps)}
    </Query>
  );
}

export default compose(
  setDisplayName('Dealers'),
  withSearchUrlParams,
)(DealersWithQuery);

import moment from 'moment-timezone';

const findActiveDelay = ({ startTime, endTime }) => {
  const now = moment();

  return (
    // Compare with the minute because when creating the delay,
    // the startTime coming back from the server might be a second
    // or two after "now". There should be no other reason to have
    // a delay "start in the future", so this should be safe to do.
    now.isSameOrAfter(startTime, 'minute') &&
    (!endTime || now.isSameOrBefore(endTime))
  );
};

export default (caseDelays, getAll = false) => {
  return getAll
    ? caseDelays.filter(findActiveDelay)
    : caseDelays.find(findActiveDelay);
};

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName } from 'recompose';

import { Column, Row } from 'styled-components-grid';
import { ButtonLink, Text } from 'base-components';

import ButtonLinkWithIcon from 'elements/ButtonLinkWithIcon';

import { formatPhone } from 'utils/format';

import WithClickToCall from './WithClickToCall';

function CallButtonLink(props) {
  const {
    icon,
    label,
    enabled,
    placeCall,
    phoneNumber,
    currentCallNumber,
    phoneNumberCountry,
    ...rest
  } = props;

  const Component = icon ? ButtonLinkWithIcon : ButtonLink;

  return (
    <Row>
      <Column modifiers="padScale_0">
        <Component
          icon={icon}
          onClick={placeCall}
          disabled={!enabled}
          modifiers={!enabled && 'disabled'}
          {...rest}
        >
          {label || formatPhone(phoneNumber, phoneNumberCountry)}
        </Component>
      </Column>
      <Column modifiers="padScale_0">
        {currentCallNumber === phoneNumber && (
          <Text modifiers={['danger', 'small']}>
            <Trans>(Calling...)</Trans>
          </Text>
        )}
      </Column>
    </Row>
  );
}

CallButtonLink.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.node,
  enabled: PropTypes.bool.isRequired,
  placeCall: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  currentCallNumber: PropTypes.string,
  phoneNumberCountry: PropTypes.string,
};

CallButtonLink.defaultProps = {
  icon: undefined,
  label: undefined,
  currentCallNumber: null,
  phoneNumberCountry: undefined,
};

export default compose(
  setDisplayName('CallButtonLink'),
  WithClickToCall,
)(CallButtonLink);

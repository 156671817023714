//-------------------------------------------------------
// The following icons are used to render location pins
// on a Google map.

// Asset (indicates search location)
import asset from './asset.svg';

// Destination (indicates search route)
import destination from './destination.svg';

// Intermediary Destination (indicates search route)
import intermediaryDestination from './intermediaryDestination.svg';

// Local Fleet
// dealer features includes:
//   - 'LclFltDlr'
import localFleet from './localFleet.svg';

// Michelin Commercial Service Network (MCSN)
// dealer features includes:
//   - 'SerNetwork'
import mcsn from './mcsn.svg';

// Michelin
// dealer features includes:
//   - 'MichNatAcc'
// no match to other features
import michelin from './michelin.svg';

// MRT
// dealer features includes:
//   - 'MRTAscDealer'
//   - 'ProMRT'
import mrt from './mrt.svg';

export const mapIcons = {
  asset,
  destination,
  intermediaryDestination,
  localFleet,
  mcsn,
  michelin,
  mrt,
};

/**
 * Given a "dealer type" key (evaluated from dealer features in the API),
 * return the associated SVG icon for use in the dealer locator results
 * display for a dealer.
 */
export function mapIconForDealerType(dealerType) {
  return mapIcons[dealerType];
}

export default {
  mapIconForDealerType,
  mapIcons,
};

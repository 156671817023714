import React, { Component } from 'react';
import moment from 'moment-timezone';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { compose, setDisplayName } from 'recompose';

import withContext from 'utils/withContext';

import { DAY_HEADER_HEIGHT } from './constants';
import GridNavigator from './GridNavigator';

const ONE_MINUTE = 60 * 1000;

const TimeIndicator = styled.div`
  top: 50%;
  left: -1px;
  width: calc(98% + 1px);
  height: 1px;
  opacity: 0.4;
  position: absolute;
  background: red;
  pointer-events: none;

  &::before {
    content: '';
    top: -2.5px;
    right: 100%;
    width: 6px;
    height: 6px;
    position: absolute;
    background: red;
    border-radius: 100%;
  }
`;

const getPositionPropsForCurrentTime = ({ timezone }) => {
  const now = moment().tz(timezone);
  const minutes = now.hours() * 60 + now.minutes();
  const percent = (minutes * 100) / (24 * 60);

  return { top: `calc(${percent}% + ${DAY_HEADER_HEIGHT})` };
};

class CurrentDealerTimeIndicator extends Component {
  static propTypes = {
    timezone: PropTypes.string,
    weekStart: PropTypes.shape({ isSame: PropTypes.func.isRequired })
      .isRequired,
  };

  static defaultProps = {
    timezone: moment.tz.guess(),
  };

  static getDerivedStateFromProps(props, state) {
    return props.timezone !== state.timezone
      ? getPositionPropsForCurrentTime(props)
      : null;
  }

  constructor(props) {
    super(props);

    this.state = getPositionPropsForCurrentTime(props);
    this.interval = setInterval(this.update, ONE_MINUTE);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  update = () => this.setState(getPositionPropsForCurrentTime(this.props));

  render() {
    const { timezone, weekStart } = this.props;

    if (!weekStart.isSame(moment().tz(timezone), 'week')) return null;

    return <TimeIndicator style={this.state} />;
  }
}

export default compose(
  setDisplayName('CurrentDealerTimeIndicator'),
  withContext(GridNavigator),
)(CurrentDealerTimeIndicator);

import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { defineMessage, Trans } from '@lingui/macro';

import { Text } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import getCaseTransitionDatesForStatus from 'utils/getCaseTransitionDatesForStatus';

import ElapsedTime from 'components/ElapsedTime';
import { CASE_STATUS } from 'compositions/CaseStatus';
import { getDateAtAgentTZ } from 'compositions/CaseRequestsPanel/CaseRequestsPanelHeader/utils';

import EmptyCell from '../EmptyCell';
import HeaderCell from '../HeaderCell';
import { COLUMN_NAMES } from '../constants';
import { buildBaseColumnConfig } from '../utils';

const dispatchedStatuses = [CASE_STATUS.dispatched, CASE_STATUS.enRoute];

const arrivedStatuses = [
  CASE_STATUS.arrived,
  CASE_STATUS.rolling,
  CASE_STATUS.closed,
  CASE_STATUS.canceled,
];

const expiresInX = defineMessage({ message: 'Expires in {at}' });
const expiredXAgo = defineMessage({ message: 'Expired {at} ago' });

export const getMessageColor = ({ minMinutes, maxMinutes }, expDate) => {
  const minDate = moment(expDate).subtract(maxMinutes || minMinutes, 'minute');
  const isBeforeMinEta = moment().isBefore(minDate);
  const isAfterMaxEta = moment().isAfter(expDate);

  if (isBeforeMinEta) return undefined; // gray
  if (isAfterMaxEta) return 'danger'; // red

  return 'warning';
};

function CaseEtaCell(props) {
  const { status, createdAt, statusHistory } = props;
  const { servicingDealer, isDelayedService } = props;
  const { assetLocationTimezone: tz } = props;
  const { delayedServiceScheduledDate: scheduledDate } = props;

  const eta = get(servicingDealer, 'eta');
  const hasArrived = arrivedStatuses.includes(status);
  const isDispatched = dispatchedStatuses.includes(status);

  if (!eta || (!isDispatched && !hasArrived)) return <EmptyCell />;

  let etaDateMessage = '';

  const getStatusDate = (forStatus) =>
    getCaseTransitionDatesForStatus({
      status: forStatus,
      createdAt,
      statusHistory,
    }).createdAt;

  if (isDispatched) {
    let expiresAt = getDateAtAgentTZ(scheduledDate, tz, { asDate: true });
    const dispatchedAt = moment(getStatusDate(CASE_STATUS.dispatched));

    if (!isDelayedService || !expiresAt) {
      expiresAt = dispatchedAt
        .clone()
        .add(eta.maxMinutes || eta.minMinutes, 'minute')
        .toISOString();
    }

    etaDateMessage = (
      <ElapsedTime
        sinceTime={expiresAt}
        render={({ value: at, isFuture }) => {
          const messageColor = getMessageColor(eta, expiresAt);

          return (
            <Text modifiers={['small', 'textLight', messageColor]}>
              <Trans id={isFuture ? expiresInX : expiredXAgo} values={{ at }} />
            </Text>
          );
        }}
      />
    );
  } else {
    etaDateMessage = (
      <Text modifiers={['small', 'textLight', 'info']}>
        <Trans>
          Arrived {moment(getStatusDate(status)).format('h:mm A z')}
        </Trans>
      </Text>
    );
  }

  return (
    <Container modifiers="padScaleY_2">
      <Row>
        <Column modifiers={['col', 'padScaleY_0']}>
          <Text>
            {eta.maxMinutes ? (
              <Trans id="{minMinutes} - {maxMinutes} min" values={eta} />
            ) : (
              <Trans id="Over 2.5 hours" />
            )}
          </Text>
        </Column>
      </Row>
      <Row>
        <Column modifiers={['col', 'padScaleY_0']}>{etaDateMessage}</Column>
      </Row>
    </Container>
  );
}

CaseEtaCell.propTypes = {
  status: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  statusHistory: PropTypes.arrayOf(PropTypes.shape({})),
  servicingDealer: PropTypes.shape({
    eta: PropTypes.shape({
      minMinutes: PropTypes.number.isRequired,
      maxMinutes: PropTypes.number,
    }).isRequired,
  }),
  isDelayedService: PropTypes.bool.isRequired,
  assetLocationTimezone: PropTypes.string,
  delayedServiceScheduledDate: PropTypes.string,
};

CaseEtaCell.defaultProps = {
  status: CASE_STATUS.new,
  statusHistory: [],
  servicingDealer: undefined,
  assetLocationTimezone: undefined,
  delayedServiceScheduledDate: undefined,
};

export default (...args) => ({
  ...buildBaseColumnConfig(COLUMN_NAMES.eta, ...args),
  headerCellRenderer: (props) => (
    <HeaderCell {...props} label={<Trans>ETA</Trans>} />
  ),
  dataCellRenderer: CaseEtaCell,
});

import React from 'react';
import PropTypes from 'prop-types';

import { Column } from 'styled-components-grid';

export default function InfoPopoverRightPanel({
  modifiers,
  children,
  ...props
}) {
  return (
    <Column modifiers={['padScale_0', ...modifiers]} {...props}>
      {children}
    </Column>
  );
}

InfoPopoverRightPanel.propTypes = {
  children: PropTypes.node.isRequired,
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

InfoPopoverRightPanel.defaultProps = {
  modifiers: [],
};

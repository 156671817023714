import gql from 'graphql-tag';

const query = gql`
  mutation updateDealerNotes($notes: String, $id: ID!) {
    updateDealer(input: { id: $id, patch: { notes: $notes } }) {
      dealer {
        notes
      }
    }
  }
`;

export default query;

import { t } from '@lingui/macro';

// The shifts arrays contain the number of days
// to subtract from the Start and End dates, respectively.
export const periodsConfig = {
  today: { label: t`Today` },
  yesterday: { shifts: [1, 1], label: t`Yesterday` },
  'last-3-days': { shifts: [2, 0], label: t`Last 3 Days` },
  'last-7-days': { shifts: [6, 0], label: t`Last 7 Days` },
  'last-30-days': { shifts: [29, 0], label: t`Last 30 Days` },
  'last-90-days': { shifts: [89, 0], label: t`Last 90 Days` },
  custom: { label: t`Custom` },
};

export const inputFieldDateFormat = 'D MMM YYYY';

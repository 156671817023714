/* istanbul ignore file */
import React from 'react';
import { px2rem } from 'decisiv-ui-utils';
import { Trans } from '@lingui/macro';
import { get } from 'lodash';

import makeCellColumn from './builder';

export default makeCellColumn({
  columnName: 'province',
  label: <Trans>State</Trans>,
  styles: { paddingLeft: px2rem(27) },
  text: (cellData) => get(cellData, ['displayAddress', 'province']),
});

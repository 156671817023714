import React from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { map, uniq } from 'lodash';

import { Row, Column } from 'styled-components-grid';
import { Text, ButtonMicro, Tooltip } from 'base-components';

import { CASE_STATUS } from 'compositions/CaseStatus/constants';

import { buildColumn } from '../preferencesUtils';
import { equivalentSizes, alternateOptionsApplicationId } from './constants';

const equivalentSizesKeys = Object.keys(equivalentSizes);

export const tireChoicePropType = PropTypes.shape({
  type: PropTypes.string,
  size: PropTypes.string,
  brand: PropTypes.string,
  tread: PropTypes.string,
  comments: PropTypes.string,
  loadRange: PropTypes.string,
  application: PropTypes.string,
  requiredActivity: PropTypes.string,
});

export const renderGroupHeader = (metadata) => {
  const { application, sizes } = metadata;

  return (
    <Column
      style={{ whiteSpace: 'nowrap' }}
      modifiers={['col', 'padScaleX_1', 'padScaleY_2', 'display_flex']}
    >
      <Text modifiers="fontWeightMedium">{application}</Text>
      &nbsp;
      <Text modifiers={['small', 'textLight']}>|</Text>
      &nbsp;
      <Text modifiers="fontWeightMedium">{sizes.join(' & ')}</Text>
    </Column>
  );
};

const buildGroupName = (application, ...sizes) =>
  `${application}-${sizes.join(',')}`;

// Groups tires with the same application and size,
// or the same application and an equivalent size, in the same group.
export const groupTires = ({ tires, ...rest }) => {
  const groups = {};

  (tires || []).forEach((tire) => {
    const { application, size } = tire;
    let groupName = buildGroupName(application, size);

    if (equivalentSizesKeys.includes(size)) {
      const equivalentName = buildGroupName(application, equivalentSizes[size]);

      if (groups[equivalentName]) groupName = equivalentName;
    }

    if (!groups[groupName]) groups[groupName] = [];

    groups[groupName].push({ ...tire, priority: groups[groupName].length + 1 });
  });

  const tireGroups = Object.values(groups)
    .reduce((acc, gTires) => {
      const [{ applicationId, application }] = gTires;
      const sizes = uniq(map(gTires, 'size'));
      const name = buildGroupName(application, ...sizes);

      return [...acc, [{ applicationId, application, sizes, name }, gTires]];
    }, [])
    .sort(([{ applicationId: appId1 }], [{ applicationId: appId2 }]) => {
      if (![appId1, appId2].includes(alternateOptionsApplicationId)) return 0;

      return appId1 === alternateOptionsApplicationId ? 1 : -1;
    });

  return { ...rest, tireGroups };
};

const renderActionsColumn = (tire, data) => {
  const { setSelectedTire, caseStatus, isReadOnlyCase } = data;

  const caseIsToDispatch = caseStatus === CASE_STATUS.dispatch;

  const createAgreedLineFromTire = () => {
    const { caseId, axleType, createRequestAgreementLine } = data;

    const values = {
      axleType,
      tireSize: tire.size,
      loadRange: tire.loadRange,
      productType: tire.type,
      requestedAction: 'REPLACE',
      sculptureTreadName: tire.tread,
      manufacturerFullName: tire.brand,
    };

    return createRequestAgreementLine(caseId, values).catch(
      (e) => console.error(e), // eslint-disable-line no-console
    );
  };

  return (
    <Row modifiers="center">
      <Column modifiers="padScale_0">
        <Tooltip position="left">
          <Tooltip.Target>
            <ButtonMicro
              onClick={() => setSelectedTire(tire)}
              modifiers={['info']}
            >
              <ButtonMicro.Icon
                name="info-circle"
                style={{ paddingRight: 0 }}
              />
            </ButtonMicro>
          </Tooltip.Target>
          <Tooltip.Content>
            <Trans>View Details</Trans>
          </Tooltip.Content>
        </Tooltip>
      </Column>
      {caseIsToDispatch && (
        <Column modifiers="padScale_0" style={{ marginLeft: 5 }}>
          <Tooltip position="left">
            <Tooltip.Target>
              <ButtonMicro
                onClick={createAgreedLineFromTire}
                disabled={isReadOnlyCase}
                modifiers={['info', isReadOnlyCase ? 'disabled' : '']}
              >
                <ButtonMicro.Icon name="plus" style={{ paddingRight: 0 }} />
              </ButtonMicro>
            </Tooltip.Target>
            <Tooltip.Content>
              <Trans>Add to Agreed Products</Trans>
            </Tooltip.Content>
          </Tooltip>
        </Column>
      )}
    </Row>
  );
};

export const buildColumnsForTireGroup = (group, props) => {
  const [{ sizes }] = group;
  const { caseStatus } = props;

  const isSingleSize = sizes.length === 1;
  const detailsWidth = caseStatus === CASE_STATUS.dispatch ? '65px' : 'auto';

  const brandColumn = buildColumn('brand', { label: t`Brand` });
  const brandAndTreadColumn = {
    ...buildColumn('brand', { label: t`Brand / Tread` }),
    dataCellRenderer: (tire) => (
      <Row>
        <Column modifiers={['col', 'padScale_0']}>
          <Text modifiers={['small', 'textLight']}>
            {tire.brand}
            <br />
            {tire.tread}
          </Text>
        </Column>
      </Row>
    ),
  };

  const sizeColumn = buildColumn('size', { label: t`Size` });
  const treadColumn = buildColumn('tread', { label: t`Tread` });

  const columns = [
    buildColumn('priority', { label: t`#` }),
    buildColumn('type', { label: t`Type` }),
    isSingleSize ? brandColumn : brandAndTreadColumn,
    isSingleSize ? treadColumn : sizeColumn,
    buildColumn('loadRange', {
      label: t`Load`,
      cellOpts: ['small', 'center'],
      headerOpts: ['small', 'center'],
    }),
    {
      ...buildColumn('details', { label: ' ', width: detailsWidth }),
      dataCellRenderer: (tire) => renderActionsColumn(tire, props),
    },
  ];

  return columns;
};

import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Icon, Text } from 'base-components';
import { Column, Row } from 'styled-components-grid';

import countOfTime from './countOfTime';

// Adapted from the FormattedRelative component found in Fleet Web, which was
// itself adapted from the FormattedRelative component included with the
// react-intl library
// The original can be found here:
// https://github.com/yahoo/react-intl/blob/v2.4.0/src/components/relative.js

const SECOND_MS = 1000;
const MINUTE_MS = 1000 * 60;

/**
 * Calculates the remaining time until the next scheduled update.
 * @param {number} nextUpdateAt the unix timestamp of the next expected tick
 * @return {number} the delay until the next tick in MS
 */
function calculateDelay(nextUpdateAt) {
  return Math.abs(nextUpdateAt - moment().valueOf());
}

/**
 * Calculates the new nextUpdateAt
 * @param {object} options
 * @param {string} options.sinceTime A formatted string timestamp (like ISO8601)
 * @param {number} options.updateInterval a specified "tick" in MS
 * @returns {number} a unix timestamp in MS
 */
function calculateNextUpdateTime({
  sinceTime: sinceTimeString,
  updateInterval,
}) {
  if (!sinceTimeString || !updateInterval) {
    return undefined;
  }

  const sinceTime = moment(sinceTimeString);

  // if the sinceTime was less than 1 minute ago
  if (sinceTime.isAfter(moment().subtract(1, 'minute'))) {
    // "tick" on every second (or the updateInterval). Using `.startOf('second')`
    // helps keep this logic from affecting the tick timing.
    return moment()
      .startOf('second')
      .add(Math.max(updateInterval, SECOND_MS), 'milliseconds')
      .valueOf();
  }

  // otherwise, "tick" on every minute (or the updateInterval).
  return moment()
    .startOf('minute')
    .add(Math.max(updateInterval, MINUTE_MS), 'milliseconds')
    .valueOf();
}

export default class ElapsedTime extends Component {
  static propTypes = {
    sinceTime: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]).isRequired,
    inline: PropTypes.bool,
    textModifiers: PropTypes.arrayOf(PropTypes.string),
    render: PropTypes.func,
    // eslint-disable-next-line react/no-unused-prop-types
    updateInterval: PropTypes.number,
  };

  static defaultProps = {
    inline: false,
    textModifiers: [],
    render: undefined,
    updateInterval: 1000,
  };

  constructor(props) {
    super(props);

    this.state = {
      // eslint-disable-next-line react/no-unused-state
      lastUpdateAt: undefined,
      nextUpdateAt: undefined,
    };
    this.timer = undefined;
  }

  componentDidMount() {
    this.scheduleNextUpdate();
  }

  componentDidUpdate() {
    this.scheduleNextUpdate();
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  scheduleNextUpdate = () => {
    const { nextUpdateAt } = this.state;
    const newNextUpdateAt = calculateNextUpdateTime(this.props);

    if (
      newNextUpdateAt &&
      (!this.timer || !nextUpdateAt || nextUpdateAt !== newNextUpdateAt)
    ) {
      const delay = calculateDelay(newNextUpdateAt);

      clearTimeout(this.timer);
      this.timer = setTimeout(
        // Setting the lastUpdateAt time in state triggers a new render, which updates the
        // displayed time. Once that re-render is complete, a new update will be scheduled.
        () =>
          this.setState({
            // eslint-disable-next-line react/no-unused-state
            lastUpdateAt: moment().valueOf(),
          }),
        delay,
      );

      this.setState({ nextUpdateAt: newNextUpdateAt });
    }
  };

  render() {
    const { inline, textModifiers, render, sinceTime } = this.props;

    // The time value should represent the total elapsed time between the sinceTime
    // and the time of render.
    const time = countOfTime(sinceTime, new Date(), this.props);

    if (render) return render(time);

    return inline ? (
      <span>{time.value}</span>
    ) : (
      <Row modifiers={['middle']}>
        <Column modifiers={['padScale_0']}>
          <Icon modifiers={['mini']} name="stopwatch" />
        </Column>
        <Column modifiers={['padScaleY_0']}>
          <Text
            modifiers={[
              'fontWeightLight',
              'small',
              'textLight',
              ...textModifiers,
            ]}
          >
            {time.value}
          </Text>
        </Column>
      </Row>
    );
  }
}

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const styles = ({ theme: { colors } }) => `
  border: ${px2rem(1)} solid ${colors.base.chrome400};
  margin-bottom: ${px2rem(-1)};
  position: relative;
  width: 100%;
  padding: ${px2rem(10)};
  box-sizing: border-box;
  z-index: 0;
`;

const modifierConfig = {
  selectable: ({ theme: { colors } }) => `
    cursor: pointer;

    &:hover {
      background: ${colors.base.chrome100};
      z-index: 1;
    }
  `,
  disabled: ({ theme: { colors } }) => `
    position: relative;
    cursor: default;
    pointer-events: none;

    &::before {
      background-color: ${colors.base.chrome100};
      bottom: ${px2rem(-1)};
      content: '';
      left: ${px2rem(-1)};
      opacity: 0.3;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }
  `,
};

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome100: PropTypes.string.isRequired,
      chrome400: PropTypes.string.isRequired,
      chrome600: PropTypes.string.isRequired,
      background: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default buildStyledComponent(
  'DeleteContactModal_Item',
  styled.div,
  styles,
  {
    themePropTypes,
    modifierConfig,
  },
);

import moment from 'moment-timezone';
import { forEach, sortedLastIndex, sortBy } from 'lodash';
import { defineMessage } from '@lingui/macro';
import { i18n } from '@lingui/core';

const relativeTimeConfig = {
  /*
    Each relative-time language dictionary is broken up into two sections: minutes and months.

    The keys within each section define upper bounds, in the given unit, for which the
    value should be be used.

    By "upper bounds", this means that the value would be used for any time difference
    up to, but NOT including, the corresponding number.  E.g. for unit "minutes" and a key
    value of "90", the corresponding value would be used for any relative time difference
    less than 90 minutes. For a difference of 90 or more minutes, the NEXT entry would be
    used.

    Each value is a string template. Each template can contain one (and only one) of
    the following substitutions:
      {seconds}
      {minutes}
      {hours}
      {days}
      {weeks}
      {months}
      {years}

    The substitution string will be replaced by the result of moment#diff, calculated
    for the given unit. For more information:
      https://momentjs.com/docs/#/displaying/difference/
   */
  future: {
    minutes: {
      60: defineMessage({ message: 'Less than an Hour' }), // < 1 hour
      [2 * 60]: defineMessage({ message: '1 Hour' }), // ≥ 1 hour, < 2 hours
      [24 * 60]: defineMessage({ message: '{hours} Hours' }), // ≥ 2 hours, < 24 hours
      [2 * 24 * 60]: defineMessage({ message: 'More than a Day' }), // ≥ 24 hours, < 48 hours (2 days)
      [7 * 24 * 60]: defineMessage({ message: '{days} Days' }), // ≥ 2 days, < 7 days
      [2 * 7 * 24 * 60]: defineMessage({ message: 'More than a Week' }), // ≥ 7 days, < 14 days (2 weeks)
    },
    months: {
      1: defineMessage({ message: '{weeks} Weeks' }), // ≥ 2 weeks, < 1 month
      2: defineMessage({ message: 'More than a Month' }), // ≥ 1 month, < 2 months
      12: defineMessage({ message: '{months} Months' }), // ≥ 2 months, < 12 months (1 year)
      24: defineMessage({ message: 'More than a Year' }), // ≥ 1 year, < 24 months (2 years)
    },
    max: defineMessage({ message: '{years} Years' }), // ≥ 2 years
  },
  past: {
    minutes: {
      1: defineMessage({ message: 'Less than a Minute' }),
      2: defineMessage({ message: 'A Minute Ago' }),
      60: defineMessage({ message: '{minutes} Minutes Ago' }),
      [2 * 60]: defineMessage({ message: 'An Hour Ago' }),
      [24 * 60]: defineMessage({ message: '{hours} Hours Ago' }),
      [2 * 24 * 60]: defineMessage({ message: 'Yesterday' }),
      [7 * 24 * 60]: defineMessage({ message: '{days} Days Ago' }),
      [2 * 7 * 24 * 60]: defineMessage({ message: 'A Week Ago' }),
    },
    months: {
      1: defineMessage({ message: '{weeks} Weeks Ago' }),
      2: defineMessage({ message: 'A Month Ago' }),
      12: defineMessage({ message: '{months} Months Ago' }),
      24: defineMessage({ message: 'A Year Ago' }),
    },
    max: defineMessage({ message: '{years} Years Ago' }),
  },
  downtime: {
    minutes: {
      60: defineMessage({ message: 'Less than an Hour' }),
      [2 * 60]: defineMessage({ message: 'More than an Hour' }),
      [24 * 60]: defineMessage({ message: '{hours} Hours' }),
      [2 * 24 * 60]: defineMessage({ message: 'More than a Day' }),
      [7 * 24 * 60]: defineMessage({ message: '{days} Days' }),
      [2 * 7 * 24 * 60]: defineMessage({ message: 'Over a Week' }),
    },
    months: {
      1: defineMessage({ message: '{weeks} Weeks' }),
      2: defineMessage({ message: 'Over a Month' }),
      12: defineMessage({ message: '{months} Months' }),
      24: defineMessage({ message: 'Over a Year' }),
    },
    max: defineMessage({ message: 'Over {years} Years' }),
  },
};

/**
 * Get the appropriate relative-time map for the given times, style, and the
 * current language.
 * @param {Moment|String|Number|Date|Array} time
 * @param {Moment|String|Number|Date|Array} referenceTime
 * @param {String} style - a specific override format to use
 * @return {*}
 */
function relativeTimeDictionary(time, referenceTime, style = undefined) {
  if (style && relativeTimeConfig[style]) return relativeTimeConfig[style];
  if (moment(time).isAfter(referenceTime)) return relativeTimeConfig.future;

  return relativeTimeConfig.past;
}

/**
 * Return a human-readable rendering of a relative time - the duration between
 * the given `time` and some baseline "reference time".
 *
 * The style can be specified as a string which is used to look up the appropriate
 * variation of relative time strings, e.g. "downtime" or "realtime". These should
 * be keys into the current locale's relative time dictionary.
 *
 * If no `style` is specified, either "future" or "past" is used, depending on whether
 * the time is after (future) or before (past) the reference time.
 *
 * @param {Moment|String|Number|Date|Array} time
 * @param {Moment|String|Number|Date|Array} referenceTime
 * @param {String} style - a specific override format to use
 * @return {String}
 */
function relativeTimeTemplate(time, referenceTime, style) {
  const dictionary = relativeTimeDictionary(time, referenceTime, style);

  let result;
  const units = ['minutes', 'months'];

  forEach(units, (unit) => {
    // get this unit's section of the dictionary
    const dictionarySection = dictionary[unit];
    if (dictionarySection) {
      // Find out the absolute difference (in ms) between the two times, in this unit
      const diff = Math.abs(moment(referenceTime).diff(time, unit));
      // Get a sorted list of the numeric keys in the dictionary.
      // These keys each define an upper limit, i.e. for a key of 100, the value
      // of that entry in the dictionary would be used for any diff less than 100.
      const upperRanges = sortBy(Object.keys(dictionarySection).map(Number));
      // Find which position in the list of ranges this diff would fit.
      const fit = sortedLastIndex(upperRanges, diff);
      if (fit < upperRanges.length) {
        // fit is the index of the translation to use
        const fitInRange = upperRanges[fit];
        result = dictionarySection[`${fitInRange}`];
        return false; // break out of the forEach, since we have a value now
      }
    }

    return true; // move on to the next unit
  });

  return result || dictionary.max;
}

const SUBSTITUTION_UNITS = [
  'years',
  'months',
  'weeks',
  'days',
  'hours',
  'minutes',
  'seconds',
];
const SUBSTITUTION_REGEXP = `{(${SUBSTITUTION_UNITS.join('|')})}`;

export default function relativeTime(
  time,
  { referenceTime = new Date(), style = null } = {},
) {
  const template = relativeTimeTemplate(time, referenceTime, style);

  const regExp = new RegExp(SUBSTITUTION_REGEXP);
  const matches = regExp.exec(template?.id || template);

  if (!matches) {
    // no substitutions to be made in the template, so return it verbatim
    return i18n._(template);
  }

  const unit = matches[1];
  const substitution = Math.abs(moment(referenceTime).diff(time, unit));

  return i18n._(template, { [unit]: substitution });
}

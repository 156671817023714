import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { compose, setDisplayName } from 'recompose';

import { H3 } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import withContext from 'utils/withContext';
import MiniCaseStatusBadge from 'elements/CaseStatusBadge/Mini';

import CaseStatusContext from '../../../CaseStatusContext';
import { PANEL_STATUSES } from '../../../constants';

const Title = buildStyledComponent(
  'H3',
  H3,
  ({ theme }) => `
    color: ${theme.colors.base.chrome000};
    font-weight: ${theme.fontWeights.light};
  `,
  {
    modifierConfig: {
      invalid: ({ theme }) => `color: ${theme.colors.base.chrome700}`,
    },
    themePropTypes: {
      colors: PropTypes.shape({
        base: PropTypes.shape({
          chrome000: PropTypes.string.isRequired,
          chrome700: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
      fontWeights: PropTypes.shape({
        light: PropTypes.number.isRequired,
      }).isRequired,
    },
  },
);

const TitleColumn = buildStyledComponent(
  'BadgeColumn',
  styled(Column),
  `
    padding: 0;
    margin-left: ${px2rem(8)};

    @media (max-width: ${px2rem(1023)}) {
      display: none;
    }
  `,
);

const BadgeColumn = buildStyledComponent(
  'BadgeColumn',
  styled.div,
  `
    margin-left: ${px2rem(6)};
    margin-right: ${px2rem(8)};
    white-space: nowrap;

    @media (max-width: ${px2rem(1365)}) {
      display: none;
    }
  `,
  {
    modifierConfig: {
      // Add the nav's expanded width
      narrow: () => `
        @media (max-width: ${px2rem(1575)}) {
          display: none;
        }
      `,
    },
  },
);

export function Content({
  status,
  caseNumber,
  isNavExpanded,
  validationStatus,
  ...rest
}) {
  const isInvalid = validationStatus === PANEL_STATUSES.invalid;

  return (
    <Container modifiers="padScale_0" {...rest}>
      <Row modifiers={['padScaleY_0', 'middle']} style={{ flexWrap: 'nowrap' }}>
        <TitleColumn>
          <Title modifiers={isInvalid && 'invalid'}>{caseNumber}</Title>
        </TitleColumn>
        <BadgeColumn modifiers={isNavExpanded && 'narrow'}>
          <MiniCaseStatusBadge status={status} modifiers="inverted" />
        </BadgeColumn>
      </Row>
    </Container>
  );
}

Content.propTypes = {
  status: PropTypes.string.isRequired,
  caseNumber: PropTypes.string.isRequired,
  isNavExpanded: PropTypes.bool.isRequired,
  validationStatus: PropTypes.string.isRequired,
};

export default compose(
  setDisplayName('BasicInfo__Content'),
  withContext(CaseStatusContext),
)(Content);

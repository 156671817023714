import { curry, get } from 'lodash';
import React from 'react';
import { Query } from '@apollo/client/react/components';

import { CASE_STATUS } from 'compositions/CaseStatus/constants';

import caseStatusHistoryGQL from './caseStatusHistoryQuery';

export function skipQuery({ caseNumber }) {
  return !caseNumber;
}

export function buildQueryVariables({ caseNumber }) {
  return { caseNumber };
}

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const createdAt = get(queryProps, 'data.case.createdAt', '');
    const createdBy = get(queryProps, 'data.case.createdBy') || {};
    const statusHistory = get(queryProps, 'data.case.statusHistory', []);
    const loading = get(queryProps, 'loading');

    const statusUpdates = [
      ...statusHistory,
      {
        id: `${createdBy.email}-${createdAt}`,
        changedAt: createdAt,
        newStatus: CASE_STATUS.new,
        user: createdBy,
      },
    ];

    return (
      <WrappedComponent
        {...componentProps}
        statusHistory={statusUpdates}
        loading={loading}
      />
    );
  },
);

const withCaseStatusHistory = (WrappedComponent) => (componentProps) => (
  <Query
    query={caseStatusHistoryGQL}
    skip={skipQuery(componentProps)}
    variables={buildQueryVariables(componentProps)}
  >
    {buildQueryChild(WrappedComponent, componentProps)}
  </Query>
);

export default withCaseStatusHistory;

import PropTypes from 'prop-types';

import { Container } from 'styled-components-grid';
import { buildStyledComponent } from 'decisiv-ui-utils';

const styles = ({ theme }) => `
  border-radius: ${theme.dimensions.borderRadius};
  background-color: ${theme.colors.base.background};
  font-family: ${theme.fonts.primary};
  pointer-events: none;
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      background: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dimensions: PropTypes.shape({
    borderRadius: PropTypes.string.isRequired,
  }).isRequired,
  fonts: PropTypes.shape({
    primary: PropTypes.string.isRequired,
  }).isRequired,
};

export default buildStyledComponent('RouteInfo__Content', Container, styles, {
  themePropTypes,
});

import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, sortBy } from 'lodash';
import { t, Trans } from '@lingui/macro';
import { branch, compose, withState, setDisplayName } from 'recompose';

import { px2rem } from 'decisiv-ui-utils';
import { Accordion, ButtonMicro, Text } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import { useCaseShortcut, CASE_SHORTCUT_PANELS } from 'features/keyShortcuts';

import withCustomerSelect from '../../withCustomerSelect';
import withStoreCustomerSelect from '../../withStoreCustomerSelect';
import withCustomerBillingPreferences from './withCustomerBillingPreferences';
import BillingPreferenceValue, { valuesConfig } from './BillingPreferenceValue';
import ValueLabel from '../ValueLabel';
import PreferenceDetailsModal from '../PreferenceDetailsModal';

const valuesConfigEntries = Object.entries(valuesConfig);

const rowsConfig = valuesConfigEntries.map(
  ([valueProp, { label, mapValue }]) => ({ label, mapValue, valueProp }),
);

const isRequired = ({ type, value }) =>
  value === 'REQUIRED' ||
  (type === 'creditCardRuleAbbreviation' && value === 'M') ||
  (type !== 'creditCardRuleAbbreviation' &&
    !['REQUIRED', 'NOT_REQUIRED'].includes(value) &&
    value);

// Purchase Order (PO) and Credit Card goes first when required, rest is sorted in alpha order
const sortPreferences = (entries, getEntryData) =>
  sortBy(entries, [
    (entry) => {
      const { type, value } = getEntryData(entry);
      return (type === 'creditCardRuleAbbreviation' ||
        type === 'purchaseOrderRule') &&
        isRequired({ type, value })
        ? 0
        : type;
    },
  ]);

export function BillingPreferences({
  billingPreferences,
  customer,
  modalVisible,
  setModalVisible,
}) {
  const hasData = !isEmpty(billingPreferences);

  const [expanded, setExpanded] = React.useState(false);

  const { onFocusRequested } = useCaseShortcut({
    parent: CASE_SHORTCUT_PANELS.customer,
    id: 'billingPreferencesAction',
    name: t`Billing Preferences`,
    shortcut: ['f', '4'],
    keywords: 'fleet billing preferences',
    priority: 4,
    perform: () => hasData && setExpanded((e) => !e),
    deps: hasData,
  });

  // Show only required items in the widget
  const requiredConfigEntries =
    hasData &&
    sortPreferences(valuesConfigEntries, ([type]) => ({
      type,
      value: billingPreferences[type],
    })).filter(([type]) =>
      isRequired({ type, value: billingPreferences[type] }),
    );

  return (
    <Accordion
      key={get(customer, 'id')}
      disabled={!hasData}
      expanded={expanded}
      onExpandedChange={({ expanded }) => setExpanded(expanded)}
    >
      <Accordion.Head>
        <Accordion.Title ref={onFocusRequested}>
          <Trans>Billing Preferences</Trans>
        </Accordion.Title>
      </Accordion.Head>
      {!isEmpty(requiredConfigEntries) && (
        <Accordion.Body>
          <Container style={{ paddingBottom: px2rem(25) }}>
            {requiredConfigEntries.map(([type, { label }], index) => (
              <Row key={type}>
                <Column modifiers={['col_5']}>
                  <ValueLabel>
                    <Trans id={label} />
                  </ValueLabel>
                </Column>
                <Column modifiers={['col']}>
                  <BillingPreferenceValue
                    label={label}
                    type={type}
                    values={billingPreferences}
                  />
                </Column>
                {index === 0 && (
                  <Column modifiers={['col', 'padScale_0']}>
                    <ButtonMicro
                      modifiers={['mini', 'info']}
                      onClick={() => setModalVisible(true)}
                      style={{ marginLeft: 'auto' }}
                    >
                      <ButtonMicro.Icon
                        name="info-circle"
                        style={{ paddingRight: 0 }}
                      />
                    </ButtonMicro>
                  </Column>
                )}
              </Row>
            ))}
          </Container>
        </Accordion.Body>
      )}

      {isEmpty(requiredConfigEntries) && (
        <Accordion.Body>
          <Container style={{ paddingBottom: px2rem(25) }}>
            <Row modifiers="middle">
              <Column>
                <Text>
                  <Trans>No Required Billing Preferences</Trans>
                </Text>
              </Column>
              <Column modifiers={['col', 'padScale_0']}>
                <ButtonMicro
                  modifiers={['mini', 'info']}
                  onClick={() => setModalVisible(true)}
                  style={{ marginLeft: 'auto' }}
                >
                  <ButtonMicro.Icon
                    name="info-circle"
                    style={{ paddingRight: 0 }}
                  />
                </ButtonMicro>
              </Column>
            </Row>
          </Container>
        </Accordion.Body>
      )}

      {modalVisible && (
        <PreferenceDetailsModal
          title={<Trans>Billing Preference Details</Trans>}
          iconName="receipt"
          onClose={() => setModalVisible(null)}
          preference={billingPreferences}
          rowsConfig={sortPreferences(rowsConfig, ({ valueProp: type }) => ({
            type,
            value: billingPreferences[type],
          })).map(({ valueProp, ...rest }) => ({
            ...rest,
            valueProp,
            textStyle: isRequired({
              type: valueProp,
              value: billingPreferences[valueProp],
            })
              ? { color: 'red' }
              : {},
          }))}
        />
      )}
    </Accordion>
  );
}

BillingPreferences.propTypes = {
  billingPreferences: PropTypes.shape({
    creditCardRuleAbbreviation: PropTypes.string,
    purchaseOrderRule: PropTypes.string,
  }).isRequired,
  customer: PropTypes.shape({ id: PropTypes.string }),
  modalVisible: PropTypes.bool,
  setModalVisible: PropTypes.func,
};

BillingPreferences.defaultProps = {
  customer: undefined,
  modalVisible: false,
  setModalVisible: () => {},
};

const isNationalCustomer = ({ customerType, customerTypes }) =>
  customerType === customerTypes.NATIONAL.type;

export default compose(
  setDisplayName('BillingPreferences'),
  /**
   * Order is important here. `withCustomerBillingPreferences` needs the current customer
   * as a prop, so we must run `withCustomerSelect/withStoreCustomerSelect`
   * first.
   */
  branch(isNationalCustomer, withCustomerSelect, withStoreCustomerSelect),
  withCustomerBillingPreferences,
  withState('modalVisible', 'setModalVisible', false),
)(BillingPreferences);

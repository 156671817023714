import React from 'react';
import { get } from 'lodash';
import { Trans } from '@lingui/macro';

import { Text } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import EmptyCell from '../EmptyCell';
import HeaderCell from '../HeaderCell';
import { COLUMN_NAMES } from '../constants';
import { buildBaseColumnConfig } from '../utils';

export default (...args) => ({
  ...buildBaseColumnConfig(COLUMN_NAMES.inboundProgram, ...args),
  cellDataGetter: (data) => get(data, 'node.inboundProgramNumber'),
  headerCellRenderer: (props) => (
    <HeaderCell {...props} label={<Trans>Inbound Program</Trans>} />
  ),
  dataCellRenderer: (inboundProgram) =>
    inboundProgram ? (
      <Container modifiers="padScaleY_2">
        <Row>
          <Column modifiers={['col', 'padScaleY_0']}>
            <Text>{inboundProgram.customerName}</Text>
          </Column>
        </Row>
      </Container>
    ) : (
      <EmptyCell />
    ),
});

import styled from 'styled-components';
import PropTypes from 'prop-types';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const styles = ({ theme }) => `
  align-items: center;
  background-color: ${theme.colors.base.background};
  border-radius: ${theme.dimensions.borderRadius};
  box-shadow: 0 ${px2rem(1)} ${px2rem(1)} 0 ${theme.colors.base.shadowLight};
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: ${px2rem(6)};
  height: ${px2rem(29)};
  box-sizing: border-box;
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      background: PropTypes.string.isRequired,
      shadowLight: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dimensions: PropTypes.shape({
    borderRadius: PropTypes.string.isRequired,
  }).isRequired,
};

const Box = buildStyledComponent('MapControlButton__Box', styled.div, styles, {
  themePropTypes,
});

export default Box;

import gql from 'graphql-tag';
import { get, isEmpty, merge, omit } from 'lodash';
import React from 'react';
import { Query } from '@apollo/client/react/components';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { unitSelector } from 'redux/preferences/selectors';

import { getUnits } from 'utils/unit';
import { latLngToString } from 'utils/geoLocation';

const CASE_DEALER_RESPONSES_QUERY = gql`
  query compositions_CaseDealerSelectionPanel_dealerTravelEstimate(
    $dealerId: ID!
    $distanceUnit: DistanceUnit
    $assetLocation: String!
  ) {
    dealer(id: $dealerId) {
      id
      travelEstimate(destination: $assetLocation) {
        distance(unit: $distanceUnit)
        time
      }
    }
  }
`;

const withDealerTravelEstimate = (WrappedComponent) => ({
  // eslint-disable-next-line react/prop-types
  unit,
  ...componentProps
}) => {
  const { distanceUnitValue: distanceUnit } = getUnits(unit);
  const {
    dealerResponses: {
      dealer: { id: dealerId, __typename },
    },
    assetLocationCoordinates,
  } = componentProps;

  const isCustomDealer = __typename === 'CustomDealer';
  const assetLocation = latLngToString(assetLocationCoordinates);

  return (
    <Query
      query={CASE_DEALER_RESPONSES_QUERY}
      skip={!dealerId || isEmpty(assetLocationCoordinates) || isCustomDealer}
      variables={{ assetLocation, dealerId, distanceUnit }}
    >
      {({ data }) => {
        const updatedDealerResponses = {
          ...componentProps.dealerResponses,
          dealer: merge(
            {},
            get(data, 'dealer', {}),
            componentProps.dealerResponses.dealer,
          ),
        };

        return (
          <WrappedComponent
            {...componentProps}
            dealerResponses={updatedDealerResponses}
          />
        );
      }}
    </Query>
  );
};

/**
 * The asset location is required to query for a travel estimate in the dealerResponses.
 * @param {React.Component} WrappedComponent
 */
const withAssetLocation = (WrappedComponent) => (componentProps) => (
  <Query
    query={gql`
      query compositions_CaseDealerSelectionPanel_caseAssetLocation(
        $caseNumber: String!
      ) {
        case(caseNumber: $caseNumber) {
          id
          assetLocation {
            coordinates {
              latitude
              longitude
            }
          }
        }
      }
    `}
    skip={!componentProps.caseNumber}
    variables={{
      caseNumber: componentProps.caseNumber,
    }}
  >
    {({ data }) => {
      const assetLocationCoordinates = omit(
        get(data, 'case.assetLocation.coordinates', {}),
        '__typename',
      );
      return (
        <WrappedComponent
          {...componentProps}
          assetLocationCoordinates={assetLocationCoordinates}
        />
      );
    }}
  </Query>
);

const withAdditionalHOCs = (WrappedComponent) => {
  function mapStateToProps(state) {
    return { unit: unitSelector(state) };
  }

  return compose(
    connect(mapStateToProps),
    withAssetLocation,
    withDealerTravelEstimate,
  )(WrappedComponent);
};

export default withAdditionalHOCs;

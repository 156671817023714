import { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useRegisterActions, createAction, Priority } from 'kbar';
import { t } from '@lingui/macro';

import buildMenuGroups from 'compositions/LeftNav/menuGroups';

const useNavigationShortcuts = ({ role }) => {
  const location = useLocation();
  const history = useHistory();

  const actions = useMemo(() => {
    return buildMenuGroups(role, location).reduce((acc, group, index) => {
      const actions = [];

      const item = group[0];
      const action = buildActionFromMenuItem(item, history, index);

      actions.push(action);

      if (item.menuSubItems) {
        item.menuSubItems.forEach((subItem) => {
          const subAction = buildActionFromMenuItem(subItem, history);
          actions.push({
            ...subAction,
            parent: action.id,
            section: action.name,
          });
        });
      }

      return [...acc, ...actions];
    }, []);
  }, [role, location, history]);

  useRegisterActions(actions, [actions]);
};

export default useNavigationShortcuts;

function buildActionFromMenuItem(item, history, index) {
  const action = createAction({
    name: item.message,
    icon: item.icon,
    section: t`Navigation`,
    priority: Priority.LOW,
    shortcut:
      typeof index !== 'undefined' ? ['n', String(index + 1)] : undefined,
  });

  if (item.path) {
    action.perform = () => {
      history.push(item.path);
    };
  } else if (item.url) {
    action.perform = () => {
      window.open(item.url, '_blank');
    };
  }

  return action;
}

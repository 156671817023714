import gql from 'graphql-tag';

export default gql`
  fragment ContactInfo on AfterHoursContact {
    id
    name
    jobTitle
    primaryPhoneNumber
    secondaryPhoneNumber
  }

  query dealerLocator_activeAfterHoursContactsQuery($dealerId: ID!) {
    activeAfterHoursContacts(input: { dealerId: $dealerId }) {
      contacts {
        backupContact {
          ...ContactInfo
        }
        primaryContact {
          ...ContactInfo
        }
        finalContact {
          ...ContactInfo
        }
      }
    }
  }
`;

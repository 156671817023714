import React from 'react';
import PropTypes from 'prop-types';
import { compact } from 'lodash';
import { Column } from 'styled-components-grid';
import { t } from '@lingui/macro';

import { RETREAD_SERVICE_CODES } from 'utils/dealerFilter';

import ONCallSquareIcon from 'components/ONCallSquareIcon';

const IMPORTANT_SERVICE_CODES = [
  'ERS247',
  'RetreadDealer',
  'ProXone',
  'MichNatAcc',
  'FIXPIX',
];

const RETREAD_SERVICE_TOOLTIPS = {
  'ProMRT,ProMegaMile': t`Megamile and MRT Retreads`,
  'ProMRT,ProMegaMile,ProOliver': t`Megamile, MRT and Oliver Retreads`,
  'ProMegaMile,ProOliver': t`Megamile and Oliver Retreads`,
  'ProMRT,ProOliver': t`MRT and Oliver Retreads`,
};

function ServiceSummaryIcons({ services, tooltipPositions }) {
  if (!services.length) {
    return null;
  }

  const serviceCodes = services.map((f) => f.code);
  const retreadServiceCodes = serviceCodes.filter((c) =>
    RETREAD_SERVICE_CODES.includes(c),
  );

  const isRetreadService = (code) =>
    code === 'RetreadDealer' && !!retreadServiceCodes.length;

  const getActiveModifier = (code) =>
    compact([serviceCodes.includes(code) && 'active']);

  const getTooltip = (code) => {
    if (!isRetreadService(code) || retreadServiceCodes.length === 1) {
      return null;
    }

    const tooltipCode = retreadServiceCodes.sort().join(',');
    return RETREAD_SERVICE_TOOLTIPS[tooltipCode] || null;
  };

  function getToolTipPosition(i) {
    // to supress tooltips simply ignore the prop
    if (!tooltipPositions) return '';

    // to make the API more friendly :)
    // instead of passing: tooltipPositions={['top', 'top', 'top', 'top']}
    // do this: tooltipPositions={['top']}
    if (tooltipPositions.length === 1) return tooltipPositions[0];

    // to explicitly state tooltip directions:
    // tooltipPositions={['top', 'left', 'bottom', 'right']}
    // ordered from left to right
    return tooltipPositions[i] || 'top';
  }

  return (
    <>
      {IMPORTANT_SERVICE_CODES.map((serviceCode, i) => {
        const tooltip = getTooltip(serviceCode);
        const code = isRetreadService(serviceCode)
          ? retreadServiceCodes[0]
          : serviceCode;

        return (
          <Column key={code}>
            <ONCallSquareIcon
              code={code}
              modifiers={getActiveModifier(code)}
              tooltipPosition={getToolTipPosition(i)}
              tooltipContent={tooltip}
            />
          </Column>
        );
      })}
    </>
  );
}

ServiceSummaryIcons.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
    }),
  ),
  tooltipPositions: PropTypes.arrayOf(PropTypes.string),
};

ServiceSummaryIcons.defaultProps = {
  services: [],
  tooltipPositions: [],
};

export default ServiceSummaryIcons;

import { Trans } from '@lingui/macro';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { H3, Text } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

function AssetLocation({ caseData }) {
  const assetLocation = get(caseData, 'assetLocation') || {};
  const latitude = get(caseData, 'assetLocation.coordinates.latitude');
  const longitude = get(caseData, 'assetLocation.coordinates.longitude');

  return (
    <Container modifiers={['padScale_2']}>
      <Row>
        <Column modifiers={['padScaleY_2']}>
          <H3 modifiers={['fontWeightRegular']}>
            <Trans>Asset Location</Trans>
          </H3>
        </Column>
      </Row>
      {isEmpty(assetLocation) ? (
        <Row>
          <Column>
            <Text>&mdash;</Text>
          </Column>
        </Row>
      ) : (
        <>
          {assetLocation.searchValue && (
            <Row>
              <Column modifiers={['padScaleY_0']}>
                <Text>{assetLocation.searchValue}</Text>
              </Column>
            </Row>
          )}
          {!isEmpty(assetLocation.coordinates) && (
            <Row>
              <Column modifiers={['padScaleY_0']}>
                <Text>
                  <Trans>
                    Lat {latitude} Long {longitude}
                  </Trans>
                </Text>
              </Column>
            </Row>
          )}
          {(assetLocation.highway || assetLocation.mileMarker) && (
            <Row>
              <Column modifiers={['padScaleY_0']}>
                <Text>
                  {assetLocation.highway && (
                    <Trans>Highway {assetLocation.highway}</Trans>
                  )}
                  {assetLocation.highway && assetLocation.mileMarker && (
                    <> &middot; </>
                  )}
                  {assetLocation.mileMarker && (
                    <Trans>Mile Marker {assetLocation.mileMarker}</Trans>
                  )}
                </Text>
              </Column>
            </Row>
          )}
          {assetLocation.note && (
            <Row>
              <Column modifiers={['padScaleY_0']}>
                <Text>{assetLocation.note}</Text>
              </Column>
            </Row>
          )}
        </>
      )}
    </Container>
  );
}

AssetLocation.propTypes = {
  caseData: PropTypes.shape({
    assetLocation: PropTypes.shape({
      coordinates: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number,
      }),
      highway: PropTypes.string,
      mileMarker: PropTypes.string,
      note: PropTypes.string,
      searchValue: PropTypes.string,
    }),
  }).isRequired,
};

export default AssetLocation;

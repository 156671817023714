import { curry } from 'lodash';
import React from 'react';
import { Query } from '@apollo/client/react/components';

import contactsQueryGQL from './contactsQuery';

export function skipQuery(dealerId) {
  return !dealerId;
}

export function buildQueryVariables(dealerId) {
  return { id: dealerId };
}

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const { data: { afterHoursContacts: { contacts } = {} } = {} } = queryProps;

    return <WrappedComponent {...componentProps} contacts={contacts} />;
  },
);

function buildWrappedComponentWithQuery(WrappedComponent, componentProps) {
  const { dealerId } = componentProps;
  return (
    <Query
      query={contactsQueryGQL}
      skip={skipQuery(dealerId)}
      variables={buildQueryVariables(dealerId)}
    >
      {buildQueryChild(WrappedComponent, componentProps)}
    </Query>
  );
}

const withContacts = (WrappedComponent) => (componentProps) =>
  buildWrappedComponentWithQuery(WrappedComponent, componentProps);

export default withContacts;

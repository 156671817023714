import PropTypes from 'prop-types';
import React from 'react';
import { css } from 'styled-components';

import { Avatar, Icon } from 'base-components';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import CircleGhostIndicator from 'elements/CircleGhostIndicator';

const AssignUserIcon = buildStyledComponent(
  'AssignUserIcon',
  Icon,
  ({ theme }) => css`
    &:before {
      align-items: center;
      background-color: ${theme.colors.base.chrome200};
      border-radius: 50%;
      display: flex;
      height: ${px2rem(35)};
      justify-content: center;
      width: ${px2rem(35)};
    }
  `,
  {
    themePropTypes: {
      colors: PropTypes.shape({
        base: PropTypes.shape({
          chrome200: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    },
  },
);

function CaseAssignedToAvatar({ caseAssignedTo, isLoading, isAssigned }) {
  if (isLoading) {
    return <CircleGhostIndicator style={{ margin: 0 }} />;
  }

  if (isAssigned) {
    return (
      <Avatar email={caseAssignedTo.email} modifiers={['small', 'secondary']} />
    );
  }

  return <AssignUserIcon name="user-plus" />;
}

CaseAssignedToAvatar.propTypes = {
  caseAssignedTo: PropTypes.shape({
    email: PropTypes.string,
  }),
  isAssigned: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

CaseAssignedToAvatar.defaultProps = {
  caseAssignedTo: undefined,
};

export default CaseAssignedToAvatar;

import PropTypes from 'prop-types';

import { buildStyledComponent } from 'decisiv-ui-utils';

import { Text } from '../../elements';

const modifierConfig = {
  active: ({ theme }) => `
    color: ${theme.colors.status.info};
  `,
};

const styles = '';

const themePropTypes = {
  colors: PropTypes.shape({
    status: PropTypes.shape({
      info: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default buildStyledComponent('ButtonMicro__Text', Text, styles, {
  modifierConfig,
  themePropTypes,
});

import gql from 'graphql-tag';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';

// language=GraphQL
const assignCaseToCurrentUserMutation = gql`
  mutation CaseDetailOverviewPanel_CaseAssignUserButton_assignCaseToCurrentUserMutation(
    $caseId: ID!
    $onlyIfUnassigned: Boolean
  ) {
    assignCaseToMe(caseId: $caseId, onlyIfUnassigned: $onlyIfUnassigned) {
      case {
        id
        assignedTo {
          email
          name
        }
      }
    }
  }
`;

const withAssignCaseToCurrentUser = (WrappedComponent) => (componentProps) => {
  const { caseId } = componentProps;
  return (
    <Mutation mutation={assignCaseToCurrentUserMutation}>
      {(dispatchMutation, { loading }) => (
        <WrappedComponent
          {...componentProps}
          assignCaseToCurrentUser={(onlyIfUnassigned = false) =>
            dispatchMutation({ variables: { caseId, onlyIfUnassigned } })
          }
          assignCaseToCurrentUserLoading={loading}
        />
      )}
    </Mutation>
  );
};

export default withAssignCaseToCurrentUser;

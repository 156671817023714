import React from 'react';
import PropTypes from 'prop-types';
import { truncate } from 'lodash';

import { Text, ButtonIconRectangle, Popover } from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Column, Row } from 'styled-components-grid';

const lengthLimit = 36;

export function CustomReason({ children, position }) {
  if (!children || typeof children !== 'string') return null;

  const shouldTruncate = children.length > lengthLimit;

  return (
    <Row modifiers="middle">
      <Column modifiers={['padScale_0', 'flex_1']}>
        <Text modifiers="textLight">
          {shouldTruncate
            ? truncate(children, {
                length: lengthLimit,
                separator: /,? +/,
              })
            : children}
        </Text>
      </Column>
      <Column modifiers="padScale_0">
        {shouldTruncate && (
          <Popover position={position} showOnHover>
            <Popover.Target>
              <ButtonIconRectangle onClick={() => {}}>
                <ButtonIconRectangle.Icon
                  name="info-circle-f"
                  modifiers="info"
                />
              </ButtonIconRectangle>
            </Popover.Target>
            <Popover.Content style={{ width: px2rem(360) }}>
              <Row>
                <Column modifiers={['padScale_3', 'flex_1']}>
                  <Text
                    modifiers="textLight"
                    style={{ whiteSpace: 'pre-line' }}
                  >
                    {children}
                  </Text>
                </Column>
              </Row>
            </Popover.Content>
          </Popover>
        )}
      </Column>
    </Row>
  );
}

CustomReason.propTypes = {
  children: PropTypes.string.isRequired,
  position: PropTypes.string,
};

CustomReason.defaultProps = {
  position: 'right',
};

export default CustomReason;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import { get } from 'lodash';
import { Trans } from '@lingui/macro';

import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';
import { Icon, H2, Text, P, QuickActionButton } from 'base-components';

import Modal from 'components/Modal';
import TextGhostIndicator from 'elements/TextGhostIndicator';

import {
  screenTypes,
  contactTypes,
  titleByScreenType,
  descriptionsByScreenType,
} from './constants';

import {
  AfterHoursEventPropType,
  AfterHoursContactPropType,
} from '../../propTypes';

import { eventHasContact } from './utils';

import Summary from './Summary';
import Emphasis from './Emphasis';
import DeleteContact from './DeleteContact';
import EditRotations from './EditRotations';
import ReplaceContact from './ReplaceContact';
import LoadingMessage from '../../LoadingMessage';

function getScreenDescription({ events, contact, contactType, screenType }) {
  let description = get(descriptionsByScreenType, screenType);

  if (description && typeof description?.id !== 'string') {
    const eventsLength = events.filter((ev) => eventHasContact(ev, contact))
      .length;
    const plural = eventsLength !== 1 ? 'plural' : 'singular';
    if (typeof description[plural]?.id !== 'string') {
      description = description[contactType];
    }
    description = description[plural];
  }

  return description;
}

function getScreenComponent(screenType) {
  return {
    [screenTypes.summary]: Summary,
    [screenTypes.deleteOptionalContact]: DeleteContact,
    [screenTypes.replaceContact]: ReplaceContact,
    [screenTypes.editEachRotation]: EditRotations,
    [screenTypes.deleteAllRotations]: DeleteContact,
  }[screenType];
}

const buildStateFromProps = (props) => {
  const { contact, isBackup } = props;

  const events = props.events.map((event) => ({
    ...event,
    primaryContactId: get(event, 'primaryContact.id'),
    backupContactId: get(event, 'backupContact.id'),
    finalContactId: get(event, 'finalContact.id'),
  }));

  const contactType = isBackup ? contactTypes.optional : contactTypes.required;

  return {
    screenType: screenTypes.summary,
    contactType,
    events,
    canDelete: events.every((event) => !eventHasContact(event, contact)),
    replaceContactId: null,
  };
};

export class DeleteContactEventsModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-typos
    contact: AfterHoursContactPropType.isRequired,
    events: PropTypes.arrayOf(AfterHoursEventPropType).isRequired,
    canDelete: PropTypes.bool.isRequired,
    isBackup: PropTypes.bool.isRequired,
    contacts: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }),
    ).isRequired,
    deleteAfterHoursContact: PropTypes.func.isRequired,
    isDeletingAfterHoursContact: PropTypes.bool.isRequired,
    updateAfterHoursEventsContacts: PropTypes.func.isRequired,
    isUpdatingAfterHoursContacts: PropTypes.bool.isRequired,
    replaceAfterHoursContact: PropTypes.func.isRequired,
    isReplacingAfterHoursContact: PropTypes.bool.isRequired,
    deleteAfterHoursEvents: PropTypes.func.isRequired,
    isDeletingAfterHoursEvents: PropTypes.bool.isRequired,
    // this is required for withDeleteAfterHoursEvents
    dealerId: PropTypes.string.isRequired,
    // eslint-disable-next-line react/no-typos
    weekStart: MomentPropTypes.momentObj.isRequired,
  };

  static getDerivedStateFromProps(props, state) {
    if (!state.events.length && props.events.length > 0) {
      return buildStateFromProps(props);
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = buildStateFromProps(props);
  }

  handleScreenTransition = (screenType) => {
    const isDeleteScreen = [
      screenTypes.deleteOptionalContact,
      screenTypes.deleteAllRotations,
    ].includes(screenType);

    this.setState({ screenType, canDelete: isDeleteScreen });
  };

  handleGoBack = () => {
    this.setState({ screenType: screenTypes.summary });
  };

  handleContactChangeValidation = (canDelete) => {
    this.setState({ canDelete });
  };

  handleReplaceContact = (newContactId) => {
    this.setState({
      replaceContactId: newContactId,
      canDelete: !!newContactId,
    });
  };

  handleChangeEvents = async ({ eventIds, ...contacts }) => {
    await this.props.updateAfterHoursEventsContacts({ eventIds, ...contacts });
    const events = this.state.events.map((event) => {
      const matches = eventIds.find((id) => id === event.id);
      if (!matches) return event;
      return {
        ...event,
        ...contacts,
      };
    });
    const { contact } = this.props;
    this.setState({
      events,
      canDelete: events.every((event) => !eventHasContact(event, contact)),
    });
  };

  handleDeleteContact = async () => {
    const { contact, events } = this.props;
    const { screenType, replaceContactId } = this.state;
    if (screenType === screenTypes.deleteOptionalContact) {
      await this.props.updateAfterHoursEventsContacts({
        eventIds: events.map((event) => event.id),
        backupContactId: null,
      });
    }
    if (screenType === screenTypes.replaceContact && replaceContactId) {
      await this.props.replaceAfterHoursContact({
        sourceId: contact.id,
        targetId: replaceContactId,
      });
    }
    await this.props.deleteAfterHoursContact(contact.id);
    this.props.onClose();
  };

  handleDeleteContactRotations = async () => {
    const { contact, events } = this.props;
    await this.props.deleteAfterHoursEvents(events.map((event) => event.id));
    await this.props.deleteAfterHoursContact(contact.id);
    this.props.onClose();
  };

  render() {
    const { contact, contacts, onClose } = this.props;
    const { contactType, events, screenType, canDelete } = this.state;

    const screenProps = {
      contact,
      contacts,
      contactType,
      events,
      transitionTo: this.handleScreenTransition,
      onChangeEvents: this.handleChangeEvents,
      onReplaceContact: this.handleReplaceContact,
      onContactChange: this.handleContactChangeValidation,
    };

    const ScreenComponent = getScreenComponent(screenType);

    const isReplaceScreen = [
      screenTypes.editEachRotation,
      screenTypes.replaceContact,
    ].includes(screenType);

    const isDeleteAll = screenType === screenTypes.deleteAllRotations;

    const isDeleting =
      this.props.isDeletingAfterHoursEvents ||
      this.props.isDeletingAfterHoursContact ||
      this.props.isReplacingAfterHoursContact;

    const isReplacingContact = this.props.isUpdatingAfterHoursContacts;

    const isDisabled = !canDelete || isDeleting || isReplacingContact;

    return (
      <Modal onClose={onClose}>
        {() => (
          <Modal.Body>
            <Modal.Header style={{ padding: px2rem(20) }}>
              <Modal.HeaderIcon
                name={isReplaceScreen ? 'users' : 'user-times'}
              />
            </Modal.Header>
            <Modal.Content>
              <Row modifiers="center">
                <Column>
                  <H2 modifiers="fontWeightRegular">
                    <Trans id={titleByScreenType[screenType]} />
                  </H2>
                </Column>
              </Row>
              <Row modifiers="center">
                <Column modifiers="col">
                  {!events.length ? (
                    <Column modifiers="col">
                      <TextGhostIndicator>Loading...</TextGhostIndicator>
                      <TextGhostIndicator>.</TextGhostIndicator>
                      <TextGhostIndicator>.</TextGhostIndicator>
                    </Column>
                  ) : (
                    <P style={{ marginBottom: px2rem(10) }}>
                      <Trans
                        id={getScreenDescription({
                          events,
                          contact,
                          contactType,
                          screenType,
                        })}
                        components={[Emphasis, Emphasis]}
                        values={{
                          contactName: contact.name,
                          eventsNumber: events.filter((ev) =>
                            eventHasContact(ev, contact),
                          ).length,
                        }}
                      />
                    </P>
                  )}
                </Column>
              </Row>
              <Row modifiers={['padScaleY_2']}>
                <Column modifiers={['col', 'padScale_0']}>
                  <ScreenComponent {...screenProps} />
                </Column>
              </Row>
            </Modal.Content>
            <Modal.Footer>
              <Row modifiers="end">
                {!isDeleting && isReplaceScreen && canDelete && (
                  <Column modifiers={['col', 'start']}>
                    <Row modifiers="middle">
                      <Column modifiers={['padScale_0']}>
                        <Icon
                          name="check-circle-f"
                          modifiers={['mini', 'success']}
                        />
                      </Column>
                      <Column modifiers={['padScaleY_0']}>
                        <Text>
                          <Trans>You can safely delete the contact now</Trans>
                        </Text>
                      </Column>
                    </Row>
                  </Column>
                )}
                {isDeleting && (
                  <LoadingMessage
                    message={<Trans>Deleting, please wait...</Trans>}
                  />
                )}
                {isReplacingContact && (
                  <LoadingMessage
                    message={<Trans>Replacing contact, please wait...</Trans>}
                  />
                )}
                <Column
                  modifiers={['padScale_0']}
                  style={{ paddingLeft: px2rem(10) }}
                >
                  <QuickActionButton onClick={onClose}>
                    <QuickActionButton.Text>
                      <Trans>Cancel</Trans>
                    </QuickActionButton.Text>
                  </QuickActionButton>
                </Column>

                <Column
                  modifiers={['padScale_0']}
                  style={{ paddingLeft: px2rem(10) }}
                >
                  <QuickActionButton
                    onClick={
                      isDeleteAll
                        ? this.handleDeleteContactRotations
                        : this.handleDeleteContact
                    }
                    modifiers={['secondary', isDisabled && 'disabled']}
                    disabled={isDisabled}
                  >
                    <QuickActionButton.Text>
                      {isDeleteAll ? (
                        <Trans>Delete Contact & Rotations</Trans>
                      ) : (
                        <Trans>Delete Contact</Trans>
                      )}
                    </QuickActionButton.Text>
                  </QuickActionButton>
                </Column>
              </Row>
            </Modal.Footer>
          </Modal.Body>
        )}
      </Modal>
    );
  }
}

export default DeleteContactEventsModal;

import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';

import { immutableToJS } from 'decisiv-ui-utils';

import withUserRole from 'features/rbac/withUserRole';

import { setDealerDetailAccordionsExpanded } from 'redux/preferences/actions';
import { dealerDetailAccordionsExpandedSelector } from 'redux/preferences/selectors';

import withDealerDetails from '../withDealerDetails';
import withDealerActions from '../withDealerActions';

import DealerDetailsComponent from './DealerDetailsComponent';

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    accordionsExpanded: dealerDetailAccordionsExpandedSelector(state),
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    setAccordionsExpanded: (payload) =>
      dispatch(setDealerDetailAccordionsExpanded(payload)),
  };
}

export default compose(
  setDisplayName('DealerLocator__DealerDetails'),
  connect(mapStateToProps, mapDispatchToProps),
  withDealerActions,
  withDealerDetails,
  immutableToJS,
  withUserRole,
)(DealerDetailsComponent);

import gql from 'graphql-tag';

export default gql`
  mutation removeCaseDomicile($caseId: ID!) {
    removeCustomerDomicileFromCase(input: { id: $caseId }) {
      case {
        id
        fixpixPushResult
        customerDomicile {
          id
        }
        paymentMethod
      }
    }
  }
`;

import React from 'react';
import { curry } from 'lodash';

import DebouncedQuery from 'components/DebouncedQuery';

import CustomerWidgetContext from '../../CustomerWidgetContext';
import searchCustomersQuery from '../../searchCustomersQuery';
import { INBOUND_PROGRAM_ACCOUNT_TYPE } from '../../withCaseInboundProgram/constants';

export function skipQuery({ searchString }) {
  return !searchString || searchString.length < 3;
}

export function buildQueryVariables({
  searchValue,
  inboundProgramAccountType,
  inboundProgramBillTo,
}) {
  const variables = { searchString: searchValue };

  if (
    inboundProgramAccountType === INBOUND_PROGRAM_ACCOUNT_TYPE.fleet &&
    !!inboundProgramBillTo
  ) {
    variables.billTo = inboundProgramBillTo;
  }

  return variables;
}

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const { data: { searchCustomers } = {}, pending = false } = queryProps;

    return (
      <WrappedComponent
        {...componentProps}
        customers={searchCustomers}
        isSearching={pending}
      />
    );
  },
);

function buildWrappedComponentWithDebouncedQuery(
  WrappedComponent,
  componentProps,
) {
  return (
    <DebouncedQuery
      buildQueryVariables={buildQueryVariables}
      debounceMS={1000}
      query={searchCustomersQuery}
      searchValue={componentProps.domicileSearchValue}
      skipQuery={skipQuery}
      fetchPolicy="no-cache"
      {...componentProps}
    >
      {buildQueryChild(WrappedComponent, componentProps)}
    </DebouncedQuery>
  );
}

export const updateCustomerSearchValue = curry(
  (setContext, domicileSearchValue) => setContext({ domicileSearchValue }),
);

export const buildConsumerChild = curry(
  (WrappedComponent, componentProps, consumerProps) => {
    const { inboundProgramBillTo, inboundProgramAccountType } = componentProps;
    const { domicileSearchValue, updateCustomerWidgetContext } = consumerProps;

    return buildWrappedComponentWithDebouncedQuery(WrappedComponent, {
      ...componentProps,
      domicileSearchValue,
      inboundProgramBillTo,
      inboundProgramAccountType,
      onChangeDomicileSearchValue: updateCustomerSearchValue(
        updateCustomerWidgetContext,
      ),
    });
  },
);

const withDomicileSearch = (WrappedComponent) => (componentProps) => (
  <CustomerWidgetContext.Consumer>
    {buildConsumerChild(WrappedComponent, componentProps)}
  </CustomerWidgetContext.Consumer>
);

export default withDomicileSearch;

import gql from 'graphql-tag';

export default gql`
  query trailerTypesQuery {
    trailerType: __type(name: "TrailerType") {
      enumValues {
        name
      }
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';

import SearchForm from './SearchForm';
import SearchResults from './SearchResults';
import ValidationSuccess from './ValidationSuccess';
import ValidationFailure from './ValidationFailure';
import { modalModes } from '../../constants';

const containerStyles = { width: '100%', marginTop: 15 };

function AssetValidationModalContent(props) {
  const { state } = props;

  const showSearchForm = state.mode === modalModes.initial;
  const showSearchResults = state.mode === modalModes.searchResults;
  const showSuccessValidation = state.mode === modalModes.validationSucceeded;
  const showFailureValidation = state.mode === modalModes.validationFailed;

  return (
    <div style={containerStyles}>
      {showSearchForm && <SearchForm {...props} />}
      {showSearchResults && <SearchResults {...props} />}
      {showSuccessValidation && <ValidationSuccess {...props} />}
      {showFailureValidation && <ValidationFailure {...props} />}
    </div>
  );
}

AssetValidationModalContent.propTypes = {
  state: PropTypes.shape({
    mode: PropTypes.oneOf(Object.values(modalModes)),
    responseType: PropTypes.string,
  }).isRequired,
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.bool.isRequired,
  triggerAction: PropTypes.func.isRequired,
  validateAsset: PropTypes.func.isRequired,
};

export default AssetValidationModalContent;

/**
 * Component: Button Icon Rectangle
 *
 * Produces a icon button tag styled to be rectangular.
 */
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import Icon from 'decisiv-iconix-react';

const modifierConfig = {
  hoverSuccess: ({ theme }) => `
    &:hover {
      border-color: ${theme.colors.base.chrome200};
      span {
        color: ${theme.colors.status.success};
      }
    }
  `,

  hoverWarning: ({ theme }) => `
    &:hover {
      border-color: ${theme.colors.base.chrome200};
      span {
        color: ${theme.colors.status.warning};
      }
    }
  `,

  hoverDanger: ({ theme }) => `
    &:hover {
      border-color: ${theme.colors.base.chrome200};
      span {
        color: ${theme.colors.status.danger};
      }
    }
  `,

  active: ({ theme }) => `
    border-color: ${theme.colors.base.chrome200};
  `,

  disabled: () => `
    opacity: 0.5;
    pointer-events: none;
  `,
};

/* istanbul ignore next */
const styles = ({ theme }) => `
  background-color: ${theme.colors.base.chrome000};
  border-radius: ${theme.dimensions.borderRadius};
  border: 1px solid transparent;
  cursor: pointer;
  height: ${px2rem(25)};
  line-height: normal;
  outline: none;
  transition: all 200ms ease;
  width: ${px2rem(35)};
  &:hover {
    border-color: ${theme.colors.base.chrome200};
  }
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome000: PropTypes.string.isRequired,
      chrome200: PropTypes.string.isRequired,
      chrome600: PropTypes.string.isRequired,
    }).isRequired,
    status: PropTypes.shape({
      success: PropTypes.string.isRequired,
      warning: PropTypes.string.isRequired,
      danger: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dimensions: PropTypes.shape({
    borderRadius: PropTypes.string.isRequired,
  }).isRequired,
};

const ButtonIconRectangle = buildStyledComponent(
  'ButtonIconRectangle',
  styled.button,
  styles,
  { modifierConfig, themePropTypes },
);

ButtonIconRectangle.Icon = Icon;

export default ButtonIconRectangle;

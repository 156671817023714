import React from 'react';
import PropTypes from 'prop-types';
import { P } from '../../elements';

function Text(props) {
  const { modifiers, ...rest } = props;
  const defaultModifiers = ['textLight'];
  const textModifiers = defaultModifiers.concat(modifiers);

  return <P modifiers={textModifiers} {...rest} />;
}

Text.defaultProps = {
  modifiers: [],
};

Text.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

export default Text;

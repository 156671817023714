import { curry } from 'lodash';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import createAfterHoursContactMutation from './createAfterHoursContactQuery';
import { NAME as CONTACTS_QUERY_NAME } from '../withContacts/contactsQuery';

export const buildMutationChild = curry(
  (
    WrappedComponent,
    componentProps,
    createAfterHoursContact,
    mutationProps,
  ) => {
    const {
      match: {
        params: { dealerId },
      },
    } = componentProps;
    return (
      <WrappedComponent
        {...componentProps}
        isCreatingAfterHoursContact={!!mutationProps?.loading}
        createAfterHoursContact={(params) => {
          const {
            name,
            jobTitle,
            primaryPhoneNumber,
            secondaryPhoneNumber,
          } = params;
          return createAfterHoursContact({
            variables: {
              dealerId,
              name,
              jobTitle,
              primaryPhoneNumber,
              secondaryPhoneNumber,
            },
          });
        }}
      />
    );
  },
);

function buildWrappedComponentWithMutation(WrappedComponent, componentProps) {
  return (
    <Mutation
      mutation={createAfterHoursContactMutation}
      refetchQueries={[CONTACTS_QUERY_NAME]}
    >
      {buildMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

const withCreateAfterHoursContact = (WrappedComponent) => (componentProps) =>
  buildWrappedComponentWithMutation(WrappedComponent, componentProps);

export default withCreateAfterHoursContact;

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

const modifierConfig = {
  hoverInfo: ({ theme }) => `
    &:hover, &:focus {
      color: ${theme.colors.status.info};
    }
  `,

  hoverDanger: ({ theme }) => `
    &:hover, &:focus {
      color: ${theme.colors.status.danger};
    }
  `,
};

const styles = ({ theme }) => `
  background: none;
  border: none;
  color: ${theme.colors.base.chrome500};
  cursor: pointer;
  height: 100%;
  padding: 0 ${theme.dimensions.gridPad};
  width: 100%;
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome500: PropTypes.string.isRequired,
    }).isRequired,
    status: PropTypes.shape({
      danger: PropTypes.string.isRequired,
      info: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dimensions: PropTypes.shape({
    gridPad: PropTypes.string.isRequired,
  }).isRequired,
};

const propTypes = {
  type: PropTypes.string,
};

const defaultProps = {
  type: 'button',
};

export default buildStyledComponent(
  'Input__ActionButton',
  styled.button,
  styles,
  { defaultProps, propTypes, modifierConfig, themePropTypes },
);

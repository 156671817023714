import React from 'react';
import PropTypes from 'prop-types';
import { px2rem } from 'decisiv-ui-utils';
import { t, Trans, Plural } from '@lingui/macro';
import { compose, withState, setDisplayName, mapProps } from 'recompose';

import { Column, Row } from 'styled-components-grid';
import { Text, H4, QuickActionButton, InputField } from 'base-components';

import getActiveCaseDelay from 'utils/getActiveCaseDelay';

import ReasonsSelector from './ReasonsSelector';
import { DELAY_REASONS } from './constants';

const MAX_CHARACTERS = 300;

export function AddDelay({
  isLoadingCaseDelays,
  onSubmit,
  reasonType,
  setReasonType,
  customReason,
  setCustomReason,
  caseDelays,
}) {
  const activeDelays = getActiveCaseDelay(caseDelays, true);
  const activeDelaysReasons = activeDelays.map((delay) => delay.reasonType);

  const isDisabled =
    isLoadingCaseDelays ||
    !reasonType ||
    (reasonType === DELAY_REASONS.other && !customReason);

  return (
    <>
      <Row>
        <Column modifiers={['col']}>
          <H4
            modifiers={['fontWeightRegular']}
            style={{
              marginBottom: px2rem(15),
            }}
          >
            <Trans>Add new delay</Trans>
          </H4>
        </Column>
      </Row>
      <Row>
        <Column modifiers={['col']}>
          <ReasonsSelector
            onChange={setReasonType}
            selected={reasonType}
            disabledReasons={activeDelaysReasons.filter(
              (reason) => reason !== DELAY_REASONS.other,
            )}
          />
        </Column>
      </Row>
      {reasonType === DELAY_REASONS.other && (
        <Row>
          <Column modifiers={['col']}>
            <InputField
              name="customReason"
              onChange={(e) => setCustomReason(e.target.value)}
              placeholder={t`Enter custom reason...`}
              value={customReason || ''}
              modifiers={['height_auto']}
              maxLength={MAX_CHARACTERS}
            >
              <Column modifiers={['col', 'padScaleY_0']}>
                <Row>
                  <InputField.Label>
                    <Text modifiers={['small', 'textLight']}>
                      <Trans>Custom Reason</Trans>
                    </Text>
                  </InputField.Label>
                  <InputField.CharsLeftLabel>
                    {(charsLeft) => (
                      <Plural
                        value={charsLeft}
                        one="# Character Left"
                        other="# Characters Left"
                      />
                    )}
                  </InputField.CharsLeftLabel>
                </Row>
                <Row>
                  <InputField.TextArea />
                </Row>
              </Column>
            </InputField>
          </Column>
        </Row>
      )}
      <Row>
        <Column modifiers={['col']}>
          <QuickActionButton
            onClick={() => !isDisabled && onSubmit()}
            disabled={isDisabled}
            modifiers={isDisabled && 'disabled'}
          >
            <QuickActionButton.Text>
              <Trans>Add Delay</Trans>
            </QuickActionButton.Text>
          </QuickActionButton>
        </Column>
      </Row>
    </>
  );
}

AddDelay.propTypes = {
  isLoadingCaseDelays: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  reasonType: PropTypes.string.isRequired,
  setReasonType: PropTypes.func.isRequired,
  customReason: PropTypes.string.isRequired,
  setCustomReason: PropTypes.func.isRequired,
  caseDelays: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      startTime: PropTypes.string.isRequired,
      endTime: PropTypes.string,
      reasonType: PropTypes.oneOf(Object.values(DELAY_REASONS)),
      customReason: PropTypes.string,
    }),
  ).isRequired,
};

export default compose(
  setDisplayName('AddDelay'),
  withState('reasonType', 'setReasonType', ''),
  withState('customReason', 'setCustomReason', ''),
  mapProps(({ onSubmit, ...otherProps }) => ({
    ...otherProps,
    onSubmit: () => {
      const {
        reasonType,
        customReason,
        setReasonType,
        setCustomReason,
      } = otherProps;

      onSubmit({
        reasonType,
        customReason:
          reasonType === DELAY_REASONS.other ? customReason : undefined,
      });
      setReasonType('');
      setCustomReason('');
    },
  })),
)(AddDelay);

import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';

import { px2rem } from 'decisiv-ui-utils';

import { unitSelector } from 'redux/preferences/selectors';
import { weatherCodeToThemeColorPath } from 'utils/weather';

import InfoPopover from 'components/InfoPopover';

import HoverSensor from 'elements/HoverSensor';

import LocationDateTime from './LocationDateTime';
import withWeather from './locationWeatherQuery';
import WeatherDetail from './WeatherDetail';
import SearchInputWeatherSummary from './SearchInputWeatherSummary';

export function LocationTimeAndWeather({ unit, weather, hideWeather }) {
  if (isEmpty(weather)) {
    return null;
  }

  const { current, timezone } = weather || {};
  const leftPanelColorKey = weatherCodeToThemeColorPath(current?.code);
  const hasWeatherDetail = !isEmpty(weather.hourly);

  return (
    <>
      <LocationDateTime timezone={timezone} />
      {!hideWeather &&
        (hasWeatherDetail ? (
          <InfoPopover
            position="bottomLeft"
            zIndex={2}
            data-testid="WeatherDetailPopover"
          >
            {({ isVisible }) => (
              <>
                <HoverSensor>
                  {({ isActive: isHover }) => (
                    <InfoPopover.Target>
                      <SearchInputWeatherSummary
                        isHover={isHover}
                        isVisible={isVisible}
                        unit={unit}
                        weather={weather}
                      />
                    </InfoPopover.Target>
                  )}
                </HoverSensor>
                <InfoPopover.Content style={{ right: px2rem(8) }}>
                  <InfoPopover.LeftPanel colorKey={leftPanelColorKey}>
                    <WeatherDetail.LeftPanel weather={weather} />
                  </InfoPopover.LeftPanel>
                  <InfoPopover.RightPanel>
                    <WeatherDetail.RightPanel weather={weather} />
                  </InfoPopover.RightPanel>
                </InfoPopover.Content>
              </>
            )}
          </InfoPopover>
        ) : (
          <SearchInputWeatherSummary
            unit={unit}
            weather={weather}
            isExpandable={false}
            reverse
          />
        ))}
    </>
  );
}

LocationTimeAndWeather.propTypes = {
  unit: PropTypes.string.isRequired,
  weather: PropTypes.shape({
    current: PropTypes.shape({
      code: PropTypes.string,
    }),
    timezone: PropTypes.string,
    hourly: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
      }),
    ),
  }),
  hideWeather: PropTypes.bool,
};

LocationTimeAndWeather.defaultProps = {
  weather: {},
  hideWeather: false,
};

function mapStateToProps(state) {
  return {
    unit: unitSelector(state),
  };
}

export default compose(
  setDisplayName('LocationTimeAndWeather'),
  connect(mapStateToProps),
  withWeather,
)(LocationTimeAndWeather);

import React from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { compose, setDisplayName, withState, mapProps } from 'recompose';

import {
  P,
  H2,
  Text,
  Icon,
  InputField,
  QuickActionButton,
} from 'base-components';

import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';

import Modal from 'components/Modal';

import withCaseHeadsUpNote from './withCaseHeadsUpNote';

const editModeTitle = t`Edit Heads-Up Message`;
const createModeTitle = t`Create Heads-Up Message`;
const iconStyles = { display: 'inline-block', verticalAlign: 'middle' };

export function CaseHeadsUpNoteModal({
  save,
  value,
  setValue,
  headsUpNote,
  onClose,
  isSavingHeadsUpNote,
}) {
  return (
    <Modal onClose={onClose}>
      {() => (
        <Modal.Body>
          <Modal.Header>
            <Modal.HeaderIcon name="sticky-note-plus" />
          </Modal.Header>
          <Modal.Content>
            <Row modifiers="center">
              <Column>
                <H2 modifiers="fontWeightRegular">
                  <Trans id={headsUpNote ? editModeTitle : createModeTitle} />
                </H2>
              </Column>
            </Row>
            <Row modifiers="center" style={{ marginBottom: px2rem(30) }}>
              <Column>
                <P>
                  <Trans>
                    This message will display at the top of this page and in the
                    case status{' '}
                    <Icon
                      name="info-circle"
                      modifiers="info"
                      style={iconStyles}
                    />{' '}
                    on the Home page.
                    <br />
                    You can edit it at any time.
                  </Trans>
                </P>
              </Column>
            </Row>
            <Row>
              <Column modifiers={['col', 'padScale_0']}>
                <InputField
                  name="note"
                  value={value}
                  onChange={({ target: { value: newValue } }) =>
                    setValue(newValue)
                  }
                  maxLength={150}
                  placeholder={t`Enter message...`}
                >
                  <Column modifiers={['col', 'padScaleY_0']}>
                    <Row>
                      <InputField.Label>
                        <Text modifiers={['small', 'textLight']}>
                          <Trans>Message</Trans>
                        </Text>
                      </InputField.Label>
                    </Row>
                    <Row>
                      <InputField.TextField />
                    </Row>
                  </Column>
                </InputField>
              </Column>
            </Row>
          </Modal.Content>
          <Modal.Footer>
            <Row modifiers="end">
              <Column>
                <QuickActionButton onClick={onClose} type="button">
                  <QuickActionButton.Text>
                    <Trans>Cancel</Trans>
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
              <Column>
                <QuickActionButton
                  type="submit"
                  onClick={save}
                  disabled={isSavingHeadsUpNote}
                  modifiers={['secondary', isSavingHeadsUpNote && 'disabled']}
                >
                  <QuickActionButton.Text>
                    <Trans>Save Heads-up</Trans>
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
            </Row>
          </Modal.Footer>
        </Modal.Body>
      )}
    </Modal>
  );
}

CaseHeadsUpNoteModal.propTypes = {
  save: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  // Used in mapProps below
  // eslint-disable-next-line react/no-unused-prop-types
  onSave: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  // Used by withCaseHeadsUpNote
  // eslint-disable-next-line react/no-unused-prop-types
  caseNumber: PropTypes.string.isRequired,
  headsUpNote: PropTypes.string,
  isSavingHeadsUpNote: PropTypes.bool.isRequired,
};

CaseHeadsUpNoteModal.defaultProps = {
  onSave: undefined,
  headsUpNote: null,
};

export default compose(
  setDisplayName('CaseHeadsUpNoteModal'),
  withCaseHeadsUpNote,
  withState('value', 'setValue', ({ headsUpNote }) => headsUpNote || ''),
  mapProps(({ value, updateCaseHeadsUpNote, onSave, ...rest }) => ({
    ...rest,
    value,
    save: () =>
      updateCaseHeadsUpNote(value).then(() => onSave && onSave(value)),
  })),
)(CaseHeadsUpNoteModal);

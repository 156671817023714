import PropTypes from 'prop-types';
import styled from 'styled-components';
import { px2rem, buildStyledComponent } from 'decisiv-ui-utils';

const styles = (props) => `
  height: ${px2rem(props.size)};
  width: ${px2rem(props.size)};
`;

const propTypes = {
  size: PropTypes.number.isRequired,
};

export default buildStyledComponent('IconBox', styled.img, styles, propTypes);

import PropTypes from 'prop-types';
import React from 'react';

import { Column } from 'styled-components-grid';

import Text from '../../elements/Text';

function FooterText({ expanded, FooterMessage }) {
  if (!expanded || !FooterMessage) {
    return null;
  }

  return (
    <Column modifiers={['col', 'padScaleX_4']} style={{ overflow: 'hidden' }}>
      <Text modifiers={['noWrap', 'small', 'textLight']}>
        <FooterMessage />
      </Text>
    </Column>
  );
}

FooterText.propTypes = {
  FooterMessage: PropTypes.func,
  expanded: PropTypes.bool.isRequired,
};

FooterText.defaultProps = {
  FooterMessage: undefined,
};

export default FooterText;

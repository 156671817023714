/* eslint-disable import/prefer-default-export */

import { get } from 'lodash';

import { COLUMN_WIDTHS } from './constants';

export const buildBaseColumnConfig = (name, { history, location } = {}) => ({
  name,
  width: COLUMN_WIDTHS[name],
  sortable: false,
  sortDirection: null,
  cellKeyGetter: () => name,
  cellDataGetter: (data) => data.node,
  dataCellAttrsGetter: ({ rowData }) => ({
    modifiers: 'vAlign_top',
    onClick: () =>
      history &&
      history.push(
        `/cases/${get(rowData, 'node.caseNumber')}${location.search}`,
      ),
  }),
});

import gql from 'graphql-tag';

const query = gql`
  mutation deleteAfterHoursContacts($contactIds: [ID!]!) {
    deleteAfterHoursContacts(input: { ids: $contactIds }) {
      deletedCount
    }
  }
`;

export default query;

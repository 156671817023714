import React, { useCallback, useContext, useMemo } from 'react';
import { defineMessage, Trans } from '@lingui/macro';

import { isMetric as getIsMetric } from 'utils/unit';
import { milesToMeters } from 'utils/measure';

import { Context as DealerLocatorContext } from '../DealerLocatorContext';

import {
  defaultRangeInKms,
  defaultRangeInMiles,
  searchAreaOptionsInMiles,
  searchAreaOptionsInKm,
  searchRouteOptionsInMiles,
  searchRouteOptionsInKm,
} from '../constants';

const useProximityFilters = (isMetric, isRouteSearch) =>
  useMemo(() => {
    if (isRouteSearch) {
      return isMetric ? searchRouteOptionsInKm : searchRouteOptionsInMiles;
    }

    return isMetric ? searchAreaOptionsInKm : searchAreaOptionsInMiles;
  }, [isMetric, isRouteSearch]);

const useProximityFilterLabel = (isMetric) =>
  useMemo(() => {
    return isMetric
      ? defineMessage({ message: '{proximityFilterValue} Kilometers' })
      : defineMessage({ message: '{proximityFilterValue} Miles' });
  }, [isMetric]);

const useSelectedProximityFilterIndex = (props) => {
  const { searchRouteDistanceFilterIndex: routeIndex } = props;
  const { isRouteSearch, searchAreaDistanceFilterIndex: areaIndex } = props;

  return useMemo(() => {
    return isRouteSearch ? routeIndex : areaIndex;
  }, [isRouteSearch, areaIndex, routeIndex]);
};

const getDistance = (isMetric, value) => {
  return isMetric ? value * 1000 : milesToMeters(value);
};

const useSelectedProximityFilterLabel = (props) => {
  const { filters, selectedIndex, proximityFilterLabel: transId } = props;

  return useMemo(() => {
    const proximityFilterValue = filters[selectedIndex];

    return <Trans id={transId} values={{ proximityFilterValue }} />;
  }, [filters, selectedIndex, transId]);
};

const useOnSelectProximityFilter = (isRouteSearch, updateContext) => {
  return useCallback(
    (_, index) => {
      isRouteSearch
        ? updateContext({ searchRouteDistanceFilterIndex: index })
        : updateContext({ searchAreaDistanceFilterIndex: index });
    },
    [isRouteSearch, updateContext],
  );
};

// eslint-disable-next-line react/prop-types
const ComponentWithProximityFilters = ({ Component, ...rest }) => {
  const contextProps = useContext(DealerLocatorContext);

  const isMetric = getIsMetric(contextProps.unit);

  const filters = useProximityFilters(isMetric, contextProps.isRouteSearch);
  const selectedIndex = useSelectedProximityFilterIndex(contextProps);
  const proximityDistance = getDistance(isMetric, filters[selectedIndex]);

  const priorityDistance = isMetric
    ? defaultRangeInKms * 1000
    : milesToMeters(defaultRangeInMiles);

  const proximityFilterLabel = useProximityFilterLabel(isMetric);

  const onSelectProximityFilter = useOnSelectProximityFilter(
    contextProps.isRouteSearch,
    contextProps.updateDealerLocatorContext,
  );

  const selectedProximityFilterLabel = useSelectedProximityFilterLabel({
    filters,
    selectedIndex,
    proximityFilterLabel,
  });

  return (
    <Component
      {...contextProps}
      {...rest}
      proximityFilters={filters}
      proximityDistance={proximityDistance}
      proximityFilterLabel={proximityFilterLabel}
      onSelectProximityFilter={onSelectProximityFilter}
      priorityProximityDistance={priorityDistance}
      selectedProximityFilterIndex={selectedIndex}
      selectedProximityFilterLabel={selectedProximityFilterLabel}
    />
  );
};

const withProximityFilters = (Component) => (props) => (
  <ComponentWithProximityFilters Component={Component} {...props} />
);

export default withProximityFilters;

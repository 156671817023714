import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import gql from 'graphql-tag';
import { curry } from 'lodash';

import buildRefetchQueries from './buildRefetchQueries';

const deleteRotationMutation = gql`
  mutation deleteAfterHoursEvent(
    $afterHoursEventId: ID!
    $occurrence: AfterHoursOccurrenceInput
  ) {
    deleteAfterHoursEvents(
      input: { ids: [$afterHoursEventId], occurrence: $occurrence }
    ) {
      deletedCount
    }
  }
`;

export const buildMutationChild = curry(
  (WrappedComponent, componentProps, deleteAfterHoursEvents, mutationProps) => (
    <WrappedComponent
      {...componentProps}
      isDeleting={!!mutationProps?.loading}
      deleteRotation={({ afterHoursEvent }, occurrence) => {
        deleteAfterHoursEvents({
          variables: {
            afterHoursEventId: afterHoursEvent.id,
            occurrence: occurrence || null,
          },
        });
      }}
    />
  ),
);

function buildWrappedComponentWithMutation(WrappedComponent, componentProps) {
  return (
    <Mutation
      mutation={deleteRotationMutation}
      onCompleted={() => componentProps.onClose()}
      refetchQueries={buildRefetchQueries(componentProps)}
    >
      {buildMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

const withDeleteMutation = (WrappedComponent) => (componentProps) =>
  buildWrappedComponentWithMutation(WrappedComponent, componentProps);

export default withDeleteMutation;

import React, { PureComponent } from 'react';
import { Trans } from '@lingui/macro';

import { QuickActionButton } from 'base-components';
import { Row, Column } from 'styled-components-grid';

import PropTypes from '../prop-types';
import TokenizedCard from '../TokenizedCard';
import ValidateCardModal from '../ValidateCardModal';

export default class NationalExistingCard extends PureComponent {
  static propTypes = {
    caseId: PropTypes.string.isRequired,
    caseNumber: PropTypes.string.isRequired,
    isReadOnlyCase: PropTypes.bool.isRequired,
    tokenizedCard: PropTypes.tokenizedCard,
  };

  static defaultProps = {
    tokenizedCard: null,
  };

  state = {
    displayModal: false,
  };

  toggleModal = () => {
    this.setState({ displayModal: !this.state.displayModal });
  };

  render() {
    const { caseId, caseNumber, isReadOnlyCase, tokenizedCard } = this.props;

    return (
      <>
        {this.state.displayModal && (
          <ValidateCardModal
            caseId={caseId}
            caseNumber={caseNumber}
            onClose={this.toggleModal}
          />
        )}
        {tokenizedCard ? (
          <TokenizedCard
            caseId={caseId}
            caseNumber={caseNumber}
            tokenizedCard={tokenizedCard}
            isReadOnlyCase={isReadOnlyCase}
          />
        ) : (
          <Row>
            <Column>
              <QuickActionButton
                disabled={isReadOnlyCase}
                modifiers={isReadOnlyCase && 'disabled'}
                onClick={this.toggleModal}
              >
                <QuickActionButton.Text>
                  <Trans>Validate</Trans>
                </QuickActionButton.Text>
              </QuickActionButton>
            </Column>
            {/* TODO: implement IVR version of existing card validation...
            <Column>
              <QuickActionButton onClick={() => console.log('xfer to IVR')}>
                <QuickActionButton.Text>
                  <Trans>Transfer to IVR</Trans>
                </QuickActionButton.Text>
              </QuickActionButton>
            </Column>
            */}
          </Row>
        )}
      </>
    );
  }
}

import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import gql from 'graphql-tag';
import { curry } from 'lodash';

const updateProximityLevelMutation = gql`
  mutation updateProximityLevelMutation($proximityLevel: Int!, $caseId: ID!) {
    updateCase(
      input: { id: $caseId, patch: { proximityLevel: $proximityLevel } }
    ) {
      case {
        id
        proximityLevel
      }
    }
  }
`;

export const buildMutationChild = curry(
  (WrappedComponent, componentProps, updateCase) => (
    <WrappedComponent
      {...componentProps}
      updateProximityLevel={({ caseId, proximityLevel }) => {
        updateCase({
          variables: {
            caseId,
            proximityLevel,
          },
        });
      }}
    />
  ),
);

function buildWrappedComponentWithMutation(WrappedComponent, componentProps) {
  return (
    <Mutation mutation={updateProximityLevelMutation}>
      {buildMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

const withUpdateProximityLevelMutation = (WrappedComponent) => (
  componentProps,
) => buildWrappedComponentWithMutation(WrappedComponent, componentProps);

export default withUpdateProximityLevelMutation;

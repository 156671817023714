import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compact } from 'lodash';
import { compose, setDisplayName } from 'recompose';

import { WithDirections } from 'features/googleMaps';

import withDealers from '../withDealers';
import withDealerActions from '../withDealerActions';
import withLocations from '../withLocations';

import SearchResultsMapComponent from './SearchResultsMapComponent';

export function SearchResultsMapWithDirections({
  dealers,
  visibleDealers,
  displayDetailView,
  highlightDealer,
  highlightedDealer,
  isRouteSearch,
  locationSearches,
  onMapBoundsChanged,
  route,
  selectDealer,
  selectedDealer,
  updateLocationSearchAtIndex,
  readOnly,
}) {
  const assetLocation = useMemo(() => {
    const [{ location, travelEstimateDestination }] = locationSearches;
    if (!location?.latitude) return undefined;
    return { ...location, address: travelEstimateDestination };
  }, [locationSearches]);

  const destinationLocations = useMemo(() => {
    const [, ...destinationLocationSearches] = locationSearches;

    return compact(destinationLocationSearches.map(({ location }) => location));
  }, [locationSearches]);

  const onAssetLocationChange = useCallback(
    (location) => location && updateLocationSearchAtIndex(0, location),
    [updateLocationSearchAtIndex],
  );

  return (
    <WithDirections
      render={({ directions, displayRoute, middlePoint }) => (
        <SearchResultsMapComponent
          assetLocation={assetLocation}
          dealers={visibleDealers || dealers}
          destinationLocations={destinationLocations}
          directions={directions}
          displayRoute={displayRoute}
          highlightDealer={highlightDealer}
          highlightedDealer={highlightedDealer}
          isDetailView={displayDetailView}
          isRouteSearch={isRouteSearch}
          locationSearches={locationSearches}
          middlePoint={middlePoint}
          onAssetLocationChange={onAssetLocationChange}
          onMapBoundsChanged={onMapBoundsChanged}
          route={route}
          selectDealer={selectDealer}
          selectedDealer={selectedDealer}
          readOnly={readOnly}
        />
      )}
    />
  );
}

const dealerPropType = PropTypes.shape({
  id: PropTypes.string,
  location: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
});

SearchResultsMapWithDirections.propTypes = {
  dealers: PropTypes.arrayOf(dealerPropType),
  visibleDealers: PropTypes.arrayOf(dealerPropType),
  displayDetailView: PropTypes.bool.isRequired,
  highlightedDealer: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  isRouteSearch: PropTypes.bool,
  highlightDealer: PropTypes.func.isRequired,
  locationSearches: PropTypes.arrayOf(
    PropTypes.shape({
      location: PropTypes.shape({
        latitude: PropTypes.number.isRequired,
        longitude: PropTypes.number.isRequired,
      }),
      searchValue: PropTypes.string,
      searchValueTried: PropTypes.string,
    }),
  ).isRequired,
  updateLocationSearchAtIndex: PropTypes.func.isRequired,
  onMapBoundsChanged: PropTypes.func.isRequired,
  selectDealer: PropTypes.func.isRequired,
  route: PropTypes.arrayOf(PropTypes.shape({})),
  selectedDealer: PropTypes.shape({
    dealerId: PropTypes.string,
    index: PropTypes.number,
    location: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
  }),
  readOnly: PropTypes.bool,
};

SearchResultsMapWithDirections.defaultProps = {
  dealers: [],
  visibleDealers: undefined,
  highlightedDealer: undefined,
  isRouteSearch: false,
  route: [],
  selectedDealer: undefined,
  readOnly: false,
};

export default compose(
  setDisplayName('DealerLocator__SearchResultsMap'),
  withDealers,
  withDealerActions,
  withLocations,
)(SearchResultsMapWithDirections);

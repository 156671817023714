import React from 'react';
import { Trans } from '@lingui/macro';

import ScheduledEvent from './index';
import EventBlockHeader from './EventBlockHeader';

export default function OpenHoursEvent(props) {
  return (
    <ScheduledEvent type="openHours" {...props}>
      <EventBlockHeader modifiers={['openHours']}>
        <Trans>Open</Trans>
      </EventBlockHeader>
    </ScheduledEvent>
  );
}

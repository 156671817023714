import React from 'react';
import PropTypes from 'prop-types';

import withUserRole from '../withUserRole';
import AuthCheck from './AuthCheck';

/**
 * General-purpose authorization wrapper to limit use of a component
 * or tree of components to only users with one of the specified roles.
 *
 * Props are passed through to the rendered component, in addition to
 * a `userRole` string indicating the current user's role.
 *
 * @param {Array<string>} allowedRoles - an array of user role names allowed
 *  access to the nested component(s)
 * @param Unauthorized - a React component to be rendered if auth fails
 *
 * @example
 * function MyComponent({ otherProp }) { ... }
 *
 * const MyAuthedComponent = Authorized(
 *   ['roleOne', 'anotherRole'],
 *   ({ userRole, otherProp }) => <h1>No thanks, {userRole}!</h1>,
 * )(MyComponent);
 *
 * <MyAuthedComponent otherProp="foo">...</MyAuthedComponent>
 */
export default function Authorized(allowedRoles, Unauthorized) {
  return (WrappedComponent) => {
    function WithAuthorization({ loadingRole, role: userRole, ...rest }) {
      return (
        <AuthCheck
          role={userRole}
          loadingRole={loadingRole}
          allowedRoles={allowedRoles}
          unauthorized={<Unauthorized userRole={userRole} {...rest} />}
        >
          <WrappedComponent userRole={userRole} {...rest} />
        </AuthCheck>
      );
    }

    WithAuthorization.propTypes = {
      loadingRole: PropTypes.bool.isRequired,
      role: PropTypes.string.isRequired,
    };

    return withUserRole(WithAuthorization);
  };
}

import caseNumber from './caseNumber';
import agent from './agent';
import status from './status';
import inboundProgram from './inboundProgram';
import timeSummaries from './timeSummaries';
import customer from './customer';
import unitNo from './unitNo';
import dealer from './dealer';
import eta from './eta';

export default [
  caseNumber,
  agent,
  status,
  inboundProgram,
  timeSummaries,
  customer,
  unitNo,
  dealer,
  eta,
];

export const columns = {
  caseNumber,
  agent,
  status,
  inboundProgram,
  timeSummaries,
  customer,
  unitNo,
  dealer,
  eta,
};

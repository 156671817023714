import { t } from '@lingui/macro';
import { initial, last } from 'lodash';
import { parsePhoneNumber } from 'libphonenumber-js';
import { i18n } from '@lingui/core';

const DEFAULT_COUNTRY_CODE = 'US';

export function formatPhone(phoneNumber, countryCode = DEFAULT_COUNTRY_CODE) {
  if (!phoneNumber) return '';

  let normalizedPhoneNumber;

  try {
    normalizedPhoneNumber = parsePhoneNumber(phoneNumber, countryCode);
  } catch (e) {
    return phoneNumber;
  }

  if (!normalizedPhoneNumber.isPossible()) {
    return phoneNumber;
  }

  return normalizedPhoneNumber.country === DEFAULT_COUNTRY_CODE
    ? normalizedPhoneNumber.formatNational()
    : normalizedPhoneNumber.formatInternational();
}

export function isNumber(phoneNumber) {
  const reg = /^\d+$/;

  return reg.test(phoneNumber);
}

export function isPhoneNumber(number) {
  try {
    const phoneNumber = parsePhoneNumber(number, DEFAULT_COUNTRY_CODE);

    return phoneNumber.isPossible();
  } catch (error) {
    // We just want to suppress the error
  }

  return false;
}

/**
 * Given an array of strings, format those into a grammatically-correct
 * sentence-fragment with a list of the items.  `i18n` can be used to
 * translate the "and" separator for the current language.
 *
 * Note that this is geared towards western languages that format
 * items in a series as "x, y, and z" or "x and y" but will not
 * work for languages that apply a different grammatical structure
 * for items in a series.
 *
 * @example
 * toTextList(['Monday', 'Wednesday', 'Friday'])
 * // => 'Monday, Wednesday, and Friday'
 *
 * @param {[string]} array
 * @param i18n
 * @return {string}
 */
export function toTextList(array) {
  const allButLast = initial(array);
  const lastMember = last(array) || '';

  // we add the spaces separately, because lingui trims the translations
  const lastSeparators =
    array.length > 2 ? [i18n._(t`, and`), ' '] : [' ', i18n._(t`and`), ' '];

  return allButLast.length
    ? allButLast.join(', ') + lastSeparators.join('') + lastMember
    : lastMember;
}

import React, { useCallback, useEffect, useRef, useMemo } from 'react';

import { Provider, Consumer } from './FocusProviderContext';

export { scrollToElement, scrollToAndHighlightElement } from './utils';

const noop = () => {};

function FocusProvider(props) {
  const listenersRef = useRef(new Map());
  const catchAllListenersRef = useRef(new Set());

  useEffect(() => {
    listenersRef.current.clear();
    catchAllListenersRef.current.clear();
  }, []);

  // skipCatchAll is usefull/needed to prevent loops if this function
  // is called from within a callback triggered by a focus request
  const requestFocusOn = useCallback((id, skipCatchAll = false) => {
    (listenersRef.current.get(id) || noop)(id);
    !skipCatchAll && catchAllListenersRef.current.forEach((cb) => cb(id));
  }, []);

  const subscribeToFocusRequestsOn = useCallback((id, callback) => {
    listenersRef.current.set(id, callback);

    return () => listenersRef.current.delete(id);
  }, []);

  const subscribeToAnyFocusRequest = useCallback((callback) => {
    catchAllListenersRef.current.add(callback);

    return () => catchAllListenersRef.current.delete(callback);
  }, []);

  const value = useMemo(
    () => ({
      requestFocusOn,
      subscribeToFocusRequestsOn,
      subscribeToAnyFocusRequest,
    }),
    [requestFocusOn, subscribeToFocusRequestsOn, subscribeToAnyFocusRequest],
  );

  return <Provider {...props} value={value} />;
}

FocusProvider.Consumer = Consumer;

export default FocusProvider;

import { t } from '@lingui/macro';

import buildEnum from 'utils/buildEnum';

export const notificationType = buildEnum([
  'dispatched',
  'rolling',
  'poNumber',
]);

export const notificationTypeLabel = {
  [notificationType.dispatched]: t`Dispatched`,
  [notificationType.rolling]: t`Vehicle Rolling`,
  [notificationType.poNumber]: t`USX PO - Dispatched`,
};

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const modifierConfig = {
  /**
   * This needs `!important` as it shouldn't be overridden by hover state color.
   * And it can't be the default style as it should be overridden by inactive modifier.
   */
  active: ({ theme }) => `
    color: ${theme.colors.base.text} !important;
  `,
  inactive: ({ theme }) => `
    color: ${theme.colors.base.textLight};
  `,
};

const styles = `
  font-size: ${px2rem(10)};
  text-transform: capitalize;
  transition: color 200ms ease;
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      text: PropTypes.string.isRequired,
      textLight: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const OptionLabel = buildStyledComponent('OptionLabel', styled.label, styles, {
  modifierConfig,
  themePropTypes,
});

export default OptionLabel;

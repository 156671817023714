import PropTypes from 'prop-types';
import { buildStyledComponent } from 'decisiv-ui-utils';

import Text from '../../elements/Text';

const styles = ({ theme }) => `
  color: inherit;
  font-weight: ${theme.fontWeights.light};
  text-transform: uppercase;
`;

const themePropTypes = {
  fontWeights: PropTypes.shape({
    light: PropTypes.number.isRequired,
  }).isRequired,
};

export default buildStyledComponent('Text', Text, styles, { themePropTypes });

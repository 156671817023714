import elementResizeDetectorMaker from 'element-resize-detector';
import { findLastIndex, first, last, omit, sortBy, throttle } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {
  DEFAULT_MIN_ADJUSTMENT,
  DEFAULT_SIZE,
  DEFAULT_THROTTLE_MS,
} from './constants';
import SizeStore from './SizeStore';

const makeReactiveContainer = (options = {}) => (Container) => {
  const { defaultSize, minAdjustment, throttleMS } = options;

  return class Reactive extends Component {
    static childContextTypes = {
      sizeStore: PropTypes.shape({}).isRequired,
    };

    static displayName = `Reactive(${Container.displayName || Container.name})`;

    static propTypes = {
      breakpoints: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          minWidth: PropTypes.number.isRequired,
        }),
      ).isRequired,
      defaultSize: PropTypes.string,
      minAdjustment: PropTypes.number,
      throttleMS: PropTypes.number,
    };

    static defaultProps = {
      defaultSize: defaultSize || DEFAULT_SIZE,
      minAdjustment: minAdjustment || DEFAULT_MIN_ADJUSTMENT,
      throttleMS: throttleMS || DEFAULT_THROTTLE_MS,
    };

    state = {};

    getChildContext() {
      return {
        sizeStore: this.sizeStore,
      };
    }

    UNSAFE_componentWillMount() {
      this.sizeStore = new SizeStore(this.props.defaultSize);
      this.elementResizeDetector = elementResizeDetectorMaker();
    }

    /* istanbul ignore next */
    componentDidMount() {
      if (!this.element) return;

      this.setWidth();
      this.elementResizeDetector.listenTo(
        this.element,
        throttle(this.resizeEventHandler, this.props.throttleMS),
      );
    }

    /* istanbul ignore next */
    componentWillUnmount() {
      this.elementResizeDetector.removeAllListeners(this.element);
    }

    getSize = (width, breakpoints) => {
      const sortedBreakpoints = sortBy(breakpoints, (bp) => bp.minWidth);
      if (width < first(sortedBreakpoints).minWidth) {
        return this.props.defaultSize;
      }
      const largestBreakpoint = last(sortedBreakpoints);
      if (width >= largestBreakpoint.minWidth) {
        return largestBreakpoint.name;
      }
      const idx = findLastIndex(
        sortedBreakpoints,
        (bp) => width >= bp.minWidth,
      );
      const breakpoint = sortedBreakpoints[idx];
      return breakpoint.name;
    };

    setContainerRef = (element) => (this.element = element);

    setWidth = () => {
      if (!this.element) return;

      const { width } = this.element.getBoundingClientRect();
      const savedWidth = this.state.width;

      if (
        width <= savedWidth - this.props.minAdjustment ||
        width >= savedWidth + this.props.minAdjustment ||
        !savedWidth
      ) {
        this.setState({ width });

        const size = this.getSize(width, this.props.breakpoints);
        this.sizeStore.setSize(size);
      }
    };

    resizeEventHandler = () => this.setWidth();

    render() {
      const componentProps = omit(this.props, [
        'breakpoints',
        'defaultSize',
        'minAdjustment',
        'throttleMS',
      ]);

      return (
        <Container
          ref={this.setContainerRef}
          size={this.sizeStore.size}
          {...componentProps}
        />
      );
    }
  };
};

export default makeReactiveContainer;

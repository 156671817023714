import { t } from '@lingui/macro';

import buildEnum from 'utils/buildEnum';

export const selectorTypes = buildEnum(['customer', 'domicile']);

export const selectorConfig = {
  [selectorTypes.customer]: {
    label: t`Fleet`,
    optionalLabel: t`Fleet (Optional)`,
    placeholder: t`Search by fleet details...`,
    invalid: t`Select an existing fleet.`,
  },
  [selectorTypes.domicile]: {
    label: t`Domicile`,
    optionalLabel: t`Domicile (Optional)`,
    placeholder: t`Search by fleet details...`,
    invalid: t`Select an existing fleet.`,
  },
};

import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { compose, setDisplayName } from 'recompose';

import { H4 } from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Container, Row } from 'styled-components-grid';

import { CASE_STATUS } from 'compositions/CaseStatus/constants';

import { formatGroupDate } from '../utils';

import StatusUpdate from './StatusUpdate';
import withCaseStatusHistory from './withCaseStatusHistory';

import {
  groupAndSortStatuses,
  mergeUniqByStatus,
  fixRollingStatuses,
} from './utils';

/* eslint-disable react/prop-types */
export const Entry = ({ entry, ...rest }) => (
  <StatusUpdate {...rest} key={entry.id} statusUpdate={entry} />
);
/* eslint-enable react/prop-types */

export function StatusUpdatesPanel({ caseNumber, loading, statusHistory }) {
  if (loading) {
    return null;
  }

  // Only show latest entry for Vehicle Rolling and Closed statuses
  const uniqueUpdates = mergeUniqByStatus(statusHistory, [
    CASE_STATUS.rolling,
    CASE_STATUS.closed,
  ]);

  const groupedUpdates = groupAndSortStatuses(
    fixRollingStatuses(uniqueUpdates),
  );

  return (
    <Container modifiers="padScale_0">
      {map(groupedUpdates, (oneDaysUpdates, date) => (
        <Container key={date} modifiers={['padScaleX_0', 'padScaleY_2']}>
          <Row style={{ margin: `${px2rem(20)} 0 ${px2rem(10)}` }}>
            <H4 modifiers="fontWeightLight">{formatGroupDate(date)}</H4>
          </Row>
          {map(oneDaysUpdates, (update) => (
            <Entry
              key={update.id}
              entry={update}
              caseNumber={caseNumber}
              showHistoryPopover
            />
          ))}
        </Container>
      ))}
    </Container>
  );
}

StatusUpdatesPanel.propTypes = {
  caseNumber: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  statusHistory: PropTypes.arrayOf(
    PropTypes.shape({
      changedAt: PropTypes.string.isRequired,
      user: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string,
      }),
    }),
  ).isRequired,
};

export default compose(
  setDisplayName('StatusUpdates'),
  withCaseStatusHistory,
)(StatusUpdatesPanel);

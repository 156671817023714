import { Label } from 'base-components';
import { buildStyledComponent } from 'decisiv-ui-utils';

const styles = `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /*
    Make sure this has the same height as ElapsedTime so that
    EventIndicatorBar components in Timeline are vertically aligned.
  */
  line-height: 1rem;
`;

export default buildStyledComponent('Timeline__EventLabel', Label, styles);

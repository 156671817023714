import PropTypes from 'prop-types';

const spacing = {
  // margin
  gap: ({ theme }) => `
    margin: ${theme.dimensions.gridPad};
  `,

  gapBottom: ({ theme }) => `
    margin-bottom: ${theme.dimensions.gridPad};
  `,

  gapLeft: ({ theme }) => `
    margin-left: ${theme.dimensions.gridPad};
  `,

  gapNone: () => 'margin: 0;',

  gapRight: ({ theme }) => `
    margin-right: ${theme.dimensions.gridPad};
  `,

  gapTop: ({ theme }) => `
    margin-top: ${theme.dimensions.gridPad};
  `,

  gapX: ({ theme }) => `
    margin-left: ${theme.dimensions.gridPad};
    margin-right: ${theme.dimensions.gridPad};
  `,

  gapY: ({ theme }) => `
    margin-bottom: ${theme.dimensions.gridPad};
    margin-top: ${theme.dimensions.gridPad};
  `,
  // padding
  pad: ({ theme }) => `
    padding: ${theme.dimensions.gridPad};
  `,

  padBottom: ({ theme }) => `
    padding-bottom: ${theme.dimensions.gridPad};
  `,

  padLeft: ({ theme }) => `
    padding-left: ${theme.dimensions.gridPad};
  `,

  padNone: () => 'padding: 0;',

  padRight: ({ theme }) => `
    padding-right: ${theme.dimensions.gridPad};
  `,

  padTop: ({ theme }) => `
    padding-top: ${theme.dimensions.gridPad};
  `,

  padX: ({ theme }) => `
    padding-left: ${theme.dimensions.gridPad};
    padding-right: ${theme.dimensions.gridPad};
  `,

  padY: ({ theme }) => `
    padding-bottom: ${theme.dimensions.gridPad};
    padding-top: ${theme.dimensions.gridPad};
  `,
};

const spacingPropTypes = {
  dimensions: PropTypes.shape({
    gridPad: PropTypes.string.isRequired,
  }).isRequired,
};

export { spacing, spacingPropTypes };

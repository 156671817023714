import gql from 'graphql-tag';

export default gql`
  mutation createDailyCasesMetricsReport(
    $billable: Boolean
    $caseNumber: String
    $customerLocation: String
    $customerName: String
    $dealerName: String
    $dealerLocation: String
    $endTime: Date!
    $inboundProgramName: String
    $poNumber: String
    $startTime: Date!
    $status: [CaseStatus]
    $unitNumber: String
  ) {
    createDailyCasesMetricsReport(
      filters: {
        billable: $billable
        caseNumber: $caseNumber
        customerLocation: $customerLocation
        customerName: $customerName
        dealerName: $dealerName
        dealerLocation: $dealerLocation
        endTime: $endTime
        inboundProgramName: $inboundProgramName
        poNumber: $poNumber
        startTime: $startTime
        status: $status
        unitNumber: $unitNumber
      }
    ) {
      jobId
    }
  }
`;

import { withRouter } from 'react-router';
import { compose, setDisplayName } from 'recompose';

import StoreInfoPanel from './StoreInfoPanel';

import withDealerInfo from './withDealerInfo';
import withUpdateDealerEmail from './withUpdateDealerEmail';

export default compose(
  setDisplayName('StoreInfo'),
  withRouter,
  withDealerInfo,
  withUpdateDealerEmail,
)(StoreInfoPanel);

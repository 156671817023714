import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

const modifierConfig = {
  chrome400: ({ theme }) => ({
    styles: `border-color: ${theme.colors.base.chrome400};`,
  }),

  vertical: () => ({
    styles: `
      border-left-width: 1px;
      border-left-style: solid;
      height: 100%;
    `,
  }),

  // Prevents a modifiers error when using this inside a Dropdown List,
  // which always pushes the "fluid" modifier to all its children.
  // Styles can't be blank because to the JSON serializer can't handle it.
  fluid: () => ({ styles: `& {}` }),
};

const styles = ({ theme }) => `
  border-bottom: 1px solid;
  border-color: ${theme.colors.base.chrome200};
  position: relative;
  width: auto;
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome200: PropTypes.string.isRequired,
      chrome400: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default buildStyledComponent('Divider', styled.div, styles, {
  modifierConfig,
  themePropTypes,
});

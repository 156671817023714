import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { QuickActionButton } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import withContext from 'utils/withContext';

import CasesSearchContext from '../Context';

function Pagination({ pagination, updateSearchParams }) {
  const { hasPreviousPage, hasNextPage } = pagination;

  if (!hasPreviousPage && !hasNextPage) {
    return null;
  }

  const goToPreviousPage = () =>
    updateSearchParams({
      before: pagination.startCursor,
      after: null,
    });

  const goToNextPage = () =>
    updateSearchParams({
      after: pagination.endCursor,
      before: null,
    });

  return (
    <Container modifiers="padScale_5">
      <Row modifiers={['middle', 'center']}>
        {hasPreviousPage && (
          <Column>
            <QuickActionButton onClick={goToPreviousPage}>
              <QuickActionButton.Icon name="arrow-left" modifiers="left" />
              <QuickActionButton.Text>
                <Trans>Previous</Trans>
              </QuickActionButton.Text>
            </QuickActionButton>
          </Column>
        )}
        {hasNextPage && (
          <Column>
            <QuickActionButton onClick={goToNextPage}>
              <QuickActionButton.Text>
                <Trans>Next</Trans>
              </QuickActionButton.Text>
              <QuickActionButton.Icon name="arrow-right" modifiers="right" />
            </QuickActionButton>
          </Column>
        )}
      </Row>
    </Container>
  );
}

Pagination.propTypes = {
  pagination: PropTypes.shape({
    endCursor: PropTypes.string,
    startCursor: PropTypes.string,
    hasNextPage: PropTypes.bool.isRequired,
    hasPreviousPage: PropTypes.bool.isRequired,
  }).isRequired,
  updateSearchParams: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('CasesSearch__Pagination'),
  withContext(CasesSearchContext),
)(Pagination);

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Table from '../../blocks/Table';

class DataTableRow extends Component {
  static propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    modifiers: PropTypes.arrayOf(PropTypes.string),
    shouldUpdate: PropTypes.func.isRequired,
    cellRenderer: PropTypes.func.isRequired,
  };

  static defaultProps = {
    modifiers: [],
  };

  shouldComponentUpdate(nextProps) {
    return nextProps.shouldUpdate(this.props, nextProps);
  }

  render() {
    const { columns, modifiers, cellRenderer } = this.props;

    return (
      <Table.Row modifiers={modifiers}>
        {columns.map((column, colIndex) =>
          cellRenderer(column, { colIndex, ...this.props }),
        )}
      </Table.Row>
    );
  }
}

export default DataTableRow;

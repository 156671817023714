import React from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { get, isEmpty, noop } from 'lodash';

import { ButtonGroup, H3, Text } from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Container, Row, Column } from 'styled-components-grid';

import { pinIconForType } from 'assets/images/pins';
import { useCaseShortcut, CASE_SHORTCUT_PANELS } from 'features/keyShortcuts';

import { CallButton } from 'compositions/ClickToCall';
import {
  ContactsPopover,
  HoursPopover,
  NotesPopover,
  contactPropType,
  ServicesPopover,
} from 'compositions/DealerLocator/SearchResultsList/DealerList/Dealer';

import {
  evaluateDealerType,
  LOCAL_FLEET,
  MCSN,
  MICHELIN,
  MRT,
} from 'utils/dealerFilter';
import { OPERATING_STATUS_VALUES } from 'utils/operatingStatusFilter';

const pinIconTypes = {
  [LOCAL_FLEET]: 'dealerLocalFleet',
  [MCSN]: 'dealerMcsn',
  [MICHELIN]: 'dealerMichelin',
  [MRT]: 'dealerMrt',
};

export const checkIfEnabled = (dealer) => !!dealer.name;

export const getDealerPinIcon = (dealer) => {
  const isEnabled = checkIfEnabled(dealer);
  const iconType = isEnabled
    ? pinIconTypes[evaluateDealerType(dealer)]
    : 'dealerDisabled';

  return pinIconForType(iconType);
};

export function Dealer({
  dealer,
  dealer: {
    dealerAfterHoursContacts = {},
    displayAddress = {},
    features,
    name,
    operatingStatus,
    phone,
    phoneAfterHours,
    phoneAfterHours2,
    shipTo,
    billTo,
    notes,
  },
  onOutboundCall,
}) {
  const { activeAfterHoursContacts = {} } = dealerAfterHoursContacts;
  const { contacts = {} } = activeAfterHoursContacts;
  const { city, postalCode, province, streetAddress } = displayAddress;
  const isEnabled = checkIfEnabled(dealer);
  const pinIcon = getDealerPinIcon(dealer);

  const isERSOpen =
    get(operatingStatus, 'open') === OPERATING_STATUS_VALUES.CLOSED &&
    get(operatingStatus, 'ersOpen') === OPERATING_STATUS_VALUES.OPEN;

  const isAdditionalContactAvailable =
    !isEmpty(contacts.backupContact) ||
    !isEmpty(contacts.finalContact) ||
    !isEmpty(contacts.primaryContact);

  const isPhoneAfterHoursDuplicate = phoneAfterHours === phoneAfterHours2;

  const getPhoneNumber = () => {
    const phoneNumber =
      isERSOpen && (contacts.primaryContact || phoneAfterHours)
        ? get(contacts, 'primaryContact.primaryPhoneNumber', phoneAfterHours)
        : phone;

    const contactName =
      isERSOpen && contacts.primaryContact
        ? contacts.primaryContact.name
        : name;

    return { phoneNumber, contactName };
  };

  const showAdditionalContacts =
    isERSOpen &&
    (isAdditionalContactAvailable ||
      (!isPhoneAfterHoursDuplicate && phoneAfterHours2));

  const contactList = {
    ...contacts,
    storeContact: {
      jobTitle: '',
      name,
      primaryPhoneNumber: phoneAfterHours || phone,
      secondaryPhoneNumber: !isPhoneAfterHoursDuplicate
        ? phoneAfterHours2
        : undefined,
    },
  };

  const { phoneNumber, contactName } = getPhoneNumber();

  const { onFocusRequested } = useCaseShortcut({
    parent: CASE_SHORTCUT_PANELS.dealerLocator,
    id: 'goToDealerLocatorAction',
    name: t`Go to Service Provider Locator`,
    shortcut: ['s', 'l', '0'],
    priority: 4,
    icon: 'arrow-right',
  });

  return (
    <Container modifiers="padScale_3">
      <Row ref={onFocusRequested}>
        <H3 modifiers="fontWeightRegular">
          <Trans>Service Provider</Trans>
        </H3>
      </Row>
      <Row style={{ marginTop: px2rem(12) }}>
        <Column modifiers="padScale_0">
          <img src={pinIcon} alt="" />
        </Column>
        <Column modifiers={['col', 'padScaleX_2']}>
          {isEnabled ? (
            <>
              <Row modifiers={['middle', 'padScaleX_0']}>
                <Column modifiers="padScale_0">
                  <Text>{name}</Text>
                </Column>
                {notes && (
                  <Column modifiers="padScaleY_0">
                    <NotesPopover notes={notes} position="topRight" />
                  </Column>
                )}
              </Row>
              {streetAddress && (
                <Row modifiers="padScaleX_0">
                  <Column modifiers={['col', 'padScale_0']}>
                    <Text modifiers="textLight">{streetAddress}</Text>
                  </Column>
                </Row>
              )}
              {city && (
                <Row modifiers="padScaleX_0">
                  <Column modifiers={['col', 'padScale_0']}>
                    <Text modifiers="textLight">
                      {`${city}, ${province} ${postalCode}`}
                    </Text>
                  </Column>
                </Row>
              )}
              {billTo && shipTo && (
                <Row modifiers="padScaleX_0">
                  <Column modifiers={['col', 'padScale_0']}>
                    <Text modifiers={['small', 'textLight']}>
                      <Trans>Bill to #</Trans>
                      &nbsp;
                      {billTo}
                      &nbsp; &middot; &nbsp;
                      <Trans>Ship to #</Trans>
                      &nbsp;
                      {shipTo}
                    </Text>
                  </Column>
                </Row>
              )}
              <Row modifiers={['middle', 'padScaleY_3']}>
                {operatingStatus && (
                  <Column style={{ marginRight: px2rem(8) }}>
                    <HoursPopover dealer={dealer} position="topRight" />
                  </Column>
                )}
                {!isEmpty(features) && (
                  <Column>
                    <Row modifiers="middle" style={{ marginTop: px2rem(4) }}>
                      <ServicesPopover services={features} position="topLeft" />
                    </Row>
                  </Column>
                )}
                <Column>
                  <ButtonGroup>
                    {phoneNumber && (
                      <CallButton
                        dealer={dealer}
                        phone={phoneNumber}
                        name={contactName}
                        onOutboundCall={onOutboundCall}
                      />
                    )}
                    {showAdditionalContacts && (
                      <ContactsPopover
                        dealer={dealer}
                        contacts={contactList}
                        position="topLeft"
                        onOutboundCall={onOutboundCall}
                      />
                    )}
                  </ButtonGroup>
                </Column>
              </Row>
            </>
          ) : (
            <Text>&mdash;</Text>
          )}
        </Column>
      </Row>
    </Container>
  );
}

Dealer.propTypes = {
  dealer: PropTypes.shape({
    id: PropTypes.string,
    dealerAfterHoursContacts: PropTypes.shape({
      activeAfterHoursContacts: PropTypes.shape({
        contacts: PropTypes.shape({
          backupContact: contactPropType,
          finalContact: contactPropType,
          primaryContact: contactPropType,
        }),
      }),
    }),
    name: PropTypes.string,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
      }),
    ),
    phone: PropTypes.string,
    phoneAfterHours: PropTypes.string,
    phoneAfterHours2: PropTypes.string,
    operatingStatus: PropTypes.shape({
      ersOpen: PropTypes.string,
      open: PropTypes.string,
    }),
    displayAddress: PropTypes.shape({
      city: PropTypes.string,
      postalCode: PropTypes.string,
      province: PropTypes.string,
      streetAddress: PropTypes.string,
    }),
    billTo: PropTypes.string,
    shipTo: PropTypes.string,
    notes: PropTypes.string,
  }),
  onOutboundCall: PropTypes.func,
};

Dealer.defaultProps = {
  dealer: {},
  onOutboundCall: noop,
};

export default Dealer;

import { filter, groupBy, some, every } from 'lodash';
import { t } from '@lingui/macro';

import { RETREAD_SERVICE_CODES } from 'utils/dealerFilter';

export const FILTER_TYPES = ['NETWORK', 'PRODUCT', 'SERVICE'];

export const FILTER_TYPE_LABELS = {
  NETWORK: t`Network`,
  PRODUCT: t`Product`,
  SERVICE: t`Service`,
};

export const AllRetreadsFilter = {
  id: 'RetreadDealer',
  code: 'RetreadDealer',
  dealerFilter: true,
  displayIcon: true,
  type: 'PRODUCT',
};

const isRetreadFilter = (feature) =>
  RETREAD_SERVICE_CODES.includes(feature.code);

export const filterRetreads = (features) => filter(features, isRetreadFilter);

export const addRetreadFilters = (features) => {
  const retreadFilters = filterRetreads(features);

  if (!retreadFilters.length) return features;

  const otherFilters = filter(features, (feat) => !isRetreadFilter(feat));

  return [AllRetreadsFilter, ...retreadFilters, ...otherFilters];
};

export const selectRetreadFilters = (allFilters) => (
  selection = [],
  filter,
) => {
  const newSelection = [...selection, filter];
  const retreadFilters = filterRetreads(allFilters);
  if (
    retreadFilters.length &&
    (isRetreadFilter(filter) || filter?.id === AllRetreadsFilter.id)
  ) {
    if (filter?.id === AllRetreadsFilter.id) {
      return [...newSelection, ...retreadFilters];
    }

    if (every(retreadFilters, (item) => some(newSelection, { id: item.id }))) {
      return [...newSelection, AllRetreadsFilter];
    }
  }

  return newSelection;
};

export const unselectRetreadFilters = (unselected) => (
  selection = [],
  filter,
) => {
  if (
    (isRetreadFilter(filter) && unselected.id === AllRetreadsFilter.id) ||
    (filter.id === AllRetreadsFilter.id && isRetreadFilter(unselected))
  ) {
    return selection;
  }

  return [...selection, filter];
};

export const mergeSelectedRetreadFilters = (acc = [], feature) => {
  return isRetreadFilter(feature) && acc.some((item) => isRetreadFilter(item))
    ? acc
    : [...acc, feature];
};

export function buildDealerFiltersArr({ dealerFeatures }) {
  const dealerFilters = filter(addRetreadFilters(dealerFeatures), {
    dealerFilter: true,
  });
  const groupedDealerFilters = groupBy(dealerFilters, 'type');

  return FILTER_TYPES.map((type) => ({
    dealerFilters: groupedDealerFilters[type] || [],
    label: FILTER_TYPE_LABELS[type],
    type,
  }));
}

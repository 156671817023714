import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import Icon from '../../elements/Icon';

const modifierConfig = {
  left: () => `
    padding-right: ${px2rem(5)};
  `,
  right: () => `
    padding-left: ${px2rem(5)};
  `,
};

const styles = `
  color: inherit;
  font-size: ${px2rem(12)};
`;

export default buildStyledComponent('Icon', Icon, styles, { modifierConfig });

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { t, Trans } from '@lingui/macro';
import { isEmpty, isEqual, get } from 'lodash';
import { compose, setDisplayName } from 'recompose';

import { Column, Row } from 'styled-components-grid';
import { Icon, ButtonIconRectangle } from 'base-components';

import withFocusReceiver from 'setup/FocusProvider/withFocusReceiver';
import ButtonLinkWithIcon from 'elements/ButtonLinkWithIcon';
import { CaseShortcut, CASE_SHORTCUT_PANELS } from 'features/keyShortcuts';

import DomicileSelector from '../DomicileSelector';
import TerminalLocation from '../TerminalLocation';
import Selector from '../Selector';
import { selectorTypes } from '../Selector/constants';

import withClearCustomerSearchValue from '../../withClearCustomerSearchValue';
import withCaseInboundProgram from '../../withCaseInboundProgram';
import withCustomerSearch from '../../withCustomerSearch';
import withCustomerSelect from '../../withCustomerSelect';
import withRemoveCaseDomicile from '../DomicileSelector/withRemoveCaseDomicile';
import withTerminalLocation from '../TerminalLocation/withTerminalLocation';
import { fieldIds } from '../../constants';
import { buildCustomerSearchValue } from '../../utils';

export class CustomerSelector extends Component {
  static propTypes = {
    caseId: PropTypes.string,
    clearCaseCustomer: PropTypes.func.isRequired,
    clearCustomerSearchValue: PropTypes.func.isRequired,
    customer: PropTypes.shape({
      id: PropTypes.string,
    }),
    customerDomicile: PropTypes.shape({
      id: PropTypes.string,
    }),
    customerTerminalLocation: PropTypes.string,
    isTerminalLocationRequired: PropTypes.bool,
    customerSearchValue: PropTypes.string,
    customers: PropTypes.arrayOf(
      PropTypes.shape({
        billTo: PropTypes.string,
        city: PropTypes.string,
        id: PropTypes.string.isRequired,
        name: PropTypes.string,
        shipTo: PropTypes.string,
        state: PropTypes.string,
      }),
    ),
    inboundProgramAccountType: PropTypes.string,
    inboundProgramBillTo: PropTypes.string,
    inboundProgramDefaultCustomer: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    isReadOnlyCase: PropTypes.bool.isRequired,
    isSearching: PropTypes.bool,
    onChangeCustomerSearchValue: PropTypes.func.isRequired,
    onRemoveDomicileCustomer: PropTypes.func.isRequired,
    onRemoveTerminalLocation: PropTypes.func.isRequired,
    onSelectCustomer: PropTypes.func.isRequired,
    setCasePanelInvalid: PropTypes.func.isRequired,
    onFocusRequested: PropTypes.func.isRequired,
  };

  static defaultProps = {
    caseId: '',
    customer: {},
    customerDomicile: {},
    customerTerminalLocation: '',
    isTerminalLocationRequired: false,
    customerSearchValue: '',
    customers: [],
    inboundProgramAccountType: '',
    inboundProgramBillTo: '',
    inboundProgramDefaultCustomer: {},
    isSearching: false,
  };

  state = {
    isInvalid: false,
    showDomicileSelector: false,
    showTerminalLocation: !!this.props.customerTerminalLocation,
  };

  componentDidMount() {
    const customerSearchValue = buildCustomerSearchValue(this.props.customer);
    this.props.onChangeCustomerSearchValue(customerSearchValue);
  }

  componentDidUpdate(prevProps) {
    const {
      inboundProgramDefaultCustomer,
      inboundProgramAccountType,
    } = this.props;

    const inboundProgramHasChanged = !isEqual(
      prevProps.inboundProgramBillTo,
      this.props.inboundProgramBillTo,
    );

    if (
      inboundProgramHasChanged &&
      !isEmpty(inboundProgramDefaultCustomer) &&
      isEmpty(this.props.customer)
    ) {
      this.props.onSelectCustomer(
        inboundProgramDefaultCustomer.id,
        inboundProgramDefaultCustomer.name,
      );
    }

    if (
      inboundProgramHasChanged &&
      inboundProgramAccountType === 'FLEET' &&
      isEmpty(inboundProgramDefaultCustomer) &&
      isEmpty(this.props.customer)
    ) {
      this.props.onChangeCustomerSearchValue(this.props.inboundProgramBillTo);
    }
  }

  resetCustomer = () => {
    this.setState({
      isInvalid: false,
      showDomicileSelector: false,
      showTerminalLocation: false,
    });
    this.props.clearCustomerSearchValue();
    this.props.clearCaseCustomer();
    this.props.onRemoveDomicileCustomer(this.props.caseId);
    this.props.onRemoveTerminalLocation();
  };

  handleValidation = (bool) => {
    this.setState({ isInvalid: bool });
  };

  handleSelect = (id, customer) => {
    this.props.onSelectCustomer(id, buildCustomerSearchValue(customer));
  };

  validate = () => {
    const { customerSearchValue, customer, setCasePanelInvalid } = this.props;

    if (customerSearchValue && !customer) {
      setCasePanelInvalid((status) => ({
        ...status.fields,
        [fieldIds.national.customer]: 'invalid',
      }));

      this.setState({ isInvalid: true });
    } else {
      this.setState({ isInvalid: false });
    }
  };

  handleDomicileRemove = () => {
    this.setState({ showDomicileSelector: false });
    this.props.onRemoveDomicileCustomer(this.props.caseId);
  };

  handleTerminalLocationRemove = () => {
    this.setState({ showTerminalLocation: false });
    this.props.onRemoveTerminalLocation();
  };

  renderDomicile = () => {
    const { customerDomicile } = this.props;

    if (
      this.state.showDomicileSelector ||
      (customerDomicile && !!customerDomicile.id)
    ) {
      return (
        <Row>
          <Column modifiers={['col_10', 'padScaleX_0', 'padScaleY_2']}>
            <DomicileSelector isReadOnlyCase={this.props.isReadOnlyCase} />
          </Column>
          <Column modifiers={['col', 'center', 'padScaleX_0', 'padScaleY_4']}>
            {!this.props.isReadOnlyCase && (
              <ButtonIconRectangle
                type="button"
                style={{ background: 'none' }}
                onClick={this.handleDomicileRemove}
                modifiers="hoverDanger"
              >
                <Icon name="trash" />
              </ButtonIconRectangle>
            )}
          </Column>
        </Row>
      );
    }

    return (
      <Column modifiers="padScaleX_2">
        <ButtonLinkWithIcon
          icon="plus"
          onClick={() => this.setState({ showDomicileSelector: true })}
        >
          <Trans>Add Domicile</Trans>
        </ButtonLinkWithIcon>
      </Column>
    );
  };

  renderTerminalLocation = () => {
    const {
      isTerminalLocationRequired: isRequired,
      isReadOnlyCase,
      customer,
    } = this.props;

    if (isRequired || this.state.showTerminalLocation) {
      const customerHasTerminalLocation = !!get(customer, 'terminalNumber');
      const showRemoveButton =
        !customerHasTerminalLocation && !isRequired && !isReadOnlyCase;
      return (
        <Row>
          <Column
            modifiers={[
              showRemoveButton ? 'col_10' : 'col',
              'padScaleX_0',
              'padScaleY_2',
            ]}
          >
            <TerminalLocation
              isReadOnly={customerHasTerminalLocation || isReadOnlyCase}
              isRequired={customerHasTerminalLocation || isRequired}
            />
          </Column>
          {showRemoveButton && (
            <Column modifiers={['col', 'center', 'padScaleX_0', 'padScaleY_4']}>
              <ButtonIconRectangle
                type="button"
                style={{ background: 'none' }}
                onClick={this.handleTerminalLocationRemove}
                modifiers="hoverDanger"
              >
                <Icon name="trash" />
              </ButtonIconRectangle>
            </Column>
          )}
        </Row>
      );
    }

    return (
      <>
        <Column modifiers="padScaleX_2">
          <ButtonLinkWithIcon
            icon="plus"
            onClick={() => this.setState({ showTerminalLocation: true })}
          >
            <Trans>Add Terminal Location</Trans>
          </ButtonLinkWithIcon>
        </Column>
      </>
    );
  };

  render() {
    const {
      customerSearchValue,
      customer,
      customers,
      isReadOnlyCase,
      isSearching,
      onChangeCustomerSearchValue,
      onFocusRequested,
    } = this.props;

    return (
      <>
        <CaseShortcut
          action={{
            parent: CASE_SHORTCUT_PANELS.customer,
            id: 'goToCustomerAction',
            name: t`Go to Fleet`,
            shortcut: ['f', '0'],
            priority: 8,
            icon: 'arrow-right',
          }}
        >
          {({ onFocusRequested: onShortcutFocusRequested }) => (
            <Selector
              selectorType={selectorTypes.customer}
              items={customers}
              searchValue={customerSearchValue}
              selectedItem={customer}
              isRequired
              isReadOnlyCase={isReadOnlyCase}
              isSearching={isSearching}
              onChangeSearchValue={onChangeCustomerSearchValue}
              onSelect={this.handleSelect}
              resetSelector={this.resetCustomer}
              handleValidation={this.handleValidation}
              validateSelector={this.validate}
              isInvalid={this.state.isInvalid}
              onFocusRequested={(ref) => {
                onFocusRequested(ref);
                onShortcutFocusRequested(ref);
              }}
            />
          )}
        </CaseShortcut>
        {customer && !isReadOnlyCase && this.renderTerminalLocation()}
        {customer && !isReadOnlyCase && this.renderDomicile()}
      </>
    );
  }
}

export default compose(
  setDisplayName('CustomerSelector'),
  withClearCustomerSearchValue,
  withCaseInboundProgram,
  withCustomerSearch,
  withCustomerSelect,
  withRemoveCaseDomicile,
  withTerminalLocation,
  withFocusReceiver(fieldIds.national.customer),
)(CustomerSelector);

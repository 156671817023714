import React from 'react';
import { castArray } from 'lodash';
import { t, Trans } from '@lingui/macro';

import { Text } from 'base-components';
import { Row, Column } from 'styled-components-grid';

import ButtonLinkWithIcon from 'elements/ButtonLinkWithIcon';

import { allAssetTypes } from '../../constants';

const renderHeaderCell = (label) => (
  <Row>
    <Column modifiers={['col', 'padScale_2']}>
      <Text modifiers={['small', 'textLight']}>
        {label ? <Trans id={label} /> : ' '}
      </Text>
    </Column>
  </Row>
);

const lightTextModifiers = ['small', 'textLight'];

const renderDataCell = (data, config) => {
  const { formatters = {} } = config;

  const fields = castArray(config.field);

  return (
    <Row modifiers={['height_100']}>
      <Column modifiers={['col', 'padScale_2']}>
        {fields.map((type, index) => {
          const value = data[type];
          const formatter = formatters[type];

          return (
            <Row key={type}>
              <Column modifiers={['col', 'padScale_0']}>
                <Text modifiers={index > 0 ? lightTextModifiers : ''}>
                  {formatter ? formatter(value) : value || <span>&mdash;</span>}
                </Text>
              </Column>
            </Row>
          );
        })}
      </Column>
    </Row>
  );
};

const fields = [
  {
    field: ['vehicleNumber', 'vehicleType'],
    label: t`Unit # / Type`,
    formatters: {
      vehicleType: (value) => <Trans id={allAssetTypes[value] || value} />,
    },
  },
  { field: ['customer', 'shipTo'], label: t`Fleet / Ship To #` },
  { field: 'vehicleVin', label: t`VIN` },
  { field: 'application', label: t`Application` },
  { field: 'domicileLocation', label: t`Domicile Location` },
];

const getFieldName = (field) => castArray(field).join('_');

const buildColumn = (config, index) => ({
  name: getFieldName(config.field),
  cellKeyGetter: () => index,
  cellDataGetter: (data) => data,
  dataCellRenderer: (data) => renderDataCell(data, config),
  headerCellRenderer: () => renderHeaderCell(config.label),
});

export const actionsColumn = {
  name: 'actions',
  cellKeyGetter: () => 'actions',
  cellDataGetter: (data) => data,
  dataCellRenderer: (data, { tableMetaData }) => {
    const { onSelectAsset, readOnly } = tableMetaData;

    return (
      <Row modifiers={['height_100', 'center']}>
        <Column modifiers={['col', 'padScale_2']}>
          <ButtonLinkWithIcon
            icon="check-circle"
            onClick={() => onSelectAsset(data)}
            disabled={readOnly}
            modifiers={[readOnly && 'disabled']}
          >
            <Trans>Select</Trans>
          </ButtonLinkWithIcon>
        </Column>
      </Row>
    );
  },
  headerCellRenderer: () => renderHeaderCell(''),
};

export default fields.map(buildColumn);

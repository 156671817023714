/* eslint-disable react/prop-types */
import React from 'react';
import { Trans, Plural } from '@lingui/macro';

import { Row, Column } from 'styled-components-grid';
import { MessageSmall, Text } from 'base-components';

import ResetButton from '../ResetButton';
import LineCheckbox from '../LineCheckbox';
import BaseRequestLinesTable from '../BaseRequestLinesTable';
import SuppliedLineActionCell from './SuppliedLineActionCell';

import {
  getRowLocation,
  isRequestLineValid,
  getTotalGenericTireOptionSelected,
} from '../utils';

const buildAdditionalColumns = (props) => [
  {
    name: 'supplied',
    width: '5.5%',
    renderer: (data) => {
      const { rowData, isReadOnlyCase, tableData } = data;
      const { id, supplied, valid, isReadOnly } = rowData; // eslint-disable-line no-unused-vars

      // TODO: Uncomment when we want to enable readonly after dispatch
      // const readOnly = isReadOnly || isReadOnlyCase;
      const readOnly = isReadOnlyCase;
      let isDisabled = !readOnly && !supplied && !valid;
      let tooltip = '';

      if (valid && !supplied) {
        const rowLocation = getRowLocation(rowData);
        const positionAlreadyTaken = tableData
          .filter((row) => row.id !== rowData.id && !!row.supplied)
          .some((row) => getRowLocation(row) === rowLocation);

        if (positionAlreadyTaken) {
          isDisabled = true;
          tooltip = (
            <Trans>
              A row for this Unit, Position and Type has already been marked as
              &quot;supplied&quot;.
            </Trans>
          );
        }
      }

      return (
        <LineCheckbox
          id={id}
          tooltip={tooltip}
          onChange={props.toggleRequestSuppliedLineStatus}
          isChecked={!!supplied}
          isDisabled={isDisabled}
          isReadOnly={readOnly}
          focusReceiverId={`request.lines.supplied.${id}.supplied`}
        />
      );
    },

    headerRenderer: () => (
      <Row modifiers={['padScale_0', 'center']}>
        <Text modifiers={['small', 'textLight']}>
          <Trans>Supplied</Trans>
        </Text>
      </Row>
    ),
  },
  {
    name: 'actions',
    width: '4.29%',
    renderer: ({ rowData, ...rest }) => {
      if (rowData.readOnly) return <div />;

      return <SuppliedLineActionCell {...{ ...rest, ...props, rowData }} />;
    },

    headerRenderer: ({ isReadOnlyCase }) => {
      const isDisabled = !props.anyRequestSuppliedLineHasChanges();

      if (props.shouldDisplaySuppliedTableReset() && !isReadOnlyCase) {
        return (
          <ResetButton
            label={<Trans>Reset Table</Trans>}
            onClick={() => props.resetAllRequestSuppliedLines()}
            disabled={isDisabled}
            modifiers={isDisabled && 'disabled'}
          />
        );
      }

      return (
        <Row>
          <Column modifiers="col" />
        </Row>
      );
    },
  },
];

const flattenLine = (line, { isReadOnlyCase }) => {
  const { supplied, suppliedLine, agreementLineId } = line;
  // TODO: Uncomment when we want to enable readonly after dispatch
  // const readOnly = supplied || suppliedLine.isReadOnly || isReadOnlyCase;
  const readOnly = supplied || isReadOnlyCase;

  return { supplied, readOnly, agreementLineId, ...suppliedLine };
};

const addIsValid = (line, allLines, props) => {
  const valid = line.supplied
    ? true
    : isRequestLineValid(line, allLines, props);

  return { ...line, valid };
};

function mapPropsToSuppliedLinesTableProps(props) {
  const requestLines = props.requestSuppliedLines
    .map((l) => flattenLine(l, props))
    .map((l, _, allLines) => addIsValid(l, allLines, props));

  return {
    ...props,
    requestLines,
    shouldHeadUpdate: () => true,
    additionalColumns: buildAdditionalColumns(props),
    updateRequestLine: props.updateRequestSuppliedLine,
    allowGenericTireOptionsSelection: false,
    rowsType: 'supplied',
  };
}

function SuppliedRequestLinesTable(props) {
  const tableProps = mapPropsToSuppliedLinesTableProps(props);
  const uncheckedLines = tableProps.requestLines.filter((l) => !l.supplied);

  const genericTotal = getTotalGenericTireOptionSelected(
    uncheckedLines,
    tableProps.requestLines,
    props,
  );

  return (
    <>
      <Row>
        <Column modifiers={['col', 'padScale_0']}>
          <BaseRequestLinesTable {...tableProps} />
        </Column>
      </Row>
      {!!genericTotal && (
        <Row>
          <Column modifiers={['col', 'padScaleX_0', 'padScaleY_3']}>
            <MessageSmall type="warning">
              <Plural
                value={genericTotal}
                one="Select missing product."
                other="Select missing products."
              />
            </MessageSmall>
          </Column>
        </Row>
      )}
    </>
  );
}

export default SuppliedRequestLinesTable;

import { px2rem } from 'decisiv-ui-utils';
import { Trans } from '@lingui/macro';
import { concat, find, isEmpty, slice } from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import { Divider, P, Text } from 'base-components';
import { Row, Column } from 'styled-components-grid';

import BusinessHours from 'utils/BusinessHours';
import {
  evaluateOperatingStatusType,
  OPERATING_STATUS_TYPE,
} from 'utils/operatingStatusFilter';

import SoonWarning from './SoonWarning';

export const dayList = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export function formatHours(day, hours, is24HoursOpen) {
  const currentDayHours = find(hours, { weekDay: day });

  if (is24HoursOpen) {
    return <Trans>Open 24 hours</Trans>;
  }

  return isEmpty(currentDayHours)
    ? '—'
    : `${currentDayHours.start} - ${currentDayHours.end}`;
}

export function renderSoonWarning(dealer, dayIndex, now) {
  if (isEmpty(dealer) || isEmpty(dealer.workingHours)) {
    return null;
  }

  const { workingHours, timezone } = dealer;
  const hours = new BusinessHours(workingHours, timezone);

  // check to ensure there are hours available for the given day
  if (!hours.existFor(dayIndex)) {
    return null;
  }

  const closingSoon = hours.willCloseSoon(dayIndex, now);
  if (closingSoon) {
    return (
      <SoonWarning
        minutes={hours.minutesUntilClose(dayIndex, now)}
        type="close"
      />
    );
  }

  const openingSoon = hours.willOpenSoon(dayIndex, now);
  if (openingSoon) {
    return (
      <SoonWarning
        minutes={hours.minutesUntilOpen(dayIndex, now)}
        type="open"
      />
    );
  }

  return null;
}

function sortDays(dayOfWeekIndex) {
  const firstDays = slice(dayList, dayOfWeekIndex + 1);
  const lastDays = slice(dayList, 0, dayOfWeekIndex);
  return concat(firstDays, lastDays);
}

export function List({ dealer, relativeNow, dividers, popover }) {
  const { ers247, ersHours, open247, timezone, workingHours } = dealer;
  const { value: operatingStatusTypeValue } = evaluateOperatingStatusType(
    dealer,
  );

  const isERSHoursAvailable =
    ers247 ||
    operatingStatusTypeValue === OPERATING_STATUS_TYPE.Unknown.value ||
    operatingStatusTypeValue === OPERATING_STATUS_TYPE.ERSHours.value ||
    !isEmpty(ersHours);

  const today = moment().tz(timezone);
  const dayOfWeekIndex = today.isoWeekday();

  return (
    <>
      <Row>
        <Column modifiers={['col_4', 'col_offset_4']}>
          <Text modifiers={['small', 'textLight']}>
            <Trans>Business Hours</Trans>
          </Text>
        </Column>

        <Column modifiers={['col_4']}>
          <Text modifiers={['small', 'textLight']}>
            {isERSHoursAvailable && <Trans>ERS Hours</Trans>}
          </Text>
        </Column>
      </Row>
      {dividers && <Divider />}
      <Row style={{ paddingBottom: `${popover && px2rem(12)}` }}>
        <Column modifiers={['col']}>
          <Text>
            <Trans>Today</Trans>
          </Text>
        </Column>
        <Column modifiers={['col', 'padScaleY_1']}>
          <P>{formatHours(dayList[dayOfWeekIndex], workingHours, open247)}</P>
          {renderSoonWarning(dealer, dayOfWeekIndex, relativeNow)}
        </Column>
        <Column modifiers={['col']}>
          <P>
            {isERSHoursAvailable &&
              formatHours(dayList[dayOfWeekIndex], ersHours, ers247)}
          </P>
        </Column>
      </Row>
      {sortDays(dayOfWeekIndex).map((day) => (
        <Row key={day} modifiers={['padScaleY_half']}>
          <Column modifiers={['col', 'padScale_0']}>
            {dividers && <Divider />}
            <Row key={day}>
              <Column modifiers={['col', 'padScaleY_0']}>
                <Text modifiers={['capitalize', 'small', 'textLight']}>
                  {day}
                </Text>
              </Column>
              <Column modifiers={['col', 'padScaleY_0']}>
                <Text modifiers={['small', 'textLight']}>
                  {formatHours(day, workingHours, open247)}
                </Text>
              </Column>
              <Column modifiers={['col', 'padScaleY_0']}>
                <Text modifiers={['small', 'textLight']}>
                  {isERSHoursAvailable && formatHours(day, ersHours, ers247)}
                </Text>
              </Column>
            </Row>
          </Column>
        </Row>
      ))}
    </>
  );
}

List.propTypes = {
  dealer: PropTypes.shape({
    ers247: PropTypes.bool,
    ersHours: PropTypes.arrayOf(
      PropTypes.shape({
        end: PropTypes.string,
        start: PropTypes.string,
        weekDay: PropTypes.string,
      }),
    ),
    open247: PropTypes.bool,
    operatingStatus: PropTypes.shape({
      ersOpen: PropTypes.string.isRequired,
      open: PropTypes.string.isRequired,
    }),
    timezone: PropTypes.string,
    workingHours: PropTypes.arrayOf(
      PropTypes.shape({
        end: PropTypes.string,
        start: PropTypes.string,
        weekDay: PropTypes.string,
      }),
    ),
  }),
  dividers: PropTypes.bool,
  popover: PropTypes.bool,
  relativeNow: PropTypes.shape({}).isRequired,
};

List.defaultProps = {
  dealer: {},
  dividers: false,
  popover: false,
};

export default List;

/* eslint-disable camelcase */

import React, { useMemo } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { defineMessage, Trans } from '@lingui/macro';

import { px2rem } from 'decisiv-ui-utils';
import { Container, Row, Column } from 'styled-components-grid';
import { Badge, DataTable, Text } from 'base-components';

import EntryDivider from '../EntryDivider';

const headerMessages = {
  ASSET_MISMATCH: defineMessage({
    message: 'FIXPIX reported a mismatch in the <0>unit number</0>',
  }),
  CUSTOMER_MISMATCH: defineMessage({
    message: 'FIXPIX reported a mismatch in the <0>fleet</0>',
  }),
};

const message = {
  name: 'message',
  headerCellRenderer: () => (
    <Container>
      <Row>
        <Column modifiers="col">
          <Text modifiers={['small', 'textLight']}>
            <Trans>Message</Trans>
          </Text>
        </Column>
      </Row>
    </Container>
  ),
  cellKeyGetter: () => 'message',
  cellDataGetter: (data) => data.highlightAttributes,
  dataCellRenderer: (data) => (
    <Container>
      <Row>
        <Column modifiers="col">
          <Row>
            <Column modifiers="padScale_0">
              <Text>{data.message}</Text>
            </Column>
          </Row>
        </Column>
      </Row>
    </Container>
  ),
};

function NewHighlightEntry(props) {
  const { recordedAt, highlightAttributes } = props;

  const date = useMemo(
    () => moment(recordedAt).tz(moment.tz.guess()).format('h:mm A z'),
    [recordedAt],
  );

  return (
    <Row>
      <Column modifiers={['col', 'padScale_0']}>
        <Row>
          <Column
            modifiers={['center', 'height_100', 'padScale_0']}
            style={{ height: px2rem(20), width: px2rem(50) }}
          >
            <EntryDivider />
          </Column>
        </Row>

        <Row modifiers="middle">
          <Column modifiers="padScaleX_2">
            <Badge modifiers={['round', 'danger']}>
              <Badge.Icon name="cog" />
            </Badge>
          </Column>
          <Column>
            <Row>
              <Column modifiers="padScale_0">
                <Text modifiers={['small', 'textLight']}>{date}</Text>
              </Column>
            </Row>
            <Row>
              <Column modifiers="padScale_0">
                <Text>
                  <Trans
                    id={headerMessages[highlightAttributes.type]}
                    components={[<Text modifiers="fontWeightMedium" />]}
                  />
                </Text>
              </Column>
            </Row>
          </Column>
        </Row>
        <Row style={{ flexWrap: 'nowrap' }}>
          <Column
            modifiers={['height_100', 'padScaleY_0']}
            style={{ width: px2rem(50), height: 'auto' }}
          >
            <EntryDivider />
          </Column>
          <Column style={{ flex: '1' }} modifiers={['col', 'padScaleX_0']}>
            <DataTable
              name="NewHighlightEntryInfo"
              columns={[message]}
              modifiers=""
              tableData={[props]}
              rowModifiersGetter={() => ['noHighlightOnHover', 'notFlex']}
              rowsRequireRowIndex={false}
            />
          </Column>
        </Row>
      </Column>
    </Row>
  );
}

NewHighlightEntry.propTypes = {
  id: PropTypes.string.isRequired,
  recordedAt: PropTypes.string.isRequired,
  highlightAttributes: PropTypes.shape({
    type: PropTypes.oneOf(['ASSET_MISMATCH', 'CUSTOMER_MISMATCH']).isRequired,
    message: PropTypes.string.isRequired,
  }).isRequired,
};

export default NewHighlightEntry;

import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

const styles = () => `
  list-style: none;
  margin: 0;
  padding: 0;
`;

export default buildStyledComponent('MenuGroupsList', styled.ul, styles);

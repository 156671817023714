import { AsYouType } from 'libphonenumber-js';
import { parseDigit } from 'input-format';

const DEFAULT_COUNTRY_CODE = 'US';

// The following utils were copied from
// https://catamphetamine.github.io/libphonenumber-js (view page source)
// and only have a few minor formatting changes.

export const parse = (character, value) =>
  character === '+' && !value ? character : parseDigit(character);

export const format = (value) => {
  try {
    const formatter = new AsYouType(DEFAULT_COUNTRY_CODE);
    const formattedValue = formatter.input(value);

    return { text: formattedValue, template: formatter.getTemplate() };
  } catch ({ message }) {
    if (message.toLowerCase().indexOf('unknown country') === 0) {
      return { text: value, template: value.replace(/./g, 'x') };
    }

    return { text: '', template: '' };
  }
};

import gql from 'graphql-tag';

export default gql`
  query searchAssetRouteQuery(
    $assetLocation: Position!
    $countryCode: CountryCode!
    $distance: DealerSearchDistance!
    $distanceUnit: DistanceUnit
    $encodedPolyline: String!
    $features: [ID!]
    $inboundProgramBillTo: String
    $travelEstimateDestination: String!
  ) {
    searchAssetRoute(
      input: {
        assetLocation: $assetLocation
        countryCode: $countryCode
        distanceFromRoute: $distance
        encodedPolyline: $encodedPolyline
        features: $features
        inboundProgramBillTo: $inboundProgramBillTo
      }
    ) {
      dealers {
        id
        ers247
        ersHours {
          end
          start
          weekDay
        }
        features {
          id
          code
        }
        distance(unit: $distanceUnit)
        location {
          latitude
          longitude
        }
        name
        operatingStatus {
          ersOpen
          open
        }
        displayAddress {
          streetAddress
          city
          province
          postalCode
        }
        open247
        timezone
        travelEstimate(destination: $travelEstimateDestination) {
          distance(unit: $distanceUnit)
          time
        }
        workingHours {
          end
          start
          weekDay
        }
        phone
        billTo
        shipTo
        notes
        activeCases
      }
    }
  }
`;

/* eslint-disable camelcase */
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const styles = ({ theme }) => `
  box-sizing: border-box;
  min-width: ${px2rem(100)};
`;

const modifierConfig = {
  vAlign_top: () => `
    vertical-align: top;
  `,
};

export default buildStyledComponent('Table__DataCell', styled.td, styles, {
  modifierConfig,
});

import { defineMessage } from '@lingui/macro';
import { get, isEmpty } from 'lodash';

import { i18n } from '@lingui/core';

import BusinessHours from './BusinessHours';

export const OPERATING_STATUS_VALUES = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  OPENING_SOON: 'OPENING_SOON',
  CLOSING_SOON: 'CLOSING_SOON',
  ERS_CLOSING_SOON: 'ERS_CLOSING_SOON',
  ERS_HOURS: 'ERS_HOURS',
  UNKNOWN: 'UNKNOWN',
};

export const OPERATING_STATUS_TYPE = {
  Open: {
    type: OPERATING_STATUS_VALUES.OPEN,
    value: defineMessage({ message: 'Open' }),
    modifier: 'success',
  },
  OpeningSoon: {
    type: OPERATING_STATUS_VALUES.OPENING_SOON,
    value: defineMessage({ message: 'Opens in {minutes} min' }),
    modifier: 'default',
  },
  Closed: {
    type: OPERATING_STATUS_VALUES.CLOSED,
    value: defineMessage({ message: 'Closed' }),
    modifier: 'default',
  },
  ClosingSoon: {
    type: OPERATING_STATUS_VALUES.CLOSING_SOON,
    value: defineMessage({ message: 'Closes in {minutes} min' }),
    modifier: 'success',
  },
  ERSClosingSoon: {
    type: OPERATING_STATUS_VALUES.ERS_CLOSING_SOON,
    value: defineMessage({ message: 'Closes in {minutes} min' }),
    modifier: 'info',
  },
  ERSHours: {
    type: OPERATING_STATUS_VALUES.ERS_HOURS,
    value: defineMessage({ message: 'ERS' }),
    modifier: 'info',
  },
  Unknown: {
    type: OPERATING_STATUS_VALUES.UNKNOWN,
    value: '—',
    modifier: 'default',
  },
};

function translate(template, minutes) {
  return i18n._(template, { minutes });
}

function validHoursForNow(now, hours) {
  return now && hours && hours.existFor(now.isoWeekday());
}

function isOpen(now, hours) {
  // this function assumes you've already validated that operatingStatus.open === OPEN;
  return (
    !validHoursForNow(now, hours) || !hours.willCloseSoon(now.isoWeekday(), now)
  );
}

function evaluateOpenStatus(workingHours, now, timezone) {
  const hours =
    !isEmpty(workingHours) && new BusinessHours(workingHours, timezone);
  if (isOpen(now, hours)) {
    // return the default response, 'Open'
    return OPERATING_STATUS_TYPE.Open;
  }

  const { value, modifier } = OPERATING_STATUS_TYPE.ClosingSoon;
  const minutes = hours.minutesUntilClose(now.isoWeekday(), now);
  const newValue = translate(value, minutes);
  // return the minutes until closing for workingHours
  return { value: newValue, modifier };
}

function evaluateERSOpenStatus(ersHours, now, timezone) {
  const hours = !isEmpty(ersHours) && new BusinessHours(ersHours, timezone);
  // if 'now' doesn't exist, or ersHours are not available, or it's not closing soon
  if (isOpen(now, hours)) {
    // return the default response, 'ERS'
    return OPERATING_STATUS_TYPE.ERSHours;
  }
  const { value, modifier } = OPERATING_STATUS_TYPE.ERSClosingSoon;
  const minutes = hours.minutesUntilClose(now.isoWeekday(), now);
  const newValue = translate(value, minutes);
  // return the minutes until closing for ersHours
  return { value: newValue, modifier };
}

function evaluateClosedStatus(workingHours, now, timezone) {
  const hours =
    !isEmpty(workingHours) && new BusinessHours(workingHours, timezone);
  // if 'now' doesn't exist, or workingHours are not available, or it's not opening soon
  if (
    !validHoursForNow(now, hours) ||
    !hours.willOpenSoon(now.isoWeekday(), now)
  ) {
    // return the default response, 'Closed'
    return OPERATING_STATUS_TYPE.Closed;
  }
  const { value, modifier } = OPERATING_STATUS_TYPE.OpeningSoon;
  const minutes = hours.minutesUntilOpen(now.isoWeekday(), now);
  const newValue = translate(value, minutes);
  // return the minutes until opening for workingHours
  return { value: newValue, modifier };
}

// eslint-disable-next-line import/prefer-default-export
export function evaluateOperatingStatusType(dealer, now) {
  const { operatingStatus, workingHours, ersHours, timezone, ers247 } = dealer;

  const rotationActive = !isEmpty(
    get(dealer, 'activeAfterHoursContacts.contacts.primaryContact'),
  );
  const open = operatingStatus.open === OPERATING_STATUS_VALUES.OPEN;
  const closed = operatingStatus.open === OPERATING_STATUS_VALUES.CLOSED;
  const ersOpen =
    operatingStatus.ersOpen === OPERATING_STATUS_VALUES.OPEN || ers247;

  // return the expected Hours type based on 'operatingStatus' info of dealer
  // it can be one of OPERATING_STATUS_TYPE

  // If you don't pass a moment object, `now` or a timezone,
  // it will default to returning a simple status of Open, Closed, or ERS.
  // But if you provide `now` and a timezone,
  // it will return a detailed status if a dealer is opening or closing soon.

  switch (true) {
    case rotationActive:
      return evaluateERSOpenStatus(ersHours, now, timezone);
    case open:
      return evaluateOpenStatus(workingHours, now, timezone);
    case ersOpen:
      return evaluateERSOpenStatus(ersHours, now, timezone);
    case closed:
      return evaluateClosedStatus(workingHours, now, timezone);
    default:
      return OPERATING_STATUS_TYPE.Unknown;
  }
}

import { makeReactiveContainer } from 'reactive-container';

import Container, { MODIFIER_CONFIG } from './Container';

const ReactiveContainer = makeReactiveContainer()(Container);

ReactiveContainer.displayName = 'ReactiveContainer';
ReactiveContainer.modifiers = MODIFIER_CONFIG;

export default ReactiveContainer;

import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { px2rem } from 'decisiv-ui-utils';
import { QuickActionButton } from 'base-components';
import { Column, Container, Row } from 'styled-components-grid';

import RadioButton from 'components/RadioButton';
import withContext from 'utils/withContext';

import ReportConfigBuilderContext from './Context';

import {
  configTypes,
  exportTypes as allExportTypes,
  exportTypesTitles,
} from './constants';

const ReportConfigBuilderFooter = (props) => {
  const { updateReportConfig, currentRunningReport, children } = props;
  const { config, runReport, hasValidConfig, exportTypeOptions } = props;

  const isDisabled = !hasValidConfig || !!currentRunningReport;

  return (
    <Container modifiers="padScale_0" style={{ marginTop: px2rem(20) }}>
      <Row modifiers="middle">
        {children}
        {exportTypeOptions.length > 1 && (
          <Column
            style={{ marginRight: px2rem(20) }}
            modifiers={['col', 'end', 'padScale_0']}
          >
            {exportTypeOptions.map((type) => (
              <RadioButton
                id={type}
                key={type}
                label={<Trans id={exportTypesTitles[type]} />}
                value={type}
                checked={config[configTypes.exportType] === type}
                onChange={(e) =>
                  updateReportConfig(configTypes.exportType, e.target.value)
                }
                style={{ marginLeft: px2rem(20) }}
              />
            ))}
          </Column>
        )}
        <Column modifiers="padScale_0" style={{ marginLeft: 'auto' }}>
          <QuickActionButton
            disabled={isDisabled}
            modifiers={['secondary', isDisabled && 'disabled']}
            onClick={runReport}
          >
            <QuickActionButton.Text>
              <Trans>Run Report</Trans>
            </QuickActionButton.Text>
          </QuickActionButton>
        </Column>
      </Row>
    </Container>
  );
};

ReportConfigBuilderFooter.propTypes = {
  children: PropTypes.node,
  config: PropTypes.shape({
    [configTypes.exportType]: PropTypes.string,
  }).isRequired,
  currentRunningReport: PropTypes.shape({}),
  exportTypeOptions: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(allExportTypes)),
  ).isRequired,
  hasValidConfig: PropTypes.bool.isRequired,
  runReport: PropTypes.func.isRequired,
  updateReportConfig: PropTypes.func.isRequired,
};

ReportConfigBuilderFooter.defaultProps = {
  children: null,
  currentRunningReport: undefined,
};

export default compose(
  setDisplayName('ReportConfigBuilderFooter'),
  withContext(ReportConfigBuilderContext),
)(ReportConfigBuilderFooter);

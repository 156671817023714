import React from 'react';
import PropTypes from 'prop-types';

import { Badge } from 'base-components';

import { CASE_STATUS } from 'compositions/CaseStatus/constants';

import { iconForStatus, modifierForStatus } from './constants';

const iconStyles = {
  // Remove the extra bottom whitespace so that
  // the icon is properly centered vertically.
  // This should probably be done in base-components.
  lineHeight: 0,
};

const disabledStyles = { opacity: 0.3 };

function CaseStatusBadge({ status, disabled }) {
  return (
    <Badge
      style={disabled ? disabledStyles : undefined}
      modifiers={modifierForStatus[status]}
    >
      <Badge.Icon
        name={iconForStatus[status]}
        style={iconStyles}
        modifiers="chrome000"
      />
    </Badge>
  );
}

CaseStatusBadge.propTypes = {
  status: PropTypes.oneOf(Object.values(CASE_STATUS)).isRequired,
  disabled: PropTypes.bool,
};

CaseStatusBadge.defaultProps = {
  disabled: false,
};

export default CaseStatusBadge;

import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { ButtonToggle } from 'base-components';

import withContext from 'utils/withContext';

import { CaseStatusContext, CASE_STATUS } from 'compositions/CaseStatus';

import withCaseBillableToggle from './withCaseBillableToggle';

export function CaseBillableToggle({
  status,
  isBillable,
  setCaseIsBillable,
  isLoadingCaseStatus,
  isLoadingIsBillable,
  isReadOnlyCase,
}) {
  const disabled =
    isReadOnlyCase ||
    isLoadingCaseStatus ||
    isLoadingIsBillable ||
    status === CASE_STATUS.new;

  const toggle = () => setCaseIsBillable(!isBillable);

  return (
    <ButtonToggle on={isBillable} onClick={toggle} disabled={disabled}>
      <ButtonToggle.OffLabel>
        <Trans>Not Billable</Trans>
      </ButtonToggle.OffLabel>
      <ButtonToggle.OnLabel>
        <Trans>Billable</Trans>
      </ButtonToggle.OnLabel>
    </ButtonToggle>
  );
}

CaseBillableToggle.propTypes = {
  status: PropTypes.string.isRequired,
  isBillable: PropTypes.bool.isRequired,
  setCaseIsBillable: PropTypes.func.isRequired,
  isLoadingCaseStatus: PropTypes.bool.isRequired,
  isLoadingIsBillable: PropTypes.bool.isRequired,
  isReadOnlyCase: PropTypes.bool.isRequired,
};

export default compose(
  setDisplayName('CaseBillableToggle'),
  withContext(CaseStatusContext),
  withCaseBillableToggle,
)(CaseBillableToggle);

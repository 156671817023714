import React, { useContext, useMemo } from 'react';
import { compose } from 'recompose';
import { useQuery, NetworkStatus } from '@apollo/client';
import { compact, isEmpty, map } from 'lodash';

import { getUnits } from 'utils/unit';
import { latLngToString } from 'utils/geoLocation';

import withProximityFilters from '../withProximityFilters';
import { Context as DealerLocatorContext } from '../DealerLocatorContext';

import searchArea from './searchAssetAreaQuery';
import searchRoute from './searchAssetRouteQuery';

const getRoute = ({ isRouteSearch, locationSearches }) => {
  const [{ location: firstLocation }, ...otherSearches] = locationSearches;

  return isRouteSearch
    ? compact([firstLocation, ...map(otherSearches, 'location')])
    : undefined;
};

export function skipQuery(props) {
  if (props.searchEnabled === false) return true;

  const { countryCode, isRouteSearch } = props;
  const { locationSearches, proximityDistance } = props;
  const [assetLocationSearch, ...stops] = locationSearches;
  const { location: assetLocation } = assetLocationSearch;
  const { latitude, longitude } = assetLocation || {};
  const hasValidCountry = ['US', 'CA'].includes(countryCode);

  // these arguments are required for any dealer locator query:
  if (!latitude || !longitude || !hasValidCountry || !proximityDistance) {
    return true;
  }

  // searching along a route additionally requires at least one destination with a location
  // the nested if is to prevent running the extra logic on area searches.
  if (isRouteSearch) {
    const hasValidStops = stops.some(
      (s) => !!s?.location?.latitude && !!s?.location?.longitude,
    );

    if (!hasValidStops) return true;
  }

  // we have what we need, _don't_ skip the query
  return false;
}

export function buildQueryVariables(props, route) {
  const { priorityProximityDistance: priorityDistance } = props;
  const { inboundProgramNumber, selectedFeatureFilters } = props;
  const { unit, encodedPolyline, locationSearches, proximityDistance } = props;
  const [assetLocationSearch] = locationSearches;

  const inboundProgramBillTo = inboundProgramNumber?.billTo;
  const { distanceUnitValue } = getUnits(unit);
  const {
    location: assetLocation,
    searchValue,
    travelEstimateDestination,
    countryCode,
  } = assetLocationSearch;

  const features = isEmpty(selectedFeatureFilters)
    ? undefined
    : selectedFeatureFilters.map((feat) => feat.id);

  const destination =
    travelEstimateDestination || searchValue || latLngToString(assetLocation);

  return {
    route,
    features,
    distance: proximityDistance,
    countryCode,
    distanceUnit: distanceUnitValue,
    travelEstimateDestination: destination,
    assetLocation,
    encodedPolyline,
    inboundProgramBillTo,
    performanceRankingSearch: true,
    performanceRankingOrderDistance: priorityDistance,
  };
}

export const withDealers = (Component) => (props) => {
  const allProps = { ...props, ...useContext(DealerLocatorContext) };
  const { autoReloadInterval } = allProps;

  const query = allProps.isRouteSearch ? searchRoute : searchArea;
  const route = getRoute(allProps);
  const shouldSkip = skipQuery(allProps);

  // 30 seconds. https://servicesolutions.atlassian.net/browse/OQA-960;
  const pollInterval = shouldSkip ? 0 : autoReloadInterval || 30 * 1000;

  const queryProps = useQuery(query, {
    skip: shouldSkip,
    variables: buildQueryVariables(allProps, route),
    fetchPolicy: 'cache-first',
    pollInterval,
  });

  const { isRouteSearch, dealerDataTransformer } = allProps;
  const { data, error: dealersError, networkStatus } = queryProps;
  const loading = queryProps.loading && networkStatus !== NetworkStatus.poll;

  const dealers = useMemo(() => {
    let dealers = isRouteSearch
      ? data?.searchAssetRoute?.dealers
      : data?.searchAssetArea?.dealers;

    if (!!dealerDataTransformer && Array.isArray(dealers)) {
      dealers = dealers.map(dealerDataTransformer);
    }

    return dealers;
  }, [data, dealerDataTransformer, isRouteSearch]);

  return (
    <Component
      {...allProps}
      route={route}
      dealers={loading ? undefined : dealers}
      dealersError={dealersError}
      dealersPending={loading}
    />
  );
};

const withExtraData = (Component) =>
  compose(withProximityFilters, withDealers)(Component);

export default withExtraData;

import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import { Row, Column } from 'styled-components-grid';
import { P, H2, QuickActionButton } from 'base-components';

import Modal from 'components/Modal';
import CaseStatusBadge from 'elements/CaseStatusBadge/Mini';

import { CASE_STATUS } from './constants';

const { dispatched } = CASE_STATUS;

export default function FixpixPushResultModal({
  status,
  onClose,
  updateCaseStatus,
  isUpdatingCaseStatus,
}) {
  const tryAgain = () => updateCaseStatus(dispatched);

  const continueWithoutFixpix = () =>
    updateCaseStatus(dispatched, { ignoreFixpixFailure: true });

  return (
    <Modal onClose={onClose}>
      {() => (
        <Modal.Body>
          <Modal.Header modifiers="danger">
            <Modal.HeaderIcon name="send-times" />
          </Modal.Header>
          <Modal.Content>
            <Row modifiers="center">
              <Column>
                <H2 modifiers="fontWeightRegular">
                  <Trans>Delivery to FIXPIX Failed</Trans>
                </H2>
              </Column>
            </Row>
            <Row modifiers="center">
              <Column modifiers="col">
                <P>
                  <Trans>
                    You can try again or continue the case without FIXPIX.
                    Clicking CONTINUE WITHOUT FIXPIX will change the case from
                    <CaseStatusBadge
                      modifiers="inline"
                      status={status}
                    /> to{' '}
                    <CaseStatusBadge modifiers="inline" status={dispatched} />.
                    You&apos;ll need to notify the service provider and update
                    the case manually going forward.
                  </Trans>
                </P>
              </Column>
            </Row>
          </Modal.Content>
          <Modal.Footer>
            <Row>
              <Column>
                <QuickActionButton onClick={onClose} type="button">
                  <QuickActionButton.Text>
                    <Trans>Cancel</Trans>
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
              <Column modifiers={['col', 'end']}>
                <QuickActionButton
                  type="button"
                  onClick={continueWithoutFixpix}
                  disabled={isUpdatingCaseStatus}
                  modifiers={isUpdatingCaseStatus && 'disabled'}
                >
                  <QuickActionButton.Text>
                    <Trans>Continue Without FIXPIX</Trans>
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
              <Column>
                <QuickActionButton
                  type="button"
                  onClick={tryAgain}
                  disabled={isUpdatingCaseStatus}
                  modifiers={['secondary', isUpdatingCaseStatus && 'disabled']}
                >
                  <QuickActionButton.Text>
                    <Trans>Try Again</Trans>
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
            </Row>
          </Modal.Footer>
        </Modal.Body>
      )}
    </Modal>
  );
}

FixpixPushResultModal.propTypes = {
  status: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  updateCaseStatus: PropTypes.func.isRequired,
  isUpdatingCaseStatus: PropTypes.bool.isRequired,
};

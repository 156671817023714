import { useQuery } from '@apollo/client';
import { useContext, useEffect, useMemo } from 'react';
import { get, pick, reduce, isFunction, map } from 'lodash';

import buildQueryForField from './tireOptionsQuery';
import { buildTireOptions } from '../../../CaseRequestsPanelContext/utils';
import { seeNotesRequestValue } from '../constants';
import { Context as CaseRequestsPanelContext } from '../../../CaseRequestsPanelContext';

const filterTypes = {
  axleType: 'axleType',
  tireSize: 'tireSizeId',
  productType: 'productTypeId',
  manufacturerFullName: 'manufacturerId',
  sculptureTreadName: 'sculptureTreadId',
};

const selectTypes = {
  axleType: 'AXLE_TYPE',
  tireSize: 'TIRE_SIZE',
  loadRange: 'LOAD_RANGE',
  productType: 'PRODUCT_TYPE',
  tirePosition: 'POSITION',
  sculptureTreadName: 'SCULPTURE_TREAD',
  manufacturerFullName: 'MANUFACTURER',
};

const getCellConfig = (name, props) => {
  const { columnsConfig } = props;

  const columnConfig = columnsConfig.find((c) => c.name === name) || {};
  const runTimeConfig = isFunction(columnConfig.getRuntimeConfig)
    ? columnConfig.getRuntimeConfig({ ...props, name })
    : {};

  return { ...columnConfig, ...runTimeConfig };
};

const hasDepsArray = (name, props) =>
  Array.isArray(getCellConfig(name, props).dependsOn);

const getOptionId = ({ type, value, rowData, tireOptionsByType }) => {
  const lineId = get(rowData, 'id');
  const options = get(tireOptionsByType, `${lineId}.${type}`, []);
  const option = options.find((o) => [o.id, o.title].includes(value));
  const upstreamId = get(option, 'upstreamId');

  return upstreamId !== undefined ? upstreamId : get(option, 'id');
};

const allDependenciesHaveLoadedOptions = (props) => {
  const { name, rowData, tireOptionsByType: options } = props;
  const data = pick(rowData, getCellConfig(name, props).dependsOn);

  return Object.keys(data)
    .filter((key) => hasDepsArray(key, props))
    .every((key) => get(options, `${get(rowData, 'id')}.${key}.length`));
};

export const shouldSkipQuery = (props, { filters = {} } = {}) => {
  const deps = getCellConfig(props.name, props).dependsOn || [];

  const hasMissingDeps = deps.some((name) => {
    const filterKey = filterTypes[name];

    return !filterKey ? false : filters[filterKey] === undefined;
  });

  return hasMissingDeps || !allDependenciesHaveLoadedOptions(props);
};

const areOptionsEqual = (lhs, rhs) => {
  if (lhs.length !== rhs.length) return false;

  const [lhsHash, rhsHash] = [lhs, rhs].map((options) =>
    map(options, 'upstreamId').join('-').trim(),
  );

  return lhsHash === rhsHash;
};

export const buildVariables = (props) => {
  const { name, rowData, tireOptionsByType } = props;
  const deps = getCellConfig(name, props).dependsOn;

  const filters = reduce(
    pick(rowData, deps),
    (acc, value, type) => {
      if (!filterTypes[type]) return acc;

      const id = hasDepsArray(type, props)
        ? getOptionId({ type, value, rowData, tireOptionsByType })
        : value;

      return { ...acc, [filterTypes[type]]: id };
    },
    {},
  );

  return {
    select: selectTypes[name],
    filters: { ...filters, assetId: get(rowData, 'asset.id') },
  };
};

const useTireOptions = (props) => {
  const { name, rowData, readOnly } = props;

  const ctx = useContext(CaseRequestsPanelContext);
  const rowId = get(rowData, 'id');
  const rowValue = get(rowData, name);
  const variables = buildVariables({ ...props, ...ctx });
  const shouldSkip = shouldSkipQuery({ ...props, ...ctx }, variables);
  const currentOptions = get(ctx.tireOptionsByType, [rowId, name], []);

  const { data: { tiresOptions } = {}, loading } = useQuery(
    buildQueryForField(name),
    { skip: shouldSkip, variables, fetchPolicy: 'cache-first' },
  );

  const newOptions = useMemo(
    () => buildTireOptions(name, get(tiresOptions, 'options') || []),
    [name, tiresOptions],
  );

  const { addTireOptionsOfType } = ctx;

  useEffect(() => {
    if (areOptionsEqual(currentOptions, newOptions)) return;

    addTireOptionsOfType(name, newOptions, rowId);
  }, [name, rowId, addTireOptionsOfType, currentOptions, newOptions]);

  const isSeeNotes = rowValue === seeNotesRequestValue;

  const isLoading =
    isSeeNotes || (readOnly && allDependenciesHaveLoadedOptions(props))
      ? false
      : !!loading;

  return { options: newOptions, isLoading };
};

export default useTireOptions;

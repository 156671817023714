import React from 'react';
import { Trans } from '@lingui/macro';

import { Column, Row } from 'styled-components-grid';
import { Badge, Text } from 'base-components';

function NationalNoCreditCard() {
  return (
    <Row modifiers={['middle', 'padScaleY_2']}>
      <Column>
        <Badge modifiers="success">
          <Badge.Icon name="service-provider" />
        </Badge>
      </Column>
      <Column>
        <Text>
          <Trans>National Account</Trans>
        </Text>
      </Column>
    </Row>
  );
}

export default React.memo(NationalNoCreditCard);

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const styles = ({ theme }) => `
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 0 0 ${px2rem(5)};

  &:hover, &:focus {
    span.fa {
      color: ${theme.colors.status.danger};
    }
  }
`;

const themePropTypes = {
  colors: PropTypes.shape({
    status: PropTypes.shape({
      danger: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const propTypes = {
  onClick: PropTypes.func.isRequired,
};

const CloseButton = buildStyledComponent('CloseButton', styled.button, styles, {
  themePropTypes,
  propTypes,
});

export default CloseButton;

import React from 'react';
import { Query } from '@apollo/client/react/components';
import { curry, get } from 'lodash';

import caseEmailHistory from './caseEmailHistoryQuery';

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const edges = get(queryProps, 'data.case.emailHistory.edges', []);
    const nodes = edges.map(({ node }) => node);
    const loading = get(queryProps, 'loading');

    return (
      <WrappedComponent
        {...componentProps}
        notifications={nodes}
        isLoadingNotifications={!!loading}
      />
    );
  },
);

const withCaseNotificationHistory = (WrappedComponent) => (componentProps) => (
  <Query
    skip={!componentProps.caseNumber}
    query={caseEmailHistory}
    variables={{ caseNumber: componentProps.caseNumber }}
  >
    {buildQueryChild(WrappedComponent, componentProps)}
  </Query>
);

export default withCaseNotificationHistory;

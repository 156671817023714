import gql from 'graphql-tag';

export const NAME = 'casePaymentPanel_caseCustomerQuery';

const query = gql`
  query casePaymentPanel_caseCustomerQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      customer(useCachedDataForCase: true) {
        ... on Customer {
          id
          type
          name
          billingPreferences {
            creditCardRuleAbbreviation
          }
        }
        ... on CustomCustomer {
          type
          name
        }
        ... on CustomStoreCustomer {
          type
          name
          accountNumber
        }
        ... on StoreCustomer {
          id
          type
          name
          accountNumber
        }
      }
      paymentMethod
    }
  }
`;

export default query;

import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';

import { getUnits } from 'utils/unit';
import { unitSelector } from 'redux/preferences/selectors';

import { NAME as CASE_DELAYS_QUERY_NAME } from 'compositions/CaseDetailOverviewPanel/withCaseDelays/caseDelaysQuery';
import { NAME as CASE_NOTIFICATIONS_QUERY_NAME } from 'compositions/CaseActivityPanel/NotificationHistory/withCaseNotificationHistory/caseEmailHistoryQuery';
import { CASE_DEALER_RESPONSES_QUERY_NAME } from 'compositions/CaseDealerSelectionPanel/withCaseDealerResponses';
import { NAME as CASE_REQUEST_LINES_QUERY_NAME } from 'compositions/CaseRequestsPanel/CaseRequestsPanelContext/withCaseRequestLines';
import { NAME as CASE_DATA_QUERY_NAME } from '../withCaseData/caseDataQuery';
import { QUERY_NAME as CONTACT_CALLBACK_STATE } from 'compositions/CaseContacts/ContactDetails/ContactCallback/useAutomatedCallState';
import mutation from './updateCaseStatusMutation';

const refetchQueries = [
  CASE_DATA_QUERY_NAME,
  CASE_DELAYS_QUERY_NAME,
  CONTACT_CALLBACK_STATE,
  CASE_NOTIFICATIONS_QUERY_NAME,
  CASE_REQUEST_LINES_QUERY_NAME,
  CASE_DEALER_RESPONSES_QUERY_NAME,
];

// eslint-disable-next-line react/prop-types
const ComponentWithUpdateCaseStatus = ({ Component, ...rest }) => {
  const unit = useSelector(unitSelector);
  const [mutate, { loading }] = useMutation(mutation, { refetchQueries });

  const updateCase = useCallback(
    ({ variables = {}, ...other }) => {
      const distanceUnit = getUnits(unit).distanceUnitValue;

      return mutate({ ...other, variables: { ...variables, distanceUnit } });
    },
    [mutate, unit],
  );

  return (
    <Component
      {...rest}
      updateCaseStatus={updateCase}
      isUpdatingCaseStatus={!!loading}
    />
  );
};

const withUpdateCaseStatus = (Component) => (props) => (
  <ComponentWithUpdateCaseStatus {...props} Component={Component} />
);
export default withUpdateCaseStatus;

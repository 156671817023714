import styled from 'styled-components';
import PropTypes from 'prop-types';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';
import BaseIcon from 'decisiv-iconix-react';

const ICONS = {
  danger: 'exclamation-triangle',
  info: 'info-circle',
  success: 'check',
  warning: 'exclamation-circle',
};

const styles = () => `
  margin-right: ${px2rem(5)};
`;

const propTypes = {
  type: PropTypes.oneOf(['danger', 'info', 'success', 'warning']).isRequired,
};

const Icon = buildStyledComponent(
  'Icon',
  styled(BaseIcon).attrs(({ type }) => ({
    name: ICONS[type],
    modifiers: [type],
  })),
  styles,
  { propTypes },
);

export default Icon;

import gql from 'graphql-tag';
import { get } from 'lodash';
import React from 'react';
import { Query } from '@apollo/client/react/components';

const query = gql`
  query dealerLocator_allInboundProgramsQuery {
    inboundProgramNumbers {
      inboundPrograms {
        id
        billTo
        tollFreeNumber
      }
    }
  }
`;

const withFilteredInboundPrograms = (WrappedComponent) => (componentProps) => (
  <Query query={query}>
    {({ data }) => (
      <WrappedComponent
        {...componentProps}
        inboundPrograms={get(data, 'inboundProgramNumbers.inboundPrograms')}
      />
    )}
  </Query>
);

export default withFilteredInboundPrograms;

import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName, withState } from 'recompose';

import { Column, Row } from 'styled-components-grid';
import { P, H2, Text, InputGroup, QuickActionButton } from 'base-components';

import Modal from 'components/Modal';

import AfterHoursContactSelector from '../RotationEditModal/AfterHoursContactSelector';
import withOverrideRotationContacts from './withOverrideRotationContacts';
import { EventPropType } from '../../propTypes';
import LoadingMessage from '../../LoadingMessage';

const Emphasis = <Text modifiers="fontWeightMedium" />;

function RotationOverrideContactsModal(props) {
  const { event, saveEvent, isSavingEvent } = props;
  const { onClose, selection, setSelection, dealerTimezone } = props;

  const save = () =>
    saveEvent(event, selection)
      .then(onClose)
      .catch((e) => console.error(e)); // eslint-disable-line no-console

  const select = (type, id) => setSelection({ ...selection, [type]: id });
  const canSave =
    !isSavingEvent && selection.primaryContactId && selection.finalContactId;

  const startDate = moment
    .tz(event.start, dealerTimezone)
    .format('DD MMM, h:mm A z');

  const endDate = moment
    .tz(event.end, dealerTimezone)
    .format('DD MMM, h:mm A z');

  return (
    <Modal onClose={onClose}>
      {() => (
        <Modal.Body>
          <Modal.Header>
            <Modal.HeaderIcon name="users" />
          </Modal.Header>
          <Modal.Content>
            <Row modifiers="center">
              <Column>
                <H2 modifiers="fontWeightRegular">
                  <Trans>Override Active Rotation Contacts</Trans>
                </H2>
              </Column>
            </Row>
            <Row modifiers="center" style={{ margin: '10px 40px 20px' }}>
              <Column modifiers="col">
                <P>
                  <Trans
                    id="This rotation (<0>{startDate}</0> - <1>{endDate}</1>) is active and therefore you can only override contacts for this period. Please ensure that the change made is correct for the whole of the this rotation."
                    values={{ startDate, endDate }}
                    components={[Emphasis, Emphasis]}
                  />
                </P>
                <br />
                <P>
                  <Trans>
                    If you want to change this rotation for future needs you
                    must complete the full rotation update.
                  </Trans>
                </P>
              </Column>
            </Row>
            <Row modifiers="padScaleY_1">
              <Column modifiers="col">
                <InputGroup.Row>
                  <InputGroup.Column modifiers={['col']}>
                    <AfterHoursContactSelector
                      label={<Trans>Primary Contact</Trans>}
                      dealerId={event.dealerId}
                      selected={selection.primaryContactId}
                      onChange={(_, id) => select('primaryContactId', id)}
                    />
                  </InputGroup.Column>
                </InputGroup.Row>
                <InputGroup.Row>
                  <InputGroup.Column modifiers={['col']}>
                    <AfterHoursContactSelector
                      label={<Trans>Backup Contact (Optional)</Trans>}
                      dealerId={event.dealerId}
                      selected={selection.backupContactId}
                      onChange={(_, id) => select('backupContactId', id)}
                    />
                  </InputGroup.Column>
                </InputGroup.Row>
                <InputGroup.Row>
                  <InputGroup.Column modifiers={['col']}>
                    <AfterHoursContactSelector
                      label={<Trans>Final Contact</Trans>}
                      dealerId={event.dealerId}
                      selected={selection.finalContactId}
                      onChange={(_, id) => select('finalContactId', id)}
                    />
                  </InputGroup.Column>
                </InputGroup.Row>
              </Column>
            </Row>
          </Modal.Content>
          <Modal.Footer>
            <Row modifiers="end">
              {isSavingEvent && (
                <LoadingMessage
                  message={<Trans>Saving, please wait...</Trans>}
                />
              )}
              <Column>
                <QuickActionButton onClick={onClose}>
                  <QuickActionButton.Text>
                    <Trans>Cancel</Trans>
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
              <Column>
                <QuickActionButton
                  onClick={save}
                  disabled={!canSave}
                  modifiers={['secondary', !canSave && 'disabled']}
                >
                  <QuickActionButton.Text>
                    <Trans>Override Contacts</Trans>
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
            </Row>
          </Modal.Footer>
        </Modal.Body>
      )}
    </Modal>
  );
}

RotationOverrideContactsModal.propTypes = {
  // eslint-disable-next-line react/no-typos
  event: EventPropType.isRequired,
  onClose: PropTypes.func.isRequired,
  saveEvent: PropTypes.func.isRequired,
  selection: PropTypes.shape({
    primaryContactId: PropTypes.string,
    backupContactId: PropTypes.string,
    finalContactId: PropTypes.string,
  }).isRequired,
  setSelection: PropTypes.func.isRequired,
  isSavingEvent: PropTypes.bool.isRequired,
  dealerTimezone: PropTypes.string.isRequired,
};

export default compose(
  setDisplayName('RotationOverrideContactsModal'),
  withOverrideRotationContacts,
  withState('selection', 'setSelection', ({ event }) => ({
    primaryContactId: get(event, 'primaryContact.id'),
    backupContactId: get(event, 'backupContact.id'),
    finalContactId: get(event, 'finalContact.id'),
  })),
)(RotationOverrideContactsModal);

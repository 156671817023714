import styled, { css } from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

import {
  styles as baseStyles,
  themePropTypes,
  modifierConfig as baseModifierConfig,
} from '../TextField';

const styles = css`
  ${baseStyles};
  resize: none;
`;

const modifierConfig = {
  ...baseModifierConfig,
  resizable: () => `
    resize: vertical;
  `,
};

export default buildStyledComponent(
  'TextArea',
  styled.textarea.attrs((props) => ({ rows: props.rows || 2 })),
  styles,
  { modifierConfig, themePropTypes },
);

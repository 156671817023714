import React from 'react';
import PropTypes from 'prop-types';

import { Column } from 'styled-components-grid';

import { Label as DefaultLabel } from '../../elements';
import { modifiersForComponent } from '../../utils/modifiers';

function Label({ modifiers, ...rest }) {
  const columnModifiers = [
    'col',
    'padScaleY_0',
    ...modifiersForComponent(modifiers, Column),
  ];
  const labelModifiers = modifiersForComponent(modifiers, Label);

  return (
    <Column modifiers={columnModifiers}>
      <DefaultLabel modifiers={labelModifiers} {...rest} />
    </Column>
  );
}

Label.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

Label.defaultProps = {
  modifiers: [],
};

export default Label;

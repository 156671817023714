import moment from 'moment-timezone';
import { get } from 'lodash';
import { t, defineMessage } from '@lingui/macro';
import { i18n } from '@lingui/core';

import { weekdays } from './constants';

export const isRecurringRotation = (rotation) => {
  const recurringStart = moment(get(rotation, 'options.recurringStartDate'));
  const recurringEnd = moment(get(rotation, 'options.recurringEndDate'));

  return rotation.isRecurring && !recurringStart.isSame(recurringEnd);
};

export const getRecurringDates = (rotation) => {
  if (!rotation.isRecurring) return {};

  const { recurringStartDate, recurringEndDate } = get(rotation, 'options', {});

  const recurringStart = moment(recurringStartDate);
  const recurringEnd = recurringEndDate
    ? moment(recurringEndDate)
    : moment(recurringStart).add(1, 'years');

  return {
    recurringStartDate: recurringStart.format('D MMM YYYY'),
    recurringEndDate: recurringEnd.format('D MMM YYYY'),
  };
};

export const daysOfWeekValueTransformer = (daysOfWeek) =>
  daysOfWeek
    ? daysOfWeek.map((dayOfWeek) => weekdays[dayOfWeek]).join(', ')
    : '';

export const dateValueTransformer = (value, { timezone }) =>
  value ? moment.tz(value, timezone).format('MMM D YYYY') : '';

const timeValueTransformer = (value, { timezone }) =>
  value &&
  moment
    .tz(`${moment().format('YYYY-MM-DD')} ${value}`, timezone)
    .format('h:mm A z');

const rowLabelsByField = {
  startTime: t`Start Hour`,
  endTime: t`End Hour`,
  isRecurring: t`Is Recurring?`,
  recurring: t`Recurring Days`,
  options: t`Recurrence`,
  primaryContact: t`Primary Contact`,
  backupContact: t`Backup Contact`,
  finalContact: t`Final Contact`,
};

const rowValuesByField = {
  startTime: timeValueTransformer,
  endTime: timeValueTransformer,
  primaryContact: (value) => get(value, 'name'),
  backupContact: (value) => get(value, 'name'),
  finalContact: (value) => get(value, 'name'),

  isRecurring: (value) => ({ true: t`Yes`, false: t`No` }[value]),
  options: (_, { event }) =>
    event.isRecurring &&
    i18n._(
      defineMessage({ message: '{recurringStartDate} to {recurringEndDate}' }),
      getRecurringDates(event),
    ),
  recurring: (value) =>
    daysOfWeekValueTransformer(value.map(({ dayOfWeek }) => dayOfWeek)),
};

const rowFiltersByField = {
  startDate: () => false,
  endDate: () => false,
  options: (_, { event }) => event.isRecurring,
  recurring: (_, { event }) => event.isRecurring,
};

const getDisplayValue = (key, data, options) => {
  const transformer = rowValuesByField[key];

  return transformer(data, options) || t`None`;
};

export const buildOverrideRows = (eventProps) => {
  const { event, originalEvent } = eventProps;
  return (
    Object.keys(rowLabelsByField)
      .map((key) => ({
        key,
        label: rowLabelsByField[key],
        previousValue: getDisplayValue(key, originalEvent[key], {
          ...eventProps,
          event: originalEvent,
        }),
        newValue: getDisplayValue(key, event[key], eventProps),
      }))
      // filter out fields that hasn't changed
      .filter(({ previousValue, newValue }) => previousValue !== newValue)
      .filter((rowProps) => {
        const filter = rowFiltersByField[rowProps.key];
        return filter ? filter(rowProps, eventProps) : true;
      })
  );
};

import React from 'react';
import gql from 'graphql-tag';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Query } from '@apollo/client/react/components';
import { t, Trans, Plural } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { Icon, Link, Text } from 'base-components';

const StyledLink = styled(Link)`
  padding: 5px 10px;
  display: inline-block;
  background: #ff5100;
  border-radius: 20px;
  text-transform: none;

  &:hover {
    text-decoration: none;
  }

  ${Text} {
    color: white;
  }
`;

const highlightedCasesCountQuery = gql`
  query highlightedCasesCountQuery {
    highlightedCasesCount {
      count
    }
  }
`;

const withHighlightedCasesCount = (WrappedComponent) => (props) => (
  <Query query={highlightedCasesCountQuery} fetchPolicy="no-cache">
    {({ data }) => (
      <WrappedComponent
        {...props}
        total={get(data, 'highlightedCasesCount.count') || 0}
      />
    )}
  </Query>
);

function HighlightedCasesLink({ total }) {
  if (!total) return null;

  return (
    <StyledLink
      to="/cases/highlighted"
      aria-label={t`View cases needing validation`}
    >
      <Icon name="exclamation-triangle" modifiers="chrome000" />
      &nbsp;&nbsp;
      <Text>
        <Plural
          value={total}
          one="# case needing validation"
          other="# cases needing validation."
        />
      </Text>
      &nbsp;
      <Text style={{ textDecoration: 'underline' }}>
        <Trans>View</Trans>
      </Text>
    </StyledLink>
  );
}

HighlightedCasesLink.propTypes = {
  total: PropTypes.number.isRequired,
};

export default compose(
  setDisplayName('HighlightedCasesLink'),
  withHighlightedCasesCount,
)(HighlightedCasesLink);

import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { withTheme } from 'styled-components';
import { compose, setDisplayName } from 'recompose';

import { Text } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import { modifierToColors } from 'features/amazonConnect';

/**
 * Popover body for the top-nav softphone when the softphone is
 * uninitialized or completely disabled.
 *
 * Exported only for testing.
 */
export function DisabledSoftPhone({ theme }) {
  return (
    <Container style={{ backgroundColor: modifierToColors('offline').dark }}>
      <Column modifiers={['col', 'padScale_0']}>
        <Row modifiers={['center', 'flex_1', 'middle']}>
          <Column modifiers={['flex_1', 'padScaleX_5', 'padScaleY_3']}>
            <Text style={{ color: theme.colors.base.chrome000 }}>
              <Trans>Phone Disabled</Trans>
            </Text>
          </Column>
        </Row>
      </Column>
    </Container>
  );
}

DisabledSoftPhone.propTypes = {
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      base: PropTypes.shape({
        chrome000: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default compose(
  setDisplayName('DisabledSoftPhone'),
  withTheme,
)(DisabledSoftPhone);

import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rgba } from 'polished';

import { px2rem, buildStyledComponent } from 'decisiv-ui-utils';

export default buildStyledComponent(
  'ValueCell',
  styled.div,
  `padding: ${px2rem(4)} ${px2rem(8)};`,
  {
    modifierConfig: {
      modified: ({ theme }) => `
        background-color: ${theme.colors.base.selectableActive};
      `,
      removed: ({ theme }) => `
        background-color: ${rgba(theme.colors.status.danger, 0.05)};
      `,
    },
    themePropTypes: PropTypes.shape({
      colors: PropTypes.shape({
        base: PropTypes.shape({
          selectableActive: PropTypes.string.isRequired,
        }).isRequired,
        status: PropTypes.shape({
          danger: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  },
);

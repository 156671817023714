import PropTypes from 'prop-types';
import { t } from '@lingui/macro';

import buildEnum from 'utils/buildEnum';
import { withState } from 'recompose';

export const sortDirections = buildEnum(['desc', 'asc']);

export const sortDirectionsLabels = {
  [sortDirections.desc]: t`Newest First`,
  [sortDirections.asc]: t`Oldest First`,
};

export const sortDirectionPropType = PropTypes.oneOf(
  Object.values(sortDirections),
);

export const withSortDirection = (order = sortDirections.desc) =>
  withState('sortDirection', 'onSelectDirection', order);

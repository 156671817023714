import React from 'react';
import PropTypes from 'prop-types';

import PopoverBlock from '../../blocks/Popover';

import { POPOVER_CONTEXT } from './constants';

function Content({ children, modifiers, ...rest }, context) {
  const {
    arrow,
    arrowColor,
    handleHover,
    handleLeave,
    position,
    removeOnHide,
    showPopover,
    zIndex,
  } = context[POPOVER_CONTEXT];

  if (!showPopover && removeOnHide) return null;

  const finalModifiers = (Array.isArray(modifiers)
    ? [...modifiers, position]
    : [modifiers, position]
  ).filter(Boolean);

  return (
    <PopoverBlock.Content
      hideContent={!showPopover}
      modifiers={finalModifiers}
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
      zIndex={zIndex}
      {...rest}
    >
      {children}
      {arrow && (
        <PopoverBlock.Arrow arrowColor={arrowColor} modifiers={position} />
      )}
    </PopoverBlock.Content>
  );
}

Content.contextTypes = {
  [POPOVER_CONTEXT]: PropTypes.shape({}).isRequired,
};

Content.propTypes = {
  children: PropTypes.node.isRequired,
  modifiers: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

export default Content;

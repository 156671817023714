import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';
import { groupBy, isEmpty, orderBy, get } from 'lodash';

import { px2rem } from 'decisiv-ui-utils';
import { Container, Column, Row } from 'styled-components-grid';
import {
  Dropdown,
  InputField,
  InputGroup,
  MessageMedium,
  MessageSmall,
  Popover,
  Text,
} from 'base-components';

import withFocusReceiver from 'setup/FocusProvider/withFocusReceiver';

import { withReadOnlyCase } from 'compositions/CaseStatus';

import withFilteredInboundProgramLocations from '../withFilteredInboundProgramLocations';
import withInboundProgramLocationFromCall from '../withInboundProgramLocationFromCall';
import withInboundProgramLocationSelect from '../withInboundProgramLocationSelect';
import InboundProgramLocationDetails from './InboundProgramLocationDetails';
import InputIcon from './InputIcon';
import { fieldIds } from '../constants';
import { getInboundProgramLocationDisplayValue } from '../utils';

const locationsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    billTo: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    shipTo: PropTypes.string.isRequired,
    state: PropTypes.string,
  }),
);

const getCustomerNameFirstChar = (program) => get(program, 'name.0');

export function sortAndGroupLocations(locations) {
  const sortedLocations = orderBy(locations, ['name'], 'asc');
  const groupedLocations = groupBy(sortedLocations, getCustomerNameFirstChar);

  return Object.entries(groupedLocations).map(([name, customers]) => ({
    name,
    customers,
  }));
}

export class InboundProgramLocationSelector extends PureComponent {
  static propTypes = {
    displayInboundProgramLocationSelector: PropTypes.bool.isRequired,
    inboundProgramLocationSearchValue: PropTypes.string,
    inboundProgramLocations: locationsPropType.isRequired,
    allInboundProgramLocations: locationsPropType.isRequired,
    isReadOnlyCase: PropTypes.bool.isRequired,
    onSelectInboundProgramLocation: PropTypes.func.isRequired,
    onChangeInboundProgramLocationSearchValue: PropTypes.func.isRequired,
    clearSelectedInboundProgramLocation: PropTypes.func.isRequired,
    selectedInboundProgramLocation: PropTypes.shape({
      id: PropTypes.string,
      shipTo: PropTypes.string,
    }),
    onFocusRequested: PropTypes.func.isRequired,
  };

  static defaultProps = {
    inboundProgramLocationSearchValue: '',
    selectedInboundProgramLocation: null,
  };

  state = { isInvalid: false };

  componentWillUnmount() {
    clearTimeout(this.resetDisplayValueTimeout);
  }

  handleDropdownSelect = (_, inboundProgramShipTo) => {
    if (!inboundProgramShipTo) return;

    clearTimeout(this.resetDisplayValueTimeout);

    this.setState({ isInvalid: false });
    this.props.onSelectInboundProgramLocation(inboundProgramShipTo);
  };

  handleSearchValueChange = ({ target: { value } }) =>
    this.props.onChangeInboundProgramLocationSearchValue(value);

  handleResetButtonClick = () => {
    this.setState({ isInvalid: false });
    this.props.clearSelectedInboundProgramLocation();
  };

  validate = () => {
    const isInvalid =
      this.props.inboundProgramLocationSearchValue &&
      !this.props.selectedInboundProgramLocation;

    if (isInvalid !== this.state.isInvalid) this.setState({ isInvalid });

    this.resetDisplayValueTimeout = setTimeout(
      this.autoResetDisplayValueTimeout,
      100,
    );
  };

  autoResetDisplayValueTimeout = () => {
    const { allInboundProgramLocations } = this.props;
    const { selectedInboundProgramLocation } = this.props;
    const { onChangeInboundProgramLocationSearchValue } = this.props;
    const { inboundProgramLocationSearchValue: searchValue } = this.props;

    if (!!selectedInboundProgramLocation) {
      const displayValue = getInboundProgramLocationDisplayValue(
        selectedInboundProgramLocation.shipTo,
        allInboundProgramLocations,
      );

      if (searchValue !== displayValue) {
        !searchValue
          ? this.handleResetButtonClick()
          : onChangeInboundProgramLocationSearchValue(displayValue);
      }
    }
  };

  renderErrorMessage = () => (
    <Container
      style={{ whiteSpace: 'normal', minWidth: px2rem(300) }}
      className="no-results"
    >
      <MessageMedium>
        <MessageMedium.Header>
          <MessageMedium.Icon name="search" />
        </MessageMedium.Header>
        <MessageMedium.Section>
          <MessageMedium.Title>
            <Trans>No Matches Found</Trans>
          </MessageMedium.Title>
        </MessageMedium.Section>
        <MessageMedium.Section>
          <MessageMedium.Text>
            <Trans>
              Try removing or editing some of your criteria or search for
              different words
            </Trans>
          </MessageMedium.Text>
        </MessageMedium.Section>
      </MessageMedium>
    </Container>
  );

  renderLocations = () => {
    const { inboundProgramLocationSearchValue } = this.props;
    const groupedLocations = sortAndGroupLocations(
      this.props.inboundProgramLocations,
    );

    if (isEmpty(groupedLocations)) {
      return this.renderErrorMessage();
    }

    return groupedLocations.map((group) => (
      <Fragment key={group.name}>
        <Dropdown.SectionHeader>
          <Text modifiers={['small', 'noWrap', 'capitalize', 'textLight']}>
            {group.name}
          </Text>
        </Dropdown.SectionHeader>
        <Dropdown.List>
          {group.customers.map((location) => (
            <Dropdown.ListItem
              key={location.shipTo}
              id={location.shipTo}
              type="submit"
            >
              <InboundProgramLocationDetails
                location={location}
                highlightText={inboundProgramLocationSearchValue}
              />
            </Dropdown.ListItem>
          ))}
        </Dropdown.List>
      </Fragment>
    ));
  };

  renderPopoverContent = (show, toggle, isVisible) => {
    const { inboundProgramLocationSearchValue, isReadOnlyCase } = this.props;

    return (
      <InputField
        isValid={!this.state.isInvalid}
        onBlur={this.validate}
        onFocus={show}
        onChange={this.handleSearchValueChange}
        placeholder={t`Search store location...`}
        readOnly={isReadOnlyCase}
        value={inboundProgramLocationSearchValue}
      >
        {this.renderAvatar()}
        <Column modifiers={['col', 'padScale_0']}>
          <Row>
            <InputField.Label>
              <Trans>Store</Trans>
            </InputField.Label>
          </Row>
          <Row>
            <InputField.TextField ref={this.props.onFocusRequested} />
            {inboundProgramLocationSearchValue && (
              <InputField.ActionButton
                icon="times"
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  this.handleResetButtonClick();
                }}
                modifiers={['padScaleX_0', 'hoverDanger']}
              />
            )}
            <InputField.ActionButton
              icon={isVisible ? 'chevron-up' : 'chevron-down'}
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                toggle();
              }}
              modifiers={['padScaleX_1', 'hoverInfo']}
            />
          </Row>
        </Column>
      </InputField>
    );
  };

  renderAvatar = () => {
    const { selectedInboundProgramLocation } = this.props;

    return (
      <Column>
        {selectedInboundProgramLocation ? (
          <InputIcon name="map-pin" modifiers="info" />
        ) : (
          <InputIcon name="map-pin" />
        )}
      </Column>
    );
  };

  render() {
    if (!this.props.displayInboundProgramLocationSelector) {
      return null;
    }

    const { isReadOnlyCase, selectedInboundProgramLocation } = this.props;
    const hasPopover = !!selectedInboundProgramLocation;

    return (
      <InputGroup.Row>
        <InputGroup.Column modifiers={['col']}>
          <Dropdown
            fullWidth
            hideOnChange
            activeItem={get(selectedInboundProgramLocation, 'shipTo')}
            onChange={this.handleDropdownSelect}
            zIndex={2}
            readOnly={isReadOnlyCase}
          >
            {({ show, toggle, isVisible }) => (
              <>
                <Container modifiers="padScale_0" onClick={show}>
                  {hasPopover ? (
                    <Popover
                      arrow
                      showOnHover
                      position="right"
                      style={{ flex: 1 }}
                    >
                      <Popover.Target style={{ flex: 1 }}>
                        {this.renderPopoverContent(show, toggle, isVisible)}
                      </Popover.Target>
                      <Popover.Content
                        style={{
                          padding: `${px2rem(10)} ${px2rem(14)}`,
                          width: px2rem(300),
                          zIndex: 2,
                        }}
                      >
                        <InboundProgramLocationDetails
                          location={selectedInboundProgramLocation}
                          showAvatar={false}
                        />
                      </Popover.Content>
                    </Popover>
                  ) : (
                    this.renderPopoverContent(show, toggle, isVisible)
                  )}
                </Container>
                <Dropdown.Content>{this.renderLocations()}</Dropdown.Content>
              </>
            )}
          </Dropdown>
          {this.state.isInvalid && (
            <Row>
              <Column modifiers={['col', 'padScaleY_3']}>
                <MessageSmall type="warning">
                  <Trans>Select an existing store.</Trans>
                </MessageSmall>
              </Column>
            </Row>
          )}
        </InputGroup.Column>
      </InputGroup.Row>
    );
  }
}

export default compose(
  setDisplayName('InboundProgramLocationSelector'),
  withFilteredInboundProgramLocations,
  withInboundProgramLocationSelect,
  /**
   * Order matters here. `withInboundProgramFromCall` requires props
   * supplied by the other inbound program HOCs.
   */
  withInboundProgramLocationFromCall,
  withReadOnlyCase,
  withFocusReceiver(fieldIds.location),
)(InboundProgramLocationSelector);

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import {
  fontWeights as modifierConfig,
  fontWeightPropTypes,
} from '../../utils/modifiers';

import { HEADER_LINE_HEIGHT } from '../../utils/constants';
import em2rem from '../../utils/em2rem';

const DEFAULT_FONT_SIZE = 14;

const styles = ({ theme }) => `
  color: ${theme.colors.base.text};
  font-size: ${px2rem(DEFAULT_FONT_SIZE)};
  line-height: ${em2rem(DEFAULT_FONT_SIZE, HEADER_LINE_HEIGHT)};
  margin: 0;
`;

const customThemePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      text: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const themePropTypes = {
  ...fontWeightPropTypes,
  ...customThemePropTypes,
};

export default buildStyledComponent('H4', styled.h4, styles, {
  modifierConfig,
  themePropTypes,
});

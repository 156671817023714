import React from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { px2rem } from 'decisiv-ui-utils';
import { Column, Row } from 'styled-components-grid';
import { ButtonMicro, H3, Popover, Text, Tooltip } from 'base-components';

import withContext from 'utils/withContext';
import ButtonLinkWithIcon from 'elements/ButtonLinkWithIcon';
import { CaseStatusContext, CASE_STATUS } from 'compositions/CaseStatus';
import { useCaseShortcut, CASE_SHORTCUT_PANELS } from 'features/keyShortcuts';

import ButtonToggle from './ButtonToggle';
import CaseRequestsPanelContext from '../CaseRequestsPanelContext';
import { getDateAtAssetTZ, getDateAtAgentTZ } from './utils';

const popoverContentStyles = {
  width: 320,
  maxHeight: 250,
  overflowY: 'auto',
  overflowX: 'hidden',
};

const closedStatuses = [CASE_STATUS.closed, CASE_STATUS.closed_canceled];

export function CaseRequestsPanelHeader(props) {
  const { delayedServiceScheduledDate: scheduledDate } = props;
  const { isPermissionsBasedRole, showDelayedServiceModal } = props;
  const { delayedServiceScheduledDispatchDate: dispatchDate } = props;
  const { updateCaseDelayedService, caseAssignedToCurrentUser } = props;
  const { delayedServiceNotes: notes, assetLocationTimezone: tz } = props;
  const { caseStatus, permissions, isReadOnlyCase, isDelayedService } = props;

  const hasDates = !!scheduledDate || !!dispatchDate;
  const hasAnyData = hasDates || !!notes;

  const isClosed = closedStatuses.includes(caseStatus);
  const dispatchDateAtAgentTZ = getDateAtAgentTZ(dispatchDate, tz);
  const dispatchDateAtAssetTZ = getDateAtAssetTZ(dispatchDate, tz);
  const scheduledDateAtAgentTZ = getDateAtAgentTZ(scheduledDate, tz);
  const scheduledDateAtAssetTZ = getDateAtAssetTZ(scheduledDate, tz);

  const canEdit =
    !isReadOnlyCase ||
    (!isClosed &&
      caseAssignedToCurrentUser &&
      isPermissionsBasedRole &&
      permissions.includes('case:manage_delayed_service'));

  const onToggle = () =>
    !isDelayedService
      ? showDelayedServiceModal()
      : updateCaseDelayedService({
          isDelayedService: false,
          delayedServiceNotes: null,
          delayedServiceScheduledDate: null,
          delayedServiceScheduledDispatchDate: null,
        });

  const { onFocusRequested } = useCaseShortcut({
    parent: CASE_SHORTCUT_PANELS.request,
    id: 'goToRequestAction',
    name: t`Go to Request`,
    shortcut: ['r', '0'],
    icon: 'arrow-right',
    priority: 5,
  });

  return (
    <Row modifiers="middle" style={{ marginBottom: px2rem(10) }}>
      <Column
        modifiers="padScaleY_2"
        style={{ paddingLeft: 0 }}
        ref={onFocusRequested}
      >
        <H3 modifiers="fontWeightRegular">
          <Trans>Request</Trans>
        </H3>
      </Column>
      <Column modifiers="padScaleX_4">
        <ButtonToggle
          on={isDelayedService}
          onClick={onToggle}
          disabled={!canEdit}
        >
          <ButtonToggle.OffLabel>
            <Trans>ERS</Trans>
          </ButtonToggle.OffLabel>
          <ButtonToggle.OnLabel>
            <Trans>Delayed Service</Trans>
          </ButtonToggle.OnLabel>
        </ButtonToggle>
      </Column>
      {isDelayedService && (
        <>
          {hasAnyData && (
            <>
              {notes && (
                <Column>
                  <Popover arrow>
                    <Popover.Target>
                      <ButtonMicro
                        style={{ cursor: 'default' }}
                        modifiers={['info', 'mini']}
                      >
                        <ButtonMicro.Icon
                          name="info-circle"
                          style={{ paddingRight: 0 }}
                        />
                      </ButtonMicro>
                    </Popover.Target>
                    <Popover.Content style={{ zIndex: 2 }}>
                      <Row style={popoverContentStyles}>
                        <Column modifiers={['col', 'padScale_2']}>
                          <Text
                            style={{ whiteSpace: 'pre-line' }}
                            modifiers="textLight"
                          >
                            {notes}
                          </Text>
                        </Column>
                      </Row>
                    </Popover.Content>
                  </Popover>
                </Column>
              )}
              {hasDates && (
                <>
                  {!!scheduledDate && (
                    <Column modifiers="display_flex">
                      <Text modifiers="fontWeightLight">
                        <Trans>Completion:</Trans>
                      </Text>
                      &nbsp;
                      {tz ? (
                        <Tooltip>
                          <Tooltip.Target>
                            <Text modifiers="fontWeightMedium">
                              {scheduledDateAtAgentTZ}
                            </Text>
                          </Tooltip.Target>
                          <Tooltip.Content>
                            <Trans>
                              Date at asset location: {scheduledDateAtAssetTZ}
                            </Trans>
                          </Tooltip.Content>
                        </Tooltip>
                      ) : (
                        <Text modifiers="fontWeightMedium">
                          {scheduledDateAtAgentTZ}
                        </Text>
                      )}
                    </Column>
                  )}
                  {!!dispatchDate && (
                    <Column modifiers="display_flex">
                      <Text modifiers="fontWeightLight">
                        <Trans>Dispatch at:</Trans>
                      </Text>
                      &nbsp;
                      {tz ? (
                        <Tooltip>
                          <Tooltip.Target>
                            <Text modifiers="fontWeightMedium">
                              {dispatchDateAtAgentTZ}
                            </Text>
                          </Tooltip.Target>
                          <Tooltip.Content>
                            <Trans>
                              Date at asset location: {dispatchDateAtAssetTZ}
                            </Trans>
                          </Tooltip.Content>
                        </Tooltip>
                      ) : (
                        <Text modifiers="fontWeightMedium">
                          {dispatchDateAtAgentTZ}
                        </Text>
                      )}
                    </Column>
                  )}
                </>
              )}
            </>
          )}
          <Column modifiers="padScaleX_2">
            <ButtonLinkWithIcon
              type="button"
              icon="pencil"
              onClick={() => showDelayedServiceModal()}
              disabled={!canEdit}
              modifiers={[!canEdit && 'disabled']}
            >
              <Trans>Edit</Trans>
            </ButtonLinkWithIcon>
          </Column>
        </>
      )}
    </Row>
  );
}

CaseRequestsPanelHeader.propTypes = {
  caseStatus: PropTypes.string.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string),
  isReadOnlyCase: PropTypes.bool.isRequired,
  isDelayedService: PropTypes.bool.isRequired,
  delayedServiceNotes: PropTypes.string,
  assetLocationTimezone: PropTypes.string,
  isPermissionsBasedRole: PropTypes.bool.isRequired,
  showDelayedServiceModal: PropTypes.func.isRequired,
  updateCaseDelayedService: PropTypes.func.isRequired,
  caseAssignedToCurrentUser: PropTypes.bool.isRequired,
  delayedServiceScheduledDate: PropTypes.string,
  delayedServiceScheduledDispatchDate: PropTypes.string,
};

CaseRequestsPanelHeader.defaultProps = {
  permissions: [],
  delayedServiceNotes: null,
  assetLocationTimezone: '',
  delayedServiceScheduledDate: null,
  delayedServiceScheduledDispatchDate: null,
};

export default compose(
  setDisplayName('CaseRequestsPanelHeader'),
  withContext(CaseRequestsPanelContext),
  withContext(CaseStatusContext),
)(React.memo(CaseRequestsPanelHeader));

import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { t, Trans } from '@lingui/macro';
import { compose, withState } from 'recompose';

import { Text } from 'base-components';
import { Row, Column } from 'styled-components-grid';

import {
  containsCreditCardNumber,
  excludeCreditCardNumber,
  CreditCardComplianceModal,
} from 'utils/creditCardNumberCompliance';

import withCaseDealerResponseActions from '../../withCaseDealerResponseActions';

import { TextInputField } from '../InputField';

export function BaseRenderer({
  id,
  value,
  setValue,
  readOnly,
  updateDealerResponse,
  valueContainsCreditCardNumber,
  setContainsCreditCardNumber,
  ignoreCreditCardNumber,
  setIgnoreCreditCardNumber,
}) {
  const handleChange = (newNote) => {
    setValue(newNote);
    updateDealerResponse({ id, patch: { note: newNote } });
  };

  return (
    <>
      <TextInputField
        defaultValue={value || ''}
        name={`dealerResponse-${id}-note`}
        onChange={(name, newNote) => {
          handleChange(newNote);

          if (!ignoreCreditCardNumber && containsCreditCardNumber(newNote)) {
            setContainsCreditCardNumber(true);
          }
        }}
        placeholder={t`Enter note...`}
        readOnly={readOnly}
      />

      {valueContainsCreditCardNumber && (
        <CreditCardComplianceModal
          onDeleteCreditCardNumber={() => {
            handleChange(excludeCreditCardNumber(value));
            setContainsCreditCardNumber(false);
          }}
          onContinue={() => {
            setContainsCreditCardNumber(false);
            setIgnoreCreditCardNumber(true);
          }}
        />
      )}
    </>
  );
}

BaseRenderer.propTypes = {
  id: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  updateDealerResponse: PropTypes.func.isRequired,
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  valueContainsCreditCardNumber: PropTypes.bool.isRequired,
  setContainsCreditCardNumber: PropTypes.func.isRequired,
  ignoreCreditCardNumber: PropTypes.bool.isRequired,
  setIgnoreCreditCardNumber: PropTypes.func.isRequired,
  // Used in withState below
  /* eslint-disable react/no-unused-prop-types */
  note: PropTypes.string,
  /* eslint-enable react/no-unused-prop-types */
};

BaseRenderer.defaultProps = {
  note: undefined,
  value: undefined,
};

const CellRenderer = compose(
  withCaseDealerResponseActions,
  withState('value', 'setValue', ({ note }) => note),
  withState(
    'valueContainsCreditCardNumber',
    'setContainsCreditCardNumber',
    false,
  ),
  withState('ignoreCreditCardNumber', 'setIgnoreCreditCardNumber', false),
)(BaseRenderer);

export default {
  name: 'note',
  cellKeyGetter: ({ id }) => `${id}:note`,
  cellDataGetter: (args) => args,
  cellStyles: { flex: 3 },
  headerCellRenderer: () => (
    <Row>
      <Column modifiers={['padScaleX_2', 'padScaleY_3']}>
        <Text modifiers={['small', 'textLight']}>
          <Trans>Note (optional)</Trans>
        </Text>
      </Column>
    </Row>
  ),
  dataCellRenderer: (
    { id, note }, // eslint-disable-line react/prop-types
    { tableMetaData },
  ) => (
    <CellRenderer
      id={id}
      note={note}
      readOnly={get(tableMetaData, 'readOnly')}
    />
  ),
};

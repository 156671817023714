import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { get, debounce } from 'lodash';
import { compose, setDisplayName } from 'recompose';

import { Row, Column } from 'styled-components-grid';
import { InputField, MessageSmall } from 'base-components';

import withFocusReceiver from 'setup/FocusProvider/withFocusReceiver';

import withTerminalLocation from './withTerminalLocation';
import { fieldIds } from '../../constants';

const requiredLabel = t`Fleet Terminal Location`;
const optionalLabel = t`Fleet Terminal Location (Optional)`;

export class TerminalLocationSelector extends Component {
  static propTypes = {
    customerTerminalLocation: PropTypes.string,
    isReadOnly: PropTypes.bool.isRequired,
    isRequired: PropTypes.bool,
    onUpdateTerminalLocation: PropTypes.func.isRequired,
    onRemoveTerminalLocation: PropTypes.func.isRequired,
    customer: PropTypes.shape({ id: PropTypes.string }),
    setCasePanelPartial: PropTypes.func.isRequired,
    onFocusRequested: PropTypes.func.isRequired,
  };

  static defaultProps = {
    customerTerminalLocation: '',
    isRequired: false,
    customer: undefined,
  };

  state = {
    isInvalid: this.props.isRequired && !this.props.customerTerminalLocation,
    value: this.props.customerTerminalLocation || '',
  };

  componentDidUpdate(prevProps) {
    if (get(prevProps.customer, 'id') !== get(this.props.customer, 'id')) {
      this.onReset();
    }
  }

  componentWillUnmount() {
    this.updateTerminalLocation.cancel();
  }

  onReset = () => {
    this.setState({ value: '' });
    this.props.onRemoveTerminalLocation();
  };

  // eslint-disable-next-line react/sort-comp
  handleUpdate = (value) => {
    this.props.onUpdateTerminalLocation(value);
  };

  // eslint-disable-next-line react/sort-comp
  updateTerminalLocation = debounce(this.handleUpdate, 1000, {
    trailing: true,
  });

  onChange = (value) => {
    this.setState({ value });
    this.updateTerminalLocation(value);
  };

  validate = () => {
    const { isRequired } = this.props;
    const { value } = this.state;
    if (isRequired && !value) {
      this.props.setCasePanelPartial((status) => ({
        ...status.fields,
        [fieldIds.national.terminal]: 'missing',
      }));

      this.setState({ isInvalid: true });
    } else {
      this.setState({ isInvalid: false });
    }
  };

  render() {
    const { isReadOnly, isRequired, onFocusRequested } = this.props;

    const { value, isInvalid } = this.state;

    return (
      <>
        <InputField
          isValid={!isInvalid}
          onBlur={this.validate}
          onChange={(event) => this.onChange(event.target.value)}
          placeholder={t`Enter Terminal Location details`}
          readOnly={isReadOnly}
          value={value}
        >
          <InputField.Avatar modifiers={['secondary', 'small']} isCompany />
          <Column modifiers={['col', 'padScale_0']}>
            <Row>
              <InputField.Label>
                {isRequired && <Trans id={requiredLabel} />}
                {!isRequired && <Trans id={optionalLabel} />}
              </InputField.Label>
            </Row>
            <Row>
              <InputField.TextField ref={onFocusRequested} />
              {value && (
                <InputField.ActionButton
                  icon="times"
                  type="button"
                  onClick={this.onReset}
                  modifiers={['hoverDanger', 'padScaleX_0']}
                />
              )}
            </Row>
          </Column>
        </InputField>
        {isInvalid && (
          <Row>
            <Column modifiers={['col', 'padScaleY_3']}>
              <MessageSmall type="warning">
                <Trans>Enter a Terminal Location</Trans>
              </MessageSmall>
            </Column>
          </Row>
        )}
      </>
    );
  }
}

export default compose(
  setDisplayName('TerminalLocationSelector'),
  withTerminalLocation,
  withFocusReceiver(fieldIds.national.terminal),
)(TerminalLocationSelector);

// These are common styles shared by our A and Link Elements.
// Keeping them consolidated will help ensure they stay in sync.
import PropTypes from 'prop-types';

export const modifierConfig = {
  colorText: ({ theme }) => ({
    styles: `color: ${theme.colors.base.text};`,
  }),
  colorTextLight: ({ theme }) => ({
    styles: `color: ${theme.colors.base.textLight};`,
  }),
};

export const styles = ({ theme }) => `
  color: ${theme.colors.base.link};
  cursor: pointer;
  font-size: 12px;
  text-decoration: none;
  text-transform: capitalize;
  white-space: nowrap;

  &:hover {
    color: ${theme.colors.base.linkHover};
    text-decoration: underline;
    .fa {
      color: ${theme.colors.base.linkHover};
    }
  }
`;

export const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      link: PropTypes.string.isRequired,
      linkHover: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      textLight: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

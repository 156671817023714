import { Icon } from 'base-components';
import PropTypes from 'prop-types';
import React from 'react';

function HeaderIcon({ modifiers, ...rest }) {
  const defaultModifiers = ['chrome000', 'large'];
  return <Icon modifiers={[...defaultModifiers, ...modifiers]} {...rest} />;
}

HeaderIcon.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
  ...Icon.propTypes,
};

HeaderIcon.defaultProps = {
  modifiers: [],
};

HeaderIcon.displayName = 'Modal__HeaderIcon';

export default HeaderIcon;

import gql from 'graphql-tag';
import React from 'react';
import { Query } from '@apollo/client/react/components';

import { buildQueryChild } from 'utils/queryHelpers';

const dealerTimezoneGQL = gql`
  query dealerTimezoneQuery($id: ID!) {
    dealer(id: $id) {
      id
      timezone
    }
  }
`;

function skipQuery(id) {
  return !id;
}

function buildQueryVariables(id) {
  return { id };
}

function buildWrappedComponentWithQuery(WrappedComponent, componentProps) {
  const { dealerId } = componentProps;

  return (
    <Query
      query={dealerTimezoneGQL}
      skip={skipQuery(dealerId)}
      variables={buildQueryVariables(dealerId)}
    >
      {buildQueryChild(WrappedComponent, componentProps)}
    </Query>
  );
}

/**
 * Given a UI component (which receives some `componentProps`), connect
 * this component to the API so that it receives all the `timezone` for
 * the specified `dealer`.
 */
const withDealerTimezone = (WrappedComponent) => (componentProps) =>
  /* istanbul ignore next */
  buildWrappedComponentWithQuery(WrappedComponent, componentProps);

export default withDealerTimezone;

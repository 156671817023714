import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName, pure } from 'recompose';

import { Dropdown } from 'base-components';

// Rendering the whole list can be slow, so we only do it when necessary
const DropdownList = pure(({ options, optionRenderer, ...rest }) => (
  <Dropdown.List>
    {options.map(([key, optionLabel]) => (
      <Dropdown.ListItem key={key} id={key}>
        {optionRenderer(key, optionLabel, rest)}
      </Dropdown.ListItem>
    ))}
  </Dropdown.List>
));

DropdownList.propTypes = {
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  optionRenderer: PropTypes.func.isRequired,
};

export default compose(setDisplayName('DropdownList'), pure)(DropdownList);

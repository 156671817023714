import React from 'react';
import PropTypes from 'prop-types';

import { Dropdown } from 'base-components';

import DropdownContent from './DropdownContent';
import DropdownContainer from './DropdownContainer';

import { useEventCallback, useDateRangeData } from './utils';

export function DateRangeSelectorDropdown(props) {
  const { range, renderCount, clear, resetOnClose } = props;
  const { onSelectPeriod, applyDateRange, selectedPeriod } = props;

  return (
    <DropdownContainer>
      <Dropdown
        onChange={useEventCallback(onSelectPeriod)}
        position="bottomLeft"
        activeItem={selectedPeriod !== 'custom' ? selectedPeriod : ''}
        onExpandedChange={resetOnClose}
      >
        {(props) => (
          <DropdownContent
            {...props}
            {...range}
            key={renderCount}
            onClear={clear}
            onChange={applyDateRange}
            selectedItem={selectedPeriod}
          />
        )}
      </Dropdown>
    </DropdownContainer>
  );
}

DateRangeSelectorDropdown.propTypes = {
  range: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }).isRequired,
  clear: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  renderCount: PropTypes.number.isRequired,
  resetOnClose: PropTypes.func.isRequired,
  onSelectPeriod: PropTypes.func.isRequired,
  applyDateRange: PropTypes.func.isRequired,
  selectedPeriod: PropTypes.string,
};

const DateRangeSelectorDropdownHooked = (props) => {
  return <DateRangeSelectorDropdown {...props} {...useDateRangeData(props)} />;
};

DateRangeSelectorDropdownHooked.propTypes = {
  endDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date),
};

export default DateRangeSelectorDropdownHooked;

import { compact } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import StyledLeftNav from '../../blocks/LeftNav';

import MenusList from './MenusList';
import menuPropTypes from './menuPropTypes';

function MenuGroupItem({
  TextRenderer,
  expanded,
  expandedMenus,
  handleExpandMenuClick,
  last,
  location,
  menuGroup,
  menuGroupsLoading,
}) {
  return (
    <StyledLeftNav.MenuGroupItem>
      <MenusList
        TextRenderer={TextRenderer}
        expanded={expanded}
        expandedMenus={expandedMenus}
        handleExpandMenuClick={handleExpandMenuClick}
        location={location}
        menuGroup={menuGroup}
        menuGroupsLoading={menuGroupsLoading}
      />
      <StyledLeftNav.MenuGroupsDivider
        modifiers={compact([expanded && 'expanded', last && 'last'])}
      />
    </StyledLeftNav.MenuGroupItem>
  );
}

MenuGroupItem.propTypes = {
  TextRenderer: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  expandedMenus: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleExpandMenuClick: PropTypes.func.isRequired,
  last: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/no-typos
  menuGroup: menuPropTypes.menuGroup.isRequired,
  menuGroupsLoading: PropTypes.bool.isRequired,
};

export default MenuGroupItem;

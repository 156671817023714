import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { t, Trans } from '@lingui/macro';

import { Text } from 'base-components';
import { Container, Row } from 'styled-components-grid';

import { formatPhone } from 'utils/format';

const firstOtherAccountsStyles = { paddingTop: 0 };

const renderText = (text) => <Text modifiers="textLight">{text}</Text>;

const renderAccountType = (value) => {
  if (!value) {
    return renderText(<span>&mdash;</span>);
  }

  return renderText(<Trans>{value}</Trans>);
};

const renderFleet = (value, { name, address, city, state, zip }) => {
  if (![name, address, city, state, zip].some(Boolean)) {
    return renderText(<span>&mdash;</span>);
  }

  return (
    <Container modifiers="padScale_0">
      {name && <Row modifiers="padScale_0">{renderText(name)}</Row>}
      {address && <Row modifiers="padScale_0">{renderText(address)}</Row>}
      {(city || state || zip) && (
        <Row modifiers="padScale_0">
          {city && renderText(city)}
          {city && state && <>&nbsp;</>}
          {state && renderText(state)}
          {(city || state) && zip && <>&nbsp;</>}
          {zip && renderText(zip)}
        </Row>
      )}
    </Container>
  );
};

const renderOtherAccounts = (otherAccounts) => {
  if (!get(otherAccounts, 'length')) {
    return renderText(<span>&mdash;</span>);
  }

  return otherAccounts.map(({ name, accountType, accountNumber }, index) =>
    !!name || !!accountNumber || !!accountNumber ? (
      <Container
        key={accountNumber}
        modifiers="padScaleX_0"
        style={!index ? firstOtherAccountsStyles : undefined}
      >
        {name && <Row modifiers="padScale_0">{renderText(name)}</Row>}
        {accountType && (
          <Row modifiers="padScale_0">{renderAccountType(accountType)}</Row>
        )}
        {accountNumber && (
          <Row modifiers="padScale_0">
            {renderText(<Trans>Account # {accountNumber}</Trans>)}
          </Row>
        )}
      </Container>
    ) : null,
  );
};

const renderDealerName = (_, allValues) => {
  const value = get(allValues, 'dealer.name');

  if (!value) {
    return renderText(<span>&mdash;</span>);
  }

  return renderText(<Trans>{value}</Trans>);
};

const renderDealerBillTo = (_, allValues) => {
  const value = get(allValues, 'dealer.billTo');

  if (!value) {
    return renderText(<span>&mdash;</span>);
  }

  return renderText(<Trans>{value}</Trans>);
};

const renderPhoneNumber = (value) =>
  renderText(value ? formatPhone(value) : <span>&mdash;</span>);

const commonFields = [
  {
    type: 'type',
    label: t`Account Type`,
    renderer: renderAccountType,
  },
  { type: 'country', label: t`Country` },
  { type: 'fleet', label: t`Fleet`, renderer: renderFleet },
];

export const nationalCustomerFields = [
  ...commonFields,
  { type: 'billTo', label: t`Bill To #` },
  { type: 'shipTo', label: t`Ship To #` },
  { type: 'terminalNumber', label: t`Terminal #` },
  {
    type: 'homeOfficeNumber',
    label: t`Home Office #`,
    renderer: renderPhoneNumber,
  },
  {
    type: 'otherAccounts',
    label: t`Other Accounts`,
    renderer: renderOtherAccounts,
  },
];

export const storeCustomerFields = [
  ...commonFields,
  { type: 'accountNumber', label: t`Account #` },
  {
    type: 'dealerName',
    label: t`Service Provider Name`,
    renderer: renderDealerName,
  },
  {
    type: 'dealerBillTo',
    label: t`Service Provider Bill To #`,
    renderer: renderDealerBillTo,
  },
];

export default function CustomerAccountValue({ type, renderer, values }) {
  const value = values[type];

  if (renderer) {
    return renderer(value, values);
  }

  return renderText(value || <span>&mdash;</span>);
}

CustomerAccountValue.propTypes = {
  type: PropTypes.string.isRequired,
  values: PropTypes.shape({}).isRequired,
};

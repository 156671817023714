import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { i18n } from '@lingui/core';

import { Row, Column } from 'styled-components-grid';
import { MessageSmall } from 'base-components';

import { fieldIds } from '../../constants';

export function getErrors(errors, touched) {
  if (
    touched.fleetName &&
    errors.fleetName &&
    touched.zipCode &&
    errors.zipCode
  ) {
    return {
      message: i18n._(t`Fleet Name and ZIP code are required.`),
      fields: {
        [fieldIds.other.name]: 'invalid',
        [fieldIds.other.zip]: 'invalid',
      },
    };
  }

  if (touched.fleetName && errors.fleetName) {
    return {
      message: errors.fleetName,
      fields: { [fieldIds.other.name]: 'invalid' },
    };
  }

  if (touched.zipCode && errors.zipCode) {
    return {
      message: errors.zipCode,
      fields: { [fieldIds.other.zip]: 'invalid' },
    };
  }

  return null;
}

export function Error(props) {
  const { setCasePanelInvalid, touched } = props;

  const errors = getErrors(props.errors, touched);

  if (errors) {
    setCasePanelInvalid(errors.fields);

    return (
      <Row modifiers={['padScaleY_2']}>
        <Column>
          <MessageSmall type="warning">{errors.message}</MessageSmall>
        </Column>
      </Row>
    );
  }

  return null;
}

Error.propTypes = {
  /* eslint-disable react/no-unused-prop-types */
  errors: PropTypes.shape({
    fleetName: PropTypes.string,
    zipCode: PropTypes.string,
  }).isRequired,
  /* eslint-enable react/no-unused-prop-types */
  setCasePanelInvalid: PropTypes.func.isRequired,
  touched: PropTypes.shape({
    fleetName: PropTypes.bool,
    zipCode: PropTypes.bool,
  }).isRequired,
};

Error.displayName = 'OtherCustomer__Error';

export default Error;

import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Trans } from '@lingui/macro';

import { Text } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import EmptyCell from '../EmptyCell';
import HeaderCell from '../HeaderCell';
import { COLUMN_NAMES } from '../constants';
import { buildBaseColumnConfig } from '../utils';

function CaseCustomerCell({ name }) {
  if (!name) return <EmptyCell />;

  return (
    <Container modifiers="padScaleY_2">
      <Row>
        <Column modifiers={['col', 'padScaleY_0']}>
          <Text>{name}</Text>
        </Column>
      </Row>
    </Container>
  );
}

CaseCustomerCell.propTypes = {
  name: PropTypes.string,
};

CaseCustomerCell.defaultProps = {
  name: undefined,
};

export default (...args) => ({
  ...buildBaseColumnConfig(COLUMN_NAMES.customer, ...args),
  cellDataGetter: (data) => get(data, 'node.customer') || {},
  headerCellRenderer: (props) => (
    <HeaderCell {...props} label={<Trans>Fleet</Trans>} />
  ),
  dataCellRenderer: CaseCustomerCell,
});

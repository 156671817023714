import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, reduce } from 'lodash';
import { i18n } from '@lingui/core';

import { Row, Column } from 'styled-components-grid';
import { MessageSmall } from 'base-components';

import { contactFields } from './constants';

export function getErrorMessage(errors) {
  if (isEmpty(errors)) return null;

  return reduce(errors, (message, value) => `${message}${i18n._(value)}\n`, '');
}

export function CaseContactsError({ errors }) {
  const errorMessage = getErrorMessage(errors);

  if (errorMessage) {
    return (
      <Row modifiers={['padScaleY_2']}>
        <Column modifiers="col">
          <MessageSmall type="warning">
            <span style={{ whiteSpace: 'pre-line' }}>{errorMessage}</span>
          </MessageSmall>
        </Column>
      </Row>
    );
  }

  return null;
}

CaseContactsError.propTypes = {
  errors: PropTypes.shape({
    [contactFields.name]: PropTypes.string,
    [contactFields.phone]: PropTypes.string,
    [contactFields.phoneExt]: PropTypes.string,
    [contactFields.email]: PropTypes.string,
  }).isRequired,
};

export default CaseContactsError;

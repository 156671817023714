import PropTypes from 'prop-types';
import { reduce } from 'lodash';

import { ButtonIconCircle } from 'base-components';
import { buildStyledComponent } from 'decisiv-ui-utils';

import { modifiers, modifierToColors } from 'features/amazonConnect';

/**
 * All the backgrounds for our custom CCP button are dark; the icon
 * itself will always be white. This is using _actual white_ and not
 * `chrome000` because the background colors aren't tied to the theme.
 */
const styles = () => `
  span {
    color: #FFFFFF;
  }
`;

/**
 * Create new modifiers on our custom ButtonIconCircle for each
 * Amazon Connect UI-state modifier.
 */
const modifierConfig = reduce(
  modifiers,
  (acc, modifier) => ({
    ...acc,
    [modifier]: () => {
      const { normal, dark } = modifierToColors(modifier);
      return `
        background-color: ${normal};

        &:hover {
          background-color: ${dark};
        }
      `;
    },
  }),
  {},
);

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome000: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

/**
 * Extend the `ButtonIconCircle` base component to provide
 * modifiers for the various Amazon Connect UI states.
 */
const PhoneButtonIconCircle = buildStyledComponent(
  'PhoneButtonIconCircle',
  ButtonIconCircle,
  styles,
  {
    modifierConfig,
    themePropTypes,
  },
);

export default PhoneButtonIconCircle;

import React from 'react';
import { Query } from '@apollo/client/react/components';
import { curry, get } from 'lodash';

import highlightedCasesQuery from './highlightedCasesQuery';
import { sanitizeSortBy, sanitizeSortDirection } from '../utils';
import {
  ITEMS_PER_PAGE,
  STATUS_GROUPS_NAMES,
  COLUMN_NAME_TO_API_NAME,
} from '../constants';

export const buildVariables = (props) => {
  const { sortDirection, after, sortBy, before } = props;
  const sortFields = [COLUMN_NAME_TO_API_NAME[sortBy]];

  return {
    sort: { fields: sortFields, direction: sortDirection.toUpperCase() },
    after,
    before,
    pageSize: ITEMS_PER_PAGE,
    cacheBuster: Math.random(),
  };
};

export const getSanitizedSortProps = ({ sortBy, sortDirection }) => ({
  sortBy: sanitizeSortBy(sortBy, STATUS_GROUPS_NAMES.all),
  sortDirection: sanitizeSortDirection(sortDirection),
});

export const buildQueryChild = curry(
  (
    WrappedComponent,
    componentProps,
    { data, loading, error, refetch, networkStatus, stopPolling, startPolling },
  ) => (
    <WrappedComponent
      {...componentProps}
      cases={get(data, 'highlightedCases.cases.edges', [])}
      error={error}
      refetch={refetch}
      isLoading={!!loading}
      pagination={get(data, 'highlightedCases.cases.pageInfo', {
        hasNextPage: false,
        hasPreviousPage: false,
      })}
      stopAutoRefresh={stopPolling}
      startAutoRefresh={startPolling}
      isAutoRefreshing={networkStatus === 6} // 6 means it is polling
    />
  ),
);

const withHighlightedCasesResults = (WrappedComponent) => (componentProps) => (
  <Query
    query={highlightedCasesQuery}
    variables={buildVariables({
      ...componentProps,
      ...getSanitizedSortProps(componentProps),
    })}
    errorPolicy="all"
    fetchPolicy="cache-and-network"
  >
    {buildQueryChild(WrappedComponent, componentProps)}
  </Query>
);

export default withHighlightedCasesResults;

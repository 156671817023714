import React, { useCallback } from 'react';
import { omit } from 'lodash';
import { useApolloClient } from '@apollo/client';

import query from './query';

const errorMessage = '[validateAsset] requires the "caseId" prop.';

const withValidateAsset = (Component) => (cProps) => {
  const client = useApolloClient();
  const { caseId, ...rest } = cProps;

  const validateAsset = useCallback(
    (input = {}) => {
      if (!caseId) return Promise.reject(new Error(errorMessage));

      return client.query({
        query,
        variables: { caseId, input: omit(input, '__typename') },
        fetchPolicy: 'network-only',
      });
    },
    [caseId, client],
  );

  return <Component {...{ ...rest, caseId, validateAsset }} />;
};

export default withValidateAsset;

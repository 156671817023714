import { compact, includes } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import ButtonMicro from '../../blocks/ButtonMicro';

function Text({ modifiers, ...rest }) {
  const textModifiers = compact([
    'capitalize',
    'fontWeightLight',
    'small',
    'textLight',
    includes(modifiers, 'active') && 'active',
  ]);
  return <ButtonMicro.Text modifiers={textModifiers} {...rest} />;
}

Text.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

Text.defaultProps = {
  modifiers: [],
};

export default Text;

import { get, isEmpty } from 'lodash';
import React from 'react';

import { AmazonConnect, isConversationState } from 'features/amazonConnect';

const RELEVANT_CALL_CONTACT_ATTRIBUTES = ['shipTo'];

/**
 * Verifies that the call is currently ongoing (vs in after call work where the
 * contact is still available), then returns only the relevant call contact
 * attributes in a flattened shape.
 *
 * @param {Object} contact the contact provided by Amazon Connect.
 * @returns {Object} may contain contact attributes and values.
 */
function getActiveCallContactAttributes(contact, uiState) {
  if (!isConversationState(uiState) || isEmpty(contact)) {
    return {};
  }

  const contactAttributes = contact.getAttributes();
  return RELEVANT_CALL_CONTACT_ATTRIBUTES.reduce((acc, key) => {
    acc[key] = get(contactAttributes, [key, 'value']);
    return acc;
  }, {});
}

const withCallContactAttributes = (WrappedComponent) => (componentProps) => (
  <AmazonConnect>
    {({ contact, uiState }) => (
      <WrappedComponent
        {...componentProps}
        callContactAttributes={getActiveCallContactAttributes(contact, uiState)}
      />
    )}
  </AmazonConnect>
);

export default withCallContactAttributes;

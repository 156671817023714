import { createAction } from 'redux-actions';

import {
  SET_DEALER_DETAIL_ACCORDIONS_EXPANDED,
  SET_EXPANDED_MENUS,
  SET_LEFT_NAV_STATE,
  TOGGLE_UNIT,
} from '../constants';

export const setDealerDetailAccordionsExpanded = createAction(
  SET_DEALER_DETAIL_ACCORDIONS_EXPANDED,
);
export const setExpandedMenus = createAction(SET_EXPANDED_MENUS);
export const setLeftNavState = createAction(SET_LEFT_NAV_STATE);
export const toggleUnit = createAction(TOGGLE_UNIT);

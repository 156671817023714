import gql from 'graphql-tag';

const query = gql`
  mutation createAfterHoursContact(
    $jobTitle: String
    $name: String!
    $primaryPhoneNumber: String!
    $secondaryPhoneNumber: String
    $dealerId: ID!
  ) {
    createAfterHoursContact(
      input: {
        contact: {
          dealerId: $dealerId
          jobTitle: $jobTitle
          name: $name
          primaryPhoneNumber: $primaryPhoneNumber
          secondaryPhoneNumber: $secondaryPhoneNumber
        }
      }
    ) {
      contact {
        name
      }
    }
  }
`;

export default query;

import React from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import styled from 'styled-components';
import { Column } from 'styled-components-grid';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const modifierConfig = {
  flexRow: () => ({
    styles: 'flex-direction: row;',
  }),
};

const Data = (props) => {
  const componentProps = omit(props, ['modifiers']);
  return <Column {...componentProps} modifiers={['col']} />;
};

// this acts as our <td> element
const styles = ({ flex, minWidth }) => `
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex: ${flex};
  font-size: ${px2rem(12)};
  justify-content: flex-start;
  min-height: ${px2rem(60)};
  min-width: ${px2rem(minWidth)};
`;

const defaultProps = {
  flex: 1,
  minWidth: 100,
};

const propTypes = {
  flex: PropTypes.number.isRequired,
  minWidth: PropTypes.number.isRequired,
};

export default buildStyledComponent('Data', styled(Data), styles, {
  defaultProps,
  modifierConfig,
  propTypes,
});

import gql from 'graphql-tag';

export const NAME = 'setCaseAssetValidationMutation';

export default gql`
  mutation setCaseAssetValidationMutation(
    $caseId: ID!
    $type: AssetValidationType!
    $notes: String
    $entries: [AssetValidationEntryInput!]
  ) {
    setCaseAssetValidation(
      input: { caseId: $caseId, type: $type, notes: $notes, entries: $entries }
    ) {
      id
      assetValidation {
        type
        notes
        assetValidationEntries {
          assetId
          assetValidationEntryAttempts {
            id
            type
            input {
              shipTo
              customer
              vehicleVin
              application
              vehicleType
              vehicleNumber
              domicileLocation
            }
            user {
              name
              email
            }
            timestamp
            reference
            asset {
              shipTo
              customer
              vehicleVin
              application
              vehicleType
              vehicleNumber
              domicileLocation
            }
          }
        }
      }
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import Tag from 'blocks/Tag';

import { CASE_STATUS } from 'compositions/CaseStatus/constants';

import { iconForStatus, modifierForStatus, titleForStatus } from './constants';

function MiniCaseStatusBadge({ status, ...rest }) {
  return (
    <Tag {...rest}>
      <Tag.Icon
        name={iconForStatus[status]}
        modifiers={[modifierForStatus[status], 'mini']}
      />
      <Tag.Text>
        <Trans id={titleForStatus[status]} />
      </Tag.Text>
    </Tag>
  );
}

MiniCaseStatusBadge.propTypes = {
  status: PropTypes.oneOf(Object.values(CASE_STATUS)).isRequired,
};

export default MiniCaseStatusBadge;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Container } from 'styled-components-grid';
import { buildStyledComponent } from 'decisiv-ui-utils';

function Tabs({ modifiers, ...rest }) {
  const defaultModifiers = ['fluid'];
  const tabsModifiers = defaultModifiers.concat(modifiers);

  return <Container modifiers={tabsModifiers} {...rest} />;
}

Tabs.defaultProps = {
  modifiers: [],
};

Tabs.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

export default buildStyledComponent('Tabs', styled(Tabs));

import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { Icon } from 'base-components';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import { getBreakpointFor } from '../utils';

export default buildStyledComponent(
  'StatusCheckbox__Checkmark',
  styled(Icon).attrs({ name: 'check' }),
  ({ theme }) => css`
    color: ${theme.colors.base.chrome400};
    display: none;
    position: absolute;
    right: ${px2rem(8)};
    top: ${px2rem(14)};

    @media (max-width: ${getBreakpointFor('textWrap', false)}) {
      top: ${px2rem(10)};
    }
  `,
  {
    modifierConfig: {
      checked: ({ theme }) => `
        color: ${theme.colors.base.link};
        display: block;
      `,

      navExpanded: () => css`
        @media (max-width: ${getBreakpointFor('textWrap', true)}) {
          top: ${px2rem(10)};
        }
      `,
    },
    themePropTypes: PropTypes.shape({
      colors: PropTypes.shape({
        base: PropTypes.shape({
          link: PropTypes.string.isRequired,
          chrome400: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  },
);

import { milesToMeters } from 'utils/measure';

export const defaultLocationSearch = {
  location: undefined,
  searchValue: '',
  searchValueTried: '',
};

export const ASSET_LOCATION_SEARCH_INDEX = 0;
export const LOCATION_SEARCHES = 'locationSearches';

export const defaultRangeInMiles = parseFloat(
  process.env.REACT_APP_DEALER_LOCATOR_DEFAULT_PROXIMITY_FILTER_IN_MILES || 25,
);

export const defaultRangeInKms = Math.round(
  milesToMeters(defaultRangeInMiles) / 1000,
);

export const searchAreaOptionsInMiles = Array.from(
  new Set([10, 25, 50, 100, 150, 200, defaultRangeInMiles]),
).sort((a, b) => a - b);

export const searchAreaOptionsInKm = Array.from(
  new Set([16, 40, 80, 160, 240, 320, defaultRangeInKms]),
).sort((a, b) => a - b);

export const searchRouteOptionsInMiles = [5, 10, 15, 20, 25];
export const searchRouteOptionsInKm = [8, 16, 24, 40, 80];

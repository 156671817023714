import PropTypes from 'prop-types';

import { Text } from 'base-components';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const styles = ({ theme }) => `
  font-size: ${px2rem(20)};
  font-weight: ${theme.fontWeights.light};
  padding-top: ${px2rem(2)};
`;

const themePropTypes = {
  fontWeights: PropTypes.shape({
    light: PropTypes.number.isRequired,
  }).isRequired,
};

export default buildStyledComponent('Label', Text, styles, { themePropTypes });

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink as BaseNavLink } from 'react-router-dom';
import { omit } from 'lodash';

import { buildStyledComponent } from 'decisiv-ui-utils';

function UnstyledNavLink(props) {
  const navLinkProps = omit(props, ['modifiers', 'theme']);
  return <BaseNavLink {...navLinkProps} />;
}

const NavLink = buildStyledComponent('NavLink', styled(UnstyledNavLink));

NavLink.propTypes = {
  'aria-label': PropTypes.string.isRequired,
};

export default NavLink;

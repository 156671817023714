import React from 'react';
import { Query } from '@apollo/client/react/components';
import { curry, get } from 'lodash';

import inboundProgramQuery from './inboundProgramQuery';

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, { data, loading }) => (
    <WrappedComponent
      {...componentProps}
      isUsxpress={get(data, 'case.inboundProgramNumber.isUsxpress') || false}
      isLoadingInboundProgram={!!loading}
    />
  ),
);

const withInboundProgram = (WrappedComponent) => (componentProps) => {
  const { caseNumber } = componentProps;

  return (
    <Query
      query={inboundProgramQuery}
      variables={{ caseNumber }}
      skip={!caseNumber}
    >
      {buildQueryChild(WrappedComponent, componentProps)}
    </Query>
  );
};

export default withInboundProgram;

import React, { useMemo } from 'react';
import { Query, Mutation } from '@apollo/client/react/components';
import { curry, get, pick } from 'lodash';

import { CASE_DEALER_RESPONSES_QUERY_NAME } from 'compositions/CaseDealerSelectionPanel/withCaseDealerResponses';
import { NAME as CASE_DELAYS_QUERY_NAME } from 'compositions/CaseDetailOverviewPanel/withCaseDelays/caseDelaysQuery';

import caseDelayedServiceQuery from './caseDelayedServiceQuery';
import updateCaseDelayedServiceMutation from './updateCaseDelayedServiceMutation';

export const buildMutationChild = curry(
  (WrappedComponent, componentProps, mutate) => {
    const { caseId } = componentProps;

    const updateCaseDelayedService = useMemo(
      () => (patch) => mutate({ variables: { caseId, patch } }),
      [mutate, caseId],
    );

    return (
      <WrappedComponent
        {...componentProps}
        updateCaseDelayedService={updateCaseDelayedService}
      />
    );
  },
);

function buildWrappedComponentWithMutation(WrappedComponent, componentProps) {
  return (
    <Mutation
      mutation={updateCaseDelayedServiceMutation}
      refetchQueries={[
        CASE_DELAYS_QUERY_NAME,
        // Refetch the dealer responses because the backend might have
        // changed a "Accepted / Fleet Delay" to "Accepted"
        CASE_DEALER_RESPONSES_QUERY_NAME,
      ]}
    >
      {buildMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const data = get(queryProps, 'data.case', {});
    const isDelayedService = get(data, 'isDelayedService') || false;

    const props = pick(data, [
      'delayedServiceNotes',
      'assetLocationTimezone',
      'delayedServiceScheduledDate',
      'delayedServiceScheduledDispatchDate',
    ]);

    return buildWrappedComponentWithMutation(WrappedComponent, {
      ...componentProps,
      ...props,
      isDelayedService,
    });
  },
);

function buildWrappedComponentWithQuery(WrappedComponent, componentProps) {
  const { caseNumber } = componentProps;

  return (
    <Query
      skip={!caseNumber}
      query={caseDelayedServiceQuery}
      variables={{ caseNumber }}
    >
      {buildQueryChild(WrappedComponent, componentProps)}
    </Query>
  );
}

const withCaseDelayedService = (WrappedComponent) => (componentProps) =>
  buildWrappedComponentWithQuery(WrappedComponent, componentProps);

export default withCaseDelayedService;

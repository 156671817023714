import PropTypes from 'prop-types';
import { Component } from 'react';

import relativeTime from './relativeTime';

// Adapted from the FormattedRelative component included with the react-intl library
// The original can be found here:
// https://github.com/yahoo/react-intl/blob/v2.4.0/src/components/relative.js

const SECOND = 1000;
const MINUTE = 1000 * 60;
const HOUR = 1000 * 60 * 60;
const DAY = 1000 * 60 * 60 * 24;

// The maximum timer delay value is a 32-bit signed integer.
// See: https://mdn.io/setTimeout
const MAX_TIMER_DELAY = 2147483647;

export function selectUnits(delta) {
  const absDelta = Math.abs(delta);

  if (absDelta < MINUTE) {
    return 'second';
  }

  if (absDelta < HOUR) {
    return 'minute';
  }

  if (absDelta < DAY) {
    return 'hour';
  }

  // The maximum scheduled delay will be measured in days since the maximum
  // timer delay is less than the number of milliseconds in 25 days.
  return 'day';
}

function getUnitDelay(units) {
  /* istanbul ignore next */
  switch (units) {
    case 'second':
      return SECOND;
    case 'minute':
      return MINUTE;
    case 'hour':
      return HOUR;
    case 'day':
      return DAY;
    default:
      return MAX_TIMER_DELAY;
  }
}

function isSameDate(a, b) {
  if (a === b) {
    return true;
  }

  const aTime = new Date(a).getTime();
  const bTime = new Date(b).getTime();

  return Number.isFinite(aTime) && Number.isFinite(bTime) && aTime === bTime;
}

export class FormattedRelative extends Component {
  static propTypes = {
    children: PropTypes.func,
    render: PropTypes.func,
    // eslint-disable-next-line react/no-unused-prop-types
    updateInterval: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any.isRequired,
  };

  static defaultProps = {
    children: null,
    render: null,
    updateInterval: 1000 * 10,
  };

  state = { now: new Date() };

  componentDidMount() {
    this.scheduleNextUpdate(this.props, this.state);
  }

  UNSAFE_componentWillReceiveProps({ value: nextValue }) {
    // When the `props.value` date changes, `state.now` needs to be updated,
    // and the next update can be rescheduled.
    if (!isSameDate(nextValue, this.props.value)) {
      this.setState({ now: new Date() });
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    this.scheduleNextUpdate(nextProps, nextState);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  scheduleNextUpdate = (props, state) => {
    // Cancel and pending update because we're scheduling a new update.
    clearTimeout(this.timer);

    const { value, updateInterval } = props;
    const time = new Date(value).getTime();

    // If the `updateInterval` is falsy, including `0` or we don't have a
    // valid date, then auto updates have been turned off, so we bail and
    // skip scheduling an update.
    if (!updateInterval || !Number.isFinite(time)) {
      return;
    }

    const delta = time - state.now;
    // const unitDelay = getUnitDelay(units || selectUnits(delta));
    const unitDelay = getUnitDelay(selectUnits(delta));
    const unitRemainder = Math.abs(delta % unitDelay);

    // We want the largest possible timer delay which will still display
    // accurate information while reducing unnecessary re-renders. The delay
    // should be until the next "interesting" moment, like a tick from
    // "1 minute ago" to "2 minutes ago" when the delta is 120,000ms.
    const delay =
      delta < 0
        ? Math.max(updateInterval, unitDelay - unitRemainder)
        : Math.max(updateInterval, unitRemainder);

    this.timer = setTimeout(() => {
      this.setState({ now: new Date() });
    }, delay);
  };

  render() {
    const { children, render, value } = this.props;
    const renderFunc = children || render;
    const timeString = relativeTime(value, this.props);

    return renderFunc(timeString);
  }
}

export default FormattedRelative;

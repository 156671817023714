import { t, defineMessage, Trans } from '@lingui/macro';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName } from 'recompose';

import { Text } from 'base-components';
import { Column, Row } from 'styled-components-grid';

import { mapIcons } from 'assets/images/mapIcons';

import Panel from 'blocks/Panel';

import { CASE_STATUS } from 'compositions/CaseStatus';
import withCaseStatus from 'compositions/CaseDealerSelectionPanel/withCaseStatus';

import { isMetric } from 'utils/unit';

import Item from './Item';

const LEGEND_ITEMS = [
  {
    icon: mapIcons.mcsn,
    label: t`Michelin Commercial Service Network`,
  },
  { icon: mapIcons.michelin, label: t`Michelin Service Provider` },
  { icon: mapIcons.mrt, label: t`Michelin Retread Technologies` },
  {
    icon: mapIcons.localFleet,
    label: t`Local Fleet Service Provider`,
  },
];

export function getDealerResultsLabel(unit) {
  return isMetric(unit)
    ? defineMessage({
        message:
          '{total} Service Providers within {proximityFilterValue} Kilometers of Lat {latitude} Long {longitude}',
      })
    : defineMessage({
        message:
          '{total} Service Providers within {proximityFilterValue} Miles of Lat {latitude} Long {longitude}',
      });
}

function LegendComponent({
  caseStatus,
  dealers,
  isExpanded,
  locationSearches,
  proximityFilters,
  selectedProximityFilterIndex,
  unit,
}) {
  const selectedProximityFilterValue =
    proximityFilters[selectedProximityFilterIndex];
  const location = get(locationSearches, '0.location') || {};

  return (
    <Panel>
      <Row modifiers={['middle']} style={{ alignItems: 'flex-start' }}>
        <Column modifiers="col">
          <Row modifiers="padScale_0">
            {LEGEND_ITEMS.map((element) => (
              <Item key={element.label} {...element} />
            ))}
          </Row>
        </Column>
        {caseStatus === CASE_STATUS.new && dealers.length > 0 && !isExpanded && (
          <Column style={{ whiteSpace: 'nowrap' }} modifiers="padScaleY_2">
            <Text modifiers={['small']}>
              <Trans
                id={getDealerResultsLabel(unit)}
                values={{
                  total: dealers.length,
                  proximityFilterValue: selectedProximityFilterValue,
                  latitude: location.latitude.toFixed(6),
                  longitude: location.longitude.toFixed(6),
                }}
              />
            </Text>
          </Column>
        )}
      </Row>
    </Panel>
  );
}

LegendComponent.propTypes = {
  caseStatus: PropTypes.string.isRequired,
  dealers: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  locationSearches: PropTypes.arrayOf(PropTypes.shape),
  proximityFilters: PropTypes.arrayOf(PropTypes.number),
  selectedProximityFilterIndex: PropTypes.string,
  unit: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool,
};

LegendComponent.defaultProps = {
  dealers: [],
  isExpanded: true,
  locationSearches: [],
  proximityFilters: [],
  selectedProximityFilterIndex: '0',
};

export default compose(
  setDisplayName('LegendComponent'),
  withCaseStatus,
)(LegendComponent);

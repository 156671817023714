import gql from 'graphql-tag';

export const NAME = 'contactsQuery';

export default gql`
  query contactsQuery($id: ID!) {
    afterHoursContacts(input: { dealerId: $id }) {
      contacts {
        id
        jobTitle
        name
        primaryPhoneNumber
        secondaryPhoneNumber
      }
    }
  }
`;

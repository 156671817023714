import React from 'react';
import PropTypes from 'prop-types';
import { Plural } from '@lingui/macro';

import { Accordion } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import DealerActiveCasesTable from 'compositions/DealerActiveCasesTable';

import AccordionBody from '../AccordionBody';

export function Cases({
  expanded,
  onExpandedChange,
  dealerId,
  totalActiveCases,
}) {
  return (
    <Container modifiers={['flex_column', 'padScale_0']}>
      <Accordion.Divider />
      <Accordion
        name="cases"
        expanded={expanded}
        onExpandedChange={onExpandedChange}
      >
        <Accordion.Head>
          <Container modifiers="fluid">
            <Accordion.Title>
              <Row modifiers="middle">
                <Column>
                  <Plural
                    value={totalActiveCases}
                    one="Case (#)"
                    other="Cases (#)"
                  />
                </Column>
              </Row>
            </Accordion.Title>
          </Container>
        </Accordion.Head>
        <AccordionBody>
          {expanded && <DealerActiveCasesTable dealerId={dealerId} />}
        </AccordionBody>
      </Accordion>
    </Container>
  );
}

Cases.propTypes = {
  expanded: PropTypes.bool.isRequired,
  onExpandedChange: PropTypes.func.isRequired,
  totalActiveCases: PropTypes.number.isRequired,
  dealerId: PropTypes.string.isRequired,
};

export default Cases;

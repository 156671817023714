import React from 'react';
import moment from 'moment-timezone';
import { t, Trans, Plural } from '@lingui/macro';

import { Text, H4, Label } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import ContactInfo from '../../ContactInfo';
import { EventPropType } from '../../propTypes';

const labelsWidth = 70;
const dateFormat = 'ddd, D MMM YYYY, h:mm A z';

const contactTypesEntries = Object.entries({
  primaryContact: t`Primary`,
  backupContact: t`Backup`,
  finalContact: t`Final`,
});

export default function RotationDetails(props) {
  const { rotation } = props;
  const eventDuration = moment.duration(rotation.end.diff(rotation.start));
  const durationInMinutes = eventDuration.asMinutes();
  const durationHours = parseInt(durationInMinutes / 60, 10);
  const durationMinutes = durationInMinutes % 60 || '';

  return (
    <Container modifiers="padScaleX_0">
      <Row style={{ margin: '10px 0' }}>
        <Column modifiers="padScaleX_0">
          <H4 modifiers="fontWeightRegular">
            <Trans>Schedule</Trans>
          </H4>
        </Column>
      </Row>
      <Row modifiers="middle">
        <Column modifiers="padScale_0" style={{ width: labelsWidth }}>
          <Label modifiers="textLight">
            <Trans>Start</Trans>
          </Label>
        </Column>
        <Column modifiers={['col', 'padScaleY_0']}>
          <Text>{rotation.start.format(dateFormat)}</Text>
        </Column>
      </Row>
      <Row modifiers="middle">
        <Column modifiers="padScale_0" style={{ width: labelsWidth }}>
          <Label modifiers="textLight">
            <Trans>End</Trans>
          </Label>
        </Column>
        <Column modifiers={['col', 'padScaleY_0']}>
          <Text>{rotation.end.format(dateFormat)}</Text>
        </Column>
      </Row>
      <Row modifiers="middle">
        <Column modifiers="padScale_0" style={{ width: labelsWidth }}>
          <Label modifiers="textLight">
            <Trans>Duration</Trans>
          </Label>
        </Column>
        <Column modifiers={['col', 'padScaleY_0']}>
          <Text>
            <Plural value={durationHours} one="# hour" other="# hours" />
            {durationMinutes && (
              <>
                &nbsp;
                <Trans>, {durationMinutes} minutes</Trans>
              </>
            )}
          </Text>
        </Column>
      </Row>
      <Row style={{ margin: '20px 0 10px' }}>
        <Column modifiers="padScale_0">
          <H4 modifiers="fontWeightRegular">
            <Trans>Contacts</Trans>
          </H4>
        </Column>
      </Row>
      {contactTypesEntries.map(([type, label]) => (
        <Row key={label} modifiers="padScaleY_0">
          <Column
            style={{ width: labelsWidth, paddingTop: 3 }}
            modifiers="padScale_0"
          >
            <Label modifiers="textLight">
              <Trans id={label} />
            </Label>
          </Column>
          <Column modifiers={['col', 'padScale_0']}>
            <Row modifiers="middle">
              <ContactInfo contact={rotation[type] || {}} />
            </Row>
          </Column>
        </Row>
      ))}
    </Container>
  );
}

RotationDetails.propTypes = {
  // eslint-disable-next-line react/no-typos
  rotation: EventPropType.isRequired,
};

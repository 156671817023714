import gql from 'graphql-tag';

export default gql`
  query dealerDetailsQuery(
    $assetLocation: String!
    $distanceUnit: DistanceUnit
    $id: ID!
  ) {
    dealer(id: $id) {
      id
      name
      ers247
      ersHours {
        end
        start
        weekDay
      }
      billTo
      features {
        id
        code
      }
      operatingStatus {
        changesAt
        ersOpen
        open
      }
      shipTo
      displayAddress {
        countryCode
        city
        postalCode
        province
        streetAddress
      }
      open247
      timezone
      travelEstimate(destination: $assetLocation) {
        distance(unit: $distanceUnit)
        time
      }
      workingHours {
        end
        start
        weekDay
      }
      phone
      notes
      activeCases
    }
  }
`;

import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { t, Trans, Plural } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { compose, setDisplayName, withState } from 'recompose';

import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';
import { P, H2, Text, InputField, QuickActionButton } from 'base-components';

import Modal from 'components/Modal';

import { CASE_STATUS } from 'compositions/CaseStatus/constants';

import DatetimeSelector from './DatetimeSelector';
import { delayedServiceModalModes as modes } from '../constants';

const unknownTimezone = t`unknown`;

const modesContent = {
  [modes.default]: {
    title: t`Delayed Service`,
    helpContent: (
      <P>
        <Trans>Enter the Delayed Service information below.</Trans>
        <br />
        <Trans>
          You must enter a completion date and time, notes or both to save.
        </Trans>
      </P>
    ),
    buttons: {
      cancel: t`Cancel`,
      submit: t`Save Delayed Service`,
    },
  },

  [modes.confirmDelayedService]: {
    title: t`Is this case still ERS?`,
    helpContent: (
      <P>
        <Trans>
          Press &quot;Keep as ERS&quot; if this case is still ERS
          <br />
          or enter the appropriate values below and press
          <br />
          &quot;Save as Delayed Service&quot; to mark the case as Delayed
          Service.
        </Trans>
      </P>
    ),
    buttons: {
      cancel: t`Keep as ERS`,
      submit: t`Save as Delayed Service`,
    },
  },

  [modes.confirmERS]: {
    title: t`Is this case still Delayed Service?`,
    helpContent: (
      <P>
        <Trans>
          Press &quot;Keep as Delayed Service&quot; if this case is still
          Delayed Service
          <br />
          or press &quot;Save as ERS&quot; to mark the case as ERS.
        </Trans>
      </P>
    ),
    buttons: {
      cancel: t`Keep as Delayed Service`,
      submit: t`Save as ERS`,
    },
  },
};

export function DelayedServiceModal(props) {
  const { mode, notes, timezone, setDispatchDate } = props;
  const { save, onClose, setScheduledDate, setNotes, caseStatus } = props;

  const dates = {
    dispatch: props.dispatchDate,
    scheduled: props.scheduledDate,
  };

  const canSubmit = !!notes || !!dates.scheduled;

  const canEditDispatchDate = [CASE_STATUS.new, CASE_STATUS.dispatch].includes(
    caseStatus,
  );

  const onSubmit = () => {
    let data = {
      notes,
      dispatchDate: dates.dispatch && moment(dates.dispatch).toISOString(),
      scheduledDate: dates.scheduled && moment(dates.scheduled).toISOString(),
      isDelayedService: true,
    };

    if (mode === modes.confirmERS) {
      data = {
        notes: null,
        dispatchDate: null,
        scheduledDate: null,
        isDelayedService: false,
      };
    }

    return save(data).then(onClose);
  };

  return (
    <Modal onClose={onClose}>
      {() => (
        <Modal.Body>
          <Modal.Header>
            <Modal.HeaderIcon name="hourglass" />
          </Modal.Header>
          <Modal.Content>
            <Row modifiers="center">
              <Column>
                <H2 modifiers="fontWeightRegular">
                  <Trans id={modesContent[mode].title} />
                </H2>
              </Column>
            </Row>
            <Row modifiers="center" style={{ marginBottom: px2rem(30) }}>
              <Column>{modesContent[mode].helpContent}</Column>
            </Row>
            {mode !== modes.confirmERS && (
              <>
                <Row>
                  <Column modifiers={['col', 'padScale_0']}>
                    <DatetimeSelector
                      date={dates.scheduled}
                      label={<Trans>Completion Date</Trans>}
                      onChange={setScheduledDate}
                    />
                  </Column>
                </Row>
                <Row style={{ marginTop: 15 }}>
                  <Column modifiers={['col', 'padScale_0']}>
                    <DatetimeSelector
                      date={dates.dispatch}
                      label={<Trans>Dispatch Date</Trans>}
                      onChange={setDispatchDate}
                      readOnly={!canEditDispatchDate}
                    />
                  </Column>
                </Row>
                <Row>
                  <Column modifiers={['col', 'padScale_0']}>
                    <Text modifiers={['small', 'textLight']}>
                      <Trans
                        id="Dates are in the asset location's time zone ({tz})"
                        values={{
                          tz: timezone
                            ? moment.tz(timezone).format('z')
                            : i18n._(unknownTimezone),
                        }}
                      />
                    </Text>
                  </Column>
                </Row>
                <br />
                <Row>
                  <Column modifiers={['col', 'padScale_0']}>
                    <InputField
                      name="notes"
                      value={notes || ''}
                      onChange={(e) => setNotes(e.target.value)}
                      modifiers={['height_auto']}
                      maxLength={300}
                      placeholder={t`Enter notes...`}
                    >
                      <Column modifiers={['col', 'padScaleY_0']}>
                        <Row>
                          <InputField.Label>
                            <Text modifiers={['small', 'textLight']}>
                              <Trans>Notes</Trans>
                            </Text>
                          </InputField.Label>
                          <InputField.CharsLeftLabel>
                            {(charsLeft) => (
                              <Plural
                                value={charsLeft}
                                one="# Character Left"
                                other="# Characters Left"
                              />
                            )}
                          </InputField.CharsLeftLabel>
                        </Row>
                        <Row>
                          <InputField.TextArea />
                        </Row>
                      </Column>
                    </InputField>
                  </Column>
                </Row>
              </>
            )}
          </Modal.Content>
          <Modal.Footer>
            <Row modifiers="end">
              <Column>
                <QuickActionButton onClick={onClose} type="button">
                  <QuickActionButton.Text>
                    <Trans id={modesContent[mode].buttons.cancel} />
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
              <Column>
                <QuickActionButton
                  type="submit"
                  onClick={onSubmit}
                  disabled={!canSubmit}
                  modifiers={['secondary', !canSubmit && 'disabled']}
                >
                  <QuickActionButton.Text>
                    <Trans id={modesContent[mode].buttons.submit} />
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
            </Row>
          </Modal.Footer>
        </Modal.Body>
      )}
    </Modal>
  );
}

DelayedServiceModal.propTypes = {
  mode: PropTypes.oneOf(Object.values(modes)).isRequired,
  save: PropTypes.func.isRequired,
  notes: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  setNotes: PropTypes.func.isRequired,
  timezone: PropTypes.string,
  caseStatus: PropTypes.string.isRequired,
  dispatchDate: PropTypes.string,
  scheduledDate: PropTypes.string,
  setDispatchDate: PropTypes.func.isRequired,
  setScheduledDate: PropTypes.func.isRequired,
};

DelayedServiceModal.defaultProps = {
  notes: null,
  timezone: null,
  dispatchDate: null,
  scheduledDate: null,
};

export default compose(
  setDisplayName('DelayedServiceModal'),
  withState('notes', 'setNotes', (p) => p.notes || ''),
  withState('dispatchDate', 'setDispatchDate', (p) => p.dispatchDate || null),
  withState(
    'scheduledDate',
    'setScheduledDate',
    (p) => p.scheduledDate || null,
  ),
)(DelayedServiceModal);

import React from 'react';
import { Trans } from '@lingui/macro';

import { H3 } from 'base-components';
import { Column, Row } from 'styled-components-grid';

import Panel from 'blocks/Panel';

import CaseEventTimeline from './CaseEventTimeline';
import CasePanelStatuses from './CasePanelStatuses';
import CaseFixpixPushResult from './CaseFixpixPushResult';

export default function CaseStatusPanel() {
  return (
    <>
      <Panel
        style={{ paddingBottom: 0 }}
        modifiers="padScale_3"
        data-testid="CaseStatusPanel"
      >
        <Row modifiers="middle">
          <Column modifiers={['col', 'padScaleY_2']}>
            <H3 modifiers="fontWeightRegular">
              <Trans>Status</Trans>
            </H3>
          </Column>
          <Column>
            <CaseFixpixPushResult />
          </Column>
        </Row>
        <Row>
          <Column modifiers={['col', 'padScaleY_0']}>
            <CaseEventTimeline />
          </Column>
        </Row>
      </Panel>
      <CasePanelStatuses />
    </>
  );
}

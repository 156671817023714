import PropTypes from 'prop-types';
import styled from 'styled-components';
import { get } from 'lodash';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import { setBackground, setBoxShadow } from '../../utils/arrow';

import constants from './constants';

const {
  ARROW_BASE,
  ARROW_HEIGHT,
  ARROW_OFFSET,
  BOTTOM_ROTATE,
  LEFT_ROTATE,
  RIGHT_ROTATE,
  TOP_ROTATE,
} = constants;

/**
 * Attempt to get the correct background color from the provided
 * `arrowColor`. If `arrowColor` is a valid key in `theme.colors.status`,
 * use the value there; otherwise use the given `arrowColor` directly,
 * assuming that it is a valid CSS color. The default fallback will
 * be the theme base background color.
 * @param theme {object}
 * @param arrowColor {string}
 * @return {string}
 */
function backgroundFor(theme, arrowColor) {
  const defaultColor = theme.colors.base.background;
  const color = get(theme, ['colors', 'status', arrowColor], arrowColor);
  return setBackground(defaultColor, color);
}

const modifierConfig = {
  bottom: ({ arrowColor, theme }) => `
    background: ${backgroundFor(theme, arrowColor)};
    box-shadow: ${setBoxShadow('bottom', theme.colors.base.shadowLight)};
    left: 0;
    margin: auto;
    right: 0;
    top: -${px2rem(ARROW_HEIGHT)};
    transform: rotate(${BOTTOM_ROTATE}deg) !important;
  `,

  bottomLeft: ({ arrowColor, theme }) => `
    background: ${backgroundFor(theme, arrowColor)};
    box-shadow: ${setBoxShadow('bottom', theme.colors.base.shadowLight)};
    right: ${px2rem(ARROW_OFFSET)};
    top: -${px2rem(ARROW_HEIGHT)};
    transform: rotate(${BOTTOM_ROTATE}deg) !important;
  `,

  bottomRight: ({ arrowColor, theme }) => `
    background: ${backgroundFor(theme, arrowColor)};
    box-shadow: ${setBoxShadow('bottom', theme.colors.base.shadowLight)};
    left: ${px2rem(ARROW_OFFSET)};
    top: -${px2rem(ARROW_HEIGHT)};
    transform: rotate(${BOTTOM_ROTATE}deg) !important;
  `,

  left: ({ arrowColor, theme }) => `
    background: ${backgroundFor(theme, arrowColor)};
    bottom: 0;
    box-shadow: ${setBoxShadow('left', theme.colors.base.shadow)};
    margin: auto;
    right: -${px2rem(ARROW_HEIGHT)};
    top: 0;
    transform: rotate(${LEFT_ROTATE}deg) !important;
  `,

  leftBottom: ({ arrowColor, theme }) => `
    background: ${backgroundFor(theme, arrowColor)};
    box-shadow: ${setBoxShadow('left', theme.colors.base.shadow)};
    right: -${px2rem(ARROW_HEIGHT)};
    top: ${px2rem(ARROW_OFFSET)};
    transform: rotate(${LEFT_ROTATE}deg) !important;
  `,

  leftTop: ({ arrowColor, theme }) => `
    background: ${backgroundFor(theme, arrowColor)};
    bottom: ${px2rem(ARROW_OFFSET)};
    box-shadow: ${setBoxShadow('left', theme.colors.base.shadow)};
    right: -${px2rem(ARROW_HEIGHT)};
    transform: rotate(${LEFT_ROTATE}deg) !important;
  `,

  right: ({ arrowColor, theme }) => `
    background: ${backgroundFor(theme, arrowColor)};
    bottom: 0;
    box-shadow: ${setBoxShadow('right', theme.colors.base.shadow)};
    left: -${px2rem(ARROW_HEIGHT)};
    margin: auto;
    top: 0;
    transform: rotate(${RIGHT_ROTATE}deg) !important;
  `,

  rightBottom: ({ arrowColor, theme }) => `
    background: ${backgroundFor(theme, arrowColor)};
    box-shadow: ${setBoxShadow('right', theme.colors.base.shadow)};
    left: -${px2rem(ARROW_HEIGHT)};
    top: ${px2rem(ARROW_OFFSET)};
    transform: rotate(${RIGHT_ROTATE}deg) !important;
  `,

  rightTop: ({ arrowColor, theme }) => `
    background: ${backgroundFor(theme, arrowColor)};
    bottom: ${px2rem(ARROW_OFFSET)};
    box-shadow: ${setBoxShadow('right', theme.colors.base.shadow)};
    left: -${px2rem(ARROW_HEIGHT)};
    transform: rotate(${RIGHT_ROTATE}deg) !important;
  `,

  top: ({ arrowColor, theme }) => `
    background: ${backgroundFor(theme, arrowColor)};
    bottom: -${px2rem(ARROW_HEIGHT)};
    box-shadow: ${setBoxShadow('top', theme.colors.base.shadow)};
    left: 0;
    margin: auto;
    right: 0;
    transform: rotate(${TOP_ROTATE}deg) !important;
  `,

  topLeft: ({ arrowColor, theme }) => `
    background: ${backgroundFor(theme, arrowColor)};
    bottom: -${px2rem(ARROW_HEIGHT)};
    box-shadow: ${setBoxShadow('top', theme.colors.base.shadow)};
    right: ${px2rem(ARROW_OFFSET)};
    transform: rotate(${TOP_ROTATE}deg) !important;
  `,

  topRight: ({ arrowColor, theme }) => `
    background: ${backgroundFor(theme, arrowColor)};
    bottom: -${px2rem(ARROW_HEIGHT)};
    box-shadow: ${setBoxShadow('top', theme.colors.base.shadow)};
    left: ${px2rem(ARROW_OFFSET)};
    transform: rotate(${TOP_ROTATE}deg) !important;
  `,
};

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      background: PropTypes.string.isRequired,
      shadow: PropTypes.string.isRequired,
      shadowLight: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const styles = `
  height: ${px2rem(ARROW_BASE)};
  position: absolute;
  width: ${px2rem(ARROW_BASE)};
`;

export default buildStyledComponent('Popover__Arrow', styled.div, styles, {
  modifierConfig,
  themePropTypes,
});

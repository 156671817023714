import { px2rem } from 'decisiv-ui-utils';
import { t } from '@lingui/macro';

import { allAssetTypes } from 'compositions/CaseAssetValidationPanel/constants';

import { PANEL_STATUSES } from '../../constants';

export const STATUS_BAR_HEIGHT_PX = 45;
export const STATUS_BAR_HEIGHT = px2rem(STATUS_BAR_HEIGHT_PX);

export const MAIN_CONTENT_PADDING = px2rem(16);

export const iconForValidationStatus = {
  [PANEL_STATUSES.complete]: 'check-circle-f',
  [PANEL_STATUSES.incomplete]: 'circle-f',
  [PANEL_STATUSES.invalid]: 'exclamation-circle-f',
  [PANEL_STATUSES.partial]: 'circle-half-f',
};

export const severityGroupNames = {
  missing: t`MISSING`,
  invalid: t`INVALID`,
};

export const fieldNames = {
  inboundProgram: {
    program: t`Program`,
    location: t`Store`,
  },

  customer: {
    national: {
      customer: t`Fleet`,
      domicile: t`Domicile`,
      terminal: t`Terminal Location`,
    },
    store: { customer: t`Fleet` },
    customStore: { name: t`Name` },
    other: { name: t`Name`, zip: t`Zip Code` },
  },

  contact: {
    name: t`Name`,
    phone: t`Phone Number`,
    email: t`Email`,
    phoneExt: t`Extension`,
  },

  payment: {
    national: {
      newCC: t`New Credit Card`,
      existingCC: t`Existing Credit Card`,
    },
    store: { type: t`Payment Option` },
    other: { receipt: t`Transaction Receipt` },
  },

  assetLocation: {
    location: t`Asset Location`,
  },

  dealerSelection: {
    noServicingDealer: t`Servicing Service Provider`,
    name: t`Name`,
    mileageRange: {
      id: t`Mileage Range`,
    },
    responses: {
      eta: t`ETA`,
      reason: t`Accepted/Rejected Reason`,
      phoneNumber: t`Phone Number`,
      contactPerson: t`Contact`,
    },
  },

  request: {
    assets: {
      unitNumber: t`Unit Number`,
      assetType: t`Unit Type`,
    },

    lines: {
      all: t`All Fields`,
      asset: t`Unit`,
      requestedAction: t`Requested Action`,
      tireCondition: t`Tire Condition`,
      axleType: t`Axle Type`,
      tirePosition: t`Tire Position`,
      productType: t`Tire Type`,
      manufacturerFullName: t`Brand`,
      tireSize: t`Tire Size`,
      sculptureTreadName: t`Tread Design`,
      loadRange: t`Load Range`,
      rimType: t`Rim Type`,
      agreed: t`Agree`,
      supplied: t`Supplied`,
    },
  },

  assetValidation: {
    notes: t`Notes`,
    entries: allAssetTypes,
  },
};

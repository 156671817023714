import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { capitalize } from 'lodash';

import { Icon, Text } from 'base-components';
import { Row, Column } from 'styled-components-grid';

import SelectableItem from './SelectableItem';
import ShortcutBadge from './ShortcutBadge';

const ResultItem = React.forwardRef(
  ({ action, active, currentRootActionId }, ref) => {
    const ancestors = React.useMemo(() => {
      if (!currentRootActionId) return action.ancestors;

      const index = action.ancestors.findIndex(
        (ancestor) => ancestor.id === currentRootActionId,
      );
      // +1 removes the currentRootAction; e.g.
      // if we are on the "External Links" parent action,
      // the UI should not display "External Links > Michelin Tires"
      // but rather just "Michelin Tires"
      return action.ancestors.slice(index + 1);
    }, [action.ancestors, currentRootActionId]);

    if (!action?.name) {
      return null;
    }

    return (
      <SelectableItem
        modifiers={[active ? 'active' : undefined]}
        style={{ display: 'block' }}
      >
        <Row
          modifiers={['padScale_2', 'middle']}
          style={{ justifyContent: 'space-between' }}
          ref={ref}
        >
          <Column modifiers={['padScaleX_0', 'flex_1']}>
            <Row
              modifiers={['middle', 'flex_1']}
              style={{ flexWrap: 'nowrap' }}
            >
              {ancestors?.length > 0 &&
                ancestors.map((ancestor) => (
                  <Column key={ancestor.id} modifiers={['padScaleX_0']}>
                    <Row>
                      {ancestor.icon && (
                        <Column>
                          <Icon
                            modifiers={['mini', 'textLight']}
                            style={{ opacity: 0.5 }}
                            name={ancestor.icon}
                          />
                        </Column>
                      )}

                      <Column>
                        <Text
                          modifiers={['textLight']}
                          style={{ opacity: 0.5 }}
                        >
                          <Trans id={ancestor.name} />

                          <Text style={{ marginLeft: 8 }}>&rsaquo;</Text>
                        </Text>
                      </Column>
                    </Row>
                  </Column>
                ))}

              {action.icon && (
                <Column>
                  <Icon modifiers={['mini']} name={action.icon} />
                </Column>
              )}

              <Column modifiers={['flex_1']} style={{ marginTop: 4 }}>
                <Text
                  style={{
                    maxWidth: 'calc(100% - 50px)',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <Trans id={action.name} />
                  {action.children?.length > 0 && (
                    <Text style={{ marginLeft: 8 }}>&rsaquo;</Text>
                  )}
                </Text>
              </Column>
            </Row>
          </Column>

          {action.shortcut?.length > 0 && (
            <Column>
              <Row>
                {action.shortcut.map((shortcut, index) => (
                  <Column key={`${action.id}${shortcut}${index}`}>
                    <ShortcutBadge>
                      <ShortcutBadge.Text>
                        {capitalize(shortcut)}
                      </ShortcutBadge.Text>
                    </ShortcutBadge>
                  </Column>
                ))}
              </Row>
            </Column>
          )}
        </Row>
      </SelectableItem>
    );
  },
);

ResultItem.propTypes = {
  action: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    shortcut: PropTypes.arrayOf(PropTypes.string),
    ancestors: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        icon: PropTypes.string,
      }),
    ),
    children: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        icon: PropTypes.string,
      }),
    ),
  }).isRequired,
  active: PropTypes.bool.isRequired,
  currentRootActionId: PropTypes.string,
};

export default ResultItem;

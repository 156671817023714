import { logoutConnect } from 'features/amazonConnect';

import { locationOrigin } from './url';

export const bffAPILink = `${process.env.REACT_APP_GRAPHQL_API_URL}/api`;
export const bffLoginLink = `${process.env.REACT_APP_GRAPHQL_API_URL}/login`;
export const bffLogoutLink = `${process.env.REACT_APP_GRAPHQL_API_URL}/logout`;
export const bffCSRFLink = `${process.env.REACT_APP_GRAPHQL_API_URL}/csrf`;
export const bffCaseUnassignLink = `${process.env.REACT_APP_GRAPHQL_API_URL}/case-unassign`;

export const bffAPISocketLink = bffAPILink
  .replace(/^http/, 'ws')
  .replace('/api', '/socket');

export function logoutURL(returnURL = locationOrigin()) {
  return `${bffLogoutLink}?redirect_to=${encodeURIComponent(returnURL)}`;
}

export function sendToLogout(returnURL = locationOrigin()) {
  logoutConnect(() => {
    window.location.href = logoutURL(returnURL);
  });
}

export function loginURL(returnURL = window.location.href) {
  return `${bffLoginLink}?redirect_to=${encodeURIComponent(returnURL)}&saml=${
    process.env.REACT_APP_SAML_LOGIN
  }`;
}

export function sendToLogin(returnURL = window.location.href) {
  // JSDOM/ Jest does not play nicely with setting window variables
  // like location. There is no good way to test this.
  // istanbul ignore next
  window.location.href = loginURL(returnURL);
}

export function sendToAccessDenied() {
  // istanbul ignore next
  window.location.href = `${window.location.origin}/access-denied`;
}

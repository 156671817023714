import { buildStyledComponent } from 'decisiv-ui-utils';
import PropTypes from 'prop-types';

import Content from './Content';

const styles = ({ theme }) => `
  background: ${theme.colors.base.chrome100};
  padding: calc(${theme.dimensions.gridPad} * 5)
           calc(${theme.dimensions.gridPad} * 10);
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome100: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dimensions: PropTypes.shape({
    gridPad: PropTypes.string.isRequired,
  }).isRequired,
};

export default buildStyledComponent('Modal__Footer', Content, styles, {
  themePropTypes,
});

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { merge } from 'lodash';
import { ThemeProvider } from 'styled-components';
import {
  theme as defaultTheme,
  GlobalStyles as BaseGlobalStyles,
} from 'decisiv-ui-utils';

import GlobalStyles from './GlobalStyles';
import configureFonts from './configureFonts';

// These colors have been defined by Michelin. As this app does not currently have
// a Decisiv equivalent, these colors are hardcoded to override the defaults.
// https://decisiv.atlassian.net/wiki/spaces/DUE/pages/190119957/Michelin+Branding
export const customOncallValues = {
  colors: {
    brand: {
      hover: '#1095F9',
      primary: '#27509B',
      secondary: '#FCE500',
      tertiary: '#00205B',
    },
    weather: {
      clearDay: '#f6a623',
      clearNight: '#005d7e',
      partlyCloudyNight: '#005d7e',
      partlyCloudyDay: '#0492d2',
      cloudy: '#0492d2',
      wind: '#0492d2',
      rain: '#999999',
      sleet: '#999999',
      snow: '#999999',
      fog: '#999999',
    },
  },
};

export class AppThemeProvider extends Component {
  static propTypes = {
    baseTheme: PropTypes.shape({
      animations: PropTypes.shape({}).isRequired,
      colors: PropTypes.shape({
        base: PropTypes.shape({}).isRequired,
        brand: PropTypes.shape({}).isRequired,
        status: PropTypes.shape({}).isRequired,
      }).isRequired,
      dimensions: PropTypes.shape({}).isRequired,
      transitions: PropTypes.shape({}).isRequired,
    }),
    children: PropTypes.node.isRequired,
  };

  static defaultProps = { baseTheme: defaultTheme };

  state = { RenderComponent: null };

  UNSAFE_componentWillMount() {
    this.buildComponent();
  }

  buildAppTheme = (baseTheme, customTheme) => merge({}, baseTheme, customTheme);

  buildComponent = () => {
    const { baseTheme, children } = this.props;

    const appTheme = this.buildAppTheme(baseTheme, customOncallValues);

    configureFonts(appTheme);

    this.setState({
      RenderComponent: () => (
        <ThemeProvider theme={appTheme}>
          {children}
          <BaseGlobalStyles />
          <GlobalStyles />
        </ThemeProvider>
      ),
    });
  };

  render() {
    const { RenderComponent } = this.state;

    return <RenderComponent />;
  }
}

export default AppThemeProvider;

import gql from 'graphql-tag';

const query = gql`
  mutation restoreCase($caseId: ID!) {
    restoreCanceledCase(input: { id: $caseId }) {
      case {
        id
        status
        statusHistory {
          id
          changedAt
          createdAt
          newStatus
          oldStatus
          user {
            email
            name
          }
        }
      }
    }
  }
`;

export default query;

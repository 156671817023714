import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { css } from 'styled-components';
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';

import { Icon, Text } from 'base-components';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';
import { Container, Column, Row } from 'styled-components-grid';

import EntryDivider from '../EntryDivider';
import { notificationType, notificationTypeLabel } from './constants';

const Emphasis = <Text modifiers="fontWeightMedium" />;
const validTypes = Object.values(notificationType);

const Badge = buildStyledComponent(
  'NotificationEntryBadge',
  Icon,
  css`
    &:before {
      align-items: center;
      background-color: ${({ theme }) => theme.colors.status.info};
      border-radius: 50%;
      color: white;
      display: flex;
      height: ${px2rem(35)};
      justify-content: center;
      width: ${px2rem(35)};
    }
  `,
);

function NotificationEntry({ caseEmailType, insertedAt, recipientsEmails }) {
  return (
    <>
      <Row>
        <Column
          modifiers={['center', 'height_100', 'padScaleY_0']}
          style={{ height: px2rem(25), width: px2rem(50) }}
        >
          <EntryDivider />
        </Column>
      </Row>
      <Row>
        <Column
          modifiers={['center', 'padScaleY_0']}
          style={{ width: px2rem(50) }}
        >
          <Container modifiers={['flex_column', 'height_100', 'padScale_0']}>
            <Row>
              <Column modifiers={['center', 'padScaleY_1']}>
                <Badge name="envelope" />
              </Column>
            </Row>
            <Row modifiers={['center', 'flex_1']}>
              <Column modifiers={['padScaleY_0']}>
                <EntryDivider />
              </Column>
            </Row>
          </Container>
        </Column>
        <Column modifiers={['col']} style={{ paddingBottom: px2rem(20) }}>
          <Row>
            <Column modifiers={['padScale_0']}>
              <Text modifiers={['small', 'textLight']}>
                {moment(insertedAt).tz(moment.tz.guess()).format('h:mm A z')}
              </Text>
            </Column>
          </Row>
          <Row modifiers={['middle']}>
            <Text>
              <Trans
                id='"<0>{notificationType}</0>" notification email was sent to: {recipients}.'
                values={{
                  notificationType: i18n._(
                    notificationTypeLabel[caseEmailType],
                  ),
                  recipients: recipientsEmails.join(', '),
                }}
                components={[Emphasis]}
              />
            </Text>
          </Row>
        </Column>
      </Row>
    </>
  );
}

NotificationEntry.propTypes = {
  insertedAt: PropTypes.string.isRequired,
  caseEmailType: PropTypes.oneOf(validTypes).isRequired,
  recipientsEmails: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default NotificationEntry;

import { compose, setDisplayName } from 'recompose';

import withDealers from '../withDealers';
import withDealerActions from '../withDealerActions';
import withIsRouteSearch from '../withIsRouteSearch';
import withLocations from '../withLocations';

import SearchResultsComponent from './SearchResultsListComponent';

export default compose(
  setDisplayName('DealerLocator__SearchResultsList'),
  withDealers,
  withDealerActions,
  withIsRouteSearch,
  withLocations,
)(SearchResultsComponent);

import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName } from 'recompose';
import { Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { px2rem } from 'decisiv-ui-utils';
import { H2, QuickActionButton } from 'base-components';
import { Column, Row } from 'styled-components-grid';

import withContext from 'utils/withContext';

import { CaseStatusContext } from 'compositions/CaseStatus';

import Modal from 'components/Modal';

import withCaseDelays from '../withCaseDelays';
import CaseDelaysTimeline from './CaseDelaysTimeline';
import AddDelay from './AddDelay';

import { labelForStatus } from './constants';

export function ManageDelaysModal({
  status,
  isLoadingCaseDelays,
  createCaseDelay,
  updateCaseDelay,
  caseDelays,
  onClose,
}) {
  const filteredCaseDelays = caseDelays.filter(
    (delay) => delay.status === status,
  );

  return (
    <Modal onClose={onClose}>
      {({ closeModal }) => (
        <Modal.Body>
          <Modal.Header>
            <Modal.HeaderIcon name="hourglass" />
          </Modal.Header>
          <Modal.Content>
            <Row modifiers={['center']}>
              <Column>
                <H2
                  modifiers={['fontWeightRegular']}
                  style={{
                    marginBottom: px2rem(20),
                  }}
                >
                  <Trans
                    id='Manage "{status}" Delays'
                    values={{ status: i18n._(labelForStatus[status]) }}
                  />
                </H2>
              </Column>
            </Row>

            <Row modifiers={['padScaleY_5']}>
              <Column modifiers={['col', 'padScale_0']}>
                <AddDelay
                  onSubmit={createCaseDelay}
                  caseDelays={filteredCaseDelays}
                  isLoadingCaseDelays={isLoadingCaseDelays}
                />
              </Column>
            </Row>

            <Row modifiers={['padScaleY_5']} style={{ paddingBottom: 0 }}>
              <Column modifiers={['col', 'padScale_0']}>
                <CaseDelaysTimeline
                  status={status}
                  caseDelays={filteredCaseDelays}
                  updateCaseDelay={updateCaseDelay}
                />
              </Column>
            </Row>
          </Modal.Content>
          <Modal.Footer>
            <Row modifiers={['end']}>
              <Column>
                <QuickActionButton onClick={closeModal}>
                  <QuickActionButton.Text>
                    <Trans>Close</Trans>
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
            </Row>
          </Modal.Footer>
        </Modal.Body>
      )}
    </Modal>
  );
}

ManageDelaysModal.propTypes = {
  status: PropTypes.string.isRequired,
  caseDelays: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      startTime: PropTypes.string.isRequired,
      endTime: PropTypes.string,
      status: PropTypes.string,
      reasonType: PropTypes.string.isRequired,
      customReason: PropTypes.string,
    }),
  ).isRequired,
  isLoadingCaseDelays: PropTypes.bool.isRequired,
  createCaseDelay: PropTypes.func.isRequired,
  updateCaseDelay: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('ManageDelaysModal'),
  withContext(CaseStatusContext),
  withCaseDelays,
)(ManageDelaysModal);

import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Column } from 'styled-components-grid';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

// this acts as our <th> element
const styles = ({ theme, flex, minWidth }) => `
  align-items: center;
  color: ${theme.colors.base.chrome500};
  display: flex;
  flex: ${flex};
  font-size: ${px2rem(12)};
  font-weight: 400;
  justify-content: flex-start;
  min-width: ${px2rem(minWidth)};
`;

const propTypes = {
  flex: PropTypes.number.isRequired,
  minWidth: PropTypes.number.isRequired,
};

const defaultProps = {
  flex: 1,
  minWidth: 100,
};

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome500: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default buildStyledComponent('Header', styled(Column), styles, {
  defaultProps,
  propTypes,
  themePropTypes,
});

import gql from 'graphql-tag';

export default gql`
  mutation updateCaseDelayMutation($delayId: ID!, $endTime: Datetime!) {
    updateCaseDelay(input: { id: $delayId, patch: { endTime: $endTime } }) {
      caseDelay {
        id
        startTime
        endTime
        status
        reasonType
        customReason
      }
    }
  }
`;

import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compact } from 'lodash';
import { compose, setDisplayName } from 'recompose';

import { getEncodedPolyline } from 'features/googleMaps/location';
import GoogleMapPropTypes from 'features/googleMaps/propTypes';
import withGoogleMapJS from 'features/googleMaps/withGoogleMapJS';

import { unitSelector } from 'redux/preferences/selectors';

import patchFromChangedProps from 'utils/patchFromChangedProps';

import {
  LOCATION_SEARCHES,
  defaultLocationSearch,
  defaultRangeInMiles,
  searchAreaOptionsInMiles,
} from './constants';

export const Context = createContext();

const { Provider, Consumer } = Context;

/* eslint-disable react/no-unused-state */
export class DealerLocatorContext extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    searchEnabled: PropTypes.bool,
    autoReloadInterval: PropTypes.number,
    displayDetailView: PropTypes.bool,
    googleMaps: GoogleMapPropTypes.googleMapsApi.isRequired,
    initialSelectedDealerId: PropTypes.string,
    inboundProgramNumber: PropTypes.shape({
      billTo: PropTypes.string.isRequired,
    }),
    onSelectDealer: PropTypes.func,
    toggleDetailView: PropTypes.func,
    unit: PropTypes.oneOf(['Imperial', 'Metric']).isRequired,
    dealerDataTransformer: PropTypes.func,
    searchAreaDistanceFilterIndex: PropTypes.string,
    updateSearchAreaDistanceFilterIndex: PropTypes.func,
    initialAssetLocation: PropTypes.shape({}),
  };

  static defaultProps = {
    searchEnabled: true,
    autoReloadInterval: undefined,
    displayDetailView: false,
    initialSelectedDealerId: undefined,
    inboundProgramNumber: undefined,
    onSelectDealer: undefined,
    toggleDetailView: undefined,
    dealerDataTransformer: undefined,
    searchAreaDistanceFilterIndex: undefined,
    updateSearchAreaDistanceFilterIndex: undefined,
  };

  static getDerivedStateFromProps(props, state) {
    const checkedProps = compact([
      'unit',
      'searchEnabled',
      'autoReloadInterval',
      'inboundProgramNumber',
      'dealerDataTransformer',
      !state.selectedDealer && 'initialSelectedDealerId',
      props.toggleDetailView && 'displayDetailView',
    ]);

    return patchFromChangedProps(props, state, checkedProps);
  }

  dealerLocatorContextMounted = false;

  constructor(props) {
    super(props);

    const assetLocation = props?.initialAssetLocation || defaultLocationSearch;

    const encodedPolyline = getEncodedPolyline(props.googleMaps, [
      assetLocation,
    ]);

    const searchAreaDistanceFilterIndex =
      props.searchAreaDistanceFilterIndex ||
      `${Math.max(0, searchAreaOptionsInMiles.indexOf(defaultRangeInMiles))}`;

    this.state = {
      [LOCATION_SEARCHES]: [assetLocation],
      countryCode: assetLocation.countryCode,
      searchEnabled: props.searchEnabled,
      displayDetailView: !!(props.toggleDetailView && props.displayDetailView),
      encodedPolyline: encodedPolyline || undefined,
      highlightedDealer: undefined,
      initialSelectedDealerId: props.initialSelectedDealerId,
      inboundProgramNumber: props.inboundProgramNumber,
      isRouteSearch: false,
      onSelectDealer: props.onSelectDealer,
      searchAreaDistanceFilterIndex,
      searchRouteDistanceFilterIndex: '0',
      selectedDealer: undefined,
      selectedFeatureFilters: undefined,
      shouldAutoSelectInitialDealer: !!props.initialSelectedDealerId,
      toggleDetailView: props.toggleDetailView,
      unit: props.unit,
      updateDealerLocatorContext: this.updateDealerLocatorContext,
      dealerDataTransformer: props.dealerDataTransformer,
    };
  }

  componentDidMount() {
    this.dealerLocatorContextMounted = true;
  }

  componentWillUnmount() {
    this.dealerLocatorContextMounted = false;
  }

  updateDealerLocatorContext = (newContext, callback) => {
    // the component was unmounted while we were waiting on this async response
    if (!this.dealerLocatorContextMounted) return;

    const newState = { ...newContext };
    const newSelectedDealerId = newContext?.selectedDealer?.dealerId;
    const currentSelectedDealerId = this.state?.selectedDealer?.dealerId;
    const { searchAreaDistanceFilterIndex } = newContext;
    const { updateSearchAreaDistanceFilterIndex: updateIndex } = this.props;

    if (
      !currentSelectedDealerId &&
      newSelectedDealerId &&
      newSelectedDealerId &&
      this.state.initialSelectedDealerId
    ) {
      newState.initialSelectedDealerId = undefined;
      newState.shouldAutoSelectInitialDealer = false;
    }

    if (updateIndex && searchAreaDistanceFilterIndex) {
      updateIndex(Number(searchAreaDistanceFilterIndex));
    }

    this.setState(newState, callback);
  };

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

const DealerLocatorContextWithGoogleMapJS = withGoogleMapJS(
  DealerLocatorContext,
);

DealerLocatorContextWithGoogleMapJS.Consumer = Consumer;

export default compose(
  setDisplayName('DealerLocatorContext'),
  connect((state) => ({ unit: unitSelector(state) })),
)(DealerLocatorContextWithGoogleMapJS);

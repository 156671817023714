import { t } from '@lingui/macro';
import { difference, map, memoize } from 'lodash';

import USER_ROLES from 'features/rbac/constants';

const walmartAssetSearchURL = process.env.REACT_APP_WALMART_ASSET_SEARCH_URL;

const getMenusForLocation = memoize(
  ({ pathname }) => [
    {
      roles: USER_ROLES.ALL_ROLES,
      menu: [
        {
          icon: 'home',
          message: t`Dashboard`,
          // TODO: support passing a `shouldBeActive` or similar function
          // so that we don't need to rebuild the menus by location just
          // to have an item be active for multiple paths.
          path: '/', // the routing figures out what to render for `/`
          activePath: pathname.includes('/cases/') ? pathname : '/',
        },
      ],
    },
    {
      roles: USER_ROLES.CALL_CENTER,
      menu: [
        {
          icon: 'map-pin',
          message: t`Service Provider Locator`,
          path: '/dealer-locator',
        },
      ],
    },
    {
      roles: [
        ...USER_ROLES.ONCALL_ADMIN_ONLY,
        ...USER_ROLES.CALL_CENTER_ONLY,
        USER_ROLES.MICHELIN_OPERATIONS,
      ],
      menu: [
        {
          icon: 'tools',
          message: t`Tech Admin`,
          path: '/tech-admin/dealers',
        },
      ],
    },
    {
      roles: [
        ...USER_ROLES.ONCALL_ADMIN_ONLY,
        USER_ROLES.DEALER_USER,
        USER_ROLES.DEALER_ADMIN,
        USER_ROLES.MICHELIN_OPERATIONS,
      ],
      menu: [
        {
          icon: 'bar-chart',
          message: t`Reports`,
          path: '/reports',
        },
      ],
    },
    {
      roles: difference(USER_ROLES.ALL_ROLES, USER_ROLES.TECH_ADMIN_ONLY), // This menu item is not shown for the dealer admin and user
      menu: [
        {
          icon: 'external-link',
          message: t`External Links`,
          menuSubItems: [
            {
              message: t`Michelin Tires & Retread`,
              url: 'http://www.michelintruck.com/tires-and-retreads/',
            },
            {
              message: t`NTTS Locator`,
              url: 'http://www.nttsbreakdown.com/ntts/programs/main/main.php',
            },
            {
              message: t`Schwab Locator`,
              url: 'https://www.lesschwab.com/',
            },
            {
              message: t`STTC Accounts`,
              url: 'https://www.sttconline.com/reach/login.cfm',
            },
            !!walmartAssetSearchURL && {
              message: t`Walmart Asset Search`,
              url: walmartAssetSearchURL,
            },
          ].filter(Boolean),
        },
      ],
    },
  ],
  ({ pathname }) => pathname,
);

/**
 * Filter the list of menu groups, only returning the ones that are
 * appropriate for the given user role.
 * @param {string} role     - one of the roles defined in {USER_ROLES.ALL_ROLES}.
 * @param {object} location - the current react-router location.
 * @return {Array}
 */
export default function buildMenuGroups(role, location) {
  return map(
    getMenusForLocation(location).filter((group) =>
      group.roles.includes(USER_ROLES[role]),
    ),
    'menu',
  );
}

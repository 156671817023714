import React, { PureComponent } from 'react';
import { isEmpty } from 'lodash';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { Container, Column, Row } from 'styled-components-grid';
import { Badge, ButtonIconRectangle, Icon, Text } from 'base-components';

import Dot from 'elements/Dot';

import PropTypes from './prop-types';
import withRemovePaymentMutation from './withRemovePaymentMutation';

export class TokenizedCard extends PureComponent {
  static propTypes = {
    caseId: PropTypes.string.isRequired,
    isReadOnlyCase: PropTypes.bool.isRequired,
    tokenizedCard: PropTypes.tokenizedCard.isRequired,
    removePaymentDetails: PropTypes.func.isRequired,
  };

  render() {
    const {
      caseId,
      isReadOnlyCase,
      tokenizedCard,
      removePaymentDetails,
    } = this.props;
    if (isEmpty(tokenizedCard)) {
      return null;
    }

    const {
      expirationMonth,
      expirationYear,
      firstDigit,
      last4Digits,
      name,
    } = tokenizedCard;

    const expiration = `${expirationMonth}/${expirationYear}`;

    return (
      <Container modifiers={['padScaleX_4', 'padScaleY_2']}>
        <Row modifiers="top">
          <Column modifiers={['padScaleY_0']}>
            <Badge modifiers={['success']}>
              <Badge.Icon name="credit-card-check" />
            </Badge>
          </Column>
          <Column modifiers={['col', 'padScaleY_0']}>
            <Row>
              <Text>{name}</Text>
            </Row>
            <Row>
              <Text>
                {firstDigit}
                <Dot repeat={[3, 4, 4]} />
                {last4Digits}
              </Text>
            </Row>
            <Row>
              <Text>
                <Trans>Expires {expiration}</Trans>
              </Text>
            </Row>
          </Column>
          {isReadOnlyCase || (
            <Column modifiers={['end', 'padScaleY_0']}>
              <ButtonIconRectangle
                type="button"
                onClick={() => removePaymentDetails({ variables: { caseId } })}
                modifiers="hoverDanger"
              >
                <Icon name="trash" />
              </ButtonIconRectangle>
            </Column>
          )}
        </Row>
      </Container>
    );
  }
}

export default compose(
  setDisplayName('TokenizedCard'),
  withRemovePaymentMutation,
)(TokenizedCard);

import gql from 'graphql-tag';
import { get } from 'lodash';
import React from 'react';
import { Query } from '@apollo/client/react/components';

// language=GraphQL
const caseAssignedToQuery = gql`
  query CaseDetailOverviewPanel_CaseAssignUserButton_caseAssignedToQuery(
    $caseNumber: String!
  ) {
    case(caseNumber: $caseNumber) {
      id
      assignedTo {
        email
        name
      }
    }
  }
`;

const withCaseAssignedTo = (WrappedComponent) => (componentProps) => {
  const { caseNumber } = componentProps;
  return (
    <Query
      query={caseAssignedToQuery}
      skip={!caseNumber}
      variables={{ caseNumber }}
      // We always want the most up to date value possible,
      // because the case might have changed its owner while
      // we have it in cache and rerender it from the cache.
      // (loading the case, navigating away and then hitting
      // the back button into the case again, for example).
      fetchPolicy="network-only"
    >
      {({ data, loading }) => {
        const caseId = get(data, 'case.id');
        const caseAssignedTo = get(data, 'case.assignedTo');
        return (
          <WrappedComponent
            {...componentProps}
            caseId={caseId}
            caseAssignedTo={caseAssignedTo}
            caseAssignedToLoading={loading}
          />
        );
      }}
    </Query>
  );
};

export default withCaseAssignedTo;

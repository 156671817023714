import React from 'react';
import styled from 'styled-components';
import Measure from 'react-measure';
import PropTypes from 'prop-types';
import { pick } from 'lodash';
import { createPortal } from 'react-dom';
import { fromRenderProps } from 'recompose';

import { buildStyledComponent } from 'decisiv-ui-utils';

const measureRoot = document.getElementById('offscreen-measurements-root');

const Container = buildStyledComponent(
  'MeasureContainer',
  styled.div,
  `display: inline-block;`,
);

const MeasureComponentOffscreen = ({
  component: ComponentToMeasure,
  componentProps,
  children,
}) => (
  <Measure bounds>
    {({ measureRef, contentRect: { bounds } }) => (
      <>
        {createPortal(
          <Container ref={measureRef}>
            <ComponentToMeasure {...componentProps} />
          </Container>,
          measureRoot,
        )}
        {children({ size: pick(bounds, 'width', 'height') })}
      </>
    )}
  </Measure>
);

MeasureComponentOffscreen.propTypes = {
  children: PropTypes.func.isRequired,
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  componentProps: PropTypes.shape({}),
};

MeasureComponentOffscreen.defaultProps = { componentProps: {} };

export default MeasureComponentOffscreen;

export const withComponentOffscreenSize = (
  component,
  propName = 'size',
  measuredComponentProps,
) =>
  fromRenderProps(
    (measureProps) => (
      <MeasureComponentOffscreen
        {...measureProps}
        componentProps={measuredComponentProps}
        component={component}
      />
    ),
    ({ size }) => ({ [propName]: size }),
  );

import styled from 'styled-components';
import PropTypes from 'prop-types';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const styles = ({ color }) => `
  background-color: ${color};
  height: ${px2rem(5)};
  margin-right: ${px2rem(1)};
  width: ${px2rem(15)};
`;

const propTypes = {
  color: PropTypes.string.isRequired,
};

const Bar = buildStyledComponent('Bar', styled.div, styles, {
  propTypes,
});

export default Bar;

import React, { Fragment } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { includes, difference } from 'lodash';

import MessageSmall from 'base-components/blocks/MessageSmall';
import { px2rem } from 'decisiv-ui-utils';
import { Column, Row } from 'styled-components-grid';
import { Checkbox, InputGroup, Text } from 'base-components';

import DateSelector from './DateSelector';
import { daysOfWeek } from './constants';
import { formatRecurrenceDays } from '../helpers';

const weekdaysLabelsByMode = {
  update: t`Select the weekdays you want to update:`,
  delete: t`Select the weekdays you want to delete:`,
};

const uncheckedLabelsByMode = {
  update: t`Unchecked days will not be changed.`,
  delete: t`Unchecked days will not be deleted.`,
};

const periodLabelByMode = {
  update: t`Rotation period to update:`,
  delete: t`Rotation period to delete:`,
};

const OccurrenceRangeSelector = (props) => {
  const { endDate, weekdays, startDate, updateDates } = props;
  const { updateWeekdays, initialWeekdays, disabledDaysOfWeek, mode } = props;

  const addedDays = difference(weekdays, initialWeekdays);
  const addedDaysString = addedDays.length
    ? formatRecurrenceDays({
        recurring: addedDays.map((v) => ({ dayOfWeek: v })),
      })
    : '';

  return (
    <Fragment>
      <Row>
        <Column modifiers="col">
          <Text>
            <Trans id={weekdaysLabelsByMode[mode]} />
          </Text>
        </Column>
      </Row>
      <Row modifiers="middle">
        {daysOfWeek.map((day) => (
          <Column key={`occurrence-day-of-week-checkbox-${day.value}`}>
            <Checkbox
              id={`occurrence-day-of-week-checkbox-${day.value}`}
              checked={includes(weekdays, day.value)}
              disabled={includes(disabledDaysOfWeek, day.value)}
              label={<Trans id={day.label} />}
              onChange={() => updateWeekdays(day.value)}
            />
          </Column>
        ))}
      </Row>
      <Row>
        <Column modifiers={['col', 'padScaleY_3']}>
          <MessageSmall style={{ alignItems: 'flex-start' }}>
            <MessageSmall.Icon
              type="info"
              style={{ marginTop: 3 }}
              modifiers={['mini', 'info']}
            />
            <MessageSmall.Text style={{ whiteSpace: 'initial' }}>
              <Trans id={uncheckedLabelsByMode[mode]} />
              {addedDaysString && (
                <Fragment>
                  <br />
                  <Trans>
                    {addedDaysString} will be added to the rotation within the
                    selected period.
                  </Trans>
                </Fragment>
              )}
            </MessageSmall.Text>
          </MessageSmall>
        </Column>
      </Row>

      <Row style={{ marginTop: 5 }}>
        <Column modifiers="col">
          <Text>
            <Trans id={periodLabelByMode[mode]} />
          </Text>
        </Column>
      </Row>
      <Row style={{ marginBottom: px2rem(20) }}>
        <Column modifiers="col">
          <InputGroup.Row>
            <InputGroup.Column modifiers={['col']}>
              <DateSelector
                inputId="fromDate"
                label={<Trans>From date</Trans>}
                placeholder={t`Select date...`}
                onChange={(date) => updateDates(date, null)}
                selected={startDate && moment(startDate).toDate()}
              />
            </InputGroup.Column>
            <InputGroup.Column modifiers={['col']}>
              <DateSelector
                inputId="toDate"
                label={<Trans>To date (optional)</Trans>}
                placeholder={t`Select date...`}
                helpText={
                  !endDate && (
                    <Trans>
                      Leave this empty to select all the future occurrences of
                      this rotation in the selected days of the week
                    </Trans>
                  )
                }
                onChange={(date) => updateDates(null, date)}
                selected={endDate && moment(endDate).toDate()}
                initialVisibleDate={
                  moment(startDate).isBefore(moment())
                    ? new Date()
                    : moment(startDate).toDate()
                }
                disableBeforeDate={
                  moment(startDate).isBefore(moment())
                    ? new Date()
                    : moment(startDate).add(1, 'days').toDate()
                }
                optional
              />
            </InputGroup.Column>
          </InputGroup.Row>
        </Column>
      </Row>
    </Fragment>
  );
};

OccurrenceRangeSelector.propTypes = {
  mode: PropTypes.oneOf(['update', 'delete']),
  weekdays: PropTypes.arrayOf(PropTypes.string).isRequired,
  initialWeekdays: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabledDaysOfWeek: PropTypes.arrayOf(PropTypes.string),
  updateWeekdays: PropTypes.func.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  updateDates: PropTypes.func.isRequired,
};

OccurrenceRangeSelector.defaultProps = {
  mode: 'update',
  startDate: null,
  endDate: null,
  disabledDaysOfWeek: [],
};

export default OccurrenceRangeSelector;

import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import { Row, Column } from 'styled-components-grid';
import { H4, Text, QuickActionButton } from 'base-components';

import ValidationMetadata from './ValidationMetadata';
import ValidationInstructions from '../../ValidationInstructions';
import { modalActions } from '../../constants';

function ValidationFailure(props) {
  const { triggerAction, readOnly } = props;
  const { assetValidationFailureInstructions = '' } = props;

  return (
    <>
      <Row>
        <Column modifiers={['col', 'padScaleX_0']}>
          <H4 modifiers="fontWeightMedium">
            <Trans>No matches</Trans>
          </H4>
        </Column>
      </Row>
      <Row>
        <Column modifiers={['col', 'padScaleX_0']}>
          <Text>
            <Trans>
              Your search did not return any matches.
              <br />
              Click &quot;CLEAR VALIDATION&quot; to enter a different search
              criteria, or follow the fallback instructions as an alternative to
              validate the asset.
            </Trans>
          </Text>
        </Column>
      </Row>

      <Row style={{ marginTop: 15 }}>
        <Column modifiers={['col', 'padScaleX_0']}>
          <H4 modifiers="fontWeightMedium">
            <Trans>Fallback Instructions</Trans>
          </H4>
        </Column>
      </Row>
      <Row>
        <Column modifiers={['col', 'padScaleX_0']}>
          <ValidationInstructions
            text={assetValidationFailureInstructions.trim()}
          />
        </Column>
      </Row>

      <Row style={{ padding: '15px 0 0 0' }}>
        <Column modifiers="padScale_0">
          <QuickActionButton
            onClick={() => triggerAction(modalActions.clear)}
            disabled={readOnly}
            modifiers={[readOnly && 'disabled']}
          >
            <QuickActionButton.Text>
              <Trans>Clear Validation</Trans>
            </QuickActionButton.Text>
          </QuickActionButton>
        </Column>
      </Row>

      <ValidationMetadata {...props} />
    </>
  );
}

ValidationFailure.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  triggerAction: PropTypes.func.isRequired,
  assetValidationFailureInstructions: PropTypes.string.isRequired,
};

export default ValidationFailure;

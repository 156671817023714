import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const modifierConfig = {
  bgDanger: ({ theme }) => `
    background: ${theme.colors.status.danger};
  `,
  bgInfo: ({ theme }) => `
    background: ${theme.colors.status.info};
  `,
  bgSuccess: ({ theme }) => `
    background: ${theme.colors.status.success};
  `,
  bgWarning: ({ theme }) => `
    background: ${theme.colors.status.warning};
  `,
};

const styles = ({ theme }) => `
  align-items: center;
  background: ${theme.colors.status.default};
  border-radius: ${px2rem(30)};
  display: flex;
  justify-content: center;
  height: ${px2rem(60)};
  width: ${px2rem(60)};
`;

const themePropTypes = {
  colors: PropTypes.shape({
    status: PropTypes.shape({
      danger: PropTypes.string.isRequired,
      default: PropTypes.string.isRequired,
      info: PropTypes.string.isRequired,
      success: PropTypes.string.isRequired,
      warning: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default buildStyledComponent('IconBackground', styled.div, styles, {
  modifierConfig,
  themePropTypes,
});

import React from 'react';
import PropTypes from 'prop-types';
import SmoothCollapse from 'react-smooth-collapse';

import { ACCORDION_CONTEXT } from './constants';

function Body({ children, ...rest }, context) {
  const { expanded } = context[ACCORDION_CONTEXT];

  return (
    <SmoothCollapse expanded={expanded} {...rest}>
      {children}
    </SmoothCollapse>
  );
}

Body.contextTypes = {
  [ACCORDION_CONTEXT]: PropTypes.shape({}).isRequired,
};

Body.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Body;

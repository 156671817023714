import { t } from '@lingui/macro';

import buildEnum from 'utils/buildEnum';

export const otherTireTypeKey = 'Other';

export const alternateOptionsApplicationId = '999064';

export const specialTireTypes = buildEnum([
  'Steer',
  'Drive',
  'Trailer',
  otherTireTypeKey,
]);

export const tabTitleByTireType = {
  [specialTireTypes.Steer]: t`Steer`,
  [specialTireTypes.Drive]: t`Drive`,
  [specialTireTypes.Trailer]: t`Trailer`,
  [specialTireTypes.Other]: t`Other`,
};

// List of sizes that are equivalent between Michelin and the competition.
// Keys represent competitor sizes, and values the Michelin equivalents
export const equivalentSizes = {
  '245/70R17.5': '235/75R17.5',
  '245/75R22.5': '235/80R22.5',
  '265/75R22.5': '255/80R22.5',
  '295/75R22.5': '275/80R22.5',
  '285/75R24.5': '275/80R24.5',
};

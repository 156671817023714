import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import moment from 'moment-timezone';
import { trim } from 'lodash';

import { Avatar, Popover, Text } from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Column, Row } from 'styled-components-grid';

import Tag from 'blocks/Tag';

function EditedBadge({ email, name, updatedAt }) {
  return (
    <Popover position="right" showOnHover>
      <Popover.Target>
        <Tag modifiers="inline">
          <Tag.Text>
            <Trans>Edited</Trans>
          </Tag.Text>
        </Tag>
      </Popover.Target>
      <Popover.Content style={{ paddingRight: px2rem(10) }}>
        <Row
          style={{ flexWrap: 'nowrap' }}
          modifiers={['middle', 'padScaleY_2']}
        >
          <Column
            modifiers={['center', 'padScale_0']}
            style={{ width: px2rem(50) }}
          >
            <Avatar modifiers={['small']} email={email} name={name} />
          </Column>
          <Column modifiers={['col', 'padScale_0']}>
            <Row modifiers="padScaleY_0">
              <Column modifiers="padScale_0">
                <Text modifiers={['small', 'textLight']}>
                  {moment(updatedAt)
                    .tz(moment.tz.guess())
                    .format('DD MMM, h:mm A z')}
                </Text>
              </Column>
            </Row>
            <Row modifiers="padScaleY_0">
              <Column modifiers={['col', 'padScale_0']}>
                <Text>{trim(name) || email}</Text>
              </Column>
            </Row>
          </Column>
        </Row>
      </Popover.Content>
    </Popover>
  );
}

EditedBadge.propTypes = {
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
};

export default EditedBadge;

import PropTypes from 'prop-types';
import { withSize } from 'reactive-container';
import { responsiveStyleModifierPropTypes } from 'styled-components-modifiers';

import Container from './Container';
import ReactiveContainer from './ReactiveContainer';

import BaseRow, { MODIFIER_CONFIG as rowModifiersConfig } from './Row';
import BaseColumn, { MODIFIER_CONFIG as columnModifiersConfig } from './Column';

const Row = withSize(BaseRow);
const Column = withSize(BaseColumn);

Row.propTypes = {
  ...BaseRow.propTypes,
  size: PropTypes.string,
  responsiveModifiers: responsiveStyleModifierPropTypes(rowModifiersConfig),
};

Column.propTypes = {
  ...BaseColumn.propTypes,
  size: PropTypes.string,
  responsiveModifiers: responsiveStyleModifierPropTypes(columnModifiersConfig),
};

export { Row, Column, Container, ReactiveContainer };

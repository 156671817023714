import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

const modifierConfig = {
  expanded: ({ theme }) => `
    border-top: solid 1px ${theme.colors.base.chrome200};
  `,
};

const styles = ({ theme }) => `
  align-items: center;
  background-color: ${theme.colors.base.chrome100};
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  height: ${theme.dimensions.leftNavWidthCollapsed};
  justify-content: flex-end;
  left: 0;
  position: absolute;
  right: 0;
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome100: PropTypes.string.isRequired,
      chrome200: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dimensions: PropTypes.shape({
    leftNavWidthCollapsed: PropTypes.string.isRequired,
  }).isRequired,
};

export default buildStyledComponent('Footer', styled.div, styles, {
  modifierConfig,
  themePropTypes,
});

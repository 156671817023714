import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

const styles = ({ theme }) => `
  border-bottom: 1px solid ${theme.colors.base.chrome200};
  cursor: default;
  display: flex;

  &:hover {
    background: ${theme.colors.base.chrome100};
  }

  th, td {
    flex: 1;
  }
`;

const modifierConfig = {
  noHighlightOnHover: () => `
    &:hover {
      background: none;
    }
  `,
  notFlex: () => `
    display: table-row;

    th, td {
      min-width: 0;
    }
  `,
};

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome100: PropTypes.string.isRequired,
      chrome200: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default buildStyledComponent('Table__Row', styled.tr, styles, {
  modifierConfig,
  themePropTypes,
});

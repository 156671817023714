import gql from 'graphql-tag';

const query = gql`
  mutation updateAfterHoursContact(
    $jobTitle: String!
    $name: String!
    $primaryPhoneNumber: String!
    $secondaryPhoneNumber: String
    $id: ID!
  ) {
    updateAfterHoursContact(
      input: {
        id: $id
        patch: {
          jobTitle: $jobTitle
          name: $name
          primaryPhoneNumber: $primaryPhoneNumber
          secondaryPhoneNumber: $secondaryPhoneNumber
        }
      }
    ) {
      contact {
        name
      }
    }
  }
`;

export default query;

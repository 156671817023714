import React, { Component } from 'react';
import { compose, setDisplayName } from 'recompose';

import WithCaseCustomerType from 'compositions/WithCaseCustomerType';
import { CUSTOMER_TYPES } from 'compositions/WithCaseCustomerType/constants';

import {
  withCasePanelStatusActions,
  CASE_PANELS,
  PANEL_STATUSES,
} from 'compositions/CaseStatus';

import PropTypes from '../prop-types';
import reportStatusForNationalCustomer from './national';
import reportStatusForStoreCustomer from './store';
import reportStatusForOtherCustomer from './other';

const { incomplete } = PANEL_STATUSES;
const { NATIONAL, STORE, CUSTOM_STORE, OTHER } = CUSTOMER_TYPES;

const reporterForCustomerType = {
  [NATIONAL.type]: reportStatusForNationalCustomer,
  [STORE.type]: reportStatusForStoreCustomer,
  [CUSTOM_STORE.type]: reportStatusForStoreCustomer,
  [OTHER.type]: reportStatusForOtherCustomer,
};

export class WithPaymentPanelStatusReporting extends Component {
  static propTypes = {
    caseCustomerType: PropTypes.string,
    customer: PropTypes.shape({}),
    paymentMethod: PropTypes.string,
    setCasePanelStatus: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired,
    // These are used by the reporters
    /* eslint-disable react/no-unused-prop-types */
    creditCardRuleAbbreviation: PropTypes.string,
    thirdPartyTransactionDetails: PropTypes.string,
    tokenizedCard: PropTypes.tokenizedCard,
    /* eslint-enable react/no-unused-prop-types */
  };

  static defaultProps = {
    caseCustomerType: undefined,
    creditCardRuleAbbreviation: undefined,
    customer: undefined,
    paymentMethod: undefined,
    thirdPartyTransactionDetails: null,
    tokenizedCard: null,
  };

  static getDerivedStateFromProps(props, state) {
    const { customer } = props;
    const { selectedCustomer } = state;

    if (selectedCustomer && !customer) return { selectedCustomer: undefined };
    if (selectedCustomer !== customer) return { selectedCustomer: customer };

    return null;
  }

  state = { selectedCustomer: this.props.customer };

  componentDidMount() {
    this.updateStatus();
  }

  componentDidUpdate() {
    this.updateStatus();
  }

  updateStatus() {
    const { caseCustomerType, customer, setCasePanelStatus } = this.props;
    const reporter = reporterForCustomerType[caseCustomerType];

    if (!customer) return setCasePanelStatus(incomplete);

    if (reporter) reporter({ ...this.props, ...this.state });
  }

  render() {
    const { children: Child, ...rest } = this.props;

    return <Child {...rest} />;
  }
}

export default compose(
  setDisplayName('withPaymentPanelStatusReporting'),
  withCasePanelStatusActions(CASE_PANELS.payment),
  (WrappedComponent) => (props) => (
    <WithCaseCustomerType {...props}>
      {(innerProps) => (
        <WithPaymentPanelStatusReporting
          {...{ ...props, ...innerProps, children: WrappedComponent }}
        />
      )}
    </WithCaseCustomerType>
  ),
);

import gql from 'graphql-tag';
import { curry, get } from 'lodash';
import React from 'react';
import { Query, Mutation } from '@apollo/client/react/components';

import CustomerWidgetContext from './CustomerWidgetContext';
import updateCaseCustomCustomerMutation from './updateCaseCustomCustomerMutation';

export const buildMutationChild = curry(
  (WrappedComponent, componentProps, updateCaseCustomCustomer) => {
    const {
      caseId,
      setCasePanelComplete,
      updateCustomerWidgetContext,
    } = componentProps;

    return (
      <WrappedComponent
        {...componentProps}
        onUpdateCustomer={({ fleetName, zipCode }) => {
          updateCustomerWidgetContext(
            { customerSearchValue: '', storeCustomerSearchValue: '' },
            () => {
              setCasePanelComplete();
              updateCaseCustomCustomer({
                variables: { caseId, fleetName, zipCode },
              });
            },
          );
        }}
      />
    );
  },
);

function buildWrappedComponentWithMutation(WrappedComponent, componentProps) {
  const { setCasePanelComplete } = componentProps;

  return (
    <Mutation
      mutation={updateCaseCustomCustomerMutation}
      onCompleted={(data) => {
        const customerName = get(
          data,
          'setCaseOtherCustomer.case.customer.name',
        );
        if (customerName) {
          setCasePanelComplete();
        }
      }}
    >
      {buildMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const caseId = get(queryProps, 'data.case.id');
    const customer = get(queryProps, 'data.case.customer');

    return buildWrappedComponentWithMutation(WrappedComponent, {
      ...componentProps,
      caseId,
      customer,
    });
  },
);

function skipQuery({ caseNumber }) {
  return !caseNumber;
}

function buildQueryVariables({ caseNumber }) {
  return { caseNumber };
}

export const CASE_CUSTOM_CUSTOMER_QUERY_NAME = 'customCustomerQuery';

const customCustomerQuery = gql`
  query customCustomerQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      customer(useCachedDataForCase: true) {
        ... on Customer {
          id
          type
          name
        }
        ... on StoreCustomer {
          id
          type
          name
        }
        ... on CustomCustomer {
          type
          name
          zip
        }
        ... on CustomStoreCustomer {
          type
          name
        }
      }
    }
  }
`;

function buildWrappedComponentWithQuery(WrappedComponent, componentProps) {
  const { setCasePanelComplete, setCasePanelIncomplete } = componentProps;
  return (
    <Query
      fetchPolicy="cache-and-network"
      onCompleted={(data) => {
        const customerName = get(data, 'case.customer.name');
        if (customerName) {
          setCasePanelComplete();
        } else {
          setCasePanelIncomplete();
        }
      }}
      query={customCustomerQuery}
      skip={skipQuery(componentProps)}
      variables={buildQueryVariables(componentProps)}
    >
      {buildQueryChild(WrappedComponent, componentProps)}
    </Query>
  );
}

export const buildConsumerChild = curry(
  (WrappedComponent, componentProps, consumerProps) => {
    const {
      caseNumber,
      setCasePanelComplete,
      setCasePanelIncomplete,
      setCasePanelInvalid,
      updateCustomerWidgetContext,
    } = consumerProps;

    return buildWrappedComponentWithQuery(WrappedComponent, {
      ...componentProps,
      caseNumber,
      setCasePanelComplete,
      setCasePanelIncomplete,
      setCasePanelInvalid,
      updateCustomerWidgetContext,
    });
  },
);

const withCustomCustomer = (WrappedComponent) => (componentProps) => (
  <CustomerWidgetContext.Consumer>
    {buildConsumerChild(WrappedComponent, componentProps)}
  </CustomerWidgetContext.Consumer>
);

export default withCustomCustomer;

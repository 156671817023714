import { ButtonIconRectangle } from 'base-components';
import { curry } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Consumer } from '../ModalContext';

export function CloseButton(props) {
  return (
    <ButtonIconRectangle {...props} type="button">
      <ButtonIconRectangle.Icon modifiers={['chrome000']} name="times" />
    </ButtonIconRectangle>
  );
}

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const buildConsumerChild = curry((componentProps, { closeModal }) => (
  <CloseButton {...componentProps} onClick={closeModal} />
));

/* istanbul ignore next */
function CloseButtonWithConsumerProps(componentProps) {
  const consumerChild = buildConsumerChild(componentProps);
  return <Consumer>{consumerChild}</Consumer>;
}

CloseButtonWithConsumerProps.displayName = 'CloseButton';

export default CloseButtonWithConsumerProps;

import React, { useMemo } from 'react';
import { curry, get } from 'lodash';
import { Query, Mutation } from '@apollo/client/react/components';
import { gql, useApolloClient } from '@apollo/client';

import { NAME as ASSET_VALIDATION_QUERY } from 'compositions/CaseAssetValidationPanel/withCaseAssetValidation/withCaseAssetValidationData/query';

import CaseInboundProgramPanelContext from '../CaseInboundProgramPanelContext';

import caseInboundProgramNumberQuery, {
  NAME as CASE_INBOUND_PROGRAM_NUMBER_QUERY,
} from './caseInboundProgramNumberQuery';
import updateCaseInboundProgramNumberIDMutation from './updateCaseInboundProgramNumberIDMutation';

const handleRequestCompletion = curry(
  (updateContext, inboundProgramIdPath, customerNameDataPath, data) => {
    const inboundProgramNumberId = get(data, inboundProgramIdPath);
    const inboundProgramSearchValue = get(data, customerNameDataPath, '');
    const newContext = { inboundProgramSearchValue };

    if (!inboundProgramNumberId) {
      newContext.inboundProgramLocationSearchValue = '';
      newContext.displayInboundProgramLocationSelector = false;
    }

    updateContext(newContext);
  },
);

export const buildMutationChild = curry(
  (programs, WrappedComponent, componentProps, mutate) => {
    const {
      caseId,
      // remove these from the props passed to the WrappedComponent
      caseNumber,
      updateCaseInboundProgramPanelContext: updateCtx,
      ...rest
    } = componentProps;

    const onSelectInboundProgram = (inboundProgramNumberId) => {
      const program = (programs || []).find(
        (p) => p?.id === inboundProgramNumberId,
      );

      if (program) {
        updateCtx({ inboundProgramSearchValue: program.customerName });
      }

      mutate({ variables: { caseId, inboundProgramNumberId } });
    };

    const clearSelectedInboundProgram = () => {
      updateCtx({ inboundProgramSearchValue: '' });
      mutate({ variables: { caseId, inboundProgramNumberId: null } });
    };

    return (
      <WrappedComponent
        {...rest}
        caseId={caseId}
        onSelectInboundProgram={onSelectInboundProgram}
        clearSelectedInboundProgram={clearSelectedInboundProgram}
      />
    );
  },
);

function buildWrappedComponentWithMutation(
  programs,
  WrappedComponent,
  componentProps,
) {
  const { onRequestCompleted } = componentProps;

  return (
    <Mutation
      mutation={updateCaseInboundProgramNumberIDMutation}
      onCompleted={onRequestCompleted(
        'updateCase.case.inboundProgramNumber.id',
        'updateCase.case.inboundProgramNumber.customerName',
      )}
      refetch={[CASE_INBOUND_PROGRAM_NUMBER_QUERY, ASSET_VALIDATION_QUERY]}
    >
      {buildMutationChild(programs, WrappedComponent, componentProps)}
    </Mutation>
  );
}

export const buildQueryChild = curry(
  (programs, WrappedComponent, componentProps, { data }) =>
    buildWrappedComponentWithMutation(programs, WrappedComponent, {
      ...componentProps,
      selectedInboundProgram: get(data, 'case.inboundProgramNumber'),
    }),
);

export function buildWrappedComponentWithQuery(
  programs,
  WrappedComponent,
  componentProps,
) {
  const { onRequestCompleted, caseNumber } = componentProps;

  return (
    <Query
      query={caseInboundProgramNumberQuery}
      variables={{ caseNumber }}
      onCompleted={onRequestCompleted(
        'case.inboundProgramNumber.id',
        'case.inboundProgramNumber.customerName',
      )}
      fetchPolicy="cache-and-network"
    >
      {buildQueryChild(programs, WrappedComponent, componentProps)}
    </Query>
  );
}

export const buildConsumerChild = curry(
  (programs, WrappedComponent, componentProps, consumerProps) => {
    const { caseId, caseNumber, inboundProgramSearchValue } = consumerProps;
    const { updateCaseInboundProgramPanelContext: updateCtx } = consumerProps;

    const onRequestCompleted = handleRequestCompletion(updateCtx);

    return buildWrappedComponentWithQuery(programs, WrappedComponent, {
      ...componentProps,
      caseId,
      caseNumber,
      onRequestCompleted,
      inboundProgramSearchValue,
      updateCaseInboundProgramPanelContext: updateCtx,
    });
  },
);

const withInboundProgramSelect = (WrappedComponent) => (componentProps) => {
  const apolloClient = useApolloClient();

  // prettier-ignore
  const query = gql`
    query allInboundProgramNumbers {
      inboundProgramNumbers { inboundPrograms { id customerName } }
    }
  `;

  const programs = useMemo(() => {
    let data = {};

    try {
      data = apolloClient.readQuery({ query });
    } catch (error) {
      //
    }

    return data?.inboundProgramNumbers?.inboundPrograms;
  }, [query, apolloClient]);

  return (
    <CaseInboundProgramPanelContext.Consumer>
      {buildConsumerChild(programs, WrappedComponent, componentProps)}
    </CaseInboundProgramPanelContext.Consumer>
  );
};

export default withInboundProgramSelect;

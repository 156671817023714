import { t, defineMessage } from '@lingui/macro';
import buildEnum from 'utils/buildEnum';

import { CASE_STATUS } from '../constants';

export const allowedStatuses = [
  CASE_STATUS.dispatch,
  CASE_STATUS.dispatched,
  CASE_STATUS.enRoute,
  CASE_STATUS.arrived,
  CASE_STATUS.rolling,
];

export const labelForStatus = {
  [CASE_STATUS.dispatch]: t`To Dispatch`,
  [CASE_STATUS.dispatched]: t`Dispatched`,
  [CASE_STATUS.enRoute]: t`Dispatched - Enroute`,
  [CASE_STATUS.arrived]: t`Dispatched - Arrived`,
  [CASE_STATUS.rolling]: t`Vehicle Rolling`,
};

export const errorTypes = buildEnum(['futureTime', 'longDowntime']);

export const messageForError = {
  [errorTypes.futureTime]: defineMessage({
    message:
      "Sorry something went wrong. Make sure that your computer's clock is accurate and that the date and time you selected is not in the future.",
  }),
  [errorTypes.longDowntime]: defineMessage({
    message:
      'Warning: You have indicated a downtime of {hours} hours{minutes, plural, =0 {.} =1 {, # minute.} other {, # minutes.}} Are you sure you want to use the selected Roll Time? Please verify if this is correct before proceeding.',
  }),
};

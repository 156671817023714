import React from 'react';
import { Helmet } from 'react-helmet';
import { i18n } from '@lingui/core';

import { Row, Column } from 'styled-components-grid';

import Page from 'blocks/Page';
import ScrollColumn from 'elements/ScrollColumn';
import DealerSearch from 'compositions/TechAdmin/DealerSearch';

import { pageTitles, pageMeta } from '../../constants';

function DealerSearchPage() {
  return (
    <Page modifiers={['bgChrome100', 'flex_column', 'fluid', 'height_100']}>
      <Helmet>
        <title>{i18n._(pageTitles.techAdminDealerSearch)}</title>
        <meta
          name="description"
          content={i18n._(pageMeta.techAdminDealerSearch)}
        />
      </Helmet>
      <DealerSearch>
        <Row>
          <Column modifiers={['col', 'padScale_0']}>
            <DealerSearch.Input />
          </Column>
        </Row>
        <Row modifiers={['flex_1']}>
          <ScrollColumn modifiers={['col', 'padScale_0']}>
            <DealerSearch.Dealers />
          </ScrollColumn>
        </Row>
      </DealerSearch>
    </Page>
  );
}

export default DealerSearchPage;

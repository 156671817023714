import React, { PureComponent, createContext } from 'react';
import produce from 'immer';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';
import { debounce, isEqual, get, map } from 'lodash';

import { immutableToJS } from 'decisiv-ui-utils';

import { setNewCaseNumber } from 'redux/cases/actions';
import { newCaseNumberSelector } from 'redux/cases/selectors';

import USER_ROLES, { PERMISSIONS_BASED_ROLES } from 'features/rbac/constants';
import withUserRole from 'features/rbac/withUserRole';

import withCaseAssignedTo from 'compositions/CaseDetailOverviewPanel/withCaseAssignedTo';
import withCurrentUser from 'compositions/CaseDetailOverviewPanel/withCurrentUser';
import withCaseAssetValidation from 'compositions/CaseAssetValidationPanel/withCaseAssetValidation';

import patchFromChangedProps from 'utils/patchFromChangedProps';

import FixpixPushResultModal from './FixpixPushResultModal';
import withCaseData from './withCaseData';
import withReopenCase from './withReopenCase';
import withUpdateCaseStatus from './withUpdateCaseStatus';

import { CASE_STATUS, PANEL_STATUSES, FIXPIX_PUSH_RESULT } from './constants';
import {
  getPanelStatusesForCaseStatus,
  hasBeenDispatched,
  isFeatureEnabled,
} from './utils';

export const Context = createContext();

const { Provider, Consumer } = Context;

const closedStatuses = [CASE_STATUS.closed, CASE_STATUS.closed_canceled];

const dispatchedStatuses = [
  CASE_STATUS.dispatched,
  CASE_STATUS.enRoute,
  CASE_STATUS.arrived,
  CASE_STATUS.rolling,
];

const readOnlyRoles = [
  ...PERMISSIONS_BASED_ROLES,
  USER_ROLES.ONCALL_ADMIN_READ_ONLY,
];

function getCaseValidationStatus(casePanelStatuses) {
  const allStatuses = new Set(map(casePanelStatuses, 'status.panel'));

  if (allStatuses.has(PANEL_STATUSES.complete) && allStatuses.size === 1) {
    return PANEL_STATUSES.complete;
  }

  if (allStatuses.has(PANEL_STATUSES.invalid)) return PANEL_STATUSES.invalid;

  return PANEL_STATUSES.incomplete;
}

const setPanelStatus = produce(
  (statuses, panelName, panelStatus, fieldsStatus) => {
    const panel = statuses[panelName] || {};
    let finalFieldStatus = fieldsStatus;

    if (typeof fieldsStatus === 'function') {
      finalFieldStatus = fieldsStatus(panel.status);
    }

    panel.status = { panel: panelStatus, fields: finalFieldStatus };
  },
);

const isNewCase = (props) => props.caseNumber === props.newCaseNumber;

// We treat "dispatched" cases and "permissions based roles" as read only,
// to make everything disabled by default, and then we check for specific
// permissions in components where we might still want to allow changes.
const isReadOnlyCase = (props) => {
  const { status, role } = props;

  return (
    readOnlyRoles.includes(USER_ROLES[role]) || closedStatuses.includes(status)
    // TODO: Uncomment when we want to enable readonly after dispatch
    // || hasBeenDispatched(props)
  );
};

const hasClosedStatus = (props) => closedStatuses.includes(props.status);
const hasDispatchedStatus = (props) =>
  dispatchedStatuses.includes(props.status);

/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unused-state */
class CaseStatusContext extends PureComponent {
  static propTypes = {
    caseAssignedTo: PropTypes.shape({
      email: PropTypes.string,
      name: PropTypes.string,
    }),
    caseId: PropTypes.string,
    caseNumber: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    clearNewCaseNumber: PropTypes.func.isRequired,
    createdAt: PropTypes.string.isRequired,
    currentUser: PropTypes.shape({ email: PropTypes.string }),
    fixpixPushResult: PropTypes.string.isRequired,
    isLoadingCaseStatus: PropTypes.bool.isRequired,
    isUpdatingCaseStatus: PropTypes.bool.isRequired,
    newCaseNumber: PropTypes.string,
    reopenCase: PropTypes.func.isRequired,
    servicingDealerTimezone: PropTypes.string,
    status: PropTypes.string.isRequired,
    statusHistory: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    updateCaseStatus: PropTypes.func.isRequired,
    role: PropTypes.string,
    loadingRole: PropTypes.bool.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string),
    requiresAssetValidation: PropTypes.bool.isRequired,
    currentUserLoading: PropTypes.bool.isRequired,
    caseAssignedToLoading: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    role: undefined,
    permissions: [],
    caseAssignedTo: {},
    caseId: undefined,
    currentUser: {},
    newCaseNumber: undefined,
    servicingDealerTimezone: undefined,
  };

  static getDerivedStateFromProps(props, state) {
    let patch = patchFromChangedProps(props, state, [
      'caseAssignedTo',
      'caseId',
      'caseNumber',
      'createdAt',
      'fixpixPushResult',
      'isLoadingCaseStatus',
      'isUpdatingCaseStatus',
      'servicingDealerTimezone',
      'status',
      'role',
      'permissions',
      'requiresAssetValidation',
    ]);

    if (
      get(patch, 'status') ||
      get(patch, 'requiresAssetValidation') !== undefined
    ) {
      patch.casePanelStatuses = getPanelStatusesForCaseStatus(
        props,
        state.casePanelStatuses,
      );
      patch.validationStatus = getCaseValidationStatus(patch.casePanelStatuses);
      patch.isReadOnlyCase = isReadOnlyCase(props);

      if (patch.status === CASE_STATUS.dispatched) {
        patch.showFixpixPushResultModal = false;
      }
    }

    if (
      props.loadingRole ||
      props.currentUserLoading ||
      props.caseAssignedToLoading
    ) {
      patch = { ...patch, isReadOnlyCase: true };
    } else {
      const caseAssignedToCurrentUser = [
        !!props.caseAssignedTo,
        !!props.currentUser,
        props.caseAssignedTo?.email === props.currentUser?.email,
      ].every(Boolean);

      patch = {
        ...patch,
        isReadOnlyCase: isReadOnlyCase(props) || !caseAssignedToCurrentUser,
        caseAssignedToCurrentUser,
      };
    }

    if (!isEqual(props.statusHistory, state.statusHistory)) {
      patch = { ...patch, statusHistory: props.statusHistory };
    }

    if (get(patch, 'role')) {
      patch.isPermissionsBasedRole = PERMISSIONS_BASED_ROLES.includes(
        USER_ROLES[props.role],
      );
    }

    if (get(patch, 'status')) {
      const data = { ...state, ...patch };

      patch.hasClosedStatus = hasClosedStatus(data);
      patch.hasBeenDispatched = hasBeenDispatched(data);
      patch.hasDispatchedStatus = hasDispatchedStatus(data);
    }

    return patch;
  }

  constructor(props) {
    super(props);

    const casePanelStatuses = getPanelStatusesForCaseStatus(props);
    const userRole = USER_ROLES[props.role];

    this.state = {
      caseId: props.caseId,
      caseNumber: props.caseNumber,
      casePanelStatuses,
      createdAt: props.createdAt,
      fixpixPushResult: props.fixpixPushResult,
      isLoadingCaseStatus: props.isLoadingCaseStatus,
      isNewCase: isNewCase(props),
      hasClosedStatus: hasClosedStatus(props),
      isReadOnlyCase: isReadOnlyCase(props),
      hasBeenDispatched: hasBeenDispatched(props),
      hasDispatchedStatus: hasDispatchedStatus(props),
      caseAssignedToCurrentUser: false,
      isUpdatingCaseStatus: props.isUpdatingCaseStatus,
      reopenCase: this.reopenCase,
      servicingDealerTimezone: props.servicingDealerTimezone,
      showFixpixPushResultModal: false,
      status: props.status,
      statusHistory: props.statusHistory,
      updateCasePanelStatusFactory: this.updateCasePanelStatusFactory,
      updateCaseStatus: this.debouncedUpdateCaseStatus,
      validationStatus: getCaseValidationStatus(casePanelStatuses),
      role: props.role,
      permissions: props.permissions,
      isPermissionsBasedRole: PERMISSIONS_BASED_ROLES.includes(userRole),
      requiresAssetValidation: props.requiresAssetValidation,
      isFeatureEnabled: this.isFeatureEnabled,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.newCaseNumber !== this.props.newCaseNumber) {
      // Per the React docs, calling set state here is fine because there is a condition.
      // It does cause a double render, but this change happens very infrequently so it
      // doesn't matter.
      // https://reactjs.org/docs/react-component.html#componentdidupdate

      const newState = isNewCase(this.props);

      if (newState !== this.state.isNewCase) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ isNewCase: newState });
      }
    }
  }

  componentWillUnmount() {
    this.willUnmount = true;

    this.props.clearNewCaseNumber();
  }

  isFeatureEnabled = (fieldId) => isFeatureEnabled(fieldId, this.state);

  updateCasePanelStatusFactory = (panelName) =>
    // Debouncing here allows you to update the status as often
    // as you want with minimal performance hits. It also provides
    // some delay for waiting on API requests to resolve.
    debounce((panelStatus, fieldsStatus) => {
      if (this.willUnmount) return; // Prevent calling setState when un-mounting

      const { casePanelStatuses: statuses, validationStatus } = this.state;
      const currentStatus = get(statuses, `${panelName}.status`) || {};

      if (
        !get(statuses, panelName) ||
        (panelStatus === currentStatus.panel &&
          typeof fieldsStatus !== 'function' &&
          isEqual(fieldsStatus, currentStatus.fields))
      ) {
        return;
      }

      const newStatuses = setPanelStatus(
        statuses,
        panelName,
        panelStatus,
        fieldsStatus,
      );

      const newValidationStatus = getCaseValidationStatus(newStatuses);

      if (
        !isEqual(statuses, newStatuses) ||
        validationStatus !== newValidationStatus
      ) {
        this.setState({
          casePanelStatuses: newStatuses,
          validationStatus: newValidationStatus,
        });
      }
    }, 500);

  reopenCase = () =>
    this.props.reopenCase({ variables: { id: this.props.caseId } });

  updateCaseStatus = (newStatus, params) => {
    const onUpdate =
      newStatus === CASE_STATUS.dispatched
        ? this.handleFixpixResult
        : undefined;

    return this.props
      .updateCaseStatus({
        variables: {
          id: this.props.caseId,
          status: newStatus,
          ...{
            ignoreFixpixFailure: false,
            ...params,
          },
        },
      })
      .then(onUpdate);
  };

  // eslint-disable-next-line react/sort-comp
  debouncedUpdateCaseStatus = debounce(this.updateCaseStatus, 1000, {
    leading: true,
    trailing: false,
  });

  handleFixpixResult = ({ data }) => {
    const status = get(data, 'updateCaseStatus.case.status');
    const pushResult = get(data, 'updateCaseStatus.case.fixpixPushResult');
    const newState =
      pushResult === FIXPIX_PUSH_RESULT.error &&
      status === CASE_STATUS.dispatch;

    if (this.state.showFixpixPushResultModal !== newState) {
      this.setState({ showFixpixPushResultModal: newState });
    }
  };

  hideFixpixResultModal = () => {
    if (this.state.showFixpixPushResultModal) {
      this.setState({ showFixpixPushResultModal: false });
    }
  };

  render() {
    const {
      status,
      updateCaseStatus,
      isUpdatingCaseStatus,
      showFixpixPushResultModal,
    } = this.state;

    const modalProps = {
      status,
      onClose: this.hideFixpixResultModal,
      updateCaseStatus,
      isUpdatingCaseStatus,
    };

    return (
      <Provider value={this.state}>
        <>
          {this.props.children}
          {showFixpixPushResultModal && (
            <FixpixPushResultModal {...modalProps} />
          )}
        </>
      </Provider>
    );
  }
}

function mapStateToProps(state) {
  return {
    newCaseNumber: newCaseNumberSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearNewCaseNumber: () =>
      dispatch(setNewCaseNumber({ caseNumber: undefined })),
  };
}

const CaseStatusContextWithData = compose(
  setDisplayName('CaseStatusContext'),
  withCaseData,
  withReopenCase,
  withUpdateCaseStatus,
  withCaseAssignedTo,
  withCurrentUser,
  withUserRole,
  withCaseAssetValidation,
  connect(mapStateToProps, mapDispatchToProps),
  immutableToJS,
)(CaseStatusContext);

CaseStatusContextWithData.Consumer = Consumer;

export default CaseStatusContextWithData;

import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName, withState } from 'recompose';

import { H3, Popover, Tabs } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import withContext from 'utils/withContext';

import RotationButtons from './RotationButtons';
import RotationDetails from './RotationDetails';
import RecurringDetails from './RecurringDetails';
import RotationModalContext from '../RotationModalContext';
import { EventPropType } from '../../propTypes';
import { isRecurringRotation } from './utils';
import withDeleteExceptionMutation from './withDeleteExceptionMutation';

const contentStyles = { width: 400, paddingBottom: 0, overflow: 'auto' };

export function AfterHoursInfoPopup(props) {
  const { rotation, children, dayIndex, dealerTimezone } = props;
  const { activeTab, setActiveTab, isReadOnly } = props;

  const { afterHoursEvent: event } = rotation;

  const position = dayIndex < 3 ? 'right' : 'left';
  const isRecurring = isRecurringRotation(event);
  const height = isRecurring ? 400 : 360;

  return (
    <Popover style={{ height: '100%' }} position={position}>
      <Popover.Target style={{ cursor: 'pointer' }}>{children}</Popover.Target>
      <Popover.Content>
        <Container
          style={{ ...contentStyles, height }}
          modifiers={['padScaleX_5', 'padScaleY_4']}
        >
          <Row modifiers="middle" style={{ marginBottom: 5 }}>
            <Column modifiers={['col', 'padScale_0']}>
              <H3 modifiers="fontWeightRegular">
                <Trans>ERS Rotation</Trans>
              </H3>
            </Column>
            {!isReadOnly && (
              <RotationButtons {...props} timezone={dealerTimezone} />
            )}
          </Row>
          {!isRecurring && <RotationDetails {...props} />}
          {isRecurring && (
            <Tabs
              activeTab={activeTab}
              onTabChange={(_, index) => setActiveTab(index)}
            >
              <Tabs.Menu>
                <Tabs.List>
                  <Tabs.ListItem modifiers={['spaceEven']}>
                    <Tabs.ListItemButton aria-label="">
                      <Trans>Details</Trans>
                    </Tabs.ListItemButton>
                  </Tabs.ListItem>
                  <Tabs.ListItem modifiers={['spaceEven']}>
                    <Tabs.ListItemButton aria-label="">
                      <Trans>Recurring Details</Trans>
                    </Tabs.ListItemButton>
                  </Tabs.ListItem>
                </Tabs.List>
              </Tabs.Menu>
              <Tabs.Content>
                <RotationDetails {...props} />
                <RecurringDetails {...props} />
              </Tabs.Content>
            </Tabs>
          )}
        </Container>
      </Popover.Content>
    </Popover>
  );
}

AfterHoursInfoPopup.propTypes = {
  // eslint-disable-next-line react/no-typos
  rotation: EventPropType.isRequired,
  children: PropTypes.node.isRequired,
  dayIndex: PropTypes.number.isRequired,
  activeTab: PropTypes.number.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  dealerTimezone: PropTypes.string.isRequired,
};

export default compose(
  setDisplayName('AfterHoursInfoPopup'),
  withState('activeTab', 'setActiveTab', 0),
  withContext(RotationModalContext),
  withDeleteExceptionMutation,
)(AfterHoursInfoPopup);

import React from 'react';
import { curry } from 'lodash';
import { Mutation } from '@apollo/client/react/components';

import { NAME as CASE_DELAYS_QUERY_NAME } from 'compositions/CaseDetailOverviewPanel/withCaseDelays/caseDelaysQuery';
import restoreCaseMutation from './restoreCaseMutation';

const buildCreateMutationChild = curry(
  (WrappedComponent, componentProps, restoreCase) => (
    <WrappedComponent
      {...componentProps}
      restoreCase={() =>
        restoreCase({ variables: { caseId: componentProps.caseId } })
      }
    />
  ),
);

const withRestoreCase = (WrappedComponent) => (componentProps) => (
  <Mutation
    skip={!componentProps.caseId}
    mutation={restoreCaseMutation}
    refetchQueries={[CASE_DELAYS_QUERY_NAME]}
  >
    {buildCreateMutationChild(WrappedComponent, componentProps)}
  </Mutation>
);
export default withRestoreCase;

import styled from 'styled-components';
import { buildStyledComponent } from 'decisiv-ui-utils';

import Icon from './Icon';
import Text from './Text';

const styles = () => `
  align-items: center;
  display: flex;
`;

const MessageSmall = buildStyledComponent('MessageSmall', styled.div, styles);

MessageSmall.Icon = Icon;
MessageSmall.Text = Text;

export default MessageSmall;

import React from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { compose, setDisplayName, onlyUpdateForKeys } from 'recompose';

import { Icon, Tooltip, ButtonIconRectangle } from 'base-components';
import { Row, Column } from 'styled-components-grid';

import withContext from 'utils/withContext';

import { CaseStatusContext } from 'compositions/CaseStatus';
import { CaseShortcut, CASE_SHORTCUT_PANELS } from 'features/keyShortcuts';

import CaseRequestsPanelContext from '../CaseRequestsPanelContext';
import SpecialInstructionsInputField from './InputField';

export function SpecialInstructions({
  specialInstructions,
  isFeatureEnabled,
  updateSpecialInstructions,
}) {
  const handleRemove = () => updateSpecialInstructions();

  const canEdit = isFeatureEnabled('updateSpecialInstructions');
  const isEmpty = specialInstructions === null;

  if (isEmpty) {
    // Render some whitespace.
    return (
      <Row>
        <Column />
      </Row>
    );
  }

  return (
    <Row>
      <Column modifiers={['col', 'padScaleX_2', 'padScaleY_3']}>
        <CaseShortcut
          action={{
            parent: CASE_SHORTCUT_PANELS.request,
            id: `specialInstructionsAction`,
            name: t`Special Instructions`,
            shortcut: ['s', 'i'],
            priority: 3,
            icon: 'pencil',
          }}
          passRef="onFocusRequested"
        >
          <SpecialInstructionsInputField readOnly={!canEdit} />
        </CaseShortcut>
      </Column>

      {canEdit && (
        <Column modifiers={['end', 'fluid', 'padScaleX_3', 'padScaleY_3']}>
          <Tooltip position="left">
            <Tooltip.Target>
              <ButtonIconRectangle
                type="button"
                onClick={handleRemove}
                modifiers="hoverDanger"
              >
                <Icon name="trash" />
              </ButtonIconRectangle>
            </Tooltip.Target>
            <Tooltip.Content>
              <Trans>Remove Instructions</Trans>
            </Tooltip.Content>
          </Tooltip>
        </Column>
      )}
    </Row>
  );
}

SpecialInstructions.propTypes = {
  isFeatureEnabled: PropTypes.func.isRequired,
  specialInstructions: PropTypes.string,
  updateSpecialInstructions: PropTypes.func.isRequired,
  onShortcutFocusRequested: PropTypes.func,
};

SpecialInstructions.defaultProps = {
  specialInstructions: null,
};

export default compose(
  setDisplayName('SpecialInstructions'),
  withContext(CaseRequestsPanelContext),
  withContext(CaseStatusContext),
  onlyUpdateForKeys([
    'status',
    'specialInstructions',
    'caseAssignedToCurrentUser',
  ]),
)(SpecialInstructions);

import styled, { css } from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

export const WarningIndicator = buildStyledComponent(
  'WarningIndicator',
  styled.div,
  css`
    top: 2px;
    right: 2px;
    border: 4px solid transparent;
    position: absolute;
    border-top-color: #0492d2;
    border-right-color: #0492d2;
  `,
);

export default buildStyledComponent(
  'IndicatorWrapper',
  styled.div,
  css`
    flex: 1 1 auto;
    cursor: default;
    padding: 0;
    box-sizing: content-box;

    .indicator-content:hover {
      background-color: rgba(51, 51, 51, 0.2);
    }
  `,
);

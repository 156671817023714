import gql from 'graphql-tag';

export const NAME = 'caseCallHistory';

export default gql`
  query caseCallHistory($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      unifiedHistory(
        first: 10000
        types: [CASE_CALL, CASE_AUTOMATED_CALL_ENTRY]
      ) {
        edges {
          node {
            ... on CaseCall {
              id
              amazonConnectContactId
              destinationName
              destinationNumber
              destinationOrganization
              direction
              sourceName
              sourceNumber
              sourceOrganization
              startTime

              user {
                name
                email
                userName
              }
            }
            ... on CaseAutomatedCallEntry {
              id
              user {
                name
                email
                userName
              }
              recordedAt
              callAttributes {
                type
                status
                contactName
                contactType
                callMessage
                contactPhoneNumber
                contactPhoneExtension
              }
            }
          }
        }
      }
    }
  }
`;

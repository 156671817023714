import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { parsePhoneNumber } from 'libphonenumber-js';
import { debounce, memoize } from 'lodash';

import { CallAndLinkToCase } from 'compositions/CaseCallLinks';

const WithClickToCall = (WrappedComponent) =>
  class OutboundCallTrigger extends Component {
    static propTypes = {
      phone: PropTypes.string.isRequired,
      name: PropTypes.string,
      dealer: PropTypes.shape({}),
      dealerContact: PropTypes.shape({}),
      onOutboundCall: PropTypes.func,
      phoneNormalizer: PropTypes.func,
    };

    static defaultProps = {
      name: null,
      dealer: null,
      dealerContact: null,
      onOutboundCall: null,
      phoneNormalizer: (phone) => {
        try {
          return parsePhoneNumber(phone, 'US').number;
        } catch (e) {
          return '';
        }
      },
    };

    /**
     * Parsing the phone number might be a rather computationally-intense
     * process. We should only do it if the phone prop changes.
     */
    phoneNumber = memoize(
      () => this.props.phoneNormalizer(this.props.phone),
      () => this.props.phone,
    );

    /**
     * Create an event handler function which will dial the outbound
     * call and then trigger the `onOutboundCall` callback.
     * @param callAndLinkToCase {Function}
     * @return {Function}
     */
    placeCall = debounce(
      (callAndLinkToCase) => {
        if (!callAndLinkToCase) return;

        const { name, onOutboundCall, dealer, dealerContact } = this.props;

        callAndLinkToCase(name);

        if (onOutboundCall) {
          onOutboundCall({
            phoneNumber: this.phoneNumber(),
            dealer,
            dealerContact,
          });
        }
      },
      3000,
      { leading: true, trailing: false },
    );

    render() {
      const { name, dealer, dealerContact } = this.props;
      return (
        <CallAndLinkToCase
          phoneNumber={this.phoneNumber()}
          dealer={dealer}
          dealerContact={dealerContact}
          name={name}
        >
          {({ contactNumber, callAndLinkToCase }) => (
            <WrappedComponent
              {...this.props}
              currentCallNumber={contactNumber}
              enabled={!!callAndLinkToCase}
              phoneNumber={this.phoneNumber()}
              placeCall={() => this.placeCall(callAndLinkToCase)}
            />
          )}
        </CallAndLinkToCase>
      );
    }
  };

export default WithClickToCall;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName, withState, mapProps } from 'recompose';

import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';
import { H4, Badge, Icon, Tooltip, ButtonIconRectangle } from 'base-components';

import withContext from 'utils/withContext';
import Panel from 'blocks/Panel';
import { CaseStatusContext } from 'compositions/CaseStatus';

import CaseHeadsUpNoteModal from './CaseHeadsUpNoteModal';
import withCaseHeadsUpNote from './withCaseHeadsUpNote';

const panelStyles = { borderRadius: px2rem(2), overflow: 'hidden' };
const badgeStyles = {
  width: px2rem(50),
  height: '100%',
  minHeight: px2rem(50),
  borderRadius: 0,
};

const CenteredColumn = styled(Column)`
  align-self: center;
`;

export function CaseHeadsUpNoteBar(props) {
  const { toggleModal, isFeatureEnabled } = props;
  const { showModal, caseNumber, deleteNote, headsUpNote } = props;

  if (!headsUpNote) return null;

  const openModal = () => toggleModal(true);
  const closeModal = () => toggleModal(false);
  // If we get a value, we close the modal, if not, this component
  // will unmount and remove the modal with it. If we always called
  // `closeModal`, we would get a warning about calling setState on
  // an unmounted component and a memory leak.
  const onSave = (value) => value && closeModal();

  const canEdit = isFeatureEnabled('manageHeadsUpNote');

  return (
    <Row>
      <Column
        style={{ marginTop: px2rem(16) }}
        modifiers={['col', 'padScaleX_4', 'padScaleY_0']}
      >
        <Panel modifiers="padScale_0" style={panelStyles}>
          <Row modifiers="padScale_0">
            <Column modifiers="padScale_0">
              <Badge modifiers="info" style={badgeStyles}>
                <Badge.Icon name="info-circle" modifiers="chrome000" />
              </Badge>
            </Column>
            <CenteredColumn modifiers={['col', 'padScaleX_4', 'padScaleY_0']}>
              <H4 modifiers="fontWeightRegular">{headsUpNote}</H4>
            </CenteredColumn>
            {canEdit && (
              <>
                <CenteredColumn modifiers="end">
                  <Tooltip position="left">
                    <Tooltip.Target>
                      <ButtonIconRectangle type="button" onClick={openModal}>
                        <Icon name="pencil" />
                      </ButtonIconRectangle>
                    </Tooltip.Target>
                    <Tooltip.Content>
                      <Trans>Edit Heads-Up</Trans>
                    </Tooltip.Content>
                  </Tooltip>
                </CenteredColumn>
                <CenteredColumn
                  style={{ paddingRight: px2rem(16) }}
                  modifiers="end"
                >
                  <Tooltip position="left">
                    <Tooltip.Target>
                      <ButtonIconRectangle
                        type="button"
                        onClick={deleteNote}
                        modifiers="hoverDanger"
                      >
                        <Icon name="trash" />
                      </ButtonIconRectangle>
                    </Tooltip.Target>
                    <Tooltip.Content>
                      <Trans>Delete Heads-Up</Trans>
                    </Tooltip.Content>
                  </Tooltip>
                </CenteredColumn>
              </>
            )}
          </Row>

          {showModal && (
            <CaseHeadsUpNoteModal
              onSave={onSave}
              onClose={closeModal}
              caseNumber={caseNumber}
            />
          )}
        </Panel>
      </Column>
    </Row>
  );
}

CaseHeadsUpNoteBar.propTypes = {
  showModal: PropTypes.bool.isRequired,
  caseNumber: PropTypes.string.isRequired,
  deleteNote: PropTypes.func.isRequired,
  headsUpNote: PropTypes.string,
  toggleModal: PropTypes.func.isRequired,
  isFeatureEnabled: PropTypes.func.isRequired,
};

CaseHeadsUpNoteBar.defaultProps = {
  headsUpNote: null,
};

export default compose(
  setDisplayName('CaseHeadsUpNoteBar'),
  withCaseHeadsUpNote,
  withContext(CaseStatusContext),
  withState('showModal', 'toggleModal', false),
  mapProps(({ updateCaseHeadsUpNote, ...rest }) => ({
    ...rest,
    deleteNote: () => updateCaseHeadsUpNote(null),
  })),
)(CaseHeadsUpNoteBar);

import gql from 'graphql-tag';

export const NAME = 'userRoleQuery';

export default gql`
  query userRoleQuery {
    currentUserProfile {
      user {
        email
        name
        userName
        firstName
        lastName
      }
      role {
        name
        permissions
      }
    }
  }
`;

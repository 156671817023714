import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { defineMessage, Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';

import { Column, Row } from 'styled-components-grid';
import { P, H2, Text, QuickActionButton } from 'base-components';

import Modal from 'components/Modal';

import { AfterHoursEventPropType } from '../../propTypes';
import { daysOfWeekValueTransformer } from '../AfterHoursInfoPopup/utils';

const Emphasis = <Text modifiers="fontWeightMedium" />;

const periodSentences = {
  singleDay: defineMessage({ message: '{startDateTime} - {endTime}' }),
  datesOnly: defineMessage({ message: '{startDateTime} - {endDateTime}' }),
  withDaysOfWeek: defineMessage({
    message: '{startDateTime} - {endDateTime}, on {daysOfWeek}',
  }),
};

const getDateTime = (date, time, tz) => moment.tz(`${date} ${time}`, tz);

const formatDateTime = (dateTime) => dateTime.format('DD MMM, h:mm A z');

function ExceptionConflictModal(props) {
  const { event, onClose, dealerTimezone } = props;
  const { exception, originalEvent } = event;
  const { startDate, endDate, daysOfWeek } = exception;
  const { startTime, endTime } = originalEvent;

  const startDateTime = getDateTime(startDate, startTime, dealerTimezone);
  const endDateTime = getDateTime(endDate, endTime, dealerTimezone);

  const periodDates = [
    startDate === endDate && periodSentences.singleDay,
    daysOfWeek && daysOfWeek.length && periodSentences.withDaysOfWeek,
    periodSentences.datesOnly,
  ].find((t) => t);

  const periodSentence = i18n._(periodDates, {
    startDateTime: formatDateTime(startDateTime),
    endDateTime: formatDateTime(endDateTime),
    endTime: endDateTime.format('h:mm A z'),
    daysOfWeek: daysOfWeekValueTransformer(daysOfWeek),
  });

  return (
    <Modal onClose={onClose}>
      {() => (
        <Modal.Body>
          <Modal.Header modifiers={['danger']}>
            <Modal.HeaderIcon name="calendar-exclamation" />
          </Modal.Header>
          <Modal.Content>
            <Row modifiers="center">
              <Column>
                <H2 modifiers="fontWeightRegular">
                  <Trans>Conflict Found</Trans>
                </H2>
              </Column>
            </Row>
            <Row modifiers="center" style={{ margin: '10px 0' }}>
              <Column modifiers="col">
                <P>
                  <Trans
                    id="There is one or more After-Hours Rotations happening during this period: <0>{period}</0>."
                    values={{ period: periodSentence }}
                    components={[Emphasis]}
                  />
                </P>
                <br />
                <P>
                  <Trans>
                    Delete or adjust the conflicting rotations before
                    re-activating an After-Hours Rotation.
                  </Trans>
                </P>
              </Column>
            </Row>
          </Modal.Content>
          <Modal.Footer>
            <Row modifiers="end">
              <Column>
                <QuickActionButton onClick={onClose}>
                  <QuickActionButton.Text>
                    <Trans>Close</Trans>
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
            </Row>
          </Modal.Footer>
        </Modal.Body>
      )}
    </Modal>
  );
}

ExceptionConflictModal.propTypes = {
  event: PropTypes.shape({
    exception: PropTypes.shape({
      daysOfWeek: PropTypes.arrayOf(PropTypes.string),
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }).isRequired,
    /* eslint-disable react/no-typos */
    originalEvent: AfterHoursEventPropType.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  dealerTimezone: PropTypes.string.isRequired,
};

export default ExceptionConflictModal;

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import Icon from './Icon';
import Item from './Item';
import ItemButton from './ItemButton';
import ItemLink from './ItemLink';

const styles = ({ theme }) => `
  border-bottom: solid ${px2rem(2)} ${theme.colors.base.chrome200};
  display: flex;
  flex: 1;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome200: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const TabList = buildStyledComponent('TabList', styled.ul, styles, {
  themePropTypes,
});

TabList.Icon = Icon;
TabList.Item = Item;
TabList.ItemButton = ItemButton;
TabList.ItemLink = ItemLink;

export default TabList;

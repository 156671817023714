import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName } from 'recompose';
import { Trans } from '@lingui/macro';

import { Icon } from 'base-components';

import StyledTopNav from 'blocks/TopNav';

import CaseLink from 'elements/CaseLink';

import withCallCaseLinkQuery from './withCallCaseLinkQuery';

export function CallLinkInfo({ callLinkLoading, linkedCase }) {
  if (callLinkLoading) {
    return null;
  }

  return (
    <>
      <StyledTopNav.Text modifiers={['fontWeightMedium', 'text', 'gapRight']}>
        <Icon name={linkedCase ? 'link' : 'unlink'} modifiers="mini" />
      </StyledTopNav.Text>
      <StyledTopNav.Text modifiers="small">
        {linkedCase ? (
          <Trans>
            Linked to case <CaseLink caseNumber={linkedCase.caseNumber} />
          </Trans>
        ) : (
          <Trans>Not linked to a case</Trans>
        )}
      </StyledTopNav.Text>
    </>
  );
}

CallLinkInfo.propTypes = {
  callLinkLoading: PropTypes.bool.isRequired,
  linkedCase: PropTypes.shape({
    caseNumber: PropTypes.string.isRequired,
  }),
};

CallLinkInfo.defaultProps = {
  linkedCase: null,
};

export default compose(
  setDisplayName('CallLinkInfo'),
  withCallCaseLinkQuery,
)(CallLinkInfo);

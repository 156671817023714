import React from 'react';
import { Text } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';
import PropTypes from 'prop-types';

function ContactGroupLabel({ children }) {
  return (
    <Container className="ContactGroupLabel" modifiers={['padScaleX_0']}>
      <Row>
        <Column modifiers={['padScaleY_0']}>
          <Text modifiers={['small', 'textLight']}>{children}</Text>
        </Column>
      </Row>
    </Container>
  );
}

ContactGroupLabel.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContactGroupLabel;

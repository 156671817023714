import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compact } from 'lodash';

import { Text } from 'base-components';

import { evaluateOperatingStatusType } from 'utils/operatingStatusFilter';

function DealerOperatingStatusLabel({ dealer, now }) {
  const { modifier, value } = evaluateOperatingStatusType(dealer, now);

  return (
    <Text modifiers={compact(['fontWeightMedium', modifier])}>
      <Trans id={value} />
    </Text>
  );
}

DealerOperatingStatusLabel.propTypes = {
  now: PropTypes.shape({}).isRequired,
  dealer: PropTypes.shape({}).isRequired,
};

export default DealerOperatingStatusLabel;

import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { defineMessage, Trans, Plural } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { Text } from 'base-components';
import { Column, Container, Row } from 'styled-components-grid';

import withContext from 'utils/withContext';

import DealerLocatorContext from '../DealerLocatorContext';
import FeaturesFilterSelector from './FeaturesFilterSelector';
import ProximityFilterSelector from './ProximityFilterSelector';

const resultsMessage = defineMessage({
  message:
    '<0>{total}</0> <1></1> for Lat <2>{latitude}</2> Long <3>{longitude}</3>',
});

const highlightText = (
  <Text modifiers={['small', 'textLight', 'fontWeightMedium']} />
);

export const SearchFiltersComponent = ({ dealers, locationSearches }) => {
  const location = get(locationSearches, '0.location') || {};
  const resultsComponent = (
    <Plural value={dealers.length} one="Result" other="Results" />
  );

  return (
    <Container>
      <Row modifiers="middle">
        <Column>
          <ProximityFilterSelector />
        </Column>
        <Column>
          <FeaturesFilterSelector />
        </Column>
        {location.latitude && location.longitude && (
          <Column style={{ whiteSpace: 'nowrap' }} modifiers={['col', 'end']}>
            <Text modifiers={['small', 'textLight']}>
              <Trans
                id={resultsMessage}
                components={[
                  highlightText,
                  resultsComponent,
                  highlightText,
                  highlightText,
                ]}
                values={{
                  total: dealers.length,
                  latitude: location.latitude.toFixed(6),
                  longitude: location.longitude.toFixed(6),
                }}
              />
            </Text>
          </Column>
        )}
      </Row>
    </Container>
  );
};

SearchFiltersComponent.propTypes = {
  dealers: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  locationSearches: PropTypes.arrayOf(PropTypes.shape),
};

SearchFiltersComponent.defaultProps = {
  dealers: [],
  locationSearches: [],
};

export default compose(
  setDisplayName('SearchFiltersComponent'),
  withContext(DealerLocatorContext),
)(SearchFiltersComponent);

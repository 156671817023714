import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { px2rem } from 'decisiv-ui-utils';
import { Trans, Plural } from '@lingui/macro';

import { Row, Column } from 'styled-components-grid';
import { Badge, Checkbox, Icon, P, Text } from 'base-components';

import Ul from 'elements/Ul';
import Li from 'elements/Li';

import RadioButton from 'components/RadioButton';

import { formatEventTime, formatRecurrenceDays } from '../helpers';

function createDatetime(date, time) {
  return moment(`${date} ${time}`);
}

function EventConflicts({
  conflictingEvents,
  event: {
    id: eventId,
    isRecurring,
    options: { resolveConflicts, skipConflicts },
  },
  toggleResolveConflicts,
  toggleSkipConflicts,
}) {
  const canSkipConflicts = eventId && isRecurring;

  return (
    <Row modifiers="padScaleY_3">
      <Column>
        <Badge
          modifiers={['danger']}
          style={{
            borderRadius: px2rem(9),
            height: px2rem(18),
            width: px2rem(18),
          }}
        >
          <Icon
            modifiers={['chrome000', 'mini']}
            name="exclamation-triangle"
            style={{ paddingBottom: px2rem(3), paddingLeft: px2rem(1) }}
          />
        </Badge>
      </Column>
      <Column modifiers={['col']}>
        <P style={{ marginBottom: px2rem(10) }}>
          <Plural
            value={conflictingEvents.length}
            one="A rotation conflict was found. Adjust your rotation settings so it doesn't overlap with the following rotation:"
            other="Multiple rotation conflicts were found. Adjust your rotation settings so it doesn't overlap with the following rotations:"
          />
        </P>
        <Ul style={{ marginBottom: px2rem(20) }}>
          {conflictingEvents.map(({ event }) => {
            const { id, startDate, startTime, endDate, endTime } = event;

            const start = createDatetime(startDate, startTime);
            const startString = formatEventTime(start, 'DD MMM YYYY, ');

            const end = createDatetime(endDate, endTime);
            const endString = formatEventTime(end);

            const recurring = get(event, 'isRecurring');
            const recurrenceDays = recurring && formatRecurrenceDays(event);

            return (
              <Li key={`${id}:${start.toISOString()}`}>
                <Text>
                  &middot; {startString} - {endString}
                  {recurring && (
                    <>
                      {' '}
                      <Trans>
                        (this rotation repeats every {recurrenceDays} from this
                        date forward)
                      </Trans>
                    </>
                  )}
                </Text>
              </Li>
            );
          })}
        </Ul>

        <P>
          <Plural
            value={conflictingEvents.length}
            one="You can also choose to either override or skip the conflicting rotation in the date the conflict occurs:"
            other="You can also choose to either override or skip the conflicting rotations in the dates the conflicts occur:"
          />
        </P>
        {canSkipConflicts ? (
          <Row modifiers={['middle', 'padScaleY_3']}>
            <Column modifiers={['padScale_0']}>
              <Row modifiers={['middle', 'padScaleY_1']}>
                <Column modifiers={['padScale_0']}>
                  <RadioButton
                    id="resolve-conflicts"
                    checked={resolveConflicts}
                    onChange={toggleResolveConflicts}
                    label={
                      <Plural
                        value={conflictingEvents.length}
                        one="Override conflicting rotation"
                        other="Override conflicting rotations"
                      />
                    }
                  />
                </Column>
              </Row>
              <Row modifiers={['middle', 'padScaleY_1']}>
                <Column modifiers={['padScale_0']}>
                  <RadioButton
                    id="skip-conflicts"
                    checked={skipConflicts}
                    onChange={toggleSkipConflicts}
                    label={
                      <Plural
                        value={conflictingEvents.length}
                        one="Skip conflicting rotation"
                        other="Skip conflicting rotations"
                      />
                    }
                  />
                </Column>
              </Row>
            </Column>
          </Row>
        ) : (
          <Row modifiers={['middle', 'padScaleY_3']}>
            <Column modifiers={['padScale_0']}>
              <Checkbox
                id="resolve-conflicts"
                checked={resolveConflicts}
                onChange={toggleResolveConflicts}
                label={
                  <Plural
                    value={conflictingEvents.length}
                    one="Override conflicting rotation"
                    other="Override conflicting rotations"
                  />
                }
              />
            </Column>
          </Row>
        )}
      </Column>
    </Row>
  );
}

EventConflicts.propTypes = {
  conflictingEvents: PropTypes.arrayOf(
    PropTypes.shape({
      event: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  ).isRequired,
  toggleResolveConflicts: PropTypes.func.isRequired,
  toggleSkipConflicts: PropTypes.func.isRequired,
  event: PropTypes.shape({
    id: PropTypes.string,
    isRecurring: PropTypes.bool,
    options: PropTypes.shape({
      resolveConflicts: PropTypes.bool,
      skipConflicts: PropTypes.bool,
    }).isRequired,
  }).isRequired,
};

export default EventConflicts;

import React from 'react';
import styled from 'styled-components';

import { Icon } from 'base-components';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const BreadcrumbsDividerWrapper = buildStyledComponent(
  'Breadcrumbs__DividerWrapper',
  styled.span,
  `
    padding: 0 ${px2rem(10)};
  `,
);

function BreadcrumbsDivider() {
  return (
    <BreadcrumbsDividerWrapper>
      <Icon modifiers={['mini']} name="chevron-right" />
    </BreadcrumbsDividerWrapper>
  );
}

BreadcrumbsDivider.displayName = 'Breadcrumbs__Divider';

export default BreadcrumbsDivider;

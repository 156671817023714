import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName } from 'recompose';
import { get, isEmpty } from 'lodash';

import { px2rem } from 'decisiv-ui-utils';
import { sizes } from 'reactive-container';

import {
  Row,
  Column,
  Container,
  ReactiveContainer,
} from 'styled-components-grid/reactive';

import Panel from 'blocks/Panel';

import { WithDirections } from 'features/googleMaps';

import {
  withCasePanelStatusActions,
  CASE_PANELS,
} from 'compositions/CaseStatus';

import AssetLocation from './AssetLocation';
import Dealer from './Dealer';
import InfoAtDispatch from './InfoAtDispatch';
import Map from './Map';
import withClosedCaseDetails from './withClosedCaseDetails';
import { reportAssetLocationStatus } from '../CaseDealerLocatorPanel/utils';

export function CaseDealerLocatorClosedPanel(props) {
  const { caseDetails } = props;

  // To convert the fallback value `null` to an empty object
  const assetLocation = get(caseDetails, 'assetLocation') || {};
  const dealer = get(caseDetails, 'servicingDealer.dealer') || {};
  const {
    assetLocationWeatherAtDispatch,
    dealerTravelEstimateAtDispatch,
  } = caseDetails;

  reportAssetLocationStatus({ ...props, assetLocation });

  return (
    <ReactiveContainer breakpoints={[{ name: sizes.LG, minWidth: 780 }]}>
      <Row>
        <Column
          modifiers={['col_12', 'padScale_0']}
          responsiveModifiers={{ [sizes.LG]: 'col' }}
        >
          <Panel modifiers={['height_100', 'padScale_0']}>
            <AssetLocation assetLocation={assetLocation} />
            <Dealer dealer={dealer} />
          </Panel>
        </Column>
        <Column
          modifiers={['col_12', 'padScale_0']}
          responsiveModifiers={{ [sizes.LG]: 'col' }}
        >
          <Container
            modifiers={['flex_column', 'height_100', 'padScale_0']}
            style={{ minHeight: px2rem(290) }}
          >
            <Row modifiers={['flex_1', 'padScale_0']}>
              <Column modifiers={['col', 'padScale_0']}>
                <WithDirections
                  render={({ directions, displayRoute }) => (
                    <Map
                      assetLocation={get(assetLocation, 'coordinates') || {}}
                      dealerLocation={get(dealer, 'location') || {}}
                      dealer={dealer}
                      directions={directions}
                      displayRoute={displayRoute}
                    />
                  )}
                />
              </Column>
            </Row>
            {(!isEmpty(assetLocationWeatherAtDispatch) ||
              !isEmpty(dealerTravelEstimateAtDispatch)) && (
              <Row modifiers="padScale_0">
                <Column modifiers={['col_12', 'padScale_0']}>
                  <InfoAtDispatch
                    travelEstimate={dealerTravelEstimateAtDispatch}
                    weather={assetLocationWeatherAtDispatch}
                  />
                </Column>
              </Row>
            )}
          </Container>
        </Column>
      </Row>
    </ReactiveContainer>
  );
}

CaseDealerLocatorClosedPanel.propTypes = {
  caseDetails: PropTypes.shape({
    assetLocation: PropTypes.shape({}),
    servicingDealer: PropTypes.shape({}),
    assetLocationWeatherAtDispatch: PropTypes.shape({}),
    dealerTravelEstimateAtDispatch: PropTypes.shape({}),
  }),
};

CaseDealerLocatorClosedPanel.defaultProps = {
  caseDetails: {},
};

export default compose(
  setDisplayName('CaseDealerLocatorClosedPanel'),
  withCasePanelStatusActions(CASE_PANELS.assetLocation),
  withClosedCaseDetails,
)(CaseDealerLocatorClosedPanel);

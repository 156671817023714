import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import { Row } from 'styled-components-grid';
import { Icon, Tooltip, ButtonIconRectangle } from 'base-components';

import ResetButton from '../ResetButton';

function AgreementLineActionCell({
  rowData: { id, agreed, requestLineId },
  resetRequestAgreementLine,
  removeRequestAgreementLine,
  requestAgreementLineHasChanges,
}) {
  if (agreed) return null;

  // This line has an associated requestLine
  if (requestLineId) {
    const isDisabled = !requestAgreementLineHasChanges(id);

    return (
      <ResetButton
        label={<Trans>Reset Row</Trans>}
        onClick={() => resetRequestAgreementLine(id)}
        disabled={isDisabled}
        modifiers={isDisabled && 'disabled'}
      />
    );
  }

  // This line was manually added by the agent
  return (
    <Row modifiers="center">
      <Tooltip position="left">
        <Tooltip.Target>
          <ButtonIconRectangle
            type="button"
            onClick={() => removeRequestAgreementLine(id)}
            modifiers="hoverDanger"
          >
            <Icon name="trash" />
          </ButtonIconRectangle>
        </Tooltip.Target>
        <Tooltip.Content>
          <Trans>Remove Row</Trans>
        </Tooltip.Content>
      </Tooltip>
    </Row>
  );
}

AgreementLineActionCell.propTypes = {
  rowData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    agreed: PropTypes.bool,
    requestLineId: PropTypes.string,
  }).isRequired,
  resetRequestAgreementLine: PropTypes.func.isRequired,
  removeRequestAgreementLine: PropTypes.func.isRequired,
  requestAgreementLineHasChanges: PropTypes.func.isRequired,
};

export default AgreementLineActionCell;

import React from 'react';
import gql from 'graphql-tag';
import { get } from 'lodash';
import { Query } from '@apollo/client/react/components';

const query = gql`
  query hasCustomerMismatchQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      highlights {
        id
        type
      }
    }
  }
`;

function withHasCustomerMismatch(WrappedComponent) {
  return (p) => {
    const { caseNumber } = p;

    return (
      <Query skip={!caseNumber} query={query} variables={{ caseNumber }}>
        {({ data }) => {
          const items = get(data, 'case.highlights') || [];
          const hasMismatch = items.some((h) => h.type === 'CUSTOMER_MISMATCH');

          return <WrappedComponent {...p} hasCustomerMismatch={hasMismatch} />;
        }}
      </Query>
    );
  };
}

export default withHasCustomerMismatch;

import React from 'react';
import PropTypes from 'prop-types';
import { Marker } from 'react-google-maps';
import { compose, setDisplayName } from 'recompose';

import { mapIcons } from 'assets/images/mapIcons';

import withGeocoder from 'features/googleMaps/withGeocoder';
import {
  geocodeSearchValue,
  getLocationSearchUpdates,
} from 'compositions/DealerLocator/withLocations/utils';

export function AssetMarker({
  assetLocation,
  onAssetLocationChange,
  zIndex,
  reverseGeocode,
  draggable,
}) {
  const onDragEnd = async ({ latLng }) => {
    try {
      const location = { latitude: latLng.lat(), longitude: latLng.lng() };
      const response = await geocodeSearchValue({
        latLng: location,
        reverseGeocode,
      });

      onAssetLocationChange(getLocationSearchUpdates(response, location));
    } catch (e) {
      onAssetLocationChange(undefined);
    }
  };

  return (
    <Marker
      cursor="-webkit-grab"
      draggable={draggable}
      icon={{
        url: mapIcons.asset,
      }}
      onDragEnd={onDragEnd}
      position={{
        lat: assetLocation.latitude,
        lng: assetLocation.longitude,
      }}
      zIndex={zIndex}
    />
  );
}

AssetMarker.propTypes = {
  assetLocation: PropTypes.shape({
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
  }).isRequired,
  onAssetLocationChange: PropTypes.func.isRequired,
  zIndex: PropTypes.number.isRequired,
  reverseGeocode: PropTypes.func.isRequired,
  draggable: PropTypes.bool.isRequired,
};

export default compose(
  setDisplayName('AssetMarker'),
  withGeocoder,
)(AssetMarker);

/**
 * FIXME: Testing Portals is not well supported at this time. Since there is
 * minimal logic in this component coverage reporting has been ignored.
 */
/* istanbul ignore file */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

import Body from './Body';
import Content from './Content';
import Footer from './Footer';
import Header from './Header';
import HeaderIcon from './HeaderIcon';
import Overlay from './Overlay';
import { Provider, Consumer } from './ModalContext';

function modalRoot() {
  return document.getElementById('modal-root');
}

class Modal extends Component {
  static Body = Body;

  static Header = Header;

  static HeaderIcon = HeaderIcon;

  static Footer = Footer;

  static Content = Content;

  static propTypes = {
    children: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.el = document.createElement('div');

    this.state = { closeModal: this.props.onClose };
  }

  componentDidMount() {
    modalRoot().appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot().removeChild(this.el);
  }

  render() {
    const { onClose, ...rest } = this.props;

    return createPortal(
      <Provider value={this.state}>
        <Overlay {...rest}>{this.props.children(this.state)}</Overlay>
      </Provider>,
      this.el,
    );
  }
}

export { Consumer };

export default Modal;

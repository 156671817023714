import { css } from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import { Label as DefaultLabel } from '../../elements';

const modifierConfig = {
  withContent: () => ({
    styles: css`
      span::before {
        margin-right: ${px2rem(5)};
      }
    `,
  }),
};

const styles = ({ theme }) => css`
  span {
    align-items: center;
    color: ${theme.colors.base.textLight};
    display: flex;
    text-transform: inherit;
  }
`;

export default buildStyledComponent('Checkbox__Label', DefaultLabel, styles, {
  modifierConfig,
});

import gql from 'graphql-tag';

export const NAME = 'resourceCheckQuery';

export default gql`
  query resourceCheckQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
    }
  }
`;

import PropTypes from 'prop-types';
import { Component } from 'react';
import { compose, setDisplayName } from 'recompose';

import {
  withCasePanelStatusActions,
  withReadOnlyCase,
  CASE_PANELS,
} from 'compositions/CaseStatus';

import { CUSTOMER_TYPES, CUSTOMER_TYPE_OPTIONS } from './constants';
import withCaseCustomerType from './withCaseCustomerType';

/**
 * Evaluate the available props and state and determine the customerType
 * value for the first/ next render cycle.
 *
 * @param {object} props the component's current props
 * @property {string} props.caseCustomerType the customer type currently saved onto the case
 * @param {object} [state={}] the component's current state
 * @property {string} state.customerType the current local customer type
 * @property {boolean} state.useCustomerType a flag controlling if local or persisted customer type
 *                     is preferred
 * @returns {string} the customer type for the next render cycle
 */
function getCustomerType(
  { caseCustomerType },
  { customerType, useCustomerType } = {},
) {
  // If directed, prefer the local version of the customer type.
  if (useCustomerType) {
    return customerType;
  }

  // Otherwise, always prefer the customer type saved to the case (or the default).
  return caseCustomerType;
}

/* eslint-disable react/no-unused-state */
class WithCaseCustomerType extends Component {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    caseNumber: PropTypes.string.isRequired,
    caseCustomerType: PropTypes.oneOf([...Object.keys(CUSTOMER_TYPES), null]),
    children: PropTypes.func,
    isReadOnlyCase: PropTypes.bool.isRequired,
    render: PropTypes.func,
    resetCaseCustomer: PropTypes.func.isRequired,
    setCasePanelIncomplete: PropTypes.func.isRequired,
    caseCustomerTypeLoading: PropTypes.bool.isRequired,
    resetCaseCustomerLoading: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    caseCustomerType: null,
    children: undefined,
    render: undefined,
  };

  static getDerivedStateFromProps(props, state) {
    const customerType = getCustomerType(props, state);

    /**
     * The intent is to use the local customerType _only_ if it is different
     * from props.caseCustomerType. If/ when the two match up again,
     * we should turn the local preference off. An example of this might
     * be when a new customer of a different type is saved to the case.
     */
    if (
      !props.caseCustomerTypeLoading &&
      !props.resetCaseCustomerLoading &&
      props.caseCustomerType === customerType
    ) {
      return {
        customerType: customerType || CUSTOMER_TYPES.NATIONAL.type,
        useCustomerType: false,
      };
    }

    if (props.caseCustomerType !== customerType) {
      return { customerType: customerType || CUSTOMER_TYPES.NATIONAL.type };
    }

    return null;
  }

  constructor(props) {
    super(props);

    const customerType = getCustomerType(props);

    this.state = {
      customerType: customerType || CUSTOMER_TYPES.NATIONAL.type,
      useCustomerType: false,
    };
  }

  handleClearCaseCustomer = () => {
    const { customerType } = this.state;
    this.props.resetCaseCustomer();
    this.props.setCasePanelIncomplete();
    this.setState({ customerType, useCustomerType: true });
  };

  /**
   * Saves a new value for `customerType` (the locally tracked version of the customer type).
   * This method will also remove any saved customer on the case, and set the case's
   * customer status to "incomplete".
   *
   * @param {string} customerType must be one of the valid customerTypes based on the CUSTOMER_TYPES
   * @memberof WithCaseCustomerType
   */
  updateCustomerType = (customerType) => {
    if (!Object.keys(CUSTOMER_TYPES).includes(customerType)) {
      throw Error(`${customerType} is an invalid customer type.`);
    }

    this.setState({ customerType, useCustomerType: true }, () => {
      this.props.resetCaseCustomer();
      this.props.setCasePanelIncomplete();
    });
  };

  render() {
    const { children, render } = this.props;
    const renderFunc = render || children;

    return renderFunc({
      // This is an object containing each type by key, with the type and label
      CUSTOMER_TYPES,
      // This is the customer types placed in the preferred order for display.
      CUSTOMER_TYPE_OPTIONS,
      /**
       * `caseCustomerType` indicates that a customer of this type has been saved.
       * Values may include one of the type values found in CUSTOMER_TYPES or null.
       * This value should be used when you need to know what customer type is saved
       * to the case.
       */
      caseCustomerType: this.props.caseCustomerType,
      /**
       * `clearCaseCustomer` is used to clear the customer but leave the customer type
       */
      clearCaseCustomer: this.handleClearCaseCustomer,
      /**
       * `customerType` is the "draft" value. It is seeded by the `caseCustomerType`,
       * but may not match exactly. Values may only be one of the type values found in
       * CUSTOMER_TYPES. This value should be used when you need to update UI based in
       * order to save the customer.
       */
      customerType: this.state.customerType,
      /**
       *  if the case is "closed" or "closed-canceled" status, the value of `isReadOnly` is true.
       */
      isReadOnlyCase: this.props.isReadOnlyCase,
      /**
       * `updateCustomerType` allows you to set the "draft" customerType to a
       * different value. Any previously saved case customer data will be cleared.
       */
      updateCustomerType: this.updateCustomerType,
    });
  }
}

export default compose(
  setDisplayName('WithCaseCustomerType'),
  withCaseCustomerType,
  withCasePanelStatusActions(CASE_PANELS.customer),
  withReadOnlyCase,
)(WithCaseCustomerType);

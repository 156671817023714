/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export default function Dot({ repeat, className }) {
  const groups = Array.isArray(repeat) ? repeat : [repeat];

  return (
    <span className={className}>
      {groups.map((count, groupIndex, array) => (
        <Fragment key={groupIndex}>
          {Array.from({ length: count }).map(() => '●')}
          {groupIndex !== array.length - 1 ? ' ' : null}
        </Fragment>
      ))}
    </span>
  );
}

Dot.propTypes = {
  // To support extending with styled-components
  className: PropTypes.string,
  repeat: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
};

Dot.defaultProps = {
  repeat: 1,
  className: undefined,
};

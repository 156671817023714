import styled from 'styled-components';
import { Container } from 'styled-components-grid';

import { buildStyledComponent } from 'decisiv-ui-utils';

// this acts as our <tbody> element
const styles = () => `
  flex: 1;
`;

export default buildStyledComponent('Body', styled(Container), styles);

import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

export default buildStyledComponent(
  'StatusCheckbox__ToggleButton',
  styled.div,
  ({ theme }) => css`
    align-items: center;
    background-color: ${theme.colors.base.chrome100};
    border-radius: ${px2rem(2)};
    border: 1px solid ${theme.colors.base.chrome200};
    display: flex;
    flex-wrap: nowrap;
    padding-right: ${px2rem(32)};
    padding: ${px2rem(8)} ${px2rem(4)};
    width: 100%;
  `,
  {
    modifierConfig: {
      checked: ({ theme }) => `
        background-color: ${theme.colors.base.selectableActive};
      `,
    },

    themePropTypes: PropTypes.shape({
      colors: PropTypes.shape({
        base: PropTypes.shape({
          chrome100: PropTypes.string.isRequired,
          chrome200: PropTypes.string.isRequired,
          selectableActive: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  },
);

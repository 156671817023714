import PropTypes from 'prop-types';
import React from 'react';

import TooltipBlock from '../../blocks/Tooltip';

import { TOOLTIP_CONTEXT } from './constants';

function Target(props, context) {
  const { onMouseEnterTarget, onMouseLeaveTarget } = context[TOOLTIP_CONTEXT];

  return (
    <TooltipBlock.Target
      onMouseEnter={onMouseEnterTarget}
      onMouseLeave={onMouseLeaveTarget}
      {...props}
    />
  );
}

Target.contextTypes = {
  [TOOLTIP_CONTEXT]: PropTypes.shape({}).isRequired,
};

Target.displayName = 'Tooltip__Target';

Target.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Target;

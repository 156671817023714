import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';
import { Row } from 'styled-components-grid';

const styles = ({ theme }) => `
  background: ${theme.colors.base.chrome100};
  border-radius: 0
                 0
                 ${theme.dimensions.borderRadius}
                 ${theme.dimensions.borderRadius};
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome100: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dimensions: PropTypes.shape({
    borderRadius: PropTypes.string.isRequired,
  }).isRequired,
};

export default buildStyledComponent('Card__Footer', styled(Row), styles, {
  themePropTypes,
});

import gql from 'graphql-tag';
import { curry, get } from 'lodash';
import React from 'react';
import { Query, Mutation } from '@apollo/client/react/components';

import { CUSTOMER_TYPES } from './constants';

const resetCaseCustomerMutation = gql`
  mutation resetCaseCustomer($caseId: ID!) {
    removeCustomerFromCase(input: { id: $caseId }) {
      case {
        id
        fixpixPushResult
        customer(useCachedDataForCase: true) {
          ... on Customer {
            id
            type
            name
          }
          ... on StoreCustomer {
            id
            type
            name
          }
          ... on CustomCustomer {
            type
            name
          }
          ... on CustomStoreCustomer {
            type
            name
          }
        }
        paymentMethod
      }
    }
  }
`;

const buildMutationChild = curry(
  (WrappedComponent, componentProps, resetCaseCustomer, { loading }) => {
    const { caseId } = componentProps;
    return (
      <WrappedComponent
        {...componentProps}
        resetCaseCustomer={() => {
          resetCaseCustomer({ variables: { caseId } });
        }}
        resetCaseCustomerLoading={loading}
      />
    );
  },
);

function buildWrappedComponentWithMutations(WrappedComponent, componentProps) {
  return (
    <Mutation mutation={resetCaseCustomerMutation}>
      {buildMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

const caseCustomerTypeQuery = gql`
  query caseCustomerTypeQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      customer(useCachedDataForCase: true) {
        ... on Customer {
          id
          type
          name
        }
        ... on StoreCustomer {
          id
          type
          name
        }
        ... on CustomCustomer {
          type
          name
        }
        ... on CustomStoreCustomer {
          type
          name
          accountNumber
        }
      }
    }
  }
`;

function skipQuery({ caseNumber }) {
  return !caseNumber;
}

function buildQueryVariables({ caseNumber }) {
  return { caseNumber };
}

function getCaseCustomerType({ data }) {
  const customer = get(data, 'case.customer') || {};

  // CustomStoreCustomers and StoreCustomers will return the same value for `type`.
  // This logic sets the correct caseCustomerType based on additional values
  if (customer.type === CUSTOMER_TYPES.STORE.type) {
    // StoreCustomers do must have an ID, CustomStoreCustomers can not.
    if (customer.id) {
      return customer.type;
    }
    return CUSTOMER_TYPES.CUSTOM_STORE.type;
  }

  return customer.type;
}

const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const caseId = get(queryProps, 'data.case.id');
    const caseCustomerType = getCaseCustomerType(queryProps);
    const caseCustomerTypeLoading = get(queryProps, 'loading');

    return buildWrappedComponentWithMutations(WrappedComponent, {
      ...componentProps,
      caseId,
      caseCustomerType,
      caseCustomerTypeLoading,
    });
  },
);

const withCaseCustomerType = (WrappedComponent) => (componentProps) => (
  <Query
    query={caseCustomerTypeQuery}
    skip={skipQuery(componentProps)}
    variables={buildQueryVariables(componentProps)}
  >
    {buildQueryChild(WrappedComponent, componentProps)}
  </Query>
);

export default withCaseCustomerType;

import React from 'react';
import PropTypes from 'prop-types';
import { uniq } from 'lodash';

import { Column } from 'styled-components-grid';

import { Text as DefaultText } from '../../elements';
import { modifiersForComponent } from '../../utils/modifiers';

function Text({ modifiers, ...rest }) {
  const columnModifiers = uniq([
    'padScale_0',
    ...modifiersForComponent(modifiers, Column),
  ]);
  const labelModifiers = modifiersForComponent(modifiers, DefaultText);

  return (
    <Column
      modifiers={columnModifiers}
      style={{
        position: 'relative',
        bottom: '2px',
        left: '2px',
      }}
    >
      <DefaultText modifiers={labelModifiers} {...rest} />
    </Column>
  );
}

Text.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

Text.defaultProps = {
  modifiers: [],
};

export default Text;

import React from 'react';
import { t, Trans } from '@lingui/macro';

import { Row, Column } from 'styled-components-grid';
import { A, MessageMedium } from 'base-components';

import Card from 'blocks/Card';
import Page from 'blocks/Page';

import { logoutURL } from 'utils/bff';
import { locationOrigin } from 'utils/url';

export function AccessDenied() {
  return (
    <Page modifiers={['fluid', 'bgBrandSecondary']}>
      <Row modifiers={['center', 'padScaleY_5']}>
        <Column modifiers={['padScaleY_5']}>
          <Card modifiers={['fluid']}>
            <Column modifiers={['col', 'padScale_5']}>
              <MessageMedium modifiers={['padScaleY_5']}>
                <MessageMedium.Header>
                  <MessageMedium.Icon name="lock" />
                </MessageMedium.Header>

                <MessageMedium.Section>
                  <Column modifiers={['col']}>
                    <MessageMedium.Title>
                      <Trans>Application Unavailable</Trans>
                    </MessageMedium.Title>
                  </Column>
                </MessageMedium.Section>

                <MessageMedium.Section>
                  <Column modifiers={['col']}>
                    <MessageMedium.Text>
                      <Trans>
                        You do not have permission to access this application.
                        Please contact your administrator to request it.
                      </Trans>
                    </MessageMedium.Text>
                  </Column>
                </MessageMedium.Section>

                <MessageMedium.Section>
                  <Column modifiers={['col']}>
                    <MessageMedium.Text>
                      <A
                        href={logoutURL(locationOrigin())}
                        aria-label={t`Logout`}
                      >
                        <Trans>Logout</Trans>
                      </A>
                    </MessageMedium.Text>
                  </Column>
                </MessageMedium.Section>
              </MessageMedium>
            </Column>
          </Card>
        </Column>
      </Row>
    </Page>
  );
}

export default AccessDenied;

/**
 * The status color to base open-hours block colors upon.
 * @type {string}
 */
export const OPEN_HOURS_COLOR_KEY = 'success';

/**
 * The status color to base after-hours rotation block colors upon.
 * @type {string}
 */
export const AFTER_HOURS_COLOR_KEY = 'info';

/**
 * The `__typename` of GraphQL nodes representing an after-hours
 * rotation / ERS event.
 * @type {string}
 */
export const AFTER_HOURS_EVENT_TYPE = 'AfterHoursEventInstance';

/**
 * The `__typename` of GraphQL nodes representing a store-hours event.
 * @type {string}
 */
export const STORE_HOURS_EVENT_TYPE = 'OpenHoursEventInstance';

/**
 * Identifier for the "placeholder" events created to indicated
 * blocks of time in the schedule which do not currently have
 * an after-hours rotation.
 * @type {string}
 */
export const UNALLOCATED_ROTATION_GAP = 'NoRotations';

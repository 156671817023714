import { useState, useEffect } from 'react';
import { gql, useQuery, useSubscription } from '@apollo/client';

export const QUERY_NAME = 'ContactCallbackState';

const automatedCallFields = gql`
  fragment CaseAutomatedCallFields on CurrentAutomatedCallPayload {
    id
    type
    status
    successTime
    retryTimestamps

    user {
      email
      firstName
      lastName
    }
  }
`;

export const query = gql`
  query ContactCallbackState($contactId: ID!, $type: AutomatedCallType!) {
    currentAutomatedCall(input: { caseContactId: $contactId, type: $type }) {
      ...CaseAutomatedCallFields
    }
  }
  ${automatedCallFields}
`;

const subscription = gql`
  subscription automatedCallState($type: AutomatedCallType!, $contactId: ID!) {
    onCaseAutomatedCallChanged(
      input: { type: $type, caseContactId: $contactId }
    ) {
      ...CaseAutomatedCallFields
    }
  }
  ${automatedCallFields}
`;

const useAutomatedCallState = (contactId, type) => {
  const [state, setState] = useState();

  const skip = !contactId || !type;

  const { data: queryData, loading } = useQuery(query, {
    skip,
    variables: { contactId, type },
    fetchPolicy: 'network-only',
  });

  const { data: subData } = useSubscription(subscription, {
    skip,
    variables: { contactId, type },
  });

  const dataFromSub = subData?.onCaseAutomatedCallChanged;
  const dataFromQuery = queryData?.currentAutomatedCall;

  useEffect(() => {
    const newState = dataFromSub !== undefined ? dataFromSub : dataFromQuery;

    if (newState !== state) setState(newState);
  }, [state, dataFromSub, dataFromQuery]);

  return { state, loading };
};

export default useAutomatedCallState;

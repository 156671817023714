import React from 'react';
import PropTypes from 'prop-types';

import { Icon as BaseIcon } from 'base-components';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

export const Icon = buildStyledComponent(
  'Icon',
  BaseIcon,
  ({ theme }) => `color: ${theme.colors.status.default}`,
  {
    themePropTypes: PropTypes.shape({
      colors: PropTypes.shape({
        status: PropTypes.shape({
          default: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  },
);

const iconStyles = {
  // Without this the icon's height will be 16px * baseLineHeight (1.15)
  // and that throws of the horizontal alignment with the bar.
  height: px2rem(16),
};

const modifierIcons = {
  disabled: 'circle',
  info: 'circle-f',
  success: 'check-circle-f',
};

function EventIndicatorIcon({ modifiers }) {
  const finalModifiers = Array.isArray(modifiers) ? modifiers : [modifiers];

  const iconName = Object.keys(modifierIcons).reduce(
    (finalIcon, modifier) =>
      finalModifiers.includes(modifier) ? modifierIcons[modifier] : finalIcon,
    'circle-f',
  );

  return <Icon style={iconStyles} modifiers={finalModifiers} name={iconName} />;
}

EventIndicatorIcon.propTypes = {
  modifiers: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

EventIndicatorIcon.defaultProps = { modifiers: [] };

EventIndicatorIcon.displayName = 'Timeline__EventIndicatorIcon';

export default EventIndicatorIcon;

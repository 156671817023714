import PropTypes from 'prop-types';
import React, { Component } from 'react';

import StyledButtonMicro from '../../blocks/ButtonMicro';

import { modifiersForComponent } from '../../utils/modifiers';

import Icon from './Icon';
import Text from './Text';

const BC_BUTTON_MICRO_CONTEXT = '__BC_BUTTON_MICRO_CONTEXT__';

function withButtonMicroProps(WrappedComponent) {
  function WithButtonMicroProps(props, context) {
    const propsFromButtonMicro = context[BC_BUTTON_MICRO_CONTEXT];
    return <WrappedComponent {...propsFromButtonMicro} {...props} />;
  }

  WithButtonMicroProps.contextTypes = {
    [BC_BUTTON_MICRO_CONTEXT]: PropTypes.shape({
      modifiers: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
  };

  WithButtonMicroProps.displayName = `ButtonMicro.${
    WrappedComponent.displayName || WrappedComponent.name || ''
  }`;

  return WithButtonMicroProps;
}

class ButtonMicro extends Component {
  static Icon = withButtonMicroProps(Icon);

  static Text = withButtonMicroProps(Text);

  static propTypes = {
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    modifiers: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    disabled: false,
    modifiers: [],
  };

  static childContextTypes = {
    [BC_BUTTON_MICRO_CONTEXT]: PropTypes.shape({}).isRequired,
  };

  getChildContext() {
    return {
      [BC_BUTTON_MICRO_CONTEXT]: {
        disabled: this.props.disabled,
        modifiers: this.props.modifiers,
      },
    };
  }

  render() {
    const buttonMicroModifiers = modifiersForComponent(
      this.props.modifiers,
      StyledButtonMicro,
    );
    return (
      <StyledButtonMicro {...this.props} modifiers={buttonMicroModifiers} />
    );
  }
}

export default ButtonMicro;

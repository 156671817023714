import PropTypes from 'prop-types';
import styled from 'styled-components';
import { buildStyledComponent } from 'decisiv-ui-utils';

import Icon from './Icon';
import Label from './Label';
import Text from './Text';

const modifierConfig = {
  active: ({ theme }) => `
    span {
      color: ${theme.colors.base.linkHover};
    }
  `,

  disabled: ({ theme }) => `
    opacity: 0.5;
    pointer-events: none;
    span {
      color: ${theme.colors.base.text};
    }
  `,
};

const styles = ({ theme }) => `
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  font-weight: ${theme.fontWeights.light};
  outline: none;
  padding: 0;
  white-space: nowrap;

  &:hover {
    span {
      color: ${theme.colors.base.linkHover};
    }
    .Text {
      text-decoration: underline;
    }
  }
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      linkHover: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  fontWeights: PropTypes.shape({
    light: PropTypes.number.isRequired,
  }).isRequired,
};

const ButtonSort = buildStyledComponent('ButtonSort', styled.button, styles, {
  modifierConfig,
  themePropTypes,
});

ButtonSort.Label = Label;
ButtonSort.Text = Text;
ButtonSort.Icon = Icon;

export default ButtonSort;

import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'styled-components-grid';

function Footer(props) {
  const { modifiers, ...rest } = props;
  const defaultModifiers = ['center'];
  const footerModifiers = defaultModifiers.concat(modifiers);

  return <Row modifiers={footerModifiers} {...rest} />;
}

Footer.defaultProps = {
  modifiers: [],
};

Footer.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

export default Footer;

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Cases from 'pages/Cases';
import CasePrint from 'pages/CasePrint';
import CreateCase from 'pages/CreateCase';
import CaseDetails from 'pages/CaseDetails';
import ReportsPage from 'pages/Reports';
import DealerLocator from 'pages/DealerLocator';

import TechAdminRoutes from './TechAdmin';
import {
  CallCenterAuthorized,
  DealerAuthorized,
  TechAdminAuthorized,
} from './Authorized';
import HomePageRouter from './HomePageRouter';
import DefaultLayout from './DefaultLayout';

const SecureDealerLocator = CallCenterAuthorized(DealerLocator);
const SecureCases = CallCenterAuthorized(Cases);
const SecureCreateCase = CallCenterAuthorized(CreateCase);
const SecureCasePrint = CallCenterAuthorized(CasePrint);
const SecureCaseDetails = CallCenterAuthorized(CaseDetails);
const SecureTechAdmin = TechAdminAuthorized(TechAdminRoutes);
const SecureReportsPage = DealerAuthorized(ReportsPage);

export default function Routes() {
  return (
    <Switch>
      <DefaultLayout exact path="/" component={HomePageRouter} />
      <DefaultLayout exact path="/cases" component={SecureCases} />
      <DefaultLayout exact path="/cases/new" component={SecureCreateCase} />
      <DefaultLayout
        exact
        mode="highlightedOnly"
        path="/cases/highlighted"
        component={SecureCases}
      />
      <Route
        exact
        path="/cases/:caseNumber/print"
        component={SecureCasePrint}
      />
      <DefaultLayout
        exact
        path="/cases/:caseNumber"
        component={SecureCaseDetails}
        noScrollBar={false}
      />
      <DefaultLayout
        exact
        path="/dealer-locator"
        component={SecureDealerLocator}
      />
      <DefaultLayout path="/tech-admin" component={SecureTechAdmin} />
      <DefaultLayout path="/reports/:type?" component={SecureReportsPage} />
      {/* Throws an Error for any unknown routes; this in turn will trigger the
        RouteErrorBoundary and reroute to the ErrorPage */}
      <Route
        path="*"
        component={() => {
          throw new Error('page does not exist');
        }}
      />
    </Switch>
  );
}

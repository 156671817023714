import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

function Newline2Br({ value }) {
  const lines = value.split(/\n/);

  return lines.map((line, index) => (
    <Fragment key={`${line}-${index}`}>
      {line}
      {index < lines.length - 1 && <br />}
    </Fragment>
  ));
}

Newline2Br.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Newline2Br;

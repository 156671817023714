import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import { Row, Column } from 'styled-components-grid';
import { Divider, Text } from 'base-components';

import RelativeNow from 'components/RelativeNow';

const thirtySeconds = 30 * 1000;

const LocationDateTime = ({ timezone }) => {
  if (!moment.tz.zone(timezone)) {
    return null;
  }

  return (
    <Column modifiers={['height_100', 'padScaleY_0']}>
      <Row modifiers={['height_100', 'middle']}>
        <Column modifiers={['height_100', 'padScaleX_0', 'padScaleY_2']}>
          <Divider modifiers="vertical" />
        </Column>
        <RelativeNow
          interval={thirtySeconds}
          timezone={timezone}
          render={() => {
            const datetimeAtLocation = moment.tz(timezone);
            const date = datetimeAtLocation.format('D MMM');
            const time = datetimeAtLocation.format('h:mm A z');

            return (
              <Column modifiers={['col', 'padScaleY_0']}>
                <Row>
                  <Column modifiers={['col', 'padScaleY_0']}>
                    <Text modifiers="small">{date}</Text>
                  </Column>
                </Row>
                <Row>
                  <Column modifiers={['col', 'padScaleY_0']}>
                    <Text modifiers="small">{time}</Text>
                  </Column>
                </Row>
              </Column>
            );
          }}
        />
      </Row>
    </Column>
  );
};

LocationDateTime.propTypes = {
  timezone: PropTypes.string.isRequired,
};

export default LocationDateTime;

import React, { Component } from 'react';
import { Trans } from '@lingui/macro';
import { isEmpty } from 'lodash';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router';
import { compose, setDisplayName } from 'recompose';

import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';
import { H3, QuickActionButton } from 'base-components';

import Panel from 'blocks/Panel';
import withContext from 'utils/withContext';
import ButtonLinkWithIcon from 'elements/ButtonLinkWithIcon';

import withContacts from './withContacts';
import AfterHoursContact from './AfterHoursContact';
import NoContactsMessage from './NoContactsMessage';
import StoreContactsModal from './StoreContactsModal';
import DeleteContactModal from './DeleteContactModal';
import DealerDetailsContext from '../DealerDetailsContext';
import withDeleteAfterHoursContact from './withDeleteAfterHoursContact';
import { AfterHoursContactPropType } from '../propTypes';

function CreateContactButton({ onClick }) {
  return (
    <QuickActionButton onClick={onClick}>
      <QuickActionButton.Icon modifiers="left" name="plus" />
      <QuickActionButton.Text>
        <Trans>Create Contact</Trans>
      </QuickActionButton.Text>
    </QuickActionButton>
  );
}

CreateContactButton.propTypes = { onClick: PropTypes.func.isRequired };

export class Contacts extends Component {
  static propTypes = {
    isReadOnly: PropTypes.bool.isRequired,
    // this is used in DeleteContactModal
    dealerId: PropTypes.string.isRequired,
    contacts: PropTypes.arrayOf(AfterHoursContactPropType),
    deleteAfterHoursContact: PropTypes.func.isRequired,
  };

  static defaultProps = {
    contacts: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
      selectedContact: null,
      showStoreContactsModal: false,
      showDeleteContactModal: false,
    };
  }

  toggleModal = ({ isEditing, contact = {} }) =>
    this.setState({
      isEditing,
      selectedContact: contact,
      showStoreContactsModal: !this.state.showStoreContactsModal,
    });

  toggleCreateModal = () => this.toggleModal({ isEditing: false });

  toggleEditModal = (contact) => this.toggleModal({ isEditing: true, contact });

  toggleDeleteModal = (contact) => {
    this.setState({
      selectedContact: contact,
      showDeleteContactModal: !this.state.showDeleteContactModal,
    });
  };

  render() {
    const { contacts, isReadOnly, dealerId } = this.props;
    const { isEditing, selectedContact } = this.state;
    const { showDeleteContactModal, showStoreContactsModal } = this.state;

    return (
      <Panel modifiers="padScale_0">
        <Row modifiers={['padScaleX_4', 'padScaleY_3']}>
          <Column modifiers="col">
            <H3 modifiers="fontWeightRegular">
              <Trans>Contacts</Trans>
              {contacts && contacts.length ? ` (${contacts.length})` : ''}
            </H3>
          </Column>
        </Row>

        {isEmpty(contacts) && (
          <>
            <NoContactsMessage />
            <Row modifiers="center" style={{ paddingBottom: px2rem(16) }}>
              {!isReadOnly && (
                <Column>
                  <CreateContactButton onClick={this.toggleCreateModal} />
                </Column>
              )}
            </Row>
          </>
        )}

        {isEmpty(contacts) || (
          <>
            <Row style={{ maxHeight: '17rem', overflowY: 'auto' }}>
              <Column modifiers={['col', 'padScale_0']}>
                {contacts.map((contact) => (
                  <AfterHoursContact
                    key={contact.id}
                    contact={contact}
                    isReadOnly={isReadOnly}
                    toggleEditContact={this.toggleEditModal}
                    toggleDeleteContact={this.toggleDeleteModal}
                  />
                ))}
              </Column>
            </Row>
            <Row modifiers={['padScaleX_4', 'padScaleY_3']}>
              {!isReadOnly && (
                <Column>
                  <ButtonLinkWithIcon
                    icon="plus"
                    onClick={this.toggleCreateModal}
                  >
                    <Trans>Create Contact</Trans>
                  </ButtonLinkWithIcon>
                </Column>
              )}
            </Row>
          </>
        )}

        {showStoreContactsModal && (
          <StoreContactsModal
            isEditing={isEditing}
            onClose={this.toggleCreateModal}
            selectedContact={selectedContact}
          />
        )}

        {showDeleteContactModal && (
          <DeleteContactModal
            onClose={this.toggleDeleteModal}
            contact={selectedContact}
            contacts={contacts}
            dealerId={dealerId}
          />
        )}
      </Panel>
    );
  }
}

export default compose(
  setDisplayName('Contacts'),
  withRouter,
  withContacts,
  withDeleteAfterHoursContact,
  withContext(DealerDetailsContext),
)(Contacts);

import { compact, isEmpty, zipObject } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Calendars from './Calendars';
import { isBeforeDate, isSameDate, isAfterDate } from './utils';

/**
 * Builds a selectedDateRange based on the current date range and the date clicked
 * @param    {Object}    currentDateRange      The current date range.
 * @property {Date|null} currentDateRange.from The from date currently selected
 * @property {Date|null} currentDateRange.to   The to date currently selected
 * @param    {Date|null} newDate               The newDate to set in the range.
 * @param    {Date}      dateClicked           The date that was actually clicked.
 * @return   {Object}    The new date range. Keys ['from', 'to'].
 */
export function buildDateRange(currentDateRange, newDate, dateClicked) {
  // Creates an array with the dates. Useful for sorting.
  const currentDatesArr = compact([currentDateRange.from, currentDateRange.to]);

  // Based on the present values, create a new array of dates.
  const newDatesArr = (() => {
    if (!newDate) {
      // newDate will be null if the dateClicked is already selected.
      return compact(
        currentDatesArr.map((date) => {
          if (!isSameDate(date, dateClicked)) {
            return date;
          }
          return undefined;
        }),
      );
    }

    if (isEmpty(currentDatesArr)) {
      // no dates have yet been selected
      return [newDate];
    }

    if (isBeforeDate(currentDatesArr[0], newDate)) {
      // newDate should replace the `from` date.
      return [newDate, currentDatesArr[0]];
    }

    if (isAfterDate(currentDatesArr[0], newDate)) {
      // newDate should replace the `to` date.
      return [currentDatesArr[0], newDate];
    }

    // Failsafe
    return [];
  })();

  // Create the object based on the sorted dates in the array.
  return zipObject(['from', 'to'], newDatesArr);
}

class DateRangeSelector extends Component {
  static propTypes = {
    disabledDates: PropTypes.shape({
      after: PropTypes.instanceOf(Date),
      before: PropTypes.instanceOf(Date),
    }),
    multiMonth: PropTypes.bool,
    multiMonthDirection: PropTypes.oneOf(['past', 'future']),
    onSelectDate: PropTypes.func.isRequired,
    selectedDateRange: PropTypes.shape({
      from: PropTypes.instanceOf(Date),
      to: PropTypes.instanceOf(Date),
    }),
    initialVisibleDate: PropTypes.instanceOf(Date),
  };

  static defaultProps = {
    disabledDates: {},
    multiMonth: false,
    multiMonthDirection: 'future',
    selectedDateRange: null,
    initialVisibleDate: null,
  };

  state = {
    selectedDateRange: this.props.selectedDateRange || {},
  };

  /**
   * Handle clicking a date in the calendar grid. Calls `this.props.onSelectDate` with the current
   * selected date range.
   * @param  {Date|null} selectedDate The new date to add to the range
   * @param  {Date} dateClicked The actual date that was clicked.
   */
  handleSelectDate = (selectedDate, dateClicked) => {
    const selectedDateRange = buildDateRange(
      this.props.selectedDateRange || this.state.selectedDateRange,
      selectedDate,
      dateClicked,
    );

    this.setState({ selectedDateRange });
    this.props.onSelectDate(selectedDateRange);
  };

  render() {
    return (
      <Calendars
        disabledDates={this.props.disabledDates}
        multiMonth={this.props.multiMonth}
        multiMonthDirection={this.props.multiMonthDirection}
        onSelectDate={this.handleSelectDate}
        range
        selectedDateRange={
          this.props.selectedDateRange || this.state.selectedDateRange
        }
        initialVisibleDate={this.props.initialVisibleDate}
      />
    );
  }
}

export default DateRangeSelector;

/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/**
 * To suppress
 * onMouseOver must be accompanied by onFocus for accessibility  jsx-a11y/mouse-events-have-key-events
 */

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Marker } from 'react-google-maps';
import { compose, setDisplayName } from 'recompose';
import { withTheme } from 'styled-components';

import { px2rem } from 'decisiv-ui-utils';

import { mapIconForDealerType } from 'assets/images/mapIcons';

import GoogleMapPropTypes from 'features/googleMaps/propTypes';

import { evaluateDealerType, MCSN } from 'utils/dealerFilter';

function markerLabelProps(dealerType, theme) {
  return {
    color:
      dealerType === MCSN
        ? theme.colors.base.text
        : theme.colors.base.chrome000,
    fontSize: px2rem(12),
    fontWeight: `${theme.fontWeights.medium}`,
  };
}

function buildMarkerProps(dealer, theme, index) {
  if (dealer.icon) return { icon: { url: dealer.icon } };

  const dealerType = evaluateDealerType(dealer);

  return {
    label: {
      ...markerLabelProps(dealerType, theme),
      text: `${index + 1}`,
    },
    icon: {
      url: mapIconForDealerType(dealerType),
    },
  };
}

export class DealerMarker extends Component {
  static propTypes = {
    highlightedDealer: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    assetLocation: PropTypes.shape({
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
    }).isRequired,
    dealer: PropTypes.shape({
      id: PropTypes.string.isRequired,
      features: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string.isRequired,
        }),
      ).isRequired,
      location: PropTypes.shape({
        longitude: PropTypes.number.isRequired,
        latitude: PropTypes.number.isRequired,
      }),
    }).isRequired,
    dealers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    // eslint-disable-next-line react/no-typos
    directions: GoogleMapPropTypes.googleDirections,
    index: PropTypes.number.isRequired,
    theme: PropTypes.shape({
      colors: PropTypes.shape({
        base: PropTypes.shape({
          chrome000: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
    displayRoute: PropTypes.func.isRequired,
    onDealerHighlight: PropTypes.func.isRequired,
    onDealerSelect: PropTypes.func.isRequired,
    selectedDealer: PropTypes.shape({
      dealerId: PropTypes.string,
      index: PropTypes.number,
      location: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number,
      }),
    }),
    isRouteSearch: PropTypes.bool,
    route: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    highlightedDealer: null,
    dealers: [],
    directions: {
      routes: [],
      status: 'empty',
    },
    selectedDealer: {},
    isRouteSearch: false,
    route: [],
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      assetLocation,
      dealer,
      displayRoute,
      highlightedDealer,
      isRouteSearch,
      route,
    } = nextProps;

    if (highlightedDealer !== this.props.highlightedDealer) {
      if (highlightedDealer && highlightedDealer.id === dealer.id) {
        displayRoute(dealer.location, assetLocation);
      } else if (
        !highlightedDealer &&
        this.props.highlightedDealer.id === dealer.id // only if this marker has been blurred
      ) {
        if (isRouteSearch) {
          displayRoute(...route); // default route, i.e. from asset to destination
        } else {
          displayRoute(); // clear route
        }
      }
    }
  }

  getMarkerOpacity = () => {
    const { highlightedDealer, dealer } = this.props;
    if (highlightedDealer && highlightedDealer.id !== dealer.id) {
      return 0.3;
    }
    return 1;
  };

  getZIndex = () => {
    const { dealer, dealers, highlightedDealer, index } = this.props;
    const dealerCount = dealers.length;
    if (!highlightedDealer || highlightedDealer.id !== dealer.id) {
      // This way, it shows lower indexed dealers on top
      return dealerCount - index;
    }
    return dealerCount + 1;
  };

  handleClick = () => {
    const {
      dealer: { id: dealerId, location },
      index,
      onDealerSelect,
    } = this.props;
    onDealerSelect(dealerId, index, location);
  };

  handleHover = () => {
    const { dealer, onDealerHighlight } = this.props;
    onDealerHighlight(dealer);
  };

  handleMouseOut = () => {
    const { dealers, selectedDealer } = this.props;
    let stickyDealer;

    if (selectedDealer) {
      stickyDealer = dealers[selectedDealer.index];
    }
    this.props.onDealerHighlight(stickyDealer);
  };

  render() {
    const { dealer, theme, index } = this.props;
    const { latitude, longitude } = dealer.location;

    return (
      <Marker
        {...buildMarkerProps(dealer, theme, index)}
        position={{ lat: latitude, lng: longitude }}
        onClick={this.handleClick}
        onMouseOut={this.handleMouseOut}
        onMouseOver={this.handleHover}
        opacity={this.getMarkerOpacity()}
        zIndex={this.getZIndex()}
      />
    );
  }
}

export default compose(setDisplayName('DealerMarker'), withTheme)(DealerMarker);

import * as AbsintheSocket from '@absinthe/socket';
import { Socket as PhoenixSocket } from 'phoenix';
import { createAbsintheSocketLink } from '@absinthe/socket-apollo-link';

import { bffAPISocketLink } from 'utils/bff';

import { CSRF_TOKEN, SESSION_TOKEN } from '../../../constants';

const absintheSocketLink = createAbsintheSocketLink(
  AbsintheSocket.create(
    new PhoenixSocket(bffAPISocketLink, {
      params: () => ({
        token: memoryDB.getItem(CSRF_TOKEN),
        session: memoryDB.getItem(SESSION_TOKEN),
      }),
    }),
  ),
);

export default absintheSocketLink;

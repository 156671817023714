import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'styled-components';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName, pure } from 'recompose';

import { buildStyledComponent } from 'decisiv-ui-utils';
import { Column, Row } from 'styled-components-grid';
import { MessageMedium, ButtonLink as BCButtonLink } from 'base-components';

const ButtonLink = buildStyledComponent(
  'ButtonLink',
  BCButtonLink,
  css`
    text-transform: none;
  `,
  {
    modifierConfig: {
      readOnly: () => ({
        styles: css`
          cursor: not-allowed;
          opacity: 0.5;

          &:hover {
            text-decoration: none;
          }
        `,
      }),
    },
  },
);

export function EmptyState(props) {
  const { caseId, createCustomDealer, readOnly, onFocusRequested } = props;

  return (
    <Row modifiers="center">
      <Column modifiers="padScaleY_2" ref={onFocusRequested}>
        <MessageMedium>
          <MessageMedium.Header>
            <MessageMedium.Icon name="service-provider" />
          </MessageMedium.Header>
          <MessageMedium.Section>
            <MessageMedium.Text>
              <Trans>
                Search for a service provider above and use the call option to
                contact them, then record the decision here. Service Provider
                not listed?
              </Trans>
              &nbsp;
              <ButtonLink
                disabled={readOnly}
                modifiers={[readOnly && 'readOnly', 'small']}
                onClick={() => createCustomDealer({ caseId })}
              >
                <Trans>Create a call record here</Trans>
              </ButtonLink>
              .
            </MessageMedium.Text>
          </MessageMedium.Section>
        </MessageMedium>
      </Column>
    </Row>
  );
}

EmptyState.propTypes = {
  caseId: PropTypes.string,
  readOnly: PropTypes.bool.isRequired,
  createCustomDealer: PropTypes.func.isRequired,
  onFocusRequested: PropTypes.func,
};

EmptyState.defaultProps = {
  caseId: undefined,
};

export default compose(
  setDisplayName('CaseDealerSelectionPanel__EmptyState'),
  pure,
)(EmptyState);

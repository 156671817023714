import gql from 'graphql-tag';

export default gql`
  mutation setCaseCustomerDomicile($customerId: ID!, $id: ID!) {
    setCaseCustomerDomicile(
      input: { id: $id, patch: { customerId: $customerId } }
    ) {
      case {
        id
        fixpixPushResult
        customerDomicile {
          ... on Customer {
            id
            name
            type
            city
            state
            billTo
            shipTo
          }
        }
        customer(useCachedDataForCase: true) {
          ... on Customer {
            id
            name
            type
            city
            state
            billTo
            shipTo
          }
          ... on StoreCustomer {
            id
            type
            name
          }
          ... on CustomCustomer {
            type
            name
          }
          ... on CustomStoreCustomer {
            type
            name
          }
        }
        paymentMethod
      }
    }
  }
`;

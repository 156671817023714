// Idea: this list could be auto generated by statically analyzing
// the graphql schema. We could also automatically list the mutations
// that should be observed to drive the case "saving indicator" by
// collecting the nested Types in the Case type that are returned by
// the mutations.
export const mutationType = {
  assignCaseToMe: 'assignCaseToMe',
  createCaseContact: 'createCaseContact',
  createCaseRequestLine: 'createCaseRequestLine',
  createCaseSuppliedLine: 'createCaseSuppliedLine',
  createCustomDealer: 'createCustomDealer',
  createDealerResponse: 'createDealerResponse',
  createPrimaryAsset: 'createPrimaryAsset',
  createRelatedAsset: 'createRelatedAsset',
  deleteAssets: 'deleteAssets',
  deleteCaseContacts: 'deleteCaseContacts',
  deleteCaseRequestLine: 'deleteCaseRequestLine',
  deleteCaseSuppliedLine: 'deleteCaseSuppliedLine',
  deleteDealerResponse: 'deleteDealerResponse',
  removeCustomerFromCase: 'removeCustomerFromCase',
  resetAgreement: 'resetAgreement',
  resetAllAgreements: 'resetAllAgreements',
  resetAllSupplied: 'resetAllSupplied',
  resetSupplied: 'resetSupplied',
  setCaseNationalCustomer: 'setCaseNationalCustomer',
  setCaseNewStoreCustomer: 'setCaseNewStoreCustomer',
  setCaseOtherCustomer: 'setCaseOtherCustomer',
  setCaseStoreCustomer: 'setCaseStoreCustomer',
  setCaseCustomerDomicile: 'setCaseCustomerDomicile',
  removeCustomerDomicileFromCase: 'removeCustomerDomicileFromCase',
  setCasePaymentDetails: 'setCasePaymentDetails',
  removeCasePaymentDetails: 'removeCasePaymentDetails',
  updateAsset: 'updateAsset',
  updateCase: 'updateCase',
  updateCaseAgreementLine: 'updateCaseAgreementLine',
  updateCaseContact: 'updateCaseContact',
  updateCaseRequestLine: 'updateCaseRequestLine',
  updateCaseStatus: 'updateCaseStatus',
  updateCaseStatusChange: 'updateCaseStatusChange',
  updateCaseSuppliedLine: 'updateCaseSuppliedLine',
  updateCustomDealer: 'updateCustomDealer',
  updateDealerResponse: 'updateDealerResponse',
  createCaseDelay: 'createCaseDelay',
  updateCaseDelay: 'updateCaseDelay',
  setCaseAssetValidation: 'setCaseAssetValidation',
  updateCaseDelayedService: 'updateCaseDelayedService',
  sendToFixpix: 'sendToFixpix',
  clearCaseHighlights: 'clearCaseHighlights',
};

export const operationStatus = {
  complete: 'complete', // operation never called or called and succeeded
  loading: 'loading', // operation in progress
  error: 'error', // operation failed
};

import { curry } from 'lodash';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';

import updateDealerEmailMutation from './updateDealerEmailMutation';
import { NAME as DEALER_INFO_QUERY_NAME } from '../withDealerInfo/dealerInfoQuery';

export const buildMutationChild = curry(
  (WrappedComponent, componentProps, updateDealer, mutationProps) => {
    const { dealerId } = componentProps;

    return (
      <WrappedComponent
        {...componentProps}
        isUpdatingDealerEmail={!!mutationProps?.loading}
        updateDealerEmail={(email) => {
          updateDealer({
            variables: { id: dealerId, email },
          });
        }}
      />
    );
  },
);

function buildWrappedComponentWithMutation(WrappedComponent, componentProps) {
  return (
    <Mutation
      mutation={updateDealerEmailMutation}
      refetchQueries={[DEALER_INFO_QUERY_NAME]}
    >
      {buildMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

const withUpdateDealerEmail = (WrappedComponent) => (componentProps) =>
  buildWrappedComponentWithMutation(WrappedComponent, componentProps);

export default withUpdateDealerEmail;

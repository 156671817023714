/* eslint-disable import/prefer-default-export */

import { get, isNumber } from 'lodash';

import { hasBeenDispatched } from 'compositions/CaseStatus/utils';

import { fieldIds } from './constants';

function validAssetLocation(assetLocation) {
  const coordinates = get(assetLocation, 'coordinates') || {};

  return isNumber(coordinates.latitude) && isNumber(coordinates.longitude);
}

function validSearchValue(assetLocation) {
  const searchValue = get(assetLocation, 'searchValue');

  return !!searchValue;
}

export const reportAssetLocationStatus = ({
  assetLocation,
  setCasePanelComplete,
  setCasePanelIncomplete,
  setCasePanelInvalid,
}) => {
  const hasValidAssetLocation = validAssetLocation(assetLocation);
  const hasValidSearchValue = validSearchValue(assetLocation);

  // - search value with a geocoded location reports valid
  // - search value with no geocoded location reports invalid
  // - NO search value OR NO geocoded location reports incomplete
  if (hasValidAssetLocation && hasValidSearchValue) {
    return setCasePanelComplete();
  }

  return !hasValidAssetLocation && hasValidSearchValue
    ? setCasePanelInvalid({ [fieldIds.location]: 'invalid' })
    : setCasePanelIncomplete();
};

// Returns a function that overrides the `travelEstimate` property with
// "at dispatch time" estimates, if we have them, and if the dealer is
// the servicing dealer.
export const getDealerDataTransformer = (props) => (dealerData) => {
  const { caseDetail } = props;
  const servicingDealerId = get(caseDetail, 'servicingDealer.dealer.id');
  const atDispatchEstimates = get(caseDetail, 'dealerTravelEstimateAtDispatch');
  const isServicingDealer = dealerData.id === servicingDealerId;

  if (
    !isServicingDealer ||
    !atDispatchEstimates ||
    !hasBeenDispatched(caseDetail)
  ) {
    return dealerData;
  }

  return { ...dealerData, travelEstimate: atDispatchEstimates };
};

/* eslint-disable import/prefer-default-export */

export const responsiveBreakpoints = {
  navExpanded: {
    textWrap: 1720,
    twoItemsPerRow: 904,
    threeItemsPerRow: 1480,
  },
  navCollapsed: {
    textWrap: 1550,
    twoItemsPerRow: 767,
    threeItemsPerRow: 1300,
  },
};

// For 'All' filter option, '-1' is used for a temporary id
export const ALL_FILTER_OPTION = {
  name: 'All',
  id: '-1',
  customerName: 'All',
};

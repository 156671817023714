import React from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { withRouter } from 'react-router';
import { compose, setDisplayName } from 'recompose';

import {
  ButtonIconCircle,
  ButtonMicro,
  Divider,
  Link,
  Tooltip,
} from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import withUserRole from 'features/rbac/withUserRole';
import { USER_ROLES, IfRolesMatch } from 'features/rbac';
import { userCanCreateNewCase } from 'features/rbac/utils';
import { useToggleKeyShortcuts } from 'features/keyShortcuts';

import Logo from 'blocks/Logo';
import StyledTopNav from 'blocks/TopNav';

import onCallLogo from 'assets/images/oncall.jpg';

import UserInfo from 'compositions/UserInfo';
import CaseSaveIndicator from 'compositions/CaseSaveIndicator';
import AmazonConnectPopup from 'components/AmazonConnectPopup';
import CurrentCallInfo from 'compositions/CurrentCallInfo';

export function TopNav(props) {
  const isCasePage = !!props?.match?.path?.match('/cases/:caseNumber');
  const canCreateNewCase = userCanCreateNewCase(props);
  const toggleKeyShortcuts = useToggleKeyShortcuts();

  return (
    <StyledTopNav>
      <Container>
        <Row modifiers={['height_100', 'middle']}>
          <Column modifiers="padScaleY_0">
            <Container>
              <Row modifiers={['height_100', 'middle']}>
                <Column modifiers={['display_flex', 'padScaleY_0']}>
                  <Logo src={onCallLogo} alt="Logo" />
                </Column>
                <Column>
                  <Row modifiers="middle">
                    <Column modifiers="padScaleY_0">
                      <StyledTopNav.Text
                        modifiers={['fontWeightMedium', 'text']}
                      >
                        Michelin®
                      </StyledTopNav.Text>
                      &nbsp;
                      <StyledTopNav.Text modifiers={['fontWeightLight']}>
                        ONCall 2.0
                      </StyledTopNav.Text>
                    </Column>
                  </Row>
                  {isCasePage && (
                    <Row modifiers="middle" style={{ height: 0 }}>
                      <Column modifiers="padScale_0">
                        <CaseSaveIndicator />
                      </Column>
                    </Row>
                  )}
                </Column>
              </Row>
            </Container>
          </Column>
          <Column modifiers={['col']}>
            <IfRolesMatch roles={USER_ROLES.CALL_CENTER}>
              <CurrentCallInfo />
            </IfRolesMatch>
          </Column>
          <Column modifiers={['end']}>
            <Container modifiers={['padScaleX_3']}>
              <Row modifiers={['height_100', 'end', 'middle']}>
                {canCreateNewCase && (
                  <>
                    <Column style={{ marginRight: 20 }}>
                      <Tooltip position="bottom">
                        <Tooltip.Target>
                          <ButtonMicro onClick={toggleKeyShortcuts}>
                            <ButtonMicro.Icon name="keyboard-o" />
                            <ButtonMicro.Text>
                              <Trans>Ctrl + K</Trans>
                            </ButtonMicro.Text>
                          </ButtonMicro>
                        </Tooltip.Target>
                        <Tooltip.Content style={{ zIndex: 4 }}>
                          <Trans id="Keyboard Shortcuts" />
                        </Tooltip.Content>
                      </Tooltip>
                    </Column>
                    <Column style={{ marginRight: 20 }}>
                      <Tooltip position="bottom">
                        <Tooltip.Target>
                          <Link
                            aria-label={t`Create new case`}
                            to="/cases/new"
                            style={{ textDecoration: 'none' }}
                          >
                            <ButtonIconCircle modifiers="active">
                              <ButtonIconCircle.Icon name="briefcase-plus" />
                            </ButtonIconCircle>
                          </Link>
                        </Tooltip.Target>
                        <Tooltip.Content style={{ zIndex: 4 }}>
                          <Trans id="Create Case" />
                        </Tooltip.Content>
                      </Tooltip>
                    </Column>
                    <Column>
                      <AmazonConnectPopup />
                    </Column>
                    <Column style={{ height: '35px' }}>
                      <Divider modifiers="vertical" />
                    </Column>
                  </>
                )}
                <Column>
                  <UserInfo />
                </Column>
              </Row>
            </Container>
          </Column>
        </Row>
      </Container>
    </StyledTopNav>
  );
}

TopNav.propTypes = {
  role: PropTypes.string,
  match: PropTypes.object.isRequired,
  loadingRole: PropTypes.bool.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string),
};

TopNav.defaultProps = {
  role: undefined,
  permissions: [],
};

export default compose(
  setDisplayName('TopNav'),
  withUserRole,
  withRouter,
)(TopNav);

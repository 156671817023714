import React from 'react';
import PropTypes from 'prop-types';
import { findNumbers } from 'libphonenumber-js';

import { Text } from 'base-components';

import { CallButtonLink } from 'compositions/ClickToCall';

const Instructions = ({ text, style, ...rest }) => {
  let curPos = 0;
  const parts = [];
  const contacts = findNumbers(text, 'US', { v2: true });

  contacts.forEach(({ number: { number, country }, startsAt, endsAt }) => {
    if (startsAt) parts.push(text.substr(curPos, startsAt - curPos));

    parts.push(
      <span style={{ display: 'inline-block' }}>
        <CallButtonLink
          style={{ fontSize: 'inherit' }}
          phone={number}
          phoneNumberCountry={country}
        />
      </span>,
    );

    curPos = endsAt;
  });

  if (curPos < text.length) {
    parts.push(<span>{text.substr(curPos)}</span>);
  }

  return (
    <Text style={{ whiteSpace: 'pre-line', ...style }} {...rest}>
      {parts.map((part, index) => (
        <span key={index}>{part}</span>
      ))}
    </Text>
  );
};

Instructions.propTypes = {
  text: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
};

Instructions.defaultProps = { style: undefined };

export default Instructions;

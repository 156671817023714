import gql from 'graphql-tag';

export default gql`
  mutation updateCaseStoreCustomer(
    $customerId: ID!
    $id: ID!
    $zip: String
    $city: String
    $state: String
  ) {
    setCaseStoreCustomer(
      input: {
        id: $id
        patch: {
          customerId: $customerId
          zip: $zip
          city: $city
          state: $state
        }
      }
    ) {
      case {
        id
        fixpixPushResult
        customer(useCachedDataForCase: true) {
          ... on StoreCustomer {
            id
            name
            type
            accountNumber
            zip
            city
            state
          }
        }
        paymentMethod
      }
    }
  }
`;

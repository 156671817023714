import React, { useCallback } from 'react';
import { useMutation, gql } from '@apollo/client';

import { RequestLineFieldsFragment } from '../constants';
import { NAME as REQUEST_LINES_QUERY } from '../withCaseRequestLines';

const errorMessage =
  'createRequestSuppliedLine requires the case id as first argument';

const refetchQueries = [REQUEST_LINES_QUERY];

const mutation = gql`
  mutation createSuppliedLine($caseId: ID!) {
    createCaseSuppliedLine(input: { caseId: $caseId, suppliedLine: {} }) {
      requestSuppliedLine {
        supplied
        suppliedLine {
          ...RequestLineFields
        }
        agreementLineId
      }
    }
  }
  ${RequestLineFieldsFragment}
`;

const withCreateRequestSuppliedLineMutation = (Component) => (props) => {
  const [mutate] = useMutation(mutation, { refetchQueries });

  const createLine = useCallback(
    (caseId) => {
      if (!caseId) return Promise.reject(new Error(errorMessage));

      return mutate({ variables: { caseId } });
    },
    [mutate],
  );

  return <Component {...props} createRequestSuppliedLine={createLine} />;
};

export default withCreateRequestSuppliedLineMutation;

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import { H2, P, QuickActionButton } from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';

import Modal from 'components/Modal';

function ConfirmDeleteModal({
  cancelRemoveRequestLine,
  confirmDeleteOfRequestLineId,
  removeRequestLine,
}) {
  if (!confirmDeleteOfRequestLineId) {
    return null;
  }

  return (
    <Modal onClose={cancelRemoveRequestLine}>
      {({ closeModal }) => (
        <Modal.Body>
          <Modal.Header modifiers={['danger']}>
            <Modal.HeaderIcon name="trash" />
          </Modal.Header>
          <Modal.Content>
            <Row modifiers="center">
              <Column>
                <H2 modifiers="fontWeightRegular">
                  <Trans>Delete Request?</Trans>
                </H2>
              </Column>
            </Row>
            <Row modifiers="center">
              <Column modifiers="col">
                <P style={{ marginBottom: px2rem(20) }}>
                  <Trans>
                    This request has a corresponding Agreed request. Removing it
                    will remove both requests.
                  </Trans>
                </P>
              </Column>
            </Row>
          </Modal.Content>
          <Modal.Footer>
            <Row modifiers="end">
              <Column>
                <QuickActionButton onClick={closeModal}>
                  <QuickActionButton.Text>
                    <Trans>Keep Request</Trans>
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
              <Column>
                <QuickActionButton
                  modifiers="secondary"
                  onClick={() =>
                    removeRequestLine(confirmDeleteOfRequestLineId)
                  }
                >
                  <QuickActionButton.Text>
                    <Trans>Remove Request</Trans>
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
            </Row>
          </Modal.Footer>
        </Modal.Body>
      )}
    </Modal>
  );
}

ConfirmDeleteModal.propTypes = {
  cancelRemoveRequestLine: PropTypes.func.isRequired,
  confirmDeleteOfRequestLineId: PropTypes.string,
  removeRequestLine: PropTypes.func.isRequired,
};

ConfirmDeleteModal.defaultProps = {
  confirmDeleteOfRequestLineId: undefined,
};

export default ConfirmDeleteModal;

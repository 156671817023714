import gql from 'graphql-tag';

export default gql`
  query assetTypesQuery {
    assetType: __type(name: "AssetType") {
      enumValues {
        name
      }
    }
  }
`;

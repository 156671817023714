import gql from 'graphql-tag';

import { configTypes } from '../constants';

export const queryNameForConfigType = {
  [configTypes.caseNumber]: 'suggestCaseNumbers',
  [configTypes.unitNumber]: 'suggestUnitNumbers',
  [configTypes.customerName]: 'suggestCustomers',
  [configTypes.customerLocation]: 'suggestCustomers',
  [configTypes.inboundProgramName]: 'suggestInboundProgramNames',
  [configTypes.dealerName]: 'suggestDealerNames',
  [configTypes.dealerLocation]: 'suggestDealerNames',
  [configTypes.poNumber]: 'suggestPoNumbers',
};

const suggestCustomers = gql`
  query suggestCustomers($searchValue: String!) {
    suggestCustomers(input: { prefix: $searchValue, limit: 20 }) {
      name
      city
      state
      billTo
      shipTo
    }
  }
`;
const suggestDealerNames = gql`
  query suggestDealerNames($searchValue: String!) {
    suggestDealerNames(input: { prefix: $searchValue, limit: 20 }) {
      name
      city
      state
      billTo
      shipTo
    }
  }
`;

export const queryForConfigType = {
  [configTypes.caseNumber]: gql`
    query suggestCaseNumbers($searchValue: String!) {
      suggestCaseNumbers(input: { prefix: $searchValue, limit: 20 }) {
        values
      }
    }
  `,

  [configTypes.unitNumber]: gql`
    query suggestUnitNumbers($searchValue: String!) {
      suggestUnitNumbers(input: { prefix: $searchValue, limit: 20 }) {
        values
      }
    }
  `,

  [configTypes.customerName]: suggestCustomers,

  [configTypes.customerLocation]: suggestCustomers,

  [configTypes.inboundProgramName]: gql`
    query suggestInboundProgramNames($searchValue: String!) {
      suggestInboundProgramNames(input: { prefix: $searchValue, limit: 20 }) {
        values
      }
    }
  `,

  [configTypes.dealerName]: suggestDealerNames,

  [configTypes.dealerLocation]: suggestDealerNames,

  [configTypes.poNumber]: gql`
    query suggestPoNumbers($searchValue: String!) {
      suggestPoNumbers(input: { prefix: $searchValue, limit: 20 }) {
        values
      }
    }
  `,
};

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

import { GRID_HEIGHT, ROW_HEADING_WIDTH } from './constants';
import HourLabels from './HourLabels';
import ScheduleTopNav from './ScheduleTopNav';
import CurrentDealerTimeIndicator from './CurrentDealerTimeIndicator';

const GridWrapper = buildStyledComponent(
  'ScheduleGrid__GridWrapper',
  styled.div,
  `
    position: relative;
    width: calc(100% - ${ROW_HEADING_WIDTH});
    margin-left: ${ROW_HEADING_WIDTH};

    ul {
      list-style: none;
      padding: 0;
    }
  `,
);

const GridDayList = buildStyledComponent(
  'ScheduleGrid__GridDayList',
  styled.ul,
  `
    position: relative;
    height: ${GRID_HEIGHT};
    display: flex;
    overflow: visible;
  `,
);

/**
 * A block for rendering the schedule grid with a series of days (columns)
 * containing various events.
 * @param children
 * @return {ReactElement}
 */
export default function ScheduleGrid({ children }) {
  return (
    <>
      <ScheduleTopNav />
      <GridWrapper>
        <HourLabels />
        <GridDayList>{children}</GridDayList>
        <CurrentDealerTimeIndicator />
      </GridWrapper>
    </>
  );
}

ScheduleGrid.propTypes = {
  children: PropTypes.node,
};

ScheduleGrid.defaultProps = {
  children: null,
};

import { find } from 'lodash';
import React from 'react';

const withMatchingInboundProgram = (WrappedComponent) => (componentProps) => {
  const { callContactAttributes, inboundPrograms } = componentProps;
  const inboundProgram = find(
    inboundPrograms,
    ({ tollFreeNumber }) =>
      callContactAttributes.dialedNumber === tollFreeNumber,
  );
  return (
    <WrappedComponent
      {...componentProps}
      inboundProgramNumber={inboundProgram}
    />
  );
};

export default withMatchingInboundProgram;

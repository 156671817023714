import React from 'react';

import DealerDetails from './DealerDetails';
import DealerLocatorContext from './DealerLocatorContext';
import Legend from './Legend';
import SearchFilters from './SearchFilters';
import SearchInput from './SearchInput';
import SearchResultsList from './SearchResultsList';
import SearchResultsMap from './SearchResultsMap';

function DealerLocator(props) {
  return <DealerLocatorContext {...props} />;
}

DealerLocator.DealerDetails = DealerDetails;
DealerLocator.Legend = Legend;
DealerLocator.SearchFilters = SearchFilters;
DealerLocator.SearchInput = SearchInput;
DealerLocator.SearchResultsList = SearchResultsList;
DealerLocator.SearchResultsMap = SearchResultsMap;

export default DealerLocator;

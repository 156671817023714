import React from 'react';
import { curry, get } from 'lodash';
import { Query, Mutation } from '@apollo/client/react/components';

import caseHeadsUpNoteQuery from './caseHeadsUpNoteQuery';
import updateCaseHeadsUpNoteMutation from './updateCaseHeadsUpNoteMutation';

export const buildMutationChild = curry(
  (WrappedComponent, componentProps, mutate, { loading }) => {
    const { caseId } = componentProps;

    const updateNote = (note) => mutate({ variables: { caseId, note } });

    return (
      <WrappedComponent
        {...componentProps}
        isSavingHeadsUpNote={!!loading}
        updateCaseHeadsUpNote={updateNote}
      />
    );
  },
);

function buildWrappedComponentWithMutation(WrappedComponent, componentProps) {
  return (
    <Mutation
      skip={!componentProps.caseId}
      mutation={updateCaseHeadsUpNoteMutation}
    >
      {buildMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, { data, loading }) =>
    buildWrappedComponentWithMutation(WrappedComponent, {
      ...componentProps,
      caseId: get(data, 'case.id'),
      headsUpNote: get(data, 'case.quickNote'),
      isLoadingHeadsUpNote: !!loading,
    }),
);

function buildWrappedComponentWithQuery(WrappedComponent, componentProps) {
  const { caseNumber } = componentProps;

  return (
    <Query
      skip={!caseNumber}
      query={caseHeadsUpNoteQuery}
      variables={{ caseNumber }}
    >
      {buildQueryChild(WrappedComponent, componentProps)}
    </Query>
  );
}

const withCaseHeadsUpNote = curry(buildWrappedComponentWithQuery);

export default withCaseHeadsUpNote;

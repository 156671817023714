import { rgba } from 'polished';

import { addDeprecationWarning } from '../utils/deprecationWarning';

import brandColors from './brandColors';

// status colors
const danger = '#cc1111';
const info = '#3DB9FF';
const success = '#00a651';
const warning = '#f2c144';

const statusColors = {
  danger,
  dangerLight: rgba(danger, 0.1),
  default: '#999999',
  info,
  infoLight: rgba(info, 0.1),
  success,
  successBackground: rgba(success, 0.1),
  successLight: rgba(success, 0.1),
  warning,
  warningLight: rgba(warning, 0.2),
};

const statusColorsWithDeprecations = addDeprecationWarning(statusColors, {
  parent: 'colors.status',
  key: 'successBackground',
  replacement: 'colors.status.successLight',
});

const colors = {
  base: {
    background: '#ffffff',
    chrome000: '#ffffff',
    chrome100: '#f7f7f7',
    chrome200: '#e6e7e8',
    chrome300: '#cccccc',
    chrome400: '#bbbbbb',
    chrome500: '#999999',
    chrome600: '#666666',
    chrome700: '#333333',
    chrome800: '#111111',
    link: '#0375a8',
    linkHover: '#0492d2',
    linkVisited: '#884893',
    overlay: rgba('#333333', 0.85),
    selectableActive: rgba('#0492d2', 0.05),
    selectableHover: rgba('#cccccc', 0.2),
    shadow: rgba('#333333', 0.4),
    shadowLight: rgba('#333333', 0.2),
    text: '#333333',
    textLight: '#666666',
  },
  brand: brandColors,
  status: statusColorsWithDeprecations,
};

export default colors;

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { buildStyledComponent } from 'decisiv-ui-utils';

import Bar from './Bar';
import Checkbox from './Checkbox';
import Switch from './Switch';
import Content from './Content';
import OptionLabel from './OptionLabel';

const modifierConfig = {
  disabled: ({ theme }) => `
    opacity: 0.5;
    pointer-events: none;
    span {
      color: ${theme.colors.base.textLight};
    }
  `,
};

const styles = ({ theme }) => `
  cursor: pointer;

  &:hover > .Content,
  .Checkbox:focus + .Content {

    label {
      color: ${theme.colors.base.linkHover};
    }
    .Switch {
      border-color: transparent;
      background-color: ${theme.colors.base.linkHover};
    }
  }
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      linkHover: PropTypes.string.isRequired,
      textLight: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const ButtonToggle = buildStyledComponent('ButtonToggle', styled.div, styles, {
  modifierConfig,
  themePropTypes,
});

ButtonToggle.Bar = Bar;
ButtonToggle.Checkbox = Checkbox;
ButtonToggle.Switch = Switch;
ButtonToggle.Content = Content;
ButtonToggle.OptionLabel = OptionLabel;

export default ButtonToggle;

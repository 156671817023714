import { t } from '@lingui/macro';

export default {
  AMBULANCE: t`Ambulance`,
  BACKHOE: t`Backhoe`,
  BOOM_TRUCK: t`Boom truck`,
  BOX_TRUCK: t`Box truck`,
  BUS: t`Bus`,
  CAR_HAULER: t`Car hauler`,
  CHASSIS: t`Chassis`,
  CONTAINER: t`Container`,
  DOLLY: t`Dolly`,
  DUMP_TRUCK: t`Dump truck`,
  FARM_TRACTOR: t`Farm tractor`,
  FIRE_TRUCK: t`Fire truck`,
  FORK_LIFT: t`Fork lift`,
  FRONT_END_LOADER: t`Front-end loader`,
  GARBAGE_TRUCK: t`Garbage truck`,
  MOFFITT: t`Moffitt`,
  MOTOR_HOME: t`Motor home`,
  OTHER_COMMERCIAL_EQUIP: t`Other commercial equipment`,
  PACKAGE_CAR: t`Package car`,
  PICKUP_TRUCK: t`Pickup truck`,
  ROLLOFF_TRUCK: t`Rolloff truck`,
  STEP_VAN: t`Step van`,
  STRAIGHT_TRUCK: t`Straight truck`,
  TRACTOR: t`Tractor`,
  TRAILER: t`Trailer`,
  UTILITY_TRAILER: t`Utility trailer`,
};

import React from 'react';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { chunk, get } from 'lodash';
import { compose, setDisplayName, withState } from 'recompose';

import { Row, Column } from 'styled-components-grid';
import {
  H4,
  Text,
  InputField,
  MessageSmall,
  QuickActionButton,
} from 'base-components';

import { getFieldDisplayValue } from '../../utils';

import {
  modalModes,
  modalActions,
  allAssetFields,
  modalStatePropType,
  validationFieldsLabels,
} from '../../constants';

const { validationFailed, searchResults } = modalModes;
const { updateSearchValue, startSearch, endSearch, clear } = modalActions;

const getFieldsColumnStyles = (colIndex, colCount) => {
  const styles = {};

  if (!colIndex) styles.paddingLeft = 0;
  if (colIndex === colCount - 1) styles.paddingRight = 0;

  return styles;
};

const isFailedValidation = (response) => !get(response, 'assets.length', 0);

const getInvalidFields = ({ state, fields }) =>
  fields
    .filter((type) => !get(state, `attempt.input.${type}`))
    .map((type) => type);

const getFieldChunks = memoize((fields, chunkSize) => {
  const sortedFields = Array.from(new Set(['vehicleType', ...fields])).sort(
    (a, b) => allAssetFields.indexOf(a) > allAssetFields.indexOf(b),
  );

  return chunk(sortedFields, chunkSize);
});

const SearchForm = (props) => {
  const { invalidFields, setInvalidFields, readOnly } = props;
  const { fields, state, validateAsset, triggerAction } = props;
  const { attempt, isSearching } = state;

  const chunkSize = [4, 5].includes(fields.length) ? 2 : 3;
  const fieldChunks = getFieldChunks(fields, chunkSize);
  const fieldValues = attempt.input;

  const updateFieldValue = (name, value = null) =>
    triggerAction(updateSearchValue, { name, value });

  const search = (event) => {
    event.preventDefault();

    const newInvalidFields = getInvalidFields(props);

    setInvalidFields(newInvalidFields);

    if (newInvalidFields.length) return;

    triggerAction(startSearch, {}, async () => {
      try {
        const validation = await validateAsset(fieldValues);
        const searchResponse = get(validation, 'data.validateAsset');
        const mode = isFailedValidation(searchResponse)
          ? validationFailed
          : searchResults;

        const data = { mode, searchResponse };

        if (mode === validationFailed) {
          const { user, reference, timestamp } = searchResponse;

          data.asset = null;
          data.attempt = { ...attempt, user, reference, timestamp };
        }

        triggerAction(endSearch, data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        triggerAction(clear);
      }
    });
  };

  return (
    <form onSubmit={search}>
      <Row>
        <Column modifiers={['col', 'padScaleX_0']}>
          <H4 modifiers="fontWeightMedium">Find Assets</H4>
        </Column>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Column modifiers={['col', 'padScaleX_0']}>
          <Text>
            <Trans>
              Enter the requested information as per the instructions above,
              then press &quot;SEARCH&quot; and select a match, if any was
              returned.
            </Trans>
          </Text>
        </Column>
      </Row>
      {fieldChunks.map((columns, rowIndex) => (
        <Row key={rowIndex} style={{ width: '100%' }}>
          {columns.map((type, colIndex) => (
            <Column
              key={type}
              style={getFieldsColumnStyles(colIndex, columns.length)}
              modifiers="col"
            >
              <InputField
                name={type}
                value={getFieldDisplayValue(type, fieldValues)}
                isValid={!invalidFields.includes(type)}
                onChange={(e) => updateFieldValue(type, e.target.value)}
                readOnly={readOnly || type === 'vehicleType'}
              >
                <Column modifiers="col">
                  <Row>
                    <InputField.Label>
                      <Trans id={validationFieldsLabels[type]} />
                    </InputField.Label>
                  </Row>
                  <Row>
                    <InputField.TextField />
                    {!!fieldValues[type] && (
                      <InputField.ActionButton
                        icon="times"
                        type="button"
                        onClick={() => updateFieldValue(type, null)}
                        modifiers={['padScaleX_0', 'hoverDanger']}
                      />
                    )}
                  </Row>
                </Column>
              </InputField>
            </Column>
          ))}
        </Row>
      ))}

      {!!invalidFields.length && (
        <Row>
          <Column modifiers={['padScale_0', 'padScaleY_2']}>
            <MessageSmall type="warning">
              <Trans>Please enter all required fields.</Trans>
            </MessageSmall>
          </Column>
        </Row>
      )}

      <Row modifiers="middle" style={{ padding: '15px 0 0 0' }}>
        <Column modifiers="padScale_0">
          <QuickActionButton
            onClick={search}
            disabled={isSearching || readOnly}
            modifiers={[(isSearching || readOnly) && 'disabled']}
          >
            <QuickActionButton.Text>
              <Trans>Search</Trans>
            </QuickActionButton.Text>
          </QuickActionButton>
        </Column>
        {isSearching && (
          <Column modifiers={['padScaleX_3', 'padScaleY_0']}>
            <Text>
              <Trans>Looking for matches... please wait.</Trans>
            </Text>
          </Column>
        )}
      </Row>
    </form>
  );
};

SearchForm.propTypes = {
  state: modalStatePropType.isRequired,
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.bool.isRequired,
  triggerAction: PropTypes.func.isRequired,
  validateAsset: PropTypes.func.isRequired,
  invalidFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  setInvalidFields: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('SearchForm'),
  withState('invalidFields', 'setInvalidFields', []),
)(SearchForm);

import { curry } from 'lodash';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';

import { NAME as CASE_CONTACTS_QUERY } from '../withContacts/caseContactsQuery';

import deleteCaseContactsGQL from './deleteCaseContactsMutation';
import updateCaseContactGQL from './updateCaseContactMutation';
import createCaseContactGQL from './createCaseContactMutation';

const buildDeleteMutationChild = curry(
  (WrappedComponent, componentProps, deleteCaseContacts) => (
    <WrappedComponent
      {...componentProps}
      deleteCaseContacts={deleteCaseContacts}
    />
  ),
);

function withDeleteMutation(WrappedComponent, componentProps) {
  const { formId, onDelete } = componentProps;

  return (
    <Mutation
      mutation={deleteCaseContactsGQL}
      onCompleted={() => onDelete(formId)}
      refetchQueries={[CASE_CONTACTS_QUERY]}
    >
      {buildDeleteMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

const buildUpdateMutationChild = curry(
  (WrappedComponent, componentProps, updateCaseContact, mutationProps) => {
    const { loading: updateCaseContactPending } = mutationProps;

    return withDeleteMutation(WrappedComponent, {
      ...componentProps,
      updateCaseContact,
      updateCaseContactPending,
    });
  },
);

function withUpdateMutation(WrappedComponent, componentProps) {
  return (
    <Mutation
      mutation={updateCaseContactGQL}
      refetchQueries={[CASE_CONTACTS_QUERY]}
    >
      {buildUpdateMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

const buildCreateMutationChild = curry(
  (WrappedComponent, componentProps, createCaseContact, mutationProps) => {
    const { formId, onCreate } = componentProps;

    const { loading: createCaseContactPending } = mutationProps;

    return withUpdateMutation(WrappedComponent, {
      ...componentProps,
      createCaseContact: (...args) => {
        createCaseContact(...args);
        onCreate(formId);
      },
      createCaseContactPending,
    });
  },
);

function withCreateMutation(WrappedComponent, componentProps) {
  return (
    <Mutation
      mutation={createCaseContactGQL}
      refetchQueries={[CASE_CONTACTS_QUERY]}
    >
      {buildCreateMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

const withContactActions = (WrappedComponent) => (componentProps) =>
  withCreateMutation(WrappedComponent, componentProps);

export default withContactActions;

import { find, values } from 'lodash';
import { compose, withProps } from 'recompose';

import { withOperationsStatus } from 'features/apolloOperationsObserver';

// List of mutations that result in changes to a dealer.
const dealerMutations = [
  'updateDealer',
  'createAfterHoursContact',
  'updateAfterHoursContact',
  'deleteAfterHoursContacts',
  'createAfterHoursEvent',
  'updateAfterHoursEvent',
  'deleteAfterHoursEvents',
];

export default compose(
  withOperationsStatus(dealerMutations, 'dealerMutationsStatus'),
  withProps(({ dealerMutationsStatus }) => ({
    isSaving: !!find(values(dealerMutationsStatus), { isLoading: true }),
  })),
);

import React from 'react';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import moment from 'moment-timezone';
import { find, inRange, isNumber, range } from 'lodash';

import {
  SECONDS_PER_ADD_BUTTON,
  SECONDS_PER_TIME_BLOCK,
  TIME_BLOCK_HEIGHT,
} from './constants';
import ScheduleBlock from './ScheduleBlock';
import ScheduleBlockPositioner from './ScheduleBlockPositioner';
import RotationGapButton from './RotationGapButton';

export default class RotationGapBlock extends React.Component {
  static propTypes = {
    day: PropTypes.oneOfType([
      MomentPropTypes.momentString,
      MomentPropTypes.momentObj,
    ]).isRequired,
    event: PropTypes.shape({
      blockStart: MomentPropTypes.momentObj.isRequired,
      blockEnd: MomentPropTypes.momentObj.isRequired,
      dayOffsetInSeconds: PropTypes.number,
    }).isRequired,
    isReadOnly: PropTypes.bool.isRequired,
  };

  state = {
    /**
     * Offset in seconds from the top of the gap block where
     * a button should be rendered.
     */
    visibleButtonOffset: null,
  };

  /**
   * Handles movement of the mouse within the ScheduleBlock
   * that wraps this unallocated block of time. Updates the
   * `visibleButtonOffset` in state to reflect the offset
   * of the button that would correspond to the current
   * mouse position.
   * @param {SyntheticMouseEvent} e
   */
  onMouseMove = (e) => {
    const target = e.currentTarget; // the ScheduleBlock
    const bounds = target.getBoundingClientRect();

    // determine the distance (in pixels) between the top of the
    // schedule-gap block and the mouse position
    const verticalOffset = e.pageY - bounds.top;

    this.setState({ visibleButtonOffset: this.nearestOffset(verticalOffset) });
  };

  /**
   * Handles the mouse leaving the ScheduleBlock, clearing
   * the `visibleButtonOffset` in state so that no buttons
   * are displayed.
   */
  onMouseLeave = () => this.setState({ visibleButtonOffset: null });

  /**
   * Determine where to place an "add" button that would match up with the
   * mouse position specified by `verticalOffset`.
   *
   * @param {number} verticalOffset - distance in pixels from top of gap block
   * @return {number} offset (in seconds) from the top of the gap block for
   *    the "add" button would contain the given vertical position
   */
  nearestOffset = (verticalOffset) => {
    const secondsPerPx = SECONDS_PER_TIME_BLOCK / TIME_BLOCK_HEIGHT;
    const verticalOffsetSeconds = verticalOffset * secondsPerPx;
    const buttonOffsets = this.buttonStartOffsets();

    return find(
      buttonOffsets,
      (offset, index) =>
        index >= buttonOffsets.length - 1 ||
        inRange(verticalOffsetSeconds, offset, buttonOffsets[index + 1]),
    );
  };

  /**
   * @return {Array<number>} an array of offsets (in seconds) for each
   *                         "add" button within the current gap block
   */
  buttonStartOffsets = () => {
    const { event } = this.props;

    // get the total duration of this unallocated block of time:
    const gapBlockSeconds = moment
      .duration(event.blockEnd.diff(event.blockStart))
      .asSeconds();

    // generate an array of offsets (in seconds) for each button
    return range(0, gapBlockSeconds, SECONDS_PER_ADD_BUTTON);
  };

  buildEvent = (blockStart, offsets = []) => {
    const start = moment(blockStart);
    const baseOffset = offsets.reduce((acc, offset) => acc + offset, 0);

    return {
      blockStart: start.clone().add(baseOffset, 's'),
      blockEnd: start.clone().add(baseOffset + SECONDS_PER_ADD_BUTTON, 's'),
    };
  };

  render() {
    const { visibleButtonOffset } = this.state;
    const { day, event, isReadOnly } = this.props;
    const { dayOffsetInSeconds = 0 } = event;

    let buttonEvent = null;
    let eventForCreate = null;

    if (isNumber(visibleButtonOffset)) {
      const blockStart = moment(event.blockStart);

      // Event with the day offset included, to position the "Add Event" button
      buttonEvent = this.buildEvent(blockStart, [
        visibleButtonOffset,
        dayOffsetInSeconds,
      ]);

      // Event without the day offset included, to pass to the "Add Event" modal
      eventForCreate = this.buildEvent(blockStart, [visibleButtonOffset]);
    }

    return (
      <ScheduleBlockPositioner day={day} event={event}>
        {({ top, height }) => (
          <ScheduleBlock
            style={{ top, height }}
            onMouseMove={isReadOnly ? undefined : this.onMouseMove}
            onMouseLeave={isReadOnly ? undefined : this.onMouseLeave}
          >
            <ul>
              {buttonEvent && (
                <RotationGapButton
                  day={day}
                  event={eventForCreate}
                  buttonEvent={buttonEvent}
                  wrapperOffset={top}
                />
              )}
            </ul>
          </ScheduleBlock>
        )}
      </ScheduleBlockPositioner>
    );
  }
}

import { Text } from 'base-components';
import PropTypes from 'prop-types';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const styles = () => `
  padding: 0 ${px2rem(5)};
`;

const themePropTypes = {
  fontWeights: PropTypes.shape({
    medium: PropTypes.number.isRequired,
  }).isRequired,
};

export default buildStyledComponent('Text', Text, styles, {
  themePropTypes,
});

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

export const modifierConfig = {
  expanded: ({ theme }) => `
    width: calc(${theme.dimensions.leftNavWidth} - ${theme.dimensions.leftNavWidthCollapsed});
    margin: 0;
    margin-left: ${theme.dimensions.leftNavWidthCollapsed};
  `,

  last: () => 'display: none;',
};

const styles = ({ theme }) => `
  background: ${theme.colors.base.chrome200};
  height: 1px;
  margin: auto;
  transition: width ${theme.dimensions.leftNavExpandTime};
  width: ${px2rem(30)};
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome200: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dimensions: PropTypes.shape({
    leftNavExpandTime: PropTypes.string.isRequired,
    leftNavWidth: PropTypes.string.isRequired,
    leftNavWidthCollapsed: PropTypes.string.isRequired,
  }).isRequired,
};

export default buildStyledComponent('MenuGroupsDivider', styled.div, styles, {
  modifierConfig,
  themePropTypes,
});

import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { withTheme } from 'styled-components';

import { px2rem } from 'decisiv-ui-utils';
import { Icon, Popover, Text } from 'base-components';
import { Column, Container, Row } from 'styled-components-grid';
import { compose, setDisplayName } from 'recompose';

import HoverSensor from 'elements/HoverSensor';
import HoursDetail from 'components/HoursDetail';
import RelativeNow from 'components/RelativeNow';
import DealerOperatingStatusLabel from 'components/DealerOperatingStatusLabel';
import { evaluateOperatingStatusType } from 'utils/operatingStatusFilter';

import InfoButton from 'blocks/InfoButton';
import LeftPanel from './LeftPanel';

export function HoursPopover({ dealer, position, theme }) {
  const { timezone } = dealer;
  const today = moment().tz(timezone);

  return (
    <RelativeNow
      interval={5000}
      timezone={timezone}
      render={({ relativeNow }) => {
        const {
          modifier: operatingStatusTypeModifier,
          value: operatingStatusTypeValue,
        } = evaluateOperatingStatusType(dealer, relativeNow);
        const arrowColor =
          operatingStatusTypeModifier === 'default'
            ? theme.colors.base.chrome600
            : operatingStatusTypeModifier;

        return (
          <Popover arrowColor={arrowColor} position={position} zIndex={2}>
            {({ isVisible }) => (
              <>
                <Popover.Target>
                  <HoverSensor>
                    {({ isActive: isHover }) => (
                      <InfoButton>
                        <InfoButton.Icon
                          name="clock"
                          style={{ marginRight: theme.dimensions.gridPad }}
                        />
                        <DealerOperatingStatusLabel
                          dealer={dealer}
                          now={relativeNow}
                        />
                        <InfoButton.Icon
                          name={isVisible ? 'chevron-up' : 'chevron-down'}
                          style={{ marginLeft: theme.dimensions.gridPad }}
                          modifiers={['mini', isHover && 'info']}
                        />
                      </InfoButton>
                    )}
                  </HoverSensor>
                </Popover.Target>
                <Popover.Content>
                  <Container
                    modifiers={['fluid']}
                    style={{ width: px2rem(310) }}
                  >
                    <HoursDetail>
                      <Row
                        modifiers={['height_100']}
                        style={{ flexWrap: 'nowrap' }}
                      >
                        <Column modifiers={['padScale_0']}>
                          <LeftPanel modifiers={operatingStatusTypeModifier}>
                            <Text
                              modifiers={[
                                'fontWeightLight',
                                'padLeft',
                                'small',
                              ]}
                            >
                              <Trans>Now</Trans>
                            </Text>
                            <HoursDetail.OperatingStatus
                              operatingStatusTypeModifier={
                                operatingStatusTypeModifier
                              }
                              operatingStatusTypeValue={
                                operatingStatusTypeValue
                              }
                            />
                            <Column modifiers={['padScaleY_2']} />
                            <Row modifiers={['middle']}>
                              <Column modifiers={['padScaleY_0']}>
                                <Icon
                                  name="clock"
                                  modifiers={['mini']}
                                  style={{ color: theme.colors.base.chrome000 }}
                                />
                                <Text
                                  modifiers={[
                                    'fontWeightLight',
                                    'padLeft',
                                    'small',
                                  ]}
                                >
                                  <Trans>Local Time</Trans>
                                </Text>
                              </Column>
                            </Row>
                            <Column modifiers={['padScaleY_0']}>
                              <HoursDetail.LocalTime
                                relativeNow={relativeNow}
                              />
                            </Column>
                            <HoursDetail.UtcTime today={today} />
                          </LeftPanel>
                        </Column>
                        <Column modifiers={['col', 'padScale_4']}>
                          <HoursDetail.Summary
                            dealer={dealer}
                            relativeNow={relativeNow}
                            popover
                          />
                        </Column>
                      </Row>
                    </HoursDetail>
                  </Container>
                </Popover.Content>
              </>
            )}
          </Popover>
        );
      }}
    />
  );
}

HoursPopover.propTypes = {
  dealer: PropTypes.shape({
    ers247: PropTypes.bool,
    ersHours: PropTypes.arrayOf(
      PropTypes.shape({
        end: PropTypes.string,
        start: PropTypes.string,
        weekDay: PropTypes.string,
      }),
    ),
    open247: PropTypes.bool,
    operatingStatus: PropTypes.shape({
      ersOpen: PropTypes.string.isRequired,
      open: PropTypes.string.isRequired,
    }),
    timezone: PropTypes.string,
    workingHours: PropTypes.arrayOf(
      PropTypes.shape({
        end: PropTypes.string,
        start: PropTypes.string,
        weekDay: PropTypes.string,
      }),
    ),
  }),
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      base: PropTypes.shape({
        chrome000: PropTypes.string.isRequired,
        chrome600: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    dimensions: PropTypes.shape({
      gridPad: PropTypes.string.isRequired,
    }),
  }).isRequired,
  position: PropTypes.string,
};

HoursPopover.defaultProps = {
  dealer: {},
  position: 'bottomRight',
};

export default compose(setDisplayName('HoursPopover'), withTheme)(HoursPopover);

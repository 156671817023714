import React from 'react';
import { MessageMedium } from 'base-components';
import { Trans } from '@lingui/macro';
import { Container, Row, Column } from 'styled-components-grid';

function NoSearchMessage() {
  return (
    <Container modifiers={['height_100']}>
      <Row modifiers={['center', 'height_100', 'middle']}>
        <Column>
          <MessageMedium>
            <MessageMedium.Header>
              <MessageMedium.Icon name="search" />
            </MessageMedium.Header>
            <MessageMedium.Section>
              <Column modifiers={['col']}>
                <MessageMedium.Text>
                  <Trans>
                    Enter at least three characters to start searching for a
                    service provider location.
                  </Trans>
                </MessageMedium.Text>
              </Column>
            </MessageMedium.Section>
          </MessageMedium>
        </Column>
      </Row>
    </Container>
  );
}

export default NoSearchMessage;

import React from 'react';

import { Container, Row, Column } from 'styled-components-grid';

import NavigationButtons from './NavigationButtons';
import CurrentDateLabel from './CurrentDateLabel';
import EventCaption from './EventCaption';

/**
 * The top title/nav area for the schedule grid. Contains nav buttons,
 * an indicator of the current date, and description of the event block
 * colors.
 *
 * @return {ReactElement}
 */
export default function ScheduleTopNav() {
  return (
    <Container modifiers={['flex_column', 'padScale_0']}>
      <Row modifiers={['middle']}>
        <NavigationButtons />
        <Column modifiers={['col', 'center']}>
          <CurrentDateLabel />
        </Column>
        <EventCaption />
      </Row>
    </Container>
  );
}

import { Text } from 'base-components';
import { Trans } from '@lingui/macro';
import { Column } from 'styled-components-grid';
import PropTypes from 'prop-types';
import React from 'react';

export default function UtcTime({ today }) {
  return (
    <Column modifiers={['col']}>
      <Text modifiers={['small', 'textLight']}>
        <Trans>
          {today.format('z')}
          UTC/GMT {today.format('Z')} Hours
        </Trans>
      </Text>
    </Column>
  );
}

UtcTime.propTypes = {
  today: PropTypes.shape({ format: PropTypes.func.isRequired }).isRequired,
};

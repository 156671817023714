import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import EditDeleteIcons from 'components/EditDeleteIcons';

import { EventPropType } from '../../propTypes';

function RotationButtons(props) {
  const { rotation, timezone } = props;
  const { start, end } = rotation;
  const { showEditModal, showDeleteModal, showOverrideContactsModal } = props;

  const now = moment().tz(timezone);
  const hasFutureSchedule = start.isAfter(now);
  const isCurrentlyActive = start.isSameOrBefore(now) && end.isSameOrAfter(now);

  return (
    <>
      {hasFutureSchedule && (
        <EditDeleteIcons
          onEdit={() => showEditModal(rotation)}
          onDelete={() => showDeleteModal(rotation)}
          iconModifiers="mini"
        />
      )}
      {isCurrentlyActive && (
        <EditDeleteIcons
          onEdit={() => showOverrideContactsModal(rotation)}
          editIcon="users"
          editTooltip={<Trans>Override Contacts</Trans>}
          iconModifiers="mini"
        />
      )}
    </>
  );
}

RotationButtons.propTypes = {
  // eslint-disable-next-line react/no-typos
  rotation: EventPropType.isRequired,
  timezone: PropTypes.string.isRequired,
  showEditModal: PropTypes.func.isRequired,
  showDeleteModal: PropTypes.func.isRequired,
  showOverrideContactsModal: PropTypes.func.isRequired,
};

export default RotationButtons;

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { buildStyledComponent } from 'decisiv-ui-utils';

import { Text } from '../../elements';

const styles = ({ theme }) => `
  color: ${theme.colors.base.link};
  text-transform: capitalize;
  padding: 0 ${theme.dimensions.gridPad};
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      link: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dimensions: PropTypes.shape({
    gridPad: PropTypes.string.isRequired,
  }).isRequired,
};

export default buildStyledComponent('Text', styled(Text), styles, {
  themePropTypes,
});

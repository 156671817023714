import PropTypes from 'prop-types';
import { css } from 'styled-components';
import { Container } from 'styled-components-grid';
import { buildStyledComponent } from 'decisiv-ui-utils';

const modifierConfig = {
  bgBrandPrimary: ({ theme }) => css`
    background: ${theme.colors.brand.primary};
    & > * {
      background: ${theme.colors.brand.primary};
    }
  `,
  bgBrandSecondary: ({ theme }) => css`
    background: ${theme.colors.brand.secondary};
    & > * {
      background: ${theme.colors.brand.secondary};
    }
  `,
  bgChrome100: ({ theme }) => css`
    background: ${theme.colors.base.chrome100};
    & > * {
      background: ${theme.colors.base.chrome100};
    }
  `,
  bgCaseDetails: () => css`
    background: #e4e7ee;
    & > * {
      background: #e4e7ee;
    }
  `,
};

const styles = '';

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome100: PropTypes.string.isRequired,
    }).isRequired,
    brand: PropTypes.shape({
      primary: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default buildStyledComponent('Page', Container, styles, {
  modifierConfig,
  themePropTypes,
});

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const modifierConfig = {
  active: ({ theme }) => `background: ${theme.colors.status.info};`,
  complete: ({ theme }) => `background: ${theme.colors.status.success};`,
  interrupted: ({ theme }) => `background: ${theme.colors.status.default};`,
};

const styles = ({ theme }) => `
  background: ${theme.colors.base.chrome300};
  height: ${px2rem(2)};
  width: 100%;
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome300: PropTypes.string.isRequired,
    }).isRequired,
    status: PropTypes.shape({
      info: PropTypes.string.isRequired,
      success: PropTypes.string.isRequired,
      default: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default buildStyledComponent(
  'Timeline__EventIndicatorBar',
  styled.div,
  styles,
  {
    modifierConfig,
    themePropTypes,
  },
);

import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';
import SelectableLi from 'elements/SelectableLi';

import { buildStyledComponent } from 'decisiv-ui-utils';

export default buildStyledComponent(
  'KeyboardShortcuts_SelectableItem',
  styled(SelectableLi),
  ({ theme }) => css`
    display: block;

    button {
      border-left: 2px solid transparent;
    }

    button:hover,
    button:focus {
      background-color: ${theme.colors.base.selectableActive};
      border-left: 2px solid ${theme.colors.base.linkHover};

      span {
        color: ${theme.colors.base.linkHover};
        text-decoration: none;
      }
    }
  `,
  {
    themePropTypes: {
      colors: PropTypes.shape({
        base: PropTypes.shape({
          selectableActive: PropTypes.string.isRequired,
          linkHover: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    },
    modifierConfig: {
      active: ({ theme }) => css`
        button {
          background-color: ${theme.colors.base.selectableActive};
          border-left: 2px solid ${theme.colors.base.linkHover};

          span {
            color: ${theme.colors.base.linkHover};
            text-decoration: none;
          }
        }
      `,
    },
  },
);

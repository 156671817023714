import gql from 'graphql-tag';

export default gql`
  query caseInboundProgramQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      inboundProgramNumber(useCachedDataForCase: true) {
        id
        billTo
        defaultCustomer {
          id
          name
          type
        }
        inboundProgramAccountType
      }
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';

import Modal from './ManageDelaysModal';

const ModalContext = React.createContext();

export default class ManageDelaysModalContext extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    caseNumber: PropTypes.string,
  };

  static defaultProps = { caseNumber: undefined };

  state = { isVisible: false };

  value = { showManageDelaysModal: () => this.setState({ isVisible: true }) };

  closeModal = () => this.setState({ isVisible: false });

  render() {
    const { isVisible } = this.state;
    const { children, caseNumber } = this.props;
    const modalProps = { onClose: this.closeModal, caseNumber };

    return (
      <ModalContext.Provider value={this.value}>
        {children}
        {isVisible && <Modal {...modalProps} />}
      </ModalContext.Provider>
    );
  }
}

ManageDelaysModalContext.Consumer = ModalContext.Consumer;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import { Dropdown, Text } from 'base-components';

import Item from './Item';

function GroupList({ groupedCustomers, isStore = false, highlightText }) {
  return groupedCustomers.map((group) => (
    <Fragment key={group.name}>
      <Dropdown.SectionHeader>
        <Text modifiers={['small', 'textLight']}>
          <Trans id={group.name} />
        </Text>
      </Dropdown.SectionHeader>
      <Dropdown.List>
        {group.customers.map((customer) => (
          <Dropdown.ListItem key={customer.id} id={customer.id}>
            <Item
              info={customer}
              isStore={isStore}
              highlightText={highlightText}
            />
          </Dropdown.ListItem>
        ))}
      </Dropdown.List>
    </Fragment>
  ));
}

GroupList.propTypes = {
  groupedCustomers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      customers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }),
  ).isRequired,
  highlightText: PropTypes.string,
};

GroupList.defaultProps = {
  highlightText: undefined,
};

export default GroupList;

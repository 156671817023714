import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { Row, Column } from 'styled-components-grid';
import { P, H2, QuickActionButton } from 'base-components';

import withContext from 'utils/withContext';

import Modal from 'components/Modal';

import CaseStatusContext from './CaseStatusContext';
import useResolveMismatches from './useResolveMismatches';

function ResolveMismatchesModal({ onClose, caseId }) {
  const {
    run: resolveMismatches,
    loading: isResolvingMismatches,
  } = useResolveMismatches(caseId);

  const onResolveMismatches = () => {
    resolveMismatches()
      // eslint-disable-next-line no-console
      .catch((e) => console.error(e))
      .finally(() => onClose());
  };

  return (
    <Modal onClose={onClose}>
      {() => (
        <Modal.Body>
          <Modal.Header>
            <Modal.HeaderIcon name="check-circle" />
          </Modal.Header>
          <Modal.Content>
            <Row modifiers="center">
              <Column>
                <H2 modifiers="fontWeightRegular">
                  <Trans>Resolve Mismatches</Trans>
                </H2>
              </Column>
            </Row>
            <Row modifiers="center">
              <Column modifiers="col">
                <P>
                  <Trans>
                    Please ensure that the correct values have been established
                    for the FIXPIX mismatch before proceeding.
                  </Trans>
                </P>
              </Column>
            </Row>
          </Modal.Content>
          <Modal.Footer>
            <Row modifiers="end">
              <Column>
                <QuickActionButton onClick={onClose} type="button">
                  <QuickActionButton.Text>
                    <Trans>Cancel</Trans>
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
              <Column>
                <QuickActionButton
                  type="button"
                  onClick={onResolveMismatches}
                  disabled={isResolvingMismatches}
                  modifiers={['secondary', isResolvingMismatches && 'disabled']}
                >
                  <QuickActionButton.Text>
                    <Trans>Proceed</Trans>
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
            </Row>
          </Modal.Footer>
        </Modal.Body>
      )}
    </Modal>
  );
}

ResolveMismatchesModal.propTypes = {
  caseId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('ResolveMismatchesModal'),
  withContext(CaseStatusContext),
)(ResolveMismatchesModal);

import { remove } from 'lodash';

class SizeStore {
  constructor(size) {
    this.size = size;
    this.subscriptions = [];
  }

  setSize(size) {
    this.size = size;
    this.subscriptions.forEach((subscription) => subscription());
  }

  subscribe(subscription) {
    this.subscriptions.push(subscription);
  }

  unsubscribe(subscription) {
    remove(this.subscriptions, (sub) => sub === subscription);
  }
}

export default SizeStore;

import React from 'react';
import gql from 'graphql-tag';
import { curry, get } from 'lodash';
import { Query, Mutation } from '@apollo/client/react/components';

const setCaseThirdPartyTransactionDetailsMutation = gql`
  mutation setCaseThirdPartyTransactionDetails(
    $caseId: ID!
    $thirdPartyTransactionDetails: String
  ) {
    setCasePaymentDetails(
      input: {
        caseId: $caseId
        paymentMethod: THIRD_PARTY_CREDIT_CARD_TXN
        thirdPartyTransactionDetails: $thirdPartyTransactionDetails
      }
    ) {
      case {
        id
        paymentMethod
        thirdPartyTransactionDetails
      }
    }
  }
`;

const buildMutationChild = curry(
  (WrappedComponent, componentProps, setCasePaymentDetails) => (
    <WrappedComponent
      {...componentProps}
      setCaseThirdPartyTransactionDetails={(thirdPartyTransactionDetails) => {
        setCasePaymentDetails({
          variables: {
            caseId: componentProps.caseId,
            thirdPartyTransactionDetails,
          },
        });
      }}
    />
  ),
);

function buildWrappedComponentWithMutation(WrappedComponent, componentProps) {
  return (
    <Mutation mutation={setCaseThirdPartyTransactionDetailsMutation}>
      {buildMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

const caseThirdPartyTransactionDetailsQuery = gql`
  query thirdPartyTransactionDetailsQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      paymentMethod
      thirdPartyTransactionDetails
    }
  }
`;

const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const caseData = get(queryProps, 'data.case', {});

    return buildWrappedComponentWithMutation(WrappedComponent, {
      ...componentProps,
      caseId: caseData.id,
      thirdPartyTransactionDetails: caseData.thirdPartyTransactionDetails,
    });
  },
);

const withCaseThirdPartyTransactionDetails = (WrappedComponent) => (
  componentProps,
) => (
  <Query
    skip={!componentProps.caseNumber}
    query={caseThirdPartyTransactionDetailsQuery}
    variables={{ caseNumber: componentProps.caseNumber }}
  >
    {buildQueryChild(WrappedComponent, componentProps)}
  </Query>
);

export default withCaseThirdPartyTransactionDetails;

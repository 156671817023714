import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import { noop } from 'lodash';
import { compose, setDisplayName } from 'recompose';

import { Row, Column } from 'styled-components-grid';
import { Text, GhostIndicator } from 'base-components';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import CaseStatusBadge from 'elements/CaseStatusBadge';

import Container from './Container';
import Checkmark from './Checkmark';
import ToggleButton from './ToggleButton';
import { STATUS_TITLES } from '../../constants';
import { getBreakpointFor } from '../utils';

const GhostBadge = () => (
  <GhostIndicator
    style={{ margin: 0, width: px2rem(30), height: px2rem(30) }}
  />
);

const GhostText = () => (
  <GhostIndicator style={{ margin: 0, width: '100%', height: px2rem(16) }} />
);

const TextRow = buildStyledComponent(
  'StatusCheckbox__TextRow',
  styled(Row),
  ({ isNavExpanded }) => css`
    @media (max-width: ${getBreakpointFor('textWrap', isNavExpanded)}) {
      flex-direction: column;
    }
  `,
);

function StatusCheckbox({
  type,
  total,
  theme: { colors },
  checked,
  onChange,
  isLoading,
  isNavExpanded,
  ...rest
}) {
  const handleOnChange = ({ target: { checked: isChecked } }) =>
    onChange(type, isChecked);

  return (
    <Container>
      <input
        type="checkbox"
        value={type}
        checked={checked}
        disabled={isLoading}
        onChange={handleOnChange}
        {...rest}
      />
      <ToggleButton modifiers={checked && 'checked'}>
        <Column modifiers="padScaleY_0">
          {isLoading && <GhostBadge />}
          {!isLoading && <CaseStatusBadge status={type} disabled={!total} />}
        </Column>
        <Column modifiers={['col', 'padScaleY_0']}>
          {isLoading && <GhostText />}
          {!isLoading && (
            <TextRow
              style={{ color: colors.base[checked ? 'link' : 'textLight'] }}
              isNavExpanded={isNavExpanded}
            >
              <Text
                style={{
                  color: 'inherit',
                  fontSize: px2rem(20),
                  marginRight: px2rem(4),
                }}
                modifiers="fontWeightLight"
              >
                {total}
              </Text>
              <Text style={{ color: 'inherit' }}>{STATUS_TITLES[type]}</Text>
            </TextRow>
          )}
        </Column>
        {!isLoading && (
          <Checkmark
            modifiers={[checked && 'checked', isNavExpanded && 'navExpanded']}
          />
        )}
      </ToggleButton>
    </Container>
  );
}

StatusCheckbox.propTypes = {
  type: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
  isNavExpanded: PropTypes.bool.isRequired,
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      base: PropTypes.shape({
        link: PropTypes.string.isRequired,
        textLight: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

StatusCheckbox.defaultProps = {
  checked: false,
  onChange: noop,
};

export default compose(
  setDisplayName('StatusCheckbox'),
  withTheme,
)(StatusCheckbox);

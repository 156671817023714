import React from 'react';
import { Trans } from '@lingui/macro';

import ApiVersion from './ApiVersion';

function FooterMessage() {
  return (
    <div>
      {process.env.REACT_APP_VERSION && (
        <div>
          <Trans>UI Version {process.env.REACT_APP_VERSION}</Trans>
        </div>
      )}
      <ApiVersion />
    </div>
  );
}

export default FooterMessage;

import gql from 'graphql-tag';

export const NAME = 'dealerNotesQuery';

export const dealerNotesGQL = gql`
  query dealerNotesQuery($id: ID!) {
    dealer(id: $id) {
      id
      notes
    }
  }
`;

export default dealerNotesGQL;

import { Trans } from '@lingui/macro';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose, setDisplayName } from 'recompose';

import { LeftNav as DefaultLeftNav } from 'base-components';
import { immutableToJS } from 'decisiv-ui-utils';

import { setExpandedMenus, setLeftNavState } from 'redux/preferences/actions';
import {
  expandedMenusSelector,
  leftNavStateSelector,
} from 'redux/preferences/selectors';

import withRoles from 'features/rbac/withUserRole';

import FooterMessage from './FooterMessage';
import menuGroups from './menuGroups';

export function LeftNav({
  expandedMenus,
  location,
  onChangeExpandedMenus,
  onChangeExpandedState,
  requestedExpanded,
  role,
}) {
  return (
    <DefaultLeftNav
      FooterMessage={FooterMessage}
      TextRenderer={({ message }) => <Trans id={message} />}
      expandedMenus={expandedMenus}
      location={location}
      menuGroups={menuGroups(role, location)}
      menuGroupsLoading={false}
      onChangeExpandedMenus={onChangeExpandedMenus}
      onChangeExpandedState={onChangeExpandedState}
      requestedExpanded={requestedExpanded}
    />
  );
}

LeftNav.propTypes = {
  expandedMenus: PropTypes.arrayOf(PropTypes.string),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  onChangeExpandedMenus: PropTypes.func,
  onChangeExpandedState: PropTypes.func,
  requestedExpanded: PropTypes.bool,
  role: PropTypes.string,
};

LeftNav.defaultProps = {
  expandedMenus: [],
  onChangeExpandedMenus: noop,
  onChangeExpandedState: noop,
  requestedExpanded: undefined,
  role: undefined,
};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    expandedMenus: expandedMenusSelector(state),
    requestedExpanded: leftNavStateSelector(state).get('requestedExpanded'),
  };
}

export default compose(
  setDisplayName('LeftNav'),
  withRouter,
  connect(mapStateToProps, {
    onChangeExpandedState: setLeftNavState,
    onChangeExpandedMenus: setExpandedMenus,
  }),
  immutableToJS,
  withRoles,
)(LeftNav);

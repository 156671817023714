import { Trans } from '@lingui/macro';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { H3, Text } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

function Billing({ caseData }) {
  const billingInfo = get(caseData, 'billingInfo') || {};
  const mainColumnStyles = { flexGrow: 1 };
  const contentColumnStyles = { maxWidth: '100%' };

  return (
    <Container modifiers={['padScale_2']}>
      <Row>
        <Column modifiers={['padScaleY_2']}>
          <H3 modifiers={['fontWeightRegular']}>
            <Trans>Billing</Trans>
          </H3>
        </Column>
      </Row>
      <Row>
        <Column style={mainColumnStyles}>
          <Row>
            <Column modifiers={['padScaleY_0']}>
              <Text modifiers={['small', 'textLight']}>
                <Trans>PO Number</Trans>
              </Text>
            </Column>
          </Row>
          <Row>
            <Column style={contentColumnStyles}>
              {billingInfo.poNumber ? (
                <Text>{billingInfo.poNumber}</Text>
              ) : (
                <Text>&mdash;</Text>
              )}
            </Column>
          </Row>
        </Column>
        <Column style={mainColumnStyles}>
          <Row>
            <Column modifiers={['padScaleY_0']}>
              <Text modifiers={['small', 'textLight']}>
                <Trans>Reference Number</Trans>
              </Text>
            </Column>
          </Row>
          <Row>
            <Column style={contentColumnStyles}>
              {billingInfo.referenceNumber ? (
                <Text>{billingInfo.referenceNumber}</Text>
              ) : (
                <Text>&mdash;</Text>
              )}
            </Column>
          </Row>
        </Column>
        <Column style={mainColumnStyles}>
          <Row>
            <Column modifiers={['padScaleY_0']}>
              <Text modifiers={['small', 'textLight']}>
                <Trans>Ticket/DR Number</Trans>
              </Text>
            </Column>
          </Row>
          <Row>
            <Column style={contentColumnStyles}>
              {billingInfo.ticketNumber ? (
                <Text>{billingInfo.ticketNumber}</Text>
              ) : (
                <Text>&mdash;</Text>
              )}
            </Column>
          </Row>
        </Column>
        <Column style={mainColumnStyles}>
          <Row>
            <Column modifiers={['padScaleY_0']}>
              <Text modifiers={['small', 'textLight']}>
                <Trans>Work Order Number</Trans>
              </Text>
            </Column>
          </Row>
          <Row>
            <Column style={contentColumnStyles}>
              {billingInfo.woNumber ? (
                <Text>{billingInfo.woNumber}</Text>
              ) : (
                <Text>&mdash;</Text>
              )}
            </Column>
          </Row>
        </Column>
        <Column style={mainColumnStyles}>
          <Row>
            <Column modifiers={['padScaleY_0']}>
              <Text modifiers={['small', 'textLight']}>
                <Trans>Invoice Number</Trans>
              </Text>
            </Column>
          </Row>
          <Row>
            <Column style={contentColumnStyles}>
              {billingInfo.invoiceNumber ? (
                <Text>{billingInfo.invoiceNumber}</Text>
              ) : (
                <Text>&mdash;</Text>
              )}
            </Column>
          </Row>
        </Column>
        <Column style={mainColumnStyles}>
          <Row>
            <Column modifiers={['padScaleY_0']}>
              <Text modifiers={['small', 'textLight']}>
                <Trans>Amount</Trans>
              </Text>
            </Column>
          </Row>
          <Row>
            <Column style={contentColumnStyles}>
              {billingInfo.amount ? (
                <Text>{billingInfo.amount}</Text>
              ) : (
                <Text>&mdash;</Text>
              )}
            </Column>
          </Row>
        </Column>
      </Row>
      <Row>
        <Column style={mainColumnStyles}>
          <Row>
            <Column modifiers={['padScaleY_0']}>
              <Text modifiers={['small', 'textLight']}>
                <Trans>Billing Comment</Trans>
              </Text>
            </Column>
          </Row>
          <Row>
            <Column style={contentColumnStyles}>
              {billingInfo.comment ? (
                <Text>{billingInfo.comment}</Text>
              ) : (
                <Text>&mdash;</Text>
              )}
            </Column>
          </Row>
        </Column>
      </Row>
    </Container>
  );
}

Billing.propTypes = {
  caseData: PropTypes.shape({
    billingInfo: PropTypes.shape({
      amount: PropTypes.string,
      comment: PropTypes.string,
      invoiceNumber: PropTypes.string,
      poNumber: PropTypes.string,
      referenceNumber: PropTypes.string,
      ticketNumber: PropTypes.string,
    }),
  }).isRequired,
};

export default Billing;

import React from 'react';
import moment from 'moment-timezone';
import { get } from 'lodash';
import { Trans } from '@lingui/macro';

import { Row, Column } from 'styled-components-grid';
import { H4, Text, Popover } from 'base-components';

import ButtonLinkWithIcon from 'elements/ButtonLinkWithIcon';

import { getFieldDisplayValue } from '../../utils';

import {
  allAssetFields,
  modalStatePropType,
  validationFieldsLabels,
} from '../../constants';

const detailsLabelColStyles = { width: 90 };

function ValidationMetadata({ state: { searchResponse, attempt } }) {
  const data = get(searchResponse, 'reference') ? searchResponse : attempt;
  const user = get(data, 'user') || {};
  const searchValues = attempt.input;
  const searchedAt = moment(data.timestamp)
    .tz(moment.tz.guess())
    .format('D MMM YYYY, h:mm A z');

  const userDisplayName = user.name
    ? `${user.name.trim()} (${user.email})`
    : user.email;

  return (
    <>
      <Row style={{ margin: `30px 0 5px 0` }}>
        <Column modifiers={['col', 'padScaleX_0']}>
          <H4 modifiers="fontWeightMedium">
            <Trans>Validation Details</Trans>
          </H4>
        </Column>
      </Row>
      <Row modifiers="middle">
        <Column style={detailsLabelColStyles} modifiers="padScale_0">
          <Text modifiers={['small', 'textLight']}>
            <Trans>Date:</Trans>
          </Text>
        </Column>
        <Column modifiers={['col', 'padScaleY_0']}>
          <Text>{searchedAt}</Text>
        </Column>
      </Row>
      <Row modifiers="middle">
        <Column style={detailsLabelColStyles} modifiers="padScale_0">
          <Text modifiers={['small', 'textLight']}>
            <Trans>Author:</Trans>
          </Text>
        </Column>
        <Column modifiers={['col', 'padScaleY_0']}>
          <Text>{userDisplayName}</Text>
        </Column>
      </Row>
      <Row modifiers="middle">
        <Column style={detailsLabelColStyles} modifiers="padScale_0">
          <Text modifiers={['small', 'textLight']}>
            <Trans>Reference:</Trans>
          </Text>
        </Column>
        <Column modifiers={['col', 'padScaleY_0']}>
          <Text>{data.reference}</Text>
        </Column>
      </Row>
      <Row modifiers="middle">
        <Column style={detailsLabelColStyles} modifiers="padScale_0">
          <Text modifiers={['small', 'textLight']}>
            <Trans>Search Criteria:</Trans>
          </Text>
        </Column>
        <Column modifiers="padScaleY_0">
          <Popover position="top">
            <Popover.Target>
              <ButtonLinkWithIcon>
                <Trans>Show</Trans>
              </ButtonLinkWithIcon>
            </Popover.Target>
            <Popover.Content style={{ width: 360 }}>
              <Row style={{ flexWrap: 'nowrap' }}>
                <Column modifiers={['col', 'padScale_4']}>
                  {allAssetFields.map((field) => (
                    <Row key={field}>
                      <Column style={{ width: 120, textAlign: 'right' }}>
                        <Text modifiers={['textLight', 'small']}>
                          {validationFieldsLabels[field]}:
                        </Text>
                      </Column>
                      <Column modifiers={['col', 'padScaleX_3']}>
                        <Text style={{ wordBreak: 'break-word' }}>
                          {getFieldDisplayValue(field, searchValues) || (
                            <span>&mdash;</span>
                          )}
                        </Text>
                      </Column>
                    </Row>
                  ))}
                </Column>
              </Row>
            </Popover.Content>
          </Popover>
        </Column>
      </Row>
    </>
  );
}

ValidationMetadata.propTypes = {
  // eslint-disable-next-line react/no-typos
  state: modalStatePropType.isRequired,
};

export default ValidationMetadata;

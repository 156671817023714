import { PANEL_STATUSES } from 'compositions/CaseStatus';

import { fieldIds, PAYMENT_METHODS } from '../constants';

const { partial, complete } = PANEL_STATUSES;

const {
  STORE_ACCOUNT: storeAccount,
  NONE: continueWithoutPayment,
} = PAYMENT_METHODS;

// From https://docs.google.com/document/d/1g442usX08QWB0AfveSitIHKnlFfiq7Zb3eNeZvMA62Y/edit
//
// Selected “Store account” payment method, or
// Selected “continue without a payment method”

export default ({ setCasePanelStatus, paymentMethod: method }) => {
  const status = [storeAccount, continueWithoutPayment].includes(method)
    ? complete
    : partial;

  return setCasePanelStatus(
    status,
    status === complete
      ? undefined
      : { [fieldIds.store.type]: { severity: 'missing' } },
  );
};

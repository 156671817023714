import memoize from 'memoize-one';
import { get, omit, isEmpty, find } from 'lodash';

import { PANEL_STATUSES } from 'compositions/CaseStatus';

import { fieldIds } from './constants';

const { incomplete, complete, invalid, partial } = PANEL_STATUSES;

// Normalizing the locations removes formatting differences in the address.
// This allows the same components to render both types in the union.
// eslint-disable-next-line import/prefer-default-export
export function normalizeInboundProgramLocations(locations) {
  return locations.map((loc) => {
    let newLoc = { ...loc };

    if (loc.displayAddress) {
      const { city, province } = loc.displayAddress;
      newLoc = { ...omit(loc, 'displayAddress'), city, state: province };
    }

    newLoc.name = newLoc?.name || loc?.dealerName || loc?.customerName;

    return newLoc;
  });
}

const getFieldStatus = (hasSelectedValue, hasSearchValude) => {
  if (hasSearchValude && hasSelectedValue) return null;

  return hasSearchValude && !hasSelectedValue ? 'invalid' : 'missing';
};

const getStatus = memoize(
  (program, location, programSearch, locationSearch, withLocation) => {
    const flags = [program, location, programSearch, locationSearch];

    if (flags.every((value) => !value)) return [incomplete];
    if (program && (!withLocation || location)) return [complete];

    const severity = {
      [fieldIds.program]: getFieldStatus(program, programSearch),
      [fieldIds.location]: withLocation
        ? getFieldStatus(location, locationSearch)
        : null,
    };

    return Object.values(severity).includes('invalid')
      ? [invalid, severity]
      : [partial, severity];
  },
);

export function updatePanelStatus(props, state) {
  const { setCasePanelStatus } = props;
  const { displayInboundProgramLocationSelector } = state;
  const { inboundProgramSearchValue, inboundProgramNumberId } = state;
  const { inboundProgramLocationSearchValue, inboundProgramShipTo } = state;

  const [panelStatus, fieldsStatus] = getStatus(
    !!inboundProgramNumberId,
    !!inboundProgramShipTo,
    !!inboundProgramSearchValue,
    !!inboundProgramLocationSearchValue,
    displayInboundProgramLocationSelector,
  );

  setCasePanelStatus(panelStatus, fieldsStatus);
}

export function findInboundProgramLocationByShipTo(locations, shipTo) {
  if (!shipTo || isEmpty(locations)) return undefined;

  return find(locations, (location) => location.shipTo === shipTo);
}

const middleDot = String.fromCharCode(183);

export function getInboundProgramLocationDisplayValue(shipTo, locations) {
  const location = findInboundProgramLocationByShipTo(locations, shipTo);

  if (!location) return '';

  const name = get(location, 'name') || '';
  const city = get(location, 'city') || '';
  const state = get(location, 'state') || '';

  return [name, middleDot, `${city},`, state].join(' ');
}

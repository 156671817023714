import PropTypes from 'prop-types';
import { castArray } from 'lodash';

import USER_ROLES from '../constants';

/**
 * UI component that can be wrapped around a slice of the component tree
 * to only render its children if the current user role (the `role` prop)
 * is among the specified `allowedRoles` AND the current user role is NOT
 * among the specified `excludedRoles`.
 *
 * If the user is _not_ allowed access, an optional `unauthorized` alternative
 * will be rendered.
 *
 * Typically will be used via the {@link IfRolesMatch} wrapper.
 */
export default function AuthCheck({
  allowedRoles,
  excludedRoles,
  loadingRole,
  role: userRole,
  unauthorized,
  children,
}) {
  if (loadingRole) {
    // wait until we actually know the user role to make a decision...
    return null;
  }

  if (!castArray(excludedRoles).includes(USER_ROLES[userRole])) {
    if (castArray(allowedRoles).includes(USER_ROLES[userRole])) {
      return children;
    }
  }

  return unauthorized;
}

/**
 * Custom prop-type checker to require at least one
 * of `allowedRoles` or `excludedRoles`.
 */
function requiredRolesPropType(props, propName, componentName) {
  if (!props.allowedRoles && !props.excludedRoles) {
    return new Error(
      `'${componentName}' requires one or both of 'allowedRoles' or 'excludedRoles'.`,
    );
  }
  ['allowedRoles', 'excludedRoles'].forEach((prop) => {
    if (props[prop]) {
      PropTypes.checkPropTypes(
        {
          [prop]: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.string,
          ]),
        },
        { [prop]: props[prop] },
        'prop',
        componentName,
      );
    }
  });
  return null;
}

AuthCheck.propTypes = {
  allowedRoles: requiredRolesPropType,
  excludedRoles: requiredRolesPropType,
  loadingRole: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  unauthorized: PropTypes.node,
  children: PropTypes.node.isRequired,
};

AuthCheck.defaultProps = {
  allowedRoles: [],
  excludedRoles: [],
  unauthorized: null,
};

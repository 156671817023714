import React from 'react';
import PropTypes from 'prop-types';
import { defineMessage, Trans } from '@lingui/macro';
import toast from 'react-hot-toast';

import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';
import { ButtonIconRectangle, Tooltip, Text, Icon } from 'base-components';

const ERROR_CODES = [
  'external_api_error',
  'unexpected_exception',
  'application_error',
  'after_hours_rotation_conflict',
];

const genericErrorMessage = defineMessage({
  message: `The application has detected an issue with the server. This may cause some delays or errors in displaying the data. Please refresh the page and try again. {errorCode}`,
});

const rotationConflictsErrorMessage = defineMessage({
  message: `The rotation you're trying to change or delete conflicts with one or more existing rotations. Adjust your rotation settings so it doesn't overlap with other rotations. {errorCode}`,
});

let lastToastId;

// Only show the generic error toast for one of the ERROR_CODES
export function showGenericErrorToast(errors) {
  const error = errors?.find?.((err) => ERROR_CODES.includes(err.code));

  if (!error) return;
  if (lastToastId) toast.dismiss(lastToastId);

  const errorMessage =
    error.code === 'after_hours_rotation_conflict'
      ? rotationConflictsErrorMessage
      : genericErrorMessage;

  lastToastId = toast(
    (t) => (
      <ErrorToast
        id={t.id}
        errorCode={error.code}
        errorMessage={errorMessage}
      />
    ),
    {
      style: {
        border: '3px solid #cc1111',
        margin: 24,
        padding: 0,
        maxWidth: 520,
      },
      duration: 10000,
    },
  );
}

export function ErrorToast({ id, errorMessage, errorCode }) {
  return (
    <Row modifiers={['flex_1', 'middle', 'padScaleX_0', 'padScaleY_2']}>
      <Column modifiers="padScaleX_3">
        <Icon
          name="exclamation-circle-f"
          modifiers={['danger']}
          style={{ fontSize: px2rem(24) }}
        />
      </Column>

      <Column modifiers={['flex_1']}>
        <Text style={{ whiteSpace: 'pre-line' }}>
          <Trans
            id={errorMessage}
            values={{ errorCode: errorCode && `(${errorCode})` }}
          />
        </Text>
      </Column>

      {typeof id !== 'undefined' && (
        <Column modifiers={['padScaleX_3']}>
          <Tooltip position="top">
            <Tooltip.Target>
              <ButtonIconRectangle
                modifiers={['hoverDanger']}
                onClick={() => toast.dismiss(id)}
                type="button"
                style={{ padding: 0, width: 25 }}
              >
                <Icon name="times" modifiers={['mini']} />
              </ButtonIconRectangle>
            </Tooltip.Target>

            <Tooltip.Content>
              <Trans>Dismiss</Trans>
            </Tooltip.Content>
          </Tooltip>
        </Column>
      )}
    </Row>
  );
}

ErrorToast.propTypes = {
  errorMessage: PropTypes.any.isRequired,
  id: PropTypes.string,
  errorCode: PropTypes.string,
};

ErrorToast.defaultProps = {
  id: undefined,
  errorCode: undefined,
};

export default ErrorToast;

/* eslint-disable import/prefer-default-export */

import buildEnum from 'utils/buildEnum';

import { allRequestLineKeys } from '../CaseRequestsPanelContext/constants';

export const fieldIds = ['requested', 'agreed', 'supplied'].reduce(
  (acc, type) => ({
    ...acc,
    [type]: buildEnum(
      allRequestLineKeys,
      (key) => `request.lines.${type}.{id}.${key}`,
    ),
  }),
  {},
);

import PropTypes from 'prop-types';
import React from 'react';

import TooltipBlock from '../../blocks/Tooltip';

import { TOOLTIP_CONTEXT } from './constants';

function Content({ children, ...rest }, context) {
  const { position, showTooltip, zIndex } = context[TOOLTIP_CONTEXT];

  if (!showTooltip) {
    return null;
  }

  return (
    <TooltipBlock.Content modifiers={[position]} zIndex={zIndex} {...rest}>
      {children}
      <TooltipBlock.Arrow modifiers={[position]} />
    </TooltipBlock.Content>
  );
}

Content.contextTypes = {
  [TOOLTIP_CONTEXT]: PropTypes.shape({}).isRequired,
};

Content.displayName = 'Tooltip__Content';

Content.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Content;

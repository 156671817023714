import React, { Component } from 'react';
import { Trans } from '@lingui/macro';

import { ButtonIconRectangle, Tooltip } from 'base-components';
import { Column } from 'styled-components-grid';

import UpdateRollTimeModal from './UpdateRollTimeModal';

class EditRollTimeActionButton extends Component {
  state = { showModal: false };

  showModal = () => this.setState({ showModal: true });

  hideModal = () => this.setState({ showModal: false });

  render() {
    return (
      <>
        <Column modifiers={['padScale_0']}>
          <Tooltip position="top">
            <Tooltip.Target>
              <ButtonIconRectangle type="button" onClick={this.showModal}>
                <ButtonIconRectangle.Icon name="pencil" />
              </ButtonIconRectangle>
            </Tooltip.Target>
            <Tooltip.Content>
              <Trans>Edit Roll Time</Trans>
            </Tooltip.Content>
          </Tooltip>
        </Column>

        {this.state.showModal && (
          <UpdateRollTimeModal {...this.props} onClose={this.hideModal} />
        )}
      </>
    );
  }
}

export default EditRollTimeActionButton;

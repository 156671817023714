import styled from 'styled-components';
import PropTypes from 'prop-types';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import constants from './constants';

const { ARROW_WIDTH, ARROW_OFFSET } = constants;

const modifierConfig = {
  bottom: () => `
    top: calc(100% + ${px2rem(ARROW_WIDTH)});
  `,

  bottomLeft: () => `
    top: calc(100% + ${px2rem(ARROW_WIDTH)});
  `,

  bottomRight: () => `
    top: calc(100% + ${px2rem(ARROW_WIDTH)});
  `,

  left: () => `
    right: calc(100% + ${px2rem(ARROW_WIDTH)});
  `,

  leftBottom: () => `
    right: calc(100% + ${px2rem(ARROW_WIDTH)});
    top: -${px2rem(ARROW_OFFSET)};
  `,

  leftTop: () => `
    bottom: -${px2rem(ARROW_OFFSET)};
    right: calc(100% + ${px2rem(ARROW_WIDTH)});
  `,

  right: () => `
    left: calc(100% + ${px2rem(ARROW_WIDTH)});
  `,

  rightBottom: () => `
    left: calc(100% + ${px2rem(ARROW_WIDTH)});
    top: -${px2rem(ARROW_OFFSET)};
  `,

  rightTop: () => `
    bottom: -${px2rem(ARROW_OFFSET)};
    left: calc(100% + ${px2rem(ARROW_WIDTH)});
  `,

  top: () => `
    bottom: calc(100% + ${px2rem(ARROW_WIDTH)});
  `,

  topLeft: () => `
    bottom: calc(100% + ${px2rem(ARROW_WIDTH)});
  `,

  topRight: () => `
    bottom: calc(100% + ${px2rem(ARROW_WIDTH)});
  `,

  noWrap: () => `
    white-space: nowrap;
  `,
};

const styles = ({ theme, hideContent, zIndex }) => `
  background-color: ${theme.colors.base.background};
  border-radius: ${theme.dimensions.borderRadius};
  ${theme.planes.sky1};
  display: ${hideContent ? 'none' : 'block'};
  position: absolute;
  z-index: ${zIndex};
`;

const propTypes = {
  hideContent: PropTypes.bool,
  zIndex: PropTypes.number,
};

const defaultProps = {
  hideContent: false,
  zIndex: 1,
};

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      background: PropTypes.string.isRequired,
      shadow: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dimensions: PropTypes.shape({
    borderRadius: PropTypes.string.isRequired,
  }).isRequired,
};

const Content = buildStyledComponent('Popover__Content', styled.div, styles, {
  defaultProps,
  modifierConfig,
  propTypes,
  themePropTypes,
});

export default Content;

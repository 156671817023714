import React from 'react';
import { Query } from '@apollo/client/react/components';
import { curry, get } from 'lodash';

import userQuery from './userInfoQuery';

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const { data } = queryProps;
    const user = get(data, 'currentUserProfile.user', {});

    return <WrappedComponent {...componentProps} user={user} />;
  },
);

const withUserInfo = (WrappedComponent) => (componentProps) => (
  <Query query={userQuery}>
    {buildQueryChild(WrappedComponent, componentProps)}
  </Query>
);

export default withUserInfo;

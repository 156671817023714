import gql from 'graphql-tag';

export const NAME = 'createCaseMutation';

const query = gql`
  mutation createCaseMutation {
    createCase {
      case {
        id
        caseNumber
      }
    }
  }
`;

export default query;

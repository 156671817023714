import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Icon } from 'base-components';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const styles = ({ theme }) => `
  align-items: center;
  background-color: #3DB9FF;
  border-radius: ${theme.dimensions.borderRadius};
  display: flex;
  height: ${px2rem(30)};
  justify-content: center;
  width: ${px2rem(30)};
`;

const themePropTypes = {
  dimensions: PropTypes.shape({
    borderRadius: PropTypes.string.isRequired,
  }).isRequired,
};

const StyledTravelEstimateBadge = buildStyledComponent(
  'StyledTravelEstimateBadge',
  styled.div,
  styles,
  {
    themePropTypes,
  },
);

function TravelEstimateBadge() {
  return (
    <StyledTravelEstimateBadge>
      <Icon modifiers="chrome000" name="directions" />
    </StyledTravelEstimateBadge>
  );
}

export default TravelEstimateBadge;

import React, { Component } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { last, isEmpty } from 'lodash';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { H2, P, QuickActionButton, Text } from 'base-components';
import MessageSmall from 'base-components/blocks/MessageSmall';
import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';

import RadioButton from 'blocks/RadioButton';

import DatetimeSelector from 'components/DatetimeSelector';
import Modal from 'components/Modal';

import CaseStatusBadge from 'elements/CaseStatusBadge';
import RadioWrapper from 'elements/RadioWrapper';

import withContext from 'utils/withContext';

import CaseStatusContext from '../CaseStatusContext';
import { CASE_STATUS } from '../constants';

import {
  allowedStatuses,
  labelForStatus,
  errorTypes,
  messageForError,
} from './constants';
export class UpdateStatusModal extends Component {
  static propTypes = {
    isUpdatingCaseStatus: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    servicingDealerTimezone: PropTypes.string,
    status: PropTypes.string.isRequired,
    statusHistory: PropTypes.arrayOf(
      PropTypes.shape({ changedAt: PropTypes.string.isRequired }).isRequired,
    ).isRequired,
    updateCaseStatus: PropTypes.func.isRequired,
    createdAt: PropTypes.string.isRequired,
  };

  static defaultProps = {
    servicingDealerTimezone: undefined,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedDate: null,
      selectedDateIsValid: false,
      selectedStatus: null,
      selectedTimezone: props.servicingDealerTimezone,
      errors: [],
    };
  }

  getEarliestValidTime = () => {
    const { statusHistory } = this.props;

    const { changedAt } = last(statusHistory);
    return moment(changedAt).toDate();
  };

  getDiffToCreatedCaseTime = (selectedDate, as) =>
    moment(selectedDate)
      .startOf('minute')
      .diff(moment(this.props.createdAt).startOf('minute'), as, true);

  getDowntimeDuration = () => {
    const duration = this.getDiffToCreatedCaseTime(
      this.state.selectedDate,
      'minutes',
    );
    const hours = Math.floor(duration / 60);
    const minutes = Math.round(duration % 60);
    return { hours, minutes };
  };

  isDowntimeTooLong = (
    selectedDate = this.state.selectedDate,
    selectedStatus = this.state.selectedStatus,
  ) =>
    selectedStatus === CASE_STATUS.rolling &&
    this.getDiffToCreatedCaseTime(selectedDate, 'hours') >= 3;

  handleStatusChange = ({ target: { value } }) =>
    this.setState({
      selectedStatus: value,
      errors: this.isDowntimeTooLong(undefined, value)
        ? [errorTypes.longDowntime]
        : [],
    });

  handleDateChange = ({
    selectedDate,
    selectedDateIsValid,
    selectedTimezone,
  }) => {
    this.setState({
      selectedDate,
      selectedDateIsValid,
      selectedTimezone,
      errors: this.isDowntimeTooLong(selectedDate)
        ? [errorTypes.longDowntime]
        : [],
    });
  };

  handleError = () => {
    // if an error happens, it's due to user's clock being inaccurate
    // and the datetime they sent is in the future
    this.setState({
      selectedDateIsValid: false,
      errors: this.state.errors.push(errorTypes.futureTime),
    });
  };

  updateStatus = () =>
    this.props
      .updateCaseStatus(this.state.selectedStatus, {
        timestamp: this.state.selectedDate.toISOString(),
      })
      .then(this.props.onClose)
      .catch(this.handleError);

  renderRadioButtonForStatus = (status) => {
    const { status: currentCaseStatus } = this.props;
    const { selectedStatus } = this.state;

    const id = `case_status_${status}`;
    const isCurrentStatus = status === currentCaseStatus;

    return (
      <Row key={status}>
        <Column modifiers={['col', 'padScale_0']}>
          <RadioWrapper modifiers={['compact', isCurrentStatus && 'disabled']}>
            <RadioButton>
              <RadioButton.Label htmlFor={id}>
                <Row modifiers={['middle', 'flex_1']}>
                  <Column style={{ paddingLeft: 0 }} modifiers="padScaleY_0">
                    <CaseStatusBadge status={status} />
                  </Column>
                  <Column modifiers={['col', 'padScaleY_0']}>
                    <Text modifiers="textLight">{labelForStatus[status]}</Text>
                  </Column>
                  <Column style={{ paddingRight: 0 }} modifiers="padScaleY_0">
                    {isCurrentStatus ? (
                      <Text modifiers="small">
                        <Trans>Current Status</Trans>
                      </Text>
                    ) : (
                      <>
                        <RadioButton.Input
                          id={id}
                          name="status"
                          value={status}
                          checked={status === selectedStatus}
                          onChange={this.handleStatusChange}
                        />
                        {/* This label is what gets styled as the radio button */}
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label htmlFor={id} />
                      </>
                    )}
                  </Column>
                </Row>
              </RadioButton.Label>
            </RadioButton>
          </RadioWrapper>
        </Column>
      </Row>
    );
  };

  render() {
    const { isUpdatingCaseStatus, onClose } = this.props;
    const { selectedStatus, errors } = this.state;

    return (
      <Modal onClose={onClose}>
        {() => (
          <Modal.Body>
            <Modal.Header>
              <Modal.HeaderIcon name="refresh" />
            </Modal.Header>
            <Modal.Content>
              <Row modifiers="center">
                <Column>
                  <H2 modifiers="fontWeightRegular">
                    <Trans>Update Case Status?</Trans>
                  </H2>
                </Column>
              </Row>
              <Row modifiers="center" style={{ marginBottom: px2rem(40) }}>
                <Column modifiers={['col']}>
                  <P>
                    {this.props.servicingDealerTimezone ? (
                      <Trans>
                        Select the new status below, then enter the date and
                        time the status officially changed using the service
                        provider&#39;s time zone.
                      </Trans>
                    ) : (
                      <Trans>
                        Select the new status below, then enter the date and
                        time the status officially changed using your time zone.
                      </Trans>
                    )}
                  </P>
                </Column>
              </Row>
              {allowedStatuses.map(this.renderRadioButtonForStatus)}
              <Row modifiers="middle" style={{ marginTop: px2rem(20) }}>
                <Column modifiers={['col', 'padScale_0']}>
                  <DatetimeSelector
                    onChange={(
                      selectedDate,
                      selectedTimezone,
                      { isValid: selectedDateIsValid },
                    ) =>
                      this.handleDateChange({
                        selectedDate,
                        selectedDateIsValid,
                        selectedTimezone,
                      })
                    }
                    selectedDatetime={this.state.selectedDate}
                    selectedTimezone={this.state.selectedTimezone}
                    validBetween={[this.getEarliestValidTime()]}
                  />
                </Column>
              </Row>
              {!isEmpty(errors) &&
                errors.map((error) => {
                  const isLongDowntimeError = error === errorTypes.longDowntime;
                  return (
                    <Row modifiers={['padScaleY_2']} key={error}>
                      <Column modifiers={['col']}>
                        <MessageSmall style={{ alignItems: 'flex-start' }}>
                          <MessageSmall.Icon
                            type={isLongDowntimeError ? 'warning' : 'danger'}
                            style={{ marginTop: 3 }}
                            modifiers={[
                              'mini',
                              isLongDowntimeError ? 'warning' : 'danger',
                            ]}
                          />
                          <MessageSmall.Text style={{ whiteSpace: 'initial' }}>
                            <Trans
                              id={messageForError[error]}
                              values={
                                isLongDowntimeError
                                  ? this.getDowntimeDuration()
                                  : {}
                              }
                            />
                          </MessageSmall.Text>
                        </MessageSmall>
                      </Column>
                    </Row>
                  );
                })}
            </Modal.Content>
            <Modal.Footer>
              <Row modifiers="end">
                <Column>
                  <QuickActionButton onClick={onClose} type="button">
                    <QuickActionButton.Text>
                      <Trans>Keep Status</Trans>
                    </QuickActionButton.Text>
                  </QuickActionButton>
                </Column>
                <Column>
                  <QuickActionButton
                    type="button"
                    onClick={this.updateStatus}
                    disabled={
                      !selectedStatus ||
                      !this.state.selectedDateIsValid ||
                      isUpdatingCaseStatus
                    }
                    modifiers={[
                      'secondary',
                      (!selectedStatus ||
                        !this.state.selectedDateIsValid ||
                        isUpdatingCaseStatus) &&
                        'disabled',
                    ]}
                  >
                    <QuickActionButton.Text>
                      <Trans>Update Status</Trans>
                    </QuickActionButton.Text>
                  </QuickActionButton>
                </Column>
              </Row>
            </Modal.Footer>
          </Modal.Body>
        )}
      </Modal>
    );
  }
}

export default compose(
  setDisplayName('UpdateStatusModal'),
  withContext(CaseStatusContext),
)(UpdateStatusModal);

import React from 'react';
import PropTypes from 'prop-types';

import CaseCall from './CaseCall';
import CaseAutomatedCallEntry from './CaseAutomatedCallEntry';

const componentByType = { CaseCall, CaseAutomatedCallEntry };

export const Entry = ({ entry, ...rest }) => {
  const Component = componentByType[entry.__typename];

  return <Component {...rest} {...entry} key={entry.id} />;
};

const validTypes = ['CaseCall', 'CaseAutomatedCallEntry'];

Entry.propTypes = {
  entry: PropTypes.shape({
    id: PropTypes.string.isRequired,
    __typename: PropTypes.oneOf(validTypes).isRequired,
  }),
};

export default Entry;

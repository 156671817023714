import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';
import { Row } from 'styled-components-grid';

const styles = `
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export default buildStyledComponent(
  'ScrollableContainer__Row',
  styled(Row),
  styles,
);

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const styles = ({ theme }) => `
  background: linear-gradient(to right, transparent 0%,${
    theme.colors.base.chrome100
  } 7%,${
  theme.colors.base.chrome100
} 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  bottom: 0;
  padding: ${px2rem(5)};
  position: absolute;
  right: 0;
  top: 0;
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome100: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const ButtonBox = buildStyledComponent('ButtonBox', styled.div, styles, {
  themePropTypes,
});

export default ButtonBox;

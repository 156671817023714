import React from 'react';
import gql from 'graphql-tag';
import { get } from 'lodash';
import { Trans } from '@lingui/macro';
import { useQuery } from '@apollo/client';

const query = gql`
  query apiVersion {
    version {
      api
    }
  }
`;

const threeMinutes = 3 * 60 * 1000;

export default function APIVersion() {
  const { data } = useQuery(query, { pollInterval: threeMinutes });

  return <Trans>API Version {get(data, 'version.api', '--')}</Trans>;
}

import PropTypes from 'prop-types';
import React from 'react';

import StyledLeftNav from '../../blocks/LeftNav';

import MenuGroupItem from './MenuGroupItem';
import menuPropTypes from './menuPropTypes';

export const ghostMenuGroups = () =>
  [4, 2, 1].reduce((acc, val) => {
    const currentGhostGroup = Array.from(new Array(val), (v, idx) => ({
      // path is used as a key later on, so a unique value must
      // be provided here.
      path: `${val}:${idx}`,
      loading: true,
    }));
    return acc.concat([currentGhostGroup]);
  }, []);

function MenuGroupsList({
  TextRenderer,
  expanded,
  expandedMenus,
  handleExpandMenuClick,
  location,
  menuGroups,
  menuGroupsLoading,
}) {
  const renderedMenuGroups = menuGroupsLoading ? ghostMenuGroups() : menuGroups;

  return (
    <StyledLeftNav.MenuGroupsList>
      {renderedMenuGroups.map((menuGroup, index) => {
        const last = renderedMenuGroups.length - 1 === index;
        return (
          <MenuGroupItem
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            TextRenderer={TextRenderer}
            expanded={expanded}
            expandedMenus={expandedMenus}
            handleExpandMenuClick={handleExpandMenuClick}
            last={last}
            location={location}
            menuGroup={menuGroup}
            menuGroupsLoading={menuGroupsLoading}
          />
        );
      })}
    </StyledLeftNav.MenuGroupsList>
  );
}

MenuGroupsList.propTypes = {
  TextRenderer: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  expandedMenus: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleExpandMenuClick: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/no-typos
  menuGroups: menuPropTypes.menuGroups.isRequired,
  menuGroupsLoading: PropTypes.bool.isRequired,
};

export default MenuGroupsList;

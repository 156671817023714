import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

const styles = css`
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border: 8px solid transparent;
`;

const modifierConfig = {
  info: ({ theme }) => `
    border-bottom-color: ${theme.colors.status.info};
  `,

  success: ({ theme }) => `
    border-bottom-color: ${theme.colors.status.success};
  `,

  warning: ({ theme }) => `
    border-bottom-color: ${theme.colors.status.warning};
  `,
};

const themePropTypes = {
  colors: PropTypes.shape({
    status: PropTypes.shape({
      info: PropTypes.string.isRequired,
      success: PropTypes.string.isRequired,
      warning: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default buildStyledComponent(
  'CaseEventTimelineArrow',
  styled.div,
  styles,
  {
    modifierConfig,
    themePropTypes,
  },
);

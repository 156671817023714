import gql from 'graphql-tag';

// language=GraphQL
export default gql`
  mutation createCaseDelayMutation(
    $caseId: ID!
    $status: CaseStatus
    $reasonType: DelayReasonType
    $customReason: String
  ) {
    createCaseDelay(
      input: {
        caseId: $caseId
        status: $status
        reasonType: $reasonType
        customReason: $customReason
      }
    ) {
      caseDelay {
        id
        startTime
        endTime
        status
        reasonType
        customReason
      }
    }
  }
`;

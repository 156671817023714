import { curry, get } from 'lodash';
import React from 'react';
import { Query } from '@apollo/client/react/components';

import query from './afterHoursContactsQuery';

const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const contacts = get(queryProps, 'data.afterHoursContacts.contacts', []);
    return <WrappedComponent {...componentProps} contacts={contacts} />;
  },
);

const withAfterHoursContacts = (WrappedComponent) => (componentProps) => {
  const { dealerId } = componentProps;
  return (
    <Query query={query} skip={!dealerId} variables={{ dealerId }}>
      {buildQueryChild(WrappedComponent, componentProps)}
    </Query>
  );
};

export default withAfterHoursContacts;

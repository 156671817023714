import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compact, isEmpty } from 'lodash';

import { Container, Row, Column } from 'styled-components-grid';
import { ButtonLink, Divider, H3, P } from 'base-components';

import TravelEstimate from 'compositions/TravelEstimate';
import { CallButton } from 'compositions/ClickToCall';

import { evaluateDealerType } from 'utils/dealerFilter';

import DealerMarker from '../DealerMarker';

export function DealerSummary({
  dealer,
  index,
  isCollapsible,
  isExpandable,
  onExpandedChange,
}) {
  if (isEmpty(dealer)) return null;

  const {
    displayAddress: { city, postalCode, province, streetAddress },
    name,
    phone,
    travelEstimate: { distance, time },
    billTo,
    shipTo,
  } = dealer;

  const dealerType = evaluateDealerType(dealer);

  return (
    <Container modifiers={['padScale_0']}>
      <Row modifiers={['padScale_0']}>
        <Column>
          <DealerMarker type={dealerType}>
            <DealerMarker.Text modifiers={['small']}>
              {`${index + 1}`}
            </DealerMarker.Text>
          </DealerMarker>
        </Column>
      </Row>
      <Row>
        <Column modifiers={['col']}>
          <H3 modifiers={['fontWeightRegular']}>{name}</H3>
        </Column>
        <Column>
          {phone && <CallButton dealer={dealer} phone={phone} name={name} />}
        </Column>
      </Row>
      <Row>
        <Column modifiers={['col']}>
          <P>{streetAddress}</P>
          <P>{`${city}, ${province} ${postalCode}`}</P>
          <P>
            <Trans>Bill to #</Trans>
            &nbsp;
            {billTo}
            &nbsp; &middot; &nbsp;
            <Trans>Ship to #</Trans>
            &nbsp;
            {shipTo}
          </P>
        </Column>
      </Row>
      <Row modifiers="middle">
        <Column modifiers={['col', 'padScaleY_3']}>
          <TravelEstimate distance={distance} duration={time} />
        </Column>
        <Column modifiers="padScaleY_3">
          <Row modifiers="end">
            <Column>
              <ButtonLink
                modifiers={compact(!isCollapsible && ['disabled'])}
                onClick={() => onExpandedChange(false)}
              >
                <Trans>Collapse All</Trans>
              </ButtonLink>
            </Column>
            <Column modifiers={['padScaleX_1', 'padScaleY_2']}>
              <Divider modifiers={['vertical']} />
            </Column>
            <Column>
              <ButtonLink
                modifiers={compact(!isExpandable && ['disabled'])}
                onClick={() => onExpandedChange(true)}
              >
                <Trans>Expand All</Trans>
              </ButtonLink>
            </Column>
          </Row>
        </Column>
      </Row>
    </Container>
  );
}

DealerSummary.propTypes = {
  dealer: PropTypes.shape({
    features: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
      }),
    ),
    displayAddress: PropTypes.shape({
      city: PropTypes.string,
      countryCode: PropTypes.string,
      postalCode: PropTypes.string,
      province: PropTypes.string,
      streetAddress: PropTypes.string,
    }),
    name: PropTypes.string,
    phone: PropTypes.string,
    travelEstimate: PropTypes.shape({
      distance: PropTypes.number.isRequired,
      time: PropTypes.number.isRequired,
    }),
    billTo: PropTypes.string,
    shipTo: PropTypes.string,
  }).isRequired,
  index: PropTypes.number,
  isCollapsible: PropTypes.bool.isRequired,
  isExpandable: PropTypes.bool.isRequired,
  onExpandedChange: PropTypes.func.isRequired,
};

DealerSummary.defaultProps = { index: undefined };

export default DealerSummary;

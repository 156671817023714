import gql from 'graphql-tag';

export default gql`
  mutation reopenCase($id: ID!) {
    reopenCase(input: { id: $id }) {
      case {
        id
        status
      }
    }
  }
`;

import { curry } from 'lodash';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';

import sendToFixpixMutation from './sendToFixpixMutation';

const buildUpdateMutationChild = curry(
  (WrappedComponent, componentProps, mutate, { loading }) => (
    <WrappedComponent
      {...componentProps}
      sendToFixpix={() => mutate({ variables: { id: componentProps.caseId } })}
      isSendingToFixpix={!!loading}
    />
  ),
);

const withSendToFixpix = (WrappedComponent) => (componentProps) => (
  <Mutation
    skip={!componentProps.caseId}
    mutation={sendToFixpixMutation}
    // It can fail for several reasons, but we don't care
    // about the errors. If it succeeds, the case cache will
    // be updated and the UI will reflect the correct state.
    errorPolicy="all"
  >
    {buildUpdateMutationChild(WrappedComponent, componentProps)}
  </Mutation>
);

export default withSendToFixpix;

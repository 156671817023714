import gql from 'graphql-tag';

export default gql`
  mutation updateCaseCustomCustomer(
    $caseId: ID!
    $fleetName: String!
    $zipCode: String!
  ) {
    setCaseOtherCustomer(
      input: { id: $caseId, patch: { name: $fleetName, zip: $zipCode } }
    ) {
      case {
        id
        fixpixPushResult
        customer(useCachedDataForCase: true) {
          ... on CustomCustomer {
            type
            name
            zip
          }
        }
        paymentMethod
      }
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';

import PopoverBlock from '../../blocks/Popover';

import { POPOVER_CONTEXT } from './constants';

function Target(props, context) {
  const { handleHover, handleLeave, handleToggle } = context[POPOVER_CONTEXT];

  return (
    <PopoverBlock.Target
      onClick={handleToggle}
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
      {...props}
    >
      {props.children}
    </PopoverBlock.Target>
  );
}

Target.contextTypes = {
  [POPOVER_CONTEXT]: PropTypes.shape({}).isRequired,
};

Target.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Target;

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import { Tooltip } from 'base-components';

import { DEALER_LOCATION_ICON_NAMES } from 'utils/dealerFilter';

import SquareIcon from './SquareIcon';

function ONCallSquareIcon({
  code,
  modifiers,
  tooltipPosition,
  tooltipContent,
}) {
  if (tooltipPosition === '') {
    return <SquareIcon code={code} modifiers={modifiers} />;
  }

  return (
    <Tooltip position={tooltipPosition}>
      <Tooltip.Target>
        <SquareIcon code={code} modifiers={modifiers} />
      </Tooltip.Target>
      <Tooltip.Content>
        <Trans id={tooltipContent || DEALER_LOCATION_ICON_NAMES[code]} />
      </Tooltip.Content>
    </Tooltip>
  );
}

ONCallSquareIcon.propTypes = {
  code: PropTypes.string.isRequired,
  modifiers: PropTypes.arrayOf(PropTypes.string),
  tooltipPosition: PropTypes.string,
  tooltipContent: PropTypes.string,
};

ONCallSquareIcon.defaultProps = {
  modifiers: [],
  tooltipPosition: '',
  tooltipContent: '',
};

export default ONCallSquareIcon;

import gql from 'graphql-tag';

export default gql`
  mutation updateCaseStatus(
    $id: ID!
    $status: CaseStatus!
    $timestamp: Datetime
    $ignoreFixpixFailure: Boolean
    $distanceUnit: DistanceUnit
  ) {
    updateCaseStatus(
      input: {
        id: $id
        ignoreFixpixFailure: $ignoreFixpixFailure
        patch: { status: $status, timestamp: $timestamp }
      }
    ) {
      case {
        id
        billable
        createdAt
        createdBy {
          name
          email
        }
        fixpixPushResult
        status
        statusHistory {
          id
          createdAt
          changedAt
          newStatus
          oldStatus
          user {
            email
            name
          }
        }
        dealerTravelEstimateAtDispatch {
          distance(unit: $distanceUnit)
          time
        }
      }
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { round } from 'lodash';

import { Text } from 'base-components';
import { Row, Column } from 'styled-components-grid';

import getTravelEstimate from 'utils/travelEstimate';
import { getUnits } from 'utils/unit';

import TravelEstimateBadge from './TravelEstimateBadge';

export default function TravelEstimate({ travelEstimate, unit }) {
  const { distanceUnitLabel } = getUnits(unit);

  const {
    assetLocationDriveDistance: distance,
    assetLocationDriveTime: time,
  } = getTravelEstimate(travelEstimate);

  return (
    <Row modifiers="middle">
      <Column>
        <TravelEstimateBadge />
      </Column>
      <Column modifiers="padScaleY_0">
        <Row>
          <Column modifiers="padScaleY_0">
            <Text modifiers={['small', 'textLight']}>
              <Trans>Drive Distance&nbsp;&middot;&nbsp;Time</Trans>
            </Text>
          </Column>
        </Row>
        <Row>
          <Column modifiers="padScaleY_0">
            <Text>
              {round(distance, 1)} {distanceUnitLabel}
              &nbsp;&middot;&nbsp;
              {time}
            </Text>
          </Column>
        </Row>
      </Column>
    </Row>
  );
}

TravelEstimate.propTypes = {
  unit: PropTypes.string.isRequired,
  travelEstimate: PropTypes.shape({
    distance: PropTypes.number,
    time: PropTypes.number,
  }).isRequired,
};

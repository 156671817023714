/**
 * Modified version of https://stackoverflow.com/questions/3518504/regular-expression-for-matching-latitude-longitude-coordinates#comment87491242_18690202
 * that also allows for spaces before the comma, as well as leading and trailling spaces.
 */

const latLngReg = /(^\s*[-+]?(?:[1-8]?\d(?:\.\d+)?|90(?:\.0+)?))\s*,\s*([-+]?(?:180(?:\.0+)?|(?:(?:1[0-7]\d)|(?:[1-9]?\d))(?:\.\d+)?))\s*$/;

export function parseLatLng(input) {
  const matches = latLngReg.exec(input);

  if (matches) {
    return {
      latitude: parseFloat(matches[1]),
      longitude: parseFloat(matches[2]),
    };
  }

  return null;
}

export default parseLatLng;

export function latLngToString(latLng) {
  return (
    [latLng?.latitude, latLng?.longitude].filter(Boolean).join(', ') || latLng
  );
}

import React from 'react';
import gql from 'graphql-tag';
import moment from 'moment-timezone';
import { compose } from 'recompose';
import { Mutation } from '@apollo/client/react/components';

import withCaseDealerResponseActions from '../withCaseDealerResponseActions';

const createMutationFor = (
  mutation,
  faacArgsMapper,
  mutationPropsMapper = () => ({}),
) => (Component) => (props) => (
  <Mutation mutation={mutation} {...mutationPropsMapper(props)}>
    {(mutate, mutationProps) => (
      <Component {...props} {...faacArgsMapper(mutate, mutationProps, props)} />
    )}
  </Mutation>
);

const mapFAACArgsToProps = (...options) => (mutate, { loading }) => {
  const [mutationPropName, loadingPropName, customMutationFn] = options;

  const mutationFn =
    typeof customMutationFn === 'function'
      ? (variables = {}) => customMutationFn(mutate, variables)
      : (variables = {}) => mutate({ variables });

  return { [mutationPropName]: mutationFn, [loadingPropName]: !!loading };
};

const createCustomDealerGQL = gql`
  mutation compositions_CaseDealerSelectionPanel_createCustomDealer(
    $caseId: ID!
  ) {
    createCustomDealer(input: { caseId: $caseId, customDealer: {} }) {
      customDealer {
        id
      }
    }
  }
`;

const updateCustomDealerGQL = gql`
  mutation compositions_CaseDealerSelectionPanel_updateCustomDealer(
    $id: ID!
    $patch: CustomDealerAttrs!
  ) {
    updateCustomDealer(input: { id: $id, patch: $patch }) {
      customDealer {
        id
        mileageRange {
          id
          max
          min
        }
        name
      }
    }
  }
`;

export default compose(
  withCaseDealerResponseActions,
  createMutationFor(
    createCustomDealerGQL,
    mapFAACArgsToProps(
      'createCustomDealer',
      'isCreatingCustomDealer',
      (mutate, variables) => {
        if (!variables.caseId) return Promise.resolve();

        return mutate({ variables });
      },
    ),
    ({ caseId, createDealerResponse }) => ({
      onCompleted: (data) => {
        const dealerId = data?.createCustomDealer?.customDealer?.id;

        if (!caseId || !dealerId) return;

        // This timeout prevents the createCustomDealer mutation status from
        // being stuck as "loading" forever. Not sure why it does that, though.
        setTimeout(() => {
          createDealerResponse({
            caseId,
            dealerId,
            dealerType: 'CUSTOM_DEALER',
            dealerResponseAttrs: { responseTime: moment.tz().format() },
          });
        });
      },
      skip: !caseId,
    }),
  ),
  createMutationFor(
    updateCustomDealerGQL,
    mapFAACArgsToProps('updateCustomDealer', 'isUpdatingCustomDealer'),
  ),
);

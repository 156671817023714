import React from 'react';
import PropTypes from 'prop-types';
import { Column, Container, Row } from 'styled-components-grid';
import { compose, setDisplayName } from 'recompose';

import withCaseNotes from './withCaseNotes';
import { CreateForm } from './Form';
import NotesPanel from './NotesPanel';

export function Notes({ caseId, caseNumber, notes }) {
  return (
    <Container>
      <Row modifiers={['padScaleY_2']}>
        <Column modifiers={['col']}>
          <Row>
            <Column modifiers={['col', 'padScale_0']}>
              <CreateForm caseId={caseId} caseNumber={caseNumber} />
            </Column>
          </Row>
        </Column>
      </Row>
      <Row modifiers={['padScaleY_0']}>
        <NotesPanel notes={notes} />
      </Row>
    </Container>
  );
}

Notes.propTypes = {
  caseId: PropTypes.string,
  caseNumber: PropTypes.string.isRequired,
  /* eslint-disable react/forbid-prop-types */
  notes: PropTypes.array.isRequired,
};

Notes.defaultProps = {
  caseId: '',
};

export default compose(setDisplayName('Notes'), withCaseNotes)(Notes);

import React from 'react';
import PropTypes from 'prop-types';
import { defineMessage, Trans } from '@lingui/macro';

import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';
import { H2, P, QuickActionButton, Text } from 'base-components';

import Modal from 'components/Modal';

import { AfterHoursContactPropType } from '../../propTypes';

const Emphasis = (
  <Text modifiers="fontWeightMedium" style={{ display: 'inline' }} />
);

const message = defineMessage({
  message:
    'The contact <0>{contactName}</0> is assigned to an After-Hours Rotation that is happening right now. You must first replace <1>{contactName}</1> with a different contact in that rotation before you can proceed.',
});

function CannotDeleteModal({ onClose, contact }) {
  return (
    <Modal onClose={onClose}>
      {() => (
        <Modal.Body>
          <Modal.Header modifiers="danger" style={{ padding: px2rem(20) }}>
            <Modal.HeaderIcon name="user-times" />
          </Modal.Header>
          <Modal.Content>
            <Row modifiers="center" style={{ marginBottom: px2rem(10) }}>
              <Column>
                <H2 modifiers="fontWeightRegular">
                  <Trans>Contact can't be deleted</Trans>
                </H2>
              </Column>
            </Row>
            <Row modifiers="center">
              <Column modifiers="col">
                <P>
                  <Trans
                    id={message}
                    values={{ contactName: contact.name }}
                    components={[Emphasis, Emphasis]}
                  />
                </P>
              </Column>
            </Row>
          </Modal.Content>
          <Modal.Footer>
            <Row modifiers="end">
              <Column modifiers="padScale_0">
                <QuickActionButton onClick={onClose}>
                  <QuickActionButton.Text>
                    <Trans>Close</Trans>
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
            </Row>
          </Modal.Footer>
        </Modal.Body>
      )}
    </Modal>
  );
}

CannotDeleteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  contact: AfterHoursContactPropType.isRequired,
};

export default CannotDeleteModal;

import React from 'react';
import PropTypes from 'prop-types';

import TechAdminDealerSearch from 'pages/TechAdmin/DealerSearch';
import TechAdminDealers from 'pages/TechAdmin/Dealers';

import { USER_ROLES, withUserRole } from 'features/rbac';

const dealerSearchRoles = [
  USER_ROLES.TECH_ADMIN_ADMIN,
  ...USER_ROLES.ONCALL_ADMIN_ONLY,
  ...USER_ROLES.CALL_CENTER_ONLY,
  USER_ROLES.MICHELIN_OPERATIONS,
];

/**
 * Route the "root" tech admin path, based on roles
 * * If the current user's role allows search for _any_ dealer, use `TechAdminDealerSearch`
 * * Otherwise, just show the dealers accessible by this user, via `TechAdminDealers`
 */
function TechAdminRouter({ role, ...rest }) {
  if (dealerSearchRoles.includes(USER_ROLES[role])) {
    return <TechAdminDealerSearch {...rest} />;
  }

  return <TechAdminDealers {...rest} />;
}

TechAdminRouter.propTypes = {
  role: PropTypes.string.isRequired,
};

export default withUserRole(TechAdminRouter);

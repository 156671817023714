import { Container } from 'styled-components-grid';
import { buildStyledComponent } from 'decisiv-ui-utils';

import Icon from './Icon';
import IconBox from './IconBox';
import Time from './Time';
import Temperature from './Temperature';

const Weather = buildStyledComponent('Weather', Container);

Weather.Time = Time;
Weather.Icon = Icon;
Weather.IconBox = IconBox;
Weather.Temperature = Temperature;

export default Weather;

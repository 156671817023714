import React from 'react';
import PropTypes from 'prop-types';

import { InputGroup } from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Column } from 'styled-components-grid';

import DealerHighwayInput from './DealerHighwayInput';
import DealerMileMarkerInput from './DealerMileMarkerInput';

export default function CaseDealerLocatorExtraInputs(props) {
  const { caseDetail, loading, readOnly } = props;
  const inputProps = { caseDetail, readOnly };

  return (
    <Column
      modifiers={['col_4', 'padScale_0']}
      style={{ paddingLeft: px2rem(8) }}
    >
      <InputGroup>
        <InputGroup.Row>
          <InputGroup.Column modifiers={['flex_1']}>
            {!loading && <DealerHighwayInput {...inputProps} />}
          </InputGroup.Column>
          <InputGroup.Column modifiers={['flex_1']}>
            {!loading && <DealerMileMarkerInput {...inputProps} />}
          </InputGroup.Column>
        </InputGroup.Row>
      </InputGroup>
    </Column>
  );
}

CaseDealerLocatorExtraInputs.propTypes = {
  caseDetail: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

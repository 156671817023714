import { find, map, sortBy } from 'lodash';
import moment from 'moment-timezone';

import OpenHoursEvent from './ScheduledEvent/OpenHoursEvent';
import AfterHoursEvent from './ScheduledEvent/AfterHoursEvent';
import RotationGapBlock from './ScheduleGrid/RotationGapBlock';

import {
  AFTER_HOURS_EVENT_TYPE,
  STORE_HOURS_EVENT_TYPE,
  UNALLOCATED_ROTATION_GAP,
} from './constants';

/**
 * Search through `allEvents` for any event that would overlap with the
 * given `start` and `end` times. Return true if any overlapping event
 * is found; false otherwise.
 */
function findOverlaps(start, end, index, allEvents) {
  return !!find(allEvents, (other, eventIndex) => {
    if (eventIndex === index) {
      return false;
    }

    return (
      moment(other.start).isBefore(end) && moment(other.end).isAfter(start)
    );
  });
}

/**
 * Map of event types to the React components that should be
 * used to render them.
 */
const eventComponentMap = {
  [AFTER_HOURS_EVENT_TYPE]: AfterHoursEvent,
  [STORE_HOURS_EVENT_TYPE]: OpenHoursEvent,
  [UNALLOCATED_ROTATION_GAP]: RotationGapBlock,
};

/**
 * Build a new event (in our preferred format for UI rendering) for rendering
 * on a particular day.
 *
 * @param {string} type - the type of event (OpenHours, etc)
 * @param {Moment} startTime - when the event starts (may be on previous day)
 * @param {Moment} endTime - when the event ends (may be on next day)
 * @param {Moment|null} day - the day we're concerned about
 * @return {{type: string, start: Moment, end: Moment, blockStart: Moment, blockEnd: Moment, component: React.Component, key: string}}
 */
function makeEvent(config) {
  const { type, startTime, endTime, day = moment(startTime), events } = config;
  const timezone = day.tz();

  // we'll be time-boxing each event to fit in the given day
  const startOfDay = moment(day).startOf('day');
  const endOfDay = moment(day).endOf('day');
  const blockStart = moment.max(startOfDay, startTime);

  let dayOffsetInSeconds = 0;

  // If the timezone offset changes between two days visible in the calendar,
  // and an event spans those two days, we store the difference of the
  // offsets for the day that includes the event that started in the day
  // before. The offset difference is then used to "slide" the events up
  // or down in the day, so that they are all properly aligned.
  if (events.length) {
    const [firstEvent] = events;
    const startDate = moment(firstEvent.start);

    if (!day.isSame(startDate, 'day')) {
      const firstEventEnd = moment(firstEvent.end).tz(timezone);

      dayOffsetInSeconds =
        (firstEventEnd.utcOffset() - startDate.tz(timezone).utcOffset()) * 60;
    }
  }

  return {
    type,
    start: startTime,
    end: endTime,
    blockStart,
    blockEnd: moment.min(endOfDay, endTime),
    component: eventComponentMap[type],
    key: `${type}-${startTime.format('x')}`,
    dayOffsetInSeconds,
    isOvernight: !moment(startTime).isSame(blockStart, 'day'),
  };
}

/**
 * Process the event data for a particular day into a format easier to render.
 * * sorts the events in chronological order
 * * identifies overlapping events
 * * adds blockStart and blockEnd to indicate the render top/bottom times
 * * determines the component to use for rendering this block
 * All times are converted to the given time-zone (presumably the time zone
 * of the dealer being displayed).
 *
 * @param {Moment} day - The day this event is being rendered into
 * @param {Array} events - An array of events
 * @return {Array}
 */
function transformEvents(day, events) {
  // incoming start/end times will be converted to time zone of the given day
  const timezone = day.tz();

  // compare the start times of all events to sort them by start time
  // note: the format('x') returns a numeric epoch timestamp
  const sortedEvents = sortBy(events, (event) =>
    moment(event.start).format('x'),
  );

  // map the events to our preferred structure and indicate which ones
  // overlap with some other event
  return map(sortedEvents, ({ start, end, __typename, ...rest }, index) => {
    const startTime = moment(start).tz(timezone);
    const endTime = moment(end).tz(timezone);

    const type = __typename;
    const component =
      type === STORE_HOURS_EVENT_TYPE ? OpenHoursEvent : AfterHoursEvent;

    const event = makeEvent({
      type,
      startTime,
      endTime,
      day,
      component,
      events: sortedEvents,
    });

    return {
      ...event,
      ...rest,
      overlap: findOverlaps(startTime, endTime, index, sortedEvents),
    };
  });
}

/**
 * Build a reducer callback function for a given day.
 * @param {Moment} day
 * @return {Function} to be used while reducing over events to identify after-hours rotation gaps
 */
const gapProcessor = (day, events) => (
  { lastAfterHoursEvent, eventsWithGaps },
  event,
) => {
  // if this is a store-hours event, skip it
  if (event.type === STORE_HOURS_EVENT_TYPE) {
    return {
      lastAfterHoursEvent,
      eventsWithGaps: [...eventsWithGaps, event],
    };
  }

  // get the end of the previous after-hours event, or the start of the
  // day if we're looking at the first after-hours event on this day
  const endOfPreviousEvent = lastAfterHoursEvent
    ? lastAfterHoursEvent.blockEnd
    : moment(day).startOf('day');

  if (endOfPreviousEvent < event.blockStart) {
    // add a placeholder indicating a gap in the after-hours rotation:
    const newGap = makeEvent({
      type: UNALLOCATED_ROTATION_GAP,
      startTime: moment(endOfPreviousEvent),
      endTime: moment(event.blockStart),
      events,
    });

    return {
      lastAfterHoursEvent: event,
      eventsWithGaps: [...eventsWithGaps, newGap, event],
    };
  }

  // no gap needed...
  return {
    lastAfterHoursEvent: event,
    eventsWithGaps: [...eventsWithGaps, event],
  };
};

/**
 * Given a list of all the (already-transformed) events, add additional
 * placeholder events indicating time-slots not yet allocated for
 * after-hours rotations. These will be used to determine where to
 * add hover-buttons for adding new rotation events.
 *
 * @param {Array} events - a list of transformed events
 * @param {Moment} day - the day we're rendering
 * @return {Array} the original `events` with additional gap events inserted
 */
function addRotationGapEvents(day, events) {
  const { lastAfterHoursEvent, eventsWithGaps } = events.reduce(
    gapProcessor(day, events),
    // starting values for the reduce:
    {
      lastAfterHoursEvent: null,
      eventsWithGaps: [],
    },
  );

  // if the last event doesn't run all the way to midnight on this day,
  // add one more gap to cover the final time-block...
  const endOfLastRotation = lastAfterHoursEvent
    ? lastAfterHoursEvent.blockEnd
    : moment(day).startOf('day');

  const endOfDay = moment(day).endOf('day');

  if (endOfLastRotation.isBefore(endOfDay)) {
    return [
      ...eventsWithGaps,
      makeEvent({
        type: UNALLOCATED_ROTATION_GAP,
        startTime: moment(endOfLastRotation),
        endTime: moment(endOfDay),
        events,
      }),
    ];
  }

  return eventsWithGaps;
}

/**
 * Transform all the events for a given day into a preferred format, then
 * insert placeholder "gap" events indicating portions of the day that are
 * not yet associated with after-hours rotations.
 * @param {Moment} day
 * @param {Array} events
 * @return {Array}
 */
export default function processEvents(day, events) {
  return addRotationGapEvents(day, transformEvents(day, events));
}

import React, { Component, createContext } from 'react';
import produce from 'immer';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { get, set } from 'lodash';
import { compose, setDisplayName } from 'recompose';

import { isPhoneNumber } from 'utils/format';
import { invalidCharsForExporting } from 'constants/index';

import {
  withCasePanelStatusActions,
  CASE_PANELS,
  CASE_STATUS,
  PANEL_STATUSES,
} from 'compositions/CaseStatus';
import withCaseDelays from 'compositions/CaseDetailOverviewPanel/withCaseDelays';

import withCaseStatus from '../withCaseStatus';

import {
  etaRequiringReasons,
  requiredDealerPaths,
  requiredResponsePaths as requiredPaths,
} from '../constants';

const { Provider, Consumer } = createContext();
const { incomplete, partial, complete, invalid } = PANEL_STATUSES;

const ignoredStatuses = [CASE_STATUS.new];

const isCustomDealer = (dealer) => get(dealer, '__typename') === 'CustomDealer';

const hasInvalidContact = (response, errors) => {
  const { id, contactPerson: name } = response;

  return (
    (name && invalidCharsForExporting.test(name)) ||
    get(errors, [id, 'contactPerson'])
  );
};

const hasInvalidPhone = (response, errors) => {
  const { id, phoneNumber: number } = response;

  return (number && !isPhoneNumber(number)) || get(errors, [id, 'phoneNumber']);
};

export const hasInvalidContactPersons = (responses, errors) =>
  responses.some((r) => hasInvalidContact(r, errors));

export const hasInvalidPhoneNumbers = (responses, errors) =>
  responses.some((r) => hasInvalidPhone(r, errors));

export const hasMissingETAs = (responses, errors) =>
  responses.some(
    ({ id, reason, eta }) =>
      (etaRequiringReasons.includes(reason) && !eta) ||
      get(errors, [id, 'eta']),
  );

const getCaseDelaysForStatus = (props) =>
  props.caseDelays.filter((delay) => delay.status === props.caseStatus);

const buildFieldPrefix = (dealerIdx, responseIdx) => {
  const id =
    responseIdx === undefined
      ? t`Service Provider ${dealerIdx + 1}:`
      : t`Service Provider ${dealerIdx + 1} - Call Record ${responseIdx + 1}:`;

  return (
    <span>
      {i18n._(id)}
      &nbsp;
    </span>
  );
};
const buildStatus = ({ key, severity, dealerIndex, responseIndex }) => {
  if (!severity) return {};

  const prefix = buildFieldPrefix(dealerIndex, responseIndex);
  // eslint-disable-next-line no-restricted-globals
  const isDealerKey = isNaN(responseIndex);

  const id = isDealerKey
    ? `dealerSelection.${dealerIndex}.${key}`
    : `dealerSelection.${dealerIndex}.responses.${responseIndex}.${key}`;

  const translationKey = isDealerKey
    ? `dealerSelection.${key}`
    : `dealerSelection.responses.${key}`;

  return { [id]: { prefix, severity, translationKey } };
};

const getEntriesFieldsStatus = (entries = [], errors = {}) => {
  let fields = {};

  entries.forEach(({ dealer, responses = [] }, dealerIndex) => {
    const dealerKeys = isCustomDealer(dealer) ? requiredDealerPaths : [];

    dealerKeys.forEach((key) => {
      if (!get(dealer, key))
        fields = {
          ...fields,
          ...buildStatus({ key, severity: 'missing', dealerIndex }),
        };
    });

    responses.forEach((r, responseIndex) => {
      const keys = etaRequiringReasons.includes(r.reason)
        ? [...requiredPaths, 'eta']
        : requiredPaths;

      keys.forEach((key) => {
        let severity = '';
        const value = get(r, key);

        if (!value) severity = 'missing';
        if (
          (key === 'phoneNumber' && hasInvalidPhone(r, errors)) ||
          (key === 'contactPerson' && hasInvalidContact(r, errors))
        ) {
          severity = 'invalid';
        }

        fields = {
          ...fields,
          ...buildStatus({ key, severity, dealerIndex, responseIndex }),
        };
      });
    });
  });

  return fields;
};

const isDelayActive = (delay) =>
  get(delay, 'dealerResponse.dealer') && !get(delay, 'endTime');

/*
 * Returns an object of the following structure:
 * {
 *    status: PANEL_STATUS,
 *    hasMissingETAs: boolean
 *    hasInvalidPhoneNumbers: boolean,
 *    hasInvalidContactPersons: boolean,
 * }
 *
 * Exported for testing purposes.
 */
export const getEntriesValidationStatus = (props, errors = {}, delays = []) => {
  const { caseDealerResponses = [], servicingDealer } = props;

  // If we do not have any response, the panel is "untouched" (incomplete)
  if (!caseDealerResponses.length) {
    return {
      fields: {},
      status: incomplete,
      hasMissingETAs: false,
      hasInvalidPhoneNumbers: false,
      hasInvalidContactPersons: false,
    };
  }

  const statuses = caseDealerResponses.map(({ dealer, responses = [] }) => {
    const paths = isCustomDealer(dealer) ? requiredDealerPaths : [];
    const values = [...paths.map((path) => !!get(dealer, path))];

    responses.forEach((r) =>
      values.push(...requiredPaths.map((p) => !!get(r, p))),
    );

    return {
      allFilled: !values.includes(false),
      hasMissingETAs: hasMissingETAs(responses, errors),
      hasInvalidPhoneNumbers: hasInvalidPhoneNumbers(responses, errors),
      hasInvalidContactPersons: hasInvalidContactPersons(responses, errors),
    };
  });

  const fields = getEntriesFieldsStatus(caseDealerResponses, errors);
  const isComplete = statuses.every((s) => s.allFilled);
  const missingETAs = statuses.some((s) => s.hasMissingETAs);
  const hasActiveDelays = delays.some(isDelayActive);
  const invalidPhoneNumbers = statuses.some((s) => s.hasInvalidPhoneNumbers);
  const invalidContactPersons = statuses.some(
    (s) => s.hasInvalidContactPersons,
  );

  const isInvalid = missingETAs || invalidPhoneNumbers || invalidContactPersons;

  // If we have any response, we start as partial
  let status = partial;

  if (isComplete && !!servicingDealer && !hasActiveDelays) status = complete;
  if (isInvalid) status = invalid;

  if (status === partial && !servicingDealer && !Object.keys(fields).length) {
    fields['dealerSelection.noServicingDealer'] = {
      severity: 'missing',
      requestFocus: false,
    };
  }

  return {
    status,
    fields,
    hasMissingETAs: missingETAs,
    hasInvalidPhoneNumbers: invalidPhoneNumbers,
    hasInvalidContactPersons: invalidContactPersons,
  };
};

/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unused-state */
class DealerResponsesValidationContextProvider extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    caseStatus: PropTypes.string.isRequired,
    setCasePanelStatus: PropTypes.func.isRequired,
    caseDealerResponses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    servicingDealer: PropTypes.shape({ id: PropTypes.string }),
    caseDelays: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string,
        endTime: PropTypes.string,
        dealerResponse: PropTypes.shape({
          dealer: PropTypes.shape({
            id: PropTypes.string,
          }),
        }),
      }),
    ).isRequired,
  };

  static defaultProps = {
    servicingDealer: null,
  };

  constructor(props) {
    super(props);

    const delays = getCaseDelaysForStatus(props);

    this.state = {
      validationStatus: getEntriesValidationStatus(props, {}, delays),
      onValidateResponseColumn: this.onValidateResponseColumn,
      // { [responseId]: { contactPerson: Bool, phoneNumber: Bool, eta: Bool } }
      validationErrors: {},
    };
  }

  componentDidMount() {
    this.setCasePanelStatus();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { servicingDealer, caseDealerResponses } = this.props;

    if (
      servicingDealer !== nextProps.servicingDealer ||
      caseDealerResponses !== nextProps.caseDealerResponses
    ) {
      const delays = getCaseDelaysForStatus(nextProps);
      const { validationErrors: errors } = this.state;

      this.setState({
        validationStatus: getEntriesValidationStatus(nextProps, errors, delays),
      });
    }
  }

  componentDidUpdate() {
    this.setCasePanelStatus();
  }

  onValidateResponseColumn = (responseId, column, isValid) => {
    const delays = getCaseDelaysForStatus(this.props);

    const errors = produce(this.state.validationErrors, (draft) => {
      set(draft, [responseId, column], !isValid);
    });

    this.setState({
      validationErrors: errors,
      validationStatus: getEntriesValidationStatus(this.props, errors, delays),
    });
  };

  setCasePanelStatus = () => {
    const { validationStatus } = this.state;
    const { caseStatus, setCasePanelStatus } = this.props;

    if (!ignoredStatuses.includes(caseStatus)) {
      setCasePanelStatus(validationStatus.status, validationStatus.fields);
    }
  };

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

const DealerResponsesValidationContextWithData = compose(
  setDisplayName('DealerResponsesValidationContext'),
  withCasePanelStatusActions(CASE_PANELS.dealerSelection),
  withCaseStatus,
  withCaseDelays,
)(DealerResponsesValidationContextProvider);

DealerResponsesValidationContextWithData.Consumer = Consumer;

export const DealerResponsesValidationContext = DealerResponsesValidationContextWithData;

export default DealerResponsesValidationContextWithData;

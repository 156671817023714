/* eslint-disable import/prefer-default-export */

import buildEnum from 'utils/buildEnum';

export const COLUMN_NAMES = buildEnum([
  'caseNumber',
  'agent',
  'status',
  'inboundProgram',
  'timeSummaries',
  'customer',
  'unitNo',
  'dealer',
  'eta',
]);

// The sum should be 100%
export const COLUMN_WIDTHS = {
  [COLUMN_NAMES.caseNumber]: '5.3%',
  [COLUMN_NAMES.agent]: '5.3%',
  [COLUMN_NAMES.status]: '19.7%',
  [COLUMN_NAMES.inboundProgram]: '11.36%',
  [COLUMN_NAMES.timeSummaries]: '11.36%',
  [COLUMN_NAMES.customer]: '15.15%',
  [COLUMN_NAMES.unitNo]: '5.3%',
  [COLUMN_NAMES.dealer]: '15.15%',
  [COLUMN_NAMES.eta]: '11.36%',
};

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import { Text } from 'base-components';
import { Column } from 'styled-components-grid';

import Icon from './Icon';

function Item({ icon, label }) {
  return (
    <Column modifiers={['display_flex', 'padScaleX_2']}>
      <Icon src={icon} />
      <Text modifiers={['small']}>
        <Trans id={label} />
      </Text>
    </Column>
  );
}

Item.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  label: PropTypes.string.isRequired,
};

export default Item;

import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { delay, filter, findKey, get, isEmpty, mapValues } from 'lodash';

import { Accordion } from 'base-components';
import { Column, Container, Row } from 'styled-components-grid';

import ScrollableContainer from 'blocks/ScrollableContainer';
import ButtonLinkWithIcon from 'elements/ButtonLinkWithIcon';

import { userCanCreateNewCase } from 'features/rbac/utils';

import Cases from './Cases';
import Contacts from './Contacts';
import Hours from './Hours';
import Services from './Services';
import Summary from './Summary';
import Notes from './Notes';
import NewCaseFromLocation from './NewCaseFromLocation';

export class DealerDetailsComponent extends Component {
  static propTypes = {
    accordionsExpanded: PropTypes.shape(),
    clearSelectedDealer: PropTypes.func.isRequired,
    dealerDetailsLoading: PropTypes.bool,
    dealerInfo: PropTypes.shape({
      id: PropTypes.string,
      ers247: PropTypes.bool,
      ersHours: PropTypes.arrayOf(
        PropTypes.shape({
          end: PropTypes.string,
          start: PropTypes.string,
          weekDay: PropTypes.string,
        }),
      ),
      billTo: PropTypes.string,
      features: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          code: PropTypes.string.isRequired,
        }),
      ),
      name: PropTypes.string,
      shipTo: PropTypes.string,
      timezone: PropTypes.string,
      displayAddress: PropTypes.shape({
        countryCode: PropTypes.string,
        city: PropTypes.string,
        postalCode: PropTypes.string,
        province: PropTypes.string,
        streetAddress: PropTypes.string,
      }),
      open247: PropTypes.bool,
      operatingStatus: PropTypes.shape({
        changesAt: PropTypes.string,
        ersOpen: PropTypes.string.isRequired,
        open: PropTypes.string.isRequired,
      }),
      workingHours: PropTypes.arrayOf(
        PropTypes.shape({
          end: PropTypes.string,
          start: PropTypes.string,
          weekDay: PropTypes.string,
        }),
      ),
      activeCases: PropTypes.number,
    }),
    refetchDealerDetails: PropTypes.func.isRequired,
    selectedDealer: PropTypes.shape({
      index: PropTypes.number.isRequired,
    }),
    setAccordionsExpanded: PropTypes.func.isRequired,
    /* eslint-disable react/no-unused-prop-types */
    role: PropTypes.string,
    loadingRole: PropTypes.bool.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string),
    /* eslint-enable react/no-unused-prop-types */
  };

  static defaultProps = {
    accordionsExpanded: {},
    dealerInfo: {},
    dealerDetailsLoading: false,
    selectedDealer: undefined,
    role: undefined,
    permissions: [],
  };

  state = {
    accordionsExpanded: {
      cases: false,
      contacts: false,
      hours: false,
      services: false,
      notes: false,
    },
  };

  UNSAFE_componentWillMount() {
    const { accordionsExpanded } = this.props;

    this.updateAccordionsExpanded(accordionsExpanded);

    const operatingStatus = get(this.props, ['dealerInfo', 'operatingStatus']);
    this.setRefetchTimer(operatingStatus);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const oldOperatingStatus = get(this.props, [
      'dealerInfo',
      'operatingStatus',
    ]);
    const newOperatingStatus = get(nextProps, [
      'dealerInfo',
      'operatingStatus',
    ]);
    if (newOperatingStatus !== oldOperatingStatus) {
      this.setRefetchTimer(newOperatingStatus);
    }
  }

  componentWillUnmount() {
    this.cancelRefetch();
  }

  /**
   * Creates a delayed function that updates the dealer details when the
   * operating status is set to change. Saves the timeout's id to the
   * class instance. Cancels any previous delayed refetch function.
   *
   * @param {object} operatingStatus the dealer's operating status info
   * @memberof DealerDetail
   */
  setRefetchTimer = (operatingStatus) => {
    if (!operatingStatus) return;

    this.cancelRefetch();
    const delayTime = this.refetchDealerDetailsDelayTime(operatingStatus);
    // if delayTime exists and is not negative, create the delayed function call.
    this.refetchDealerDetailsFnId =
      delayTime && delayTime >= 0
        ? delay(this.updateDealerDetails, delayTime)
        : undefined;
  };

  /**
   * Updates dealer details by calling query refetch.
   *
   * @memberof DealerDetail
   */
  updateDealerDetails = () => {
    if (!this.props.dealerDetailsLoading) {
      this.props.refetchDealerDetails();
    }
    this.refetchDealerDetailsFnId = undefined;
  };

  /**
   * Evaluates the open state change hints for the best delay time
   *
   * @returns {number} the number of milliseconds until a dealer's
   *  operating status changes
   * @memberof DealerDetail
   */
  refetchDealerDetailsDelayTime = ({ changesAt }) =>
    changesAt && moment(changesAt).diff(moment());

  /**
   * Cancels the delayed refetch function by clearing the timeout and
   * the timeout id.
   *
   * @memberof DealerDetail
   */
  cancelRefetch = () => {
    clearTimeout(this.refetchDealerDetailsFnId);
    this.refetchDealerDetailsFnId = undefined;
  };

  handleAccordionExpandedChange = (value) => {
    const accordionsExpanded = {
      ...this.state.accordionsExpanded,
      [value.name]: value.expanded,
    };

    this.setState(
      {
        accordionsExpanded,
      },
      () => this.props.setAccordionsExpanded({ accordionsExpanded }),
    );
  };

  handleAllAccordionExpandedChange = (expanded) => {
    const accordionsExpanded = {
      ...mapValues(this.state.accordionsExpanded, () => expanded),
    };

    this.props.setAccordionsExpanded({ accordionsExpanded });
    this.updateAccordionsExpanded(accordionsExpanded);
  };

  updateAccordionsExpanded = (accordionsExpanded) => {
    if (!isEmpty(accordionsExpanded)) {
      this.setState({
        accordionsExpanded: { ...accordionsExpanded },
      });
    }
  };

  filterAccordionToggles = () =>
    filter(this.state.accordionsExpanded, (value, key) => {
      if (key === 'notes') return !!get(this.props, 'dealerInfo.notes');

      if (key === 'cases')
        return !!get(this.props, 'dealerInfo.activeCases', 0) > 0;

      return true;
    });

  render() {
    const { accordionsExpanded } = this.state;
    const { selectedDealer: { index } = {} } = this.props;
    const { dealerInfo, clearSelectedDealer } = this.props;

    const accordionToggles = this.filterAccordionToggles();
    const isExpandable = !!findKey(accordionToggles, (item) => item === false);
    const isCollapsible = !!findKey(accordionToggles, (item) => item === true);
    const canCreateNewCase = userCanCreateNewCase(this.props);

    // FIXME: clean up this component stacking for grid stuff
    return (
      <ScrollableContainer modifiers={['fluid', 'padScale_5']}>
        <ScrollableContainer.Row>
          <Column modifiers={['col']}>
            <Container modifiers={['padScale_5']}>
              <Row modifiers={['padScaleX_3']}>
                <Column modifiers={['col', 'padScaleY_2']}>
                  <ButtonLinkWithIcon
                    onClick={clearSelectedDealer}
                    icon="arrow-left"
                  >
                    <Trans>Back to Results</Trans>
                  </ButtonLinkWithIcon>
                </Column>
                {canCreateNewCase && (
                  <Column modifiers={['padScaleY_2']}>
                    <NewCaseFromLocation />
                  </Column>
                )}
              </Row>
              <Row>
                <Column modifiers={['col', 'padScaleX_3', 'padScaleY_0']}>
                  <Summary
                    dealer={dealerInfo}
                    index={index}
                    isCollapsible={isCollapsible}
                    isExpandable={isExpandable}
                    onExpandedChange={this.handleAllAccordionExpandedChange}
                  />
                </Column>
              </Row>
              <Row>
                <Column modifiers={['col', 'padScaleX_3', 'padScaleY_0']}>
                  <Hours
                    dealer={dealerInfo}
                    expanded={accordionsExpanded.hours}
                    onExpandedChange={this.handleAccordionExpandedChange}
                  />
                </Column>
              </Row>
              <Row>
                <Column modifiers={['col', 'padScaleX_3', 'padScaleY_0']}>
                  <Services
                    dealer={dealerInfo}
                    expanded={accordionsExpanded.services}
                    onExpandedChange={this.handleAccordionExpandedChange}
                  />
                </Column>
              </Row>
              <Row>
                <Column modifiers={['col', 'padScaleX_3', 'padScaleY_0']}>
                  <Contacts
                    dealer={dealerInfo}
                    expanded={accordionsExpanded.contacts}
                    onExpandedChange={this.handleAccordionExpandedChange}
                  />
                </Column>
              </Row>
              <Row>
                <Column modifiers={['col', 'padScaleX_3', 'padScaleY_0']}>
                  <Notes
                    dealer={dealerInfo}
                    expanded={accordionsExpanded.notes}
                    onExpandedChange={this.handleAccordionExpandedChange}
                  />
                </Column>
              </Row>
              {get(dealerInfo, 'activeCases', 0) > 0 && (
                <Row>
                  <Column modifiers={['col', 'padScaleX_3', 'padScaleY_0']}>
                    <Cases
                      dealerId={dealerInfo.id}
                      totalActiveCases={get(dealerInfo, 'activeCases')}
                      expanded={accordionsExpanded.cases}
                      onExpandedChange={this.handleAccordionExpandedChange}
                    />
                  </Column>
                </Row>
              )}
              <Row>
                <Column modifiers={['col', 'padScaleX_3', 'padScaleY_0']}>
                  <Accordion.Divider />
                </Column>
              </Row>
            </Container>
          </Column>
        </ScrollableContainer.Row>
      </ScrollableContainer>
    );
  }
}

export default DealerDetailsComponent;

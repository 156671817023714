import gql from 'graphql-tag';

const updateAssetLocationSearchMutation = gql`
  mutation updateAssetLocationSearchMutation(
    $id: ID!
    $countryCode: String!
    $location: Position
    $searchValue: String!
    $address: AssetAddressInput
    $useCoordinates: Boolean
  ) {
    updateCase(
      input: {
        id: $id
        patch: {
          assetLocation: {
            countryCode: $countryCode
            coordinates: $location
            searchValue: $searchValue
            address: $address
            useAssetCoordinatesForTravelEstimation: $useCoordinates
          }
        }
      }
    ) {
      case {
        id
        assetLocation {
          countryCode
          coordinates {
            latitude
            longitude
          }
          searchValue
          address {
            city
            country
            postalCode
            province
            streetAddress
          }
          useAssetCoordinatesForTravelEstimation
        }
      }
    }
  }
`;

export default updateAssetLocationSearchMutation;

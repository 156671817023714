/**
 * Component: ButtonLink
 *
 */

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const modifierConfig = {
  active: ({ theme }) => ({
    styles: `
      color: ${theme.colors.base.linkHover};
      text-decoration: underline;
    `,
  }),

  disabled: () => ({
    styles: `
      opacity: 0.5;
      pointer-events: none;
    `,
  }),

  small: () => `
    font-size: ${px2rem(12)};
  `,
};

const styles = ({ theme }) => `
  background: transparent;
  border: none;
  color: ${theme.colors.base.link};
  cursor: pointer;
  font-size: ${px2rem(10)};
  line-height: ${px2rem(16)};
  outline: none;
  padding: 0;
  text-decoration: none;
  text-transform: capitalize;
  white-space: nowrap;
  &:hover {
    color: ${theme.colors.base.linkHover};
    text-decoration: underline;
  }
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      link: PropTypes.string.isRequired,
      linkHover: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default buildStyledComponent('ButtonLink', styled.button, styles, {
  modifierConfig,
  themePropTypes,
});

import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Dropdown, InputField, Text } from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';

const hourOptions = [
  { label: '12', value: '12' },
  ...Array.from({ length: 11 }).map((_, index) => ({
    label: `${index + 1}`,
    value: `${index + 1}`,
  })),
];

const minuteOptions = Array.from({ length: 60 }).map((_, index) => ({
  label: `${index}`.padStart(2, '0'),
  value: `${index}`,
}));

function TimeDropdown(props) {
  const { isValid, label, name, onChange } = props;
  const { value = '', options, placeholder, readOnly } = props;

  return (
    <Dropdown
      onChange={(e, newValue) => onChange(name, newValue)}
      readOnly={readOnly}
      fullWidth
      hideOnChange
    >
      {({ isVisible }) => (
        <>
          <Dropdown.Target>
            <InputField
              name={name}
              value={value}
              isValid={isValid}
              onChange={noop}
              readOnly={readOnly}
              placeholder={placeholder}
            >
              <Column modifiers="col">
                <Row>
                  <InputField.Label>{label}</InputField.Label>
                </Row>
                <Row>
                  <InputField.TextField
                    readOnly
                    autoComplete="off"
                    style={{
                      cursor: 'pointer',
                      caretColor: 'transparent',
                    }}
                  />
                  <InputField.ActionButton
                    icon={isVisible ? 'chevron-up' : 'chevron-down'}
                    modifiers={['hoverInfo']}
                    onClick={noop}
                    type="button"
                  />
                </Row>
              </Column>
            </InputField>
          </Dropdown.Target>
          <Dropdown.Content
            style={{ maxHeight: px2rem(360), overflowY: 'auto', zIndex: 2 }}
          >
            <Dropdown.List>
              {options.map((option) => (
                <Dropdown.ListItem
                  id={option.value}
                  key={option.value}
                  modifiers={[value === option.value ? 'selected' : undefined]}
                >
                  <Row>
                    <Column modifiers="padScale_0">
                      <Text>{option.label}</Text>
                    </Column>
                  </Row>
                </Dropdown.ListItem>
              ))}
            </Dropdown.List>
          </Dropdown.Content>
        </>
      )}
    </Dropdown>
  );
}

TimeDropdown.propTypes = {
  isValid: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
};

TimeDropdown.defaultProps = {
  value: undefined,
  isValid: true,
  readOnly: false,
  placeholder: undefined,
};

TimeDropdown.hourOptions = hourOptions;
TimeDropdown.minuteOptions = minuteOptions;

export default TimeDropdown;

import React from 'react';
import PropTypes from 'prop-types';

import ButtonMicro from '../../blocks/ButtonMicro';

import { modifiersForComponent } from '../../utils/modifiers';

function Icon({ modifiers, ...rest }) {
  const iconModifiers = [
    'mini',
    ...modifiersForComponent(modifiers, ButtonMicro.Icon),
  ];
  return <ButtonMicro.Icon modifiers={iconModifiers} {...rest} />;
}

Icon.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

Icon.defaultProps = {
  modifiers: [],
};

export default Icon;

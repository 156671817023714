import gql from 'graphql-tag';

export const NAME = 'requestsPanel_caseStatusQuery';

const query = gql`
  query requestsPanel_caseStatusQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      createdAt
      id
      status
      statusHistory {
        id
        changedAt
        createdAt
        newStatus
        oldStatus
      }
    }
  }
`;

export default query;

import PropTypes from 'prop-types';
import React from 'react';

import { buildStyledComponent } from 'decisiv-ui-utils';
import { Container } from 'styled-components-grid';

import { modifiersForComponent } from '../../utils/modifiers';

import Row from './Row';
import Column from './Column';

const StyledInputGroup = buildStyledComponent('InputGroup', Container);

function InputGroup({ modifiers, ...rest }) {
  const inputGroupModifiers = [
    'padScale_0',
    ...modifiersForComponent(modifiers, StyledInputGroup),
  ];
  return <StyledInputGroup modifiers={inputGroupModifiers} {...rest} />;
}

InputGroup.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

InputGroup.defaultProps = {
  modifiers: [],
};

InputGroup.Row = Row;
InputGroup.Column = Column;

export default InputGroup;

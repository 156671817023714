import PropTypes from 'prop-types';
import styled from 'styled-components';
import { get } from 'lodash';
import { px2rem, buildStyledComponent } from 'decisiv-ui-utils';

const styles = ({ theme, colorKey }) => `
  box-sizing: border-box;
  height: 100%;
  padding: calc(4 * ${theme.dimensions.gridPad});
  width: ${px2rem(140)};
  background-color: ${get(theme.colors, colorKey, 'transparent')};
  color: ${theme.colors.base.chrome000};

  p, span, h1, h2, h3 {
    color: ${theme.colors.base.chrome000};
  }
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome000: PropTypes.string.isRequired,
    }),
    status: PropTypes.shape({
      danger: PropTypes.string.isRequired,
      warning: PropTypes.string.isRequired,
    }).isRequired,
  }),
  dimensions: PropTypes.shape({
    gridPad: PropTypes.string.isRequired,
  }).isRequired,
};

export default buildStyledComponent('LeftPanel', styled.div, styles, {
  themePropTypes,
});

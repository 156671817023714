import { compose, setDisplayName } from 'recompose';

import withFeatureFilters from '../../withFeatureFilters';

import FeaturesFilterSelectorComponent from './FeaturesFilterSelectorComponent';

export default compose(
  setDisplayName('FeaturesFilterSelector'),
  withFeatureFilters,
)(FeaturesFilterSelectorComponent);

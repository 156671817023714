import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';

import { buildStyledComponent } from 'decisiv-ui-utils';

const StyledHighlighter = buildStyledComponent(
  'HighlightText__Container',
  styled(Highlighter),
  ({ theme }) => `
    mark {
      color: inherit;
      font-weight: ${theme.fontWeights.bold};
      background-color: transparent;
    }
  `,
  {
    themePropTypes: PropTypes.shape({
      fontWeights: PropTypes.shape({
        bold: PropTypes.number.isRequired,
      }),
    }).isRequired,
  },
);

const HighlightText = ({ children, text }) => {
  if (!text || !text.trim()) return children || null;

  return (
    <StyledHighlighter
      autoEscape
      searchWords={text.split(/\s+/)}
      textToHighlight={children || ''}
    />
  );
};

HighlightText.propTypes = {
  text: PropTypes.string,
  children: PropTypes.string,
};

HighlightText.defaultProps = {
  text: undefined,
  children: undefined,
};

export default HighlightText;

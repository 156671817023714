/* eslint-disable react/no-unused-state */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isPlainObject } from 'lodash';
import { compose, setDisplayName } from 'recompose';

import { px2rem } from 'decisiv-ui-utils';

import Panel from 'blocks/Panel';
import { reportTypes } from 'pages/Reports/constants';

import patchFromChangedProps from 'utils/patchFromChangedProps';

import Header from './Header';
import Footer from './Footer';
import { Provider } from './Context';

import ReportStatusModal from './ReportStatusModal';
import withReportActions from './withReportActions';
import { dateRangeTypes, defaultReportConfig, exportTypes } from './constants';
import { getInvalidConfigKeys } from './utils';

class ReportConfigBuilder extends Component {
  static propTypes = {
    children: PropTypes.node,
    // data bag that is used in the report status modal.
    // Allows passing data to be used to transform config values
    // to meaningfull information to the user (ids to names, for example).
    metadata: PropTypes.shape({}),
    reportType: PropTypes.oneOf(Object.values(reportTypes)).isRequired,
    cancelReport: PropTypes.func.isRequired,
    createReport: PropTypes.func.isRequired,
    initialConfig: PropTypes.shape(),
    exportTypeOptions: PropTypes.arrayOf(
      PropTypes.oneOf(Object.values(exportTypes)),
    ),
    showBillableStatus: PropTypes.bool,
    currentRunningReport: PropTypes.shape({
      type: PropTypes.string.isRequired,
      config: PropTypes.shape().isRequired,
      result: PropTypes.shape(),
    }),
    dateRangeTypeOptions: PropTypes.arrayOf(
      PropTypes.oneOf(Object.values(dateRangeTypes)),
    ),
    showBtStModelToggle: PropTypes.bool,
  };

  static defaultProps = {
    children: (
      <>
        <Header />
        <Footer />
      </>
    ),
    metadata: undefined,
    initialConfig: {},
    exportTypeOptions: [exportTypes.csv],
    showBillableStatus: true,
    showBtStModelToggle: false,
    currentRunningReport: undefined,
    dateRangeTypeOptions: [dateRangeTypes.creationTime],
  };

  static getDerivedStateFromProps(props, state) {
    return patchFromChangedProps(props, state, [
      'metadata',
      'reportType',
      'exportTypeOptions',
      'currentRunningReport',
      'dateRangeTypeOptions',
    ]);
  }

  constructor(props) {
    super(props);

    this.state = {
      config: { ...defaultReportConfig, ...props.initialConfig },
      metadata: props.metadata,
      runReport: this.runReport,
      reportType: props.reportType,
      cancelReport: props.cancelReport,
      hasValidConfig: true,
      invalidConfigKeys: [],
      exportTypeOptions: props.exportTypeOptions,
      updateReportConfig: this.updateReportConfig,
      showBillableStatus: props.showBillableStatus,
      showBtStModelToggle: props.showBtStModelToggle,
      currentRunningReport: props.currentRunningReport,
      dateRangeTypeOptions: props.dateRangeTypeOptions,
    };
  }

  updateReportConfig = (name, value) => {
    const newValues = isPlainObject(name) ? name : { [name]: value };

    const newConfig = { ...this.state.config, ...newValues };

    const invalidConfigKeys = getInvalidConfigKeys(
      newConfig,
      this.state.reportType,
    );

    this.setState({
      config: newConfig,
      invalidConfigKeys,
      hasValidConfig: !invalidConfigKeys.length,
    });
  };

  runReport = () => {
    if (!this.state.hasValidConfig) return;

    this.props.createReport(this.state.reportType, this.state.config);
  };

  render() {
    const { children, currentRunningReport } = this.props;

    return (
      <Provider value={this.state}>
        <Panel style={{ padding: px2rem(20) }}>{children}</Panel>
        {currentRunningReport && <ReportStatusModal />}
      </Provider>
    );
  }
}

const BuilderWithActions = compose(
  setDisplayName('ReportConfigBuilder'),
  withReportActions,
)(ReportConfigBuilder);

BuilderWithActions.Header = Header;
BuilderWithActions.Footer = Footer;

export default BuilderWithActions;

import PropTypes from 'prop-types';

const contactPropType = PropTypes.shape({
  id: PropTypes.string,
  jobTitle: PropTypes.string,
  name: PropTypes.string.isRequired,
  primaryPhoneNumber: PropTypes.string.isRequired,
  secondaryPhoneNumber: PropTypes.string,
});

export default contactPropType;

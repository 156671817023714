import React from 'react';
import PropTypes from 'prop-types';

import { Column } from 'styled-components-grid';
import DefaultIcon from 'decisiv-iconix-react';

import { modifiersForComponent } from '../../utils/modifiers';

function Icon({ modifiers, ...rest }) {
  const columnModifiers = [
    'padScaleX_3',
    ...modifiersForComponent(modifiers, Column),
  ];
  const iconModifiers = modifiersForComponent(modifiers, DefaultIcon);

  return (
    <Column modifiers={columnModifiers}>
      <DefaultIcon modifiers={iconModifiers} {...rest} />
    </Column>
  );
}

Icon.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

Icon.defaultProps = {
  modifiers: [],
};

export default Icon;

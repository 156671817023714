import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

import { styles, themePropTypes } from './MenuItemTitleLink';

export default buildStyledComponent(
  'MenuItemTitleNoLink',
  styled.span,
  styles,
  {
    themePropTypes,
  },
);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose, setDisplayName } from 'recompose';

import withFocusReceiver from 'setup/FocusProvider/withFocusReceiver';

import Selector from '../Selector';
import { selectorTypes } from '../Selector/constants';

import withCaseInboundProgram from '../../withCaseInboundProgram';
import withDomicileSearch from './withDomicileSearch';
import withDomicileSelect from './withDomicileSelect';
import withRemoveCaseDomicile from './withRemoveCaseDomicile';
import { fieldIds } from '../../constants';
import {
  buildCustomerSearchValue,
  setCaseCustomerPanelStatus,
} from '../../utils';

export class DomicileSelector extends Component {
  static propTypes = {
    caseId: PropTypes.string,
    customerDomicile: PropTypes.shape({
      id: PropTypes.string,
    }),
    domicileSearchValue: PropTypes.string,
    customers: PropTypes.arrayOf(
      PropTypes.shape({
        billTo: PropTypes.string,
        city: PropTypes.string,
        id: PropTypes.string.isRequired,
        name: PropTypes.string,
        shipTo: PropTypes.string,
        state: PropTypes.string,
      }),
    ),
    isReadOnlyCase: PropTypes.bool.isRequired,
    isSearching: PropTypes.bool,
    onChangeDomicileSearchValue: PropTypes.func.isRequired,
    onRemoveDomicileCustomer: PropTypes.func.isRequired,
    onSelectCustomer: PropTypes.func.isRequired,
    setCasePanelInvalid: PropTypes.func.isRequired,
    onFocusRequested: PropTypes.func.isRequired,
  };

  static defaultProps = {
    caseId: '',
    customerDomicile: {},
    domicileSearchValue: '',
    customers: [],
    isSearching: false,
  };

  state = {
    isInvalid: false,
  };

  handleValidation = (bool) => {
    this.setState({ isInvalid: bool });
  };

  handleSelect = (id, customer) => {
    this.props.onSelectCustomer(id, buildCustomerSearchValue(customer));
  };

  resetSelector = () => {
    this.setState({ isInvalid: false });
    this.props.onRemoveDomicileCustomer(this.props.caseId);
  };

  validate = () => {
    const {
      domicileSearchValue,
      customerDomicile,
      setCasePanelInvalid,
    } = this.props;

    if (domicileSearchValue && !customerDomicile) {
      setCasePanelInvalid((status) => ({
        ...status.fields,
        [fieldIds.national.domicile]: 'invalid',
      }));
      this.setState({ isInvalid: true });
    } else {
      setCaseCustomerPanelStatus(this.props);
      this.setState({ isInvalid: false });
    }
  };

  render() {
    const {
      domicileSearchValue,
      customerDomicile,
      customers,
      isReadOnlyCase,
      isSearching,
      onChangeDomicileSearchValue,
      onFocusRequested,
    } = this.props;

    return (
      <Selector
        selectorType={selectorTypes.domicile}
        isRequired={false}
        items={customers}
        searchValue={domicileSearchValue}
        selectedItem={customerDomicile}
        isReadOnlyCase={isReadOnlyCase}
        isSearching={isSearching}
        onChangeSearchValue={onChangeDomicileSearchValue}
        onSelect={this.handleSelect}
        resetSelector={this.resetSelector}
        handleValidation={this.handleValidation}
        validateSelector={this.validate}
        isInvalid={this.state.isInvalid}
        onFocusRequested={onFocusRequested}
      />
    );
  }
}

export default compose(
  setDisplayName('DomicileSelector'),
  withCaseInboundProgram,
  withDomicileSearch,
  withDomicileSelect,
  withRemoveCaseDomicile,
  withFocusReceiver(fieldIds.national.domicile),
)(DomicileSelector);

import React from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { Formik, Form, Field } from 'formik';
import { compose, setDisplayName } from 'recompose';
import { curry, debounce, get, isEmpty } from 'lodash';

import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';
import { InputField, InputGroup } from 'base-components';

import withFocusReceiver from 'setup/FocusProvider/withFocusReceiver';
import { CaseShortcut, CASE_SHORTCUT_PANELS } from 'features/keyShortcuts';

import Error from './Error';
import withCustomCustomer from '../../withCustomCustomer';
import { fieldIds } from '../../constants';

function getInitialValues(customer) {
  return {
    fleetName: get(customer, 'name', ''),
    zipCode: get(customer, 'zip', ''),
  };
}

export const handleFormSubmit = curry((onUpdateCustomer, values) =>
  onUpdateCustomer(values),
);

export const validateValues = (values) => {
  const { fleetName, zipCode } = values;
  const errors = {};

  if (!fleetName) {
    errors.fleetName = i18n._(t`Fleet Name is required.`);
  }

  if (!zipCode && zipCode.length < 5) {
    errors.zipCode = i18n._(t`ZIP Code is required.`);
  }

  return errors;
};

export function OtherCustomer({
  customer,
  isReadOnlyCase,
  onUpdateCustomer,
  setCasePanelComplete,
  setCasePanelInvalid,
  onNameFocusRequested,
  onZipFocusRequested,
}) {
  const submitForm = debounce(handleFormSubmit(onUpdateCustomer), 1000);

  const validateForm = (values) => {
    const errors = validateValues(values);
    if (isEmpty(errors)) {
      setCasePanelComplete();
      submitForm(values);
    }
    return errors;
  };

  return (
    <Formik
      enableReinitialize
      initialValues={getInitialValues(customer)}
      onSubmit={submitForm}
      validate={validateForm}
      validateOnBlur={false}
    >
      {({ errors, touched }) => (
        <Form>
          <InputGroup style={{ marginBottom: px2rem(8) }}>
            <InputGroup.Row>
              <InputGroup.Column style={{ flex: '1' }}>
                <Field name="fleetName">
                  {({ field }) => (
                    <InputField
                      {...field}
                      isValid={touched.fleetName && !errors.fleetName}
                      readOnly={isReadOnlyCase}
                      placeholder={t`Enter fleet name...`}
                      maxLength={255}
                    >
                      <Column modifiers={['col', 'padScaleY_0']}>
                        <Row>
                          <InputField.Label>
                            <Trans>Fleet Name</Trans>
                          </InputField.Label>
                        </Row>
                        <Row>
                          <CaseShortcut
                            action={{
                              parent: CASE_SHORTCUT_PANELS.customer,
                              id: 'goToCustomerAction',
                              name: t`Go to Fleet`,
                              shortcut: ['f', '0'],
                              priority: 8,
                              icon: 'arrow-right',
                            }}
                          >
                            {({
                              onFocusRequested: onShortcutFocusRequested,
                            }) => (
                              <InputField.TextField
                                ref={(ref) => {
                                  onNameFocusRequested(ref);
                                  onShortcutFocusRequested(ref);
                                }}
                              />
                            )}
                          </CaseShortcut>
                        </Row>
                      </Column>
                    </InputField>
                  )}
                </Field>
              </InputGroup.Column>
              <InputGroup.Column modifiers={['col_4']}>
                <Field name="zipCode">
                  {({ field }) => (
                    <InputField
                      {...field}
                      readOnly={isReadOnlyCase}
                      isValid={touched.zipCode && !errors.zipCode}
                      placeholder={t`####`}
                      maxLength={255}
                    >
                      <Column modifiers={['col', 'padScaleY_0']}>
                        <Row>
                          <InputField.Label>
                            <Trans>Zip Code</Trans>
                          </InputField.Label>
                        </Row>
                        <Row>
                          <InputField.TextField ref={onZipFocusRequested} />
                        </Row>
                      </Column>
                    </InputField>
                  )}
                </Field>
              </InputGroup.Column>
            </InputGroup.Row>
            <Error
              errors={errors}
              setCasePanelInvalid={setCasePanelInvalid}
              touched={touched}
            />
          </InputGroup>
        </Form>
      )}
    </Formik>
  );
}

OtherCustomer.propTypes = {
  customer: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    zip: PropTypes.string,
  }),
  isReadOnlyCase: PropTypes.bool.isRequired,
  onUpdateCustomer: PropTypes.func.isRequired,
  setCasePanelComplete: PropTypes.func.isRequired,
  setCasePanelInvalid: PropTypes.func.isRequired,
  onNameFocusRequested: PropTypes.func.isRequired,
  onZipFocusRequested: PropTypes.func.isRequired,
};

OtherCustomer.defaultProps = {
  customer: {},
};

export default compose(
  setDisplayName('OtherCustomer'),
  withCustomCustomer,
  withFocusReceiver(fieldIds.other.name, 'onNameFocusRequested'),
  withFocusReceiver(fieldIds.other.zip, 'onZipFocusRequested'),
)(OtherCustomer);

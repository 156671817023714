const ALL_ROLES = {
  //-------------------------------------------------------------------
  // Tech-Admin-only roles:

  // access to only dealers in a given dealer group:
  DEALER_ADMIN: 'dealerAdminUser',

  // access to only a single dealer:
  DEALER_USER: 'dealerUser',

  // access to all dealers in tech admin:
  TECH_ADMIN_ADMIN: 'techAdminUser',

  //-------------------------------------------------------------------
  // Call-Center-only role:

  // only has access to the Call Center features:
  EAGLE_AGENT_USER: 'eagleAgentUser',

  //-------------------------------------------------------------------
  // Superuser role:

  // has access to both Call Center and all of Tech Admin
  ONCALL_ADMIN: 'onCallAdminUser',

  // has read-only access to Call Center and read/write to all of Tech Admin
  ONCALL_ADMIN_READ_ONLY: 'oncallAdminReadOnly',

  MICHELIN_OPERATIONS: 'michelinOperations',
};

const ONCALL_ADMIN_ONLY = [
  ALL_ROLES.ONCALL_ADMIN,
  ALL_ROLES.ONCALL_ADMIN_READ_ONLY,
];

const CALL_CENTER_ONLY = [ALL_ROLES.EAGLE_AGENT_USER];

const CALL_CENTER = [
  ...CALL_CENTER_ONLY,
  ...ONCALL_ADMIN_ONLY,
  ALL_ROLES.MICHELIN_OPERATIONS,
];

const TECH_ADMIN_ONLY = [
  ALL_ROLES.DEALER_ADMIN,
  ALL_ROLES.DEALER_USER,
  ALL_ROLES.TECH_ADMIN_ADMIN,
];

const TECH_ADMIN = [
  ...TECH_ADMIN_ONLY,
  ...ONCALL_ADMIN_ONLY,
  ALL_ROLES.MICHELIN_OPERATIONS,
];

export const PERMISSIONS_BASED_ROLES = [ALL_ROLES.MICHELIN_OPERATIONS];

export default {
  ...ALL_ROLES,
  ALL_ROLES: Object.values(ALL_ROLES),
  CALL_CENTER_ONLY,
  CALL_CENTER,
  TECH_ADMIN_ONLY,
  TECH_ADMIN,
  ONCALL_ADMIN_ONLY,
};

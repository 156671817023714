import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { Text } from 'base-components';
import { buildStyledComponent } from 'decisiv-ui-utils';

const TagText = buildStyledComponent(
  'Tag__Text',
  styled(Text).attrs(({ modifiers = '' }) => ({
    modifiers: ['small', 'fontWeightLight', ...modifiers],
  })),
  ({ theme }) => css`
    color: ${theme.colors.base.chrome600};
    line-height: 1;
  `,
  {
    themePropTypes: {
      colors: PropTypes.shape({
        base: PropTypes.shape({
          chrome600: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    },
  },
);

export default TagText;

import gql from 'graphql-tag';

export const NAME = 'caseCustomerTirePreferencesQuery';

const query = gql`
  query caseCustomerTirePreferencesQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      customer(useCachedDataForCase: true) {
        ... on Customer {
          id
          name
          type
          tirePreferences {
            type
            tires {
              type
              brand
              size
              tread
              loadRange
              application
              applicationId
              requiredActivity
              comments
            }
          }
        }

        ... on StoreCustomer {
          id
          name
          type
          tirePreferences {
            type
            tires {
              type
              brand
              size
              tread
              loadRange
              application
              applicationId
              requiredActivity
              comments
            }
          }
        }
        ... on CustomCustomer {
          type
          name
        }
        ... on CustomStoreCustomer {
          type
          name
        }
      }
    }
  }
`;

export default query;

import React from 'react';
import gql from 'graphql-tag';
import { curry, get } from 'lodash';
import { Query } from '@apollo/client/react/components';

import { createReportJobStatusTypes, reportStatusTypes } from '../constants';

const POLL_INTERVAL = 2000;

const query = gql`
  query jobStatusQuery($jobId: ID!) {
    job(id: $jobId) {
      status
      resultFileUrl
    }
  }
`;

const loadingStatuses = ['CREATED', 'NOT_STARTED', 'RUNNING'];

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, { data, loading, error, stopPolling }) => {
    const { status = 'RUNNING', resultFileUrl } = get(data, 'job') || {};
    const reportJobStatus = get(componentProps, 'currentRunningReport.status');
    const hasError = [
      error,
      reportJobStatus === createReportJobStatusTypes.failure,
    ].some(Boolean);

    let reportStatus;

    if (!hasError && (loading || loadingStatuses.includes(status))) {
      reportStatus = reportStatusTypes.loading;
    } else if (!hasError && status === reportStatusTypes.success) {
      reportStatus = reportStatusTypes.success;
    } else {
      reportStatus = reportStatusTypes.failure;
    }

    if (reportStatus !== reportStatusTypes.loading) {
      stopPolling();
    }

    return (
      <WrappedComponent
        {...componentProps}
        reportStatus={reportStatus}
        reportUrl={resultFileUrl}
      />
    );
  },
);

const withReportStatus = (WrappedComponent) => (componentProps) => (
  <Query
    query={query}
    skip={!get(componentProps, 'currentRunningReport.jobId')}
    variables={{ jobId: get(componentProps, 'currentRunningReport.jobId') }}
    pollInterval={POLL_INTERVAL}
  >
    {buildQueryChild(WrappedComponent, componentProps)}
  </Query>
);

export default withReportStatus;

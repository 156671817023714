/**
 * Component: Button Icon Circle
 *
 * Produces a icon button tag styled to be circular.
 */
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import Icon from 'decisiv-iconix-react';

const modifierConfig = {
  primary: ({ theme }) => `
    background-color: ${theme.colors.base.chrome100};
    border-color: transparent;
  `,

  secondary: ({ theme }) => `
    background-color: ${theme.colors.base.chrome600};
    border-color: transparent;
    span {
      color: ${theme.colors.base.chrome000};
    }
  `,

  active: ({ theme }) => `
    background-color: ${theme.colors.base.linkHover};
    border-color: transparent;
    span {
      color: ${theme.colors.base.chrome000};
    }
  `,

  disabled: () => `
    opacity: 0.5;
    pointer-events: none;
  `,
};

const styles = ({ theme }) => `
  align-items: center;
  background-color: ${theme.colors.base.chrome100};
  border-radius: 50%;
  border: 1px solid ${theme.colors.base.chrome200};
  cursor: pointer;
  display: flex;
  height: ${px2rem(35)};
  justify-content: center;
  line-height: normal;
  outline: none;
  transition: all 200ms ease;
  width: ${px2rem(35)};
  &:hover {
    background-color: ${theme.colors.base.linkHover};
    border-color: transparent;
    span {
      color: ${theme.colors.base.chrome000};
    }
  }
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome000: PropTypes.string.isRequired,
      chrome100: PropTypes.string.isRequired,
      chrome600: PropTypes.string.isRequired,
      linkHover: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const ButtonIconCircle = buildStyledComponent(
  'ButtonIconCircle',
  styled.button,
  styles,
  { modifierConfig, themePropTypes },
);

ButtonIconCircle.Icon = Icon;

export default ButtonIconCircle;

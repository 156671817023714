import { compact, curry } from 'lodash';

const DEPRECATION_MESSAGES = {
  warn: '`{key}` has been deprecated and will be removed in a future release.',
  error: '`{key}` has been removed and is no longer valid.',
};

const ALT_VALUE_MESSAGE = 'Please use `{altKey}` instead.';

function addDeprecation(severity, object, { parent, key, replacement }) {
  if (window.Proxy || global.Proxy) {
    const handler = {
      get(target, prop, receiver, ...rest) {
        if (prop === key) {
          if (process.env.NODE_ENV !== 'production') {
            const displayKey = parent ? `${parent}.${key}` : key;
            const message = DEPRECATION_MESSAGES[severity].replace(
              '{key}',
              displayKey,
            );
            const replacementMsg =
              replacement && ALT_VALUE_MESSAGE.replace('{altKey}', replacement);
            const finalMsg = compact([message, replacementMsg]).join(' ');
            // eslint-disable-next-line no-console
            console[severity === 'warn' ? 'warn' : 'error'](finalMsg);
          }

          if (severity === 'error') {
            return undefined;
          }
        }

        return Reflect.get(target, prop, receiver, ...rest);
      },
    };
    return new Proxy(object, handler);
  }

  // for IE, which does not support Proxy
  // eslint-disable-next-line no-console
  console.warn('Object property deprecations will not be reported on IE');
  return object;
}

export const addDeprecationWarning = curry(addDeprecation)('warn');
export const addDeprecationError = curry(addDeprecation)('error');

import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compact, noop, castArray } from 'lodash';

import { Column, Row } from 'styled-components-grid';
import { ButtonIconRectangle, Tooltip } from 'base-components';

/**
 * A pair of icons, one for editing and one for deleting, for use in
 * various spots within ONCall.
 *
 * @param onEdit - called when the edit icon is clicked
 * @param editTooltip - a tooltip for the edit icon
 * @param onDelete - called when the delete icon is clicked
 * @param deleteTooltip - a tooltip for the delete icon
 * @param deleteEnabled - if false, the delete icon will show disabled and be non-clickable
 * @param disabledDeleteTooltip - if given and `deleteEnabled`, use this tooltip instead
 * @param position - direction in which a tooltip pops up
 */
export default function EditDeleteIcons(props) {
  const { position, disabledDeleteTooltip, iconModifiers, editIcon } = props;
  const { onEdit, editTooltip, onDelete, deleteTooltip, deleteEnabled } = props;

  if (!onEdit && !onDelete) return null;

  return (
    <Row modifiers={['padScale_0']}>
      {onEdit && (
        <Column modifiers={['padScale_0']}>
          <Tooltip position={position}>
            <Tooltip.Target>
              <ButtonIconRectangle
                style={{ backgroundColor: 'inherit' }}
                onClick={onEdit}
              >
                <ButtonIconRectangle.Icon
                  name={editIcon}
                  modifiers={castArray(iconModifiers)}
                />
              </ButtonIconRectangle>
            </Tooltip.Target>
            <Tooltip.Content>{editTooltip}</Tooltip.Content>
          </Tooltip>
        </Column>
      )}
      {onDelete && (
        <Column modifiers={['padScale_0']}>
          <Tooltip position={position}>
            <Tooltip.Target>
              <ButtonIconRectangle
                style={{ backgroundColor: 'inherit' }}
                onClick={deleteEnabled ? onDelete : noop}
                modifiers={compact([
                  'hoverDanger',
                  !deleteEnabled && 'disabled',
                ])}
              >
                <ButtonIconRectangle.Icon
                  name="trash"
                  modifiers={castArray(iconModifiers)}
                />
              </ButtonIconRectangle>
            </Tooltip.Target>
            <Tooltip.Content>
              {deleteEnabled || !disabledDeleteTooltip
                ? deleteTooltip
                : disabledDeleteTooltip}
            </Tooltip.Content>
          </Tooltip>
        </Column>
      )}
    </Row>
  );
}

EditDeleteIcons.propTypes = {
  onEdit: PropTypes.func,
  editIcon: PropTypes.string,
  editTooltip: PropTypes.node,
  onDelete: PropTypes.func,
  deleteTooltip: PropTypes.node,
  deleteEnabled: PropTypes.bool,
  disabledDeleteTooltip: PropTypes.node,
  position: PropTypes.string,
  iconModifiers: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

EditDeleteIcons.defaultProps = {
  deleteEnabled: true,
  editIcon: 'pencil',
  editTooltip: <Trans>Edit</Trans>,
  deleteTooltip: <Trans>Delete</Trans>,
  disabledDeleteTooltip: null,
  position: 'left',
  iconModifiers: [],
  onEdit: undefined,
  onDelete: undefined,
};

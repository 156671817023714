import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import { Column, Row } from 'styled-components-grid';
import { Avatar, Text } from 'base-components';

import HighlightText from 'components/HighlightText';

function InboundProgramLocationDetails({
  location: { name, city, state, billTo, shipTo },
  highlightText,
  showAvatar,
}) {
  return (
    <>
      {showAvatar && (
        <Column>
          <Avatar isCompany name={name} modifiers={['small']} />
        </Column>
      )}
      <Column>
        <Row>
          <Text>
            <HighlightText text={highlightText}>
              {`${name} · ${city}, ${state}`}
            </HighlightText>
          </Text>
        </Row>
        <Row>
          <Text modifiers={['small', 'textLight']}>
            <Trans>
              Bill To # {billTo} · Ship To # {shipTo}
            </Trans>
          </Text>
        </Row>
      </Column>
    </>
  );
}

InboundProgramLocationDetails.propTypes = {
  location: PropTypes.shape({
    billTo: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    shipTo: PropTypes.string.isRequired,
    state: PropTypes.string,
  }).isRequired,
  highlightText: PropTypes.string,
  showAvatar: PropTypes.bool,
};

InboundProgramLocationDetails.defaultProps = {
  highlightText: undefined,
  showAvatar: true,
};

export default InboundProgramLocationDetails;

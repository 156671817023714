import React, { useCallback } from 'react';
import { useMutation, gql } from '@apollo/client';

import { RequestLineFieldsFragment } from '../constants';
import { NAME as REQUEST_LINES_QUERY } from '../withCaseRequestLines';

const refetchQueries = [REQUEST_LINES_QUERY];

const mutation = gql`
  mutation resetSuppliedLine($id: ID!) {
    resetSupplied(input: { id: $id }) {
      requestSuppliedLine {
        supplied
        suppliedLine {
          ...RequestLineFields
        }
      }
    }
  }
  ${RequestLineFieldsFragment}
`;

const withRemoveCaseSuppliedLineMutation = (Component) => (props) => {
  const [mutate] = useMutation(mutation, { refetchQueries });

  const resetLine = useCallback((id) => mutate({ variables: { id } }), [
    mutate,
  ]);

  return <Component {...props} resetRequestSuppliedLine={resetLine} />;
};

export default withRemoveCaseSuppliedLineMutation;

/* eslint-disable camelcase */
import { css } from 'styled-components';

const dimensionModifiers = {
  display_flex: () => ({
    styles: css`
      display: flex;
    `,
  }),
  height_100: () => ({
    styles: css`
      height: 100%;
    `,
  }),
  flex_1: () => ({
    styles: css`
      flex: 1;
    `,
  }),
};

const displayModifiers = {
  noDisplay: () => ({
    styles: css`
      display: none;
    `,
  }),
};

const padScaleModifiers = {
  padScale_0: () => ({
    styles: css`
      padding: 0;
    `,
  }),
  padScale_half: ({ theme }) => ({
    styles: css`
      padding: calc(${theme.dimensions.gridPad} * 0.5);
    `,
  }),
  padScale_1: ({ theme }) => ({
    styles: css`
      padding: ${theme.dimensions.gridPad};
    `,
  }),
  padScale_2: ({ theme }) => ({
    styles: css`
      padding: calc(${theme.dimensions.gridPad} * 2);
    `,
  }),
  padScale_3: ({ theme }) => ({
    styles: css`
      padding: calc(${theme.dimensions.gridPad} * 3);
    `,
  }),
  padScale_4: ({ theme }) => ({
    styles: css`
      padding: calc(${theme.dimensions.gridPad} * 4);
    `,
  }),
  padScale_5: ({ theme }) => ({
    styles: css`
      padding: calc(${theme.dimensions.gridPad} * 5);
    `,
  }),
  padScaleX_0: () => ({
    styles: css`
      padding-left: 0;
      padding-right: 0;
    `,
  }),
  padScaleX_half: ({ theme }) => ({
    styles: css`
      padding-left: calc(${theme.dimensions.gridPad} * 0.5);
      padding-right: calc(${theme.dimensions.gridPad} * 0.5);
    `,
  }),
  padScaleX_1: ({ theme }) => ({
    styles: css`
      padding-left: ${theme.dimensions.gridPad};
      padding-right: ${theme.dimensions.gridPad};
    `,
  }),
  padScaleX_2: ({ theme }) => ({
    styles: css`
      padding-left: calc(${theme.dimensions.gridPad} * 2);
      padding-right: calc(${theme.dimensions.gridPad} * 2);
    `,
  }),
  padScaleX_3: ({ theme }) => ({
    styles: css`
      padding-left: calc(${theme.dimensions.gridPad} * 3);
      padding-right: calc(${theme.dimensions.gridPad} * 3);
    `,
  }),
  padScaleX_4: ({ theme }) => ({
    styles: css`
      padding-left: calc(${theme.dimensions.gridPad} * 4);
      padding-right: calc(${theme.dimensions.gridPad} * 4);
    `,
  }),
  padScaleX_5: ({ theme }) => ({
    styles: css`
      padding-left: calc(${theme.dimensions.gridPad} * 5);
      padding-right: calc(${theme.dimensions.gridPad} * 5);
    `,
  }),
  padScaleY_0: () => ({
    styles: css`
      padding-top: 0;
      padding-bottom: 0;
    `,
  }),
  padScaleY_half: ({ theme }) => ({
    styles: css`
      padding-top: calc(${theme.dimensions.gridPad} * 0.5);
      padding-bottom: calc(${theme.dimensions.gridPad} * 0.5);
    `,
  }),
  padScaleY_1: ({ theme }) => ({
    styles: css`
      padding-top: ${theme.dimensions.gridPad};
      padding-bottom: ${theme.dimensions.gridPad};
    `,
  }),
  padScaleY_2: ({ theme }) => ({
    styles: css`
      padding-top: calc(${theme.dimensions.gridPad} * 2);
      padding-bottom: calc(${theme.dimensions.gridPad} * 2);
    `,
  }),
  padScaleY_3: ({ theme }) => ({
    styles: css`
      padding-top: calc(${theme.dimensions.gridPad} * 3);
      padding-bottom: calc(${theme.dimensions.gridPad} * 3);
    `,
  }),
  padScaleY_4: ({ theme }) => ({
    styles: css`
      padding-top: calc(${theme.dimensions.gridPad} * 4);
      padding-bottom: calc(${theme.dimensions.gridPad} * 4);
    `,
  }),
  padScaleY_5: ({ theme }) => ({
    styles: css`
      padding-top: calc(${theme.dimensions.gridPad} * 5);
      padding-bottom: calc(${theme.dimensions.gridPad} * 5);
    `,
  }),
};

export { dimensionModifiers, displayModifiers, padScaleModifiers };

import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { Trans } from '@lingui/macro';
import { withRouter } from 'react-router';
import { compose, setDisplayName } from 'recompose';

import { H1, Text } from 'base-components';
import { Row, Column } from 'styled-components-grid';

import USER_ROLES from 'features/rbac/constants';
import withUserRole from 'features/rbac/withUserRole';

import Panel from 'blocks/Panel';
import Breadcrumbs from 'blocks/Breadcrumbs';

import { CallCaseLinkToggleButton } from 'compositions/CaseCallLinks';
import { CreateHeadsUpNoteButton } from 'compositions/CaseHeadsUpNote';

import ButtonsContainer from './ButtonsContainer';
import CaseTimeSummaries from './CaseTimeSummaries';
import CaseCurrentStatus from './CaseCurrentStatus';
import CaseBillableToggle from './CaseBillableToggle';
import CaseAssignUserButton from './CaseAssignUserButton';
import CaseDelayedToggleButton from './CaseDelayedToggleButton';
import CasePrintButton from './CasePrintButton';
import ManageDelaysModalContext from './ManageDelaysModalContext';

const searchQueryLimit = 20;

const getIsReadOnlyUser = ({ role }) =>
  USER_ROLES[role] === USER_ROLES.ONCALL_ADMIN_READ_ONLY;

const getSearchQueryDisplayValue = ({ query }) =>
  `${query.substr(0, searchQueryLimit)}${
    query.length > searchQueryLimit ? '...' : ''
  }`;

export function CaseDetailOverviewPanel(props) {
  const { caseNumber, location, loadingRole } = props;
  const showSearchResultsBreadcrumb = !!location.search;
  const params = showSearchResultsBreadcrumb
    ? qs.parse(location.search, { ignoreQueryPrefix: true })
    : {};

  const isReadOnlyUser = !!loadingRole || getIsReadOnlyUser(props);

  return (
    <ManageDelaysModalContext caseNumber={caseNumber}>
      <Panel
        modifiers={['padScaleX_5', 'padScaleY_2']}
        data-testid="CaseDetailsOverviewPanel"
      >
        <Row modifiers={['middle', 'padScaleX_2', 'padScaleY_1']}>
          <Column>
            <Breadcrumbs>
              <Breadcrumbs.Link to="/">
                <Trans>Dashboard</Trans>
              </Breadcrumbs.Link>
              <Breadcrumbs.Divider />
              {showSearchResultsBreadcrumb && (
                <>
                  <Breadcrumbs.Link to={`/${location.search}`}>
                    {params.query ? (
                      <Trans
                        id='Search results for "{query}"'
                        values={{ query: getSearchQueryDisplayValue(params) }}
                      />
                    ) : (
                      <Trans>Search results</Trans>
                    )}
                  </Breadcrumbs.Link>
                  <Breadcrumbs.Divider />
                </>
              )}
              <Text modifiers="small">{caseNumber}</Text>
            </Breadcrumbs>
          </Column>
          <Column modifiers="end" style={{ marginLeft: 'auto' }}>
            <CaseBillableToggle caseNumber={caseNumber} />
          </Column>
        </Row>
        <Row modifiers={['middle', 'padScaleX_2', 'padScaleY_1']}>
          <Column modifiers="padScaleX_0">
            <H1 modifiers="fontWeightLight">
              <Trans>Case {caseNumber}</Trans>
            </H1>
          </Column>
          <Column modifiers="padScaleX_0">
            <CaseTimeSummaries caseNumber={caseNumber} />
          </Column>
          <Column modifiers="padScaleX_0">
            <CaseCurrentStatus caseNumber={caseNumber} />
          </Column>
          <Column modifiers="padScaleX_0">
            <CaseAssignUserButton
              caseNumber={caseNumber}
              isReadOnly={isReadOnlyUser}
            />
          </Column>
          <ButtonsContainer>
            <Column style={{ paddingLeft: 0 }}>
              <CreateHeadsUpNoteButton caseNumber={caseNumber} />
            </Column>
            <Column>
              <CaseDelayedToggleButton caseNumber={caseNumber} />
            </Column>
            <Column>
              <CallCaseLinkToggleButton caseNumber={caseNumber} />
            </Column>
            <Column>
              <CasePrintButton disabled={isReadOnlyUser} />
            </Column>
          </ButtonsContainer>
        </Row>
      </Panel>
    </ManageDelaysModalContext>
  );
}

CaseDetailOverviewPanel.propTypes = {
  caseNumber: PropTypes.string.isRequired,
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  role: PropTypes.string,
  loadingRole: PropTypes.bool.isRequired,
};

CaseDetailOverviewPanel.defaultProps = {
  role: undefined,
};

export default compose(
  setDisplayName('CaseDetailOverviewPanel'),
  withRouter,
  withUserRole,
)(CaseDetailOverviewPanel);

import { intersection } from 'lodash';

export { fontColors, fontColorPropTypes } from './fontColors';
export { default as fontStyles } from './fontStyles';
export { fontWeightPropTypes, fontWeights } from './fontWeights';
export { spacingPropTypes, spacing } from './spacing';

// This will split up/ apply only the correct modifiers
// to the internally rendered components.
// eslint-disable-next-line import/prefer-default-export
export function modifiersForComponent(modifiers, Component) {
  if (Component.modifiers) {
    return intersection(Object.keys(Component.modifiers), modifiers);
  }
  return [];
}

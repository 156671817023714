import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { i18n } from '@lingui/core';

import { Row, Column } from 'styled-components-grid';
import { MessageSmall } from 'base-components';

import contactFields from '../constants';

function getErrorMessage(errors, touched) {
  const { name, phoneNumber, backupPhoneNumber } = contactFields;

  if (touched[name] && touched[phoneNumber]) {
    if (errors[name] && errors[phoneNumber]) {
      return i18n._(t`Name and phone number are required`);
    }
  }

  return (
    (touched[name] && errors[name]) ||
    (touched[phoneNumber] && errors[phoneNumber]) ||
    (touched[backupPhoneNumber] && errors[backupPhoneNumber])
  );
}

export function CaseContactsError({ errors, touched }) {
  const errorMessage = getErrorMessage(errors, touched);

  if (errorMessage) {
    return (
      <Row>
        <Column>
          <MessageSmall type="warning">{errorMessage}</MessageSmall>
        </Column>
      </Row>
    );
  }

  return null;
}

CaseContactsError.propTypes = {
  errors: PropTypes.shape({
    [contactFields.backupPhoneNumber]: PropTypes.string,
    [contactFields.name]: PropTypes.string,
    [contactFields.phone]: PropTypes.string,
    [contactFields.email]: PropTypes.string,
  }).isRequired,
  touched: PropTypes.shape({
    [contactFields.name]: PropTypes.bool,
    [contactFields.title]: PropTypes.bool,
    [contactFields.phoneNumber]: PropTypes.bool,
    [contactFields.backupPhoneNumber]: PropTypes.bool,
  }).isRequired,
};

export default CaseContactsError;

import React from 'react';
import PropTypes from 'prop-types';

import DefaultIcon from 'decisiv-iconix-react';

function Icon({ arrowUp, modifiers, ...rest }) {
  const name = arrowUp ? 'chevron-up' : 'chevron-down';
  const iconModifiers = ['mini'].concat(modifiers);
  return <DefaultIcon name={name} modifiers={iconModifiers} {...rest} />;
}

Icon.propTypes = {
  arrowUp: PropTypes.bool,
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

Icon.defaultProps = {
  arrowUp: false,
  modifiers: [],
};

export default Icon;

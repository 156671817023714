import gql from 'graphql-tag';

export const NAME = 'setCaseCustomerMutation';

export default gql`
  mutation setCaseCustomerMutation($caseId: ID!, $customerId: ID!) {
    setCaseNationalCustomer(
      input: { id: $caseId, patch: { customerId: $customerId } }
    ) {
      case {
        id
        customerTerminalLocation
        customer(useCachedDataForCase: true) {
          ... on Customer {
            id
            name
            type
            city
            state
            billTo
            shipTo
            terminalNumber
          }
        }
        paymentMethod
      }
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { t, Trans } from '@lingui/macro';
import { compose, setDisplayName, branch } from 'recompose';

import { px2rem } from 'decisiv-ui-utils';
import { Accordion } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import { useCaseShortcut, CASE_SHORTCUT_PANELS } from 'features/keyShortcuts';

import withCustomerSelect from '../../withCustomerSelect';
import withStoreCustomerSelect from '../../withStoreCustomerSelect';
import withCustomerAccount from './withCustomerAccount';
import CustomerAccountValue, {
  nationalCustomerFields,
  storeCustomerFields,
} from './CustomerAccountValue';
import ValueLabel from '../ValueLabel';

const containerStyles = { paddingBottom: px2rem(25) };

export function AccountPreferences({
  account,
  customer,
  customerType,
  customerTypes,
}) {
  const hasData = !isEmpty(account);
  const fields =
    customerType === customerTypes.NATIONAL.type
      ? nationalCustomerFields
      : storeCustomerFields;

  const [expanded, setExpanded] = React.useState(false);

  const { onFocusRequested } = useCaseShortcut({
    parent: CASE_SHORTCUT_PANELS.customer,
    id: 'customerAccountAction',
    name: t`Account`,
    shortcut: ['f', '1'],
    priority: 7,
    perform: () => hasData && setExpanded((e) => !e),
    deps: hasData,
  });

  return (
    <Accordion
      key={get(customer, 'id')}
      disabled={!hasData}
      expanded={expanded}
      onExpandedChange={({ expanded }) => setExpanded(expanded)}
    >
      <Accordion.Head>
        <Accordion.Title ref={onFocusRequested}>
          <Trans>Account</Trans>
        </Accordion.Title>
      </Accordion.Head>
      {hasData && (
        <Accordion.Body>
          <Container style={containerStyles}>
            {fields.map((config) => (
              <Row key={config.type}>
                <Column modifiers="col_4">
                  <ValueLabel>
                    <Trans id={config.label} />
                  </ValueLabel>
                </Column>
                <Column modifiers="col">
                  <CustomerAccountValue {...config} values={account} />
                </Column>
              </Row>
            ))}
          </Container>
        </Accordion.Body>
      )}
    </Accordion>
  );
}

AccountPreferences.propTypes = {
  customerType: PropTypes.string.isRequired,
  customerTypes: PropTypes.shape({
    NATIONAL: PropTypes.shape({ type: PropTypes.string }),
  }).isRequired,
  account: PropTypes.shape({
    accountNumber: PropTypes.string,
    accountType: PropTypes.string,
    address: PropTypes.string,
    billTo: PropTypes.string,
    terminalNumber: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    fleet: PropTypes.string,
    homeOfficeNumber: PropTypes.string,
    name: PropTypes.string,
    otherAccounts: PropTypes.arrayOf(PropTypes.shape({})),
    shipTo: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    dealer: PropTypes.shape({
      name: PropTypes.string,
      billTo: PropTypes.string,
    }),
  }),
  customer: PropTypes.shape({ id: PropTypes.string }),
};

AccountPreferences.defaultProps = {
  account: {},
  customer: undefined,
};

const isNationalCustomer = ({ customerType, customerTypes }) =>
  customerType === customerTypes.NATIONAL.type;

export default compose(
  setDisplayName('AccountPreferences'),
  /**
   * Order is important here. `withCustomerAccount` needs the current customer
   * as a prop, so we must run `withCustomerSelect/withStoreCustomerSelect`
   * first.
   */
  branch(isNationalCustomer, withCustomerSelect, withStoreCustomerSelect),
  withCustomerAccount,
)(AccountPreferences);

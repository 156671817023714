import ScheduleGrid from './ScheduleGrid';
import GridDay from './GridDay';
import GridNavigator from './GridNavigator';
import ScheduleBlock from './ScheduleBlock';
import ScheduleBlockPositioner from './ScheduleBlockPositioner';

ScheduleGrid.Day = GridDay;
ScheduleGrid.Navigator = GridNavigator.Consumer;
ScheduleGrid.Block = ScheduleBlock;
ScheduleGrid.BlockPositioner = ScheduleBlockPositioner;

export default ScheduleGrid;

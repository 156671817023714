import PropTypes from 'prop-types';
import { css } from 'styled-components';

import { ButtonLink, Icon } from 'base-components';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

export default buildStyledComponent(
  'DropdownButtonLink',
  ButtonLink,
  ({ theme }) => css`
    color: ${theme.colors.base.text};
    font-size: ${px2rem(12)};
    text-align: left;
    text-transform: initial;
    transition: background 200ms ease, color 200ms ease,
      text-decoration 200ms ease;
    white-space: normal;
    width: 100%;

    ${Icon} {
      color: ${theme.colors.base.textLight};
      text-decoration: none;
    }

    strong {
      font-weight: ${theme.fontWeights.medium};
    }

    &:hover {
      background-color: ${theme.colors.base.selectableHover};

      span {
        color: ${theme.colors.base.linkHover};
        text-decoration: underline;
      }

      ${Icon} {
        text-decoration: none;
      }
    }
  `,
  {
    themePropTypes: {
      colors: PropTypes.shape({
        base: PropTypes.shape({
          linkHover: PropTypes.string.isRequired,
          selectableHover: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired,
          textLight: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
      fontWeights: PropTypes.shape({
        bold: PropTypes.number.isRequired,
      }).isRequired,
    },
  },
);

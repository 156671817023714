import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { withTheme } from 'styled-components';
import { compose, setDisplayName } from 'recompose';

import { Icon, Text } from 'base-components';
import { Row, Column } from 'styled-components-grid';

import withCaseSavingStatus from './withCaseSavingStatus';

export function CaseSaveIndicator({ isSaving, theme }) {
  const color = theme?.colors?.base?.chrome300;
  const iconStyles = isSaving ? { color } : undefined;
  const iconModifier = isSaving ? '' : 'success';

  return (
    <Row modifiers="middle">
      <Column modifiers="padScaleY_0" style={{ height: '1rem' }}>
        <Icon
          name="check-circle-f"
          style={iconStyles}
          modifiers={iconModifier}
        />
      </Column>
      <Column modifiers="padScale_0">
        <Text modifiers="small">
          {isSaving ? (
            <Trans>Saving changes...</Trans>
          ) : (
            <Trans>All changes saved</Trans>
          )}
        </Text>
      </Column>
    </Row>
  );
}

CaseSaveIndicator.propTypes = {
  isSaving: PropTypes.bool.isRequired,
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      base: PropTypes.shape({
        chrome300: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default compose(
  setDisplayName('CaseSaveIndicator'),
  withCaseSavingStatus,
  withTheme,
)(CaseSaveIndicator);

import { applyMiddleware, compose, createStore } from 'redux';

import { reduxBugsnagMiddleware } from './BugSnag';
import createReducer from './reducer';
import setupSubscribers from './subscribers';

/* istanbul ignore next */
export default function configureStore(initialState) {
  const middlewares = [
    // sagaMiddleware,
    // routerMiddleware(history),
    reduxBugsnagMiddleware,
  ];

  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  /* istanbul ignore next */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;
  /* eslint-enable */

  const store = createStore(
    createReducer(),
    initialState,
    composeEnhancers(...enhancers),
  );

  setupSubscribers(store);

  /* istanbul ignore if */
  if (module.hot && process.env.NODE_ENV !== 'production') {
    module.hot.accept('./reducer', () => {
      // eslint-disable-next-line global-require
      const nextRootReducer = require('./reducer').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}

import { combineReducers } from 'redux-immutable';

import casesReducer from 'redux/cases/reducer';
import preferencesReducer from 'redux/preferences/reducer';

const rootReducer = combineReducers({
  cases: casesReducer,
  preferences: preferencesReducer,
});

export default function createReducer() {
  return function applicationReducer(state, action) {
    return rootReducer(state, action);
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Accordion } from 'base-components';
import { Container } from 'styled-components-grid';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

// We don't apply the padding directly to the Accordion.Body
// to avoid the wonkiness of when smooth-collapse is applied
// to elements with padding.
const InnerContent = buildStyledComponent(
  'AccordionBody',
  styled(Container),
  `
    padding: ${px2rem(10)} 0 ${px2rem(30)};
  `,
);

export default function AccordionBody({ children }) {
  return (
    <Accordion.Body>
      <InnerContent>{children}</InnerContent>
    </Accordion.Body>
  );
}

AccordionBody.propTypes = {
  children: PropTypes.node.isRequired,
};

import addPreferencesSubscriber from 'redux/preferences/subscriber';

/**
 * List of all the various Redux store subscribers to be registered.
 */
const storeSubscribers = [addPreferencesSubscriber];

/**
 * Register all the Redux store subscribers.
 * @param store The Redux store
 */
export default function setupSubscribers(store) {
  storeSubscribers.forEach((subscribe) => subscribe(store));
}

import moment from 'moment-timezone';
import { defineMessage } from '@lingui/macro';

import { i18n } from '@lingui/core';

const DAYS_HOURS_MINUTES = defineMessage({
  message: '{days} d {hours} hr {minutes} min',
});
const HOURS_MINUTES = defineMessage({ message: '{hours} hr {minutes} min' });
const MINUTES = defineMessage({ message: '{minutes} min' });
const SECONDS = defineMessage({ message: '{seconds} sec' });

function selectTemplate(duration) {
  if (duration.get('days') >= 1) return DAYS_HOURS_MINUTES;
  if (duration.get('hours') >= 1) return HOURS_MINUTES;
  if (duration.get('minutes') >= 1) return MINUTES;

  return SECONDS;
}

export function formattedDuration(duration) {
  const template = selectTemplate(duration);
  const values = {
    days: Math.floor(duration.asDays()),
    hours: duration.get('hours'),
    minutes: duration.get('minutes'),
    seconds: duration.get('seconds'),
  };

  return i18n._(template, values);
}

export default function countOfTime(duration) {
  return formattedDuration(moment.duration(duration));
}

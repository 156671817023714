import gql from 'graphql-tag';

export default gql`
  query inboundProgramsLocationsQuery($inboundProgramNumberId: ID!) {
    inboundProgramNumber(id: $inboundProgramNumberId) {
      inboundProgram {
        id
        locations {
          ... on Dealer {
            id
            dealerName: name
            billTo
            shipTo
            displayAddress {
              city
              province
            }
          }
          ... on Customer {
            id
            customerName: name
            type
            city
            state
            billTo
            shipTo
          }
        }
      }
    }
  }
`;

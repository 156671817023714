import gql from 'graphql-tag';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';

import { SEARCH_CASES_QUERY_NAME } from 'compositions/CasesSearch/withCasesSearchResults';

export const unassignCaseMutation = gql`
  mutation CaseDetailOverviewPanel_CaseAssignUserButton_unassignCaseMutation(
    $caseId: ID!
  ) {
    updateCase(input: { id: $caseId, patch: { unassign: true } }) {
      case {
        id
        assignedTo {
          email
          name
        }
      }
    }
  }
`;

const withUnassignCase = (WrappedComponent) => (componentProps) => {
  const { caseId } = componentProps;
  return (
    <Mutation
      mutation={unassignCaseMutation}
      refetchQueries={[SEARCH_CASES_QUERY_NAME]}
    >
      {(dispatchMutation, { loading }) => (
        <WrappedComponent
          {...componentProps}
          unassignCase={() => dispatchMutation({ variables: { caseId } })}
          unassignCaseLoading={loading}
        />
      )}
    </Mutation>
  );
};

export default withUnassignCase;

import { ApolloLink } from '@apollo/client';
import { hasSubscription } from '@jumpn/utils-graphql';
import { bugsnagBreadcrumbLink, registerBugsnagUserLink } from 'setup/BugSnag';

import wsLink from './wsLink';
import csrfLink from './csrfLink';
import errorLink from './errorLink';
import httpLink from './httpLink';
import operationObserverLink from './operationObserverLink';

const transportLink = ApolloLink.split(
  (op) => hasSubscription(op.query),
  wsLink,
  httpLink,
);

/**
 * Build the composed final `ApolloLink` for our client.
 * Note: `.from` is basically a compose function for links.
 * @return {ApolloLink}
 */
export default function buildApolloLink() {
  return ApolloLink.from([
    bugsnagBreadcrumbLink,
    registerBugsnagUserLink,
    errorLink,
    csrfLink,
    operationObserverLink,
    transportLink,
  ]);
}

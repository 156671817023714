import { Trans } from '@lingui/macro';
import { compact, find, get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { H3, Text } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import { normalizeInboundProgramLocations } from 'compositions/CaseInboundProgramPanel/utils';

function getSelectedInboundProgramLocation(locations, shipTo) {
  if (!isEmpty(locations) && shipTo) {
    return find(locations, (location) => location.shipTo === shipTo);
  }
  return undefined;
}

function InboundProgramStore({ caseData }) {
  const locations = normalizeInboundProgramLocations(
    get(caseData, 'inboundProgramNumber.locations', []),
  );
  const selectedInboundProgramLocation = getSelectedInboundProgramLocation(
    locations,
    get(caseData, 'inboundProgramShipTo'),
  );

  if (!selectedInboundProgramLocation) {
    return null;
  }

  return (
    <>
      <Row>
        <Column>
          <Text modifiers={['small', 'textLight']}>
            <Trans>Store</Trans>
          </Text>
        </Column>
      </Row>
      <Row>
        <Column modifiers={['padScaleY_0']}>
          <Text>{selectedInboundProgramLocation.name}</Text>
        </Column>
      </Row>
      {(selectedInboundProgramLocation.city ||
        selectedInboundProgramLocation.state) && (
        <Row>
          <Column modifiers={['padScaleY_0']}>
            <Text>
              {compact([
                selectedInboundProgramLocation.city,
                selectedInboundProgramLocation.state,
              ]).join(', ')}
            </Text>
          </Column>
        </Row>
      )}
      <Row>
        <Column modifiers={['padScaleY_0']}>
          <Text>
            {selectedInboundProgramLocation.billTo && (
              <Trans>Bill To #{selectedInboundProgramLocation.billTo}</Trans>
            )}
            {selectedInboundProgramLocation.billTo &&
              selectedInboundProgramLocation.shipTo && <span> &middot; </span>}
            {selectedInboundProgramLocation.shipTo && (
              <Trans>Ship To #{selectedInboundProgramLocation.shipTo}</Trans>
            )}
          </Text>
        </Column>
      </Row>
    </>
  );
}

InboundProgramStore.propTypes = {
  caseData: PropTypes.shape({
    inboundProgramNumber: PropTypes.shape({
      locations: PropTypes.arrayOf(
        PropTypes.shape({
          billTo: PropTypes.string,
          city: PropTypes.string,
          id: PropTypes.string,
          name: PropTypes.string,
          shipTo: PropTypes.string,
          state: PropTypes.string,
          displayAddress: PropTypes.shape({
            city: PropTypes.string,
            province: PropTypes.string,
          }),
        }),
      ),
    }),
    inboundProgramShipTo: PropTypes.string,
  }).isRequired,
};

function InboundProgram({ caseData }) {
  const inboundProgramName = get(caseData, 'inboundProgramNumber.customerName');

  return (
    <Container modifiers={['padScale_2']}>
      <Row>
        <Column modifiers={['padScaleY_2']}>
          <H3 modifiers={['fontWeightRegular']}>
            <Trans>Inbound Program</Trans>
          </H3>
        </Column>
      </Row>
      <Row>
        <Column>
          {inboundProgramName ? (
            <Text>{inboundProgramName}</Text>
          ) : (
            <Text>&mdash;</Text>
          )}
        </Column>
      </Row>
      {caseData.inboundProgramShipTo && (
        <InboundProgramStore caseData={caseData} />
      )}
    </Container>
  );
}

InboundProgram.propTypes = {
  caseData: PropTypes.shape({
    inboundProgramNumber: PropTypes.shape({
      customerName: PropTypes.string,
    }),
    inboundProgramShipTo: PropTypes.string,
  }).isRequired,
};

export default InboundProgram;

import gql from 'graphql-tag';

export default gql`
  mutation updateCaseNoteMutation($body: String!, $caseNoteId: ID!) {
    updateCaseNote(input: { id: $caseNoteId, patch: { body: $body } }) {
      caseNote {
        id
      }
    }
  }
`;

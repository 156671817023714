import PropTypes from 'prop-types';

import { buildStyledComponent } from 'decisiv-ui-utils';
import { Container } from 'styled-components-grid';

import List from './List';
import ListItem from './ListItem';
import Row from './Row';

const modifierConfig = {
  borderBottom: ({ theme }) => `
    border-bottom: 1px solid ${theme.colors.base.chrome200};
  `,
};

const styles = `
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  padding: 0;
  position: relative;
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome200: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const ScrollableContainer = buildStyledComponent(
  'ScrollableContainer',
  Container,
  styles,
  {
    modifierConfig,
    themePropTypes,
  },
);

ScrollableContainer.List = List;
ScrollableContainer.ListItem = ListItem;
ScrollableContainer.Row = Row;

export default ScrollableContainer;

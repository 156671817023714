import { buildStyledComponent } from 'decisiv-ui-utils';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const styles = ({ theme }) => `
  background: ${theme.colors.base.overlay};
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  overflow-y: scroll;
  z-index: 100;
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      overlay: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default buildStyledComponent('Modal__Overlay', styled.div, styles, {
  themePropTypes,
});

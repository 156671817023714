import React, { useContext, useEffect, useRef } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { createAction, useKBar } from 'kbar';
import { i18n } from '@lingui/core';

import FocusProviderContext from 'setup/FocusProvider/FocusProviderContext';
import { useOnFocusRequested } from 'setup/FocusProvider/withFocusReceiver';

import { casePanelsActions } from '../constants';

export const useCaseShortcut = ({
  autoFocus = true,
  deps = '',
  ...actionParams
}) => {
  const parentAction = useRegisterParentAction(actionParams.parent);

  // `createAction` generates an id if it doesn't exist
  const { id: actionId } = createAction(actionParams);

  const { requestFocus, onFocusRequested } = useActionFocus(actionId);
  const keywords = getKeywords(actionParams, parentAction);

  const action = {
    ...actionParams,
    id: actionId,
    section: actionParams.section ?? parentAction?.name,
    keywords,
    perform: () => {
      actionParams.perform && actionParams.perform(requestFocus);
      autoFocus && requestFocus();
    },
  };

  useRegisterAction(action, parentAction, deps);

  return { onFocusRequested };
};

export default useCaseShortcut;

// Use this component on Class components
// or if you have a component with many shortcuts but
// don't want to fill it with a bunch of useCaseShortcut hooks
export function CaseShortcut({ action, passRef, innerRef, children }) {
  const { onFocusRequested } = useCaseShortcut(action);

  if (passRef) {
    const refProp = passRef === true ? 'ref' : passRef;
    return React.cloneElement(children, {
      [refProp]: (ref) => {
        onFocusRequested(ref);
        innerRef && innerRef(ref);
      },
    });
  }

  return typeof children === 'function'
    ? children({ onFocusRequested })
    : children;
}

export const useCleanupCasePanelActions = () => {
  const { query, actions } = useKBar((state) => ({ actions: state.actions }));
  const match = useRouteMatch({ path: '/cases/:caseNumber' });
  const isCasePage = !!match;

  // when going out of a case page, cleanup all panel actions (and its children)
  useEffect(() => {
    const caseActions = Object.values(casePanelsActions);
    if (!isCasePage && caseActions.some(({ id }) => actions[id])) {
      // only way to unregister is by registering first.
      // if it is already registered it will be overwritten and then removed
      const unregister = query.registerActions(caseActions);
      unregister();
    }
  }, [isCasePage, actions, query]);
};

const useRegisterParentAction = (parentId = '') => {
  const { actions, query } = useKBar((state) => ({
    actions: state.actions,
  }));

  useEffect(() => {
    const action = casePanelsActions[parentId];
    if (!action || actions[parentId]) return;

    // can't cleanup parent action arbitrarily here because children need it
    // cleanup is done at useCleanupCasePanelActions
    query.registerActions([action]);
  }, [actions, parentId, query]);

  return actions[parentId];
};

const useRegisterAction = (action, parent, deps) => {
  const { query } = useKBar();
  const register = useRef();

  if (parent?.id) {
    register.current = () => query.registerActions([action]);
  }

  useEffect(() => {
    const unregister = register.current && register.current();
    return () => unregister && unregister?.();
  }, [action?.id, parent?.id, deps]);
};

const useActionFocus = (id) => {
  const ref = useRef();
  const { requestFocusOn } = useContext(FocusProviderContext);
  const onFocusRequested = useOnFocusRequested(id);

  const handleFocusRef = (elementRef) => {
    ref.current = elementRef;
    onFocusRequested(elementRef);
  };

  const handleRequestFocus = () => {
    ref?.current?.focus();
    requestFocusOn(id);
  };

  return {
    requestFocus: handleRequestFocus,
    onFocusRequested: handleFocusRef,
  };
};

const getKeywords = (action, parent) => {
  return [
    parent?.name,
    parent?.keywords,
    action.keywords,
    action.name && i18n._(action.name),
  ]
    .filter(Boolean)
    .join(' ');
};

import gql from 'graphql-tag';

export const CASE_ASSET_LOCATION_QUERY_NAME = 'caseAssetLocationQuery';

const caseAssetLocationQuery = gql`
  query caseAssetLocationQuery(
    $caseNumber: String!
    $distanceUnit: DistanceUnit
  ) {
    case(caseNumber: $caseNumber) {
      id
      status
      assetLocation {
        countryCode
        coordinates {
          latitude
          longitude
        }
        highway
        mileMarker
        note
        searchValue
        address {
          city
          country
          postalCode
          province
          streetAddress
        }
        useAssetCoordinatesForTravelEstimation
      }
      servicingDealer {
        id
        dealer {
          ... on Dealer {
            id
            name
            ers247
            open247
            timezone
            features {
              id
              code
            }
            location {
              latitude
              longitude
            }
            operatingStatus {
              open
              ersOpen
            }
          }

          ... on CustomDealer {
            id
          }
        }
      }
      dealerTravelEstimateAtDispatch {
        distance(unit: $distanceUnit)
        time
      }
      statusHistory {
        id
        changedAt
        createdAt
        newStatus
        oldStatus
        user {
          email
          name
        }
      }
      proximityLevel
    }
  }
`;

export default caseAssetLocationQuery;

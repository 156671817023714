import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName } from 'recompose';
import { Trans } from '@lingui/macro';
import { get } from 'lodash';

import { Row, Column } from 'styled-components-grid';
import { H2, P, QuickActionButton, Text } from 'base-components';

import { AmazonConnect } from 'features/amazonConnect';

import Modal from 'components/Modal';

import withCallCaseLinkQuery from './withCallCaseLinkQuery';
import withCurrentCaseIdQuery from './withCurrentCaseIdQuery';
import withCallHistoryActions from './withCallHistoryActions';
import { caseCallLinkHelper, caseCallUnlinkHelper } from './helpers';

export class LinkToggleQuickActionButton extends Component {
  static propTypes = {
    caseNumber: PropTypes.string.isRequired,
    contact: PropTypes.shape({ contactId: PropTypes.string.isRequired }),
    callLinkLoading: PropTypes.bool.isRequired,
    currentCaseId: PropTypes.string,
    // These are used by caseCallLinkHelper/caseCallUnlinkHelper
    /* eslint-disable react/no-unused-prop-types */
    contactNumber: PropTypes.string,
    stateStartTime: PropTypes.instanceOf(Date),
    linkedCase: PropTypes.shape({ id: PropTypes.string.isRequired }),
    linkCaseToCall: PropTypes.func.isRequired,
    unlinkCaseFromCall: PropTypes.func.isRequired,
    /* eslint-enable react/no-unused-prop-types */
    isLinkingCallToCase: PropTypes.bool.isRequired,
    isUnlinkingCallFromCase: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    contact: null,
    currentCaseId: null,
    linkedCase: null,
    contactNumber: null,
    stateStartTime: null,
  };

  state = { showModal: false };

  toggleModal = () => this.setState({ showModal: !this.state.showModal });

  linkCallToCase = (closeModal = null) => () => {
    caseCallLinkHelper(this.props);
    if (closeModal) closeModal();
  };

  unlinkCallFromCase = () => caseCallUnlinkHelper(this.props);

  renderModal = () => {
    const { caseNumber: currentCaseNumber, linkedCase } = this.props;
    const linkedCaseNumber = get(linkedCase, 'caseNumber');

    return (
      <Modal onClose={this.toggleModal}>
        {() => (
          <Modal.Body>
            <Modal.Header>
              <Modal.HeaderIcon name="link" />
            </Modal.Header>
            <Modal.Content>
              <Row modifiers="center">
                <Column>
                  <H2 modifiers="fontWeightRegular">
                    <Trans>Link Call to Case?</Trans>
                  </H2>
                </Column>
              </Row>
              <Row modifiers="center">
                <Column>
                  <P>
                    <Trans>
                      Click CONFIRM to unlink call from{' '}
                      <Text modifiers="fontWeightMedium">
                        {linkedCaseNumber}
                      </Text>{' '}
                      and link it to{' '}
                      <Text modifiers="fontWeightMedium">
                        {currentCaseNumber}
                      </Text>
                      .
                    </Trans>
                  </P>
                </Column>
              </Row>
            </Modal.Content>
            <Modal.Footer>
              <Row modifiers="end">
                <Column>
                  <QuickActionButton onClick={this.toggleModal} type="button">
                    <QuickActionButton.Text>
                      <Trans>Cancel</Trans>
                    </QuickActionButton.Text>
                  </QuickActionButton>
                </Column>
                <Column>
                  <QuickActionButton
                    type="button"
                    onClick={this.linkCallToCase(this.toggleModal)}
                    modifiers="secondary"
                  >
                    <QuickActionButton.Text>
                      <Trans>Confirm</Trans>
                    </QuickActionButton.Text>
                  </QuickActionButton>
                </Column>
              </Row>
            </Modal.Footer>
          </Modal.Body>
        )}
      </Modal>
    );
  };

  render() {
    const { showModal } = this.state;
    const {
      contact,
      linkedCase,
      currentCaseId,
      callLinkLoading,
      isLinkingCallToCase,
      isUnlinkingCallFromCase,
    } = this.props;

    const disabled =
      callLinkLoading ||
      isLinkingCallToCase ||
      isUnlinkingCallFromCase ||
      !(contact && currentCaseId);

    const linkedCaseId = get(linkedCase, 'id');

    const linkedToDifferentCase =
      !!linkedCaseId && currentCaseId !== linkedCaseId;

    let icon = 'link';
    let label = <Trans>Link Call</Trans>;
    let onClick = linkedToDifferentCase
      ? this.toggleModal
      : this.linkCallToCase();

    if (currentCaseId === linkedCaseId) {
      icon = 'unlink';
      label = <Trans>Unlink Call</Trans>;
      onClick = this.unlinkCallFromCase;
    }

    return (
      <>
        <QuickActionButton
          onClick={onClick}
          disabled={disabled}
          modifiers={disabled && 'disabled'}
        >
          <QuickActionButton.Icon name={icon} modifiers="left" />
          <QuickActionButton.Text>{label}</QuickActionButton.Text>
        </QuickActionButton>
        {showModal && this.renderModal()}
      </>
    );
  }
}

const Button = compose(
  setDisplayName('LinkToggleQuickActionButton'),
  withCurrentCaseIdQuery,
  withCallCaseLinkQuery,
  withCallHistoryActions,
)(LinkToggleQuickActionButton);

export default function CallCaseLinkToggleButton(props) {
  return (
    <AmazonConnect>
      {({ contact, contactNumber, stateStartTime }) => (
        <Button
          contact={contact}
          contactNumber={contactNumber}
          stateStartTime={stateStartTime}
          {...props}
        />
      )}
    </AmazonConnect>
  );
}

import { curry } from 'lodash';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import gql from 'graphql-tag';

import buildRefetchQueries from './buildRefetchQueries';

const mutation = gql`
  mutation updateAfterHoursEventsContacts(
    $eventIds: [ID!]!
    $primaryContactId: ID
    $backupContactId: ID
    $finalContactId: ID
  ) {
    updateAfterHoursEventsContacts(
      input: {
        ids: $eventIds
        patch: {
          primaryContactId: $primaryContactId
          backupContactId: $backupContactId
          finalContactId: $finalContactId
        }
      }
    ) {
      events {
        id
      }
    }
  }
`;

export const buildMutationChild = curry(
  (
    WrappedComponent,
    componentProps,
    updateAfterHoursEventsContacts,
    mutationProps,
  ) => (
    <WrappedComponent
      {...componentProps}
      isUpdatingAfterHoursContacts={!!mutationProps?.loading}
      updateAfterHoursEventsContacts={(variables) =>
        updateAfterHoursEventsContacts({
          variables,
        })
      }
    />
  ),
);

function buildWrappedComponentWithMutation(WrappedComponent, componentProps) {
  return (
    <Mutation
      mutation={mutation}
      refetchQueries={buildRefetchQueries(componentProps)}
    >
      {buildMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

const withUpdateAfterHoursEventsContacts = (WrappedComponent) => (
  componentProps,
) => buildWrappedComponentWithMutation(WrappedComponent, componentProps);

export default withUpdateAfterHoursEventsContacts;

import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import Icon from './Icon';
import Text from './Text';

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      background: PropTypes.string.isRequired,
      chrome200: PropTypes.string.isRequired,
      textLight: PropTypes.string.isRequired,
    }).isRequired,
    status: PropTypes.shape({
      success: PropTypes.string.isRequired,
      danger: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dimensions: PropTypes.shape({
    borderRadius: PropTypes.string.isRequired,
    gridPad: PropTypes.string.isRequired,
  }).isRequired,
};

const modifierConfig = {
  inline: () => `display: inline-flex;`,
  inverted: ({ theme }) => `background: ${theme.colors.base.background};`,

  error: ({ theme }) =>
    css`
      border-color: ${theme.colors.status.danger};

      span {
        color: ${theme.colors.status.danger};
      }
    `,
  success: ({ theme }) =>
    css`
      border-color: ${theme.colors.status.success};

      span {
        color: ${theme.colors.status.success};
      }
    `,
};

const Tag = buildStyledComponent(
  'Tag',
  styled.span,
  ({ theme }) => css`
    align-items: center;
    border-radius: ${theme.dimensions.borderRadius};
    border: 1px solid ${theme.colors.base.chrome200};
    display: flex;
    padding: ${px2rem(2)} ${theme.dimensions.gridPad};
  `,
  { themePropTypes, modifierConfig },
);

Tag.Icon = Icon;
Tag.Text = Text;

export default Tag;

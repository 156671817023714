import { px2rem, buildStyledComponent } from 'decisiv-ui-utils';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const modifierConfig = {
  disabled: ({ theme }) => ({
    styles: `
      cursor: inherit;

      div {
        color: ${theme.colors.base.chrome500};
      }

      &:hover {
        background-color: ${theme.colors.base.background};

        div {
          color: ${theme.colors.base.chrome500};
        }
      }
    `,
  }),

  inRange: ({ theme }) => ({
    styles: `
      background-color: ${theme.colors.base.chrome100};
    `,
  }),

  selected: ({ theme }) => ({
    styles: `
      background-color: ${theme.colors.status.info};
      color: ${theme.colors.base.chrome000};

      &:hover {
        background-color: ${theme.colors.status.info};

        div {
          color: ${theme.colors.base.chrome000};
        }
      }
    `,
  }),
};

const styles = ({ theme }) => `
  background-color: ${theme.colors.base.background};
  border-radius: 0;
  border: none;
  color: ${theme.colors.base.textLight};
  cursor: pointer;
  height: ${px2rem(45)};
  padding: 0;
  width: 100%;

  div {
    font-size: ${px2rem(12)};
    line-height: ${px2rem(20)};
    min-height: ${px2rem(20)};
    width: 100%;
  }

  .fa {
    font-size: ${px2rem(4)};
    margin-top: ${px2rem(12)};
  }

  &:hover {
    background-color: ${theme.colors.base.chrome100};

    div {
      color: ${theme.colors.base.linkHover};
    }
  }
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      background: PropTypes.string.isRequired,
      chrome000: PropTypes.string.isRequired,
      chrome100: PropTypes.string.isRequired,
      chrome500: PropTypes.string.isRequired,
      linkHover: PropTypes.string.isRequired,
      textLight: PropTypes.string.isRequired,
    }).isRequired,
    status: PropTypes.shape({
      info: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default buildStyledComponent(
  'Date',
  styled.button.attrs({ type: 'button' }),
  styles,
  {
    modifierConfig,
    themePropTypes,
  },
);

import styled, { css } from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import { getBreakpointFor } from './utils';

export default buildStyledComponent(
  'CheckboxesContainer',
  styled.div,
  ({ isNavExpanded }) => css`
    display: flex;
    padding: ${px2rem(8)} 0;

    > * {
      margin-left: ${px2rem(8)};
    }

    > *:first-child {
      margin-left: 0;
    }

    /* Three items per row */
    @media (max-width: ${getBreakpointFor('threeItemsPerRow', isNavExpanded)}) {
      flex-wrap: wrap;

      > * {
        flex-basis: calc(33.33% - ${px2rem(8)});
      }

      > *:nth-child(3n - 2) {
        margin-left: 0;
      }

      > *:nth-child(n + 4) {
        margin-top: ${px2rem(8)};
      }
    }

    /* Two items per row */
    @media (max-width: ${getBreakpointFor('twoItemsPerRow', isNavExpanded)}) {
      flex-wrap: wrap;

      > * {
        flex-basis: calc(50% - ${px2rem(8)});
      }

      > *:nth-child(3n - 2) {
        margin-left: ${px2rem(8)};
      }

      > *:nth-child(2n - 3) {
        margin-left: 0;
      }

      > *:nth-child(n + 3) {
        margin-top: ${px2rem(8)};
      }
    }
  `,
);

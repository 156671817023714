import { px2rem, buildStyledComponent } from 'decisiv-ui-utils';
import styled from 'styled-components';
import { Container } from 'styled-components-grid';

import Date from './Date';
import DateTable from './DateTable';
import DateTableCell from './DateTableCell';
import DateTableRow from './DateTableRow';

const styles = `
  max-width: ${px2rem(474)};
  min-width: ${px2rem(344)};
`;

const Calendar = buildStyledComponent('Calendar', styled(Container), styles);

Calendar.Date = Date;
Calendar.DateTable = DateTable;
Calendar.DateTableCell = DateTableCell;
Calendar.DateTableRow = DateTableRow;

export default Calendar;

import qs from 'qs';

import { CCP_URLS } from './constants';
import { showPopup } from './utils';

export {
  default as AmazonConnect,
  AmazonConnectProvider,
} from './AmazonConnect';

export {
  modifiers,
  modifierToColors,
  uiStateColors,
  uiStateToModifier,
} from './colors';

export {
  isIncomingState,
  isConversationState,
  isConnectingState,
  isDisconnectedState,
  contactDetailsEnabled,
} from './states';

export function makeCallRecordingUrl(
  amazonConnectContactId,
  { format = 'WAV' } = {},
) {
  const params = {
    format,
    callLegId: amazonConnectContactId,
  };
  return `${CCP_URLS.GET_RECORDING}?${qs.stringify(params)}`;
}

export function logoutConnect(callback) {
  const connectLogoutWindow = showPopup(CCP_URLS.LOGOUT);

  setTimeout(() => {
    connectLogoutWindow.close();
    callback();
  }, 1000);
}

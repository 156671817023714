// TODO: Extract this block and supporting elements out to Base Components.
import { buildStyledComponent } from 'decisiv-ui-utils';
import { Container } from 'styled-components-grid';

import Event from './Event';
import EventIndicatorBar from './EventIndicatorBar';
import EventIndicatorIcon from './EventIndicatorIcon';
import EventLabel from './EventLabel';

const Timeline = buildStyledComponent('Timeline', Container);

Timeline.Event = Event;
Timeline.EventIndicatorBar = EventIndicatorBar;
Timeline.EventIndicatorIcon = EventIndicatorIcon;
Timeline.EventLabel = EventLabel;

export default Timeline;

import React, { Component } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { findLast, get, last } from 'lodash';
import { compose, setDisplayName } from 'recompose';

import {
  H2,
  MessageSmall as BaseMessageSmall,
  P,
  QuickActionButton,
} from 'base-components';
import MessageSmall from 'base-components/blocks/MessageSmall';
import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';

import DatetimeSelector from 'components/DatetimeSelector';
import Modal from 'components/Modal';

import countOfTime from 'utils/countOfTime';
import withContext from 'utils/withContext';
import { CASE_STATUS } from 'utils/caseEventsFromStatusHistory';

import CaseStatusContext from '../CaseStatusContext';
import withUpdateCaseStatusChange from '../withUpdateCaseStatusChange';

export class UpdateRollTimeModal extends Component {
  static propTypes = {
    caseStatusChangeId: PropTypes.string.isRequired,
    completedAt: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    isUpdatingCaseStatusChange: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    servicingDealerTimezone: PropTypes.string,
    statusHistory: PropTypes.arrayOf(
      PropTypes.shape({ changedAt: PropTypes.string.isRequired }).isRequired,
    ).isRequired,
    updateCaseStatusChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    completedAt: undefined,
    servicingDealerTimezone: undefined,
  };

  constructor(props) {
    super(props);

    const { completedAt, servicingDealerTimezone: selectedTimezone } = props;

    this.state = {
      selectedDate: completedAt
        ? moment(completedAt).toDate()
        : moment().toDate(),
      selectedDateIsValid: true,
      selectedTimezone,
      showFutureTimeError: false,
    };
  }

  getEarliestValidTime = () => {
    const statusChangeEvent = findLast(this.props.statusHistory, {
      newStatus: CASE_STATUS.dispatched,
    });
    return moment(get(statusChangeEvent, `changedAt`)).toDate();
  };

  getLatestValidTime = () => {
    // closed time or now
    const statusChangeEvent = last(this.props.statusHistory);
    if (get(statusChangeEvent, 'newStatus') === CASE_STATUS.closed) {
      return moment(get(statusChangeEvent, 'changedAt')).toDate();
    }
    return undefined;
  };

  handleError = () => {
    // if an error happens, it's due to user's clock being inaccurate
    // and the datetime they sent is in the future
    this.setState({ selectedDateIsValid: false, showFutureTimeError: true });
  };

  updateRollTime = () => {
    this.props
      .updateCaseStatusChange({
        caseStatusChangeId: this.props.caseStatusChangeId,
        timestamp: this.state.selectedDate.toISOString(),
      })
      .then(this.props.onClose)
      .catch(this.handleError);
  };

  render() {
    const { createdAt, onClose, isUpdatingCaseStatusChange } = this.props;
    const totalDowntime = moment(this.state.selectedDate).diff(
      moment(createdAt),
    );
    const totalDowntimeDuration = moment.duration(totalDowntime);
    const totalDowntimeString = countOfTime(totalDowntime);

    return (
      <Modal onClose={onClose}>
        {() => (
          <Modal.Body>
            <Modal.Header>
              <Modal.HeaderIcon name="road" />
            </Modal.Header>
            <Modal.Content>
              <Row modifiers="center">
                <Column>
                  <H2 modifiers="fontWeightRegular">
                    <Trans>Edit Roll Time?</Trans>
                  </H2>
                </Column>
              </Row>
              <Row modifiers="center" style={{ marginBottom: px2rem(40) }}>
                <Column>
                  <P>
                    {this.props.servicingDealerTimezone ? (
                      <Trans>
                        Enter the date and time the vehicle officially started
                        rolling using the service provider&#39;s time zone.
                      </Trans>
                    ) : (
                      <Trans>
                        Enter the date and time the vehicle officially started
                        rolling using your time zone.
                      </Trans>
                    )}
                  </P>
                </Column>
              </Row>
              <DatetimeSelector
                onChange={(
                  selectedDate,
                  selectedTimezone,
                  { isValid: selectedDateIsValid },
                ) =>
                  this.setState({
                    selectedDate,
                    selectedDateIsValid,
                    selectedTimezone,
                    showFutureTimeError: false,
                  })
                }
                selectedDatetime={this.state.selectedDate}
                selectedTimezone={this.state.selectedTimezone}
                validBetween={[
                  this.getEarliestValidTime(),
                  this.getLatestValidTime(),
                ]}
              />
              {this.state.showFutureTimeError && (
                <Row modifiers={['padScaleY_2']}>
                  <Column modifiers={['col']}>
                    <MessageSmall style={{ alignItems: 'flex-start' }}>
                      <MessageSmall.Icon
                        type="danger"
                        style={{ marginTop: 3 }}
                        modifiers={['mini', 'danger']}
                      />
                      <MessageSmall.Text style={{ whiteSpace: 'initial' }}>
                        <Trans>
                          Sorry something went wrong. Make sure that your
                          computer&#39;s clock is accurate and that the date and
                          time you selected is not in the future.
                        </Trans>
                      </MessageSmall.Text>
                    </MessageSmall>
                  </Column>
                </Row>
              )}
            </Modal.Content>
            <Modal.Footer>
              <Row>
                <Column modifiers="col">
                  <BaseMessageSmall
                    type={
                      totalDowntimeDuration.asMinutes() < 60
                        ? 'warning'
                        : 'info'
                    }
                  >
                    <Trans>{totalDowntimeString} Downtime</Trans>
                  </BaseMessageSmall>
                </Column>
                <Column>
                  <QuickActionButton onClick={onClose} type="button">
                    <QuickActionButton.Text>
                      <Trans>Keep Roll Time</Trans>
                    </QuickActionButton.Text>
                  </QuickActionButton>
                </Column>
                <Column>
                  <QuickActionButton
                    type="button"
                    onClick={this.updateRollTime}
                    disabled={
                      !this.state.selectedDateIsValid ||
                      isUpdatingCaseStatusChange
                    }
                    modifiers={[
                      'secondary',
                      (!this.state.selectedDateIsValid ||
                        isUpdatingCaseStatusChange) &&
                        'disabled',
                    ]}
                  >
                    <QuickActionButton.Text>
                      <Trans>Save Roll Time</Trans>
                    </QuickActionButton.Text>
                  </QuickActionButton>
                </Column>
              </Row>
            </Modal.Footer>
          </Modal.Body>
        )}
      </Modal>
    );
  }
}

export default compose(
  setDisplayName('UpdateRollTimeModal'),
  withContext(CaseStatusContext),
  withUpdateCaseStatusChange,
)(UpdateRollTimeModal);

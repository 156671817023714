import React from 'react';
import PropTypes from 'prop-types';

import { MessageSmall, Tooltip } from 'base-components';
import { buildStyledComponent } from 'decisiv-ui-utils';
import { Container, Row, Column } from 'styled-components-grid';

const styles = ({ theme }) => `
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: ${theme.colors.base.chrome100};
  justify-content: center;
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome100: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export const StyledContainer = buildStyledComponent(
  'StyledContainer',
  Container,
  styles,
  { themePropTypes },
);

function RequiredDependencyMessage({ message, tooltip, ...rest }) {
  const content = (
    <Row modifiers={['center']}>
      <Column>
        <MessageSmall type="info">{tooltip ? '' : message}</MessageSmall>
      </Column>
    </Row>
  );

  return (
    <StyledContainer
      modifiers={['flex_column', 'height_100', 'padScale_0']}
      {...rest}
    >
      {tooltip ? (
        <Tooltip>
          <Tooltip.Target>{content}</Tooltip.Target>
          <Tooltip.Content>{message}</Tooltip.Content>
        </Tooltip>
      ) : (
        content
      )}
    </StyledContainer>
  );
}

RequiredDependencyMessage.propTypes = {
  message: PropTypes.string,
  tooltip: PropTypes.bool,
};

RequiredDependencyMessage.defaultProps = {
  message: '',
  tooltip: false,
};

RequiredDependencyMessage.displayName = 'RequiredDependencyMessage';

export default RequiredDependencyMessage;

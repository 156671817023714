import { Trans } from '@lingui/macro';
import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { compose, setDisplayName } from 'recompose';
import { withTheme } from 'styled-components';
import { noop, trim } from 'lodash';

import { px2rem } from 'decisiv-ui-utils';
import { Avatar, Text } from 'base-components';
import { Container, Column, Row } from 'styled-components-grid';

import Newline2Br from 'elements/Newline2Br';

import EditedBadge from './EditedBadge';
import EntryDivider from '../../EntryDivider';
import { UpdateForm } from '../Form';

function NoteView({ bordered, editing, note, theme, onStopEditing }) {
  const {
    author: { email, name },
    body,
    createdAt,
    id,
    lastUpdatedBy,
    updatedAt,
  } = note;

  const displayName = trim(name) || email;
  // createdAt and updatedAt have a difference of few milliseconds
  // even if the note was not updated.
  // If the difference is bigger than 1 second, we consider it updated.
  const wasUpdated = moment(updatedAt).unix() - moment(createdAt).unix() > 1;

  return (
    <div
      style={
        bordered
          ? {
              border: `1px solid ${theme.colors.base.chrome200}`,
              paddingTop: px2rem(5),
            }
          : {}
      }
    >
      {!bordered && (
        <Row>
          <Column
            modifiers={['center', 'height_100', 'padScaleY_0']}
            style={{ height: px2rem(25), width: px2rem(50) }}
          >
            <EntryDivider />
          </Column>
        </Row>
      )}
      <Row>
        <Column
          modifiers={['center', 'padScaleY_0']}
          style={{ width: px2rem(50) }}
        >
          <Container modifiers={['flex_column', 'height_100', 'padScale_0']}>
            <Row>
              <Column modifiers={['center', 'padScaleY_1']}>
                <Avatar
                  modifiers={['small']}
                  email={email}
                  name={displayName}
                />
              </Column>
            </Row>
            {!bordered && (
              <Row modifiers={['center', 'flex_1']}>
                <Column modifiers={['padScaleY_0']}>
                  <EntryDivider />
                </Column>
              </Row>
            )}
          </Container>
        </Column>
        <Column
          modifiers={['col']}
          style={{ paddingBottom: px2rem(bordered ? 15 : 20) }}
        >
          <Row>
            <Column modifiers={['padScaleY_0']}>
              <Text modifiers={['small', 'textLight']}>
                {moment(createdAt).tz(moment.tz.guess()).format('h:mm A z')}
              </Text>
            </Column>
          </Row>
          <Row>
            <Column modifiers={['padScaleY_0']}>
              <Text>{displayName}</Text>
              <Text>
                &nbsp;
                <Trans>created a note:</Trans>
              </Text>
            </Column>
          </Row>
          <Row>
            <Column modifiers={['col', 'padScaleY_0']}>
              {editing ? (
                <UpdateForm
                  caseNoteId={id}
                  notes={body}
                  onCancel={onStopEditing}
                  onSubmitSuccess={onStopEditing}
                />
              ) : (
                <Text>
                  <Newline2Br value={body} />
                </Text>
              )}
            </Column>
          </Row>
          {!bordered && wasUpdated && (
            <Row>
              <Column>
                <EditedBadge
                  email={lastUpdatedBy.email}
                  name={lastUpdatedBy.name}
                  updatedAt={updatedAt}
                />
              </Column>
            </Row>
          )}
        </Column>
      </Row>
    </div>
  );
}

NoteView.propTypes = {
  bordered: PropTypes.bool,
  editing: PropTypes.bool,
  note: PropTypes.shape({
    author: PropTypes.shape({
      email: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
    body: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    lastUpdatedBy: PropTypes.shape({
      email: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
    updatedAt: PropTypes.string.isRequired,
  }).isRequired,
  onStopEditing: PropTypes.func,
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      base: PropTypes.shape({
        chrome200: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

NoteView.defaultProps = {
  bordered: false,
  editing: false,
  onStopEditing: noop,
};

export default compose(setDisplayName('NoteView'), withTheme)(NoteView);

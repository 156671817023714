import gql from 'graphql-tag';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { compose } from 'recompose';

import { NAME as CASE_DELAYS_QUERY_NAME } from 'compositions/CaseDetailOverviewPanel/withCaseDelays/caseDelaysQuery';
import { NAME as CASE_REQUEST_LINES_QUERY_NAME } from 'compositions/CaseRequestsPanel/CaseRequestsPanelContext/withCaseRequestLines';
import { CASE_DEALER_RESPONSES_QUERY_NAME } from '../withCaseDealerResponses';

const createMutationFor = (mutation, faacArgsMapper) => (WrappedComponent) => (
  componentProps,
) => (
  <Mutation
    mutation={mutation}
    refetchQueries={[
      CASE_DEALER_RESPONSES_QUERY_NAME,
      CASE_DELAYS_QUERY_NAME,
      CASE_REQUEST_LINES_QUERY_NAME,
    ]}
  >
    {(...args) => (
      <WrappedComponent {...componentProps} {...faacArgsMapper(...args)} />
    )}
  </Mutation>
);

const mapFAACArgsToProps = (mutationPropName, loadingPropName) => (
  mutate,
  { loading },
) => ({
  [mutationPropName]: (variables) => mutate({ variables }),
  [loadingPropName]: !!loading,
});

const createDealerResponseGQL = gql`
  mutation compositions_CaseDealerSelectionPanel_createDealerResponse(
    $caseId: ID!
    $dealerId: ID!
    $dealerResponseAttrs: DealerResponseAttrs!
    $dealerType: DealerType!
  ) {
    createDealerResponse(
      input: {
        caseId: $caseId
        dealerId: $dealerId
        dealerResponse: $dealerResponseAttrs
        dealerType: $dealerType
      }
    ) {
      dealerResponse {
        id
      }
    }
  }
`;

const updateDealerResponseGQL = gql`
  mutation compositions_CaseDealerSelectionPanel_updateDealerResponse(
    $id: ID!
    $patch: DealerResponseAttrs!
  ) {
    updateDealerResponse(input: { id: $id, patch: $patch }) {
      dealerResponse {
        id
      }
    }
  }
`;

const deleteDealerResponseGQL = gql`
  mutation compositions_CaseDealerSelectionPanel_deleteDealerResponse(
    $ids: [ID!]!
  ) {
    deleteDealerResponse(input: { ids: $ids }) {
      deletedCount
    }
  }
`;

export default compose(
  createMutationFor(
    createDealerResponseGQL,
    mapFAACArgsToProps('createDealerResponse', 'isCreatingDealerResponse'),
  ),
  createMutationFor(
    updateDealerResponseGQL,
    mapFAACArgsToProps('updateDealerResponse', 'isUpdatingDealerResponse'),
  ),
  createMutationFor(
    deleteDealerResponseGQL,
    mapFAACArgsToProps('deleteDealerResponse', 'isDeletingDealerResponse'),
  ),
);

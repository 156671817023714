import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const styles = () => `
  position: relative;
  z-index: 0;
  width: 100%;
  box-sizing: border-box;
`;

const modifierConfig = {
  scrollable: ({ theme: { colors } }) => `
    overflow-y: auto;
    height: ${px2rem(240)};
    border: ${px2rem(1)} solid ${colors.base.chrome400};
    border-left: 0;

    > :first-child {
      margin-top: ${px2rem(-1)};
    }
  `,
};

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome100: PropTypes.string.isRequired,
      chrome400: PropTypes.string.isRequired,
      chrome600: PropTypes.string.isRequired,
      background: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default buildStyledComponent(
  'DeleteContactModal_ListWrapper',
  styled.div,
  styles,
  {
    themePropTypes,
    modifierConfig,
  },
);

import PropTypes from 'prop-types';
import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import TechAdminDealerDetails from 'pages/TechAdmin/DealerDetails';

import TechAdminRouter from './TechAdminRouter';

export default function TechAdminRoutes({ match }) {
  return (
    <Switch>
      <Route exact path={`${match.url}/dealers`} component={TechAdminRouter} />
      <Route
        exact
        path={`${match.url}/dealers/:dealerId`}
        component={TechAdminDealerDetails}
      />
      <Redirect to="/" />
    </Switch>
  );
}

TechAdminRoutes.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

import { t, defineMessage } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { get } from 'lodash';
import moment from 'moment-timezone';

import buildEnum from 'utils/buildEnum';

import { formatRecurrenceDays } from '../helpers';

export const daysOfWeek = [
  { label: t`Mon`, value: 'MONDAY' },
  { label: t`Tue`, value: 'TUESDAY' },
  { label: t`Wed`, value: 'WEDNESDAY' },
  { label: t`Thu`, value: 'THURSDAY' },
  { label: t`Fri`, value: 'FRIDAY' },
  { label: t`Sat`, value: 'SATURDAY' },
  { label: t`Sun`, value: 'SUNDAY' },
];

export const helperMessagesTypes = buildEnum([
  'checkingConflicts',
  'startTimeInvalid',
  'isSaving',
]);

export const helperMessages = {
  [helperMessagesTypes.checkingConflicts]: {
    label: defineMessage({
      message: 'Checking for conflicting events, please wait...',
    }),
  },
  [helperMessagesTypes.startTimeInvalid]: {
    label: defineMessage({
      message: 'Start Time cannot be before now ({timeNow})',
    }),
    icon: 'exclamation-circle',
    modifier: 'danger',
  },
  [helperMessagesTypes.isSaving]: {
    label: defineMessage({ message: 'Saving, please wait...' }),
  },
};

export function getHelperMessage({
  checkingConflicts,
  startTimeInvalid,
  isSaving,
}) {
  const status = [
    isSaving && helperMessagesTypes.isSaving,
    startTimeInvalid && helperMessagesTypes.startTimeInvalid,
    checkingConflicts && helperMessagesTypes.checkingConflicts,
  ].find(Boolean);
  return status ? helperMessages[status] : {};
}

export const occurrenceKinds = buildEnum(['single', 'range']);

export const occurrenceKindsLabels = {
  [occurrenceKinds.single]: t`Edit only this occurrence of the rotation`,
  [occurrenceKinds.range]: t`Edit a specific period within this rotation`,
};

const formatDay = (day) => moment(day).format('MMM DD YYYY');
const mapParagraphsWithValues = (paragraphs, values) =>
  paragraphs.map((paragraph) => i18n._(paragraph, values));

export function getEditRotationDescription(event, occurrence) {
  const descriptionByOccurrence = {
    [occurrenceKinds.single]: [
      defineMessage({
        message: "You're editing this rotation on <0>{blockDate}</0>.",
      }),
      defineMessage({
        message:
          'You can change the rotation start time, end time, and contacts.',
      }),
    ],
    [occurrenceKinds.range]: [
      defineMessage({
        message:
          "You're editing this rotation at every <0>{recurrenceDays}, starting on {startDate}{endDate}</0>.",
      }),
      defineMessage({
        message:
          'You can change the rotation start time, end time, and contacts.',
      }),
    ],
  };

  if (!get(occurrence, 'kind')) {
    const values = {
      blockDate: formatDay(event.start),
    };

    return mapParagraphsWithValues(
      descriptionByOccurrence[occurrenceKinds.single],
      values,
    );
  }

  const { kind, startDate, endDate } = occurrence;

  const descriptionTemplate = descriptionByOccurrence[kind];

  const values = {
    recurrenceDays: formatRecurrenceDays(event),
    startDate: formatDay(startDate),
    endDate: endDate ? t` to ${formatDay(endDate)}` : '',
    blockDate: formatDay(event.start),
  };

  return mapParagraphsWithValues(descriptionTemplate, values);
}

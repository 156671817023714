import React from 'react';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';
import { Icon } from 'base-components';

import RotationModalContext from '../RotationModalContext';

import ScheduleBlock from './ScheduleBlock';
import ScheduleBlockPositioner from './ScheduleBlockPositioner';

const ButtonWrapper = buildStyledComponent(
  'ScheduleGrid__RotationGapBlock__ButtonWrapper',
  ScheduleBlock,
  () => `
    border: none;
    left: 0px;
    margin: ${px2rem(2)} 0 0 ${px2rem(1)};
    padding: 0;
    width: calc(100% - 2px);
    text-align: center;
  `,
  {
    themePropTypes: {
      colors: PropTypes.shape({
        base: PropTypes.shape({
          chrome200: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    },
  },
);

const EventAddButton = buildStyledComponent(
  'ScheduleGrid__RotationGapBlock__EventAddButton',
  styled.button,
  ({ theme }) => `
    background: ${theme.colors.status.info};
    border: none;
    border-radius: ${theme.dimensions.borderRadius};
    height: ${px2rem(25)};
    outline: none;
    padding: ${theme.dimensions.gridPad};
    width: 100%;
    cursor: pointer;
    span {
      color: ${theme.colors.base.chrome000};
    }
  `,
  {
    themePropTypes: {
      colors: PropTypes.shape({
        base: PropTypes.shape({
          chrome000: PropTypes.string.isRequired,
          chrome600: PropTypes.string.isRequired,
          linkHover: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
      dimensions: PropTypes.shape({
        gridPad: PropTypes.string.isRequired,
      }).isRequired,
    },
  },
);

export default function RotationGapButton(props) {
  const { day, wrapperOffset, buttonEvent, event } = props;

  return (
    <RotationModalContext.Consumer>
      {({ showEditModal }) => (
        <ScheduleBlockPositioner day={day} event={buttonEvent}>
          {({ top: buttonTop, height: buttonHeight }) => (
            <ButtonWrapper
              style={{
                top: `${buttonTop - wrapperOffset - 1}px`,
                height: `${buttonHeight + 1}px`,
              }}
            >
              <EventAddButton onClick={() => showEditModal(event)}>
                <Icon name="plus" />
              </EventAddButton>
            </ButtonWrapper>
          )}
        </ScheduleBlockPositioner>
      )}
    </RotationModalContext.Consumer>
  );
}

RotationGapButton.propTypes = {
  day: PropTypes.oneOfType([
    MomentPropTypes.momentString,
    MomentPropTypes.momentObj,
  ]).isRequired,

  event: PropTypes.shape({
    blockStart: MomentPropTypes.momentObj.isRequired,
    blockEnd: MomentPropTypes.momentObj.isRequired,
  }).isRequired,

  buttonEvent: PropTypes.shape({
    blockStart: MomentPropTypes.momentObj.isRequired,
    blockEnd: MomentPropTypes.momentObj.isRequired,
  }).isRequired,

  // wrapperOffset is the pixel offset of the gap event that
  // wraps this button, used to determine position of the button
  // within that wrapping event
  wrapperOffset: PropTypes.number.isRequired,
};

import { t, Trans } from '@lingui/macro';
import { isArray, isEmpty, noop } from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import {
  ButtonToggle,
  DateSelector,
  Dropdown,
  InputField,
  InputGroup,
  MessageSmall,
} from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';

import TimeDropdown from './TimeDropdown';
import TimezoneDropdown from './TimezoneDropdown';

function isSelectedDatetimeTooEarly(
  validBetween,
  selectedDatetime,
  selectedTimezone,
) {
  return (
    !isEmpty(validBetween) &&
    validBetween[0] instanceof Date &&
    moment.tz(validBetween[0], selectedTimezone).isAfter(selectedDatetime)
  );
}

/**
 *
 * @param {Array<Date, Date|undefined>} validBetween An array containing 1 or 2 dates. The second element should be undefined to use the current time.
 * @param {Date} selectedDatetime a JS date object.
 */
function isSelectedDatetimeTooLate(
  validBetween,
  selectedDatetime,
  selectedTimezone,
) {
  return (
    !isEmpty(validBetween) &&
    validBetween[0] instanceof Date &&
    moment.tz(validBetween[1], selectedTimezone).isBefore(selectedDatetime)
  );
}

function isSelectedDatetimeValid(
  validBetween,
  selectedDatetime,
  selectedTimezone,
) {
  return (
    selectedDatetime &&
    !isSelectedDatetimeTooEarly(
      validBetween,
      selectedDatetime,
      selectedTimezone,
    ) &&
    !isSelectedDatetimeTooLate(validBetween, selectedDatetime, selectedTimezone)
  );
}

function getEarliestValidTimeString(validBetween, selectedTimezone) {
  const earliestValidTime = moment.tz(validBetween[0], selectedTimezone);
  return earliestValidTime.format('D MMM, h:mm A z');
}

function getLatestValidTimeString(validBetween, selectedTimezone) {
  if (validBetween[1] instanceof Date) {
    const latestValidTime = moment.tz(validBetween[1], selectedTimezone);
    return latestValidTime.format('D MMM, h:mm A z');
  }

  const selectedTimezoneAbbr = moment
    .tz(new Date(), selectedTimezone)
    .format('z');

  return t`now (${selectedTimezoneAbbr})`;
}

function DatetimeSelector({
  onChange,
  selectedDatetime: unlocalizedSelectedDatetime,
  selectedTimezone = moment.tz.guess(),
  validBetween,
}) {
  const selectedDatetime =
    unlocalizedSelectedDatetime &&
    moment.tz(unlocalizedSelectedDatetime, selectedTimezone);

  // using this to only show the time after it has been selected
  const [touchedTime, setTouchedTime] = React.useState(!!selectedDatetime);

  function handleDateChange(date) {
    // `date` is a JS date in the user's timezone, not the selected timezone.
    // This code extracts the relevant bits of data from the new date and the
    // current selected date and creates a new JS date with accurate data.
    const momentDate = moment(date);
    const newSelectedDate = moment
      .tz(new Date(), selectedTimezone)
      .year(momentDate.year())
      .month(momentDate.month())
      .date(momentDate.date())
      .hour(selectedDatetime?.hour() || 0)
      .minutes(selectedDatetime?.minutes() || 0)
      .toDate();

    onChange(newSelectedDate, selectedTimezone, {
      isValid: isSelectedDatetimeValid(
        validBetween,
        newSelectedDate,
        selectedTimezone,
      ),
    });
  }

  function handleTimeChange(name, value) {
    setTouchedTime(true);
    const workingSelection = moment.tz(selectedDatetime, selectedTimezone);

    if (name === 'hours') {
      const dayPeriod = moment(selectedDatetime).format('A');
      workingSelection.hour(moment(`${value} ${dayPeriod}`, 'h A').hours());
    } else {
      workingSelection.minutes(value);
    }

    const newSelectedDate = workingSelection.toDate();

    onChange(newSelectedDate, selectedTimezone, {
      isValid: isSelectedDatetimeValid(
        validBetween,
        newSelectedDate,
        selectedTimezone,
      ),
    });
  }

  function handleDayPeriodChange(isPM) {
    const date = moment.tz(selectedDatetime, selectedTimezone);
    let hours;

    if (date.hours() === 12) {
      hours = isPM ? 0 : 12;
    } else {
      hours = moment(`${date.hours()}PM`, 'hA').format(isPM ? 'h' : 'H');
    }

    const newSelectedDate = moment
      .tz(date.hours(hours), selectedTimezone)
      .toDate();

    onChange(newSelectedDate, selectedTimezone, {
      isValid: isSelectedDatetimeValid(
        validBetween,
        newSelectedDate,
        selectedTimezone,
      ),
    });
  }

  function handleTimezoneChange(name, value) {
    const workingSelection = moment.tz(selectedDatetime, selectedTimezone);

    const newSelectedDate = moment
      .tz(
        {
          year: workingSelection.year(),
          month: workingSelection.month(),
          day: workingSelection.date(),
          hour: workingSelection.hour(),
          minute: workingSelection.minute(),
        },
        value,
      )
      .toDate();

    onChange(newSelectedDate, value, {
      isValid: isSelectedDatetimeValid(
        validBetween,
        newSelectedDate,
        selectedTimezone,
      ),
    });
  }

  const isValid =
    !touchedTime ||
    isSelectedDatetimeValid(validBetween, selectedDatetime, selectedTimezone);

  const earliestValidTimeString = getEarliestValidTimeString(
    validBetween,
    selectedTimezone,
  );

  const latestValidTimeString = getLatestValidTimeString(
    validBetween,
    selectedTimezone,
  );

  return (
    <>
      <InputGroup
        style={{
          // There is a negative margin applied to every InputGroup.Column except the first,
          // so these columns are actually 3px short of where the Row ends.
          width: 'calc(100% + 3px)',
        }}
      >
        <InputGroup.Row modifiers={['middle']}>
          <InputGroup.Column modifiers={['col_6']}>
            <Dropdown fullWidth onChange={noop}>
              {({ isVisible, hide }) => (
                <>
                  <Dropdown.Target>
                    <InputField
                      name="selectedDatetime"
                      value={selectedDatetime?.format('D MMM YYYY') || ''}
                      onChange={noop}
                      placeholder={t`Select a date`}
                      isValid={!selectedDatetime || isValid}
                    >
                      <Column modifiers="col">
                        <Row>
                          <InputField.Label>
                            <Trans>Date</Trans>
                          </InputField.Label>
                        </Row>
                        <Row>
                          <InputField.TextField
                            readOnly
                            autoComplete="off"
                            style={{
                              cursor: 'pointer',
                              caretColor: 'transparent',
                            }}
                          />
                          <InputField.ActionButton
                            icon={isVisible ? 'chevron-up' : 'chevron-down'}
                            type="button"
                            onClick={noop}
                            modifiers={['hoverInfo']}
                          />
                        </Row>
                      </Column>
                    </InputField>
                  </Dropdown.Target>
                  <Dropdown.Content
                    style={{
                      minWidth: px2rem(370),
                      maxHeight: 'none',
                    }}
                  >
                    <DateSelector
                      onSelectDate={(d) => {
                        handleDateChange(d);
                        hide();
                      }}
                      selectedDate={selectedDatetime?.toDate()}
                    />
                  </Dropdown.Content>
                </>
              )}
            </Dropdown>
          </InputGroup.Column>

          <InputGroup.Column modifiers={['col_2']}>
            <TimeDropdown
              isValid={isValid}
              label={<Trans>Hour</Trans>}
              placeholder="00"
              name="hours"
              onChange={handleTimeChange}
              options={TimeDropdown.hourOptions}
              value={touchedTime ? selectedDatetime?.format('h') : ''}
              readOnly={!selectedDatetime}
            />
          </InputGroup.Column>

          <InputGroup.Column modifiers={['col_2']}>
            <TimeDropdown
              isValid={isValid}
              label={<Trans>Minutes</Trans>}
              placeholder="00"
              name="minutes"
              onChange={handleTimeChange}
              options={TimeDropdown.minuteOptions}
              value={touchedTime ? selectedDatetime?.format('mm') : ''}
              readOnly={!selectedDatetime}
            />
          </InputGroup.Column>

          <InputGroup.Column modifiers={['col_2']}>
            <InputField isValid={isValid} readOnly={!selectedDatetime}>
              <Column modifiers={['col', 'padScale_0']}>
                <Row modifiers={['center']}>
                  <Column>
                    <ButtonToggle
                      on={selectedDatetime?.format('A') === 'PM'}
                      onClick={handleDayPeriodChange}
                      disabled={!selectedDatetime}
                    >
                      <ButtonToggle.OffLabel>
                        <Trans>AM</Trans>
                      </ButtonToggle.OffLabel>
                      <ButtonToggle.OnLabel>
                        <Trans>PM</Trans>
                      </ButtonToggle.OnLabel>
                    </ButtonToggle>
                  </Column>
                </Row>
              </Column>
            </InputField>
          </InputGroup.Column>
        </InputGroup.Row>
        <InputGroup.Row style={{ marginRight: 3 }}>
          <InputGroup.Column modifiers={['col']}>
            <TimezoneDropdown
              isValid={isValid}
              label={<Trans>Time Zone</Trans>}
              name="timezone"
              onChange={handleTimezoneChange}
              value={selectedTimezone}
              readOnly={!selectedDatetime}
            />
          </InputGroup.Column>
        </InputGroup.Row>
      </InputGroup>
      <Row modifiers={['padScaleY_2']}>
        <Column>
          <MessageSmall type={isValid ? 'info' : 'warning'}>
            <Trans>
              Date and time must be between {earliestValidTimeString} and{' '}
              {latestValidTimeString}.
            </Trans>
          </MessageSmall>
        </Column>
      </Row>
    </>
  );
}

function validBetweenPropTypes(props, propName, componentName) {
  const validBetween = props[propName];

  if (
    validBetween === undefined ||
    (isArray(validBetween) &&
      (validBetween.length === 1 || validBetween.length === 2) &&
      validBetween[0] instanceof Date &&
      (validBetween[1] instanceof Date || validBetween[1] === undefined))
  ) {
    return undefined;
  }

  return new Error(
    `Invalid prop ${propName} supplied to ${componentName}. If used, ${propName} must be an array containing 1 or 2 Dates. Validation failed.`,
  );
}

DatetimeSelector.propTypes = {
  selectedDatetime: PropTypes.instanceOf(Date),
  selectedTimezone: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  validBetween: validBetweenPropTypes,
};

DatetimeSelector.defaultProps = {
  selectedDatetime: undefined,
  selectedTimezone: undefined,
  validBetween: undefined,
};

export default DatetimeSelector;

import styled from 'styled-components';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { withSize } from 'reactive-container';
import {
  applyResponsiveStyleModifiers,
  applyStyleModifiers,
  responsiveStyleModifierPropTypes,
  styleModifierPropTypes,
} from 'styled-components-modifiers';
import {
  buildThemePropTypes,
  validateTheme,
} from 'styled-components-theme-validator';

import defaultTheme from '../style/theme';

/**
 * This utility was borrowed from the source code of styled-components v3, but it works just
 * fine in v2 as well.
 * https://github.com/styled-components/styled-components/blob/master/src/utils/isStyledComponent.js
 * @param {function} target a component to test.
 */
function isStyledComponent(target) {
  return !!target && typeof target.styledComponentId === 'string';
}

/**
 * Applies the theme to styled component as a defaultProp in test env only.
 * @param  {Component} StyledComponent A styled component
 * @return {Component}                 The styled component with the expected defaultProps
 */
export const withTestTheme = (StyledComponent) => {
  /* istanbul ignore else */
  if (process.env.NODE_ENV === 'test') {
    // eslint-disable-next-line no-param-reassign
    StyledComponent.defaultProps = {
      ...StyledComponent.defaultProps,
      theme: defaultTheme,
    };
  }

  return StyledComponent;
};

/**
 * Builder function for creating a new styled component with all the fancy add-ons and
 * extensions we've developed.
 *
 * * The theme available to the styled component will be validated using the given `themePropTypes`
 * * Style modifiers will be applied based on the given `modifierConfig`
 * * If `responsive` is truthy, responsive style modifiers will also be applied
 * * A `className` will be added based on...
 *   * the specified `className`, or
 *   * the `className` value from `defaultProps`, or
 *   * the `displayName` of the component.
 * * The styled component will be enhanced with `withTestTheme`
 * * If `responsive`, the styled component will also be enhanced via `withSize`
 *
 * @param {string} displayName - The meaningful display name for the rendered component
 * @param {function|StyledComponent} builderFn - A styled-component builder function, e.g. `styled.div`, or a previously built styled component, e.g `Button`.
 * @param {string|function} styles - The template string (or a function that produces one) used
 *   to render the styles for the component. If a function, receives a single `props` argument.
 * @param {Object} options - Additional options for the builder.
 * @param {Object} options.defaultProps - default property values for the component
 * @param {Object} options.modifierConfig - modifier configuration for component variations
 * @param {Object} options.propTypes - specification of the prop types for the component
 * @param {boolean} options.responsive - if true, responsive extensions will be applied
 * @param {Object} options.themePropTypes - for validation of the theme passed to the component
 * @param {string} options.className - used to explicitly set the `className` for the component
 * @returns {Component}
 */
function buildStyledComponent(
  displayName,
  builderFn,
  styles = '',
  {
    defaultProps = {},
    modifierConfig = {},
    propTypes = {}, // eslint-disable-line react/forbid-foreign-prop-types
    responsive = false,
    themePropTypes = {},
    className = '',
  } = {},
) {
  const validationPropTypes = buildThemePropTypes(themePropTypes);

  // If the builderFn is a styled component, the developer is attempting
  // to extend a previously built styled component. These components get
  // some special treatment
  const isBuilderFnStyledComponent = isStyledComponent(builderFn);

  // A previously built styled component should be extended instead of
  // creating a completely new component.
  const finalBuilderFn = isBuilderFnStyledComponent
    ? styled(builderFn)
    : builderFn;

  // Also, if the developer is using a styled component, merge the modifiers
  // from the parent with the modifierConfig specified for this component. This
  // allows the developer to use both without throwing errors.
  const finalModifierConfig =
    isBuilderFnStyledComponent && typeof builderFn.modifiers === 'object'
      ? {
          ...builderFn.modifiers,
          ...modifierConfig,
        }
      : modifierConfig;

  const component = finalBuilderFn`
    ${validateTheme(displayName, validationPropTypes)}
    ${styles}
    ${applyStyleModifiers(finalModifierConfig)}
    ${responsive && applyResponsiveStyleModifiers(finalModifierConfig)}
  `;

  const finalPropTypes = { ...propTypes };

  if (!isEmpty(finalModifierConfig)) {
    finalPropTypes.modifiers = styleModifierPropTypes(finalModifierConfig);
  }

  if (responsive) {
    finalPropTypes.responsiveModifiers = responsiveStyleModifierPropTypes(
      finalModifierConfig,
    );
    finalPropTypes.size = PropTypes.string.isRequired;
  }

  const { className: classNameProp, ...otherDefaultProps } = defaultProps;
  component.defaultProps = {
    className: className || classNameProp || displayName,
    ...otherDefaultProps,
  };

  component.displayName = displayName;
  component.modifiers = finalModifierConfig;
  component.propTypes = finalPropTypes;

  if (responsive) {
    return withTestTheme(withSize(component));
  }

  return withTestTheme(component);
}

export default buildStyledComponent;

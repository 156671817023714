import { camelCase } from 'lodash';

import clearDay from './clear-day.svg';
import clearNight from './clear-night.svg';
import cloudy from './cloudy.svg';
import fog from './fog.svg';
import partlyCloudyDay from './partly-cloudy-day.svg';
import partlyCloudyNight from './partly-cloudy-night.svg';
import rain from './rain.svg';
import sleet from './sleet.svg';
import snow from './snow.svg';
import wind from './wind.svg';

const icons = {
  clearDay,
  clearNight,
  cloudy,
  fog,
  partlyCloudyDay,
  partlyCloudyNight,
  rain,
  sleet,
  snow,
  wind,
};

/**
 * Given a kebab-case weather state from the API, get
 * the associated SVG icon.
 */
function iconFor(name) {
  return icons[camelCase(name)];
}

export default {
  iconFor,
  ...icons,
};

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  geocode as geocodeGoogleMaps,
  reverseGeocode as reverseGeocodeGoogleMaps,
} from './geocoders/googleMapsGeocoder';

import withGoogleMapJS from './withGoogleMapJS';

export const useGeocoder = (gMaps) => {
  const geocode = useCallback(
    (address) => (!gMaps ? null : geocodeGoogleMaps(gMaps, address)),
    [gMaps],
  );

  const reverseGeocode = useCallback(
    (location) => (!gMaps ? null : reverseGeocodeGoogleMaps(gMaps, location)),
    [gMaps],
  );

  return { geocode, reverseGeocode };
};

// eslint-disable-next-line react/prop-types
const ComponentWithGeocoder = ({ Component, googleMaps, ...rest }) => {
  const geocoder = useGeocoder(googleMaps);

  return <Component {...rest} {...geocoder} googleMaps={googleMaps} />;
};

export const withGeocoder = (Component) => {
  const ComponentWithGMaps = withGoogleMapJS((props) => (
    <ComponentWithGeocoder {...props} Component={Component} />
  ));

  return (props) => <ComponentWithGMaps {...props} />;
};

withGeocoder.propTypes = { googleMaps: PropTypes.googleMapsApi };
withGeocoder.defaultProps = { googleMaps: null };

export default withGeocoder;

import gql from 'graphql-tag';

export const NAME = 'caseHeadsUpNoteQuery';

export default gql`
  query caseHeadsUpNoteQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      quickNote
    }
  }
`;

/* eslint-disable react/sort-comp */
import { Formik } from 'formik';
import { debounce, get, isEmpty, isEqual, omit, pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose, setDisplayName } from 'recompose';

import withContactActions from '../withContactActions';
import withContactTypes from '../withContactTypes';

import { contactFields } from './constants';
import ContactDetailsForm from './ContactDetailsForm';
import { getContactErrors, getContactStatus } from '../utils';

function getDefaultContactType({ contactTypes, index }) {
  if (index === 0) {
    return get(
      contactTypes.find((type) => type.default),
      'option',
      '',
    );
  }
  if (index === 1) {
    return get(
      contactTypes.find((type) => type.option === 'driver_cell'),
      'option',
      '',
    );
  }
  return '';
}

function getInitialValues(componentProps) {
  const {
    contact: {
      callbackEta = false,
      callbackRollTime = false,
      contactType = getDefaultContactType({ ...componentProps }),
      email,
      name,
      phone,
      phoneExt,
    },
  } = componentProps;

  return {
    [contactFields.callbackEta]: callbackEta,
    [contactFields.callbackRollTime]: callbackRollTime,
    [contactFields.contactType]: contactType,
    // these values use the || instead of a default value because it may be null.
    // null is a value (just a non-value), so it won't get assigned with a
    // default value.
    [contactFields.email]: email || '',
    [contactFields.name]: name || '',
    [contactFields.phone]: phone || '',
    [contactFields.phoneExt]: `${phoneExt || ''}`,
  };
}

function reinitializeForm(prevProps, nextProps, formik) {
  const { contact: prevContact } = prevProps;
  const { contact: nextContact } = nextProps;
  const { touched, values, errors } = formik?.state || {};
  const touchedFields = Object.keys(touched || {});

  // reset form if contact was deleted
  if (prevContact.createdAt && !nextContact.createdAt) {
    return formik.resetForm(getInitialValues(nextProps));
  }

  // merge every changed value except touched
  if (
    !isEqual(omit(prevContact, touchedFields), omit(nextContact, touchedFields))
  ) {
    formik.resetForm({
      ...omit(getInitialValues({ contact: nextContact }), touchedFields),
      ...pick(values, touchedFields),
    });
    formik.setTouched(touched);
    formik.setErrors(errors);
  }
}

export class ContactDetails extends Component {
  static propTypes = {
    caseId: PropTypes.string,
    contact: PropTypes.shape({
      callbackEta: PropTypes.bool,
      callbackRollTime: PropTypes.bool,
      contactType: PropTypes.string,
      email: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
      phone: PropTypes.string,
      phoneExt: PropTypes.string,
      createdAt: PropTypes.string,
    }),
    contactTypes: PropTypes.arrayOf(
      PropTypes.shape({
        default: PropTypes.bool,
        option: PropTypes.string,
        text: PropTypes.string,
      }),
    ),
    createCaseContact: PropTypes.func.isRequired,
    createCaseContactPending: PropTypes.bool.isRequired,
    deletable: PropTypes.bool.isRequired,
    deleteCaseContacts: PropTypes.func.isRequired,
    formId: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    isReadOnlyCase: PropTypes.bool.isRequired,
    onDelete: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    updateCaseContact: PropTypes.func.isRequired,
    updateCaseContactPending: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    caseId: '',
    contact: {},
    contactTypes: [],
  };

  static getDerivedStateFromProps(props) {
    return { initialValues: getInitialValues(props) };
  }

  state = { initialValues: getInitialValues(this.props) };

  formikRef = null;

  componentDidUpdate = (prevProps) => {
    reinitializeForm(prevProps, this.props, this.formikRef);
  };

  deleteContact = () => {
    const {
      contact: { id: contactId, createdAt },
      deleteCaseContacts,
      formId,
      onDelete,
    } = this.props;
    // When the contact is persisted...
    if (createdAt) {
      return deleteCaseContacts({
        variables: {
          caseContactIds: [contactId],
        },
      });
    }

    // When the contact is NOT persisted
    return onDelete(formId);
  };

  handleFormSubmit = (values) => {
    const {
      caseId,
      contact: { id, createdAt },
      createCaseContact,
      createCaseContactPending,
      updateCaseContact,
      updateCaseContactPending,
    } = this.props;
    if (createCaseContactPending || updateCaseContactPending) {
      return false;
    }

    const phoneExt = get(values, 'phoneExt.length', 0) ? values.phoneExt : null;

    // When the contact has been persisted, update it.
    if (createdAt) {
      return updateCaseContact({
        variables: { ...values, phoneExt, id },
      });
    }

    // When the contact has NOT been persisted, create it.
    return createCaseContact({ variables: { ...values, phoneExt, caseId } });
  };

  validateForm = (values) => {
    const { onValidate, formId } = this.props;
    const errors = getContactErrors(values);
    const status = getContactStatus(values);

    if (isEmpty(errors)) {
      // If there are no errors and required fields exist, trigger form submission
      // This form is considered valid and should be submitted.
      onValidate(formId, status);

      this.submitForm(values);
      return;
    }

    onValidate(formId, status);

    return errors;
  };

  // Debouncing the submit prevents saving on every key stroke when valid.
  // Ex., the user has added name + phone and is typing in the email.
  submitForm = debounce(this.handleFormSubmit, 1000);

  render() {
    const { contactTypes, deletable, caseId } = this.props;
    const { formId, isReadOnlyCase, index, contact } = this.props;

    return (
      <Formik
        ref={(ref) => {
          this.formikRef = ref;
        }}
        onSubmit={this.submitForm}
        validate={this.validateForm}
        initialValues={this.state.initialValues}
        validateOnBlur={false}
      >
        {(props) => (
          <ContactDetailsForm
            {...props}
            caseId={caseId}
            index={index}
            formId={formId}
            contactTypes={contactTypes}
            deletable={deletable}
            isReadOnlyCase={isReadOnlyCase}
            onDelete={this.deleteContact}
            contactId={contact?.id}
          />
        )}
      </Formik>
    );
  }
}

export default compose(
  setDisplayName('ContactDetails'),
  withContactTypes,
  withContactActions,
)(ContactDetails);

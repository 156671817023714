import gql from 'graphql-tag';

export const NAME = 'caseInboundProgramLocationQuery';

export default gql`
  query caseInboundProgramLocationQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      inboundProgramShipTo
      inboundProgramNumber(useCachedDataForCase: true) {
        id
        inboundProgramAccountType
        locations {
          ... on Dealer {
            id
            dealerName: name
            billTo
            shipTo
            displayAddress {
              city
              province
            }
          }
          ... on Customer {
            id
            customerName: name
            type
            city
            state
            billTo
            shipTo
          }
        }
      }
    }
  }
`;

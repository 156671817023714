/* eslint-disable camelcase */

import { get } from 'lodash';

const US_STATES = {
  Alabama: 'AL',
  Montana: 'MT',
  Alaska: 'AK',
  Nebraska: 'NE',
  Arizona: 'AZ',
  Nevada: 'NV',
  Arkansas: 'AR',
  'New Hampshire': 'NH',
  California: 'CA',
  'New Jersey': 'NJ',
  Colorado: 'CO ',
  'New Mexico': 'NM',
  Connecticut: 'CT',
  'New York': 'NY',
  Delaware: 'DE',
  'North Carolina': 'NC',
  'District of Columbia': 'DC',
  'North Dakota': 'ND',
  Florida: 'FL',
  Ohio: 'OH',
  Georgia: 'GA',
  Oklahoma: 'OK',
  Guam: 'GU',
  Oregon: 'OR',
  Hawaii: 'HI',
  Pennsylvania: 'PA',
  Idaho: 'ID',
  'Puerto Rico': 'PR',
  Illinois: 'IL',
  'Rhode Island': 'RI',
  Indiana: 'IN',
  'South Carolina': 'SC',
  Iowa: 'IA',
  'South Dakota': 'SD',
  Kansas: 'KS',
  Tennessee: 'TN',
  Kentucky: 'KY',
  Texas: 'TX',
  Louisiana: 'LA',
  Utah: 'UT',
  Maine: 'ME',
  Vermont: 'VT',
  Maryland: 'MD',
  Virginia: 'VA',
  Massachusetts: 'MA',
  'Virgin Islands': 'VI',
  Michigan: 'MI',
  Washington: 'WA',
  Minnesota: 'MN',
  'West Virginia': 'WV',
  Mississippi: 'MS',
  Wisconsin: 'WI',
  Missouri: 'MO',
  Wyoming: 'WY',
};

const CA_PROVINCES = {
  'Newfoundland and Labrador': 'NL',
  'Prince Edward Island': 'PE',
  'Nova Scotia': 'NS',
  'New Brunswick': 'NB',
  Quebec: 'QC',
  Ontario: 'ON',
  Manitoba: 'MB',
  Saskatchewan: 'SK',
  Alberta: 'AB',
  'British Columbia': 'BC',
  Yukon: 'YT',
  'Northwest Territories': 'NT',
  Nunavut: 'NU',
};

const getComponent = (type, components = [], shortName = false) => {
  const component = components.find((c) => get(c, 'types', []).includes(type));

  const value = shortName ? component?.short_name : component?.long_name;

  return value || null;
};

/**
 * In some cases Google Maps API is simply not returning the State name
 * in its short version inside the field short_name and it uses the full
 * state name instead.
 *
 * To test this, paste the following address into the Asset Location
 * field and just press ENTER: US-441, Sevierville, TN 37862, USA
 *
 * There are a few issues reported in Google's issue tracker and they
 * say it's solved, but I've tried the newest version of the API
 * today (using env var REACT_APP_GOOGLE_MAP_VERSION=3.45)
 * and the problem still happens.
 * See issue https://issuetracker.google.com/issues/37479392?pli=1
 */
const getProvince = (components) => {
  let value = getComponent('administrative_area_level_1', components, true);

  if (value && value.length > 2) {
    const country = getComponent('country', components, true);
    value = country === 'US' ? US_STATES[value] : CA_PROVINCES[value];
  }

  return value || null;
};

const getStreetAddress = (components, short = false) => {
  let streetAddress = getComponent('street_address', components, short);
  const streetNumber = getComponent('street_number', components);

  if (!streetAddress) {
    streetAddress =
      [streetNumber, getComponent('route', components, short)]
        .filter(Boolean)
        .join(' ') || null;
  }

  return streetAddress;
};

const getFormattedAddress = (result) => {
  const components = get(result, 'address_components', []);
  const formattedAddress = get(result, 'formatted_address', '');

  const streetAddress = getStreetAddress(components);
  const shortStreetAddress = getStreetAddress(components, true);

  return formattedAddress.replace(shortStreetAddress, streetAddress);
};

// Extracts the most relevant address parts of a Google maps GeocoderResult
//
// https://developers.google.com/maps/documentation/javascript/reference/geocoder#GeocoderResult
// https://developers.google.com/maps/documentation/geocoding/intro#Types
const getAddressComponents = (result) => {
  const components = get(result, 'address_components', []);

  return {
    city: getComponent('locality', components),
    country: getComponent('country', components),
    countryCode: getComponent('country', components, true),
    postalCode: getComponent('postal_code', components),
    province: getProvince(components),
    streetAddress: getStreetAddress(components),
    streetNumber: getComponent('street_number', components),
  };
};

export default (result = {}) => {
  const addressComponents = getAddressComponents(result);
  const formattedAddress = getFormattedAddress(result);

  return {
    ...addressComponents,
    formattedAddress,
  };
};

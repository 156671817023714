import { curry, get } from 'lodash';
import React from 'react';
import { Query } from '@apollo/client/react/components';
import dealerTopNavQuery from './dealerTopNavQuery';

function skipQuery(id) {
  return !id;
}

function buildQueryVariables(id) {
  return { id };
}

const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const { data, loading } = queryProps;
    const dealer = get(data, 'dealer');
    return (
      <WrappedComponent {...componentProps} dealer={dealer} loading={loading} />
    );
  },
);

function buildWrappedComponentWithQuery(WrappedComponent, componentProps) {
  const { dealerId } = componentProps;
  return (
    <Query
      query={dealerTopNavQuery}
      skip={skipQuery(dealerId)}
      variables={buildQueryVariables(dealerId)}
    >
      {buildQueryChild(WrappedComponent, componentProps)}
    </Query>
  );
}

const withDealerQuery = (WrappedComponent) => (componentProps) =>
  /* istanbul ignore next */
  buildWrappedComponentWithQuery(WrappedComponent, componentProps);

export default withDealerQuery;

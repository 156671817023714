import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { t, Trans } from '@lingui/macro';
import { compose, setDisplayName, withProps } from 'recompose';

import { px2rem } from 'decisiv-ui-utils';
import { Accordion } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import withCaseDelayedService from 'compositions/CaseRequestsPanel/withCaseDelayedService';

import ButtonLinkWithIcon from 'elements/ButtonLinkWithIcon';

import getTravelEstimate from 'utils/travelEstimate';
import withContext from 'utils/withContext';

import { CaseShortcut, CASE_SHORTCUT_PANELS } from 'features/keyShortcuts';

import withCaseDealerResponseActions from '../withCaseDealerResponseActions';
import withDealerTravelEstimate from '../withDealerTravelEstimate';
import {
  DealerResponsesValidationContext,
  hasInvalidContactPersons,
  hasInvalidPhoneNumbers,
  hasMissingETAs,
} from '../DealerResponsesValidationContext';

import CustomDealerDisplay from './CustomDealerDisplay';
import DealerDisplay from './DealerDisplay';
import ResponseTable from './ResponseTable';
import ErrorMessage from './ErrorMessage';

function isCustomDealer({ __typename: typename }) {
  return typename === 'CustomDealer';
}

function getDealerType(dealer) {
  return isCustomDealer(dealer) ? 'CUSTOM_DEALER' : 'DEALER';
}

/* eslint-disable react/prop-types */
function renderDealerDisplay(props) {
  if (isCustomDealer(props.dealerResponses.dealer)) {
    return (
      <CustomDealerDisplay
        dealer={props.dealerResponses.dealer}
        isServicingDealer={props.isServicingDealer}
        readOnly={props.isReadOnlyCase}
        responses={props.dealerResponses.responses}
        dealerIndex={props.dealerIndex}
      />
    );
  }
  return (
    <DealerDisplay
      caseNumber={props.caseNumber}
      dealer={props.dealerResponses.dealer}
      isServicingDealer={props.isServicingDealer}
      responses={props.dealerResponses.responses}
      caseStatus={props.caseStatus}
      isReadOnlyCase={props.isReadOnlyCase}
      isDispatchedStatus={props.isDispatchedStatus}
      servicingDealerTravelEstimateAtDispatch={
        props.servicingDealerTravelEstimateAtDispatch
      }
    />
  );
}
function renderResponseTable(props, style) {
  return (
    <Row style={{ paddingLeft: px2rem(43), ...style }}>
      <Column modifiers={['col', 'padScale_0']}>
        <ResponseTable
          dealer={props.dealerResponses.dealer}
          hasServicingDealer={props.hasServicingDealer}
          isDispatchedStatus={props.isDispatchedStatus}
          isServicingDealer={props.isServicingDealer}
          readOnly={!props.canEditDealerResponses}
          responses={props.dealerResponses.responses}
          servicingDealerResponseId={props.servicingDealerResponseId}
          isPermissionsBasedRole={props.isPermissionsBasedRole}
          isDelayedService={props.isDelayedService}
          caseStatus={props.caseStatus}
          dealerIndex={props.dealerIndex}
        />
      </Column>
    </Row>
  );
}
/* eslint-enable react/prop-types */

function DealerResponsesRenderer(props) {
  const { expanded, onExpandedChange, isServicingDealer } = props;
  const { isDispatchedStatus, isDealerResponsesExpandable } = props;

  const expandableMarginRight = isDealerResponsesExpandable
    ? { paddingRight: px2rem(46) }
    : {};

  if (isDispatchedStatus && !isServicingDealer) {
    return (
      <Row>
        <Column modifiers={['col', 'padScale_0']}>
          <Accordion expanded={expanded} onExpandedChange={onExpandedChange}>
            <Accordion.Head style={{ height: 'auto', alignItems: 'baseline' }}>
              <Accordion.Title
                style={{
                  textTransform: 'none',
                  width: '100%',
                  paddingRight: px2rem(12),
                }}
              >
                {renderDealerDisplay(props)}
              </Accordion.Title>
            </Accordion.Head>
            <Accordion.Body allowOverflowWhenOpen>
              {renderResponseTable(props, expandableMarginRight)}
            </Accordion.Body>
          </Accordion>
        </Column>
      </Row>
    );
  }

  return (
    <Row style={expandableMarginRight}>
      <Column modifiers={['col', 'padScale_0']}>
        {renderDealerDisplay(props)}
        {renderResponseTable(props)}
      </Column>
    </Row>
  );
}

DealerResponsesRenderer.propTypes = {
  // Used in renderDealerDisplay and renderResponseTable above
  /* eslint-disable react/no-unused-prop-types */
  dealerResponses: PropTypes.shape({
    dealer: PropTypes.shape({
      __typename: PropTypes.oneOf(['Dealer', 'CustomDealer']).isRequired,
    }).isRequired,
    responses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  }).isRequired,
  isServicingDealer: PropTypes.bool.isRequired,
  isReadOnlyCase: PropTypes.bool.isRequired,
  caseNumber: PropTypes.string.isRequired,
  caseStatus: PropTypes.string.isRequired,
  isDelayedService: PropTypes.bool.isRequired,
  hasServicingDealer: PropTypes.bool.isRequired,
  isDispatchedStatus: PropTypes.bool.isRequired,
  canEditDealerResponses: PropTypes.bool.isRequired,
  servicingDealerTravelEstimateAtDispatch: PropTypes.shape({
    distance: PropTypes.number,
    time: PropTypes.number,
  }),
  servicingDealerResponseId: PropTypes.string,
  isPermissionsBasedRole: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  isDealerResponsesExpandable: PropTypes.bool.isRequired,
  onExpandedChange: PropTypes.func.isRequired,
  /* eslint-enable react/no-unused-prop-types */
};

DealerResponsesRenderer.defaultProps = {
  servicingDealerResponseId: undefined,
  servicingDealerTravelEstimateAtDispatch: undefined,
};

function BaseRenderer(props) {
  const { caseId, isOnly, errors, isReadOnlyCase, dealerIndex } = props;
  const { dealerResponses, createDealerResponse } = props;
  const { createCustomDealer, isDispatchedStatus } = props;
  const { dealer } = dealerResponses;

  const {
    assetLocationDriveDistance,
    assetLocationDriveTime,
  } = getTravelEstimate(get(dealer, 'travelEstimate'));

  const onCreateDealerResponse = () => {
    createDealerResponse({
      caseId,
      dealerId: dealer.id,
      dealerResponseAttrs: {
        assetLocationDriveDistance,
        assetLocationDriveTime,
        phoneNumber: dealer.phone,
        responseTime: moment.tz().format(),
      },
      dealerType: getDealerType(dealer),
    });
  };

  return (
    <Container>
      <Row>
        <Column modifiers={['col', 'padScaleX_0']}>
          <DealerResponsesRenderer {...props} />

          <ErrorMessage errors={errors} />

          {isReadOnlyCase
            ? !isOnly && <Row style={{ height: px2rem(16) }} />
            : !isDispatchedStatus && (
                <Row>
                  <CaseShortcut
                    action={{
                      parent: CASE_SHORTCUT_PANELS.dealerSelection,
                      id: `createDealerCallRecord${dealerIndex}Action`,
                      name: t`Service Provider #${
                        dealerIndex + 1
                      }: Create New Call Record`,
                      shortcut: ['c', 's', `${dealerIndex + 1}`],
                      perform: () => onCreateDealerResponse(),
                    }}
                  >
                    {({ onFocusRequested }) => (
                      <Column
                        modifiers={['padScaleX_0']}
                        style={{
                          marginLeft: px2rem(42),
                          marginRight: px2rem(10),
                        }}
                        ref={onFocusRequested}
                      >
                        <ButtonLinkWithIcon
                          icon="plus"
                          onClick={onCreateDealerResponse}
                        >
                          <Trans>
                            Create New Call Record for this Service Provider
                          </Trans>
                        </ButtonLinkWithIcon>
                      </Column>
                    )}
                  </CaseShortcut>

                  {isOnly && (
                    <CaseShortcut
                      action={{
                        parent: CASE_SHORTCUT_PANELS.dealerSelection,
                        id: `createCallRecordAction`,
                        name: t`Create New Call Record for Out-of-network Service Provider`,
                        shortcut: ['c', 's', '0'],
                        perform: () => createCustomDealer({ caseId }),
                        priority: 3,
                        deps: caseId,
                      }}
                    >
                      {({ onFocusRequested }) => (
                        <Column
                          modifiers={['padScaleX_0']}
                          ref={onFocusRequested}
                        >
                          <ButtonLinkWithIcon
                            icon="plus"
                            onClick={() => createCustomDealer({ caseId })}
                          >
                            <Trans>
                              Create New Call Record for Out-of-network Service
                              Provider
                            </Trans>
                          </ButtonLinkWithIcon>
                        </Column>
                      )}
                    </CaseShortcut>
                  )}
                </Row>
              )}
        </Column>
      </Row>
    </Container>
  );
}

BaseRenderer.propTypes = {
  caseId: PropTypes.string.isRequired,
  caseNumber: PropTypes.string.isRequired,
  caseStatus: PropTypes.string.isRequired,
  createCustomDealer: PropTypes.func.isRequired,
  createDealerResponse: PropTypes.func.isRequired,
  dealerResponses: PropTypes.shape({
    dealer: PropTypes.shape({
      id: PropTypes.string,
      phone: PropTypes.string,
      __typename: PropTypes.oneOf(['Dealer', 'CustomDealer']).isRequired,
    }).isRequired,
    responses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  }).isRequired,
  hasServicingDealer: PropTypes.bool.isRequired,
  isDispatchedStatus: PropTypes.bool.isRequired,
  isOnly: PropTypes.bool.isRequired,
  isReadOnlyCase: PropTypes.bool.isRequired,
  isServicingDealer: PropTypes.bool.isRequired,
  canEditDealerResponses: PropTypes.bool.isRequired,
  isPermissionsBasedRole: PropTypes.bool.isRequired,
  servicingDealerResponseId: PropTypes.string,
  servicingDealerTravelEstimateAtDispatch: PropTypes.shape({
    distance: PropTypes.number,
    time: PropTypes.number,
  }),
  errors: PropTypes.shape({
    contactPerson: PropTypes.bool,
    phoneNumber: PropTypes.bool,
    eta: PropTypes.bool,
  }).isRequired,
  isDelayedService: PropTypes.bool.isRequired,
  // Used in withProps below
  /* eslint-disable react/no-unused-prop-types */
  validationErrors: PropTypes.objectOf(
    PropTypes.shape({
      contactPerson: PropTypes.bool,
      phoneNumber: PropTypes.bool,
      eta: PropTypes.bool,
    }),
  ).isRequired,
  dealerIndex: PropTypes.number.isRequired,
  isDealerResponsesExpandable: PropTypes.bool,
  expanded: PropTypes.bool,
  onExpandedChange: PropTypes.func.isRequired,
  /* eslint-enable react/no-unused-prop-types */
};

BaseRenderer.defaultProps = {
  servicingDealerResponseId: undefined,
  servicingDealerTravelEstimateAtDispatch: undefined,
  isDealerResponsesExpandable: false,
  expanded: false,
};

export default compose(
  setDisplayName('DealerResponses'),
  withCaseDealerResponseActions,
  withDealerTravelEstimate,
  withCaseDelayedService,
  withContext(DealerResponsesValidationContext),
  withProps(({ dealerResponses: { responses }, validationErrors }) => ({
    errors: {
      contactPerson: hasInvalidContactPersons(responses, validationErrors),
      phoneNumber: hasInvalidPhoneNumbers(responses, validationErrors),
      eta: hasMissingETAs(responses, validationErrors),
    },
  })),
)(BaseRenderer);

import { Trans } from '@lingui/macro';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { DataTable, H3, H4, Icon, Text } from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Container, Row, Column } from 'styled-components-grid';

import Tag from 'blocks/Tag';

import { getShouldShowSuppliedTab } from 'compositions/CaseRequestsPanel/CaseRequestsPanelContext/utils';
import assetTypeNames from 'compositions/CaseRequestsPanel/RequestAssetsForm/RequestAsset/assetTypeNames';

import columns from './columns';

function AssetDetails({ asset }) {
  if (isEmpty(asset)) {
    return (
      <Row>
        <Column modifiers={['padScaleY_0']}>
          <Text>&mdash;</Text>
        </Column>
      </Row>
    );
  }

  return (
    <>
      <Row>
        <Column modifiers={['padScaleY_0']}>
          <Text>
            {asset.unitNumber && `${asset.unitNumber}`}
            {asset.unitNumber && asset.assetType && <> &middot; </>}
            {asset.assetType && <Trans id={assetTypeNames[asset.assetType]} />}
          </Text>
        </Column>
      </Row>
      {asset.droppedUnit && (
        <Row>
          <Column>
            <Tag>
              <Tag.Text>
                <Trans>Dropped Unit</Trans>
              </Tag.Text>
            </Tag>
          </Column>
        </Row>
      )}
    </>
  );
}

AssetDetails.propTypes = {
  asset: PropTypes.shape({
    assetType: PropTypes.oneOf(Object.keys(assetTypeNames)),
    droppedUnit: PropTypes.bool,
    unitNumber: PropTypes.string,
  }).isRequired,
};

function Request({ caseData }) {
  const primaryAsset = get(caseData, 'primaryAsset') || {};
  const relatedAssets = get(caseData, 'relatedAssets') || [];
  const specialInstructions = get(caseData, 'specialInstructions');

  const requestLines = get(caseData, 'requestLines') || [];
  const agreementLines = (
    get(caseData, 'requestAgreementLines') || []
  ).map(({ agreed, agreementLine }) => ({ agreed, ...agreementLine }));
  const suppliedLines = (
    get(caseData, 'suppliedLines') || []
  ).map(({ supplied, suppliedLine }) => ({ supplied, ...suppliedLine }));

  return (
    <Container modifiers={['padScale_2']}>
      <Row>
        <Column modifiers={['col_2', 'padScaleY_2']}>
          <H3 modifiers={['fontWeightRegular']}>
            <Trans>Request</Trans>
          </H3>
        </Column>
        <Column modifiers={['col']}>
          <Row>
            <Column modifiers={['padScaleY_0']}>
              <Text modifiers={['small', 'textLight']}>
                <Trans>Asset</Trans>
              </Text>
            </Column>
          </Row>
          <AssetDetails asset={primaryAsset} />
        </Column>
        <Column modifiers={['col']}>
          <Row>
            <Column modifiers={['padScaleY_0']}>
              <Text modifiers={['small', 'textLight']}>
                <Trans>Related Asset</Trans>
              </Text>
            </Column>
          </Row>
          <AssetDetails asset={get(relatedAssets, '0', {})} />
        </Column>
        <Column modifiers={['col']}>
          <Row>
            <Column modifiers={['padScaleY_0']}>
              <Text modifiers={['small', 'textLight']}>
                <Trans>Related Asset</Trans>
              </Text>
            </Column>
          </Row>
          <AssetDetails asset={get(relatedAssets, '1', {})} />
        </Column>
        <Column modifiers={['col_4']}>
          <Row>
            <Column modifiers={['padScaleY_0']}>
              <Text modifiers={['small', 'textLight']}>
                <Trans>Special Instructions</Trans>
              </Text>
            </Column>
          </Row>
          <Row>
            <Column style={{ maxWidth: '100%' }} modifiers={['padScaleY_0']}>
              {specialInstructions ? (
                <Text>{specialInstructions}</Text>
              ) : (
                <Text>&mdash;</Text>
              )}
            </Column>
          </Row>
        </Column>
      </Row>
      <Row>
        <Column modifiers={['padScaleY_2']}>
          <H4 modifiers={['fontWeightRegular']}>
            <Trans>Requested</Trans>
          </H4>
        </Column>
      </Row>
      <Row>
        <Column modifiers={['col', 'padScaleY_2']}>
          <DataTable
            columns={[
              ...columns,
              {
                name: 'empty',
                cellKeyGetter: ({ id }) => `${id}:emptyCell`,
                cellStyles: { minWidth: px2rem(40) },
                cellDataGetter: () => {},
                headerCellRenderer: () => (
                  <Row>
                    <Column modifiers={['padScaleX_2']} />
                  </Row>
                ),
                dataCellRenderer: () => (
                  <Row>
                    <Column modifiers={['padScaleX_2']} />
                  </Row>
                ),
              },
            ]}
            modifiers=""
            rowModifiersGetter={() => ['noHighlightOnHover']}
            rowsRequireRowIndex={false}
            scrollX
            tableData={
              isEmpty(requestLines) ? [{ id: 'no-table-data' }] : requestLines
            }
          />
        </Column>
      </Row>
      {caseData.status !== 'NEW' && (
        <>
          <Row>
            <Column modifiers={['padScaleY_2']}>
              <H4 modifiers={['fontWeightRegular']}>
                <Trans>Agreed</Trans>
              </H4>
            </Column>
          </Row>
          <Row>
            <Column modifiers={['col', 'padScaleY_2']}>
              <DataTable
                columns={[
                  ...columns,
                  {
                    name: 'agreed',
                    cellKeyGetter: ({ id }) => `${id}:agreed`,
                    cellStyles: { minWidth: px2rem(40) },
                    cellDataGetter: ({ agreed }) => ({ agreed }),
                    headerCellRenderer: () => (
                      <Row>
                        <Column modifiers={['center', 'col', 'padScaleX_2']}>
                          <Text modifiers={['small', 'textLight']}>
                            <Trans>Agreed</Trans>
                          </Text>{' '}
                        </Column>
                      </Row>
                    ),
                    dataCellRenderer: (
                      { agreed }, // eslint-disable-line react/prop-types
                    ) => (
                      <Row>
                        <Column modifiers={['center', 'col', 'padScaleX_2']}>
                          {agreed && (
                            <Icon
                              name="check"
                              modifiers={['small', 'textLight']}
                            />
                          )}
                        </Column>
                      </Row>
                    ),
                  },
                ]}
                modifiers=""
                rowModifiersGetter={() => ['noHighlightOnHover']}
                rowsRequireRowIndex={false}
                scrollX
                tableData={agreementLines}
              />
            </Column>
          </Row>
        </>
      )}
      {getShouldShowSuppliedTab(caseData) && (
        <>
          <Row>
            <Column modifiers={['padScaleY_2']}>
              <H4 modifiers={['fontWeightRegular']}>
                <Trans>Supplied</Trans>
              </H4>
            </Column>
          </Row>
          <Row>
            <Column modifiers={['col', 'padScaleY_2']}>
              <DataTable
                columns={[
                  ...columns,
                  {
                    name: 'supplied',
                    cellKeyGetter: ({ id }) => `${id}:supplied`,
                    cellStyles: { minWidth: px2rem(40) },
                    cellDataGetter: ({ supplied }) => ({ supplied }),
                    headerCellRenderer: () => (
                      <Row>
                        <Column modifiers={['center', 'col', 'padScaleX_2']}>
                          <Text modifiers={['small', 'textLight']}>
                            <Trans>Supplied</Trans>
                          </Text>
                        </Column>
                      </Row>
                    ),
                    dataCellRenderer: (
                      { supplied }, // eslint-disable-line react/prop-types
                    ) => (
                      <Row modifiers={['middle', 'center']}>
                        <Column modifiers={['center', 'col', 'padScaleX_2']}>
                          {supplied && (
                            <Icon
                              name="check"
                              modifiers={['small', 'textLight']}
                            />
                          )}
                        </Column>
                      </Row>
                    ),
                  },
                ]}
                modifiers=""
                rowModifiersGetter={() => ['noHighlightOnHover']}
                rowsRequireRowIndex={false}
                scrollX
                tableData={suppliedLines}
              />
            </Column>
          </Row>
        </>
      )}
    </Container>
  );
}

Request.propTypes = {
  caseData: PropTypes.shape({
    status: PropTypes.string,
    agreementLines: PropTypes.arrayOf(
      PropTypes.shape({
        agreed: PropTypes.bool,
        agreementLine: PropTypes.shape({}),
      }),
    ),
    primaryAsset: PropTypes.shape({
      assetType: PropTypes.oneOf(Object.keys(assetTypeNames)),
      droppedUnit: PropTypes.bool,
      unitNumber: PropTypes.string,
    }),
    relatedAssets: PropTypes.arrayOf(
      PropTypes.shape({
        assetType: PropTypes.oneOf(Object.keys(assetTypeNames)),
        droppedUnit: PropTypes.bool,
        unitNumber: PropTypes.string,
      }),
    ),
    requestLines: PropTypes.arrayOf(PropTypes.shape({})),
    specialInstructions: PropTypes.string,
    suppliedLines: PropTypes.arrayOf(
      PropTypes.shape({
        supplied: PropTypes.bool,
        suppliedLine: PropTypes.shape({}),
      }),
    ),
  }).isRequired,
};

export default Request;

/* istanbul ignore file */
import React from 'react';
import PropTypes from 'prop-types';

import { Text, Icon } from 'base-components';
import { buildStyledComponent } from 'decisiv-ui-utils';
import {
  Row,
  Column,
  Container as BaseContainer,
} from 'styled-components-grid';

import { SORT_DIRECTIONS } from 'compositions/CasesSearch/constants';

const StyledContainer = buildStyledComponent(
  'StyledContainer',
  BaseContainer,
  ({ theme }) => `
    height: 100%;
    display: flex;

    &:hover { background: ${theme.colors.base.chrome200}; }
  `,
  {
    themePropTypes: {
      colors: PropTypes.shape({
        base: PropTypes.shape({ chrome200: PropTypes.string.isRequired })
          .isRequired,
      }).isRequired,
    },
  },
);

export default function HeaderCell({ label, sortable, sortDirection }) {
  const Container = sortable ? StyledContainer : BaseContainer;
  const labelModifiers = [
    'small',
    sortDirection ? 'fontWeightMedium' : 'textLight',
  ];

  return (
    <Container>
      <Row modifiers="middle" style={{ flexWrap: 'nowrap' }}>
        <Column style={{ flexShrink: 1 }}>
          <Text style={{ wordBreak: 'break-word' }} modifiers={labelModifiers}>
            {label}
          </Text>
        </Column>
        {sortable && (
          <Column modifiers="padScaleX_0">
            <Icon
              name={`sort${sortDirection ? `-${sortDirection}` : ''}`}
              modifiers={['mini', !sortDirection && 'disabled']}
            />
          </Column>
        )}
      </Row>
    </Container>
  );
}

HeaderCell.propTypes = {
  label: PropTypes.node.isRequired,
  sortable: PropTypes.bool,
  sortDirection: PropTypes.oneOf(Object.values(SORT_DIRECTIONS)),
};

HeaderCell.defaultProps = {
  sortable: false,
  sortDirection: null,
};

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

export const modifierConfig = {
  highlight: () => `
    text-decoration: underline;
  `,

  flyout: () => `
    padding-left: ${px2rem(10)};
  `,
};

const styles = ({ theme }) => `
  align-items: center;
  color: ${theme.colors.base.textLight};
  display: flex;
  flex: 1;
  font-size: ${px2rem(12)};
  height: ${theme.dimensions.leftNavWidthCollapsed};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      textLight: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dimensions: PropTypes.shape({
    leftNavWidthCollapsed: PropTypes.string.isRequired,
  }).isRequired,
};

export default buildStyledComponent('MenuItemTitleText', styled.div, styles, {
  modifierConfig,
  themePropTypes,
});

import { curry, find, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import CaseInboundProgramPanelContext from '../CaseInboundProgramPanelContext';

class WithInboundProgramLocationFromCall extends Component {
  static propTypes = {
    callContactAttributes: PropTypes.shape({
      shipTo: PropTypes.string,
    }).isRequired,
    caseId: PropTypes.string,
    children: PropTypes.node.isRequired,
    displayInboundProgramLocationSelector: PropTypes.bool.isRequired,
    inboundProgramShipTo: PropTypes.string,
    inboundProgramLocations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        shipTo: PropTypes.string,
      }),
    ).isRequired,
    isLoadingInboundProgramLocations: PropTypes.bool.isRequired,
    isNewCase: PropTypes.bool.isRequired,
    onSelectInboundProgramLocation: PropTypes.func.isRequired,
  };

  static defaultProps = {
    caseId: undefined,
    inboundProgramShipTo: undefined,
  };

  state = { inboundProgramLocationSaved: false };

  componentDidMount() {
    this.setInboundProgramFromCall();
  }

  componentDidUpdate() {
    this.setInboundProgramFromCall();
  }

  /**
   * If the conditions are juuuust right, automatically saves the
   * inbound program location to the case.
   *
   * @memberof WithInboundProgramLocationFromCall
   */
  setInboundProgramFromCall = () => {
    if (
      this.shouldAutoSelectInboundProgramLocation() &&
      this.canFindInboundProgramLocation()
    ) {
      const inboundProgramLocation = this.findInboundProgramLocationFromCallContactAttributes();
      this.setState({ inboundProgramLocationSaved: true }, () => {
        if (inboundProgramLocation) {
          this.props.onSelectInboundProgramLocation(
            inboundProgramLocation.shipTo,
          );
        }
      });
    }
  };

  /**
   * Matches the shipTo from the call up to an inbound program location's shipTo
   *
   * @returns {object} an inbound program if a match is found.
   * @memberof WithInboundProgramLocationFromCall
   */
  findInboundProgramLocationFromCallContactAttributes = () =>
    find(
      this.props.inboundProgramLocations,
      ({ shipTo }) => this.props.callContactAttributes.shipTo === shipTo,
    );

  /**
   * Evaluates props to determine if an inbound program location should be saved.
   * Specific conditions include:
   * - The case must have just been created
   * - The case must be loaded
   * - The case must require an inbound program location
   * - No previously existing inbound program location information
   * - The "save" action has not been previously called by this class.
   * - The agent must have been on the phone with the customer when creating the case
   *
   * @memberof WithInboundProgramLocationFromCall
   */
  shouldAutoSelectInboundProgramLocation = () =>
    this.props.isNewCase &&
    this.props.caseId &&
    this.props.displayInboundProgramLocationSelector &&
    !this.props.inboundProgramShipTo &&
    !this.state.inboundProgramLocationSaved &&
    !isEmpty(this.props.callContactAttributes);

  /**
   * Evaluates props to determine if all of the data needed to find a
   * matching inbound program location is present.
   *
   * @memberof WithInboundProgramLocationFromCall
   */
  canFindInboundProgramLocation = () =>
    !this.props.isLoadingInboundProgramLocations &&
    this.props.callContactAttributes.shipTo;

  render() {
    return this.props.children;
  }
}

const buildConsumerChild = curry(
  (WrappedComponent, componentProps, consumerProps) => {
    const {
      caseId,
      callContactAttributes,
      inboundProgramShipTo,
      isNewCase,
    } = consumerProps;
    const {
      displayInboundProgramLocationSelector,
      inboundProgramLocations,
      isLoadingInboundProgramLocations,
      onSelectInboundProgramLocation,
    } = componentProps;

    return (
      <WithInboundProgramLocationFromCall
        caseId={caseId}
        callContactAttributes={callContactAttributes}
        inboundProgramShipTo={inboundProgramShipTo}
        inboundProgramLocations={inboundProgramLocations}
        displayInboundProgramLocationSelector={
          displayInboundProgramLocationSelector
        }
        isLoadingInboundProgramLocations={isLoadingInboundProgramLocations}
        isNewCase={isNewCase}
        onSelectInboundProgramLocation={onSelectInboundProgramLocation}
      >
        <WrappedComponent {...componentProps} />
      </WithInboundProgramLocationFromCall>
    );
  },
);

const withInboundProgramLocationFromCall = (WrappedComponent) => (
  componentProps,
) => (
  <CaseInboundProgramPanelContext.Consumer>
    {buildConsumerChild(WrappedComponent, componentProps)}
  </CaseInboundProgramPanelContext.Consumer>
);

export default withInboundProgramLocationFromCall;

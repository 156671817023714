import gql from 'graphql-tag';

export const NAME = 'dealerQuery';

export default gql`
  query dealerQuery($dealerId: ID!) {
    dealer(id: $dealerId) {
      id
      name
      timezone
    }
  }
`;

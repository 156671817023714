import { buildStyledComponent } from 'decisiv-ui-utils';

import Ul from 'elements/Ul';

const styles = `
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export default buildStyledComponent('ScrollableContainer__List', Ul, styles);

import React from 'react';

import CaseStatusContext from './CaseStatusContext';

const withReadOnlyCase = (WrappedComponent) => (componentProps) => (
  <CaseStatusContext.Consumer>
    {({ isReadOnlyCase }) => (
      <WrappedComponent {...componentProps} isReadOnlyCase={isReadOnlyCase} />
    )}
  </CaseStatusContext.Consumer>
);

export default withReadOnlyCase;

import React from 'react';
import PropTypes from 'prop-types';
import { compact } from 'lodash';

import { Avatar, Text } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import { CallButtonLink } from 'compositions/ClickToCall';

function ContactItem({ isCompany, dealer, info }) {
  const { name, jobTitle, primaryPhoneNumber, secondaryPhoneNumber } = info;
  return (
    <Container className="Contact" modifiers={['padScaleX_0']}>
      <Row modifiers={['middle']}>
        <Column modifiers={['padScaleY_0']}>
          <Avatar
            isCompany={isCompany}
            modifiers={compact(['small', !name && 'secondary'])}
          />
        </Column>
        <Column modifiers={['col', 'padScale_0']}>
          {name ? (
            <Container modifiers={['padScaleX_2', 'padScaleY_0']}>
              <Row>
                <Column modifiers={['padScale_0']}>
                  <Text>{name}</Text>
                </Column>
                {jobTitle && (
                  <Column modifiers={['padScaleY_0']}>
                    <Text modifiers={['fontWeightLight', 'textLight']}>
                      ({jobTitle})
                    </Text>
                  </Column>
                )}
              </Row>
              <Row>
                {primaryPhoneNumber && (
                  <Column modifiers={['padScaleY_0', 'padScaleX_0']}>
                    <CallButtonLink
                      dealer={dealer}
                      dealerContact={info}
                      modifiers={['small']}
                      name={name}
                      phone={primaryPhoneNumber}
                    />
                  </Column>
                )}
                {primaryPhoneNumber && secondaryPhoneNumber && (
                  <>&nbsp;&middot;&nbsp;</>
                )}
                {secondaryPhoneNumber && (
                  <Column modifiers={['padScaleY_0', 'padScaleX_0']}>
                    <CallButtonLink
                      dealer={dealer}
                      dealerContact={info}
                      modifiers={['small']}
                      name={name}
                      phone={secondaryPhoneNumber}
                    />
                  </Column>
                )}
              </Row>
            </Container>
          ) : (
            <Text>&nbsp;&mdash;</Text>
          )}
        </Column>
      </Row>
    </Container>
  );
}

ContactItem.propTypes = {
  isCompany: PropTypes.bool.isRequired,
  info: PropTypes.shape({
    name: PropTypes.string,
    jobTitle: PropTypes.string,
    primaryPhoneNumber: PropTypes.string,
    secondaryPhoneNumber: PropTypes.string,
  }).isRequired,
  dealer: PropTypes.shape({}).isRequired,
};

export default ContactItem;

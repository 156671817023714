import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Column } from 'styled-components-grid';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const styles = ({ theme }) => `
  background-color: ${theme.colors.base.chrome100};
  width: ${px2rem(330)};
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome100: PropTypes.string.isRequired,
    }),
  }),
};

export default buildStyledComponent('StatusPanel', styled(Column), styles, {
  themePropTypes,
});

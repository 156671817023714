import { css } from 'styled-components';

import { Text } from 'base-components';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

export default buildStyledComponent(
  'DealerName',
  Text,
  css`
    font-size: ${px2rem(14)};
  `,
);

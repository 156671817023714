import gql from 'graphql-tag';
import { get } from 'lodash';
import React from 'react';
import { Query } from '@apollo/client/react/components';

const query = gql`
  query compositions_CaseDealerSelectionPanel_etas {
    etas {
      etas {
        id
        maxMinutes
        minMinutes
      }
    }
  }
`;

const withEtas = (WrappedComponent) => (componentProps) => (
  <Query query={query}>
    {({ data }) => {
      /** @type {Etas} */
      const etas = get(data, 'etas.etas', []);

      return <WrappedComponent {...componentProps} etas={etas} />;
    }}
  </Query>
);

export default withEtas;

import React from 'react';
import { get } from 'lodash';
import { Query } from '@apollo/client/react/components';

import query from './caseMismatchesQuery';
import { mismatchTypes } from './constants';

const types = Object.values(mismatchTypes);

function withCaseMismatches(WrappedComponent) {
  return (p) => {
    const { caseNumber } = p;

    return (
      <Query skip={!caseNumber} query={query} variables={{ caseNumber }}>
        {({ data, loading }) => {
          const highlights = loading ? [] : get(data, 'case.highlights') || [];
          const mismatches = highlights.filter((i) => types.includes(i.type));

          return <WrappedComponent {...p} mismatches={mismatches} />;
        }}
      </Query>
    );
  };
}

export default withCaseMismatches;

import { Component } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

// TODO: This should be consolidated with FormattedRelative in domain-utils

class RelativeNow extends Component {
  static propTypes = {
    interval: PropTypes.number,
    render: PropTypes.func.isRequired,
    timezone: PropTypes.string.isRequired,
  };

  static defaultProps = {
    interval: 1000,
  };

  state = {
    relativeNow: moment.tz(this.props.timezone),
  };

  componentDidMount() {
    this.timer = setInterval(this.setTimer, this.props.interval);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  setTimer = () => {
    const relativeNow = moment.tz(this.props.timezone);
    this.setState({ relativeNow });
  };

  render() {
    const { relativeNow } = this.state;
    return this.props.render({ relativeNow });
  }
}

export default RelativeNow;

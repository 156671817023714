import React from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { get, isEmpty } from 'lodash';
import { compose, setDisplayName, withState, mapProps } from 'recompose';

import { H3, ButtonLink } from 'base-components';
import { Column, Row } from 'styled-components-grid';

import withContext from 'utils/withContext';

import Panel from 'blocks/Panel';
import ButtonLinkWithIcon from 'elements/ButtonLinkWithIcon';
import { CaseStatusContext, CASE_STATUS } from 'compositions/CaseStatus';
import { CaseShortcut, CASE_SHORTCUT_PANELS } from 'features/keyShortcuts';

import DealerResponses from './DealerResponses';
import EmptyState from './EmptyState';
import withCaseDealerResponses from './withCaseDealerResponses';
import withCustomDealerActions from './withCustomDealerActions';
import DealerResponsesValidationContext from './DealerResponsesValidationContext';

const ignoredStatuses = [CASE_STATUS.new];

const dispatchedStatuses = [
  CASE_STATUS.dispatched,
  CASE_STATUS.enRoute,
  CASE_STATUS.arrived,
  CASE_STATUS.rolling,
];

const closedStatuses = [CASE_STATUS.closed, CASE_STATUS.closed_canceled];

const areAllExpanded = (state) => Object.values(state).every(Boolean);

const toggleExpanded = (state) => {
  const newState = !areAllExpanded(state);

  return Object.keys(state).reduce((acc, k) => ({ ...acc, [k]: newState }), {});
};

export function CaseDealerSelectionPanel({
  caseDealerResponses: responses,
  caseId,
  caseNumber,
  status,
  createCustomDealer,
  isReadOnlyCase: isReadOnly,
  servicingDealer,
  caseAssignedToCurrentUser,
  permissions,
  isPermissionsBasedRole,
  expandedDealerResponses,
  onExpandedDealerResponsesChanged,
  ...rest
}) {
  if (ignoredStatuses.includes(status)) return null;

  const isClosed = closedStatuses.includes(status);
  const isDispatched = dispatchedStatuses.includes(status);
  const isExpandable = isDispatched && !isEmpty(expandedDealerResponses);
  const hasResponses = !isEmpty(responses);
  const showAddCustom = responses.length > 1 && !isReadOnly && !isDispatched;

  const canEditDealerResponses =
    !isReadOnly ||
    (!isClosed &&
      caseAssignedToCurrentUser &&
      isPermissionsBasedRole &&
      status === CASE_STATUS.rolling &&
      permissions.includes('case:update_dealer'));

  return (
    <Row modifiers="padScale_1">
      <Column modifiers="col">
        <Panel modifiers="padScaleX_3">
          <Row>
            <CaseShortcut
              action={{
                parent: CASE_SHORTCUT_PANELS.dealerSelection,
                id: 'goToDealerSelectionAction',
                name: t`Go to Service Provider Selection`,
                shortcut: ['s', 's', '0'],
                priority: 4,
                icon: 'arrow-right',
              }}
              passRef
            >
              <Column modifiers={['col', 'padScaleY_2']}>
                <H3 modifiers="fontWeightRegular">
                  <Trans>Service Provider Selection</Trans>
                </H3>
              </Column>
            </CaseShortcut>
            {isExpandable && (
              <Column modifiers="padScaleY_2">
                <ButtonLink
                  modifiers="small"
                  onClick={() =>
                    onExpandedDealerResponsesChanged(
                      toggleExpanded(expandedDealerResponses),
                    )
                  }
                >
                  {areAllExpanded(expandedDealerResponses) ? (
                    <Trans>Hide All</Trans>
                  ) : (
                    <Trans>Show All</Trans>
                  )}
                </ButtonLink>
              </Column>
            )}
          </Row>
          <DealerResponsesValidationContext
            key={caseNumber}
            caseNumber={caseNumber}
            servicingDealer={servicingDealer}
            caseDealerResponses={responses}
          >
            {!hasResponses && (
              <CaseShortcut
                action={{
                  parent: CASE_SHORTCUT_PANELS.dealerSelection,
                  id: `createCallRecordAction`,
                  name: t`Create New Call Record`,
                  shortcut: ['c', 's', '0'],
                  perform: () => !isReadOnly && createCustomDealer({ caseId }),
                  priority: 3,
                  deps: `${caseId}${isReadOnly && 'readonly'}`,
                }}
                passRef="onFocusRequested"
              >
                <EmptyState
                  caseId={caseId}
                  readOnly={isReadOnly}
                  createCustomDealer={createCustomDealer}
                />
              </CaseShortcut>
            )}
            {hasResponses &&
              responses.map((dealerResponses, index) => (
                <DealerResponses
                  key={dealerResponses.id}
                  caseId={caseId}
                  caseNumber={caseNumber}
                  createCustomDealer={createCustomDealer}
                  dealerResponses={dealerResponses}
                  hasServicingDealer={!isEmpty(servicingDealer)}
                  isServicingDealer={dealerResponses.isServicingDealer}
                  servicingDealerResponseId={get(servicingDealer, 'id')}
                  isOnly={responses.length === 1}
                  isDispatchedStatus={isDispatched}
                  caseStatus={status}
                  isReadOnlyCase={isReadOnly}
                  canEditDealerResponses={canEditDealerResponses}
                  isPermissionsBasedRole={isPermissionsBasedRole}
                  dealerIndex={index}
                  isDealerResponsesExpandable={isExpandable}
                  expanded={expandedDealerResponses[dealerResponses.id]}
                  onExpandedChange={({ expanded }) =>
                    onExpandedDealerResponsesChanged({
                      ...expandedDealerResponses,
                      [dealerResponses.id]: expanded,
                    })
                  }
                  {...rest}
                />
              ))}
          </DealerResponsesValidationContext>

          {showAddCustom && (
            <Row>
              <CaseShortcut
                action={{
                  parent: CASE_SHORTCUT_PANELS.dealerSelection,
                  id: `createCallRecordAction`,
                  name: t`Create New Call Record for Out-of-network Service Provider`,
                  shortcut: ['c', 's', '0'],
                  perform: () => createCustomDealer({ caseId }),
                  priority: 3,
                  deps: caseId,
                }}
                passRef
              >
                <Column modifiers="padScaleY_2">
                  <ButtonLinkWithIcon
                    icon="plus"
                    onClick={() => createCustomDealer({ caseId })}
                  >
                    <Trans>
                      Create New Call Record for Out-of-network Service Provider
                    </Trans>
                  </ButtonLinkWithIcon>
                </Column>
              </CaseShortcut>
            </Row>
          )}
        </Panel>
      </Column>
    </Row>
  );
}

CaseDealerSelectionPanel.propTypes = {
  caseDealerResponses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  caseId: PropTypes.string,
  status: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  caseNumber: PropTypes.string.isRequired,
  createCustomDealer: PropTypes.func.isRequired,
  isReadOnlyCase: PropTypes.bool.isRequired,
  servicingDealer: PropTypes.shape({ id: PropTypes.string }),
  servicingDealerTravelEstimateAtDispatch: PropTypes.shape({
    distance: PropTypes.number,
    time: PropTypes.number,
  }),
  caseAssignedToCurrentUser: PropTypes.bool.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string),
  isPermissionsBasedRole: PropTypes.bool.isRequired,
  expandedDealerResponses: PropTypes.shape().isRequired,
  onExpandedDealerResponsesChanged: PropTypes.func.isRequired,
};

CaseDealerSelectionPanel.defaultProps = {
  caseId: undefined,
  status: CASE_STATUS.new,
  servicingDealer: null,
  servicingDealerTravelEstimateAtDispatch: undefined,
  permissions: [],
};

export default compose(
  setDisplayName('CaseDealerSelectionPanel'),
  withContext(CaseStatusContext),
  withCaseDealerResponses,
  withCustomDealerActions,
  mapProps(({ caseDealerResponses, servicingDealer, ...rest }) => ({
    ...rest,
    servicingDealer,
    caseDealerResponses: caseDealerResponses.map((rs) => {
      const dealerId = rs?.dealer?.id;
      const responseId = `${dealerId}:${get(rs, 'responses.0.id')}`;
      const isServicingDealer = servicingDealer?.dealer?.id === dealerId;

      return { ...rs, id: responseId, isServicingDealer };
    }),
  })),
  withState(
    'expandedDealerResponses',
    'onExpandedDealerResponsesChanged',
    ({ caseDealerResponses }) =>
      caseDealerResponses.reduce(
        (acc, r) => (r.isServicingDealer ? acc : { ...acc, [r.id]: false }),
        {},
      ),
  ),
)(CaseDealerSelectionPanel);

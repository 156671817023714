/* eslint-disable react/prop-types */
import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';

import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';
import { A, H2, P, QuickActionButton, Text } from 'base-components';

import Modal from 'components/Modal';

const maxVisibleChars = 300;

const formatFallbackErsComments = (value) => {
  let newValue = (value || '')
    .split('    ') // replace 4 spaces with line-break
    .join('\n')
    .split('\n')
    .filter((x) => x.trim()) // filter out empty lines
    .map((x) => `<p>${x.trim()}</p>`) // make sure each paragraph is wrapped in <p>
    .join('');
  return newValue;
};

export const valuesConfig = {
  driverAuthorized: {
    label: t`Driver Authorized`,

    mapValue: (value) => {
      return value !== undefined ? (value ? t`Yes` : t`No`) : value;
    },
  },

  ersComments: {
    label: t`ERS Instructions`,
    mapModalValue: (values) =>
      values.formattedErsComments ||
      formatFallbackErsComments(values.ersComments),
    modalLinkLabel: t`Show Full Instructions`,
    alwaysShowModalLink: true,
  },

  tireDisposition: { label: t`Disposition of Off Tires` },
};

function renderModal({ config, modalValue, toggleModal }) {
  return (
    <Modal onClose={toggleModal}>
      {() => (
        <Modal.Body>
          <Modal.Header>
            <Modal.HeaderIcon name="info-circle" />
          </Modal.Header>
          <Modal.Content style={{ padding: `${px2rem(20)} 0 ${px2rem(4)} 0` }}>
            <Row modifiers={['center', 'padScaleY_5']}>
              <Column modifiers="padScaleY_0">
                <H2 modifiers="fontWeightRegular">
                  <Trans id={config.label} />
                </H2>
              </Column>
            </Row>
            <Row
              modifiers="padScaleX_5"
              style={{ overflowY: 'auto', maxHeight: px2rem(350) }}
            >
              <Column modifiers={['col', 'padScaleX_5']}>
                <P dangerouslySetInnerHTML={{ __html: modalValue }} />
              </Column>
            </Row>
          </Modal.Content>
          <Modal.Footer>
            <Row modifiers="end">
              <Column>
                <QuickActionButton
                  type="button"
                  onClick={toggleModal}
                  modifiers="secondary"
                >
                  <QuickActionButton.Text>
                    <Trans>Close</Trans>
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
            </Row>
          </Modal.Footer>
        </Modal.Body>
      )}
    </Modal>
  );
}

export default function ServicePreferenceValue({ type, values }) {
  const [showModal, toggleModal] = useReducer((s) => !s, false);

  const config = valuesConfig[type] ?? {};

  const {
    mapValue = (v) => v,
    mapModalValue,
    modalLinkLabel = t`Read More`,
    alwaysShowModalLink,
  } = config;

  const value = mapValue(String(values[type] || '').trim());
  const modalValue = mapModalValue ? mapModalValue(values, value) : value;

  const isOverCharLimit = value.length > maxVisibleChars;

  const displayValue = isOverCharLimit
    ? `${value.slice(0, maxVisibleChars)}... `
    : value;

  const showLink = isOverCharLimit || alwaysShowModalLink;

  if (!displayValue) {
    return (
      <Text modifiers="textLight">
        <span>&mdash;</span>
      </Text>
    );
  }

  return (
    <>
      <Text modifiers="textLight">
        <Trans id={displayValue} />
      </Text>
      {showLink && (
        <>
          <br />
          <A aria-label={modalLinkLabel} onClick={toggleModal}>
            <Trans>{modalLinkLabel}</Trans>
          </A>
          {showModal && renderModal({ config, toggleModal, modalValue })}
        </>
      )}
    </>
  );
}

ServicePreferenceValue.propTypes = {
  type: PropTypes.string.isRequired,
  values: PropTypes.shape({}).isRequired,
};

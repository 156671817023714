import { px2rem } from 'decisiv-ui-utils';

// Design used em units for headers and text line-heights, but all of our units are in rem.
// This is a quick conversion util to help keep all our units in rem.

function em2rem(px, em) {
  return px2rem(px * em);
}

export default em2rem;

import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { compose, setDisplayName } from 'recompose';

import { Text } from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Container } from 'styled-components-grid';

import withContext from 'utils/withContext';

import { reportTypes } from 'pages/Reports/constants';

import TextField from './ReportConfigBuilder/TextField';
import StaticListDropdown from './ReportConfigBuilder/StaticListDropdown';
import ReportConfigBuilder from './ReportConfigBuilder';
import InputFieldWithSuggestions from './ReportConfigBuilder/InputFieldWithSuggestions';
import ReportConfigBuilderContext from './ReportConfigBuilder/Context';
import {
  configTypes,
  exportTypes,
  reportLevels,
  btStModeTypes,
  dateRangeTypes,
  paymentMethodTitles,
  paymentMethodTypesOptions,
} from './ReportConfigBuilder/constants';

const allDateRangeTypes = Object.values(dateRangeTypes);
const allExportTypes = Object.values(exportTypes);

const CustomFilters = ({ config, updateReportConfig, isDealer }) => {
  const paymentMethod = config[configTypes.paymentMethod];
  const isBTMode = config[configTypes.btStModeType] === btStModeTypes.bt;

  return (
    <Container modifiers="padScale_0" style={{ marginTop: px2rem(20) }}>
      {isDealer && <TextField configType={configTypes.recipientAddresses} />}
      {!isDealer && (
        <>
          <Text>
            <Trans>Advanced Filters (Optional)</Trans>
          </Text>
          <InputFieldWithSuggestions configType={configTypes.caseNumber} />
          <InputFieldWithSuggestions configType={configTypes.unitNumber} />
          <InputFieldWithSuggestions
            configType={
              isBTMode ? configTypes.customerName : configTypes.customerLocation
            }
          />
          <InputFieldWithSuggestions
            configType={configTypes.inboundProgramName}
            grouped
          />
          <InputFieldWithSuggestions
            configType={
              isBTMode ? configTypes.dealerName : configTypes.dealerLocation
            }
          />
          <InputFieldWithSuggestions configType={configTypes.poNumber} />
          <StaticListDropdown
            name={configTypes.paymentMethod}
            label={<Trans>Payment Type</Trans>}
            value={
              (paymentMethodTitles?.[paymentMethod] &&
                i18n._(paymentMethodTitles[paymentMethod])) ||
              ''
            }
            style={{ marginTop: px2rem(10) }}
            options={paymentMethodTypesOptions}
            onChange={updateReportConfig}
            clearable
            activeItem={paymentMethod}
          />
        </>
      )}
    </Container>
  );
};

CustomFilters.propTypes = {
  config: PropTypes.shape({
    [configTypes.paymentMethod]: PropTypes.string,
  }).isRequired,
  updateReportConfig: PropTypes.func.isRequired,
  isDealer: PropTypes.bool.isRequired,
};

const DailyDealerReportCustomFilters = compose(
  setDisplayName('DailyDealerReportCustomFilters'),
  withContext(ReportConfigBuilderContext),
)(CustomFilters);

const DailyDealerReport = (props) => {
  const { isDealer } = props;
  const { reportLevel, exportType } = configTypes;

  const reportType = isDealer
    ? reportTypes.dailyDealerReportByEmail
    : reportTypes.dailyDealerReport;

  const initialConfig = {
    [exportType]: isDealer ? exportTypes.pdf : exportTypes.csv,
    [reportLevel]: reportLevels[isDealer ? 'billTo' : 'shipTo'],
  };

  return (
    <ReportConfigBuilder
      reportType={reportType}
      initialConfig={initialConfig}
      exportTypeOptions={isDealer ? [exportTypes.pdf] : allExportTypes}
      showBtStModelToggle
      dateRangeTypeOptions={allDateRangeTypes}
    >
      <ReportConfigBuilder.Header />
      <DailyDealerReportCustomFilters {...props} />
      <ReportConfigBuilder.Footer />
    </ReportConfigBuilder>
  );
};

DailyDealerReport.propTypes = {
  isDealer: PropTypes.bool.isRequired,
};

export default DailyDealerReport;

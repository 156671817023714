import PropTypes from 'prop-types';
import React from 'react';
import { Toaster } from 'react-hot-toast';

import LayoutBlock from 'blocks/Layout';
import TopNav from 'components/TopNav';
import LeftNav from 'compositions/LeftNav';

function Layout({ noScrollBar, children }) {
  return (
    <LayoutBlock>
      <LayoutBlock.Header>
        <TopNav />
      </LayoutBlock.Header>
      <LayoutBlock.Body modifiers={['fluid']}>
        <LayoutBlock.Panel>
          <LeftNav />
        </LayoutBlock.Panel>
        <LayoutBlock.Panel
          id="scroll-content-layout"
          modifiers={['fillWidth', 'scrollY', noScrollBar && 'noScrollBar']}
        >
          {children}
        </LayoutBlock.Panel>
      </LayoutBlock.Body>

      <Toaster position="bottom-right" />
    </LayoutBlock>
  );
}

Layout.propTypes = {
  noScrollBar: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  noScrollBar: true,
};

export default Layout;

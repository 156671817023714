import gql from 'graphql-tag';

export const NAME = 'validateAssetQuery';

export default gql`
  query validateAssetQuery(
    $caseId: ID!
    $input: ValidateAssetResponseAssetInput!
  ) {
    validateAsset(caseId: $caseId, input: $input) {
      user {
        name
        email
      }
      reference
      timestamp
      assets {
        shipTo
        customer
        vehicleVin
        application
        vehicleType
        vehicleNumber
        domicileLocation
      }
    }
  }
`;

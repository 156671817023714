import React, { Component } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { px2rem } from 'decisiv-ui-utils';
import { Container, Column, Row } from 'styled-components-grid';
import { H4, MessageMedium, QuickActionButton } from 'base-components';

import withCaseSavingStatus from 'compositions/CaseSaveIndicator/withCaseSavingStatus';
import { Entry as FieldUpdatesEntry } from 'components/FieldUpdates';
import FieldUpdatesFilter from 'components/FieldUpdates/Filters';
import {
  withSortDirection,
  sortDirectionPropType,
} from 'components/FieldUpdates/withSortDirection';

import withCaseUnifiedHistory from './withCaseUnifiedHistory';
import { formatGroupDate } from '../utils';
import { config as fieldUpdatesConfig } from '../FieldUpdates';
import { Entry as CallHistoryEntry } from '../CallHistory/Entry';
import { Entry as NotesEntry } from '../Notes/NotesPanel';
import { Entry as StatusUpdatesEntry } from '../StatusUpdates';
import { Entry as NotificationHistoryEntry } from '../NotificationHistory';
import { Entry as CaseHighlightEntry } from '../CaseHighlightEntry';

const entryComponentByEntryType = {
  CaseHistoryEntryChange: FieldUpdatesEntry,
  CaseCall: CallHistoryEntry,
  CaseAutomatedCallEntry: CallHistoryEntry,
  CaseNote: NotesEntry,
  StatusHistory: StatusUpdatesEntry,
  CaseEmail: NotificationHistoryEntry,
  CaseHighlightEntry: CaseHighlightEntry,
};

export class UnifiedHistory extends Component {
  static propTypes = {
    caseNumber: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    loadNewestResults: PropTypes.func.isRequired,
    loadOlderResults: PropTypes.func.isRequired,
    pagination: PropTypes.shape({
      hasPreviousPage: PropTypes.bool.isRequired,
      hasNextPage: PropTypes.bool.isRequired,
      endCursor: PropTypes.string,
    }).isRequired,
    results: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onSelectDirection: PropTypes.func.isRequired,
    sortDirection: sortDirectionPropType.isRequired,
  };

  componentDidUpdate({ isSaving: wasSaving }) {
    if (!this.props.isSaving && wasSaving) this.props.loadNewestResults();
  }

  renderEntry = (entry) => {
    const { caseNumber } = this.props;

    const type = entry.__typename; // eslint-disable-line no-underscore-dangle
    const Entry = entryComponentByEntryType[type];

    if (!Entry) {
      // eslint-disable-next-line no-console
      console.error(`UnifiedHistory can't render entries of type`, type);

      return null;
    }

    return (
      <Entry
        key={entry.id}
        {...{ entry, caseNumber, config: fieldUpdatesConfig }}
      />
    );
  };

  render() {
    const {
      isLoading,
      loadOlderResults,
      pagination,
      results,
      sortDirection,
      onSelectDirection,
    } = this.props;

    if (!results.length) {
      return (
        <Container>
          <Row modifiers="center">
            <Column modifiers={['col', 'padScale_5']}>
              <MessageMedium>
                <MessageMedium.Header>
                  <MessageMedium.Icon name="pencil" />
                </MessageMedium.Header>
                <MessageMedium.Section>
                  <MessageMedium.Text>
                    <Trans>No activity yet.</Trans>
                  </MessageMedium.Text>
                </MessageMedium.Section>
              </MessageMedium>
            </Column>
          </Row>
        </Container>
      );
    }

    return (
      <Container modifiers="padScale_0">
        <FieldUpdatesFilter.Header
          sortDirection={sortDirection}
          onSelectDirection={onSelectDirection}
        />

        {results.map(({ timestamp, entries }) => (
          <Container key={timestamp} modifiers={['padScaleX_0', 'padScaleY_2']}>
            <Row style={{ margin: `${px2rem(20)} 0 ${px2rem(10)}` }}>
              <H4 modifiers="fontWeightLight">
                {formatGroupDate(moment(timestamp, 'x'))}
              </H4>
            </Row>
            {entries.map(this.renderEntry)}
          </Container>
        ))}

        {pagination.hasNextPage && (
          <Row>
            <Column modifiers={['col', 'center', 'padScaleY_3']}>
              <QuickActionButton
                disabled={isLoading}
                modifiers={isLoading && 'disabled'}
                onClick={loadOlderResults}
              >
                <QuickActionButton.Text>
                  <Trans>Load More</Trans>
                </QuickActionButton.Text>
              </QuickActionButton>
            </Column>
          </Row>
        )}

        <FieldUpdatesFilter.Footer />
      </Container>
    );
  }
}

export default compose(
  setDisplayName('UnifiedHistory'),
  withCaseSavingStatus,
  withSortDirection(),
  withCaseUnifiedHistory,
)(UnifiedHistory);

import React from 'react';
import PropTypes from 'prop-types';

import { Column } from 'styled-components-grid';

import RequestAsset from './RequestAsset';

export default function RelatedAssets({ form }) {
  const { handleBlur, handleChange, setFieldValue, values } = form;

  return values.relatedAssets.map((relatedAsset, index) => (
    <Column modifiers="col_4" key={relatedAsset.id || index}>
      <RequestAsset
        name={`relatedAssets.${index}`}
        index={index + 1}
        values={values}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
      />
    </Column>
  ));
}

RelatedAssets.propTypes = {
  form: PropTypes.shape({
    handleBlur: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    values: PropTypes.shape({
      relatedAssets: PropTypes.arrayOf(PropTypes.object).isRequired,
    }).isRequired,
  }).isRequired,
};

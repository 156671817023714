import { Trans } from '@lingui/macro';
import moment from 'moment-timezone';
import React from 'react';

import { Icon, Text } from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';

import { buildEtaStringAndValues } from 'compositions/CaseDealerSelectionPanel/DealerResponses/responseTableColumns/eta';

import { formatPhone } from 'utils/format';

const commonCellStyles = {
  flex: 1,
  minWidth: px2rem(20),
};

const textColumnStyles = {
  maxWidth: '100%',
};

export default [
  {
    name: 'responseTime',
    cellKeyGetter: ({ id }) => `${id}:responseTime`,
    cellDataGetter: ({ responseTime }) => ({ responseTime }),
    cellStyles: commonCellStyles,
    headerCellRenderer: () => (
      <Row>
        <Column style={textColumnStyles} modifiers={['padScaleX_2']}>
          <Text modifiers={['small', 'textLight']}>
            <Trans>Date/Time</Trans>
          </Text>
        </Column>
      </Row>
    ),
    dataCellRenderer: (
      { responseTime }, // eslint-disable-line react/prop-types
    ) => (
      <Row>
        <Column style={textColumnStyles} modifiers={['padScaleX_2']}>
          {responseTime ? (
            <Text modifiers={['small']}>
              {moment(new Date(responseTime))
                .tz(moment.tz.guess())
                .format('D MMM, h:mm A z')}
            </Text>
          ) : (
            <Text modifiers={['small']}>&mdash;</Text>
          )}
        </Column>
      </Row>
    ),
  },
  {
    name: 'phoneNumber',
    cellKeyGetter: ({ id }) => `${id}:phoneNumber`,
    cellDataGetter: ({ phoneNumber }) => ({ phoneNumber }),
    cellStyles: commonCellStyles,
    headerCellRenderer: () => (
      <Row>
        <Column style={textColumnStyles} modifiers={['padScaleX_2']}>
          <Text modifiers={['small', 'textLight']}>
            <Trans>Phone Number</Trans>
          </Text>
        </Column>
      </Row>
    ),
    dataCellRenderer: (
      { phoneNumber }, // eslint-disable-line react/prop-types
    ) => (
      <Row>
        <Column style={textColumnStyles} modifiers={['padScaleX_2']}>
          {phoneNumber ? (
            <Text modifiers={['small']}>{formatPhone(phoneNumber)}</Text>
          ) : (
            <Text modifiers={['small']}>&mdash;</Text>
          )}
        </Column>
      </Row>
    ),
  },
  {
    name: 'contactPerson',
    cellKeyGetter: ({ id }) => `${id}:contactPerson`,
    cellDataGetter: ({ contactPerson }) => ({ contactPerson }),
    cellStyles: commonCellStyles,
    headerCellRenderer: () => (
      <Row>
        <Column style={textColumnStyles} modifiers={['padScaleX_2']}>
          <Text modifiers={['small', 'textLight']}>
            <Trans>Contact</Trans>
          </Text>
        </Column>
      </Row>
    ),
    dataCellRenderer: (
      { contactPerson }, // eslint-disable-line react/prop-types
    ) => (
      <Row>
        <Column style={textColumnStyles} modifiers={['padScaleX_2']}>
          {contactPerson ? (
            <Text modifiers={['small']}>{contactPerson}</Text>
          ) : (
            <Text modifiers={['small']}>&mdash;</Text>
          )}
        </Column>
      </Row>
    ),
  },
  {
    name: 'reason',
    cellKeyGetter: ({ id }) => `${id}:reason`,
    cellDataGetter: ({ reason }) => ({ reason }),
    cellStyles: commonCellStyles,
    headerCellRenderer: () => (
      <Row>
        <Column style={textColumnStyles} modifiers={['padScaleX_2']}>
          <Text modifiers={['small', 'textLight']}>
            <Trans>Accepted/Rejected</Trans>
          </Text>
        </Column>
      </Row>
    ),
    dataCellRenderer: (
      { reason }, // eslint-disable-line react/prop-types
    ) => (
      <Row>
        <Column style={textColumnStyles} modifiers={['padScaleX_2']}>
          {reason ? (
            <Row>
              <Column modifiers="padScaleX_0">
                <Icon name="check" modifiers="success" />
              </Column>
              <Column modifiers="padScaleX_2">
                <Text modifiers="textLight">
                  <Trans id={reason} />
                </Text>
              </Column>
            </Row>
          ) : (
            <Text modifiers={['small']}>&mdash;</Text>
          )}
        </Column>
      </Row>
    ),
  },
  {
    name: 'eta',
    cellKeyGetter: ({ id }) => `${id}:eta`,
    cellDataGetter: ({ eta }) => ({ eta }),
    cellStyles: commonCellStyles,
    headerCellRenderer: () => (
      <Row>
        <Column style={textColumnStyles} modifiers={['padScaleX_2']}>
          <Text modifiers={['small', 'textLight']}>
            <Trans>ETA</Trans>
          </Text>
        </Column>
      </Row>
    ),
    dataCellRenderer: (
      { eta }, // eslint-disable-line react/prop-types
    ) => {
      const { shortTemplate, values } = buildEtaStringAndValues(eta);
      return (
        <Row>
          <Column style={textColumnStyles} modifiers={['padScaleX_2']}>
            {eta ? (
              <Row>
                <Column modifiers="padScaleX_0">
                  <Icon name="check" modifiers="success" />
                </Column>
                <Column modifiers="padScaleX_2">
                  <Text modifiers="textLight">
                    <Trans id={shortTemplate} values={values} />
                  </Text>
                </Column>
              </Row>
            ) : (
              <Text modifiers={['small']}>&mdash;</Text>
            )}
          </Column>
        </Row>
      );
    },
  },
  {
    name: 'note',
    cellKeyGetter: ({ id }) => `${id}:note`,
    cellDataGetter: ({ note }) => ({ note }),
    cellStyles: commonCellStyles,
    headerCellRenderer: () => (
      <Row>
        <Column style={textColumnStyles} modifiers={['padScaleX_2']}>
          <Text modifiers={['small', 'textLight']}>
            <Trans>Note</Trans>
          </Text>
        </Column>
      </Row>
    ),
    dataCellRenderer: (
      { note }, // eslint-disable-line react/prop-types
    ) => (
      <Row>
        <Column style={textColumnStyles} modifiers={['padScaleX_2']}>
          {note ? (
            <Text modifiers={['small']}>{note}</Text>
          ) : (
            <Text modifiers={['small']}>&mdash;</Text>
          )}
        </Column>
      </Row>
    ),
  },
];

import gql from 'graphql-tag';

export default gql`
  mutation createAssetValidationReport(
    $startTime: Date!
    $endTime: Date!
    $detailLevel: AssetValidationReportType!
    $inboundProgramIds: [Int]
  ) {
    createAssetValidationReport(
      filters: {
        type: $detailLevel
        endTime: $endTime
        startTime: $startTime
        inboundProgramIds: $inboundProgramIds
      }
    ) {
      jobId
    }
  }
`;

import styled from 'styled-components';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';
import DefaultIcon from 'decisiv-iconix-react';

const styles = `
  display: block;
  padding: ${px2rem(5)} 0;
  text-align: center;
`;

export default buildStyledComponent(
  'TabList.Icon',
  styled(DefaultIcon),
  styles,
);

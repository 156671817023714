import React from 'react';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { Text } from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Container } from 'styled-components-grid';

import withContext from 'utils/withContext';

import { reportTypes } from 'pages/Reports/constants';

import ReportConfigBuilder from './ReportConfigBuilder';
import InputFieldWithSuggestions from './ReportConfigBuilder/InputFieldWithSuggestions';
import ReportConfigBuilderContext from './ReportConfigBuilder/Context';
import { configTypes } from './ReportConfigBuilder/constants';

const CustomFilters = () => (
  <Container modifiers="padScale_0" style={{ marginTop: px2rem(20) }}>
    <Text>
      <Trans>Advanced Filters (Optional)</Trans>
    </Text>
    <InputFieldWithSuggestions configType={configTypes.caseNumber} />
  </Container>
);

const DealerSelectionAuditReportCustomFilters = compose(
  setDisplayName('DailyCasesMetricsCustomFilters'),
  withContext(ReportConfigBuilderContext),
)(CustomFilters);

const DealerSelectionAuditReport = () => (
  <ReportConfigBuilder
    reportType={reportTypes.dealerSelectionAuditReport}
    showBillableStatus={false}
  >
    <ReportConfigBuilder.Header />
    <DealerSelectionAuditReportCustomFilters />
    <ReportConfigBuilder.Footer />
  </ReportConfigBuilder>
);

export default DealerSelectionAuditReport;

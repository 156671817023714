import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import { px2rem } from 'decisiv-ui-utils';
import { MessageMedium } from 'base-components';
import { Container } from 'styled-components-grid';

function ErrorMessage({ locationText, isRouteError, isRouteSearch }) {
  let title;
  let body;

  if (isRouteError) {
    title = <Trans>No route found.</Trans>;
    body = (
      <Trans>
        Try searching by different locations or search by area instead of route.
      </Trans>
    );
  } else if (locationText) {
    // when search is valid or no dealers found
    title = (
      <span>
        <Trans id="No matches for" /> {locationText}
      </span>
    );
    body = (
      <Trans>
        Try removing or editing some of your filters to expand the search, or
        search by a different location.
      </Trans>
    );
  } else {
    // placeholder
    body = isRouteSearch ? (
      <Trans>
        Search by asset location and destination to see service providers along
        the route.
      </Trans>
    ) : (
      <Trans>Search by asset location to see nearby service providers</Trans>
    );
  }

  return (
    <Container className="no-results" style={{ marginTop: px2rem(60) }}>
      <MessageMedium>
        <MessageMedium.Header>
          <MessageMedium.Icon name="search" />
        </MessageMedium.Header>
        {title && (
          <MessageMedium.Section>
            <MessageMedium.Title>{title}</MessageMedium.Title>
          </MessageMedium.Section>
        )}
        <MessageMedium.Section>
          <MessageMedium.Text>{body}</MessageMedium.Text>
        </MessageMedium.Section>
      </MessageMedium>
    </Container>
  );
}

ErrorMessage.propTypes = {
  locationText: PropTypes.node,
  isRouteError: PropTypes.bool,
  isRouteSearch: PropTypes.bool,
};

ErrorMessage.defaultProps = {
  locationText: '',
  isRouteError: false,
  isRouteSearch: false,
};

export default ErrorMessage;

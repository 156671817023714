import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';
import { find, get, isEmpty, lowerCase } from 'lodash';

import { px2rem } from 'decisiv-ui-utils';
import { Container, Row, Column } from 'styled-components-grid';
import { Dropdown, InputField, MessageMedium } from 'base-components';

import ContactInfo from '../../../ContactInfo';
import withAfterHoursContacts from './withAfterHoursContacts';

function getSelectedContactName(contacts, id) {
  const selectedContact = find(contacts, (contact) => contact.id === id);

  return get(selectedContact, 'name', '');
}

function NoResults() {
  return (
    <Container style={{ paddingBottom: '20px' }}>
      <MessageMedium>
        <MessageMedium.Header>
          <MessageMedium.Icon name="search" />
        </MessageMedium.Header>
        <MessageMedium.Section>
          <MessageMedium.Title>
            <Trans>No Matches Found</Trans>
          </MessageMedium.Title>
        </MessageMedium.Section>
        <MessageMedium.Section>
          <MessageMedium.Text>
            <Trans>
              Check that all words are spelled correctly or search by a
              different name.
            </Trans>
          </MessageMedium.Text>
        </MessageMedium.Section>
      </MessageMedium>
    </Container>
  );
}

export class AfterHoursContactSelector extends Component {
  static propTypes = {
    contacts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        jobTitle: PropTypes.string,
        primaryPhoneNumber: PropTypes.string.isRequired,
      }),
    ).isRequired,
    label: PropTypes.node.isRequired,
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.string,
    readOnly: PropTypes.bool,
    inputId: PropTypes.string,
  };

  static defaultProps = {
    selected: null,
    readOnly: false,
    inputId: undefined,
  };

  state = { searchValue: '' };

  buildFilteredOptions = () =>
    this.props.contacts.reduce((acc, contact) => {
      const match =
        lowerCase(contact.name).match(lowerCase(this.state.searchValue)) &&
        contact;

      if (match) {
        return acc.concat(
          <Dropdown.ListItem key={contact.id} id={contact.id}>
            <Row modifiers={['middle']}>
              <ContactInfo contact={contact} />
            </Row>
          </Dropdown.ListItem>,
        );
      }

      return acc;
    }, []);

  buildOptions = () => {
    const filteredOptions = this.buildFilteredOptions();

    if (isEmpty(filteredOptions)) {
      return <NoResults />;
    }
    return <Dropdown.List>{filteredOptions}</Dropdown.List>;
  };

  render() {
    const {
      contacts,
      selected,
      onChange,
      label,
      inputId,
      readOnly,
    } = this.props;

    const selectedName = getSelectedContactName(contacts, selected);

    return (
      <Dropdown
        fullWidth
        hideOnChange
        activeItem={selected}
        onChange={onChange}
        zIndex={2}
        readOnly={readOnly}
      >
        {({ show, toggle, isVisible }) => (
          <>
            <Container modifiers="padScale_0" onClick={show}>
              <InputField
                onFocus={show}
                onChange={(event) =>
                  this.setState(
                    { searchValue: event.target.value },
                    () => selected && onChange(event, null),
                  )
                }
                id={inputId}
                placeholder={t`Search by name...`}
                value={selected ? selectedName : this.state.searchValue}
                readOnly={readOnly}
              >
                <InputField.Avatar
                  name={selected ? selectedName : undefined}
                  style={{ textDecoration: 'none' }}
                  modifiers={['small', selected ? 'primary' : 'secondary']}
                />
                <Column modifiers={['col', 'padScale_0']}>
                  <Row>
                    <InputField.Label htmlFor={inputId}>
                      {label}
                    </InputField.Label>
                  </Row>
                  <Row>
                    <InputField.TextField />
                    {(this.state.searchValue || selected) && (
                      <InputField.ActionButton
                        icon="times"
                        type="button"
                        modifiers={['padScaleX_0', 'hoverDanger']}
                        onClick={(event) => {
                          // Required to prevent toggling the dropdown.
                          event.stopPropagation();
                          this.setState({ searchValue: '' }, () =>
                            onChange(event, null),
                          );
                        }}
                      />
                    )}
                    <InputField.ActionButton
                      icon={isVisible ? 'chevron-up' : 'chevron-down'}
                      type="button"
                      modifiers={['padScaleX_1', 'hoverInfo']}
                      onClick={(event) => {
                        event.stopPropagation();
                        toggle();
                      }}
                    />
                  </Row>
                </Column>
              </InputField>
            </Container>
            <Dropdown.Content
              style={{ maxHeight: px2rem(250), overflowY: 'auto' }}
            >
              {this.buildOptions()}
            </Dropdown.Content>
          </>
        )}
      </Dropdown>
    );
  }
}

export default compose(
  setDisplayName('AfterHoursContactSelector'),
  withAfterHoursContacts,
)(AfterHoursContactSelector);

import { get, isEqual } from 'lodash';

const rowModifiers = ['noHighlightOnHover', 'notFlex'];

export const getRowModifiers = () => rowModifiers;

/**
 * Returns whether or not the row's data has values for all
 * the fields marked as dependencies of the field in this column.
 */
export const cellIsUnlocked = (params) =>
  (params.dependsOn || []).map((name) => params.rowData[name]).every(Boolean);

/**
 * Returns the col index of the first unmet dependency for the given cell
 */
export const getFirstUnmetDependencyIndexForCell = (params) => {
  const { rowData, dependsOn } = params;

  const firstNonMetDep = (dependsOn || []).find((k) => !rowData[k]);

  return firstNonMetDep
    ? params.columns.findIndex((c) => c.name === firstNonMetDep)
    : -1;
};

/**
 * Returns the available options for a given column's dropdown.
 * Some dropdowns don't change their options as the user changes other
 * fields. For those dropdowns, we have an hardcoded list of options,
 * used here.
 */
export const getDropdownOptions = (params) => params.columnConfig.values || [];

/**
 * Returns whether or not the table is changing from having a single row
 * to multiple, or vice-versa. Ignores changes in how many "multiple" it has.
 */
const tableIsChangingSingleRowState = (previousTableData, nextTableData) =>
  [previousTableData.length, nextTableData.length].includes(1) &&
  Math.abs(previousTableData.length - nextTableData.length) === 1;

/**
 * Returns whether or not we are re-rendering because an "asset",
 * "position" or "type" dropdown was changed, in which case we want
 * all rows to update in order to re-calculate their disabled options.
 */
const tableIsChangingInterDependentOptions = (prevLines, nextLines) => {
  const valuePaths = ['asset.id', 'tirePosition', 'productType'];

  // If we've removed a line, we must update
  if (nextLines.length < prevLines.length) return true;

  // If we're just adding a new empty line, no need to update the options
  if (nextLines.length === prevLines.length + 1) {
    const { length, [length - 1]: newLine = {} } = nextLines;

    if (valuePaths.every((path) => !get(newLine, path))) return false;
  }

  // Returns true if any of the relevant values changed
  return valuePaths
    .map((path) => [
      prevLines.map((line) => get(line, path)),
      nextLines.map((line) => get(line, path)),
    ])
    .some((entry) => !isEqual(...entry));
};

const tableIsChangingARowConfirmedState = (prevProps, nextProps) => {
  const { tableData, tableMetaData } = prevProps;
  const { tableData: nextTableData } = nextProps;
  const { rowsType: confirmedKey } = tableMetaData;

  const prevStates = tableData
    .reduce((acc, row) => [...acc, row[confirmedKey]], [])
    .join('-');

  const nextStates = nextTableData
    .reduce((acc, row) => [...acc, row[confirmedKey]], [])
    .join('-');

  return prevStates !== nextStates;
};

/**
 * Returns whether or not a row should update based on its data.
 * The first row is treated specially because it must also update
 * when changing from a single row to multiple and vice-versa, in
 * order to update the "remove row" button.
 */
export const shouldRowUpdate = (prevProps, nextProps) => {
  const { rowData, tableData, tableMetaData = {} } = prevProps;

  const {
    rowData: nextRowData,
    tableData: nextTableData,
    tableMetaData: nextTableMetaData = {},
  } = nextProps;

  if (
    [
      tableIsChangingSingleRowState(tableData, nextTableData),
      tableIsChangingARowConfirmedState(prevProps, nextProps),
      tableIsChangingInterDependentOptions(tableData, nextTableData),
      nextTableMetaData.isReadOnlyCase !== tableMetaData.isReadOnlyCase,
      nextTableMetaData.genericTireOptions !== tableMetaData.genericTireOptions,
    ].some(Boolean)
  ) {
    return true;
  }

  return !isEqual(rowData, nextRowData);
};

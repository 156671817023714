import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { t, Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { get, noop } from 'lodash';

import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';
import { Dropdown, Icon, Text } from 'base-components';

import RelativeNow from 'components/RelativeNow';
import withFocusReceiver from 'setup/FocusProvider/withFocusReceiver';

import {
  evaluateOperatingStatusType,
  OPERATING_STATUS_VALUES,
} from 'utils/operatingStatusFilter';

import { CASE_STATUS } from 'compositions/CaseStatus/constants';

import {
  rejectedReasons,
  allAcceptReasons,
  allReasonsLabels,
  closedDealerAcceptReasons,
  closedDealerRejectReasons,
  delayedServiceAcceptReasons,
  checkingTireAvailabilityReason,
} from '../../constants';
import withCaseDealerResponseActions from '../../withCaseDealerResponseActions';
import { buildFocusReceiverId } from '../utils';

import InputField from '../InputField';

const thirtySeconds = 1000 * 30;

function getReasonIconProps(reason) {
  return allAcceptReasons.includes(reason)
    ? { name: 'check', modifiers: 'success' }
    : { name: 'times', modifiers: 'danger' };
}

function renderAcceptedReasons(allowAcceptedReasons, acceptReasons) {
  return (
    <>
      <Row>
        <Column modifiers={['col', 'padScaleX_2']}>
          <Text modifiers={['small']}>
            <Trans>Accept Reasons</Trans>
          </Text>
        </Column>
      </Row>
      {!allowAcceptedReasons ? (
        <ul style={{ listStyle: 'none', margin: 0, padding: 0, width: '100%' }}>
          {acceptReasons.map((value) => (
            <li
              key={value}
              style={{
                display: 'flex',
                padding: `${px2rem(5)} ${px2rem(10)}`,
              }}
            >
              <Row style={{ flexWrap: 'nowrap' }}>
                <Column>
                  <Icon name="check" modifiers="disabled" />
                </Column>
                <Column modifiers="padScaleX_3">
                  <Text modifiers="textLight" style={{ opacity: '0.5' }}>
                    <Trans id={allReasonsLabels[value] || value} />
                  </Text>
                </Column>
              </Row>
            </li>
          ))}
        </ul>
      ) : (
        <Dropdown.List>
          {acceptReasons.map((value) => (
            <Dropdown.ListItem key={value} id={value}>
              <Row style={{ flexWrap: 'nowrap' }}>
                <Column>
                  <Icon name="check" modifiers="success" />
                </Column>
                <Column modifiers="padScaleX_3">
                  <Text>
                    <Trans id={allReasonsLabels[value] || value} />
                  </Text>
                </Column>
              </Row>
            </Dropdown.ListItem>
          ))}
        </Dropdown.List>
      )}
    </>
  );
}

function renderRejectedReasons(rejectReasons) {
  return (
    <>
      <Row>
        <Column modifiers={['col', 'padScaleX_2']}>
          <Text modifiers={['small']}>
            <Trans>Reject Reasons</Trans>
          </Text>
        </Column>
      </Row>
      <Dropdown.List>
        {rejectReasons.map((value) => (
          <Dropdown.ListItem key={value} id={value}>
            <Row style={{ flexWrap: 'nowrap' }}>
              <Column>
                <Icon name="times" modifiers="danger" />
              </Column>
              <Column modifiers="padScaleX_3">
                <Text>
                  <Trans id={allReasonsLabels[value] || value} />
                </Text>
              </Column>
            </Row>
          </Dropdown.ListItem>
        ))}
      </Dropdown.List>
    </>
  );
}

function renderDropdownWithOptions(acceptReasons, rejectReasons) {
  return (
    <>
      <Row>
        <Column modifiers={['col', 'padScaleX_2']}>
          <Text modifiers={['small']}>
            <Trans>Accept/Reject Reasons</Trans>
          </Text>
        </Column>
      </Row>
      <Dropdown.List>
        {acceptReasons.map((value) => (
          <Dropdown.ListItem key={value} id={value}>
            <Row style={{ flexWrap: 'nowrap' }}>
              <Column>
                <Icon name="check" modifiers="success" />
              </Column>
              <Column modifiers="padScaleX_3">
                <Text>
                  <Trans id={allReasonsLabels[value] || value} />
                </Text>
              </Column>
            </Row>
          </Dropdown.ListItem>
        ))}
        {rejectReasons.map((value) => (
          <Dropdown.ListItem key={value} id={value}>
            <Row style={{ flexWrap: 'nowrap' }}>
              <Column>
                <Icon name="times" modifiers="danger" />
              </Column>
              <Column modifiers="padScaleX_3">
                <Text>
                  <Trans id={allReasonsLabels[value] || value} />
                </Text>
              </Column>
            </Row>
          </Dropdown.ListItem>
        ))}
      </Dropdown.List>
    </>
  );
}

function renderSplitDropdown(
  isAcceptedReason,
  allowAcceptedReasons,
  acceptReasons,
  rejectReasons,
) {
  return (
    <>
      {isAcceptedReason &&
        renderAcceptedReasons(allowAcceptedReasons, acceptReasons)}
      {!isAcceptedReason && renderRejectedReasons(rejectReasons)}
    </>
  );
}

function renderNormalDropdown(
  allowAcceptedReasons,
  acceptReasons,
  rejectReasons,
) {
  return (
    <>
      {renderAcceptedReasons(allowAcceptedReasons, acceptReasons)}
      {renderRejectedReasons(rejectReasons)}
    </>
  );
}

function ReasonRenderer({ icon, reason, displayValue, onFocusRequested }) {
  if (!reason) {
    return (
      <Row>
        <InputField.TextField
          readOnly
          ref={(el) => onFocusRequested(el, { click: true })}
        />
        <InputField.ActionButton
          icon={icon}
          onClick={noop}
          modifiers={['hoverInfo']}
        />
      </Row>
    );
  }

  return (
    <Row style={{ flexWrap: 'nowrap', paddingLeft: px2rem(4) }}>
      <Column>
        <Icon {...getReasonIconProps(reason)} />
      </Column>
      <Column
        modifiers={['col', 'padScaleX_3']}
        style={{
          position: 'relative',
          bottom: '2px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <Text>{displayValue}</Text>
      </Column>
      <InputField.ActionButton
        icon={icon}
        onClick={noop}
        modifiers={['hoverInfo']}
      />
    </Row>
  );
}

ReasonRenderer.propTypes = {
  icon: PropTypes.string.isRequired,
  reason: PropTypes.string,
  displayValue: PropTypes.string,
  onFocusRequested: PropTypes.func.isRequired,
};

ReasonRenderer.defaultProps = {
  reason: undefined,
  displayValue: undefined,
};

function BaseRenderer({
  allowAcceptedReasons,
  caseStatus,
  id,
  isDealerClosed,
  isDelayedService,
  isDispatchedStatus,
  readOnly,
  reason,
  updateDealerResponse,
  isPermissionsBasedRole,
  onFocusRequested,
}) {
  const isAcceptedReason = allAcceptReasons.includes(reason);
  const rejectReasons = (isDealerClosed
    ? closedDealerRejectReasons
    : rejectedReasons
  ).filter(
    (rejectReason) =>
      // Exclude CTA reason option if case is not in To Dispatch
      !(
        caseStatus !== CASE_STATUS.dispatch &&
        rejectReason === checkingTireAvailabilityReason
      ),
  );

  let content;

  if (isDealerClosed || isDelayedService) {
    content = renderDropdownWithOptions(
      isDelayedService
        ? delayedServiceAcceptReasons
        : closedDealerAcceptReasons,
      rejectReasons,
    );
  } else {
    content =
      isDispatchedStatus && !isPermissionsBasedRole
        ? renderSplitDropdown(
            isAcceptedReason,
            allowAcceptedReasons,
            allAcceptReasons,
            rejectReasons,
          )
        : renderNormalDropdown(
            allowAcceptedReasons,
            allAcceptReasons,
            rejectReasons,
          );
  }

  const handleChange = (_, newReason) => {
    const accepted = allAcceptReasons.includes(newReason);

    updateDealerResponse({ id, patch: { accepted, reason: newReason } });
  };

  const displayValue =
    reason && allReasonsLabels[reason]
      ? i18n._(allReasonsLabels[reason])
      : reason || '';

  return (
    <Dropdown
      name={`dealerResponse-${id}-reason`}
      fullWidth
      readOnly={readOnly}
      onChange={handleChange}
      activeItem={reason}
      hideOnChange
    >
      {({ isVisible }) => (
        <>
          <Dropdown.Target>
            <InputField
              isValid
              placeholder={t`Select...`}
              readOnly={readOnly}
              style={{ cursor: 'pointer' }}
              value={displayValue || ''}
            >
              <Column modifiers={['col', 'padScaleY_0']}>
                <ReasonRenderer
                  icon={isVisible ? 'chevron-up' : 'chevron-down'}
                  reason={reason}
                  displayValue={displayValue}
                  onFocusRequested={onFocusRequested}
                />
              </Column>
            </InputField>
          </Dropdown.Target>
          <Dropdown.Content
            style={{
              right: 'auto',
              whiteSpace: 'nowrap',
              maxHeight: px2rem(300),
              overflowY: 'auto',
            }}
          >
            {content}
          </Dropdown.Content>
        </>
      )}
    </Dropdown>
  );
}

BaseRenderer.propTypes = {
  allowAcceptedReasons: PropTypes.bool.isRequired,
  caseStatus: PropTypes.string.isRequired,
  isDealerClosed: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isDispatchedStatus: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  reason: PropTypes.string,
  updateDealerResponse: PropTypes.func.isRequired,
  isPermissionsBasedRole: PropTypes.bool.isRequired,
  isDelayedService: PropTypes.bool.isRequired,
  onFocusRequested: PropTypes.func.isRequired,
};

BaseRenderer.defaultProps = {
  isDealerClosed: false,
  reason: undefined,
};

const CellRenderer = compose(
  withCaseDealerResponseActions,
  withFocusReceiver(buildFocusReceiverId('reason')),
)(BaseRenderer);

export default {
  name: 'reason',
  cellKeyGetter: ({ id }) => `${id}:reason`,
  cellDataGetter: (args) => args,
  cellStyles: { flex: 2 },
  headerCellRenderer: () => (
    <Row>
      <Column modifiers={['padScaleX_2', 'padScaleY_3']}>
        <Text modifiers={['small', 'textLight']}>
          <Trans>Accepted/Rejected</Trans>
        </Text>
      </Column>
    </Row>
  ),
  dataCellRenderer: (
    { id, reason }, // eslint-disable-line react/prop-types
    { rowIndex, tableData, tableMetaData },
  ) => {
    const dealer = get(tableMetaData, 'dealer', {});
    const hasServicingDealer = get(tableMetaData, 'hasServicingDealer', false);
    const isServicingDealer = get(tableMetaData, 'isServicingDealer', false);
    const servicingDealerResponseId = get(
      tableMetaData,
      'servicingDealerResponseId',
    );

    const isCustomDealer = get(dealer, '__typename') === 'CustomDealer';

    const allowAcceptedReasons =
      !hasServicingDealer ||
      (isServicingDealer && servicingDealerResponseId === id);

    const commonProps = {
      id,
      reason,
      isLast: rowIndex === tableData.length - 1,
      readOnly: get(tableMetaData, 'readOnly'),
      rowIndex,
      caseStatus: tableMetaData.caseStatus,
      dealerIndex: tableMetaData.dealerIndex,
      isDelayedService: tableMetaData.isDelayedService,
      isDispatchedStatus: get(tableMetaData, 'isDispatchedStatus', false),
      allowAcceptedReasons,
      isPermissionsBasedRole: get(tableMetaData, 'isPermissionsBasedRole'),
    };

    if (isCustomDealer) {
      return <CellRenderer {...commonProps} />;
    }

    return (
      <RelativeNow
        interval={thirtySeconds}
        timezone={dealer.timezone}
        render={({ relativeNow }) => {
          const { type } = evaluateOperatingStatusType(dealer, relativeNow);
          const isClosed = type === OPERATING_STATUS_VALUES.CLOSED;

          return <CellRenderer {...commonProps} isDealerClosed={isClosed} />;
        }}
      />
    );
  },
};

import gql from 'graphql-tag';

export const NAME = 'caseCustomerEmailNotificationPreferencesQuery';

export default gql`
  query caseCustomerEmailNotificationPreferencesQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      customer(useCachedDataForCase: true) {
        ... on Customer {
          id
          name
          type
          emailNotificationPreferences {
            email
            notifyWhenDispatched
            notifyWhenRolling
          }
        }
        ... on StoreCustomer {
          id
          name
          type
          emailNotificationPreferences {
            email
            notifyWhenDispatched
            notifyWhenRolling
          }
        }
        ... on CustomCustomer {
          type
          name
        }
        ... on CustomStoreCustomer {
          type
          name
        }
      }
    }
  }
`;

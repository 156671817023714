import { includes, noop, omit, without } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export class WithExpandedMenus extends Component {
  static propTypes = {
    expandedMenus: PropTypes.arrayOf(PropTypes.string),
    onChangeExpandedMenus: PropTypes.func,
    render: PropTypes.func.isRequired,
  };

  static defaultProps = {
    expandedMenus: [],
    onChangeExpandedMenus: noop,
  };

  state = {
    expandedMenus: this.props.expandedMenus,
  };

  /**
   * Includes logic for updating the expanded menus in state, and updating the
   * app level component via onChangeExpandedMenus.
   * @param {string} menuKey a key used to identify a specific menu.
   */
  handleExpandMenuClick = (menuKey) => {
    const { expandedMenus: prevExpandedMenus } = this.state;
    // adds or removes the menuKey based on it's existence in the current expanded menus array.
    const expandedMenus = includes(prevExpandedMenus, menuKey)
      ? without(prevExpandedMenus, menuKey)
      : prevExpandedMenus.concat(menuKey);

    this.setState({ expandedMenus });

    // Alerts the app level component to the new expanded menus. Mainly used for
    // persistence to local storage and setting initial state.
    this.props.onChangeExpandedMenus({ expandedMenus });
  };

  render() {
    // "consume" the props that no longer need to be passed down for proper functionality
    const passThroughProps = omit(this.props, [
      'onChangeExpandedMenus',
      'render',
    ]);

    return this.props.render({
      ...passThroughProps,
      expandedMenus: this.state.expandedMenus,
      handleExpandMenuClick: this.handleExpandMenuClick,
    });
  }
}

/* istanbul ignore next */
const withExpandedMenus = (WrappedComponent) => (props) => (
  <WithExpandedMenus
    {...props}
    render={(componentProps) => <WrappedComponent {...componentProps} />}
  />
);

export default withExpandedMenus;

import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { compose, setDisplayName } from 'recompose';

import { px2rem } from 'decisiv-ui-utils';
import { InputField } from 'base-components';
import { Column, Container, Row } from 'styled-components-grid';

import withContext from 'utils/withContext';

import ReportConfigBuilderContext from './Context';
import {
  configTypes,
  configTypesPlaceholders,
  configTypesTitles,
} from './constants';

const TextField = ({ config, configType, updateReportConfig }) => (
  <Container modifiers="padScale_0" style={{ marginTop: px2rem(10) }}>
    <InputField
      name={configType}
      onChange={({ target: { value } }) =>
        updateReportConfig(configType, value)
      }
      placeholder={i18n._(configTypesPlaceholders[configType])}
      value={config[configType] || ''}
    >
      <Column modifiers={['col', 'padScaleY_0']}>
        <Row>
          <InputField.Label>
            <Trans id={configTypesTitles[configType]} />
          </InputField.Label>
        </Row>
        <Row>
          <InputField.TextField />
          {config[configType] && (
            <InputField.ActionButton
              icon="times"
              type="button"
              onClick={() => updateReportConfig(configType, undefined)}
              modifiers={['hoverDanger', 'padScaleX_0']}
            />
          )}
        </Row>
      </Column>
    </InputField>
  </Container>
);

TextField.propTypes = {
  config: PropTypes.shape({}).isRequired,
  configType: PropTypes.oneOf(Object.values(configTypes)).isRequired,
  updateReportConfig: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('TextField'),
  withContext(ReportConfigBuilderContext),
)(TextField);

import React from 'react';
import moment from 'moment-timezone';
import { useQuery, gql } from '@apollo/client';

export const queryName = 'dealerScheduleDayQuery';

export const scheduleDayGql = gql`
  fragment ContactFields on AfterHoursContact {
    id
    name
    jobTitle
    primaryPhoneNumber
    secondaryPhoneNumber
  }

  fragment EventFields on AfterHoursEvent {
    id
    startDate
    startTime
    endDate
    endTime
    options {
      recurringStartDate
      recurringEndDate
    }
    recurring {
      dayOfWeek
    }
    isRecurring
    primaryContact {
      ...ContactFields
    }
    backupContact {
      ...ContactFields
    }
    finalContact {
      ...ContactFields
    }
  }

  query dealerScheduleDayQuery($dealerId: ID!, $day: Date!) {
    calendarEvents(input: { dealerId: $dealerId, date: $day }) {
      events {
        __typename
        ... on OpenHoursEventInstance {
          start
          end
        }
        ... on AfterHoursEventInstance {
          start
          end
          primaryContact {
            ...ContactFields
          }
          backupContact {
            ...ContactFields
          }
          finalContact {
            ...ContactFields
          }
          referenceDate: reference
          afterHoursEvent {
            ...EventFields
            exceptionsAndOverrides {
              ... on AfterHoursException {
                id
                daysOfWeek
                startDate
                endDate
                __typename
              }
              ... on AfterHoursEvent {
                # overrides
                ...EventFields
                __typename
              }
            }
          }
        }
      }
    }
  }
`;

const withCalendarEvents = (WrappedComponent) => (componentProps) => {
  const { day, dealer } = componentProps;
  const dealerId = dealer.id;

  const queryProps = useQuery(scheduleDayGql, {
    skip: !dealerId || !day,
    variables: { dealerId, day: moment(day).format('Y-MM-DD') },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  return <WrappedComponent {...componentProps} {...queryProps} />;
};

export default withCalendarEvents;

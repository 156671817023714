import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { t, Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';
import { find, groupBy, isEmpty, isEqual, orderBy, get } from 'lodash';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';
import { Container, Column, Row } from 'styled-components-grid';
import {
  Avatar,
  Dropdown,
  InputField,
  InputGroup,
  MessageMedium,
  MessageSmall,
  Text,
} from 'base-components';

import withAllInboundPrograms from './withAllInboundPrograms';
import InboundProgramCustomerDetails from './InboundProgramCustomerDetails';
import { ALL_FILTER_OPTION } from '../constants';

const DropdownContainer = buildStyledComponent(
  'DropdownContainer',
  styled(InputGroup.Column),
  `
    width: 100%;
    max-width: ${px2rem(370)};
    margin-bottom: -1px;

    @media (max-width: ${px2rem(1024)}) {
      max-width: none;
    }
  `,
);

const getCustomerNameFirstChar = (program) => get(program, 'customerName.0');

const getSelectedInboundProgramById = (inboundPrograms, id) =>
  find(inboundPrograms, ['id', id]) || ALL_FILTER_OPTION;

export function sortAndGroupPrograms(inboundPrograms) {
  const sortedPrograms = orderBy(inboundPrograms, ['customerName'], 'asc');
  const groupedPrograms = groupBy(sortedPrograms, getCustomerNameFirstChar);
  const groupArray = Object.entries(groupedPrograms).map(
    ([name, customers]) => ({
      name,
      customers,
    }),
  );

  return groupArray;
}

export class InboundProgramSelector extends PureComponent {
  static propTypes = {
    inboundPrograms: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        customerName: PropTypes.string.isRequired,
        tollFreeNumber: PropTypes.string,
      }),
    ).isRequired,
    inboundProgramSearchValue: PropTypes.string,
    onChangeInboundProgramSearchValue: PropTypes.func.isRequired,
    onSelectInboundProgram: PropTypes.func.isRequired,
    clearSelectedInboundProgram: PropTypes.func.isRequired,
    selectedInboundProgram: PropTypes.shape({ id: PropTypes.string }),
  };

  static defaultProps = {
    inboundProgramSearchValue: '',
    selectedInboundProgram: null,
  };

  state = {
    inboundProgramSearchValue: getSelectedInboundProgramById(
      this.props.inboundPrograms,
      this.props.selectedInboundProgram.id,
    ).customerName,
    isInvalid: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.selectedInboundProgram.id ===
        this.props.selectedInboundProgram.id &&
      this.props.inboundProgramSearchValue !==
        nextProps.inboundProgramSearchValue
    ) {
      this.setState({
        inboundProgramSearchValue: nextProps.inboundProgramSearchValue,
      });
    } else if (
      nextProps.selectedInboundProgram.id !==
        this.props.selectedInboundProgram.id ||
      !isEqual(nextProps.inboundPrograms, this.props.inboundPrograms)
    ) {
      this.setState({
        inboundProgramSearchValue: getSelectedInboundProgramById(
          nextProps.inboundPrograms,
          nextProps.selectedInboundProgram.id,
        ).customerName,
      });
    }
  }

  handleDropdownSelect = (_, inboundProgram) => {
    if (inboundProgram && inboundProgram !== ALL_FILTER_OPTION.id) {
      this.setState({ isInvalid: false });

      const selectedInboundProgram = getSelectedInboundProgramById(
        this.props.inboundPrograms,
        inboundProgram,
      );

      this.props.onSelectInboundProgram(selectedInboundProgram);
    } else if (inboundProgram === ALL_FILTER_OPTION.id) {
      this.setState({ isInvalid: false });
      this.props.onSelectInboundProgram(ALL_FILTER_OPTION);
    }
  };

  handleSearchValueChange = ({ target: { value } }) => {
    this.props.onChangeInboundProgramSearchValue(value);
  };

  handleResetButtonClick = () => {
    this.setState({ isInvalid: false });
    this.props.clearSelectedInboundProgram();
  };

  validate = () => {
    const { inboundProgramSearchValue } = this.state;
    const selectedInboundProgram = find(this.props.inboundPrograms, [
      'id',
      this.props.selectedInboundProgram.id,
    ]);

    if (this.props.selectedInboundProgram.id === ALL_FILTER_OPTION.id) {
      if (inboundProgramSearchValue) {
        this.setState({ isInvalid: false });
      } else {
        this.setState({ isInvalid: true });
      }
    } else {
      if (inboundProgramSearchValue && !isEmpty(selectedInboundProgram)) {
        this.setState({ isInvalid: false });
      } else {
        this.setState({ isInvalid: true });
      }

      if (
        inboundProgramSearchValue &&
        inboundProgramSearchValue !== selectedInboundProgram.customerName
      ) {
        this.setState({ isInvalid: true });
      }
    }
  };

  renderErrorMessage = () => (
    <Container
      style={{ whiteSpace: 'normal', minWidth: px2rem(300) }}
      className="no-results"
    >
      <MessageMedium>
        <MessageMedium.Header>
          <MessageMedium.Icon name="search" />
        </MessageMedium.Header>
        <MessageMedium.Section>
          <MessageMedium.Title>
            <Trans>No Matches Found</Trans>
          </MessageMedium.Title>
        </MessageMedium.Section>
        <MessageMedium.Section>
          <MessageMedium.Text>
            <Trans>
              Try removing or editing some of your criteria or search for
              different words
            </Trans>
          </MessageMedium.Text>
        </MessageMedium.Section>
      </MessageMedium>
    </Container>
  );

  renderPrograms = () => {
    const { inboundPrograms } = this.props;
    const { inboundProgramSearchValue } = this.state;
    const groupedPrograms = sortAndGroupPrograms(inboundPrograms);

    if (isEmpty(groupedPrograms)) {
      return this.renderErrorMessage();
    }

    groupedPrograms.unshift(ALL_FILTER_OPTION);

    return groupedPrograms.map((group) =>
      group.name === ALL_FILTER_OPTION.name ? (
        <Dropdown.List key={ALL_FILTER_OPTION.id}>
          <Dropdown.ListItem id={ALL_FILTER_OPTION.id}>
            <Container modifiers="padScale_0">
              <Row modifiers={['middle', 'padScale_0']}>
                <Column>
                  <Avatar modifiers={['small']} isCompany />
                </Column>
                <Column>
                  <Row modifiers="padScale_0">
                    <Text>
                      <Trans>All</Trans>
                    </Text>
                  </Row>
                </Column>
              </Row>
            </Container>
          </Dropdown.ListItem>
        </Dropdown.List>
      ) : (
        <Fragment key={group.name}>
          <Dropdown.SectionHeader>
            <Row>
              <Column modifiers="padScaleY_0">
                <Text
                  modifiers={['small', 'noWrap', 'capitalize', 'textLight']}
                >
                  {group.name}
                </Text>
              </Column>
            </Row>
          </Dropdown.SectionHeader>
          <Dropdown.List>
            {group.customers.map((customer) => (
              <Dropdown.ListItem
                key={customer.id}
                id={customer.id}
                type="submit"
              >
                <InboundProgramCustomerDetails
                  customer={customer}
                  highlightText={
                    inboundProgramSearchValue === ALL_FILTER_OPTION.customerName
                      ? ''
                      : inboundProgramSearchValue
                  }
                />
              </Dropdown.ListItem>
            ))}
          </Dropdown.List>
        </Fragment>
      ),
    );
  };

  render() {
    const { inboundPrograms } = this.props;
    const { inboundProgramSearchValue } = this.state;

    const selectedInboundProgram = getSelectedInboundProgramById(
      inboundPrograms,
      this.props.selectedInboundProgram.id,
    );

    return (
      <DropdownContainer>
        <Dropdown
          fullWidth
          hideOnChange
          activeItem={get(selectedInboundProgram, 'id')}
          onChange={this.handleDropdownSelect}
          zIndex={2}
        >
          {({ show, toggle, isVisible }) => (
            <>
              <Container modifiers="padScale_0" onClick={show}>
                <InputField
                  isValid={!this.state.isInvalid}
                  onBlur={this.validate}
                  onChange={this.handleSearchValueChange}
                  placeholder={t`Select Inbound Program...`}
                  value={inboundProgramSearchValue}
                >
                  <InputField.Icon name="building" />
                  <Column modifiers={['col', 'padScale_0']}>
                    <Row>
                      <InputField.Label>
                        <Trans>Inbound Program</Trans>
                      </InputField.Label>
                    </Row>
                    <Row>
                      <InputField.TextField />
                      {selectedInboundProgram.id !== ALL_FILTER_OPTION.id && (
                        <InputField.ActionButton
                          icon="times"
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            toggle();
                            this.handleResetButtonClick();
                          }}
                          modifiers={['padScaleX_0', 'hoverDanger']}
                        />
                      )}
                      <InputField.ActionButton
                        icon={isVisible ? 'chevron-up' : 'chevron-down'}
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggle();
                        }}
                        modifiers={['padScale_0', 'hoverInfo']}
                      />
                    </Row>
                  </Column>
                </InputField>
              </Container>
              <Dropdown.Content>{this.renderPrograms()}</Dropdown.Content>
            </>
          )}
        </Dropdown>
        {this.state.isInvalid && (
          <Row>
            <Column modifiers={['col', 'padScaleY_3']}>
              <MessageSmall type="warning">
                <Trans>Select an existing program.</Trans>
              </MessageSmall>
            </Column>
          </Row>
        )}
      </DropdownContainer>
    );
  }
}

export default compose(
  setDisplayName('InboundProgramSelector'),
  withAllInboundPrograms,
)(InboundProgramSelector);

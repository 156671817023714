import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import moment from 'moment-timezone';
import { Trans } from '@lingui/macro';
import { memoize } from 'lodash';

import { Popover, Text } from 'base-components';
import { Row, Column } from 'styled-components-grid';
import { FormattedRelative } from 'decisiv-domain-utils';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import ElapsedTime from 'components/ElapsedTime';
import countOfTime from 'utils/countOfTime';

import CaseEventTimelineArrow from './CaseEventTimelineArrow';
import { CASE_STATUS } from '../constants';
import { getColorModifierForStatus } from '../utils';

const Container = buildStyledComponent(
  'CaseEventTimelineEvent__Container',
  styled.div,
  css`
    flex: 1;
    position: relative;
  `,
);

const PopoverTarget = buildStyledComponent(
  'CaseEventTimelineEvent__PopoverTarget',
  styled(Popover.Target),
  css`
    display: block;
    width: 100%;
  `,
);

const PopoverContent = buildStyledComponent(
  'CaseEventTimelineEvent__PopoverContent',
  styled(Popover.Content),
  css`
    left: ${px2rem(-21)};
    width: ${px2rem(260)};
    padding: ${px2rem(16)} ${px2rem(20)};
    margin-top: ${px2rem(-25)};
    pointer-events: none;
  `,
);

const getFormattedTimeInStatus = memoize((createdAt, completedAt) =>
  countOfTime(moment(completedAt).diff(moment(createdAt))),
);

function CaseEventTimelineEvent(props) {
  const {
    completedAt,
    createdAt,
    defaultRenderer,
    hasArrow,
    hasPopover,
    isActive,
    type,
    validationStatus,
    label,
  } = props;

  const isClosed = type === CASE_STATUS.closed;
  const arrowModifiers = getColorModifierForStatus(validationStatus);

  const popoverPosition = 'bottomRight';
  const popoverStyles = {
    width: px2rem(180),
    marginLeft: px2rem(-4),
    marginTop: px2rem(-30),
  };

  const fillParentStyles = {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  };

  const renderPopover = () => (
    <Popover
      arrow
      showOnHover
      removeOnHide
      position={popoverPosition}
      style={{ ...fillParentStyles, zIndex: 2 }}
    >
      <PopoverTarget>
        <div style={fillParentStyles} />
      </PopoverTarget>
      <PopoverContent zIndex={2} style={popoverStyles}>
        <Row>
          <Column modifiers={['col', 'padScaleX_0']}>
            <Text modifiers={['small', 'textLight']}>
              {isClosed ? <Trans>Close Time</Trans> : <Trans>Start Time</Trans>}
            </Text>
            <br />
            <Text>
              <FormattedRelative
                value={createdAt}
                render={(relative) => (
                  <>
                    {relative}
                    <br />
                    {moment(createdAt)
                      .tz(moment.tz.guess())
                      .format('D MMM YYYY, h:mm A z')}
                  </>
                )}
              />
            </Text>
          </Column>
        </Row>
        {!isClosed && (
          <Row>
            <Column modifiers={['col', 'padScaleX_0']}>
              <Text modifiers={['small', 'textLight']}>
                <Trans>Time in Status</Trans>
              </Text>
              <br />
              {isActive ? (
                <ElapsedTime
                  textModifiers={[
                    'fontSizeRegular',
                    'fontWeightRegular',
                    'text',
                  ]}
                  sinceTime={createdAt}
                />
              ) : (
                <Text>{getFormattedTimeInStatus(createdAt, completedAt)}</Text>
              )}
            </Column>
          </Row>
        )}
      </PopoverContent>
    </Popover>
  );

  return (
    <Container>
      {hasPopover && renderPopover()}
      {hasArrow && <CaseEventTimelineArrow modifiers={arrowModifiers} />}
      {defaultRenderer({
        ...props,
        label,
        createdAt: isClosed ? '' : createdAt,
      })}
    </Container>
  );
}

CaseEventTimelineEvent.propTypes = {
  completedAt: PropTypes.string,
  createdAt: PropTypes.string,
  defaultRenderer: PropTypes.func.isRequired,
  hasArrow: PropTypes.bool,
  hasPopover: PropTypes.bool,
  isActive: PropTypes.bool,
  type: PropTypes.string.isRequired,
  validationStatus: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
};

CaseEventTimelineEvent.defaultProps = {
  completedAt: undefined,
  createdAt: undefined,
  hasArrow: false,
  hasPopover: false,
  isActive: false,
};

export default CaseEventTimelineEvent;

import React from 'react';
import PropTypes from 'prop-types';
import { range } from 'lodash';
import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

import {
  GRID_HEIGHT,
  TIME_BLOCK_COUNT,
  TIME_BLOCK_HEIGHT,
  TIME_BLOCKS_PER_DIVIDER,
} from './constants';

const TimeBlock = buildStyledComponent(
  'ScheduleGrid__TimeBlock',
  styled.li,
  /* istanbul ignore next */
  ({ theme }) => `
    float: left;
    height: ${TIME_BLOCK_HEIGHT * TIME_BLOCKS_PER_DIVIDER}px;
    width: 100%;
    left: 1px;
    flex-shrink: 1;
    max-width: none;
    margin: 0;
    border-bottom: 1px solid ${theme.colors.base.chrome200};
    box-sizing: border-box;
  `,
  {
    themePropTypes: {
      colors: PropTypes.shape({
        base: PropTypes.shape({
          chrome200: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    },
  },
);

const TimeBlockWrapper = buildStyledComponent(
  'ScheduleGrid_TimeBlockWrapper',
  styled.ul,
  /* istanbul ignore next */
  ({ theme, last }) => `
    position: relative;
    height: ${GRID_HEIGHT};
    display: block;
    overflow: visible;
    border: 1px solid ${theme.colors.base.chrome200};
    border-right-width: ${last ? '1px' : '0'};
    border-bottom: none;
    margin-bottom: 0;

    // so that the last column will be the same width at the others,
    // including the border...
    ${last ? 'width: calc(100% - 1px);' : ''}
  `,
  {
    themePropTypes: {
      colors: PropTypes.shape({
        base: PropTypes.shape({
          chrome200: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    },
  },
);

/**
 * Renders the vertical stack of time blocks for a day, with
 * each block separated by a border.
 *
 * @param {Object} params
 * @param {boolean} last - is this for the last (right-most) day in the schedule?
 * @return {ReactElement}
 */
export default function TimeBlocks({ last }) {
  const timeBlockDividers = TIME_BLOCK_COUNT / TIME_BLOCKS_PER_DIVIDER;
  return (
    <TimeBlockWrapper last={last}>
      {range(0, timeBlockDividers).map((block) => (
        <TimeBlock key={block} />
      ))}
    </TimeBlockWrapper>
  );
}

TimeBlocks.propTypes = {
  last: PropTypes.bool,
};

TimeBlocks.defaultProps = {
  last: false,
};

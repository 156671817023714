import PropTypes from 'prop-types';
import styled from 'styled-components';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import { TEXT_LINE_HEIGHT } from '../../utils/constants';
import em2rem from '../../utils/em2rem';

const FONT_SIZE = 12;

const styles = ({ theme }) => `
  background: none;
  border: 2px solid transparent;
  color: ${theme.colors.base.textLight};
  cursor: pointer;
  font-size: ${px2rem(FONT_SIZE)};
  font-weight: ${theme.fontWeights.light};
  line-height: ${em2rem(FONT_SIZE, TEXT_LINE_HEIGHT)};
  padding: ${px2rem(5)} ${px2rem(20)};
  text-transform: capitalize;
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      linkHover: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      textLight: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  fontWeights: PropTypes.shape({
    light: PropTypes.number.isRequired,
  }).isRequired,
};

const propTypes = {
  'aria-label': PropTypes.string.isRequired,
};

export default buildStyledComponent(
  'TabList.ItemButton',
  styled.button,
  styles,
  {
    propTypes,
    themePropTypes,
  },
);

import React, { PureComponent } from 'react';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';

import { Row, Column } from 'styled-components-grid';
import { Text, Avatar, ButtonIconRectangle } from 'base-components';

import HighlightText from 'components/HighlightText';

import StaticListDropdown from '../ReportConfigBuilder/StaticListDropdown';
import { configTypes } from '../ReportConfigBuilder/constants';

const { inboundProgramIds: ipConfigType } = configTypes;

const buildOptions = memoize((ids, values) =>
  ids.reduce((acc, id) => [...acc, [id, values[id]]], []),
);

// eslint-disable-next-line react/prop-types
const IndentedRow = ({ style, ...rest }) => (
  <Row style={{ marginLeft: 25, ...style }} {...rest} />
);

// eslint-disable-next-line react/prop-types
const ShortText = ({ style, ...rest }) => (
  <Text style={{ lineHeight: 1, ...style }} {...rest} />
);

export default class InboundProgramsSelector extends PureComponent {
  static propTypes = {
    config: PropTypes.shape({
      [ipConfigType]: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    updateReportConfig: PropTypes.func.isRequired,
    inboundProgramsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    inboundProgramsValues: PropTypes.objectOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        billTo: PropTypes.string,
        phoneNumber: PropTypes.string,
      }),
    ).isRequired,
    isLoadingInboundPrograms: PropTypes.bool.isRequired,
  };

  handleDropdownChange = (_, id) => {
    const { inboundProgramsIds } = this.props;

    if (!id || !inboundProgramsIds.includes(id)) return;

    const newIds = new Set([...this.props.config[ipConfigType], id]);

    this.props.updateReportConfig({ [ipConfigType]: [...newIds] });
  };

  handleDeleteClick = (idToDelete) => {
    const { config, updateReportConfig } = this.props;
    const newIds = [...config[ipConfigType]].filter((id) => id !== idToDelete);

    updateReportConfig({ [ipConfigType]: [...newIds] });
  };

  filterInboundPrograms = (program, filterValue) => {
    const lowerCasedFilterValue = filterValue.toLowerCase();
    const [, data] = program;

    return [data.name, data.billTo, data.phone]
      .map((value) => (value || '').toLowerCase())
      .some((value) => value.includes(lowerCasedFilterValue));
  };

  renderProgramDetails = (program, options = {}) => {
    const opts = { highlightText: '', ...options };
    const mark = opts.highlightText.length < 2 ? '' : opts.highlightText;

    return (
      <Column modifiers={['col', 'padScale_0']}>
        <Row>
          <Column modifiers="padScale_0">
            <ShortText>
              <HighlightText text={mark}>{program.name || ''}</HighlightText>
            </ShortText>
          </Column>
        </Row>
        <Row>
          <Column modifiers="padScale_0">
            <ShortText modifiers={['small', 'textLight']}>
              <Trans>Bill To #</Trans>{' '}
              <ShortText modifiers="small">
                <HighlightText text={mark}>
                  {program.billTo || ''}
                </HighlightText>
              </ShortText>
              {!!program.phone && (
                <span>
                  , <Trans>Phone #</Trans>{' '}
                  <ShortText modifiers="small">
                    <HighlightText text={mark}>
                      {program.phone || ''}
                    </HighlightText>
                  </ShortText>
                </span>
              )}
            </ShortText>
          </Column>
        </Row>
      </Column>
    );
  };

  renderOption = (_, program, options) => (
    <Row modifiers="middle">
      <Column>
        <Avatar name={program.name} modifiers={['small']} />
      </Column>

      {this.renderProgramDetails(program, options)}
    </Row>
  );

  renderInboundProgram = (id, index) => {
    const program = this.props.inboundProgramsValues[id];

    return (
      <IndentedRow
        key={id}
        style={{
          padding: '5px 0',
          borderTop: !index ? '1px solid #ddd' : '',
          borderBottom: '1px solid #ddd',
        }}
        modifiers="middle"
      >
        {this.renderProgramDetails(program)}

        <Column modifiers="padScale_0">
          <ButtonIconRectangle onClick={() => this.handleDeleteClick(id)}>
            <ButtonIconRectangle.Icon name="trash" modifiers="small" />
          </ButtonIconRectangle>
        </Column>
      </IndentedRow>
    );
  };

  render() {
    const { inboundProgramsIds, inboundProgramsValues } = this.props;
    const { config, isLoadingInboundPrograms: isLoading } = this.props;

    const selectedIds = config[ipConfigType];
    const hasSelectedIPs = !!selectedIds.length;

    const options = buildOptions(
      inboundProgramsIds.filter((id) => !selectedIds.includes(id)),
      inboundProgramsValues,
    );

    return (
      <div style={{ marginTop: 10 }}>
        {!hasSelectedIPs && (
          <IndentedRow>
            <Column modifiers="padScale_0">
              <Text modifiers={['small', 'textLight']}>
                <Trans>
                  No inbound programs selected. Use the field below to add some
                  to the list.
                </Trans>
              </Text>
            </Column>
          </IndentedRow>
        )}

        {selectedIds.map(this.renderInboundProgram)}

        <IndentedRow style={{ marginTop: 15 }}>
          <Column modifiers={['padScaleX_0', 'col']}>
            <StaticListDropdown
              key={isLoading}
              name="inboundProgram"
              label={<Trans>Inbound Program</Trans>}
              value={isLoading ? t`Loading options, please wait...` : ''}
              options={options}
              readOnly={isLoading}
              onChange={this.handleDropdownChange}
              clearable
              filterable
              placeholder={t`Search by name...`}
              removeOnHide={false}
              optionRenderer={this.renderOption}
              optionFilterer={this.filterInboundPrograms}
            />
          </Column>
        </IndentedRow>
      </div>
    );
  }
}

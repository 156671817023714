import styled, { keyframes } from 'styled-components';

import { Icon } from 'base-components';

const Appear = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const Rotate = keyframes`
  from { transform: rotateZ(0); }
  to { transform: rotateZ(1turn); }
`;

export default styled(Icon).attrs({ name: 'refresh' })`
  animation: ${Appear} 200ms;

  &::before {
    animation: ${Rotate} 1s linear infinite;
  }
`;

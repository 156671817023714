import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName, onlyUpdateForKeys } from 'recompose';

import Panel from 'blocks/Panel';

import CaseRequestsPanelContext from './CaseRequestsPanelContext';
import CaseRequestsPanelHeader from './CaseRequestsPanelHeader';
import RequestAssetsForm from './RequestAssetsForm';
import CaseRequestLines from './CaseRequestLines';
import CaseRequestsActions from './CaseRequestsActions';
import SpecialInstructions from './SpecialInstructions';
import withCaseCustomer from './withCaseCustomer';

export function CaseRequestsPanel({ caseNumber, customerId }) {
  return (
    <CaseRequestsPanelContext key={customerId} caseNumber={caseNumber}>
      <Panel modifiers="padScaleX_3" data-testid="CaseRequestsPanel">
        <CaseRequestsPanelHeader />
        <RequestAssetsForm caseNumber={caseNumber} />
        <CaseRequestLines />
        <CaseRequestsActions />
        <SpecialInstructions />
      </Panel>
    </CaseRequestsPanelContext>
  );
}

CaseRequestsPanel.propTypes = {
  caseNumber: PropTypes.string.isRequired,
  customerId: PropTypes.string,
};

CaseRequestsPanel.defaultProps = {
  customerId: null,
};

export default compose(
  setDisplayName('CaseRequestsPanel'),
  withCaseCustomer,
  onlyUpdateForKeys(['caseNumber', 'customerId']),
)(CaseRequestsPanel);

import React, { Component, Fragment } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import { t, Trans } from '@lingui/macro';
import { groupBy, get } from 'lodash';
import { compose, setDisplayName } from 'recompose';

import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';
import { Dropdown, InputField, Text } from 'base-components';

import withDealerWorkingHours from './withDealerWorkingHours';

const dateTimeFormat = 'YYYY-MM-DD h:mm A';
const isWithinWorkingHours = (workingHours, option) => {
  const day = moment().format('YYYY-MM-DD');
  const getTime = (date) => moment(date).format('h:mm A');
  return (
    workingHours &&
    workingHours.some(
      ({ start, end, weekDay }) =>
        option.format('dddd').toLowerCase() === weekDay &&
        moment(`${day} ${start}`, dateTimeFormat).isSameOrBefore(
          moment(`${day} ${getTime(option)}`, dateTimeFormat),
        ) &&
        moment(`${day} ${end}`, dateTimeFormat).isSameOrAfter(
          moment(`${day} ${getTime(option)}`, dateTimeFormat),
        ),
    )
  );
};

const DaylightSavingsNotice = styled.div`
  color: black;
  height: 30px;
  display: flex;
  padding: 0 10px;
  background: #eeeeee;
  align-items: center;
  pointer-events: none;
`;

/* eslint-disable react/prop-types */
const TimeOptionItem = ({
  label,
  onClick,
  selectedStart,
  duration,
  withinWorkingHours,
  showDaylightNotice,
  modifiers,
}) => (
  <Fragment>
    <Dropdown.ListItem onClick={onClick} modifiers={modifiers}>
      <Row>
        <Column modifiers={['padScale_0']}>
          <Text modifiers={withinWorkingHours && 'success'}>
            {label}
            {selectedStart && <Trans>{`(${duration} hours)`}</Trans>}
          </Text>
        </Column>
      </Row>
    </Dropdown.ListItem>
    {showDaylightNotice && (
      <DaylightSavingsNotice>
        <Text modifiers={['small', 'capitalize', 'textLight']}>
          <Trans>Daylight Saving Time Transition</Trans>
        </Text>
      </DaylightSavingsNotice>
    )}
  </Fragment>
);
/* eslint-enable react/prop-types */

class TimeSelector extends Component {
  static propTypes = {
    label: PropTypes.node.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(MomentPropTypes.momentObj).isRequired,
    selected: MomentPropTypes.momentObj,
    selectedStart: MomentPropTypes.momentObj,
    // dealerId is used in withDealerWorkingHours
    // eslint-disable-next-line react/no-unused-prop-types
    dealerId: PropTypes.string,
    data: PropTypes.shape({
      dealer: PropTypes.shape({
        workingHours: PropTypes.arrayOf(
          PropTypes.shape({
            start: PropTypes.string.isRequired,
            end: PropTypes.string.isRequired,
            weekDay: PropTypes.string.isRequired,
          }),
        ),
      }),
    }),
    isInvalid: PropTypes.bool,
    inputId: PropTypes.string,
  };

  static defaultProps = {
    selected: null,
    selectedStart: null,
    dealerId: undefined,
    data: null,
    isInvalid: false,
    inputId: undefined,
  };

  buildGroupedOptions = () => {
    const groupedOptions = groupBy(this.props.options, (option) =>
      // must convert to timestamp then back to label because the label is not sortable.
      // groupings overlapping month end/start would display in reverse order.
      moment(option).startOf('day').toISOString(),
    );

    return Object.keys(groupedOptions)
      .sort()
      .map((key) => (
        <Fragment key={key}>
          <Dropdown.SectionHeader>
            <Text modifiers={['small', 'noWrap', 'capitalize', 'textLight']}>
              {moment(key).format('dddd, D MMM')}
            </Text>
          </Dropdown.SectionHeader>
          <Dropdown.List>
            {groupedOptions[key].map((option, i) => {
              const isoString = option.toISOString();

              const duration =
                this.props.selectedStart &&
                moment
                  .duration(option.diff(this.props.selectedStart))
                  .as('hours');

              const withinWorkingHours = isWithinWorkingHours(
                get(this.props.data, 'dealer.workingHours'),
                option,
              );

              // add timezone to option label when timezone shifts so that it's clearer what happened
              // eg. when it enters DST you might see 3am after 1:30am (instead of 2am)
              // and that could be confusing if you're not aware that it is shifting to DST
              const prevOption = groupedOptions[key][i - 1];
              const nextOption = groupedOptions[key][i + 1];

              const curTZ = option.format('z');
              const prevTZ = prevOption?.format('z') || curTZ;
              const nextTZ = nextOption?.format('z') || curTZ;

              const hasDSTShifted = prevTZ !== curTZ || nextTZ !== curTZ;
              const optionDateFormat = hasDSTShifted ? 'h:mm A z ' : 'h:mm A ';

              return (
                <TimeOptionItem
                  key={isoString}
                  id={isoString}
                  label={option.format(optionDateFormat)}
                  duration={duration}
                  selectedStart={this.props.selectedStart}
                  withinWorkingHours={withinWorkingHours}
                  showDaylightNotice={nextTZ !== curTZ}
                />
              );
            })}
          </Dropdown.List>
        </Fragment>
      ));
  };

  render() {
    const { isInvalid, inputId } = this.props;

    return (
      <Dropdown
        activeItem={this.props.selected && this.props.selected.toISOString()}
        fullWidth
        hideOnChange
        onChange={this.props.onChange}
        zIndex={2}
      >
        {({ toggle, isVisible }) => (
          <>
            <Dropdown.Target>
              <InputField
                id={inputId}
                onChange={(event) => event.stopPropagation()}
                placeholder={t`Select time...`}
                value={
                  this.props.selected
                    ? this.props.selected.format('dddd, D MMM, h:mm A')
                    : ''
                }
                isValid={!isInvalid}
              >
                <InputField.Icon name="clock" />
                <Column modifiers={['col', 'padScale_0']}>
                  <Row>
                    <InputField.Label htmlFor={inputId}>
                      {this.props.label}
                    </InputField.Label>
                  </Row>
                  <Row>
                    <InputField.TextField
                      style={{ caretColor: 'transparent', cursor: 'pointer' }}
                    />
                    <InputField.ActionButton
                      icon={isVisible ? 'chevron-up' : 'chevron-down'}
                      type="button"
                      modifiers={['padScaleX_1', 'hoverInfo']}
                      onClick={(event) => {
                        event.stopPropagation();
                        toggle();
                      }}
                    />
                  </Row>
                </Column>
              </InputField>
            </Dropdown.Target>
            <Dropdown.Content
              style={{ maxHeight: px2rem(250), overflowY: 'auto' }}
            >
              {this.buildGroupedOptions()}
            </Dropdown.Content>
          </>
        )}
      </Dropdown>
    );
  }
}

export default compose(
  setDisplayName('RotationTimeSelector'),
  withDealerWorkingHours,
)(TimeSelector);

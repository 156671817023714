import React, { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';

const errorMessage = '[withResetInactivityTimer] case number is required';

const mutation = gql`
  mutation resetUserActivityInCase($caseNumber: String!) {
    resetUserActivityInCase(caseNumber: $caseNumber) {
      id
      timestamp
    }
  }
`;

const withResetInactivityTimer = (Component) => (cProps) => {
  const { caseNumber } = cProps;

  const [mutate] = useMutation(mutation);
  const reset = useCallback(() => {
    if (!caseNumber) return Promise.reject(errorMessage);

    return mutate({ variables: { caseNumber } });
  }, [mutate, caseNumber]);

  return <Component {...cProps} resetInactivityTimer={reset} />;
};

export default withResetInactivityTimer;

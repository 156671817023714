/* istanbul ignore file */
import React from 'react';
import { Link, Text } from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Container, Row, Column } from 'styled-components-grid';

function makeCellText(cellData, textGetter) {
  return (
    <Container modifiers={['height_100']}>
      <Row modifiers={['height_100', 'middle']}>
        <Column modifiers={['col', 'padScaleY_4']}>
          {textGetter(cellData)}
        </Column>
      </Row>
    </Container>
  );
}

/**
 * @typedef {Object} CellDefinition
 * @property {function} cellDataGetter Function to retrieve cell data from row data
 * @property {function} cellKeyGetter Function to get the unique key for the cell
 * @property {Object} cellStyles inline-style CSS for the cell
 * @property {function} dataCellRenderer Function to render the visible cell text
 * @property {function} headerCellRenderer Function to render the cell column headder
 * @property {string} name Unique name for the column
 */

/**
 * Build a column configuration object with the standard styles / formatting
 * required for the Tech Admin dealer search results table.
 *
 * @param {Object} styles - Any additional styling to be added to the cell
 * @param {function} linkProps - If specified, a function to return Link props for making the cell linkable
 * @param {function} text - A function to receive cell data and return the visible text for the cell
 * @param {string} label - a string or React node for the column label
 * @param {string} columnName - the unique name of this column
 * @return {CellDefinition}
 */
export default function makeCellColumn({
  styles = {},
  linkProps = null,
  text,
  label,
  columnName,
}) {
  return {
    cellDataGetter: (data) => data,
    cellKeyGetter: (data) => `${data.id}:${columnName}`,
    cellStyles: { minHeight: px2rem(36), ...styles },
    dataCellRenderer: (cellData) =>
      linkProps ? (
        <Link {...linkProps(cellData)}>{makeCellText(cellData, text)}</Link>
      ) : (
        makeCellText(cellData, (data) => (
          <Text modifiers={['textLight']}>{text(data)}</Text>
        ))
      ),
    headerCellRenderer: () => (
      <Container modifiers={['height_100']}>
        <Row modifiers={['height_100', 'middle']}>
          <Column>
            <Text modifiers={['small', 'textLight']}>{label}</Text>
          </Column>
        </Row>
      </Container>
    ),
    name: columnName,
  };
}

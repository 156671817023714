import PropTypes from 'prop-types';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';
import { Container } from 'styled-components-grid';

import Footer from './Footer';

const styles = ({ theme }) => `
  background-color: ${theme.colors.base.background};
  border-radius: ${theme.dimensions.borderRadius};
  box-shadow: 0 3px 7px ${theme.colors.base.shadow};
  width: ${px2rem(400)};
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      background: PropTypes.string.isRequired,
      shadow: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dimensions: PropTypes.shape({
    borderRadius: PropTypes.string.isRequired,
  }).isRequired,
};

const Card = buildStyledComponent('Card', Container, styles, {
  themePropTypes,
});

Card.Footer = Footer;

export default Card;

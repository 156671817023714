import React from 'react';

import { reportTypes } from 'pages/Reports/constants';

import ReportConfigBuilder from './ReportConfigBuilder';

const EmailDistributionReport = () => (
  <ReportConfigBuilder
    reportType={reportTypes.emailDistributionReport}
    showBillableStatus={false}
  />
);

export default EmailDistributionReport;

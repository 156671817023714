import { curry, debounce, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ApolloConsumer } from '@apollo/client';
import { compose, setDisplayName } from 'recompose';

export const initialState = {
  loading: false,
  pending: false,
  queryValues: {},
};

/**
 * An Apollo GraphQL Query based component that has a very significant difference,
 * when the "searchValue" changes, it will validate/ dispatch the query only after
 * a debounce period. The intent is to make a component that can easily be used to
 * build something like a typeahead or search component.
 *
 * This component DOES NOT HAVE 100% feature parity with the standard `Query` component.
 * If you need something else, add it!
 *
 * @class DebouncedQuery
 * @extends {Component}
 */
export class DebouncedQuery extends Component {
  static propTypes = {
    apolloClient: PropTypes.shape({
      query: PropTypes.func.isRequired,
    }).isRequired,
    buildQueryVariables: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired,
    debounceMS: PropTypes.number,
    searchValue: PropTypes.string.isRequired,
    skipQuery: PropTypes.func.isRequired,
    query: PropTypes.shape().isRequired,
    fetchPolicy: PropTypes.string,
  };

  static defaultProps = {
    debounceMS: 500,
    fetchPolicy: undefined,
  };

  static getDerivedStateFromProps(props, state) {
    if (!props.searchValue && !isEmpty(state.queryValues)) {
      return initialState;
    }
    return null;
  }

  state = initialState;

  debounceQuery = debounce(() => this.dispatchQuery(), this.props.debounceMS);

  componentDidMount() {
    this.dispatchQuery();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.searchValue &&
      this.props.searchValue !== prevProps.searchValue
    ) {
      this.initializeQuery();
    }
  }

  componentWillUnmount() {
    this.debounceQuery.cancel();
  }

  resetState = () => {
    this.setState(initialState);
  };

  initializeQuery = () => {
    this.setState({ pending: true });
    this.debounceQuery();
  };

  dispatchQuery = async () => {
    const { skipQuery, query, fetchPolicy } = this.props;
    const { apolloClient, buildQueryVariables } = this.props;

    const variables = buildQueryVariables(this.props);

    if (!skipQuery(variables)) {
      this.setState({ loading: true });

      const queryValues = await apolloClient.query({
        query,
        variables,
        fetchPolicy,
      });

      return this.setState({ queryValues, loading: false, pending: false });
    }

    return this.resetState();
  };

  render() {
    const childProps = {
      data: {},
      loading: this.state.loading,
      pending: this.state.pending,
      searchValue: this.props.searchValue,
      ...this.state.queryValues,
    };

    return this.props.children(childProps);
  }
}

export const buildApolloConsumerChild = curry(
  (componentProps, apolloClient) => (
    <DebouncedQuery {...componentProps} apolloClient={apolloClient} />
  ),
);

function DebouncedQueryWithApolloClient(componentProps) {
  return (
    <ApolloConsumer>{buildApolloConsumerChild(componentProps)}</ApolloConsumer>
  );
}
export default compose(setDisplayName('DebouncedQuery'))(
  DebouncedQueryWithApolloClient,
);

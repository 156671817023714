import gql from 'graphql-tag';

export const NAME = 'requestsPanel_caseCustomerQuery';

const query = gql`
  query requestsPanel_caseCustomerQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      customer(useCachedDataForCase: true) {
        ... on Customer {
          id
          type
          name
        }
        ... on StoreCustomer {
          id
          type
          name
        }
        ... on CustomCustomer {
          type
          name
        }
        ... on CustomStoreCustomer {
          type
          name
        }
      }
    }
  }
`;

export default query;

/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  applyStyleModifiers,
  styleModifierPropTypes,
  applyResponsiveStyleModifiers,
} from 'styled-components-modifiers';

import {
  buildThemePropTypes,
  validateTheme,
} from 'styled-components-theme-validator';

import {
  displayModifiers,
  padScaleModifiers,
  dimensionModifiers,
} from './utils/modifiers';

const COMPONENT_NAME = 'Column';

export const MODIFIER_CONFIG = {
  ...dimensionModifiers,
  ...displayModifiers,
  ...padScaleModifiers,
  center: () => ({
    styles: css`
      justify-content: center;
      text-align: center;
    `,
  }),
  col: () => ({
    styles: css`
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      -ms-flex-positive: 1;
    `,
  }),
  col_1: () => ({
    styles: css`
      flex-basis: 8.33333333%;
      max-width: 8.33333333%;
      -ms-flex-preferred-size: 8.33333333%;
    `,
  }),
  col_2: () => ({
    styles: css`
      flex-basis: 16.66666667%;
      max-width: 16.66666667%;
      -ms-flex-preferred-size: 16.66666667%;
    `,
  }),
  col_3: () => ({
    styles: css`
      flex-basis: 25%;
      max-width: 25%;
      -ms-flex-preferred-size: 25%;
    `,
  }),
  col_4: () => ({
    styles: css`
      flex-basis: 33.33333333%;
      max-width: 33.33333333%;
      -ms-flex-preferred-size: 33.33333333%;
    `,
  }),
  col_5: () => ({
    styles: css`
      flex-basis: 41.66666667%;
      max-width: 41.66666667%;
      -ms-flex-preferred-size: 41.66666667%;
    `,
  }),
  col_6: () => ({
    styles: css`
      flex-basis: 50%;
      max-width: 50%;
      -ms-flex-preferred-size: 50%;
    `,
  }),
  col_7: () => ({
    styles: css`
      flex-basis: 58.33333333%;
      max-width: 58.33333333%;
      -ms-flex-preferred-size: 58.33333333%;
    `,
  }),

  col_8: () => ({
    styles: css`
      flex-basis: 66.66666667%;
      max-width: 66.66666667%;
      -ms-flex-preferred-size: 66.66666667%;
    `,
  }),

  col_9: () => ({
    styles: css`
      flex-basis: 75%;
      max-width: 75%;
      -ms-flex-preferred-size: 75%;
    `,
  }),
  col_10: () => ({
    styles: css`
      flex-basis: 83.33333333%;
      max-width: 83.33333333%;
      -ms-flex-preferred-size: 83.33333333%;
    `,
  }),
  col_11: () => ({
    styles: css`
      flex-basis: 91.66666667%;
      max-width: 91.66666667%;
      -ms-flex-preferred-size: 91.66666667%;
    `,
  }),
  col_12: () => ({
    styles: css`
      flex-basis: 100%;
      max-width: 100%;
      -ms-flex-preferred-size: 100%;
    `,
  }),
  col_offset_1: () => ({
    styles: css`
      margin-left: 8.33333333%;
    `,
  }),
  col_offset_2: () => ({
    styles: css`
      margin-left: 16.66666667%;
    `,
  }),
  col_offset_3: () => ({
    styles: css`
      margin-left: 25%;
    `,
  }),
  col_offset_4: () => ({
    styles: css`
      margin-left: 33.33333333%;
    `,
  }),
  col_offset_5: () => ({
    styles: css`
      margin-left: 41.66666667%;
    `,
  }),
  col_offset_6: () => ({
    styles: css`
      margin-left: 50%;
    `,
  }),
  col_offset_7: () => ({
    styles: css`
      margin-left: 58.33333333%;
    `,
  }),
  col_offset_8: () => ({
    styles: css`
      margin-left: 66.66666667%;
    `,
  }),
  col_offset_9: () => ({
    styles: css`
      margin-left: 75%;
    `,
  }),
  col_offset_10: () => ({
    styles: css`
      margin-left: 83.33333333%;
    `,
  }),
  col_offset_11: () => ({
    styles: css`
      margin-left: 91.66666667%;
    `,
  }),
  end: () => ({
    styles: css`
      justify-content: flex-end;
      text-align: end;
    `,
  }),
  fluid: () => ({
    styles: css`
      padding-left: 0;
      padding-right: 0;
    `,
  }),
  reverse: () => ({
    styles: css`
      flex-direction: column-reverse;
    `,
  }),
  start: () => ({
    styles: css`
      justify-content: flex-start;
      text-align: start;
    `,
  }),
};

const THEME_PROPTYPES = buildThemePropTypes({
  dimensions: PropTypes.shape({
    gridPad: PropTypes.string.isRequired,
  }).isRequired,
});

const Column = styled.div`
  ${validateTheme(COMPONENT_NAME, THEME_PROPTYPES)}
  box-sizing: border-box;
  flex: 0 0 auto;
  padding: ${(props) => props.theme.dimensions.gridPad};
  position: relative;
  ${applyStyleModifiers(MODIFIER_CONFIG)}
  ${applyResponsiveStyleModifiers(MODIFIER_CONFIG)}
`;

Column.propTypes = {
  className: PropTypes.string,
  modifiers: styleModifierPropTypes(MODIFIER_CONFIG),
};

Column.defaultProps = {
  className: COMPONENT_NAME,
};

Column.displayName = COMPONENT_NAME;
Column.modifiers = MODIFIER_CONFIG;

export default Column;

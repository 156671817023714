import React, { Component } from 'react';
import Bugsnag from '@bugsnag/browser';
import PropTypes from 'prop-types';

class TestEnvErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  componentDidCatch(error, info) {
    // eslint-disable-next-line no-console
    console.error(
      'TestEnvErrorBoundary has caught the following error:',
      error,
      info,
    );
  }

  render() {
    return this.props.children;
  }
}

/**
 * Creates an error boundary component, with reporting to bugsnag if not in the test env.
 */
function init() {
  if (process.env.NODE_ENV === 'test') return TestEnvErrorBoundary;

  return Bugsnag.getPlugin('react').createErrorBoundary(React);
}

export default { init };

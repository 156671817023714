import { includes } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import Icon from 'decisiv-iconix-react';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import { modifiersForComponent } from 'utils/modifiers';

const IconBackground = buildStyledComponent(
  'IconBackground',
  styled.div,
  ({ theme }) => `
    align-items: center;
    background: ${theme.colors.base.chrome200};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    height: ${px2rem(35)};
    width: ${px2rem(35)};
  `,
  {
    modifierConfig: {
      info: ({ theme }) => `
        background: ${theme.colors.status.info};
      `,
    },
    themePropTypes: {
      colors: PropTypes.shape({
        base: PropTypes.shape({
          chrome200: PropTypes.string.isRequired,
        }).isRequired,
        status: PropTypes.shape({
          info: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    },
  },
);

function InputIcon(props) {
  const { modifiers, ...rest } = props;
  const bgModifiers = modifiersForComponent(modifiers, IconBackground);

  const iconModifiers = [
    'small',
    includes(modifiers, 'info') && 'chrome000',
    ...modifiersForComponent(modifiers, Icon),
  ].filter((mod) => mod !== 'info');

  return (
    <IconBackground modifiers={bgModifiers}>
      <Icon modifiers={iconModifiers} {...rest} />
    </IconBackground>
  );
}

InputIcon.defaultProps = {
  modifiers: [],
};

InputIcon.propTypes = {
  modifiers: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

export default InputIcon;

import React from 'react';
import qs from 'qs';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { Query } from '@apollo/client/react/components';
import { curry, get, isEmpty } from 'lodash';
import { Redirect, withRouter } from 'react-router-dom';
import {
  compose,
  withHandlers,
  setDisplayName,
  withPropsOnChange,
} from 'recompose';

import { Row, Column } from 'styled-components-grid';
import { H1, Text, Checkbox } from 'base-components';

import Panel from 'blocks/Panel';
import Loading from 'components/Loading';
import ScrollColumn from 'elements/ScrollColumn';
import { parseQueryParams } from 'utils/url';

import DealersTable from '../DealersTable';

import manageableDealersQuery from './manageableDealersQuery';

function Dealers(props) {
  const { dealers, dealersLoading } = props;
  const { excludeMrtAssociates, toggleExcludeMrtAssociates } = props;

  if (!dealersLoading && dealers.length === 1) {
    return <Redirect to={`/tech-admin/dealers/${dealers[0].id}`} />;
  }

  return (
    <>
      <Row>
        <Column modifiers={['col', 'padScale_0']}>
          <Panel modifiers={['padScale_5']}>
            <Row>
              <Column modifiers={['col', 'padScaleX_4', 'padScaleY_2']}>
                <H1 modifiers={['fontWeightLight']}>
                  {dealersLoading || isEmpty(dealers) ? (
                    <Trans>Loading...</Trans>
                  ) : (
                    <Trans>My Stores</Trans>
                  )}
                </H1>
                <Text>
                  <Trans>
                    Select one of the{' '}
                    <Text modifiers={['fontWeightBold']}>
                      {dealers.length} stores
                    </Text>{' '}
                    to view and edit details
                  </Trans>
                </Text>
              </Column>
            </Row>
            <Row>
              <Column modifiers={['col', 'padScaleX_4']}>
                <Checkbox
                  id="exclude-mrt-associates"
                  name="exclude-mrt-associates"
                  label={<Trans>Exclude MRT Associate Service Providers</Trans>}
                  checked={excludeMrtAssociates}
                  onChange={toggleExcludeMrtAssociates}
                />
              </Column>
            </Row>
          </Panel>
        </Column>
      </Row>
      <Row modifiers={['flex_1']}>
        {dealersLoading ? (
          <Loading />
        ) : (
          <ScrollColumn modifiers={['col', 'padScale_0']}>
            <Panel
              modifiers={['height_100', 'flex_column', 'padScale_0']}
              style={{ overflowY: 'scroll' }}
            >
              <Row modifiers={['flex_1']}>
                <DealersTable dealers={dealers} />
              </Row>
            </Panel>
          </ScrollColumn>
        )}
      </Row>
    </>
  );
}

Dealers.propTypes = {
  dealers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  dealersLoading: PropTypes.bool.isRequired,
  excludeMrtAssociates: PropTypes.bool.isRequired,
  toggleExcludeMrtAssociates: PropTypes.func.isRequired,
};

const buildQueryChild = curry((componentProps, queryProps) => {
  const { loading: dealersLoading } = queryProps;
  const dealers = get(queryProps, 'data.manageableDealers.dealers', []);

  return (
    <Dealers
      {...componentProps}
      dealers={dealers}
      dealersLoading={dealersLoading}
    />
  );
});

function DealersWithQuery(componentProps) {
  const { excludeMrtAssociates } = componentProps;

  return (
    <Query query={manageableDealersQuery} variables={{ excludeMrtAssociates }}>
      {buildQueryChild(componentProps)}
    </Query>
  );
}

export default compose(
  setDisplayName('Dealers'),
  withRouter,
  withPropsOnChange(['location'], ({ location }) => {
    const params = parseQueryParams(location.search);

    return { excludeMrtAssociates: params['exclude-mrt-associates'] === '1' };
  }),
  withHandlers({
    toggleExcludeMrtAssociates: (props) => () => {
      const { location, history, excludeMrtAssociates } = props;
      const params = parseQueryParams(location.search);

      history.replace({
        search: qs.stringify(
          {
            ...params,
            'exclude-mrt-associates': excludeMrtAssociates ? '0' : '1',
          },
          { skipNulls: true, encodeValuesOnly: true },
        ),
      });
    },
  }),
)(DealersWithQuery);

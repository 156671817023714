import { mutationType } from 'features/apolloOperationsObserver';

/* istanbul ignore file */
/**
 *
 * WorkSpace BFF related constants
 *
 */

// Error codes
export const AUTHENTICATION_ERROR = 'authentication_error';
export const NOT_FOUND_ERROR = 'not_found';

/**
 *
 * Internal application constants
 *
 */

export const CSRF_TOKEN = 'DECISIV_CSRF_TOKEN';
export const SESSION_TOKEN = 'DECISIV_SESSION_TOKEN';

// List of mutations that result in changes to a case.
export const caseMutations = [
  mutationType.updateCase,
  mutationType.updateCaseStatus,
  mutationType.updateCaseStatusChange,
  mutationType.createCaseDelay,
  mutationType.updateCaseDelay,
  mutationType.updateCaseDelayedService,

  mutationType.createCaseContact,
  mutationType.updateCaseContact,
  mutationType.deleteCaseContacts,

  mutationType.removeCustomerFromCase,
  mutationType.setCaseNationalCustomer,
  mutationType.setCaseNewStoreCustomer,
  mutationType.setCaseOtherCustomer,
  mutationType.setCaseStoreCustomer,
  mutationType.setCaseCustomerDomicile,
  mutationType.removeCustomerDomicileFromCase,

  mutationType.setCasePaymentDetails,
  mutationType.removeCasePaymentDetails,

  mutationType.createDealerResponse,
  mutationType.updateDealerResponse,
  mutationType.deleteDealerResponse,
  mutationType.createCustomDealer,
  mutationType.updateCustomDealer,

  mutationType.createCaseRequestLine,
  mutationType.updateCaseRequestLine,
  mutationType.deleteCaseRequestLine,

  mutationType.updateCaseAgreementLine,
  mutationType.resetAgreement,
  mutationType.resetAllAgreements,

  mutationType.createCaseSuppliedLine,
  mutationType.updateCaseSuppliedLine,
  mutationType.deleteCaseSuppliedLine,
  mutationType.resetSupplied,
  mutationType.resetAllSupplied,

  mutationType.createPrimaryAsset,
  mutationType.createRelatedAsset,
  mutationType.deleteAssets,
  mutationType.updateAsset,

  mutationType.setCaseAssetValidation,
  mutationType.sendToFixpix,
  mutationType.clearCaseHighlights,
];

export const invalidCharsForExporting = /[~`]/;

import React from 'react';
import moment from 'moment-timezone';
import { Trans } from '@lingui/macro';

import { Text } from 'base-components';
import { Row, Column } from 'styled-components-grid';

export default {
  cellKeyGetter: ({ id }) => `${id}:responseTime`,
  cellDataGetter: ({ responseTime }) => responseTime,
  name: 'responseTime',
  headerCellRenderer: () => (
    <Row>
      <Column modifiers={['padScaleX_2', 'padScaleY_3']}>
        <Text modifiers={['small', 'textLight']}>
          <Trans>Date/Time</Trans>
        </Text>
      </Column>
    </Row>
  ),
  dataCellRenderer: (responseTime) => (
    <Row modifiers={['height_100', 'middle']}>
      <Column>
        <Text>{moment(new Date(responseTime)).format('D MMM, h:mm A z')}</Text>
      </Column>
    </Row>
  ),
};

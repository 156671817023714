import { last } from 'lodash';

/**
 * @enum {DealerResponseStatusIconProps}
 */
const dealerResponseStatusIconProps = {
  accepted: { modifiers: ['accepted'], name: 'check' },
  rejected: { modifiers: ['rejected'], name: 'times' },
  unknown: { modifiers: ['unknown'], name: 'question' },
};

/**
 * Evaluates parameters and determines
 *
 * @param {object} options
 * @param {boolean} options.isServicingDealer
 * @param {DealerResponse[]} options.responses
 * @returns
 */
export function getDealerResponseStatusIndicatorProps({
  isServicingDealer,
  responses,
}) {
  if (isServicingDealer) {
    return dealerResponseStatusIconProps.accepted;
  }

  const { accepted } = last(responses);

  // accepted will be null if the status is unknown.
  if (accepted === false) {
    return dealerResponseStatusIconProps.rejected;
  }

  return dealerResponseStatusIconProps.unknown;
}

export const buildFocusReceiverId = (forKey) => (props) => {
  const { dealerIndex, rowIndex } = props;

  return `dealerSelection.${dealerIndex}.responses.${rowIndex}.${forKey}`;
};

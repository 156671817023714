import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

export const modifierConfig = {
  active: ({ theme }) => `
    background: ${theme.colors.base.chrome000};
    border-left: 2px solid ${theme.colors.brand.primary};
  `,

  ghost: ({ theme }) => `
    border: none;
    padding: ${px2rem(8)};

    &:hover {
      background: ${theme.colors.base.chrome100};
    }
  `,

  highlight: ({ theme }) => `
    background: ${theme.colors.base.chrome000};
  `,
};

const styles = ({ theme }) => `
  align-items: center;
  background: ${theme.colors.base.chrome100};
  border-left: 2px solid transparent;
  box-sizing: border-box;
  display: flex;
  height: ${theme.dimensions.leftNavWidthCollapsed};
  padding: 0;
  pointer-events: auto;


  &: hover {
   background: ${theme.colors.base.chrome000};

    .MenuItemTitleText {
      text-decoration: underline;
    }
  }
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome000: PropTypes.string.isRequired,
      chrome100: PropTypes.string.isRequired,
    }).isRequired,
    brand: PropTypes.shape({
      primary: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dimensions: PropTypes.shape({
    leftNavWidthCollapsed: PropTypes.string.isRequired,
  }).isRequired,
};

export default buildStyledComponent('MenuItemTitle', styled.div, styles, {
  modifierConfig,
  themePropTypes,
});

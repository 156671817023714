import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import { Container } from 'styled-components-grid';

import constants from './constants';

const { ARROW_WIDTH } = constants;

const styles = ({ theme, zIndex, hideContent }) => `
    ${theme.planes.sky1};
    background-color: ${theme.colors.base.background};
    border-radius: 0;
    display: ${hideContent ? 'none' : 'flex'};
    position: absolute;
    z-index: ${zIndex};
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overflow: -moz-scrollbars-none;
    right: auto;
    white-space: nowrap;
    max-height: ${px2rem(400)};
    overflow-y: auto;
  `;

const getTopValue = (hasArrow) =>
  hasArrow ? `calc(100% + ${px2rem(ARROW_WIDTH)})` : '100%';

const modifierConfig = {
  bottom: ({ arrow }) => `
    top: ${getTopValue(arrow)};
  `,

  bottomLeft: ({ theme, arrow }) => `
    top: ${getTopValue(arrow)};
    border-top-left-radius: ${theme.dimensions.borderRadius};
    left: 0;
  `,

  bottomRight: ({ theme, arrow }) => `
    top: ${getTopValue(arrow)};
    border-top-right-radius: ${theme.dimensions.borderRadius};
    right: 0;
  `,

  fullWidth: () => `
    min-width: 100%;
    left: 0;
    right: auto;
  `,
};

const propTypes = {
  hideContent: PropTypes.bool,
  zIndex: PropTypes.number,
  arrow: PropTypes.bool,
};

const defaultProps = {
  hideContent: false,
  zIndex: 2,
  arrow: false,
};

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      background: PropTypes.string.isRequired,
      shadow: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dimensions: PropTypes.shape({
    borderRadius: PropTypes.string.isRequired,
  }),
};

export const StyledDropdownContent = buildStyledComponent(
  'Dropdown__Content',
  styled.div,
  styles,
  {
    defaultProps,
    modifierConfig,
    propTypes,
    themePropTypes,
  },
);

function DropdownContent(props) {
  return (
    <StyledDropdownContent {...props}>
      <Container modifiers={['padScale_0']}>{props.children}</Container>
    </StyledDropdownContent>
  );
}

DropdownContent.displayName = 'Dropdown__Content';

DropdownContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DropdownContent;

import gql from 'graphql-tag';

export default gql`
  mutation updateCaseHeadsUpNoteMutation($caseId: ID!, $note: String) {
    updateCase(input: { id: $caseId, patch: { quickNote: $note } }) {
      case {
        id
        quickNote
      }
    }
  }
`;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { H2, P } from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Column, Row } from 'styled-components-grid';

import Modal from 'components/Modal';

import { PAYMENT_METHODS } from '../constants';
import withSetPaymentDetails from '../withSetPaymentDetails';

import { cardInfoPropType, FIELDS } from './constants';
import InvalidCardModalBody from './InvalidCardModalBody';
import ValidCardModalBody from './ValidCardModalBody';

class SubmitCardModalBody extends Component {
  static propTypes = {
    // caseId is not used by the component but is used by the mutation...
    // eslint-disable-next-line react/no-unused-prop-types
    caseId: PropTypes.string.isRequired,
    cardInfo: cardInfoPropType.isRequired,
    closeModal: PropTypes.func.isRequired,
    retryEdit: PropTypes.func.isRequired,
    setPaymentDetails: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    setPaymentDetailsData: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    setPaymentDetailsError: PropTypes.object,
    setPaymentDetailsLoading: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    setPaymentDetailsData: null,
    setPaymentDetailsError: null,
  };

  componentDidMount() {
    const { setPaymentDetails } = this.props;
    setPaymentDetails({
      paymentMethod: PAYMENT_METHODS.CARD_ON_FILE,
      tokenizedCreditCard: this.tokenizedCard(),
    });
  }

  /**
   * Transform the card info obtained from the form (via props) into
   * the format expected by the mutation.
   */
  tokenizedCard = () => {
    const { cardInfo } = this.props;
    return {
      expirationMonth: parseInt(cardInfo[FIELDS.expMonth.name], 10),
      expirationYear: parseInt(cardInfo[FIELDS.expYear.name], 10),
      firstDigit: cardInfo[FIELDS.firstDigit.name],
      last4Digits: cardInfo[FIELDS.lastFourDigits.name],
      name: cardInfo[FIELDS.cardholder.name],
    };
  };

  render() {
    const {
      cardInfo,
      closeModal,
      retryEdit,
      setPaymentDetailsData,
      setPaymentDetailsError,
      setPaymentDetailsLoading,
    } = this.props;

    if (!setPaymentDetailsLoading) {
      if (setPaymentDetailsError) {
        return (
          <InvalidCardModalBody
            cardInfo={cardInfo}
            closeModal={closeModal}
            retryEdit={retryEdit}
          />
        );
      }
      if (setPaymentDetailsData) {
        return (
          <ValidCardModalBody cardInfo={cardInfo} closeModal={closeModal} />
        );
      }
    }

    return (
      <Modal.Body>
        <Modal.Header>
          <Modal.HeaderIcon name="credit-card" />
        </Modal.Header>
        <Modal.Content>
          <Row modifiers={['center']}>
            <Column>
              <H2 modifiers={['fontWeightRegular']}>
                <Trans>Credit Card Validation in Progress...</Trans>
              </H2>
            </Column>
          </Row>
          <Row modifiers={['center']}>
            <Column>
              <P style={{ marginBottom: px2rem(20) }}>
                <Trans>Please wait while the credit card is validated.</Trans>
              </P>
            </Column>
          </Row>
        </Modal.Content>
      </Modal.Body>
    );
  }
}

export default compose(
  setDisplayName('SubmitCardModalBody'),
  withSetPaymentDetails,
)(SubmitCardModalBody);

import gql from 'graphql-tag';
import { curry, get } from 'lodash';
import React from 'react';
import { Query } from '@apollo/client/react/components';

export const NAME = 'caseTokenizedCardQuery';

export const caseTokenizedCardGQL = gql`
  query caseTokenizedCardQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      paymentMethod
      tokenizedCreditCard {
        expirationMonth
        expirationYear
        firstDigit
        last4Digits
        name
      }
    }
  }
`;

function skipQuery({ caseNumber }) {
  return !caseNumber;
}

function buildQueryVariables({ caseNumber }) {
  return { caseNumber };
}

const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const tokenizedCard = get(queryProps, [
      'data',
      'case',
      'tokenizedCreditCard',
    ]);
    return (
      <WrappedComponent {...componentProps} tokenizedCard={tokenizedCard} />
    );
  },
);

const withCaseTokenizedCard = (WrappedComponent) => (componentProps) => (
  <Query
    fetchPolicy="network-only"
    query={caseTokenizedCardGQL}
    skip={skipQuery(componentProps)}
    variables={buildQueryVariables(componentProps)}
  >
    {buildQueryChild(WrappedComponent, componentProps)}
  </Query>
);

export default withCaseTokenizedCard;

import gql from 'graphql-tag';

export default gql`
  query afterHoursContactsQuery($dealerId: ID!) {
    afterHoursContacts(input: { dealerId: $dealerId }) {
      contacts {
        id
        jobTitle
        name
        primaryPhoneNumber
        secondaryPhoneNumber
      }
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';

import { Label, Text } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

export default function ContactAttribute({ label, value, ...rest }) {
  return (
    <Container modifiers="padScaleY_0" {...rest}>
      <Row modifiers={['flex_1', 'middle']}>
        <Column modifiers={['flex_1', 'padScale_0']}>
          <Label>{label}</Label>
        </Column>
      </Row>
      <Row modifiers={['flex_1', 'middle']}>
        <Column modifiers={['flex_1', 'padScale_0']}>
          <Text>{value || <span>&mdash;</span>}</Text>
        </Column>
      </Row>
    </Container>
  );
}

ContactAttribute.propTypes = {
  label: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
};

import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';
import { Column } from 'styled-components-grid';

// TODO: These styles should really be a special use modifier on the `Column`
// component, but that is currently not possible.
const styles = () => `
  -ms-overflow-style: none;
  overflow: scroll;
  overflow: -moz-scrollbars-none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export default buildStyledComponent('ScrollColumn', styled(Column), styles);

import React from 'react';
import { Query } from '@apollo/client/react/components';
import { curry, get } from 'lodash';

import caseQuery from './caseQuery';

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, { data, loading = false }) => {
    const caseData = get(data, 'case') || {};
    const program = caseData.inboundProgramNumber || {};
    const { requiresValidationForAssetTypes } = program;

    const validateAssetTypes = (requiresValidationForAssetTypes || []).filter(
      (item) => !!get(item, 'requirements.length'),
    );

    return (
      <WrappedComponent
        {...componentProps}
        caseId={caseData.id}
        isLoadingCase={loading}
        inboundProgramShipTo={caseData.inboundProgramShipTo}
        inboundProgramNumberId={program.id}
        requiresValidationForAssetTypes={validateAssetTypes}
      />
    );
  },
);

function buildQueryVariables({ caseNumber }) {
  return { caseNumber };
}

const withCase = (WrappedComponent) => (componentProps) => (
  <Query query={caseQuery} variables={buildQueryVariables(componentProps)}>
    {buildQueryChild(WrappedComponent, componentProps)}
  </Query>
);

export default withCase;

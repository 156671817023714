import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { find, isEmpty, get } from 'lodash';

import { px2rem } from 'decisiv-ui-utils';
import { Container, Row, Column } from 'styled-components-grid';
import { Dropdown, InputField, MessageSmall, Popover } from 'base-components';

import GroupList from '../GroupList';
import ErrorMessage from './ErrorMessage';
import GroupListItem from '../GroupList/Item';
import { sortAndGroupCustomers } from '../utils';
import { selectorTypes, selectorConfig } from './constants';

export class CustomerSelector extends Component {
  static propTypes = {
    selectorType: PropTypes.oneOf(Object.values(selectorTypes)).isRequired,
    handleValidation: PropTypes.func.isRequired,
    isReadOnlyCase: PropTypes.bool.isRequired,
    isRequired: PropTypes.bool,
    isSearching: PropTypes.bool,
    isInvalid: PropTypes.bool.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        billTo: PropTypes.string,
        city: PropTypes.string,
        id: PropTypes.string.isRequired,
        name: PropTypes.string,
        shipTo: PropTypes.string,
        state: PropTypes.string,
      }),
    ),
    onChangeSearchValue: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    selectedItem: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    resetSelector: PropTypes.func.isRequired,
    searchValue: PropTypes.string,
    validateSelector: PropTypes.func.isRequired,
    onFocusRequested: PropTypes.func,
  };

  static defaultProps = {
    selectedItem: {},
    searchValue: '',
    items: [],
    isSearching: false,
    onFocusRequested: () => {},
    isRequired: false,
  };

  handleSelect = (_, id) => {
    if (id) {
      const item = find(this.props.items, (c) => c.id === id);
      this.props.handleValidation(false);
      this.props.onSelect(id, item);
    }
  };

  renderItems = (searchValue, items) => {
    const groupedCustomers = sortAndGroupCustomers(items);

    if (searchValue && searchValue.length >= 3 && isEmpty(groupedCustomers)) {
      return <ErrorMessage />;
    }

    return (
      <GroupList
        highlightText={searchValue}
        groupedCustomers={groupedCustomers}
      />
    );
  };

  renderPopoverContent = (show) => {
    const {
      searchValue,
      selectorType,
      isReadOnlyCase,
      onChangeSearchValue,
      isRequired,
    } = this.props;

    const { label, placeholder, optionalLabel } = selectorConfig[selectorType];

    return (
      <InputField
        isValid={!this.props.isInvalid}
        onBlur={this.props.validateSelector}
        onFocus={show}
        onChange={(event) => onChangeSearchValue(event.target.value)}
        placeholder={i18n._(placeholder)}
        readOnly={isReadOnlyCase}
        value={searchValue}
      >
        {this.renderAvatar()}
        <Column modifiers={['col', 'padScale_0']}>
          <Row>
            <InputField.Label>
              {isRequired && <Trans id={label} />}
              {!isRequired && <Trans id={optionalLabel} />}
            </InputField.Label>
          </Row>
          <Row>
            <InputField.TextField ref={this.props.onFocusRequested} />
            {searchValue && (
              <InputField.ActionButton
                icon="times"
                type="button"
                onClick={this.props.resetSelector}
                modifiers={['hoverDanger', 'padScaleX_0']}
              />
            )}
          </Row>
        </Column>
      </InputField>
    );
  };

  renderAvatar = () => {
    const { selectedItem } = this.props;

    if (selectedItem && selectedItem.name) {
      return (
        <InputField.Avatar
          name={selectedItem.name}
          modifiers={['primary', 'small']}
          isCompany
        />
      );
    }
    return <InputField.Avatar modifiers={['secondary', 'small']} isCompany />;
  };

  render() {
    const {
      searchValue,
      selectedItem,
      items,
      isReadOnlyCase,
      isSearching,
      selectorType,
    } = this.props;

    const hasPopover = get(selectedItem, 'type') === 'NATIONAL';

    return (
      <>
        <Dropdown
          fullWidth
          hideOnChange
          onChange={this.handleSelect}
          activeItem={get(selectedItem, 'id')}
          zIndex={2}
          readOnly={isReadOnlyCase}
        >
          {({ show }) => (
            <>
              <Container modifiers="padScale_0" onClick={show}>
                {hasPopover ? (
                  <Popover
                    arrow
                    showOnHover
                    position="right"
                    style={{ flex: 1 }}
                  >
                    <Popover.Target style={{ flex: 1 }}>
                      {this.renderPopoverContent(show)}
                    </Popover.Target>
                    <Popover.Content
                      style={{
                        padding: `${px2rem(10)} ${px2rem(14)}`,
                        width: px2rem(300),
                      }}
                    >
                      <GroupListItem
                        info={selectedItem}
                        isStore={false}
                        showAvatar={false}
                      />
                    </Popover.Content>
                  </Popover>
                ) : (
                  this.renderPopoverContent(show)
                )}
              </Container>
              <Dropdown.Content
                hideContent={
                  isSearching || !searchValue || searchValue.length < 3
                }
              >
                {this.renderItems(searchValue, items)}
              </Dropdown.Content>
            </>
          )}
        </Dropdown>
        {this.props.isInvalid && (
          <Row>
            <Column modifiers={['col', 'padScaleY_3']}>
              <MessageSmall type="warning">
                <Trans id={selectorConfig[selectorType].invalid} />
              </MessageSmall>
            </Column>
          </Row>
        )}
      </>
    );
  }
}

export default CustomerSelector;

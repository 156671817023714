import styled from 'styled-components';
import { buildStyledComponent } from 'decisiv-ui-utils';

const styles = `
  align-items: center;
  display: flex;
`;

const Content = buildStyledComponent('Content', styled.div, styles);

export default Content;

import React from 'react';
import { curry, get } from 'lodash';
import { Query } from '@apollo/client/react/components';
import gql from 'graphql-tag';

export const NAME = 'caseDetailsQuery';

const caseDetailGQL = gql`
  query caseDetailsQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
    }
  }
`;

function skipQuery({ caseNumber }) {
  return !caseNumber;
}

function buildQueryVariables({ caseNumber }) {
  return { caseNumber };
}

export const buildQueryChild = curry(
  (WrapperComponent, componentProps, queryProps) => {
    const { loading: caseIdLoading } = queryProps;
    const currentCaseId = get(queryProps, 'data.case.id');

    return (
      <WrapperComponent
        {...componentProps}
        caseIdLoading={caseIdLoading}
        currentCaseId={currentCaseId}
      />
    );
  },
);

const withCurrentCaseIdQuery = (WrappedComponent) => (componentProps) => (
  <Query
    query={caseDetailGQL}
    skip={skipQuery(componentProps)}
    variables={buildQueryVariables(componentProps)}
  >
    {buildQueryChild(WrappedComponent, componentProps)}
  </Query>
);

export default withCurrentCaseIdQuery;

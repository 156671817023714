import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import {
  fontColors,
  fontColorPropTypes,
  fontStyles,
  fontWeights,
  fontWeightPropTypes,
  spacing,
  spacingPropTypes,
} from '../../utils/modifiers';

import { TEXT_LINE_HEIGHT } from '../../utils/constants';
import em2rem from '../../utils/em2rem';

const DEFAULT_FONT_SIZE = 12;
const SMALL_FONT_SIZE = 10;

const customModifiers = {
  capitalize: () => 'text-transform: capitalize;',
  fontSizeRegular: () => `
    font-size: ${px2rem(DEFAULT_FONT_SIZE)};
    line-height: ${em2rem(DEFAULT_FONT_SIZE, TEXT_LINE_HEIGHT)};
  `,
  noWrap: () => 'white-space: nowrap;',
  small: () => `
    font-size: ${px2rem(SMALL_FONT_SIZE)};
    line-height: ${em2rem(SMALL_FONT_SIZE, TEXT_LINE_HEIGHT)};
  `,
  textLight: ({ theme }) => `
    color: ${theme.colors.base.textLight};
  `,
};

const styles = ({ theme }) => `
  color: ${theme.colors.base.text};
  display: inline-block;
  font-size: ${px2rem(DEFAULT_FONT_SIZE)};
  line-height: ${em2rem(DEFAULT_FONT_SIZE, TEXT_LINE_HEIGHT)};
`;

const customThemePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      text: PropTypes.string.isRequired,
      textLight: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const modifierConfig = {
  ...fontColors,
  ...fontStyles,
  ...fontWeights,
  ...spacing,
  ...customModifiers,
};

const themePropTypes = {
  ...fontColorPropTypes,
  ...fontWeightPropTypes,
  ...customThemePropTypes,
  ...spacingPropTypes,
};

export default buildStyledComponent('Text', styled.span, styles, {
  modifierConfig,
  themePropTypes,
});

import React from 'react';
import PropTypes from 'prop-types';
import { Text as StyledText } from '../../elements';

function Text(props) {
  const { modifiers, ...rest } = props;
  const defaultModifiers = ['small', 'capitalize'];
  const textModifiers = defaultModifiers.concat(modifiers);
  return <StyledText modifiers={textModifiers} {...rest} />;
}

Text.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

Text.defaultProps = {
  modifiers: [],
};

export default Text;

/* eslint-disable react/no-unused-state */

import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName } from 'recompose';

import { USER_ROLES, withUserRole } from 'features/rbac';

const { Provider, Consumer } = createContext();

const readOnlyRoles = [...USER_ROLES.CALL_CENTER_ONLY];

export class DealerDetailsContext extends Component {
  static propTypes = {
    role: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
  };

  state = { isReadOnly: readOnlyRoles.includes(USER_ROLES[this.props.role]) };

  UNSAFE_componentWillReceiveProps({ role }) {
    if (this.props.role !== role) {
      this.setState({ isReadOnly: readOnlyRoles.includes(USER_ROLES[role]) });
    }
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

const Context = compose(
  setDisplayName('DealerDetailsContext'),
  withUserRole,
)(DealerDetailsContext);

Context.Consumer = Consumer;

export default Context;

import styled from 'styled-components';
import { buildStyledComponent } from 'decisiv-ui-utils';

const styles = `
  opacity: 0;
  position: absolute;
  z-index: -1;
`;

const Checkbox = buildStyledComponent(
  'Checkbox',
  styled.input.attrs({ tabIndex: '0', type: 'checkbox' }),
  styles,
);

export default Checkbox;

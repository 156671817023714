import React from 'react';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import moment from 'moment-timezone';

import { SECONDS_PER_TIME_BLOCK, TIME_BLOCK_HEIGHT } from './constants';

function calculateEventPosition(day, event) {
  const { blockStart, blockEnd } = event;
  const { isOvernight, dayOffsetInSeconds: offset = 0 } = event;

  const topOffset = isOvernight && offset > 0 ? 0 : offset;

  // determine how many seconds from midnight this event starts and ends
  // (based on the block times, which fit into the current day, event if
  // the actual event start/end times span midnight)
  const startOfDay = moment(day).startOf('day');
  const startOffsetSeconds = Math.max(
    0,
    blockStart.diff(startOfDay, 'seconds') + topOffset,
  );

  const endOffsetSeconds = blockEnd.diff(startOfDay, 'seconds') + offset;

  // get the number of pixels from the top of the day column to offset this block
  const top =
    (startOffsetSeconds / SECONDS_PER_TIME_BLOCK) * TIME_BLOCK_HEIGHT + 1;

  // get the height of this block in pixels
  const height =
    ((endOffsetSeconds - startOffsetSeconds) / SECONDS_PER_TIME_BLOCK) *
      TIME_BLOCK_HEIGHT -
    1;

  return { top, height };
}

export default function ScheduleBlockPositioner({ day, event, children }) {
  const { top, height } = calculateEventPosition(day, event);

  return <>{children({ top, height })}</>;
}

ScheduleBlockPositioner.propTypes = {
  children: PropTypes.func.isRequired,
  day: PropTypes.oneOfType([
    MomentPropTypes.momentString,
    MomentPropTypes.momentObj,
  ]).isRequired,
  event: PropTypes.shape({
    blockStart: MomentPropTypes.momentObj.isRequired,
    blockEnd: MomentPropTypes.momentObj.isRequired,
  }).isRequired,
};

import styled from 'styled-components';
import PropTypes from 'prop-types';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import Content from './Content';

const ARROW_WIDTH = 11;

const styles = ({ theme }) => `
  ${theme.planes.sky1};
  position: relative;
  z-index: 1;

  ::after {
    ${theme.planes.sky1};
    background: ${theme.colors.base.background};
    content: '';
    height: ${px2rem(ARROW_WIDTH)};
    left: ${px2rem(10)};
    position: absolute;
    top: -${px2rem(ARROW_WIDTH / 2)};
    transform: rotate(45deg);
    width: ${px2rem(ARROW_WIDTH)};
    z-index: -1;
  }
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      background: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  planes: PropTypes.shape({
    sky1: PropTypes.string.isRequired,
  }).isRequired,
};

const RouteInfo = buildStyledComponent('RouteInfo', styled.div, styles, {
  themePropTypes,
});

RouteInfo.Content = Content;

export default RouteInfo;

import PropTypes from 'prop-types';
import React from 'react';

import Label from './Label';

export default function LocalTime({ relativeNow }) {
  return <Label>{relativeNow.format('hh:mm A')}</Label>;
}

LocalTime.propTypes = {
  relativeNow: PropTypes.shape({
    format: PropTypes.func.isRequired,
  }).isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { isEmpty } from 'lodash';
import { compose, setDisplayName, branch } from 'recompose';

import { px2rem } from 'decisiv-ui-utils';
import { Accordion } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import { useCaseShortcut, CASE_SHORTCUT_PANELS } from 'features/keyShortcuts';

import withCustomerSelect from '../../withCustomerSelect';
import withStoreCustomerSelect from '../../withStoreCustomerSelect';
import useCustomerServicePreferences from './useCustomerServicePreferences';
import ServicePreferenceValue, { valuesConfig } from './ServicePreferenceValue';
import ValueLabel from '../ValueLabel';

const valuesConfigEntries = Object.entries(valuesConfig);

export function ServicePreferences(props) {
  const prefs = useCustomerServicePreferences(props);
  const hasData = !isEmpty(prefs);
  const customerId = props?.customer?.id;

  const [expanded, setExpanded] = React.useState(false);

  const { onFocusRequested } = useCaseShortcut({
    parent: CASE_SHORTCUT_PANELS.customer,
    id: 'servicePreferencesAction',
    name: t`Service Preferences`,
    shortcut: ['f', '3'],
    keywords: 'fleet service preferences',
    priority: 5,
    perform: () => hasData && setExpanded((e) => !e),
    deps: hasData,
  });

  return (
    <Accordion
      key={customerId}
      disabled={!hasData}
      expanded={expanded}
      onExpandedChange={({ expanded }) => setExpanded(expanded)}
    >
      <Accordion.Head>
        <Accordion.Title ref={onFocusRequested}>
          <Trans>Service Preferences</Trans>
        </Accordion.Title>
      </Accordion.Head>
      {hasData && (
        <Accordion.Body>
          <Container style={{ paddingBottom: px2rem(25) }}>
            {valuesConfigEntries.map(([type, { label }]) => (
              <Row key={type}>
                <Column modifiers="col_4">
                  <ValueLabel>
                    <Trans id={label} />
                  </ValueLabel>
                </Column>
                <Column modifiers="col">
                  <ServicePreferenceValue type={type} values={prefs} />
                </Column>
              </Row>
            ))}
          </Container>
        </Accordion.Body>
      )}
    </Accordion>
  );
}

ServicePreferences.propTypes = {
  customer: PropTypes.shape({ id: PropTypes.string }),
  caseNumber: PropTypes.string,
};

ServicePreferences.defaultProps = {
  customer: undefined,
  caseNumber: undefined,
};

const isNationalCustomer = ({ customerType, customerTypes }) =>
  customerType === customerTypes.NATIONAL.type;

export default compose(
  setDisplayName('ServicePreferences'),
  branch(isNationalCustomer, withCustomerSelect, withStoreCustomerSelect),
)(ServicePreferences);

import { Trans } from '@lingui/macro';
import React from 'react';
import PropTypes from 'prop-types';
import { MessageMedium } from 'base-components';
import { compose, setDisplayName } from 'recompose';
import { withTheme } from 'styled-components';

export function NoContactsMessage({ theme }) {
  return (
    <MessageMedium modifiers={['padScaleY_1']}>
      <MessageMedium.Header modifiers={['padScaleY_1']}>
        <MessageMedium.Icon name="address-book" />
      </MessageMedium.Header>
      <MessageMedium.Section modifiers={['padScaleY_1']}>
        <MessageMedium.Title modifiers={['textLight']}>
          <Trans>No Contacts</Trans>
        </MessageMedium.Title>
      </MessageMedium.Section>
      <MessageMedium.Section modifiers={['padScaleY_0', 'padScaleX_4']}>
        {/* FIXME: I've been seeing this color for text come up, I believe
            we need to update our texts to have this color */}
        <MessageMedium.Text style={{ color: `${theme.colors.base.chrome600}` }}>
          <Trans>
            Add your staff as contacts so you can add them to rotations
          </Trans>
        </MessageMedium.Text>
      </MessageMedium.Section>
    </MessageMedium>
  );
}

NoContactsMessage.propTypes = {
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      base: PropTypes.shape({
        chrome600: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default compose(
  setDisplayName('NoContactsMessage'),
  withTheme,
)(NoContactsMessage);

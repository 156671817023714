import styled, { css } from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

export default buildStyledComponent(
  'ButtonsContainer',
  styled.div,
  css`
    display: flex;
    flex: 1;
    justify-content: flex-end;
    padding: 0;

    @media (max-width: ${px2rem(1500)}) {
      flex-basis: 100%;
      justify-content: flex-start;
      margin-top: ${px2rem(10)};
    }
  `,
);

/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { get, isEmpty, sortBy } from 'lodash';

import { px2rem } from 'decisiv-ui-utils';
import { Popover, QuickActionButton } from 'base-components';
import { Column, Container, Row } from 'styled-components-grid';

import ContactItem from './ContactItem';
import ERSHours from './ERSHours';
import contactPropType from '../contactPropType';

const techContactTypes = [
  {
    id: 'primaryContact',
    label: t`Primary Contact`,
    priority: {
      open: 2,
      ers: 1,
      open_ers: 1,
    },
  },
  {
    id: 'backupContact',
    label: t`Backup Contact`,
    priority: {
      open: 2,
      ers: 1,
      open_ers: 2,
    },
  },
  {
    id: 'finalContact',
    label: t`Final Contact`,
    priority: {
      open: 2,
      ers: 1,
      open_ers: 3,
    },
  },
];

const storeContactTypes = [
  {
    id: 'storeContact',
    label: t`Service Provider`,
    priority: {
      open: 1,
      ers: 3,
      open_ers: 4,
    },
  },
  {
    id: 'afterHoursContact',
    label: t`After-Hours`,
    priority: {
      open: 3,
      ers: 2,
      open_ers: 5,
    },
  },
  {
    id: 'allContacts',
    label: t`Tech Admin Contacts`,
    priority: {
      open: 4,
      ers: 4,
      open_ers: 5,
    },
  },
];

export const allContactTypes = [...techContactTypes, ...storeContactTypes];

function ContactItemRow({ id, label, info, dealer, onOutboundCall }) {
  return (
    <Row key={id} modifiers={['padScale_0', 'start']}>
      <Column key={id} modifiers={['col', 'padScale_0']}>
        <ContactItem
          dealer={dealer}
          info={info}
          label={label}
          type={id}
          onOutboundCall={onOutboundCall}
        />
      </Column>
    </Row>
  );
}

ContactItemRow.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  info: contactPropType,
  dealer: PropTypes.shape({}).isRequired,
  onOutboundCall: PropTypes.func,
};

ContactItemRow.defaultProps = {
  info: {},
  label: null,
  onOutboundCall: null,
};

function ContactsPopover({
  dealer,
  contacts,
  position,
  onOutboundCall,
  rotationSchedule: { start, end },
  isOpen,
  isTechRotationActive,
}) {
  const isOnlyStoreContactAvailable =
    !isEmpty(contacts.storeContact) &&
    isEmpty(contacts.backupContact) &&
    isEmpty(contacts.finalContact) &&
    isEmpty(contacts.primaryContact);

  const availableContactTypes = isOnlyStoreContactAvailable
    ? storeContactTypes
    : sortBy(
        allContactTypes,
        [
          isTechRotationActive && isOpen && 'priority.open_ers',
          isTechRotationActive && 'priority.ers',
          isOpen && 'priority.open',
        ].find(Boolean),
      );

  return (
    <Popover position={position} zIndex={200}>
      <Popover.Target>
        <QuickActionButton modifiers={['iconOnly']}>
          <QuickActionButton.Icon name="chevron-down" />
        </QuickActionButton>
      </Popover.Target>
      <Popover.Content style={{ right: px2rem(-15) }}>
        <Container
          style={{
            width: px2rem(300),
            maxHeight: px2rem(200),
            overflowY: 'auto',
          }}
        >
          {start && end && (
            <ERSHours start={start} end={end} timezone={dealer.timezone} />
          )}
          {availableContactTypes.map((contactType) => {
            if (!contacts[contactType.id]) return null;

            if (Array.isArray(contacts[contactType.id])) {
              return contacts[contactType.id].map((contact, index) => (
                <ContactItemRow
                  id={contactType.id}
                  label={index === 0 ? contactType.label : null}
                  key={get(contact, 'primaryPhoneNumber', index)}
                  info={contact}
                  contactType={contactType}
                  dealer={dealer}
                  onOutboundCall={onOutboundCall}
                />
              ));
            }

            return (
              <ContactItemRow
                id={contactType.id}
                label={contactType.label}
                key={contactType.id}
                info={contacts[contactType.id]}
                contactType={contactType}
                dealer={dealer}
                onOutboundCall={onOutboundCall}
              />
            );
          })}
        </Container>
      </Popover.Content>
    </Popover>
  );
}

ContactsPopover.propTypes = {
  contacts: PropTypes.shape({
    backupContact: contactPropType,
    finalContact: contactPropType,
    primaryContact: contactPropType,
    storeContact: contactPropType,
  }).isRequired,
  position: PropTypes.string,
  dealer: PropTypes.shape({ timezone: PropTypes.string }).isRequired,
  onOutboundCall: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  isTechRotationActive: PropTypes.bool.isRequired,
  rotationSchedule: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
};

ContactsPopover.defaultProps = {
  position: 'bottomLeft',
  onOutboundCall: null,
  rotationSchedule: {},
};

export default ContactsPopover;

import { createHttpLink } from '@apollo/client';

import { bffAPILink } from 'utils/bff';

/**
 * The Apollo HTTP link with credentials included.
 * @type {ApolloLink}
 */
const httpLink = createHttpLink({
  credentials: 'include',
  uri: bffAPILink,
});

export default httpLink;

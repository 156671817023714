import gql from 'graphql-tag';

export const NAME = 'caseStatusHistory';

export default gql`
  query caseStatusHistory($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      createdAt
      createdBy {
        name
        email
      }
      status
      statusHistory {
        changedAt
        createdAt
        id
        newStatus
        oldStatus
        user {
          email
          name
        }
      }
    }
  }
`;

import React from 'react';
import { curry, get } from 'lodash';
import { Query } from '@apollo/client/react/components';

import activeAfterHoursContactsQuery from './activeAfterHoursContactsQuery';

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const { data } = queryProps;
    const contacts = get(data, 'activeAfterHoursContacts.contacts', {});

    return (
      <WrappedComponent
        {...componentProps}
        activeAfterHoursContacts={contacts}
      />
    );
  },
);

function withActiveAfterHoursContacts(WrappedComponent) {
  return (componentProps) => {
    const { dealer = {} } = componentProps;

    return (
      <Query
        query={activeAfterHoursContactsQuery}
        skip={!dealer.id}
        variables={{ dealerId: dealer.id }}
      >
        {buildQueryChild(WrappedComponent, componentProps)}
      </Query>
    );
  };
}

export default withActiveAfterHoursContacts;

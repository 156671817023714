import React, { useCallback } from 'react';
import { useMutation, gql } from '@apollo/client';

import { NAME as REQUEST_LINES_QUERY } from '../withCaseRequestLines';

const refetchQueries = [REQUEST_LINES_QUERY];

const mutation = gql`
  mutation deleteSuppliedLine($id: ID!) {
    deleteCaseSuppliedLine(input: { ids: [$id] }) {
      deletedCount
    }
  }
`;

const withRemoveCaseSuppliedLineMutation = (Component) => (props) => {
  const [mutate] = useMutation(mutation, { refetchQueries });

  // prettier-ignore
  const removeLine = useCallback(
    (id) => mutate({ variables: { id } }),
    [mutate],
  );

  return <Component {...props} removeRequestSuppliedLine={removeLine} />;
};

export default withRemoveCaseSuppliedLineMutation;

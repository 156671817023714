import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import MomentPropTypes from 'react-moment-proptypes';
import styled, { css } from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';
import { Label } from 'base-components';

import { SpinnerIcon } from '../../Spinner';
import TimeBlocks from './TimeBlocks';
import { GRID_HEIGHT, DAY_HEADER_HEIGHT } from './constants';

// assume 7 days (columns) in the schedule, round 100/7 down to 14...
const columnWidth = '14%';

const GridDayWrapper = buildStyledComponent(
  'ScheduleGrid__GridDay',
  styled.li,
  `
    width: ${columnWidth};
    margin-bottom: 0;
    position: relative;
  `,
  {
    modifierConfig: {
      firstOfMonth: () => ({
        styles: css`
          &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 2px;
            background: #e6e7e8;
            height: calc(100% + ${DAY_HEADER_HEIGHT});
          }
        `,
      }),
    },
  },
);

const DayHeaderWrapper = buildStyledComponent(
  'ScheduleGrid__GridDay__DayHeaderWrapper',
  styled.div,
  `
    position: relative;
    width: 100%;
    display: table;
    height: ${DAY_HEADER_HEIGHT};
    padding: 0;
  `,
);

const DayHeaderBody = buildStyledComponent(
  'ScheduleGrid__GridDay__DayHeaderBody',
  Label,
  `
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    line-height: ${px2rem(11.5)}; // to match the height of the spinner

    em {
      font-style: normal;
    }

    &.today {
      font-weight: 500;

      em {
        color: white;
        background: red;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        min-width: 20px;
        border-radius: 50%;
      }
    }
  `,
);

const EventList = buildStyledComponent(
  'ScheduleGrid__GridDay__EventList',
  styled.ul,
  `
    position: absolute;
    top: ${DAY_HEADER_HEIGHT};
    height: ${GRID_HEIGHT};
    width: 100%;
    display: block;
    overflow: visible;
  `,
);

/**
 * Renders a single vertical column for one day in the schedule
 * grid. Contains a header indicating the date, the grid-lines
 * separating time-blocks, and the list of events.
 *
 * @param {Object} params
 * @param {Moment|string} params.day - the date of the day being rendered
 * @param {boolean} params.last - is this the last day in the grid?
 * @return {ReactElement}
 */
export default function GridDay({ day, last, children, loading }) {
  const now = moment();
  const dayAsMoment = moment(day);
  const isToday = dayAsMoment.isSame(now, 'day');
  const isFirstOfMonth = dayAsMoment.date() === 1;

  return (
    <GridDayWrapper last={last} modifiers={isFirstOfMonth && 'firstOfMonth'}>
      <DayHeaderWrapper>
        <DayHeaderBody modifiers="uppercase" className={isToday && 'today'}>
          {dayAsMoment.format('ddd')}
          &nbsp;
          <em>{dayAsMoment.format('D')}</em>
          {loading && <SpinnerIcon modifiers={['mini']} name="refresh" />}
        </DayHeaderBody>
      </DayHeaderWrapper>
      <TimeBlocks last={last} />
      <EventList>{children}</EventList>
    </GridDayWrapper>
  );
}

GridDay.propTypes = {
  day: PropTypes.oneOfType([
    MomentPropTypes.momentString,
    MomentPropTypes.momentObj,
  ]).isRequired,
  last: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.node,
};

GridDay.defaultProps = {
  last: false,
  loading: false,
  children: null,
};

import gql from 'graphql-tag';

export default gql`
  mutation createPerHourReport(
    $billable: Boolean
    $startTime: Date!
    $endTime: Date!
  ) {
    createPerHourReport(
      filters: { billable: $billable, startTime: $startTime, endTime: $endTime }
    ) {
      jobId
    }
  }
`;

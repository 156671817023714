import { useState, useEffect } from 'react';
import { gql, useQuery, useSubscription } from '@apollo/client';

import query from './caseAssignedToQuery';

const watchForOwnerChanges =
  process.env.REACT_APP_ENABLE_CASE_OWNERSHIP_CHANGE_WATCHER === 'true';

const ownerSubscritopn = gql`
  subscription caseOwnerChangeSubscription($caseNumber: String!) {
    onCaseOwnerChanged(caseNumber: $caseNumber) {
      id
      assignedTo {
        name
        email
        userName
        firstName
        lastName
      }
    }
  }
`;

const useCaseOwner = (caseNumber) => {
  const [owner, setOwner] = useState();

  const { data, loading } = useQuery(query, {
    skip: !caseNumber,
    variables: { caseNumber },
    fetchPolicy: 'network-only',
  });

  const sub = useSubscription(ownerSubscritopn, {
    skip: !caseNumber || !watchForOwnerChanges,
    variables: { caseNumber },
  });

  const currentUser = data?.currentUserProfile?.user || {};
  const ownerFromSub = sub?.data?.onCaseOwnerChanged?.assignedTo;
  const ownerFromQuery = data?.case?.assignedTo;

  useEffect(() => {
    const newOwner = ownerFromSub !== undefined ? ownerFromSub : ownerFromQuery;

    if (newOwner?.email !== owner?.email) {
      setOwner(newOwner);
    }
  }, [owner, ownerFromQuery, ownerFromSub]);

  return {
    loading,
    currentUser,
    caseAssignedTo: owner,
  };
};

export default useCaseOwner;

import React, { createContext } from 'react';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import { get, keyBy } from 'lodash';
import { compose, setDisplayName, withStateHandlers } from 'recompose';

import { assetPropType } from 'compositions/CaseRequestsPanel/RequestAssetsForm/constants';

import withCaseAssetValidation from './withCaseAssetValidation';
import { getAssetData } from './utils';

import {
  assetValidationPropType,
  assetValidationRulesPropType,
} from './constants';

const { Provider, Consumer } = createContext();

const getRequirementsByType = memoize((rules) =>
  rules.reduce((acc, rule) => {
    if (!get(rule, 'requirements.length')) return acc;

    return { ...acc, [rule.type]: rule.requirements };
  }, {}),
);

const getValidationStatus = memoize(
  (validation, primaryAsset, relatedAssets, rules, isUsxpress) => {
    const requirementsByType = getRequirementsByType(rules);

    const assets = [primaryAsset, ...relatedAssets].map((asset, index) => ({
      ...getAssetData(asset, isUsxpress),
      index,
    }));

    return assets.reduce((validations, requestAsset) => {
      const attempts = keyBy(
        get(validation.entries, requestAsset.id, []),
        'type',
      );

      const requirements = (requirementsByType[requestAsset.type] || []).reduce(
        (acc, type) => {
          const attempt = attempts[type] || null;
          const isSameTypeAsRequest = type === requestAsset.type;

          const matchesRequest =
            get(attempt, 'type') === requestAsset.type &&
            get(attempt, 'asset.vehicleNumber') === requestAsset.number;

          const hasAsset = !!attempt && !!attempt.asset;
          const hasAttempt = !!attempt && !!attempt.reference;
          const isMismatch = hasAsset && isSameTypeAsRequest && !matchesRequest;
          const isValidated = hasAsset && !isMismatch;

          const status = {
            type,
            attempt,
            hasAsset,
            hasAttempt,
            isMismatch,
            isValidated,
            requestAsset,
            isSameTypeAsRequest,
          };

          return { ...acc, [type]: status };
        },
        {},
      );

      return {
        ...validations,
        [requestAsset.id]: { asset: requestAsset, requirements },
      };
    }, {});
  },
);

function CaseAssetValidationContext(props) {
  const { children, ...rest } = props;

  const value = {
    ...rest,
    assetValidationStatus: getValidationStatus(
      props.assetValidation,
      props.primaryAsset,
      props.relatedAssets || [],
      props.requiresValidationForAssetTypes,
      props.isUsxpress,
    ),
  };

  return <Provider value={value}>{children}</Provider>;
}

CaseAssetValidationContext.propTypes = {
  children: PropTypes.node.isRequired,
  isUsxpress: PropTypes.bool.isRequired,

  /* eslint-disable react/no-typos */
  primaryAsset: assetPropType.isRequired,
  relatedAssets: PropTypes.arrayOf(assetPropType).isRequired,
  assetValidation: assetValidationPropType.isRequired,
  requiresValidationForAssetTypes: assetValidationRulesPropType.isRequired,
  /* eslint-enable react/no-typos */
};

const initialState = {
  shouldShowAssetValidationModal: false,
  assetValidationModalInitialState: {},
};

const withModalState = withStateHandlers(initialState, {
  showAssetValidationModal: () => (state) => {
    if (!state.requestAsset) {
      // eslint-disable-next-line no-console
      console.error(
        'showAssetValidationModal: You must provide an "requestAsset". Got:',
        state,
      );

      return initialState;
    }

    if (!state.assetType) {
      // eslint-disable-next-line no-console
      console.error(
        'showAssetValidationModal: You must provide an "assetType". Got:',
        state,
      );

      return initialState;
    }

    return {
      shouldShowAssetValidationModal: true,
      assetValidationModalInitialState: state,
    };
  },

  hideAssetValidationModal: () => () => initialState,
});

const Context = compose(
  setDisplayName('CaseAssetValidationContext'),
  withCaseAssetValidation,
  // Must be last because it relies on props from the previous HOCs
  withModalState,
)(CaseAssetValidationContext);

Context.Consumer = Consumer;
Context.propTypes = { caseNumber: PropTypes.string.isRequired };

export default Context;

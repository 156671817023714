import gql from 'graphql-tag';

const updateAssetLocationSearchMutation = gql`
  mutation updateAssetLocationSearch(
    $countryCode: String!
    $location: Position
    $searchValue: String!
    $id: ID!
  ) {
    updateCase(
      input: {
        id: $id
        patch: {
          assetLocation: {
            countryCode: $countryCode
            coordinates: $location
            searchValue: $searchValue
          }
        }
      }
    ) {
      case {
        id
        assetLocation {
          countryCode
          coordinates {
            latitude
            longitude
          }
          searchValue
          address {
            city
            country
            postalCode
            province
            streetAddress
          }
        }
      }
    }
  }
`;

export default updateAssetLocationSearchMutation;

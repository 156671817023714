import { isEmpty } from 'lodash';

import { PANEL_STATUSES } from 'compositions/CaseStatus';

import {
  fieldIds,
  PAYMENT_METHODS,
  CREDIT_CARD_RULE_TYPES as ccRules,
} from '../constants';

const { incomplete, partial, complete } = PANEL_STATUSES;

const {
  CARD_ON_FILE: existingCC,
  OTHER_NATIONAL_ACCOUNT: otherNatAccount,
  MICHELIN_LINE_OF_CREDIT: natAccount,
  NONE: continueWithoutPayment,
  ONE_TIME_CARD: newCC,
  CREDIT_CARD_NO_PREAUTH: noPreAuth,
} = PAYMENT_METHODS;

// From https://docs.google.com/document/d/1g442usX08QWB0AfveSitIHKnlFfiq7Zb3eNeZvMA62Y/edit
// N => No CC Required; O => CC Optional; M => CC Mandatory
//
//
// CC_RULE = N, or CC_RULE = Z
//
// CC_RULE = O and selected “national account” payment method, or
// CC_RULE = O and entered valid existing credit card, or
// CC_RULE = O and entered valid new credit card, or
// CC_RULE in [O, M] and selected “Other National Account” payment method, or
//
// CC_RULE = M and entered valid existing credit card, or
// CC_RULE = M and entered valid new credit card, or
// CC_RULE = M and selected “continue without payment method”

export default ({
  tokenizedCard,
  setCasePanelStatus,
  paymentMethod: method,
  creditCardRuleAbbreviation: ccRule,
}) => {
  if (!method && ccRule !== ccRules.N) return setCasePanelStatus(incomplete);

  const hasCC = !isEmpty(tokenizedCard);

  if (
    [
      ccRule === ccRules.N,
      ccRule === ccRules.Z,
      ccRule === ccRules.O && method === noPreAuth,
      ccRule === ccRules.O && method === natAccount,
      ccRule === ccRules.O && method === existingCC && hasCC,
      ccRule === ccRules.O && method === newCC && hasCC,
      [ccRules.O, ccRules.M].includes(ccRule) && method === otherNatAccount,
      ccRule === ccRules.M && method === existingCC && hasCC,
      ccRule === ccRules.M && method === newCC && hasCC,
      ccRule === ccRules.M && method === continueWithoutPayment,
      ccRule === ccRules.M && method === noPreAuth,
    ].some(Boolean)
  ) {
    return setCasePanelStatus(complete);
  }

  const fields = {};

  if ([ccRules.O, ccRules.M].includes(ccRule) && !hasCC) {
    const id =
      method === newCC ? fieldIds.national.newCC : fieldIds.national.existingCC;

    fields[id] = { severity: 'missing' };
  }

  return setCasePanelStatus(partial, fields);
};

import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import MapControlButton from 'components/MapControlButton';

import Bar from './Bar';
import FastSlowLegend from './FastSlowLegend';

const barColors = ['#84ca50', '#f07d02', '#e60000', '#9e1313'];

const TrafficLayerToggle = ({ showTraffic, onClick }) => (
  <MapControlButton
    icon={showTraffic ? 'traffic-light-slash' : 'traffic-light'}
    style={{ marginLeft: 147, marginTop: -36 }}
    onClick={onClick}
  >
    {showTraffic && (
      <FastSlowLegend>
        <Trans>Fast</Trans>
        {barColors.map((color) => (
          <Bar key={color} color={color} />
        ))}
        <Trans>Slow</Trans>
      </FastSlowLegend>
    )}
  </MapControlButton>
);

TrafficLayerToggle.propTypes = {
  showTraffic: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default TrafficLayerToggle;

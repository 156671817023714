import Bugsnag from '@bugsnag/browser';
import { ApolloLink } from '@apollo/client';

const REQUEST_BREADCRUMB = 'GraphQL Request';
const RESPONSE_BREADCRUMB = 'GraphQL Response';

const getRequestValues = (operation) => {
  const { operationName, variables } = operation;

  return { operationName, variables };
};

const getResponseValues = (operation, operationData) => {
  const { data, errors } = operationData;
  const { operationName, variables } = operation;

  return { operationName, variables, data, errors };
};

/**
 * Factory function to create a new ApolloLink that observes all
 * queries / mutations and creates Bugsnag breadcrumbs for the
 * request and the response, including operation name, variables,
 * and response data/errors.
 */
const init = () =>
  new ApolloLink((op, forward) => {
    Bugsnag.leaveBreadcrumb(REQUEST_BREADCRUMB, getRequestValues(op));

    return forward(op).map((data) => {
      Bugsnag.leaveBreadcrumb(RESPONSE_BREADCRUMB, getResponseValues(op, data));

      return data;
    });
  });

export default { init };

import { compose, setDisplayName } from 'recompose';

import withRequestAssets from 'compositions/CaseRequestsPanel/RequestAssetsForm/withRequestAssets';
import withValidateAsset from './withValidateAsset';
import withSetAssetValidation from './withSetAssetValidation';
import withCaseAssetValidationData from './withCaseAssetValidationData';

// Order matters here
export default compose(
  setDisplayName('withCaseAssetValidation'),
  withRequestAssets,
  withCaseAssetValidationData,
  withSetAssetValidation,
  withValidateAsset,
);

import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

const styles = `
  pointer-events: none;
  position: relative;
`;

export default buildStyledComponent('MenuItem', styled.li, styles);

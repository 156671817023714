import { curry, get } from 'lodash';
import React from 'react';
import { Query, Mutation } from '@apollo/client/react/components';

import CustomerWidgetContext from '../../CustomerWidgetContext';
import updateCaseTerminalLocationMutation from './updateTerminalLocationMutation';
import { customerQuery } from '../../caseCustomerQuery';
import { setCaseCustomerPanelStatus } from '../../utils';

export const buildMutationChild = curry(
  (WrappedComponent, componentProps, updateTerminalLocation) => {
    const { caseId } = componentProps;

    return (
      <WrappedComponent
        {...componentProps}
        onUpdateTerminalLocation={(customerTerminalLocation) => {
          setCaseCustomerPanelStatus({
            ...componentProps,
            customerTerminalLocation,
          });

          if (!customerTerminalLocation) return;

          updateTerminalLocation({
            variables: { caseId, customerTerminalLocation },
          });
        }}
        onRemoveTerminalLocation={() => {
          setCaseCustomerPanelStatus({
            ...componentProps,
            customerTerminalLocation: '',
          });
          updateTerminalLocation({
            variables: { caseId, customerTerminalLocation: '' },
          });
        }}
      />
    );
  },
);

function buildWrappedComponentWithMutation(WrappedComponent, componentProps) {
  return (
    <Mutation mutation={updateCaseTerminalLocationMutation}>
      {buildMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const caseId = get(queryProps, 'data.case.id');
    const customer = get(queryProps, 'data.case.customer');
    const customerTerminalLocation = get(
      queryProps,
      'data.case.customerTerminalLocation',
    );

    return buildWrappedComponentWithMutation(WrappedComponent, {
      ...componentProps,
      caseId,
      customer,
      customerTerminalLocation,
    });
  },
);

function skipQuery({ caseNumber }) {
  return !caseNumber;
}

function buildQueryVariables({ caseNumber }) {
  return { caseNumber };
}

function buildWrappedComponentWithQuery(WrappedComponent, componentProps) {
  return (
    <Query
      query={customerQuery}
      skip={skipQuery(componentProps)}
      variables={buildQueryVariables(componentProps)}
      fetchPolicy="cache-and-network"
    >
      {buildQueryChild(WrappedComponent, componentProps)}
    </Query>
  );
}

export const buildConsumerChild = curry(
  (WrappedComponent, componentProps, consumerProps) => {
    const {
      caseNumber,
      setCasePanelComplete,
      setCasePanelIncomplete,
      setCasePanelPartial,
      setCasePanelInvalid,
    } = consumerProps;

    return buildWrappedComponentWithQuery(WrappedComponent, {
      ...componentProps,
      caseNumber,
      setCasePanelComplete,
      setCasePanelIncomplete,
      setCasePanelPartial,
      setCasePanelInvalid,
    });
  },
);

const withTerminalLocationSelect = (WrappedComponent) => (componentProps) => (
  <CustomerWidgetContext.Consumer>
    {buildConsumerChild(WrappedComponent, componentProps)}
  </CustomerWidgetContext.Consumer>
);

export default withTerminalLocationSelect;

import React from 'react';
import { Trans } from '@lingui/macro';

import { Text } from 'base-components';

import { AmazonConnect } from 'features/amazonConnect';

export default function UserCallStatus() {
  return (
    <AmazonConnect>
      {({ callState }) => (
        <Text modifiers={['small', 'fontWeightRegular']}>
          <Trans id={callState} />
        </Text>
      )}
    </AmazonConnect>
  );
}

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { compose, setDisplayName } from 'recompose';

import { px2rem } from 'decisiv-ui-utils';
import { Column, Container, Row } from 'styled-components-grid';
import { DateRangeSelector, H3, InputGroup, P } from 'base-components';

import withContext from 'utils/withContext';
import RadioButton from 'components/RadioButton';

import { reportTypes, reportTitles } from 'pages/Reports/constants';

import StaticListDropdown from './StaticListDropdown';
import ReportConfigBuilderContext from './Context';
import {
  billableStatusOptions,
  billableStatusTitles,
  configTypes,
  dateRangeTypes,
  dateRangeTypesTitles,
  instructionsByType,
  reportingPeriodOptions,
  reportingPeriodTitles,
  reportingPeriodTypes,
  btStModeTypes,
  btStModeTypesTitles,
} from './constants';

import { formatReportingPeriod } from './utils';

const ReportConfigBuilderHeader = (props) => {
  const { config, reportType, updateReportConfig, showBillableStatus } = props;

  const formattedReportingPeriod = formatReportingPeriod(config);
  const {
    [configTypes.billableStatus]: billableStatus,
    [configTypes.reportingPeriod]: reportingPeriod,
  } = config;

  return (
    <Container modifiers="padScale_0" style={{ zIndex: 2 }}>
      <Row>
        <Column modifiers={['col', 'padScale_0']}>
          <H3 modifiers="fontWeightRegular">
            <Trans id={reportTitles[reportType]} />
          </H3>
          {instructionsByType[reportType] && (
            <P>
              <Trans id={instructionsByType[reportType]} />
            </P>
          )}
        </Column>
      </Row>
      <Row style={{ marginTop: px2rem(40) }}>
        <Container modifiers="padScale_0">
          <TogglesRow {...props} />
          <Row>
            <Column modifiers={['col', 'padScale_0']}>
              <InputGroup>
                <InputGroup.Row>
                  <InputGroup.Column modifiers={['flex_1']}>
                    <StaticListDropdown
                      icon="calendar"
                      name={configTypes.reportingPeriod}
                      activeItem={reportingPeriod}
                      value={`${i18n._(
                        reportingPeriodTitles[reportingPeriod],
                      )}${
                        formattedReportingPeriod
                          ? ` (${formattedReportingPeriod})`
                          : ''
                      }`}
                      label={<Trans>Reporting Period</Trans>}
                      onChange={updateReportConfig}
                      options={reportingPeriodOptions}
                    />
                  </InputGroup.Column>
                  {showBillableStatus && (
                    <InputGroup.Column modifiers={['flex_1']}>
                      <StaticListDropdown
                        name={configTypes.billableStatus}
                        activeItem={billableStatus}
                        value={i18n._(billableStatusTitles[billableStatus])}
                        label={<Trans>Billable/Not Billable</Trans>}
                        onChange={updateReportConfig}
                        options={billableStatusOptions}
                      />
                    </InputGroup.Column>
                  )}
                </InputGroup.Row>
              </InputGroup>
            </Column>
          </Row>
        </Container>
      </Row>
      {reportingPeriod === reportingPeriodTypes.custom && (
        <Row>
          <Column
            style={{
              margin: `0 ${px2rem(-8)}`,
              maxWidth: px2rem(930),
              flex: 1,
            }}
            modifiers={['col', 'padScale_0']}
          >
            <DateRangeSelector
              multiMonth
              multiMonthDirection="past"
              onSelectDate={(dates) =>
                updateReportConfig(configTypes.customReportingPeriod, dates)
              }
              disabledDates={{ after: new Date() }}
            />
          </Column>
        </Row>
      )}
    </Container>
  );
};

ReportConfigBuilderHeader.propTypes = {
  config: PropTypes.shape({
    [configTypes.billableStatus]: PropTypes.string.isRequired,
    [configTypes.reportingPeriod]: PropTypes.string.isRequired,
  }).isRequired,
  dateRangeTypeOptions: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(dateRangeTypes)),
  ).isRequired,
  reportType: PropTypes.oneOf(Object.values(reportTypes)).isRequired,
  updateReportConfig: PropTypes.func.isRequired,
  showBillableStatus: PropTypes.bool,
  showBtStModelToggle: PropTypes.bool.isRequired,
};

ReportConfigBuilderHeader.defaultProps = {
  showBillableStatus: true,
};

const TogglesRow = (props) => {
  const { config, updateReportConfig: set } = props;
  const { showBtStModelToggle, dateRangeTypeOptions } = props;

  const onBtStModeChange = useCallback(
    ({ target: { name, value } }) => {
      const fieldsToClear =
        name === btStModeTypes.bt
          ? [configTypes.dealerLocation, configTypes.customerLocation]
          : [configTypes.dealerName, configTypes.customerName];

      set({
        ...fieldsToClear.reduce(
          (acc, field) => ({
            ...acc,
            [field]: undefined,
            [`${field}_SEARCH_VALUE`]: undefined,
          }),
          {},
        ),
        [configTypes.btStModeType]: value,
      });
    },
    [set],
  );

  if (!showBtStModelToggle && !dateRangeTypeOptions.length) return null;

  return (
    <Row style={{ marginBottom: px2rem(10) }}>
      {showBtStModelToggle && (
        <Column modifiers={['col', 'padScale_0']}>
          <RadioButton
            id="mode_bt"
            name={btStModeTypes.bt}
            value={btStModeTypes.bt}
            label={<Trans id={btStModeTypesTitles[btStModeTypes.bt]} />}
            style={{ marginRight: px2rem(20) }}
            checked={config[configTypes.btStModeType] === btStModeTypes.bt}
            onChange={onBtStModeChange}
          />
          <RadioButton
            id="mode_st"
            name={btStModeTypes.st}
            value={btStModeTypes.st}
            label={<Trans id={btStModeTypesTitles[btStModeTypes.st]} />}
            checked={config[configTypes.btStModeType] === btStModeTypes.st}
            onChange={onBtStModeChange}
          />
        </Column>
      )}
      {dateRangeTypeOptions.length > 1 && (
        <Column modifiers={['col', 'padScale_0', showBtStModelToggle && 'end']}>
          {dateRangeTypeOptions.map((type) => (
            <RadioButton
              id={type}
              key={type}
              label={<Trans id={dateRangeTypesTitles[type]} />}
              value={type}
              checked={config[configTypes.dateRangeType] === type}
              onChange={(e) => set(configTypes.dateRangeType, e.target.value)}
              style={{ marginRight: px2rem(20) }}
            />
          ))}
        </Column>
      )}
    </Row>
  );
};

TogglesRow.propTypes = ReportConfigBuilderHeader.propTypes;

export default compose(
  setDisplayName('ReportConfigBuilderHeader'),
  withContext(ReportConfigBuilderContext),
)(ReportConfigBuilderHeader);

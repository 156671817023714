/* eslint-disable camelcase */
import moment from 'moment-timezone';
import { get } from 'lodash';
import { t, defineMessage } from '@lingui/macro';
import { i18n } from '@lingui/core';

import { formatPhone } from 'utils/format';
import { pickTranslationForValue } from 'components/FieldUpdates/utils';

const eventFieldTitles = {
  start_date: t`Start Date`,
  start_time: t`Start Hour`,
  end_date: t`End Date`,
  end_time: t`End Hour`,
  is_recurring: t`Is Recurring?`,
  ah_recurring_pattern: t`Recurring Days`,
  recurring_start_date: t`Recurrence Start Date`,
  recurring_end_date: t`Recurrence End Date`,
  primary_contact_name: t`Primary Contact`,
  primary_contact_primary_phone_number: t`Primary Contact Phone Number`,
  backup_contact_name: t`Backup Contact`,
  backup_contact_primary_phone_number: t`Backup Contact Phone Number`,
  final_contact_name: t`Final Contact`,
  final_contact_primary_phone_number: t`Final Contact Phone Number`,
};

export const fieldTitles = {
  dealer: {
    store_manager_email: t`Store Manager Email`,
  },
  ah_contact: {
    name: t`Name`,
    job_title: t`Job Title`,
    primary_phone_number: t`Phone Number`,
    secondary_phone_number: t`Backup Number`,
    deleted_at: t`Deleted At`,
  },
  ah_event: eventFieldTitles,
  ah_event_override: eventFieldTitles,
};

export const changeSummarySentences = {
  ah_contact: defineMessage({
    message: `{userName} {performedAction} contact {contactName}`,
  }),
  ah_event: defineMessage({
    message: `{userName} {performedAction} an after-hours rotation: {rotationPeriod}`,
  }),
  ah_event_exception: defineMessage({
    message: `{userName} {performedAction} an exception in the event <0>{eventId}</0> {rotationPeriod}`,
  }),
  ah_event_override: defineMessage({
    message: `{userName} {performedAction} a source event <0>{sourceEventId}</0> {overrideKind} override in the target event <1>{targetEventId}</1>`,
  }),
  dealer: defineMessage({
    message: `{userName} {performedAction} the store manager email`,
  }),

  rotations_email_snoozed: defineMessage({
    message: `{userName} snoozed the rotation emails until <0>{autoResumeDate}</0>`,
  }),
  rotations_email_resumed: defineMessage({
    message: `{userName} resumed receiving the rotation emails`,
  }),
};

const phoneValueTransformer = (value) => formatPhone(value);

const dateValueTransformer = (value) =>
  moment(value).tz(moment.tz.guess()).format('MMM D YYYY');

const timeValueTransformer = (value) =>
  moment(`${moment().format('YYYY-MM-DD')} ${value}`)
    .tz(moment.tz.guess())
    .format('h:mm A');

const daysOfWeekTitles = {
  MONDAY: t`Monday`,
  TUESDAY: t`Tuesday`,
  WEDNESDAY: t`Wednesday`,
  THURSDAY: t`Thursday`,
  FRIDAY: t`Friday`,
  SATURDAY: t`Saturday`,
  SUNDAY: t`Sunday`,
};

const findDayIndex = (day) =>
  Object.keys(daysOfWeekTitles).findIndex((key) => key === day.toUpperCase());

const daysOfWeekTransformer = (value) =>
  value
    .split(',')
    .sort((a, b) => findDayIndex(a) - findDayIndex(b))
    .map((val) => get(daysOfWeekTitles, (val || '').toUpperCase(), val))
    .join(', ');

const rotationPeriodSentences = {
  singleDay: defineMessage({ message: `on {startDate}` }),
  datesOnly: defineMessage({ message: `from {startDate} to {endDate}` }),
  withTime: defineMessage({
    message: `from {startDate}, {startTime} to {endDate}, {endTime}`,
  }),
  withDaysOfWeek: defineMessage({
    message: `from {startDate} to {endDate}, on {daysOfWeek}`,
  }),
};

const getRotationPeriodFromInfo = (entry) => {
  const info = get(entry, 'info', {});

  if (!info.start_date) return '';

  const sentence = [
    info.start_time && rotationPeriodSentences.withTime,
    info.days_of_week && rotationPeriodSentences.withDaysOfWeek,
    info.start_date === info.end_date &&
      !info.start_time &&
      rotationPeriodSentences.singleDay,
    rotationPeriodSentences.datesOnly,
  ].find((t) => t);

  return i18n._(sentence, {
    startDate: dateValueTransformer(info.start_date),
    endDate: dateValueTransformer(info.end_date),
    startTime: timeValueTransformer(info.start_time),
    endTime: timeValueTransformer(info.end_time),
    daysOfWeek: info.days_of_week
      ? daysOfWeekTransformer(info.days_of_week)
      : '',
  });
};

export const relatedInfoPathsByType = {
  ah_contact: { contactName: 'info.name', contactId: 'entityId' },
  ah_event: {
    rotationPeriod: getRotationPeriodFromInfo,
  },
  ah_event_exception: {
    eventId: 'info.event_id',
    rotationPeriod: getRotationPeriodFromInfo,
  },
  ah_event_override: {
    overrideKind: 'info.kind',
    sourceEventId: 'info.source_id',
    targetEventId: 'info.target_id',
  },
  rotations_email_snoozed: {
    autoResumeDate: (entry) => get(entry, 'info.autoResumeDate'),
  },
};

const eventFieldValueTransformers = {
  primary_contact_primary_phone_number: phoneValueTransformer,
  backup_contact_primary_phone_number: phoneValueTransformer,
  final_contact_primary_phone_number: phoneValueTransformer,

  start_date: dateValueTransformer,
  start_time: timeValueTransformer,
  end_date: dateValueTransformer,
  end_time: timeValueTransformer,

  is_recurring: (value) =>
    pickTranslationForValue(value, {
      TRUE: t`Yes`,
      FALSE: t`No`,
    }),

  recurring_start_date: dateValueTransformer,
  recurring_end_date: dateValueTransformer,
  ah_recurring_pattern: daysOfWeekTransformer,
};

export const fieldValueTransformers = {
  ah_contact: {
    primary_phone_number: phoneValueTransformer,
    secondary_phone_number: phoneValueTransformer,
    deleted_at: dateValueTransformer,
  },
  ah_event: eventFieldValueTransformers,
  ah_event_override: eventFieldValueTransformers,
};

/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, debounce, noop } from 'lodash';
import { compose, setDisplayName, branch } from 'recompose';

import patchFromChangedProps from 'utils/patchFromChangedProps';

import Filters from './Filters';
import Results from './Results';
import withCasesSearchResults from './withCasesSearchResults';
import withHighlightedCasesResults from './withHighlightedCasesResults';
import Provider from './Context';
import {
  DEFAULT_SORT_BY,
  DEFAULT_STATUS_GROUP,
  DEFAULT_SORT_DIRECTIONS,
} from './constants';

const DEFAULT_AUTO_REFRESH_INTERVAL = 1000 * 30;

export class CasesSearch extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    mode: PropTypes.oneOf(['default', 'highlightedOnly']).isRequired,
    query: PropTypes.string,
    cases: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    error: PropTypes.shape({ message: PropTypes.string }),
    status: PropTypes.arrayOf(PropTypes.string),
    sortBy: PropTypes.string,
    refetch: PropTypes.func.isRequired,
    endDate: PropTypes.instanceOf(Date),
    startDate: PropTypes.instanceOf(Date),
    isLoading: PropTypes.bool.isRequired,
    pagination: PropTypes.shape({
      endCursor: PropTypes.string,
      startCursor: PropTypes.string,
      hasNextPage: PropTypes.bool.isRequired,
      hasPreviousPage: PropTypes.bool.isRequired,
    }).isRequired,
    statusGroup: PropTypes.string,
    sortDirection: PropTypes.string,
    totalsByStatus: PropTypes.shape({}),
    stopAutoRefresh: PropTypes.func.isRequired,
    startAutoRefresh: PropTypes.func.isRequired,
    isAutoRefreshing: PropTypes.bool.isRequired,
    autoRefreshInterval: PropTypes.number,
    onChangeSearchParams: PropTypes.func,
    inboundProgramId: PropTypes.string,
  };

  static defaultProps = {
    query: '',
    error: {},
    sortBy: DEFAULT_SORT_BY,
    status: [],
    endDate: undefined,
    startDate: undefined,
    statusGroup: DEFAULT_STATUS_GROUP,
    sortDirection: DEFAULT_SORT_DIRECTIONS[DEFAULT_SORT_BY],
    totalsByStatus: {},
    autoRefreshInterval: DEFAULT_AUTO_REFRESH_INTERVAL,
    onChangeSearchParams: noop,
    inboundProgramId: undefined,
  };

  static getDerivedStateFromProps(props, state) {
    return patchFromChangedProps(props, state, [
      'mode',
      'query',
      'cases',
      'status',
      'sortBy',
      'endDate',
      'startDate',
      'isLoading',
      'pagination',
      'statusGroup',
      'sortDirection',
      'totalsByStatus',
      'isAutoRefreshing',
      'inboundProgramId',
    ]);
  }

  constructor(props) {
    super(props);

    this.state = {
      mode: props.mode,
      query: props.query,
      cases: props.cases,
      status: props.status,
      sortBy: props.sortBy,
      refetch: props.refetch,
      endDate: props.endDate,
      startDate: props.startDate,
      isLoading: props.isLoading,
      pagination: props.pagination,
      statusGroup: props.statusGroup,
      sortDirection: props.sortDirection,
      totalsByStatus: props.totalsByStatus,
      isAutoRefreshing: props.isAutoRefreshing,
      inboundProgramId: props.inboundProgramId,
      updateSearchParams: this.updateSearchParams,
    };
  }

  componentDidMount() {
    this.props.startAutoRefresh(this.props.autoRefreshInterval);
  }

  componentDidUpdate() {
    this.props.startAutoRefresh(this.props.autoRefreshInterval);
  }

  componentWillUnmount() {
    this.cancelDebouncedTriggerChange();
  }

  cancelDebouncedTriggerChange = () =>
    this.debouncedTriggerChange && this.debouncedTriggerChange.cancel();

  updateSearchParams = (params, options) => {
    const { stopAutoRefresh, onChangeSearchParams } = this.props;

    const delayBy = get(options, 'delayBy');
    const triggerChange = () => onChangeSearchParams(params);

    stopAutoRefresh();
    this.cancelDebouncedTriggerChange();

    if (!delayBy) {
      triggerChange();
    } else {
      this.debouncedTriggerChange = debounce(triggerChange, delayBy);
      this.debouncedTriggerChange();
    }
  };

  render() {
    // MICONCALL-1084 - report any errors to the console, but render anyway
    const errorMsg = get(this.props.error, 'message');
    if (errorMsg) {
      // eslint-disable-next-line no-console
      console.warn('[CasesSearch] error in case search results:', errorMsg);
    }
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

const EnhancedCasesSearch = compose(
  setDisplayName('CasesSearch'),
  branch(
    (p) => p.mode === 'highlightedOnly',
    withHighlightedCasesResults,
    withCasesSearchResults,
  ),
)(CasesSearch);

EnhancedCasesSearch.Filters = Filters;
EnhancedCasesSearch.Results = Results;

export default EnhancedCasesSearch;

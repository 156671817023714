import gql from 'graphql-tag';

export const NAME = 'caseDelayedServiceQuery';

export default gql`
  query caseDelayedServiceQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      isDelayedService
      delayedServiceNotes
      assetLocationTimezone
      delayedServiceScheduledDate
      delayedServiceScheduledDispatchDate
    }
  }
`;

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const styles = ({ theme }) => css`
  display: none;

  & + span::before {
    background: ${theme.colors.base.background};
    border-radius: ${theme.dimensions.borderRadius};
    border: 1px solid ${theme.colors.base.chrome400};
    color: transparent;
    content: '\f00c';
    cursor: pointer;
    display: inline-block;
    font-family: FontAwesome;
    font-size: ${px2rem(16)};
    height: ${px2rem(18)};
    text-align: center;
    transition: all 200ms ease;
    width: ${px2rem(18)};
  }

  & + span {
    color: ${theme.colors.base.textLight};

    &:hover {
      color: ${theme.colors.base.text};
    }
  }

  &:checked + span::before {
    color: ${theme.colors.base.linkHover};
  }

  &:checked + span {
    color: ${theme.colors.base.text};
  }

  &:hover + span::before {
    border: 1px solid ${theme.colors.base.chrome600};
  }
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      background: PropTypes.string.isRequired,
      chrome400: PropTypes.string.isRequired,
      chrome600: PropTypes.string.isRequired,
      linkHover: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dimensions: PropTypes.shape({
    borderRadius: PropTypes.string.isRequired,
  }).isRequired,
};

const Input = buildStyledComponent(
  'Checkbox__Input',
  styled.input.attrs({ type: 'checkbox' }),
  styles,
  { themePropTypes },
);

export default Input;

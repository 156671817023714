import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName, onlyUpdateForKeys } from 'recompose';

import { DataTable } from 'base-components';

import columns from './responseTableColumns';

function ResponseTable({
  dealer,
  hasServicingDealer,
  isDelayedService,
  isDispatchedStatus,
  isServicingDealer,
  readOnly,
  responses,
  servicingDealerResponseId,
  isPermissionsBasedRole,
  caseStatus,
  dealerIndex,
}) {
  return (
    <DataTable
      scrollX
      columns={columns}
      modifiers=""
      tableData={responses}
      tableMetaData={{
        dealer,
        readOnly,
        caseStatus,
        dealerIndex,
        isDelayedService,
        isServicingDealer,
        isDispatchedStatus,
        hasServicingDealer,
        isPermissionsBasedRole,
        servicingDealerResponseId,
      }}
      rowModifiersGetter={() => ['noHighlightOnHover']}
      rowsRequireRowIndex
    />
  );
}

ResponseTable.propTypes = {
  dealer: PropTypes.shape({
    __typename: PropTypes.oneOf(['Dealer', 'CustomDealer']).isRequired,
  }).isRequired,
  hasServicingDealer: PropTypes.bool.isRequired,
  isDelayedService: PropTypes.bool.isRequired,
  isDispatchedStatus: PropTypes.bool.isRequired,
  isServicingDealer: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  isPermissionsBasedRole: PropTypes.bool.isRequired,
  responses: PropTypes.arrayOf(PropTypes.object).isRequired,
  servicingDealerResponseId: PropTypes.string,
  caseStatus: PropTypes.string.isRequired,
  dealerIndex: PropTypes.number.isRequired,
};

ResponseTable.defaultProps = {
  servicingDealerResponseId: undefined,
};

export default compose(
  setDisplayName('ResponseTable'),
  onlyUpdateForKeys([
    'dealer',
    'hasServicingDealer',
    'isDelayedService',
    'isDispatchedStatus',
    'isServicingDealer',
    'readOnly',
    'responses',
    'servicingDealerResponseId',
    'isPermissionsBasedRole',
    'caseStatus',
  ]),
)(ResponseTable);

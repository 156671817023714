import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { isEmail } from 'validator';

import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';
import {
  H2,
  InputField,
  MessageSmall,
  QuickActionButton,
} from 'base-components';

import Modal from 'components/Modal';

import LoadingMessage from '../../LoadingMessage';

const titles = {
  add: t`Add Store Manager Email Address`,
  edit: t`Edit Store Manager Email Address`,
};

export class StoreEmailModal extends Component {
  static propTypes = {
    email: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    isUpdatingDealerEmail: PropTypes.bool,
  };

  static defaultProps = {
    isUpdatingDealerEmail: false,
  };

  state = { value: this.props.email, isValid: true };

  handleTextChange = ({ target: { value } }) => {
    const isValid = isEmail(value);

    this.setState({ value, isValid });
  };

  render() {
    const { value, isValid } = this.state;
    const { email, onSave, onClose, isUpdatingDealerEmail } = this.props;

    const headerIcon = email ? 'sticky-note' : 'sticky-note-plus';
    const disableSave = isUpdatingDealerEmail || !value || !isValid;

    return (
      <Modal onClose={onClose}>
        {({ closeModal }) => (
          <Modal.Body>
            <Modal.Header>
              <Modal.HeaderIcon name={headerIcon} />
            </Modal.Header>
            <Modal.Content>
              <Row modifiers="center">
                <Column>
                  <H2
                    style={{ marginBottom: px2rem(25) }}
                    modifiers="fontWeightRegular"
                  >
                    <Trans id={titles[email ? 'edit' : 'add']} />
                  </H2>
                </Column>
              </Row>
              <Row>
                <Column modifiers="col">
                  <InputField
                    name="email-input"
                    value={value}
                    isValid={isValid}
                    onChange={this.handleTextChange}
                    placeholder={t`Enter email address...`}
                  >
                    <Column modifiers={['col', 'padScaleY_0']}>
                      <Row>
                        <InputField.Label>
                          <Trans>Email</Trans>
                        </InputField.Label>
                      </Row>
                      <Row>
                        <InputField.TextField />
                      </Row>
                    </Column>
                  </InputField>
                  {!isValid && (
                    <Row>
                      <Column modifiers="padScaleY_2">
                        <MessageSmall type="warning">
                          <Trans>
                            Email must be in name@domain.com format.
                          </Trans>
                        </MessageSmall>
                      </Column>
                    </Row>
                  )}
                </Column>
              </Row>
            </Modal.Content>
            <Modal.Footer>
              <Row modifiers="end">
                {isUpdatingDealerEmail && (
                  <LoadingMessage
                    message={<Trans>Saving, please wait...</Trans>}
                  />
                )}
                <Column>
                  <QuickActionButton onClick={closeModal}>
                    <QuickActionButton.Text>
                      <Trans>Cancel</Trans>
                    </QuickActionButton.Text>
                  </QuickActionButton>
                </Column>
                <Column>
                  <QuickActionButton
                    onClick={() => onSave(value)}
                    disabled={disableSave}
                    modifiers={['secondary', disableSave && 'disabled']}
                  >
                    <QuickActionButton.Text>
                      <Trans>Save Email</Trans>
                    </QuickActionButton.Text>
                  </QuickActionButton>
                </Column>
              </Row>
            </Modal.Footer>
          </Modal.Body>
        )}
      </Modal>
    );
  }
}

export default StoreEmailModal;

import React from 'react';
import PropTypes from 'prop-types';
import { compact } from 'lodash';
import { Trans } from '@lingui/macro';

import { Divider, Icon, Text } from 'base-components';
import { Row, Column } from 'styled-components-grid';
import { px2rem } from 'decisiv-ui-utils';

import { getUnits } from 'utils/unit';

import WeatherBadge from './WeatherBadge';

export default function SearchInputWeatherSummary({
  isHover,
  isVisible,
  unit,
  weather,
  isExpandable,
  reverse,
}) {
  const { current } = weather || {};
  const { code, summary } = current || {};
  const { temperatureUnitLabel } = getUnits(unit);

  const temperature = Math.round(current?.temperature);

  return (
    <Column modifiers={['height_100', 'padScaleY_0']}>
      <Row modifiers={['height_100', 'middle']}>
        <Column modifiers={['height_100', 'padScaleX_0', 'padScaleY_2']}>
          <Divider modifiers={['vertical']} />
        </Column>
        <Row
          modifiers={[
            'height_100',
            'middle',
            'padScale_0',
            reverse && 'reverse',
          ]}
        >
          <Column modifiers={['col', 'padScaleY_0']}>
            <Row>
              <Column
                modifiers={['col', reverse ? 'start' : 'end', 'padScaleY_0']}
              >
                <Text
                  modifiers={['small', 'textLight']}
                  style={{ whiteSpace: 'pre', lineHeight: px2rem(14) }}
                >
                  <Trans>{summary}</Trans>
                </Text>
              </Column>
            </Row>
            {!Number.isNaN(temperature) && (
              <Row>
                <Column modifiers={['col', 'end', 'padScaleY_0']}>
                  <Text>
                    {`${temperature}º`}
                    {temperatureUnitLabel}
                  </Text>
                </Column>
              </Row>
            )}
          </Column>
          <Column modifiers={['center']}>
            <WeatherBadge name={code} />
          </Column>
        </Row>
        {isExpandable && (
          <Column modifiers={['center']}>
            <Icon
              name={isVisible ? 'chevron-up' : 'chevron-down'}
              modifiers={compact(['mini', isHover && 'info'])}
            />
          </Column>
        )}
      </Row>
    </Column>
  );
}

SearchInputWeatherSummary.propTypes = {
  isHover: PropTypes.bool,
  isVisible: PropTypes.bool,
  isExpandable: PropTypes.bool,
  reverse: PropTypes.bool,
  unit: PropTypes.string.isRequired,
  weather: PropTypes.shape({
    current: PropTypes.shape({
      code: PropTypes.string,
      summary: PropTypes.string,
      temperature: PropTypes.number,
    }),
  }).isRequired,
};

SearchInputWeatherSummary.defaultProps = {
  isExpandable: true,
  isHover: false,
  isVisible: false,
  reverse: false,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';
import qs from 'qs';

import { Text } from 'base-components';
import { Row, Column } from 'styled-components-grid';
import { px2rem } from 'decisiv-ui-utils';

import { USER_ROLES, IfRolesMatch } from 'features/rbac';

import BreadcrumbsBlock from 'blocks/Breadcrumbs';

import withSearchUrlParams from '../../DealerSearch/withSearchUrlParams';
import { queryParamsKeys } from '../../DealerSearch/constants';

export function Breadcrumbs(props) {
  const { loading, dealer, currentSearchParam, excludeMrtAssociates } = props;
  const dealerName = loading ? '...' : dealer.name || '<no name>';

  return (
    <BreadcrumbsBlock>
      <Row style={{ paddingTop: px2rem(10), minHeight: px2rem(18) }}>
        <Column modifiers={['col', 'padScaleX_2', 'padScaleY_0']}>
          {/*-----
            dealer admin -
            My Dealers > current dealer name
          */}
          <IfRolesMatch roles={USER_ROLES.DEALER_ADMIN}>
            <BreadcrumbsBlock.Link to="/tech-admin/dealers">
              <Trans>My Service Providers</Trans>
            </BreadcrumbsBlock.Link>
            <BreadcrumbsBlock.Divider />
            <Text modifiers={['small']}>{dealerName}</Text>
          </IfRolesMatch>
          {/*-----
            general admin -
            Find Dealer > Search results for xxx > current dealer name
          */}
          <IfRolesMatch
            roles={[USER_ROLES.TECH_ADMIN_ADMIN, USER_ROLES.ONCALL_ADMIN]}
          >
            <BreadcrumbsBlock.Link to="/tech-admin/dealers">
              <Trans>Find Service Provider</Trans>
            </BreadcrumbsBlock.Link>
            <BreadcrumbsBlock.Divider />
            {currentSearchParam && (
              <>
                <BreadcrumbsBlock.Link
                  to={{
                    pathname: '/tech-admin/dealers',
                    search: `?${qs.stringify({
                      [queryParamsKeys.searchQuery]: currentSearchParam,
                      [queryParamsKeys.excludeMrtAssociates]: excludeMrtAssociates
                        ? '1'
                        : '0',
                    })}`,
                  }}
                >
                  <Trans>Search Results for {currentSearchParam}</Trans>
                </BreadcrumbsBlock.Link>
                <BreadcrumbsBlock.Divider />
              </>
            )}
            <Text modifiers={['small']}>{dealerName}</Text>
          </IfRolesMatch>
        </Column>
      </Row>
    </BreadcrumbsBlock>
  );
}

Breadcrumbs.propTypes = {
  loading: PropTypes.bool,
  dealer: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  currentSearchParam: PropTypes.string,
  excludeMrtAssociates: PropTypes.bool.isRequired,
};

Breadcrumbs.defaultProps = {
  loading: true,
  dealer: null,
  currentSearchParam: null,
};

export default compose(
  setDisplayName('DealerDetails__Breadcrumbs'),
  withSearchUrlParams,
)(Breadcrumbs);

import React, { useCallback } from 'react';
import { useMutation, gql } from '@apollo/client';

import { RequestLineFieldsFragment } from '../constants';
import { NAME as REQUEST_LINES_QUERY } from '../withCaseRequestLines';

const refetchQueries = [REQUEST_LINES_QUERY];
const errorMessage =
  'createRequestAgreementLine requires the case id as first argument';

const mutation = gql`
  mutation createCaseAgreementLine($caseId: ID!, $values: CaseLineAttrs!) {
    createCaseAgreementLine(
      input: { caseId: $caseId, agreementLine: $values }
    ) {
      requestAgreementLine {
        agreed
        agreementLine {
          ...RequestLineFields
        }
        requestLineId
      }
    }
  }
  ${RequestLineFieldsFragment}
`;

const withCreateRequestAgreementLine = (Component) => (props) => {
  const [mutate] = useMutation(mutation, { refetchQueries });

  const createLine = useCallback(
    (caseId, values = {}) => {
      if (!caseId) return Promise.reject(new Error(errorMessage));

      return mutate({ variables: { caseId, values } });
    },
    [mutate],
  );

  return <Component {...props} createRequestAgreementLine={createLine} />;
};

export default withCreateRequestAgreementLine;

import { useEffect, useMemo } from 'react';
import { useLazyQuery, gql } from '@apollo/client';

const query = gql`
  query withAfterHoursData($id: ID!) {
    dealer(id: $id) {
      id

      dealerAfterHoursContacts {
        activeAfterHoursContacts {
          contacts {
            backupContact {
              id
              jobTitle
              name
              primaryPhoneNumber
              secondaryPhoneNumber
            }
            finalContact {
              id
              jobTitle
              name
              primaryPhoneNumber
              secondaryPhoneNumber
            }
            primaryContact {
              id
              jobTitle
              name
              primaryPhoneNumber
              secondaryPhoneNumber
            }
          }
        }

        afterHoursRotationContacts {
          start
          end
          contacts {
            backupContact {
              id
              jobTitle
              name
              primaryPhoneNumber
              secondaryPhoneNumber
            }
            finalContact {
              id
              jobTitle
              name
              primaryPhoneNumber
              secondaryPhoneNumber
            }
            primaryContact {
              id
              jobTitle
              name
              primaryPhoneNumber
              secondaryPhoneNumber
            }
          }
        }

        allAfterHoursContacts {
          id
          jobTitle
          name
          primaryPhoneNumber
          secondaryPhoneNumber
        }
      }
    }
  }
`;

const useAfterHoursData = (dealerId, { enabled, pollInterval }) => {
  const id = dealerId;

  const [run, queryProps] = useLazyQuery(query, {
    skip: !id,
    query: query,
    variables: { id },
    pollInterval: !id ? 0 : pollInterval || 60 * 1000, // 1 minute
    notifyOnNetworkStatusChange: true,
  });

  const { data, loading, error, networkStatus, refetch, called } = queryProps;

  const dealer = data?.dealer || {};
  const isLoading = !!loading || networkStatus === 4; // loading or refetching
  const reloadAfterHoursData = useMemo(() => () => refetch(), [refetch]);

  useEffect(() => {
    if (dealerId && enabled && !called) run();
  }, [run, dealerId, enabled, called]);

  if (!enabled) return {};

  const contacts = dealer?.dealerAfterHoursContacts;

  return {
    allContacts: contacts?.allAfterHoursContacts,
    reloadAfterHoursData: reloadAfterHoursData,
    isLoadingAfterHoursData: isLoading,
    activeAfterHoursContacts: contacts?.activeAfterHoursContacts,
    afterHoursRotationContacts: contacts?.afterHoursRotationContacts,
    didFailLoadingAfterHoursData: !!error,
  };
};

export default useAfterHoursData;

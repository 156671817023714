import { gql, useSubscription } from '@apollo/client';

const envKey = 'REACT_APP_ENABLE_CASE_USER_INACTIVITY_WATCHER';
const enabled = process.env[envKey] === 'true';

const subscription = gql`
  subscription onUserInactiveInCase($caseNumber: String!) {
    onUserInactiveInCase(caseNumber: $caseNumber)
  }
`;

const useIsUserInactiveInCase = (caseNumber) => {
  const skip = !caseNumber || !enabled;

  const { data } = useSubscription(subscription, {
    skip,
    variables: { caseNumber },
  });

  return data?.onUserInactiveInCase || false;
};

export default useIsUserInactiveInCase;

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { i18n } from '@lingui/core';

import CasePrint from 'compositions/CasePrint';
import { pageMeta, pageTitles } from 'pages/constants';

function CasePrintPage(props) {
  const {
    match: {
      params: { caseNumber },
    },
  } = props;

  return (
    <>
      <Helmet>
        <title>{i18n._(pageTitles.casePrintPage, { caseNumber })}</title>
        <meta name="description" content={i18n._(pageMeta.casePrintPage)} />
      </Helmet>
      <CasePrint {...props} caseNumber={caseNumber} />
    </>
  );
}

CasePrintPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      caseNumber: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CasePrintPage;

import React from 'react';
import { Query } from '@apollo/client/react/components';
import { curry, get, map } from 'lodash';

import trailerTypesQuery from './trailerTypesQuery';

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, { data, loading }) => {
    const trailerTypes = map(get(data, 'trailerType.enumValues', []), 'name');

    return (
      <WrappedComponent
        {...componentProps}
        trailerTypes={trailerTypes}
        isLoadingTrailerTypes={!!loading}
      />
    );
  },
);

/* istanbul ignore next */
const withTrailerTypes = (WrappedComponent) => (componentProps) => (
  <Query query={trailerTypesQuery}>
    {buildQueryChild(WrappedComponent, componentProps)}
  </Query>
);

export default withTrailerTypes;

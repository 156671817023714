import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { groupBy, map } from 'lodash';

import { px2rem } from 'decisiv-ui-utils';
import { H4, MessageMedium } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import Loading from 'components/Loading';

import Entry from './Entry';
import useCallHistory from './useCallHistory';
import { formatGroupDate } from '../utils';

function renderNoActivity() {
  return (
    <Container>
      <Row modifiers={['center']}>
        <Column modifiers={['col', 'padScale_5']}>
          <MessageMedium>
            <MessageMedium.Header>
              <MessageMedium.Icon name="phone" />
            </MessageMedium.Header>
            <MessageMedium.Section>
              <MessageMedium.Text>
                <Trans>No call history yet.</Trans>
              </MessageMedium.Text>
            </MessageMedium.Section>
          </MessageMedium>
        </Column>
      </Row>
    </Container>
  );
}

function getEntryDate({ __typename, startTime, recordedAt }) {
  const date = __typename === 'CaseCall' ? startTime : recordedAt;

  return moment(date).startOf('day').format();
}

function CallHistory({ caseNumber }) {
  const { data: calls, loading } = useCallHistory(caseNumber);

  if (loading) return <Loading style={{ padding: 40 }} />;

  if (!calls.length) return renderNoActivity();

  const groupedCalls = groupBy(calls, getEntryDate);

  return (
    <Container modifiers="padScale_0">
      {map(groupedCalls, (oneDaysCalls, date) => (
        <Container key={date} modifiers={['padScaleX_0', 'padScaleY_2']}>
          <Row style={{ margin: `${px2rem(20)} 0 ${px2rem(10)}` }}>
            <H4 modifiers={['fontWeightLight']}>{formatGroupDate(date)}</H4>
          </Row>
          {map(oneDaysCalls, (call) => (
            <Entry key={call.id} entry={call} />
          ))}
        </Container>
      ))}
    </Container>
  );
}

CallHistory.propTypes = {
  caseNumber: PropTypes.string.isRequired,
};

export default CallHistory;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { t, Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { withRouter } from 'react-router';
import { compose, setDisplayName } from 'recompose';

import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';
import { H2, P, QuickActionButton } from 'base-components';

import Modal from 'components/Modal';

import { isPhoneNumber } from 'utils/format';

import Form from './Form';
import contactFields from './constants';
import withCreateAfterHoursContact from '../withCreateAfterHoursContact';
import withUpdateAfterHoursContact from '../withUpdateAfterHoursContact';
import LoadingMessage from '../../LoadingMessage';

export class StoreContactsModal extends Component {
  static propTypes = {
    isEditing: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedContact: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      jobTitle: PropTypes.string,
      primaryPhoneNumber: PropTypes.string,
      secondaryPhoneNumber: PropTypes.string,
      contactName: PropTypes.string,
      contactTitle: PropTypes.string,
      contactPhoneNumber: PropTypes.string,
      contactBackupPhoneNumber: PropTypes.string,
    }).isRequired,
    createAfterHoursContact: PropTypes.func.isRequired,
    isCreatingAfterHoursContact: PropTypes.bool,
    updateAfterHoursContact: PropTypes.func.isRequired,
    isUpdatingAfterHoursContact: PropTypes.bool,
  };

  static defaultProps = {
    isCreatingAfterHoursContact: false,
    isUpdatingAfterHoursContact: false,
  };

  onFormSubmit = async (values, closeModal) => {
    const {
      contactName,
      contactTitle,
      contactPhoneNumber,
      contactBackupPhoneNumber,
    } = values;

    const {
      isEditing,
      selectedContact,
      createAfterHoursContact,
      updateAfterHoursContact,
    } = this.props;

    if (isEditing) {
      await updateAfterHoursContact({
        id: selectedContact.id,
        name: contactName,
        jobTitle: contactTitle,
        primaryPhoneNumber: contactPhoneNumber,
        secondaryPhoneNumber: contactBackupPhoneNumber,
      });
    } else {
      await createAfterHoursContact({
        name: contactName,
        jobTitle: contactTitle,
        primaryPhoneNumber: contactPhoneNumber,
        secondaryPhoneNumber: contactBackupPhoneNumber,
      });
    }

    closeModal();
  };

  initialValues = () => {
    const { name, title, phoneNumber, backupPhoneNumber } = contactFields;
    const { selectedContact } = this.props;

    return {
      [name]: selectedContact.name || '',
      [title]: selectedContact.jobTitle || '',
      [phoneNumber]: selectedContact.primaryPhoneNumber || '',
      [backupPhoneNumber]: selectedContact.secondaryPhoneNumber || '',
    };
  };

  initRef = (ref) => {
    this.formRef = ref;
  };

  validateValues = (values) => {
    const errors = {};

    if (!values[contactFields.name]) {
      errors[contactFields.name] = i18n._(t`Name is required`);
    }
    if (!values[contactFields.phoneNumber]) {
      errors[contactFields.phoneNumber] = i18n._(t`Phone number is required`);
    } else if (!isPhoneNumber(values[contactFields.phoneNumber])) {
      errors[contactFields.phoneNumber] = i18n._(t`Phone number is not valid`);
    }

    if (
      values[contactFields.backupPhoneNumber] &&
      !isPhoneNumber(values[contactFields.backupPhoneNumber])
    ) {
      errors[contactFields.backupPhoneNumber] = i18n._(
        t`Backup Phone number is not valid`,
      );
    }

    return errors;
  };

  render() {
    const {
      isEditing,
      onClose,
      isCreatingAfterHoursContact,
      isUpdatingAfterHoursContact,
    } = this.props;

    const isSaving = isCreatingAfterHoursContact || isUpdatingAfterHoursContact;

    return (
      <Modal onClose={onClose}>
        {({ closeModal }) => (
          <Formik
            initialValues={this.initialValues()}
            onSubmit={(values) => this.onFormSubmit(values, closeModal)}
            ref={this.initRef}
            validate={this.validateValues}
          >
            {(formProps) => (
              <Modal.Body>
                <Modal.Header>
                  <Modal.HeaderIcon name={isEditing ? 'user' : 'user-plus'} />
                </Modal.Header>
                <Modal.Content>
                  <Row modifiers="center">
                    <Column>
                      <H2 modifiers="fontWeightRegular">
                        {isEditing ? (
                          <Trans>Edit Contact</Trans>
                        ) : (
                          <Trans>Create Contact</Trans>
                        )}
                      </H2>
                    </Column>
                  </Row>
                  <Row modifiers="center">
                    <Column modifiers="col">
                      <P
                        style={{
                          // FIXME: A P element should have a bottom margin,
                          // just like default styles in html
                          marginBottom: px2rem(20),
                        }}
                      >
                        {isEditing ? (
                          <Trans>
                            Any Changes you make to the contact will also update
                            in their assigned rotations
                          </Trans>
                        ) : (
                          <Trans>
                            Staff members must be added as a contact to assign
                            them to a rotation
                          </Trans>
                        )}
                      </P>
                    </Column>
                  </Row>
                  <Form {...formProps} />
                </Modal.Content>
                <Modal.Footer>
                  <Row modifiers="end">
                    {isSaving && (
                      <LoadingMessage
                        message={<Trans>Saving, please wait...</Trans>}
                      />
                    )}
                    <Column>
                      <QuickActionButton onClick={closeModal}>
                        <QuickActionButton.Text>
                          <Trans>Cancel</Trans>
                        </QuickActionButton.Text>
                      </QuickActionButton>
                    </Column>
                    <Column>
                      <QuickActionButton
                        disabled={isSaving || !formProps.isValid}
                        modifiers={[
                          'secondary',
                          (isSaving || !formProps.isValid) && 'disabled',
                        ]}
                        onClick={formProps.submitForm}
                      >
                        <QuickActionButton.Text>
                          <Trans>Save Contact</Trans>
                        </QuickActionButton.Text>
                      </QuickActionButton>
                    </Column>
                  </Row>
                </Modal.Footer>
              </Modal.Body>
            )}
          </Formik>
        )}
      </Modal>
    );
  }
}

export default compose(
  setDisplayName('StoreContactsModal'),
  withRouter,
  withCreateAfterHoursContact,
  withUpdateAfterHoursContact,
)(StoreContactsModal);

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

import { modifierConfig, styles, themePropTypes } from '../utils/linkStyles';

const propTypes = {
  'aria-label': PropTypes.string.isRequired,
};

export default buildStyledComponent('A', styled.a, styles, {
  modifierConfig,
  propTypes,
  themePropTypes,
});

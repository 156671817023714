import PropTypes from 'prop-types';
import styled from 'styled-components';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const modifierConfig = {
  active: ({ theme }) => `
    border-bottom: solid ${px2rem(2)} ${theme.colors.base.chrome600};
    color: ${theme.colors.base.text};
    pointer-events: none;
  `,
  spaceEven: () => `
    flex: 1;
    justify-content: center;
  `,
};

const styles = ({ theme }) => `
  border-bottom: solid 2px transparent;
  display: flex;
  position: relative;
  top: ${px2rem(2)};

  &:hover {
    border-bottom: solid ${px2rem(2)} ${theme.colors.base.linkHover};

    span.fa {
      color: ${theme.colors.base.linkHover};
    }
  }
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome600: PropTypes.string.isRequired,
      linkHover: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const defaultProps = {
  modifiers: [],
};

const propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

const Item = buildStyledComponent('TabList.Item', styled.li, styles, {
  defaultProps,
  modifierConfig,
  propTypes,
  themePropTypes,
});

export default Item;

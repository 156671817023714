import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { buildStyledComponent } from 'decisiv-ui-utils';
import { Row } from 'styled-components-grid';
import { TOGGLE_TAB_CONTEXT } from './constants';

const StyledContent = buildStyledComponent('Tabs.Content', styled(Row));

function Content({ children, ...props }, context) {
  const { activeTab } = context[TOGGLE_TAB_CONTEXT];
  const activeContent = React.Children.map(children, (child, i) =>
    activeTab === i ? child : null,
  );

  return <StyledContent {...props}>{activeContent}</StyledContent>;
}

Content.contextTypes = {
  [TOGGLE_TAB_CONTEXT]: PropTypes.shape({}).isRequired,
};

Content.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Content;

/* eslint-disable react/prop-types */

import React, { useCallback, forwardRef } from 'react';
import styled from 'styled-components';

import TextArea from '../TextArea';
import { styles as baseStyles } from '../TextField';

const Container = styled.div`
  display: grid;

  ::after {
    content: attr(data-value) ' ';
    visibility: hidden;
    white-space: pre-wrap;
  }

  ::after,
  textarea {
    ${baseStyles}
    /* both take the full parent size and render above each other */
    grid-area: 1 / 1;
  }
`;

const AutoSizingTextArea = forwardRef((props, ref) => {
  const { value, onInput, style } = props;

  const handleOnInput = useCallback(
    (event) => {
      event.target.parentNode.dataset.value = event.target.value;

      onInput && onInput(event);
    },
    [onInput],
  );

  return (
    <Container data-value={value} style={style}>
      <TextArea {...props} onInput={handleOnInput} ref={ref} />
    </Container>
  );
});

export default AutoSizingTextArea;

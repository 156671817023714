import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import constants from './constants';

const { ARROW_WIDTH } = constants;

const modifierConfig = {
  bottom: () => `
    left: 50%;
    top: calc(100% + ${px2rem(ARROW_WIDTH)});
    transform: translateX(-50%);
  `,

  left: () => `
    right: calc(100% + ${px2rem(ARROW_WIDTH)});
    top: 50%;
    transform: translateY(-50%);
  `,

  right: () => `
    left: calc(100% + ${px2rem(ARROW_WIDTH)});
    top: 50%;
    transform: translateY(-50%);
  `,

  top: () => `
    left: 50%;
    bottom: calc(100% + ${px2rem(ARROW_WIDTH)});
    transform: translateX(-50%);
  `,
};

const styles = ({ theme, zIndex }) => `
  align-items: center;
  background-color: ${theme.colors.base.chrome700};
  border-radius: ${theme.dimensions.borderRadius};
  ${theme.planes.sky1};
  color: ${theme.colors.base.chrome000};
  font-size: ${px2rem(12)};
  font-weight: ${theme.fontWeights.light};
  padding: 5px 20px;
  position: absolute;
  white-space: nowrap;
  z-index: ${zIndex};
`;

const propTypes = {
  zIndex: PropTypes.number,
};

const defaultProps = {
  zIndex: 1,
};

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome000: PropTypes.string.isRequired,
      chrome700: PropTypes.string.isRequired,
      shadow: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dimensions: PropTypes.shape({
    borderRadius: PropTypes.string.isRequired,
  }).isRequired,
  fontWeights: PropTypes.shape({
    light: PropTypes.number.isRequired,
  }).isRequired,
};

const Content = buildStyledComponent('Tooltip__Content', styled.div, styles, {
  defaultProps,
  modifierConfig,
  propTypes,
  themePropTypes,
});

export default Content;

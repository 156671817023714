import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import { Accordion, P } from 'base-components';
import { Container } from 'styled-components-grid';

import Newline2Br from 'elements/Newline2Br';

import AccordionBody from './AccordionBody';

export function Notes({ dealer, expanded, onExpandedChange }) {
  const { notes } = dealer;

  if (!notes) {
    return null;
  }

  return (
    <Container modifiers="padScale_0">
      <Accordion.Divider />
      <Accordion
        name="notes"
        expanded={expanded}
        onExpandedChange={onExpandedChange}
      >
        <Accordion.Head>
          <Accordion.Title>
            <Trans>Notes</Trans>
          </Accordion.Title>
        </Accordion.Head>
        <AccordionBody>
          <P>
            <Newline2Br value={notes} />
          </P>
        </AccordionBody>
      </Accordion>
    </Container>
  );
}

Notes.propTypes = {
  dealer: PropTypes.shape({ notes: PropTypes.string }),
  expanded: PropTypes.bool.isRequired,
  onExpandedChange: PropTypes.func.isRequired,
};

Notes.defaultProps = {
  dealer: {},
};

export default Notes;

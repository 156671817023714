import React from 'react';
import PropTypes from 'prop-types';

import Dropdown from '../../blocks/Dropdown';

import { DROPDOWN_CONTEXT } from './constants';

function Action({ children, ...rest }, context) {
  const { hideContent } = context[DROPDOWN_CONTEXT];

  return (
    <Dropdown.Action onClick={hideContent} {...rest}>
      {children}
    </Dropdown.Action>
  );
}

Action.contextTypes = {
  [DROPDOWN_CONTEXT]: PropTypes.shape({}).isRequired,
};

Action.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Action;

import { gql } from '@apollo/client';

const config = {
  axleType: {
    name: 'axleTypeOptionsQuery',
    query: gql`
      query axleTypeOptionsQuery(
        $filters: TireOptionsFilters!
        $select: TireSelectOption!
      ) {
        tiresOptions(input: { filters: $filters, select: $select }) {
          options {
            id
            name
            generic
          }
        }
      }
    `,
  },
  loadRange: {
    name: 'loadRangeOptionsQuery',
    query: gql`
      query loadRangeOptionsQuery(
        $filters: TireOptionsFilters!
        $select: TireSelectOption!
      ) {
        tiresOptions(input: { filters: $filters, select: $select }) {
          options {
            id
            name
            generic
          }
        }
      }
    `,
  },
  manufacturerFullName: {
    name: 'brandOptionsQuery',
    query: gql`
      query brandOptionsQuery(
        $filters: TireOptionsFilters!
        $select: TireSelectOption!
      ) {
        tiresOptions(input: { filters: $filters, select: $select }) {
          options {
            id
            name
            generic
          }
        }
      }
    `,
  },
  tirePosition: {
    name: 'tirePositionOptionsQuery',
    query: gql`
      query tirePositionOptionsQuery(
        $filters: TireOptionsFilters!
        $select: TireSelectOption!
      ) {
        tiresOptions(input: { filters: $filters, select: $select }) {
          options {
            id
            name
            generic
          }
        }
      }
    `,
  },
  productType: {
    name: 'productTypeOptionsQuery',
    query: gql`
      query productTypeOptionsQuery(
        $filters: TireOptionsFilters!
        $select: TireSelectOption!
      ) {
        tiresOptions(input: { filters: $filters, select: $select }) {
          options {
            id
            name
            generic
          }
        }
      }
    `,
  },
  sculptureTreadName: {
    name: 'treadDesignOptionsQuery',
    query: gql`
      query treadDesignOptionsQuery(
        $filters: TireOptionsFilters!
        $select: TireSelectOption!
      ) {
        tiresOptions(input: { filters: $filters, select: $select }) {
          options {
            id
            name
            generic
          }
        }
      }
    `,
  },
  tireSize: {
    name: 'tireSizeOptionsQuery',
    query: gql`
      query tireSizeOptionsQuery(
        $filters: TireOptionsFilters!
        $select: TireSelectOption!
      ) {
        tiresOptions(input: { filters: $filters, select: $select }) {
          options {
            id
            name
            generic
          }
        }
      }
    `,
  },
};

export const buildQueryNameForField = (fieldName) => config[fieldName]?.name;

export default function buildQueryForField(fieldName) {
  return config[fieldName]?.query;
}

import { css } from 'styled-components';

import { GhostIndicator } from 'base-components';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const modifierConfig = {
  medium: () => `
    width: ${px2rem(60)};
    height: ${px2rem(60)};
  `,
};

// TODO: Maybe move this to base-components.
export default buildStyledComponent(
  'CircleGhostIndicator',
  GhostIndicator,
  css`
    border-radius: 50%;
    width: ${px2rem(35)};
    height: ${px2rem(35)};
  `,
  { modifierConfig },
);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { includes } from 'lodash';

import StyledLeftNav from '../../blocks/LeftNav';

import MenuItemFlyout from './MenuItemFlyout';
import MenuItemTitle from './MenuItemTitle';
import MenuSubItemsList from './MenuSubItemsList';
import menuPropTypes from './menuPropTypes';

/**
 * Compares the menu item's activePath or path to the current pathname.
 * Boolean returned indicates if the two match.
 * @param {object} props the component's props (may be next props)
 * @property {object} props.location a location object provided by the withRouter HOC
 * @property {object} props.menuItem a menuItem object provided by the menu groups list
 * @returns {boolean}
 */
function evaluateMenuItemActive({
  location: { pathname = '' },
  menuItem: { activePath, path },
}) {
  if (activePath) {
    return activePath === pathname;
  }

  const primaryPath = pathname.split('/')[1];
  return !!(
    (path && primaryPath && path.match(primaryPath)) ||
    (path === '/' && !primaryPath)
  );
}

export class MenuItem extends Component {
  static propTypes = {
    TextRenderer: PropTypes.func.isRequired,
    expanded: PropTypes.bool.isRequired,
    expandedMenus: PropTypes.arrayOf(PropTypes.string).isRequired,
    handleExpandMenuClick: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    menuItem: menuPropTypes.menuItem.isRequired,
    menuGroupsLoading: PropTypes.bool.isRequired,
  };

  state = {
    // A menu item's active state is based on the current route
    isActive: evaluateMenuItemActive(this.props),
    // A menu item's highlighted state is based on the user's cursor position
    isHighlighted: false,
    // A menu item's highlighted state is based on the user's cursor position
    // + the left nav expanded state
    isFlyoutOpen: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const isActive = evaluateMenuItemActive(nextProps);
    this.setState({
      isActive,
    });
  }

  handleMouseEnter = () => {
    if (!this.props.expanded) {
      this.setState({
        isHighlighted: true,
        isFlyoutOpen: true,
      });
    }
  };

  handleMouseLeave = () => {
    this.setState({
      isHighlighted: false,
      isFlyoutOpen: false,
    });
  };

  handleMouseEnterMenuItemTitle = () => {
    if (this.props.expanded) {
      this.setState({ isHighlighted: true });
    }
  };

  handleMouseLeaveMenuItemTitle = () => {
    if (this.props.expanded) {
      this.setState({ isHighlighted: false });
    }
  };

  render() {
    const {
      TextRenderer,
      expanded,
      expandedMenus,
      handleExpandMenuClick,
      menuItem,
      menuGroupsLoading,
      location,
    } = this.props;

    const { isActive, isHighlighted, isFlyoutOpen } = this.state;

    const menuKey = `${menuItem.icon}:${menuItem.path}`;
    const isMenuExpanded = !!(
      expanded &&
      Array.isArray(expandedMenus) &&
      includes(expandedMenus, menuKey)
    );

    return (
      <StyledLeftNav.MenuItem
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <MenuItemTitle
          TextRenderer={TextRenderer}
          expanded={expanded}
          handleExpandMenuClick={handleExpandMenuClick}
          isActive={isActive}
          isFlyoutOpen={isFlyoutOpen}
          isHighlighted={isHighlighted}
          isMenuExpanded={isMenuExpanded}
          menuItem={menuItem}
          menuGroupsLoading={menuGroupsLoading}
          menuKey={menuKey}
          onMouseEnter={this.handleMouseEnterMenuItemTitle}
          onMouseLeave={this.handleMouseLeaveMenuItemTitle}
        />
        {isFlyoutOpen && !menuGroupsLoading ? (
          <MenuItemFlyout
            TextRenderer={TextRenderer}
            expanded={expanded}
            handleExpandMenuClick={handleExpandMenuClick}
            isActive={isActive}
            isFlyoutOpen={isFlyoutOpen}
            isHighlighted={isHighlighted}
            isMenuExpanded={isMenuExpanded}
            menuItem={menuItem}
            menuGroupsLoading={menuGroupsLoading}
            menuKey={menuKey}
            onMouseEnter={this.handleMouseEnterMenuItemTitle}
            onMouseLeave={this.handleMouseLeaveMenuItemTitle}
          />
        ) : (
          <MenuSubItemsList
            TextRenderer={TextRenderer}
            isFlyoutOpen={isFlyoutOpen}
            isMenuExpanded={isMenuExpanded}
            location={location}
            menuSubItems={menuItem.menuSubItems}
          />
        )}
      </StyledLeftNav.MenuItem>
    );
  }
}

export default MenuItem;

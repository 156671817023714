/* eslint-disable import/prefer-default-export */

import { flow, getOr, map, filter, flatten } from 'lodash/fp';

export const getOperationNames = flow(
  getOr([], 'query.definitions'),
  filter(({ operation: op }) => ['query', 'mutation'].includes(op)),
  map('selectionSet.selections'),
  flatten,
  map('name.value'),
);

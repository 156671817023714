/**
 * Application-wide dimension constants
 */

import px2rem from '../utils/px2rem';

import { BASE_SIZE_PX, BASE_FONT_SIZE_PX } from './constants';

const topNavHeightInPixel = 80;

export default {
  fontSize: BASE_FONT_SIZE_PX,
  gridPad: px2rem(BASE_SIZE_PX / 2),

  // These dimensions options are deprecated and will be removed from the application.
  autoCollapseLeftNavPx: 1366, // no unit given because need to compare raw pixel value
  borderRadius: '2px',
  cardGridPadding: px2rem(4),
  cardMinWidth: 300,
  caseActionBarIconSize: px2rem(16),
  fontSizeMedium: px2rem(14),
  fontSizeNormal: px2rem(12),
  gridGutterSizeHalf: px2rem(25),
  leftNavChildItemHeight: px2rem(22),
  leftNavExpandTime: '0.3s',
  leftNavItemHeight: px2rem(26),
  leftNavWidth: px2rem(211),
  leftNavWidthCollapsed: px2rem(48),
  popOverArrowOffset: px2rem(40),
  popOverArrowSize: px2rem(18),
  popOverArrowSizeSmall: px2rem(12),
  popOverMinHeight: px2rem(25),
  popOverMinWidth: px2rem(150),
  spacingNormal: px2rem(15),
  statusCircleRadius: 10,
  tabWidth: px2rem(200),
  tooltipArrowSize: px2rem(6),
  topNavHeight: px2rem(topNavHeightInPixel),
  topNavHeightInPixel,
};

import PropTypes from 'prop-types';
import { t } from '@lingui/macro';

const relatedInfoPathLeafPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.func,
]);

export const fieldUpdateEntryPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  entityId: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      previousValue: PropTypes.string,
      newValue: PropTypes.string,
    }),
  ).isRequired,
  info: PropTypes.shape({}).isRequired,
  type: PropTypes.string.isRequired,
  recordedAt: PropTypes.string.isRequired,
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string.isRequired,
  }).isRequired,
});

export const fieldUpdatesConfigPropType = PropTypes.shape({
  entryTransformer: PropTypes.func,
  fieldValueTransformers: PropTypes.shape({}),
  fieldTitles: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.objectOf(PropTypes.string),
    ]),
  ).isRequired,
  changeSummarySentences: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.objectOf(PropTypes.string),
    ]),
  ).isRequired,
  relatedInfoPathsByType: PropTypes.objectOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        relatedInfoPathLeafPropType,
        PropTypes.objectOf(relatedInfoPathLeafPropType),
      ]),
    ),
  ).isRequired,
  emptyStateMessage: PropTypes.node,
});

export const columnTitles = {
  field: t`Field`,
  newValue: t`After`,
  previousValue: t`Before`,
};

export const actionTitles = {
  created: t`created`,
  deleted: t`removed`,
  updated: t`updated`,
  ended: t`ended`,
};

import gql from 'graphql-tag';

const createCaseMutation = gql`
  mutation createCase {
    createCase {
      case {
        id
        caseNumber
      }
    }
  }
`;

export default createCaseMutation;

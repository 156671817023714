import React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { defineMessage, Trans } from '@lingui/macro';

import { Row, Column as BaseColumn } from 'styled-components-grid';
import {
  Text as BaseText,
  Label,
  Icon,
  ButtonIconRectangle,
  Tooltip,
} from 'base-components';

import { AfterHoursEventPropType } from '../../propTypes';
import {
  isRecurringRotation,
  getRecurringDates,
  daysOfWeekValueTransformer,
  dateValueTransformer,
  buildOverrideRows,
} from './utils';

const Container = styled.div`
  padding: 5px 10px;
  background: ${({ theme }) => theme.colors.status.infoLight};
  border-left: 2px solid ${({ theme }) => theme.colors.status.info};
  border-radius: 2px;
  margin-bottom: 3px;
`;

const Text = styled(BaseText)`
  display: inline;
`;

const Column = styled(BaseColumn)`
  padding: 0;
  padding-bottom: 2px;
`;

const LabelColumn = styled(Column)`
  width: 90px;
`;

const Emphasis = <Text modifiers="fontWeightMedium" />;

const exceptionLines = {
  singleDay: defineMessage({ message: 'Not active on <0>{startDate}</0>' }),
  datesOnly: defineMessage({
    message: 'Not active from <0>{startDate} to {endDate}</0>',
  }),
  withDaysOfWeek: defineMessage({
    message: 'Not active from <0>{startDate} to {endDate}, on {daysOfWeek}</0>',
  }),
};

/* eslint-disable react/prop-types */
function ExceptionItem({ event, timezone, deleteException }) {
  const { startDate, endDate, daysOfWeek } = event;
  const exceptionLine = [
    startDate === endDate && exceptionLines.singleDay,
    daysOfWeek && daysOfWeek.length && exceptionLines.withDaysOfWeek,
    exceptionLines.datesOnly,
  ].find((t) => t);

  const isFutureException = moment(endDate).isAfter(moment());

  return (
    <Container>
      <Row style={{ marginBottom: 3 }} modifiers="middle">
        <BaseColumn modifiers={['col', 'padScale_0']}>
          <Text modifiers="fontWeightRegular">
            <Trans
              id={exceptionLine}
              values={{
                startDate: dateValueTransformer(startDate, { timezone }),
                endDate: dateValueTransformer(endDate, { timezone }),
                daysOfWeek: daysOfWeekValueTransformer(daysOfWeek),
              }}
              components={[Emphasis]}
            />
          </Text>
        </BaseColumn>

        {isFutureException && (
          <BaseColumn modifiers={['padScale_0']}>
            <Tooltip position="left">
              <Tooltip.Target>
                <ButtonIconRectangle
                  style={{ backgroundColor: 'inherit' }}
                  onClick={deleteException}
                >
                  <ButtonIconRectangle.Icon
                    name="revert"
                    modifiers={['mini']}
                  />
                </ButtonIconRectangle>
              </Tooltip.Target>
              <Tooltip.Content>
                <Trans>Reactivate</Trans>
              </Tooltip.Content>
            </Tooltip>
          </BaseColumn>
        )}
      </Row>
    </Container>
  );
}

const occurrenceDates = {
  startEnd: defineMessage({ message: '{startDate} to {endDate}' }),
  startOnly: defineMessage({ message: '{startDate}' }),
};

const getOccurrenceDate = ({ event, timezone }) => {
  const isRecurring = isRecurringRotation(event);
  const { recurringStartDate, recurringEndDate } = getRecurringDates(event);

  const startDate = isRecurring
    ? recurringStartDate
    : dateValueTransformer(event.startDate, { timezone });

  const endDate = isRecurring
    ? recurringEndDate
    : event.endDate && dateValueTransformer(event.endDate, { timezone });

  const occurrenceDate = [
    startDate === endDate && occurrenceDates.startOnly,
    occurrenceDates.startEnd,
  ].find((d) => d);

  return { occurrenceDate, startDate, endDate };
};

function OverrideItem(props) {
  const { occurrenceDate, startDate, endDate } = getOccurrenceDate(props);
  const rows = buildOverrideRows(props);
  return (
    <Container>
      <Row style={{ marginBottom: 3 }}>
        <Text modifiers="fontWeightRegular">
          <Trans id={occurrenceDate} values={{ startDate, endDate }} />
        </Text>
      </Row>
      {rows.map(({ key, label, previousValue, newValue }) => (
        <Row key={key} modifiers={['middle']}>
          <LabelColumn>
            <Label modifiers="textLight">
              <Trans id={label} />
            </Label>
          </LabelColumn>
          <Column modifiers="col">
            <Text modifiers="small">
              <Trans>{previousValue}</Trans>
            </Text>
            &nbsp;
            <Icon name="arrow-right" modifiers={['mini', 'disabled']} />
            &nbsp;
            <Text modifiers={['small', 'fontWeightMedium']}>
              <Trans>{newValue}</Trans>
            </Text>
          </Column>
        </Row>
      ))}
    </Container>
  );
}
/* eslint-enable react/prop-types */

function RotationException(props) {
  const { event } = props;
  if (get(event, '__typename') === 'AfterHoursException') {
    return <ExceptionItem {...props} />;
  }

  return <OverrideItem {...props} />;
}

RotationException.propTypes = {
  event: PropTypes.oneOfType([
    PropTypes.shape({
      __typename: PropTypes.oneOf(['AfterHoursException']),
      daysOfWeek: PropTypes.arrayOf(PropTypes.string),
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }),
    AfterHoursEventPropType,
  ]).isRequired,
  /* eslint-disable react/no-typos */
  originalEvent: AfterHoursEventPropType.isRequired,
  timezone: PropTypes.string.isRequired,
  deleteException: PropTypes.func.isRequired,
};

export default RotationException;

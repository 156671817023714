import PropTypes from 'prop-types';

/**
 * Expand the default PropTypes with custom types needed for the
 * case payment panel and its subcomponents.
 */
export default {
  ...PropTypes,
  tokenizedCard: PropTypes.shape({
    expirationMonth: PropTypes.number.isRequired,
    expirationYear: PropTypes.number.isRequired,
    firstDigit: PropTypes.string.isRequired,
    last4Digits: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

import React from 'react';
import { KBarSearch, useKBar } from 'kbar';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { compact } from 'lodash';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';
import { Input } from 'base-components';
import Icon from 'base-components/components/InputField/Icon';
import ActionButton from 'base-components/components/InputField/ActionButton';

const inputPlaceholder = t`Type a command or search shortcuts...`;

const BaseSearchInput = buildStyledComponent(
  'KeyboardShortcuts_SearchInput',
  styled(KBarSearch),
  ({ theme }) => css`
    border: none;
    color: ${theme.colors.base.text};
    font-size: ${px2rem(12)};
    line-height: ${px2rem(20)};
    outline: none;
    padding: 0;
    width: 100%;

    &::placeholder {
      color: ${theme.colors.base.textLight};
    }
  `,
  {
    themePropTypes: {
      colors: PropTypes.shape({
        base: PropTypes.shape({
          text: PropTypes.string.isRequired,
          textLight: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    },
  },
);

function SearchInput() {
  const { query, search } = useKBar((state) => ({ search: state.searchQuery }));

  const onClear = React.useCallback(() => {
    query.setSearch('');
  }, [query]);

  return (
    <Input modifiers={compact([!!search && 'focused'])}>
      <Row modifiers={['height_100', 'middle']}>
        <Icon name="keyboard-o" />

        <Column modifiers={['col', 'padScale_0']}>
          <Row>
            <Column modifiers={['col', 'padScale_0']}>
              <BaseSearchInput defaultPlaceholder={i18n._(inputPlaceholder)} />
            </Column>

            {!!search && (
              <ActionButton
                icon="times"
                type="button"
                onClick={onClear}
                modifiers={['hoverDanger']}
              />
            )}
          </Row>
        </Column>
      </Row>
    </Input>
  );
}

export default SearchInput;

import styled, { css } from 'styled-components';
import { t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { toLower } from 'lodash';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';
import { Dropdown as DefaultDropdown } from 'base-components';

import { seeNotesRequestValue } from '../constants';

export const Dropdown = buildStyledComponent(
  'Dropdown',
  styled(DefaultDropdown),
  ({ isNonPreferredSelection, theme }) =>
    isNonPreferredSelection &&
    css`
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        border: ${px2rem(5)} solid transparent;
        border-right-color: ${theme.colors.status.info};
        border-top-color: ${theme.colors.status.info};
        z-index: 1;
        pointer-events: none;
      }
    `,
);

export function getSelectedOption(props) {
  const { value, options, rowData = {} } = props;

  const dependsOn = props.dependsOn || [];
  const lowerCasedValue = toLower(value);

  if (value === seeNotesRequestValue) {
    return { id: value, title: i18n._(t`See Notes`) };
  }

  const option = options.find(
    ({ id, title }) => value === id || lowerCasedValue === toLower(title),
  );

  if (
    !option &&
    dependsOn.some((key) => rowData[key] === seeNotesRequestValue)
  ) {
    return { id: value, title: value };
  }

  return option;
}

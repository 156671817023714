import React from 'react';
import PropTypes from 'prop-types';

import { P } from 'base-components';

import { Redirect, withRouter } from 'react-router-dom';
import Authorized from './Authorized';

/**
 * Component which will issue an "unauthorized" warning to the console
 * and redirect to the root path `/`.
 */
function UnauthorizedRedirect({ userRole, location: { pathname } }) {
  // prevent an infinite-redirect loop...
  if (pathname === '/') {
    return (
      <P>
        You are not authorized to access <code>{pathname}</code>.
      </P>
    );
  }

  // eslint-disable-next-line no-console
  console.warn(
    `user role '${userRole}' not authorized to access '${pathname}'; redirecting to /`,
  );
  // redirect to the root path, maybe the routing will sort itself out...
  return <Redirect to="/" />;
}

UnauthorizedRedirect.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  userRole: PropTypes.string.isRequired,
};

/**
 * An {@link Authorized} wrapper setup to redirect to the root path if
 * authorization fails.
 */
export default function RouteAuthorization(allowedRoles) {
  return Authorized(allowedRoles, withRouter(UnauthorizedRedirect));
}

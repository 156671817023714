import styled from 'styled-components';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import { Row } from 'styled-components-grid';

const styles = `
  flex-wrap: nowrap;
  padding: ${px2rem(10)};
`;

export default buildStyledComponent(
  'Dropdown__SectionBody',
  styled(Row),
  styles,
);

import React from 'react';
import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';
import { round } from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { t, Trans } from '@lingui/macro';

import { Row, Column } from 'styled-components-grid';
import { Text, Icon, ButtonToggle } from 'base-components';

import Weather from 'blocks/Weather';

import { toggleUnit } from 'redux/preferences/actions';
import { unitSelector } from 'redux/preferences/selectors';

import { getUnits, isMetric } from 'utils/unit';
import { degToCompass } from 'utils/weather';

import StatusPanel from './StatusPanel';

/**
 * Right panel of the location weather info popup.
 * Exported only for testing.
 */
export function WeatherDetailRightPanel({
  unit,
  weather: {
    current: { code, windBearing, windSpeed },
    today: { precipProbability, temperatureLow, temperatureHigh },
    hourly,
    timezone,
  },
  onUnitToggle,
}) {
  const precipIcon = code === 'snow' ? 'snowflake' : 'tint';
  const precipText = code === 'snow' ? t`Chance Of Snow` : t`Chance Of Rain`;
  const { temperatureUnitLabel, windSpeedUnitLabel } = getUnits(unit);

  return (
    <>
      <Row modifiers={['padScale_2']} style={{ flexWrap: 'nowrap' }}>
        {hourly.map(({ code, time, temperature }) => (
          <Column modifiers={['col']} key={time}>
            <Weather>
              <Weather.Time>
                {moment(time * 1000)
                  .tz(timezone)
                  .format('hA')}
              </Weather.Time>
              <Weather.Icon name={code} />
              <Weather.Temperature>
                {Math.round(temperature)}
              </Weather.Temperature>
            </Weather>
          </Column>
        ))}
      </Row>
      <Row>
        <StatusPanel modifiers={['col', 'padScaleX_5', 'padScaleY_2']}>
          <Row style={{ flexWrap: 'nowrap' }} modifiers={['padScaleY_3']}>
            <Column modifiers={['col', 'padScale_0']}>
              <Row modifiers={['padScaleX_0']}>
                <Column modifiers={['padScaleX_0']}>
                  <Icon name="thermometer-full" />
                </Column>
              </Row>
              <Row modifiers={['padScaleX_0']}>
                <Column modifiers={['padScale_0']}>
                  <Text modifiers={['small', 'textLight']}>
                    <Trans>High/Low</Trans>
                  </Text>
                </Column>
              </Row>
              <Row modifiers={['padScaleX_0']}>
                <Column modifiers={['padScale_0']}>
                  <Text>
                    {Math.round(temperatureHigh)}/{Math.round(temperatureLow)}°
                    <Trans id={temperatureUnitLabel} />
                  </Text>
                </Column>
              </Row>
            </Column>
            <Column modifiers={['col', 'padScale_0']}>
              <Row modifiers={['padScaleX_0']}>
                <Column modifiers={['padScaleX_0']}>
                  <Icon name="wind" />
                </Column>
              </Row>
              <Row modifiers={['padScaleX_0']}>
                <Column modifiers={['padScale_0']}>
                  <Text modifiers={['small', 'textLight']}>
                    <Trans>Winds</Trans>
                  </Text>
                </Column>
              </Row>
              <Row modifiers={['padScaleX_0']}>
                <Column modifiers={['padScale_0']}>
                  <Text>
                    <Trans id={degToCompass(windBearing)} />
                    {` ${Math.round(windSpeed)}`}
                    <Trans id={windSpeedUnitLabel} />
                  </Text>
                </Column>
              </Row>
            </Column>
            <Column modifiers={['padScale_0']}>
              <Row modifiers={['padScaleX_0']}>
                <Column modifiers={['padScaleX_0']}>
                  <Icon name={precipIcon} />
                </Column>
              </Row>
              <Row modifiers={['padScaleX_0']}>
                <Column modifiers={['padScale_0']}>
                  <Text modifiers={['small', 'textLight']}>
                    <Trans id={precipText} />
                  </Text>
                </Column>
              </Row>
              <Row modifiers={['padScaleX_0']}>
                <Column modifiers={['padScale_0']}>
                  <Text>{round(precipProbability * 100)}%</Text>
                </Column>
              </Row>
            </Column>
          </Row>
          <Row>
            <Column
              modifiers={['col']}
              style={{ alignItems: 'center', display: 'flex' }}
            >
              <ButtonToggle on={isMetric(unit)} onClick={onUnitToggle}>
                <ButtonToggle.OffLabel>°F</ButtonToggle.OffLabel>
                <ButtonToggle.OnLabel>°C</ButtonToggle.OnLabel>
              </ButtonToggle>
            </Column>
          </Row>
        </StatusPanel>
      </Row>
    </>
  );
}

WeatherDetailRightPanel.propTypes = {
  unit: PropTypes.string.isRequired,
  weather: PropTypes.shape({
    current: PropTypes.shape({
      code: PropTypes.string.isRequired,
      windBearing: PropTypes.number.isRequired,
      windSpeed: PropTypes.number.isRequired,
    }).isRequired,
    today: PropTypes.shape({
      precipProbability: PropTypes.number.isRequired,
      temperatureLow: PropTypes.number.isRequired,
      temperatureHigh: PropTypes.number.isRequired,
    }).isRequired,
    hourly: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
        temperature: PropTypes.number.isRequired,
        time: PropTypes.number.isRequired,
      }),
    ).isRequired,
    timezone: PropTypes.string.isRequired,
  }).isRequired,
  onUnitToggle: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    unit: unitSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onUnitToggle: () => dispatch(toggleUnit()),
  };
}

export default compose(
  setDisplayName('WeatherDetailRightPanel'),
  connect(mapStateToProps, mapDispatchToProps),
)(WeatherDetailRightPanel);

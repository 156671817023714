import { merge } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { Icon } from 'base-components';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import {
  modifierConfig,
  themePropTypes as weatherThemePropTypes,
} from 'utils/weather';

const styles = ({ theme }) => `
  align-items: center;
  background-color: ${theme.colors.base.chrome200};
  border-radius: ${theme.dimensions.borderRadius};
  display: flex;
  height: ${px2rem(30)};
  justify-content: center;
  width: ${px2rem(30)};
`;

const themePropTypes = merge(weatherThemePropTypes, {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome200: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dimensions: PropTypes.shape({
    borderRadius: PropTypes.string.isRequired,
  }).isRequired,
});

const StyledWeatherBadge = buildStyledComponent(
  'StyledWeatherBadge',
  styled.div,
  styles,
  {
    modifierConfig,
    themePropTypes,
  },
);

function WeatherBadge({ name, ...props }) {
  return (
    <StyledWeatherBadge modifiers={[name]} {...props}>
      <Icon modifiers={['chrome000']} name={name} />
    </StyledWeatherBadge>
  );
}

WeatherBadge.propTypes = {
  name: PropTypes.string.isRequired,
};

WeatherBadge.displayName = 'WeatherBadge';
WeatherBadge.modifiers = modifierConfig;

export default WeatherBadge;

/* eslint-disable import/prefer-default-export */

import { get } from 'lodash';
import { px2rem } from 'decisiv-ui-utils';

import { responsiveBreakpoints } from './constants';

export const getBreakpointFor = (key, isNavExpanded) =>
  px2rem(
    get(
      responsiveBreakpoints,
      [isNavExpanded ? 'navExpanded' : 'navCollapsed', key],
      0,
    ),
  );

import React, { useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';

export const NAME = 'activeAfterHoursContactsQuery';

const query = gql`
  query techAdmin_activeAfterHoursContactsQuery($id: ID!) {
    dealer(id: $id) {
      id
      dealerAfterHoursContacts {
        activeAfterHoursContacts {
          contacts {
            primaryContact {
              id
              name
            }
            backupContact {
              id
              name
            }
            finalContact {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const withActiveAfterHoursContacts = (Component) => (cProps) => {
  const { data, loading, error } = useQuery(query, {
    skip: !cProps.dealerId,
    variables: { id: cProps.dealerId },
  });

  const contacts = useMemo(
    () =>
      data?.dealer?.dealerAfterHoursContacts?.activeAfterHoursContacts
        ?.contacts || {},
    [data],
  );

  return (
    <Component
      {...cProps}
      activeAfterHoursContacts={contacts}
      loadingActiveAHContacts={loading}
      loadingActiveAHContactsError={error}
    />
  );
};

export default withActiveAfterHoursContacts;

import React from 'react';
import { curry } from 'lodash';

import DebouncedQuery from 'components/DebouncedQuery';

import CustomerWidgetContext from './CustomerWidgetContext';
import searchCustomersQuery from './searchCustomersQuery';

export function skipQuery({ searchString }) {
  return !searchString || searchString.length < 3;
}

export function buildQueryVariables({ searchValue = '' }) {
  return { searchString: searchValue };
}

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const { data: { searchCustomers } = {}, pending = false } = queryProps;

    return (
      <WrappedComponent
        {...componentProps}
        customers={searchCustomers}
        isSearching={pending}
      />
    );
  },
);

function buildWrappedComponentWithDebouncedQuery(
  WrappedComponent,
  componentProps,
) {
  return (
    <DebouncedQuery
      buildQueryVariables={buildQueryVariables}
      debounceMS={1000}
      query={searchCustomersQuery}
      searchValue={componentProps.customerSearchValue}
      skipQuery={skipQuery}
      fetchPolicy="no-cache"
      {...componentProps}
    >
      {buildQueryChild(WrappedComponent, componentProps)}
    </DebouncedQuery>
  );
}

export const updateCustomerSearchValue = curry(
  (setContext, customerSearchValue) => setContext({ customerSearchValue }),
);

export const buildConsumerChild = curry(
  (WrappedComponent, componentProps, consumerProps) => {
    const { customerSearchValue, updateCustomerWidgetContext } = consumerProps;

    return buildWrappedComponentWithDebouncedQuery(WrappedComponent, {
      ...componentProps,
      customerSearchValue,
      onChangeCustomerSearchValue: updateCustomerSearchValue(
        updateCustomerWidgetContext,
      ),
    });
  },
);

const withCustomerSearch = (WrappedComponent) => (componentProps) => (
  <CustomerWidgetContext.Consumer>
    {buildConsumerChild(WrappedComponent, componentProps)}
  </CustomerWidgetContext.Consumer>
);

export default withCustomerSearch;

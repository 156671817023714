const scrollConfig = { block: 'center', behavior: 'smooth' };
const defaultOptions = { focus: true, selectText: true };

const isDom = (el) => el instanceof Element;

function highlightElement(element) {
  element.classList.add('highlight');

  function onAnimationEnd() {
    element.classList.remove('highlight');
    element.removeEventListener('animationend', onAnimationEnd);
  }

  element.addEventListener('animationend', onAnimationEnd);
  // make sure highlight is cleaned up after animation.
  // in some places it fails to cleanup for some reason.
  // FIXME: investigate why
  setTimeout(() => {
    if (element.classList.contains('highlight')) {
      onAnimationEnd();
    }
  }, 2500);
}

export const scrollToElement = (el) =>
  isDom(el) && el.scrollIntoView(scrollConfig);

export const scrollToAndHighlightElement = (el, opts = {}) => {
  const { focus, click, selectText, delay } = {
    ...defaultOptions,
    ...opts,
  };

  const onScrollEnd = () => {
    if (isDom(el)) {
      try {
        if (!!focus && el.focus) el.focus();
        if (!!click && el.click) el.click();
        if (!!selectText && el.select) el.select();

        highlightElement(el);
      } catch (error) {
        //
      }
    }
  };

  scrollToElement(el);

  // The scrollIntoView behaviour is not normalized
  // across vendors // so this timeout value is a best guess 🤞
  return setTimeout(onScrollEnd, delay || 400);
};

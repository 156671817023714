import PropTypes from 'prop-types';

import { Container } from 'styled-components-grid';
import { buildStyledComponent } from 'decisiv-ui-utils';

import { STATUS_BAR_HEIGHT, MAIN_CONTENT_PADDING } from './constants';

const modifierConfig = {
  info: ({ theme }) => `
    background: ${theme.colors.status.info};

    .indicator-content .Column > span {
      color: ${theme.colors.base.chrome000};
    }

    .indicator-content .Column > [data-status="INCOMPLETE"] {
      color: ${theme.colors.base.chrome700};
      opacity: 0.4;
    }
  `,

  success: ({ theme }) => `
    background: ${theme.colors.status.success};

    .indicator-content .Column > span {
      color: ${theme.colors.base.chrome000};
    }

    .indicator-content .Column > [data-status="INCOMPLETE"] {
      color: ${theme.colors.base.chrome000};
    }
  `,

  warning: ({ theme }) => `
    background: ${theme.colors.status.warning};

    .indicator-content .Column > span {
      color: ${theme.colors.base.chrome700};
    }

    .indicator-content .Column > [data-status="INCOMPLETE"] {
      color: ${theme.colors.base.chrome000};
    }
  `,

  sticky: () => `
    position: fixed;
    z-index: 3;
  `,

  wide: () => `
    margin-left: -${MAIN_CONTENT_PADDING};
    margin-right: -${MAIN_CONTENT_PADDING};
  `,
};

const styles = ({ theme }) => `
  align-items: center;
  display: flex;
  height: ${STATUS_BAR_HEIGHT};
  position: static;
  right: ${MAIN_CONTENT_PADDING};
  top: ${theme.dimensions.topNavHeight};
  transition: all .3s ease-in-out;
  width: auto;
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome000: PropTypes.string.isRequired,
      chrome700: PropTypes.string.isRequired,
    }).isRequired,
    status: PropTypes.shape({
      info: PropTypes.string.isRequired,
      success: PropTypes.string.isRequired,
      warning: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dimensions: PropTypes.shape({
    topNavHeight: PropTypes.string.isRequired,
  }).isRequired,
};

export default buildStyledComponent('Status', Container, styles, {
  modifierConfig,
  themePropTypes,
});

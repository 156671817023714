import { parseToRgb, rgb } from 'polished';

/**
 * Convert an RGBA color (containing an alpha component) into an opaque
 * RGB color, as if this color was rendered over the given background.
 *
 * @param {string} foreground - the foreground CSS color value
 * @param {string} background - the background CSS color value
 * @return {string} - the resulting non-alpha CSS color value
 *
 * TODO: move this into decisiv-ui-utils and use it to render "light" color variations in the theme
 */
// eslint-disable-next-line import/prefer-default-export
export function rgbaToRgb(foreground, background = '#ffffff') {
  const fg = parseToRgb(foreground);
  const bg = parseToRgb(background);
  const result = ['red', 'green', 'blue'].reduce(
    (values, key) => ({
      ...values,
      [key]: Math.round(fg[key] * fg.alpha + bg[key] * (1.0 - fg.alpha)),
    }),
    {},
  );
  return rgb(result);
}

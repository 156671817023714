import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';
import { ButtonIconRectangle, Dropdown, Text, Tooltip } from 'base-components';

import Ul from 'elements/Ul';
import SelectableLi from 'elements/SelectableLi';

const renderButton = (iconName, tooltip) => {
  const button = (
    <ButtonIconRectangle>
      <ButtonIconRectangle.Icon name={iconName} />
    </ButtonIconRectangle>
  );

  return tooltip ? (
    <Tooltip position="left">
      <Tooltip.Target>{button}</Tooltip.Target>
      <Tooltip.Content>
        <Trans id={tooltip} />
      </Tooltip.Content>
    </Tooltip>
  ) : (
    button
  );
};

export function MenuButton(props) {
  const { onSelect, items, width, iconName, tooltip, ...rest } = props;

  const contentStyles = {
    left: 'auto',
    right: px2rem(-14),
    width,
    whiteSpace: width ? undefined : 'nowrap',
  };

  const handleItemClick = (item) => {
    if (typeof item.onSelect === 'function') item.onSelect();
    if (typeof props.onSelect === 'function') props.onSelect(item.key, item);
  };

  return (
    <Dropdown arrow fullWidth hideOnChange position="bottomLeft" {...rest}>
      <Dropdown.Target>{renderButton(iconName, tooltip)}</Dropdown.Target>
      <Dropdown.Content style={contentStyles}>
        <Ul>
          {items.map((item) =>
            item.label ? (
              <SelectableLi
                key={item.key}
                onClick={() => handleItemClick(item)}
                disabled={!!item.disabled}
                modifiers={item.disabled ? ['disabled'] : undefined}
              >
                <Row>
                  <Column modifiers={['col', 'padScaleX_3', 'padScaleY_2']}>
                    <Text modifiers="textLight">
                      <Trans id={item.label} />
                    </Text>
                  </Column>
                </Row>
              </SelectableLi>
            ) : (
              <Dropdown.Divider key={item.key} />
            ),
          )}
        </Ul>
      </Dropdown.Content>
    </Dropdown>
  );
}

MenuButton.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  width: PropTypes.string,
  zIndex: PropTypes.number,
  iconName: PropTypes.string,
  onSelect: PropTypes.func,
  tooltip: PropTypes.string,
};

MenuButton.defaultProps = {
  width: undefined,
  zIndex: 2,
  tooltip: undefined,
  iconName: 'ellipsis-h',
  onSelect: undefined,
};

export default MenuButton;

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const styles = ({ theme }) => `
  background: ${theme.colors.base.chrome100};
  border-radius: ${theme.dimensions.borderRadius};
  box-shadow: 0 3px 7px ${theme.colors.base.shadow};
  left: ${theme.dimensions.leftNavWidthCollapsed};
  position: absolute;
  top: 0;
  width: ${px2rem(180)};
  z-index: 100;
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome100: PropTypes.string.isRequired,
      shadow: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dimensions: PropTypes.shape({
    borderRadius: PropTypes.string.isRequired,
    leftNavWidthCollapsed: PropTypes.string.isRequired,
  }).isRequired,
};

export default buildStyledComponent('MenuItemFlyout', styled.div, styles, {
  themePropTypes,
});

import React, { useCallback, useContext } from 'react';
import { isEmpty, noop } from 'lodash';

import { Context as DealerLocatorContext } from './DealerLocatorContext';

const useDealerActions = (props) => {
  const { updateDealerLocatorContext: updateContext } = props;
  const { selectedDealer, onSelectDealer = noop, toggleDetailView } = props;

  const highlightDealer = useCallback(
    (highlightedDealer) => updateContext({ highlightedDealer }),
    [updateContext],
  );

  const selectDealer = useCallback(
    (dealerId, index, location) =>
      updateContext(
        { selectedDealer: { dealerId, index, location } },
        // The isEmpty check is for when the user is viewing a dealer detail
        // and chooses a different dealer on the map.
        () => {
          if (isEmpty(selectedDealer) && toggleDetailView) toggleDetailView();

          onSelectDealer({ dealerId, index, location });
        },
      ),
    [updateContext, onSelectDealer, selectedDealer, toggleDetailView],
  );

  const clearSelectedDealer = useCallback(
    () =>
      updateContext({ selectedDealer: undefined }, () => {
        toggleDetailView && toggleDetailView();

        onSelectDealer(undefined);
      }),
    [updateContext, toggleDetailView, onSelectDealer],
  );

  return { highlightDealer, selectDealer, clearSelectedDealer };
};

const withDealerActions = (WrappedComponent) => (props) => {
  const context = useContext(DealerLocatorContext);

  const {
    selectDealer,
    highlightDealer,
    clearSelectedDealer,
  } = useDealerActions(context);

  return (
    <WrappedComponent
      {...props}
      selectDealer={selectDealer}
      highlightDealer={highlightDealer}
      displayDetailView={context.displayDetailView}
      clearSelectedDealer={clearSelectedDealer}
      displayDetailViewEnabled={Boolean(context.toggleDetailView)}
    />
  );
};

export default withDealerActions;

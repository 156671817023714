import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';

import getBrowserLocale from 'utils/browserLocale';
import { METRIC, IMPERIAL } from 'utils/unit';

import {
  LOCALSTORAGE_PREFERENCES_KEY,
  SET_DEALER_DETAIL_ACCORDIONS_EXPANDED,
  SET_EXPANDED_MENUS,
  SET_LEFT_NAV_STATE,
  TOGGLE_UNIT,
} from '../constants';

export function getDefaultState(navigator) {
  const browserLocale = getBrowserLocale(navigator);
  return {
    // default preferences
    browserLocale,
    dealerDetailAccordionsExpanded: {},
    expandedMenus: [],
    language: browserLocale,
    leftNavState: { requestedExpanded: undefined, isExpanded: true },
    unit: !browserLocale || browserLocale === 'en-US' ? IMPERIAL : METRIC,
  };
}

/**
 * Attempt to load the initial app UI state from the localStorage.  Note that this is
 * purposefully using localStorage and not the memoryDB proxy because...
 *  * memoryDB is not available at app bootstrap
 *  * the only reason for this loadInitialState to exist is to re-hydrate the persisted
 *    app UI state; memoryDB is not persisted
 *  * if localStorage is not available (e.g. Safari Private Browsing) then getItem just
 *    returns null, so the defaults above will be used instead, and that's fine.
 */
export function loadInitialState(storage = localStorage) {
  const defaultState = getDefaultState();
  let preferencesState =
    storage && storage.getItem && storage.getItem(LOCALSTORAGE_PREFERENCES_KEY);
  if (preferencesState) {
    try {
      preferencesState = JSON.parse(preferencesState);

      // iterate over the default appstate, and add the default key/value if not currently present.
      Object.keys(defaultState).forEach((k) => {
        if (preferencesState[k] === undefined) {
          preferencesState[k] = defaultState[k];
        }
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(
        `error parsing ${LOCALSTORAGE_PREFERENCES_KEY} value from localStorage:`,
        e,
      );
      preferencesState = null;
    }
  }

  if (!preferencesState) {
    preferencesState = defaultState;
  }

  return fromJS({
    dealerDetailAccordionsExpanded:
      preferencesState.dealerDetailAccordionsExpanded,
    expandedMenus: preferencesState.expandedMenus,
    language: preferencesState.language,
    leftNavState: preferencesState.leftNavState,
    unit: preferencesState.unit,
  });
}

function toggleUnit(state) {
  const newUnit = state.get('unit') === METRIC ? IMPERIAL : METRIC;
  return state.set('unit', newUnit);
}

function setDealerDetailAccordionsExpanded(state, { payload }) {
  return state.set(
    'dealerDetailAccordionsExpanded',
    payload.accordionsExpanded,
  );
}

function setLeftNavState(state, { payload }) {
  return state.set('leftNavState', fromJS(payload));
}

function setExpandedMenus(state, { payload }) {
  return state.set('expandedMenus', fromJS(payload.expandedMenus));
}

export default handleActions(
  {
    [SET_DEALER_DETAIL_ACCORDIONS_EXPANDED]: setDealerDetailAccordionsExpanded,
    [SET_EXPANDED_MENUS]: setExpandedMenus,
    [SET_LEFT_NAV_STATE]: setLeftNavState,
    [TOGGLE_UNIT]: toggleUnit,
  },
  loadInitialState(),
);

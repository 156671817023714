import { Trans } from '@lingui/macro';
import { capitalize, get, lowerCase } from 'lodash';
import React from 'react';

import { Text } from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';

import { optionsByType } from 'compositions/CaseRequestsPanel/CaseRequestsPanelContext/withRequestLineOptions/constants';

const {
  requestedAction: requestedActionStrings,
  tireCondition: tireConditionStrings,
  // rimType: rimTypeStrings,
  tirePosition: tirePositionStrings,
  productType: productTypeStrings,
} = optionsByType;

const commonCellStyles = {
  flex: 1,
  minWidth: px2rem(20),
};

const textColumnStyles = {
  maxWidth: '100%',
};

export default [
  {
    name: 'unit',
    cellKeyGetter: ({ id }) => `${id}:unit`,
    cellDataGetter: ({ asset }) => ({ asset }),
    cellStyles: commonCellStyles,
    headerCellRenderer: () => (
      <Row>
        <Column style={textColumnStyles} modifiers={['padScaleX_2']}>
          <Text modifiers={['small', 'textLight']}>
            <Trans>Unit</Trans>
          </Text>
        </Column>
      </Row>
    ),
    dataCellRenderer: (
      { asset }, // eslint-disable-line react/prop-types
    ) => {
      const unitNumber = get(asset, 'unitNumber');
      return (
        <Row>
          <Column style={textColumnStyles} modifiers={['padScaleX_2']}>
            {unitNumber ? (
              <Text modifiers={['small']}>{unitNumber}</Text>
            ) : (
              <Text modifiers={['small']}>&mdash;</Text>
            )}
          </Column>
        </Row>
      );
    },
  },
  {
    name: 'action',
    cellKeyGetter: ({ id }) => `${id}:action`,
    cellDataGetter: ({ requestedAction }) => ({ requestedAction }),
    cellStyles: { ...commonCellStyles, flex: 2 },
    headerCellRenderer: () => (
      <Row>
        <Column style={textColumnStyles} modifiers={['padScaleX_2']}>
          <Text modifiers={['small', 'textLight']}>
            <Trans>Action</Trans>
          </Text>
        </Column>
      </Row>
    ),
    dataCellRenderer: (
      { requestedAction }, // eslint-disable-line react/prop-types
    ) => (
      <Row>
        <Column style={textColumnStyles} modifiers={['padScaleX_2']}>
          {requestedAction ? (
            <Text modifiers={['small']}>
              <Trans
                id={
                  requestedActionStrings[requestedAction] ||
                  capitalize(lowerCase(requestedAction))
                }
              />
            </Text>
          ) : (
            <Text modifiers={['small']}>&mdash;</Text>
          )}
        </Column>
      </Row>
    ),
  },
  {
    name: 'tireCondition',
    cellKeyGetter: ({ id }) => `${id}:tireCondition`,
    cellDataGetter: ({ tireCondition }) => ({ tireCondition }),
    cellStyles: { ...commonCellStyles, flex: 2 },
    headerCellRenderer: () => (
      <Row>
        <Column style={textColumnStyles} modifiers={['padScaleX_2']}>
          <Text modifiers={['small', 'textLight']}>
            <Trans>Tire Condition</Trans>
          </Text>
        </Column>
      </Row>
    ),
    dataCellRenderer: (
      { tireCondition }, // eslint-disable-line react/prop-types
    ) => (
      <Row>
        <Column style={textColumnStyles} modifiers={['padScaleX_2']}>
          {tireCondition ? (
            <Text modifiers={['small']}>
              <Trans
                id={
                  tireConditionStrings[tireCondition] ||
                  capitalize(lowerCase(tireCondition))
                }
              />
            </Text>
          ) : (
            <Text modifiers={['small']}>&mdash;</Text>
          )}
        </Column>
      </Row>
    ),
  },
  {
    name: 'tirePosition',
    cellKeyGetter: ({ id }) => `${id}:tirePosition`,
    cellDataGetter: ({ tirePosition }) => ({ tirePosition }),
    cellStyles: commonCellStyles,
    headerCellRenderer: () => (
      <Row>
        <Column style={textColumnStyles} modifiers={['padScaleX_2']}>
          <Text modifiers={['small', 'textLight']}>
            <Trans>Tire Position</Trans>
          </Text>
        </Column>
      </Row>
    ),
    dataCellRenderer: (
      { tirePosition }, // eslint-disable-line react/prop-types
    ) => (
      <Row>
        <Column style={textColumnStyles} modifiers={['padScaleX_2']}>
          {tirePosition ? (
            <Text modifiers={['small']}>
              <Trans
                id={
                  tirePositionStrings[tirePosition] ||
                  capitalize(lowerCase(tirePosition))
                }
              />
            </Text>
          ) : (
            <Text modifiers={['small']}>&mdash;</Text>
          )}
        </Column>
      </Row>
    ),
  },
  {
    name: 'tireType',
    cellKeyGetter: ({ id }) => `${id}:tireType`,
    cellDataGetter: ({ productType }) => ({ productType }),
    cellStyles: commonCellStyles,
    headerCellRenderer: () => (
      <Row>
        <Column style={textColumnStyles} modifiers={['padScaleX_2']}>
          <Text modifiers={['small', 'textLight']}>
            <Trans>Tire Type</Trans>
          </Text>
        </Column>
      </Row>
    ),
    dataCellRenderer: (
      { productType }, // eslint-disable-line react/prop-types
    ) => (
      <Row>
        <Column style={textColumnStyles} modifiers={['padScaleX_2']}>
          {productType ? (
            <Text modifiers={['small']}>
              <Trans
                id={
                  productTypeStrings[productType] ||
                  capitalize(lowerCase(productType))
                }
              />
            </Text>
          ) : (
            <Text modifiers={['small']}>&mdash;</Text>
          )}
        </Column>
      </Row>
    ),
  },
  {
    name: 'brand',
    cellKeyGetter: ({ id }) => `${id}:brand`,
    cellDataGetter: ({ manufacturerFullName }) => ({ manufacturerFullName }),
    cellStyles: { ...commonCellStyles, flex: 2 },
    headerCellRenderer: () => (
      <Row>
        <Column style={textColumnStyles} modifiers={['padScaleX_2']}>
          <Text modifiers={['small', 'textLight']}>
            <Trans>Brand</Trans>
          </Text>
        </Column>
      </Row>
    ),
    dataCellRenderer: (
      { manufacturerFullName }, // eslint-disable-line react/prop-types
    ) => (
      <Row>
        <Column style={textColumnStyles} modifiers={['padScaleX_2']}>
          {manufacturerFullName ? (
            <Text modifiers={['small']}>{manufacturerFullName}</Text>
          ) : (
            <Text modifiers={['small']}>&mdash;</Text>
          )}
        </Column>
      </Row>
    ),
  },
  {
    name: 'tireSize',
    cellKeyGetter: ({ id }) => `${id}:tireSize`,
    cellDataGetter: ({ tireSize }) => ({ tireSize }),
    cellStyles: commonCellStyles,
    headerCellRenderer: () => (
      <Row>
        <Column style={textColumnStyles} modifiers={['padScaleX_2']}>
          <Text modifiers={['small', 'textLight']}>
            <Trans>Tire Size</Trans>
          </Text>
        </Column>
      </Row>
    ),
    dataCellRenderer: (
      { tireSize }, // eslint-disable-line react/prop-types
    ) => (
      <Row>
        <Column style={textColumnStyles} modifiers={['padScaleX_2']}>
          {tireSize ? (
            <Text modifiers={['small']}>{tireSize}</Text>
          ) : (
            <Text modifiers={['small']}>&mdash;</Text>
          )}
        </Column>
      </Row>
    ),
  },
  {
    name: 'treadDesign',
    cellKeyGetter: ({ id }) => `${id}:treadDesign`,
    cellDataGetter: ({ sculptureTreadName }) => ({ sculptureTreadName }),
    cellStyles: commonCellStyles,
    headerCellRenderer: () => (
      <Row>
        <Column style={textColumnStyles} modifiers={['padScaleX_2']}>
          <Text modifiers={['small', 'textLight']}>
            <Trans>Tread Design</Trans>
          </Text>
        </Column>
      </Row>
    ),
    dataCellRenderer: (
      { sculptureTreadName }, // eslint-disable-line react/prop-types
    ) => (
      <Row>
        <Column style={textColumnStyles} modifiers={['padScaleX_2']}>
          {sculptureTreadName ? (
            <Text modifiers={['small']}>{sculptureTreadName}</Text>
          ) : (
            <Text modifiers={['small']}>&mdash;</Text>
          )}
        </Column>
      </Row>
    ),
  },
  {
    name: 'loadRange',
    cellKeyGetter: ({ id }) => `${id}:loadRange`,
    cellDataGetter: ({ loadRange }) => ({ loadRange }),
    cellStyles: commonCellStyles,
    headerCellRenderer: () => (
      <Row>
        <Column style={textColumnStyles} modifiers={['padScaleX_2']}>
          <Text modifiers={['small', 'textLight']}>
            <Trans>Load Range</Trans>
          </Text>
        </Column>
      </Row>
    ),
    dataCellRenderer: (
      { loadRange }, // eslint-disable-line react/prop-types
    ) => (
      <Row>
        <Column style={textColumnStyles} modifiers={['padScaleX_2']}>
          {loadRange ? (
            <Text modifiers={['small']}>{loadRange}</Text>
          ) : (
            <Text modifiers={['small']}>&mdash;</Text>
          )}
        </Column>
      </Row>
    ),
  },
  // {
  //   name: 'rimType',
  //   cellKeyGetter: ({ id }) => `${id}:rimType`,
  //   cellDataGetter: ({ rimType }) => ({ rimType }),
  //   cellStyles: commonCellStyles,
  //   headerCellRenderer: () => (
  //     <Row>
  //       <Column style={textColumnStyles} modifiers={['padScaleX_2']}>
  //         <Text modifiers={['small', 'textLight']}>
  //           <Trans>Rim Type</Trans>
  //         </Text>
  //       </Column>
  //     </Row>
  //   ),
  //   dataCellRenderer: (
  //     { rimType }, // eslint-disable-line react/prop-types
  //   ) => (
  //     <Row>
  //       <Column style={textColumnStyles} modifiers={['padScaleX_2']}>
  //         {rimType ? (
  //           <Text modifiers={['small']}>
  //             <Trans
  //               id={rimTypeStrings[rimType] || capitalize(lowerCase(rimType))}
  //             />
  //           </Text>
  //         ) : (
  //           <Text modifiers={['small']}>&mdash;</Text>
  //         )}
  //       </Column>
  //     </Row>
  //   ),
  // },
];

import PropTypes from 'prop-types';

const fontColors = {
  danger: ({ theme }) => `color: ${theme.colors.status.danger};`,
  default: ({ theme }) => `color: ${theme.colors.status.default};`,
  info: ({ theme }) => `color: ${theme.colors.status.info};`,
  success: ({ theme }) => `color: ${theme.colors.status.success};`,
  text: ({ theme }) => `color: ${theme.colors.base.text};`,
  textLight: ({ theme }) => `color: ${theme.colors.base.textLight};`,
  warning: ({ theme }) => `color: ${theme.colors.status.warning};`,
};

const fontColorPropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      text: PropTypes.string.isRequired,
      textLight: PropTypes.string.isRequired,
    }).isRequired,
    status: PropTypes.shape({
      danger: PropTypes.string.isRequired,
      default: PropTypes.string.isRequired,
      info: PropTypes.string.isRequired,
      success: PropTypes.string.isRequired,
      warning: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export { fontColors, fontColorPropTypes };

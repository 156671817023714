import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/string/match-all';
import 'intersection-observer';
import 'setup/ignoreConsoleWarnings';

import React from 'react';
import { render } from 'react-dom';

import App, { store, installHotjar, unregisterServiceWorker } from 'setup';

installHotjar();

function renderApp(AppComponent, appStore) {
  render(<AppComponent store={appStore} />, document.getElementById('root'));
}

renderApp(App, store);

if (module.hot && process.env.NODE_ENV !== 'production') {
  module.hot.accept('./setup/index.js', () => {
    // eslint-disable-next-line global-require
    const NewApp = require('./setup/index.js').default;
    renderApp(NewApp, store);
  });
}

unregisterServiceWorker();

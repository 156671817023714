import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import withUpdateCaseNote from '../withUpdateCaseNote';

const withUpdateForm = (WrappedComponent) => {
  class UpdateForm extends Component {
    static propTypes = {
      notes: PropTypes.string,
      onCancel: PropTypes.func.isRequired,
      caseNoteId: PropTypes.string.isRequired,
      updateCaseNote: PropTypes.func.isRequired,
      onSubmitSuccess: PropTypes.func.isRequired,
    };

    static defaultProps = { notes: '' };

    handleSubmit = async (body) => {
      const { onSubmitSuccess, caseNoteId, updateCaseNote } = this.props;

      await updateCaseNote({ variables: { caseNoteId, body } });

      setTimeout(onSubmitSuccess, 500);
    };

    render() {
      const { notes, onCancel } = this.props;

      return (
        <WrappedComponent
          autoFocus
          notes={notes}
          onCancel={onCancel}
          onSubmit={this.handleSubmit}
          saveButtonLabel={<Trans>Save</Trans>}
        />
      );
    }
  }

  return compose(setDisplayName('UpdateForm'), withUpdateCaseNote)(UpdateForm);
};

export default withUpdateForm;

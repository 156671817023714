import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { Formik, Form, Field } from 'formik';
import { curry, has, isEmpty } from 'lodash';

import {
  H2,
  InputField,
  InputGroup,
  MessageSmall,
  P,
  QuickActionButton,
  Text,
} from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';

import Dot from 'elements/Dot';
import Modal from 'components/Modal';

import {
  cardInfoPropType,
  FIELDS,
  FIELDS_VALIDATION_SCHEMA,
  MONTH_OPTIONS,
  YEAR_OPTIONS,
} from './constants';
import InputSelect from './InputSelect';

function getInitialValues(fields, overrides = {}) {
  return Object.keys(fields).reduce((acc, key) => {
    acc[key] = has(overrides, key) ? overrides[key] : '';
    return acc;
  }, {});
}

function shouldDisplayErrors({ errors, touched, values }) {
  const hasEmptyStrings = Object.values(values).some((field) => field === '');
  const hasErrors = !isEmpty(errors);
  const touchedAllFields =
    Object.keys(touched).length === Object.keys(FIELDS).length;

  return hasErrors && touchedAllFields && !hasEmptyStrings;
}

const isFieldDisplayValid = curry(({ errors }, displayErrors, { name }) => {
  if (!displayErrors) {
    return true;
  }

  if (isEmpty(errors[name])) {
    return true;
  }

  return false;
});

function FormModalBody({ cardInfo, closeModal, handleSubmit }) {
  return (
    <Formik
      initialValues={getInitialValues(FIELDS, cardInfo)}
      onSubmit={handleSubmit}
      validationSchema={FIELDS_VALIDATION_SCHEMA}
    >
      {(formProps) => {
        const displayErrors = shouldDisplayErrors(formProps);
        const isFieldValid = isFieldDisplayValid(formProps, displayErrors);

        return (
          <Form>
            <Modal.Body>
              <Modal.Header>
                <Modal.HeaderIcon name="credit-card" />
              </Modal.Header>
              <Modal.Content>
                <Row modifiers={['center']}>
                  <Column>
                    <H2 modifiers={['fontWeightRegular']}>
                      <Trans>Validate Existing Credit Card</Trans>
                    </H2>
                  </Column>
                </Row>
                <Row modifiers={['center']}>
                  <Column>
                    <P style={{ marginBottom: px2rem(20) }}>
                      <Trans>
                        Enter the cardholder name, first and last four digits of
                        credit card, and expiration date.
                      </Trans>
                    </P>
                  </Column>
                </Row>
                {displayErrors && (
                  <Row modifiers={['padScaleY_2']}>
                    <Column>
                      <MessageSmall type="warning">
                        <Trans>
                          All fields must be completed for validation.
                        </Trans>
                      </MessageSmall>
                    </Column>
                  </Row>
                )}
                <Row modifiers={['padScaleY_2']}>
                  <Column modifiers={['col']}>
                    <Field name={FIELDS.cardholder.name}>
                      {({ field }) => (
                        <InputField
                          {...field}
                          isValid={isFieldValid(field)}
                          placeholder={i18n._(FIELDS.cardholder.placeholder)}
                        >
                          <Column modifiers={['col', 'padScaleY_0']}>
                            <Row>
                              <InputField.Label>
                                <Trans id={FIELDS.cardholder.label} />
                              </InputField.Label>
                            </Row>
                            <Row>
                              <InputField.TextField />
                            </Row>
                          </Column>
                        </InputField>
                      )}
                    </Field>
                  </Column>
                </Row>
                <Row modifiers={['padScaleY_2']}>
                  <Column modifiers={['col_8']}>
                    <Row>
                      <Column modifiers={['padScaleX_0']}>
                        <Text modifiers={['small', 'textLight']}>
                          <Trans>Credit Card Number</Trans>
                        </Text>
                      </Column>
                    </Row>
                    <InputGroup.Row modifiers={['bottom']}>
                      <InputGroup.Column modifiers={['col_3']}>
                        <Field name={FIELDS.firstDigit.name}>
                          {({ field }) => (
                            <InputField
                              {...field}
                              isValid={isFieldValid(field)}
                              maxLength={1}
                              placeholder={i18n._(
                                FIELDS.firstDigit.placeholder,
                              )}
                            >
                              <Column modifiers={['col', 'padScaleY_0']}>
                                <Row>
                                  <InputField.Label>
                                    <Trans id={FIELDS.firstDigit.label} />
                                  </InputField.Label>
                                </Row>
                                <Row>
                                  <InputField.TextField />
                                </Row>
                              </Column>
                            </InputField>
                          )}
                        </Field>
                      </InputGroup.Column>
                      <InputGroup.Column
                        modifiers={['col', 'center', 'padScaleY_1']}
                      >
                        <Dot repeat={[3, 4, 4]} />
                      </InputGroup.Column>
                      <InputGroup.Column modifiers={['col']}>
                        <Field name={FIELDS.lastFourDigits.name}>
                          {({ field }) => (
                            <InputField
                              {...field}
                              isValid={isFieldValid(field)}
                              maxLength={4}
                              placeholder={i18n._(
                                FIELDS.lastFourDigits.placeholder,
                              )}
                            >
                              <Column modifiers={['col', 'padScaleY_0']}>
                                <Row>
                                  <InputField.Label>
                                    <Trans id={FIELDS.lastFourDigits.label} />
                                  </InputField.Label>
                                </Row>
                                <Row>
                                  <InputField.TextField />
                                </Row>
                              </Column>
                            </InputField>
                          )}
                        </Field>
                      </InputGroup.Column>
                    </InputGroup.Row>
                  </Column>
                  <Column modifiers={['col_4']}>
                    <Row>
                      <Column modifiers={['padScaleX_0']}>
                        <Text modifiers={['small', 'textLight']}>
                          <Trans>Expiration Date</Trans>
                        </Text>
                      </Column>
                    </Row>
                    <InputGroup.Row>
                      <InputGroup.Column modifiers={['col']}>
                        <Field name={FIELDS.expMonth.name}>
                          {({ field }) => (
                            <InputSelect
                              field={field}
                              isValid={isFieldValid(field)}
                              inputAttrs={FIELDS.expMonth}
                              options={MONTH_OPTIONS}
                            />
                          )}
                        </Field>
                      </InputGroup.Column>
                      <InputGroup.Column modifiers={['col']}>
                        <Field name={FIELDS.expYear.name}>
                          {({ field }) => (
                            <InputSelect
                              field={field}
                              isValid={isFieldValid(field)}
                              inputAttrs={FIELDS.expYear}
                              options={YEAR_OPTIONS}
                            />
                          )}
                        </Field>
                      </InputGroup.Column>
                    </InputGroup.Row>
                  </Column>
                </Row>
              </Modal.Content>
              <Modal.Footer>
                <Row modifiers={['end']}>
                  <Column>
                    <QuickActionButton onClick={closeModal} type="button">
                      <QuickActionButton.Text>
                        <Trans>Cancel</Trans>
                      </QuickActionButton.Text>
                    </QuickActionButton>
                  </Column>
                  <Column>
                    <QuickActionButton
                      disabled={!formProps.isValid}
                      modifiers={[
                        'secondary',
                        !formProps.isValid && 'disabled',
                      ]}
                      type="submit"
                    >
                      <QuickActionButton.Text>
                        <Trans>Submit</Trans>
                      </QuickActionButton.Text>
                    </QuickActionButton>
                  </Column>
                </Row>
              </Modal.Footer>
            </Modal.Body>
          </Form>
        );
      }}
    </Formik>
  );
}

FormModalBody.propTypes = {
  cardInfo: cardInfoPropType,
  closeModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

FormModalBody.defaultProps = { cardInfo: null };

export default FormModalBody;

import gql from 'graphql-tag';
import { curry } from 'lodash';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';

import { NAME as CASE_DELAYS_QUERY_NAME } from 'compositions/CaseDetailOverviewPanel/withCaseDelays/caseDelaysQuery';
import { NAME as CASE_DATA_QUERY_NAME } from '../withCaseData/caseDataQuery';

const updateCaseStatusChangeGQL = gql`
  mutation updateCaseStatusChange(
    $caseStatusChangeId: ID!
    $timestamp: Datetime!
  ) {
    updateCaseStatusChange(
      input: { id: $caseStatusChangeId, patch: { timestamp: $timestamp } }
    ) {
      statusChange {
        changedAt
        id
        newStatus
        oldStatus
      }
    }
  }
`;

const buildUpdateMutationChild = curry(
  (WrappedComponent, componentProps, mutate, { loading }) => (
    <WrappedComponent
      {...componentProps}
      updateCaseStatusChange={({ caseStatusChangeId, timestamp }) =>
        mutate({ variables: { caseStatusChangeId, timestamp } })
      }
      isUpdatingCaseStatusChange={!!loading}
    />
  ),
);

function withUpdateMutation(WrappedComponent, componentProps) {
  return (
    <Mutation
      mutation={updateCaseStatusChangeGQL}
      refetchQueries={[CASE_DATA_QUERY_NAME, CASE_DELAYS_QUERY_NAME]}
    >
      {buildUpdateMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

const withUpdateCaseStatusChange = (WrappedComponent) => (componentProps) =>
  withUpdateMutation(WrappedComponent, componentProps);

export default withUpdateCaseStatusChange;

import { curry } from 'lodash';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import gql from 'graphql-tag';

import buildRefetchQueries from './buildRefetchQueries';

const mutation = gql`
  mutation deleteAfterHoursEvents($eventIds: [ID!]!) {
    deleteAfterHoursEventsBatch(input: { ids: $eventIds }) {
      deletedCount
    }
  }
`;

export const buildMutationChild = curry(
  (WrappedComponent, componentProps, deleteAfterHoursEvents, mutationProps) => (
    <WrappedComponent
      {...componentProps}
      isDeletingAfterHoursEvents={!!mutationProps?.loading}
      deleteAfterHoursEvents={(eventIds) =>
        deleteAfterHoursEvents({
          variables: {
            eventIds,
          },
        })
      }
    />
  ),
);

function buildWrappedComponentWithMutation(WrappedComponent, componentProps) {
  return (
    <Mutation
      mutation={mutation}
      refetchQueries={buildRefetchQueries(componentProps)}
    >
      {buildMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

const withDeleteAfterHoursEvents = (WrappedComponent) => (componentProps) =>
  buildWrappedComponentWithMutation(WrappedComponent, componentProps);

export default withDeleteAfterHoursEvents;

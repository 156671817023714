import styled from 'styled-components';
import { buildStyledComponent } from 'decisiv-ui-utils';

const styles = `
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
`;

export default buildStyledComponent('Dropdown__List', styled.ul, styles);

import React from 'react';
import PropTypes from 'prop-types';
import { range } from 'lodash';
import styled from 'styled-components';
import moment from 'moment-timezone';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';
import { Label } from 'base-components';

import {
  ROW_HEADING_WIDTH,
  SECONDS_PER_TIME_BLOCK,
  TIME_BLOCK_HEIGHT,
  TIME_BLOCK_COUNT,
  TIME_BLOCKS_PER_DIVIDER,
  DAY_HEADER_HEIGHT,
} from './constants';

const HourLabelWrapper = buildStyledComponent(
  'ScheduleGrid__HourLabels',
  styled.div,
  `
    display: block;
    position: absolute;
    top: 0;
    left: -${ROW_HEADING_WIDTH};
    height: 100%;
    width: ${ROW_HEADING_WIDTH};
    padding-top: ${DAY_HEADER_HEIGHT};
    
    > ul {
      margin: 0;
    }
  `,
);

const HourLabel = buildStyledComponent(
  'ScheduleGrid__HourLabel',
  styled.li,
  ({ theme }) => `
    position: relative;
    height: ${px2rem(TIME_BLOCK_HEIGHT * TIME_BLOCKS_PER_DIVIDER)};
    text-align: right;
    padding-right: calc(${theme.dimensions.gridPad} * 2);
  `,
  {
    themePropTypes: {
      dimensions: PropTypes.shape({
        gridPad: PropTypes.string.isRequired,
      }).isRequired,
    },
  },
);

const HourLabelText = buildStyledComponent(
  'ScheduleGrid__HourLabelsText',
  Label,
  `
    position: relative;
    top: -${px2rem(11)};
  `,
);

/**
 * Renders the list of hours down the left border of the schedule
 * grid. One label is rendered for each time-block divider.
 * @return {ReactElement}
 */
export default function HourLabels() {
  // TODO: how should we show a daylight-savings-time transition day?
  //       (i.e. a day with > or < 24 hours)

  // midnight on Jan 1 of the current year:
  const startOfDay = moment().startOf('year');

  // create a range from midnight to midnight, stepped based
  // on the number of seconds per labeled time-block
  const labelRange = range(
    0,
    TIME_BLOCK_COUNT * SECONDS_PER_TIME_BLOCK + 1,
    TIME_BLOCKS_PER_DIVIDER * SECONDS_PER_TIME_BLOCK,
  );

  // map this range to a series of times, offset from the start of the day
  const hours = labelRange.map((labelNum) =>
    moment(startOfDay).add(labelNum, 'seconds'),
  );

  return (
    <HourLabelWrapper>
      <ul>
        {hours.map((hour) => (
          <HourLabel key={hour}>
            <HourLabelText>{hour.format('hA')}</HourLabelText>
          </HourLabel>
        ))}
      </ul>
    </HourLabelWrapper>
  );
}

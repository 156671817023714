import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { find, get, noop } from 'lodash';

import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';
import { Dropdown, InputField, Text } from 'base-components';

// Timezone long names are not available through moment.
// This list is based on the abbreviations found here:
// https://github.com/moment/moment-timezone/blob/develop/data/packed/latest.json
const timezoneLongNames = {
  ADT: t`Atlantic Daylight Time`,
  AHDT: t`Alaska-Hawaii Daylight Time`,
  AHST: t`Alaska-Hawaii Standard Time`,
  AKDT: t`Alaska Daylight Time`,
  AKST: t`Alaska Standard Time`,
  AST: t`Atlantic Standard Time`,
  CDT: t`Central Daylight Time`,
  CST: t`Central Standard Time`,
  EDT: t`Eastern Daylight Time`,
  EST: t`Eastern Standard Time`,
  HDT: t`Hawaii Daylight Time`,
  HST: t`Hawaii Standard Time`,
  MDT: t`Mountain Daylight Time`,
  MST: t`Mountain Standard Time`,
  NDT: t`Newfoundland Daylight Time`,
  NST: t`Newfoundland Standard Time`,
  PDT: t`Pacific Daylight Time`,
  PST: t`Pacific Standard Time`,
  YST: t`Yukon Standard Time`,
};

const optionValues = [
  ['America/St_Johns', t`(St. John's)`],
  ['America/Halifax', t`(Halifax)`],
  ['America/New_York', t`(New York, Toronto, Cancún)`],
  ['America/Chicago', t`(Chicago, Winnipeg, Mexico City)`],
  ['America/Denver', t`(Salt Lake City, Edmonton, Hermosillo)`],
  ['America/Los_Angeles', t`(Los Angeles, Vancouver, Tijuana)`],
  ['America/Anchorage', t`(Anchorage)`],
  ['Pacific/Honolulu', t`(Honolulu)`],
];

const buildDropdownOptions = () =>
  optionValues.map(([timezoneIdentifier, cities]) => {
    const timeInZone = moment.tz(timezoneIdentifier);
    const timezoneOffset = timeInZone.format('Z');
    const timezoneAbbr = timeInZone.format('z');
    const timezoneLongName = timezoneLongNames[timezoneAbbr];
    return {
      value: timezoneIdentifier,
      // this must return a string so it can be used as a value for the input field.
      label: t`(UTC${timezoneOffset}) ${timezoneAbbr} • ${timezoneLongName} ${cities}`,
    };
  });

function TimezoneDropdown({ isValid, label, name, onChange, value, readOnly }) {
  const options = buildDropdownOptions();
  return (
    <Dropdown
      onChange={(e, newValue) => onChange(name, newValue)}
      readOnly={readOnly}
      fullWidth
      hideOnChange
    >
      {({ isVisible }) => (
        <>
          <Dropdown.Target>
            <InputField
              isValid={isValid}
              name={name}
              value={get(find(options, { value }), 'label', '')}
              onChange={noop}
              readOnly={readOnly}
            >
              <Column modifiers="col">
                <Row>
                  <InputField.Label>{label}</InputField.Label>
                </Row>
                <Row>
                  <InputField.TextField
                    readOnly
                    autoComplete="off"
                    style={{
                      cursor: 'pointer',
                      caretColor: 'transparent',
                    }}
                  />
                  <InputField.ActionButton
                    icon={isVisible ? 'chevron-up' : 'chevron-down'}
                    modifiers={['hoverInfo']}
                    onClick={noop}
                    type="button"
                  />
                </Row>
              </Column>
            </InputField>
          </Dropdown.Target>
          <Dropdown.Content
            style={{ maxHeight: px2rem(360), overflowY: 'auto' }}
          >
            <Dropdown.List>
              {options.map((option) => (
                <Dropdown.ListItem
                  id={option.value}
                  key={option.value}
                  modifiers={[value === option.value ? 'selected' : undefined]}
                >
                  <Row>
                    <Column modifiers="padScale_0">
                      <Text>{option.label}</Text>
                    </Column>
                  </Row>
                </Dropdown.ListItem>
              ))}
            </Dropdown.List>
          </Dropdown.Content>
        </>
      )}
    </Dropdown>
  );
}

TimezoneDropdown.propTypes = {
  isValid: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

TimezoneDropdown.defaultProps = {
  isValid: true,
  readOnly: false,
};

export default TimezoneDropdown;

import { t, defineMessage } from '@lingui/macro';

export const pageTitles = {
  casePage: defineMessage({ message: 'ONCall - Case {caseNumber}' }),
  casePrintPage: defineMessage({ message: 'ONCall - Print Case {caseNumber}' }),
  dashboard: t`ONCall - Dashboard`,
  highlightedCases: t`ONCall - Cases Needing Validation`,
  dealerLocator: t`ONCall - Service Provider Locator`,
  techAdminDealerList: t`ONCall - Tech Admin - Service Providers`,
  techAdminDealerPage: t`ONCall - Tech Admin`,
  techAdminDealerSearch: t`ONCall - Tech Admin - Search`,
  reports: defineMessage({ message: 'ONCall - Reports - {reportType}' }),
};

export const pageMeta = {
  casePage: t`Case Detail`,
  casePrintPage: t`Print Case`,
  dashboard: t`Dashboard`,
  highlightedCases: t`Cases Needing Validation`,
  dealerLocator: t`Service Provider Locator`,
  techAdminDealerList: t`TechAdmin Service Providers`,
  techAdminDealerPage: t`TechAdmin Service Provider Detail`,
  techAdminDealerSearch: t`TechAdmin Service Provider Search`,
  reports: t`Reports`,
};

import React from 'react';
import gql from 'graphql-tag';
import { Query } from '@apollo/client/react/components';
import { curry, get } from 'lodash';

import { CASE_STATUS } from 'compositions/CaseStatus';

export const NAME = 'dealerActiveCasesQuery';

const activeStatuses = [
  CASE_STATUS.new,
  CASE_STATUS.dispatch,
  CASE_STATUS.dispatched,
  CASE_STATUS.enRoute,
  CASE_STATUS.arrived,
  CASE_STATUS.rolling,
];

const activeCasesQuery = gql`
  query dealerActiveCasesQuery($dealerId: ID!, $status: [CaseStatus]!) {
    searchCases(
      input: {
        sort: { direction: DESC, fields: [STATUS, INSERTED_AT] }
        status: $status
        dealerId: $dealerId
        dateRange: { startTime: null, endTime: null }
      }
    ) {
      cases {
        edges {
          node {
            id
            caseNumber
            status
            createdAt
            customer(useCachedDataForCase: true) {
              ... on Customer {
                id
                name
                type
              }
              ... on StoreCustomer {
                id
                name
                type
              }
              ... on CustomCustomer {
                name
                type
              }
              ... on CustomStoreCustomer {
                name
                type
              }
            }
            isDelayed
            caseDelays {
              id
              startTime
              endTime
              reasonType
              customReason
              status
            }
            quickNote
            primaryAsset {
              id
              unitNumber
            }
            statusHistory {
              id
              oldStatus
              newStatus
              changedAt
              createdAt
            }
            servicingDealer {
              id
              contactPerson
              phoneNumber
            }
          }
        }
      }
    }
  }
`;

export default curry((WrappedComponent, componentProps) => {
  const { dealerId } = componentProps;

  return (
    <Query
      skip={!dealerId}
      query={activeCasesQuery}
      variables={{ dealerId, status: activeStatuses }}
    >
      {({ data, loading }) => (
        <WrappedComponent
          {...componentProps}
          activeCases={get(data, 'searchCases.cases.edges', [])}
          isLoading={!!loading}
        />
      )}
    </Query>
  );
});

import React from 'react';
import { compact } from 'lodash';
import { Container, Row, Column } from 'styled-components-grid';
import { GhostIndicator } from 'base-components';

import ScrollableContainer from 'blocks/ScrollableContainer';

function GhostDealerList() {
  return (
    <ScrollableContainer modifiers={['fluid']}>
      <ScrollableContainer.List>
        {[0, 1, 2, 3].map((value) => (
          <ScrollableContainer.ListItem
            key={`DealerResults__GhostDealerList__ListItem__${value}`}
            modifiers={compact(['borders'])}
          >
            <Container>
              <Row>
                <Column modifiers={['col_1']}>
                  <GhostIndicator />
                </Column>
                <Column modifiers={['col_11']}>
                  <GhostIndicator />
                  <GhostIndicator />
                  <GhostIndicator />
                </Column>
              </Row>
            </Container>
          </ScrollableContainer.ListItem>
        ))}
      </ScrollableContainer.List>
    </ScrollableContainer>
  );
}

export default GhostDealerList;

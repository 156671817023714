import { Trans } from '@lingui/macro';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import { Divider, P, Text } from 'base-components';
import { Row, Column } from 'styled-components-grid';

import {
  evaluateOperatingStatusType,
  OPERATING_STATUS_TYPE,
} from 'utils/operatingStatusFilter';

import { dayList, formatHours, renderSoonWarning } from './List';

export function Summary({ dealer, relativeNow }) {
  const { ers247, ersHours, open247, timezone, workingHours } = dealer;
  const { value: operatingStatusTypeValue } = evaluateOperatingStatusType(
    dealer,
  );

  const isERSHoursAvailable =
    ers247 ||
    operatingStatusTypeValue === OPERATING_STATUS_TYPE.Unknown.value ||
    operatingStatusTypeValue === OPERATING_STATUS_TYPE.ERSHours.value ||
    !isEmpty(ersHours);

  const today = moment().tz(timezone);
  const dayOfWeekIndex = today.isoWeekday();

  return (
    <>
      <Row>
        <Column modifiers="col">
          <Text>
            <Trans>Today</Trans>
          </Text>
        </Column>
      </Row>
      <Row modifiers="padScaleY_0">
        <Column modifiers={['col', 'padScaleY_0']}>
          <Text modifiers={['small', 'textLight']}>
            <Trans>Business Hours</Trans>
          </Text>
        </Column>
      </Row>
      <Row modifiers="padScaleY_0">
        <Column modifiers={['col', 'padScaleY_0']}>
          <P>{formatHours(dayList[dayOfWeekIndex], workingHours, open247)}</P>
          {renderSoonWarning(dealer, dayOfWeekIndex, relativeNow)}
        </Column>
      </Row>
      {isERSHoursAvailable && (
        <>
          <Row modifiers="padScaleY_0">
            <Column modifiers={['col', 'padScaleY_0']}>
              <Text modifiers={['small', 'textLight']}>
                <Trans>ERS Hours</Trans>
              </Text>
            </Column>
          </Row>
          <Row modifiers="padScaleY_0">
            <Column modifiers="col">
              <P>{formatHours(dayList[dayOfWeekIndex], ersHours, ers247)}</P>
            </Column>
          </Row>
        </>
      )}
      <Row>
        <Column modifiers="col">
          <Divider />
        </Column>
      </Row>
      <Row>
        <Column modifiers="col">
          <Text>
            <Trans>Tomorrow</Trans>
          </Text>
        </Column>
      </Row>
      <Row modifiers="padScaleY_0">
        <Column modifiers={['col', 'padScaleY_0']}>
          <Text modifiers={['small', 'textLight']}>
            <Trans>Business Hours</Trans>
          </Text>
        </Column>
      </Row>
      <Row modifiers="padScaleY_0">
        <Column modifiers={['col', 'padScaleY_0']}>
          <P>
            {formatHours(dayList[dayOfWeekIndex + 1], workingHours, open247)}
          </P>
        </Column>
      </Row>
      {isERSHoursAvailable && (
        <>
          <Row modifiers="padScaleY_0">
            <Column modifiers={['col', 'padScaleY_0']}>
              <Text modifiers={['small', 'textLight']}>
                <Trans>ERS Hours</Trans>
              </Text>
            </Column>
          </Row>
          <Row modifiers="padScaleY_0">
            <Column modifiers="col">
              <P>
                {formatHours(dayList[dayOfWeekIndex + 1], ersHours, ers247)}
              </P>
            </Column>
          </Row>
        </>
      )}
    </>
  );
}

Summary.propTypes = {
  dealer: PropTypes.shape({
    ers247: PropTypes.bool,
    ersHours: PropTypes.arrayOf(
      PropTypes.shape({
        end: PropTypes.string,
        start: PropTypes.string,
        weekDay: PropTypes.string,
      }),
    ),
    open247: PropTypes.bool,
    operatingStatus: PropTypes.shape({
      ersOpen: PropTypes.string.isRequired,
      open: PropTypes.string.isRequired,
    }),
    timezone: PropTypes.string,
    workingHours: PropTypes.arrayOf(
      PropTypes.shape({
        end: PropTypes.string,
        start: PropTypes.string,
        weekDay: PropTypes.string,
      }),
    ),
  }),
  relativeNow: PropTypes.shape({}).isRequired,
};

Summary.defaultProps = {
  dealer: {},
};

export default Summary;

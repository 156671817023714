/* istanbul ignore file */
import React from 'react';
import { Trans } from '@lingui/macro';
import { get } from 'lodash';

import makeCellColumn from './builder';

export default makeCellColumn({
  columnName: 'city',
  label: <Trans>City</Trans>,
  text: (cellData) => get(cellData, ['displayAddress', 'city']),
});

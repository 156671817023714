import { css } from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';
import { Container } from 'styled-components-grid';

const Page = buildStyledComponent(
  'Page',
  Container,
  css`
    margin: 0 auto ${px2rem(100)};
    max-width: ${px2rem(1000)};

    @media print {
      margin: auto;
    }
  `,
);

export default Page;

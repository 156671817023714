import React from 'react';
import { curry, get } from 'lodash';
import { Query } from '@apollo/client/react/components';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { getUnits } from 'utils/unit';
import { unitSelector } from 'redux/preferences/selectors';

import closedCaseDetailsGQL from './closedCaseDetailsQuery';

function skipQuery({ caseNumber }) {
  return !caseNumber;
}

function buildQueryVariables({ caseNumber, unit }) {
  return { caseNumber, distanceUnit: getUnits(unit).distanceUnitValue };
}

const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const caseDetails = get(queryProps, 'data.case');
    const loading = get(queryProps, 'loading');
    const props = {
      ...componentProps,
      caseDetails,
      loading,
    };

    return <WrappedComponent {...props} />;
  },
);

const withQuery = (WrappedComponent) => (componentProps) => (
  <Query
    fetchPolicy="cache-and-network"
    query={closedCaseDetailsGQL}
    skip={skipQuery(componentProps)}
    variables={buildQueryVariables(componentProps)}
  >
    {buildQueryChild(WrappedComponent, componentProps)}
  </Query>
);

const mapStateToProps = (state) => ({ unit: unitSelector(state) });

const withClosedCaseDetails = (WrappedComponent) =>
  compose(connect(mapStateToProps), withQuery)(WrappedComponent);

export default withClosedCaseDetails;

import { t } from '@lingui/macro';

/* eslint-disable import/prefer-default-export */
export const weekdays = {
  MONDAY: t`Mon`,
  TUESDAY: t`Tue`,
  WEDNESDAY: t`Wed`,
  THURSDAY: t`Thu`,
  FRIDAY: t`Fri`,
  SATURDAY: t`Sat`,
  SUNDAY: t`Sun`,
};

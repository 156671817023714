import React from 'react';
import PropTypes from 'prop-types';

import { Popover } from 'base-components';
import { configEnabled } from 'decisiv-domain-utils';

import { AmazonConnect, uiStateColors } from 'features/amazonConnect';

import DisabledSoftPhone from './DisabledSoftPhone';
import PhoneButton from './PhoneButton';
import SoftPhone from './SoftPhone';

function enableAmazonConnect() {
  return configEnabled('REACT_APP_SOFTPHONE_ENABLED');
}

/**
 * The Popover containing the CCP, triggered from a PhoneButton.
 * Exported only for test purposes.
 */
export function PhonePopover({ showPopover, softPhoneEnabled }) {
  return (
    <Popover
      position="bottom"
      showOnHover={false}
      removeOnHide={false}
      arrow
      arrowColor={uiStateColors().dark}
      showPopover={showPopover}
    >
      <Popover.Target>
        <PhoneButton />
      </Popover.Target>
      <Popover.Content zIndex={10}>
        {softPhoneEnabled ? <SoftPhone /> : <DisabledSoftPhone />}
      </Popover.Content>
    </Popover>
  );
}

PhonePopover.propTypes = {
  showPopover: PropTypes.bool,
  softPhoneEnabled: PropTypes.bool.isRequired,
};

PhonePopover.defaultProps = {
  showPopover: undefined,
};

/**
 * The top-nav phone button and CCP popover, wrapped with the AmazonConnect
 * context to provide UI state information.
 */
export default function AmazonConnectPopup({ softPhoneEnabled }) {
  return (
    <AmazonConnect>
      {({ uiState, acceptContact, rejectCall }) => (
        <PhonePopover
          softPhoneEnabled={softPhoneEnabled}
          uiState={uiState}
          showPopover={
            // if the acceptCall or rejectCall functions are available,
            // then that means there's an unanswered incoming call
            // happening right now...
            Boolean(acceptContact || rejectCall)
          }
        />
      )}
    </AmazonConnect>
  );
}

AmazonConnectPopup.propTypes = {
  softPhoneEnabled: PropTypes.bool,
};

AmazonConnectPopup.defaultProps = {
  softPhoneEnabled: enableAmazonConnect(),
};

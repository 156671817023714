import gql from 'graphql-tag';

export const NAME = 'updateCaseBillingInfoMutation';

export default gql`
  mutation updateCaseBillingInfoMutation(
    $caseId: ID!
    $billingInfo: BillingInfoParams!
  ) {
    updateCase(input: { id: $caseId, patch: { billingInfo: $billingInfo } }) {
      case {
        id
        fixpixPushResult
        billingInfo {
          amount
          comment
          poNumber
          woNumber
          ticketNumber
          invoiceNumber
          referenceNumber
          contractNumber
          releaseNumber
        }
      }
    }
  }
`;

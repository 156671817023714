import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { compose, withState } from 'recompose';

import { Row, Column } from 'styled-components-grid';
import { Icon, Text, Checkbox, Popover } from 'base-components';

import withCaseDealerResponseActions from '../../withCaseDealerResponseActions';

function BaseRenderer(props) {
  const { id, value, setValue, readOnly } = props;
  const { fieldKey, updateDealerResponse } = props;

  const onChange = () => {
    setValue(!value);
    updateDealerResponse({ id, patch: { [fieldKey]: !value } });
  };

  return (
    <Row modifiers={['height_100', 'middle']}>
      <Column modifiers={['col', 'center', 'padScaleX_2']}>
        <Checkbox
          id={`dealerResponse-${id}-${fieldKey}`}
          checked={value}
          readOnly={readOnly}
          onChange={onChange}
        />
      </Column>
    </Row>
  );
}

BaseRenderer.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  fieldKey: PropTypes.string.isRequired,
  updateDealerResponse: PropTypes.func.isRequired,
};

const CellRenderer = compose(
  withCaseDealerResponseActions,
  withState('value', 'setValue', (p) => !!get(p, ['rowData', p.fieldKey])),
)(BaseRenderer);

export const buildCheckboxColumn = (fieldKey, { label, popoverContent }) => ({
  name: fieldKey,
  cellKeyGetter: () => fieldKey,
  cellDataGetter: (args) => args,
  headerCellRenderer: () => (
    <Row>
      <Column modifiers={['col', 'center', 'padScaleX_2', 'padScaleY_3']}>
        <Popover showOnHover>
          <Popover.Target>
            <Text
              style={{ display: 'inline' }}
              modifiers={['small', 'textLight']}
            >
              {label}
            </Text>
            &nbsp;
            <Icon name="info-circle" modifiers={['mini', 'info']} />
          </Popover.Target>
          <Popover.Content style={{ padding: 10, width: 250 }}>
            {popoverContent}
          </Popover.Content>
        </Popover>
      </Column>
    </Row>
  ),
  dataCellRenderer: (rowData, { tableMetaData }) => (
    <CellRenderer
      id={rowData.id}
      rowData={rowData}
      fieldKey={fieldKey}
      readOnly={get(tableMetaData, 'readOnly')}
    />
  ),
});

export const buildCheckboxColumnHeader = () => ({});

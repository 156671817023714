import React from 'react';
import PropTypes from 'prop-types';
import { includes } from 'lodash';

import { Column } from 'styled-components-grid';

import DefaultAvatar from '../Avatar';
import { modifiersForComponent } from '../../utils/modifiers';

function Avatar({ modifiers, ...rest }) {
  const columnModifiers = [...modifiersForComponent(modifiers, Column)];
  const avatarModifiers = [
    includes(modifiers, 'primary') ? null : 'secondary',
    'small',
    ...modifiersForComponent(modifiers, Avatar),
  ];

  return (
    <Column
      modifiers={columnModifiers}
      style={{
        // NOTE: inline styles to tweak grid components is necessary until there
        // modifier configs can be extended or the required styles are able to be
        // added with grid modifiers.
        display: 'flex',
      }}
    >
      <DefaultAvatar modifiers={avatarModifiers} {...rest} />
    </Column>
  );
}

Avatar.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

Avatar.defaultProps = {
  modifiers: [],
};

export default Avatar;

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { get, uniqBy } from 'lodash';
import { Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { compose, setDisplayName } from 'recompose';

import { px2rem } from 'decisiv-ui-utils';
import { Column, Container, Row } from 'styled-components-grid';

import {
  Avatar,
  Dropdown,
  InputField,
  MessageMedium,
  MessageSmall,
  Text,
} from 'base-components';

import withContext from 'utils/withContext';
import HighlightText from 'components/HighlightText';
import TextGhostIndicator from 'elements/TextGhostIndicator';

import ConfigValueSuggestions from './ConfigValueSuggestions';
import ReportConfigBuilderContext from '../Context';
import {
  configTypes,
  configTypesPlaceholders,
  configTypesTitles,
  configTypesWarnings,
  locationFieldTypes,
  getLocationFieldKey,
  getLocationFieldMarker,
  getLocationFieldDisplayValue,
  getLocationFieldValue,
} from '../constants';

const locationTypeByConfigType = {
  [configTypes.customerName]: locationFieldTypes.billTo,
  [configTypes.customerLocation]: locationFieldTypes.shipTo,
  [configTypes.dealerName]: locationFieldTypes.billTo,
  [configTypes.dealerLocation]: locationFieldTypes.shipTo,
};

const locationConfigTypes = [
  configTypes.customerName,
  configTypes.customerLocation,
  configTypes.dealerName,
  configTypes.dealerLocation,
];

class InputFieldWithSuggestions extends Component {
  static propTypes = {
    config: PropTypes.shape({}).isRequired,
    configType: PropTypes.oneOf(Object.values(configTypes)).isRequired,
    grouped: PropTypes.bool,
    invalidConfigKeys: PropTypes.arrayOf(
      PropTypes.oneOf(Object.values(configTypes)),
    ).isRequired,
    updateReportConfig: PropTypes.func.isRequired,
  };

  static defaultProps = {
    grouped: false,
  };

  state = {
    isOpen: false,
    wasValidWhenOpened: false,
  };

  get searchValueConfigKey() {
    return `${this.props.configType}_SEARCH_VALUE`;
  }

  get selectedItem() {
    return get(this.props.config, this.props.configType);
  }

  get searchValue() {
    return get(this.props.config, this.searchValueConfigKey, '');
  }

  get displayValue() {
    return getLocationFieldDisplayValue(this.searchValue);
  }

  get queryValue() {
    return getLocationFieldValue(this.searchValue);
  }

  get isValid() {
    const { configType, invalidConfigKeys } = this.props;
    const { isOpen, wasValidWhenOpened } = this.state;

    if (!this.searchValue) {
      return true;
    }

    return isOpen
      ? wasValidWhenOpened
      : !invalidConfigKeys.includes(configType);
  }

  handleSelect = (_, value) => {
    if (!value) {
      return;
    }

    this.props.updateReportConfig({
      [this.props.configType]: value,
      [this.searchValueConfigKey]: value,
    });
  };

  handleSearchChange = ({ target: { value } }) => {
    this.props.updateReportConfig({
      [this.searchValueConfigKey]: value,
      // If the user "backspaces" all text, treat it as clearing the selection.
      // If not, and we have a selected item, it will be treated as an error.
      ...(!value ? { [this.props.configType]: undefined } : {}),
    });
  };

  handleClearSearch = () =>
    this.props.updateReportConfig({
      [this.props.configType]: undefined,
      [this.searchValueConfigKey]: '',
    });

  handleDropdownToggle = (isOpen) => {
    const { invalidConfigKeys, configType } = this.props;

    const isValid = !invalidConfigKeys.includes(configType);
    const newState = { isOpen };

    if (!this.state.isOpen && isOpen) {
      newState.wasValidWhenOpened = isValid;
    }

    this.setState(newState);
  };

  filterSuggestions = (suggestions) => {
    const { configType } = this.props;

    return locationConfigTypes.includes(configType)
      ? this.filterLocationSuggestions(suggestions)
      : suggestions;
  };

  filterLocationSuggestions = (suggestions) => {
    const { configType } = this.props;
    const locationType = locationTypeByConfigType[configType];
    const key = getLocationFieldKey(locationType);
    const marker = getLocationFieldMarker(locationType);

    const [name, value] = this.searchValue?.split(marker) ?? [];

    const filteredSuggestions = suggestions?.filter((suggestion) => {
      if (value) {
        // when item is selected, show only suggestions with the same value
        return suggestion?.[key] === value && suggestion?.name === name;
      }

      if (locationType === locationFieldTypes.billTo) {
        // for billTo searches, make sure results match the name being searched
        // otherwise we might search for "Snider Tire" and get "R & I REPAIR SHOP INC."
        // just because they have the same billTo numbers
        return suggestion?.name?.toLowerCase().startsWith(name?.toLowerCase());
      }

      return true;
    });

    const suggestionsToShow =
      filteredSuggestions.length === 0 ? suggestions : filteredSuggestions;

    const uniqSuggestionsWithLocation = uniqBy(
      suggestionsToShow.filter((s) => s?.[key]),
      key,
    );

    // suggestions without shipTo/billTo such as custom customers and dealers
    const otherSuggestions = suggestionsToShow.filter((s) => s?.[key] === null);

    return [...uniqSuggestionsWithLocation, ...otherSuggestions];
  };

  renderSuggestion = (suggestion, index) => {
    const { configType } = this.props;
    return locationConfigTypes.includes(configType)
      ? this.renderLocationSuggestion(suggestion, index)
      : this.renderStringSuggestion(suggestion, index);
  };

  renderLocationSuggestion = (data, index) => {
    const { name, city, state, billTo, shipTo } = data;

    const { configType } = this.props;
    const locationType = locationTypeByConfigType[configType];
    const key = getLocationFieldKey(locationType);
    const marker = getLocationFieldMarker(locationType);

    const location = data[key];
    const id = marker && location ? `${name}${marker}${location}` : name;

    const showShipTo = shipTo && locationFieldTypes.shipTo === locationType;

    return (
      <Dropdown.ListItem key={`${name}_${index}`} id={id}>
        <Row
          style={{ flexWrap: 'nowrap' }}
          modifiers={['middle', 'padScale_0']}
        >
          <Column style={{ paddingLeft: 0 }}>
            <Avatar name={name} modifiers={['small']} />
          </Column>
          <Column modifiers="col">
            <Row>
              <Text>
                <HighlightText text={this.displayValue}>{name}</HighlightText>
              </Text>
            </Row>
            {showShipTo && [city, state].some(Boolean) && (
              <Row>
                <Text modifiers={['small', 'textLight']}>
                  <HighlightText text={this.searchValue}>
                    {city || ''}
                  </HighlightText>
                  {city && state && <span>, </span>}
                  <HighlightText text={this.searchValue}>
                    {state || ''}
                  </HighlightText>
                </Text>
              </Row>
            )}
            {[billTo, shipTo].some(Boolean) && (
              <Row>
                <Text modifiers={['small', 'textLight']}>
                  {billTo && (
                    <Trans>
                      Bill To #{' '}
                      <HighlightText text={this.searchValue}>
                        {billTo}
                      </HighlightText>
                    </Trans>
                  )}
                  {billTo && showShipTo && <span> · </span>}
                  {showShipTo && (
                    <Trans>
                      Ship To #{' '}
                      <HighlightText text={this.searchValue}>
                        {shipTo}
                      </HighlightText>
                    </Trans>
                  )}
                </Text>
              </Row>
            )}
          </Column>
        </Row>
      </Dropdown.ListItem>
    );
  };

  renderStringSuggestion = (suggestion, index) => (
    <Dropdown.ListItem key={`${suggestion}_${index}`} id={suggestion}>
      <Row style={{ flexWrap: 'nowrap' }} modifiers={['middle', 'padScale_0']}>
        {this.props.grouped && (
          <Column style={{ paddingLeft: 0 }}>
            <Avatar name={suggestion} modifiers={['small']} />
          </Column>
        )}
        <Column modifiers="col">
          <Text>
            <HighlightText text={this.displayValue}>{suggestion}</HighlightText>
          </Text>
        </Column>
      </Row>
    </Dropdown.ListItem>
  );

  renderDropdown = ({ suggestions, isLoading }) => {
    const { grouped } = this.props;

    if (!this.displayValue) return null;

    if (!isLoading && !suggestions.length) {
      return (
        <Dropdown.Content>
          <Container
            style={{ whiteSpace: 'normal', minWidth: px2rem(300) }}
            className="no-results"
          >
            <MessageMedium>
              <MessageMedium.Header>
                <MessageMedium.Icon name="search" />
              </MessageMedium.Header>
              <MessageMedium.Section>
                <MessageMedium.Title>
                  <Trans>No Matches Found</Trans>
                </MessageMedium.Title>
              </MessageMedium.Section>
              <MessageMedium.Section>
                <MessageMedium.Text>
                  <Trans>
                    Try removing or editing some of your criteria or search for
                    different words
                  </Trans>
                </MessageMedium.Text>
              </MessageMedium.Section>
            </MessageMedium>
          </Container>
        </Dropdown.Content>
      );
    }

    if (isLoading) {
      return (
        <Dropdown.Content>
          <Dropdown.SectionHeader>
            <TextGhostIndicator
              style={{ flex: `0 0 ${px2rem(150)}`, margin: `${px2rem(5)} 0` }}
            >
              Loading...
            </TextGhostIndicator>
          </Dropdown.SectionHeader>
        </Dropdown.Content>
      );
    }

    const filteredSuggestions = this.filterSuggestions(suggestions);

    return (
      <Dropdown.Content>
        {!grouped && (
          <Dropdown.List>
            {filteredSuggestions.map(this.renderSuggestion)}
          </Dropdown.List>
        )}

        {grouped &&
          filteredSuggestions.map(([group, items]) => (
            <Fragment key={group}>
              <Dropdown.SectionHeader>
                <Text
                  modifiers={['small', 'noWrap', 'capitalize', 'textLight']}
                >
                  {group}
                </Text>
              </Dropdown.SectionHeader>
              <Dropdown.List>{items.map(this.renderSuggestion)}</Dropdown.List>
            </Fragment>
          ))}
      </Dropdown.Content>
    );
  };

  render() {
    const { configType, grouped } = this.props;

    return (
      <Container modifiers="padScale_0" style={{ marginTop: px2rem(10) }}>
        <Dropdown
          activeItem={this.selectedItem}
          fullWidth
          hideOnChange
          onChange={this.handleSelect}
          onExpandedChange={this.handleDropdownToggle}
          zIndex={2}
        >
          {({ show }) => (
            <>
              <Container modifiers="padScale_0" onClick={show}>
                <InputField
                  isValid={this.isValid}
                  onChange={this.handleSearchChange}
                  placeholder={i18n._(configTypesPlaceholders[configType])}
                  value={this.displayValue}
                >
                  <Column modifiers={['col', 'padScaleY_0']}>
                    <Row>
                      <InputField.Label>
                        <Trans id={configTypesTitles[configType]} />
                      </InputField.Label>
                    </Row>
                    <Row>
                      <InputField.TextField />
                      {this.displayValue && (
                        <InputField.ActionButton
                          icon="times"
                          type="button"
                          onClick={this.handleClearSearch}
                          modifiers={['hoverDanger', 'padScaleX_0']}
                        />
                      )}
                    </Row>
                  </Column>
                </InputField>
              </Container>
              <ConfigValueSuggestions
                grouped={grouped}
                configType={configType}
                searchValue={this.queryValue}
              >
                {this.renderDropdown}
              </ConfigValueSuggestions>
            </>
          )}
        </Dropdown>
        {!this.isValid && (
          <Row style={{ marginTop: px2rem(5), marginBottom: px2rem(10) }}>
            <Column modifiers={['col', 'padScale_0']}>
              <MessageSmall type="warning">
                <Trans id={configTypesWarnings[configType]} />
              </MessageSmall>
            </Column>
          </Row>
        )}
      </Container>
    );
  }
}

export default compose(
  setDisplayName('InputFieldWithSuggestions'),
  withContext(ReportConfigBuilderContext),
)(InputFieldWithSuggestions);

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';
import { ButtonSort, Dropdown, Text } from 'base-components';

import {
  sortDirections,
  sortDirectionsLabels,
  sortDirectionPropType,
} from './withSortDirection';

const Options = buildStyledComponent(
  'EntriesOrderSelector__Options',
  styled.div,
  css`
    min-width: ${px2rem(100)};
  `,
);

const EntriesOrderSelector = ({ onSelectDirection, selectedDirection }) => (
  <Dropdown
    activeItem={selectedDirection}
    arrow
    hideOnChange
    onChange={(e, value) => onSelectDirection(value)}
    position="bottomLeft"
  >
    {({ isVisible }) => (
      <>
        <Dropdown.Target>
          <ButtonSort>
            <ButtonSort.Label>
              <Trans>Sort by</Trans>
            </ButtonSort.Label>
            <ButtonSort.Text>
              <Trans id={sortDirectionsLabels[selectedDirection]} />
            </ButtonSort.Text>
            <ButtonSort.Icon arrowUp={isVisible} />
          </ButtonSort>
        </Dropdown.Target>
        <Dropdown.Content>
          <Options>
            <Dropdown.List>
              {Object.values(sortDirections).map((order) => (
                <Dropdown.ListItem key={order} id={order}>
                  <Text>
                    <Trans id={sortDirectionsLabels[order]} />
                  </Text>
                </Dropdown.ListItem>
              ))}
            </Dropdown.List>
          </Options>
        </Dropdown.Content>
      </>
    )}
  </Dropdown>
);

EntriesOrderSelector.propTypes = {
  onSelectDirection: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-typos
  selectedDirection: sortDirectionPropType.isRequired,
};

export default EntriesOrderSelector;

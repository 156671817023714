import React from 'react';
import PropTypes from 'prop-types';

import { Row } from 'styled-components-grid';
import { Icon, Tooltip, ButtonIconRectangle } from 'base-components';

function ResetButton({ label, ...rest }) {
  return (
    <Row modifiers="center">
      <Tooltip position="left">
        <Tooltip.Target>
          <ButtonIconRectangle
            type="button"
            style={{ background: 'none' }}
            {...rest}
          >
            <Icon name="undo" />
          </ButtonIconRectangle>
        </Tooltip.Target>
        <Tooltip.Content>{label}</Tooltip.Content>
      </Tooltip>
    </Row>
  );
}

ResetButton.propTypes = {
  label: PropTypes.node.isRequired,
};

export default ResetButton;

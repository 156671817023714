import gql from 'graphql-tag';

/**
 * NOTE: the data from this query is also used (via Apollo Link)
 * to include user info in Bugsnag error reports.
 *
 * @see registerBugsnagUserLinkFactory
 *
 * If the query changes here, the code there needs to be updated as well.
 */

export default gql`
  query userInfoQuery {
    currentUserProfile {
      user {
        email
        name
        userName
        firstName
        lastName
      }
      role {
        name
        permissions
      }
    }
  }
`;

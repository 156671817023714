import React from 'react';
import PropTypes from 'prop-types';

import { Container, Column, Row } from 'styled-components-grid';
import { Avatar, Text } from 'base-components';

import { formatPhone } from 'utils/format';

import HighlightText from 'components/HighlightText';

function InboundProgramCustomerDetails(props) {
  const { customer, highlightText } = props;
  const { customerName, tollFreeNumber } = customer;

  return (
    <Container modifiers="padScale_0">
      <Row modifiers="padScale_0">
        <Column>
          <Avatar modifiers={['small']} name={customerName} />
        </Column>
        <Column>
          <Row modifiers="padScale_0">
            <Text>
              <HighlightText text={highlightText}>{customerName}</HighlightText>
            </Text>
          </Row>
          {!!tollFreeNumber && (
            <Row modifiers="padScale_0">
              <Text modifiers={['small', 'textLight']}>
                {formatPhone(tollFreeNumber)}
              </Text>
            </Row>
          )}
        </Column>
      </Row>
    </Container>
  );
}

InboundProgramCustomerDetails.propTypes = {
  customer: PropTypes.shape({
    customerName: PropTypes.string.isRequired,
    tollFreeNumber: PropTypes.string,
  }).isRequired,
  highlightText: PropTypes.string,
};

InboundProgramCustomerDetails.defaultProps = {
  highlightText: undefined,
};

export default InboundProgramCustomerDetails;

import { t, defineMessage } from '@lingui/macro';

import buildEnum from 'utils/buildEnum';

export const contactTypes = buildEnum(['optional', 'required']);

export const contactIdKeys = [
  'primaryContactId',
  'backupContactId',
  'finalContactId',
];

export const screenTypes = buildEnum([
  'summary',
  'deleteOptionalContact',
  'replaceContact',
  'editEachRotation',
  'deleteAllRotations',
]);

export const titleByScreenType = {
  [screenTypes.summary]: t`Deleting Contact`,
  [screenTypes.deleteOptionalContact]: t`Deleting Contact`,
  [screenTypes.replaceContact]: t`Replace And Delete Contact`,
  [screenTypes.editEachRotation]: t`Replace And Delete Contact`,
  [screenTypes.deleteAllRotations]: t`Delete Contact And Rotations`,
};

export const descriptionsByScreenType = {
  [screenTypes.summary]: {
    [contactTypes.optional]: {
      plural: defineMessage({
        message: `The contact <0>{contactName}</0> is assigned to upcoming after-hours rotations as the Backup Contact. You can simply delete this contact, or replace it for another existing contact.`,
      }),
      singular: defineMessage({
        message: `The contact <0>{contactName}</0> is assigned to one upcoming after-hours rotation as the Backup Contact. You can simply delete this contact, or replace it for another existing contact.`,
      }),
    },
    [contactTypes.required]: {
      plural: defineMessage({
        message: `The contact <0>{contactName}</0> is assigned to upcoming after-hours rotations as the Primary or Final Contact. In order to delete this contact, you need to modify the Primary or Final contacts of those rotations. You can also choose to just delete the rotations as well.`,
      }),
      singular: defineMessage({
        message: `The contact <0>{contactName}</0> is assigned to one upcoming after-hours rotation as the Primary or Final Contact. In order to delete this contact, you need to modify the Primary or Final contacts of that rotation. You can also choose to just delete the rotation as well.`,
      }),
    },
  },
  [screenTypes.deleteOptionalContact]: defineMessage({
    message: `<0>{contactName}</0> will be deleted and unassigned from the following upcoming after-hours rotations:`,
  }),
  [screenTypes.replaceContact]: defineMessage({
    message: `Replace <0>{contactName}</0> by another contact in all upcoming after-hours rotations assigned to them:`,
  }),
  [screenTypes.editEachRotation]: {
    plural: defineMessage({
      message: `To be able to delete this contact, you can replace <0>{contactName}</0> by another contact in each upcoming after-hours rotation assigned to them. There are <1>{eventsNumber} upcoming after-hours rotations</1> currently assigned to {contactName}.`,
    }),
    singular: defineMessage({
      message: `To be able to delete this contact, you can replace <0>{contactName}</0> by another contact in each upcoming after-hours rotation assigned to them. There is <1>one upcoming after-hours rotation</1> currently assigned to {contactName}.`,
    }),
  },
  [screenTypes.deleteAllRotations]: defineMessage({
    message: `<0>{contactName}</0> and all the following upcoming after-hours rotations will be deleted. Past and ongoing rotations will be left unchanged.`,
  }),
};

export const optionsByContactType = {
  [contactTypes.optional]: [
    screenTypes.deleteOptionalContact,
    screenTypes.replaceContact,
    screenTypes.editEachRotation,
    screenTypes.deleteAllRotations,
  ],
  [contactTypes.required]: [
    screenTypes.replaceContact,
    screenTypes.editEachRotation,
    screenTypes.deleteAllRotations,
  ],
};

export const summaryOptionsLabels = {
  [screenTypes.deleteOptionalContact]: defineMessage({
    message: `Delete <0>{contactName}</0> from all upcoming rotations assigned to them`,
  }),
  [screenTypes.replaceContact]: defineMessage({
    message: `Replace <0>{contactName}</0> by another contact in all upcoming rotations assigned to them`,
  }),
  [screenTypes.editEachRotation]: defineMessage({
    message: `Edit contacts of each upcoming rotation assigned to <0>{contactName}</0>`,
  }),
  [screenTypes.deleteAllRotations]: defineMessage({
    message: `Delete all upcoming rotations assigned to <0>{contactName}</0>`,
  }),
};

export const buttonTypes = buildEnum([
  'cancel',
  'back',
  'deleteContact',
  'deleteContactRotations',
]);

export const buttonLabelsByType = {
  [buttonTypes.deleteContact]: t`Delete Contact`,
  [buttonTypes.deleteContactRotations]: t`Delete Contact & Rotations`,
};

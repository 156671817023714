import { get } from 'lodash';
import { t } from '@lingui/macro';

import { rimReplacementProductType } from '../../CaseRequestsPanelContext/constants';

export const tireSpecificColumns = [
  'productType',
  'manufacturerFullName',
  'tireSize',
  'sculptureTreadName',
  'loadRange',
];

const buildDepsConfig = (forType, params) => {
  const productType = get(params, 'rowData.productType');
  const isRimReplacement = productType === rimReplacementProductType;
  const isTireSpecificDep = tireSpecificColumns.includes(forType);

  if (isTireSpecificDep && isRimReplacement) {
    // Rim replacement columns options do not depend on each other,
    // so we only need to check if productType is set to rim replacement
    return { dependsOn: forType !== 'productType' ? ['productType'] : [] };
  }

  const index = tireSpecificColumns.indexOf(forType);
  const dependsOn = tireSpecificColumns.slice(0, index);

  return tireSpecificColumns.includes(forType)
    ? { dependsOn, depsMessageColSpan: tireSpecificColumns.length - index }
    : { dependsOn: undefined };
};

export default ({ requestLineOptions }) =>
  [
    {
      name: 'asset',
      title: t`Unit`,
      width: '7%',
      values: get(requestLineOptions, 'asset', []),
    },
    {
      name: 'requestedAction',
      title: t`Action`,
      width: '9%',
      values: get(requestLineOptions, 'requestedAction', []),
    },
    {
      name: 'tireCondition',
      title: t`Condition`,
      width: '11%',
      values: get(requestLineOptions, 'tireCondition', []),
    },
    {
      name: 'axleType',
      title: t`Axle`,
      width: '6.5%',
      depsMessage: t`Select Unit`,
      getRuntimeConfig: () => ({
        dependsOn: ['asset'],
        depsMessageColSpan: 2,
      }),
    },
    {
      name: 'tirePosition',
      title: t`Position`,
      width: '6%',
      depsMessage: t`Select Axle Type`,
      showDepsMessageInToolTip: true,
      getRuntimeConfig: () => ({
        dependsOn: ['asset', 'axleType'],
        depsMessageColSpan: 1,
      }),
    },
    {
      name: 'productType',
      title: t`Type`,
      width: '9%',
      depsMessage: t`Select Tire Postion`,
      getRuntimeConfig: () => ({ dependsOn: [] }),
    },
    {
      name: 'manufacturerFullName',
      title: t`Brand`,
      width: '12%',
      depsMessage: t`Select Tire Type`,
      autocomplete: true,
      getRuntimeConfig: (params) =>
        buildDepsConfig('manufacturerFullName', params),
    },
    {
      name: 'tireSize',
      title: t`Size`,
      width: '12%',
      depsMessage: t`Select Brand`,
      autocomplete: true,
      getRuntimeConfig: (params) => buildDepsConfig('tireSize', params),
    },
    {
      name: 'sculptureTreadName',
      title: t`Design`,
      width: '12%',
      depsMessage: t`Select Tire Size`,
      autocomplete: true,
      getRuntimeConfig: (params) =>
        buildDepsConfig('sculptureTreadName', params),
    },
    {
      name: 'loadRange',
      title: t`Load Range`,
      width: '6%',
      depsMessage: t`Select Tire Design`,
      showDepsMessageInToolTip: true,
      getRuntimeConfig: (params) => buildDepsConfig('loadRange', params),
    },
    // {
    //   name: 'rimType',
    //   title: t`Rim Type (optional)`,
    //   width: '7%',
    //   values: get(requestLineOptions, 'rimType', []),
    //   optional: true,
    // },
  ].filter(Boolean);

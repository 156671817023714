import gql from 'graphql-tag';

export default gql`
  mutation updateCaseBillableMutation($caseId: ID!, $isBillable: Boolean!) {
    updateCase(input: { id: $caseId, patch: { billable: $isBillable } }) {
      case {
        id
        billable
      }
    }
  }
`;

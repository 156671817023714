import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import StandaloneSearchBox from 'react-google-maps/lib/components/places/StandaloneSearchBox';
import { t, Trans } from '@lingui/macro';
import { compact, get, isEmpty, isMatch, omit } from 'lodash';

import {
  H3,
  InputField,
  InputGroup,
  ButtonToggle,
  MessageSmall,
  ButtonIconRectangle,
} from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Column, Container, Row } from 'styled-components-grid';

import arraysMatchWith from 'utils/arraysMatchWith';
import GooglePropTypes from 'features/googleMaps/propTypes';
import ButtonLinkWithIcon from 'elements/ButtonLinkWithIcon';
import { PANEL_STATUSES } from 'compositions/CaseStatus';

import LocationTimeAndWeather from './LocationTimeAndWeather';
import { ASSET_LOCATION_SEARCH_INDEX } from '../constants';

export function generateInputIconStyle(location, searchValue, theme) {
  let inputIconStyle = {};
  if (!location) {
    if (searchValue === '') {
      inputIconStyle = {
        color: theme.colors.base.chrome600,
        opacity: 0.5,
      };
    } else {
      inputIconStyle = { color: theme.colors.status.danger };
    }
  }

  return inputIconStyle;
}

export class SearchInputComponent extends Component {
  static propTypes = {
    assetLocationOnly: PropTypes.bool,
    extraInputsRight: PropTypes.node,
    isRouteSearch: PropTypes.bool.isRequired,
    // This is used in getDerivedStateFromProps
    // eslint-disable-next-line react/no-unused-prop-types
    locationSearches: PropTypes.arrayOf(
      PropTypes.shape({
        location: PropTypes.shape({
          latitude: PropTypes.number.isRequired,
          longitude: PropTypes.number.isRequired,
        }),
        searchValue: PropTypes.string,
        searchValueTried: PropTypes.string,
      }),
    ).isRequired,
    mapBounds: GooglePropTypes.latLngBounds,
    extraInputsBottom: PropTypes.node,
    onAddLocationSearch: PropTypes.func.isRequired,
    onAssetLocationKeyUp: PropTypes.func,
    onAssetLocationBlur: PropTypes.func,
    assetLocationValidationError: PropTypes.node,
    onDeleteLocationSearch: PropTypes.func.isRequired,
    onLocationInputChange: PropTypes.func.isRequired,
    onLocationReset: PropTypes.func.isRequired,
    toggleIsRouteSearch: PropTypes.func.isRequired,
    updateLocationToSelectedPlace: PropTypes.func.isRequired,
    theme: PropTypes.shape({
      colors: PropTypes.shape({
        base: PropTypes.shape({
          chrome600: PropTypes.string.isRequired,
        }).isRequired,
        status: PropTypes.shape({
          danger: PropTypes.string.isRequired,
        }),
      }).isRequired,
    }).isRequired,
    casePanelStatuses: PropTypes.shape({
      assetLocation: PropTypes.shape({
        status: PropTypes.shape({
          panel: PropTypes.string.isRequired,
        }).isRequired,
      }),
    }),
    readOnly: PropTypes.bool,
    textFieldRef: PropTypes.func,
  };

  static defaultProps = {
    assetLocationOnly: false,
    extraInputsRight: null,
    mapBounds: undefined,
    extraInputsBottom: null,
    casePanelStatuses: null,
    onAssetLocationKeyUp: () => {},
    onAssetLocationBlur: () => {},
    assetLocationValidationError: null,
    readOnly: false,
    textFieldRef: undefined,
  };

  // eslint-disable-next-line react/sort-comp
  state = { hoveredDestInputIndex: -1, locationSearches: [] };

  static getDerivedStateFromProps(props, state) {
    const stateUpdates = {};

    // This component adds a ref to each new location search box so we can track that target.
    // This ref doesn't need to be known anywhere except this component though, so we'll add/
    // manage it locally.

    const locationSearchesSimilar = arraysMatchWith(
      props.locationSearches,
      state.locationSearches,
      // The 'searchBoxRef' field won't be in props.locationsSearches,
      // but everything else should match.
      (propsLocationSearch, stateLocationSearch) =>
        isMatch(
          propsLocationSearch,
          omit(stateLocationSearch, ['searchBoxRef']),
        ),
    );

    if (!locationSearchesSimilar) {
      // add logic to inspect for/ add a searchBoxRef for the new input field.
      stateUpdates.locationSearches = props.locationSearches.map(
        (propsLocationSearch, index) => {
          const searchBoxRef = get(
            state.locationSearches[index],
            'searchBoxRef',
            createRef(),
          );
          return {
            ...propsLocationSearch,
            searchBoxRef,
          };
        },
      );
    }

    if (isEmpty(stateUpdates)) {
      return null;
    }

    return stateUpdates;
  }

  onPlaceSelect = (locationSearchIndex) => () => {
    const { searchBoxRef } = this.state.locationSearches[locationSearchIndex];
    const places = searchBoxRef.current.getPlaces();
    this.props.updateLocationToSelectedPlace(locationSearchIndex, places);

    if (ASSET_LOCATION_SEARCH_INDEX === locationSearchIndex) {
      this.handleAssetLocationBlur();
    }
  };

  getAssetLocationSearchValue = () => {
    const { locationSearches } = this.state;
    const { searchValue } = locationSearches[ASSET_LOCATION_SEARCH_INDEX];
    return searchValue;
  };

  handleAssetLocationKeyUp = (event) => {
    this.props.onAssetLocationKeyUp(event);
  };

  handleAssetLocationBlur = () => {
    const { onAssetLocationBlur } = this.props;
    const searchValue = this.getAssetLocationSearchValue();
    onAssetLocationBlur(searchValue);
  };

  handleAssetLocationReset = () => {
    const { onAssetLocationBlur } = this.props;
    onAssetLocationBlur('');
  };

  render() {
    const {
      extraInputsRight,
      assetLocationOnly,
      isRouteSearch,
      mapBounds,
      extraInputsBottom,
      assetLocationValidationError,
      onAddLocationSearch,
      onDeleteLocationSearch,
      onLocationInputChange,
      onLocationReset,
      theme,
      toggleIsRouteSearch,
      casePanelStatuses,
      readOnly,
      textFieldRef,
    } = this.props;

    const {
      hoveredDestInputIndex,
      locationSearches: [assetLocationSearch, ...destinationLocationSearches],
    } = this.state;

    const {
      location: assetLocation,
      searchBoxRef: assetLocationSearchBoxRef,
      searchValue: assetLocationSearchValue,
    } = assetLocationSearch;

    const panelStatus = get(casePanelStatuses, 'assetLocation.status.panel');

    return (
      <Container
        style={{
          padding: assetLocationOnly
            ? px2rem(12)
            : `${px2rem(30)} ${px2rem(5)} ${px2rem(5)}`,
        }}
      >
        <Row
          modifiers={['middle']}
          style={{
            paddingLeft: assetLocationOnly ? 0 : px2rem(35),
            paddingRight: assetLocationOnly ? 0 : px2rem(35),
          }}
        >
          <Column
            modifiers={[
              'col',
              assetLocationOnly ? 'padScaleY_0' : 'padScaleY_4',
            ]}
          >
            <H3 modifiers={['fontWeightRegular']}>
              {assetLocationOnly ? (
                <Trans>Asset Location</Trans>
              ) : (
                <Trans>Service Provider Locator</Trans>
              )}
            </H3>
          </Column>
          {!assetLocationOnly && (
            <Column modifiers={['end']}>
              <ButtonToggle on={isRouteSearch} onClick={toggleIsRouteSearch}>
                <ButtonToggle.OffLabel>
                  <Trans>Search Area</Trans>
                </ButtonToggle.OffLabel>
                <ButtonToggle.OnLabel>
                  <Trans>Search Route</Trans>
                </ButtonToggle.OnLabel>
              </ButtonToggle>
            </Column>
          )}
        </Row>
        {assetLocationOnly && <Row modifiers={['padScaleY_1']} />}
        {/*  Asset Location Input */}
        <Row modifiers={['middle']} style={{ zIndex: 1 }}>
          <Column modifiers={['col', 'padScaleX_0']}>
            <InputGroup>
              <InputGroup.Row>
                <Column
                  modifiers={['col', 'padScaleX_1', 'padScaleY_0']}
                  style={
                    !extraInputsRight
                      ? {
                          paddingLeft: px2rem(35),
                          paddingRight: px2rem(35),
                        }
                      : {}
                  }
                >
                  <StandaloneSearchBox
                    bounds={mapBounds}
                    ref={assetLocationSearchBoxRef}
                    onPlacesChanged={this.onPlaceSelect(
                      ASSET_LOCATION_SEARCH_INDEX,
                    )}
                  >
                    <InputField
                      name="assetLocation"
                      onChange={onLocationInputChange(
                        ASSET_LOCATION_SEARCH_INDEX,
                      )}
                      placeholder={t`Enter a city and state or zip code...`}
                      value={assetLocationSearchValue || ''}
                      style={{ overflow: 'visible' }}
                      onBlur={this.handleAssetLocationBlur}
                      readOnly={readOnly}
                      maxLength={255}
                    >
                      <InputField.Icon
                        name="truck"
                        modifiers={compact([assetLocation && 'textLight'])}
                        style={generateInputIconStyle(
                          assetLocation,
                          assetLocationSearchValue,
                          theme,
                        )}
                      />
                      <Column modifiers={['col', 'padScale_0']}>
                        <Row>
                          <InputField.Label>
                            <Trans>Asset Location</Trans>
                          </InputField.Label>
                        </Row>
                        <Row>
                          <InputField.TextField
                            onKeyUp={this.handleAssetLocationKeyUp}
                            ref={textFieldRef}
                          />
                          {assetLocationSearchValue && (
                            <InputField.ActionButton
                              icon="times"
                              modifiers={['hoverDanger']}
                              onClick={(e) => {
                                onLocationReset(ASSET_LOCATION_SEARCH_INDEX)(e);
                                this.handleAssetLocationReset();
                              }}
                            />
                          )}
                        </Row>
                      </Column>
                      <LocationTimeAndWeather
                        location={assetLocation}
                        hideWeather={isRouteSearch}
                      />
                    </InputField>
                  </StandaloneSearchBox>
                </Column>
                {extraInputsRight}
              </InputGroup.Row>
              {/*  Destination Location Inputs */}
              {isRouteSearch && (
                <>
                  {destinationLocationSearches.map(
                    ({ location, searchBoxRef, searchValue }, index) => {
                      const key = `SearchInputComponent_destinationInput_${index}`;

                      // The assetLocationSearch is always the first in the array
                      // of locationSearches.
                      const LOCATION_SEARCH_INDEX = index + 1;
                      const isLast =
                        LOCATION_SEARCH_INDEX ===
                        destinationLocationSearches.length;

                      return (
                        <InputGroup.Row
                          key={key}
                          modifiers={['middle']}
                          style={{ paddingLeft: px2rem(35) }}
                          onMouseEnter={() =>
                            this.setState({ hoveredDestInputIndex: index })
                          }
                          onMouseLeave={() =>
                            this.setState({ hoveredDestInputIndex: -1 })
                          }
                        >
                          <Column modifiers={['col', 'padScale_0']}>
                            <StandaloneSearchBox
                              bounds={mapBounds}
                              ref={searchBoxRef}
                              onPlacesChanged={this.onPlaceSelect(
                                LOCATION_SEARCH_INDEX,
                              )}
                            >
                              <InputField
                                name={`destinationLocation_${index}`}
                                onChange={onLocationInputChange(
                                  LOCATION_SEARCH_INDEX,
                                )}
                                placeholder={t`Enter a city and state or zip code...`}
                                style={{ overflow: 'visible' }}
                                value={searchValue || ''}
                              >
                                <InputField.Icon
                                  name={isLast ? 'finish-line' : 'circle'}
                                  modifiers={compact([location && 'textLight'])}
                                  style={generateInputIconStyle(
                                    location,
                                    searchValue,
                                    theme,
                                  )}
                                />
                                <Column modifiers={['col', 'padScale_0']}>
                                  <Row>
                                    <InputField.Label>
                                      <Trans>Destination</Trans>
                                    </InputField.Label>
                                  </Row>
                                  <Row>
                                    <InputField.TextField />
                                    {searchValue && (
                                      <InputField.ActionButton
                                        icon="times"
                                        modifiers={['hoverDanger']}
                                        onClick={onLocationReset(
                                          LOCATION_SEARCH_INDEX,
                                        )}
                                      />
                                    )}
                                  </Row>
                                </Column>
                              </InputField>
                            </StandaloneSearchBox>
                          </Column>
                          <Column
                            modifiers={['padScaleX_1', 'padScaleY_0']}
                            style={{
                              visibility:
                                hoveredDestInputIndex === index &&
                                destinationLocationSearches.length > 1
                                  ? 'visible'
                                  : 'hidden',
                            }}
                          >
                            <ButtonIconRectangle
                              modifiers={['hoverDanger']}
                              style={{ width: px2rem(27) }}
                              type="button"
                              onClick={() => onDeleteLocationSearch(index)}
                            >
                              <ButtonIconRectangle.Icon
                                name="trash"
                                modifiers={['mini']}
                              />
                            </ButtonIconRectangle>
                          </Column>
                        </InputGroup.Row>
                      );
                    },
                  )}
                  <InputGroup.Row
                    modifiers={['padScaleY_3']}
                    style={{
                      paddingLeft: px2rem(35),
                      paddingRight: px2rem(35),
                    }}
                  >
                    <ButtonLinkWithIcon
                      icon="plus"
                      onClick={onAddLocationSearch}
                    >
                      <Trans>Add Destination</Trans>
                    </ButtonLinkWithIcon>
                  </InputGroup.Row>
                </>
              )}
            </InputGroup>
          </Column>
        </Row>
        {/*  Filters */}
        {assetLocationOnly && panelStatus === PANEL_STATUSES.invalid && (
          <Row>
            <Column modifiers={['col', 'padScaleY_2']}>
              <MessageSmall type="warning">
                <Trans>
                  No matches for &quot;
                  <span>{assetLocationSearchValue}</span>
                  &quot;. Search by a different location.
                </Trans>
              </MessageSmall>
            </Column>
          </Row>
        )}
        {assetLocationValidationError}
        <Row
          modifiers={['middle']}
          style={
            !extraInputsBottom
              ? {
                  paddingLeft: px2rem(35),
                  paddingRight: px2rem(35),
                }
              : {}
          }
        >
          {extraInputsBottom}
        </Row>
      </Container>
    );
  }
}

export default SearchInputComponent;

import { USER_ROLES, RouteAuthorization } from 'features/rbac';

export const CallCenterAuthorized = RouteAuthorization(USER_ROLES.CALL_CENTER);

export const TechAdminAuthorized = RouteAuthorization([
  ...USER_ROLES.TECH_ADMIN,
  ...USER_ROLES.CALL_CENTER_ONLY,
]);

export const DealerAuthorized = RouteAuthorization([
  USER_ROLES.DEALER_USER,
  USER_ROLES.DEALER_ADMIN,
  ...USER_ROLES.TECH_ADMIN,
]);

import {
  difference as arrayDiff,
  isEqual,
  isPlainObject,
  keys,
  reduce,
  zipObject,
} from 'lodash';

/**
 * Deep compare to object, returning a new object capturing all
 * the differences. Only plain key-value "objects" are "dived into"
 * for deep comparison; all other types (including arrays) are
 * shallow-compared.
 *
 * Keys that have a value in `base` but are missing (or have a null
 * value) in `object` will be included in the return value with a
 * value of `null`.
 *
 * @param  {Object} object The new object to compare with `base`
 * @param  {Object} base   The original object for comparison
 * @return {Object}        Differences between the two
 */
export default function difference(object, base) {
  // eslint-disable-next-line no-use-before-define
  const changes = findChanges(object, base);
  const missingKeys = arrayDiff(keys(base), keys(object));
  return {
    ...zipObject(missingKeys, new Array(missingKeys.length).fill(null)),
    ...changes,
  };
}

function findChanges(object, base) {
  return reduce(
    object,
    (acc, value, key) => {
      if (!isEqual(value, base[key])) {
        return {
          ...acc,
          [key]:
            isPlainObject(value) && isPlainObject(base[key])
              ? difference(value, base[key])
              : value,
        };
      }
      return acc;
    },
    {},
  );
}

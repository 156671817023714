import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from '@lingui/core';
import { en } from 'make-plural/plurals';
import { I18nProvider as BaseProvider } from '@lingui/react';

import catalogEn from 'locales/en/messages';

// TODO: if we ever enable other languages,
// we'll need to load their catalogs.
i18n.loadLocaleData('en', { plurals: en });
i18n.load('en', catalogEn.messages);
i18n.activate('en');

export default function I18nProvider({ children }) {
  return <BaseProvider i18n={i18n}>{children}</BaseProvider>;
}

I18nProvider.propTypes = { children: PropTypes.node.isRequired };

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  applyResponsiveStyleModifiers,
  applyStyleModifiers,
  styleModifierPropTypes,
} from 'styled-components-modifiers';
import {
  buildThemePropTypes,
  validateTheme,
} from 'styled-components-theme-validator';

import {
  dimensionModifiers,
  displayModifiers,
  padScaleModifiers,
} from './utils/modifiers';

const COMPONENT_NAME = 'Row';

export const MODIFIER_CONFIG = {
  ...dimensionModifiers,
  ...displayModifiers,
  ...padScaleModifiers,
  bottom: () => ({
    styles: css`
      align-items: flex-end;
    `,
  }),
  center: () => ({
    styles: css`
      justify-content: center;
      text-align: center;
    `,
  }),
  end: () => ({
    styles: css`
      justify-content: flex-end;
      text-align: end;
    `,
  }),
  fluid: ({ theme }) => ({
    styles: css`
      margin-left: calc(-${theme.dimensions.gridPad} * 2);
      margin-right: calc(-${theme.dimensions.gridPad} * 2);
    `,
  }),
  middle: () => ({
    styles: css`
      align-items: center;
    `,
  }),
  reverse: () => ({
    styles: css`
      -webkit-box-direction: reverse;
      -webkit-box-orient: horizontal;
      flex-direction: row-reverse;
    `,
  }),
  start: () => ({
    styles: css`
      justify-content: flex-start;
      text-align: start;
    `,
  }),
  top: () => ({
    styles: css`
      align-items: flex-start;
    `,
  }),
};

const THEME_PROPTYPES = buildThemePropTypes({
  dimensions: PropTypes.shape({
    gridPad: PropTypes.string.isRequired,
  }).isRequired,
});

const Row = styled.div`
  ${validateTheme(COMPONENT_NAME, THEME_PROPTYPES)}
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 0 1 auto;
  position: relative;
  ${applyStyleModifiers(MODIFIER_CONFIG)}
  ${applyResponsiveStyleModifiers(MODIFIER_CONFIG)}
`;

Row.propTypes = {
  className: PropTypes.string,
  modifiers: styleModifierPropTypes(MODIFIER_CONFIG),
};

Row.defaultProps = {
  className: COMPONENT_NAME,
};

Row.displayName = COMPONENT_NAME;
Row.modifiers = MODIFIER_CONFIG;

export default Row;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { trim, isEmpty } from 'lodash';
import { withRouter } from 'react-router';
import { compose, setDisplayName } from 'recompose';

import { Divider, Text } from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Container, Row, Column } from 'styled-components-grid';

import TextGhostIndicator from 'elements/TextGhostIndicator';

import { bffCaseUnassignLink } from 'utils/bff';

import AssignUserAction from './AssignUserAction';
import CaseAssignedToAvatar from './CaseAssignedToAvatar';
import withCaseAssignUser from './withCaseAssignUser';

const FIXED_HEIGHT_PX = 35;

class CaseAssignUserButton extends Component {
  static propTypes = {
    assignCaseToCurrentUser: PropTypes.func.isRequired,
    caseAssignedTo: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
    }),
    caseId: PropTypes.string,
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
    isAssigned: PropTypes.bool.isRequired,
    isAssignedToCurrentUser: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    caseAssignedToLoading: PropTypes.bool.isRequired,
    isReadOnly: PropTypes.bool,
  };

  static defaultProps = {
    caseAssignedTo: {},
    caseId: undefined,
    isReadOnly: false,
  };

  componentDidMount() {
    window.addEventListener('pagehide', this.unassignCase);

    this.tryToAssignCaseToCurrentUser();
  }

  componentDidUpdate(prevProps) {
    // caseId may not exist on mount
    if (
      (!prevProps.caseId || prevProps.caseAssignedToLoading) &&
      this.props.caseId &&
      !this.props.caseAssignedToLoading
    ) {
      this.tryToAssignCaseToCurrentUser();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('pagehide', this.unassignCase);

    /**
     * This handles the auto-unassign for in-app navigation,
     * such as when navigating from a case page to the search results.
     */
    if (this.props.isAssignedToCurrentUser) this.unassignCase();
  }

  tryToAssignCaseToCurrentUser = () => {
    if (this.props.isReadOnly) return;

    // Passing `true` sets the `onlyIfUnassigned` flag on the mutation. This
    // prevents re-assigning the case if it is currently assigned to someone
    // else - and also prevents the need to query for and check the current
    // case assignment before assigning it to the current user.
    if (this.props.caseId) this.props.assignCaseToCurrentUser(true);
  };

  unassignCase = () => {
    const { caseId, isReadOnly } = this.props;

    if (isReadOnly) return;

    navigator.sendBeacon(
      bffCaseUnassignLink,
      new Blob([JSON.stringify({ caseId })], { type: 'application/json' }),
    );
  };

  navigateToDashboard = () => this.props.history.push('/');

  render() {
    const {
      assignCaseToCurrentUser,
      caseAssignedTo,
      isAssigned,
      isAssignedToCurrentUser,
      caseAssignedToLoading,
      isReadOnly,
    } = this.props;

    const isLoading = this.props.isLoading || caseAssignedToLoading;

    if (!isLoading && isReadOnly && isEmpty(caseAssignedTo)) {
      return null;
    }

    return (
      <Container modifiers="padScale_0">
        <Row>
          <Column modifiers={['padScaleX_4', 'padScaleY_0']}>
            <Divider modifiers="vertical" />
          </Column>
          <Column
            style={{
              alignSelf: 'center',
              paddingRight: px2rem(10),
              minHeight: px2rem(FIXED_HEIGHT_PX),
            }}
            modifiers="padScale_0"
          >
            <CaseAssignedToAvatar
              caseAssignedTo={caseAssignedTo}
              isAssigned={isAssigned}
              isLoading={isLoading}
            />
          </Column>
          <Column
            modifiers="padScale_0"
            style={{ minHeight: px2rem(FIXED_HEIGHT_PX) }}
          >
            {isLoading && (
              <Row modifiers={['height_100', 'middle']}>
                <Column modifiers="padScale_0">
                  <TextGhostIndicator style={{ width: px2rem(80) }}>
                    .
                  </TextGhostIndicator>
                </Column>
              </Row>
            )}
            {!isLoading && isAssigned && (
              <Row>
                <Column modifiers="padScale_0" style={{ top: '2px' }}>
                  <Text>
                    {`${trim(caseAssignedTo.name) || caseAssignedTo.email}`}
                  </Text>
                </Column>
              </Row>
            )}
            {!isLoading && !isReadOnly && (
              <AssignUserAction
                assignCaseToCurrentUser={assignCaseToCurrentUser}
                isAssigned={isAssigned}
                isAssignedToCurrentUser={isAssignedToCurrentUser}
                unassignCase={this.navigateToDashboard}
              />
            )}
          </Column>
        </Row>
      </Container>
    );
  }
}

export default compose(
  setDisplayName('CaseAssignUserButton'),
  withCaseAssignUser,
  withRouter,
)(CaseAssignUserButton);

import React from 'react';
import { curry, get } from 'lodash';
import { Query } from '@apollo/client/react/components';

import CustomerWidgetContext from '../../CustomerWidgetContext';
import caseCustomerBillingPreferencesQuery from './caseCustomerBillingPreferencesQuery';

const valuesToUpperCase = (prefs = {}) =>
  Object.entries(prefs).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value && value.toUpperCase(),
    }),
    {},
  );

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => (
    <WrappedComponent
      {...componentProps}
      billingPreferences={valuesToUpperCase(
        get(queryProps, 'data.case.customer.billingPreferences') || {},
      )}
    />
  ),
);

function buildWrappedComponentWithQuery(WrappedComponent, componentProps) {
  const { caseNumber, customerId } = componentProps;

  return (
    <Query
      query={caseCustomerBillingPreferencesQuery}
      skip={!caseNumber || !customerId}
      /**
       * Pass the customer id, even though it is not used in the query,
       * so that Apollo thinks something has changed and queries new data,
       * but still uses the cache when possible.
       */
      variables={{ caseNumber, customerId }}
      fetchPolicy="cache-and-network"
    >
      {buildQueryChild(WrappedComponent, componentProps)}
    </Query>
  );
}

export const buildConsumerChild = curry(
  (WrappedComponent, componentProps, consumerProps) => {
    const { caseNumber } = consumerProps;

    return buildWrappedComponentWithQuery(WrappedComponent, {
      ...componentProps,
      caseNumber,
      customerId: get(componentProps, 'customer.id'),
    });
  },
);

const withCustomerBillingPreferences = (WrappedComponent) => (
  componentProps,
) => (
  <CustomerWidgetContext.Consumer>
    {buildConsumerChild(WrappedComponent, componentProps)}
  </CustomerWidgetContext.Consumer>
);

export default withCustomerBillingPreferences;

import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { t, Trans } from '@lingui/macro';
import { compose, withState, mapProps } from 'recompose';

import { Text } from 'base-components';
import { Row, Column } from 'styled-components-grid';

import withContext from 'utils/withContext';
import withFocusReceiver from 'setup/FocusProvider/withFocusReceiver';

import { isPhoneNumber } from 'utils/format';

import withCaseDealerResponseActions from '../../withCaseDealerResponseActions';
import DealerResponsesValidationContext from '../../DealerResponsesValidationContext';

import { TextInputField } from '../InputField';
import { buildFocusReceiverId } from '../utils';

function BaseRenderer(props) {
  const { id, readOnly, value } = props;
  const { updatePhoneNumber, onFocusRequested } = props;

  return (
    <TextInputField
      name={`dealerResponse-${id}-phoneNumber`}
      isValid={value ? isPhoneNumber(value) : true}
      onChange={(_, newPhoneNumber) => updatePhoneNumber(newPhoneNumber)}
      readOnly={readOnly}
      phoneNumber
      defaultValue={value || ''}
      placeholder={t`(###) ###-####`}
      onFocusRequested={onFocusRequested}
    />
  );
}

BaseRenderer.propTypes = {
  id: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  value: PropTypes.string,
  updatePhoneNumber: PropTypes.func.isRequired,
  onFocusRequested: PropTypes.func.isRequired,
  // Used in withState and mapProps below
  /* eslint-disable react/no-unused-prop-types */
  phoneNumber: PropTypes.string,
  updateDealerResponse: PropTypes.func,
  onValidateResponseColumn: PropTypes.func,
  /* eslint-enable react/no-unused-prop-types */
};

BaseRenderer.defaultProps = {
  phoneNumber: undefined,
  updateDealerResponse: undefined,
  onValidateResponseColumn: noop,
  value: undefined,
};

const CellRenderer = compose(
  withCaseDealerResponseActions,
  withContext(DealerResponsesValidationContext),
  withState('value', 'setValue', ({ phoneNumber }) => phoneNumber),
  mapProps(
    ({
      id,
      setValue,
      updateDealerResponse,
      onValidateResponseColumn,
      ...rest
    }) => ({
      id,
      ...rest,
      updatePhoneNumber: (phoneNumber) => {
        const isValid = !phoneNumber || isPhoneNumber(phoneNumber);

        setValue(phoneNumber);
        onValidateResponseColumn(id, 'phoneNumber', isValid);

        if (!phoneNumber || isPhoneNumber(phoneNumber)) {
          updateDealerResponse({ id, patch: { phoneNumber } });
        }
      },
    }),
  ),
  withFocusReceiver(buildFocusReceiverId('phoneNumber')),
)(BaseRenderer);

export default {
  cellKeyGetter: ({ id }) => `${id}:phoneNumber`,
  cellDataGetter: (args) => args,
  name: 'phoneNumber',
  headerCellRenderer: () => (
    <Row>
      <Column modifiers={['padScaleX_2', 'padScaleY_3']}>
        <Text modifiers={['small', 'textLight']}>
          <Trans>Phone Number</Trans>
        </Text>
      </Column>
    </Row>
  ),
  dataCellRenderer: (
    { id, phoneNumber }, // eslint-disable-line react/prop-types
    { tableMetaData, rowIndex },
  ) => (
    <CellRenderer
      id={id}
      readOnly={tableMetaData.readOnly}
      rowIndex={rowIndex}
      phoneNumber={phoneNumber}
      dealerIndex={tableMetaData.dealerIndex}
    />
  ),
};

import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName, withState, mapProps } from 'recompose';

import { QuickActionButton } from 'base-components';

import { formatPhone } from 'utils/format';

import WithClickToCall from './WithClickToCall';

function CallButton({
  currentCallNumber,
  enabled,
  onCancel,
  onClick,
  onConfirm,
  phoneNumber,
  renderConfirmationModal,
  shouldShowModal,
}) {
  const showModal = [
    shouldShowModal,
    typeof renderConfirmationModal === 'function',
  ].every(Boolean);

  return (
    <>
      <QuickActionButton
        disabled={!enabled}
        modifiers={!enabled && 'disabled'}
        onClick={onClick}
      >
        <QuickActionButton.Icon name="phone" modifiers={['left']} />
        <QuickActionButton.Text>
          {currentCallNumber === phoneNumber ? (
            <Trans id="Calling..." />
          ) : (
            formatPhone(phoneNumber)
          )}
        </QuickActionButton.Text>
      </QuickActionButton>
      {showModal && renderConfirmationModal({ onCancel, onConfirm })}
    </>
  );
}

CallButton.propTypes = {
  currentCallNumber: PropTypes.string,
  enabled: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  renderConfirmationModal: PropTypes.func,
  shouldShowModal: PropTypes.bool,
};

CallButton.defaultProps = {
  currentCallNumber: null,
  renderConfirmationModal: undefined,
  shouldShowModal: false,
};

export default compose(
  setDisplayName('CallButton'),
  WithClickToCall,
  withState('shouldShowModal', 'toggleModal', false),
  mapProps(({ renderConfirmationModal, toggleModal, placeCall, ...rest }) => ({
    ...rest,
    onClick: renderConfirmationModal ? () => toggleModal(true) : placeCall,
    onCancel: () => toggleModal(false),
    onConfirm: () => toggleModal(false, placeCall),
    renderConfirmationModal,
  })),
)(CallButton);

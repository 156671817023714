import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Icon } from 'base-components';
import { buildStyledComponent } from 'decisiv-ui-utils';

import Text from './Text';

const styles = ({ theme }) => `
  align-items: center;
  background: none;
  border: none;
  color: ${theme.colors.base.chrome600};
  display: flex;
  outline: none;
  padding: 0;
  white-space: nowrap;
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome600: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const InfoButton = buildStyledComponent('InfoButton', styled.button, styles, {
  themePropTypes,
});

InfoButton.Text = Text;
InfoButton.Icon = Icon;

export default InfoButton;

import React from 'react';
import PropTypes from 'prop-types';
import { H2 } from '../../elements';

function Title(props) {
  const { modifiers, ...rest } = props;
  const defaultModifiers = ['fontWeightMedium'];
  const titleModifiers = defaultModifiers.concat(modifiers);

  return <H2 modifiers={titleModifiers} {...rest} />;
}

Title.defaultProps = {
  modifiers: [],
};

Title.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

export default Title;

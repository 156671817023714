/* eslint-disable no-unused-expressions */
import { useState, useCallback, useReducer } from 'react';
import moment from 'moment-timezone';
import { t } from '@lingui/macro';
import { i18n } from '@lingui/core';

import { periodsConfig, inputFieldDateFormat } from './constants';

export const getInputDisplayValue = (props) => {
  const { startDate, endDate, period } = props;

  if (!startDate && !endDate) return '';

  const end = !moment(endDate).isSame(startDate, 'day') && endDate;

  const dates = [startDate, end]
    .filter(Boolean)
    .map((date) => moment(date).format(inputFieldDateFormat))
    .join(' - ');

  const periodLabel = periodsConfig[period]?.label;

  return i18n._(t`${periodLabel} (${dates})`);
};

export const getRangeForPeriod = (period) => {
  const [startShift, endShift] = periodsConfig[period]?.shifts || [0, 0];

  return [
    moment().subtract(startShift, 'days').startOf('day').toDate(),
    moment().subtract(endShift, 'days').endOf('day').toDate(),
  ];
};

export const getSelectedPeriodFromRange = (start, end) => {
  if (!start && !end) return '';

  const startM = moment(start);
  const endM = moment(end);

  const period = Object.keys(periodsConfig).find((period) => {
    const [periodStart, periodEnd] = getRangeForPeriod(period);

    return startM.isSame(periodStart, 'day') && endM.isSame(periodEnd, 'day');
  });

  return period || 'custom';
};

export const useDateRangeData = ({ startDate, endDate, onChange }) => {
  const [range, setRange] = useState({ startDate, endDate });

  const [selectedPeriod, setSelectedPeriod] = useState(
    getSelectedPeriodFromRange(startDate, endDate),
  );

  // Used to force render the dropdown contents,
  // when resetting it to the initial values from props
  const [renderCount, forceUpdate] = useReducer((i) => i + 1, 0);

  const clear = useCallback(
    () => onChange({ startDate: undefined, endDate: undefined }),
    [onChange],
  );

  const resetOnClose = useCallback(
    (isOpen) => {
      if (isOpen) return;

      setRange({ startDate, endDate });
      setSelectedPeriod(getSelectedPeriodFromRange(startDate, endDate));
      forceUpdate();
    },
    [startDate, endDate],
  );

  const applyDateRange = useCallback(
    (startDate, endDate) => onChange({ startDate, endDate }),
    [onChange],
  );

  const onSelectPeriod = useCallback(
    (_, period) => {
      setSelectedPeriod(period);

      // When selecting "Custom", wait for the user to click "Apply"
      if (period === 'custom') return;

      applyDateRange(...getRangeForPeriod(period));
    },
    [applyDateRange],
  );

  const data = { range, renderCount, selectedPeriod };
  const actions = { clear, resetOnClose, applyDateRange, onSelectPeriod };

  return { ...data, ...actions };
};

export const useEventCallback = (fn) =>
  useCallback(
    (event, ...rest) => {
      event?.preventDefault();
      event?.stopPropagation();
      fn?.(event, ...rest);
    },
    [fn],
  );

import React from 'react';
import { Trans } from '@lingui/macro';
import { get, startCase } from 'lodash';

import { Text } from 'base-components';
import { Container } from 'styled-components-grid';

import ValueCell from './ValueCell';
import { columnTitles } from '../constants';

export const getDisplayValue = (data, options = {}) => {
  const { rowData, fieldName, entry } = data;

  let value;
  const fieldId = rowData.field.toLowerCase();

  if (!rowData[fieldName]) {
    value = <span>&mdash;</span>;
  } else if (fieldName === 'field') {
    const { fieldTitles } = options.config;
    value = get(fieldTitles, `${entry.type}.${fieldId}`) || startCase(fieldId);
  } else {
    const { fieldValueTransformers: transformers } = options.config;
    const transformer = get(transformers, `${entry.type}.${fieldId}`);

    value = transformer
      ? transformer(rowData[fieldName], { ...data, ...options })
      : rowData[fieldName];
  }

  return (
    <Text modifiers={options.cellTextModifiers}>
      {typeof value === 'string' ? <Trans id={value} /> : value}
    </Text>
  );
};

export const buildColumnForField = (fieldName, opts) => ({
  name: fieldName,
  width: '33.33%',
  cellKeyGetter: () => fieldName,
  cellDataGetter: (rowData) => rowData,

  dataCellRenderer: (rowData, { tableMetaData: { entry } }) => {
    let cellModifiers = '';
    const displayValue = getDisplayValue({ entry, rowData, fieldName }, opts);
    const { previousValue, newValue } = rowData;

    if (fieldName === 'newValue' && newValue !== previousValue) {
      cellModifiers = newValue ? 'modified' : 'removed';
    }

    return <ValueCell modifiers={cellModifiers}>{displayValue}</ValueCell>;
  },

  headerCellRenderer: () => (
    <Container modifiers="padScaleX_2">
      <Text modifiers={['small', 'textLight']}>
        <Trans id={columnTitles[fieldName]} />
      </Text>
    </Container>
  ),
});

import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { CASE_STATUS } from 'compositions/CaseStatus';

import query from './caseStatusQuery';

const withCaseStatus = (Component) => (cProps) => {
  const { data } = useQuery(query, {
    skip: !cProps.caseNumber,
    variables: { caseNumber: cProps.caseNumber },
  });

  const { id, status, statusHistory } = data?.case || {};

  const allProps = {
    ...cProps,
    caseId: id,
    caseStatus: status || CASE_STATUS.new,
    statusHistory: useMemo(() => statusHistory || [], [statusHistory]),
  };

  return <Component {...allProps} />;
};

export default withCaseStatus;

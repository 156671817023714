import React from 'react';
import memoize from 'memoize-one';
import { Query } from '@apollo/client/react/components';
import { curry, get } from 'lodash';

import query from './caseAssetsQuery';

const buildAssets = memoize((primary, related) =>
  [primary, ...(related || [])].filter(Boolean),
);

export const buildQueryChild = curry(
  (WrapperComponent, componentProps, { data, loading }) => (
    <WrapperComponent
      {...componentProps}
      assets={buildAssets(
        get(data, 'case.primaryAsset'),
        get(data, 'case.relatedAssets'),
      )}
      isLoadingAssets={!!loading}
    />
  ),
);

const withCaseAssets = (WrappedComponent) => (componentProps) => (
  <Query
    skip={!componentProps.caseNumber}
    query={query}
    variables={{ caseNumber: componentProps.caseNumber }}
    fetchPolicy="network-only"
  >
    {buildQueryChild(WrappedComponent, componentProps)}
  </Query>
);

export default withCaseAssets;

import React from 'react';
import { Query } from '@apollo/client/react/components';
import { curry, get, map } from 'lodash';

import caseFieldUpdatesQuery from './caseFieldUpdatesQuery';
import { usePagination } from '../../utils';
import { ITEMS_PER_PAGE } from '../constants';
import { processNodes, groupNodesByTimestamp } from './utils';

const edgesPath = 'case.history.edges';
const pageInfoPath = 'case.history.pageInfo';

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const { sortDirection } = componentProps;
    const edges = get(queryProps.data, edgesPath) || [];
    const nodes = processNodes(map(edges, 'node'), sortDirection.toLowerCase());
    const results = groupNodesByTimestamp(nodes, sortDirection.toLowerCase());

    const pager = usePagination({
      ...queryProps,
      edgesPath,
      pageInfoPath,
      itemsPerPage: ITEMS_PER_PAGE,
      sortDirection,
    });

    // Because we filter out "empty" and blacklisted entries on the client, it
    // is possible that we ask for a slice of changes and end up showing the
    // "No results" UI to the user. When that happens, we ask for the next slice
    // behind the scenes until we do get visible results or we reach the end of
    // the pagination. This solution still has the downside of flashing the
    // "No results" UI while we are inside this loop of asking for changes and
    // filtering them all out until finding some that we can show. Ideally, this
    // filtering would be done in the server, but turns out that complicates
    // things too much for them.
    if (!results.length && pager.pagination.hasNextPage) {
      pager.loadOlderResults();
    }

    return (
      <WrappedComponent
        {...componentProps}
        results={results}
        isLoading={!!queryProps.loading}
        {...pager}
      />
    );
  },
);

const withCaseFieldUpdates = (WrappedComponent) => (componentProps) => (
  <Query
    skip={!componentProps.caseNumber}
    query={caseFieldUpdatesQuery}
    variables={{
      caseNumber: componentProps.caseNumber,
      first: ITEMS_PER_PAGE,
      sortDirection: componentProps.sortDirection,
    }}
    fetchPolicy="cache-and-network"
  >
    {buildQueryChild(WrappedComponent, componentProps)}
  </Query>
);

export default withCaseFieldUpdates;

import { compact } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import TabList from '../../blocks/TabList';

import { TOGGLE_TAB_CONTEXT } from './constants';

function List({ children, ...props }, context) {
  const { activeTab, handleTabClick } = context[TOGGLE_TAB_CONTEXT];

  const mappedChildren = React.Children.map(children, (child, i) => {
    const active = activeTab === i && 'active';
    return React.cloneElement(child, {
      modifiers: compact(child.props?.modifiers?.concat(active) ?? [active]),
      onClick: (e) => handleTabClick(e, i),
    });
  });
  return <TabList {...props}>{mappedChildren}</TabList>;
}

List.propTypes = {
  children: PropTypes.node.isRequired,
};

List.contextTypes = {
  [TOGGLE_TAB_CONTEXT]: PropTypes.shape({}).isRequired,
};

export default List;

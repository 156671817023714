import gql from 'graphql-tag';

export default gql`
  query caseQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      inboundProgramShipTo
      inboundProgramNumber(useCachedDataForCase: true) {
        id
        billTo
        customerName
        inboundProgramAccountType
        tollFreeNumber
        requiresValidationForAssetTypes {
          type
          requirements
        }
      }
    }
  }
`;

import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { get, keys, reduce, startCase } from 'lodash';

/**
 * Map of weather codes from the API to the keys used to refer to these
 * codes within the theme colors.
 */
const weatherColors = {
  'clear-day': 'clearDay',
  'clear-night': 'clearNight',
  cloudy: 'cloudy',
  fog: 'fog',
  'partly-cloudy-day': 'partlyCloudyDay',
  'partly-cloudy-night': 'partlyCloudyNight',
  rain: 'rain',
  sleet: 'sleet',
  snow: 'snow',
  wind: 'wind',
};

const errorWeatherColors = {
  'question-circle': 'warning',
  'exclamation-circle': 'warning',
};

const allWeatherColors = { ...weatherColors, ...errorWeatherColors };

/**
 * Map OpenWeather's weather condition codes
 * https://openweathermap.org/weather-conditions#Weather-Condition-Codes-2
 */
const weatherCodes = {
  Thunderstorm: 'rain',
  Drizzle: 'rain',
  Rain: 'rain',
  Snow: 'snow',
  Sleet: 'sleet',
  Mist: 'fog',
  Smoke: 'fog',
  Haze: 'fog',
  Dust: 'fog',
  Fog: 'fog',
  Sand: 'fog',
  Ash: 'fog',
  Squall: 'wind',
  Tornado: 'wind',
  ClearDay: 'clear-day',
  ClearNight: 'clear-night',
  Clouds: 'cloudy',
  PartlyCloudyDay: 'partly-cloudy-day',
  PartlyCloudyNight: 'partly-cloudy-night',
};

const daytimeAffectedCodes = ['Clear', 'PartlyCloudy'];
const sleetWeatherIds = [611, 612, 613];
const partlyCloudyWeatherIds = [801, 802];

/**
 * `code` and `weatherId` are `Main` and `ID` from:
 *  https://openweathermap.org/weather-conditions#Weather-Condition-Codes-2
 */
export function getWeatherCode(current, today) {
  let { time, code, weatherId } = current || {};
  const { sunrise, sunset } = today || {};

  if (!current && !today) {
    return 'exclamation-circle';
  }

  if (!sunrise || !sunset || !weatherId) {
    return weatherCodes[code] || 'question-circle';
  }

  if (sleetWeatherIds.includes(weatherId)) code = 'Sleet';
  if (partlyCloudyWeatherIds.includes(weatherId)) code = 'PartlyCloudy';

  if (daytimeAffectedCodes.includes(code)) {
    const daytime = sunrise < time && time < sunset ? 'Day' : 'Night';
    return weatherCodes[code + daytime];
  }

  return weatherCodes[code] || 'question-circle';
}

export function getWeatherSummary(current) {
  const { weatherId, summary } = current || {};

  if (!weatherId || !summary)
    return t`Weather\nUnavailable`.replace(/\\n/g, '\n');

  if (weatherId < 801) return startCase(summary);

  const [description, numbers] = summary.split(':');
  return [startCase(description), numbers].filter((x) => x).join(':');
}

/**
 * Given a "current weather" code from the API (e.g. `'clear-day'`),
 * return the path within the theme colors (e.g. `'weather.clearDay'`)
 * to the matching background color for that weather code.
 * @param {string} code
 * @return {string}
 */
export function weatherCodeToThemeColorPath(code) {
  if (get(errorWeatherColors, code)) {
    return `status.${get(errorWeatherColors, code)}`;
  }

  return `weather.${get(weatherColors, code, 'clearDay')}`;
}

/**
 * Shared styled-component modifier configuration that allows the direct
 * use of API weather codes to set the background color of an element.
 */
export const modifierConfig = reduce(
  keys(allWeatherColors),
  (acc, key) => ({
    ...acc,
    [key]: ({ theme }) => `
      background-color: ${get(theme.colors, weatherCodeToThemeColorPath(key))};
    `,
  }),
  {},
);

const weatherColorPropTypes = reduce(
  keys(allWeatherColors),
  (acc, key) => ({
    ...acc,
    [weatherColors[key] || errorWeatherColors[key]]: PropTypes.string
      .isRequired,
  }),
  {},
);

/**
 * PropTypes for the theme parts that are required to support the colors
 * specified in the weather `modifierConfig`.
 */
export const themePropTypes = {
  colors: PropTypes.shape({
    weather: PropTypes.shape(weatherColorPropTypes).isRequired,
  }).isRequired,
};

const directionText = [
  t`N`,
  t`NNE`,
  t`NE`,
  t`ENE`,
  t`E`,
  t`ESE`,
  t`SE`,
  t`SSE`,
  t`S`,
  t`SSW`,
  t`SW`,
  t`WSW`,
  t`W`,
  t`WNW`,
  t`NW`,
  t`NNW`,
];

/**
 * Given a compass direction in degrees (0-360), return one of the sixteen
 * cardinal or intercardinal directions (N, NNE, NE, ENE, E, etc) that aligns
 * most closely with the numeric direction.
 * @param {number} num
 * @return {String}
 */
export function degToCompass(num) {
  // Since there is an angle change at every 22.5 degrees, the direction
  // should swap hands after 11.25 degrees.

  // STEPS:
  // 1. Divide the angle by 22.5 because 360deg/16 directions =
  //    22.5deg/direction change.
  // 2. Add 0.5 so that when you truncate the value you can break the 'tie'
  //    between the change threshold.
  // 3. Truncate the value using integer division (so there is no rounding).
  // 4. Directly index into the array and print the value (mod 16).
  return directionText[Math.floor(num / 22.5 + 0.5) % 16];
}

export const fahrenheitToCelsius = (f) => (5 / 9) * (f - 32);

import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

const styles = () => `
  display: flex;
  flex: 1;
`;

export default buildStyledComponent('Layout__Body', styled.section, styles);

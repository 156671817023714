import PropTypes from 'prop-types';
import styled from 'styled-components';
import { px2rem, buildStyledComponent } from 'decisiv-ui-utils';

const modifierConfig = {
  active: ({ theme }) => `background-color: ${theme.colors.brand.primary};`,
};

const styles = (props) => `
  background-color: ${props.theme.colors.base.chrome300};
  border-radius: 4px;
  display: inline-block;
  height: ${px2rem(25)};
  width: ${px2rem(25)};

  img {
    width: 100%;
    height: 100%;
  }
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome300: PropTypes.string.isRequired,
    }).isRequired,
    brand: PropTypes.shape({
      primary: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default buildStyledComponent('IconBox', styled.div, styles, {
  modifierConfig,
  themePropTypes,
});

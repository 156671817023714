import gql from 'graphql-tag';

export default gql`
  mutation updateAfterHoursEvent(
    $afterHoursEventId: ID!
    $patch: AfterHoursEventUpdatePatch!
  ) {
    updateAfterHoursEvent(input: { id: $afterHoursEventId, patch: $patch }) {
      event {
        id
        startDate
      }
    }
  }
`;

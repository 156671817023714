import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose, setDisplayName } from 'recompose';

import { ButtonSort, Dropdown, Text } from 'base-components';

import withIsRouteSearch from '../../withIsRouteSearch';
import withProximityFilter from '../../withProximityFilters';

import Options from './Options';

export class ProximityFilterSelector extends Component {
  static propTypes = {
    isRouteSearch: PropTypes.bool.isRequired,
    onSelectProximityFilter: PropTypes.func.isRequired,
    proximityFilters: PropTypes.arrayOf(PropTypes.number).isRequired,
    proximityFilterLabel: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({}),
    ]).isRequired,
    selectedProximityFilterIndex: PropTypes.string.isRequired,
    selectedProximityFilterLabel: PropTypes.node.isRequired,
  };

  state = {
    arrowUp: false,
  };

  handleExpandedChange = (isExpanded) => this.setState({ arrowUp: isExpanded });

  render() {
    const {
      isRouteSearch,
      onSelectProximityFilter,
      proximityFilters,
      proximityFilterLabel,
      selectedProximityFilterIndex,
      selectedProximityFilterLabel,
    } = this.props;

    return (
      <Dropdown
        activeItem={String(selectedProximityFilterIndex)}
        arrow
        className="ProximityFilterSelector"
        hideOnChange
        onChange={onSelectProximityFilter}
        onExpandedChange={this.handleExpandedChange}
        position="bottomLeft"
      >
        <Dropdown.Target>
          <ButtonSort>
            <ButtonSort.Label>
              {isRouteSearch ? (
                <Trans>Off Route</Trans>
              ) : (
                <Trans>Proximity</Trans>
              )}
            </ButtonSort.Label>
            <ButtonSort.Text>{selectedProximityFilterLabel}</ButtonSort.Text>
            <ButtonSort.Icon arrowUp={this.state.arrowUp} />
          </ButtonSort>
        </Dropdown.Target>
        <Dropdown.Content>
          <Options>
            <Dropdown.List>
              {proximityFilters.map((filter) => (
                <Dropdown.ListItem key={filter}>
                  <Text modifiers={['capitalize']}>
                    <Trans
                      id={proximityFilterLabel}
                      values={{ proximityFilterValue: filter }}
                    />
                  </Text>
                </Dropdown.ListItem>
              ))}
            </Dropdown.List>
          </Options>
        </Dropdown.Content>
      </Dropdown>
    );
  }
}

export default compose(
  setDisplayName('ProximityFilterSelector'),
  withIsRouteSearch,
  withProximityFilter,
)(ProximityFilterSelector);

import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import { Column, Row } from 'styled-components-grid';

import ButtonLinkWithIcon from 'elements/ButtonLinkWithIcon';

import EntriesOrderSelector from './EntriesOrderSelector';
import { sortDirectionPropType } from './withSortDirection';

const scrollIntoView = (id) =>
  document.querySelector(`#${id}`).scrollIntoView({ behavior: 'smooth' });

const HeaderFilters = ({ sortDirection, onSelectDirection }) => (
  <Row modifiers="middle">
    <Column modifiers={['col', 'padScaleX_0', 'padScaleY_2']}>
      <Row modifiers="middle">
        <Column>
          {sortDirection && (
            <EntriesOrderSelector
              selectedDirection={sortDirection}
              onSelectDirection={onSelectDirection}
            />
          )}
        </Column>
        <Column modifiers={['col', 'end']}>
          <ButtonLinkWithIcon
            id="toplink"
            icon="arrow-down"
            iconModifiers={['mini']}
            onClick={() => scrollIntoView('bottomlink')}
          >
            <Trans>Go to bottom</Trans>
          </ButtonLinkWithIcon>
        </Column>
      </Row>
    </Column>
  </Row>
);

HeaderFilters.propTypes = {
  onSelectDirection: PropTypes.func,
  sortDirection: sortDirectionPropType,
};

HeaderFilters.defaultProps = {
  onSelectDirection: undefined,
  sortDirection: undefined,
};

const FooterFilters = () => (
  <Row modifiers="end">
    <Column modifiers={['col', 'end', 'padScaleX_0', 'padScaleY_2']}>
      <ButtonLinkWithIcon
        id="bottomlink"
        icon="arrow-up"
        iconModifiers={['mini']}
        onClick={() => scrollIntoView('toplink')}
      >
        <Trans>Go to top</Trans>
      </ButtonLinkWithIcon>
    </Column>
  </Row>
);

const Filters = {
  Header: HeaderFilters,
  Footer: FooterFilters,
};

export default Filters;

import styled from 'styled-components';
import { buildStyledComponent } from 'decisiv-ui-utils';

import { Divider } from '../../elements';

import Action from './Action';
import Arrow from './Arrow';
import Content from './Content';
import List from './List';
import ListItem from './ListItem';
import SectionBody from './SectionBody';
import SectionHeader from './SectionHeader';
import Target from './Target';

const modifierConfig = {
  bottom: () => 'justify-content: center;',
  bottomLeft: () => 'justify-content: flex-end;',
  bottomRight: () => 'justify-content: flex-start;',
};

const styles = `
  display: flex;
  position: relative;
`;

const Dropdown = buildStyledComponent('Dropdown', styled.div, styles, {
  modifierConfig,
});

Dropdown.Action = Action;
Dropdown.Arrow = Arrow;
Dropdown.Content = Content;
Dropdown.Divider = Divider;
Dropdown.List = List;
Dropdown.ListItem = ListItem;
Dropdown.SectionBody = SectionBody;
Dropdown.SectionHeader = SectionHeader;
Dropdown.Target = Target;

export default Dropdown;

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Column } from 'styled-components-grid';

import { TextField as DefaultTextField } from '../../elements';
import { modifiersForComponent } from '../../utils/modifiers';

const TextField = forwardRef(({ modifiers, readOnly, ...rest }, ref) => {
  const columnModifiers = [
    'col',
    'padScaleY_0',
    ...modifiersForComponent(modifiers, Column),
  ];
  const textFieldModifiers = modifiersForComponent(
    [...modifiers, readOnly && 'readOnly'],
    DefaultTextField,
  );

  return (
    <Column
      modifiers={columnModifiers}
      style={{
        position: 'relative',
        bottom: '2px',
      }}
    >
      <DefaultTextField
        ref={ref}
        modifiers={textFieldModifiers}
        {...rest}
        readOnly={readOnly}
      />
    </Column>
  );
});

TextField.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
  readOnly: PropTypes.bool.isRequired,
};

TextField.defaultProps = {
  modifiers: [],
};

export default TextField;

import React from 'react';
import PropTypes from 'prop-types';

import { Column, Row } from 'styled-components-grid';
import { Avatar, Text } from 'base-components';

import { formatPhone } from 'utils/format';

import HighlightText from 'components/HighlightText';

function InboundProgramCustomerDetails({
  customer: { customerName, tollFreeNumber },
  highlightText,
}) {
  return (
    <>
      <Column>
        <Avatar isCompany name={customerName} modifiers={['small']} />
      </Column>
      <Column>
        <Row>
          <Text>
            <HighlightText text={highlightText}>{customerName}</HighlightText>
          </Text>
        </Row>
        {!!tollFreeNumber && (
          <Row>
            <Text modifiers={['small', 'textLight']}>
              {formatPhone(tollFreeNumber)}
            </Text>
          </Row>
        )}
      </Column>
    </>
  );
}

InboundProgramCustomerDetails.propTypes = {
  customer: PropTypes.shape({
    customerName: PropTypes.string.isRequired,
    tollFreeNumber: PropTypes.string,
  }).isRequired,
  highlightText: PropTypes.string,
};

InboundProgramCustomerDetails.defaultProps = {
  highlightText: undefined,
};

export default InboundProgramCustomerDetails;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';
import { debounce, pick, noop, isNil, omit } from 'lodash';

import { px2rem } from 'decisiv-ui-utils';
import { Accordion, Divider, MessageSmall } from 'base-components';
import { Row, Column } from 'styled-components-grid';

import Panel from 'blocks/Panel';

import {
  withCasePanelStatusActions,
  CASE_STATUS,
  CASE_PANELS,
  PANEL_STATUSES,
} from 'compositions/CaseStatus';
import DealerLocator from 'compositions/DealerLocator';

import getTravelEstimate from 'utils/travelEstimate';
import withPatternValidationOnKeyUp from 'utils/withPatternValidationOnKeyUp';
import withFocusReceiver from 'setup/FocusProvider/withFocusReceiver';
import { CaseShortcut, CASE_SHORTCUT_PANELS } from 'features/keyShortcuts';

import { invalidCharsForExporting } from 'constants/index';

import AssetLocationNotesInput from './AssetLocationNotesInput';
import withAssetLocationSearch from './withAssetLocationSearch';
import withCaseInboundProgramNumber from './withCaseInboundProgramNumber';
import withCreateDealerResponseMutation from './withCreateDealerResponseMutation';
import CaseDealerLocatorExtraInputs from './ExtraInputs';
import withUpdateProximityLevelMutation from './withUpdateProximityLevelMutation';

import { fieldIds } from './constants';
import { getDealerPinIcon } from 'compositions/CaseDealerLocatorClosedPanel/Dealer';
import { reportAssetLocationStatus, getDealerDataTransformer } from './utils';

const DealerLocatorShortcut = ({ perform, deps, passRef, children }) => (
  <CaseShortcut
    action={{
      parent: CASE_SHORTCUT_PANELS.dealerLocator,
      id: 'goToDealerLocatorAction',
      name: t`Go to Service Provider Locator`,
      shortcut: ['s', 'l', '0'],
      priority: 4,
      icon: 'arrow-right',
      perform,
      deps,
    }}
    passRef={passRef}
    children={children}
  />
);

DealerLocatorShortcut.propTypes = {
  perform: PropTypes.func,
  children: PropTypes.any.isRequired,
  deps: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  passRef: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export class CaseDealerLocatorPanel extends Component {
  static propTypes = {
    caseDetail: PropTypes.shape({
      id: PropTypes.string,
      status: PropTypes.string,
      assetLocation: PropTypes.shape({
        coordinates: PropTypes.shape({
          latitude: PropTypes.number,
          longitude: PropTypes.number,
        }),
        useAssetCoordinatesForTravelEstimation: PropTypes.bool,
        address: PropTypes.shape({
          city: PropTypes.string,
          country: PropTypes.string,
          postalCode: PropTypes.string,
          province: PropTypes.string,
          streetAddress: PropTypes.string,
        }),
        searchValue: PropTypes.string,
        countryCode: PropTypes.string,
      }),
      proximityLevel: PropTypes.number,
      servicingDealer: PropTypes.shape({
        id: PropTypes.string.isRequired,
        location: PropTypes.shape({
          latitude: PropTypes.number,
          longitude: PropTypes.number,
        }),
      }),
      useAssetCoordinatesForTravelEstimation: PropTypes.bool,
      dealerTravelEstimateAtDispatch: PropTypes.string,
    }),
    casePanelStatuses: PropTypes.shape({
      assetLocation: PropTypes.shape({
        label: PropTypes.string,
        status: PropTypes.shape({ panel: PropTypes.string }),
      }),
    }),
    updateProximityLevel: PropTypes.func,
    caseNumber: PropTypes.string.isRequired,
    inboundProgramNumber: PropTypes.shape({
      billTo: PropTypes.string.isRequired,
    }),
    loading: PropTypes.bool.isRequired,
    updateCase: PropTypes.func.isRequired,

    // from withCreateDealerResponseMutation:
    createDealerResponse: PropTypes.func.isRequired,

    // from withPatternValidationOnKeyUp
    hasInvalidPattern: PropTypes.func,
    onValidatingFieldBlur: PropTypes.func,
    onValidatingFieldKeyUp: PropTypes.func,
    showValidationError: PropTypes.func,
    readOnly: PropTypes.bool,
    onFocusRequested: PropTypes.func.isRequired,
  };

  static defaultProps = {
    caseDetail: {},
    casePanelStatuses: {},
    inboundProgramNumber: undefined,
    hasInvalidPattern: noop,
    onValidatingFieldBlur: noop,
    onValidatingFieldKeyUp: noop,
    showValidationError: noop,
    readOnly: false,
    updateProximityLevel: undefined,
  };

  state = {
    searchResultsExpanded: false,
    errors: { assetLocation: false, notes: false },
  };

  componentDidUpdate(prevProps) {
    const assetLocation = this.props?.caseDetail?.assetLocation || {};
    const newStatus = this.props.caseDetail.status;
    const prevStatus = prevProps.caseDetail.status;

    reportAssetLocationStatus({ ...this.props, assetLocation });

    // Collapse the results list after dispatching the case
    if (
      prevStatus === CASE_STATUS.dispatch &&
      ![CASE_STATUS.new, CASE_STATUS.dispatch].includes(newStatus) &&
      this.state.searchResultsExpanded
    ) {
      this.setState({ searchResultsExpanded: false });
    }
  }

  onExpandedChange = ({ expanded }) => {
    this.setState({ searchResultsExpanded: expanded });
  };

  createResponseForOutboundCall = ({ phoneNumber, dealer, dealerContact }) => {
    const { caseDetail, createDealerResponse } = this.props;

    const estimate = getTravelEstimate(dealer?.travelEstimate);

    if (caseDetail.status === CASE_STATUS.dispatch) {
      createDealerResponse({
        caseId: caseDetail.id,
        dealerId: dealer.id,
        contactPerson: dealerContact?.name,
        phoneNumber,
        dealerType: 'DEALER', // the Asset Location panel only has "real dealers"
        assetLocationDriveDistance: estimate.assetLocationDriveDistance,
        assetLocationDriveTime: estimate.assetLocationDriveTime,
      });
    }
  };

  buildInitialAssetLocation = () => {
    const { assetLocation } = this.props.caseDetail;
    const coords = assetLocation?.coordinates;
    const location = coords && pick(coords, ['latitude', 'longitude']);

    const travelEstimateDestination =
      assetLocation?.useAssetCoordinatesForTravelEstimation &&
      location?.latitude
        ? `${location.latitude}, ${location.longitude}`
        : assetLocation?.searchValue;

    return {
      location: location || undefined,
      countryCode: assetLocation?.countryCode || '',
      searchValue: assetLocation?.searchValue || '',
      searchValueTried: assetLocation?.searchValue || '',
      addressComponents: assetLocation?.address,
      travelEstimateDestination,
    };
  };

  handleUpdateAssetLocation = (data) => {
    const { errors } = this.state;
    const { hasInvalidPattern, caseDetail } = this.props;
    const { countryCode, location, addressComponents } = data;
    const { searchValue, useCoordinates } = data;

    if (!searchValue || !hasInvalidPattern('assetLocation', searchValue)) {
      this.props.updateCase({
        variables: {
          id: caseDetail.id,
          location: location || null,
          countryCode: countryCode || '',
          searchValue: searchValue || '',
          addressComponents: omit(addressComponents, 'streetNumber'),
          useCoordinates,
        },
      });

      const newState = {};

      if (errors?.assetLocation) {
        newState.errors = { ...errors, assetLocation: false };
      }

      if (!!searchValue && caseDetail.status !== CASE_STATUS.new) {
        newState.searchResultsExpanded = true;
      }

      if (Object.keys(newState).length) this.setState(newState);
    }
  };

  updateAssetLocation = debounce(this.handleUpdateAssetLocation, 1000, {
    trailing: true,
  });

  handleBlur = (name) => (value) => {
    const { errors } = this.state;
    const { onValidatingFieldBlur, hasInvalidPattern } = this.props;

    onValidatingFieldBlur(name);

    this.setState({
      errors: { ...errors, [name]: hasInvalidPattern(name, value) },
    });
  };

  handleKeyUp = (name) => (event) => {
    const { errors } = this.state;
    const { onValidatingFieldKeyUp, hasInvalidPattern } = this.props;
    const value = event?.target?.value;

    onValidatingFieldKeyUp(name, event);

    this.setState({
      errors: { ...errors, [name]: hasInvalidPattern(name, value) },
    });
  };

  renderMap = () => {
    const { readOnly, caseDetail } = this.props;
    const { searchResultsExpanded } = this.state;

    const dealer = caseDetail?.servicingDealer?.dealer;
    const travelEstimate = caseDetail?.dealerTravelEstimateAtDispatch;

    const onlyShowServicingDealer =
      !!dealer?.location && // If it has a location, it is not a custom dealer
      ![CASE_STATUS.new, CASE_STATUS.dispatch].includes(caseDetail.status) &&
      !searchResultsExpanded;

    const visibleDealers = onlyShowServicingDealer
      ? [{ ...dealer, icon: getDealerPinIcon(dealer), travelEstimate }]
      : undefined;

    return (
      <DealerLocator.SearchResultsMap
        readOnly={readOnly}
        visibleDealers={visibleDealers}
        onAssetLocationChange={this.updateAssetLocation}
      />
    );
  };

  renderSearchResults = () => {
    const { searchResultsExpanded } = this.state;
    const { caseDetail, casePanelStatuses } = this.props;
    const panelStatus = casePanelStatuses?.assetLocation?.status?.panel;

    if (caseDetail.status !== CASE_STATUS.dispatch) {
      if (panelStatus === PANEL_STATUSES.complete) {
        return (
          <Row>
            <DealerLocatorShortcut
              perform={() =>
                this.onExpandedChange({ expanded: !searchResultsExpanded })
              }
              deps={searchResultsExpanded}
              passRef
            >
              <Column modifiers={['col', 'padScaleX_3']}>
                <Accordion
                  expanded={searchResultsExpanded}
                  onExpandedChange={this.onExpandedChange}
                >
                  <Accordion.Head
                    style={{ height: 'auto', alignItems: 'baseline' }}
                  >
                    <Accordion.Title
                      style={{ textTransform: 'none', width: '100%' }}
                    >
                      <Row>
                        <Column modifiers={['col', 'padScale_0']}>
                          <DealerLocator.Legend
                            isExpanded={searchResultsExpanded}
                          />
                        </Column>
                      </Row>
                    </Accordion.Title>
                  </Accordion.Head>
                  <Accordion.Body allowOverflowWhenOpen>
                    <>
                      <Row style={{ padding: `0 ${px2rem(5)}` }}>
                        <Column modifiers={['col', 'padScale_0']}>
                          <DealerLocator.SearchFilters />
                        </Column>
                      </Row>
                      <Divider />
                      <Row style={{ height: px2rem(300) }}>
                        <Column modifiers={['col', 'padScale_0']}>
                          <DealerLocator.SearchResultsList
                            onOutboundCall={this.createResponseForOutboundCall}
                          />
                        </Column>
                      </Row>
                    </>
                  </Accordion.Body>
                </Accordion>
              </Column>
            </DealerLocatorShortcut>
          </Row>
        );
      }

      return (
        <Row>
          <DealerLocatorShortcut passRef>
            <Column modifiers={['col', 'padScaleX_3', 'padScaleY_0']}>
              <DealerLocator.Legend />
            </Column>
          </DealerLocatorShortcut>
        </Row>
      );
    }

    return (
      <>
        <Row>
          <Column modifiers={['col', 'padScaleX_3', 'padScaleY_0']}>
            <DealerLocator.Legend />
          </Column>
        </Row>
        <Row>
          <Column modifiers={['col', 'padScaleX_3', 'padScaleY_0']}>
            <DealerLocator.SearchFilters />
          </Column>
        </Row>
        <Divider />
        <Row style={{ height: px2rem(300) }}>
          <DealerLocatorShortcut passRef>
            <Column modifiers={['col', 'padScale_0']}>
              <DealerLocator.SearchResultsList
                onOutboundCall={this.createResponseForOutboundCall}
              />
            </Column>
          </DealerLocatorShortcut>
        </Row>
      </>
    );
  };

  renderValidationError = (name) => {
    const { showValidationError } = this.props;
    const { errors } = this.state;

    if (!showValidationError(name) || !errors[name]) return null;

    const field = { assetLocation: 'location', notes: 'note' }[name];

    return (
      <Row>
        <Column modifiers={['col', 'padScaleY_2']}>
          <MessageSmall type="warning">
            <Trans>
              The {field} cannot contain the following characters: ~`
            </Trans>
          </MessageSmall>
        </Column>
      </Row>
    );
  };

  render() {
    const { updateProximityLevel } = this.props;
    const { searchResultsExpanded } = this.state;
    const { hasInvalidPattern, inboundProgramNumber } = this.props;
    const { loading, readOnly, caseDetail, onFocusRequested } = this.props;

    const dealerDataTransformer = getDealerDataTransformer(this.props);

    const isSearchEnabled =
      caseDetail.status === CASE_STATUS.dispatch || searchResultsExpanded;

    const autoReloadInterval =
      !caseDetail.status || caseDetail.status === CASE_STATUS.dispatch
        ? undefined // undefined, so that the default interval is used
        : 5 * 60 * 1000; // 5 minutes

    const proximityIndex = !isNil(caseDetail.proximityLevel)
      ? String(caseDetail.proximityLevel)
      : undefined;

    const updateProximityIndex = (proximityLevel) =>
      updateProximityLevel({ caseId: caseDetail.id, proximityLevel });

    return (
      <Panel modifiers="padScale_0">
        <DealerLocator
          key={caseDetail.id || 'case-not-loaded'}
          searchEnabled={isSearchEnabled}
          autoReloadInterval={autoReloadInterval}
          initialAssetLocation={this.buildInitialAssetLocation()}
          inboundProgramNumber={inboundProgramNumber}
          dealerDataTransformer={dealerDataTransformer}
          searchAreaDistanceFilterIndex={proximityIndex}
          updateSearchAreaDistanceFilterIndex={updateProximityIndex}
        >
          <Row>
            <Column modifiers={['col', 'padScale_0']}>
              <CaseShortcut
                action={{
                  parent: CASE_SHORTCUT_PANELS.assetLocation,
                  id: 'goToAssetLocationAction',
                  name: t`Go to Asset Location`,
                  shortcut: ['a', 'l', '0'],
                  priority: 4,
                  icon: 'arrow-right',
                }}
                passRef="textFieldRef"
                innerRef={onFocusRequested}
              >
                <DealerLocator.SearchInput
                  assetLocationOnly
                  onAssetLocationChange={this.updateAssetLocation}
                  onAssetLocationKeyUp={this.handleKeyUp('assetLocation')}
                  onAssetLocationBlur={this.handleBlur('assetLocation')}
                  assetLocationValidationError={this.renderValidationError(
                    'assetLocation',
                  )}
                  extraInputsRight={
                    <CaseDealerLocatorExtraInputs
                      caseDetail={caseDetail}
                      loading={loading}
                      readOnly={readOnly}
                    />
                  }
                  extraInputsBottom={
                    !loading && (
                      <AssetLocationNotesInput
                        caseDetail={caseDetail}
                        readOnly={readOnly}
                        onKeyUp={this.handleKeyUp('notes')}
                        onBlur={this.handleBlur('notes')}
                        validationError={this.renderValidationError('notes')}
                        hasInvalidPattern={(v) => hasInvalidPattern('notes', v)}
                      />
                    )
                  }
                  readOnly={readOnly}
                />
              </CaseShortcut>
            </Column>
          </Row>
          <Row>
            <Column
              style={{ height: px2rem(365), overflow: 'hidden' }}
              modifiers={['col', 'padScale_0']}
            >
              {this.renderMap()}
            </Column>
          </Row>
          {this.renderSearchResults()}
        </DealerLocator>
      </Panel>
    );
  }
}

export default compose(
  setDisplayName('CaseDealerLocatorPanel'),
  withCasePanelStatusActions(CASE_PANELS.assetLocation),
  withAssetLocationSearch,
  withCreateDealerResponseMutation,
  withCaseInboundProgramNumber,
  withUpdateProximityLevelMutation,
  withPatternValidationOnKeyUp({
    fields: {
      assetLocation: invalidCharsForExporting,
      notes: invalidCharsForExporting,
    },
  }),
  withFocusReceiver(fieldIds.location),
)(CaseDealerLocatorPanel);

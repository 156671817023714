import initBugsnagClient from './initBugsnagClient';
import errorBoundaryFactory from './errorBoundaryFactory';
import fetchBreadcrumb from './fetchBreadcrumb';
import reduxMiddlewareFactory from './reduxMiddlewareFactory';
import registerBugsnagUserLinkFactory from './registerBugsnagUserLink';
import reportGraphQLErrorsFactory from './reportGraphQLErrors';
import bugsnagBreadcrumbLinkFactory from './bugsnagBreadcrumbLink';

initBugsnagClient();

// Adds a breadcrumb to the bugsnag client that reports on any errors occurring within
// a fetch request.
fetchBreadcrumb.init();

// Creates an error boundary component with reporting to bugsnag.
const ErrorBoundary = errorBoundaryFactory.init();

// Creates a redux middleware preloaded with the bugsnag client.
const reduxBugsnagMiddleware = reduxMiddlewareFactory.init();

// Creates an apollo link that registers the current user in bugsnag;
const registerBugsnagUserLink = registerBugsnagUserLinkFactory.init();

// Creates an ApolloLink that creates Bugsnag breadcrumbs for every
// GraphQL query/mutation request and response.
const bugsnagBreadcrumbLink = bugsnagBreadcrumbLinkFactory.init();

const reportGraphQLErrors = reportGraphQLErrorsFactory.init();

export {
  ErrorBoundary,
  reduxBugsnagMiddleware,
  registerBugsnagUserLink,
  bugsnagBreadcrumbLink,
  reportGraphQLErrors,
};

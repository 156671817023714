import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { t, Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { Column, Row } from 'styled-components-grid';
import { H4, Icon, Text, Popover, ButtonLink } from 'base-components';

import withFocusReceiver from 'setup/FocusProvider/withFocusReceiver';
import ButtonLinkWithIcon from 'elements/ButtonLinkWithIcon';
import { useCaseShortcut, CASE_SHORTCUT_PANELS } from 'features/keyShortcuts';

import {
  allAssetTypes,
  validationStatus,
  assetValidationEntryIcon as iconByType,
  assetValidationEntryIconColorByType as iconColorByType,
} from '../constants';

const { success, missing, failure } = validationStatus;

function AssetValidationRequirement(props) {
  const { isReadOnlyCase, showAssetValidationModal } = props;
  const { requirement, isLast, assetIndex, onFocusRequested } = props;

  const { isMismatch, isValidated, requestAsset } = requirement;
  const { type: assetType, attempt, hasAsset, hasAttempt } = requirement;

  const icon = iconByType[isValidated ? success : failure];
  const canEdit = !isReadOnlyCase;
  const canShowButton = hasAsset || canEdit;

  let iconColor = iconColorByType[isValidated ? success : missing];
  if ((hasAttempt || isMismatch) && !isValidated) {
    iconColor = iconColorByType[failure];
  }

  // Pre-fill the unit number, if the type being edited is the same
  // as the request asset type, and the attempt has no value set.
  let inputValues;
  if (assetType === requestAsset.type && !get(attempt, 'input.vehicleNumber')) {
    inputValues = { vehicleNumber: requestAsset.number };
  }

  const showModal = () =>
    showAssetValidationModal({
      attempt,
      assetType,
      requestAsset,
      inputValues,
    });

  const modalButtonLabel = [
    !canEdit && t`Show Details`,
    canEdit && hasAttempt && t`Edit`,
    canEdit && !hasAttempt && t`Validate`,
  ].find(Boolean);

  const assetTypeLabel = allAssetTypes[assetType];

  const { onFocusRequested: onShortcutFocusRequested } = useCaseShortcut({
    parent: CASE_SHORTCUT_PANELS.assetValidation,
    id: `assetValidationRequirementAction${assetIndex}`,
    name: t`Asset ${assetIndex + 1}: ${modalButtonLabel} (${assetTypeLabel})`,
    shortcut: ['a', 'v', `${assetIndex + 1}`],
    priority: 0,
    perform: () => canShowButton && showModal(),
    deps: `${modalButtonLabel}${canShowButton && 'hasButton'}`,
  });

  return (
    <Row
      key={assetType}
      ref={(ref) => {
        onFocusRequested(ref);
        onShortcutFocusRequested(ref);
      }}
      style={{ flexWrap: 'nowrap', marginBottom: isLast ? 0 : 10 }}
    >
      <Column style={{ paddingRight: 0 }} modifiers="padScale_0">
        <Icon name={icon} modifiers={['mini', iconColor]} />
      </Column>
      <Column modifiers="padScaleY_0" style={{ flex: 1 }}>
        <Row style={{ flexWrap: 'wrap' }}>
          <Column
            modifiers="padScale_0"
            style={{ marginRight: 8, flexShrink: 1 }}
          >
            <Text style={{ display: 'inline' }}>
              <Trans id={allAssetTypes[assetType]} />
            </Text>
            &nbsp;
            {canShowButton && (
              <ButtonLink onClick={showModal}>
                <Trans id={modalButtonLabel} />
              </ButtonLink>
            )}
          </Column>
        </Row>
        {isMismatch && (
          <Row style={{ flexWrap: 'wrap' }}>
            <Column
              style={{ lineHeight: 1, flexShrink: 1 }}
              modifiers="padScale_0"
            >
              <Text style={{ display: 'inline' }} modifiers="small">
                <Trans>Validated/Requested mismatch</Trans>
                &nbsp;
              </Text>
              <Popover
                style={{ display: 'inline-flex' }}
                position={assetIndex === 2 ? 'left' : 'top'}
              >
                <Popover.Target>
                  <ButtonLinkWithIcon modifiers={[]}>
                    <Trans>Details</Trans>
                  </ButtonLinkWithIcon>
                </Popover.Target>
                <Popover.Content style={{ width: 300, padding: 20 }}>
                  <Row>
                    <Column style={{ paddingBottom: 0 }} modifiers="padScale_0">
                      <H4 modifiers="fontWeightMedium">
                        <Trans>Unit Number Mismatch</Trans>
                      </H4>
                    </Column>
                  </Row>
                  <Row>
                    <Column
                      modifiers={['padScaleX_0', 'padScaleY_3', 'flex_1']}
                    >
                      <Text>
                        <Trans>
                          The Unit number of the validated and requested assets
                          is not the same.
                        </Trans>
                      </Text>
                    </Column>
                  </Row>
                  <Row modifiers="middle">
                    <Column style={{ width: 100, padding: 0 }}>
                      <Text modifiers={['small', 'textLight']}>
                        <Trans>Validated Unit #:</Trans>
                      </Text>
                    </Column>
                    <Column modifiers={['col', 'padScaleY_0']}>
                      <Text modifiers="fontWeightMedium">
                        {attempt.asset.vehicleNumber}
                      </Text>
                    </Column>
                  </Row>
                  <Row modifiers="middle">
                    <Column style={{ width: 100, padding: 0 }}>
                      <Text modifiers={['small', 'textLight']}>
                        <Trans>Requested Unit #:</Trans>
                      </Text>
                    </Column>
                    <Column modifiers={['col', 'padScaleY_0']}>
                      <Text modifiers="fontWeightMedium">
                        {requestAsset.number || <span>&mdash;</span>}
                      </Text>
                    </Column>
                  </Row>
                </Popover.Content>
              </Popover>
            </Column>
          </Row>
        )}
      </Column>
    </Row>
  );
}

const requirementPropType = PropTypes.shape({
  type: PropTypes.string.isRequired,
  attempt: PropTypes.shape({
    asset: PropTypes.shape({ vehicleNumber: PropTypes.string }),
  }),
  hasAsset: PropTypes.bool.isRequired,
  hasAttempt: PropTypes.bool.isRequired,
  isMismatch: PropTypes.bool.isRequired,
  isValidated: PropTypes.bool.isRequired,
  requestAsset: PropTypes.shape({
    type: PropTypes.string,
    number: PropTypes.string,
  }).isRequired,
});

AssetValidationRequirement.propTypes = {
  isLast: PropTypes.bool.isRequired,
  assetIndex: PropTypes.number.isRequired,
  // eslint-disable-next-line react/no-typos
  requirement: requirementPropType.isRequired,
  isReadOnlyCase: PropTypes.bool.isRequired,
  onFocusRequested: PropTypes.func.isRequired,
  showAssetValidationModal: PropTypes.func.isRequired,
};

const buildFocusReceiverId = (props) => {
  const { assetIndex, requirement } = props;
  const { type } = requirement;

  return `assetValidation.entries.${assetIndex}.${type}`;
};

export default compose(
  setDisplayName('AssetValidationRequirement'),
  withFocusReceiver(buildFocusReceiverId),
)(AssetValidationRequirement);

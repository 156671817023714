import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Column } from 'styled-components-grid';
import Icon from 'decisiv-iconix-react';

import Input from '../../blocks/Input';
import { modifiersForComponent } from '../../utils/modifiers';

const ActionButton = forwardRef((props, ref) => {
  const { icon, modifiers, readOnly, ...rest } = props;

  if (readOnly) return null;

  const columnModifiers = [
    'padScale_0',
    ...modifiersForComponent(modifiers, Column),
  ];
  const actionButtonModifiers = modifiersForComponent(
    modifiers,
    Input.ActionButton,
  );
  const iconModifiers = [
    'colorInherit',
    'mini',
    ...modifiersForComponent(modifiers, Icon),
  ];

  return (
    <Column
      modifiers={columnModifiers}
      style={{
        position: 'relative',
        bottom: '2px',
      }}
    >
      <Input.ActionButton modifiers={actionButtonModifiers} {...rest} ref={ref}>
        <Icon modifiers={iconModifiers} name={icon} />
      </Input.ActionButton>
    </Column>
  );
});

ActionButton.propTypes = {
  icon: PropTypes.string.isRequired,
  modifiers: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

ActionButton.defaultProps = {
  modifiers: [],
};

export default ActionButton;

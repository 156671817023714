import { NAME as contactsQuery } from '../Contacts/withContacts/contactsQuery';
import { NAME as activeAfterHoursContactsQuery } from '../Contacts/DeleteContactModal/withActiveAfterHoursContacts';
import { queryName as eventsQueryName } from './withCalendarEvents';

/**
 * Return the refetch queries needed for the overall dealer detail page
 * upon change to any after-hours rotation event (create, edit, or delete).
 *
 * @param {Moment|string} startDate - start date of the modified event
 * @param {string} dealerId - ID of the current dealer
 * @return {Array} - an array of refetch queries
 */
export default function scheduleChangeRefetch() {
  // A contact changes to non-deletable when it is first used in a rotation. Including the
  // contacts query here refreshes the contacts panel's data & updates the display.
  return [contactsQuery, activeAfterHoursContactsQuery, eventsQueryName];
}

import React from 'react';
import moment from 'moment-timezone';
import { Trans } from '@lingui/macro';
import { flow, cloneDeep } from 'lodash';
import { compose, setDisplayName } from 'recompose';

import { px2rem } from 'decisiv-ui-utils';
import { H3, Divider } from 'base-components';
import { Column, Row } from 'styled-components-grid';

import Panel from 'blocks/Panel';
import FieldUpdates from 'components/FieldUpdates';
import { withSortDirection } from 'components/FieldUpdates/withSortDirection';

import withDealerHistory from './withDealerHistory';
import withDealerSavingStatus from '../withDealerSavingStatus';

import {
  fieldTitles,
  changeSummarySentences,
  relatedInfoPathsByType,
  fieldValueTransformers,
} from './constants';

const snoozeDateKey = 'rotations_email_snoozed_at';
const snoozeEntryKeys = ['updated_by', 'rotations_email_snoozed'];

const emailRotationsSnoozeEntryTransformer = (entry = {}) => {
  const { info = {} } = entry;
  const infoKeys = Object.keys(info);
  const finalEntry = cloneDeep(entry);

  if (snoozeEntryKeys.every((k) => infoKeys.includes(k))) {
    const isSnoozed = info.rotations_email_snoozed === 'true';
    const snoozeDate = entry.fields.find((f) => f.field === snoozeDateKey);
    const autoResumeDate = moment(snoozeDate).add(30, 'days').format('MMMM D');

    finalEntry.type = isSnoozed
      ? 'rotations_email_snoozed'
      : 'rotations_email_resumed';

    finalEntry.user.email = info.updated_by;
    finalEntry.info.autoResumeDate = autoResumeDate;
    finalEntry.fields = [];
  }

  return finalEntry;
};

const deletedContactKey = 'deleted_at';

const contactDeletedEntryTransformer = (entry = {}) => {
  const finalEntry = cloneDeep(entry);
  if (entry.fields.some((f) => f.field === deletedContactKey)) {
    finalEntry.action = 'deleted';
    finalEntry.fields = [];
  }
  return finalEntry;
};

const transforms = [
  emailRotationsSnoozeEntryTransformer,
  contactDeletedEntryTransformer,
];

const config = {
  fieldTitles,
  entryTransformer: flow(transforms),
  changeSummarySentences,
  relatedInfoPathsByType,
  fieldValueTransformers,
};

function DealerDetailsActivityPanel(props) {
  return (
    <Panel>
      <Row modifiers="padScale_2">
        <H3 modifiers="fontWeightRegular">
          <Trans>Activity</Trans>
        </H3>
      </Row>
      <Row modifiers={['padScaleY_0', 'padScaleX_2']}>
        <Column modifiers={['col', 'padScale_0']}>
          <Divider style={{ marginTop: px2rem(10) }} />
          <FieldUpdates config={config} {...props} />
        </Column>
      </Row>
    </Panel>
  );
}

export default compose(
  setDisplayName('DealerDetailsActivityPanel'),
  withSortDirection(),
  withDealerHistory,
  withDealerSavingStatus,
)(DealerDetailsActivityPanel);

import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { Row, Column } from 'styled-components-grid';
import { GhostIndicator } from 'base-components';

import DropdownCell from '../DropdownCell';
import useTireOptions from './useTireOptions';

const loading = (
  <Row>
    <Column modifiers="col">
      <GhostIndicator />
    </Column>
  </Row>
);

export function TireOptionsDropdownCell(props) {
  const { onChange, rowData } = props;
  const { options, isLoading } = useTireOptions(props);

  if (isLoading && !rowData.readOnly) return loading;

  return (
    <DropdownCell
      {...props}
      options={options}
      onChange={onChange}
      autoSelectSingleOption
    />
  );
}

TireOptionsDropdownCell.propTypes = {
  rowData: PropTypes.shape({ readOnly: PropTypes.bool }).isRequired,
  onChange: PropTypes.func,
};

TireOptionsDropdownCell.defaultProps = { onChange: noop };

export default React.memo(TireOptionsDropdownCell);

import React from 'react';
import gql from 'graphql-tag';
import { get } from 'lodash';
import { Query } from '@apollo/client/react/components';

export const NAME = 'withCurrentUser';

const query = gql`
  query withCurrentUser {
    currentUserProfile {
      user {
        name
        email
        userName
        firstName
        lastName
      }
    }
  }
`;

const withCurrentUser = (WrappedComponent) => (componentProps) => (
  <Query query={query}>
    {({ data, loading }) => (
      <WrappedComponent
        {...componentProps}
        currentUser={get(data, 'currentUserProfile.user') || {}}
        isLoadingCurrentUser={!!loading}
      />
    )}
  </Query>
);

export default withCurrentUser;

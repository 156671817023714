import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { gql, useQuery } from '@apollo/client';

import { getUnits } from 'utils/unit';
import { unitSelector } from 'redux/preferences/selectors';

export const CASE_DEALER_RESPONSES_QUERY_NAME = 'caseDealerResponsesQuery';

export const CASE_DEALER_RESPONSES_QUERY = gql`
  query caseDealerResponsesQuery(
    $caseNumber: String!
    $distanceUnit: DistanceUnit
  ) {
    case(caseNumber: $caseNumber) {
      id
      status
      dealerResponses {
        dealer {
          ... on Dealer {
            id
            name
            phone
            shipTo
            billTo
            ers247
            timezone
            storeNumber
            displayAddress {
              city
              province
              countryCode
              postalCode
              streetAddress
            }
            operatingStatus {
              open
              ersOpen
            }
          }
          ... on CustomDealer {
            id
            name
            mileageRange {
              id
              max
              min
            }
          }
        }
        responses {
          id
          eta {
            id
          }
          note
          reason
          accepted
          phoneNumber
          responseTime
          contactPerson
          alternativeProductAvailable
          alternativePreferenceAvailable
        }
      }
      dealerTravelEstimateAtDispatch {
        time
        distance(unit: $distanceUnit)
      }
      servicingDealer {
        id
        eta {
          id
        }
        note
        dealer {
          ... on Dealer {
            id
          }
          ... on CustomDealer {
            id
            name
            mileageRange {
              id
            }
          }
        }
        reason
        accepted
        phoneNumber
        responseTime
        contactPerson
        alternativeProductAvailable
        alternativePreferenceAvailable
      }
    }
  }
`;

const withCaseDealerResponses = (Component) => (cProps) => {
  const { caseNumber } = cProps;

  const unit = useSelector(unitSelector);
  const { data } = useQuery(CASE_DEALER_RESPONSES_QUERY, {
    skip: !caseNumber,
    variables: { caseNumber, distanceUnit: getUnits(unit).distanceUnitValue },
  });

  const caseId = data?.case?.id;
  const responses = useMemo(() => data?.case?.dealerResponses || [], [data]);
  const servicingDealer = useMemo(() => data?.case?.servicingDealer, [data]);

  const travelEstimateAtDispatch = useMemo(
    () => data?.case?.dealerTravelEstimateAtDispatch,
    [data],
  );

  return (
    <Component
      {...cProps}
      caseId={caseId}
      servicingDealer={servicingDealer}
      caseDealerResponses={responses}
      servicingDealerTravelEstimateAtDispatch={travelEstimateAtDispatch}
    />
  );
};

export default withCaseDealerResponses;

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName } from 'recompose';

import withContext from 'utils/withContext';

import { AfterHoursContactPropType } from '../../propTypes';

import withContactEvents from './withContactEvents';
import withActiveAfterHoursContacts from './withActiveAfterHoursContacts';
import ScheduleGridNavigator from '../../RotationSchedulePanel/ScheduleGrid/GridNavigator';
import withDeleteAfterHoursEvents from './withDeleteAfterHoursEvents';
import withDeleteAfterHoursContact from '../withDeleteAfterHoursContact';
import withReplaceAfterHoursContact from './withReplaceAfterHoursContact';
import withUpdateAfterHoursEventsContacts from './withUpdateAfterHoursEventsContacts';

import LoadingContactEventsModal from './LoadingContactEventsModal';
import CannotDeleteModal from './CannotDeleteModal';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import DeleteContactEventsModal from './DeleteContactEventsModal';

const isContactActive = (contact, activeAfterHoursContacts) =>
  Object.values(activeAfterHoursContacts).some(
    (activeContact) => activeContact?.id === contact.id,
  );

export function DeleteContactModal(props) {
  const { onClose } = props;
  const { activeAfterHoursContacts, contact, canDelete } = props;
  const { loadingEvents, loadingActiveAHContacts } = props;
  const { loadingEventsError, loadingActiveAHContactsError } = props;

  const didShowDeleteContactEventsModal = useRef(false);

  if (
    !didShowDeleteContactEventsModal.current &&
    (loadingEvents ||
      loadingActiveAHContacts ||
      loadingEventsError ||
      loadingActiveAHContactsError)
  ) {
    return (
      <LoadingContactEventsModal
        hasError={!!loadingEventsError || !!loadingActiveAHContactsError}
        onClose={onClose}
      />
    );
  }

  if (isContactActive(contact, activeAfterHoursContacts)) {
    return <CannotDeleteModal contact={contact} onClose={onClose} />;
  }

  if (canDelete && !didShowDeleteContactEventsModal.current) {
    return <ConfirmDeleteModal {...props} />;
  }

  didShowDeleteContactEventsModal.current = true;

  return <DeleteContactEventsModal {...props} />;
}

DeleteContactModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  contact: AfterHoursContactPropType.isRequired,
  activeAfterHoursContacts: PropTypes.shape({
    backupContact: AfterHoursContactPropType,
    finalContact: AfterHoursContactPropType,
    primaryContact: AfterHoursContactPropType,
  }),
  loadingEvents: PropTypes.bool.isRequired,
  loadingEventsError: PropTypes.shape({}),
  loadingActiveAHContacts: PropTypes.bool.isRequired,
  loadingActiveAHContactsError: PropTypes.shape({}),
  canDelete: PropTypes.bool,
  // these are required for withDeleteAfterHoursEvents
  dealerId: PropTypes.string.isRequired,
};

DeleteContactModal.defaultProps = {
  loadingEventsError: undefined,
  canDelete: undefined,
};

export default compose(
  setDisplayName('DeleteContactModal'),
  withContext(ScheduleGridNavigator),
  withContactEvents,
  withActiveAfterHoursContacts,
  withDeleteAfterHoursContact,
  withUpdateAfterHoursEventsContacts,
  withReplaceAfterHoursContact,
  withDeleteAfterHoursEvents,
)(DeleteContactModal);

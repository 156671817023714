import gql from 'graphql-tag';

export const NAME = 'caseEmailHistory';

export default gql`
  query caseEmailHistory($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      emailHistory {
        edges {
          node {
            insertedAt
            caseEmailType
            recipientsEmails
          }
        }
      }
    }
  }
`;

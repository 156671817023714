import gql from 'graphql-tag';

export const NAME = 'dealerTopNavQuery';

export const dealerTopNavQuery = gql`
  query dealerTopNavQuery($id: ID!) {
    dealer(id: $id) {
      id
      name
    }
  }
`;

export default dealerTopNavQuery;

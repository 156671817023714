import React from 'react';
import PropTypes from 'prop-types';
import { Provider as StoreProvider } from 'react-redux';

import TravelEstimate from 'compositions/TravelEstimate';
import AppThemeProvider from 'setup/AppThemeProvider';
import I18nProvider from 'setup/I18nProvider';
import { store } from 'setup';

import RouteInfo from './RouteInfo';

export function RouteOverlay({ dealer, isRouteSearch }) {
  const {
    distance: distanceFromRoute,
    travelEstimate: { distance, time },
  } = dealer;

  return (
    <AppThemeProvider>
      <StoreProvider store={store}>
        <I18nProvider>
          <RouteInfo>
            <RouteInfo.Content modifiers={['padScale_2']}>
              <TravelEstimate
                distance={distance}
                distanceFromRoute={distanceFromRoute}
                duration={time}
                isRouteSearch={isRouteSearch}
                modifiers={['small']}
              />
            </RouteInfo.Content>
          </RouteInfo>
        </I18nProvider>
      </StoreProvider>
    </AppThemeProvider>
  );
}

RouteOverlay.propTypes = {
  dealer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    distance: PropTypes.number,
    travelEstimate: PropTypes.shape({
      time: PropTypes.number,
      distance: PropTypes.number,
    }),
  }).isRequired,
  isRouteSearch: PropTypes.bool.isRequired,
};

export default RouteOverlay;

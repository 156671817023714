import { isDate } from 'lodash';

/**
 * Evaluates a displayMonth to determine the index of the first day within the first
 * week of the month.
 * @param    {Object} displayMonth             a displayMonth Object
 * @property {Number} displayMonth.year        the full year of the displayed month
 * @property {Number} displayMonth.month       the month of the displayed month
 *                                             (as it would be written in a normal date)
 * @property {Number} displayMonth.daysInMonth the total number of days in this month
 * @return   {Number}                          the index of the first day within the first week
 *                                             of the month
 */
export function getFirstDayOfMonthIndex(displayMonth) {
  const date = new Date(displayMonth.year, displayMonth.month - 1, 1);
  return date.getDay();
}

/**
 * Evaluates two JavaScript Dates for existence and similarity. Time doesn't matter, only dates.
 * @param  {Date}    date1 A JavaScript Date
 * @param  {Date}    date2 A JavaScript Date
 * @return {Boolean}       If both dates exist and have the same year, month, and date.
 */
export function isSameDate(date1, date2) {
  return !!(
    date1 &&
    date2 &&
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

/**
 * Evaluates two JavaScript Dates for existence and order. Time doesn't matter, only dates.
 * @param  {Date}    setDate    The date to use as the base
 * @param  {Date}    dateToTest The date to evaluate
 * @return {Boolean}            If both dates exist and the dateToTest is after the setDate.
 */
export function isAfterDate(setDate, dateToTest) {
  return !!(
    setDate &&
    dateToTest &&
    setDate < dateToTest &&
    !isSameDate(setDate, dateToTest)
  );
}

/**
 * Evaluates two JavaScript Dates for existence and order. Time doesn't matter, only dates.
 * @param  {Date}    setDate    The date to use as the base
 * @param  {Date}    dateToTest The date to evaluate
 * @return {Boolean}            If both dates exist and the dateToTest is before the setDate.
 */
export function isBeforeDate(setDate, dateToTest) {
  return !!(
    setDate &&
    dateToTest &&
    setDate > dateToTest &&
    !isSameDate(setDate, dateToTest)
  );
}

/**
 * Evaluates three JavaScript Dates for existence and order. Time doesn't matter, only dates.
 * @param  {Date}    setDate1   The earlier date to use as the base
 * @param  {Date}    setDate2   The later date to use as the base
 * @param  {Date}    dateToTest The date to evaluate
 * @return {Boolean}            If all dates exist and the dateToTest is between the set dates.
 */
export function isBetweenDates(setDate1, setDate2, dateToTest) {
  return !!(
    setDate1 &&
    setDate2 &&
    dateToTest &&
    isAfterDate(setDate1, dateToTest) &&
    isBeforeDate(setDate2, dateToTest)
  );
}

/**
 * Evaluates and returns the number of days in the month of the provided JS date. Compensates for
 * leap years.
 * @param  {Date}   jsDate The date to find the days in the month of.
 * @return {Number}        The number of days in the month of the provided date.
 */
export function getDaysInMonth(jsDate) {
  // This creates a date that is the last day of the month of the provided date,
  // ... because JavaScript.
  const date = new Date(jsDate.getFullYear(), jsDate.getMonth() + 1, 0);
  return date.getDate();
}

/**
 * Generates a displayMonth object for ingestion by the DateGrid.
 * @param  {Date|displayMonth} date          Can be either a JavaScript Date object or
 *                                           displayMonth object.
 * @param  {Number}            monthOffset   How many months to offset from the passed in date.
 * @return {Object}            A displayMonth object. Fields: ['year', 'month', 'daysInMonth']
 */
export function buildDisplayMonth(date, monthOffset = 0) {
  // Ensures we are working with the correct JavaScript date object.
  const jsDate = isDate(date) ? date : new Date(date.year, date.month - 1, 1);

  jsDate.setMonth(jsDate.getMonth() + monthOffset);

  return {
    year: jsDate.getFullYear(),
    month: jsDate.getMonth() + 1,
    daysInMonth: getDaysInMonth(jsDate),
  };
}

import gql from 'graphql-tag';

const query = gql`
  mutation updateDealerEmail($email: String, $id: ID!) {
    updateDealer(input: { id: $id, patch: { storeManagerEmail: $email } }) {
      dealer {
        storeManagerEmail
      }
    }
  }
`;

export default query;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { H2, P, QuickActionButton } from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';

import Modal from 'components/Modal';

import withRemoveCaseNote from '../withRemoveCaseNote';

import NoteView from './NoteView';

const noticeRowStyles = { marginBottom: px2rem(40) };

class NoteDeleteModal extends Component {
  static propTypes = {
    note: PropTypes.shape({
      author: PropTypes.shape({
        email: PropTypes.string,
        name: PropTypes.string,
      }).isRequired,
      body: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired,
    onCloseModal: PropTypes.func.isRequired,
    removeCaseNote: PropTypes.func.isRequired,
  };

  handleDeleteNote = async () => {
    const { note, removeCaseNote, onCloseModal } = this.props;

    await removeCaseNote({
      variables: {
        ids: [note.id],
      },
    });

    onCloseModal();
  };

  render() {
    const { note, onCloseModal } = this.props;

    return (
      <Modal onClose={onCloseModal}>
        {() => (
          <Modal.Body>
            <Modal.Header modifiers="danger">
              <Modal.HeaderIcon name="briefcase-times" />
            </Modal.Header>
            <Modal.Content>
              <Row modifiers="center">
                <Column>
                  <H2 modifiers="fontWeightRegular">
                    <Trans>Delete Note?</Trans>
                  </H2>
                </Column>
              </Row>
              <Row modifiers="center" style={noticeRowStyles}>
                <Column>
                  <P>
                    <Trans>
                      You&apos;re about to delete the note below. Once deleted,
                      you cannot restore it.
                    </Trans>
                  </P>
                </Column>
              </Row>
              <NoteView bordered note={note} />
            </Modal.Content>
            <Modal.Footer>
              <Row modifiers="end">
                <Column>
                  <QuickActionButton onClick={onCloseModal} type="button">
                    <QuickActionButton.Text>
                      <Trans>Keep Note</Trans>
                    </QuickActionButton.Text>
                  </QuickActionButton>
                </Column>
                <Column>
                  <QuickActionButton
                    type="submit"
                    onClick={this.handleDeleteNote}
                    modifiers={['hoverDanger', 'secondary']}
                  >
                    <QuickActionButton.Text>
                      <Trans>Delete Note</Trans>
                    </QuickActionButton.Text>
                  </QuickActionButton>
                </Column>
              </Row>
            </Modal.Footer>
          </Modal.Body>
        )}
      </Modal>
    );
  }
}

export default compose(
  setDisplayName('NoteDeleteModal'),
  withRemoveCaseNote,
)(NoteDeleteModal);

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import { Avatar, Text } from 'base-components';
import { Row, Column } from 'styled-components-grid';

import HighlightText from 'components/HighlightText';

function renderAdditionalInfo(info, isStore, highlightText) {
  const highlight = <HighlightText text={highlightText} />;

  if (isStore) {
    return (
      <>
        <Trans
          id="Account #<0>{number}</0>"
          values={{ number: info.accountNumber }}
          components={[highlight]}
        />
      </>
    );
  }

  return (
    <>
      <Trans
        id="Bill To: <0>{number}</0>"
        values={{ number: info.billTo }}
        components={[highlight]}
      />
      &nbsp;&middot;&nbsp;
      <Trans
        id="Ship To: <0>{number}</0>"
        values={{ number: info.shipTo }}
        components={[highlight]}
      />
      {info.terminalNumber && (
        <span>
          &nbsp;&middot;&nbsp;
          <Trans
            id="Terminal #: <0>{number}</0>"
            values={{ number: info.terminalNumber }}
            components={[highlight]}
          />
        </span>
      )}
    </>
  );
}

function Item({ info, isStore, highlightText, showAvatar }) {
  const locationString = [info.city, info.state].filter((s) => s).join(', ');
  return (
    <>
      {showAvatar && (
        <Column>
          <Avatar isCompany name={info.name} modifiers={['small']} />
        </Column>
      )}
      <Column>
        <Row>
          <Column modifiers="padScaleY_0">
            <Text>
              <HighlightText text={highlightText}>{info.name}</HighlightText>
            </Text>
          </Column>
        </Row>
        {locationString && (
          <Row>
            <Column modifiers="padScaleY_0">
              <Text modifiers={['small', 'textLight']}>
                <HighlightText text={highlightText}>
                  {locationString}
                </HighlightText>
              </Text>
            </Column>
          </Row>
        )}
        <Row>
          <Column modifiers="padScaleY_0">
            <Text modifiers={['small', 'textLight']}>
              {renderAdditionalInfo(info, isStore)}
            </Text>
          </Column>
        </Row>
      </Column>
    </>
  );
}

Item.propTypes = {
  info: PropTypes.shape({
    billTo: PropTypes.string,
    city: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    shipTo: PropTypes.string,
    state: PropTypes.string,
  }).isRequired,
  isStore: PropTypes.bool.isRequired,
  highlightText: PropTypes.string,
  showAvatar: PropTypes.bool,
};

Item.defaultProps = {
  highlightText: undefined,
  showAvatar: true,
};

export default Item;

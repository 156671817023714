import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Trans, Plural } from '@lingui/macro';
import { compose, setDisplayName, withState } from 'recompose';

import { px2rem } from 'decisiv-ui-utils';
import { Container, Row, Column } from 'styled-components-grid';
import { QuickActionButton, H2, P } from 'base-components';

import Modal from 'components/Modal';
import DealerActiveCasesTable from 'compositions/DealerActiveCasesTable';
import InfoButton from 'blocks/InfoButton';
import HoverSensor from 'elements/HoverSensor';

const joinedAddressParts = (joiner, ...rest) =>
  rest.filter(Boolean).join(joiner);

function ActiveCasesModalTrigger({
  dealer,
  dealer: { activeCases },
  showModal,
  toggleModal,
}) {
  const openModal = () => toggleModal(true);
  const closeModal = () => toggleModal(false);

  return (
    <>
      <InfoButton onClick={openModal}>
        <HoverSensor style={{ display: 'flex', alignItems: 'center' }}>
          {({ isActive: isHover }) => (
            <>
              <InfoButton.Icon
                name="briefcase"
                style={{ marginTop: px2rem(3) }}
              />
              <InfoButton.Text>
                <Plural value={activeCases} one="# Case" other="# Cases" />
              </InfoButton.Text>
              <InfoButton.Icon
                modifiers={['mini', (isHover || showModal) && 'info']}
                name={showModal ? 'chevron-up' : 'chevron-down'}
              />
            </>
          )}
        </HoverSensor>
      </InfoButton>
      {showModal && <ActiveCasesModal dealer={dealer} onClose={closeModal} />}
    </>
  );
}

ActiveCasesModalTrigger.propTypes = {
  dealer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    activeCases: PropTypes.number.isRequired,
  }).isRequired,
  showModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

function ActiveCasesModal({ dealer, onClose }) {
  const displayAddress = joinedAddressParts(
    ', ',
    joinedAddressParts(
      ' ',
      get(dealer, 'displayAddress.streetAddress'),
      get(dealer, 'displayAddress.city'),
    ),
    joinedAddressParts(
      ' ',
      get(dealer, 'displayAddress.province'),
      get(dealer, 'displayAddress.postalCode'),
    ),
  );

  return (
    <Modal onClose={onClose}>
      {() => (
        <Modal.Body style={{ maxWidth: 800, width: '100%' }}>
          <Modal.Header>
            <Modal.HeaderIcon name="briefcase" />
          </Modal.Header>
          <Modal.Content style={{ padding: `${px2rem(40)} 0 0` }}>
            <Container
              style={{ maxHeight: px2rem(450) }}
              modifiers={['padScale_0', 'flex_column']}
            >
              <Row modifiers="center" style={{ flex: '0 0 auto' }}>
                <Column>
                  <H2 modifiers="fontWeightRegular">
                    <Trans>Open Cases</Trans>
                  </H2>
                </Column>
              </Row>
              <Row
                style={{ flex: '0 0 auto', marginBottom: px2rem(40) }}
                modifiers="center"
              >
                <Column>
                  <P>
                    {dealer.name}
                    {!!displayAddress && <br />}
                    {displayAddress}
                  </P>
                </Column>
              </Row>
              <Row
                style={{
                  flex: '1 1 auto',
                  overflow: 'auto',
                  paddingLeft: px2rem(40),
                  paddingRight: px2rem(40),
                }}
              >
                <Column modifiers="col">
                  <DealerActiveCasesTable dealerId={dealer.id} />
                </Column>
              </Row>
            </Container>
          </Modal.Content>
          <Modal.Footer>
            <Row modifiers="end">
              <Column>
                <QuickActionButton
                  type="button"
                  onClick={onClose}
                  modifiers="secondary"
                >
                  <QuickActionButton.Text>
                    <Trans>Close</Trans>
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
            </Row>
          </Modal.Footer>
        </Modal.Body>
      )}
    </Modal>
  );
}

ActiveCasesModal.propTypes = {
  dealer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    displayAddress: PropTypes.shape({
      city: PropTypes.string,
      province: PropTypes.string,
      postalCode: PropTypes.string,
      streetAddress: PropTypes.string,
    }),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('ActiveCasesModalTrigger'),
  withState('showModal', 'toggleModal', false),
)(ActiveCasesModalTrigger);

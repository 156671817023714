import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';

import { Column, Container, Row } from 'styled-components-grid';
import { Dropdown, InputField, Text } from 'base-components';

import Spinner from 'elements/Spinner';

import DropdownList from './DropdownList';

const propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.array).isRequired,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  clearable: PropTypes.bool,
  activeItem: PropTypes.string,
  filterable: PropTypes.bool,
  placeholder: PropTypes.string,
  optionRenderer: PropTypes.func,
  optionFilterer: PropTypes.func,
};

export default class StaticListDropdown extends Component {
  static propTypes = propTypes;

  static defaultProps = {
    icon: undefined,
    readOnly: false,
    clearable: false,
    filterable: false,
    activeItem: undefined,
    placeholder: undefined,
    optionFilterer: () => true, // Include all by default,
    optionRenderer: (_, label) => <Text>{label}</Text>,
  };

  state = {
    options: this.props.options,
    filterValue: '',
    highlightText: '',
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.options !== this.props.options) {
      this.filterBy(this.state.filterValue, nextProps);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.filterOptionsTimeout);
  }

  onTextChange = (e) => {
    const { value } = e.target;
    const { options } = value ? this.state : this.props;
    const { highlightText } = this.state;

    e.stopPropagation();
    clearTimeout(this.filterOptionsTimeout);

    if (value) this.filterBy(value);

    this.setState({
      options,
      filterValue: value,
      isFiltering: !!value,
      highlightText: value ? highlightText : '',
    });
  };

  onClearButtonClick = (e, hide) => {
    const { name, onChange, options } = this.props;

    e.stopPropagation();
    onChange(name, undefined);
    hide();

    this.setState({
      options,
      filterValue: '',
      highlightText: '',
      isFiltering: false,
    });
  };

  onSelectOption = (_, newValue) => {
    const { name, onChange, options } = this.props;

    this.setState(
      { options, filterValue: '', highlightText: '', isFiltering: false },
      () => onChange(name, newValue),
    );
  };

  // Ideally, the dropdown would support keyboard navigation.
  // This is a small UX improvement that allows selecting the first
  // option by pressing "Enter", if there is only one visible.
  onKeyPress = (e) => {
    const { options } = this.state;
    const [firstOption = []] = options;

    if (e.key !== 'Enter' || options.length !== 1) return;

    this.onSelectOption(e, firstOption[0]);
  };

  filterBy = (text, props = this.props) => {
    clearTimeout(this.filterOptionsTimeout);

    if (!text) {
      this.setState({
        options: props.options,
        isFiltering: false,
        highlightText: '',
      });

      return;
    }

    this.filterOptionsTimeout = setTimeout(() => {
      const { options, optionFilterer } = props;
      const filteredOptions = options.filter((o) => optionFilterer(o, text));

      this.setState({
        options: filteredOptions,
        isFiltering: false,
        highlightText: text,
      });
    }, 300);
  };

  render() {
    const { name, label, value, icon, readOnly, clearable } = this.props;
    const { filterValue, highlightText, isFiltering, options } = this.state;
    const { filterable, activeItem, placeholder, optionRenderer } = this.props;

    const textFieldStyles = !filterable
      ? { cursor: 'pointer', caretColor: 'transparent' }
      : undefined;

    const displayValue = filterValue || value;
    const showClearButton = !!clearable && !!displayValue;

    return (
      <Dropdown
        onChange={this.onSelectOption}
        readOnly={readOnly}
        fullWidth
        activeItem={activeItem}
        hideOnChange
        {...omit(this.props, Object.keys(propTypes))}
      >
        {({ isVisible, show, hide, toggle }) => (
          <>
            <Container modifiers="padScale_0" onClick={toggle}>
              <InputField
                name={name}
                value={displayValue}
                onChange={this.onTextChange}
                readOnly={readOnly}
                placeholder={placeholder}
              >
                {icon && <InputField.Icon name={icon} />}
                <Column modifiers="col" style={icon ? { paddingLeft: 0 } : {}}>
                  <Row>
                    <InputField.Label>{label}</InputField.Label>
                  </Row>
                  <Row>
                    <InputField.TextField
                      style={textFieldStyles}
                      onClick={(e) => e.stopPropagation()}
                      onFocus={show}
                      readOnly={readOnly || !filterable}
                      onKeyPress={this.onKeyPress}
                      autoComplete="off"
                    />
                    {isFiltering && (
                      <Column modifiers="padScaleY_0">
                        <Spinner modifiers="mini" />
                      </Column>
                    )}
                    {showClearButton && (
                      <InputField.ActionButton
                        icon="times"
                        type="button"
                        onClick={(e) => this.onClearButtonClick(e, hide)}
                        modifiers={['hoverDanger', 'padScaleX_0']}
                      />
                    )}
                    <InputField.ActionButton
                      icon={isVisible ? 'chevron-up' : 'chevron-down'}
                      type="button"
                      onClick={(e) => e.stopPropagation() || toggle()}
                      modifiers={['hoverInfo']}
                    />
                  </Row>
                </Column>
              </InputField>
            </Container>
            <Dropdown.Content>
              <DropdownList
                options={options}
                highlightText={highlightText}
                optionRenderer={optionRenderer}
              />
            </Dropdown.Content>
          </>
        )}
      </Dropdown>
    );
  }
}

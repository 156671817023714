/**
 * This HOC is used to convert the immutable objects held in the redux store into plain JS for
 * our React Components. For more information about this, please see this page in the redux docs:
 * http://redux.js.org/docs/recipes/UsingImmutableJS.html
 *
 * This HOC was adapted from these docs.
 */

import { Iterable } from 'immutable';
import React from 'react';

const immutableToJS = (WrappedComponent) => (wrappedComponentProps) => {
  const propsJS = Object.entries(wrappedComponentProps).reduce(
    (newProps, [key, value]) => ({
      ...newProps,
      [key]: Iterable.isIterable(value) ? value.toJS() : value,
    }),
    {},
  );

  return <WrappedComponent {...propsJS} />;
};

export default immutableToJS;

import React from 'react';
import { t, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';
import { Row, Column } from 'styled-components-grid';
import { Text, Icon } from 'base-components';
import { buildStyledComponent } from 'decisiv-ui-utils';

import ShortcutBadge from './ShortcutBadge';

const Container = buildStyledComponent(
  'KeyboardShortcuts_FooterContainer',
  styled(Row),
  ({ theme }) => css`
    background-color: ${theme.colors.base.chrome100};
    border-top: solid 1px ${theme.colors.base.chrome200};
  `,
  {
    themePropTypes: {
      colors: PropTypes.shape({
        base: PropTypes.shape({
          background: PropTypes.string.isRequired,
          chrome200: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    },
  },
);

const FooterShortcut = ({ label, icons, shortcut }) => {
  return (
    <Column modifiers={['padScale_2']}>
      <Row modifiers={'middle'}>
        {icons?.length > 0 ? (
          icons.map((icon) => (
            <Column key={icon} modifiers={['padScaleY_0']}>
              <ShortcutBadge modifiers={['dark']}>
                <Icon name={icon} modifiers={['mini', 'textLight']} />
              </ShortcutBadge>
            </Column>
          ))
        ) : (
          <Column modifiers={['padScaleY_0']}>
            <ShortcutBadge modifiers={['dark']}>
              <ShortcutBadge.Text modifiers={['dark']}>
                {shortcut}
              </ShortcutBadge.Text>
            </ShortcutBadge>
          </Column>
        )}
        <Column
          modifiers={['padScaleY_0']}
          style={{
            position: 'relative',
            bottom: '2px',
          }}
        >
          <Text modifiers={['small']}>
            <Trans id={label} />
          </Text>
        </Column>
      </Row>
    </Column>
  );
};

FooterShortcut.propTypes = {
  label: PropTypes.string.isRequired,
  shortcut: PropTypes.string,
  icons: PropTypes.arrayOf(PropTypes.string),
};

function Footer() {
  return (
    <Container>
      <FooterShortcut label={t`to select`} shortcut="Enter" />
      <FooterShortcut
        label={t`to navigate`}
        icons={['arrow-down', 'arrow-up']}
      />
      <FooterShortcut label={t`to close`} shortcut="Esc" />
      <FooterShortcut label={t`to go back`} shortcut="Backspace" />
    </Container>
  );
}

export default Footer;

import gql from 'graphql-tag';

const query = gql`
  query searchDealersQuery(
    $searchString: String!
    $excludeMrtAssociates: Boolean
  ) {
    searchDealers(
      input: {
        query: $searchString
        excludeMrtAssociates: $excludeMrtAssociates
      }
    ) {
      dealers {
        id
        name
        displayAddress {
          city
          province
        }
        shipTo
        billTo
      }
    }
  }
`;

export default query;

import moment from 'moment-timezone';
import { get, groupBy, orderBy } from 'lodash';
import produce from 'immer';

import { CASE_STATUS } from 'compositions/CaseStatus/constants';

/**
 * Merges the status history records so that only the latest entry
 * for given statuses remain in the full history.
 */
export function mergeUniqByStatus(statusHistory, statuses) {
  const orderedHistory = orderBy(statusHistory, 'changedAt', 'desc');

  return orderedHistory.reduce((fullHistory, statusUpdate) => {
    if (statuses.includes(statusUpdate.newStatus)) {
      return produce(fullHistory, (draft) => {
        let entryIndex = draft.findIndex(
          (entry) => entry.newStatus === statusUpdate.newStatus,
        );

        if (entryIndex < 0) {
          entryIndex = draft.length;
          /* eslint-disable no-param-reassign */
          draft[entryIndex] = { ...statusUpdate };
          draft[entryIndex].history = [];
          /* eslint-enable no-param-reassign */
        }

        // Keep all same-status entries in the last entry's own history
        draft[entryIndex].history.push(statusUpdate);
      });
    }

    return fullHistory.concat(statusUpdate);
  }, []);
}

/**
 * The main vehicle rolling status update needs
 * to be when user has actually put the case into
 * vehicle rolling status, not when user closes
 * and re-opens case.
 */

export function fixRollingStatuses(statusHistory) {
  return produce(statusHistory, (draft) => {
    const rollingStatusIndex = draft.findIndex(
      (entry) => entry.newStatus === CASE_STATUS.rolling,
    );

    if (rollingStatusIndex < 0) return;

    const rollingStatus = draft[rollingStatusIndex];

    if (
      rollingStatus.oldStatus === CASE_STATUS.closed &&
      get(rollingStatus, 'history', []).length > 0
    ) {
      const { history } = rollingStatus;

      /* eslint-disable no-param-reassign */
      draft[rollingStatusIndex] = orderBy(history, 'changedAt', 'desc').find(
        (entry) => entry.oldStatus !== CASE_STATUS.closed,
      );
      draft[rollingStatusIndex].history = history;
      /* eslint-enable no-param-reassign */
    }
  });
}

/**
 * Groups the status history records into buckets by day, with the
 * day groups sorted in descending order, and also with individual
 * status updates within each day sorted in descending order.
 */
export function groupAndSortStatuses(statusHistory) {
  return groupBy(orderBy(statusHistory, 'changedAt', 'desc'), (n) =>
    moment(n.changedAt).startOf('day').format(),
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';

import { H3, P } from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Container, Row, Column } from 'styled-components-grid';

import { pinIconForType } from 'assets/images/pins';
import { useCaseShortcut, CASE_SHORTCUT_PANELS } from 'features/keyShortcuts';

const assetLocationKeys = ['highway', 'mileMarker', 'note', 'searchValue'];

export const checkIfEnabled = (assetLocation) =>
  assetLocationKeys.some((key) => assetLocation[key]);

export function AssetLocation({ assetLocation }) {
  const { highway, mileMarker, note, searchValue } = assetLocation;
  const isEnabled = checkIfEnabled(assetLocation);
  const pinIcon = pinIconForType(isEnabled ? 'asset' : 'assetDisabled');

  const { onFocusRequested } = useCaseShortcut({
    parent: CASE_SHORTCUT_PANELS.assetLocation,
    id: 'goToAssetLocationAction',
    name: t`Go to Asset Location`,
    shortcut: ['a', 'l', '0'],
    priority: 4,
    icon: 'arrow-right',
  });

  return (
    <Container modifiers="padScale_3">
      <Row ref={onFocusRequested}>
        <H3 modifiers="fontWeightRegular">
          <Trans>Asset Location</Trans>
        </H3>
      </Row>
      <Row style={{ marginTop: px2rem(12) }}>
        <Column modifiers="padScale_0">
          <img src={pinIcon} alt="" />
        </Column>
        <Column modifiers={['col', 'padScaleX_2']}>
          {isEnabled ? (
            <>
              <P>{searchValue}</P>
              <P modifiers="textLight">
                {highway && <Trans>Highway {highway}</Trans>}
                {highway && mileMarker && <span>&nbsp;&middot;&nbsp;</span>}
                {mileMarker && <Trans>Mile Marker {mileMarker}</Trans>}
              </P>
              <P modifiers="textLight">{note}</P>
            </>
          ) : (
            <P>&mdash;</P>
          )}
        </Column>
      </Row>
    </Container>
  );
}

AssetLocation.propTypes = {
  assetLocation: PropTypes.shape({
    highway: PropTypes.string,
    mileMarker: PropTypes.string,
    note: PropTypes.string,
    searchValue: PropTypes.string,
  }),
};

AssetLocation.defaultProps = {
  assetLocation: {},
};

export default AssetLocation;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Trans } from '@lingui/macro';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import { Text, Popover } from 'base-components';
import { Container } from 'styled-components-grid';

import Tag from 'blocks/Tag';

import Newline2Br from 'elements/Newline2Br';

const PopoverContent = buildStyledComponent(
  'PopoverContent',
  styled(Popover.Content),
  `
    width: ${px2rem(250)};
    /* To align the arrow with the info icon */
    margin-left: ${px2rem(-22)};
  `,
);

function NotesPopover({ notes, position }) {
  return (
    <Popover position={position} showOnHover arrow>
      <Popover.Target>
        <Tag modifiers="inline">
          <Tag.Icon modifiers={['mini', 'info']} name="info-circle" />
          <Tag.Text>
            <Trans>Notes</Trans>
          </Tag.Text>
        </Tag>
      </Popover.Target>
      <PopoverContent zIndex={10}>
        <Container modifiers="padScale_3">
          <Text>
            <Newline2Br value={notes} />
          </Text>
        </Container>
      </PopoverContent>
    </Popover>
  );
}

NotesPopover.propTypes = {
  notes: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
};

export default NotesPopover;

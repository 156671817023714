import { PANEL_STATUSES } from 'compositions/CaseStatus';

import { fieldIds, PAYMENT_METHODS } from '../constants';

const { incomplete, partial, complete } = PANEL_STATUSES;
const {
  THIRD_PARTY_CREDIT_CARD_TXN: cc,
  CASH: cash,
  COM_CHECK: comCheck,
  NONE: continueWithoutPayment,
  OTHER_NATIONAL_ACCOUNT: otherNatAccount,
  CREDIT_CARD_NO_PREAUTH: noPreAuth,
} = PAYMENT_METHODS;

// From https://docs.google.com/document/d/1g442usX08QWB0AfveSitIHKnlFfiq7Zb3eNeZvMA62Y/edit
//
// Selected “credit card” payment method, and entered transaction receipt (at least 1 digit/character), or
// Selected “cash” payment method, or
// Selected “COMcheck” payment method, or
// Selected “Other National Account” payment method, or
// Selected “continue without a payment method”

export default ({
  setCasePanelStatus,
  paymentMethod: method,
  thirdPartyTransactionDetails,
}) => {
  let newStatus = method ? partial : incomplete;
  const fields = {};

  if (
    [
      method === cc && !!thirdPartyTransactionDetails,
      method === cash,
      method === comCheck,
      method === otherNatAccount,
      method === continueWithoutPayment,
      method === noPreAuth,
    ].some(Boolean)
  ) {
    newStatus = complete;
  }

  if (newStatus === partial) fields[fieldIds.other.receipt] = 'missing';

  setCasePanelStatus(newStatus, fields);
};

import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import { Column } from 'styled-components-grid';
import { px2rem } from 'decisiv-ui-utils';
import { Dropdown, ButtonLink, DateRangeSelector } from 'base-components';

function Calendar(props) {
  const { startDate, endDate, onChange, onClear, onApply } = props;

  const selectedRange = { from: startDate, to: endDate };
  const hasAnyDate = !!startDate || !!endDate;

  return (
    <div style={{ marginTop: -15 }}>
      <DateRangeSelector
        onSelectDate={onChange}
        disabledDates={{ after: new Date() }}
        selectedDateRange={selectedRange}
      />
      <Dropdown.SectionBody style={{ paddingTop: 0, marginTop: px2rem(-5) }}>
        <Column modifiers={['col', 'start', 'padScale_0']}>
          <ButtonLink
            type="button"
            onClick={onClear}
            disabled={!hasAnyDate}
            modifiers={!hasAnyDate && 'disabled'}
          >
            <Trans>Clear</Trans>
          </ButtonLink>
        </Column>
        <Column modifiers={['end', 'padScale_0']}>
          <ButtonLink type="button" onClick={onApply}>
            <Trans>Apply</Trans>
          </ButtonLink>
        </Column>
      </Dropdown.SectionBody>
    </div>
  );
}

Calendar.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  onApply: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default React.memo(Calendar);

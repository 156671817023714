import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { has } from 'lodash';

import { Text } from 'base-components';

import MiniCaseStatusBadge from 'elements/CaseStatusBadge/Mini';

import { messageForStatus } from './constants';

export default function StatusUpdateText({ type, caseNumber, showMessage }) {
  if (showMessage && !has(messageForStatus, type)) {
    return null;
  }

  return (
    <>
      {showMessage && (
        <Text>
          &nbsp;
          <Trans id={messageForStatus[type]} values={{ caseNumber }} />
          &nbsp;
        </Text>
      )}
      <MiniCaseStatusBadge status={type} />
    </>
  );
}

StatusUpdateText.propTypes = {
  type: PropTypes.string.isRequired,
  caseNumber: PropTypes.string,
  showMessage: PropTypes.bool,
};

StatusUpdateText.defaultProps = {
  caseNumber: null,
  showMessage: true,
};

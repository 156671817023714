import PropTypes from 'prop-types';
import React from 'react';

import images from 'assets/images';

import IconBox from './IconBox';

function SquareIcon({ code, modifiers }) {
  const icon = images.dealerFeatures.oncallIconImgForCode(code);

  return (
    <IconBox modifiers={modifiers}>
      <img src={icon} alt={code} />
    </IconBox>
  );
}

SquareIcon.propTypes = {
  code: PropTypes.string.isRequired,
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

SquareIcon.defaultProps = {
  modifiers: [],
};

export default SquareIcon;

import React from 'react';
import { Query } from '@apollo/client/react/components';
import { curry, get } from 'lodash';

import { CREDIT_CARD_RULE_TYPES } from './constants';

import caseCustomerGQL from './caseCustomerQuery';

export function skipQuery({ caseNumber }) {
  return !caseNumber;
}

export function buildQueryVariables({ caseNumber }) {
  return { caseNumber };
}

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const customer = get(queryProps, ['data', 'case', 'customer']);
    const creditCardRuleAbbreviation = get(queryProps, [
      'data',
      'case',
      'customer',
      'billingPreferences',
      'creditCardRuleAbbreviation',
    ]);
    const caseId = get(queryProps, ['data', 'case', 'id']);
    const paymentMethod = get(queryProps, ['data', 'case', 'paymentMethod']);

    return (
      <WrappedComponent
        {...componentProps}
        caseId={caseId}
        customer={customer}
        creditCardRuleAbbreviation={
          creditCardRuleAbbreviation || CREDIT_CARD_RULE_TYPES.N
        }
        paymentMethod={paymentMethod}
      />
    );
  },
);

const withCaseCustomer = (WrappedComponent) => (componentProps) => (
  <Query
    fetchPolicy="cache-and-network"
    query={caseCustomerGQL}
    skip={skipQuery(componentProps)}
    variables={buildQueryVariables(componentProps)}
  >
    {buildQueryChild(WrappedComponent, componentProps)}
  </Query>
);

export default withCaseCustomer;

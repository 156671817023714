import gql from 'graphql-tag';

export default gql`
  query customerPanel_searchCustomersQuery(
    $searchString: String
    $billTo: String
  ) {
    searchCustomers(filters: { searchString: $searchString, billTo: $billTo }) {
      billTo
      city
      id
      name
      shipTo
      state
      terminalNumber
    }
  }
`;

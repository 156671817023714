import { get } from 'lodash';
import { ApolloLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';

import observeOperation from 'features/apolloOperationsObserver';

/**
 * A custom Apollo link middleware that tracks and notifies
 * about grapqhQL operations lifecycle status.
 * @type {ApolloLink}
 */
const operationObserverLink = new ApolloLink((operation, forward) => {
  const definition = getMainDefinition(operation.query);

  if (['query', 'mutation'].includes(get(definition, 'operation'))) {
    return observeOperation(operation, forward);
  }

  return forward(operation);
});

export default operationObserverLink;

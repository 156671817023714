import React from 'react';
import { useQuery, gql } from '@apollo/client';

const query = gql`
  query withCaseInboundProgramNumberQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      inboundProgramNumber(useCachedDataForCase: true) {
        id
        billTo
      }
    }
  }
`;

const withCaseInboundProgramNumber = (Component) => (cProps) => {
  const { caseNumber } = cProps;

  const { data } = useQuery(query, {
    skip: !caseNumber,
    variables: { caseNumber },
  });

  const inboundProgramNumber = data?.case?.inboundProgramNumber;

  return <Component {...cProps} inboundProgramNumber={inboundProgramNumber} />;
};

export default withCaseInboundProgramNumber;

import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import TabList from '../../blocks/TabList';

import Content from './Content';
import List from './List';
import Menu from './Menu';
import Wrapper from './Wrapper';

import { TOGGLE_TAB_CONTEXT } from './constants';

class Tabs extends Component {
  static Content = Content;

  static Menu = Menu;

  static List = List;

  static ListItem = TabList.Item;

  static ListItemIcon = TabList.Icon;

  static ListItemLink = TabList.ItemLink;

  static ListItemButton = TabList.ItemButton;

  static childContextTypes = {
    [TOGGLE_TAB_CONTEXT]: PropTypes.shape({}).isRequired,
  };

  static propTypes = {
    activeTab: PropTypes.number,
    children: PropTypes.node.isRequired,
    onTabChange: PropTypes.func,
  };

  static defaultProps = {
    activeTab: 0,
    onTabChange: noop,
  };

  state = {
    activeTab: this.props.activeTab,
  };

  getChildContext() {
    return {
      [TOGGLE_TAB_CONTEXT]: {
        activeTab: this.state.activeTab,
        handleTabClick: this.toggleTab,
      },
    };
  }

  toggleTab = (e, i) => {
    this.props.onTabChange(e, i);
    this.setState({ activeTab: i });
  };

  render() {
    const { onTabChange, ...rest } = this.props;

    return <Wrapper {...rest}>{this.props.children}</Wrapper>;
  }
}

export default Tabs;

import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Trans } from '@lingui/macro';

import { Text } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import EmptyCell from 'compositions/CasesDataTable/EmptyCell';
import HeaderCell from 'compositions/CasesDataTable/HeaderCell';
import { CallButtonLink } from 'compositions/ClickToCall';
import { buildBaseColumnConfig } from 'compositions/CasesDataTable/utils';
import { COLUMN_NAMES } from 'compositions/CasesDataTable/constants';

function DataCell({ servicingDealer }) {
  if (!servicingDealer) {
    return <EmptyCell />;
  }

  return (
    <Container modifiers="padScaleY_2">
      <Row>
        <Column modifiers={['col', 'padScaleY_0']}>
          <Text>{servicingDealer.contactPerson}</Text>
        </Column>
      </Row>
      <Row>
        <Column modifiers={['col', 'padScaleY_0']}>
          <CallButtonLink
            name={servicingDealer.contactPerson}
            phone={servicingDealer.phoneNumber}
          />
        </Column>
      </Row>
    </Container>
  );
}

DataCell.propTypes = {
  servicingDealer: PropTypes.shape({
    contactPerson: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
  }),
};

DataCell.defaultProps = { servicingDealer: undefined };

export default (...args) => ({
  ...buildBaseColumnConfig(COLUMN_NAMES.dealer, ...args),
  cellDataGetter: (data) => ({
    servicingDealer: get(data, 'node.servicingDealer'),
  }),
  headerCellRenderer: (props) => (
    <HeaderCell {...props} label={<Trans>Service Provider Contact</Trans>} />
  ),
  dataCellRenderer: DataCell,
});

import React, { Component, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { Tabs } from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';

import { CaseStatusContext } from 'compositions/CaseStatus';

import withFocusReceiver from 'setup/FocusProvider/withFocusReceiver';
import {
  scrollToElement,
  scrollToAndHighlightElement,
} from 'setup/FocusProvider';

import withContext from 'utils/withContext';

import RequestedLinesTable from './RequestedLinesTable';
import AgreedLinesTable from './AgreedLinesTable';
import SuppliedLinesTable from './SuppliedLinesTable';
import CaseRequestsPanelContext from '../CaseRequestsPanelContext';
import { requestsTabIndices } from '../CaseRequestsPanelContext/constants';

// request.lines.[type].[index].[fieldName]
const requestLinesFocusRegex = /request\.lines\.([^.]+)\.([^.]+)\.([^.]+)/;

const TableWrapper = forwardRef(({ children, ...rest }, ref) => (
  <Row ref={ref} style={{ width: '100%' }} {...rest}>
    <Column
      style={{ paddingBottom: px2rem(8) }}
      modifiers={['col', 'padScaleX_0', 'padScaleY_4']}
    >
      {children}
    </Column>
  </Row>
));

TableWrapper.propTypes = { children: PropTypes.node.isRequired };

class CaseRequestLines extends Component {
  static propTypes = {
    caseStatus: PropTypes.string.isRequired,
    activeRequestsTab: PropTypes.number.isRequired,
    setActiveRequestsTab: PropTypes.func.isRequired,
    shouldShowAgreedTab: PropTypes.bool.isRequired,
    shouldShowSuppliedTab: PropTypes.bool.isRequired,
    onFocusRequested: PropTypes.func.isRequired,
    isFeatureEnabled: PropTypes.func.isRequired,
  };

  tableContainers = {};

  componentDidMount() {
    this.props.onFocusRequested(this.changeTabFromFocusRequest);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { activeRequestsTab } = nextProps;
    const { activeRequestsTab: currentTab } = this.props;

    if (this.tabsInstance && activeRequestsTab !== currentTab) {
      this.tabsInstance.toggleTab({}, activeRequestsTab);
    }
  }

  componentDidUpdate() {
    this.props.onFocusRequested(this.changeTabFromFocusRequest);
  }

  componentWillUnmount() {
    clearTimeout(this.focusTimeout);
  }

  highlightTableRow = (rowsType, rowId) => {
    const containerEl = this.tableContainers[rowsType];

    if (!containerEl) return;

    const query = `table tbody td[data-row-id="${rowId}"]`;
    const firstCell = containerEl.querySelector(query);

    if (firstCell) scrollToAndHighlightElement(firstCell.parentElement);
  };

  changeTabFromFocusRequest = ({ id, requestFocusOn }) => {
    if (!id.startsWith('request.lines.')) return;

    clearTimeout(this.focusTimeout);

    const { activeRequestsTab } = this.props;
    const [, type, rowId, field] = id.match(requestLinesFocusRegex);
    const requestedTabIndex = requestsTabIndices[type];

    const focus = () => {
      field === 'all'
        ? this.highlightTableRow(type, rowId)
        : requestFocusOn(id);
    };

    if (activeRequestsTab !== requestedTabIndex) {
      if (this.tabsInstance) this.tabsInstance.toggleTab({}, requestedTabIndex);

      scrollToElement(this.tabsContainer);

      this.focusTimeout = setTimeout(focus, 200);
    } else {
      focus();
    }
  };

  saveTabsRef = (instance) => (this.tabsInstance = instance);
  saveTabsContainerRef = (el) => (this.tabsContainer = el);
  saveTableContainerRef = (type) => (el) => (this.tableContainers[type] = el);

  render() {
    const { isFeatureEnabled } = this.props;
    const { shouldShowAgreedTab, shouldShowSuppliedTab } = this.props;
    const { caseStatus, activeRequestsTab, setActiveRequestsTab } = this.props;

    const isReadOnlyCase =
      activeRequestsTab === requestsTabIndices.supplied
        ? !isFeatureEnabled('manageSuppliedLines')
        : !isFeatureEnabled('manageRequestLines');

    const tableData = { ...this.props, isReadOnlyCase };

    if (!shouldShowAgreedTab && !shouldShowSuppliedTab) {
      return (
        <TableWrapper ref={this.saveTableContainerRef('requested')}>
          <RequestedLinesTable {...tableData} />
        </TableWrapper>
      );
    }

    return (
      <Row ref={this.saveTabsContainerRef}>
        <Column modifiers={['col', 'padScaleX_2', 'padScaleY_0']}>
          <Tabs
            // Force remount when the case status changes,
            // in order to force the correct activeTab.
            key={caseStatus}
            ref={this.saveTabsRef}
            activeTab={activeRequestsTab}
            onTabChange={(_, index) => setActiveRequestsTab(index)}
          >
            <Tabs.Menu>
              <Tabs.List>
                <Tabs.ListItem>
                  <Tabs.ListItemButton aria-label="Requested lines">
                    <Trans>Requested</Trans>
                  </Tabs.ListItemButton>
                </Tabs.ListItem>
                {shouldShowAgreedTab ? (
                  <Tabs.ListItem>
                    <Tabs.ListItemButton aria-label="Agreed lines">
                      <Trans>Agreed</Trans>
                    </Tabs.ListItemButton>
                  </Tabs.ListItem>
                ) : (
                  <Tabs.ListItem />
                )}
                {shouldShowSuppliedTab ? (
                  <Tabs.ListItem>
                    <Tabs.ListItemButton aria-label="Agreed lines">
                      <Trans>Supplied</Trans>
                    </Tabs.ListItemButton>
                  </Tabs.ListItem>
                ) : (
                  <Tabs.ListItem />
                )}
              </Tabs.List>
            </Tabs.Menu>
            <Tabs.Content>
              <TableWrapper ref={this.saveTableContainerRef('requested')}>
                <RequestedLinesTable {...tableData} />
              </TableWrapper>
              {shouldShowAgreedTab && (
                <TableWrapper ref={this.saveTableContainerRef('agreed')}>
                  <AgreedLinesTable {...tableData} />
                </TableWrapper>
              )}
              {shouldShowSuppliedTab && (
                <TableWrapper ref={this.saveTableContainerRef('supplied')}>
                  <SuppliedLinesTable {...tableData} />
                </TableWrapper>
              )}
            </Tabs.Content>
          </Tabs>
        </Column>
      </Row>
    );
  }
}

export default compose(
  setDisplayName('CaseRequestLines'),
  withContext(CaseRequestsPanelContext),
  withContext(CaseStatusContext),
  withFocusReceiver('*'),
)(CaseRequestLines);

import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, Form } from 'formik';

import { Column, Row } from 'styled-components-grid';

import RelatedAssets from './RelatedAssets';
import RequestAsset from './RequestAsset';

const FormComponent = ({ handleBlur, handleChange, values, setFieldValue }) => (
  <Form>
    <Row modifiers="start">
      <Column modifiers="col_4" style={{ paddingLeft: 0 }}>
        <RequestAsset
          name="asset"
          index={0}
          values={values}
          handleBlur={handleBlur}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
        />
      </Column>
      <FieldArray name="relatedAssets" render={RelatedAssets} />
    </Row>
  </Form>
);

FormComponent.propTypes = {
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({
    asset: PropTypes.shape({}),
    relatedAssets: PropTypes.arrayOf(PropTypes.object),
  }),
};

FormComponent.defaultProps = { values: {} };

export default FormComponent;

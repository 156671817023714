import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ReactiveContainer, Row } from 'styled-components-grid/reactive';
import { withSize } from 'reactive-container';

import Month from './Month';
import toRenderProps from '../../../utils/toRenderProps';
import { buildDisplayMonth } from '../utils';

const MIN_CALENDAR_WIDTH = 344;

const BREAKPOINTS = [{ name: 'LG', minWidth: MIN_CALENDAR_WIDTH * 2 }];

const WithSize = toRenderProps(withSize);

class Calendars extends Component {
  static propTypes = {
    disabledDates: PropTypes.shape({
      after: PropTypes.instanceOf(Date),
      before: PropTypes.instanceOf(Date),
    }),
    multiMonth: PropTypes.bool,
    multiMonthDirection: PropTypes.oneOf(['past', 'future']),
    onSelectDate: PropTypes.func.isRequired,
    range: PropTypes.bool,
    selectedDate: PropTypes.instanceOf(Date),
    selectedDateRange: PropTypes.shape({
      from: PropTypes.instanceOf(Date),
      to: PropTypes.instanceOf(Date),
    }),
    initialVisibleDate: PropTypes.instanceOf(Date),
  };

  static defaultProps = {
    disabledDates: {},
    multiMonth: false,
    multiMonthDirection: 'future',
    range: false,
    selectedDate: null,
    selectedDateRange: {},
    initialVisibleDate: null,
  };

  static displayName = 'Calendars';

  constructor(props) {
    super(props);

    const {
      range,
      selectedDate,
      selectedDateRange,
      initialVisibleDate,
    } = props;

    const today = new Date();

    const visibleDate =
      initialVisibleDate ||
      (range ? selectedDateRange.from : selectedDate) ||
      today;

    const referenceMonth = buildDisplayMonth(visibleDate);

    this.state = {
      displayMonthsArr: this.buildDisplayMonthsArr(referenceMonth),
      hoveredOverDate: null,
      today: {
        year: today.getFullYear(),
        month: today.getMonth() + 1,
        date: today.getDate(),
      },
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Clear the value of hoveredOverDate when a "to" selection is made
    if (
      this.props.range &&
      nextProps.selectedDateRange.to &&
      !this.props.selectedDateRange.to
    ) {
      this.setState({ hoveredOverDate: null });
    }
  }

  get currentReferenceMonth() {
    return this.state.displayMonthsArr[
      this.props.multiMonthDirection === 'future' ? 0 : 1
    ];
  }

  /**
   * Builds an array of display months reflecting the months that may currently be displayed.
   */
  buildDisplayMonthsArr = (referenceMonth) => {
    const { multiMonth, multiMonthDirection } = this.props;

    if (multiMonth) {
      const expandRight = multiMonthDirection === 'future';
      const monthOffset = expandRight ? 1 : -1;
      const otherMonth = buildDisplayMonth(referenceMonth, monthOffset);

      if (expandRight) {
        return [referenceMonth, otherMonth];
      }

      return [otherMonth, referenceMonth];
    }

    return [referenceMonth];
  };

  /**
   * Handles the navigation to earlier months.
   */
  displayPreviousMonth = () => {
    const month = buildDisplayMonth(this.currentReferenceMonth, -1);

    this.setState({ displayMonthsArr: this.buildDisplayMonthsArr(month) });
  };

  /**
   * Handles the navigation to later months
   */
  displayNextMonth = () => {
    const month = buildDisplayMonth(this.currentReferenceMonth, 1);

    this.setState({ displayMonthsArr: this.buildDisplayMonthsArr(month) });
  };

  /**
   * Handles updating the hoveredOverDate when the user moves the cursor
   * @param  {Date} date the date the cursor is currently hovered over
   */
  updateHoveredOverDate = (date) => {
    if (this.props.range && !this.props.selectedDateRange.to) {
      this.setState({ hoveredOverDate: date });
    }
  };

  /**
   * Maps over the available displayMonths and returns the visible Months.
   */
  buildMonths = ({ size }) => {
    const isSmall = size === 'XS';
    const hiddenIndex = this.props.multiMonthDirection === 'future' ? 1 : 0;

    const finalMonths = isSmall
      ? this.state.displayMonthsArr.filter((_, i) => i !== hiddenIndex)
      : this.state.displayMonthsArr;

    return (
      <Row style={{ flexWrap: 'nowrap' }}>
        {finalMonths.map((displayMonth, index) => (
          <Month
            key={displayMonth.month}
            disabledDates={this.props.disabledDates}
            displayMonth={displayMonth}
            displayNextNav={index === finalMonths.length - 1}
            displayPreviousNav={index === 0}
            hoveredOverDate={this.state.hoveredOverDate}
            onClickNext={this.displayNextMonth}
            onClickPrevious={this.displayPreviousMonth}
            onSelectDate={this.props.onSelectDate}
            range={this.props.range}
            selectedDate={this.props.selectedDate}
            selectedDateRange={this.props.selectedDateRange}
            today={this.state.today}
            updateHoveredOverDate={this.updateHoveredOverDate}
          />
        ))}
      </Row>
    );
  };

  render() {
    return (
      <ReactiveContainer
        breakpoints={BREAKPOINTS}
        className="CalendarsContainer"
        modifiers={['fluid']}
      >
        <WithSize>{this.buildMonths}</WithSize>
      </ReactiveContainer>
    );
  }
}

export default Calendars;

import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import MomentPropTypes from 'react-moment-proptypes';
import { compose, setDisplayName } from 'recompose';

import withContext from 'utils/withContext';

import ScheduleGrid from './ScheduleGrid';
import processEvents from './processEvents';
import withCalendarEvents from './withCalendarEvents';
import DealerDetailsContext from '../DealerDetailsContext';

/**
 * Renders a single day into the grid, containing all of the events of the day.
 */
function ScheduleDay({ data, loading, day, isLastDay, dayIndex, isReadOnly }) {
  const events = processEvents(day, get(data, 'calendarEvents.events') || []);

  return (
    <ScheduleGrid.Day day={day} last={isLastDay} loading={loading}>
      {events.map((event) => (
        <event.component
          key={event.key}
          day={day}
          event={event}
          dayIndex={dayIndex}
          isReadOnly={isReadOnly}
        />
      ))}
    </ScheduleGrid.Day>
  );
}

ScheduleDay.propTypes = {
  day: PropTypes.oneOfType([
    MomentPropTypes.momentString,
    MomentPropTypes.momentObj,
  ]).isRequired,
  data: PropTypes.shape({
    calendarEvents: PropTypes.shape({
      events: PropTypes.arrayOf(
        PropTypes.shape({
          start: PropTypes.string.isRequired,
          end: PropTypes.string.isRequired,
          __typename: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  }),
  isLastDay: PropTypes.bool,
  loading: PropTypes.bool,
  dayIndex: PropTypes.number.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};

ScheduleDay.defaultProps = {
  data: {},
  isLastDay: false,
  loading: false,
};

export default compose(
  setDisplayName('ScheduleDay'),
  withCalendarEvents,
  withContext(DealerDetailsContext),
)(ScheduleDay);

import React from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { get, isEmpty } from 'lodash';

import { DataTable, H3, Text } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import DealerResponseStatusIndicator from 'compositions/CaseDealerSelectionPanel/DealerResponses/DealerResponseStatusIndicator';

import columns from './columns';

// These strings are different from where this data is displayed in the dealer selection table.
// The word "miles" is added to the tmeplate.
function buildMileageRangeString(mileageRange) {
  if (isEmpty(mileageRange)) {
    return {};
  }

  const { min, max } = mileageRange;

  if (min === 0) {
    return t`< ${max} miles`;
  }

  if (max === null) {
    return t`${min}+ miles`;
  }

  return t`${min} - ${max} miles`;
}

function DealerSelection({ caseData }) {
  const servicingDealer = get(caseData, 'servicingDealer') || {};
  const dealer = get(servicingDealer, 'dealer') || {};
  const { billTo, name, shipTo } = dealer;
  const mileageRange = get(dealer, 'mileageRange') || {};
  const displayAddress = get(dealer, 'displayAddress') || {};
  const { city, postalCode, province, streetAddress } = displayAddress;
  const canDisplayAddress = city || postalCode || province || streetAddress;

  const mileageRangeString = buildMileageRangeString(mileageRange);

  return (
    <Container modifiers={['padScale_2']}>
      <Row>
        <Column modifiers={['padScaleY_2']}>
          <H3 modifiers={['fontWeightRegular']}>
            <Trans>Service Provider Selection</Trans>
          </H3>
        </Column>
      </Row>
      {isEmpty(servicingDealer) ? (
        <Row>
          <Column>
            <Text>&mdash;</Text>
          </Column>
        </Row>
      ) : (
        <>
          <Row>
            <Column>
              <DealerResponseStatusIndicator
                modifiers={['accepted']}
                name="check"
              />
            </Column>
            <Column modifiers={['col', 'padScaleX_2']}>
              <Row>
                <Column modifiers="padScale_0">
                  <Text>{name}</Text>
                </Column>
              </Row>
              {isEmpty(mileageRange) && (
                <Row>
                  <Column modifiers="padScale_0">
                    {canDisplayAddress && (
                      <Text>
                        {streetAddress}
                        {streetAddress && <>&nbsp;</>}
                        {city}
                        {(streetAddress || city) && ', '}
                        {province} {province && <>&nbsp;</>}
                        {postalCode}
                      </Text>
                    )}
                    {canDisplayAddress && (billTo || shipTo) && (
                      <Text>&nbsp;&middot;&nbsp;</Text>
                    )}
                    {billTo && (
                      <Text>
                        <Trans>Bill to #{billTo}</Trans>
                      </Text>
                    )}
                    {billTo && shipTo && <Text>&nbsp;&middot;&nbsp;</Text>}
                    {shipTo && (
                      <Text>
                        <Trans>Ship to #{shipTo}</Trans>
                      </Text>
                    )}
                  </Column>
                </Row>
              )}
              {mileageRange.id && (
                <Row>
                  <Column modifiers="padScale_0">
                    <Text>
                      <Trans id={mileageRangeString} />
                    </Text>
                  </Column>
                </Row>
              )}
            </Column>
          </Row>
          <Row>
            <Column modifiers={['col', 'padScaleY_2']}>
              <DataTable
                columns={columns}
                modifiers=""
                rowModifiersGetter={() => ['noHighlightOnHover']}
                rowsRequireRowIndex={false}
                scrollX
                tableData={[servicingDealer]}
              />
            </Column>
          </Row>
        </>
      )}
    </Container>
  );
}

DealerSelection.propTypes = {
  caseData: PropTypes.shape({
    servicingDealer: PropTypes.shape({
      dealer: PropTypes.shape({
        billTo: PropTypes.string,
        displayAddress: PropTypes.shape({
          city: PropTypes.string,
          postalCode: PropTypes.string,
          province: PropTypes.string,
          streetAddress: PropTypes.string,
        }),
        mileageRange: PropTypes.shape({
          min: PropTypes.number,
          max: PropTypes.number,
        }),
        name: PropTypes.string,
        shipTo: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default DealerSelection;

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { px2rem, buildStyledComponent } from 'decisiv-ui-utils';

import Icon from './Icon';
import Text from './Text';

const modifierConfig = {
  active: ({ theme }) => ({
    styles: `
      background-color: ${theme.colors.base.linkHover};
      color: ${theme.colors.base.chrome000};
      border-color: transparent;
    `,
  }),
  bgTransparent: () => ({
    styles: `
      background-color: transparent;
      border-color: transparent;
    `,
  }),
  disabled: ({ theme }) => ({
    styles: `
      opacity: 0.5;
      pointer-events: none;
      color: ${theme.colors.base.chrome500};
    `,
  }),
  hoverSuccess: ({ theme }) => ({
    styles: `
      &:hover {
        background-color: ${theme.colors.status.success};
        border-color: transparent;
      }
    `,
  }),
  hoverTransparent: () => ({
    styles: `
      &:hover {
        background-color: transparent;
        border-color: transparent;
      }
    `,
  }),
  hoverWarning: ({ theme }) => ({
    styles: `
      &:hover {
        background-color: ${theme.colors.status.warning};
        border-color: transparent;
      }
    `,
  }),
  hoverDanger: ({ theme }) => ({
    styles: `
      &:hover {
        background-color: ${theme.colors.status.danger};
        border-color: transparent;
      }
    `,
  }),
  iconOnly: () => ({
    styles: `
      padding: 0;
      width: ${px2rem(35)};
    `,
  }),
  secondary: ({ theme }) => ({
    styles: `
      background-color: ${theme.colors.base.chrome600};
      color: ${theme.colors.base.chrome000};
      border-color: transparent;
    `,
  }),
};

const styles = ({ theme }) => `
  background-color: ${theme.colors.base.chrome100};
  color: ${theme.colors.base.chrome600};
  border: 1px solid ${theme.colors.base.chrome200};;
  border-radius: ${theme.dimensions.borderRadius};
  cursor: pointer;
  min-height: ${px2rem(25)};
  padding: 0 ${px2rem(20)};
  outline: none;
  transition: all 200ms ease;
  white-space: nowrap;

  &:hover {
    background-color: ${theme.colors.base.linkHover};
    border-color: transparent;
    color: ${theme.colors.base.chrome000};

    span:not(.ix):not(.fa) {
      text-decoration: underline;
    }
  }
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome000: PropTypes.string.isRequired,
      chrome100: PropTypes.string.isRequired,
      chrome200: PropTypes.string.isRequired,
      chrome500: PropTypes.string.isRequired,
      chrome600: PropTypes.string.isRequired,
      linkHover: PropTypes.string.isRequired,
    }).isRequired,
    status: PropTypes.shape({
      success: PropTypes.string.isRequired,
      warning: PropTypes.string.isRequired,
      danger: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dimensions: PropTypes.shape({
    borderRadius: PropTypes.string.isRequired,
  }).isRequired,
};

const QuickActionButton = buildStyledComponent(
  'QuickActionButton',
  styled.button,
  styles,
  { modifierConfig, themePropTypes },
);

QuickActionButton.Icon = Icon;
QuickActionButton.Text = Text;

export default QuickActionButton;

import React from 'react';
import PropTypes from 'prop-types';

import { MessageMedium } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import ResultsContainer from './ResultsContainer';

export default function Message({ title, description }) {
  return (
    <ResultsContainer>
      <Container modifiers={['display_flex', 'height_100']}>
        <Row style={{ width: '100%', alignSelf: 'center' }}>
          <MessageMedium>
            <MessageMedium.Header>
              <MessageMedium.Icon name="search" />
            </MessageMedium.Header>
            <MessageMedium.Section>
              <MessageMedium.Title>{title}</MessageMedium.Title>
            </MessageMedium.Section>
            {description && (
              <MessageMedium.Section>
                <Column modifiers={['col', 'padScaleY_2']}>
                  <MessageMedium.Text>{description}</MessageMedium.Text>
                </Column>
              </MessageMedium.Section>
            )}
          </MessageMedium>
        </Row>
      </Container>
    </ResultsContainer>
  );
}

Message.propTypes = {
  title: PropTypes.node.isRequired,
  description: PropTypes.node,
};

Message.defaultProps = { description: undefined };

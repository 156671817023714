import PropTypes from 'prop-types';

// --------------- PropType extensions specific to the Google Maps API ---------------

// A Google Map latitude & longitude pair (with `lat` and `lng` numeric values).
const latLng = PropTypes.shape({
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
});

// `PropTypes.googleMapsApi` defines the shape of the Google Maps API, or at least
// the parts of the API that we use within this application.
const googleMapsApi = PropTypes.shape({
  DirectionsService: PropTypes.func.isRequired,
  DirectionsStatus: PropTypes.shape({
    OK: PropTypes.string.isRequired,
  }).isRequired,
  Geocoder: PropTypes.func.isRequired,
  GeocoderStatus: PropTypes.shape({
    OK: PropTypes.string.isRequired,
  }).isRequired,
  LatLng: PropTypes.func.isRequired,
  TravelMode: PropTypes.shape({
    DRIVING: PropTypes.string.isRequired,
  }).isRequired,
});

const textAndValue = PropTypes.shape({
  text: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
});

const googleDirections = PropTypes.shape({
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      legs: PropTypes.arrayOf(
        PropTypes.shape({
          distance: textAndValue,
          duration: textAndValue,
        }),
      ),
    }),
  ),
  status: PropTypes.string.isRequired,
});

const latLngBounds = PropTypes.shape({
  getCenter: PropTypes.func.isRequired,
});

export default {
  googleMapsApi,
  googleDirections,
  latLng,
  latLngBounds,
};

import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { t, Trans } from '@lingui/macro';

import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';
import { Dropdown, InputField, Text } from 'base-components';

import { labelForReason, ignoredReasons } from './constants';

const selectableReasons = Object.keys(labelForReason).filter(
  (reason) => !ignoredReasons.includes(reason),
);

export function ReasonsSelector({ onChange, selected, disabledReasons }) {
  return (
    <Dropdown
      activeItem={selected}
      fullWidth
      hideOnChange
      onChange={(e, newValue) => onChange(newValue)}
      zIndex={2}
    >
      {({ isVisible }) => (
        <>
          <Dropdown.Target>
            <InputField
              onChange={noop}
              placeholder={t`Select Delay Reason...`}
              value={labelForReason[selected] || ''}
            >
              <Column modifiers={['col', 'padScaleY_0']}>
                <Row>
                  <InputField.Label>Delay Reason</InputField.Label>
                </Row>
                <Row>
                  <InputField.TextField
                    style={{ caretColor: 'transparent', cursor: 'pointer' }}
                    autoComplete="off"
                    autoCorrect="off"
                    spellCheck="false"
                  />
                  <InputField.ActionButton
                    icon={isVisible ? 'chevron-up' : 'chevron-down'}
                    modifiers={['padScaleX_1', 'hoverInfo']}
                    onClick={noop}
                  />
                </Row>
              </Column>
            </InputField>
          </Dropdown.Target>
          <Dropdown.Content
            style={{ maxHeight: px2rem(250), overflowY: 'auto' }}
          >
            <Dropdown.List>
              {selectableReasons.map((key) => (
                <Dropdown.ListItem
                  id={key}
                  key={key}
                  modifiers={
                    key !== selected && disabledReasons.includes(key)
                      ? ['disabled']
                      : undefined
                  }
                >
                  <Text>
                    <Trans id={labelForReason[key] || ''} />
                  </Text>
                </Dropdown.ListItem>
              ))}
            </Dropdown.List>
          </Dropdown.Content>
        </>
      )}
    </Dropdown>
  );
}

ReasonsSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string,
  disabledReasons: PropTypes.arrayOf(PropTypes.string),
};

ReasonsSelector.defaultProps = {
  selected: null,
  disabledReasons: [],
};

export default ReasonsSelector;

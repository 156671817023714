import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { Formik, Form, Field } from 'formik';
import { debounce, get, isEmpty } from 'lodash';
import { compose, setDisplayName } from 'recompose';

import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';
import { ButtonIconRectangle, InputField, InputGroup } from 'base-components';

import withFocusReceiver from 'setup/FocusProvider/withFocusReceiver';
import { CaseShortcut, CASE_SHORTCUT_PANELS } from 'features/keyShortcuts';

import withCustomStoreCustomer from '../../withCustomStoreCustomer';
import withClearCustomerSearchValue from '../../withClearCustomerSearchValue';
import { fieldIds } from '../../constants';

import Error from './Error';

class CustomStoreCustomer extends Component {
  static propTypes = {
    clearCaseCustomer: PropTypes.func.isRequired,
    clearCustomerSearchValue: PropTypes.func.isRequired,
    customer: PropTypes.shape({
      id: PropTypes.string,
    }),
    customerTypes: PropTypes.objectOf(
      PropTypes.shape({ type: PropTypes.string.isRequired }),
    ).isRequired,
    isReadOnlyCase: PropTypes.bool.isRequired,
    isNewCustomStoreCustomer: PropTypes.bool.isRequired,
    onUpdateCustomer: PropTypes.func.isRequired,
    setCasePanelComplete: PropTypes.func.isRequired,
    setCasePanelInvalid: PropTypes.func.isRequired,
    storeCustomerSearchValue: PropTypes.string,
    updateCustomerType: PropTypes.func.isRequired,
    onFocusRequested: PropTypes.func.isRequired,
  };

  static defaultProps = {
    customer: {},
    storeCustomerSearchValue: '',
  };

  state = {
    initialCustomerName: this.props.storeCustomerSearchValue,
  };

  componentDidMount() {
    this.props.clearCustomerSearchValue();
    if (this.props.isNewCustomStoreCustomer) {
      const initialValues = this.getInitialValues();
      if (isEmpty(this.validateForm(initialValues))) {
        this.handleFormSubmit(initialValues);
      }
    }
  }

  getInitialValues = () => {
    if (this.props.customer) {
      return {
        customerName: get(this.props, 'customer.name') || '',
        storeAccountNumber: get(this.props, 'customer.accountNumber') || '',
      };
    }

    return {
      customerName: this.state.initialCustomerName || '',
      storeAccountNumber: '',
    };
  };

  clearCustomStoreCustomer = () => {
    this.props.clearCaseCustomer();
    this.props.updateCustomerType(this.props.customerTypes.STORE.type);
  };

  handleFormSubmit = (values) => {
    this.props.onUpdateCustomer(values);
  };

  // eslint-disable-next-line react/sort-comp
  submitForm = debounce(this.handleFormSubmit, 1000);

  validateForm = (values) => {
    const errors = this.validateValues(values);
    if (isEmpty(errors)) {
      this.props.setCasePanelComplete();
      this.submitForm(values);
    }
    return errors;
  };

  validateValues = (values) => {
    if (!values.customerName) {
      return { customerName: i18n._(t`Fleet Name is required.`) };
    }

    return {};
  };

  render() {
    const { isReadOnlyCase } = this.props;

    return (
      <Row>
        <Column modifiers={['col', 'padScale_0']}>
          <Formik
            enableReinitialize
            initialValues={this.getInitialValues()}
            onSubmit={this.submitForm}
            validate={this.validateForm}
            validateOnBlur={false}
          >
            {({ errors, touched }) => (
              <Form>
                <InputGroup style={{ marginBottom: px2rem(8) }}>
                  <InputGroup.Row>
                    <InputGroup.Column style={{ flex: '1' }}>
                      <Field name="customerName">
                        {({ field }) => (
                          <InputField
                            {...field}
                            autoFocus
                            isValid={
                              touched.customerName && !errors.customerName
                            }
                            placeholder={t`Enter fleet name...`}
                            readOnly={isReadOnlyCase}
                          >
                            <Column modifiers={['col', 'padScaleY_0']}>
                              <Row>
                                <InputField.Label>
                                  <Trans>Fleet Name</Trans>
                                </InputField.Label>
                              </Row>
                              <Row>
                                <CaseShortcut
                                  action={{
                                    parent: CASE_SHORTCUT_PANELS.customer,
                                    id: 'goToCustomerAction',
                                    name: t`Go to Fleet`,
                                    shortcut: ['f', '0'],
                                    priority: 8,
                                    icon: 'arrow-right',
                                  }}
                                >
                                  {({
                                    onFocusRequested: onShortcutFocusRequested,
                                  }) => (
                                    <InputField.TextField
                                      ref={(ref) => {
                                        this.props.onFocusRequested(ref);
                                        onShortcutFocusRequested(ref);
                                      }}
                                    />
                                  )}
                                </CaseShortcut>
                              </Row>
                            </Column>
                          </InputField>
                        )}
                      </Field>
                    </InputGroup.Column>
                  </InputGroup.Row>
                  <InputGroup.Row>
                    <InputGroup.Column style={{ flex: '1' }}>
                      <Field name="storeAccountNumber">
                        {({ field }) => (
                          <InputField
                            {...field}
                            isValid={
                              touched.storeAccountNumber &&
                              !errors.storeAccountNumber
                            }
                            placeholder={t`Enter number...`}
                            readOnly={isReadOnlyCase}
                          >
                            <Column modifiers={['col', 'padScaleY_0']}>
                              <Row>
                                <InputField.Label>
                                  <Trans>Store Account Number (Optional)</Trans>
                                </InputField.Label>
                              </Row>
                              <Row>
                                <InputField.TextField />
                              </Row>
                            </Column>
                          </InputField>
                        )}
                      </Field>
                    </InputGroup.Column>
                  </InputGroup.Row>
                  <Error
                    errors={errors}
                    setCasePanelInvalid={this.props.setCasePanelInvalid}
                    touched={touched}
                  />
                </InputGroup>
              </Form>
            )}
          </Formik>
        </Column>
        {!isReadOnlyCase && (
          <Column modifiers="padScale_0">
            <ButtonIconRectangle
              modifiers="hoverDanger"
              onClick={this.clearCustomStoreCustomer}
              style={{ marginLeft: px2rem(8) }}
            >
              <ButtonIconRectangle.Icon name="trash" />
            </ButtonIconRectangle>
          </Column>
        )}
      </Row>
    );
  }
}

export default compose(
  setDisplayName('CustomStoreCustomer'),
  withClearCustomerSearchValue,
  withCustomStoreCustomer,
  withFocusReceiver(fieldIds.customStore.name),
)(CustomStoreCustomer);

/* eslint-disable react/no-unused-state */
import React from 'react';
import qs from 'qs';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { withRouter } from 'react-router';
import { compose, setDisplayName, mapProps } from 'recompose';

import patchFromChangedProps from 'utils/patchFromChangedProps';

import withDealerTimezone from '../withDealerTimezone';

export const ScheduleContext = React.createContext({
  weekStart: moment().isoWeekday(1),
  goToLastWeek: noop,
  goToNextWeek: noop,
  goToThisWeek: noop,
});

const getLocationParamsFromProps = (props) =>
  qs.parse(get(props, 'location.search').replace('?', ''));

const getDatesFromLocation = (props) => {
  const p = getLocationParamsFromProps(props);
  const tz = props.timezone;
  const date = p.date && moment(p.date).isValid() ? p.date : undefined;
  const weekStart = moment.tz(date, tz).isoWeekday(1);

  return { date: p.date, weekStart: weekStart.startOf('day') };
};

/**
 * Context wrapper for the schedule to provide the current
 * week (`weekStart`, a MomentJS value) along with functions
 * for navigation to the previous week (`goToLastWeek`), the
 * next week (`goToNextWeek`), or to the week containing today
 * (`goToThisWeek`).
 */
export class GridNavigator extends React.Component {
  static propTypes = {
    timezone: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  };

  static getDerivedStateFromProps(props, state) {
    const patch = patchFromChangedProps(props, state, ['location', 'timezone']);

    return patch ? { ...patch, ...getDatesFromLocation(props) } : patch;
  }

  constructor(props) {
    super(props);

    this.state = {
      ...getDatesFromLocation(props),
      history: props.history,
      timezone: props.timezone,
      goToLastWeek: this.goToLastWeek,
      goToNextWeek: this.goToNextWeek,
      goToThisWeek: this.goToThisWeek,
    };
  }

  // eslint-disable-next-line react/sort-comp
  goToDate = (newDateGenerator) => () => {
    const { history } = this.props;

    const weekStart = newDateGenerator(this.state.weekStart).isoWeekday(1);

    const params = {
      ...getLocationParamsFromProps(this.props),
      date: weekStart.format('YYYY-MM-DD'),
    };

    history.push({ search: qs.stringify(params, { encodeValuesOnly: true }) });
  };

  // eslint-disable-next-line react/sort-comp
  goToLastWeek = this.goToDate((oldDate) =>
    moment(oldDate).subtract(7, 'days'),
  );

  goToNextWeek = this.goToDate((oldDate) => moment(oldDate.add(7, 'days')));

  goToThisWeek = this.goToDate(() => this.getCurrentWeek());

  getCurrentWeek = () => {
    const now = moment().tz(this.props.timezone);

    return now.isoWeekday(1);
  };

  render() {
    return (
      <ScheduleContext.Provider value={this.state}>
        {this.props.children}
      </ScheduleContext.Provider>
    );
  }
}

const GridNavigatorWithTimezone = compose(
  setDisplayName('GridNavigator'),
  withRouter,
  withDealerTimezone,
  mapProps(({ data, ...props }) =>
    !data
      ? props
      : {
          ...props,
          timezone: get(data, 'dealer.timezone'),
        },
  ),
)(GridNavigator);

GridNavigatorWithTimezone.Consumer = ScheduleContext.Consumer;

export default GridNavigatorWithTimezone;

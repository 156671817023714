/* istanbul ignore file */
import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Trans } from '@lingui/macro';

import { Avatar, Text, Tooltip } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import HeaderCell from '../HeaderCell';
import { COLUMN_NAMES } from '../constants';
import { buildBaseColumnConfig } from '../utils';

function CaseAgentCell({ agent }) {
  if (!agent) {
    return <div />;
  }
  const { firstName, lastName = '', email } = agent;
  const finalLastName = firstName ? lastName[0] : lastName;

  const name = [firstName, finalLastName].filter(Boolean).join(' ');

  return (
    <Container modifiers="padScaleY_2">
      <Row>
        <Column modifiers={['col', 'padScaleY_0', 'center']}>
          <Tooltip position="right">
            <Tooltip.Target>
              <Avatar email={email} modifiers={['small', 'secondary']} />
            </Tooltip.Target>
            <Tooltip.Content>{email}</Tooltip.Content>
          </Tooltip>
        </Column>
      </Row>
      <Row>
        <Column modifiers={['col', 'padScaleY_0', 'center']}>
          <Text modifiers={['small', 'textLight']}>
            {name || <span>&mdash;</span>}
          </Text>
        </Column>
      </Row>
    </Container>
  );
}

CaseAgentCell.propTypes = {
  agent: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }),
};

CaseAgentCell.defaultProps = { agent: undefined };

export default (...args) => ({
  ...buildBaseColumnConfig(COLUMN_NAMES.agent, ...args),
  cellDataGetter: (data) => ({ agent: get(data, 'node.assignedTo') }),
  headerCellRenderer: (props) => (
    <HeaderCell {...props} label={<Trans>Agent</Trans>} />
  ),
  dataCellRenderer: CaseAgentCell,
});

import styled, { css } from 'styled-components';

import Icon from 'decisiv-iconix-react';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const TagIcon = buildStyledComponent(
  'Tag__Icon',
  styled(Icon).attrs(({ modifiers = '' }) => ({
    modifiers: [
      'mini',
      ...(Array.isArray(modifiers) ? modifiers : [modifiers]),
    ],
  })),
  () => css`
    margin-right: ${px2rem(5)};
    /* Remove bottom whitespace so that the icon can be vertically aligned */
    line-height: 0;
  `,
);

export default TagIcon;

import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

import StyledLeftNav from '../../blocks/LeftNav';

import MenuItemTitle from './MenuItemTitle';
import MenuSubItem from './MenuSubItem';
import menuPropTypes from './menuPropTypes';

export function MenuItemFlyout({
  TextRenderer,
  expanded,
  handleExpandMenuClick,
  isActive,
  isHighlighted,
  isFlyoutOpen,
  isMenuExpanded,
  location,
  menuItem,
  menuGroupsLoading,
  menuKey,
  onMouseEnter,
  onMouseLeave,
}) {
  const { menuSubItems = [] } = menuItem;

  return (
    <StyledLeftNav.MenuItemFlyout>
      <MenuItemTitle
        TextRenderer={TextRenderer}
        expanded={expanded}
        handleExpandMenuClick={handleExpandMenuClick}
        isActive={isActive}
        isHighlighted={isHighlighted}
        isFlyout
        isFlyoutOpen={isFlyoutOpen}
        isMenuExpanded={isMenuExpanded}
        menuItem={menuItem}
        menuGroupsLoading={menuGroupsLoading}
        menuKey={menuKey}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      <StyledLeftNav.MenusList>
        {menuSubItems.map((menuSubItem) => (
          <MenuSubItem
            key={menuSubItem.path || menuSubItem.url}
            isFlyoutOpen={isFlyoutOpen}
            location={location}
            menuSubItem={menuSubItem}
            TextRenderer={TextRenderer}
          />
        ))}
      </StyledLeftNav.MenusList>
    </StyledLeftNav.MenuItemFlyout>
  );
}

MenuItemFlyout.propTypes = {
  TextRenderer: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  handleExpandMenuClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  isFlyoutOpen: PropTypes.bool.isRequired,
  isHighlighted: PropTypes.bool.isRequired,
  isMenuExpanded: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  menuGroupsLoading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/no-typos
  menuItem: menuPropTypes.menuItem.isRequired,
  menuKey: PropTypes.string.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
};

const MenuItemFlyoutWithRouter = withRouter(MenuItemFlyout);
MenuItemFlyoutWithRouter.displayName = 'MenuItemFlyout';

export default MenuItemFlyoutWithRouter;

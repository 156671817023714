import { curry } from 'lodash';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';

import { NAME as CASE_DELAYS_QUERY_NAME } from 'compositions/CaseDetailOverviewPanel/withCaseDelays/caseDelaysQuery';
import reopenCaseGQL from './reopenCaseMutation';

const buildUpdateMutationChild = curry(
  (WrappedComponent, componentProps, reopenCase) => (
    <WrappedComponent {...componentProps} reopenCase={reopenCase} />
  ),
);

function withUpdateMutation(WrappedComponent, componentProps) {
  return (
    <Mutation
      mutation={reopenCaseGQL}
      refetchQueries={[CASE_DELAYS_QUERY_NAME]}
    >
      {buildUpdateMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

const withReopenCase = (WrappedComponent) => (componentProps) =>
  withUpdateMutation(WrappedComponent, componentProps);

export default withReopenCase;

/* istanbul ignore file */
import React from 'react';
import { px2rem } from 'decisiv-ui-utils';
import { Trans } from '@lingui/macro';

import makeCellColumn from './builder';

export default makeCellColumn({
  columnName: 'billTo',
  label: <Trans>Bill To</Trans>,
  styles: { paddingLeft: px2rem(27) },
  text: ({ billTo }) => billTo,
});

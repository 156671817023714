import { compose, withState, mapProps } from 'recompose';
import { reduce } from 'lodash';

const mapFields = (fields, value) =>
  reduce(fields, (obj, _, field) => ({ ...obj, [field]: value }), {});

/**
 * withPatternValidationOnKeyUp
 *
 * @param {*} {
 *   fields = { [name]: invalidPatternRegex },
 *   onlyAfterKeys = ['Dead'],
 *   ignoredKeys = ['Shift', 'Alt'],
 * }
 *
 * Mark as invalid a field that has the given pattern only
 * after the 'Dead' key is pressed.
 * https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values#IME_and_composition_keys
 *
 * When typing an accent sign like ~ (tilde) before typing a letter,
 * we receive a key called 'Dead' where it is waiting for the
 * letter to take effect. We don't want to mark a field as invalid
 * in that scenario, until the actual invalid accent sign is left
 * alone in the field (on blur or next key stroke after 'Dead').
 *
 * Ideally, prevent the invalid characters to be sent to the API
 * by checking with `hasInvalidPattern` before submitting.
 *
 */
const withPatternValidationOnKeyUp = ({
  fields = {},
  onlyAfterKeys = ['Dead'],
  ignoredKeys = ['Shift', 'Alt'],
}) =>
  compose(
    withState(
      'validateNextChange',
      'setValidateNextChange',
      mapFields(fields, false),
    ),
    withState(
      'showValidationError',
      'setShowValidationError',
      mapFields(fields, false),
    ),
    mapProps(
      ({
        validateNextChange,
        setValidateNextChange,
        showValidationError,
        setShowValidationError,
        ...rest
      }) => ({
        ...rest,
        hasInvalidPattern: (field, value) =>
          fields[field] && fields[field].test(value),

        showValidationError: (field) => showValidationError[field],

        onValidatingFieldBlur: (field) => {
          setValidateNextChange({ ...validateNextChange, [field]: false });
          setShowValidationError({ ...showValidationError, [field]: true });
        },

        onValidatingFieldKeyUp: (field, { key, target: { value } }) => {
          if (ignoredKeys.includes(key)) {
            return {
              validateNextChange: validateNextChange[field],
              showValidationError: showValidationError[field],
            };
          }

          const isInvalid = fields[field] && fields[field].test(value);

          let nextValidateNextChange = {};
          let nextShowValidationError = {};

          if (validateNextChange[field]) {
            nextValidateNextChange = { ...validateNextChange, [field]: false };
            nextShowValidationError = {
              ...showValidationError,
              [field]: isInvalid,
            };
            setValidateNextChange(nextValidateNextChange);
            setShowValidationError(nextShowValidationError);
            return {
              validateNextChange: nextValidateNextChange[field],
              showValidationError: nextShowValidationError[field],
            };
          }

          if (onlyAfterKeys.includes(key)) {
            nextValidateNextChange = { ...validateNextChange, [field]: true };
            nextShowValidationError = {
              ...showValidationError,
              [field]: showValidationError[field] && isInvalid,
            };
          } else {
            nextShowValidationError = {
              ...showValidationError,
              [field]: isInvalid,
            };
          }

          setValidateNextChange(nextValidateNextChange);
          setShowValidationError(nextShowValidationError);

          return {
            validateNextChange: nextValidateNextChange[field],
            showValidationError: nextShowValidationError[field],
          };
        },
      }),
    ),
  );

export default withPatternValidationOnKeyUp;

import PropTypes from 'prop-types';
import { Container } from 'styled-components-grid';
import { buildStyledComponent } from 'decisiv-ui-utils';

const modifierConfig = {
  active: ({ theme }) => `
    background-color: ${theme.colors.base.selectableActive};
    pointer-events: none;
    span {
      color: ${theme.colors.base.linkHover};
    }
  `,
  disabled: () => `
    opacity: 0.5;
    pointer-events: none;
  `,
};

const styles = ({ theme }) => `
  cursor: pointer;
  transition: background 200ms ease;
  &:hover {
    background-color: ${theme.colors.base.selectableHover};
    span {
      color: ${theme.colors.base.linkHover};
      text-decoration: underline;
    }
  }
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      linkHover: PropTypes.string.isRequired,
      selectableActive: PropTypes.string.isRequired,
      selectableHover: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const ContainerSelectable = buildStyledComponent(
  'ContainerSelectable',
  Container,
  styles,
  { modifierConfig, themePropTypes },
);

export default ContainerSelectable;

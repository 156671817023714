import React from 'react';
import PropTypes from 'prop-types';
import { defineMessage, Trans } from '@lingui/macro';

import { Row, Column } from 'styled-components-grid';

import { H4, Text, QuickActionButton, DataTable } from 'base-components';

import columns, { actionsColumn } from './columns';
import { modalActions, modalStatePropType } from '../../constants';

const { clear, selectAsset } = modalActions;

const resultsMessage = {
  one: defineMessage({
    message:
      'Your search returned <0>{totalResults} result</0>. Click "SELECT" to set it as the validated asset.',
  }),
  other: defineMessage({
    message:
      'Your search returned <0>{totalResults} results</0>. Click "SELECT" on one to set it as the validated asset.',
  }),
};

function SearchResults(props) {
  const { searchResponse } = props.state;
  const { triggerAction, readOnly } = props;
  const { assets = [], user, reference, timestamp } = searchResponse;

  const onSelectAsset = (asset) =>
    triggerAction(selectAsset, { asset, user, reference, timestamp });

  return (
    <>
      <Row>
        <Column modifiers={['col', 'padScaleX_0']}>
          <H4 modifiers="fontWeightMedium">Search Results</H4>
        </Column>
      </Row>
      <Row>
        <Column style={{ paddingBottom: 0 }} modifiers={['col', 'padScaleX_0']}>
          <Text>
            <Trans
              id={resultsMessage[assets.length === 1 ? 'one' : 'other']}
              values={{ totalResults: assets.length }}
              components={[<Text modifiers="fontWeightMedium" />]}
            />
          </Text>
        </Column>
      </Row>
      <Row style={{ marginBottom: 10 }}>
        <Column modifiers={['col', 'padScale_0']}>
          <Text>
            <Trans>
              If the asset you are looking for is not shown, either use
              different search values or refer to the fallback instructions
              above.
            </Trans>
          </Text>
        </Column>
      </Row>

      <Row>
        <Column modifiers={['col', 'padScaleX_0']}>
          <DataTable
            columns={[...columns, actionsColumn]}
            modifiers={[]}
            tableData={assets}
            rowKeyGetter={(data) => data.vehicleNumber}
            tableMetaData={{ onSelectAsset, readOnly }}
          />
        </Column>
      </Row>
      <Row style={{ padding: '15px 0 0 0' }}>
        <Column modifiers="padScale_0">
          <QuickActionButton
            onClick={() => triggerAction(clear)}
            disabled={readOnly}
            modifiers={[readOnly && 'disabled']}
          >
            <QuickActionButton.Text>
              <Trans>Clear Results</Trans>
            </QuickActionButton.Text>
          </QuickActionButton>
        </Column>
      </Row>
    </>
  );
}

SearchResults.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  triggerAction: PropTypes.func.isRequired,

  // eslint-disable-next-line react/no-typos
  state: modalStatePropType.isRequired,
};

export default SearchResults;

import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { get, isPlainObject } from 'lodash';
import { compose, setDisplayName, pure } from 'recompose';

import { Container, Row, Column } from 'styled-components-grid';
import { Icon, Text, Popover, ButtonLink } from 'base-components';

import withContext from 'utils/withContext';

import FocusProvider from 'setup/FocusProvider';

import { PANEL_STATUSES } from '../../constants';
import { WarningIndicator } from './IndicatorWrapper';

import {
  fieldNames,
  STATUS_BAR_HEIGHT,
  severityGroupNames,
  iconForValidationStatus,
} from './constants';

const { incomplete } = PANEL_STATUSES;

const textStyles = {
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const validSeverities = Object.keys(severityGroupNames);

const groupBySeverity = (fields = {}) =>
  Object.entries(fields).reduce((acc, [key, value]) => {
    if (value) {
      const severity = get(value, 'severity') || value;
      const other = isPlainObject(value) ? value : {};
      const config = { id: key, severity, ...other };

      if (!validSeverities.includes(severity)) return acc;

      acc[config.severity] = [...(acc[config.severity] || []), config];
    }

    return acc;
  }, {});

const renderItemContent = (item, { requestFocusOn }) => {
  const translationId =
    get(fieldNames, item.translationKey || item.id) ||
    item.translationKey ||
    `${item.id}`;

  const content = (
    <>
      {item.prefix || null}
      {translationId && <Trans id={translationId} />}
    </>
  );

  if (item.requestFocus === false) return <Text>{content}</Text>;

  return (
    <ButtonLink onClick={() => requestFocusOn(item.id)} modifiers="small">
      {content}
    </ButtonLink>
  );
};

function Indicator(props) {
  const { label, status } = props;
  const { panel: panelStatus, fields } = status;

  const severityGroups = groupBySeverity(fields);
  const hasPopover = !!Object.keys(severityGroups).length;

  const indicatorContent = (
    <Row
      style={{ height: STATUS_BAR_HEIGHT, padding: '0 4px' }}
      modifiers="middle"
      className="indicator-content"
    >
      <Column>
        <Icon
          data-status={panelStatus}
          name={iconForValidationStatus[panelStatus]}
          modifiers={['mini', panelStatus === incomplete && 'disabled']}
        />
      </Column>
      <Column
        style={{
          flex: '0 1 auto',
          maxWidth: 'calc(100% - 20px)',
          marginTop: 2,
        }}
      >
        <Text style={textStyles} modifiers={['small', 'noWrap']}>
          {label}
        </Text>
      </Column>
      {hasPopover && <WarningIndicator />}
    </Row>
  );

  if (!hasPopover) return indicatorContent;

  return (
    <Popover
      arrow={false}
      position="bottomRight"
      showOnHover
      hoverHideDelay={200}
    >
      <Popover.Target>{indicatorContent}</Popover.Target>
      <Popover.Content
        style={{
          zIndex: 3,
          minWidth: 200,
          maxWidth: 450,
          marginTop: -9,
          paddingBottom: 5,
        }}
        // Prevent the event from reaching the indicator wrapper,
        // and scrolling to the panel
        onClick={(e) => e.stopPropagation()}
      >
        {Object.entries(severityGroups).map(([name, items]) => (
          <Container
            key={name}
            // style={{ paddingBotton: 5 }}
            modifiers={['padScaleX_2', 'padScaleY_2']}
          >
            <Row>
              <Column modifiers={['col', 'padScaleY_0']}>
                <Text modifiers={['small', 'textLight']}>
                  <Trans id={severityGroupNames[name]} />
                </Text>
              </Column>
            </Row>
            {items.map((item) => (
              <Row key={item.id}>
                <Column
                  style={{ marginBotton: 0, paddingBottom: 0 }}
                  modifiers="col"
                >
                  {renderItemContent(item, props)}
                </Column>
              </Row>
            ))}
          </Container>
        ))}
      </Popover.Content>
    </Popover>
  );
}

Indicator.propTypes = {
  label: PropTypes.node.isRequired,
  status: PropTypes.shape({
    panel: PropTypes.string.isRequired,
    fields: PropTypes.shape({}),
  }).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  requestFocusOn: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('Indicator'),
  withContext(FocusProvider),
  pure,
)(Indicator);

import React from 'react';
import { curry, get } from 'lodash';
import { Query, Mutation } from '@apollo/client/react/components';

import caseBillableQuery, {
  NAME as caseBillableQueryName,
} from './caseBillableQuery';
import updateCaseBillableMutation from './updateCaseBillableMutation';

export const buildMutationChild = curry(
  (WrappedComponent, componentProps, mutate) => {
    const { caseId } = componentProps;

    const setCaseIsBillable = (isBillable) =>
      mutate({
        variables: { caseId, isBillable },
      });

    return (
      <WrappedComponent
        {...componentProps}
        setCaseIsBillable={setCaseIsBillable}
      />
    );
  },
);

function buildWrappedComponentWithMutation(WrappedComponent, componentProps) {
  return (
    <Mutation
      mutation={updateCaseBillableMutation}
      refetchQueries={[caseBillableQueryName]}
    >
      {buildMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, { data, loading }) =>
    buildWrappedComponentWithMutation(WrappedComponent, {
      ...componentProps,
      isBillable: get(data, 'case.billable', false),
      isLoadingIsBillable: !!loading,
    }),
);

function buildWrappedComponentWithQuery(WrappedComponent, componentProps) {
  const { caseNumber } = componentProps;

  return (
    <Query
      skip={!caseNumber}
      query={caseBillableQuery}
      variables={{ caseNumber }}
    >
      {buildQueryChild(WrappedComponent, componentProps)}
    </Query>
  );
}

const withCaseBillableToggle = curry(buildWrappedComponentWithQuery);

export default withCaseBillableToggle;

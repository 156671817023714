import styled from 'styled-components';
import PropTypes from 'prop-types';
import { buildStyledComponent } from 'decisiv-ui-utils';
import Icon from 'decisiv-iconix-react';

import Button from './Button';

const styles = ({ theme }) => `
  display: flex;

  button:first-child {
    border-radius: ${theme.dimensions.borderRadius} 0 0 ${theme.dimensions.borderRadius};
  }

  button:not(:last-child) {
    border-right: transparent;
  }

  button:last-child {
    border-radius: 0 ${theme.dimensions.borderRadius} ${theme.dimensions.borderRadius} 0;
  }
`;

const themePropTypes = {
  dimensions: PropTypes.shape({
    borderRadius: PropTypes.string.isRequired,
  }).isRequired,
};

const ButtonGroup = buildStyledComponent('ButtonGroup', styled.div, styles, {
  themePropTypes,
});

ButtonGroup.Button = Button;
ButtonGroup.ButtonIcon = Icon;

export default ButtonGroup;

import React from 'react';
import PropTypes from 'prop-types';

import { Popover } from 'base-components';
import { Row } from 'styled-components-grid';

export default function InfoPopoverContent({ children, ...props }) {
  return (
    <Popover.Content {...props}>
      <Row modifiers={['height_100']} style={{ flexWrap: 'nowrap' }}>
        {children}
      </Row>
    </Popover.Content>
  );
}

InfoPopoverContent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
};

InfoPopoverContent.defaultProps = {
  style: {},
};

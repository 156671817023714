import { t, Trans } from '@lingui/macro';
import { filter, get, uniq } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName, branch } from 'recompose';

import { Accordion, Badge, P, Text } from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Column, Container, Row } from 'styled-components-grid';

import { useCaseShortcut, CASE_SHORTCUT_PANELS } from 'features/keyShortcuts';

import withCustomerSelect from '../../withCustomerSelect';
import withStoreCustomerSelect from '../../withStoreCustomerSelect';
import withEmailNotificationPreferences from './withEmailNotificationPreferences';

function NotificationPreference({ type, emailNotificationPreferences }) {
  const isActive = emailNotificationPreferences.length > 0;

  return (
    <Row modifiers="padScaleY_0">
      <Column>
        <Badge modifiers={[isActive && 'info']}>
          <Badge.Icon name={isActive ? 'envelope-check' : 'envelope-times'} />
        </Badge>
      </Column>
      <Column modifiers={['col', 'padScaleY_1']}>
        <Row modifiers="padScaleY_0">
          <Column modifiers="padScaleY_0">
            <Text>
              <Trans id={type.title} />
            </Text>
          </Column>
        </Row>
        <Row>
          <Column modifiers={['col', 'padScaleY_0']}>
            <Text modifiers={['small', 'textLight']}>
              <Trans id={type.description} />
            </Text>
          </Column>
        </Row>
        <Row modifiers="padScaleY_1">
          <Column>
            {emailNotificationPreferences.map((pref) => (
              <P modifiers={['small', 'textLight']} key={`email - ${pref}`}>
                {pref}
              </P>
            ))}
          </Column>
        </Row>
      </Column>
    </Row>
  );
}

NotificationPreference.propTypes = {
  emailNotificationPreferences: PropTypes.arrayOf(PropTypes.string.isRequired)
    .isRequired,
  type: PropTypes.shape({
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

const NotificationTypes = {
  dispatched: {
    title: t`Dispatched`,
    description: t`Receive notification when breakdown has been dispatched.`,
  },
  rolling: {
    title: t`Vehicle Rolling`,
    description: t`Receive notification when the service provider technician has vehicle rolling.`,
  },
};

function EmailNotifications({ emailNotificationPreferences, customer }) {
  const dispatchedPreferences = uniq(
    filter(
      emailNotificationPreferences,
      ({ notifyWhenDispatched, email }) => !!notifyWhenDispatched && !!email,
    ).map((pref) => pref.email),
  );

  const rollingPreferences = uniq(
    filter(
      emailNotificationPreferences,
      ({ notifyWhenRolling, email }) => !!notifyWhenRolling && !!email,
    ).map((pref) => pref.email),
  );

  const hasData = !!dispatchedPreferences.length || !!rollingPreferences.length;

  const [expanded, setExpanded] = React.useState(false);

  const { onFocusRequested } = useCaseShortcut({
    parent: CASE_SHORTCUT_PANELS.customer,
    id: 'emailNotificationsAction',
    name: t`Email Notifications`,
    shortcut: ['f', '7'],
    keywords: 'fleet email notifications',
    priority: 1,
    perform: () => hasData && setExpanded((e) => !e),
    deps: hasData,
  });

  return (
    <Accordion
      key={get(customer, 'id')}
      disabled={!hasData}
      expanded={expanded}
      onExpandedChange={({ expanded }) => setExpanded(expanded)}
    >
      <Accordion.Head>
        <Accordion.Title ref={onFocusRequested}>
          <Trans>Email Notifications</Trans>
        </Accordion.Title>
      </Accordion.Head>
      {hasData && (
        <Accordion.Body>
          <Container style={{ paddingBottom: px2rem(20) }}>
            <NotificationPreference
              type={NotificationTypes.dispatched}
              emailNotificationPreferences={dispatchedPreferences}
            />
            <NotificationPreference
              type={NotificationTypes.rolling}
              emailNotificationPreferences={rollingPreferences}
            />
          </Container>
        </Accordion.Body>
      )}
    </Accordion>
  );
}

EmailNotifications.propTypes = {
  emailNotificationPreferences: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      notifyWhenDispatched: PropTypes.bool.isRequired,
      notifyWhenRolling: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  customer: PropTypes.shape({ id: PropTypes.string }),
};

EmailNotifications.defaultProps = {
  customer: undefined,
};

const isNationalCustomer = ({ customerType, customerTypes }) =>
  customerType === customerTypes.NATIONAL.type;

export default compose(
  setDisplayName('EmailNotifications'),
  /**
   * Order is important here. `withEmailNotificationPreferences`
   * needs the current customer as a prop, so we must run
   * `withCustomerSelect/withStoreCustomerSelect` first.
   */
  branch(isNationalCustomer, withCustomerSelect, withStoreCustomerSelect),
  withEmailNotificationPreferences,
)(EmailNotifications);

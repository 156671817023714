import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import { Row } from 'styled-components-grid';

import EditDeletableItem from 'components/EditDeletableItem';

import ContactInfo from '../../ContactInfo';

export class AfterHoursContact extends Component {
  static propTypes = {
    contact: PropTypes.shape({
      canDelete: PropTypes.bool,
      id: PropTypes.string,
      name: PropTypes.string,
      jobTitle: PropTypes.string,
      primaryPhoneNumber: PropTypes.string,
      secondaryPhoneNumber: PropTypes.string,
    }).isRequired,
    toggleEditContact: PropTypes.func.isRequired,
    toggleDeleteContact: PropTypes.func.isRequired,
    isReadOnly: PropTypes.bool,
  };

  static defaultProps = {
    isReadOnly: false,
  };

  handleDelete = () => {
    const { contact } = this.props;
    this.props.toggleDeleteContact(contact);
  };

  handleEdit = () => {
    this.props.toggleEditContact(this.props.contact);
  };

  render() {
    const { contact, isReadOnly } = this.props;

    return (
      <EditDeletableItem
        onEditItem={this.handleEdit}
        onDeleteItem={this.handleDelete}
        editTooltip={<Trans>Edit Contact</Trans>}
        deleteTooltip={<Trans>Delete Contact</Trans>}
        modifiers={['padScaleY_1', 'padScaleX_4']}
        position="left"
        showButtons={!isReadOnly}
      >
        <Row>
          <ContactInfo contact={contact} />
        </Row>
      </EditDeletableItem>
    );
  }
}

export default AfterHoursContact;

import React from 'react';
import { curry } from 'lodash';

export const buildConsumerChild = curry(
  (ProviderName, WrappedComponent, componentProps, warnIfMissing, props) => {
    if (!props && warnIfMissing) {
      // eslint-disable-next-line no-console
      console.error(
        `[withContext] Provider with name ${ProviderName} did not provide a value to its consumers.\n\nDid you forget to wrap the app in the Provider?`,
      );
    }

    return <WrappedComponent {...{ ...componentProps, ...props }} />;
  },
);

const withContext = (Provider, warnIfMissing = true) => (WrappedComponent) => (
  componentProps,
) => {
  const { displayName } = Provider;

  if (!Provider.Consumer && !!warnIfMissing) {
    // eslint-disable-next-line no-console
    console.error(
      `[withContext] Could not find a consumer on the provider: ${displayName}`,
    );
  }

  return (
    <Provider.Consumer>
      {buildConsumerChild(
        displayName,
        WrappedComponent,
        componentProps,
        warnIfMissing,
      )}
    </Provider.Consumer>
  );
};

export default withContext;

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import {
  fontStyles,
  fontWeightPropTypes,
  fontWeights,
} from '../../utils/modifiers';
import { spacing } from '../../utils/modifiers/spacing';

import { TEXT_LINE_HEIGHT } from '../../utils/constants';
import em2rem from '../../utils/em2rem';

const DEFAULT_FONT_SIZE = 12;
const SMALL_FONT_SIZE = 10;

const customModifiers = {
  small: () => ({
    styles: `
      font-size: ${px2rem(SMALL_FONT_SIZE)};
      line-height: ${em2rem(SMALL_FONT_SIZE, TEXT_LINE_HEIGHT)};
    `,
  }),
  textLight: ({ theme }) => ({
    styles: `
      color: ${theme.colors.base.textLight};
    `,
  }),
};

const styles = ({ theme }) => `
  color: ${theme.colors.base.text};
  font-size: ${px2rem(DEFAULT_FONT_SIZE)};
  line-height: ${em2rem(DEFAULT_FONT_SIZE, TEXT_LINE_HEIGHT)};
  margin: 0;
`;

const customThemePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      text: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const modifierConfig = {
  ...fontStyles,
  ...fontWeights,
  ...spacing,
  ...customModifiers,
};

const themePropTypes = {
  ...fontWeightPropTypes,
  ...customThemePropTypes,
};

export default buildStyledComponent('P', styled.p, styles, {
  modifierConfig,
  themePropTypes,
});

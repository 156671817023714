import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { A, H2, P, QuickActionButton } from 'base-components';
import { Column, Row } from 'styled-components-grid';

import Modal from 'components/Modal';
import withContext from 'utils/withContext';

import LoadingBar from './LoadingBar';
import ConfigTable from './ConfigTable';
import ReportConfigBuilderContext from '../Context';
import withReportStatus from './withReportStatus';
import { reportStatusTypes } from '../constants';

const configForStatus = {
  [reportStatusTypes.loading]: {
    modifiers: 'info',
    icon: 'bar-chart',
    title: t`Running Report...`,
    message: t`Please wait while we generate the following report.`,
  },
  [reportStatusTypes.failure]: {
    modifiers: 'danger',
    icon: 'exclamation-triangle',
    title: t`Report Error`,
    message: t`An issue occurred and the following report could not be generated.`,
  },
  [reportStatusTypes.success]: {
    modifiers: 'success',
    icon: 'check-circle',
    title: t`Report Ready`,
    message: t`The following report has been downloaded to your computer.<0/>Can't find it? <1>Download here</1>.`,
  },
};

class ReportStatusModal extends Component {
  static propTypes = {
    metadata: PropTypes.shape({}),
    reportUrl: PropTypes.string,
    runReport: PropTypes.func.isRequired,
    reportStatus: PropTypes.oneOf(Object.values(reportStatusTypes)).isRequired,
    cancelReport: PropTypes.func.isRequired,
    showBillableStatus: PropTypes.bool.isRequired,
    currentRunningReport: PropTypes.shape({
      type: PropTypes.string.isRequired,
      config: PropTypes.shape().isRequired,
    }).isRequired,
    dateRangeTypeOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  static defaultProps = {
    metadata: undefined,
    reportUrl: null,
  };

  componentDidUpdate({ reportStatus: prevStatus }) {
    const { reportStatus: newStatus, reportUrl } = this.props;

    if (
      newStatus === reportStatusTypes.success &&
      prevStatus === reportStatusTypes.loading &&
      !!reportUrl
    ) {
      window.open(reportUrl);
    }
  }

  render() {
    const { currentRunningReport, metadata } = this.props;
    const { reportUrl, runReport, dateRangeTypeOptions } = this.props;
    const { cancelReport, reportStatus, showBillableStatus } = this.props;

    const { type: reportType, config: reportConfig } = currentRunningReport;

    return (
      <Modal onClose={cancelReport}>
        {() => (
          <Modal.Body>
            <Modal.Header
              modifiers={configForStatus[reportStatus].modifiers}
              showCloseButton={false}
            >
              <Modal.HeaderIcon name={configForStatus[reportStatus].icon} />
            </Modal.Header>
            <Modal.Content>
              {reportStatus === reportStatusTypes.loading && <LoadingBar />}
              <Row modifiers="center">
                <Column>
                  <H2 modifiers="fontWeightRegular">
                    <Trans id={configForStatus[reportStatus].title} />
                  </H2>
                </Column>
              </Row>
              <Row modifiers="center">
                <Column>
                  <P>
                    <Trans
                      id={configForStatus[reportStatus].message}
                      components={[
                        <br />,
                        <A
                          href={reportUrl}
                          target="_blank"
                          aria-label={t`Download Report`}
                          style={{ textTransform: 'none' }}
                        />,
                      ]}
                    />
                  </P>
                </Column>
              </Row>
              <Row>
                <Column modifiers="col">
                  <ConfigTable
                    {...currentRunningReport}
                    metadata={metadata}
                    showBillableStatus={showBillableStatus}
                    showDateRangeType={dateRangeTypeOptions.length > 1}
                  />
                </Column>
              </Row>
            </Modal.Content>
            <Modal.Footer>
              <Row modifiers="end">
                <Column>
                  <QuickActionButton
                    type="button"
                    modifiers={
                      reportStatus === reportStatusTypes.success && 'secondary'
                    }
                    onClick={cancelReport}
                  >
                    <QuickActionButton.Text>
                      {reportStatus === reportStatusTypes.success ? (
                        <Trans>Close</Trans>
                      ) : (
                        <Trans>Cancel Report</Trans>
                      )}
                    </QuickActionButton.Text>
                  </QuickActionButton>
                </Column>
                {reportStatus === reportStatusTypes.failure && (
                  <Column>
                    <QuickActionButton
                      type="button"
                      onClick={() => runReport(reportType, reportConfig)}
                      modifiers="secondary"
                    >
                      <QuickActionButton.Text>
                        <Trans>Run Again</Trans>
                      </QuickActionButton.Text>
                    </QuickActionButton>
                  </Column>
                )}
              </Row>
            </Modal.Footer>
          </Modal.Body>
        )}
      </Modal>
    );
  }
}

export default compose(
  setDisplayName('ReportStatusModal'),
  withContext(ReportConfigBuilderContext),
  withReportStatus,
)(ReportStatusModal);

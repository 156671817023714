import { Trans } from '@lingui/macro';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { withTheme } from 'styled-components';

import { Divider, P, Text, Icon } from 'base-components';
import { Column, Container, Row } from 'styled-components-grid';

function ERSHours({ start, end, timezone }) {
  const startTime = moment(start).tz(timezone).format('LT');
  const endTime = moment(end).tz(timezone).format('LT z');

  return (
    <>
      <Row modifiers={['padScale_0', 'start']}>
        <Column modifiers={['col', 'padScale_0']}>
          <Container>
            <Row>
              <Column>
                <Icon name="clock" modifiers={['medium']} />
              </Column>
              <Column modifiers={['col', 'padScale_0']}>
                <Container modifiers={['padScaleX_2', 'padScaleY_0']}>
                  <Row modifiers="padScaleY_0">
                    <Column modifiers={['col', 'padScaleY_0']}>
                      <Text modifiers={['small', 'textLight']}>
                        <Trans>ERS Hours</Trans>
                      </Text>
                    </Column>
                  </Row>
                  <Row modifiers="padScaleY_0">
                    <Column modifiers={['col', 'padScaleY_0']}>
                      <P>
                        {startTime} - {endTime}
                      </P>
                    </Column>
                  </Row>
                </Container>
              </Column>
            </Row>
          </Container>
        </Column>
      </Row>
      <Row>
        <Column modifiers="col">
          <Divider />
        </Column>
      </Row>
    </>
  );
}

ERSHours.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
};

export default withTheme(ERSHours);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compact, noop } from 'lodash';

import ButtonToggleBlock from '../../blocks/ButtonToggle';

const { OptionLabel, Bar, Switch, Checkbox, Content } = ButtonToggleBlock;

class ButtonToggle extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    on: PropTypes.bool,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    disabled: false,
    on: true,
    onClick: noop,
  };

  state = {
    options: [],
  };

  UNSAFE_componentWillMount() {
    this.parseChildren();
  }

  cloneOption = (option, isDefaultOption) =>
    React.cloneElement(option, {
      modifiers: [isDefaultOption ? 'active' : 'inactive'],
    });

  parseChildren() {
    const options = [];
    React.Children.forEach(this.props.children, (child) => {
      if (child.type === OptionLabel) {
        options.push(child);
      }
    });
    this.setState({ options });
  }

  renderButtonContent() {
    if (!this.state.options.length) {
      return null;
    }

    return (
      <Content>
        {this.cloneOption(this.state.options[0], !this.props.on)}
        <Bar>
          <Switch modifiers={compact([this.props.on && 'on'])} />
        </Bar>
        {this.cloneOption(this.state.options[1], this.props.on)}
      </Content>
    );
  }

  render() {
    const { disabled, on, onClick } = this.props;

    return (
      <ButtonToggleBlock
        modifiers={compact([disabled && 'disabled'])}
        onClick={() => onClick(on)}
      >
        <Checkbox
          checked={on}
          disabled={disabled}
          onChange={() => onClick(on)}
        />
        {this.renderButtonContent()}
      </ButtonToggleBlock>
    );
  }
}

ButtonToggle.OnLabel = OptionLabel;
ButtonToggle.OffLabel = OptionLabel;

export default ButtonToggle;

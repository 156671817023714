import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { Column, Row } from 'styled-components-grid';

import {
  Icon,
  Text,
  Tooltip,
  Popover,
  QuickActionButton,
  ButtonIconRectangle,
} from 'base-components';

import withContext from 'utils/withContext';
import withCaseMismatches from 'compositions/CaseMismatchesBar/withCaseMismatches';

import withSendToFixpix from './withSendToFixpix';
import CaseStatusContext from './CaseStatusContext';
import useResolveMismatches from './useResolveMismatches';
import ResolveMismatchesModal from './ResolveMismatchesModal';

import { CASE_STATUS, PANEL_STATUSES, FIXPIX_PUSH_RESULT } from './constants';

const StyledQuickActionButton = styled(QuickActionButton)`
  color: white;
  border: 0;
  padding: 5px 10px;
  display: inline-block;
  background: #ff5100;
  border-radius: 20px;
`;

const ignoredStatuses = [CASE_STATUS.new, CASE_STATUS.dispatch];
// eslint-disable-next-line no-unused-vars
const { notAttempted, error, outdated } = FIXPIX_PUSH_RESULT;

function CaseFixpixPushResult(props) {
  const { fixpixPushResult: result } = props;
  const { sendToFixpix, isSendingToFixpix } = props;
  const { caseAssignedToCurrentUser, isUpdatingCaseStatus, caseId } = props;
  const { status, hasDispatchedStatus, mismatches, validationStatus } = props;

  const [
    showResolveMismatchesModal,
    setShowResolveMismatchesModal,
  ] = React.useState(false);

  const { loading: isResolvingMismatches } = useResolveMismatches(caseId);

  const isClosedOrCanceled = [
    CASE_STATUS.canceled,
    CASE_STATUS.closed,
    CASE_STATUS.closed_canceled,
  ].includes(status);

  const caseIsValid = validationStatus === PANEL_STATUSES.complete;

  const isLoading =
    isUpdatingCaseStatus || isSendingToFixpix || isResolvingMismatches;

  const disabled = isLoading || !caseIsValid || !caseAssignedToCurrentUser;
  const hasMismatches = !!mismatches.length;

  const showRetryButton = !isClosedOrCanceled && result === error;

  const showPillButton =
    hasDispatchedStatus && !showRetryButton && hasMismatches;

  // TODO: uncomment to enable the "Send Updates" feature
  // const showPillButton =
  //   hasDispatchedStatus &&
  //   !showRetryButton &&
  //   (result === outdated || hasMismatches);

  const action = useCallback(() => {
    return hasMismatches
      ? setShowResolveMismatchesModal(true)
      : // eslint-disable-next-line no-console
        sendToFixpix().catch((e) => console.error(e));
  }, [hasMismatches, sendToFixpix]);

  if (ignoredStatuses.includes(status) || result === notAttempted) return null;

  return (
    <Row modifiers="middle">
      <Column>
        <Icon
          name={result === error ? 'send-times' : 'send-check'}
          modifiers={result === error ? 'danger' : 'success'}
        />
      </Column>
      <Column>
        <Text>
          {result === error && <Trans>FIXPIX Failed</Trans>}
          {result !== error && <Trans>Sent to FIXPIX</Trans>}
        </Text>
      </Column>
      {showRetryButton && (
        <Column>
          <Tooltip>
            <Tooltip.Target>
              <ButtonIconRectangle
                onClick={action}
                disabled={disabled}
                modifiers={disabled && 'disabled'}
              >
                <ButtonIconRectangle.Icon name="redo" />
              </ButtonIconRectangle>
            </Tooltip.Target>
            <Tooltip.Content>
              <Trans>Retry</Trans>
            </Tooltip.Content>
          </Tooltip>
        </Column>
      )}
      {showPillButton && (
        <Column>
          <Popover position="left" showOnHover>
            <Popover.Target>
              <StyledQuickActionButton
                onClick={action}
                disabled={disabled}
                modifiers={disabled && 'disabled'}
              >
                <QuickActionButton.Text>
                  {hasMismatches && <Trans>Resolve Mismatches</Trans>}
                  {!hasMismatches && <Trans>Send Updates</Trans>}
                </QuickActionButton.Text>
              </StyledQuickActionButton>
            </Popover.Target>
            <Popover.Content style={{ padding: 10, width: 310 }}>
              <Text>
                {hasMismatches && (
                  <Trans>Click to clear the reported FIXPIX mismatches.</Trans>
                )}
                {!hasMismatches && (
                  <Trans>
                    Click to send FIXPIX the most up-to-date case data.
                  </Trans>
                )}
              </Text>
            </Popover.Content>
          </Popover>
        </Column>
      )}

      {showResolveMismatchesModal && (
        <ResolveMismatchesModal
          onClose={() => setShowResolveMismatchesModal(false)}
        />
      )}
    </Row>
  );
}

CaseFixpixPushResult.propTypes = {
  caseId: PropTypes.string,
  status: PropTypes.string.isRequired,
  mismatches: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sendToFixpix: PropTypes.func.isRequired,
  validationStatus: PropTypes.string.isRequired,
  fixpixPushResult: PropTypes.string.isRequired,
  isSendingToFixpix: PropTypes.bool.isRequired,
  hasDispatchedStatus: PropTypes.bool.isRequired,
  isUpdatingCaseStatus: PropTypes.bool.isRequired,
  caseAssignedToCurrentUser: PropTypes.bool.isRequired,
};

export default compose(
  setDisplayName('CaseFixpixPushResult'),
  withContext(CaseStatusContext),
  withSendToFixpix,
  withCaseMismatches,
)(CaseFixpixPushResult);

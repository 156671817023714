import PropTypes from 'prop-types';
import React from 'react';
import Icon from 'decisiv-iconix-react';

import { modifiersForComponent } from '../../utils/modifiers';

import IconBackground from './IconBackground';

function IconCircular(props) {
  const { modifiers, ...rest } = props;
  const bgModifiers = modifiersForComponent(modifiers, IconBackground);
  const iconModifiers = [
    'medium',
    'chrome000',
    ...modifiersForComponent(modifiers, Icon),
  ];

  return (
    <IconBackground modifiers={bgModifiers}>
      <Icon modifiers={iconModifiers} {...rest} />
    </IconBackground>
  );
}

IconCircular.defaultProps = {
  modifiers: [],
};

IconCircular.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

export default IconCircular;

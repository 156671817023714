import { useMemo } from 'react';
import { useRegisterActions, createAction } from 'kbar';
import { t } from '@lingui/macro';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { userCanCreateNewCase } from 'features/rbac/utils';

const QUICK_ACTIONS_PRIORITY = 10;
const QUICK_ACTIONS_PARENT_ID = 'quickActions';

function createQuickAction(action, isCasePage) {
  const quickAction = {
    ...action,
    priority: QUICK_ACTIONS_PRIORITY,
  };

  return isCasePage
    ? createAction({ ...quickAction, parent: QUICK_ACTIONS_PARENT_ID })
    : createAction({ ...quickAction, section: t`Quick Actions` });
}

const useQuickShortcuts = ({ role, loadingRole, permissions }) => {
  const history = useHistory();
  const match = useRouteMatch({ path: '/cases/:caseNumber' });
  const isCasePage = !!match;

  useRegisterActions(
    isCasePage
      ? [
          {
            id: QUICK_ACTIONS_PARENT_ID,
            name: t`Quick Actions`,
            priority: QUICK_ACTIONS_PRIORITY,
            shortcut: ['q', 'a'],
            icon: 'bolt',
          },
        ]
      : [],
    [isCasePage],
  );

  const actions = useMemo(() => {
    const canCreateNewCase = userCanCreateNewCase({
      role,
      loadingRole,
      permissions,
    });

    return [
      canCreateNewCase &&
        createQuickAction(
          {
            name: t`Create Case`,
            icon: 'briefcase-plus',
            shortcut: ['c', 'c'],
            keywords: 'new',
            perform: () => {
              history.push('/cases/new');
            },
          },
          isCasePage,
        ),
      createQuickAction(
        {
          name: t`Reload page`,
          icon: 'refresh',
          shortcut: ['r', 'r'],
          keywords: 'reload refresh page',
          perform: () => {
            window?.location?.reload();
          },
        },
        isCasePage,
      ),
    ].filter(Boolean);
  }, [history, role, loadingRole, permissions, isCasePage]);

  useRegisterActions(actions, [actions]);
};

export function QuickActionsShortcut({ action, deps, children }) {
  const match = useRouteMatch({ path: '/cases/:caseNumber' });
  const isCasePage = !!match;

  useRegisterActions(
    action ? [createQuickAction(action, isCasePage)] : [],
    deps,
  );

  return children;
}

export default useQuickShortcuts;

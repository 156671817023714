import { buildStyledComponent } from 'decisiv-ui-utils';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const styles = `
  background: transparent;
  border-radius: 0;
  border: none;
  cursor: pointer;
  height: 100%;
  padding: 0;
  text-align: left;
  width: 100%;
`;

const propTypes = {
  type: PropTypes.string,
};

const defaultProps = {
  type: 'button',
};

export default buildStyledComponent('Button', styled.button, styles, {
  defaultProps,
  propTypes,
});

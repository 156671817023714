import React from 'react';

import { reportTypes } from 'pages/Reports/constants';

import ReportConfigBuilder from './ReportConfigBuilder';

const CasesPerHourReport = () => (
  <ReportConfigBuilder reportType={reportTypes.casesPerHour} />
);

export default CasesPerHourReport;

import { compact, orderBy } from 'lodash';

export function sortAndGroupCustomers(customers) {
  const sortedCustomers = orderBy(customers, ['name'], ['asc']);

  return sortedCustomers.reduce((acc, curr) => {
    const addCustomersToGroupIndex = (groups, n, customer) => {
      const previousCustomers = groups[n].customers || [];
      return groups.map((group, i) => {
        if (i === n) {
          return {
            ...group,
            customers: compact([...previousCustomers, customer]),
          };
        }
        return group;
      });
    };

    const lastGroupIndex = acc.length - 1;

    if (lastGroupIndex > -1) {
      if (acc[lastGroupIndex].name === curr.name.charAt(0)) {
        return addCustomersToGroupIndex(acc, lastGroupIndex, curr);
      }
    }

    const newGroup = {
      name: curr.name.charAt(0),
      customers: [curr],
    };
    return acc.concat(newGroup);
  }, []);
}

export default {
  sortAndGroupCustomers,
};

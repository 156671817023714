import PropTypes from 'prop-types';
import { css } from 'styled-components';

import { Icon } from 'base-components';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

export default buildStyledComponent(
  'DealerResponseStatusIndicator',
  Icon,
  css`
    &:before {
      align-items: center;
      border-radius: 50%;
      display: flex;
      height: ${px2rem(35)};
      justify-content: center;
      width: ${px2rem(35)};
    }
  `,
  {
    modifierConfig: {
      accepted: ({ theme }) => ({
        styles: css`
          &:before {
            background-color: ${theme.colors.status.success};
          }
          color: ${theme.colors.base.chrome000};
        `,
      }),
      rejected: ({ theme }) => ({
        styles: css`
          &:before {
            background-color: ${theme.colors.status.danger};
          }
          color: ${theme.colors.base.chrome000};
        `,
      }),
      unknown: ({ theme }) => ({
        styles: css`
          &:before {
            background-color: ${theme.colors.base.chrome200};
          }
          color: ${theme.colors.base.textLight};
        `,
      }),
    },
    themePropTypes: {
      colors: PropTypes.shape({
        base: PropTypes.shape({
          chrome000: PropTypes.string.isRequired,
          chrome200: PropTypes.string.isRequired,
          textLight: PropTypes.string.isRequired,
        }).isRequired,
        status: PropTypes.shape({
          danger: PropTypes.string.isRequired,
          success: PropTypes.string.isRequired,
        }),
      }).isRequired,
    },
  },
);

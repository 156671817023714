import PropTypes from 'prop-types';
import md5 from 'md5';
import styled from 'styled-components';
import isRetina from 'is-retina';
import { buildStyledComponent } from 'decisiv-ui-utils';

const IS_RETINA = isRetina();

export function getSize(small, retina) {
  const size = small ? 35 : 60;
  return retina ? size * 2 : size;
}

const styles = `
  border-radius: 50%;
`;

function gravatarUrl({ email, small }) {
  const id = md5(email);
  const size = getSize(small, IS_RETINA);
  return `https://secure.gravatar.com/avatar/${id}?s=${size}&d=404`;
}

const Image = buildStyledComponent(
  'Image',
  styled.img.attrs((props) => ({
    alt: 'user-avatar',
    src: gravatarUrl(props),
    width: '100%',
  })),
  styles,
);

Image.propTypes = {
  email: PropTypes.string,
  small: PropTypes.bool,
};

Image.defaultProps = {
  email: '',
  small: false,
};

export default Image;

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';
import { Column } from 'styled-components-grid';

const styles = ({ theme }) => `
  background: ${theme.colors.base.background};

  &:not(:first-child) {
    padding-left: 0;
  }

  &:not(:last-child) {
    padding-right: 0;
  }
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      background: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default buildStyledComponent('Timeline__Event', styled(Column), styles, {
  themePropTypes,
});

import gql from 'graphql-tag';

export default gql`
  mutation updateAssetLocationNoteMutation(
    $assetLocationNote: String!
    $id: ID!
  ) {
    updateCase(
      input: { id: $id, patch: { assetLocation: { note: $assetLocationNote } } }
    ) {
      case {
        id
      }
    }
  }
`;

import { UI_STATES } from './constants';

/**
 * Does the given UI state indicate that a call is incoming (which
 * we haven't yet accepted or rejected)?
 * @param {string} uiState
 * @return {boolean}
 */
export function isIncomingState(uiState) {
  const matchingStates = [UI_STATES.INCOMING];
  return matchingStates.includes(uiState);
}

/**
 * Does the given UI state indicate that a connection is currently
 * being setup to a a call?
 * @param {string} uiState
 * @return {boolean}
 */
export function isConnectingState(uiState) {
  const matchingStates = [
    UI_STATES.INBOUND,
    UI_STATES.OUTBOUND,
    UI_STATES.CONNECTING,
  ];
  return matchingStates.includes(uiState);
}

/**
 * Does the given UI state indicate a "conversation" is in progress?
 * @param {string} uiState
 * @return {boolean}
 */
export function isConversationState(uiState) {
  const matchingStates = [UI_STATES.CONNECTED, UI_STATES.ON_HOLD];
  return matchingStates.includes(uiState);
}

/**
 * Is the given UI state a "disconnected" state, in which we do
 * not have a currently-active call?
 * @param {string} uiState
 * @return {boolean}
 */
export function isDisconnectedState(uiState) {
  const matchingStates = [
    UI_STATES.DISCONNECTED,
    UI_STATES.OFFLINE,
    UI_STATES.ERROR,
  ];
  return matchingStates.includes(uiState);
}

/**
 * Does the given UI state indicate a situation in which we should
 * show the call details in the top nav?
 * @param {string} uiState
 * @return {boolean}
 */
export function contactDetailsEnabled(uiState) {
  const matchingStates = [UI_STATES.ACW, UI_STATES.AUX, UI_STATES.MONITORING];
  return (
    matchingStates.includes(uiState) ||
    isIncomingState(uiState) ||
    isConnectingState(uiState) ||
    isConversationState(uiState)
  );
}

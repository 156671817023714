import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import RotationEditModal from './RotationEditModal';
import RotationDeleteModal from './RotationDeleteModal';
import RotationOverrideContactsModal from './RotationOverrideContactsModal';
import ExceptionConflictModal from './ExceptionConflictModal';

const ModalContext = React.createContext({
  event: null,
  showEditModal: noop,
  editModalVisible: false,
  showDeleteModal: noop,
  deleteModalVisible: false,
  showOverrideContactsModal: noop,
  overrideContactsModalVisible: false,
  showExceptionConflictModal: noop,
  exceptionConflictModalVisible: false,
});

const modalsConfig = [
  ['editModalVisible', RotationEditModal],
  ['deleteModalVisible', RotationDeleteModal],
  ['overrideContactsModalVisible', RotationOverrideContactsModal],
  ['exceptionConflictModalVisible', ExceptionConflictModal],
];

const initialState = {
  event: null,
  editModalVisible: false,
  deleteModalVisible: false,
  overrideContactsModalVisible: false,
  exceptionConflictModalVisible: false,
};

export default class RotationModalContext extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    dealerId: PropTypes.string,
    dealerTimezone: PropTypes.string,
  };

  static defaultProps = { dealerId: undefined, dealerTimezone: undefined };

  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      dealerId: this.props.dealerId,
      showEditModal: this.showModal('editModalVisible'),
      showDeleteModal: this.showModal('deleteModalVisible'),
      showOverrideContactsModal: this.showModal('overrideContactsModalVisible'),
      showExceptionConflictModal: this.showModal(
        'exceptionConflictModalVisible',
      ),
    };
  }

  showModal = (stateKey) => (evt) => {
    const event = { ...evt, dealerId: this.props.dealerId };

    this.setState({ ...initialState, event, [stateKey]: true });
  };

  closeModal = () => this.setState(initialState);

  render() {
    const { event } = this.state;
    const { children, dealerTimezone } = this.props;

    return (
      <ModalContext.Provider value={this.state}>
        {children}
        {modalsConfig.map(([stateKey, Component]) =>
          !!event && !!this.state[stateKey] ? (
            <Component
              key={stateKey}
              event={event}
              onClose={this.closeModal}
              dealerTimezone={dealerTimezone}
            />
          ) : null,
        )}
      </ModalContext.Provider>
    );
  }
}

RotationModalContext.Consumer = ModalContext.Consumer;

import React from 'react';
import { curry } from 'lodash';
import { compose } from 'recompose';

import withGeocoder from 'features/googleMaps/withGeocoder';

import DealerLocatorContext from '../DealerLocatorContext';

import LocationsLogicAndData from './LocationsLogicAndData';

const buildLocationsLogicAndDataChild = curry(
  (WrappedComponent, componentProps, locationsLogicAndDataProps) => (
    <WrappedComponent {...componentProps} {...locationsLogicAndDataProps} />
  ),
);

export const buildConsumerChild = curry(
  (WrappedComponent, componentProps, consumerProps) => (
    <LocationsLogicAndData {...componentProps} {...consumerProps}>
      {buildLocationsLogicAndDataChild(WrappedComponent, componentProps)}
    </LocationsLogicAndData>
  ),
);

const withLocations = (WrappedComponent) => (componentProps) => (
  <DealerLocatorContext.Consumer>
    {buildConsumerChild(WrappedComponent, componentProps)}
  </DealerLocatorContext.Consumer>
);

const withExtraData = (WrappedComponent) =>
  compose(withGeocoder, withLocations)(WrappedComponent);

export default withExtraData;

import gql from 'graphql-tag';
import { get } from 'lodash';
import React from 'react';
import { Query } from '@apollo/client/react/components';

const query = gql`
  query compositions_CaseDealerSelectionPanel_customDealerMileageRanges {
    customDealerMileageRanges {
      mileageRanges {
        id
        max
        min
      }
    }
  }
`;

const withCustomDealerMileageRanges = (WrappedComponent) => (
  componentProps,
) => (
  <Query query={query}>
    {({ data }) => {
      /** @type {MileageRanges} */
      const customDealerMileageRanges = get(
        data,
        'customDealerMileageRanges.mileageRanges',
        [],
      );

      return (
        <WrappedComponent
          {...componentProps}
          customDealerMileageRanges={customDealerMileageRanges}
        />
      );
    }}
  </Query>
);

export default withCustomDealerMileageRanges;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { Container, Column, Row } from 'styled-components-grid';

import CasesDataTable from 'compositions/CasesDataTable';
import withContext from 'utils/withContext';

import Message from './Message';
import Pagination from './Pagination';
import ResultsContainer from './ResultsContainer';
import CasesSearchContext from '../Context';
import {
  SORT_DIRECTIONS,
  SORTABLE_COLUMNS_PER_GROUP,
  DEFAULT_SORT_DIRECTIONS,
} from '../constants';

import { noResultsTitlesForMode, noResultsMessagesForMode } from './constants';

const stickyHeaderStyles = {
  height: 'auto',
  tableLayout: 'fixed',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1,
};

const containerStyles = {
  position: 'relative',
  padding: 0,
  flex: '1 1 auto',
  display: 'flex',
  overflow: 'hidden',
};

export class Results extends Component {
  static propTypes = {
    mode: PropTypes.string.isRequired,
    cases: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    sortBy: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    statusGroup: PropTypes.string.isRequired,
    stickyHeader: PropTypes.bool,
    sortDirection: PropTypes.string.isRequired,
    isAutoRefreshing: PropTypes.bool.isRequired,
    updateSearchParams: PropTypes.func.isRequired,
  };

  static defaultProps = {
    stickyHeader: true,
  };

  handleHeaderCellClick = ({ column: { name: columnName } }) => {
    let { sortBy, sortDirection } = this.props;
    const { statusGroup } = this.props;

    if (!SORTABLE_COLUMNS_PER_GROUP[statusGroup].includes(columnName)) {
      return;
    }

    if (sortBy === columnName) {
      sortDirection =
        sortDirection === SORT_DIRECTIONS.asc
          ? SORT_DIRECTIONS.desc
          : SORT_DIRECTIONS.asc;
    } else {
      sortBy = columnName;
      sortDirection = DEFAULT_SORT_DIRECTIONS[columnName];
    }

    this.props.updateSearchParams({
      sortBy,
      sortDirection,
      before: null,
      after: null,
    });
  };

  render() {
    const { mode, cases, sortBy, isLoading, stickyHeader } = this.props;
    const { sortDirection, statusGroup, isAutoRefreshing } = this.props;

    if (isLoading && !isAutoRefreshing) {
      return (
        <Message
          title={<Trans>Searching...</Trans>}
          description={<Trans>Please wait while we load the results.</Trans>}
        />
      );
    }

    if (!cases.length) {
      const title = noResultsTitlesForMode[mode];
      const description = noResultsMessagesForMode[mode];

      return (
        <Message
          title={<Trans id={title} />}
          description={description ? <Trans id={description} /> : undefined}
        />
      );
    }

    if (!stickyHeader) {
      return (
        <ResultsContainer>
          <CasesDataTable
            style={{ height: 'auto', tableLayout: 'fixed' }}
            sortBy={sortBy}
            entries={cases}
            sortDirection={sortDirection}
            sortableColumns={SORTABLE_COLUMNS_PER_GROUP[statusGroup]}
          />
          <Pagination />
        </ResultsContainer>
      );
    }

    return (
      <Container style={containerStyles}>
        <CasesDataTable
          style={stickyHeaderStyles}
          sortBy={sortBy}
          entries={[]}
          sortDirection={sortDirection}
          sortableColumns={SORTABLE_COLUMNS_PER_GROUP[statusGroup]}
          onHeaderCellClick={this.handleHeaderCellClick}
        />
        <Row style={{ overflowY: 'auto', flex: '0 1 auto', maxHeight: '100%' }}>
          <Column modifiers={['col', 'padScale_0']}>
            <ResultsContainer>
              <CasesDataTable
                style={{ height: 'auto', tableLayout: 'fixed' }}
                sortBy={sortBy}
                entries={cases}
                sortDirection={sortDirection}
                sortableColumns={SORTABLE_COLUMNS_PER_GROUP[statusGroup]}
              />
              <Pagination />
            </ResultsContainer>
          </Column>
        </Row>
      </Container>
    );
  }
}

export default compose(
  setDisplayName('CasesSearch__Results'),
  withContext(CasesSearchContext),
)(Results);

import React from 'react';
import PropTypes from 'prop-types';

import { MessageMedium } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

const PreferencesEmptyState = ({ title }) => (
  <Container>
    <Row modifiers="center">
      <Column modifiers={['col', 'padScale_5']}>
        <MessageMedium>
          <MessageMedium.Header>
            <MessageMedium.Icon name="truck" />
          </MessageMedium.Header>
          <MessageMedium.Section>
            <MessageMedium.Text>{title}</MessageMedium.Text>
          </MessageMedium.Section>
        </MessageMedium>
      </Column>
    </Row>
  </Container>
);

PreferencesEmptyState.propTypes = {
  title: PropTypes.node.isRequired,
};

export default PreferencesEmptyState;

import React from 'react';
import { Query, Mutation } from '@apollo/client/react/components';
import { curry, get, isEmpty } from 'lodash';

import CaseInboundProgramPanelContext from '../CaseInboundProgramPanelContext';

import {
  normalizeInboundProgramLocations,
  findInboundProgramLocationByShipTo,
  getInboundProgramLocationDisplayValue,
} from '../utils';

import caseInboundProgramLocationQuery, {
  NAME as CASE_INBOUND_PROGRAM_LOCATION_QUERY,
} from './caseInboundProgramLocationQuery';
import updateCaseInboundProgramShipToMutation from './updateCaseInboundProgramShipToMutation';

const handleRequestCompletion = curry(
  (
    updateCaseInboundProgramPanelContext,
    locationsDataPath,
    inboundProgramShipToPath,
    accountTypePath,
    data,
  ) => {
    const locations = normalizeInboundProgramLocations(
      get(data, locationsDataPath, []),
    );
    const shipTo = get(data, inboundProgramShipToPath);

    const inboundProgramLocationSearchValue = getInboundProgramLocationDisplayValue(
      shipTo,
      locations,
    );

    const displayInboundProgramLocationSelector =
      !isEmpty(locations) && get(data, accountTypePath) === 'DEALER';

    updateCaseInboundProgramPanelContext({
      displayInboundProgramLocationSelector,
      inboundProgramLocationSearchValue,
    });
  },
);

const buildMutationChild = curry(
  (WrappedComponent, componentProps, updateCaseInboundProgramShipTo) => {
    const { caseId } = componentProps;

    const onSelectInboundProgramLocation = (inboundProgramShipTo) => {
      updateCaseInboundProgramShipTo({
        variables: { caseId, inboundProgramShipTo },
      });
    };

    const clearSelectedInboundProgramLocation = () => {
      updateCaseInboundProgramShipTo({
        variables: { caseId, inboundProgramShipTo: null },
      });
    };

    return (
      <WrappedComponent
        {...componentProps}
        onSelectInboundProgramLocation={onSelectInboundProgramLocation}
        clearSelectedInboundProgramLocation={
          clearSelectedInboundProgramLocation
        }
      />
    );
  },
);

function buildWrappedComponentWithMutation(WrappedComponent, componentProps) {
  const { onRequestCompleted } = componentProps;

  return (
    <Mutation
      mutation={updateCaseInboundProgramShipToMutation}
      onCompleted={onRequestCompleted(
        'updateCase.case.inboundProgramNumber.locations',
        'updateCase.case.inboundProgramShipTo',
        'updateCase.case.inboundProgramNumber.inboundProgramAccountType',
      )}
      refetch={[CASE_INBOUND_PROGRAM_LOCATION_QUERY]}
    >
      {buildMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const locations = normalizeInboundProgramLocations(
      get(queryProps, 'data.case.inboundProgramNumber.locations', []),
    );
    const shipTo = get(queryProps, 'data.case.inboundProgramShipTo');
    const selectedInboundProgramLocation = findInboundProgramLocationByShipTo(
      locations,
      shipTo,
    );

    const displayInboundProgramLocationSelector =
      !isEmpty(locations) &&
      get(
        queryProps,
        'data.case.inboundProgramNumber.inboundProgramAccountType',
      ) === 'DEALER';

    return buildWrappedComponentWithMutation(WrappedComponent, {
      ...componentProps,
      displayInboundProgramLocationSelector,
      selectedInboundProgramLocation,
    });
  },
);

function skipQuery({ caseNumber, inboundProgramNumberId }) {
  return !caseNumber && !inboundProgramNumberId;
}

function buildQueryVariables({ caseNumber, inboundProgramNumberId }) {
  // inboundProgramNumberId is a trick to force a re-render and is not needed by the query.
  return { caseNumber, inboundProgramNumberId };
}

function buildWrappedComponentWithQuery(WrappedComponent, componentProps) {
  const { onRequestCompleted } = componentProps;

  return (
    <Query
      fetchPolicy="cache-and-network"
      query={caseInboundProgramLocationQuery}
      onCompleted={onRequestCompleted(
        'case.inboundProgramNumber.locations',
        'case.inboundProgramShipTo',
        'case.inboundProgramNumber.inboundProgramAccountType',
      )}
      skip={skipQuery(componentProps)}
      variables={buildQueryVariables(componentProps)}
    >
      {buildQueryChild(WrappedComponent, componentProps)}
    </Query>
  );
}

const buildConsumerChild = curry(
  (WrappedComponent, componentProps, consumerProps) => {
    const {
      caseId,
      caseNumber,
      inboundProgramNumberId,
      inboundProgramLocationSearchValue,
      updateCaseInboundProgramPanelContext,
    } = consumerProps;

    const onRequestCompleted = handleRequestCompletion(
      updateCaseInboundProgramPanelContext,
    );

    return buildWrappedComponentWithQuery(WrappedComponent, {
      ...componentProps,
      caseId,
      caseNumber,
      inboundProgramNumberId,
      inboundProgramLocationSearchValue,
      onRequestCompleted,
      updateCaseInboundProgramPanelContext,
    });
  },
);

const withInboundProgramLocationSelect = (WrappedComponent) => (
  componentProps,
) => (
  <CaseInboundProgramPanelContext.Consumer>
    {buildConsumerChild(WrappedComponent, componentProps)}
  </CaseInboundProgramPanelContext.Consumer>
);

export default withInboundProgramLocationSelect;

import React from 'react';
import PropTypes from 'prop-types';

import MessageSmallBlock from '../../blocks/MessageSmall';

function MessageSmall({ children, type }) {
  return (
    <MessageSmallBlock>
      <MessageSmallBlock.Icon type={type} />
      <MessageSmallBlock.Text>{children}</MessageSmallBlock.Text>
    </MessageSmallBlock>
  );
}

MessageSmall.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['danger', 'info', 'success', 'warning']).isRequired,
};

export default MessageSmall;

import React from 'react';
import { Helmet } from 'react-helmet';
import { i18n } from '@lingui/core';

import Page from 'blocks/Page';

import Dealers from 'compositions/TechAdmin/Dealers';

import { pageTitles, pageMeta } from '../../constants';

function DealersPage() {
  return (
    <Page modifiers={['bgChrome100', 'flex_column', 'fluid', 'height_100']}>
      <Helmet>
        <title>{i18n._(pageTitles.techAdminDealerList)}</title>
        <meta
          name="description"
          content={i18n._(pageMeta.techAdminDealerList)}
        />
      </Helmet>
      <Dealers />
    </Page>
  );
}

export default DealersPage;

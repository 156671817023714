import React from 'react';
import PropTypes from 'prop-types';
import { KBarProvider, KBarPortal, KBarPositioner, KBarAnimator } from 'kbar';
import { compose, setDisplayName } from 'recompose';
import styled from 'styled-components';
import { Row, Column } from 'styled-components-grid';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import withUserRole from 'features/rbac/withUserRole';

import { useCleanupCasePanelActions } from './shortcuts/useCaseShortcuts';
import useQuickShortcuts from './shortcuts/useQuickShortcuts';
import useNavigationShortcuts from './shortcuts/useNavigationShortcuts';

import SearchInput from './SearchInput';
import Results from './Results';
import Footer from './Footer';

export { useCaseShortcut, CaseShortcut } from './shortcuts/useCaseShortcuts';
export { QuickActionsShortcut } from './shortcuts/useQuickShortcuts';
export * from './utils';
export * from './constants';

function useEscapeToBlur() {
  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        event.target.blur();
      }
    };

    document?.addEventListener('keydown', handleKeyDown);

    return () => {
      document?.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
}

const Container = buildStyledComponent(
  'KeyboardShortcuts_Container',
  styled(KBarAnimator),
  ({ theme }) => `
    width: 100%;
    max-width: ${px2rem(600)};
    background-color: ${theme.colors.base.background};
    box-shadow: 0 3px 7px ${theme.colors.base.shadow};
  `,
  {
    themePropTypes: {
      colors: PropTypes.shape({
        base: PropTypes.shape({
          background: PropTypes.string.isRequired,
          shadow: PropTypes.string.isRequired,
        }),
      }).isRequired,
    },
  },
);

function CommandBar(props) {
  useQuickShortcuts(props);
  useNavigationShortcuts(props);
  useCleanupCasePanelActions(props);
  useEscapeToBlur();

  return (
    <KBarPortal>
      <KBarPositioner style={{ zIndex: 100 }}>
        <Container>
          <Row>
            <Column modifiers={['flex_1', 'padScale_2']}>
              <SearchInput />
            </Column>
          </Row>
          <Row modifiers={['flex_1']}>
            <Column modifiers={['flex_1', 'padScale_2']}>
              <Results />
            </Column>
          </Row>
          <Row>
            <Column modifiers={['flex_1', 'padScale_0']}>
              <Footer />
            </Column>
          </Row>
        </Container>
      </KBarPositioner>
    </KBarPortal>
  );
}

const CommandBarContainer = compose(
  setDisplayName('CommandBar'),
  withUserRole,
)(CommandBar);

export function KeyShortcutsProvider({ children }) {
  return (
    <KBarProvider actions={[]} options={{ toggleShortcut: 'Control+K' }}>
      <CommandBarContainer />
      {children}
    </KBarProvider>
  );
}

KeyShortcutsProvider.propTypes = {
  children: PropTypes.node,
};

import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import gql from 'graphql-tag';
import { curry } from 'lodash';

import buildRefetchQueries from '../RotationDeleteModal/buildRefetchQueries';

const deleteExceptionMutation = gql`
  mutation deleteAfterHoursEventException($exceptionId: ID!) {
    deleteAfterHoursEventException(input: { ids: [$exceptionId] }) {
      deletedCount
    }
  }
`;

export const buildMutationChild = curry(
  (WrappedComponent, componentProps, deleteAfterHoursEventException) => {
    const { showExceptionConflictModal } = componentProps;
    return (
      <WrappedComponent
        {...componentProps}
        deleteException={async (exception, originalEvent) => {
          try {
            await deleteAfterHoursEventException({
              variables: { exceptionId: exception.id },
            });
          } catch (err) {
            // if an error happens, it's due to a rotation conflict
            showExceptionConflictModal({ exception, originalEvent });
          }
        }}
      />
    );
  },
);

function buildWrappedComponentWithMutation(WrappedComponent, componentProps) {
  const { dealerId, rotation: { blockStart } = {} } = componentProps;
  return (
    <Mutation
      mutation={deleteExceptionMutation}
      refetchQueries={buildRefetchQueries({ event: { dealerId, blockStart } })}
    >
      {buildMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

const withDeleteExceptionMutation = (WrappedComponent) => (componentProps) =>
  buildWrappedComponentWithMutation(WrappedComponent, componentProps);

export default withDeleteExceptionMutation;

import React, { Component, Fragment } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import { t, Trans } from '@lingui/macro';
import { px2rem } from 'decisiv-ui-utils';
import {
  compact,
  get,
  includes,
  isEmpty,
  range,
  isEqual,
  omit,
  uniq,
} from 'lodash';

import {
  P,
  H2,
  Text,
  Icon,
  Checkbox,
  InputGroup,
  QuickActionButton,
} from 'base-components';
import { Column, Row } from 'styled-components-grid';

import Modal from 'components/Modal';
import difference from 'utils/difference';

import LoadingMessage from '../../LoadingMessage';

import TimeSelector from './TimeSelector';
import DateSelector from './DateSelector';
import EventConflicts from './EventConflicts';
import OccurrenceModal from './OccurrenceModal';
import AfterHoursContactSelector from './AfterHoursContactSelector';
import EventConflictsProvider from './EventConflictsProvider';
import {
  daysOfWeek,
  occurrenceKinds,
  getEditRotationDescription,
  getHelperMessage,
} from './constants';

const Emphasis = (
  <Text modifiers="fontWeightMedium" style={{ display: 'inline' }} />
);

function buildEventFromRawEvent({ recurring, ...rest }) {
  const event = { ...rest };

  if (!isEmpty(recurring)) {
    event.recurring = recurring.map((dayOfWeek) => ({ dayOfWeek }));
  }

  return event;
}

function generateTimeOptions(startOfOptions) {
  return range(48).map((index) =>
    moment(startOfOptions).add(index * 30, 'minutes'),
  );
}

function generateEventStartOptions(blockStart) {
  return generateTimeOptions(moment(blockStart).startOf('day'));
}

export function generateEventEndOptions(blockStart) {
  const options = generateTimeOptions(moment(blockStart).add(30, 'minutes'));
  const hasDSTShifted = uniq(options.map((o) => o.isDST()));
  // If there is only one item in hasDSTShifted,
  // it means the array of options has not shifted to DST
  if (hasDSTShifted.length === 1) return options;

  // If DST shifted from false to true, it means the options crossed to DST at some point.
  // We remove the last hour so it isn't possible to select a timespan bigger than 25 hours.
  if (isEqual(hasDSTShifted, [false, true])) {
    return options.slice(0, 46);
  }

  // If DST shifted from true to false it means it just went out of the DST, so the options
  // technically have a timespan of only 23 hours. We add another hour to complete 24 hours.
  return options.concat(
    range(2).map((index) =>
      moment(options[options.length - 1]).add((index + 1) * 30, 'minutes'),
    ),
  );
}

function isBeforeNow(blockStart) {
  return moment(blockStart).isBefore(moment());
}

function isEventValid({
  dealerId,
  endDate,
  endTime,
  finalContactId,
  isRecurring,
  primaryContactId,
  recurring,
  startDate,
  startTime,
}) {
  // missing mandatory fields
  if (
    !dealerId ||
    !endDate ||
    !endTime ||
    !finalContactId ||
    !primaryContactId ||
    !startDate ||
    !startTime
  ) {
    return false;
  }

  // must provide days to recur if event is recurring.
  if (isRecurring && isEmpty(recurring)) {
    return false;
  }

  return true;
}

function hasEventChanged(props, state) {
  const originalState = getInitialStateFromProps(props);
  return !isEqual(
    omit(originalState.event, ['occurrence']),
    omit(state.event, ['occurrence']),
  );
}

function getInitialStateFromProps(props) {
  if (props.variant === 'create') {
    const {
      event: { blockStart, dealerId },
    } = props;

    return {
      blockEnd: null,
      blockStart,
      startTimeInvalid: isBeforeNow(blockStart),
      event: {
        backupContactId: null,
        dealerId,
        endDate: null,
        endTime: null,
        finalContactId: null,
        isRecurring: false,
        primaryContactId: null,
        recurring: [],
        startDate: blockStart.format('YYYY-MM-DD'),
        startTime: blockStart.format('HH:mm:ss'),
        options: {
          recurringStartDate: null,
          recurringEndDate: null,
        },
      },
    };
  }

  const {
    event: { afterHoursEvent: event, start, end, dealerId, occurrence },
  } = props;

  return {
    // If we happened to click on some instance of a repeating
    // event after the initial event, we want to edit either
    // that instance of the event, or upcoming instances of it.
    // The initial event and past instances won't be changed.
    // To reflect that, we're showing the actual start and end dates
    // of the block on the time selection.
    // (see OQA-941 https://servicesolutions.atlassian.net/browse/OQA-941)
    blockEnd: end,
    blockStart: start,
    startTimeInvalid: false,
    event: {
      dealerId,
      endDate: event.endDate,
      endTime: event.endTime,
      id: event.id,
      isRecurring: event.isRecurring,
      primaryContactId: get(event, ['primaryContact', 'id']),
      backupContactId: get(event, ['backupContact', 'id']),
      finalContactId: get(event, ['finalContact', 'id']),
      recurring: (event.recurring || []).map(({ dayOfWeek }) => dayOfWeek),
      startDate: event.startDate,
      startTime: event.startTime,
      options: {
        recurringStartDate:
          get(event, 'options.recurringStartDate') ||
          (event.isRecurring ? start : null),
        recurringEndDate: get(event, 'options.recurringEndDate') || null,
        resolveConflicts: false,
        skipConflicts: false,
      },
      occurrence: occurrence || null,
    },
  };
}

class RotationModal extends Component {
  static propTypes = {
    event: PropTypes.shape({
      blockStart: MomentPropTypes.momentObj.isRequired,
      dealerId: PropTypes.string.isRequired,
      afterHoursEvent: PropTypes.shape({ isRecurring: PropTypes.bool }),
    }).isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    variant: PropTypes.oneOf(['create', 'edit']).isRequired,
    dealerTimezone: PropTypes.string.isRequired,
    isSaving: PropTypes.bool.isRequired,
  };

  state = getInitialStateFromProps(this.props);

  /**
   * Generates an object containing only the changes to the event, plus its ID
   * and the recurring data.
   * @return {object} the changes made to the event.
   * @memberof RotationModal
   */
  getEventDelta = () => {
    const { event: originalEvent } = getInitialStateFromProps(this.props);
    const { event, blockStart, blockEnd } = this.state;

    const kind = get(event, 'occurrence.kind');
    const { range: RANGE } = occurrenceKinds;

    const originalRecurring = {
      recurring: originalEvent.recurring,
      options: {
        ...originalEvent.options,
        resolveConflicts: get(event, 'options.resolveConflicts'),
        skipConflicts: get(event, 'options.skipConflicts'),
      },
    };

    // Always sending the event's start/end date and time in the updates
    // ensures we create an override for the actual date and time
    // that we want to update.
    return {
      id: originalEvent.id,
      startDate: blockStart.format('YYYY-MM-DD'),
      endDate: blockEnd ? blockEnd.format('YYYY-MM-DD') : event.endDate,
      startTime: blockStart.format('HH:mm:ss'),
      endTime: blockEnd ? blockEnd.format('HH:mm:ss') : event.endTime,
      ...difference(
        kind === RANGE ? { ...event, ...originalRecurring } : event,
        originalEvent,
      ),
    };
  };

  /**
   * Toggles the recurring state/ day of week selector.
   * @memberof RotationModal
   */
  toggleRecurring = () => {
    if (this.state.event.isRecurring) {
      return this.updateEvent({
        isRecurring: false,
        recurring: [],
        options: {
          recurringStartDate: null,
          recurringEndDate: null,
        },
      });
    }

    const { blockStart } = this.state;
    const today = moment();
    const recurringStartDate = blockStart.isBefore(today) ? today : blockStart;

    return this.updateEvent({
      isRecurring: true,
      options: {
        recurringStartDate: recurringStartDate.format('YYYY-MM-DD'),
        recurringEndDate: null,
      },
    });
  };

  /**
   * Applies a patch to the nested event object in state.
   * @param {object} patch The updates to apply to the event.
   * @memberof RotationModal
   */
  updateEvent = (patch) => {
    this.setState({ event: { ...this.state.event, ...patch } });
  };

  /**
   * Adds or removes the day from the event.recurring array
   * @param {string} dayOfWeekValue a constant value representing a day of the week
   * @memberof RotationModal
   */
  updateEventRecurring = (dayOfWeekValue) => {
    const {
      event: { recurring },
    } = this.state;
    if (includes(recurring, dayOfWeekValue)) {
      // remove the dayOfWeekValue from event.recurring
      return this.updateEvent({
        recurring: recurring.filter(
          (currentDay) => currentDay !== dayOfWeekValue,
        ),
      });
    }
    // add the dayOfWeekValue to event.recurring
    return this.updateEvent({ recurring: recurring.concat(dayOfWeekValue) });
  };

  updateStartTime = (_, eventStartTimestamp) => {
    const { dealerTimezone } = this.props;
    const {
      event: { startDate },
    } = this.state;

    const blockStart = moment(eventStartTimestamp).tz(dealerTimezone);

    this.setState({
      blockStart,
      blockEnd: null,
      startTimeInvalid: isBeforeNow(blockStart),
    });
    this.updateEvent({
      endDate: null,
      endTime: null,
      startDate,
      startTime: blockStart.format('HH:mm:ss'),
    });
  };

  updateEndTime = (_, eventEndTimestamp) => {
    const { dealerTimezone } = this.props;

    const {
      event: { startDate, endDate },
      blockStart,
    } = this.state;

    const blockEnd = moment(eventEndTimestamp).tz(dealerTimezone);
    this.setState({ blockEnd });

    if (!endDate) {
      this.updateEvent({
        endDate: blockEnd.format('YYYY-MM-DD'),
        endTime: blockEnd.format('HH:mm:ss'),
      });
      return;
    }

    // When a recurring event is created, startDate tends
    // to be the blockStart of the original event's instance.
    // When changing the endDate and endTime, we need to take
    // that into consideration.
    const blockStartEndDiff = blockEnd
      .clone()
      .startOf('day')
      .diff(blockStart.clone().startOf('day'), 'days');

    const eventStartEndDiff = moment(endDate).diff(moment(startDate), 'days');

    this.updateEvent({
      endDate: moment(endDate)
        .add(blockStartEndDiff - eventStartEndDiff, 'days')
        .format('YYYY-MM-DD'),
      endTime: blockEnd.format('HH:mm:ss'),
    });
  };

  updateRecurringStartDate = (startDate) => {
    const options = get(this.state, 'event.options', {});

    options.recurringStartDate = moment(startDate).format('YYYY-MM-DD');

    if (
      options.recurringEndDate &&
      moment(options.recurringEndDate).isSameOrBefore(startDate)
    ) {
      options.recurringEndDate = null;
    }
    this.updateEvent({ options });
  };

  updateRecurringEndDate = (endDate) => {
    const options = get(this.state, 'event.options', {});

    options.recurringEndDate = endDate
      ? moment(endDate).format('YYYY-MM-DD')
      : null;

    this.updateEvent({ options });
  };

  updateOccurrence = (occurrence) => {
    if (occurrence.kind === occurrenceKinds.range) {
      return this.updateEvent({
        occurrence,
        recurring: occurrence.daysOfWeek,
        options: {
          recurringStartDate: occurrence.startDate,
          recurringEndDate: occurrence.endDate,
          resolveConflicts: false,
          skipConflicts: false,
        },
      });
    }

    return this.updateEvent({
      occurrence,
      options: {
        ...this.state.event.options,
        resolveConflicts: false,
        skipConflicts: false,
      },
    });
  };

  clearOccurrence = () => {
    this.setState(getInitialStateFromProps(this.props));
  };

  saveEvent = () => {
    const isCreateVariant = this.props.variant === 'create';

    const rawEvent = isCreateVariant ? this.state.event : this.getEventDelta();
    const event = buildEventFromRawEvent(rawEvent);

    this.props.onSave(event);
  };

  render() {
    const { isSaving } = this.props;

    const { recurringStartDate, recurringEndDate } = get(
      this.state,
      'event.options',
      {},
    );

    const eventDuration =
      this.state.blockEnd &&
      moment
        .duration(this.state.blockEnd.diff(this.state.blockStart))
        .as('hours');

    const isCreateVariant = this.props.variant === 'create';

    // Editing recurrence is available depending on different Occurrence Kinds:
    // if single, recurrence disabled and false
    // if range, recurring disabled and true
    const isRecurring =
      get(this.state.event, 'occurrence.kind') !== occurrenceKinds.single &&
      this.state.event.isRecurring;

    const isRecurrenceReadOnly = [
      occurrenceKinds.range,
      occurrenceKinds.single,
    ].includes(get(this.state.event, 'occurrence.kind'));

    const showOccurrenceModal =
      !isCreateVariant &&
      get(this.props, 'event.afterHoursEvent.isRecurring') &&
      this.state.event.isRecurring &&
      !this.state.event.occurrence;

    const showedOccurrenceModal =
      !isCreateVariant &&
      get(this.props, 'event.afterHoursEvent.isRecurring') &&
      this.state.event.occurrence;

    const modalStyles = showOccurrenceModal
      ? { visibility: 'hidden' }
      : undefined;

    return (
      <Fragment>
        <Modal onClose={this.props.onClose} style={modalStyles}>
          {({ closeModal }) => (
            <Modal.Body>
              <EventConflictsProvider
                event={buildEventFromRawEvent(this.state.event)}
                isEventValid={
                  !showOccurrenceModal &&
                  isEventValid(this.state.event) &&
                  (isCreateVariant || hasEventChanged(this.props, this.state))
                }
              >
                {({ conflictingEvents, checkingConflicts }) => {
                  const {
                    resolveConflicts,
                    skipConflicts,
                  } = this.state.event.options;

                  const eventHasConflicts =
                    !resolveConflicts &&
                    !skipConflicts &&
                    !isEmpty(conflictingEvents);

                  const { startTimeInvalid } = this.state;
                  const helperMessage = getHelperMessage({
                    checkingConflicts,
                    startTimeInvalid,
                    isSaving,
                  });

                  const submitDisabled =
                    isSaving ||
                    checkingConflicts ||
                    eventHasConflicts ||
                    startTimeInvalid ||
                    !isEventValid(this.state.event);

                  return (
                    <Fragment>
                      <Modal.Header>
                        <Modal.HeaderIcon
                          name={isCreateVariant ? 'calendar-plus' : 'calendar'}
                        />
                      </Modal.Header>
                      <Modal.Content>
                        <Row modifiers={['center']}>
                          <Column>
                            <H2 modifiers={['fontWeightRegular']}>
                              {isCreateVariant ? (
                                <Trans>Create After-Hours Rotation</Trans>
                              ) : (
                                <Trans>Edit After-Hours Rotation</Trans>
                              )}
                            </H2>
                          </Column>
                        </Row>
                        <Row
                          modifiers={['center']}
                          style={{ marginBottom: px2rem(20) }}
                        >
                          <Column modifiers={['col']}>
                            {isCreateVariant ? (
                              <P>
                                <Trans>
                                  Select rotation start time, end time, and
                                  contacts. You can also set it as a recurring
                                  rotation, select the days of the week that the
                                  rotation should repeat and also the start and
                                  end date for the recurrence.
                                </Trans>
                              </P>
                            ) : (
                              getEditRotationDescription(
                                this.props.event,
                                this.state.event.occurrence,
                              ).map((paragraph) => (
                                <P
                                  key={paragraph}
                                  style={{ marginBottom: px2rem(10) }}
                                >
                                  <Trans
                                    id={paragraph}
                                    components={[Emphasis]}
                                  />
                                </P>
                              ))
                            )}
                          </Column>
                        </Row>
                        <Row modifiers={['padScaleY_1']}>
                          <Column modifiers={['col']}>
                            <InputGroup.Row>
                              <InputGroup.Column modifiers={['col']}>
                                <TimeSelector
                                  inputId="startTime"
                                  label={<Trans>Start Time</Trans>}
                                  onChange={this.updateStartTime}
                                  options={generateEventStartOptions(
                                    this.state.blockStart,
                                  )}
                                  selected={this.state.blockStart}
                                  dealerId={this.props.event.dealerId}
                                  isInvalid={startTimeInvalid}
                                />
                              </InputGroup.Column>
                              <InputGroup.Column modifiers={['col']}>
                                <TimeSelector
                                  inputId="endTime"
                                  label={<Trans>End Time</Trans>}
                                  onChange={this.updateEndTime}
                                  options={generateEventEndOptions(
                                    this.state.blockStart,
                                  )}
                                  selected={this.state.blockEnd}
                                  selectedStart={this.state.blockStart}
                                  dealerId={this.props.event.dealerId}
                                />
                              </InputGroup.Column>
                            </InputGroup.Row>
                          </Column>
                        </Row>
                        <Row modifiers={['middle', 'padScaleY_1']}>
                          <Column>
                            <Checkbox
                              id="is-recurring"
                              checked={isRecurring}
                              onChange={this.toggleRecurring}
                              label={<Trans>Recurring</Trans>}
                              readOnly={isRecurrenceReadOnly}
                            />
                          </Column>
                          <Column
                            modifiers={['col', 'end']}
                            style={{ textAlign: 'right' }}
                          >
                            <Text modifiers={['small', 'textLight']}>
                              {eventDuration ? (
                                <Trans>Duration: {eventDuration} hours</Trans>
                              ) : (
                                <Trans>Duration: —</Trans>
                              )}
                            </Text>
                          </Column>
                        </Row>
                        {isRecurring && (
                          <Fragment>
                            <Row modifiers={['middle', 'padScaleY_1']}>
                              {daysOfWeek.map((day) => (
                                <Column
                                  key={`day-of-week-checkbox-${day.value}`}
                                >
                                  <Checkbox
                                    id={`day-of-week-checkbox-${day.value}`}
                                    checked={includes(
                                      this.state.event.recurring,
                                      day.value,
                                    )}
                                    label={<Trans id={day.label} />}
                                    onChange={() =>
                                      this.updateEventRecurring(day.value)
                                    }
                                    readOnly={isRecurrenceReadOnly}
                                  />
                                </Column>
                              ))}
                            </Row>

                            <Row modifiers={['padScaleY_1']}>
                              <Column modifiers={['col']}>
                                <InputGroup.Row>
                                  <InputGroup.Column modifiers={['col']}>
                                    <DateSelector
                                      inputId="startDate"
                                      label={<Trans>Start Date</Trans>}
                                      placeholder={t`Select recurrence start date...`}
                                      onChange={this.updateRecurringStartDate}
                                      selected={
                                        recurringStartDate &&
                                        moment(recurringStartDate).toDate()
                                      }
                                      readOnly={isRecurrenceReadOnly}
                                    />
                                  </InputGroup.Column>
                                  <InputGroup.Column modifiers={['col']}>
                                    <DateSelector
                                      inputId="endDate"
                                      label={<Trans>End Date (optional)</Trans>}
                                      helpText={
                                        !recurringEndDate &&
                                        !isRecurrenceReadOnly && (
                                          <Trans>
                                            This rotation will be active for up
                                            to one calendar year from the Start
                                            Date
                                          </Trans>
                                        )
                                      }
                                      placeholder={t`Select recurrence end date...`}
                                      onChange={this.updateRecurringEndDate}
                                      selected={
                                        recurringEndDate &&
                                        moment(recurringEndDate).toDate()
                                      }
                                      initialVisibleDate={
                                        !recurringStartDate ||
                                        moment(recurringStartDate)
                                          .startOf('day')
                                          .isBefore(moment().startOf('day'))
                                          ? moment().toDate()
                                          : moment(recurringStartDate).toDate()
                                      }
                                      disableBeforeDate={
                                        moment(recurringStartDate)
                                          .startOf('day')
                                          .isBefore(moment().startOf('day'))
                                          ? moment().toDate()
                                          : moment(recurringStartDate)
                                              .add(1, 'days')
                                              .toDate()
                                      }
                                      optional
                                      readOnly={isRecurrenceReadOnly}
                                    />
                                  </InputGroup.Column>
                                </InputGroup.Row>
                              </Column>
                            </Row>
                          </Fragment>
                        )}
                        <Row modifiers={['padScaleY_1']}>
                          <Column modifiers={['col']}>
                            <InputGroup.Row>
                              <InputGroup.Column modifiers={['col']}>
                                <AfterHoursContactSelector
                                  inputId="primaryContact"
                                  dealerId={this.props.event.dealerId}
                                  label={<Trans>Primary Contact</Trans>}
                                  onChange={(_, primaryContactId) => {
                                    this.updateEvent({ primaryContactId });
                                  }}
                                  selected={this.state.event.primaryContactId}
                                />
                              </InputGroup.Column>
                            </InputGroup.Row>
                            <InputGroup.Row>
                              <InputGroup.Column modifiers={['col']}>
                                <AfterHoursContactSelector
                                  inputId="backupContact"
                                  dealerId={this.props.event.dealerId}
                                  label={
                                    <Trans>Backup Contact (Optional)</Trans>
                                  }
                                  onChange={(_, backupContactId) => {
                                    this.updateEvent({ backupContactId });
                                  }}
                                  selected={this.state.event.backupContactId}
                                />
                              </InputGroup.Column>
                            </InputGroup.Row>
                            <InputGroup.Row>
                              <InputGroup.Column modifiers={['col']}>
                                <AfterHoursContactSelector
                                  inputId="finalContact"
                                  dealerId={this.props.event.dealerId}
                                  label={<Trans>Final Contact</Trans>}
                                  onChange={(_, finalContactId) => {
                                    this.updateEvent({ finalContactId });
                                  }}
                                  selected={this.state.event.finalContactId}
                                />
                              </InputGroup.Column>
                            </InputGroup.Row>
                          </Column>
                        </Row>
                        {!checkingConflicts && !isEmpty(conflictingEvents) && (
                          <EventConflicts
                            conflictingEvents={conflictingEvents}
                            event={buildEventFromRawEvent(this.state.event)}
                            toggleResolveConflicts={() =>
                              this.updateEvent({
                                options: {
                                  ...this.state.event.options,
                                  resolveConflicts: !resolveConflicts,
                                  skipConflicts: false,
                                },
                              })
                            }
                            toggleSkipConflicts={() =>
                              this.updateEvent({
                                options: {
                                  ...this.state.event.options,
                                  resolveConflicts: false,
                                  skipConflicts: !skipConflicts,
                                },
                              })
                            }
                          />
                        )}
                      </Modal.Content>
                      <Modal.Footer>
                        <Row modifiers={['end']}>
                          {helperMessage.label && (
                            <LoadingMessage
                              message={
                                <Trans
                                  id={helperMessage.label}
                                  values={{
                                    timeNow: moment()
                                      .tz(this.props.dealerTimezone)
                                      .format('h:mm A z'),
                                  }}
                                />
                              }
                              icon={
                                helperMessage.icon && (
                                  <Icon
                                    name={helperMessage.icon || ''}
                                    modifiers={['mini', helperMessage.modifier]}
                                  />
                                )
                              }
                            />
                          )}
                          <Column>
                            {!isSaving && (
                              <QuickActionButton
                                onClick={
                                  showedOccurrenceModal
                                    ? this.clearOccurrence
                                    : closeModal
                                }
                              >
                                <QuickActionButton.Text>
                                  {showedOccurrenceModal ? (
                                    <Trans>Back</Trans>
                                  ) : (
                                    <Trans>Cancel</Trans>
                                  )}
                                </QuickActionButton.Text>
                              </QuickActionButton>
                            )}
                          </Column>
                          <Column>
                            <QuickActionButton
                              disabled={submitDisabled}
                              modifiers={compact([
                                'secondary',
                                submitDisabled && 'disabled',
                              ])}
                              onClick={() => this.saveEvent()}
                            >
                              <QuickActionButton.Text>
                                <Trans>Save Rotation</Trans>
                              </QuickActionButton.Text>
                            </QuickActionButton>
                          </Column>
                        </Row>
                      </Modal.Footer>
                    </Fragment>
                  );
                }}
              </EventConflictsProvider>
            </Modal.Body>
          )}
        </Modal>
        {showOccurrenceModal && (
          <OccurrenceModal
            saveOccurrence={this.updateOccurrence}
            event={get(this.props, 'event.afterHoursEvent')}
            blockStart={this.state.blockStart}
            blockEnd={this.state.blockEnd}
            onClose={this.props.onClose}
          />
        )}
      </Fragment>
    );
  }
}

export default RotationModal;

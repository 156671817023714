import React, { useState, useEffect, useCallback } from 'react';
import gt from 'semver/functions/gt';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Trans } from '@lingui/macro';
import { gql, useLazyQuery } from '@apollo/client';

import { Row, Column } from 'styled-components-grid';
import { H2, Icon, P, QuickActionButton, Text } from 'base-components';

import Modal from 'components/Modal';

const enabled =
  process.env.REACT_APP_ENABLE_CHECK_FOR_UPDATES_WATCHER === 'true';

export const query = gql`
  query webUiVersion {
    version {
      webUi
    }
  }
`;

const getVersion = (data) => get(data, 'version.webUi') || '0.0.0';
const checkDelay = 1000 * 10; // 10 seconds
const pollInterval = 1000 * 60; // 1 minute
const remindLaterDelay = 1000 * 60 * 5; // 5 minutes

// We pass appVersion as an arg, to make testing this easier
export const useVersionCheck = (appVersion) => {
  const [isOutdated, setIsOutdated] = useState(false);

  const [load, { data, stopPolling }] = useLazyQuery(query, {
    pollInterval,
    errorPolicy: 'ignore',
  });

  const [latestVersion, setLatestVersion] = useState(getVersion(data));

  // Check the latest version after a delay. This allows the app to fully load
  // and users to check the version in the left nav if they are refreshing.
  useEffect(() => {
    if (!enabled) return;

    const id = setTimeout(() => load(), checkDelay);

    return () => clearTimeout(id);
  }, [load]);

  useEffect(() => {
    const version = getVersion(data);
    const isOutdated = version !== '0.0.0' && gt(version, appVersion);

    // After showing the modal for the first time, we no longer need to keep
    // checking the latest version and either the user refreshes and we start
    // again, or they click "Remind me" and we just keep toggling the modal.
    if (isOutdated) stopPolling();

    setIsOutdated(isOutdated);
    setLatestVersion(version);
  }, [data, appVersion, stopPolling]);

  const actions = {
    refreshNow: useCallback(() => window.location.reload(), []),

    remindLater: useCallback(() => {
      setIsOutdated(false);
      const id = setTimeout(() => setIsOutdated(true), remindLaterDelay);

      return () => clearTimeout(id);
    }, []),
  };

  return { isOutdated, latestVersion, actions };
};

const AppVersionCheck = ({ appVersion }) => {
  const { isOutdated, latestVersion, actions } = useVersionCheck(appVersion);

  if (!isOutdated) return null;

  return (
    <Modal onClose={actions.remindLater}>
      {() => (
        <Modal.Body>
          <Modal.Header modifiers="danger" showCloseButton={false}>
            <Modal.HeaderIcon name="refresh" />
          </Modal.Header>
          <Modal.Content>
            <Row modifiers="center">
              <Column>
                <H2 modifiers="fontWeightRegular">
                  <Trans>Running outdated version</Trans>
                </H2>
              </Column>
            </Row>
            <Row modifiers="center" style={{ marginTop: 20 }}>
              <Column modifiers="col">
                <P>
                  <Trans>
                    Looks like you are running an outdated version of ONCall.
                    <br />
                    You are running version{' '}
                    <Text modifiers="fontWeightMedium">{appVersion}</Text>, but
                    the latest released version is{' '}
                    <Text modifiers="fontWeightMedium">{latestVersion}</Text>.
                  </Trans>
                </P>
              </Column>
            </Row>
            <Row modifiers="center">
              <Column modifiers="col">
                <P>
                  <Trans>
                    Running an outdated version can lead to errors and data
                    inconsistency problems, so you should update as soon as
                    possible. You might also be missing out on new features.
                  </Trans>
                </P>
              </Column>
            </Row>
            <Row modifiers="center">
              <Column modifiers="col">
                <P>
                  <Trans>
                    To ensure you are running the latest version, keep
                    refreshing the page until "UI version {latestVersion}" is
                    displayed at the bottom of the main navigation bar on the
                    left. If the main navigation bar is collapsed, please click
                    the{' '}
                    <Icon
                      name="chevron-double-right"
                      style={{ verticalAlign: 'middle' }}
                      modifiers={['mini', 'info']}
                    />{' '}
                    button at the bottom to expand it and display the version.
                  </Trans>
                </P>
              </Column>
            </Row>
          </Modal.Content>
          <Modal.Footer>
            <Row modifiers="end">
              <Column>
                <QuickActionButton onClick={actions.remindLater} type="button">
                  <QuickActionButton.Text>
                    <Trans>Remind me in 5 minutes</Trans>
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
              <Column>
                <QuickActionButton
                  type="submit"
                  onClick={actions.refreshNow}
                  modifiers={['hoverDanger', 'secondary']}
                >
                  <QuickActionButton.Text>
                    <Trans>Refresh Now</Trans>
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
            </Row>
          </Modal.Footer>
        </Modal.Body>
      )}
    </Modal>
  );
};

AppVersionCheck.propTypes = { appVersion: PropTypes.string };
AppVersionCheck.defaultProps = { appVersion: process.env.REACT_APP_VERSION };

export default AppVersionCheck;

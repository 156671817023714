import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { compose, setDisplayName } from 'recompose';
import { invert, isFunction, isPlainObject } from 'lodash';

import { DataTable } from 'base-components';
import { buildStyledComponent } from 'decisiv-ui-utils';

import { columns as knownColumns } from './columns';
import { COLUMN_NAMES } from './constants';

/* "hack" to fix this: https://seven.d.pr/fiExPS */
const Container = buildStyledComponent(
  'Container',
  styled.div,
  `
    thead tr { height: 1px; }

    @supports (-moz-appearance: none) {
      thead tr { height: 100%; }
    }

    thead th { height: inherit; }

    tbody td { cursor: pointer; }
  `,
);

const knownColumnNames = Object.values(COLUMN_NAMES);
const invertedColumnNamesEnum = invert(COLUMN_NAMES);

const rowModifiers = ['notFlex'];
const rowKeyGetter = ({ node: { caseNumber } }) => caseNumber;
const rowModifiersGetter = () => rowModifiers;

const buildColumns = (props) => {
  const { columns, sortableColumns, sortBy, sortDirection } = props;

  return columns.reduce((acc, name) => {
    let column = isPlainObject(name)
      ? name
      : knownColumns[invertedColumnNamesEnum[name]];

    if (!column) {
      return acc;
    }

    column = isFunction(column) ? column(props) : column;

    return [
      ...acc,
      {
        ...column,
        ...{
          // whether or not to show sorting icons
          sortable: sortableColumns.includes(column.name),
          // null means show the default icon, when sortable is true
          sortDirection: column.name === sortBy ? sortDirection : null,
        },
      },
    ];
  }, []);
};

export function CasesDataTable(props) {
  const { name, entries, columns, ...rest } = props;

  return (
    <Container>
      <DataTable
        scrollX
        name={name}
        columns={buildColumns(props)}
        tableData={entries}
        modifiers={[]}
        rowKeyGetter={rowKeyGetter}
        rowModifiersGetter={rowModifiersGetter}
        {...rest}
      />
    </Container>
  );
}

CasesDataTable.propTypes = {
  name: PropTypes.string,
  entries: PropTypes.arrayOf(PropTypes.shape()),
  columns: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({ name: PropTypes.string.isRequired }),
      PropTypes.oneOf(knownColumnNames),
    ]),
  ),
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  sortableColumns: PropTypes.arrayOf(PropTypes.oneOf(knownColumnNames)),
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
};

CasesDataTable.defaultProps = {
  name: 'CasesDataTable',
  entries: [],
  columns: knownColumnNames,
  sortBy: undefined,
  sortDirection: undefined,
  sortableColumns: [],
};

export default compose(
  setDisplayName('CasesDataTable'),
  withRouter,
)(CasesDataTable);

import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Text } from 'base-components';
import { Row, Column } from 'styled-components-grid';
import { Trans } from '@lingui/react';

import Emphasis from './Emphasis';
import Item from './Item';

import {
  contactTypes,
  optionsByContactType,
  summaryOptionsLabels,
} from './constants';

const Summary = ({ contactType, contact, transitionTo, loadingEvents }) =>
  optionsByContactType[contactType].map((option) => (
    <Item
      key={option}
      onClick={() => transitionTo(option)}
      modifiers="selectable"
    >
      <Row>
        <Column modifiers={['col', 'padScale_0']}>
          <Text>
            <Trans
              id={summaryOptionsLabels[option]}
              components={[Emphasis]}
              values={{
                contactName: contact.name,
              }}
            />
          </Text>
        </Column>
        <Column modifiers={['padScale_0']}>
          <Icon name="chevron-right" modifiers="mini" />
        </Column>
      </Row>
    </Item>
  ));

Summary.propTypes = {
  transitionTo: PropTypes.func.isRequired,
  contactType: PropTypes.oneOf(Object.values(contactTypes)),
  contact: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    jobTitle: PropTypes.string,
    primaryPhoneNumber: PropTypes.string,
    secondaryPhoneNumber: PropTypes.string,
  }).isRequired,
};

export default Summary;

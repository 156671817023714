import gql from 'graphql-tag';

const query = gql`
  mutation dealerLocator_createRequestLine($caseId: ID!) {
    createCaseRequestLine(input: { caseId: $caseId, requestLine: {} }) {
      requestLine {
        id
      }
    }
  }
`;

export default query;

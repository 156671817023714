import React, { Component, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { compact, noop, omit } from 'lodash';

import { Row } from 'styled-components-grid';

import Input from '../../blocks/Input';

import ActionButton from './ActionButton';
import Avatar from './Avatar';
import Icon from './Icon';
import Label from './Label';
import Text from './Text';
import TextField from './TextField';
import TextArea from './TextArea';
import CharsLeftLabel from './CharsLeftLabel';

const BC_INPUT_FIELD_CONTEXT = '__BC_INPUT_FIELD_CONTEXT__';

const withInputFieldProps = (WrappedComponent, displayName) => {
  function WithInputFieldProps(props, context) {
    const { innerRef, ...rest } = props;
    const propsFromInputField = context[BC_INPUT_FIELD_CONTEXT] || {};

    return (
      <WrappedComponent
        {...propsFromInputField}
        {...rest}
        ref={innerRef}
        value={
          propsFromInputField.readOnly &&
          !propsFromInputField.value &&
          !props.value
            ? props.readOnlyEmptyValue || '—'
            : propsFromInputField.value
        }
      />
    );
  }

  WithInputFieldProps.propTypes = {
    value: PropTypes.string,
    innerRef: PropTypes.func,
    readOnlyEmptyValue: PropTypes.string,
  };

  WithInputFieldProps.defaultProps = {
    value: undefined,
    innerRef: undefined,
    readOnlyEmptyValue: undefined,
  };

  WithInputFieldProps.contextTypes = {
    [BC_INPUT_FIELD_CONTEXT]: PropTypes.shape({
      disabled: PropTypes.bool.isRequired,
      onBlur: PropTypes.func.isRequired,
      onFocus: PropTypes.func.isRequired,
      placeholder: PropTypes.string.isRequired,
      readOnly: PropTypes.bool.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
  };

  const Component = forwardRef((props, ref) => (
    <WithInputFieldProps {...props} innerRef={ref} />
  ));

  Component.displayName = `InputField__${displayName}`;

  return Component;
};

class InputField extends Component {
  static Avatar = Avatar;

  static Icon = Icon;

  static Label = Label;

  static Text = Text;

  // Wrap any component needing the input field's props in withInputFieldProps
  static ActionButton = withInputFieldProps(ActionButton, 'ActionButton');

  static TextField = withInputFieldProps(TextField, 'TextField');

  static TextArea = withInputFieldProps(TextArea, 'TextArea');

  static CharsLeftLabel = withInputFieldProps(CharsLeftLabel, 'CharsLeftLabel');

  static propTypes = {
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    isValid: PropTypes.bool,
    modifiers: PropTypes.arrayOf(PropTypes.string),
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    // eslint-disable-next-line react/no-unused-prop-types
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
    isValid: true,
    modifiers: [],
    onBlur: noop,
    onFocus: noop,
    placeholder: 'Enter Value...',
    readOnly: false,
  };

  static childContextTypes = {
    [BC_INPUT_FIELD_CONTEXT]: PropTypes.shape({}).isRequired,
  };

  state = {
    isFocused: false,
  };

  getChildContext() {
    return {
      [BC_INPUT_FIELD_CONTEXT]: {
        ...omit(this.props, ['children']),
        onBlur: this.handleBlur,
        onFocus: this.handleFocus,
      },
    };
  }

  handleBlur = (e) => {
    !this.props.readOnly && this.props.onBlur(e);
    this.setState(() => ({ isFocused: false }));
  };

  handleFocus = (e) => {
    this.props.onFocus(e);
    this.setState(() => ({ isFocused: true }));
  };

  render() {
    const { isFocused } = this.state;
    const { children, disabled, isValid, modifiers, readOnly } = this.props;

    const borderFocus = isFocused && 'focused';
    const borderWarning = !isValid && 'borderWarning';
    const disabledInput = disabled && 'disabled';
    const readOnlyInput = readOnly && 'readOnly';
    const inputModifiers = compact([
      borderFocus,
      borderWarning,
      disabledInput,
      readOnlyInput,
      'fluid',
      ...modifiers,
    ]);

    const rowModifiers = [
      'height_100',
      // If set to grow in height (i.e. TextAreas), we want
      // the columns to align their content to the top.
      !modifiers.includes('height_auto') && 'middle',
    ];

    return (
      <Input modifiers={inputModifiers} readOnly={readOnly}>
        <Row modifiers={compact(rowModifiers)}>{children}</Row>
      </Input>
    );
  }
}

export default InputField;

import gql from 'graphql-tag';
import { curry, get, pick } from 'lodash';
import React from 'react';
import { Query, Mutation } from '@apollo/client/react/components';

import CustomerWidgetContext from './CustomerWidgetContext';
import updateCaseStoreCustomerMutation from './updateCaseStoreCustomerMutation';

export const buildMutationChild = curry(
  (WrappedComponent, componentProps, updateStoreCustomer) => {
    const {
      caseId,
      setCasePanelComplete,
      updateCustomerWidgetContext,
    } = componentProps;

    return (
      <WrappedComponent
        {...componentProps}
        key={get(componentProps.customer, 'id', '')}
        onSelectCustomer={(customerId, storeCustomerSearchValue, customer) => {
          updateCustomerWidgetContext(
            { storeCustomerSearchValue, customerSearchValue: '' },
            () => {
              setCasePanelComplete();
              updateStoreCustomer({
                variables: {
                  id: caseId,
                  customerId,
                  // We send these extra fields to help with the reporting
                  // See: MICONCALL-1660
                  ...pick(customer, 'zip', 'city', 'state'),
                },
              });
            },
          );
        }}
      />
    );
  },
);

function buildWrappedComponentWithMutation(WrappedComponent, componentProps) {
  const { setCasePanelComplete, updateCustomerWidgetContext } = componentProps;

  return (
    <Mutation
      mutation={updateCaseStoreCustomerMutation}
      onCompleted={(data) => {
        const storeCustomerSearchValue = get(
          data,
          'setCaseStoreCustomer.case.customer.name',
          '',
        );
        if (storeCustomerSearchValue) {
          setCasePanelComplete();
        }
        updateCustomerWidgetContext({ storeCustomerSearchValue });
      }}
    >
      {buildMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const caseId = get(queryProps, 'data.case.id');
    const customer = get(queryProps, 'data.case.customer');

    return buildWrappedComponentWithMutation(WrappedComponent, {
      ...componentProps,
      caseId,
      customer,
    });
  },
);

function skipQuery({ caseNumber }) {
  return !caseNumber;
}

function buildQueryVariables({ caseNumber }) {
  return { caseNumber };
}

export const CASE_STORE_CUSTOMER_QUERY_NAME = 'storeCustomerQuery';

const customerQuery = gql`
  query storeCustomerQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      customer(useCachedDataForCase: true) {
        ... on Customer {
          id
          type
          name
        }
        ... on StoreCustomer {
          id
          type
          name
          zip
          city
          state
          accountNumber
          singleCallRequested
        }
        ... on CustomCustomer {
          type
          name
        }
        ... on CustomStoreCustomer {
          type
          name
        }
      }
    }
  }
`;

function buildWrappedComponentWithQuery(WrappedComponent, componentProps) {
  const {
    setCasePanelComplete,
    setCasePanelIncomplete,
    updateCustomerWidgetContext,
  } = componentProps;
  return (
    <Query
      onCompleted={(data) => {
        const storeCustomerSearchValue = get(data, 'case.customer.name', '');
        if (storeCustomerSearchValue) {
          setCasePanelComplete();
        } else {
          setCasePanelIncomplete();
        }
        updateCustomerWidgetContext({ storeCustomerSearchValue });
      }}
      query={customerQuery}
      skip={skipQuery(componentProps)}
      variables={buildQueryVariables(componentProps)}
      fetchPolicy="cache-and-network"
    >
      {buildQueryChild(WrappedComponent, componentProps)}
    </Query>
  );
}

export const buildConsumerChild = curry(
  (WrappedComponent, componentProps, consumerProps) => {
    const {
      caseNumber,
      setCasePanelComplete,
      setCasePanelIncomplete,
      setCasePanelInvalid,
      updateCustomerWidgetContext,
    } = consumerProps;

    return buildWrappedComponentWithQuery(WrappedComponent, {
      ...componentProps,
      caseNumber,
      setCasePanelComplete,
      setCasePanelIncomplete,
      setCasePanelInvalid,
      updateCustomerWidgetContext,
    });
  },
);

const withStoreCustomerSelect = (WrappedComponent) => (componentProps) => (
  <CustomerWidgetContext.Consumer>
    {buildConsumerChild(WrappedComponent, componentProps)}
  </CustomerWidgetContext.Consumer>
);

export default withStoreCustomerSelect;

import { Trans } from '@lingui/macro';
import { compact, find, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import {
  ButtonLink,
  ButtonSort,
  Checkbox,
  Dropdown,
  Text,
} from 'base-components';
import { Column, Row } from 'styled-components-grid';

import ONCallSquareIcon from 'components/ONCallSquareIcon';

import Ul from 'elements/Ul';
import SelectableLi from 'elements/SelectableLi';

import { getFilterName } from 'utils/dealerFilter';

import {
  buildDealerFiltersArr,
  AllRetreadsFilter,
  selectRetreadFilters,
  unselectRetreadFilters,
  mergeSelectedRetreadFilters,
} from './utils';

export class FeaturesFilterSelectorComponent extends Component {
  static propTypes = {
    /* eslint-disable react/no-unused-prop-types */
    dealerFeatures: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
        dealerFilter: PropTypes.bool.isRequired,
        displayIcon: PropTypes.bool.isRequired,
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
      }),
    ),
    /* eslint-enable */
    onSelectFeatureFilters: PropTypes.func.isRequired,
    selectedFeatureFilters: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
      }),
    ),
  };

  static defaultProps = {
    dealerFeatures: undefined,
    selectedFeatureFilters: [],
  };

  state = {
    arrowUp: false,
    groupedDealerFilters: [],
    workingSelection: this.props.selectedFeatureFilters,
  };

  UNSAFE_componentWillMount() {
    if (this.props.dealerFeatures) {
      const groupedDealerFilters = buildDealerFiltersArr(this.props);
      this.setState({ groupedDealerFilters });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.dealerFeatures !== nextProps.dealerFeatures) {
      const groupedDealerFilters = buildDealerFiltersArr(nextProps);
      this.setState({ groupedDealerFilters });
    }
  }

  toggleArrow = () => this.setState({ arrowUp: !this.state.arrowUp });

  handleApplyClick = () => {
    const selection = this.state.workingSelection.filter(
      (item) => item.id !== AllRetreadsFilter.id,
    );
    this.props.onSelectFeatureFilters(selection);
  };

  handleCheckboxClick = (event) => {
    // to properly handle click events in the SelectableLi__Button,
    // they must be blocked in the checkbox.
    event.preventDefault();
    event.stopPropagation();
  };

  handleClearClick = () => {
    this.setState({ workingSelection: [] });
  };

  handleExpandedChange = (show) => {
    if (show) {
      const { selectedFeatureFilters, dealerFeatures } = this.props;
      return this.setState({
        workingSelection: selectedFeatureFilters.reduce(
          selectRetreadFilters(dealerFeatures),
          [],
        ),
      });
    }
    return this.setState({
      arrowUp: false,
      workingSelection: [],
    });
  };

  handleFilterClicked = (dealerFilter) => {
    const { workingSelection: oldWorkingSelection } = this.state;
    const { dealerFeatures } = this.props;

    if (find(oldWorkingSelection, (val) => val.id === dealerFilter.id)) {
      const workingSelection = oldWorkingSelection
        .filter((val) => val.id !== dealerFilter.id)
        .reduce(unselectRetreadFilters(dealerFilter), []);

      return this.setState({ workingSelection });
    }

    const workingSelection = oldWorkingSelection
      .concat(dealerFilter)
      .reduce(selectRetreadFilters(dealerFeatures), []);

    return this.setState({
      workingSelection: compact(uniqBy(workingSelection, 'id')),
    });
  };

  handleResetFilters = (event) => {
    // Clicking "Clear" button shouldn't pass the click event to ButtonSort
    event.preventDefault();
    event.stopPropagation();

    this.setState({ workingSelection: [] }, this.handleApplyClick);
  };

  renderFilterList = () => {
    const { groupedDealerFilters, workingSelection } = this.state;

    return (
      <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
        {groupedDealerFilters.map(({ dealerFilters, label, type }) => (
          <Fragment key={type}>
            <Row modifiers={['padScaleX_2']}>
              <Column>
                <Text modifiers={['small', 'capitalize', 'textLight']}>
                  <Trans id={label} />
                </Text>
              </Column>
            </Row>
            <Ul>
              {dealerFilters.map((dealerFilter) => {
                const filterSelected = !!find(
                  workingSelection,
                  (val) => val.id === dealerFilter.id,
                );
                return (
                  <SelectableLi
                    key={dealerFilter.id}
                    modifiers={compact([filterSelected && 'selected'])}
                    onClick={() => this.handleFilterClicked(dealerFilter)}
                  >
                    <Row modifiers={['middle', 'padScaleX_2', 'padScaleY_1']}>
                      <Column modifiers={['display_flex', 'height_100']}>
                        <ONCallSquareIcon
                          modifiers={['active']}
                          code={dealerFilter.code}
                        />
                      </Column>
                      <Column modifiers={['col', 'height_100', 'padScaleX_2']}>
                        <Text
                          modifiers={['small']}
                          style={{ whiteSpace: 'pre-wrap' }}
                        >
                          <Trans id={getFilterName(dealerFilter.code)} />
                        </Text>
                      </Column>
                      <Column>
                        <Checkbox
                          id={dealerFilter.id}
                          checked={filterSelected}
                          onClick={this.handleCheckboxClick}
                        />
                      </Column>
                    </Row>
                  </SelectableLi>
                );
              })}
            </Ul>
            <Dropdown.Divider />
          </Fragment>
        ))}
      </div>
    );
  };

  render() {
    const { selectedFeatureFilters } = this.props;
    const { arrowUp } = this.state;

    return (
      <Dropdown arrow onExpandedChange={this.handleExpandedChange}>
        <Dropdown.Target>
          {selectedFeatureFilters.length === 0 ? (
            <ButtonSort onClick={this.toggleArrow}>
              <ButtonSort.Text>
                <Trans>Filters</Trans>
              </ButtonSort.Text>
              <ButtonSort.Icon arrowUp={arrowUp} />
            </ButtonSort>
          ) : (
            <Row modifiers={['middle', 'padScale_0']}>
              <Column modifiers={['padScale_0']}>
                <ButtonSort onClick={this.toggleArrow}>
                  <ButtonSort.Label>
                    <Trans>Filters</Trans>
                  </ButtonSort.Label>
                  <ButtonSort.Text>
                    <Row modifiers={['middle', 'padScale_0']}>
                      {selectedFeatureFilters
                        .reduce(mergeSelectedRetreadFilters, [])
                        .map((featureFilter) => (
                          <Column
                            key={featureFilter.id}
                            modifiers={['display_flex', 'padScale_0']}
                            style={{ marginRight: '2px' }}
                          >
                            <ONCallSquareIcon
                              modifiers={['active']}
                              code={featureFilter.code}
                            />
                          </Column>
                        ))}
                    </Row>
                  </ButtonSort.Text>
                  <ButtonSort.Icon arrowUp={arrowUp} />
                </ButtonSort>
              </Column>
              <Column
                modifiers={['display_flex', 'padScaleX_2', 'padScaleY_0']}
              >
                <ButtonLink onClick={this.handleResetFilters}>
                  <Trans>Clear</Trans>
                </ButtonLink>
              </Column>
            </Row>
          )}
        </Dropdown.Target>
        <Dropdown.Content style={{ width: '300px' }}>
          {this.renderFilterList()}
          <Dropdown.SectionBody>
            <Column modifiers={['col', 'start', 'padScale_0']}>
              <ButtonLink onClick={this.handleClearClick}>
                <Trans>Clear</Trans>
              </ButtonLink>
            </Column>
            <Dropdown.Action modifiers={['col', 'end', 'padScale_0']}>
              <ButtonLink onClick={this.handleApplyClick}>
                <Trans>Apply</Trans>
              </ButtonLink>
            </Dropdown.Action>
          </Dropdown.SectionBody>
        </Dropdown.Content>
      </Dropdown>
    );
  }
}

export default FeaturesFilterSelectorComponent;

/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import { css } from 'styled-components';
import { rgba } from 'polished';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';
import { Container } from 'styled-components-grid';

import ActionButton from './ActionButton';

const modifierConfig = {
  borderWarning: ({ theme }) => ({
    styles: css`
      border-color: ${theme.colors.status.warning};

      &:hover {
        border-color: ${theme.colors.status.warning};
      }
    `,
  }),

  disabled: ({ theme }) => ({
    styles: css`
      border: solid 1px ${theme.colors.base.chrome400};
      opacity: 0.5;
      pointer-events: none;
    `,
  }),

  focused: ({ theme }) => ({
    styles: css`
      border-color: ${theme.colors.base.chrome600};
      z-index: 1;
    `,
  }),

  height_auto: () => ({
    styles: css`
      height: auto;
      min-height: ${px2rem(45)};
    `,
  }),

  readOnly: ({ theme }) => ({
    styles: css`
      background-color: ${rgba(theme.colors.base.chrome100, 0.5)};
      border-color: ${theme.colors.base.chrome200};
      cursor: not-allowed;
      z-index: 0;

      &:hover {
        border-color: ${theme.colors.base.chrome400};
        z-index: 0;
      }

      ${/* sc-sel */ ActionButton} {
        display: none;
        pointer-events: none;
      }
    `,
  }),
};

const styles = ({ theme }) => css`
  background-color: ${theme.colors.base.background};
  border: solid 1px ${theme.colors.base.chrome400};
  height: ${px2rem(45)};
  transition: border-color 200ms ease;

  &:hover {
    border-color: ${theme.colors.base.chrome600};
    z-index: 1;
  }
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      background: PropTypes.string.isRequired,
      chrome100: PropTypes.string.isRequired,
      chrome400: PropTypes.string.isRequired,
      chrome600: PropTypes.string.isRequired,
    }).isRequired,
    status: PropTypes.shape({
      warning: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const Input = buildStyledComponent('Input', Container, styles, {
  modifierConfig,
  themePropTypes,
});

Input.ActionButton = ActionButton;

export default Input;

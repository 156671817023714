import { find, includes, isString } from 'lodash';

import {
  ACTIVE_CONNECTION_STATE_NAMES,
  DISABLED_STATE_NAMES,
  CUSTOM_UI_STATES,
  makeErrorStates,
} from './constants';

/**
 * An Agent status.
 * @typedef {Object} AgentState
 * @property {string} type
 * @property {string} name
 * @property {string} agentStateARN
 * @property {Date} startTimestamp
 */

/**
 * Helper class to wrap some logic / queries specific to Amazon
 * Connect.
 */
export default class AmazonConnectHelper {
  constructor(connect) {
    /** @private */
    this.connect = connect;
    /** @private */
    this.agent = null;
    /** @private */
    this.errorStates = makeErrorStates(connect);
  }

  /**
   * Get the full data about the current agent state.
   * @return {AgentState}
   */
  getCurrentAgentState() {
    const state = this.getAgent().getState();
    return state.agentStateARN ? state : this.getAgentStateByName(state.name);
  }

  /**
   * Get the default routable agent status.
   * @return {AgentState}
   */
  getAvailableState() {
    return this.getAgentStateByType(this.connect.AgentStateType.ROUTABLE);
  }

  /**
   * Get the default offline agent status.
   */
  getOfflineState() {
    return this.getAgentStateByType(this.connect.AgentStateType.OFFLINE);
  }

  /**
   * Get the "Working On Case" agent status.
   * @return {AgentState}
   */
  getWorkingOnCaseState() {
    return this.getAgentStateByName(CUSTOM_UI_STATES.WORKING_ON_CASE);
  }

  /**
   * Change the current agent status to given state. The state
   * can be specified by name or as an Amazon Connect "agent
   * state" object with `type`, `name`, and `agentStateARN`.
   * @param {string|AgentState} newState
   */
  changeAgentState(newState) {
    const state = isString(newState)
      ? this.getAgentStateByName(newState)
      : newState;
    this.getAgent().setState(state);
  }

  /**
   * Get the currently-active voice contact for the agent.
   * @return {connect.Contact}
   */
  getVoiceContact() {
    return (
      this.getAgent().getContacts(this.connect.ContactType.VOICE)[0] ||
      this.getQueueCallBackContact()
    );
  }

  /**
   * Get the currently active contact for a queued call-back.
   * @return {connect.Contact}
   */
  getQueueCallBackContact() {
    return (
      this.getAgent().getContacts(this.connect.ContactType.QUEUE_CALLBACK)[0] ||
      null
    );
  }

  /**
   * Determine the start time at which the agent entered the current
   * Amazon Connect status. If the `stateName` is one indicating an
   * active connection, return the start time of the given connection
   * or contact status, otherwise return the start time of the agent
   * status.
   *
   * @param {string} stateName
   * @return {Date}
   */
  getStateStartTime(stateName) {
    if (includes(DISABLED_STATE_NAMES, stateName)) {
      return null; // time-in-state is not valid for disconnected states
    }

    const voiceContact = this.getVoiceContact();
    if (voiceContact && includes(ACTIVE_CONNECTION_STATE_NAMES, stateName)) {
      const activeConnection =
        voiceContact.getActiveInitialConnection() ||
        voiceContact.getSingleActiveThirdPartyConnection();
      return activeConnection
        ? activeConnection.getStatus().timestamp
        : voiceContact.getStatus().timestamp;
    }

    return this.getAgent().getStatus().startTimestamp;
  }

  /**
   * Get the phone number for the currently-active voice connection.
   * @return {string}
   */
  getContactNumber() {
    const contact = this.getVoiceContact();
    const connection = contact && contact.getInitialConnection();
    return AmazonConnectHelper.getPhoneNumberFromConnection(connection);
  }

  /**
   * Get the phone number for the current third-party connection.
   * @return {string}
   */
  getThirdPartyContactNumber() {
    const contact = this.getVoiceContact();
    const connection = contact && contact.getSingleActiveThirdPartyConnection();
    return AmazonConnectHelper.getPhoneNumberFromConnection(connection);
  }

  /**
   * Get the human-readable error status message for a given agent
   * error state.
   * @param {string} errorState
   * @return {string}
   */
  getCallError(errorState) {
    return this.errorStates[errorState] || this.errorStates.default;
  }

  /*===================================================================
   * PRIVATE HELPERS
   *===================================================================*/

  /**
   * Get the current Agent instance.
   * @private
   * @return {Agent}
   */
  getAgent() {
    this.agent = this.agent || new this.connect.Agent();
    return this.agent;
  }

  /**
   * Look up the first `AgentState` having the given state type.
   * @private
   * @param {string} type
   * @return {AgentState}
   */
  getAgentStateByType(type) {
    return find(this.getAgent().getAgentStates(), ['type', type]);
  }

  /**
   * Look up the `AgentState` matching the given state name.
   * @private
   * @param {string} stateName
   * @return {AgentState}
   */
  getAgentStateByName(stateName) {
    return find(this.getAgent().getAgentStates(), ['name', stateName]);
  }

  /**
   * @private
   * @param {connect.Connection} connection
   * @return {string}
   */
  static getPhoneNumberFromConnection(connection) {
    if (connection) {
      const endpoint = connection.getEndpoint();
      if (endpoint) {
        const result = endpoint.stripPhoneNumber();
        if (result) {
          return result;
        }
      }
    }

    return '';
  }
}

import { get } from 'lodash';
import React from 'react';
import { Query } from '@apollo/client/react/components';

import Loading from 'components/Loading';

import { NOT_FOUND_ERROR } from '../../constants';

const resourceCheck = ({ query, requiredUrlParam }) => (WrappedComponent) => (
  componentProps,
) => {
  const {
    match: {
      params: { [requiredUrlParam]: requiredUrlParamValue },
    },
  } = componentProps;

  return (
    <Query
      query={query}
      variables={{ [requiredUrlParam]: requiredUrlParamValue }}
      skip={!requiredUrlParamValue}
    >
      {(queryProps) => {
        const { error, loading } = queryProps;
        if (loading) {
          return <Loading />;
        }
        if (error) {
          const notFoundError = get(error, 'graphQLErrors', []).find(
            ({ code }) => code === NOT_FOUND_ERROR,
          );
          if (notFoundError) {
            throw new Error('resource not found');
          }
        }
        return <WrappedComponent {...componentProps} />;
      }}
    </Query>
  );
};

export default resourceCheck;

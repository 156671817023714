import React, { useCallback, useContext, useMemo } from 'react';

import { PANEL_STATUSES } from './constants';
import { Context as CaseStatusContext } from './CaseStatusContext';

const validPanelStatus = Object.values(PANEL_STATUSES);
const { complete, incomplete, invalid, partial } = PANEL_STATUSES;

const useCasePanelStatusActions = (panelName) => {
  const context = useContext(CaseStatusContext);
  const { updateCasePanelStatusFactory, casePanelStatuses: statuses } = context;

  // updateCasePanelStatusFactory creates a debounced context
  // updater unique to the scope of the current use of the HOC.
  const updateStatus = useCallback(
    (...args) => updateCasePanelStatusFactory(panelName)(...args),
    [updateCasePanelStatusFactory, panelName],
  );

  const toStatus = useCallback(
    (status, fieldsStatus) => {
      if (validPanelStatus.includes(status)) updateStatus(status, fieldsStatus);
    },
    [updateStatus],
  );

  const toPartial = useCallback((f) => toStatus(partial, f), [toStatus]);
  const toInvalid = useCallback((f) => toStatus(invalid, f), [toStatus]);
  const toComplete = useCallback((f) => toStatus(complete, f), [toStatus]);
  const toIncomplete = useCallback((f) => toStatus(incomplete, f), [toStatus]);

  return useMemo(
    () => ({
      toStatus,
      toPartial,
      toInvalid,
      toComplete,
      toIncomplete,
      statuses: statuses,
    }),
    [toStatus, toPartial, toInvalid, toComplete, toIncomplete, statuses],
  );
};

const withCasePanelStatusActions = (panelName) => (Component) => (props) => {
  const actions = useCasePanelStatusActions(panelName);

  return (
    <Component
      {...props}
      casePanelStatuses={actions.statuses}
      setCasePanelStatus={actions.toStatus}
      setCasePanelPartial={actions.toPartial}
      setCasePanelInvalid={actions.toInvalid}
      setCasePanelComplete={actions.toComplete}
      setCasePanelIncomplete={actions.toIncomplete}
    />
  );
};

export default withCasePanelStatusActions;

import { useCallback } from 'react';
import { useMutation, gql } from '@apollo/client';

const mutation = gql`
  mutation resolveMismatches($id: ID!) {
    clearCaseHighlights(id: $id) {
      id
      highlights {
        id
        type
        message
      }
    }
  }
`;

const useResolveMismatches = (caseId) => {
  const [mutate, { loading }] = useMutation(mutation);

  const run = useCallback(
    () => mutate({ skip: !caseId, variables: { id: caseId } }),
    [mutate, caseId],
  );

  return { run, loading };
};

export default useResolveMismatches;

import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { get, range } from 'lodash';

import { H3 } from 'base-components';
import { Column, Row } from 'styled-components-grid';

import Panel from 'blocks/Panel';

import ScheduleGrid from './ScheduleGrid';
import ScheduleDay from './ScheduleDay';
import RotationModalContext from './RotationModalContext';

/**
 * Overall panel for displaying the store hours and after-hours rotation
 * schedule for a dealer.
 */
export default function SchedulePanel({ data, loading }) {
  const dealer = get(data, 'dealer');
  const dayRange = range(1, 8); // 1 = Monday, 7 = the following Sunday
  const { id, timezone = moment.tz.guess() } = dealer || {};

  return (
    <RotationModalContext dealerId={id} dealerTimezone={timezone}>
      <Panel>
        <Row modifiers="padScale_2">
          <Column modifiers="col">
            <Row>
              <H3 modifiers="fontWeightRegular">
                <Trans>After-Hours Rotation Schedule</Trans>
              </H3>
            </Row>
            <Row modifiers="padScaleY_2">
              <Column modifiers={['col', 'padScale_0']}>
                <ScheduleGrid timezone={timezone}>
                  {loading ? null : (
                    <ScheduleGrid.Navigator>
                      {({ weekStart }) =>
                        dayRange.map((dayNum, index) => (
                          <ScheduleDay
                            key={dayNum}
                            day={moment(weekStart).isoWeekday(dayNum)}
                            dealer={dealer}
                            dayIndex={index}
                            isLastDay={index + 1 === dayRange.length}
                          />
                        ))
                      }
                    </ScheduleGrid.Navigator>
                  )}
                </ScheduleGrid>
              </Column>
            </Row>
          </Column>
        </Row>
      </Panel>
    </RotationModalContext>
  );
}

SchedulePanel.propTypes = {
  data: PropTypes.shape({
    dealer: PropTypes.shape({
      id: PropTypes.string.isRequired,
      timezone: PropTypes.string.isRequired,
    }),
  }),
  loading: PropTypes.bool,
};

SchedulePanel.defaultProps = {
  data: null,
  loading: false,
};

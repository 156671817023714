import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { isPlainObject, omit } from 'lodash';

import mutation from './mutation';
import { validationType } from '../../constants';

const errorMessage = '[setCaseAssetValidation] requires the "caseId" prop.';

const cleanUpAttempt = (attempt) => ({
  ...omit(attempt, ['user', '__typename']),
  input: attempt.input ? omit(attempt.input, '__typename') : null,
  asset: attempt.asset ? omit(attempt.asset, '__typename') : null,
});

const withSetAssetValidation = (Component) => (cProps) => {
  const [mutate, { loading }] = useMutation(mutation);
  const { caseId, assetValidation } = cProps;

  const setCaseAssetValidation = useCallback(
    (data) => {
      if (!caseId) return Promise.reject(new Error(errorMessage));

      const variables = {
        type: validationType.matchAsset,
        ...assetValidation,
        ...data,
        caseId,
      };

      // Transform back to an array, as the backend expects it
      if (isPlainObject(variables.entries)) {
        variables.entries = Object.entries(variables.entries).reduce(
          (acc, [assetId, attempts]) => {
            const cleanedAttempts = attempts.map(cleanUpAttempt);

            return [...acc, { assetId, attempts: cleanedAttempts }];
          },
          [],
        );
      }

      return mutate({ variables });
    },
    [mutate, caseId, assetValidation],
  );

  return (
    <Component
      {...cProps}
      setCaseAssetValidation={setCaseAssetValidation}
      isSettingCaseAssetValidation={!!loading}
    />
  );
};

export default withSetAssetValidation;

import PropTypes from 'prop-types';
import { css } from 'styled-components';

import { Label as DefaultLabel } from 'base-components';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const modifierConfig = {
  withContent: () => ({
    styles: css`
      &::before {
        margin-right: ${px2rem(5)};
      }
    `,
  }),
};

const styles = ({ theme }) => css`
  align-items: center;
  color: ${theme.colors.base.textLight};
  display: flex;
  text-transform: inherit;
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      linkHover: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default buildStyledComponent(
  'RadioButton__Label',
  DefaultLabel,
  styles,
  {
    modifierConfig,
    themePropTypes,
  },
);

import React, { Component } from 'react';
import produce from 'immer';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import {
  compact,
  difference,
  find,
  isEmpty,
  isEqual,
  range,
  sortBy,
  uniqueId,
  zipWith,
} from 'lodash';

import { Column, Row } from 'styled-components-grid';
import { ButtonLink, H3, Icon } from 'base-components';

import Panel from 'blocks/Panel';

import {
  withCasePanelStatusActions,
  withReadOnlyCase,
  CASE_PANELS,
  PANEL_STATUSES,
} from 'compositions/CaseStatus';
import { CaseShortcut, CASE_SHORTCUT_PANELS } from 'features/keyShortcuts';

import ContactDetails from './ContactDetails';
import { getContactStatus, getPanelStatus } from './utils';
import withContacts from './withContacts';

// There are numerous values in this component that are used in getDerivedStateFromProps,
// however, eslint doesn't pick up on them there.
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unused-state */
export class CaseContacts extends Component {
  static propTypes = {
    caseId: PropTypes.string,
    contacts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ).isRequired,
    contactsLoading: PropTypes.bool.isRequired,
    isReadOnlyCase: PropTypes.bool.isRequired,
    setCasePanelStatus: PropTypes.func.isRequired,
  };

  static defaultProps = { caseId: '' };

  static getDerivedStateFromProps(props, state) {
    // initial load is completing:
    // - sees if data has changed and then replaces the cached data
    // - order contacts by created at & map them into the contactForms
    // - update initialLoadComplete flag

    const savedContactForms = compact(
      state.contactForms.map((form) => (form.contact ? form.contact : null)),
    );

    const contactsHasChanged = !isEqual(
      sortBy(props.contacts, ({ createdAt }) => createdAt),
      savedContactForms,
    );

    const loadingComplete =
      !state.initialLoadComplete && !props.contactsLoading;

    if (loadingComplete || contactsHasChanged) {
      const { contacts } = props;
      const contactForms = produce(state.contactForms, (draft) =>
        zipWith(
          draft,
          sortBy(contacts, ({ createdAt }) => createdAt),
          // if there are more than 2 contacts saved, there won't be a
          // contact form present so we need to provide defaults.
          ({ formId = uniqueId() } = {}, contact) => ({
            contact,
            formId,
            status: getContactStatus(contact),
          }),
        ),
      );
      return { contactForms, initialLoadComplete: true };
    }

    // not the initial load:
    if (state.initialLoadComplete) {
      // if handling a newly created contact:
      if (state.creatingContactAtFormId) {
        // get new contact data
        const contactIds = props.contacts.map(({ id }) => id);
        const contactIdsInForms = compact(
          state.contactForms.map(({ contact: { id } = {} }) => id),
        );
        const newContactId = difference(contactIds, contactIdsInForms)[0];
        const contact = find(props.contacts, ({ id }) => id === newContactId);

        if (contact) {
          // connect the new contact data to the correct contact form
          const contactForms = produce(state.contactForms, (draft) =>
            draft.map((contactForm) => {
              if (contactForm.formId === state.creatingContactAtFormId) {
                return {
                  ...contactForm,
                  contact,
                  status: getContactStatus(contact),
                };
              }
              return contactForm;
            }),
          );
          return { contactForms, creatingContactAtFormId: undefined };
        }
      }

      if (!isEmpty(props.contacts.length)) {
        // otherwise:
        // - using the previously existing contactForms value, map the
        //   persisted contacts into the previously existing forms based
        //   on matching formId and contactId
        const contactForms = produce(state.contactForms, (draft) =>
          draft.map((contactForm) => {
            const { contact: { id } = {} } = contactForm;
            if (id) {
              const contact = find(
                props.contacts,
                ({ id: contactId }) => id === contactId,
              );
              return {
                ...contactForm,
                contact,
                status: getContactStatus(contact),
              };
            }
            return { contactForm };
          }),
        );

        return { contactForms };
      }
    }

    return null;
  }

  state = {
    // `contactForms` is a collection where each object has a random ID.
    // This is _only_ used to provide a unique index for deleting/ rerendering
    // empty forms correctly and ensuring contact display order is stable.
    // Form state is NOT managed here.
    contactForms: produce([], () =>
      range(2).map(() => ({
        formId: uniqueId(),
        status: PANEL_STATUSES.incomplete,
      })),
    ),
    // When initial load is first completed any contacts are mapped into the
    // contact forms in order of createdAt. After initial load, the contact
    // order should be stable.
    initialLoadComplete: false,
  };

  componentDidUpdate() {
    const { status, fields } = getPanelStatus(this.state);

    this.props.setCasePanelStatus(status, fields);
  }

  addContactForm = () => {
    if (this.props.isReadOnlyCase || !this.canAddContactForm()) return;

    const contactForms = produce(this.state.contactForms, (draft) =>
      draft.concat([{ formId: uniqueId(), status: PANEL_STATUSES.incomplete }]),
    );
    this.setState({ contactForms });
  };

  canAddContactForm = () => {
    const emptyContactForms = this.state.contactForms.filter(
      ({ contact }) => contact,
    );
    return this.state.contactForms.length - emptyContactForms.length < 2;
  };

  handleCreateContact = (contactFormId) => {
    this.setState({ creatingContactAtFormId: contactFormId });
  };

  handleDeleteContact = (contactFormId) => {
    const contactForms = produce(this.state.contactForms, (draft) => {
      const newContactForms = draft.filter(
        ({ formId }) => formId !== contactFormId,
      );
      return isEmpty(newContactForms)
        ? [{ formId: uniqueId(), status: PANEL_STATUSES.incomplete }]
        : newContactForms;
    });
    this.setState({ contactForms });
  };

  handleValidateContact = (formId, status) => {
    const contactForms = produce(this.state.contactForms, (draft) =>
      draft.map((form) => {
        if (form.formId === formId) {
          return { ...form, status };
        }
        return form;
      }),
    );
    this.setState({ contactForms });
  };

  isContactDeletable = () =>
    !(
      this.state.contactForms.length === 1 &&
      !this.state.contactForms[0].contact
    );

  render() {
    const { contactForms } = this.state;
    const { isReadOnlyCase } = this.props;
    const addContactDisabled = isReadOnlyCase || !this.canAddContactForm();
    const contactDeletable = this.isContactDeletable();

    return (
      <Panel modifiers={['padScaleX_3']} data-testid="CaseContacts">
        <Row>
          <CaseShortcut
            action={{
              parent: CASE_SHORTCUT_PANELS.contact,
              id: 'goToContactAction',
              name: t`Go to Contact`,
              shortcut: ['c', '0'],
              priority: contactForms.length,
              icon: 'arrow-right',
            }}
          >
            {({ onFocusRequested }) => (
              <Column modifiers={['padScaleY_2']} ref={onFocusRequested}>
                <H3 modifiers={['fontWeightRegular']}>
                  <Trans>Contact</Trans>
                </H3>
              </Column>
            )}
          </CaseShortcut>
        </Row>

        {contactForms.map(({ contact = {}, formId }, index) => (
          <Row key={`CaseContacts-ContactDetails-${formId}`}>
            <Column modifiers={['col']}>
              <ContactDetails
                caseId={this.props.caseId}
                contact={contact}
                deletable={contactDeletable}
                formId={formId}
                index={index}
                isReadOnlyCase={isReadOnlyCase}
                onCreate={this.handleCreateContact}
                onDelete={this.handleDeleteContact}
                onValidate={this.handleValidateContact}
              />
            </Column>
          </Row>
        ))}

        <Row>
          <Column modifiers="padScaleY_2" style={{ paddingTop: 0 }}>
            <CaseShortcut
              action={{
                parent: CASE_SHORTCUT_PANELS.contact,
                id: 'addContactAction',
                name: t`Add Another Contact`,
                shortcut: ['a', 'c'],
                priority: 0,
                perform: () => this.addContactForm(),
                deps: addContactDisabled,
              }}
            >
              {({ onFocusRequested }) => (
                <ButtonLink
                  disabled={addContactDisabled}
                  modifiers={compact([
                    'small',
                    addContactDisabled && 'disabled',
                  ])}
                  onClick={this.addContactForm}
                >
                  <Row modifiers={['middle']} ref={onFocusRequested}>
                    <Column modifiers={['padScaleX_0']}>
                      <Icon modifiers={['colorInherit']} name="plus" />
                    </Column>
                    <Column modifiers={['padScaleX_2']}>
                      <Trans>Add Another Contact</Trans>
                    </Column>
                  </Row>
                </ButtonLink>
              )}
            </CaseShortcut>
          </Column>
        </Row>
      </Panel>
    );
  }
}

export default compose(
  setDisplayName('CaseContacts'),
  withContacts,
  withReadOnlyCase,
  withCasePanelStatusActions(CASE_PANELS.contact),
)(CaseContacts);

import React from 'react';
import { curry, get } from 'lodash';
import { Query } from '@apollo/client/react/components';

import CustomerWidgetContext from '../../CustomerWidgetContext';
import caseCustomerTirePreferencesQuery from './caseCustomerTirePreferencesQuery';

import { otherTireTypeKey, specialTireTypes } from './constants';

const specialTireKeys = Object.keys(specialTireTypes);

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, { data }) => {
    const tirePreferences = (
      get(data, 'case.customer.tirePreferences') || []
    ).reduce((acc, { type, tires }) => {
      const finalType =
        specialTireKeys.includes(type) && type !== otherTireTypeKey
          ? type
          : otherTireTypeKey;

      return { ...acc, [finalType]: [...(acc[finalType] || []), ...tires] };
    }, {});

    return (
      <WrappedComponent {...componentProps} tirePreferences={tirePreferences} />
    );
  },
);

function buildWrappedComponentWithQuery(WrappedComponent, componentProps) {
  const { caseNumber, customerId } = componentProps;

  return (
    <Query
      query={caseCustomerTirePreferencesQuery}
      skip={!caseNumber || !customerId}
      /**
       * Pass the customer id, even though it is not used in the query,
       * so that Apollo thinks something has changed and queries new data,
       * but still uses the cache when possible.
       */
      variables={{ caseNumber, customerId }}
      fetchPolicy="cache-and-network"
    >
      {buildQueryChild(WrappedComponent, componentProps)}
    </Query>
  );
}

export const buildConsumerChild = curry(
  (WrappedComponent, componentProps, consumerProps) => {
    const { caseNumber } = consumerProps;

    return buildWrappedComponentWithQuery(WrappedComponent, {
      ...componentProps,
      caseNumber,
      customerId: get(componentProps, 'customer.id'),
    });
  },
);

const withTirePreferences = (WrappedComponent) => (componentProps) => (
  <CustomerWidgetContext.Consumer>
    {buildConsumerChild(WrappedComponent, componentProps)}
  </CustomerWidgetContext.Consumer>
);

export default withTirePreferences;

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

import Divider from './Divider';
import Head from './Head';
import Title from './Title';

const modifierConfig = {
  disabled: () => `
    opacity: 0.5;
    pointer-events: none;
    * {
      pointer-events: none;
  }`,
};

const styles = ({ theme }) => `
  background-color: ${theme.colors.base.background};
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      background: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const Accordion = buildStyledComponent('Accordion', styled.div, styles, {
  modifierConfig,
  themePropTypes,
});

Accordion.Divider = Divider;
Accordion.Head = Head;
Accordion.Title = Title;

export default Accordion;

import { gql } from '@apollo/client';

export const NAME = 'caseDelaysQuery';

export default gql`
  query caseDelaysQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      caseDelays {
        id
        startTime
        endTime
        status
        reasonType
        customReason
        dealerResponse {
          dealer {
            ... on Dealer {
              id
              name
            }
            ... on CustomDealer {
              id
              name
            }
          }
        }
      }
      isDelayed
    }
  }
`;

import { isEmpty, memoize, intersection } from 'lodash';

import { CASE_STATUS } from 'compositions/CaseStatus';

import {
  STATUS_GROUPS,
  DEFAULT_SORT_BY,
  SORT_DIRECTIONS,
  SORTABLE_COLUMNS_PER_GROUP,
  STATUS_GROUPS_NAMES,
  DEFAULT_SORT_DIRECTIONS,
} from './constants';

const statusGroupsNames = Object.values(STATUS_GROUPS_NAMES);
const validSortableDirections = Object.values(SORT_DIRECTIONS);

export const sanitizeSortBy = (sortBy, statusGroup) =>
  SORTABLE_COLUMNS_PER_GROUP[
    isEmpty(statusGroup) ? STATUS_GROUPS_NAMES.open : statusGroup
  ].includes(sortBy)
    ? sortBy
    : DEFAULT_SORT_BY;

export const sanitizeSortDirection = (
  sortDirection,
  sortBy = DEFAULT_SORT_BY,
) =>
  validSortableDirections.includes(sortDirection)
    ? sortDirection
    : DEFAULT_SORT_DIRECTIONS[sortBy];

// Try to recover from "contradicting" values in statusGroup and status.
// For example, if statusGroup says "open" but the only selected status
// is "closed", we can set statusGroup to "closed" instead.
// If there are no contradictions between the values and the value we
// got for statusGroup is a known one, we return it as-is.
export const sanitizeStatusGroup = memoize(
  ({ status, statusGroup }) => {
    let inferredStatusGroup = STATUS_GROUPS_NAMES.open;

    if (status.length === 1 && status[0] === CASE_STATUS.closed) {
      inferredStatusGroup = STATUS_GROUPS_NAMES.closed;
    } else if (
      intersection(status, STATUS_GROUPS.open).length &&
      intersection(status, STATUS_GROUPS.closed).length
    ) {
      inferredStatusGroup = STATUS_GROUPS_NAMES.all;
    }

    if (
      !statusGroupsNames.includes(statusGroup) ||
      !status.every((s) => STATUS_GROUPS[statusGroup].includes(s))
    ) {
      return inferredStatusGroup;
    }

    return statusGroup;
  },
  ({ status, statusGroup }) => `${JSON.stringify(status)}${statusGroup}`,
);

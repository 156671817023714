import React from 'react';
import { Trans } from '@lingui/macro';

import { QuickActionButton, Tooltip } from 'base-components';
import { Column } from 'styled-components-grid';

import GridNavigator from './GridNavigator';

/**
 * The previous week / next week / this week buttons for navigation
 * through the rotation schedule calendar.
 *
 * Uses functions provided by {GridNavigator} to trigger the actual
 * navigation updates.
 *
 * @return {ReactElement}
 */
export default function NavigationButtons() {
  return (
    <GridNavigator.Consumer>
      {({ goToLastWeek, goToNextWeek, goToThisWeek }) => (
        <>
          {/* < GO TO PREVIOUS WEEK */}
          <Column modifiers={['padScaleX_0']}>
            <Tooltip position="top">
              <Tooltip.Target>
                <QuickActionButton
                  modifiers={['iconOnly']}
                  onClick={goToLastWeek}
                >
                  <QuickActionButton.Icon name="chevron-left" />
                </QuickActionButton>
              </Tooltip.Target>
              <Tooltip.Content>
                <Trans>Previous Week</Trans>
              </Tooltip.Content>
            </Tooltip>
          </Column>

          {/* > GO TO NEXT WEEK */}
          <Column modifiers={['padScaleX_half']}>
            <Tooltip position="top">
              <Tooltip.Target>
                <QuickActionButton
                  modifiers={['iconOnly']}
                  onClick={goToNextWeek}
                >
                  <QuickActionButton.Icon name="chevron-right" />
                </QuickActionButton>
              </Tooltip.Target>
              <Tooltip.Content>
                <Trans>Next Week</Trans>
              </Tooltip.Content>
            </Tooltip>
          </Column>

          {/* GO TO TODAY */}
          <Column modifiers={['padScaleX_2']}>
            <QuickActionButton onClick={goToThisWeek}>
              <QuickActionButton.Text>
                <Trans>Today</Trans>
              </QuickActionButton.Text>
            </QuickActionButton>
          </Column>
        </>
      )}
    </GridNavigator.Consumer>
  );
}

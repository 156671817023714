import { Icon, Text } from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Trans } from '@lingui/macro';
import { round } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';
import { Container, Row, Column } from 'styled-components-grid';

import { unitSelector } from 'redux/preferences/selectors';

import countOfTime from 'utils/countOfTime';
import { getUnits } from 'utils/unit';
import { modifiersForComponent } from 'utils/modifiers';

export function TravelEstimate({
  distance,
  distanceFromRoute,
  duration,
  isRouteSearch,
  modifiers,
  unit,
}) {
  const { distanceUnitLabel } = getUnits(unit);
  const durationFormatted = countOfTime(duration * 1000);

  const textModifiers = modifiersForComponent(modifiers, Text);

  return (
    <Container modifiers={['padScale_0']}>
      <Row modifiers={['middle']} style={{ flexWrap: 'nowrap' }}>
        <Column modifiers={['padScale_0']}>
          <Icon name="directions" modifiers={['text']} />
        </Column>
        <Column modifiers={['padScaleY_0']}>
          <Text modifiers={['noWrap', ...textModifiers]}>
            {round(distance, 1)} {distanceUnitLabel} • {durationFormatted}
          </Text>
        </Column>
      </Row>
      {isRouteSearch && (
        <Row style={{ flexWrap: 'nowrap', marginLeft: px2rem(18) }}>
          <Column modifiers={['padScaleY_0']}>
            <Text modifiers={['noWrap', ...textModifiers]}>
              <Trans>
                {`${round(
                  distanceFromRoute,
                  1,
                )} ${distanceUnitLabel} Off Route`}
              </Trans>
            </Text>
          </Column>
        </Row>
      )}
    </Container>
  );
}

TravelEstimate.propTypes = {
  distance: PropTypes.number.isRequired,
  distanceFromRoute: PropTypes.number,
  duration: PropTypes.number.isRequired,
  isRouteSearch: PropTypes.bool,
  modifiers: PropTypes.arrayOf(PropTypes.string),
  unit: PropTypes.string.isRequired,
};

TravelEstimate.defaultProps = {
  distanceFromRoute: undefined,
  isRouteSearch: false,
  modifiers: [],
};

function mapStateToProps(state) {
  return {
    unit: unitSelector(state),
  };
}

export default compose(
  setDisplayName('TravelEstimate'),
  connect(mapStateToProps),
)(TravelEstimate);

import gql from 'graphql-tag';

export const NAME = 'caseAssetValidationQuery';

export default gql`
  query caseAssetValidationQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      status
      inboundProgramNumber(useCachedDataForCase: true) {
        id
        isUsxpress
        assetValidationFields
        requiresAssetValidation
        assetValidationInstructions
        requiresValidationForAssetTypes {
          type
          requirements
        }
        assetValidationFailureInstructions
      }
      assetValidation {
        type
        notes
        assetValidationEntries {
          assetId
          assetValidationEntryAttempts {
            id
            type
            input {
              shipTo
              customer
              vehicleVin
              application
              vehicleType
              vehicleNumber
              domicileLocation
            }
            user {
              name
              email
            }
            timestamp
            reference
            asset {
              shipTo
              customer
              vehicleVin
              application
              vehicleType
              vehicleNumber
              domicileLocation
            }
          }
        }
      }
    }
  }
`;

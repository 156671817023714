import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

const modifierConfig = {
  active: ({ theme }) => `
    background: ${theme.colors.base.chrome100};
  `,

  backgroundHover: ({ theme }) => `
    cursor: default;
    &:hover {
      background: ${theme.colors.base.background};
    }
  `,

  borders: ({ theme }) => `
    border-top: 1px solid ${theme.colors.base.chrome200};
    &:first-of-type {
      border-top: none;
    }
  `,

  borderBottom: ({ theme }) => `
    border-bottom: 1px solid ${theme.colors.base.chrome200};
  `,

  selectableHover: ({ theme }) => `
    cursor: default;
    &:hover {
      background: ${theme.colors.base.selectableHover};
    }
  `,
};

const styles = `
  padding: 0;
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      background: PropTypes.string.isRequired,
      chrome100: PropTypes.string.isRequired,
      chrome200: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      selectableHover: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

// TODO: This element should be in Base Components.
export default buildStyledComponent('Li', styled.li, styles, {
  modifierConfig,
  themePropTypes,
});

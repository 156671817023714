import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';

const MomentOrStringPropType = PropTypes.oneOfType([
  MomentPropTypes.momentString,
  MomentPropTypes.momentObj,
]);

const eventShape = {
  blockStart: MomentPropTypes.momentObj.isRequired,
  blockEnd: MomentPropTypes.momentObj.isRequired,
  start: MomentOrStringPropType.isRequired,
  end: MomentOrStringPropType.isRequired,
  overlap: PropTypes.bool,
  key: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export const ScheduledEventPropType = PropTypes.shape(eventShape);

export const AfterHoursContactPropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  jobTitle: PropTypes.string,
  primaryPhoneNumber: PropTypes.string,
  secondaryPhoneNumber: PropTypes.string,
  canDelete: PropTypes.bool,
});

export const EventPropType = PropTypes.shape({
  ...eventShape,
  primaryContact: AfterHoursContactPropType,
  backupContact: AfterHoursContactPropType,
  finalContact: AfterHoursContactPropType,
});

export const AfterHoursEventPropType = PropTypes.shape({
  startDate: MomentPropTypes.momentString,
  endDate: MomentPropTypes.momentString,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  options: PropTypes.shape({
    recurringStartDate: MomentPropTypes.momentString,
    recurringEndDate: MomentPropTypes.momentString,
  }),
  recurring: PropTypes.arrayOf(
    PropTypes.shape({ dayOfWeek: PropTypes.string }),
  ),
  isRecurring: PropTypes.bool,
  primaryContact: AfterHoursContactPropType,
  backupContact: AfterHoursContactPropType,
  finalContact: AfterHoursContactPropType,
});

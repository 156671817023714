import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { truncate, noop } from 'lodash';

import { px2rem } from 'decisiv-ui-utils';
import { Column, Row } from 'styled-components-grid';
import { Text, ButtonIconRectangle, Popover, Tooltip } from 'base-components';

import {
  getDateAtAssetTZ,
  getDateAtAgentTZ,
} from 'compositions/CaseRequestsPanel/CaseRequestsPanelHeader/utils';

const notesPreviewLength = 36;

const DelayedServiceEntry = (props) => {
  const { delayedServiceScheduledDate: scheduledDate } = props;
  const { delayedServiceNotes, assetLocationTimezone: tz } = props;
  const { delayedServiceScheduledDispatchDate: dispatchDate } = props;

  const notes = delayedServiceNotes || '';

  const scheduledDateAtAgentTZ = getDateAtAgentTZ(scheduledDate, tz);
  const scheduledDateAtAssetTZ = getDateAtAssetTZ(scheduledDate, tz);

  const dispatchDateAtAgentTZ = getDateAtAgentTZ(dispatchDate, tz);
  const dispatchDateAtAssetTZ = getDateAtAssetTZ(dispatchDate, tz);

  const scheduledDisplayDate = (
    <Text modifiers="fontWeightMedium">{scheduledDateAtAgentTZ}</Text>
  );

  const dispatchDisplayDate = (
    <Text modifiers="fontWeightMedium">{dispatchDateAtAgentTZ}</Text>
  );

  const notesPreview =
    notes.length > notesPreviewLength
      ? truncate(notes, { length: notesPreviewLength, separator: /,? +/ })
      : notes;

  return (
    <Row modifiers="middle">
      <Column modifiers="col">
        <Row>
          <Text modifiers="fontWeightMedium">Delayed Service</Text>
        </Row>
        {scheduledDate && (
          <Row>
            <Text modifiers="textLight">
              <Trans>Completion date:</Trans>
            </Text>
            &nbsp;
            {tz ? (
              <Tooltip>
                <Tooltip.Target>{scheduledDisplayDate}</Tooltip.Target>
                <Tooltip.Content>
                  <Trans>
                    Date at asset location: {scheduledDateAtAssetTZ}
                  </Trans>
                </Tooltip.Content>
              </Tooltip>
            ) : (
              scheduledDisplayDate
            )}
          </Row>
        )}
        {dispatchDate && (
          <Row>
            <Text modifiers="textLight">
              <Trans>Dispatch at date:</Trans>
            </Text>
            &nbsp;
            {tz ? (
              <Tooltip>
                <Tooltip.Target>{dispatchDisplayDate}</Tooltip.Target>
                <Tooltip.Content>
                  <Trans>Date at asset location: {dispatchDateAtAssetTZ}</Trans>
                </Tooltip.Content>
              </Tooltip>
            ) : (
              dispatchDisplayDate
            )}
          </Row>
        )}
        {notesPreview && (
          <Row modifiers="middle">
            <Column modifiers="padScale_0">
              <Text modifiers="textLight">{notesPreview}</Text>
            </Column>
            {notesPreview.length < notes.length && (
              <Column modifiers="padScale_0">
                <Popover position="right" showOnHover>
                  <Popover.Target>
                    <ButtonIconRectangle onClick={noop}>
                      <ButtonIconRectangle.Icon
                        name="info-circle-f"
                        modifiers="info"
                      />
                    </ButtonIconRectangle>
                  </Popover.Target>
                  <Popover.Content style={{ width: px2rem(360) }}>
                    <Row>
                      <Column modifiers={['padScale_3', 'flex_1']}>
                        <Text
                          style={{ whiteSpace: 'pre-line' }}
                          modifiers="textLight"
                        >
                          {notes}
                        </Text>
                      </Column>
                    </Row>
                  </Popover.Content>
                </Popover>
              </Column>
            )}
          </Row>
        )}
      </Column>
    </Row>
  );
};

DelayedServiceEntry.propTypes = {
  delayedServiceNotes: PropTypes.string,
  assetLocationTimezone: PropTypes.string,
  delayedServiceScheduledDate: PropTypes.string,
  delayedServiceScheduledDispatchDate: PropTypes.string,
};

DelayedServiceEntry.defaultProps = {
  delayedServiceNotes: '',
  assetLocationTimezone: '',
  delayedServiceScheduledDate: undefined,
  delayedServiceScheduledDispatchDate: undefined,
};

export default DelayedServiceEntry;

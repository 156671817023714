import styled from 'styled-components';
import { buildStyledComponent } from 'decisiv-ui-utils';

import { Label } from '../../elements';

const styles = `
  &:after {
    content: ":";
  }
`;

export default buildStyledComponent('Label', styled(Label), styles);

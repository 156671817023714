import React from 'react';
import { PropTypes } from 'prop-types';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { QuickActionButton } from 'base-components';

import ButtonText from './ButtonText';
import withRestoreCase from './withRestoreCase';

export function CaseRestoreButton({ restoreCase }) {
  return (
    <QuickActionButton
      onClick={restoreCase}
      modifiers={['bgTransparent', 'hoverTransparent']}
    >
      <ButtonText modifiers={['textWhite', 'fontWeightLight']}>
        <Trans>Restore</Trans>
      </ButtonText>
    </QuickActionButton>
  );
}

CaseRestoreButton.propTypes = {
  restoreCase: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('CaseRestoreButton'),
  withRestoreCase,
)(CaseRestoreButton);

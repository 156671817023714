import gql from 'graphql-tag';

export const NAME = 'dealerInfoQuery';

export default gql`
  query dealerInfoQuery($id: ID!) {
    dealer(id: $id) {
      id
      name
      billTo
      shipTo
      displayAddress {
        city
        postalCode
        province
        streetAddress
      }
      phone
      fax
      storeManagerEmail
      timezone
      rotationsEmailSnoozed
      rotationsEmailSnoozedAt
    }
  }
`;

export default function getBrowserLocale(navigatorObject = navigator) {
  let lang;

  if (navigatorObject.languages && navigatorObject.languages.length) {
    // latest versions of Chrome and Firefox set this correctly
    [lang] = navigatorObject.languages;
  } else if (navigatorObject.userLanguage) {
    // IE only
    lang = navigatorObject.userLanguage;
  } else {
    // latest versions of Chrome, Firefox, and Safari set this correctly
    lang = navigatorObject.language;
  }

  return lang;
}

import React from 'react';
import { curry, get } from 'lodash';
import { Query } from '@apollo/client/react/components';

import { CASE_STATUS } from 'compositions/CaseStatus';

import caseStatusQuery from './caseStatusQuery';

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => (
    <WrappedComponent
      {...componentProps}
      caseId={get(queryProps, 'data.case.id')}
      caseStatus={get(queryProps, 'data.case.status', CASE_STATUS.new)}
    />
  ),
);

function buildWrappedComponentWithQuery(WrappedComponent, componentProps) {
  const { caseNumber } = componentProps;

  return (
    <Query
      skip={!caseNumber}
      query={caseStatusQuery}
      variables={{ caseNumber }}
    >
      {buildQueryChild(WrappedComponent, componentProps)}
    </Query>
  );
}

const withCaseStatus = curry(buildWrappedComponentWithQuery);

export default withCaseStatus;

import React from 'react';
import PropTypes from 'prop-types';

import { Row, Column } from 'styled-components-grid';

function StatusBarButtons({ items }) {
  return (
    <Column style={{ paddingRight: 0, marginLeft: 'auto' }} modifiers="end">
      <Row modifiers="end" style={{ flexWrap: 'nowrap' }}>
        {items
          .filter(({ component }) => !!component)
          .map(({ key, component }) => (
            <Column key={key}>{component}</Column>
          ))}
      </Row>
    </Column>
  );
}

StatusBarButtons.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      component: PropTypes.node,
    }),
  ),
};

StatusBarButtons.defaultProps = { items: [] };

export default StatusBarButtons;

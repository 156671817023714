import React from 'react';
import { Helmet } from 'react-helmet';
import { compose, setDisplayName } from 'recompose';
import { i18n } from '@lingui/core';

import { Row } from 'styled-components-grid';

import Page from 'blocks/Page';
import ScrollColumn from 'elements/ScrollColumn';

import DealerDetails from 'compositions/TechAdmin/DealerDetails';

import {
  resourceCheck,
  dealerResourceCheckQuery,
} from 'setup/ResourceErrorBoundary';

import { pageTitles, pageMeta } from '../../constants';

export function DealerDetailsPage() {
  return (
    <Page modifiers={['bgChrome100', 'fluid']}>
      <Helmet>
        <title>{i18n._(pageTitles.techAdminDealerPage)}</title>
        <meta
          name="description"
          content={i18n._(pageMeta.techAdminDealerPage)}
        />
      </Helmet>
      <Row modifiers={['height_100']} style={{ flexWrap: 'nowrap' }}>
        <ScrollColumn modifiers={['col', 'padScale_0']}>
          <DealerDetails />
        </ScrollColumn>
      </Row>
    </Page>
  );
}

export default compose(
  setDisplayName('DealerDetailsPage'),
  resourceCheck({
    query: dealerResourceCheckQuery,
    requiredUrlParam: 'dealerId',
  }),
)(DealerDetailsPage);

import styled from 'styled-components';
import PropTypes from 'prop-types';
import { buildStyledComponent } from 'decisiv-ui-utils';

import Panel from './Panel';
import Body from './Body';
import Header from './Header';

const styles = ({ theme }) => `
  background-color: ${theme.colors.base.background};
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      background: PropTypes.string.isRequired,
    }),
  }),
};

const Layout = buildStyledComponent('Layout', styled.section, styles, {
  themePropTypes,
});

Layout.Panel = Panel;
Layout.Body = Body;
Layout.Header = Header;

export default Layout;

import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import { QuickActionButton, Tooltip } from 'base-components';

function CasePrintButton({ disabled }) {
  const target = (
    <QuickActionButton
      disabled={disabled}
      modifiers={['iconOnly', disabled ? 'disabled' : undefined]}
      onClick={() => {
        window.open(
          `${window.location.origin}${window.location.pathname}/print`,
        );
      }}
    >
      <QuickActionButton.Icon name="print" />
    </QuickActionButton>
  );

  if (disabled) {
    return target;
  }

  return (
    <Tooltip position="top">
      <Tooltip.Target>{target}</Tooltip.Target>
      <Tooltip.Content>
        <Trans>Print</Trans>
      </Tooltip.Content>
    </Tooltip>
  );
}

CasePrintButton.propTypes = {
  disabled: PropTypes.bool,
};

CasePrintButton.defaultProps = {
  disabled: false,
};

export default CasePrintButton;

import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { px2rem } from 'decisiv-ui-utils';
import { Text, Dropdown } from 'base-components';

import Calendar from './Calendar';
import InputField from './InputField';
import { periodsConfig } from './constants';

const shortcutPeriods = Object.entries(periodsConfig).filter(
  ([period]) => period !== 'custom',
);

function DropdowContent(props) {
  const { onChange, onClear, hide, selectedItem } = props;

  const [range, setRange] = useState({
    startDate: props.startDate,
    endDate: props.endDate,
  });

  const { startDate, endDate } = range;

  const onLocalClear = useCallback(
    () => setRange({ startDate: undefined, endDate: undefined }),
    [],
  );

  const onLocalChange = useCallback(
    ({ from, to }) => setRange({ startDate: from, endDate: to }),
    [],
  );

  const applySelectedRange = useCallback(() => {
    onChange(startDate, endDate);
    hide();
  }, [onChange, endDate, hide, startDate]);

  return (
    <>
      <InputField
        {...props}
        startDate={startDate}
        endDate={endDate}
        onClear={onClear}
        selectedItem={selectedItem}
      />

      <Dropdown.Content
        style={{
          width: px2rem(370),
          zIndex: 2,
          maxHeight: 'none',
        }}
      >
        <Dropdown.List>
          {shortcutPeriods.map(([type, { label }]) => (
            <Dropdown.ListItem key={type} id={type}>
              <Text>
                <Trans id={label} />
              </Text>
            </Dropdown.ListItem>
          ))}
          <Dropdown.Divider />
          <Dropdown.ListItem id="custom">
            <Text>
              <Trans id={periodsConfig.custom.label} />
            </Text>
          </Dropdown.ListItem>
        </Dropdown.List>
        {selectedItem === 'custom' && (
          <Calendar
            startDate={startDate}
            endDate={endDate}
            onApply={applySelectedRange}
            onClear={onLocalClear}
            onChange={onLocalChange}
          />
        )}
      </Dropdown.Content>
    </>
  );
}

DropdowContent.propTypes = {
  hide: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  selectedItem: PropTypes.string.isRequired,
};

export default DropdowContent;

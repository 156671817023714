import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import { H2, P, QuickActionButton, Text } from 'base-components';
import { Column, Row } from 'styled-components-grid';

import Modal from 'components/Modal';

export default function UserChangedWarningModal({
  onClose,
  caseNumber,
  caseIsAssigned,
}) {
  return (
    <Modal onClose={onClose}>
      {() => (
        <Modal.Body>
          <Modal.Header showCloseButton={false}>
            <Modal.HeaderIcon name="info-circle" />
          </Modal.Header>
          <Modal.Content>
            <Row modifiers="center">
              <Column>
                <H2 modifiers="fontWeightRegular">
                  {caseIsAssigned ? (
                    <Trans>Case Assigned to Another User</Trans>
                  ) : (
                    <Trans>Case is Not Assigned to You</Trans>
                  )}
                </H2>
              </Column>
            </Row>
            <Row modifiers="center">
              <Column modifiers="col">
                <P>
                  {caseIsAssigned ? (
                    <Trans>
                      An administrator is currently working on case{' '}
                      <Text modifiers="fontWeightMedium">{caseNumber}</Text>.
                      Please come back later.
                    </Trans>
                  ) : (
                    <Trans>
                      Case{' '}
                      <Text modifiers="fontWeightMedium">{caseNumber}</Text> is
                      not assigned to you. You must assign it to yourself before
                      you can make any changes to it.
                    </Trans>
                  )}
                </P>
              </Column>
            </Row>
          </Modal.Content>
          <Modal.Footer>
            <Row modifiers="end">
              <Column>
                <QuickActionButton
                  type="button"
                  onClick={onClose}
                  modifiers="secondary"
                >
                  <QuickActionButton.Text>
                    <Trans>Close</Trans>
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
            </Row>
          </Modal.Footer>
        </Modal.Body>
      )}
    </Modal>
  );
}

UserChangedWarningModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  caseNumber: PropTypes.string.isRequired,
  caseIsAssigned: PropTypes.bool,
};

UserChangedWarningModal.defaultProps = {
  caseIsAssigned: true,
};

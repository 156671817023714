import gql from 'graphql-tag';
import { curry, get } from 'lodash';
import React from 'react';
import { Query, Mutation } from '@apollo/client/react/components';

import CustomerWidgetContext from './CustomerWidgetContext';

const buildMutationChild = curry(
  (WrappedComponent, componentProps, updateCaseCustomStoreCustomer) => {
    const {
      caseId,
      setCasePanelComplete,
      updateCustomerWidgetContext,
    } = componentProps;

    return (
      <WrappedComponent
        {...componentProps}
        onUpdateCustomer={({ customerName, storeAccountNumber }) => {
          setCasePanelComplete();
          updateCustomerWidgetContext({ isNewCustomStoreCustomer: false });
          updateCaseCustomStoreCustomer({
            variables: { caseId, customerName, storeAccountNumber },
          });
        }}
      />
    );
  },
);

const updateCaseCustomStoreCustomerMutation = gql`
  mutation updateCaseCustomStoreCustomer(
    $caseId: ID!
    $customerName: String!
    $storeAccountNumber: String
  ) {
    setCaseNewStoreCustomer(
      input: {
        id: $caseId
        patch: { name: $customerName, accountNumber: $storeAccountNumber }
      }
    ) {
      case {
        id
        fixpixPushResult
        customer(useCachedDataForCase: true) {
          ... on Customer {
            id
            type
            name
          }
          ... on StoreCustomer {
            id
            type
            name
          }
          ... on CustomCustomer {
            type
            name
          }
          ... on CustomStoreCustomer {
            type
            name
            accountNumber
          }
        }
        paymentMethod
      }
    }
  }
`;

function buildWrappedComponentWithMutation(WrappedComponent, componentProps) {
  const { setCasePanelComplete } = componentProps;

  return (
    <Mutation
      mutation={updateCaseCustomStoreCustomerMutation}
      onCompleted={(data) => {
        const customerName = get(
          data,
          'setCaseNewStoreCustomer.case.customer.name',
        );
        if (customerName) {
          setCasePanelComplete();
        }
      }}
    >
      {buildMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const caseId = get(queryProps, 'data.case.id');
    const customer = get(queryProps, 'data.case.customer');

    return buildWrappedComponentWithMutation(WrappedComponent, {
      ...componentProps,
      caseId,
      customer,
    });
  },
);

function skipQuery({ caseNumber }) {
  return !caseNumber;
}

function buildQueryVariables({ caseNumber }) {
  return { caseNumber };
}

const customStoreCustomerQuery = gql`
  query customStoreCustomerQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      customer(useCachedDataForCase: true) {
        ... on Customer {
          id
          type
          name
        }
        ... on StoreCustomer {
          id
          type
          name
        }
        ... on CustomCustomer {
          type
          name
        }
        ... on CustomStoreCustomer {
          type
          name
          accountNumber
        }
      }
    }
  }
`;

function buildWrappedComponentWithQuery(WrappedComponent, componentProps) {
  const { setCasePanelComplete, setCasePanelIncomplete } = componentProps;

  return (
    <Query
      fetchPolicy="cache-and-network"
      onCompleted={(data) => {
        const customerName = get(data, 'case.customer.name');
        if (customerName) {
          setCasePanelComplete();
        } else {
          setCasePanelIncomplete();
        }
      }}
      query={customStoreCustomerQuery}
      skip={skipQuery(componentProps)}
      variables={buildQueryVariables(componentProps)}
    >
      {buildQueryChild(WrappedComponent, componentProps)}
    </Query>
  );
}

const buildConsumerChild = curry(
  (WrappedComponent, componentProps, consumerProps) => {
    const {
      caseNumber,
      isNewCustomStoreCustomer,
      setCasePanelComplete,
      setCasePanelIncomplete,
      setCasePanelInvalid,
      updateCustomerWidgetContext,
    } = consumerProps;

    return buildWrappedComponentWithQuery(WrappedComponent, {
      ...componentProps,
      caseNumber,
      isNewCustomStoreCustomer,
      setCasePanelComplete,
      setCasePanelIncomplete,
      setCasePanelInvalid,
      updateCustomerWidgetContext,
    });
  },
);

const withCustomStoreCustomer = (WrappedComponent) => (componentProps) => (
  <CustomerWidgetContext.Consumer>
    {buildConsumerChild(WrappedComponent, componentProps)}
  </CustomerWidgetContext.Consumer>
);

export default withCustomStoreCustomer;

import React, { useCallback } from 'react';
import { useMutation, gql } from '@apollo/client';

import { RequestLineFieldsFragment } from '../constants';
import { NAME as REQUEST_LINES_QUERY } from '../withCaseRequestLines';

const refetchQueries = [REQUEST_LINES_QUERY];

const mutation = gql`
  mutation resetAllSuppliedLines($caseId: ID!) {
    resetAllSupplied(input: { caseId: $caseId }) {
      requestSuppliedLines {
        supplied
        suppliedLine {
          ...RequestLineFields
        }
      }
    }
  }
  ${RequestLineFieldsFragment}
`;

const withRemoveCaseSuppliedLineMutation = (Component) => (props) => {
  const [mutate] = useMutation(mutation, { refetchQueries });

  const resetAllLines = useCallback(
    (caseId) => mutate({ variables: { caseId } }),
    [mutate],
  );

  return <Component {...props} resetAllRequestSuppliedLines={resetAllLines} />;
};

export default withRemoveCaseSuppliedLineMutation;

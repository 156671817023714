import React from 'react';
import { Query } from '@apollo/client/react/components';
import { curry, get, map } from 'lodash';

import { usePagination } from 'compositions/CaseActivityPanel/utils';

import dealerHistoryQuery from './dealerHistoryQuery';
import { processNodes } from './utils';

const ITEMS_PER_PAGE = 50;
const edgesPath = 'dealer.history.edges';
const pageInfoPath = 'dealer.history.pageInfo';

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const { sortDirection } = componentProps;
    const edges = get(queryProps.data, edgesPath) || [];
    const nodes = map(edges, 'node');
    const results = processNodes(nodes, sortDirection.toLowerCase());

    const pager = usePagination({
      ...queryProps,
      edgesPath,
      pageInfoPath,
      itemsPerPage: ITEMS_PER_PAGE,
      sortDirection,
    });

    // Ask for older results if we got no
    // results due to us filtering them all out.
    if (!results.length && pager.pagination.hasNextPage) {
      pager.loadOlderResults();
    }

    return (
      <WrappedComponent
        {...componentProps}
        results={results}
        isLoading={!!queryProps.loading}
        {...pager}
      />
    );
  },
);

const withDealerHistory = (WrappedComponent) => (componentProps) => (
  <Query
    skip={!componentProps.dealerId}
    query={dealerHistoryQuery}
    variables={{
      dealerId: componentProps.dealerId,
      first: ITEMS_PER_PAGE,
      sortDirection: componentProps.sortDirection,
    }}
    fetchPolicy="cache-and-network"
  >
    {buildQueryChild(WrappedComponent, componentProps)}
  </Query>
);

export default withDealerHistory;

import { has, omit } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';

import RotationModalComponent from './Component';
import createAfterHoursEventMutationGQL from './createAfterHoursEventMutation';
import updateAfterHoursEventMutationGQL from './updateAfterHoursEventMutation';
import scheduleChangeRefetch from '../scheduleChangeRefetch';

/**
 * Builds the refetch needed on a schedule edit or create. The refetch is
 * based on the "block start" - the start time of the schedule grid block
 * that was clicked to initial the create or edit modal - since this time
 * indicates a time actually currently visible in the schedule grid. All
 * events for the week that includes this clicked time will be refetched.
 *
 * @param blockStart The start of the block that was clicked to open the modal
 * @param dealerId The current dealer ID
 * @return {function}
 */
function buildRefetchQueries({ event: { blockStart, dealerId } }) {
  return () => scheduleChangeRefetch(blockStart, dealerId);
}

/**
 * The rotation-editing modal, wrapped in the mutation required for
 * creating a NEW rotation.
 */
function CreateRotationModal(props) {
  const buildVariables = (event) => ({ event });

  return (
    <Mutation
      mutation={createAfterHoursEventMutationGQL}
      onCompleted={() => props.onClose()}
      refetchQueries={buildRefetchQueries(props)}
    >
      {(createAfterHoursEvent, { loading }) => (
        <RotationModalComponent
          {...props}
          onSave={(event) => {
            const variables = buildVariables(event);
            createAfterHoursEvent({ variables });
          }}
          isSaving={loading}
          variant="create"
        />
      )}
    </Mutation>
  );
}

CreateRotationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

/**
 * The rotation-editing modal, wrapped in the mutation required for
 * editing an EXISTING rotation.
 */
function EditRotationModal(props) {
  const buildVariables = (event) => ({
    afterHoursEventId: event.id,
    patch: omit(event, ['dealerId', 'id']),
  });

  return (
    <Mutation
      mutation={updateAfterHoursEventMutationGQL}
      onCompleted={() => props.onClose()}
      refetchQueries={buildRefetchQueries(props)}
    >
      {(updateAfterHoursEvent, { loading }) => (
        <RotationModalComponent
          {...props}
          onSave={(event) => {
            const variables = buildVariables(event);
            updateAfterHoursEvent({ variables });
          }}
          isSaving={loading}
          variant="edit"
        />
      )}
    </Mutation>
  );
}

EditRotationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

function isNewEvent(event) {
  return !has(event, ['afterHoursEvent', 'id']);
}

/**
 * Based on whether the event included in the `props` is a NEW or EXISTING
 * event, renders the appropriate modal.
 */
function RotationEditModal(props) {
  const { event } = props;
  return isNewEvent(event) ? (
    <CreateRotationModal {...props} />
  ) : (
    <EditRotationModal {...props} />
  );
}

RotationEditModal.propTypes = {
  event: PropTypes.shape({
    afterHoursEvent: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default RotationEditModal;

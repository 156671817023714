import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { get, capitalize, lowerCase } from 'lodash';

import query from './requestLineOptionsQuery';
import { optionsByType, optionsTransformersByType } from './constants';

export const optionTypes = [
  'rimType',
  'axleType',
  'tirePosition',
  'tireCondition',
  'requestedAction',
];

const optionPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
);

export const requestLineOptionsPropType = PropTypes.shape(
  optionTypes.reduce((acc, type) => ({ ...acc, [type]: optionPropType }), {}),
);

export const getScalarTitle = (type, name) =>
  get(optionsByType, `${type}.${name}`, capitalize(lowerCase(name)));

const mapScalarOptions = (options, type) =>
  options.map(({ name }) => ({ id: name, title: getScalarTitle(type, name) }));

const buildOptions = (data) =>
  optionTypes.reduce((acc, type) => {
    let options = get(data, `${type}.enumValues`, []);
    const transformer = optionsTransformersByType[type];

    if (transformer) options = transformer(options);

    return { ...acc, [type]: mapScalarOptions(options, type) };
  }, {});

const withRequestLineOptions = (Component) => (props) => {
  const { data, loading } = useQuery(query);
  const options = useMemo(() => buildOptions(data), [data]);

  return (
    <Component
      {...props}
      requestLineOptions={options}
      isLoadingRequestLineOptions={loading}
    />
  );
};

export default withRequestLineOptions;

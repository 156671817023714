export default [
  'address-book',
  'antenna',
  'antenna-times',
  'archive',
  'archive-arrow-down',
  'archive-arrow-up',
  'arrow-bottom-left-corner',
  'arrow-bottom-right-corner',
  'arrow-compress',
  'arrow-down',
  'arrow-expand',
  'arrow-left',
  'arrow-right',
  'arrow-top-left-corner',
  'arrow-top-right-corner',
  'arrow-up',
  'ban',
  'bar-chart',
  'bars',
  'bell',
  'briefcase',
  'briefcase-plus',
  'briefcase-times',
  'building',
  'bullhorn',
  'bullhorn-times',
  'calculator',
  'calendar',
  'calendar-check',
  'calendar-exclamation',
  'calendar-plus',
  'calendar-times',
  'camera',
  'check',
  'check-circle',
  'check-circle-f',
  'check-partial-shield',
  'check-shield',
  'checklist',
  'chevron-double-left',
  'chevron-double-right',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'circle',
  'circle-f',
  'circle-half-f',
  'clear-day',
  'clear-night',
  'clipboard-check',
  'clipboard-exclamation',
  'clipboard-times',
  'clock',
  'cloudy',
  'cog',
  'comment',
  'comment-plus',
  'copy',
  'credit-card',
  'credit-card-check',
  'credit-card-exclamation',
  'credit-card-plus',
  'credit-card-times',
  'delivery-cart',
  'directions',
  'door-enter',
  'door-exit',
  'download',
  'ellipsis-h',
  'ellipsis-v',
  'engine',
  'envelope',
  'envelope-check',
  'envelope-open',
  'envelope-plus',
  'envelope-times',
  'eraser',
  'exclamation',
  'exclamation-circle',
  'exclamation-circle-f',
  'exclamation-partial-shield',
  'exclamation-shield',
  'exclamation-triangle',
  'external-link',
  'eye',
  'eye-slash',
  'fallback',
  'finish-line',
  'flag',
  'floppy',
  'fog',
  'folder',
  'funnel',
  'grid',
  'hand-paper',
  'hand-spock',
  'headset',
  'home',
  'hourglass',
  'hourglass-slash',
  'info',
  'info-circle',
  'info-circle-f',
  'key',
  'keypad',
  'lightbulb',
  'lightbulb-slash',
  'line-chart',
  'link',
  'list',
  'lock',
  'lock-open',
  'map-pin',
  'microphone',
  'microphone-slash',
  'minus',
  'minus-circle',
  'money',
  'money-check',
  'newspaper',
  'note',
  'oil-can',
  'paperclip',
  'partly-cloudy-day',
  'partly-cloudy-night',
  'pause',
  'pause-circle',
  'pencil',
  'phone',
  'phone-arrow',
  'phone-arrow-in',
  'phone-arrow-times',
  'phone-hangup',
  'pin',
  'plus',
  'plus-circle',
  'plus-circle-f',
  'print',
  'question',
  'question-circle',
  'rain',
  'raindrop',
  'receipt',
  'record',
  'redo',
  'refresh',
  'reply',
  'revert',
  'road',
  'road-exit',
  'rocket',
  'search',
  'send',
  'send-check',
  'send-times',
  'service-provider',
  'sleet',
  'snow',
  'snowflake',
  'sort',
  'sort-asc',
  'sort-desc',
  'spaces',
  'star',
  'sticky-note',
  'sticky-note-plus',
  'sticky-note-times',
  'stopwatch',
  'tachometer',
  'thermometer-full',
  'thumbs-down',
  'thumbs-up',
  'times',
  'times-circle',
  'times-partial-shield',
  'times-shield',
  'tools',
  'traffic-light',
  'traffic-light-slash',
  'trash',
  'truck',
  'undo',
  'unlink',
  'unpin',
  'upload',
  'user',
  'user-arrow-right',
  'user-check',
  'user-plus',
  'user-times',
  'users',
  'vert',
  'video-camera',
  'wind',
];

import PropTypes from 'prop-types';
import { get } from 'lodash';

import { buildStyledComponent } from 'decisiv-ui-utils';
import { P } from 'base-components';

import { AFTER_HOURS_COLOR_KEY, OPEN_HOURS_COLOR_KEY } from '../constants';

const blockLabelModifiers = {
  openHours: ({ theme }) => `
    color: ${get(theme, ['colors', 'status', OPEN_HOURS_COLOR_KEY])};
  `,
  ersHours: ({ theme }) => `
    color: ${get(theme, ['colors', 'status', AFTER_HOURS_COLOR_KEY])};
  `,
  hidden: () => `
    display: none;
  `,
  inline: () => `
    display: inline;
  `,
};

export default buildStyledComponent(
  'ScheduleGrid__EventBlock__Header',
  P,
  `
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `,
  {
    modifierConfig: blockLabelModifiers,
    themePropTypes: {
      colors: PropTypes.shape({
        status: PropTypes.shape({
          [OPEN_HOURS_COLOR_KEY]: PropTypes.string.isRequired,
          [AFTER_HOURS_COLOR_KEY]: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    },
  },
);

import { t } from '@lingui/macro';

export const noResultsTitlesForMode = {
  default: t`No Matches Found`,
  highlightedOnly: t`No cases to show at the moment`,
};

export const noResultsMessagesForMode = {
  default: t`Try removing or editing some of your filters, or search by a different field.`,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { includes, isFunction } from 'lodash';

import PopoverBlock from '../../blocks/Popover';

import Content from './Content';
import Target from './Target';

import { POPOVER_CONTEXT } from './constants';

class Popover extends Component {
  static Content = Content;

  static Target = Target;

  static modifiers = PopoverBlock.modifiers;

  static childContextTypes = {
    [POPOVER_CONTEXT]: PropTypes.shape({}).isRequired,
  };

  static propTypes = {
    arrow: PropTypes.bool,
    arrowColor: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    hoverHideDelay: PropTypes.number,
    position: PropTypes.oneOf([
      'bottom',
      'bottomLeft',
      'bottomRight',
      'left',
      'leftBottom',
      'leftTop',
      'right',
      'rightBottom',
      'rightTop',
      'top',
      'topLeft',
      'topRight',
    ]),
    removeOnHide: PropTypes.bool,
    showOnHover: PropTypes.bool,
    showPopover: PropTypes.bool,
    zIndex: PropTypes.number,
  };

  static defaultProps = {
    arrow: true,
    arrowColor: undefined,
    hoverHideDelay: undefined,
    position: 'top',
    removeOnHide: true,
    showOnHover: false,
    showPopover: false,
    zIndex: 1,
  };

  state = {
    showPopover: this.props.showPopover,
  };

  getChildContext() {
    return {
      [POPOVER_CONTEXT]: {
        arrow: this.props.arrow,
        arrowColor: this.props.arrowColor,
        handleHover: this.handleHover,
        handleLeave: this.handleLeave,
        handleToggle: this.togglePopover,
        hoverHideDelay: this.props.hoverHideDelay,
        position: this.props.position,
        removeOnHide: this.props.removeOnHide,
        showOnHover: this.props.showOnHover,
        showPopover: this.state.showPopover,
        zIndex: this.props.zIndex,
      },
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Allow manual opening of the popover from the outside by updating
   * the `showPopover` prop.
   */
  UNSAFE_componentWillReceiveProps({ showPopover }) {
    this.setState({ showPopover: this.state.showPopover || showPopover });
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);

    clearTimeout(this.hideTimeout);
  }

  setRef = (node) => {
    this.ref = node;

    return this.ref;
  };

  handleClickOutside = (e) => {
    if (this.state.showPopover && !this.ref.contains(e.target)) {
      this.hidePopover();
    }
  };

  handleHover = () => this.props.showOnHover && this.showPopover();

  handleLeave = () => {
    const { showOnHover, hoverHideDelay } = this.props;

    if (!showOnHover || !this.state.showPopover) return;

    hoverHideDelay
      ? (this.hideTimeout = setTimeout(this.hidePopover, hoverHideDelay))
      : this.hidePopover();
  };

  showPopover = () => {
    clearTimeout(this.hideTimeout);
    !this.state.showPopover && this.setState({ showPopover: true });
  };

  hidePopover = () => {
    clearTimeout(this.hideTimeout);
    this.state.showPopover && this.setState({ showPopover: false });
  };

  togglePopover = () => {
    this.state.showPopover ? this.hidePopover() : this.showPopover();
  };

  filterPopoverModifiers() {
    const { position } = this.props;
    const popoverModifiers = Object.keys(PopoverBlock.modifiers);

    return includes(popoverModifiers, position) ? position : undefined;
  }

  render() {
    const { children, ...rest } = this.props;

    return (
      <PopoverBlock
        ref={this.setRef}
        modifiers={this.filterPopoverModifiers()}
        {...rest}
      >
        {isFunction(children)
          ? children({
              show: this.showPopover,
              hide: this.hidePopover,
              toggle: this.togglePopover,
              isVisible: this.state.showPopover,
            })
          : children}
      </PopoverBlock>
    );
  }
}

export default Popover;

import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';
import { H2, Text, QuickActionButton } from 'base-components';

import Modal from 'components/Modal';

import ValueLabel from './ValueLabel';

function PreferenceValue({ value, style }) {
  return (
    <Text style={{ whiteSpace: 'pre-line', ...style }}>
      {value || <>&mdash;</>}
    </Text>
  );
}

PreferenceValue.propTypes = {
  value: PropTypes.string,
  style: PropTypes.shape({}),
};
PreferenceValue.defaultProps = { value: '', style: {} };

function PreferenceDetailsModal({
  title,
  preference,
  iconName,
  rowsConfig,
  onClose,
}) {
  return (
    <Modal onClose={onClose}>
      {() => (
        <Modal.Body>
          <Modal.Header modifiers="info">
            <Modal.HeaderIcon name={iconName} />
          </Modal.Header>
          <Modal.Content>
            <Row style={{ marginBottom: px2rem(20) }}>
              <Column>
                <H2 modifiers="fontWeightRegular">{title}</H2>
              </Column>
            </Row>

            <>
              {rowsConfig.map(({ label, valueProp, mapValue, textStyle }) => (
                <Row key={label}>
                  <Column modifiers="col_3">
                    <ValueLabel style={textStyle}>
                      <Trans id={label} />
                    </ValueLabel>
                  </Column>
                  <Column modifiers="col">
                    <PreferenceValue
                      value={
                        mapValue
                          ? mapValue(preference[valueProp])
                          : preference[valueProp]
                      }
                      style={textStyle}
                    />
                  </Column>
                </Row>
              ))}
            </>
          </Modal.Content>
          <Modal.Footer>
            <Row modifiers="end">
              <Column>
                <QuickActionButton onClick={onClose} type="button">
                  <QuickActionButton.Text>
                    <Trans>Close</Trans>
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
            </Row>
          </Modal.Footer>
        </Modal.Body>
      )}
    </Modal>
  );
}

PreferenceDetailsModal.propTypes = {
  title: PropTypes.node.isRequired,
  iconName: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  preference: PropTypes.shape({}).isRequired,
  rowsConfig: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      valueProp: PropTypes.string.isRequired,
      mapValue: PropTypes.func,
      textStyle: PropTypes.shape({}),
    }),
  ).isRequired,
};

PreferenceDetailsModal.defaultProps = {
  iconName: 'truck',
};

export default PreferenceDetailsModal;

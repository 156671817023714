/* istanbul ignore file */
import { filter, isEmpty } from 'lodash';

export function distanceBetween(fromLatLng, toLatLng) {
  const EarthRadiusMeters = 6378137.0; // meters
  const lat1 = fromLatLng.lat();
  const lon1 = fromLatLng.lng();
  const lat2 = toLatLng.lat();
  const lon2 = toLatLng.lng();
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = EarthRadiusMeters * c;

  return d;
}

/**
 * http://www.geocodezip.com/scripts/v3_epoly.js
 */
export function GetPointAtDistance(polyline, metres) {
  const path = polyline.getPath();
  if (metres === 0) return path.getAt(0);
  if (metres < 0) return null;
  if (path.getLength() < 2) return null;
  let dist = 0;
  let olddist = 0;
  let i;

  for (i = 1; i < path.getLength() && dist < metres; i += 1) {
    olddist = dist;
    dist += distanceBetween(path.getAt(i), path.getAt(i - 1));
  }
  if (dist < metres) {
    return null;
  }
  const p1 = path.getAt(i - 2);
  const p2 = path.getAt(i - 1);
  const m = (metres - olddist) / (dist - olddist);

  return {
    lat: p1.lat() + (p2.lat() - p1.lat()) * m,
    lng: p1.lng() + (p2.lng() - p1.lng()) * m,
  };
}

/**
 * https://stackoverflow.com/questions/23176555/midpoint-of-route-in-google-maps/23185777#23185777
 */
export function getMiddlePoint({ googleMaps, result }) {
  const polyline = new googleMaps.Polyline({
    path: [],
  });
  const { distance, steps } = result.routes[0].legs[0];

  for (let j = 0; j < steps.length; j += 1) {
    const nextSegment = steps[j].path;
    for (let k = 0; k < nextSegment.length; k += 1) {
      polyline.getPath().push(nextSegment[k]);
    }
  }

  const totalDist = distance.value;

  return GetPointAtDistance(polyline, (totalDist * 50) / 100);
}

/**
 * https://developers.google.com/maps/documentation/javascript/examples/geometry-encodings
 */
export function getEncodedPolyline(googleMaps, results) {
  const coordinates = filter(
    results.map((value) => {
      if (value.location && value.location.latitude) {
        return {
          lat: value.location.latitude,
          lng: value.location.longitude,
        };
      }
      return {};
    }),
    (location) => !isEmpty(location),
  );

  if (coordinates.length === 0) {
    return '';
  }

  const poly = new googleMaps.Polyline({
    path: coordinates,
  });
  const path = poly.getPath();
  const encodeString = googleMaps.geometry.encoding.encodePath(path);

  return encodeString;
}

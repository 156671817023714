import React, { useCallback, useContext } from 'react';

import { Context as DealerLocatorContext } from './DealerLocatorContext';
import { defaultLocationSearch, LOCATION_SEARCHES } from './constants';

// eslint-disable-next-line react/prop-types
const ComponentWithIsRouteSearch = ({ Component, ...rest }) => {
  const contextProps = useContext(DealerLocatorContext);

  const { [LOCATION_SEARCHES]: locationSearches } = contextProps;
  const [assetLocationSearch] = locationSearches;
  const { isRouteSearch, updateDealerLocatorContext } = contextProps;

  const toggleIsRouteSearch = useCallback(
    (isAreaSearch) => {
      // If isAreaSearch is true, only keep the assetLocationSearch.
      const locationSearches = isAreaSearch
        ? [assetLocationSearch]
        : [assetLocationSearch, defaultLocationSearch];

      return updateDealerLocatorContext({
        isRouteSearch: !isAreaSearch,
        highlightedDealer: undefined,
        [LOCATION_SEARCHES]: locationSearches,
      });
    },
    [assetLocationSearch, updateDealerLocatorContext],
  );

  return (
    <Component
      {...rest}
      isRouteSearch={isRouteSearch}
      toggleIsRouteSearch={toggleIsRouteSearch}
    />
  );
};

const withIsRouteSearch = (Component) => (props) => (
  <ComponentWithIsRouteSearch Component={Component} {...props} />
);

export default withIsRouteSearch;

import { px2rem } from 'decisiv-ui-utils';

/**
 * The fixed width of the row headings (time labels down the left side
 * of the schedule grid).
 * @type {string}
 */
export const ROW_HEADING_WIDTH = px2rem(50);

const oneDaySeconds = 24 * 60 * 60; // one day, in seconds

/**
 * Each vertical block of time in the grid covers this many seconds.
 * @type {number}
 */
export const SECONDS_PER_TIME_BLOCK = 30 * 60; // 30 minutes, in seconds

/**
 * The fixed height (in pixels) of each vertical block of time in the grid.
 * @type {number}
 */
export const TIME_BLOCK_HEIGHT = 14; // height of a 30-minute block, in pixels

/**
 * The number of vertical blocks of time in each column of the grid (i.e. the
 * number of time blocks in a day).
 * @type {number}
 */
export const TIME_BLOCK_COUNT = oneDaySeconds / SECONDS_PER_TIME_BLOCK;

/**
 * The number of vertical time blocks between grid lines.
 * @type {number}
 */
export const TIME_BLOCKS_PER_DIVIDER = 4;

/**
 * The total fixed height of the entire schedule grid.
 * @type {string}
 */
export const GRID_HEIGHT = `${TIME_BLOCK_HEIGHT * TIME_BLOCK_COUNT}px`;

/**
 * The fixed height of the column headers (for days of the week) across the
 * top of the schedule grid.
 * @type {string}
 */
export const DAY_HEADER_HEIGHT = px2rem(30);

/**
 * Amount of time in the grid (in seconds) spanned by an on-hover "add"
 * button used for adding rotation events.
 * @type {number}
 */
export const SECONDS_PER_ADD_BUTTON = 60 * 60; // one hour, in seconds

import { buildStyledComponent } from 'decisiv-ui-utils';
import PropTypes from 'prop-types';
import { Container } from 'styled-components-grid';

const styles = ({ theme }) => `
  background: ${theme.colors.base.background};
  padding: calc(${theme.dimensions.gridPad} * 10);
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      background: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dimensions: PropTypes.shape({
    gridPad: PropTypes.string.isRequired,
  }).isRequired,
};

export default buildStyledComponent('Modal__Content', Container, styles, {
  themePropTypes,
});

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isNumber } from 'lodash';

import Label from './Label';

class CharsLeftLabel extends Component {
  static propTypes = {
    value: PropTypes.string,
    autoHide: PropTypes.bool,
    children: PropTypes.func,
    maxLength: PropTypes.number,
    hideDelay: PropTypes.number,
  };

  static defaultProps = {
    value: '',
    autoHide: true,
    children: undefined,
    hideDelay: 1000,
    maxLength: undefined,
  };

  state = { show: this.props.autoHide === false };

  UNSAFE_componentWillReceiveProps({ value, autoHide, hideDelay, maxLength }) {
    if (this.props.value === value || !autoHide) {
      return;
    }

    this.clearTimeout();

    if (isNumber(maxLength)) {
      this.setState({ show: true }, () => {
        if (autoHide) {
          this.hideTimeout = setTimeout(() => {
            this.setState({ show: false });
          }, hideDelay);
        }
      });
    }
  }

  componentWillUnmount() {
    this.clearTimeout();
  }

  clearTimeout = () => clearTimeout(this.hideTimeout);

  renderContent = () => {
    const { maxLength, value, children } = this.props;
    const remaining = maxLength - value.length;

    if (children) {
      return children(remaining);
    }

    return remaining;
  };

  render() {
    const { show } = this.state;
    const { maxLength } = this.props;

    if (!maxLength) return null;

    return <Label modifiers={['end']}>{show && this.renderContent()}</Label>;
  }
}

export default CharsLeftLabel;

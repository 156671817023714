import { Trans } from '@lingui/macro';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EditDeletableItem from 'components/EditDeletableItem';

import NoteDeleteModal from './NoteDeleteModal';
import NoteView from './NoteView';

class Note extends Component {
  static propTypes = {
    note: PropTypes.shape({
      author: PropTypes.shape({
        email: PropTypes.string,
        name: PropTypes.string,
      }).isRequired,
      body: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired,
  };

  state = {
    editing: false,
    deleting: false,
  };

  startEditing = () => {
    this.setState({ editing: true });
  };

  stopEditing = () => {
    this.setState({ editing: false });
  };

  toggleDeleting = () => {
    this.setState({ deleting: !this.state.deleting });
  };

  render() {
    const { note } = this.props;
    const { editing, deleting } = this.state;

    return (
      <EditDeletableItem
        onEditItem={this.startEditing}
        onDeleteItem={this.toggleDeleting}
        editTooltip={<Trans>Edit Note</Trans>}
        deleteTooltip={<Trans>Delete Note</Trans>}
        deleteEnabled
        showButtons={!editing}
        tooltipPosition="top"
      >
        <NoteView
          editing={editing}
          note={note}
          onStopEditing={this.stopEditing}
        />
        {deleting && (
          <NoteDeleteModal note={note} onCloseModal={this.toggleDeleting} />
        )}
      </EditDeletableItem>
    );
  }
}

export default Note;

import moment from 'moment-timezone';
import { compact, invert, pick, values } from 'lodash';

import {
  billableStatusTypes,
  configTypes,
  customConfigVarsByType,
  reportingPeriodTypes,
} from './constants';

const {
  today,
  yesterday,
  last7days,
  last30days,
  last90days,
} = reportingPeriodTypes;

const distanceInDaysByPeriod = {
  [today]: 0,
  [yesterday]: 1,
  [last7days]: 6,
  [last30days]: 29,
  [last90days]: 89,
};

const formatDateToAPI = (date) => date.format('YYYY-MM-DD');

export const getCommonConfigVars = ({
  [configTypes.billableStatus]: billableStatus,
  [configTypes.reportingPeriod]: reportingPeriod,
  [configTypes.customReportingPeriod]: customReportingPeriod = {},
}) => {
  const billable =
    billableStatus === billableStatusTypes.any
      ? null
      : billableStatus === billableStatusTypes.billable;

  let startDate = moment();
  let endDate = moment();

  if (reportingPeriod === reportingPeriodTypes.custom) {
    if (!customReportingPeriod.from) {
      return { billable };
    }

    startDate = moment(customReportingPeriod.from);
    endDate = moment(customReportingPeriod.to || customReportingPeriod.from);
  } else {
    startDate.subtract(distanceInDaysByPeriod[reportingPeriod], 'days');

    if ([today, yesterday].includes(reportingPeriod)) {
      endDate = startDate.clone();
    }
  }

  return {
    billable,
    startTime: formatDateToAPI(startDate),
    endTime: formatDateToAPI(endDate),
  };
};

export const formatReportingPeriod = (config, dateFormat = 'D MMM YYYY') => {
  const { startTime, endTime } = getCommonConfigVars(config);

  if (!startTime || !endTime) {
    return '';
  }

  const fromDate = moment(startTime);
  const toDate = moment(endTime);

  return [fromDate, fromDate.isSame(toDate, 'day') ? null : toDate]
    .filter(Boolean)
    .map((date) => date.format(dateFormat))
    .join(' - ');
};

export const getConfigVarsForType = (type, config) => {
  const configVars = [
    ...(customConfigVarsByType[type] || []),
    ...(config.customConfigVars || []),
  ];

  return pick(config, ...configVars);
};

export const getInvalidConfigKeys = (config, reportType) => {
  const {
    [configTypes.customReportingPeriod]: customReportingPeriod,
    [configTypes.reportingPeriod]: reportingPeriod,
  } = config;

  const invalidKeys = [
    ...['reportingPeriod', 'billableStatus', 'exportType'].map((key) =>
      !config[configTypes[key]] ? configTypes[key] : undefined,
    ),

    ...(customConfigVarsByType[reportType] || []).map((key) => {
      const value = config[key];
      const searchValue = config[`${key}_SEARCH_VALUE`];

      return !!searchValue && searchValue !== value ? key : undefined;
    }),
  ];

  if (
    reportingPeriod === reportingPeriodTypes.custom &&
    !compact(values(customReportingPeriod)).length
  ) {
    invalidKeys.push(configTypes.reportingPeriod);
  }

  return compact(invalidKeys);
};

const invertedConfigTypes = invert(configTypes);
const configKeys = Object.keys(invertedConfigTypes);

export const mapConfigKeysToVariables = (config, valueTransformers = {}) =>
  Object.entries(config).reduce((variables, [key, value]) => {
    const transform = valueTransformers[key];
    const finalValue = transform ? transform(value) : value;

    return {
      ...variables,
      [configKeys.includes(key) ? invertedConfigTypes[key] : key]: finalValue,
    };
  }, {});

import React from 'react';
import PropTypes from 'prop-types';

import { Column } from 'styled-components-grid';

import LeftPanelWrapper from './LeftPanelWrapper';

export default function InfoPopoverLeftPanel({
  modifiers,
  colorKey,
  children,
}) {
  return (
    <Column modifiers={['padScale_0', ...modifiers]}>
      <LeftPanelWrapper colorKey={colorKey}>{children}</LeftPanelWrapper>
    </Column>
  );
}

InfoPopoverLeftPanel.propTypes = {
  colorKey: PropTypes.string,
  children: PropTypes.node.isRequired,
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

InfoPopoverLeftPanel.defaultProps = {
  colorKey: 'base.background',
  modifiers: [],
};

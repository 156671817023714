import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import { Label } from 'base-components';
import { Row, Column } from 'styled-components-grid';

const SectionHeader = React.forwardRef(({ label }, ref) => {
  return (
    <Row modifiers={['padScale_2', 'middle']} ref={ref}>
      <Column>
        <Label modifiers={['uppercase']}>
          <Trans id={label} />
        </Label>
      </Column>
    </Row>
  );
});

SectionHeader.propTypes = {
  label: PropTypes.string.isRequired,
};

export default SectionHeader;

import React from 'react';
import PropTypes from 'prop-types';

import { Text } from 'base-components';
import { Column, Row } from 'styled-components-grid';
import { px2rem } from 'decisiv-ui-utils';

import Spinner from './Spinner';

/**
 * Loading message with spinner used by Editing and Deleting after-hour
 * rotations modals, and deleting contacts modal.
 */
export default function LoadingMessage({ message, icon, centerMessage }) {
  return (
    <Column modifiers={['col', 'center']}>
      <Row
        modifiers={['middle', centerMessage && 'center']}
        style={{ lineHeight: px2rem(11.5) }}
      >
        <Column
          modifiers={
            icon ? ['padScale_0'] : ['padScaleY_0', 'padScaleX_1', 'center']
          }
        >
          {icon ? icon : <Spinner />}
        </Column>
        <Column modifiers={['padScaleY_0']}>
          <Text>{message}</Text>
        </Column>
      </Row>
    </Column>
  );
}

LoadingMessage.propTypes = {
  message: PropTypes.node.isRequired,
  icon: PropTypes.node,
  centerMessage: PropTypes.bool,
};

LoadingMessage.defaultProps = {
  icon: undefined,
  centerMessage: false,
};

import { compact, noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import CheckboxBlock from '../../blocks/Checkbox';

function Checkbox({
  checked,
  disabled,
  id,
  label,
  onChange,
  onClick,
  readOnly,
  ...rest
}) {
  return (
    <CheckboxBlock
      modifiers={compact([disabled && 'disabled', readOnly && 'readOnly'])}
    >
      <CheckboxBlock.Label
        htmlFor={id}
        modifiers={compact([label && 'withContent'])}
      >
        <CheckboxBlock.Input
          {...rest}
          checked={checked}
          disabled={disabled}
          id={id}
          onChange={!disabled && !readOnly ? onChange : noop}
          onClick={!disabled && !readOnly ? onClick : noop}
          readOnly={readOnly}
        />
        <span>{label}</span>
      </CheckboxBlock.Label>
    </CheckboxBlock>
  );
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool,
};

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  label: undefined,
  onChange: noop,
  onClick: noop,
  readOnly: false,
};

export default Checkbox;

import bounceIn from './bounceIn';
import bounceOut from './bounceOut';
import spin from './spin';

export { bounceIn, bounceOut, spin };

export default {
  bounceIn,
  bounceOut,
  spin,
};

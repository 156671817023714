import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, findIndex, get } from 'lodash';
import { Trans } from '@lingui/macro';

import GhostDealerList from './GhostDealerList';
import DealerList from './DealerList';
import ErrorMessage from './ErrorMessage';

const joinSearchesValues = (values) => {
  if (values.length === 1) return `"${values[0].searchValueTried}"`;

  return values.map(({ searchValueTried }, index) => (
    <Fragment key={searchValueTried}>
      &quot;
      {searchValueTried}
      &quot;
      {index < values.length - 1 && (
        <>
          {index !== values.length - 2 && <>,&nbsp;</>}
          {index === values.length - 2 && (
            <>
              &nbsp;
              <Trans id="or" />
              &nbsp;
            </>
          )}
        </>
      )}
    </Fragment>
  ));
};

export class SearchResultsListComponent extends PureComponent {
  static propTypes = {
    dealers: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string, location: PropTypes.shape() }),
    ),
    dealersError: PropTypes.shape({
      stack: PropTypes.string,
      graphQLErrors: PropTypes.arrayOf(
        PropTypes.shape({ code: PropTypes.string }),
      ),
    }),
    dealersPending: PropTypes.bool,
    displayDetailViewEnabled: PropTypes.bool.isRequired,
    highlightDealer: PropTypes.func.isRequired,
    highlightedDealer: PropTypes.shape({ id: PropTypes.string.isRequired }),
    isRouteSearch: PropTypes.bool.isRequired,
    selectDealer: PropTypes.func.isRequired,
    initialSelectedDealerId: PropTypes.string,
    onOutboundCall: PropTypes.func,
    shouldAutoSelectInitialDealer: PropTypes.bool.isRequired,
    locationSearches: PropTypes.arrayOf(PropTypes.shape({})),
    autoReloadInterval: PropTypes.number,
  };

  static defaultProps = {
    dealers: [],
    dealersError: null,
    dealersPending: false,
    highlightedDealer: undefined,
    onOutboundCall: null,
    initialSelectedDealerId: undefined,
    locationSearches: [],
    autoReloadInterval: undefined,
  };

  componentDidUpdate() {
    const { shouldAutoSelectInitialDealer: autoSelect } = this.props;
    const { dealers, selectDealer, initialSelectedDealerId } = this.props;

    if (autoSelect && initialSelectedDealerId && selectDealer) {
      const index = findIndex(dealers, (d) => d.id === initialSelectedDealerId);

      if (index >= 0) {
        const { id, location } = dealers[index];

        selectDealer(id, index, location);
      }
    }
  }

  render() {
    const { locationSearches, highlightedDealer } = this.props;
    const { displayDetailViewEnabled, autoReloadInterval } = this.props;
    const { onOutboundCall, dealersPending, highlightDealer } = this.props;
    const { dealers, dealersError, selectDealer, isRouteSearch } = this.props;

    const mainLocationSearchValue = get(locationSearches, '0.searchValueTried');

    if (dealersPending) return <GhostDealerList />;

    if (dealersError?.graphQLErrors) {
      const errorInfo = dealersError.graphQLErrors[0];

      if (errorInfo?.code === 'bad_route') return <ErrorMessage isRouteError />;
    }

    if (isEmpty(dealers)) {
      if (isRouteSearch) {
        const invalidLocations = locationSearches.filter(
          (s) => !!s.searchValueTried && !get(s.location, 'latitude'),
        );

        if (invalidLocations.length) {
          return (
            <ErrorMessage
              isRouteSearch
              locationText={joinSearchesValues(invalidLocations)}
            />
          );
        }
      }

      if (mainLocationSearchValue) {
        return (
          <ErrorMessage
            isRouteSearch={isRouteSearch}
            locationText={`"${mainLocationSearchValue}"`}
          />
        );
      }

      return <ErrorMessage isRouteSearch={isRouteSearch} />;
    }

    return (
      <DealerList
        dealers={dealers}
        onDealerSelect={selectDealer}
        onOutboundCall={onOutboundCall}
        highlightedDealer={highlightedDealer}
        onDealerHighlight={highlightDealer}
        autoReloadInterval={autoReloadInterval}
        displayDetailViewEnabled={displayDetailViewEnabled}
      />
    );
  }
}

export default SearchResultsListComponent;

import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { Observable } from '@apollo/client';
import { castArray, isEmpty, isEqual } from 'lodash';

import { getOperationNames } from './utils';
import { mutationType, operationStatus } from './constants';

// Exported to make testing easier
export const listeners = new Set();

const statuses = Object.values(mutationType).reduce(
  (acc, type) => ({ ...acc, [type]: { errors: null, isLoading: false } }),
  {},
);

const collectTypesStatus = (types) => {
  return types.reduce((acc, type) => ({ ...acc, [type]: statuses[type] }), {});
};

export const useOperationsStatus = (type) => {
  const types = useMemo(() => castArray(type), [type]);
  const [value, setValue] = useState(collectTypesStatus(types));
  const timeoutRef = useRef();

  const onStatusChange = useCallback(() => {
    const newValue = collectTypesStatus(types);

    if (!isEqual(value, newValue)) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => setValue(newValue), 0);
    }
  }, [value, setValue, types]);

  useEffect(() => {
    listeners.add(onStatusChange);
    return () => listeners.delete(onStatusChange);
  }, [onStatusChange]);

  return Array.isArray(type) ? value : value[type];
};

/**
 * Auto register/unregister a listener for the lifecycle of
 * graphQL operations of the provided names, and injects a prop
 * with the combined status of said operations.
 * @param {[String]} types the types/names of the mutations to listen for
 * @param {String} [propName = 'operationsStatus'] the name of the prop to inject
 */
export const withOperationsStatus = (...config) => (Component) => (props) => {
  const [types, propName = 'operationsStatus'] = config;
  const status = useOperationsStatus(types);

  return <Component {...props} {...{ [propName]: status }} />;
};

/**
 * Subscribes to lifecycle events of an operation, and notifies any
 * registered listeners about those events.
 */
const observeOperation = (operation, forward) => {
  const trigger = (status, error) => {
    const errors = castArray(error);

    getOperationNames(operation).forEach((type) => {
      const isError = status === operationStatus.error;
      const isLoading = status === operationStatus.loading;

      statuses[type] = { errors: isError ? errors : null, isLoading };
      listeners.forEach((cb) => cb());
    });
  };

  return new Observable((observer) => {
    trigger(operationStatus.loading);

    const sub = forward(operation).subscribe({
      next: (data, ...rest) => {
        if (!isEmpty(data.errors)) trigger(operationStatus.error, data.errors);
        observer.next(data, ...rest);
      },

      error: (error, ...rest) => {
        trigger(operationStatus.error, error);
        observer.error(error, ...rest);
      },

      complete: (...args) => {
        trigger(operationStatus.complete);
        observer.complete(...args);
      },
    });

    return () => {
      trigger(operationStatus.complete);
      sub.unsubscribe();
    };
  });
};

export { mutationType, operationStatus as mutationStatus };

export default observeOperation;

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { setDisplayName, compose } from 'recompose';

import AssetLocation from './AssetLocation';
import Billing from './Billing';
import Contact from './Contact';
import Customer from './Customer';
import DealerSelection from './DealerSelection';
import Frame from './Frame';
import Header from './Header';
import InboundProgram from './InboundProgram';
import Page from './Page';
import Payment from './Payment';
import Request from './Request';
import withCase from './withCase';
import withCurrentUser from './withCurrentUser';

class CasePrint extends Component {
  static propTypes = {
    caseContactTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    caseData: PropTypes.shape({
      status: PropTypes.string,
    }).isRequired,
    currentUser: PropTypes.shape({
      name: PropTypes.string,
      email: PropTypes.string,
    }).isRequired,
    isLoadingCase: PropTypes.bool.isRequired,
    isLoadingCurrentUser: PropTypes.bool.isRequired,
  };

  state = { documentReadyState: document.readyState };

  componentDidMount() {
    window.focus();
    document.addEventListener('readystatechange', this.setDocumentReadyState);

    if (!this.isLoading) {
      this.handlePrintAndClose();
    }
  }

  componentDidUpdate() {
    if (this.state.documentReadyState === 'complete' && !this.isLoading) {
      this.handlePrintAndClose();
    }
  }

  componentWillUnmount() {
    document.removeEventListener(
      'readystatechange',
      this.setDocumentReadyState,
    );
  }

  get isLoading() {
    return this.props.isLoadingCase || this.props.isLoadingCurrentUser;
  }

  setDocumentReadyState = (event) => {
    this.setState({ documentReadyState: event.target.readState });
  };

  handlePrintAndClose = () => {
    window.print();
    window.close();
  };

  render() {
    const { caseContactTypes, caseData, currentUser } = this.props;

    return (
      <Page>
        <Header caseData={caseData} currentUser={currentUser} />
        <Frame modifiers={['padScaleY_2']}>
          <Frame.Row>
            <Frame.Column style={{ flex: 1 }}>
              <InboundProgram caseData={caseData} />
            </Frame.Column>
            <Frame.Column style={{ flex: 1 }}>
              <Customer caseData={caseData} />
            </Frame.Column>
            <Frame.Column style={{ flex: 1 }}>
              <Contact
                caseContactTypes={caseContactTypes}
                caseData={caseData}
              />
            </Frame.Column>
          </Frame.Row>
          <Frame.Row>
            <Frame.Column style={{ flex: 1 }}>
              <AssetLocation caseData={caseData} />
            </Frame.Column>
          </Frame.Row>
          {caseData.status && caseData.status !== 'NEW' && (
            <Frame.Row>
              <Frame.Column style={{ flex: 1 }}>
                <DealerSelection caseData={caseData} />
              </Frame.Column>
            </Frame.Row>
          )}
          <Frame.Row>
            <Frame.Column style={{ flex: 1 }}>
              <Request caseData={caseData} />
            </Frame.Column>
          </Frame.Row>
          <Frame.Row>
            <Frame.Column style={{ flex: 1 }}>
              <Payment caseData={caseData} />
            </Frame.Column>
            <Frame.Column style={{ flex: 2 }}>
              <Billing caseData={caseData} />
            </Frame.Column>
          </Frame.Row>
        </Frame>
      </Page>
    );
  }
}
export default compose(
  setDisplayName('CasePrint'),
  withCase,
  withCurrentUser,
)(CasePrint);

import { t } from '@lingui/macro';
import { range, uniqueId } from 'lodash';
import moment from 'moment';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

const FIELDS = {
  cardholder: {
    name: 'cardholder',
    label: t`Name on Card`,
    placeholder: t`Enter name on card...`,
  },
  firstDigit: {
    name: 'firstDigit',
    label: t`First Digit`,
    placeholder: '#',
  },
  lastFourDigits: {
    name: 'lastFourDigits',
    label: t`Last Four Digits`,
    placeholder: '####',
  },
  expMonth: {
    name: 'expMonth',
    label: t`Month`,
    placeholder: t`MM`,
  },
  expYear: {
    name: 'expYear',
    label: t`Year`,
    placeholder: t`YY`,
  },
};

const FIELDS_VALIDATION_SCHEMA = Yup.object().shape({
  cardholder: Yup.string().required(),
  firstDigit: Yup.string()
    .matches(/^[0-9]$/)
    .required(),
  lastFourDigits: Yup.string()
    .matches(/^[0-9]{4}$/)
    .required(),
  expMonth: Yup.string().min(2).max(2).required(),
  expYear: Yup.string().min(2).max(2).required(),
});

const MONTH_OPTIONS = [
  { label: t`01 (January)`, value: '01' },
  { label: t`02 (February)`, value: '02' },
  { label: t`03 (March)`, value: '03' },
  { label: t`04 (April)`, value: '04' },
  { label: t`05 (May)`, value: '05' },
  { label: t`06 (June)`, value: '06' },
  { label: t`07 (July)`, value: '07' },
  { label: t`08 (August)`, value: '08' },
  { label: t`09 (September)`, value: '09' },
  { label: t`10 (October)`, value: '10' },
  { label: t`11 (November)`, value: '11' },
  { label: t`12 (December)`, value: '12' },
];

const YEAR_OPTIONS = range(51).map((offset) => {
  const year = moment().add(offset, 'years').format('YY');
  return {
    key: uniqueId(),
    label: year,
    value: year,
  };
});

/**
 * PropType definition for the object used to hold card information
 * gathered via the validation form.
 */
const cardInfoPropType = PropTypes.shape({
  [FIELDS.expMonth.name]: PropTypes.string.isRequired,
  [FIELDS.expYear.name]: PropTypes.string.isRequired,
  [FIELDS.firstDigit.name]: PropTypes.string.isRequired,
  [FIELDS.lastFourDigits.name]: PropTypes.string.isRequired,
  [FIELDS.cardholder.name]: PropTypes.string.isRequired,
});

export {
  FIELDS,
  FIELDS_VALIDATION_SCHEMA,
  MONTH_OPTIONS,
  YEAR_OPTIONS,
  cardInfoPropType,
};

import gql from 'graphql-tag';

export default gql`
  query withInboundProgram($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      inboundProgramNumber(useCachedDataForCase: true) {
        id
        isUsxpress
      }
    }
  }
`;

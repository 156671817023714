import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { includes } from 'lodash';

import AvatarBlock from '../../blocks/Avatar';

class Avatar extends Component {
  static propTypes = {
    email: PropTypes.string,
    modifiers: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    isCompany: PropTypes.bool,
    status: PropTypes.node,
  };

  static defaultProps = {
    email: '',
    modifiers: [],
    name: '',
    isCompany: false,
    status: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      isGravatarAvailable: false,
    };
  }

  UNSAFE_componentWillMount() {
    if (this.props.email) {
      this.setState({
        isGravatarAvailable: true,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.email !== nextProps.email) {
      this.setState({
        isGravatarAvailable: true, // Try to load/show gravatar when email changes
      });
    }
  }

  getInitials = () => {
    const { name, isCompany } = this.props;

    const parts = name
      .trim()
      .split(/\s+/)
      .map((w) => w.replace(/[^\w\s]/gi, ''))
      .filter((w) => !!w);

    const words = isCompany
      ? [parts[0], parts[1]]
      : [parts.shift(), parts.pop()];

    return words
      .filter(Boolean)
      .map((n) => n[0])
      .join('');
  };

  handleGravatarFetchFailed = () => {
    this.setState({ isGravatarAvailable: false });
  };

  renderContent = (mainModifiers) => {
    const { email, name, isCompany } = this.props;

    switch (true) {
      case this.state.isGravatarAvailable:
        return (
          <AvatarBlock.Image
            email={email}
            onError={this.handleGravatarFetchFailed}
            small={includes(mainModifiers, 'small')}
          />
        );
      case !!name.trim().length:
        return (
          <AvatarBlock.Initials modifiers={mainModifiers}>
            {this.getInitials()}
          </AvatarBlock.Initials>
        );
      default:
        return (
          <AvatarBlock.Icon
            modifiers={mainModifiers}
            name={isCompany ? 'building' : 'user'}
          />
        );
    }
  };

  renderStatus = (statusModifiers) => {
    const { status } = this.props;
    if (status) {
      return status;
    }

    return <AvatarBlock.Status modifiers={statusModifiers} />;
  };

  render() {
    const statusModifiers = [];
    const mainModifiers = [];
    const modifierKeys = Object.keys(AvatarBlock.Status.modifiers);
    this.props.modifiers.forEach((modifier) => {
      if (includes(modifierKeys, modifier)) {
        statusModifiers.push(modifier);
      } else {
        mainModifiers.push(modifier);
      }
    });

    return (
      <AvatarBlock modifiers={mainModifiers}>
        {this.renderContent(mainModifiers)}
        {this.renderStatus(statusModifiers)}
      </AvatarBlock>
    );
  }
}

Avatar.modifiers = {
  ...AvatarBlock.modifiers,
  ...AvatarBlock.Status.modifiers,
};

export default Avatar;

/* istanbul ignore file */
import React from 'react';
import { px2rem } from 'decisiv-ui-utils';
import { Trans } from '@lingui/macro';

import makeCellColumn from './builder';

export default makeCellColumn({
  columnName: 'shipTo',
  label: <Trans>Ship To</Trans>,
  styles: { paddingRight: px2rem(27) },
  text: ({ shipTo }) => shipTo,
});

import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';

import { Container, Row, Column } from 'styled-components-grid';
import { InputField as BaseInputField } from 'base-components';

import { useEventCallback, getInputDisplayValue } from './utils';

const noop = () => {};

function InputField(props) {
  const { toggle, isVisible, selectedItem } = props;
  const { startDate, endDate, onClear, show } = props;

  const hasAnyDate = !!startDate || !!endDate;

  const displayValue = useMemo(
    () => getInputDisplayValue({ startDate, endDate, period: selectedItem }),
    [startDate, endDate, selectedItem],
  );

  const clear = useEventCallback(useCallback(onClear, [onClear]));
  const toggleDropdown = useEventCallback(useCallback(toggle, [toggle]));

  return (
    <Container modifiers="padScale_0" onClick={show}>
      <BaseInputField
        name="dateRange"
        value={displayValue}
        onChange={noop}
        placeholder={t`Select date range...`}
      >
        <BaseInputField.Icon name="calendar" />
        <Column modifiers={['col', 'padScale_0']}>
          <Row>
            <BaseInputField.Label>
              <Trans>Period</Trans>
            </BaseInputField.Label>
          </Row>
          <Row>
            <BaseInputField.TextField
              readOnly
              autoComplete="off"
              style={{ cursor: 'pointer', caretColor: 'transparent' }}
            />
            {hasAnyDate && (
              <BaseInputField.ActionButton
                icon="times"
                type="button"
                onClick={clear}
                modifiers={['hoverDanger']}
              />
            )}
            <BaseInputField.ActionButton
              icon={isVisible ? 'chevron-up' : 'chevron-down'}
              type="button"
              onClick={toggleDropdown}
              modifiers={['hoverInfo']}
            />
          </Row>
        </Column>
      </BaseInputField>
    </Container>
  );
}

InputField.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  onClear: PropTypes.func.isRequired,
  show: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  selectedItem: PropTypes.string.isRequired,
};

export default InputField;

import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import produce from 'immer';

import { getUnits } from 'utils/unit';
import { getWeatherCode, getWeatherSummary } from 'utils/weather';

export const NAME = 'locationWeatherQuery';

const query = gql`
  query locationWeatherQuery(
    $location: Position!
    $temperatureUnit: TemperatureUnit
    $windSpeedUnit: WindSpeedUnit
  ) {
    locationWeather(location: $location) {
      current {
        time
        code
        weatherId
        temperature(unit: $temperatureUnit)
        summary
        windBearing
        windSpeed(unit: $windSpeedUnit)
      }
      today {
        precipProbability
        temperatureLow(unit: $temperatureUnit)
        temperatureHigh(unit: $temperatureUnit)
        sunset
        sunrise
      }
      hourly {
        summary
        temperature(unit: $temperatureUnit)
        time
        code
        weatherId
      }
      timezone
    }
  }
`;

export function buildOptions({ location, unit }) {
  const units = getUnits(unit);

  return {
    variables: {
      location,
      temperatureUnit: units.temperatureUnitValue,
      windSpeedUnit: units.windSpeedUnitValue,
    },
  };
}

export function mapProps({ [NAME]: { locationWeather: weather, ...rest } }) {
  return {
    weather: produce(weather, (draft) => {
      if (!draft) return;
      const { today, current } = weather;

      draft.current = current || {};

      draft.current.code = getWeatherCode(current, today);
      draft.current.summary = getWeatherSummary(current);
      draft.hourly = weather.hourly.map((hourly) => ({
        ...hourly,
        code: getWeatherCode(hourly, today),
        summary: getWeatherSummary(hourly),
      }));
    }),
    ...rest,
  };
}

export function skipQuery(props) {
  return !props.location || !props.unit;
}

const config = {
  name: NAME,
  options: buildOptions,
  props: mapProps,
  skip: skipQuery,
};

export default graphql(query, config);

import { curry, get } from 'lodash';
import React from 'react';
import { Query } from '@apollo/client/react/components';

import dealerNotesGQL from './dealerNotesQuery';

export function skipQuery(id) {
  return !id;
}

export function buildQueryVariables(id) {
  return { id };
}

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const dealer = get(queryProps, 'data.dealer', {});
    const {
      loading: dealerDetailsLoading,
      refetch: refetchDealerDetails,
    } = queryProps;

    return (
      <WrappedComponent
        {...componentProps}
        dealer={dealer}
        dealerDetailsLoading={dealerDetailsLoading}
        refetchDealerDetails={refetchDealerDetails}
      />
    );
  },
);

export function buildWrappedComponentWithQuery(
  WrappedComponent,
  componentProps,
) {
  const { dealerId } = componentProps;

  return (
    <Query
      query={dealerNotesGQL}
      skip={skipQuery(dealerId)}
      variables={buildQueryVariables(dealerId)}
    >
      {buildQueryChild(WrappedComponent, componentProps)}
    </Query>
  );
}

const withDealerNotes = (WrappedComponent) => (componentProps) =>
  /* istanbul ignore next */
  buildWrappedComponentWithQuery(WrappedComponent, componentProps);

export default withDealerNotes;

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const modifierConfig = {
  on: () => `
    left: ${px2rem(20)};
  `,
};

const styles = ({ theme }) => `
  background-color: ${theme.colors.base.chrome100};
  border-radius: 50%;
  border: ${px2rem(1)} solid ${theme.colors.base.chrome300};
  height: ${px2rem(16)};
  left: 0;
  position: absolute;
  transition: all 200ms cubic-bezier(0.39, 0.575, 0.565, 1);
  width: ${px2rem(16)};
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome100: PropTypes.string.isRequired,
      chrome300: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const Switch = buildStyledComponent('Switch', styled.div, styles, {
  modifierConfig,
  themePropTypes,
});

export default Switch;

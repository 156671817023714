import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';
import { Container } from 'styled-components-grid';

import IconCircular from '../IconCircular';

import Header from './Header';
import Section from './Section';
import Text from './Text';
import Title from './Title';

const styles = `
  margin: 0 auto;
  max-width: ${px2rem(300)};
`;

const MessageMedium = buildStyledComponent('MessageMedium', Container, styles);

MessageMedium.Header = Header;
MessageMedium.Icon = IconCircular;
MessageMedium.Section = Section;
MessageMedium.Text = Text;
MessageMedium.Title = Title;

export default MessageMedium;

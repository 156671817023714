import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Column } from 'styled-components-grid';
import { buildStyledComponent } from 'decisiv-ui-utils';

import Content from './Content';

const Curtain = buildStyledComponent(
  'BasicInfo__Curtain',
  styled(Column),
  ({ isSticky }) => `
    padding: 0;
    overflow: hidden;
    transition: width ${isSticky ? '1s' : '.5s'} ease-in-out;

    & > * { transition: opacity ${isSticky ? '1s' : '.5s'} ease-in-out; }
  `,
);

export function BasicInfo({ expandedWidth, isExpanded, isSticky, ...rest }) {
  return (
    <Curtain
      style={{ width: isExpanded ? expandedWidth : 0 }}
      isSticky={isSticky}
    >
      <Content {...rest} style={{ opacity: isExpanded ? 1 : 0 }} />
    </Curtain>
  );
}

BasicInfo.propTypes = {
  isSticky: PropTypes.bool,
  isExpanded: PropTypes.bool,
  expandedWidth: PropTypes.number,
};

BasicInfo.defaultProps = {
  isSticky: false,
  isExpanded: false,
  expandedWidth: 0,
};

BasicInfo.Content = Content;

export default BasicInfo;

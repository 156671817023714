import React, { useMemo } from 'react';
import { useMatches, useKBar, KBarResults } from 'kbar';
import { uniqBy } from 'lodash';

import { casePanelsActions } from 'features/keyShortcuts';

import SectionHeader from './SectionHeader';
import ResultItem from './ResultItem';

function Results() {
  const { search, actions } = useKBar((state) => ({
    search: state.searchQuery,
    actions: state.actions,
  }));
  const { results, rootActionId } = useMatches();

  const filteredResults = useMemo(
    () =>
      uniqBy(results, (r) => r?.id || r)
        // cleanup section headers while searching
        .filter((r) => (search === '' || typeof r !== 'string') && !r?.hide)
        // filter out case panels actions without children
        .filter(
          (r) =>
            !casePanelsActions[r?.id] || !!actions[r?.id]?.children?.length,
        ),
    [results, search, actions],
  );

  return (
    <KBarResults
      items={filteredResults}
      onRender={({ item, active }) =>
        typeof item === 'string' ? (
          <SectionHeader label={item} />
        ) : (
          <ResultItem
            action={item}
            active={active}
            currentRootActionId={rootActionId}
          />
        )
      }
    />
  );
}

export default Results;

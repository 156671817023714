import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Trans, Plural } from '@lingui/macro';
import { i18n } from '@lingui/core';

import { Text, InputField as BaseInputField } from 'base-components';
import { Row, Column } from 'styled-components-grid';

class InputField extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
      .isRequired,
    pattern: PropTypes.instanceOf(RegExp),
    isValid: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    multiline: PropTypes.bool,
    maxLength: PropTypes.number,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    onChangeDelay: PropTypes.number,
    showCharsCounter: PropTypes.bool,
    textFieldPrefix: PropTypes.string,
    onFocusRequested: PropTypes.func,
  };

  static defaultProps = {
    pattern: undefined,
    isValid: true,
    multiline: false,
    maxLength: undefined,
    placeholder: '',
    defaultValue: '',
    onChangeDelay: 1000,
    showCharsCounter: false,
    textFieldPrefix: undefined,
  };

  state = { value: this.props.defaultValue };

  UNSAFE_componentWillReceiveProps({ defaultValue }) {
    if (defaultValue !== this.props.defaultValue) {
      this.setState({ value: defaultValue });
    }
  }

  componentWillUnmount() {
    this.clearTimeouts();
  }

  onChange = ({ target: { value } }) => {
    const { name, onChange, onChangeDelay, pattern } = this.props;

    if (value && pattern && !pattern.test(value)) {
      return;
    }

    this.clearTimeouts();
    this.setState({ value });

    if (onChangeDelay) {
      this.onChangeTimeout = setTimeout(
        () => onChange(name, value),
        onChangeDelay,
      );

      return;
    }

    onChange(name, value);
  };

  clearTimeouts = () => clearTimeout(this.onChangeTimeout);

  render() {
    const {
      name,
      label,
      isValid,
      onChange,
      multiline,
      maxLength,
      placeholder,
      defaultValue,
      onChangeDelay,
      showCharsCounter,
      textFieldPrefix,
      onFocusRequested,
      ...rest
    } = this.props;

    return (
      <BaseInputField
        name={name}
        value={this.state.value}
        onChange={this.onChange}
        placeholder={i18n._(placeholder)}
        maxLength={maxLength}
        modifiers={multiline ? ['height_auto'] : undefined}
        {...rest}
      >
        <Column modifiers={['col', 'padScaleY_0']}>
          <Row>
            <BaseInputField.Label>
              <Text modifiers={['small', 'textLight']}>
                {typeof label === 'string' ? <Trans id={label} /> : label}
              </Text>
            </BaseInputField.Label>
            {showCharsCounter && (
              <BaseInputField.CharsLeftLabel>
                {(charsLeft) => (
                  <Plural
                    value={charsLeft}
                    one="# Character Left"
                    other="# Characters Left"
                  />
                )}
              </BaseInputField.CharsLeftLabel>
            )}
          </Row>
          <Row>
            {textFieldPrefix && (
              <BaseInputField.Text>{textFieldPrefix}</BaseInputField.Text>
            )}

            {multiline ? (
              <BaseInputField.TextArea ref={onFocusRequested} />
            ) : (
              <BaseInputField.TextField ref={onFocusRequested} />
            )}
          </Row>
        </Column>
      </BaseInputField>
    );
  }
}

export default InputField;

import PropTypes from 'prop-types';
import { t } from '@lingui/macro';

import buildEnum from 'utils/buildEnum';

import assetTypeNames from 'compositions/CaseRequestsPanel/RequestAssetsForm/RequestAsset/assetTypeNames';
import trailerTypeNames from 'compositions/CaseRequestsPanel/RequestAssetsForm/RequestAsset/trailerTypeNames';

export const allAssetTypes = { ...assetTypeNames, ...trailerTypeNames };

export const validationType = buildEnum(['matchAsset', 'offline', 'none']);
export const validationStatus = buildEnum(['success', 'missing', 'failure']);

export const modalModes = buildEnum([
  'initial',
  'searchResults',
  'validationSucceeded',
  'validationFailed',
]);

export const modalActions = buildEnum([
  'clear',
  'updateSearchValue',
  'startSearch',
  'selectAsset',
  'endSearch',
  'startSave',
  'endSave',
]);

export const allAssetFields = [
  'vehicleType',
  'vehicleNumber',
  'customer',
  'shipTo',
  'vehicleVin',
  'application',
  'domicileLocation',
];

export const validationTypeLabels = {
  [validationType.matchAsset]: t`Validate Asset`,
  [validationType.offline]: t`Validated by contacting fleet/shop`,
  [validationType.none]: t`Continue without Validation`,
};

export const validationFieldsLabels = {
  shipTo: t`Ship To #`,
  customer: t`Fleet`,
  vehicleVin: t`VIN`,
  application: t`Application`,
  vehicleType: t`Unit Type`,
  vehicleNumber: t`Unit #`,
  domicileLocation: t`Domicile Location`,
};

export const assetValidationEntryIcon = {
  null: 'minus',
  [validationStatus.success]: 'check-circle',
  [validationStatus.failure]: 'exclamation-circle',
};

export const assetValidationEntryIconColorByType = {
  [validationStatus.success]: 'success',
  [validationStatus.missing]: 'warning',
  [validationStatus.failure]: 'danger',
};

export const validationEntryAttemptPropType = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string,
  user: PropTypes.shape({}),
  input: PropTypes.shape({}),
  asset: PropTypes.shape({
    customer: PropTypes.string,
    shipTo: PropTypes.string,
    vehicleNumber: PropTypes.string,
    vehicleType: PropTypes.string,
    vehicleVin: PropTypes.string,
    application: PropTypes.string,
    domicileLocation: PropTypes.string,
  }),
  reference: PropTypes.string,
  timestamp: PropTypes.string,
});

export const validationEntryPropType = PropTypes.arrayOf(
  validationEntryAttemptPropType,
);

export const assetValidationPropType = PropTypes.shape({
  type: PropTypes.string,
  notes: PropTypes.string,
  entries: PropTypes.objectOf(validationEntryPropType).isRequired,
});

export const assetValidationRulesPropType = PropTypes.arrayOf(
  PropTypes.shape({
    type: PropTypes.string.isRequired,
    requirements: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
);

export const modalStatePropType = PropTypes.shape({
  mode: PropTypes.oneOf(Object.values(modalModes)).isRequired,
  attempt: validationEntryAttemptPropType,
  isSaving: PropTypes.bool.isRequired,
  assetType: PropTypes.string.isRequired,
  isSearching: PropTypes.bool.isRequired,

  // The request asset the modal is validating against
  requestAsset: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    number: PropTypes.string,
  }).isRequired,

  searchResponse: PropTypes.shape({
    user: PropTypes.shape({}),
    assets: PropTypes.arrayOf(PropTypes.shape({})),
    timestamp: PropTypes.string,
    reference: PropTypes.string,
  }),
});

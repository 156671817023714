import { intersection, isArray } from 'lodash';

/**
 * This will split up/ apply only the correct modifiers
 * to the internally rendered components.
 *
 * @export
 * @param {Array.<string>|string} modifiers an array of strings, or a string
 * @param {Object} Component a React component
 * @returns
 */
// eslint-disable-next-line import/prefer-default-export
export function modifiersForComponent(modifiers, Component) {
  if (Component.modifiers) {
    return intersection(
      Object.keys(Component.modifiers),
      isArray(modifiers) ? modifiers : [modifiers],
    );
  }
  return [];
}

import gql from 'graphql-tag';

export const NAME = 'caseBillableQuery';

export default gql`
  query caseBillableQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      billable
    }
  }
`;

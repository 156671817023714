import { throttle, noop } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';

class WindowQuery extends Component {
  static propTypes = {
    DEBOUNCE_TIME_MS: PropTypes.number,
    children: PropTypes.node.isRequired,
    onResize: PropTypes.func,
  };

  static defaultProps = {
    DEBOUNCE_TIME_MS: 250,
    onResize: noop,
  };

  UNSAFE_componentWillMount() {
    this.resizeEventListener = throttle(
      this.resizeEventHandler,
      this.props.DEBOUNCE_TIME_MS,
    );
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeEventListener);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeEventListener);
  }

  resizeEventHandler = () => {
    this.props.onResize({ windowWidth: window.innerWidth });
  };

  render() {
    return this.props.children;
  }
}

export default WindowQuery;

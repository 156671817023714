import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'styled-components-grid';

function Header(props) {
  const { modifiers, ...rest } = props;
  const defaultModifiers = ['center', 'padScaleY_3'];
  const headerModifiers = defaultModifiers.concat(modifiers);

  return <Row modifiers={headerModifiers} {...rest} />;
}

Header.defaultProps = {
  modifiers: [],
};

Header.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

export default Header;

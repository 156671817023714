import { t } from '@lingui/macro';
import { find } from 'lodash';

export const RETREAD_SERVICE_CODES = ['ProMegaMile', 'ProMRT', 'ProOliver'];

export const ADDITIONAL_SERVICES = {
  AssetMngmt: t`Asset Management`,
  OilLubeService: t`Oil & Lube Service`,
  ProAlign: t`Tire Alignment`,
  ProDOT: t`DOT Inspections`,
  ProFullMec: t`Mechanical Service`,
  ProLightMech: t`Light Mechanical`,
  ProMountDis: t`Mount & Dismount`,
  ProNailHole: t`Nail Hole Repair`,
  ProSecRepair: t`Section Repair`,
  ProTow: t`Towing Service`,
  ProWheelRefurb: t`Wheel Refurb`,
  RVDealer: t`RV Service Provider`,
  SerOnsite: t`On Site Service`,
  TireInspection: t`Automated Tire Inspection`,
  TireRepair: t`Tire Repair`,
  TravelPlaza: t`Truckstop Travel Plaza`,
};

export const DEALER_LOCATION_ICON_NAMES = {
  ERS247: t`24/7/365 Emergency Road Service`,
  MichNatAcc: t`Michelin National Accounts`,
  MichTireCare: t`Michelin Tire Care`,
  MRTAscDealer: t`Michelin Tire and Retread Service Provider`,
  RetreadDealer: t`Any Retread Service Provider`,
  ProMegaMile: t`Megamile Retreads`,
  ProMRT: t`MRT Retreads`,
  ProOliver: t`Oliver Retreads`,
  ProNewTires: t`Michelin Tire Service Provider`,
  ProXone: t`Michelin X One Tires`,
  SerNetwork: t`Michelin Commercial Service Network and Retread Service Provide`,
  FIXPIX: t`FIXPIX™`,
};

// These are the main services of dealer location and they were extracted from DEALER_LOCATION_ICON_NAMES
export const DEALER_LOCATION_SERVICES = [
  'ERS247',
  'MichNatAcc',
  'MichTireCare',
  'MRTAscDealer',
  'FIXPIX',
  'ProMegaMile',
  'ProMRT',
  'ProOliver',
  'ProNewTires',
  'ProXone',
  'SerNetwork',
];

export const LOCAL_FLEET = 'localFleet';
export const MCSN = 'mcsn';
export const MICHELIN = 'michelin';
export const MRT = 'mrt';

// The order of this array is critical. It sets the priority for dealer type matches.
export const DEALER_TYPE_MAPS = [
  { featureCode: 'SerNetwork', dealerType: MCSN },
  { featureCode: 'MRTAscDealer', dealerType: MRT },
  { featureCode: 'ProMRT', dealerType: MRT },
  { featureCode: 'MichNatAcc', dealerType: MICHELIN },
  { featureCode: 'LclFltDlr', dealerType: LOCAL_FLEET },
];

export function evaluateDealerType({ features }) {
  // This logic was updated in MICUI-365:
  // https://decisiv.atlassian.net/browse/MICUI-365
  //
  // Update the dealer map icon color selection algorithm to display the correct
  // color for a dealer based on the features present.The following list is in
  // order of priority (eg, if SerNetwork = True and MRTDealer = True, pin should
  // be yellow)
  // - Yellow if dealer is MCSN (SerNetwork = True)
  // - Green if Retread Dealer (MRTDealer = True)
  // - Blue for Michelin National Accounts who are not MCSN nor Retread
  //   (MichNatAcc = True)
  // - ~~Red~~ Dark Blue (LclFltDlr = True)
  // - If none of the above = true, use blue
  //
  const match = find(DEALER_TYPE_MAPS, (dealerTypeMap) =>
    find(features, (feature) => feature.code === dealerTypeMap.featureCode),
  );
  return match ? match.dealerType : LOCAL_FLEET;
}

export function getFilterName(code) {
  return ADDITIONAL_SERVICES[code] || DEALER_LOCATION_ICON_NAMES[code];
}

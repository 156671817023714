import PropTypes from 'prop-types';
import React from 'react';

import { DataTable } from 'base-components';

import columns from './columns';

function DealersTable({ dealers }) {
  return (
    <DataTable
      columns={columns}
      name="TechAdmin__DealersTable"
      tableData={dealers}
      headerHeight={36}
    />
  );
}

DealersTable.propTypes = {
  dealers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default DealersTable;

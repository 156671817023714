import { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName } from 'recompose';

import withContext from 'utils/withContext';
import { AmazonConnectProvider } from 'features/amazonConnect';
import { withCaseIsNewCase } from 'compositions/CaseStatus';

import { caseCallLinkHelper } from './helpers';
import withCallHistoryActions from './withCallHistoryActions';
import withCurrentCaseIdQuery from './withCurrentCaseIdQuery';
import withCallCaseLinkQuery from './withCallCaseLinkQuery';

/**
 * When...
 *  * a phone call is in progress which has not already been linked to a case,
 *  * and we create a new case,
 * ...then we want to automatically link the call to this new case.
 *
 * This component handles that magic.
 */
class AutoLinkNewCaseToCall extends Component {
  static propTypes = {
    contact: PropTypes.shape(),
    contactNumber: PropTypes.string,
    stateStartTime: PropTypes.instanceOf(Date),
    currentCaseId: PropTypes.string,
    linkCaseToCall: PropTypes.func.isRequired,
    isNewCase: PropTypes.bool,
    callLinkLoading: PropTypes.bool,
    linkedCase: PropTypes.shape(),
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    contact: null,
    contactNumber: null,
    stateStartTime: null,
    currentCaseId: null,
    isNewCase: false,
    callLinkLoading: false,
    linkedCase: null,
  };

  state = { callHasBeenLinked: false };

  componentDidMount() {
    this.linkCallToNewCase();
  }

  componentDidUpdate() {
    this.linkCallToNewCase();
  }

  linkCallToNewCase = () => {
    if (this.shouldAutoLinkCall()) {
      const {
        contact,
        contactNumber,
        stateStartTime,
        currentCaseId,
        linkCaseToCall,
      } = this.props;
      this.setState({ callHasBeenLinked: true }, () => {
        caseCallLinkHelper({
          contact,
          contactNumber,
          stateStartTime,
          currentCaseId,
          linkCaseToCall,
        });
      });
    }
  };

  shouldAutoLinkCall = () =>
    !(
      this.state.callHasBeenLinked ||
      this.props.callLinkLoading ||
      this.props.linkedCase
    ) &&
    this.props.isNewCase &&
    this.props.currentCaseId &&
    this.props.contact &&
    this.props.contactNumber;

  render() {
    return this.props.children;
  }
}

export default compose(
  setDisplayName('AutoLinkNewCaseToCall'),
  withCurrentCaseIdQuery,
  withCaseIsNewCase,
  withContext(AmazonConnectProvider),
  withCallCaseLinkQuery,
  withCallHistoryActions,
)(AutoLinkNewCaseToCall);

import React, { Component } from 'react';
import qs from 'qs';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router';
import { debounce, get } from 'lodash';
import { compose, setDisplayName } from 'recompose';
import { i18n } from '@lingui/core';

import { px2rem } from 'decisiv-ui-utils';
import { Divider } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import Page from 'blocks/Page';
import DealerLocator from 'compositions/DealerLocator';
import withGeocoder from 'features/googleMaps/withGeocoder';
import {
  geocodeSearchValue,
  getLocationSearchUpdates,
} from 'compositions/DealerLocator/withLocations/utils';

import { pageTitles, pageMeta } from '../constants';

import withInboundProgramNumberFromCall from './withInboundProgramNumberFromCall';

export class DealerLocatorPage extends Component {
  static propTypes = {
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired,
    }).isRequired,
    inboundProgramNumber: PropTypes.shape({
      billTo: PropTypes.string.isRequired,
    }),
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }).isRequired,
    geocode: PropTypes.func.isRequired,
    reverseGeocode: PropTypes.func.isRequired,
  };

  static defaultProps = {
    inboundProgramNumber: undefined,
  };

  constructor(props) {
    super(props);

    this.state = {
      displayDetailView: false,
      assetLocation: null,
      ...this.currentParams,
    };
  }

  async UNSAFE_componentWillMount() {
    try {
      const searchValue = this.currentParams.address;
      if (!searchValue) {
        this.setState({ assetLocation: undefined });
        return;
      }

      const { reverseGeocode, geocode } = this.props;

      const response = await geocodeSearchValue({
        searchValue,
        geocode,
        reverseGeocode,
      });

      this.setState({
        assetLocation: getLocationSearchUpdates(response, searchValue),
      });
    } catch (e) {
      this.setState({ assetLocation: undefined });
    }
  }

  get currentParams() {
    return qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
  }

  setSelectedDealer = (selectedDealer) => {
    const { history } = this.props;
    const id = get(selectedDealer, 'dealerId');

    history.replace({
      search: qs.stringify(
        { ...this.currentParams, id },
        { skipNulls: true, encodeValuesOnly: true },
      ),
    });
  };

  setAssetLocation = ({ searchValue: address }) => {
    const { history } = this.props;

    history.replace({
      search: qs.stringify(
        { ...this.currentParams, address },
        { skipNulls: true, encodeValuesOnly: true },
      ),
    });
  };

  // eslint-disable-next-line react/sort-comp
  debouncedSetAssetLocation = debounce(this.setAssetLocation, 1000);

  toggleDetailView = () =>
    this.setState({ displayDetailView: !this.state.displayDetailView });

  render() {
    const { assetLocation, id } = this.state;

    if (assetLocation === null) {
      return null;
    }

    const { inboundProgramNumber } = this.props;

    return (
      <Page modifiers={['flex_column', 'fluid', 'height_100']}>
        <Helmet>
          <title>{i18n._(pageTitles.dealerLocator)}</title>
          <meta name="description" content={i18n._(pageMeta.dealerLocator)} />
        </Helmet>
        <Row modifiers={['flex_1']}>
          <Column modifiers={['col', 'padScale_0']}>
            <DealerLocator
              displayDetailView={this.state.displayDetailView}
              inboundProgramNumber={inboundProgramNumber}
              initialAssetLocation={assetLocation}
              initialSelectedDealerId={id}
              onSelectDealer={this.setSelectedDealer}
              toggleDetailView={this.toggleDetailView}
            >
              <Container modifiers={['fluid', 'flex_column', 'height_100']}>
                <Row modifiers={['flex_1']}>
                  <Column modifiers={['col_6', 'padScale_0']}>
                    <DealerLocator.SearchResultsMap
                      onAssetLocationChange={this.debouncedSetAssetLocation}
                    />
                  </Column>
                  <Column modifiers={['col_6', 'padScale_0']}>
                    {this.state.displayDetailView ? (
                      <DealerLocator.DealerDetails />
                    ) : (
                      <Container
                        modifiers={['flex_column', 'height_100', 'padScale_0']}
                      >
                        <Row>
                          <Column modifiers={['col', 'padScale_0']}>
                            <DealerLocator.SearchInput
                              onAssetLocationChange={
                                this.debouncedSetAssetLocation
                              }
                            />
                          </Column>
                        </Row>
                        <Row
                          style={{
                            padding: `0 ${px2rem(35)}`,
                          }}
                        >
                          <Column modifiers={['col', 'padScale_0']}>
                            <DealerLocator.SearchFilters />
                          </Column>
                        </Row>
                        <Divider />
                        <Row modifiers={['flex_1']}>
                          <Column modifiers={['col', 'padScale_0']}>
                            <DealerLocator.SearchResultsList />
                          </Column>
                        </Row>
                      </Container>
                    )}
                  </Column>
                </Row>
                <Row>
                  <Column modifiers={['col', 'padScale_0']}>
                    <Divider />
                    <DealerLocator.Legend />
                  </Column>
                </Row>
              </Container>
            </DealerLocator>
          </Column>
        </Row>
      </Page>
    );
  }
}

export default compose(
  setDisplayName('DealerLocatorPage'),
  withRouter,
  withGeocoder,
  withInboundProgramNumberFromCall,
)(DealerLocatorPage);

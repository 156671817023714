import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const styles = ({ theme }) => `
  box-sizing: border-box;
  font-weight: ${theme.fontWeights.normal};
  min-width: ${px2rem(100)};
  text-align: left;
`;

const themePropTypes = {
  fontWeights: PropTypes.shape({
    normal: PropTypes.number.isRequired,
  }).isRequired,
};

export default buildStyledComponent('Table__HeaderCell', styled.th, styles, {
  themePropTypes,
});

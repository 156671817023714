import React, { Component, Fragment } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import { t, Trans } from '@lingui/macro';
import { compact, get, includes, difference, map, isEmpty } from 'lodash';

import { px2rem } from 'decisiv-ui-utils';
import { Column, Row } from 'styled-components-grid';
import { H2, P, Text, QuickActionButton } from 'base-components';

import Modal from 'components/Modal';
import buildEnum from 'utils/buildEnum';
import RadioButton from 'components/RadioButton';
import RadioWrapper from 'elements/RadioWrapper';

import OccurrenceRangeSelector from '../RotationEditModal/OccurrenceRangeSelector';
import { daysOfWeek } from '../RotationEditModal/constants';
import { formatEventTime, formatRecurrenceDays } from '../helpers';
import LoadingMessage from '../../LoadingMessage';

const deletionTypes = buildEnum(['single', 'range']);

const deletionTypesLabels = [
  {
    id: deletionTypes.single,
    label: t`Delete only this occurrence of the rotation`,
  },
  {
    id: deletionTypes.range,
    label: t`Delete a specific period within this rotation`,
  },
];

/**
 * Renders a modal for deleting an after-hours rotation event (either
 * a single event or a recurring event).
 */
export default class RotationDeleteModal extends Component {
  static propTypes = {
    deleteRotation: PropTypes.func.isRequired,
    event: PropTypes.shape({
      start: MomentPropTypes.momentObj.isRequired,
      end: MomentPropTypes.momentObj.isRequired,
      referenceDate: MomentPropTypes.momentString,
      afterHoursEvent: PropTypes.shape({
        isRecurring: PropTypes.bool.isRequired,
        recurring: PropTypes.array.isRequired,
        options: PropTypes.shape({ recurringEndDate: PropTypes.string }),
      }).isRequired,
    }).isRequired,
    onClose: PropTypes.func.isRequired,
    isDeleting: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    const originalDaysOfWeek = map(
      get(this.props.event, 'afterHoursEvent.recurring', []),
      'dayOfWeek',
    );

    const disabledDaysOfWeek = difference(
      map(daysOfWeek, 'value'),
      originalDaysOfWeek,
    );

    this.state = {
      deletionType: null,
      disabledDaysOfWeek,
      selectedDaysOfWeek: originalDaysOfWeek,
      startDate: this.props.event.start.format('YYYY-MM-DD'),
      endDate:
        get(this.props.event, 'afterHoursEvent.options.recurringEndDate') ||
        null,
    };
  }

  updateDeletionType = (type) => {
    this.setState({ deletionType: type });
  };

  updateWeekdays = (dayOfWeekValue) => {
    const { selectedDaysOfWeek } = this.state;

    if (includes(selectedDaysOfWeek, dayOfWeekValue)) {
      // remove the dayOfWeekValue from selectedDaysOfWeek
      return this.setState({
        selectedDaysOfWeek: selectedDaysOfWeek.filter(
          (currentDay) => currentDay !== dayOfWeekValue,
        ),
      });
    }
    // add the dayOfWeekValue to selectedDaysOfWeek
    return this.setState({
      selectedDaysOfWeek: selectedDaysOfWeek.concat(dayOfWeekValue),
    });
  };

  updateRangeDates = (startDate, endDate) => {
    startDate
      ? this.setState({ startDate: moment(startDate).format('YYYY-MM-DD') })
      : this.setState({
          endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
        });
  };

  handleDelete = (recurring) => () => {
    const { event, deleteRotation } = this.props;

    if (!recurring) return deleteRotation(event);

    const {
      deletionType: kind,
      startDate,
      endDate,
      selectedDaysOfWeek,
    } = this.state;

    const occurrence = { kind, startDate };

    // If the event's date that is showing in the calendar differs from
    // the reference date (ie. the actual event's start date behind the curtains),
    // it means the event originally happened overnight, and we need to
    // send the actual event's start date to be deleted.
    // In this case it's always one day less than the selected occurrence.startDate
    if (
      startDate &&
      event.start &&
      event.start.format('YYYY-MM-DD') !== event.referenceDate
    ) {
      occurrence.startDate = moment(occurrence.startDate)
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
    }

    if (kind === deletionTypes.range) {
      occurrence.daysOfWeek = selectedDaysOfWeek;
      occurrence.endDate = endDate;
    }

    return deleteRotation(event, occurrence);
  };

  render() {
    const { event, onClose, isDeleting } = this.props;

    const { start, end } = event;
    const { deletionType, selectedDaysOfWeek } = this.state;

    const startString = formatEventTime(start, 'DD MMM YYYY, ');
    const endString = formatEventTime(end);

    const endDateString =
      get(event, 'afterHoursEvent.options.recurringEndDate') &&
      moment(event.afterHoursEvent.options.recurringEndDate).format(
        'MMM DD YYYY',
      );

    const recurring = get(event, 'afterHoursEvent.isRecurring');
    const recurrenceDays = recurring && formatRecurrenceDays(event);
    const singleRotationString = moment(start).format('MMM DD YYYY');

    const saveDisabled =
      isDeleting ||
      (recurring && !deletionType) ||
      (deletionType === deletionTypes.range && isEmpty(selectedDaysOfWeek));

    return (
      <Modal onClose={onClose}>
        {({ closeModal }) => (
          <Modal.Body>
            <Modal.Header modifiers={['danger']}>
              <Modal.HeaderIcon name="calendar-times" />
            </Modal.Header>
            <Modal.Content>
              <Row modifiers={['center']}>
                <Column>
                  <H2 modifiers={['fontWeightRegular']}>
                    {recurring ? (
                      <Trans>Delete Recurring Rotation?</Trans>
                    ) : (
                      <Trans>Delete Rotation?</Trans>
                    )}
                  </H2>
                </Column>
              </Row>
              <Row modifiers={['center']}>
                <Column modifiers={['col']}>
                  <P style={{ marginBottom: px2rem(20) }}>
                    <Trans>
                      You&apos;re about to delete the{' '}
                      <Text modifiers="fontWeightMedium">
                        {startString} — {endString}
                      </Text>{' '}
                      rotation.
                    </Trans>
                    {recurring && (
                      <Fragment>
                        {' '}
                        <Trans>
                          This rotation repeats every {recurrenceDays}{' '}
                          {endDateString && `until ${endDateString}`}.
                        </Trans>
                      </Fragment>
                    )}
                  </P>
                  {recurring && (
                    <P style={{ marginBottom: px2rem(20) }}>
                      <Trans>
                        Do you want to delete only this occurrence of the
                        rotation on{' '}
                        <Text modifiers="fontWeightMedium">
                          {singleRotationString}
                        </Text>
                        , or a specific period within the rotation?
                      </Trans>
                    </P>
                  )}
                </Column>
              </Row>
              {recurring && (
                <Row modifiers={['padScaleY_5']}>
                  {deletionTypesLabels.map((option) => (
                    <Column
                      key={option.id}
                      modifiers={['col_12', 'padScaleY_0']}
                    >
                      <RadioWrapper>
                        <RadioButton
                          id={option.id}
                          checked={option.id === deletionType}
                          label={
                            <span
                              style={{
                                fontSize: px2rem(12),
                                textTransform: 'none',
                              }}
                            >
                              <Trans id={option.label} />
                            </span>
                          }
                          onChange={() => this.updateDeletionType(option.id)}
                        />

                        {deletionType === deletionTypes.range &&
                          option.id === deletionTypes.range && (
                            <div style={{ padding: '5px 20px 0 32px' }}>
                              <OccurrenceRangeSelector
                                mode="delete"
                                weekdays={selectedDaysOfWeek}
                                initialWeekdays={selectedDaysOfWeek}
                                disabledDaysOfWeek={
                                  this.state.disabledDaysOfWeek
                                }
                                updateWeekdays={this.updateWeekdays}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                updateDates={this.updateRangeDates}
                              />
                            </div>
                          )}
                      </RadioWrapper>
                    </Column>
                  ))}
                </Row>
              )}
            </Modal.Content>
            <Modal.Footer>
              <Row modifiers={['end']}>
                {isDeleting && (
                  <LoadingMessage
                    message={<Trans>Deleting, please wait...</Trans>}
                  />
                )}
                <Column
                  modifiers={['padScale_0']}
                  style={{ paddingLeft: px2rem(10) }}
                >
                  <QuickActionButton onClick={closeModal}>
                    <QuickActionButton.Text>
                      <Trans>Cancel</Trans>
                    </QuickActionButton.Text>
                  </QuickActionButton>
                </Column>
                <Column
                  modifiers={['padScale_0']}
                  style={{ paddingLeft: px2rem(10) }}
                >
                  <QuickActionButton
                    disabled={saveDisabled}
                    modifiers={compact([
                      'secondary',
                      saveDisabled && 'disabled',
                    ])}
                    onClick={this.handleDelete(recurring)}
                  >
                    <QuickActionButton.Text>
                      <Trans>Delete</Trans>
                    </QuickActionButton.Text>
                  </QuickActionButton>
                </Column>
              </Row>
            </Modal.Footer>
          </Modal.Body>
        )}
      </Modal>
    );
  }
}

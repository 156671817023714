import React from 'react';
import { Trans } from '@lingui/macro';

import { Icon, Text } from 'base-components';
import { Column } from 'styled-components-grid';

import { OPEN_HOURS_COLOR_KEY, AFTER_HOURS_COLOR_KEY } from '../constants';

/**
 * Caption for the rotation schedule to indicate the association
 * between event types and their colors as rendered into the grid.
 *
 * @return {ReactElement}
 */
export default function EventCaption() {
  return (
    <>
      <Column>
        <Icon modifiers={['mini', OPEN_HOURS_COLOR_KEY]} name="circle-f" />
        <Text modifiers={['small', 'textLight', 'gapX']}>
          <Trans>Open</Trans>
        </Text>
      </Column>
      <Column>
        <Icon modifiers={['mini', AFTER_HOURS_COLOR_KEY]} name="circle-f" />
        <Text modifiers={['small', 'textLight', 'gapLeft']}>
          <Trans>ERS Rotation</Trans>
        </Text>
      </Column>
    </>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { compact } from 'lodash';

import DropdownBlock from '../../blocks/Dropdown';

import { DROPDOWN_CONTEXT } from './constants';

function Content({ children, modifiers, ...rest }, context) {
  const {
    arrow,
    fullWidth,
    position,
    removeOnHide,
    showContent,
    zIndex,
  } = context[DROPDOWN_CONTEXT];

  if (!showContent && removeOnHide) {
    return null;
  }

  const fw = compact([fullWidth && 'fullWidth']);
  const contentModifiers = fw.concat(position, ...modifiers);
  const hideContent = !showContent && !removeOnHide;

  return (
    <DropdownBlock.Content
      arrow={arrow}
      modifiers={contentModifiers}
      hideContent={hideContent}
      zIndex={zIndex}
      {...rest}
    >
      {children}
      {arrow && <DropdownBlock.Arrow modifiers={[position]} />}
    </DropdownBlock.Content>
  );
}

Content.displayName = 'Dropdown__Content';

Content.contextTypes = {
  [DROPDOWN_CONTEXT]: PropTypes.shape({}).isRequired,
};

Content.defaultProps = {
  modifiers: [],
};

Content.propTypes = {
  children: PropTypes.node.isRequired,
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

export default Content;

import React, { Component } from 'react';
import moment from 'moment-timezone';
import { PropTypes } from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { Row, Column } from 'styled-components-grid';
import { H3, Label, Text, Tooltip, Icon } from 'base-components';

import Panel from 'blocks/Panel';
import MenuButton from 'components/MenuButton';
import withContext from 'utils/withContext';
import ElapsedTime from 'components/ElapsedTime';
import ButtonLinkWithIcon from 'elements/ButtonLinkWithIcon';
import { formatPhone } from 'utils/format';

import StoreInfoItem from './StoreInfoItem';
import StoreEmailModal from './StoreEmailModal';
import DealerDetailsContext from '../DealerDetailsContext';
import ToggleSnoozeConfirmModal from './ToggleSnoozeConfirmModal';

export class StoreInfoPanel extends Component {
  static propTypes = {
    dealerInfo: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      billTo: PropTypes.string,
      shipTo: PropTypes.string,
      displayAddress: PropTypes.shape({
        countryCode: PropTypes.string,
        city: PropTypes.string,
        postalCode: PropTypes.string,
        province: PropTypes.string,
        streetAddress: PropTypes.string,
      }),
      phone: PropTypes.string,
      fax: PropTypes.string,
      storeManagerEmail: PropTypes.string,
      rotationsEmailSnoozed: PropTypes.bool,
      rotationsEmailSnoozedAt: PropTypes.string,
      timezone: PropTypes.string,
    }),
    updateDealerEmail: PropTypes.func.isRequired,
    isUpdatingDealerEmail: PropTypes.bool,
    isReadOnly: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    dealerInfo: {},
    isUpdatingDealerEmail: false,
  };

  state = {
    showEmailModal: false,
    showSnoozeModal: false,
  };

  handleSaveEmail = async (email) => {
    await this.props.updateDealerEmail(email);
    this.toggleEmailModal();
  };

  handleActionSelected = (actionKey) => {
    const action = {
      editEmail: this.toggleEmailModal,
      deleteEmail: () => this.props.updateDealerEmail(''),
      toggleSnooze: this.toggleSnoozeModal,
    }[actionKey];

    if (action) action();
  };

  toggleEmailModal = () => {
    this.setState({ showEmailModal: !this.state.showEmailModal });
  };

  toggleSnoozeModal = () => {
    this.setState({ showSnoozeModal: !this.state.showSnoozeModal });
  };

  render() {
    const { dealerInfo, isReadOnly, isUpdatingDealerEmail } = this.props;
    const { name, phone, billTo, shipTo, timezone } = dealerInfo;
    const { rotationsEmailSnoozed: isSnoozed } = dealerInfo;
    const { rotationsEmailSnoozedAt: snoozedAt } = dealerInfo;
    const { fax, displayAddress = {}, storeManagerEmail } = dealerInfo;
    const { city, postalCode, province, streetAddress } = displayAddress;

    const showMenuButton = storeManagerEmail && !isReadOnly;
    const showAddEmailButton = !storeManagerEmail && !isReadOnly;

    const dropdownMenuItems = [
      { key: 'editEmail', label: t`Edit Store Manager Email` },
      { key: 'deleteEmail', label: t`Delete Store Manager Email` },
      { key: 'separator' },
      {
        key: 'toggleSnooze',
        label: isSnoozed
          ? t`Resume receiving rotation emails`
          : t`Snooze rotation emails for 30 days`,
      },
    ];

    const autoResumeDate = moment(isSnoozed ? snoozedAt : undefined)
      .add(30, 'days')
      .format('MMMM D');

    return (
      <Panel modifiers="padScale_3">
        <Row modifiers="middle">
          <Column modifiers="col">
            <H3 modifiers="fontWeightRegular">
              <Trans>Store Information</Trans>
            </H3>
          </Column>
          {isSnoozed && (
            <Column modifiers="end">
              <Tooltip position="top">
                <Tooltip.Target>
                  <Icon name="stopwatch" modifiers="info" />
                </Tooltip.Target>
                <Tooltip.Content>
                  <Trans>
                    Rotation emails are snoozed until {autoResumeDate}
                  </Trans>
                </Tooltip.Content>
              </Tooltip>
            </Column>
          )}
          {showMenuButton && (
            <Column modifiers="end">
              <MenuButton
                items={dropdownMenuItems}
                onSelect={this.handleActionSelected}
              />
            </Column>
          )}
        </Row>
        <StoreInfoItem label={<Trans>Name</Trans>} data={name} />
        <StoreInfoItem label={<Trans>Bill To</Trans>} data={billTo} />
        <StoreInfoItem label={<Trans>Ship To</Trans>} data={shipTo} />
        <Row modifiers="padScaleY_1">
          <Column>
            <Row modifiers="padScaleY_half">
              <Label modifiers="textLight">
                <Trans>Address</Trans>
              </Label>
            </Row>
            <Text>
              <Trans>{streetAddress}</Trans>
              <br />
              <Trans>{[city, province, postalCode].join(' ')}</Trans>
            </Text>
          </Column>
        </Row>

        {timezone ? (
          <ElapsedTime
            render={() => (
              <StoreInfoItem
                label={<Trans>Local Time</Trans>}
                data={moment().tz(timezone).format('D MMM YYYY, h:mm A z')}
              />
            )}
            sinceTime={moment().toDate()}
            updateInterval={10 * 1000}
          />
        ) : (
          <StoreInfoItem label={<Trans>Local Time</Trans>} />
        )}

        <StoreInfoItem
          label={<Trans>Phone Number</Trans>}
          data={formatPhone(phone)}
        />

        <StoreInfoItem label={<Trans>Fax</Trans>} data={formatPhone(fax)} />
        {storeManagerEmail && (
          <StoreInfoItem
            data={storeManagerEmail}
            label={<Trans>Store Manager Email</Trans>}
          />
        )}
        {showAddEmailButton && (
          <Row>
            <Column modifiers="padScaleY_2">
              <ButtonLinkWithIcon icon="plus" onClick={this.toggleEmailModal}>
                <Trans>Add Store Manager Email</Trans>
              </ButtonLinkWithIcon>
            </Column>
          </Row>
        )}
        {this.state.showEmailModal && (
          <StoreEmailModal
            email={storeManagerEmail === null ? '' : storeManagerEmail}
            onSave={this.handleSaveEmail}
            onClose={this.toggleEmailModal}
            isUpdatingDealerEmail={isUpdatingDealerEmail}
          />
        )}
        {this.state.showSnoozeModal && (
          <ToggleSnoozeConfirmModal
            email={storeManagerEmail}
            onClose={this.toggleSnoozeModal}
            dealerId={dealerInfo.id}
            actionType={isSnoozed ? 'resume' : 'snooze'}
            autoResumeDate={autoResumeDate}
          />
        )}
      </Panel>
    );
  }
}

export default compose(
  setDisplayName('StoreInfoPanel'),
  withContext(DealerDetailsContext),
)(StoreInfoPanel);

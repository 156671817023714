import { PropTypes } from 'prop-types';
import React from 'react';

import { Label, Text } from 'base-components';
import { Row, Column } from 'styled-components-grid';

export default function StoreInfoItem({ label, data }) {
  return (
    <Row modifiers={['padScaleY_1']}>
      <Column modifiers={['col']}>
        <Row modifiers={['padScaleY_half']}>
          <Label modifiers={['textLight']}>{label}</Label>
        </Row>
        <Row>
          <Text>{data || '—'}</Text>
        </Row>
      </Column>
    </Row>
  );
}

StoreInfoItem.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  data: PropTypes.string,
};

StoreInfoItem.defaultProps = {
  label: '',
  data: '',
};

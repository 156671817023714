import React, { useMemo } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';
import { Avatar, Text } from 'base-components';

import EntryDivider from '../EntryDivider';

function HighlightsClearedEntry({ user, recordedAt }) {
  const author = (user.name || '').trim() || user.email;

  const date = useMemo(
    () => moment(recordedAt).tz(moment.tz.guess()).format('h:mm A z'),
    [recordedAt],
  );

  return (
    <Row>
      <Column modifiers={['col', 'padScale_0']}>
        <Row>
          <Column
            modifiers={['center', 'height_100', 'padScale_0']}
            style={{ height: px2rem(20), width: px2rem(50) }}
          >
            <EntryDivider />
          </Column>
        </Row>

        <Row modifiers="middle">
          <Column modifiers="padScaleX_2">
            <Avatar
              email={user.email}
              name={(user.name || '').trim() || user.email}
              modifiers={['small']}
            />
          </Column>
          <Column>
            <Row>
              <Column modifiers="padScale_0">
                <Text modifiers={['small', 'textLight']}>{date}</Text>
              </Column>
            </Row>
            <Row>
              <Column modifiers="padScale_0">
                <Text>
                  <Trans>
                    {author} resolved all reported FIXPIX mismatches
                  </Trans>
                </Text>
              </Column>
            </Row>
          </Column>
        </Row>
      </Column>
    </Row>
  );
}

HighlightsClearedEntry.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string.isRequired,
  }).isRequired,
  recordedAt: PropTypes.string.isRequired,
};

export default HighlightsClearedEntry;

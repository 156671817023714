import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';
import { Container, Row, Column } from 'styled-components-grid';

import Timeline from 'blocks/Timeline';

import ElapsedTime from 'components/ElapsedTime';

const IconRow = buildStyledComponent(
  'Timeline__IconRow',
  styled(Row),
  css`
    margin: ${px2rem(-3)} 0;
  `,
);

const ElapsedTimeRow = buildStyledComponent(
  'Timeline__ElapsedTimeRow',
  styled(Row),
  css`
    min-height: ${px2rem(35)};
  `,
);

const IconColumn = buildStyledComponent(
  'Timeline__IconColumn',
  styled(Column),
  css`
    width: ${px2rem(16)};
    height: ${px2rem(16)};
    padding: 0;
  `,
);

const IndentedColumn = buildStyledComponent(
  'Timeline__IndentedColumn',
  styled(Column),
  css`
    padding-left: ${px2rem(20)};
  `,
);

function Event({
  actionButton,
  createdAt,
  isActive,
  isComplete,
  isInterrupted,
  label,
}) {
  let iconModifier = 'disabled';

  /* eslint-disable no-unused-expressions */
  isActive && (iconModifier = 'info');
  isComplete && (iconModifier = 'success');
  isInterrupted && (iconModifier = undefined);
  /* eslint-enable no-unused-expressions */

  return (
    <Timeline.Event modifiers={['col', 'padScale_0']}>
      <Container modifiers="padScale_0">
        <Row
          modifiers="bottom"
          style={{ flexWrap: 'nowrap', height: px2rem(25) }}
        >
          <IndentedColumn modifiers={['col', 'padScale_0']}>
            <Timeline.EventLabel modifiers={['textLight', 'uppercase']}>
              {label}
            </Timeline.EventLabel>
          </IndentedColumn>
          {actionButton}
        </Row>
        <IconRow modifiers="middle">
          <IconColumn>
            <Timeline.EventIndicatorIcon modifiers={iconModifier} />
          </IconColumn>
          <Column modifiers="col">
            <Timeline.EventIndicatorBar
              modifiers={[
                isActive && 'active',
                isComplete && 'complete',
                isInterrupted && 'interrupted',
              ]}
            />
          </Column>
        </IconRow>
        <ElapsedTimeRow>
          <IndentedColumn modifiers={['col', 'padScaleY_0', 'padScaleX_1']}>
            {isActive && !!createdAt && <ElapsedTime sinceTime={createdAt} />}
          </IndentedColumn>
        </ElapsedTimeRow>
      </Container>
    </Timeline.Event>
  );
}

Event.propTypes = {
  actionButton: PropTypes.node,
  createdAt: PropTypes.string,
  isActive: PropTypes.bool,
  isComplete: PropTypes.bool,
  isInterrupted: PropTypes.bool,
  label: PropTypes.node.isRequired,
};

Event.defaultProps = {
  actionButton: null,
  createdAt: undefined,
  isActive: false,
  isComplete: false,
  isInterrupted: false,
};

export default Event;

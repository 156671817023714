import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { InputField as BaseInputField } from 'base-components';
import { buildStyledComponent } from 'decisiv-ui-utils';

const Container = buildStyledComponent(
  'InputFieldContainer;',
  styled.div,
  css`
    /* Allows the FocusProvider highlight outline to be visible */
    .Input {
      background: none;
    }
  `,
  {
    modifierConfig: {
      // We can safely use `:focus-within` here because
      // we're only targeting browsers that support it.
      valid: () => `
      .Input:not(:hover):not(:focus-within) {
        border-color: transparent;
      }
    `,

      disabled: ({ theme }) => `
      cursor: not-allowed;

      .Input {
        opacity: 0.5;
        background: ${theme.colors.base.chrome100};
      }

      .TextField {
        background: none;
      }
    `,

      readOnly: ({ theme }) => `
      .Input {
        background: ${theme.colors.base.background};

        &:hover {
          border-color: transparent;
        }
      }
    `,
    },
    themePropTypes: {
      colors: PropTypes.shape({
        base: PropTypes.shape({
          chrome100: PropTypes.string.isRequired,
          background: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    },
  },
);

function InputField(props) {
  const { isValid, readOnly, disabled } = props;

  return (
    <Container
      modifiers={[
        isValid && 'valid',
        disabled && 'disabled',
        readOnly && 'readOnly',
      ]}
    >
      <BaseInputField {...props} />
    </Container>
  );
}

InputField.propTypes = {
  isValid: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

InputField.defaultProps = {
  isValid: true,
  disabled: false,
  readOnly: false,
};

InputField.TextField = BaseInputField.TextField;
InputField.ActionButton = BaseInputField.ActionButton;

export default InputField;

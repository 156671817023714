import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import { H2, P, QuickActionButton } from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Column, Row } from 'styled-components-grid';

import Modal from 'components/Modal';

import CardDisplay from './CardDisplay';
import { cardInfoPropType } from './constants';

export default function InvalidCardModalBody({
  cardInfo,
  closeModal,
  retryEdit,
}) {
  return (
    <Modal.Body>
      <Modal.Header modifiers="danger">
        <Modal.HeaderIcon name="credit-card-times" />
      </Modal.Header>
      <Modal.Content>
        <Row modifiers={['center']}>
          <Column>
            <H2 modifiers={['fontWeightRegular']}>
              <Trans>Credit Card Not Found</Trans>
            </H2>
          </Column>
        </Row>
        <Row modifiers={['center']}>
          <Column>
            <CardDisplay cardInfo={cardInfo} />
            <P style={{ marginBottom: px2rem(20) }}>
              <Trans>
                Try another credit card or cancel and select a different payment
                method.
              </Trans>
            </P>
          </Column>
        </Row>
      </Modal.Content>
      <Modal.Footer>
        <Row modifiers={['end']}>
          <Column>
            <QuickActionButton onClick={closeModal} type="button">
              <QuickActionButton.Text>
                <Trans>Cancel</Trans>
              </QuickActionButton.Text>
            </QuickActionButton>
          </Column>
          <Column>
            <QuickActionButton
              modifiers="secondary"
              onClick={retryEdit}
              type="button"
            >
              <QuickActionButton.Text>
                <Trans>Try Again</Trans>
              </QuickActionButton.Text>
            </QuickActionButton>
          </Column>
        </Row>
      </Modal.Footer>
    </Modal.Body>
  );
}

InvalidCardModalBody.propTypes = {
  closeModal: PropTypes.func.isRequired,
  retryEdit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-typos
  cardInfo: cardInfoPropType.isRequired,
};

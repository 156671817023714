import { t } from '@lingui/macro';

export const IMPERIAL = 'Imperial';
export const METRIC = 'Metric';

export function getUnits(type) {
  if (type === METRIC) {
    return {
      distanceUnitValue: 'KILOMETERS',
      distanceUnitLabel: t`km`,
      temperatureUnitValue: 'CELCIUS',
      temperatureUnitLabel: t`C`,
      windSpeedUnitValue: 'KPH',
      windSpeedUnitLabel: t`km/h`,
    };
  }

  return {
    distanceUnitValue: 'MILES',
    distanceUnitLabel: t`mi`,
    temperatureUnitValue: 'FAHRENHEIT',
    temperatureUnitLabel: t`F`,
    windSpeedUnitValue: 'MPH',
    windSpeedUnitLabel: t`mi/h`,
  };
}

export function isMetric(type) {
  return type === METRIC;
}

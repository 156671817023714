import gql from 'graphql-tag';

export const NAME = 'dealerHistoryQuery';

export default gql`
  query dealerHistoryQuery(
    $dealerId: ID!
    $first: Int
    $last: Int
    $beforeCursor: String
    $afterCursor: String
    $sortDirection: SortDirection = DESC
  ) {
    dealer(id: $dealerId) {
      id
      history(
        first: $first
        last: $last
        before: $beforeCursor
        after: $afterCursor
        sortDirection: $sortDirection
      ) {
        edges {
          cursor
          node {
            user {
              name
              email
            }
            recordedAt
            changes {
              name
              info {
                name
                value
              }
              fields {
                field
                previousValue
                newValue
              }
              action
              entityId
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }
      }
    }
  }
`;

import moment from 'moment-timezone';

class BusinessHours {
  constructor(hours, timezone) {
    this.soonMinutes = 60;
    this.days = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ];
    this.hours = hours;
    this.timezone = timezone;
  }

  existFor(dayIndex) {
    return !!this.getDayHours(dayIndex);
  }

  getCurrentDay(dayIndex) {
    return this.days[dayIndex % 7];
  }

  getDayHours(dayIndex) {
    const day = this.getCurrentDay(dayIndex);
    return this.hours.find((hour) => hour.weekDay === day);
  }

  getEndMoment(dayIndex) {
    const end = this.getEndTime(dayIndex);
    return moment.tz(end, 'h:mm A', this.timezone);
  }

  getEndTime(dayIndex) {
    const { end } = this.getDayHours(dayIndex);
    return end;
  }

  getStartMoment(dayIndex) {
    const start = this.getStartTime(dayIndex);
    return moment.tz(start, 'h:mm A', this.timezone);
  }

  getStartTime(dayIndex) {
    const { start } = this.getDayHours(dayIndex);
    return start;
  }

  isClosed(dayIndex, now) {
    const start = this.getStartMoment(dayIndex);
    const end = this.getEndMoment(dayIndex);
    return now.isBefore(start) || now.isAfter(end);
  }

  isOpen(dayIndex, now) {
    const start = this.getStartMoment(dayIndex);
    const end = this.getEndMoment(dayIndex);
    return now.isAfter(start) && now.isBefore(end);
  }

  minutesUntilClose(dayIndex, now) {
    const end = this.getEndMoment(dayIndex);
    const diff = moment.duration(end.diff(now));
    return parseInt(diff / 60000, 10);
  }

  minutesUntilOpen(dayIndex, now) {
    const start = this.getStartMoment(dayIndex);
    const diff = moment.duration(start.diff(now));
    return parseInt(diff / 60000, 10);
  }

  willCloseSoon(dayIndex, now) {
    const closing = this.minutesUntilClose(dayIndex, now);
    return closing > 0 && closing <= this.soonMinutes;
  }

  willOpenSoon(dayIndex, now) {
    const opening = this.minutesUntilOpen(dayIndex, now);
    return opening > 0 && opening <= this.soonMinutes;
  }
}

export default BusinessHours;

import React, { useMemo } from 'react';
import { get } from 'lodash';
import { useQuery } from '@apollo/client';

import query from './caseDataQuery';
import { CASE_STATUS } from '../constants';

const withCaseData = (Component) => (cProps) => {
  const { caseNumber, ...rest } = cProps;

  const { data: queryData, loading } = useQuery(query, {
    skip: !caseNumber,
    variables: { caseNumber },
  });

  const data = useMemo(() => queryData?.case || {}, [queryData]);

  const caseId = data.id;
  const status = data.status || CASE_STATUS.new;
  const createdAt = data.createdAt || '';
  const statusHistory = useMemo(() => data.statusHistory || [], [data]);
  const fixpixPushResult = data.fixpixPushResult || 'NOT_ATTEMPTED';
  const servicingDealerTimezone = get(data, 'servicingDealer.dealer.timezone');

  return (
    <Component
      {...rest}
      status={status}
      caseId={caseId}
      createdAt={createdAt}
      caseNumber={caseNumber}
      statusHistory={statusHistory}
      fixpixPushResult={fixpixPushResult}
      isLoadingCaseStatus={!!loading}
      servicingDealerTimezone={servicingDealerTimezone}
    />
  );
};

export default withCaseData;

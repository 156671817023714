import { find } from 'lodash';
import { onError } from '@apollo/client/link/error';

import { sendToLogin } from 'utils/bff';
import { reportGraphQLErrors } from 'setup/BugSnag';

import { AUTHENTICATION_ERROR } from '../../../constants';

import { showGenericErrorToast } from 'components/ErrorToast';

/**
 * An Apollo link afterware for handling request errors...
 * * https://www.apollographql.com/docs/react/advanced/network-layer.html#linkAfterware
 * * https://www.apollographql.com/docs/link/links/error.html
 */
const errorLink = onError((data) => {
  const { graphQLErrors: errors } = data;

  if (errors) {
    // If an authentication error is present in the array of errors,
    // it means the user isn't signed in or was signed out. If the user
    // signs out in this app, they should never hit this error. SSO means
    // they can sign out in a different app too though, perhaps while this
    // app is still open. This code really handles that scenario.
    const authError = find(errors, (err) => err.code === AUTHENTICATION_ERROR);

    if (authError) {
      sendToLogin();
    } else {
      showGenericErrorToast(errors);
      reportGraphQLErrors(data);
    }
  }
});

export default errorLink;

import React from 'react';
import PropTypes from 'prop-types';

import ScrollableContainer from 'blocks/ScrollableContainer';

import Dealer from './Dealer';

const isHighlighted = (dealer, highlighted) => highlighted?.id === dealer?.id;

function DealerList(props) {
  const { autoReloadInterval, highlightedDealer } = props;
  const { dealers, onDealerSelect, onOutboundCall } = props;
  const { onDealerHighlight, displayDetailViewEnabled } = props;

  return (
    <ScrollableContainer modifiers={['fluid']}>
      <ScrollableContainer.List>
        {dealers.map((dealer, index) => (
          <ScrollableContainer.ListItem
            key={dealer.id}
            modifiers={[
              'borders',
              'selectableHover',
              isHighlighted(dealer, highlightedDealer) && 'active',
            ]}
          >
            <Dealer
              index={index}
              dealer={dealer}
              onDealerSelect={onDealerSelect}
              onOutboundCall={onOutboundCall}
              onDealerHighlight={onDealerHighlight}
              autoReloadInterval={autoReloadInterval}
              displayDetailViewEnabled={displayDetailViewEnabled}
            />
          </ScrollableContainer.ListItem>
        ))}
      </ScrollableContainer.List>
    </ScrollableContainer>
  );
}

DealerList.propTypes = {
  dealers: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string }))
    .isRequired,
  displayDetailViewEnabled: PropTypes.bool.isRequired,
  highlightedDealer: PropTypes.shape({ id: PropTypes.string.isRequired }),
  onDealerHighlight: PropTypes.func.isRequired,
  onDealerSelect: PropTypes.func.isRequired,
  onOutboundCall: PropTypes.func,
  autoReloadInterval: PropTypes.number,
};

DealerList.defaultProps = {
  onOutboundCall: null,
  highlightedDealer: undefined,
  autoReloadInterval: undefined,
};

export default DealerList;

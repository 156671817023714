import { t } from '@lingui/macro';

const NATIONAL = {
  type: 'NATIONAL',
  label: t`National`,
};

const STORE = {
  type: 'STORE',
  types: ['CUSTOM_STORE', 'STORE'],
  label: t`Store`,
};

const CUSTOM_STORE = {
  type: 'CUSTOM_STORE',
};

const OTHER = {
  type: 'OTHER',
  label: t`Other`,
};

// These customer types are intended to map customer type ENUM values to labels
export const CUSTOMER_TYPES = { NATIONAL, STORE, CUSTOM_STORE, OTHER };
export const CUSTOMER_TYPE_OPTIONS = [NATIONAL, STORE, OTHER];

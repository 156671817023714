import React from 'react';

import { Container } from 'styled-components-grid';
import { P } from '../../elements';

import Header from './Header';
import Icon from './Icon';
import Section from './Section';
import Footer from './Footer';
import Title from './Title';

function MessageLarge(props) {
  return <Container {...props} />;
}

MessageLarge.Footer = Footer;
MessageLarge.Header = Header;
MessageLarge.Icon = Icon;
MessageLarge.Section = Section;
MessageLarge.Text = P;
MessageLarge.Title = Title;

export default MessageLarge;

import React from 'react';

import { reportTypes } from 'pages/Reports/constants';

import ReportConfigBuilder from './ReportConfigBuilder';

const DailyAgentMetrics = () => (
  <ReportConfigBuilder reportType={reportTypes.dailyAgentMetrics} />
);

export default DailyAgentMetrics;

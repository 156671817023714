import React from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { get, noop } from 'lodash';
import { compose, withState, mapProps } from 'recompose';

import { Text } from 'base-components';
import { Row, Column } from 'styled-components-grid';

import withContext from 'utils/withContext';
import withPatternValidationOnKeyUp from 'utils/withPatternValidationOnKeyUp';
import withFocusReceiver from 'setup/FocusProvider/withFocusReceiver';

import { invalidCharsForExporting } from 'constants';

import withCaseDealerResponseActions from '../../withCaseDealerResponseActions';
import DealerResponsesValidationContext from '../../DealerResponsesValidationContext';

import { TextInputField } from '../InputField';
import { buildFocusReceiverId } from '../utils';

function BaseRenderer(props) {
  const { value, isValid, id, readOnly, handleBlur, handleKeyUp } = props;
  const { updateContactPerson, showValidationError, onFocusRequested } = props;

  return (
    <TextInputField
      name={`dealerResponse-${id}-contactPerson`}
      onBlur={handleBlur}
      onKeyUp={handleKeyUp}
      isValid={value && showValidationError ? isValid : true}
      onChange={(_, newContactPerson) => updateContactPerson(newContactPerson)}
      readOnly={readOnly}
      placeholder={t`Enter name...`}
      defaultValue={value || ''}
      onFocusRequested={onFocusRequested}
      maxLength={255}
    />
  );
}

BaseRenderer.propTypes = {
  id: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  value: PropTypes.string,
  isValid: PropTypes.bool,
  updateContactPerson: PropTypes.func.isRequired,
  // Used in withState and mapProps below
  /* eslint-disable react/no-unused-prop-types */
  contactPerson: PropTypes.string,
  updateDealerResponse: PropTypes.func,
  onValidateResponseColumn: PropTypes.func,
  validationErrors: PropTypes.objectOf(
    PropTypes.shape({
      contactPerson: PropTypes.bool,
      phoneNumber: PropTypes.bool,
      eta: PropTypes.bool,
    }),
  ),
  /* eslint-enable react/no-unused-prop-types */

  // from withPatternValidationOnKeyUp
  showValidationError: PropTypes.bool,
  handleBlur: PropTypes.func.isRequired,
  handleKeyUp: PropTypes.func.isRequired,
  onFocusRequested: PropTypes.func.isRequired,
};

BaseRenderer.defaultProps = {
  contactPerson: undefined,
  updateDealerResponse: undefined,
  validationErrors: undefined,
  showValidationError: undefined,
  onValidateResponseColumn: noop,
  value: undefined,
  isValid: undefined,
};

const CellRenderer = compose(
  withCaseDealerResponseActions,
  withContext(DealerResponsesValidationContext),
  withState('value', 'setValue', ({ contactPerson }) => contactPerson),
  withPatternValidationOnKeyUp({
    fields: { contactPerson: invalidCharsForExporting },
  }),
  mapProps(
    ({
      updateDealerResponse,
      setValue,
      hasInvalidPattern,
      onValidatingFieldBlur,
      onValidatingFieldKeyUp,
      showValidationError,
      onValidateResponseColumn,
      validationErrors,
      ...otherProps
    }) => ({
      ...otherProps,

      showValidationError: showValidationError('contactPerson'),
      isValid: !get(validationErrors, [otherProps.id, 'contactPerson']),

      updateContactPerson: (newContactPerson) => {
        setValue(newContactPerson);
        if (
          !newContactPerson ||
          !hasInvalidPattern('contactPerson', newContactPerson)
        ) {
          updateDealerResponse({
            id: otherProps.id,
            patch: { contactPerson: newContactPerson },
          });
        }
      },

      handleBlur: ({ target: { value } }) => {
        onValidatingFieldBlur('contactPerson');
        onValidateResponseColumn(
          otherProps.id,
          'contactPerson',
          !hasInvalidPattern('contactPerson', value),
        );
      },

      handleKeyUp: (event) => {
        const { showValidationError: shouldValidate } = onValidatingFieldKeyUp(
          'contactPerson',
          event,
        );

        if (shouldValidate) {
          onValidateResponseColumn(
            otherProps.id,
            'contactPerson',
            !hasInvalidPattern('contactPerson', get(event, 'target.value')),
          );
        }
      },
    }),
  ),
  withFocusReceiver(buildFocusReceiverId('contactPerson')),
)(BaseRenderer);

export default {
  name: 'contactPerson',
  cellKeyGetter: ({ id }) => `${id}:contactPerson`,
  cellDataGetter: (args) => args,
  headerCellRenderer: () => (
    <Row>
      <Column modifiers={['padScaleX_2', 'padScaleY_3']}>
        <Text modifiers={['small', 'textLight']}>
          <Trans>Contact</Trans>
        </Text>
      </Column>
    </Row>
  ),
  dataCellRenderer: (
    { id, contactPerson }, // eslint-disable-line react/prop-types
    { tableMetaData, rowIndex },
  ) => (
    <CellRenderer
      id={id}
      readOnly={get(tableMetaData, 'readOnly')}
      rowIndex={rowIndex}
      dealerIndex={tableMetaData.dealerIndex}
      contactPerson={contactPerson}
    />
  ),
};

import { compose } from 'recompose';

import withCreateCaseAgreementLine from './withCreateCaseAgreementLine';
import withUpdateCaseAgreementLine from './withUpdateCaseAgreementLine';
import withResetCaseAgreementLines from './withResetCaseAgreementLines';
import withRemoveCaseAgreementLine from './withRemoveCaseAgreementLine';

export default compose(
  withCreateCaseAgreementLine,
  withUpdateCaseAgreementLine,
  withResetCaseAgreementLines,
  withRemoveCaseAgreementLine,
);

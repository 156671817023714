import asset from './pin-asset.svg';
import assetDisabled from './pin-asset-disabled.svg';
import dealerDisabled from './pin-dealer-disabled.svg';
import dealerLocalFleet from './pin-dealer-localfleet.svg';
import dealerMcsn from './pin-dealer-mcsn.svg';
import dealerMichelin from './pin-dealer-michelin.svg';
import dealerMrt from './pin-dealer-mrt.svg';

export const pinIcons = {
  asset,
  assetDisabled,
  dealerDisabled,
  dealerLocalFleet,
  dealerMcsn,
  dealerMichelin,
  dealerMrt,
};

export function pinIconForType(type) {
  return pinIcons[type];
}

export default {
  pinIconForType,
  pinIcons,
};

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import Body from './Body';
import Container from './Container';
import DataCell from './DataCell';
import Head from './Head';
import HeaderCell from './HeaderCell';
import Row from './Row';

const modifierConfig = {
  // BOUNTY: Significant karma points will be awarded to whoever figures out
  // how to have a fixed header that auto-sizes with a scrolling body without
  // using passed in hardcoded values like this.
  fillHeight: ({ headerHeight }) => `
    thead {
      height: ${px2rem(headerHeight)};
    }

    tbody {
      height: calc(100% - ${px2rem(headerHeight + 1)});
    }
  `,

  scrollBodyY: ({ headerHeight }) => `
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overflow: -moz-scrollbars-none;
    position: relative;

    &::-webkit-scrollbar {
      display: none;
    }

    tbody {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: ${px2rem(headerHeight + 1)};
    }
  `,
};

const styles = `
  border-collapse: collapse;
  height: 100%;
  text-align: left;
  width: 100%;

  th, td {
    padding: 0;
  }
`;

const Table = buildStyledComponent('Table', styled.table, styles, {
  modifierConfig,
  propTypes: {
    headerHeight: PropTypes.number,
  },
  defaultProps: {
    headerHeight: 50,
  },
});

Table.Body = Body;
Table.Container = Container;
Table.DataCell = DataCell;
Table.Head = Head;
Table.HeaderCell = HeaderCell;
Table.Row = Row;

export default Table;

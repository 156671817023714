import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { compact, isEmpty, isEqual, isMatch, noop } from 'lodash';
import { DirectionsRenderer, GoogleMap, TrafficLayer } from 'react-google-maps';

import { Icon } from 'base-components';

import MapButton from 'elements/MapButton';

import {
  OverlayView,
  PropTypes as GoogleMapPropTypes,
} from 'features/googleMaps';

import arraysMatchWith from 'utils/arraysMatchWith';

import { DEFAULT_ASSET_LOCATION, EXIT_VIEW_ZOOM_LEVEL } from './constants';

import AssetMarker from './AssetMarker';
import DealerMarker from './DealerMarker';
import DestinationMarker from './DestinationMarker';
import RouteOverlay from './RouteOverlay';
import MapCustomControl from './MapCustomControl';
import TrafficLayerToggle from './TrafficLayerToggle';

/* istanbul ignore next */
function isDefaultAssetLocation(location) {
  return isEqual(DEFAULT_ASSET_LOCATION, location);
}

/* istanbul ignore next */
const getPixelPositionOffset = () => ({
  x: -20,
  y: 10,
});

const defaultMapOptions = {
  controlSize: 29,
  gestureHandling: 'cooperative',
  styles: [
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [{ visibility: 'on' }],
    },
  ],
};

export class MapComponent extends PureComponent {
  static propTypes = {
    assetLocation: PropTypes.shape({
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
    }),
    dealers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    destinationLocations: PropTypes.arrayOf(
      PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number,
      }),
    ).isRequired,
    // eslint-disable-next-line react/no-typos
    directions: GoogleMapPropTypes.googleDirections,
    displayRoute: PropTypes.func,
    highlightDealer: PropTypes.func.isRequired,
    highlightedDealer: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    isDetailView: PropTypes.bool.isRequired,
    isRouteSearch: PropTypes.bool,
    // eslint-disable-next-line react/no-typos
    middlePoint: GoogleMapPropTypes.latLng,
    onAssetLocationChange: PropTypes.func.isRequired,
    onMapBoundsChanged: PropTypes.func.isRequired,
    route: PropTypes.arrayOf(PropTypes.shape({})),
    selectDealer: PropTypes.func.isRequired,
    selectedDealer: PropTypes.shape({
      dealerId: PropTypes.string,
      index: PropTypes.number,
      location: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number,
      }),
    }),
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    assetLocation: DEFAULT_ASSET_LOCATION,
    dealers: [],
    directions: {
      routes: [],
      status: 'empty',
    },
    displayRoute: noop,
    highlightedDealer: undefined,
    isRouteSearch: false,
    middlePoint: null,
    route: [],
    selectedDealer: undefined,
    readOnly: false,
  };

  state = {
    showTraffic: true,
    zoom: 4,
  };

  componentDidMount() {
    const { assetLocation, dealers } = this.props;

    if (!isEmpty(assetLocation) && !isEmpty(dealers)) {
      this.fitBoundsChangeForDealers(dealers, assetLocation);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      assetLocation,
      destinationLocations,
      isDetailView,
      selectedDealer,
    } = this.props;
    // This logic is required for when you are clicking markers WHILE in detail view.
    if (
      nextProps.selectedDealer &&
      selectedDealer !== nextProps.selectedDealer &&
      nextProps.isDetailView
    ) {
      this.fitBoundsChangeForDetailView(nextProps.selectedDealer);
    }

    if (isDetailView !== nextProps.isDetailView) {
      if (nextProps.isDetailView) {
        this.fitBoundsChangeForDetailView(nextProps.selectedDealer);
      } else {
        this.fitBoundsChangeForDealers(
          nextProps.dealers,
          nextProps.assetLocation,
        );
      }
    }

    if (assetLocation !== nextProps.assetLocation) {
      this.handleCenterChange(nextProps.assetLocation);

      if (isDefaultAssetLocation(nextProps.assetLocation)) {
        this.setState({ zoom: 4 });
      }
    }

    if (
      !nextProps.isDetailView &&
      !isEmpty(nextProps.dealers) &&
      !isEqual(this.props.dealers, nextProps.dealers)
    ) {
      this.fitBoundsChangeForDealers(
        nextProps.dealers,
        nextProps.assetLocation,
      );
    }

    // force the map to re-render without a highlighted dealer when either the
    // asset or destination locations change.
    if (
      !isMatch(assetLocation, nextProps.assetLocation) ||
      !arraysMatchWith(
        destinationLocations,
        nextProps.destinationLocations,
        (destinationLocation, newDestinationLocation) =>
          isMatch(destinationLocation, newDestinationLocation),
      )
    ) {
      this.props.highlightDealer(undefined);
    }

    if (!isEqual(nextProps.route, this.props.route)) {
      nextProps.displayRoute(...nextProps.route);
    }
  }

  fitBoundsChangeForDetailView = ({ location }) => {
    const { assetLocation } = this.props;
    const bounds = new google.maps.LatLngBounds();
    bounds.extend({
      lat: location.latitude,
      lng: location.longitude,
    });
    bounds.extend({
      lat: assetLocation.latitude,
      lng: assetLocation.longitude,
    });
    this.googleMap.fitBounds(bounds);
  };

  fitBoundsChangeForDealers = (dealers, assetLocation) => {
    const bounds = new google.maps.LatLngBounds();
    dealers.forEach((dealer) => {
      bounds.extend({
        lat: dealer.location.latitude,
        lng: dealer.location.longitude,
      });
    });
    bounds.extend({
      lat: assetLocation.latitude,
      lng: assetLocation.longitude,
    });
    this.googleMap.fitBounds(bounds);
  };

  handleCenterChange = (assetLocation) => {
    this.googleMap.panTo({
      lat: assetLocation.latitude,
      lng: assetLocation.longitude,
    });
  };

  handleZoomChange = () => {
    this.setState({ zoom: this.googleMap.getZoom() });
  };

  handleBoundsChange = () => {
    this.props.onMapBoundsChanged(this.googleMap.getBounds());
  };

  handleZoomExitViewClick = () => {
    this.handleCenterChange(this.props.assetLocation);
    this.setState({ zoom: EXIT_VIEW_ZOOM_LEVEL });
  };

  toggleTrafficLayer = () => {
    this.setState({ showTraffic: !this.state.showTraffic });
  };

  renderAssetMarker = () => !isDefaultAssetLocation(this.props.assetLocation);

  renderDestinationMarker = ({ latitude, longitude }) => latitude && longitude;

  renderOverlayView = () =>
    this.renderRouteInfo() && this.props.highlightedDealer;

  renderRouteInfo = () =>
    Boolean(
      this.props.assetLocation &&
        this.props.dealers &&
        this.props.dealers.length &&
        (this.props.isRouteSearch || this.props.highlightedDealer) &&
        this.props.directions,
    );

  render() {
    const {
      assetLocation,
      dealers,
      destinationLocations,
      directions,
      displayRoute,
      highlightedDealer,
      isRouteSearch,
      middlePoint,
      onAssetLocationChange,
      highlightDealer,
      selectDealer,
      route,
      selectedDealer,
      readOnly,
    } = this.props;
    const { showTraffic, zoom } = this.state;

    return (
      <GoogleMap
        zoom={zoom}
        defaultCenter={{
          lat: DEFAULT_ASSET_LOCATION.latitude,
          lng: DEFAULT_ASSET_LOCATION.longitude,
        }}
        onZoomChanged={this.handleZoomChange}
        onBoundsChanged={this.handleBoundsChange}
        ref={(map) => {
          this.googleMap = map;
        }}
        options={defaultMapOptions}
      >
        <MapCustomControl
          controlPosition={google.maps.ControlPosition.LEFT_TOP}
        >
          <TrafficLayerToggle
            showTraffic={showTraffic}
            onClick={this.toggleTrafficLayer}
          />
        </MapCustomControl>
        {this.renderAssetMarker() && (
          <AssetMarker
            assetLocation={assetLocation}
            onAssetLocationChange={onAssetLocationChange}
            /**
             * With this zIndex, AssetMarker appears on top of all DealerMarkers
             */
            zIndex={dealers.length + 1}
            draggable={!readOnly}
          />
        )}
        {dealers.map((dealer, index) => (
          <DealerMarker
            key={dealer.id}
            icon={dealer.icon}
            index={index}
            route={route}
            dealer={dealer}
            dealers={dealers}
            directions={directions}
            displayRoute={displayRoute}
            assetLocation={assetLocation}
            isRouteSearch={isRouteSearch}
            selectedDealer={selectedDealer}
            onDealerSelect={selectDealer}
            highlightedDealer={highlightedDealer}
            onDealerHighlight={highlightDealer}
          />
        ))}
        {destinationLocations.map(
          (location, index) =>
            this.renderDestinationMarker(location) && (
              <DestinationMarker
                key={JSON.stringify(location)}
                destination={location}
                /**
                 * With this zIndex, DestinationMarker appears on top of all DealerMarkers and AssetMarker
                 */
                zIndex={dealers.length + 2}
                isFinalDestination={index === destinationLocations.length - 1}
              />
            ),
        )}
        {
          // render the directions, but don't include markers/info windows
          // since we'll render our custom info windows below
          this.renderRouteInfo() && (
            <DirectionsRenderer
              directions={directions}
              options={{
                preserveViewport: true,
                suppressInfoWindows: true,
                suppressMarkers: true,
              }}
            />
          )
        }
        {this.googleMap && (
          <OverlayView
            position={middlePoint || { lat: 0, lng: 0 }}
            /*
             * An alternative to specifying position is specifying bounds.
             * bounds can either be an instance of google.maps.LatLngBounds
             * or an object in the following format:
             * bounds={{
             *    ne: { lat: 62.400471, lng: -150.005608 },
             *    sw: { lat: 62.281819, lng: -150.287132 }
             * }}
             */
            /*
             * 1. Specify the pane the OverlayView will be rendered to. For
             *    mouse interactivity, use `OverlayView.OVERLAY_MOUSE_TARGET`.
             *    Defaults to `OverlayView.OVERLAY_LAYER`.
             */
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            /*
             * 2. Tweak the OverlayView's pixel position. In this case, we're
             *    centering the content.
             */
            getPixelPositionOffset={getPixelPositionOffset}
            /*
             * 3. Create OverlayView content using standard React components.
             */
          >
            {middlePoint && this.renderOverlayView() ? (
              <RouteOverlay
                key={highlightedDealer?.id}
                dealer={highlightedDealer}
                isRouteSearch={isRouteSearch}
              />
            ) : (
              /**
               * OverlayView is required to have children
               */
              <div />
            )}
          </OverlayView>
        )}
        {showTraffic && <TrafficLayer autoUpdate />}
        <MapCustomControl
          controlPosition={google.maps.ControlPosition.RIGHT_BOTTOM}
        >
          <MapButton
            onClick={this.handleZoomExitViewClick}
            modifiers={compact([zoom === EXIT_VIEW_ZOOM_LEVEL && 'active'])}
            style={{ marginRight: 7, marginBottom: 3 }}
          >
            <Icon name="road-exit" />
          </MapButton>
        </MapCustomControl>
      </GoogleMap>
    );
  }
}

export default MapComponent;

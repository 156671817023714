import gql from 'graphql-tag';

export default gql`
  mutation createDailyDealerReport(
    $billable: Boolean
    $caseNumber: String
    $customerLocation: String
    $customerName: String
    $dateRangeType: DateRangeType!
    $dealerName: String
    $endTime: Date!
    $exportType: ReportType
    $inboundProgramName: String
    $paymentMethod: [PaymentMethod]
    $poNumber: String
    $startTime: Date!
    $unitNumber: String
    $reportLevel: DailyDealerReportLevel
  ) {
    createDailyDealerReport(
      filters: {
        billable: $billable
        caseNumber: $caseNumber
        customerLocation: $customerLocation
        customerName: $customerName
        dateRangeType: $dateRangeType
        dealerName: $dealerName
        endTime: $endTime
        inboundProgramName: $inboundProgramName
        paymentMethod: $paymentMethod
        poNumber: $poNumber
        startTime: $startTime
        type: $exportType
        unitNumber: $unitNumber
        reportLevel: $reportLevel
      }
    ) {
      jobId
    }
  }
`;

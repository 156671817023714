import { compose } from 'recompose';

import withCaseCreateRequestLines from './withCaseCreateRequestLines';
import withCaseDeleteRequestLines from './withCaseDeleteRequestLines';
import withCaseUpdateRequestLine from './withCaseUpdateRequestLine';

export default compose(
  withCaseCreateRequestLines,
  withCaseDeleteRequestLines,
  withCaseUpdateRequestLine,
);

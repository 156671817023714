import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName } from 'recompose';
import { Trans } from '@lingui/macro';
import { Mutation } from '@apollo/client/react/components';
import { get, curry } from 'lodash';
import { Redirect } from 'react-router-dom';

import { Text, QuickActionButton } from 'base-components';

import withLocations from '../../withLocations';

import createCaseMutation from './createCaseMutation';
import updateAssetLocationSearchMutation from './updateAssetLocationSearchMutation';
import createCaseRequestLineMutation from './createCaseRequestLineMutation';

/**
 * Render either a button which when clicked calls `createCase`, or status
 * text indicating the in-progress mutations. When both mutations have
 * been completed, render a Redirect to send us to the case detail page.
 */
export function NewCaseButton({
  createCase,
  createCaseInfo,
  updateCaseLocationInfo,
}) {
  if (createCaseInfo.loading) {
    return (
      <Text modifiers="small">
        <Trans>Creating a case...</Trans>
      </Text>
    );
  }

  if (updateCaseLocationInfo.loading) {
    return (
      <Text modifiers="small">
        <Trans>Setting asset location...</Trans>
      </Text>
    );
  }

  if (createCaseInfo.called && updateCaseLocationInfo.called) {
    const caseNumber = get(createCaseInfo, [
      'data',
      'createCase',
      'case',
      'caseNumber',
    ]);

    if (caseNumber) {
      return <Redirect to={`/cases/${caseNumber}`} />;
    }

    // eslint-disable-next-line no-console
    console.error('No case number found!', createCaseInfo);
  }

  return (
    <QuickActionButton onClick={createCase}>
      <QuickActionButton.Icon modifiers={['left']} name="briefcase" />
      <QuickActionButton.Text>
        <Trans>Create Case</Trans>
      </QuickActionButton.Text>
    </QuickActionButton>
  );
}

NewCaseButton.propTypes = {
  createCase: PropTypes.func.isRequired,
  createCaseInfo: PropTypes.shape({
    loading: PropTypes.bool,
    called: PropTypes.bool,
    data: PropTypes.shape({
      createCase: PropTypes.shape({
        case: PropTypes.shape({
          caseNumber: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
  updateCaseLocationInfo: PropTypes.shape({
    loading: PropTypes.bool,
    called: PropTypes.bool,
  }).isRequired,
};

/**
 * Renders a button that, when clicked, starts a four-step process:
 * 1. create a new case
 * 2. set the location of the new case to the first location given
 *    in `locationSearches`
 * 3. creates an empty request line for the newly created case
 * 4. redirect to the new case's detail page
 */
export function NewCaseFromLocation({ locationSearches }) {
  const countryCode = get(locationSearches, [0, 'countryCode']);
  const location = get(locationSearches, [0, 'location']);
  const searchValue = get(locationSearches, [0, 'searchValue']);

  if (
    !(
      countryCode &&
      location &&
      location.latitude &&
      location.longitude &&
      searchValue
    )
  ) {
    return null;
  }

  // Fire and forget 🤞 ¯\_(ツ)_/¯
  const onCreateCaseCompleted = curry(
    (updateCaseLocation, createRequestLine, caseData) => {
      const caseId = get(caseData, 'createCase.case.id');

      updateCaseLocation({
        variables: { id: caseId, location, countryCode, searchValue },
      });

      createRequestLine({ variables: { caseId } });
    },
  );

  return (
    <Mutation mutation={updateAssetLocationSearchMutation}>
      {(updateCaseLocation, updateCaseLocationInfo) => (
        <Mutation mutation={createCaseRequestLineMutation}>
          {(createRequestLine) => (
            <Mutation
              mutation={createCaseMutation}
              onCompleted={onCreateCaseCompleted(
                updateCaseLocation,
                createRequestLine,
              )}
            >
              {(createCase, createCaseInfo) =>
                NewCaseButton({
                  createCase,
                  createCaseInfo,
                  updateCaseLocation,
                  updateCaseLocationInfo,
                })
              }
            </Mutation>
          )}
        </Mutation>
      )}
    </Mutation>
  );
}

NewCaseFromLocation.propTypes = {
  locationSearches: PropTypes.arrayOf(
    PropTypes.shape({
      countryCode: PropTypes.string.isRequired,
      location: PropTypes.shape({
        latitude: PropTypes.number.isRequired,
        longitude: PropTypes.number.isRequired,
      }).isRequired,
      searchValue: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default compose(
  setDisplayName('NewCaseFromLocation'),
  withLocations,
)(NewCaseFromLocation);

import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { Text } from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Container, Row, Column } from 'styled-components-grid';

import withContext from 'utils/withContext';
import RadioButton from 'components/RadioButton';

import InboundProgramsSelector from './InboundProgramsSelector';
import ReportConfigBuilderContext from '../ReportConfigBuilder/Context';

import { configTypes } from '../ReportConfigBuilder/constants';
import { detailLevelTypes, selectionTypes } from './constants';

const {
  detailLevel,
  inboundProgramIds,
  inboundProgramSelectionType: ipSelectionType,
} = configTypes;

const AssetValidationReportCustomFilters = (props) => {
  const { config, updateReportConfig: updateConfig } = props;

  const selectedDetailLevel = config[detailLevel];
  const selectedIpSelectionType = config[ipSelectionType];

  const setConfigValue = (type, value) => () => {
    const newConfig = { [type]: value };

    if (type === ipSelectionType && value === selectionTypes.all) {
      newConfig[inboundProgramIds] = [];
    }

    return updateConfig(newConfig);
  };

  return (
    <Container modifiers="padScale_0" style={{ marginTop: px2rem(20) }}>
      <Row>
        <Column modifiers={['col', 'padScaleX_0']}>
          <Text>
            <Trans>Level of Detail</Trans>
          </Text>
        </Column>
      </Row>
      <Row>
        <Column modifiers="padScaleX_0">
          <RadioButton
            id="reportTypeSummary"
            label={<Trans>Totals by Inbound Program</Trans>}
            style={{ marginRight: px2rem(15) }}
            value={detailLevelTypes.summary}
            checked={selectedDetailLevel === detailLevelTypes.summary}
            onChange={setConfigValue(detailLevel, detailLevelTypes.summary)}
          />
        </Column>
      </Row>
      <Row>
        <Column modifiers="padScaleX_0">
          <RadioButton
            id="reportTypeDetail"
            label={<Trans>Breakdown of individual validation attempts</Trans>}
            value={detailLevelTypes.detail}
            checked={selectedDetailLevel === detailLevelTypes.detail}
            onChange={setConfigValue(detailLevel, detailLevelTypes.detail)}
          />
        </Column>
      </Row>
      <Row style={{ marginTop: 15 }}>
        <Column modifiers={['col', 'padScaleX_0']}>
          <Text>
            <Trans>Inbound Programs</Trans>
          </Text>
        </Column>
      </Row>
      <Row>
        <Column modifiers="padScaleX_0">
          <RadioButton
            id="inboundProgramSelectionTypeAll"
            label={<Trans>All</Trans>}
            style={{ marginRight: px2rem(15) }}
            value={selectionTypes.all}
            checked={selectedIpSelectionType === selectionTypes.all}
            onChange={setConfigValue(ipSelectionType, selectionTypes.all)}
          />
        </Column>
      </Row>
      <Row>
        <Column modifiers="padScaleX_0">
          <RadioButton
            id="inboundProgramSelectionTypeCustom"
            label={<Trans>Custom Selection</Trans>}
            value={selectionTypes.custom}
            checked={selectedIpSelectionType === selectionTypes.custom}
            onChange={setConfigValue(ipSelectionType, selectionTypes.custom)}
          />
        </Column>
      </Row>
      {selectedIpSelectionType === selectionTypes.custom && (
        <InboundProgramsSelector {...props} />
      )}
    </Container>
  );
};

AssetValidationReportCustomFilters.propTypes = {
  config: PropTypes.shape({}).isRequired,
  updateReportConfig: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('AssetValidationReportCustomFilters'),
  withContext(ReportConfigBuilderContext),
)(AssetValidationReportCustomFilters);

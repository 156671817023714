import { curry, debounce, get, isEmpty, noop } from 'lodash';
import { Component } from 'react';
import PropTypes from 'prop-types';

import { getEncodedPolyline } from 'features/googleMaps/location';
import GoogleMapPropTypes from 'features/googleMaps/propTypes';
import parseLatLng from 'utils/geoLocation';
import {
  geocodeSearchValue,
  parseGeocodeResult,
  getLocationSearchUpdates,
} from './utils';

import {
  defaultLocationSearch,
  LOCATION_SEARCHES,
  ASSET_LOCATION_SEARCH_INDEX,
} from '../constants';

class LocationsLogicAndData extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    geocode: PropTypes.func.isRequired,
    googleMaps: GoogleMapPropTypes.googleMapsApi,
    locationSearches: PropTypes.arrayOf(
      PropTypes.shape({
        location: PropTypes.shape({
          latitude: PropTypes.number.isRequired,
          longitude: PropTypes.number.isRequired,
        }),
        searchValue: PropTypes.string,
        searchValueTried: PropTypes.string,
      }),
    ).isRequired,
    mapBounds: GoogleMapPropTypes.latLngBounds,
    onAssetLocationChange: PropTypes.func,
    reverseGeocode: PropTypes.func.isRequired,
    updateDealerLocatorContext: PropTypes.func.isRequired,
  };

  static defaultProps = {
    googleMaps: null,
    onAssetLocationChange: noop,
    mapBounds: null,
  };

  // eslint-disable-next-line react/sort-comp
  addLocationSearch = () => {
    this.props.updateDealerLocatorContext({
      [LOCATION_SEARCHES]: this.props.locationSearches.concat(
        defaultLocationSearch,
      ),
    });
  };

  deleteLocationSearch = (index) => {
    const newLocationSearches = this.props.locationSearches.slice(0);
    newLocationSearches.splice(index + 1, 1);

    this.props.updateDealerLocatorContext({
      [LOCATION_SEARCHES]: newLocationSearches,
      countryCode: get(newLocationSearches, '0.countryCode'),
      encodedPolyline: getEncodedPolyline(
        this.props.googleMaps,
        newLocationSearches,
      ),
    });
  };

  debouncedGeocode = debounce(
    (locationSearchIndex) => this.geocodeLocation(locationSearchIndex),
    1000,
  );

  updateMapBounds = (mapBounds) => {
    this.props.updateDealerLocatorContext({ mapBounds });
  };

  debouncedUpdateMapBounds = debounce(this.updateMapBounds, 500);

  geocodeLocation = async (locationSearchIndex) => {
    const currentLocationSearch = this.props.locationSearches[
      locationSearchIndex
    ];
    const { searchValue } = currentLocationSearch;

    try {
      const { reverseGeocode, geocode } = this.props;

      const response = await geocodeSearchValue({
        searchValue,
        geocode,
        reverseGeocode,
      });

      const locationSearchUpdates = getLocationSearchUpdates(
        response,
        searchValue,
      );

      this.updateLocationSearchAtIndex(
        locationSearchIndex,
        locationSearchUpdates,
      );
    } catch (status) {
      this.updateGeocodeError(locationSearchIndex, {
        searchValueTried: searchValue || undefined,
      });
    }
  };

  resetLocation = (locationSearchIndex) => () => {
    this.updateLocationSearchAtIndex(
      locationSearchIndex,
      defaultLocationSearch,
    );
    this.debouncedGeocode(locationSearchIndex);

    this.props.onAssetLocationChange(defaultLocationSearch);
  };

  updateGeocodeError = (locationSearchIndex, locationSearchUpdates) => {
    this.updateLocationSearchAtIndex(locationSearchIndex, {
      location: undefined,
      searchValueTried: undefined,
      addressComponents: {
        city: null,
        country: null,
        postalCode: null,
        province: null,
        streetAddress: null,
      },
      ...locationSearchUpdates,
    });
  };

  updateLocation = curry((locationSearchIndex, location) => {
    this.updateLocationSearchAtIndex(locationSearchIndex, { location });
  });

  updateLocationInput = curry((locationSearchIndex, event) => {
    const searchValue = event.target.value;

    this.updateLocationSearchAtIndex(locationSearchIndex, { searchValue });

    if (isEmpty(searchValue)) {
      this.debouncedGeocode(locationSearchIndex);
    }
  });

  /**
   * Applies the locationSearchUpdates to the locationSearch at the index provided.
   *
   * @param {number} locationSearchIndex The index of the locationSearch to be updated.
   * @param {object} locationSearchUpdates The updates to apply to the existing locationSearch.
   * @memberof LocationsLogicAndData
   */
  updateLocationSearchAtIndex = (
    locationSearchIndex,
    locationSearchUpdates,
  ) => {
    const newLocationSearches = this.props.locationSearches.map(
      (locationSearch, index) =>
        index === locationSearchIndex ? locationSearchUpdates : locationSearch,
    );

    this.props.updateDealerLocatorContext(
      {
        [LOCATION_SEARCHES]: newLocationSearches,
        countryCode: get(newLocationSearches, '0.countryCode'),
        encodedPolyline: getEncodedPolyline(
          this.props.googleMaps,
          newLocationSearches,
        ),
      },
      () => {
        if (locationSearchIndex === ASSET_LOCATION_SEARCH_INDEX) {
          this.props.onAssetLocationChange(
            newLocationSearches[ASSET_LOCATION_SEARCH_INDEX],
          );
        }
      },
    );
  };

  updateLocationToSelectedPlace = async (locationSearchIndex, places) => {
    const currentLocationSearch = this.props.locationSearches[
      locationSearchIndex
    ];
    let { searchValue } = currentLocationSearch;
    const place = places?.[0];

    const locationSearchUpdates = getLocationSearchUpdates(
      parseGeocodeResult(place, parseLatLng(searchValue)),
      searchValue,
    );

    // use the selected place if it has what we need
    // if country code and coordinates are present, we can skip geocoding
    if (
      locationSearchUpdates.travelEstimateDestination &&
      locationSearchUpdates.countryCode &&
      locationSearchUpdates.location?.latitude &&
      locationSearchUpdates.location?.longitude
    ) {
      this.updateLocationSearchAtIndex(
        locationSearchIndex,
        locationSearchUpdates,
      );

      return;
    }

    // search with whats in the input exactly and not from the dropdown
    this.debouncedGeocode(locationSearchIndex);
  };

  updateReverseGeocodeError = (locationSearchIndex, location) => {
    const { searchValue } = this.props.locationSearches[locationSearchIndex];

    const locationSearchUpdates = {
      countryCode: undefined,
      location,
      searchValueTried: searchValue,
    };

    this.updateLocationSearchAtIndex(
      locationSearchIndex,
      locationSearchUpdates,
    );
  };

  render() {
    const { locationSearches, mapBounds } = this.props;

    const childProps = {
      locationSearches,
      mapBounds,
      onAddLocationSearch: this.addLocationSearch,
      onDeleteLocationSearch: this.deleteLocationSearch,
      onLocationChange: this.updateLocation,
      onLocationInputChange: this.updateLocationInput,
      onMapBoundsChanged: this.debouncedUpdateMapBounds,
      onLocationReset: this.resetLocation,
      updateLocationToSelectedPlace: this.updateLocationToSelectedPlace,
      updateLocationSearchAtIndex: this.updateLocationSearchAtIndex,
    };

    return this.props.children(childProps);
  }
}

export default LocationsLogicAndData;

import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { Text, Icon } from 'base-components';

import Temperature from './Temperature';

export default function WeatherDetailLeftPanel({
  weather: {
    current: {
      code: currentCode,
      summary: currentSummary,
      temperature: currentTemperature,
    },
  },
}) {
  return (
    <>
      <Text>
        <Trans>Now</Trans>
      </Text>
      <Temperature modifiers={['fontWeightMedium']}>
        <Icon modifiers={['chrome000', 'medium']} name={currentCode} />
        {` ${Math.round(currentTemperature)}°`}
      </Temperature>
      <Text>
        <Trans>{currentSummary}</Trans>
      </Text>
    </>
  );
}

WeatherDetailLeftPanel.propTypes = {
  weather: PropTypes.shape({
    current: PropTypes.shape({
      code: PropTypes.string,
      summary: PropTypes.string,
      temperature: PropTypes.number,
    }).isRequired,
  }).isRequired,
};

import gql from 'graphql-tag';

import { AssetFieldsFragment } from 'compositions/CaseRequestsPanel/CaseRequestsPanelContext/constants';

export const CASE_REQUEST_ASSETS_QUERY_NAME = 'caseRequestAssetsQuery';

export default gql`
  query caseRequestAssetsQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      fixpixPushResult
      primaryAsset {
        ...AssetFields
      }
      relatedAssets {
        ...AssetFields
      }
    }
  }
  ${AssetFieldsFragment}
`;

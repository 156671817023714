/* istanbul ignore file */
import React from 'react';
import { px2rem } from 'decisiv-ui-utils';
import { Trans } from '@lingui/macro';
import qs from 'qs';

import makeCellColumn from './builder';

export default makeCellColumn({
  columnName: 'name',
  label: <Trans>Name</Trans>,
  linkProps: ({ id, name, extraParams }) => ({
    'aria-label': name,
    to: `/tech-admin/dealers/${id}${
      extraParams ? `?${qs.stringify(extraParams)}` : ''
    }`,
  }),
  styles: {
    flex: 5,
    minWidth: px2rem(475),
    paddingLeft: px2rem(27),
  },
  text: ({ name }) => name,
});

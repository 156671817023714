import PropTypes from 'prop-types';
import styled from 'styled-components';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const styles = ({ theme }) => `
  align-items: center;
  background-color: ${theme.colors.base.chrome200};
  border-radius: 1px;
  display: flex;
  height: ${px2rem(2)};
  margin: 0 ${px2rem(5)};
  position: relative;
  width: ${px2rem(36)};
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome200: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const Bar = buildStyledComponent('Bar', styled.div, styles, { themePropTypes });

export default Bar;

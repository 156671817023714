import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { useQuery, gql } from '@apollo/client';

import ElapsedTime from 'components/ElapsedTime';

import countOfTime from 'utils/countOfTime';
import { CASE_STATUS } from 'compositions/CaseStatus';

const EXCLUDED_STATUSES = [
  CASE_STATUS.canceled,
  CASE_STATUS.closed,
  CASE_STATUS.rolling,
  CASE_STATUS.closed_canceled,
];

const query = gql`
  query CaseDetailOverviewPanel_AssetDowntimeQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      assetDowntimeMs
    }
  }
`;

function AssetDowntime(props) {
  const { caseNumber, createdAt, status: caseStatus } = props;
  const displayStaticTime = EXCLUDED_STATUSES.includes(caseStatus);

  const { data } = useQuery(query, {
    variables: { caseNumber },
    nextFetchPolicy: 'cache-only',
  });
  const assetDowntimeMs = data?.case.assetDowntimeMs;

  const LiveDowntime = () => <ElapsedTime inline sinceTime={createdAt} />;

  const StaticDowntime = () => (
    <span>{countOfTime(moment.duration(assetDowntimeMs, 'milliseconds'))}</span>
  );

  const DowntimeDisplay = displayStaticTime ? StaticDowntime : LiveDowntime;

  return (
    <Trans>
      <DowntimeDisplay /> Downtime
    </Trans>
  );
}

AssetDowntime.propTypes = {
  status: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  caseNumber: PropTypes.string.isRequired,
};

export default AssetDowntime;

import gql from 'graphql-tag';

export const NAME = 'caseCustomerContactsQuery';

const query = gql`
  query caseCustomerContactsQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      customer(useCachedDataForCase: true) {
        ... on Customer {
          id
          name
          type
          contacts {
            id
            name
            email
            contactTypes {
              name
            }
            contactNumbers {
              number
            }
          }
        }
        ... on StoreCustomer {
          id
          name
          type
          contacts {
            id
            name
            email
            contactTypes {
              name
            }
            contactNumbers {
              number
            }
          }
        }
        ... on CustomCustomer {
          type
          name
        }
        ... on CustomStoreCustomer {
          type
          name
        }
      }
    }
  }
`;

export default query;

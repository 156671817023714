import { t } from '@lingui/macro';

import { CASE_PANELS } from 'compositions/CaseStatus';

export const CASE_SHORTCUT_PANELS = {
  ...CASE_PANELS,
  dealerLocator: 'dealerLocator',
  billing: 'billing',
  activity: 'activity',
};

export const casePanelsActions = {
  [CASE_SHORTCUT_PANELS.inboundProgram]: {
    id: CASE_SHORTCUT_PANELS.inboundProgram,
    name: t`Inbound Program`,
    section: t`Case Navigation`,
    priority: 10,
  },
  [CASE_SHORTCUT_PANELS.customer]: {
    id: CASE_SHORTCUT_PANELS.customer,
    name: t`Fleet`,
    keywords: 'fleet customer',
    section: t`Case Navigation`,
    priority: 9,
  },
  [CASE_SHORTCUT_PANELS.contact]: {
    id: CASE_SHORTCUT_PANELS.contact,
    name: t`Contact`,
    section: t`Case Navigation`,
    priority: 8,
  },
  [CASE_SHORTCUT_PANELS.payment]: {
    id: CASE_SHORTCUT_PANELS.payment,
    name: t`Payment`,
    section: t`Case Navigation`,
    priority: 7,
  },
  [CASE_SHORTCUT_PANELS.billing]: {
    id: CASE_SHORTCUT_PANELS.billing,
    name: t`Billing`,
    section: t`Case Navigation`,
    priority: 6,
  },
  [CASE_SHORTCUT_PANELS.assetLocation]: {
    id: CASE_SHORTCUT_PANELS.assetLocation,
    name: t`Asset Location`,
    section: t`Case Navigation`,
    priority: 5,
  },
  [CASE_SHORTCUT_PANELS.dealerLocator]: {
    id: CASE_SHORTCUT_PANELS.dealerLocator,
    name: t`Service Provider Locator`,
    keywords: 'service provider dealer locator',
    section: t`Case Navigation`,
    priority: 4,
  },
  [CASE_SHORTCUT_PANELS.dealerSelection]: {
    id: CASE_SHORTCUT_PANELS.dealerSelection,
    name: t`Service Provider Selection`,
    keywords: 'service provider dealer selection',
    section: t`Case Navigation`,
    priority: 3,
  },
  [CASE_SHORTCUT_PANELS.request]: {
    id: CASE_SHORTCUT_PANELS.request,
    name: t`Request`,
    section: t`Case Navigation`,
    priority: 2,
  },
  [CASE_SHORTCUT_PANELS.assetValidation]: {
    id: CASE_SHORTCUT_PANELS.assetValidation,
    name: t`Asset Validation`,
    section: t`Case Navigation`,
    priority: 1,
  },
  [CASE_SHORTCUT_PANELS.activity]: {
    id: CASE_SHORTCUT_PANELS.activity,
    name: t`Activity`,
    section: t`Case Navigation`,
    priority: 0,
  },
};

import { t } from '@lingui/macro';

export const acceptedReasons = ['Accepted', 'Accepted / Gave to Shop'];
export const closedDealerAcceptReasons = ['Accepted', 'Accepted / Fleet Delay'];
export const delayedServiceAcceptReasons = ['Accepted / Fleet Delay'];

export const allAcceptReasons = [
  ...new Set([
    ...acceptedReasons,
    ...closedDealerAcceptReasons,
    ...delayedServiceAcceptReasons,
  ]),
];

export const checkingTireAvailabilityReason = 'Checking Tire Availability';
export const otherDealerDispatchedReason = 'Other Dealer Dispatched';

export const rejectedReasons = [
  checkingTireAvailabilityReason,
  'Fleet cancellation',
  'Fleet cancelled after dispatch',
  'Fleet on credit hold',
  'Does not accept payment method',
  'ETA missed / Fleet cancelled',
  'ETA too long',
  'Holiday or event closure',
  'Incorrect tech rotation',
  'No afterhours service',
  'No answer',
  'No national accounts',
  'No service available',
  otherDealerDispatchedReason,
  'Other shop closer',
  'Out of service area (Greater than 50 miles)',
  'Out of service area (Less than 50 miles)',
  'Phone number disconnected',
  'Phone outage',
  'Poor weather conditions',
  'Power outage',
  'Referred to backup',
  'Referred to store',
  'Rim Not Available',
  'Technician refused service',
  'Tire Brand brand - Not stocked by dealer',
  'Tire Brand - Not stocked by dealer (Non Michelin)',
  'Tire preference not available',
  'Tire size not available (included in stock profile)',
  'Tire size not available (outside stock profile)',
  'Tires on backorder',
  'Too busy',
  'Unacceptable pricing',
];

export const closedDealerRejectReasons = [
  checkingTireAvailabilityReason,
  'No afterhours service',
  otherDealerDispatchedReason,
];

export const etaRequiringReasons = [
  ...allAcceptReasons,
  'ETA missed / Fleet cancelled',
  'ETA too long',
];

export const requiredDealerPaths = ['name', 'mileageRange.id'];
export const requiredResponsePaths = ['phoneNumber', 'contactPerson', 'reason'];

export const allReasonsLabels = {
  'Accepted / Fleet Delay': t`Accepted / Fleet Delay`,
  'Accepted / Gave to Shop': t`Accepted / Gave to Shop`,
  Accepted: t`Accepted`,
  'Checking Tire Availability': t`Checking Tire Availability`,
  'Does not accept payment method': t`Does not accept payment method`,
  'ETA missed / Fleet cancelled': t`ETA missed / Fleet cancelled`,
  'ETA too long': t`ETA too long`,
  'Fleet cancellation': t`Fleet cancellation`,
  'Fleet cancelled after dispatch': t`Fleet cancelled after dispatch`,
  'Fleet on credit hold': t`Fleet on credit hold`,
  'Holiday or event closure': t`Holiday or event closure`,
  'Incorrect tech rotation': t`Incorrect tech rotation`,
  'No afterhours service': t`No afterhours service`,
  'No answer': t`No answer`,
  'No national accounts': t`No national accounts`,
  'No service available': t`No service available`,
  'Other Dealer Dispatched': t`Other Service Provider Dispatched`,
  'Other shop closer': t`Other shop closer`,
  'Out of service area (Greater than 50 miles)': t`Out of service area (Greater than 50 miles)`,
  'Out of service area (Less than 50 miles)': t`Out of service area (Less than 50 miles)`,
  'Phone number disconnected': t`Phone number disconnected`,
  'Phone outage': t`Phone outage`,
  'Poor weather conditions': t`Poor weather conditions`,
  'Power outage': t`Power outage`,
  'Referred to backup': t`Referred to backup`,
  'Referred to store': t`Referred to store`,
  'Rim Not Available': t`Rim Not Available`,
  'Technician refused service': t`Technician refused service`,
  'Tire Brand - Not stocked by dealer (Non Michelin)': t`Tire Brand - Not stocked by service provider (Non Michelin)`,
  'Tire Brand brand - Not stocked by dealer': t`Tire Brand brand - Not stocked by service provider`,
  'Tire preference not available': t`Tire preference not available`,
  'Tire size not available (included in stock profile)': t`Tire size not available (included in stock profile)`,
  'Tire size not available (outside stock profile)': t`Tire size not available (outside stock profile)`,
  'Tires on backorder': t`Tires on backorder`,
  'Too busy': t`Too busy`,
  'Unacceptable pricing': t`Unacceptable pricing`,
};

import styled, { css } from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';
import { Container, Row, Column } from 'styled-components-grid';

const FrameColumn = buildStyledComponent(
  'Frame_Column',
  styled(Column),
  ({ theme }) => css`
    border: solid 1px ${theme.colors.base.chrome300};
    margin-right: -1px;
    min-height: ${px2rem(120)};
    padding: 0;
    padding-bottom: ${px2rem(8)};

    &:last-child {
      margin-right: 0;
    }
  `,
);

const FrameRow = buildStyledComponent(
  'Frame_Row',
  styled(Row),
  css`
    margin-bottom: -1px;
  `,
);

const Frame = buildStyledComponent(
  'Frame',
  styled(Container).attrs(({ modifiers = '' }) => ({
    modifiers: [
      'padScale_0',
      ...(Array.isArray(modifiers) ? modifiers : [modifiers]),
    ],
  })),
  css`
    width: calc(100% + 1px);
  `,
);

Frame.Column = FrameColumn;
Frame.Row = FrameRow;

export default Frame;

import PropTypes from 'prop-types';
import React from 'react';

import { modifiersForComponent } from 'utils/modifiers';

import Button from './Button';
import Li from './Li';

function SelectableLi({ modifiers, children, style, className, ...rest }) {
  return (
    <Li
      modifiers={modifiersForComponent(modifiers, Li)}
      style={style}
      className={className}
    >
      <Button modifiers={modifiersForComponent(modifiers, Button)} {...rest}>
        {children}
      </Button>
    </Li>
  );
}

SelectableLi.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.element.isRequired,
  style: PropTypes.any,
  className: PropTypes.string,
};

SelectableLi.defaultProps = {
  modifiers: [],
};

export default SelectableLi;

import React from 'react';
import { Query, Mutation } from '@apollo/client/react/components';
import { curry, get, isEmpty, omit } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { getUnits } from 'utils/unit';
import { unitSelector } from 'redux/preferences/selectors';

import { NAME as DELAYED_SERVICE_QUERY } from 'compositions/CaseRequestsPanel/withCaseDelayedService/caseDelayedServiceQuery';

import caseAssetLocationGQL from './caseAssetLocationQuery';
import updateAssetLocationSearchGQL from './updateAssetLocationSearchMutation';

const buildMutationChild = curry((WrappedComponent, componentProps, mutate) => {
  const updateCase = ({ variables }) => {
    let finalVariables = variables;

    if (!isEmpty(variables.addressComponents)) {
      finalVariables = {
        ...omit(variables, 'addressComponents'),
        address: omit(variables.addressComponents, [
          '__typename',
          'countryCode',
        ]),
      };
    }

    mutate({ variables: finalVariables });
  };

  return <WrappedComponent {...componentProps} updateCase={updateCase} />;
});

function buildWrappedComponentWithMutations(WrappedComponent, componentProps) {
  return (
    <Mutation
      mutation={updateAssetLocationSearchGQL}
      refetchQueries={[DELAYED_SERVICE_QUERY]}
    >
      {buildMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

function skipQuery({ caseNumber }) {
  return !caseNumber;
}

function buildQueryVariables({ caseNumber, unit }) {
  return {
    caseNumber,
    distanceUnit: getUnits(unit).distanceUnitValue,
  };
}

const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const caseDetail = get(queryProps, 'data.case');
    const loading = get(queryProps, 'loading');

    return buildWrappedComponentWithMutations(WrappedComponent, {
      ...componentProps,
      caseDetail,
      loading,
    });
  },
);

const withQuery = (WrappedComponent) => (componentProps) => (
  <Query
    skip={skipQuery(componentProps)}
    query={caseAssetLocationGQL}
    variables={buildQueryVariables(componentProps)}
    fetchPolicy="network-only"
  >
    {buildQueryChild(WrappedComponent, componentProps)}
  </Query>
);

const mapStateToProps = (state) => ({ unit: unitSelector(state) });

const withAssetLocationSearch = (WrappedComponent) =>
  compose(connect(mapStateToProps), withQuery)(WrappedComponent);

export default withAssetLocationSearch;

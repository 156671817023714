import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { defineMessage, Trans } from '@lingui/macro';

import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';
import { H2, P, QuickActionButton, Text } from 'base-components';

import Modal from 'components/Modal';

import { AfterHoursContactPropType } from '../../propTypes';

import LoadingMessage from '../../LoadingMessage';

const Emphasis = (
  <Text modifiers="fontWeightMedium" style={{ display: 'inline' }} />
);

const message = defineMessage({
  message:
    "The contact <0>{contactName}</0> is not associated with any upcoming rotations and can be safely deleted. Are you sure you want to delete this contact? This can't be undone.",
});

class ConfirmDeleteModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    contact: AfterHoursContactPropType.isRequired,
    deleteAfterHoursContact: PropTypes.func.isRequired,
    isDeletingAfterHoursContact: PropTypes.bool.isRequired,
  };

  handleDeleteContact = async () => {
    const { onClose, contact, deleteAfterHoursContact } = this.props;
    await deleteAfterHoursContact(contact.id);
    onClose();
  };

  render() {
    const { onClose, contact, isDeletingAfterHoursContact } = this.props;

    return (
      <Modal onClose={onClose}>
        {() => (
          <Modal.Body>
            <Modal.Header style={{ padding: px2rem(20) }}>
              <Modal.HeaderIcon name="user-times" />
            </Modal.Header>
            <Modal.Content>
              <Row modifiers="center" style={{ marginBottom: px2rem(10) }}>
                <Column>
                  <H2 modifiers="fontWeightRegular">
                    <Trans>Delete Contact</Trans>
                  </H2>
                </Column>
              </Row>
              <Row modifiers="center">
                <Column modifiers="col">
                  <P>
                    <Trans
                      id={message}
                      values={{ contactName: contact.name }}
                      components={[Emphasis]}
                    />
                  </P>
                </Column>
              </Row>
            </Modal.Content>
            <Modal.Footer>
              <Row modifiers="end">
                {isDeletingAfterHoursContact && (
                  <LoadingMessage
                    message={<Trans>Deleting, please wait...</Trans>}
                  />
                )}
                <Column
                  modifiers={['padScale_0']}
                  style={{ paddingLeft: px2rem(10) }}
                >
                  <QuickActionButton onClick={onClose}>
                    <QuickActionButton.Text>
                      <Trans>Cancel</Trans>
                    </QuickActionButton.Text>
                  </QuickActionButton>
                </Column>
                <Column
                  modifiers={['padScale_0']}
                  style={{ paddingLeft: px2rem(10) }}
                >
                  <QuickActionButton
                    onClick={this.handleDeleteContact}
                    modifiers={[
                      'secondary',
                      isDeletingAfterHoursContact && 'disabled',
                    ]}
                    disabled={isDeletingAfterHoursContact}
                  >
                    <QuickActionButton.Text>
                      <Trans>Delete Contact</Trans>
                    </QuickActionButton.Text>
                  </QuickActionButton>
                </Column>
              </Row>
            </Modal.Footer>
          </Modal.Body>
        )}
      </Modal>
    );
  }
}

export default ConfirmDeleteModal;

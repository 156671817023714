import { Trans } from '@lingui/macro';
import React from 'react';

import { MessageMedium } from 'base-components';

function PaymentEmpty() {
  return (
    <MessageMedium modifiers={['padScaleY_5']}>
      <MessageMedium.Header>
        <MessageMedium.Icon name="credit-card" />
      </MessageMedium.Header>
      <MessageMedium.Section>
        <MessageMedium.Text modifiers={['textLight']}>
          <Trans>Select the fleet to view accepted payment methods.</Trans>
        </MessageMedium.Text>
      </MessageMedium.Section>
    </MessageMedium>
  );
}

export default PaymentEmpty;

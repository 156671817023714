import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';

import withGeocoder from './withGeocoder';

/**
 * `WithReverseGeocoding` is a render-prop component that has a location prop
 * containing lat/lon values, and will provide reverse-geocoding of the
 * address of this lat/lon. On each prop update, if the lat/lon has changed
 * (or no geocoding has yet happened), the HOC starts a geocode process,
 * and upon successful completion does two things:
 * 1. provides an `address` prop value to the wrapped component, set
 *    to the first formatted address provided by the geocoder
 * 2. if an `onAddressUpdate` function prop is provided, calls this
 *    function with that formatted address as well
 */
export class WithReverseGeocoding extends React.Component {
  static propTypes = {
    location: PropTypes.shape({
      lat: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      lon: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    onAddressUpdate: PropTypes.func,
    render: PropTypes.func.isRequired,
    reverseGeocode: PropTypes.func.isRequired,
  };

  static defaultProps = {
    location: {},
    onAddressUpdate: noop,
  };

  state = {
    address: undefined,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { location: { lat: oldLat, lon: oldLon } = {} } = this.props;
    const {
      location: { lat, lon } = {},
      reverseGeocode,
      onAddressUpdate,
    } = nextProps;

    // unless we're currently geocoding, then if we have a location and it is different
    // from the previous location (or the current address has not been set yet), then
    // let's geocode now...
    const currentAddress = this.state && this.state.address;
    if (
      !this.geocoding &&
      lat &&
      lon &&
      (!currentAddress || oldLat !== lat || oldLon !== lon)
    ) {
      this.geocoding = true;
      this.setAddress('Loading...');

      const location = { lat: parseFloat(lat), lng: parseFloat(lon) };

      // kick off the (asynchronous) geocode process:
      reverseGeocode(location)
        .then(({ address }) => {
          this.setAddress(address);
          // call the callback so the address is passed up to any interested parent components
          onAddressUpdate(address);
          this.geocoding = false;
        })
        .catch((status) => {
          // eslint-disable-next-line no-console
          console.error(`Geocoder failed for [${lat}, ${lon}]: `, status);
          this.geocoding = false;
        });
    }
  }

  setAddress = (address) => {
    this.setState({ address });
  };

  render() {
    return this.props.render(this.state);
  }
}

export default compose(withGeocoder)(WithReverseGeocoding);

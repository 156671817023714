import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import { setBackground, setBoxShadow } from '../../utils/arrow';

import constants from './constants';

const {
  ARROW_BASE,
  ARROW_HEIGHT,
  BOTTOM_ROTATE,
  LEFT_ROTATE,
  RIGHT_ROTATE,
  TOP_ROTATE,
} = constants;

const modifierConfig = {
  bottom: ({ theme }) => `
    background: ${setBackground(theme.colors.base.chrome700)};
    box-shadow: ${setBoxShadow('bottom', theme.colors.base.shadowLight)};
    left: 0;
    margin: auto;
    right: 0;
    top: -${px2rem(ARROW_HEIGHT)};
    transform: rotate(${BOTTOM_ROTATE}deg) !important;
  `,

  left: ({ theme }) => `
    background: ${setBackground(theme.colors.base.chrome700)};
    bottom: 0;
    box-shadow: ${setBoxShadow('left', theme.colors.base.shadow)};
    margin: auto;
    right: -${px2rem(ARROW_HEIGHT)};
    top: 0;
    transform: rotate(${LEFT_ROTATE}deg) !important;
  `,

  right: ({ theme }) => `
    background: ${setBackground(theme.colors.base.chrome700)};
    bottom: 0;
    box-shadow: ${setBoxShadow('right', theme.colors.base.shadow)};
    left: -${px2rem(ARROW_HEIGHT)};
    margin: auto;
    top: 0;
    transform: rotate(${RIGHT_ROTATE}deg) !important;
  `,

  top: ({ theme }) => `
    background: ${setBackground(theme.colors.base.chrome700)};
    bottom: -${px2rem(ARROW_HEIGHT)};
    box-shadow: ${setBoxShadow('top', theme.colors.base.shadow)};
    left: 0;
    margin: auto;
    right: 0;
    transform: rotate(${TOP_ROTATE}deg) !important;
  `,
};

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome700: PropTypes.string.isRequired,
      shadow: PropTypes.string.isRequired,
      shadowLight: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const styles = `
  height: ${px2rem(ARROW_BASE)};
  position: absolute;
  width: ${px2rem(ARROW_BASE)};
`;

export default buildStyledComponent('Tooltip__Arrow', styled.div, styles, {
  modifierConfig,
  themePropTypes,
});

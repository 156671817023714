import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

const styles = `
  cursor: default;
`;

const Target = buildStyledComponent('Tooltip__Target', styled.span, styles);

export default Target;

/**
 * This code is heavily based on the map instance persistence logic found here:
 * https://github.com/JustFly1984/react-google-maps-api/blob/master/packages/react-google-maps-api/src/utils/instance-persistance.ts
 *
 * It's been adapted to work with react-google-maps, since that's the map lib we are using.
 */
const buildHiddenMapContainerId = (id) =>
  `google-map-hidden-map-container-${id}`;
const buildMapContainerId = (id) => `google-map-container-${id}`;
const buildMapElementId = (id) => `google-map-element-${id}`;
const buildMapInstanceId = (id) => `google-map-instance-${id}`;

function clearChildren(node) {
  if (node) {
    while (node.firstChild) {
      node.removeChild(node.firstChild);
    }
  }
}

function findOrCreateHiddenMapContainer(id) {
  const hiddenMapContainerId = buildHiddenMapContainerId(id);

  let hiddenMapContainer = document.getElementById(hiddenMapContainerId);

  if (!hiddenMapContainer) {
    hiddenMapContainer = document.createElement('div');
    hiddenMapContainer.id = hiddenMapContainerId;
    hiddenMapContainer.style.display = 'none';
    document.body.appendChild(hiddenMapContainer);
  }

  return hiddenMapContainer;
}

function restoreInstance(id) {
  const map = window[buildMapInstanceId(id)];

  const hiddenMapContainer = findOrCreateHiddenMapContainer(id);

  if (map && hiddenMapContainer.children.length === 1) {
    const mapContainer = document.getElementById(buildMapContainerId(id));

    if (mapContainer) {
      clearChildren(mapContainer);
      mapContainer.appendChild(hiddenMapContainer.children[0]);
    }

    return map;
  }

  return false;
}

function saveInstance(id, map) {
  const hiddenMapContainer = findOrCreateHiddenMapContainer(id);

  clearChildren(hiddenMapContainer);

  const mapElement = document.getElementById(buildMapElementId(id));

  if (mapElement) {
    hiddenMapContainer.appendChild(mapElement);
  }

  window[buildMapInstanceId(id)] = map;
}

export {
  buildMapContainerId,
  buildMapElementId,
  restoreInstance,
  saveInstance,
};

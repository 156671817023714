import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Container } from 'styled-components-grid';
import { px2rem, buildStyledComponent } from 'decisiv-ui-utils';

export default buildStyledComponent(
  'EntryContainer',
  styled(Container),
  ({ theme }) => `
    padding: ${px2rem(20)} ${px2rem(8)} 0;

    &::before {
      background: ${theme.colors.base.chrome200};
      bottom: 0;
      content: '';
      display: block;
      left: 24px;
      position: absolute;
      top: 0;
      width: 2px;
    }
  `,
  {
    themePropTypes: PropTypes.shape({
      colors: PropTypes.shape({
        base: PropTypes.shape({
          chrome200: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  },
);

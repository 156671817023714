import PropTypes from 'prop-types';
import React from 'react';

import { Text } from 'base-components';
import { Column } from 'styled-components-grid';

import { modifiersForComponent } from 'utils/modifiers';

function WeatherTime({ children, modifiers }) {
  return (
    <Column modifiers={modifiersForComponent(modifiers, Column)}>
      <Text
        modifiers={[
          ...modifiersForComponent(modifiers, Text),
          'small',
          'textLight',
          'uppercase',
        ]}
      >
        {children}
      </Text>
    </Column>
  );
}

WeatherTime.propTypes = {
  children: PropTypes.string.isRequired,
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

WeatherTime.defaultProps = {
  modifiers: [],
};

export default WeatherTime;

import PropTypes from 'prop-types';
import styled, { keyframes, css } from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

export const Animation = keyframes`
  0%   { left: 0; right: 100%; }
  50%  { left: 0; right: 0; }
  100% { left: 100%; right: 0; }
`;

export default buildStyledComponent(
  'ReportStatusModal__LoadingBar',
  styled.div,
  ({ theme }) => css`
    background: #3e2f9a;
    height: ${px2rem(3)};
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;

    ::after {
      animation-fill-mode: none;
      animation: ${Animation} 1.6s linear infinite;
      background: ${theme.colors.base.chrome200};
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
    }
  `,
  {
    themePropTypes: PropTypes.shape({
      colors: PropTypes.shape({
        base: PropTypes.shape({
          chrome200: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  },
);

import { t, Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import { ButtonLink } from 'base-components';
import { Row, Column } from 'styled-components-grid';

import { USER_ROLES, IfRolesMatch } from 'features/rbac';
import { QuickActionsShortcut } from 'features/keyShortcuts';

function AssignUserAction({
  assignCaseToCurrentUser,
  isAssigned,
  isAssignedToCurrentUser,
  unassignCase,
}) {
  const assignCaseLink = () => (
    <ButtonLink onClick={() => assignCaseToCurrentUser()}>
      <Trans>Assign to me</Trans>
    </ButtonLink>
  );

  const rowModifiers = isAssigned ? [] : ['height_100', 'middle'];
  const columnStyle = isAssigned ? { bottom: '2px' } : {};

  return (
    <Row modifiers={rowModifiers}>
      <Column modifiers="padScale_0" style={columnStyle}>
        {isAssignedToCurrentUser && (
          <QuickActionsShortcut
            action={{
              name: t`Unassign from Case`,
              icon: 'door-exit',
              shortcut: ['u', 'u'],
              perform: () => unassignCase(),
            }}
          >
            <ButtonLink onClick={() => unassignCase()}>
              <Trans>Unassign</Trans>
            </ButtonLink>
          </QuickActionsShortcut>
        )}
        {isAssigned && !isAssignedToCurrentUser && (
          <IfRolesMatch roles={USER_ROLES.ONCALL_ADMIN}>
            {assignCaseLink()}
          </IfRolesMatch>
        )}
        {!isAssigned && assignCaseLink()}
      </Column>
    </Row>
  );
}

AssignUserAction.propTypes = {
  assignCaseToCurrentUser: PropTypes.func.isRequired,
  isAssigned: PropTypes.bool.isRequired,
  isAssignedToCurrentUser: PropTypes.bool.isRequired,
  unassignCase: PropTypes.func.isRequired,
};

export default AssignUserAction;

import moment from 'moment-timezone';

import { formattedDuration } from 'utils/countOfTime';

export default function countOfTime(sinceTime, toTime) {
  const to = moment(toTime);
  const since = moment(sinceTime);
  const isFuture = since.isAfter(to);
  const duration = moment.duration(isFuture ? since.diff(to) : to.diff(since));

  return { isFuture, value: formattedDuration(duration) };
}

import styled from 'styled-components';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const styles = `
  min-width: ${px2rem(100)};
`;

export default buildStyledComponent(
  'ProximityFilter__Options',
  styled.div,
  styles,
);

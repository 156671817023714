import PropTypes from 'prop-types';
import React from 'react';
import { Row, Column } from 'styled-components-grid';

import Calendar from '../../../blocks/Calendar';
import QuickActionButton from '../../../blocks/QuickActionButton';

import P from '../../../elements/P';

import DateGrid from '../DateGrid';

// TODO: Determine a way to implement translations
const MONTHS = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

/**
 * Renders a month of the Calendar component.
 */
export function Month({
  disabledDates,
  displayMonth,
  hoveredOverDate,
  onClickNext,
  onClickPrevious,
  onSelectDate,
  range,
  selectedDate,
  selectedDateRange,
  today,
  updateHoveredOverDate,
  displayPreviousNav,
  displayNextNav,
}) {
  return (
    <Column modifiers={['col', 'fluid']}>
      <Calendar>
        <Row modifiers={['middle']}>
          <Column modifiers={['col_2']}>
            {displayPreviousNav && (
              <QuickActionButton
                className="previous-month-nav"
                modifiers={['iconOnly']}
                onClick={onClickPrevious}
                type="button"
              >
                <QuickActionButton.Icon
                  name="chevron-left"
                  modifiers={['mini']}
                />
              </QuickActionButton>
            )}
          </Column>
          <Column modifiers={['center', 'col', 'padScaleY_4']}>
            <P>{`${MONTHS[displayMonth.month]} ${displayMonth.year}`}</P>
          </Column>
          <Column modifiers={['col_2', 'end']}>
            {displayNextNav && (
              <QuickActionButton
                className="next-month-nav"
                modifiers={['iconOnly']}
                onClick={onClickNext}
                type="button"
              >
                <QuickActionButton.Icon
                  name="chevron-right"
                  modifiers={['mini']}
                />
              </QuickActionButton>
            )}
          </Column>
        </Row>
        <DateGrid
          disabledDates={disabledDates}
          displayMonth={displayMonth}
          hoveredOverDate={hoveredOverDate}
          onSelectDate={onSelectDate}
          range={range}
          selectedDate={selectedDate}
          selectedDateRange={selectedDateRange}
          today={today}
          updateHoveredOverDate={updateHoveredOverDate}
        />
      </Calendar>
    </Column>
  );
}

Month.propTypes = {
  disabledDates: PropTypes.shape({
    after: PropTypes.instanceOf(Date),
    before: PropTypes.instanceOf(Date),
  }),
  displayMonth: PropTypes.shape({
    year: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired,
    daysInMonth: PropTypes.number.isRequired,
  }).isRequired,
  displayNextNav: PropTypes.bool.isRequired,
  displayPreviousNav: PropTypes.bool.isRequired,
  hoveredOverDate: PropTypes.instanceOf(Date),
  onClickNext: PropTypes.func.isRequired,
  onClickPrevious: PropTypes.func.isRequired,
  onSelectDate: PropTypes.func.isRequired,
  range: PropTypes.bool,
  selectedDate: PropTypes.instanceOf(Date),
  selectedDateRange: PropTypes.shape({
    from: PropTypes.instanceOf(Date),
    to: PropTypes.instanceOf(Date),
  }),
  today: PropTypes.shape({
    year: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired,
    date: PropTypes.number.isRequired,
  }).isRequired,
  updateHoveredOverDate: PropTypes.func.isRequired,
};

Month.defaultProps = {
  disabledDates: {},
  hoveredOverDate: null,
  range: false,
  selectedDate: null,
  selectedDateRange: {},
};

export default Month;

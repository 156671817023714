import { curry, merge } from 'lodash';
import React from 'react';
import { Query } from '@apollo/client/react/components';

import { getUnits } from 'utils/unit';
import { latLngToString } from 'utils/geoLocation';

import DealerLocatorContext from '../DealerLocatorContext';

import dealerDetailGQL from './dealerDetailsQuery';

export function skipQuery({
  assetLocation,
  selectedDealer: { dealerId } = {},
  unit,
}) {
  return !(assetLocation && dealerId && unit);
}

export function buildQueryVariables({
  assetLocation: assetLocationCoordinates,
  selectedDealer: { dealerId: id } = {},
  unit,
}) {
  const { distanceUnitValue: distanceUnit } = getUnits(unit);
  const assetLocation = latLngToString(assetLocationCoordinates);
  return { assetLocation, distanceUnit, id };
}

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const {
      data: { dealer: dealerInfo } = {},
      loading: dealerDetailsLoading,
      refetch: refetchDealerDetails,
    } = queryProps;

    return (
      <WrappedComponent
        {...componentProps}
        dealerInfo={dealerInfo}
        dealerDetailsLoading={dealerDetailsLoading}
        refetchDealerDetails={refetchDealerDetails}
      />
    );
  },
);

function buildWrappedComponentWithQuery(WrappedComponent, componentProps) {
  return (
    <Query
      fetchPolicy="cache-and-network"
      onCompleted={({ dealer: newHighlightedDealer }) => {
        const {
          highlightedDealer: oldHighlightedDealer,
          updateDealerLocatorContext,
        } = componentProps;

        // The oldHighlightedDealer will be sourced from the locateDealers query,
        // and may include useful info such as distance from the route.
        // The newHighlightedDealer will be sourced from the dealer query,
        // and may include different info such as updated travel estimate info.
        const highlightedDealer = merge(
          {},
          oldHighlightedDealer,
          newHighlightedDealer,
        );

        // This keeps the highlighted dealer in sync with the apollo cache.
        // Useful if there are changes to travel info as the map will display
        // the highlighted dealer in context, which may not match the data
        // in the cache.
        updateDealerLocatorContext({
          highlightedDealer,
        });
      }}
      query={dealerDetailGQL}
      skip={skipQuery(componentProps)}
      variables={buildQueryVariables(componentProps)}
    >
      {buildQueryChild(WrappedComponent, componentProps)}
    </Query>
  );
}

export const buildConsumerChild = curry(
  (WrappedComponent, componentProps, consumerProps) => {
    const {
      highlightedDealer,
      locationSearches,
      selectedDealer,
      unit,
      updateDealerLocatorContext,
    } = consumerProps;
    const [assetLocationSearch] = locationSearches;
    const assetLocation = assetLocationSearch.location;

    return buildWrappedComponentWithQuery(WrappedComponent, {
      ...componentProps,
      assetLocation,
      highlightedDealer,
      selectedDealer,
      unit,
      updateDealerLocatorContext,
    });
  },
);

const withDealerDetails = (WrappedComponent) => (componentProps) => (
  <DealerLocatorContext.Consumer>
    {buildConsumerChild(WrappedComponent, componentProps)}
  </DealerLocatorContext.Consumer>
);

export default withDealerDetails;

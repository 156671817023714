import React from 'react';
import { t, Trans } from '@lingui/macro';
import { useQuery, gql } from '@apollo/client';

import { Text } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import { formatPhone } from 'utils/format';

const contactTypesQuery = gql`
  query contactTypes {
    caseContactTypes {
      option
      text
    }
  }
`;

const cellHeader = (headerTitle) => (
  <Container>
    <Row>
      <Column modifiers="col">
        <Text modifiers={['small', 'textLight']}>
          <Trans>{headerTitle}</Trans>
        </Text>
      </Column>
    </Row>
  </Container>
);

const Contact = (attrs) => {
  const { data } = useQuery(contactTypesQuery);

  const typeLabel = data?.caseContactTypes?.find(
    (type) => type.option === attrs.contactType,
  );

  return (
    <Container modifiers={['height_100', 'padScale_0']}>
      <Row modifiers={['middle', 'height_100']}>
        <Column modifiers="center">
          <Row>
            <Column modifiers="padScale_0">
              <Text>{attrs.contactName}</Text>
            </Column>
          </Row>
          {typeLabel && (
            <Row>
              <Column modifiers="padScale_0">
                <Text modifiers={['small', 'textLight']}>{typeLabel.text}</Text>
              </Column>
            </Row>
          )}
          <Row>
            <Column modifiers="padScale_0">
              <Text modifiers={['small', 'textLight']}>
                {formatPhone(attrs.contactPhoneNumber)}
                &nbsp;{attrs.contactPhoneExtension || ''}
              </Text>
            </Column>
          </Row>
        </Column>
      </Row>
    </Container>
  );
};

export const contact = {
  name: 'contact',
  width: '30%',
  headerCellRenderer: () => cellHeader(t`Contact Info`),
  cellKeyGetter: () => 'contact',
  cellDataGetter: (data) => data.callAttributes,
  dataCellRenderer: (data) => <Contact {...data} />,
};

export const message = {
  name: 'message',
  headerCellRenderer: () => cellHeader(t`Contact message`),
  cellKeyGetter: () => 'message',
  cellDataGetter: (data) => data.callAttributes,
  dataCellRenderer: (data) => (
    <Container modifiers="height_100">
      <Row modifiers={['height_100', 'middle']}>
        <Column modifiers="col">
          <Row modifiers="middle">
            <Column modifiers={['padScale_0', 'flex_1']}>
              <Text>{data.callMessage}</Text>
            </Column>
          </Row>
        </Column>
      </Row>
    </Container>
  ),
};

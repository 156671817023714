import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { Text } from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Container } from 'styled-components-grid';

import withContext from 'utils/withContext';
import { reportTypes } from 'pages/Reports/constants';

import ReportConfigBuilder from './ReportConfigBuilder';
import InputFieldWithSuggestions from './ReportConfigBuilder/InputFieldWithSuggestions';
import StatusListDropdown from './ReportConfigBuilder/StatusListDropdown';
import ReportConfigBuilderContext from './ReportConfigBuilder/Context';
import { configTypes, btStModeTypes } from './ReportConfigBuilder/constants';

const CustomFilters = ({ config }) => {
  const isBTMode = config[configTypes.btStModeType] === btStModeTypes.bt;
  return (
    <Container modifiers="padScale_0" style={{ marginTop: px2rem(20) }}>
      <Text>
        <Trans>Advanced Filters (Optional)</Trans>
      </Text>
      <InputFieldWithSuggestions configType={configTypes.caseNumber} />
      <InputFieldWithSuggestions configType={configTypes.unitNumber} />
      <InputFieldWithSuggestions
        configType={
          isBTMode ? configTypes.customerName : configTypes.customerLocation
        }
      />
      <InputFieldWithSuggestions
        configType={configTypes.inboundProgramName}
        grouped
      />
      <InputFieldWithSuggestions
        configType={
          isBTMode ? configTypes.dealerName : configTypes.dealerLocation
        }
      />

      <InputFieldWithSuggestions configType={configTypes.poNumber} />
      <StatusListDropdown />
    </Container>
  );
};

CustomFilters.propTypes = { config: PropTypes.shape({}).isRequired };

const DailyCasesMetricsCustomFilters = compose(
  setDisplayName('DailyCasesMetricsCustomFilters'),
  withContext(ReportConfigBuilderContext),
)(CustomFilters);

const DailyCasesMetrics = () => (
  <ReportConfigBuilder
    reportType={reportTypes.dailyCasesMetrics}
    showBtStModelToggle
  >
    <ReportConfigBuilder.Header />
    <DailyCasesMetricsCustomFilters />
    <ReportConfigBuilder.Footer />
  </ReportConfigBuilder>
);

export default DailyCasesMetrics;

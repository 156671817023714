import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

const styles = `
  list-style: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
`;

// TODO: This element should be in Base Components.
export default buildStyledComponent('Ul', styled.ul, styles);

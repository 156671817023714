import React, { useMemo } from 'react';
import { omit } from 'lodash';
import { useQuery } from '@apollo/client';

import query from './caseRequestAssetsQuery';
import { defaultAsset } from '../constants';

const withRequestAssets = (Component) => (cProps) => {
  const { caseNumber, ...rest } = cProps;

  const { data, loading } = useQuery(query, {
    skip: !caseNumber,
    variables: { caseNumber },
  });

  const caseData = data?.case || {};

  const primaryAsset = useMemo(
    () => omit(caseData.primaryAsset || defaultAsset, '__typename'),
    [caseData.primaryAsset],
  );

  const relatedAssets = useMemo(() => {
    const caseRelatedAssets = caseData.relatedAssets || [];

    return [0, 1].map((index) =>
      omit(caseRelatedAssets[index] || defaultAsset, '__typename'),
    );
  }, [caseData.relatedAssets]);

  return (
    <Component
      {...rest}
      caseId={caseData.id}
      caseNumber={caseNumber}
      primaryAsset={primaryAsset}
      relatedAssets={relatedAssets}
      isLoadingAssets={!!loading}
    />
  );
};

export default withRequestAssets;

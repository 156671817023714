import { compose, withProps } from 'recompose';

import { withOperationsStatus } from 'features/apolloOperationsObserver';

import { caseMutations } from '../../constants';

export default compose(
  withOperationsStatus(caseMutations, 'caseMutationsStatus'),
  withProps(({ caseMutationsStatus }) => ({
    isSaving: Object.values(caseMutationsStatus).some((s) => s.isLoading),
  })),
);

import React from 'react';
import PropTypes from 'prop-types';
import { px2rem } from 'decisiv-ui-utils';
import { Column, Row } from 'styled-components-grid';

import CloseButton from './CloseButton';
import StyledHeader from './StyledHeader';

function Header({ children, modifiers, showCloseButton, ...rest }) {
  const finalModifiers = [
    'info',
    ...(Array.isArray(modifiers) ? modifiers : [modifiers]),
  ];

  return (
    <StyledHeader modifiers={finalModifiers} {...rest}>
      {showCloseButton && (
        <CloseButton
          style={{
            background: 'none',
            position: 'absolute',
            top: px2rem(8),
            right: px2rem(8),
          }}
        />
      )}
      <Row>
        <Column modifiers={['col', 'center']}>{children}</Column>
      </Row>
    </StyledHeader>
  );
}

Header.propTypes = {
  children: PropTypes.node.isRequired,
  modifiers: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  showCloseButton: PropTypes.bool,
  ...StyledHeader.propTypes,
};

Header.defaultProps = {
  modifiers: [],
  showCloseButton: true,
};

Header.displayName = 'Modal__Header';

export default Header;

import styled from 'styled-components';
import { px2rem, buildStyledComponent } from 'decisiv-ui-utils';

const styles = `
  height: ${px2rem(15)};
  padding-right: ${px2rem(10)};
  width: ${px2rem(15)};
`;

export default buildStyledComponent('Icon', styled.img, styles);

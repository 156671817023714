import React from 'react';

import DealerSearchContext from './DealerSearchContext';
import Input from './Input';
import Dealers from './Dealers';

function DealerSearch(props) {
  return <DealerSearchContext {...props} />;
}

DealerSearch.Input = Input;
DealerSearch.Dealers = Dealers;

export default DealerSearch;

import { ApolloClient } from '@apollo/client';

import { configEnabled } from 'decisiv-domain-utils';

import buildCache from './cache';
import buildApolloLink from './links';

export default new ApolloClient({
  link: buildApolloLink(),
  cache: buildCache(),
  connectToDevTools: configEnabled('REACT_APP_ENABLE_DEV_TOOLS'),
});

import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Trans } from '@lingui/macro';

import { H4, Text } from 'base-components';
import { Column, Row } from 'styled-components-grid';

import AssetValidationRequirement from './AssetValidationRequirement';
import { allAssetTypes } from '../constants';

function AssetValidationStatus(props) {
  const { assetValidationStatus, primaryAsset, relatedAssets } = props;

  return (
    <Row>
      {[primaryAsset, ...relatedAssets].map((asset, index) => {
        const status = assetValidationStatus[get(asset, 'id')] || {};
        const requirements = Object.values(status.requirements || {});
        const requestAsset = get(status, 'asset') || {};

        return (
          <Column
            key={index} // eslint-disable-line react/no-array-index-key
            style={{
              display: 'flex',
              paddingLeft: !index ? 0 : undefined,
              flexDirection: 'column',
            }}
            modifiers={['col_4', 'padScaleX_2']}
          >
            <Row>
              <Column modifiers={['col', 'padScaleX_0']}>
                <H4 modifiers="fontWeightRegular" style={{ display: 'inline' }}>
                  <Trans id="Asset {index}" values={{ index: index + 1 }} />
                </H4>
                &nbsp;
                {!!requestAsset.type && (
                  <Text modifiers="small">
                    (<Trans id={allAssetTypes[requestAsset.type]} />)
                  </Text>
                )}
              </Column>
            </Row>
            <Row style={{ padding: 10, background: '#f7f7f7', flex: 1 }}>
              <Column modifiers={['col', 'padScale_0']}>
                {requirements.length ? (
                  requirements.map((requirement, reqIndex) => (
                    <AssetValidationRequirement
                      {...props}
                      key={requirement.type}
                      isLast={reqIndex === requirements.length - 1}
                      assetIndex={index}
                      requirement={requirement}
                    />
                  ))
                ) : (
                  <Text modifiers={['small', 'textLight']}>
                    <Trans>No validations required</Trans>
                  </Text>
                )}
              </Column>
            </Row>
          </Column>
        );
      })}
    </Row>
  );
}

AssetValidationStatus.propTypes = {
  /* eslint-disable react/no-unused-prop-types */
  isReadOnlyCase: PropTypes.bool.isRequired,
  /* eslint-enable react/no-unused-prop-types */

  primaryAsset: PropTypes.shape({}),
  relatedAssets: PropTypes.arrayOf(PropTypes.shape({})),
  assetValidationStatus: PropTypes.shape({}).isRequired,
};

AssetValidationStatus.defaultProps = {
  primaryAsset: undefined,
  relatedAssets: [],
};

export default AssetValidationStatus;

import gql from 'graphql-tag';

export default gql`
  query contactTypesQuery {
    caseContactTypes {
      default
      option
      text
    }
  }
`;

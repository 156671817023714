import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

import Input from './Input';
import Label from './Label';

const modifierConfig = {
  disabled: () => ({
    styles: css`
      opacity: 0.5;
      pointer-events: none;
    `,
  }),

  readOnly: ({ theme }) => ({
    styles: css`
      opacity: 1;
      cursor: not-allowed;

      ${/* sc-sel */ Input} {
        & + span::before {
          cursor: not-allowed;
          background: ${theme.colors.base.chrome100};
        }

        & + span {
          color: ${theme.colors.base.textLight};

          &:hover {
            color: ${theme.colors.base.textLight};
          }
        }

        &:checked + span {
          color: ${theme.colors.base.text};

          &:hover {
            color: ${theme.colors.base.text};
          }
        }

        &:hover + span::before {
          border: 1px solid ${theme.colors.base.chrome400};
        }
      }
    `,
  }),
};

const styles = css`
  display: inline-block;
`;

const Checkbox = buildStyledComponent('Checkbox', styled.div, styles, {
  modifierConfig,
  themePropTypes: {
    colors: PropTypes.shape({
      base: PropTypes.shape({
        chrome100: PropTypes.string.isRequired,
        chrome400: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        textLight: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  },
});

Checkbox.Input = Input;
Checkbox.Label = Label;

export default Checkbox;

/* eslint-disable react/prop-types */

import React from 'react';

import BaseRequestLinesTable from '../BaseRequestLinesTable';
import RequestLineActionsCell from './RequestLineActionsCell';
import ConfirmDeleteModal from './ConfirmDeleteModal';

const buildAdditionalColumns = (props) => [
  {
    name: 'actions',
    width: '4%',
    renderer: ({ rowData, ...rest }) => {
      const { removeRequestLine, duplicateRequestLine } = props;

      if (rest.isReadOnlyCase) return null;

      const data = { ...rest, removeRequestLine, duplicateRequestLine };

      return <RequestLineActionsCell {...data} rowData={rowData} />;
    },

    headerRenderer: () => null,
  },
];

function RequestedLinesTable(props) {
  const requestLines = props.requestLines.map((line) => ({
    ...line,
    // TODO: Uncomment when we want to enable readonly after dispatch
    // readOnly: line.isReadOnly || isReadOnlyCase,
    readOnly: props.isReadOnlyCase,
  }));

  return (
    <>
      <BaseRequestLinesTable
        {...props}
        requestLines={requestLines}
        additionalColumns={buildAdditionalColumns(props)}
      />
      <ConfirmDeleteModal {...props} />
    </>
  );
}

export default RequestedLinesTable;

import { t, Trans } from '@lingui/macro';
import React from 'react';
import PropTypes from 'prop-types';

import Li from 'elements/Li';

import ContactGroupLabel from './ContactGroupLabel';
import ContactItem from './ContactItem';

const contactTypes = {
  primaryContact: t`Primary Contact`,
  backupContact: t`Backup Contact`,
  finalContact: t`Final Contact`,
  dealer: t`Service Provider`,
};

function ContactGroupItem({ dealer, contact, type }) {
  return (
    <Li>
      <ContactGroupLabel>
        <Trans id={contactTypes[type]} />
      </ContactGroupLabel>
      <ContactItem
        isCompany={type === 'dealer'}
        dealer={dealer}
        info={contact}
      />
    </Li>
  );
}

ContactGroupItem.propTypes = {
  type: PropTypes.string.isRequired,
  contact: PropTypes.shape({}).isRequired,
  dealer: PropTypes.shape({}).isRequired,
};

export default ContactGroupItem;

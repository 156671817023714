import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import { get, curry } from 'lodash';
import { ApolloConsumer } from '@apollo/client';
import { compose, setDisplayName } from 'recompose';

import searchCustomersQuery from './searchCustomersQuery';
import setCaseCustomerMutation from './setCaseCustomerMutation';

function withSetCaseCustomerByShipTo(WrappedComponent, componentProps) {
  return (
    <Mutation mutation={setCaseCustomerMutation}>
      {(mutate, { loading }) => {
        const setCaseCustomerByShipTo = async (shipTo) => {
          const { caseId, getCustomerByShipTo } = componentProps;
          const customer = await getCustomerByShipTo(shipTo);

          return mutate({ variables: { caseId, customerId: customer.id } });
        };

        return (
          <WrappedComponent
            {...componentProps}
            setCaseCustomerByShipTo={setCaseCustomerByShipTo}
            isUpdatingCaseCustomer={!!loading}
          />
        );
      }}
    </Mutation>
  );
}

function withGetCustomerByShipTo(WrappedComponent, componentProps) {
  return (
    <ApolloConsumer>
      {(client) => {
        const getCustomerByShipTo = async (shipTo) => {
          const result = await client.query({
            query: searchCustomersQuery,
            variables: { shipTo },
            fetchPolicy: 'network-only',
          });

          const customers = get(result, 'data.searchCustomers') || [];

          if (!customers.length) {
            throw new Error(`Could not find a custumer with shipTo: ${shipTo}`);
          }

          return customers.find((c) => c.shipTo === shipTo) || customers[0];
        };

        const props = { ...componentProps, getCustomerByShipTo };

        return withSetCaseCustomerByShipTo(WrappedComponent, props);
      }}
    </ApolloConsumer>
  );
}

export default compose(
  setDisplayName('withSetCaseCustomerByShipTo'),
  curry(withGetCustomerByShipTo),
);

import React from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { get, isEmpty, noop } from 'lodash';
import { compose, setDisplayName, withState } from 'recompose';

import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';
import { Dropdown, InputGroup, InputField, Text } from 'base-components';

import withFocusReceiver from 'setup/FocusProvider/withFocusReceiver';

import withCustomDealerActions from '../withCustomDealerActions';
import withCustomDealerMileageRanges from '../withCustomDealerMileageRanges';

import DealerResponseStatusIndicator from './DealerResponseStatusIndicator';
import { TextInputField } from './InputField';
import { getDealerResponseStatusIndicatorProps } from './utils';

function buildMileageRangeString(mileageRange) {
  if (isEmpty(mileageRange)) return {};

  const { min, max } = mileageRange;

  if (min === 0) return t`< ${max}`;
  if (max === null) return t`${min}+`;

  return t`${min} - ${max}`;
}

function CustomDealerDisplay(props) {
  const { onNameFocusRequested, onMileageRangeFocusRequested } = props;
  const { readOnly, customDealerMileageRanges, isServicingDealer } = props;
  const { responses, updateCustomDealer, nameValue, setNameValue } = props;

  const { id, mileageRange } = props.dealer;
  const mileageRangeId = get(mileageRange, 'id');

  const mileageRangeString = buildMileageRangeString(mileageRange);

  return (
    <Row modifiers={['middle', 'padScaleY_3']}>
      <Column modifiers="padScale_0">
        <DealerResponseStatusIndicator
          {...getDealerResponseStatusIndicatorProps({
            isServicingDealer,
            responses,
          })}
        />
      </Column>
      <Column
        modifiers={['col', 'padScaleX_2', 'padScaleY_0']}
        style={{ paddingRight: 0 }}
      >
        <InputGroup>
          <InputGroup.Row>
            <InputGroup.Column modifiers={['col_9']}>
              <TextInputField
                border
                defaultValue={nameValue || ''}
                label={t`Name`}
                name="custom-dealer-name"
                onChange={(inputName, newName) => {
                  setNameValue(newName);
                  updateCustomDealer({ id, patch: { name: newName } });
                }}
                readOnly={readOnly}
                placeholder={t`Enter name...`}
                onFocusRequested={onNameFocusRequested}
                maxLength={255}
              />
            </InputGroup.Column>
            <InputGroup.Column modifiers={['col_3']}>
              <Dropdown
                activeItem={mileageRangeId}
                name="custom-dealer-mileage-range"
                fullWidth
                hideOnChange
                onChange={(event, newMileageRangeId) =>
                  updateCustomDealer({
                    id,
                    patch: { mileageRangeId: newMileageRangeId },
                  })
                }
                readOnly={readOnly}
              >
                {({ isVisible }) => (
                  <>
                    <Dropdown.Target>
                      <InputField
                        isValid
                        placeholder={t`Select...`}
                        readOnly={readOnly}
                        style={{ cursor: 'pointer' }}
                        value={mileageRangeId ? i18n._(mileageRangeString) : ''}
                      >
                        <Column modifiers={['col', 'padScaleY_0']}>
                          <Row>
                            <InputField.Label>
                              <Trans>Mileage Range</Trans>
                            </InputField.Label>
                          </Row>
                          <Row>
                            <InputField.TextField
                              readOnly
                              ref={(el) =>
                                onMileageRangeFocusRequested(el, {
                                  click: true, // opens the dropdown
                                })
                              }
                            />
                            <InputField.ActionButton
                              icon={isVisible ? 'chevron-up' : 'chevron-down'}
                              onClick={noop}
                              modifiers={['hoverInfo']}
                            />
                          </Row>
                        </Column>
                      </InputField>
                    </Dropdown.Target>
                    <Dropdown.Content
                      style={{
                        right: 'auto',
                        whiteSpace: 'nowrap',
                        maxHeight: px2rem(300),
                        overflowY: 'auto',
                      }}
                    >
                      <Dropdown.List>
                        {customDealerMileageRanges.map((mr) => {
                          const str = buildMileageRangeString(mr);
                          return (
                            <Dropdown.ListItem key={mr.id} id={mr.id}>
                              <Text>
                                <Trans id={str} />
                              </Text>
                            </Dropdown.ListItem>
                          );
                        })}
                      </Dropdown.List>
                    </Dropdown.Content>
                  </>
                )}
              </Dropdown>
            </InputGroup.Column>
          </InputGroup.Row>
        </InputGroup>
      </Column>
    </Row>
  );
}

CustomDealerDisplay.propTypes = {
  customDealerMileageRanges: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      max: PropTypes.number,
      min: PropTypes.number,
    }),
  ),
  dealer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    mileageRange: PropTypes.shape({
      id: PropTypes.string,
      max: PropTypes.number,
      min: PropTypes.number,
    }),
  }).isRequired,
  isServicingDealer: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  responses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  updateCustomDealer: PropTypes.func.isRequired,
  nameValue: PropTypes.string,
  setNameValue: PropTypes.func.isRequired,
  dealerIndex: PropTypes.number.isRequired,
  onNameFocusRequested: PropTypes.func.isRequired,
  onMileageRangeFocusRequested: PropTypes.func.isRequired,
};

CustomDealerDisplay.defaultProps = {
  nameValue: '',
  customDealerMileageRanges: [],
};

const buildFocusReceiver = (key) => (props) =>
  `dealerSelection.${props.dealerIndex}.${key}`;

export default compose(
  setDisplayName('CustomDealerDisplay'),
  withCustomDealerActions,
  withCustomDealerMileageRanges,
  withState('nameValue', 'setNameValue', (p) => get(p, 'dealer.name', '')),
  withFocusReceiver(buildFocusReceiver('name'), 'onNameFocusRequested'),
  withFocusReceiver(
    buildFocusReceiver('mileageRange.id'),
    'onMileageRangeFocusRequested',
  ),
)(CustomDealerDisplay);

import React, { Component } from 'react';
import Bugsnag from '@bugsnag/browser';
import PropTypes from 'prop-types';
import { curry, find, isEmpty } from 'lodash';

import CaseInboundProgramPanelContext from '../CaseInboundProgramPanelContext';

const loggedCaseNumbers = new Set();

class WithInboundProgramFromCall extends Component {
  static propTypes = {
    callContactAttributes: PropTypes.shape({
      dialedNumber: PropTypes.string,
    }).isRequired,
    caseId: PropTypes.string,
    caseNumber: PropTypes.string,
    children: PropTypes.node.isRequired,
    inboundProgramNumberId: PropTypes.string,
    inboundPrograms: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        tollFreeNumber: PropTypes.string,
      }),
    ).isRequired,
    isLoadingInboundPrograms: PropTypes.bool.isRequired,
    isNewCase: PropTypes.bool.isRequired,
    onSelectInboundProgram: PropTypes.func.isRequired,
  };

  static defaultProps = {
    caseId: undefined,
    caseNumber: undefined,
    inboundProgramNumberId: undefined,
  };

  state = { inboundProgramSaved: false };

  componentDidMount() {
    this.setInboundProgramFromCall();
  }

  componentDidUpdate() {
    this.setInboundProgramFromCall();
  }

  /**
   * If the conditions are juuuust right, automatically saves the inbound program
   * for the customer to the case.
   *
   * @memberof WithInboundProgramFromCall
   */
  setInboundProgramFromCall = () => {
    const { caseNumber, inboundPrograms, callContactAttributes } = this.props;

    if (
      !this.shouldAutoSelectInboundProgram() ||
      !this.canFindInboundProgram()
    ) {
      return;
    }

    const inboundProgram = this.findInboundProgramFromCallContactAttributes();

    if (!inboundProgram && !loggedCaseNumbers.has(caseNumber)) {
      Bugsnag.notify('Could not auto-select Inbound Program', (event) => {
        event.context = 'Inbound Program Auto-Selection';

        event.addMetadata('Custom', {
          'Case Number': caseNumber,
          'CCP Attributes': callContactAttributes,
          'Inbound Programs': inboundPrograms,
        });
      });

      loggedCaseNumbers.add(caseNumber);
    }

    this.setState({ inboundProgramSaved: true }, () => {
      if (inboundProgram) this.props.onSelectInboundProgram(inboundProgram.id);
    });
  };

  /**
   * Matches the dialedNumber from the call up to an inbound program's tollFreeNumber
   *
   * @returns {object} an inbound program if a match is found.
   * @memberof WithInboundProgramFromCall
   */
  findInboundProgramFromCallContactAttributes = () => {
    const { callContactAttributes, inboundPrograms } = this.props;
    const { dialedNumber } = callContactAttributes;
    const nbs = [`${dialedNumber}`, `+${dialedNumber}`];

    return find(inboundPrograms, (ip) => nbs.includes(ip.tollFreeNumber));
  };

  /**
   * Evaluates props to determine if an inbound program should be saved.
   * Specific conditions include:
   * - The case must have just been created
   * - The case must be loaded
   * - No previously existing inbound program information
   * - The "save" action has not been previously called by this class.
   * - The agent must have been on the phone with the customer when creating the case
   *
   * @memberof WithInboundProgramFromCall
   */
  shouldAutoSelectInboundProgram = () =>
    this.props.isNewCase &&
    this.props.caseId &&
    !this.props.inboundProgramNumberId &&
    !this.state.inboundProgramSaved &&
    !isEmpty(this.props.callContactAttributes);

  /**
   * Evaluates props to determine if all of the data needed to find a
   * matching inbound program is present.
   *
   * @memberof WithInboundProgramFromCall
   */
  canFindInboundProgram = () =>
    !this.props.isLoadingInboundPrograms &&
    this.props.callContactAttributes.dialedNumber;

  render() {
    return this.props.children;
  }
}

const buildConsumerChild = curry(
  (WrappedComponent, componentProps, consumerProps) => {
    const {
      caseId,
      caseNumber,
      callContactAttributes,
      inboundProgramNumberId,
      isNewCase,
    } = consumerProps;
    const {
      inboundPrograms,
      isLoadingInboundPrograms,
      onSelectInboundProgram,
    } = componentProps;

    return (
      <WithInboundProgramFromCall
        caseId={caseId}
        caseNumber={caseNumber}
        callContactAttributes={callContactAttributes}
        inboundProgramNumberId={inboundProgramNumberId}
        inboundPrograms={inboundPrograms}
        isLoadingInboundPrograms={isLoadingInboundPrograms}
        isNewCase={isNewCase}
        onSelectInboundProgram={onSelectInboundProgram}
      >
        <WrappedComponent {...componentProps} />
      </WithInboundProgramFromCall>
    );
  },
);

const withInboundProgramFromCall = (WrappedComponent) => (componentProps) => (
  <CaseInboundProgramPanelContext.Consumer>
    {buildConsumerChild(WrappedComponent, componentProps)}
  </CaseInboundProgramPanelContext.Consumer>
);

export default withInboundProgramFromCall;

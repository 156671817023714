import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import Layout from 'components/Layout';

/**
 * Route wrapper which renders the component for a route within the
 * default page layout.
 *
 * Pass a component or a render function to be used to render the
 * body of the page within this layout. This will be passed all the
 * route props for rendering.
 */
export default function DefaultLayout({
  path,
  exact,
  component: Component,
  noScrollBar,
  ...rest
}) {
  return (
    <Route
      exact={exact}
      path={path}
      render={(matchProps) => (
        <Layout noScrollBar={noScrollBar}>
          <Component {...rest} {...matchProps} />
        </Layout>
      )}
    />
  );
}

DefaultLayout.propTypes = {
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  // For some reason, we get a props warning if we use "node" below.
  // eslint-disable-next-line react/forbid-prop-types
  component: PropTypes.any.isRequired,
  noScrollBar: PropTypes.bool,
};

DefaultLayout.defaultProps = {
  exact: false,
  noScrollBar: true,
};

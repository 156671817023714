import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

import Arrow from './Arrow';
import Content from './Content';
import Target from './Target';

const modifierConfig = {
  bottom: () => 'justify-content: center;',
  bottomLeft: () => 'justify-content: flex-end;',
  bottomRight: () => 'justify-content: flex-start;',
  left: () => 'align-items: center;',
  right: () => 'align-items: center;',
  top: () => 'justify-content: center;',
  topLeft: () => 'justify-content: flex-end;',
  topRight: () => 'justify-content: flex-start;',
};

const styles = `
  display: flex;
  position: relative;
`;

const Popover = buildStyledComponent('Popover', styled.div, styles, {
  modifierConfig,
});

Popover.Arrow = Arrow;
Popover.Content = Content;
Popover.Target = Target;

export default Popover;

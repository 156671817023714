import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import { GhostIndicator } from '../../elements';

export const modifierConfig = {
  icon: () => `
    max-width: ${px2rem(16)};
  `,
};

const styles = () => `
  height: ${px2rem(16)};
  margin: ${px2rem(8)};
`;

export default buildStyledComponent(
  'MenuItemTitleGhost',
  GhostIndicator,
  styles,
  { modifierConfig },
);

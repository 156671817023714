import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTheme } from 'styled-components';

import WindowQuery from './WindowQuery';

export class WithSmallWindowDetected extends Component {
  static propTypes = {
    render: PropTypes.func.isRequired,
    theme: PropTypes.shape({
      dimensions: PropTypes.shape({
        autoCollapseLeftNavPx: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  };

  state = {
    smallWindowDetected: false,
  };

  /**
   * On initial mount, update smallWindowDetected state based on window size.
   * @return {undefined} This method is expected to trigger a state mutation, not return a value.
   */
  componentDidMount() {
    this.updateSmallWindowDetected(window.innerWidth);
  }

  /**
   * event handler for resizing the window
   * @param  {number} windowWidth  The width of the window after resizing.
   * @return {undefined} This method is expected to mutate state, not return a value.
   */
  onResizeWindow = ({ windowWidth }) => {
    this.updateSmallWindowDetected(windowWidth);
  };

  /**
   * Sets a new value for this.state.smallWindowDetected based on the current window width
   * @param  {number} windowWidth  The width of the window to be used in determining the
   *                               smallWindowDetected state
   * @return {undefined} This method is expected to mutate state, not return a value.
   */
  updateSmallWindowDetected = (windowWidth) => {
    const {
      theme: {
        dimensions: { autoCollapseLeftNavPx },
      },
    } = this.props;

    this.setState({ smallWindowDetected: windowWidth < autoCollapseLeftNavPx });
  };

  render() {
    const passThroughProps = omit(this.props, ['render', 'theme']);

    return (
      <WindowQuery onResize={this.onResizeWindow}>
        {this.props.render({
          smallWindowDetected: this.state.smallWindowDetected,
          ...passThroughProps,
        })}
      </WindowQuery>
    );
  }
}

/* istanbul ignore next */
const withSmallWindowDetected = (WrappedComponent) => {
  function RenderPropComponent(props) {
    return (
      <WithSmallWindowDetected
        {...props}
        render={(componentProps) => <WrappedComponent {...componentProps} />}
      />
    );
  }

  return withTheme(RenderPropComponent);
};

export default withSmallWindowDetected;

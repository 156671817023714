import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { Text } from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Container } from 'styled-components-grid';

import withContext from 'utils/withContext';
import { reportTypes } from 'pages/Reports/constants';

import ReportConfigBuilder from './ReportConfigBuilder';
import InputFieldWithSuggestions from './ReportConfigBuilder/InputFieldWithSuggestions';
import ReportConfigBuilderContext from './ReportConfigBuilder/Context';
import { configTypes, btStModeTypes } from './ReportConfigBuilder/constants';

const CustomFilters = ({ config }) => (
  <Container modifiers="padScale_0" style={{ marginTop: px2rem(20) }}>
    <Text>
      <Trans>Advanced Filters (Optional)</Trans>
    </Text>
    <InputFieldWithSuggestions
      configType={configTypes.inboundProgramName}
      grouped
    />
    <InputFieldWithSuggestions
      configType={
        config[configTypes.btStModeType] === btStModeTypes.bt
          ? configTypes.dealerName
          : configTypes.dealerLocation
      }
    />
  </Container>
);

CustomFilters.propTypes = { config: PropTypes.shape({}).isRequired };

const CaseBillingReportCustomFilters = compose(
  setDisplayName('CaseBillingReportCustomFilters'),
  withContext(ReportConfigBuilderContext),
)(CustomFilters);

const CaseBillingReport = () => (
  <ReportConfigBuilder
    reportType={reportTypes.caseBillingReport}
    showBtStModelToggle
  >
    <ReportConfigBuilder.Header />
    <CaseBillingReportCustomFilters />
    <ReportConfigBuilder.Footer />
  </ReportConfigBuilder>
);

export default CaseBillingReport;

import React from 'react';
import { curry } from 'lodash';
import { Mutation } from '@apollo/client/react/components';
import gql from 'graphql-tag';

import { caseTokenizedCardGQL } from './withCaseTokenizedCard';

const removePaymentDetailsMutation = gql`
  mutation removeCasePaymentDetails($caseId: ID!) {
    removeCasePaymentDetails(input: { caseId: $caseId }) {
      case {
        id
        paymentMethod
        tokenizedCreditCard {
          expirationMonth
          expirationYear
          firstDigit
          last4Digits
          name
        }
      }
    }
  }
`;

const buildMutationChild = curry(
  (WrappedComponent, componentProps, removePaymentDetails, { loading }) => (
    <WrappedComponent
      {...componentProps}
      removePaymentDetails={removePaymentDetails}
      removePaymentDetailsLoading={loading}
    />
  ),
);

const withRemovePaymentMutation = (WrappedComponent) => (componentProps) => (
  <Mutation
    mutation={removePaymentDetailsMutation}
    refetchQueries={[
      {
        query: caseTokenizedCardGQL,
        variables: { caseNumber: componentProps.caseNumber },
      },
    ]}
  >
    {buildMutationChild(WrappedComponent, componentProps)}
  </Mutation>
);

export default withRemovePaymentMutation;

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import Icon from 'decisiv-iconix-react';

import childrenWithDefaultModifiers from '../../utils/childrenWithDefaultModifiers';

const modifierConfig = {
  danger: ({ theme }) => `background-color: ${theme.colors.status.danger};`,

  info: ({ theme }) => `background-color: ${theme.colors.status.info};`,

  round: () => 'border-radius: 50%;',

  secondary: ({ theme }) => `background-color: ${theme.colors.base.chrome500};`,

  success: ({ theme }) => `background-color: ${theme.colors.status.success};`,

  warning: ({ theme }) => `background-color: ${theme.colors.status.warning};`,
};

const styles = ({ theme }) => `
  align-items: center;
  background-color: ${theme.colors.base.chrome200};
  border-radius: ${theme.dimensions.borderRadius};
  display: flex;
  height: ${px2rem(30)};
  justify-content: center;
  width: ${px2rem(30)};
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome200: PropTypes.string.isRequired,
      chrome500: PropTypes.string.isRequired,
    }).isRequired,
    status: PropTypes.shape({
      danger: PropTypes.string.isRequired,
      default: PropTypes.string.isRequired,
      info: PropTypes.string.isRequired,
      success: PropTypes.string.isRequired,
      warning: PropTypes.string.isRequired,
    }).isRequired,
  }),
  dimensions: PropTypes.shape({
    borderRadius: PropTypes.string.isRequired,
  }).isRequired,
};

const StyledBadge = buildStyledComponent('StyledBadge', styled.div, styles, {
  modifierConfig,
  themePropTypes,
});

function Badge(props) {
  const modifiersRequiringDefaults = [
    'danger',
    'info',
    'secondary',
    'success',
    'warning',
  ];
  const defaultModifiers = ['chrome000'];
  const children = childrenWithDefaultModifiers(
    props,
    modifiersRequiringDefaults,
    defaultModifiers,
  );

  return <StyledBadge {...props}>{children}</StyledBadge>;
}

Badge.propTypes = {
  children: PropTypes.node.isRequired,
};

Badge.Icon = Icon;

export default Badge;

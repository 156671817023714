/**
 * FIXME: Testing Portals is not well supported at this time. Since there is
 * minimal logic in this component coverage reporting has been ignored.
 */
/* istanbul ignore file */

import { Component } from 'react';
import { createPortal } from 'react-dom';
import { MAP } from 'react-google-maps/lib/constants';
import PropTypes from 'prop-types';

class MapCustomControl extends Component {
  static propTypes = {
    controlPosition: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired,
  };

  static contextTypes = { [MAP]: PropTypes.shape({}) };

  map = null;

  controlDiv = null;

  divIndex = null;

  UNSAFE_componentWillMount() {
    this.map = this.context[MAP];
    this.controlDiv = document.createElement('div');
    this.divIndex =
      this.map.controls[this.props.controlPosition].push(this.controlDiv) - 1;
  }

  componentWillUnmount() {
    this.map.controls[this.props.controlPosition].removeAt(this.divIndex);
  }

  render() {
    return createPortal(this.props.children, this.controlDiv);
  }
}

export default MapCustomControl;

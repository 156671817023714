import React from 'react';
import { Trans } from '@lingui/macro';

import { Text } from 'base-components';

import responseTime from './responseTime';
import phoneNumber from './phoneNumber';
import contactPerson from './contactPerson';
import reason from './reason';
import eta from './eta';
import note from './note';
import deleteAction from './deleteAction';

import { buildCheckboxColumn } from './utils';

const conf = {
  alternateProduct: {
    label: <Trans>Alt. Product Available</Trans>,
    popoverContent: (
      <Text>
        <Trans>
          Indicates alternate tire available - Outside of Fleet profile
        </Trans>
      </Text>
    ),
  },
  alternativePreference: {
    label: <Trans>Alt. Preference Available</Trans>,
    popoverContent: (
      <Text>
        <Trans>
          Indicates alternate profile tire available - Not first preference
        </Trans>
      </Text>
    ),
  },
};

export default [
  responseTime,
  phoneNumber,
  contactPerson,
  reason,
  eta,
  buildCheckboxColumn('alternativeProductAvailable', conf.alternateProduct),
  buildCheckboxColumn(
    'alternativePreferenceAvailable',
    conf.alternativePreference,
  ),
  note,
  deleteAction,
];

import styled from 'styled-components';
import { Container } from 'styled-components-grid';

import { buildStyledComponent } from 'decisiv-ui-utils';

import Body from './Body';
import Data from './Data';
import Head from './Head';
import Header from './Header';
import Row from './Row';

//  Table Structure:
//  Table
//    Table.Head
//      Table.Header
//      Table.Header
//      ...
//    Table.Body
//      Table.Row
//        Table.Data
//        Table.Data
//        ...
//      Table.Row
//        Table.Data
//        Table.Data
//        ...
//      Table.Row
//        Table.Data
//        Table.Data
//        ...
//      ...etc.

// this acts as our <table> element
const styles = () => `
  display: table;
`;

/**
 * @deprecated Use {@link DataTable} instead.
 */
const ListTable = buildStyledComponent('ListTable', styled(Container), styles);

ListTable.Body = Body;
ListTable.Data = Data;
ListTable.Head = Head;
ListTable.Header = Header;
ListTable.Row = Row;

export default ListTable;

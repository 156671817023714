import React from 'react';
import PropTypes from 'prop-types';
import { defineMessage, Trans } from '@lingui/macro';

import { Row, Column } from 'styled-components-grid';

import { i18n } from '@lingui/core';

import Label from './Label';

function SoonWarning({ minutes, type }) {
  function formattedMessage() {
    const closingTemplate = defineMessage({
      message: `Closes in {minutes} min`,
    });
    const openingTemplate = defineMessage({
      message: `Opens in {minutes} min`,
    });

    const values = { minutes };
    const template = type === 'open' ? openingTemplate : closingTemplate;

    return i18n._(template, values);
  }

  return (
    <Row modifiers={['padScale_0']}>
      <Column modifiers={['padScale_0']}>
        <Label modifiers={['danger', 'fontWeightRegular', 'small']}>
          <Trans id={formattedMessage()} data-test-id="hours-notifier" />
        </Label>
      </Column>
    </Row>
  );
}

SoonWarning.propTypes = {
  minutes: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['open', 'close']).isRequired,
};

export default SoonWarning;

import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';

import { Link } from 'base-components';

export default function CaseLink({ caseNumber, ...rest }) {
  return (
    <Link
      aria-label={t`Open case detail`}
      {...rest}
      to={`/cases/${caseNumber}`}
    >
      {caseNumber}
    </Link>
  );
}

CaseLink.propTypes = {
  caseNumber: PropTypes.string.isRequired,
};

CaseLink.displayName = 'CaseLink';

import React from 'react';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';
import { defineMessage, Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { Row, Column } from 'styled-components-grid';
import { H2, P, QuickActionButton, Text } from 'base-components';

import Modal from 'components/Modal';

const titles = {
  resume: defineMessage({ message: 'Resume receiving rotation emails?' }),
  snooze: defineMessage({ message: 'Snooze rotation emails for 30 days?' }),
};

const messages = {
  resume: defineMessage({
    message:
      'This will resume notifications to <0>{email}</0> about ERS rotations ending soon.<1></1>These notifications will resume automatically on <2>{autoResumeDate}</2>.',
  }),
  snooze: defineMessage({
    message:
      'This will stop notifications to <0>{email}</0> about ERS rotations ending soon, until <2>{autoResumeDate}</2>.',
  }),
};

const emphasis = <Text modifiers="fontWeightMedium" />;

const updateDealerQuery = gql`
  mutation toggleDealerRotationEmails($id: ID!, $snooze: Boolean!) {
    updateDealer(input: { id: $id, patch: { snoozeRotationEmails: $snooze } }) {
      dealer {
        id
        rotationsEmailSnoozed
        rotationsEmailSnoozedAt
      }
    }
  }
`;

const withToggleEmailActions = (Component) => (componentProps) => (
  <Mutation mutation={updateDealerQuery}>
    {(mutate, { loading }) => {
      const { dealerId: id, actionType } = componentProps;
      const snooze = actionType === 'snooze';

      return (
        <Component
          {...componentProps}
          isSaving={!!loading}
          toggleRotationEmails={() => mutate({ variables: { id, snooze } })}
        />
      );
    }}
  </Mutation>
);

function ToggleSnoozeConfirmModal(props) {
  const { email, onClose, actionType } = props;
  const { autoResumeDate, toggleRotationEmails, isSaving } = props;

  const onConfirm = () =>
    toggleRotationEmails()
      .then(onClose)
      .catch((e) => console.error(e)); // eslint-disable-line no-console

  return (
    <Modal onClose={onClose}>
      {({ closeModal }) => (
        <Modal.Body>
          <Modal.Header>
            <Modal.HeaderIcon name="stopwatch" />
          </Modal.Header>
          <Modal.Content>
            <Row modifiers="center">
              <Column>
                <H2 style={{ marginBottom: 15 }} modifiers="fontWeightRegular">
                  <Trans id={titles[actionType]} />
                </H2>
              </Column>
            </Row>
            <Row modifiers="center">
              <Column>
                <P>
                  <Trans
                    id={messages[actionType]}
                    values={{ email, autoResumeDate }}
                    components={[emphasis, <br />, emphasis]}
                  />
                </P>
              </Column>
            </Row>
          </Modal.Content>
          <Modal.Footer>
            <Row modifiers="end">
              <Column>
                <QuickActionButton onClick={closeModal}>
                  <QuickActionButton.Text>
                    <Trans>Cancel</Trans>
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
              <Column>
                <QuickActionButton
                  onClick={onConfirm}
                  disabled={isSaving}
                  modifiers={['secondary', isSaving && 'disabled']}
                >
                  <QuickActionButton.Text>
                    <Trans>Confirm</Trans>
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
            </Row>
          </Modal.Footer>
        </Modal.Body>
      )}
    </Modal>
  );
}

ToggleSnoozeConfirmModal.propTypes = {
  email: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  dealerId: PropTypes.string.isRequired,
  isSaving: PropTypes.bool.isRequired,
  actionType: PropTypes.oneOf(['snooze', 'resume']).isRequired,
  autoResumeDate: PropTypes.string.isRequired,
  toggleRotationEmails: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('ToggleSnoozeConfirmModal'),
  withToggleEmailActions,
)(ToggleSnoozeConfirmModal);

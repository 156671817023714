import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { isEmpty, uniq } from 'lodash';

import { px2rem } from 'decisiv-ui-utils';
import { Column, Row } from 'styled-components-grid';
import { Badge, DataTable, Text } from 'base-components';

import { isPhoneNumber, formatPhone } from 'utils/format';

import columns from './columns';
import EntryDivider from '../../../EntryDivider';

export const CALL_TYPES = {
  INBOUND: 'INBOUND',
  OUTBOUND: 'OUTBOUND',
};

function buildCall(props) {
  return {
    ...props,
    from: {
      title: '',
      name: props.sourceName,
      company: props.sourceOrganization,
      phone: props.sourceNumber,
    },
    to: {
      title: '',
      name: props.destinationName,
      company: props.destinationOrganization,
      phone: props.destinationNumber,
    },
  };
}

export function getBadgeInfo({ direction }) {
  return {
    icon: direction === CALL_TYPES.OUTBOUND ? 'phone-arrow' : 'phone-arrow-in',
    modifiers: ['round', 'success'],
  };
}

export function isNumberOnlyUser(call, caller) {
  const data = call[caller];

  return !!uniq(
    Object.keys(data).map((k) => (k === 'phone' ? true : isEmpty(data[k]))),
  )[0];
}

export function createTitle(call) {
  const direction = call.direction === CALL_TYPES.OUTBOUND ? 'to' : 'from';
  const { phone, company, title } = isNumberOnlyUser(call, direction)
    ? {
        phone: isPhoneNumber(call[direction].phone)
          ? formatPhone(call[direction].phone)
          : t`restricted number`,
      }
    : {
        company: `${call[direction].company || call[direction].name}`,
        title: call[direction].title,
      };

  return { direction, phone, company, title };
}

function CaseCall(props) {
  const call = buildCall(props);
  const { direction, startTime } = props;

  const badgeInfo = getBadgeInfo(props);
  const typeOfCall = direction === CALL_TYPES.OUTBOUND ? 'Outbound' : 'Inbound';

  return (
    <Row>
      <Column modifiers={['col', 'padScale_0']}>
        <Row>
          <Column
            modifiers={['center', 'height_100', 'padScale_0']}
            style={{ height: px2rem(20), width: px2rem(50) }}
          >
            <EntryDivider />
          </Column>
        </Row>

        <Row modifiers="middle">
          <Column modifiers="padScaleX_2">
            <Badge modifiers={badgeInfo.modifiers}>
              <Badge.Icon name={badgeInfo.icon} />
            </Badge>
          </Column>
          <Column>
            <Row>
              <Column modifiers="padScale_0">
                <Text modifiers={['small', 'textLight']}>
                  {moment(startTime).tz(moment.tz.guess()).format('h:mm A z')}
                </Text>
              </Column>
            </Row>
            <Row>
              <Column modifiers="padScale_0">
                <Text>
                  <Trans
                    id="{typeOfCall} call {direction} {phone}{company} {title}"
                    values={{ typeOfCall, ...createTitle(call) }}
                  />
                </Text>
              </Column>
            </Row>
          </Column>
        </Row>
        <Row style={{ flexWrap: 'nowrap' }}>
          <Column
            modifiers={['height_100', 'padScaleY_0']}
            style={{ width: px2rem(50), height: 'auto' }}
          >
            <EntryDivider />
          </Column>
          <Column style={{ flex: '1' }} modifiers={['col', 'padScaleX_0']}>
            <DataTable
              name="CallInfo"
              columns={columns}
              modifiers={[]}
              tableData={[call]}
              rowModifiersGetter={() => ['noHighlightOnHover']}
            />
          </Column>
        </Row>
      </Column>
    </Row>
  );
}

CaseCall.propTypes = {
  id: PropTypes.string.isRequired,
  direction: PropTypes.string,
  startTime: PropTypes.string,
  sourceName: PropTypes.string,
  sourceNumber: PropTypes.string,
  sourceOrganization: PropTypes.string,
  destinationName: PropTypes.string,
  destinationNumber: PropTypes.string,
  destinationOrganization: PropTypes.string,
};

export default CaseCall;

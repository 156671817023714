import React from 'react';
import PropTypes from 'prop-types';
import { Plural } from '@lingui/macro';
import { pick, map } from 'lodash';
import { compose, setDisplayName } from 'recompose';

import { Accordion } from 'base-components';
import { Container } from 'styled-components-grid';

import AccordionBody from '../AccordionBody';
import ContactGroupList from './ContactGroupList';
import withActiveAfterHoursContacts from './withActiveAfterHoursContacts';

const getContacts = (dealer, afterHoursContacts) => {
  const hasAfterHoursContacts = map(
    afterHoursContacts,
    'primaryPhoneNumber',
  ).some(Boolean);
  let contacts = [];

  if (hasAfterHoursContacts) {
    contacts = contacts.concat(
      ['primaryContact', 'backupContact', 'finalContact'].map((type) => ({
        type,
        contact: {
          ...pick(afterHoursContacts[type], [
            'name',
            'jobTitle',
            'primaryPhoneNumber',
            'secondaryPhoneNumber',
          ]),
        },
      })),
    );
  }

  contacts.push({
    type: 'dealer',
    contact: {
      name: dealer.name,
      primaryPhoneNumber: dealer.phone,
    },
  });

  return contacts;
};

export function Contacts({
  expanded,
  onExpandedChange,
  dealer,
  activeAfterHoursContacts,
}) {
  const contacts = getContacts(dealer, activeAfterHoursContacts);

  return (
    <Container
      className="Contacts"
      modifiers={['flex_column', 'fluid', 'height_100']}
    >
      <Accordion.Divider />
      <Accordion
        name="contacts"
        expanded={expanded}
        onExpandedChange={onExpandedChange}
      >
        <Accordion.Head>
          <Container modifiers="fluid">
            <Accordion.Title>
              <Plural value={contacts.length} one="Contact" other="Contacts" />
            </Accordion.Title>
          </Container>
        </Accordion.Head>
        {dealer.id && (
          <AccordionBody>
            <ContactGroupList dealer={dealer} groupedContacts={contacts} />
          </AccordionBody>
        )}
      </Accordion>
    </Container>
  );
}

Contacts.propTypes = {
  dealer: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    operatingStatus: PropTypes.shape({
      open: PropTypes.string,
      ersOpen: PropTypes.string,
    }),
  }),
  expanded: PropTypes.bool.isRequired,
  onExpandedChange: PropTypes.func.isRequired,
  activeAfterHoursContacts: PropTypes.shape({
    primaryContact: PropTypes.shape({}),
    backupContact: PropTypes.shape({}),
    finalContact: PropTypes.shape({}),
  }),
};

Contacts.defaultProps = {
  dealer: {},
  activeAfterHoursContacts: {},
};

export default compose(
  setDisplayName('Contacts'),
  withActiveAfterHoursContacts,
)(Contacts);

import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName } from 'recompose';
import { Trans } from '@lingui/macro';

import { px2rem } from 'decisiv-ui-utils';
import { ButtonIconRectangle, Popover, Tooltip } from 'base-components';
import { Column, Row } from 'styled-components-grid';

import withContext from 'utils/withContext';
import CaseStatusContext from 'compositions/CaseStatus/CaseStatusContext';
import { CASE_STATUS } from 'compositions/CaseStatus/constants';
import withCaseDelays from 'compositions/CaseDetailOverviewPanel/withCaseDelays';
import getActiveCaseDelay from 'utils/getActiveCaseDelay';

import CaseDelaysTimeline from 'compositions/CaseDetailOverviewPanel/ManageDelaysModal/CaseDelaysTimeline';

export function CaseStatusDelays({ statusForDelays, caseDelays }) {
  const filteredDelays = caseDelays.filter(
    (delay) => delay.status === statusForDelays,
  );

  if (filteredDelays.length === 0) return null;

  const hasActiveDelays = getActiveCaseDelay(filteredDelays, true).length > 0;

  const customReasonPosition = [
    CASE_STATUS.new,
    CASE_STATUS.dispatch,
    CASE_STATUS.dispatched,
    CASE_STATUS.enRoute,
  ].includes(statusForDelays)
    ? 'right'
    : 'left';

  return (
    <Popover position="bottom" zIndex={2}>
      <>
        <Tooltip position="top">
          <Tooltip.Target>
            <Popover.Target>
              <ButtonIconRectangle>
                <ButtonIconRectangle.Icon
                  name="hourglass"
                  modifiers={hasActiveDelays && 'danger'}
                />
              </ButtonIconRectangle>
            </Popover.Target>
          </Tooltip.Target>
          <Tooltip.Content>
            <Trans>See delays</Trans>
          </Tooltip.Content>
        </Tooltip>
        <Popover.Content style={{ width: px2rem(320) }}>
          <Row>
            <Column modifiers={['padScale_3', 'flex_1']}>
              <CaseDelaysTimeline
                caseDelays={filteredDelays}
                customReasonPosition={customReasonPosition}
              />
            </Column>
          </Row>
        </Popover.Content>
      </>
    </Popover>
  );
}

CaseStatusDelays.propTypes = {
  statusForDelays: PropTypes.string.isRequired,
  caseDelays: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      startTime: PropTypes.string.isRequired,
      endTime: PropTypes.string,
      status: PropTypes.string,
      reasonType: PropTypes.string,
      customReason: PropTypes.string,
    }),
  ).isRequired,
};

export default compose(
  setDisplayName('CaseStatusDelays'),
  withContext(CaseStatusContext),
  withCaseDelays,
)(CaseStatusDelays);

import React from 'react';
import { Query } from '@apollo/client/react/components';
import { curry, get } from 'lodash';

import caseInboundProgramQuery from './caseInboundProgramQuery';

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const program = get(queryProps, 'data.case.inboundProgramNumber') || {};

    return (
      <WrappedComponent
        {...componentProps}
        inboundProgramBillTo={program.billTo}
        inboundProgramAccountType={program.inboundProgramAccountType}
        inboundProgramDefaultCustomer={program.defaultCustomer}
      />
    );
  },
);

function buildWrappedComponentWithQuery(WrappedComponent, componentProps) {
  const { caseNumber } = componentProps;

  return (
    <Query
      skip={!caseNumber}
      query={caseInboundProgramQuery}
      variables={{ caseNumber }}
    >
      {buildQueryChild(WrappedComponent, componentProps)}
    </Query>
  );
}

const withCaseInboundProgram = curry(buildWrappedComponentWithQuery);

export default withCaseInboundProgram;

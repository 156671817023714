import { toNumber } from 'lodash';

import { configEnabled } from 'decisiv-domain-utils';

/* istanbul ignore next */
function enableHotjar() {
  return configEnabled('REACT_APP_HOTJAR_ENABLED');
}

function hotjarID() {
  return toNumber(process.env.REACT_APP_HOTJAR_ID || '1028987');
}

/* istanbul ignore next */
// eslint-disable-next-line import/prefer-default-export
export function installHotjar() {
  if (enableHotjar()) {
    // This snippet is the install script provided by Hotjar for SPA's
    /* eslint-disable */
    // prettier-ignore
    (function(h,o,t,j,a,r) { h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:hotjarID(),hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); }
)(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    /* eslint-enable */
  }
}

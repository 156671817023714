import { buildStyledComponent } from 'decisiv-ui-utils';
import styled from 'styled-components';

const modifierConfig = {
  scrollX: () => `
    -ms-overflow-style: -ms-autohiding-scrollbar;
    max-width: 100%;
    overflow-x: auto;
  `,
};

const styles = `
  height: 100%;
  width: 100%;
`;

export default buildStyledComponent('Table__Container', styled.div, styles, {
  modifierConfig,
});

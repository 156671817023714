import gql from 'graphql-tag';

export const NAME = 'caseCustomerAccountQuery';

const query = gql`
  query caseCustomerAccountQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      customer(useCachedDataForCase: true) {
        ... on Customer {
          id
          type
          name
          address
          country
          billTo
          shipTo
          terminalNumber
          city
          state
          zip
          homeOfficeNumber
          otherAccounts {
            accountNumber
            accountType
            name
          }
        }
        ... on StoreCustomer {
          id
          type
          name
          accountNumber
          address
          country
          city
          state
          zip
          dealer {
            name
            billTo
          }
        }
        ... on CustomCustomer {
          type
          name
        }
        ... on CustomStoreCustomer {
          type
          name
        }
      }
    }
  }
`;

export default query;

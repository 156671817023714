import { curry, get, isEmpty, lowerCase, toArray } from 'lodash';
import React from 'react';
import { Query } from '@apollo/client/react/components';

import CaseInboundProgramPanelContext from '../CaseInboundProgramPanelContext';
import { normalizeInboundProgramLocations } from '../utils';

import inboundProgramLocationsQuery from './inboundProgramsLocationQuery';

const filterInboundProgramLocations = (locations, searchValue) => {
  if (isEmpty(searchValue)) {
    return locations;
  }
  return toArray(locations).filter((location) => {
    const { name, city, state, billTo, shipTo } = location;
    const searchableValues = [`${name} · ${city}, ${state}`, billTo, shipTo];

    return lowerCase(searchableValues.join(''))
      .replace(/\s/g, '')
      .includes(lowerCase(searchValue).replace(/\s/g, ''));
  });
};

const updateInboundProgramLocationSearchValue = curry(
  (setContext, inboundProgramLocationSearchValue) =>
    setContext({ inboundProgramLocationSearchValue }),
);

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const {
      inboundProgramLocationSearchValue,
      updateCaseInboundProgramPanelContext,
    } = componentProps;
    const { data, loading } = queryProps;

    const allInboundProgramLocations = get(
      data,
      'inboundProgramNumber.inboundProgram.locations',
      [],
    );

    const normalizedInboundProgramLocations = normalizeInboundProgramLocations(
      allInboundProgramLocations,
    );

    const inboundProgramLocations = filterInboundProgramLocations(
      normalizedInboundProgramLocations,
      inboundProgramLocationSearchValue,
    );

    return (
      <WrappedComponent
        {...componentProps}
        inboundProgramLocationSearchValue={inboundProgramLocationSearchValue}
        inboundProgramLocations={inboundProgramLocations}
        allInboundProgramLocations={normalizedInboundProgramLocations}
        isLoadingInboundProgramLocations={loading}
        onChangeInboundProgramLocationSearchValue={updateInboundProgramLocationSearchValue(
          updateCaseInboundProgramPanelContext,
        )}
      />
    );
  },
);

function skipQuery({ inboundProgramNumberId }) {
  return !inboundProgramNumberId;
}

function buildQueryVariables({ inboundProgramNumberId }) {
  return { inboundProgramNumberId };
}

function buildWrappedComponentWithQuery(WrappedComponent, componentProps) {
  return (
    <Query
      query={inboundProgramLocationsQuery}
      skip={skipQuery(componentProps)}
      variables={buildQueryVariables(componentProps)}
    >
      {buildQueryChild(WrappedComponent, componentProps)}
    </Query>
  );
}

const buildConsumerChild = curry(
  (WrappedComponent, componentProps, consumerProps) => {
    const {
      inboundProgramNumberId,
      inboundProgramLocationSearchValue,
      updateCaseInboundProgramPanelContext,
    } = consumerProps;

    return buildWrappedComponentWithQuery(WrappedComponent, {
      ...componentProps,
      inboundProgramNumberId,
      inboundProgramLocationSearchValue,
      updateCaseInboundProgramPanelContext,
    });
  },
);

const withFilteredInboundProgramLocations = (WrappedComponent) => (
  componentProps,
) => (
  <CaseInboundProgramPanelContext.Consumer>
    {buildConsumerChild(WrappedComponent, componentProps)}
  </CaseInboundProgramPanelContext.Consumer>
);

export default withFilteredInboundProgramLocations;

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Calendars from './Calendars';

class DateSelector extends Component {
  static propTypes = {
    disabledDates: PropTypes.shape({
      after: PropTypes.instanceOf(Date),
      before: PropTypes.instanceOf(Date),
    }),
    multiMonth: PropTypes.bool,
    multiMonthDirection: PropTypes.oneOf(['past', 'future']),
    onSelectDate: PropTypes.func.isRequired,
    selectedDate: PropTypes.instanceOf(Date),
    initialVisibleDate: PropTypes.instanceOf(Date),
  };

  static defaultProps = {
    disabledDates: {},
    multiMonth: false,
    multiMonthDirection: 'future',
    selectedDate: null,
    initialVisibleDate: null,
  };

  state = {
    selectedDate: this.props.selectedDate || null,
  };

  /**
   * Handle clicking a date in the calendar grid. Calls `this.props.onSelectDate` with the current
   * selected date.
   * @param  {Date|null} selectedDate The new date selected.
   */
  handleSelectDate = (selectedDate) => {
    this.setState({ selectedDate });
    this.props.onSelectDate(selectedDate);
  };

  render() {
    return (
      <Calendars
        disabledDates={this.props.disabledDates}
        multiMonth={this.props.multiMonth}
        multiMonthDirection={this.props.multiMonthDirection}
        onSelectDate={this.handleSelectDate}
        selectedDate={this.props.selectedDate || this.state.selectedDate}
        initialVisibleDate={this.props.initialVisibleDate}
      />
    );
  }
}

export default DateSelector;

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { InputField as BaseInputField } from 'base-components';
import { Row, Column } from 'styled-components-grid';

import styled, { css } from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

import PhoneNumberInput from 'elements/PhoneNumberInput';

// We can safely use `:focus-within` here because
// we're only targeting browsers that support it.
const Container = buildStyledComponent(
  'InputFieldContainer',
  styled.div,
  ({ border, isValid, theme }) =>
    !border &&
    isValid &&
    css`
      .Input:not(:hover):not(:focus-within) {
        border-color: transparent;
      }

      .Input:read-only {
        background: ${theme.colors.base.background};

        &:hover {
          border-color: transparent;
        }
      }
    `,
);

function InputField({ border, isValid, ...rest }) {
  return (
    <Container border={border} isValid={isValid}>
      <BaseInputField isValid={isValid} {...rest} />
    </Container>
  );
}

InputField.propTypes = {
  border: PropTypes.bool,
  isValid: PropTypes.bool,
};

InputField.defaultProps = {
  border: false,
  isValid: true,
};

InputField.ActionButton = BaseInputField.ActionButton;
InputField.Label = BaseInputField.Label;
InputField.TextArea = BaseInputField.TextArea;
InputField.TextField = BaseInputField.TextField;

export class TextInputField extends PureComponent {
  static propTypes = {
    border: PropTypes.bool,
    defaultValue: PropTypes.string,
    isValid: PropTypes.bool,
    label: PropTypes.string,
    maxLength: PropTypes.number,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onChangeDelay: PropTypes.number,
    phoneNumber: PropTypes.bool,
    placeholder: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    showCharsCounter: PropTypes.bool,
    onFocusRequested: PropTypes.func,
  };

  static defaultProps = {
    border: false,
    defaultValue: '',
    isValid: true,
    label: undefined,
    maxLength: undefined,
    onChangeDelay: 1000,
    phoneNumber: false,
    readOnly: false,
    showCharsCounter: false,
    onFocusRequested: undefined,
  };

  state = { value: this.props.defaultValue };

  UNSAFE_componentWillReceiveProps({ defaultValue }) {
    if (defaultValue !== this.props.defaultValue) {
      this.setState({ value: defaultValue });
    }
  }

  componentWillUnmount() {
    this.clearTimeouts();
  }

  onChange = ({ target: { value } }) => {
    const { name, onChange, onChangeDelay } = this.props;

    this.clearTimeouts();
    this.setState({ value });

    if (onChangeDelay) {
      this.onChangeTimeout = setTimeout(() => {
        onChange(name, value);
      }, onChangeDelay);

      return;
    }

    onChange(name, value);
  };

  getInputComponent = () => {
    const { phoneNumber, placeholder, readOnly, onFocusRequested } = this.props;

    if (phoneNumber) {
      return (
        <PhoneNumberInput
          value={this.state.value}
          innerRef={onFocusRequested}
          readOnly={readOnly}
          onChange={(value) => this.onChange({ target: { value } })}
          placeholder={placeholder}
        />
      );
    }

    return <InputField.TextField ref={onFocusRequested} />;
  };

  clearTimeouts = () => {
    clearTimeout(this.onChangeTimeout);
  };

  render() {
    const { value } = this.state;

    const {
      name,
      label,
      border,
      onChange,
      maxLength,
      placeholder,
      defaultValue,
      onChangeDelay,
      onFocusRequested,
      ...rest
    } = this.props;

    return (
      <InputField
        name={name}
        value={value}
        border={border}
        onChange={this.onChange}
        modifiers={undefined}
        maxLength={maxLength}
        placeholder={placeholder}
        {...rest}
      >
        <Column modifiers={['col', 'padScaleY_0']}>
          {label && (
            <Row>
              <InputField.Label>{label}</InputField.Label>
            </Row>
          )}
          <Row>{this.getInputComponent()}</Row>
        </Column>
      </InputField>
    );
  }
}

export default InputField;

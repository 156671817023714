import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { Mutation } from '@apollo/client/react/components';
import { curry, get } from 'lodash';
import { compose, setDisplayName } from 'recompose';

import { InputField } from 'base-components';
import { Column, Row } from 'styled-components-grid';

import { CaseShortcut, CASE_SHORTCUT_PANELS } from 'features/keyShortcuts';

import updateCaseMileMarkerGQL from './updateCaseMileMarkerMutation';

export class DealerMileMarkerInput extends Component {
  static propTypes = {
    caseDetail: PropTypes.shape({
      id: PropTypes.string,
      assetLocation: PropTypes.shape({
        mileMarker: PropTypes.string,
      }),
    }).isRequired,
    updateCase: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
  };

  state = {
    mileMarkerValue: get(this.props, 'caseDetail.assetLocation.mileMarker', ''),
  };

  onChange = (e) => {
    const { value } = e.target;
    this.setState({ mileMarkerValue: value });
  };

  handleBlur = (e) => {
    const {
      caseDetail: { id },
      updateCase,
    } = this.props;

    updateCase({
      variables: { mileMarker: e.target.value, id },
    });
  };

  render() {
    const { readOnly } = this.props;
    const { mileMarkerValue } = this.state;
    return (
      <InputField
        name="mileMarker"
        onBlur={this.handleBlur}
        onChange={this.onChange}
        placeholder={t`Enter mile marker...`}
        value={mileMarkerValue || ''}
        readOnly={readOnly}
        maxLength={255}
      >
        <Column modifiers={['col']}>
          <Row>
            <InputField.Label>
              <Trans>Mile Marker (Optional)</Trans>
            </InputField.Label>
          </Row>
          <Row>
            <CaseShortcut
              action={{
                parent: CASE_SHORTCUT_PANELS.assetLocation,
                id: 'assetLocationMileMarkerAction',
                name: t`Mile Marker`,
                shortcut: ['a', 'l', '3'],
                priority: 1,
              }}
            >
              {({ onFocusRequested }) => (
                <InputField.TextField ref={onFocusRequested} />
              )}
            </CaseShortcut>
          </Row>
        </Column>
      </InputField>
    );
  }
}

export const buildMutationChild = curry((componentProps, updateCase) => (
  <DealerMileMarkerInput {...componentProps} updateCase={updateCase} />
));

export function CaseMileMarkerUpdateMutation(componentProps) {
  const mutationChild = buildMutationChild(componentProps);

  return (
    <Mutation mutation={updateCaseMileMarkerGQL}>{mutationChild}</Mutation>
  );
}
export default compose(setDisplayName('DealerMileMarkerInput'))(
  CaseMileMarkerUpdateMutation,
);

import React from 'react';
import { curry } from 'lodash';

/**
 * Build a function-as-a-child component to be used by a parent
 * Apollo `Query` component. The final component will receive
 * both the original `componentProps` (passed in from the outside)
 * as well as the `queryProps` created by Apollo (including `loading`,
 * `error`, `data`, etc).
 */
// eslint-disable-next-line import/prefer-default-export
export const buildQueryChild = curry(
  (Component, componentProps, queryProps) => (
    <Component {...componentProps} {...queryProps} />
  ),
);

import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { t, Trans } from '@lingui/macro';
import { compose, setDisplayName, withState, withProps } from 'recompose';

import {
  Dropdown,
  InputField,
  InputGroup,
  ButtonToggle,
  DateSelector,
  ButtonIconRectangle,
} from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';

import TimeDropdown from 'components/DatetimeSelector/TimeDropdown';

function DatetimeSelector(props) {
  const { minDate, update: set, reset, label } = props;
  const { date, hours, minutes, period, readOnly } = props;
  const now = moment();

  const displayDate = date ? moment.utc(date).format('D MMM YYYY') : '';

  const displayHours = hours ? now.hours(hours).format('h') : '';
  const displayMinutes = minutes ? now.minutes(minutes).format('mm') : '';

  return (
    <InputGroup>
      <InputGroup.Row modifiers={['middle']}>
        <InputGroup.Column modifiers={['col', 'padScaleX_0']}>
          <Dropdown onChange={noop} readOnly={readOnly} fullWidth hideOnChange>
            {({ isVisible }) => (
              <>
                <Dropdown.Target>
                  <InputField
                    name="date"
                    value={displayDate}
                    onChange={noop}
                    readOnly={readOnly}
                    placeholder={t`Select date...`}
                  >
                    <Column modifiers="col">
                      <Row>
                        <InputField.Label>{label}</InputField.Label>
                      </Row>
                      <Row>
                        <InputField.TextField readOnly autoComplete="off" />
                        <InputField.ActionButton
                          icon={isVisible ? 'chevron-up' : 'chevron-down'}
                          type="button"
                          onClick={noop}
                          modifiers={['hoverInfo']}
                        />
                      </Row>
                    </Column>
                  </InputField>
                </Dropdown.Target>
                <Dropdown.Content style={{ minWidth: px2rem(370), zIndex: 2 }}>
                  <DateSelector
                    onSelectDate={(d) => set({ date: d })}
                    selectedDate={date}
                    disabledDates={{ before: minDate }}
                  />
                </Dropdown.Content>
              </>
            )}
          </Dropdown>
        </InputGroup.Column>
        <InputGroup.Column modifiers={['col_2', 'padScaleX_0']}>
          <TimeDropdown
            name="hours"
            label={<Trans>Hour</Trans>}
            value={displayHours}
            options={TimeDropdown.hourOptions}
            readOnly={readOnly}
            onChange={(_, h) => set({ hours: h })}
            placeholder={t`Select...`}
          />
        </InputGroup.Column>
        <InputGroup.Column modifiers={['col_2', 'padScaleX_0']}>
          <TimeDropdown
            name="minutes"
            label={<Trans>Minutes</Trans>}
            value={displayMinutes}
            options={TimeDropdown.minuteOptions}
            onChange={(_, m) => set({ minutes: m })}
            readOnly={readOnly}
            placeholder={t`Select...`}
          />
        </InputGroup.Column>
        <InputGroup.Column modifiers={['col_2', 'padScaleX_0']}>
          <InputField readOnly={readOnly}>
            <Column modifiers={['col', 'padScale_0']}>
              <Row modifiers="center">
                <Column>
                  <ButtonToggle
                    on={period === 'PM'}
                    onClick={(isOff) => set({ period: isOff ? 'AM' : 'PM' })}
                    disabled={readOnly}
                  >
                    <ButtonToggle.OffLabel>
                      <Trans>AM</Trans>
                    </ButtonToggle.OffLabel>
                    <ButtonToggle.OnLabel>
                      <Trans>PM</Trans>
                    </ButtonToggle.OnLabel>
                  </ButtonToggle>
                </Column>
              </Row>
            </Column>
          </InputField>
        </InputGroup.Column>
        <Column modifiers={['col_1', 'padScaleX_0', 'center']}>
          <ButtonIconRectangle
            onClick={reset}
            disabled={readOnly}
            modifiers={['hoverDanger', readOnly && 'disabled']}
          >
            <ButtonIconRectangle.Icon name="trash" />
          </ButtonIconRectangle>
        </Column>
      </InputGroup.Row>
    </InputGroup>
  );
}

DatetimeSelector.propTypes = {
  date: PropTypes.instanceOf(Date),
  label: PropTypes.node.isRequired,
  hours: PropTypes.string,
  reset: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  period: PropTypes.string,
  minDate: PropTypes.instanceOf(Date).isRequired,
  minutes: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

DatetimeSelector.defaultProps = {
  date: null,
  hours: null,
  period: null,
  minutes: null,
  readOnly: false,
  onChange: undefined,
};

const getDatePart = (date, datePart, fallbackValue = null) =>
  date ? moment.utc(date).format(datePart) : fallbackValue;

export default compose(
  setDisplayName('DatetimeSelector'),
  withState('date', 'setDate', (p) =>
    p.date ? moment.utc(p.date).toDate() : null,
  ),
  withState('hours', 'setHours', (p) => getDatePart(p.date, 'h')),
  withState('period', 'setPeriod', (p) => getDatePart(p.date, 'A', 'AM')),
  withState('minutes', 'setMinutes', (p) => getDatePart(p.date, 'mm')),
  withProps((props) => {
    const { setDate, setHours, setMinutes, setPeriod, onChange } = props;

    const update = (values) => {
      const { date, hours, minutes, period } = { ...props, ...values };

      setDate(date);
      setHours(hours);
      setMinutes(minutes);
      setPeriod(period);

      const hasAllParts = [date, hours, minutes, period].every(
        (v) => v !== null,
      );

      if (hasAllParts && onChange) {
        const fullDate = moment
          .utc(date)
          .hour(moment(`${hours} ${period}`, 'h A').hours())
          .minutes(minutes);

        onChange(fullDate.toISOString());
      }
    };

    const reset = () => {
      update({ date: null, hours: null, minutes: null, period: 'AM' });
      if (onChange) onChange(null);
    };

    return { update, reset, minDate: new Date() };
  }),
)(DatetimeSelector);

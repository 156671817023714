import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

const styles = () => `
  list-style: none;
  padding: 0;
`;

export default buildStyledComponent('MenusList', styled.ul, styles);

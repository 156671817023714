import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const modifierConfig = {
  active: ({ theme }) => `
    * {
      color: ${theme.colors.status.info}
    }
  `,
};

const styles = ({ theme }) => `
  align-items: center;
  background-color: ${theme.colors.base.background};
  border-radius: ${px2rem(2)};
  box-shadow: 0 ${px2rem(1)} ${px2rem(1)} 0 ${theme.colors.base.shadowLight};
  cursor: pointer;
  display: flex;
  height: ${px2rem(29)};
  justify-content: center;
  padding: 0;
  width: ${px2rem(29)};
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      background: PropTypes.string.isRequired,
      shadowLight: PropTypes.string.isRequired,
    }).isRequired,
    status: PropTypes.shape({
      info: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

// TODO: This element should be in Base Components.
export default buildStyledComponent('MapButton', styled.div, styles, {
  modifierConfig,
  themePropTypes,
});

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { H3, P, QuickActionButton } from 'base-components';
import { Row, Column } from 'styled-components-grid';

import Panel from 'blocks/Panel';
import withContext from 'utils/withContext';
import Newline2Br from 'elements/Newline2Br';
import MenuButton from 'components/MenuButton';

import NoNotesMessage from './NoNotesMessage';
import StoreNoteModal from './StoreNoteModal';
import withDealerNotes from './withDealerNotes';
import withUpdateDealerNotes from './withUpdateDealerNotes';
import DealerDetailsContext from '../DealerDetailsContext';

const dropdownMenuItems = [
  { key: 'editNote', label: t`Edit Note` },
  { key: 'deleteNote', label: t`Delete Note` },
];

/**
 * The "notes" panel on the Tech Admin dealer detail page.
 * Exported only for test purposes; the actual component is
 * wrapped below to wire it up to the API.
 */
export class StoreNote extends Component {
  static propTypes = {
    dealer: PropTypes.shape({
      notes: PropTypes.string,
    }),
    updateDealerNotes: PropTypes.func.isRequired,
    isReadOnly: PropTypes.bool.isRequired,
    isUpdatingDealerNotes: PropTypes.bool,
  };

  static defaultProps = {
    dealer: {},
    isUpdatingDealerNotes: false,
  };

  constructor(props) {
    super(props);
    this.state = { displayStoreNoteModal: false, isEditing: false };
  }

  handleSave = async (notes, closeModal) => {
    const { isEditing } = this.state;

    await this.props.updateDealerNotes(notes);

    if (isEditing) this.setState({ isEditing: !isEditing });

    closeModal();
  };

  toggleModal = () =>
    this.setState({
      displayStoreNoteModal: !this.state.displayStoreNoteModal,
    });

  handleItemSelect = (id) => {
    if (id === 'editNote') {
      this.editNote();
    } else {
      this.deleteNote();
    }
  };

  editNote = () => {
    this.setState({ isEditing: !this.state.isEditing });
    this.toggleModal();
  };

  deleteNote = () => {
    this.setState({ isEditing: false });
    this.props.updateDealerNotes('');
  };

  renderNotes = () => {
    const { dealer, isReadOnly } = this.props;
    const { notes } = dealer;

    if (notes) {
      return (
        <Row>
          <Column modifiers={['col']}>
            <P>
              <Newline2Br value={notes} />
            </P>
          </Column>
        </Row>
      );
    }

    return (
      <>
        <NoNotesMessage />
        {!isReadOnly && (
          <Row modifiers={['center']}>
            <Column modifiers={['padScaleY_0']}>
              <QuickActionButton onClick={this.toggleModal}>
                <QuickActionButton.Icon modifiers={['left']} name="plus" />
                <QuickActionButton.Text>
                  <Trans>Create Note</Trans>
                </QuickActionButton.Text>
              </QuickActionButton>
            </Column>
          </Row>
        )}
      </>
    );
  };

  render() {
    const { dealer, isReadOnly, isUpdatingDealerNotes } = this.props;
    const { notes } = dealer;

    return (
      <Panel modifiers={['padScale_3']}>
        <Row>
          <Column modifiers={['col']}>
            <H3 modifiers={['fontWeightRegular']}>
              <Trans>Notes</Trans>
            </H3>
          </Column>
          {notes && !isReadOnly && (
            <Column modifiers={['col_2', 'end']}>
              <MenuButton
                items={dropdownMenuItems}
                onSelect={this.handleItemSelect}
              />
            </Column>
          )}
        </Row>
        {this.renderNotes()}
        {this.state.displayStoreNoteModal && (
          <StoreNoteModal
            handleSave={this.handleSave}
            isEditing={this.state.isEditing}
            notes={notes === null ? '' : notes}
            onClose={this.toggleModal}
            isUpdatingDealerNotes={isUpdatingDealerNotes}
          />
        )}
      </Panel>
    );
  }
}
export default compose(
  setDisplayName('DealerNotes'),
  withDealerNotes,
  withUpdateDealerNotes,
  withContext(DealerDetailsContext),
)(StoreNote);

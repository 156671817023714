import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { QuickActionButton } from 'base-components';

import withContext from 'utils/withContext';

import { CaseStatusContext } from 'compositions/CaseStatus';

import withCaseDelays from './withCaseDelays';
import ManageDelaysModalContext from './ManageDelaysModalContext';

export function CaseDelayedToggleButton(props) {
  const { isLoadingCaseStatus, isFeatureEnabled } = props;
  const { isLoadingCaseDelays, showManageDelaysModal } = props;

  const isDisabled = [
    isLoadingCaseStatus,
    isLoadingCaseDelays,
    !isFeatureEnabled('manageDelays'),
  ].some(Boolean);

  return (
    <QuickActionButton
      onClick={showManageDelaysModal}
      disabled={isDisabled}
      modifiers={isDisabled && 'disabled'}
    >
      <QuickActionButton.Icon name="hourglass" modifiers="left" />
      <QuickActionButton.Text>
        <Trans>Manage delays</Trans>
      </QuickActionButton.Text>
    </QuickActionButton>
  );
}

CaseDelayedToggleButton.propTypes = {
  isFeatureEnabled: PropTypes.func.isRequired,
  isLoadingCaseStatus: PropTypes.bool.isRequired,
  isLoadingCaseDelays: PropTypes.bool.isRequired,
  showManageDelaysModal: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('CaseDelayedToggleButton'),
  withCaseDelays,
  withContext(CaseStatusContext),
  withContext(ManageDelaysModalContext),
)(CaseDelayedToggleButton);

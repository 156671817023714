import styled from 'styled-components';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const styles = `
  border-radius: 50%;
  height: ${px2rem(60)};
  width: ${px2rem(60)};
`;

export default buildStyledComponent('Logo', styled.img, styles);

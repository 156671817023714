import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import query from './caseCallHistoryQuery';

function useCallHistory(caseNumber) {
  const { data, loading } = useQuery(query, {
    skip: !caseNumber,
    variables: { caseNumber },
    fetchPolicy: 'network-only',
    pollInterval: 1000 * 15, // 15 seconds
  });

  const items = data?.case?.unifiedHistory?.edges;
  const edges = useMemo(() => items || [], [items]);
  const nodes = useMemo(() => edges.map(({ node }) => node), [edges]);

  return { data: nodes, loading };
}

export default useCallHistory;

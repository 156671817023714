import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName } from 'recompose';

import withContext from 'utils/withContext';

import CaseDealerLocatorPanel from 'compositions/CaseDealerLocatorPanel';
import CaseDealerLocatorClosedPanel from 'compositions/CaseDealerLocatorClosedPanel';

import { CaseStatusContext } from 'compositions/CaseStatus';

const DealerLocator = (props) => {
  const { caseNumber, isFeatureEnabled } = props;
  const { permissions, hasBeenDispatched } = props; // eslint-disable-line no-unused-vars

  let extraProps = { readOnly: false };

  const canFiewFullPanel = isFeatureEnabled('viewFullDealerLocatorPanel');

  const Component = canFiewFullPanel
    ? CaseDealerLocatorPanel
    : CaseDealerLocatorClosedPanel;

  // Some users have a special permission that allows
  // them to change only the servicing dealer on a case.
  // Those users do *not* have the "case:manage" permission
  // but have one called "case:update_dealer"
  const hasUpdateDealerOnlyPermissions =
    !permissions.includes('case:manage') &&
    permissions.includes('case:update_dealer');

  if (
    canFiewFullPanel &&
    hasUpdateDealerOnlyPermissions
    // TODO: Uncomment when we want to enable readonly after dispatch
    // (hasBeenDispatched || hasUpdateDealerOnlyPermissions)
  ) {
    // We are showing the full dealer locator,
    // but we don't want the user to change stuff.
    extraProps = { readOnly: true };
  }

  return <Component caseNumber={caseNumber} {...extraProps} />;
};

DealerLocator.propTypes = {
  caseNumber: PropTypes.string.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string),
  isFeatureEnabled: PropTypes.func.isRequired,
  hasBeenDispatched: PropTypes.bool.isRequired,
};

DealerLocator.defaultProps = {
  permissions: [],
};

export default compose(
  setDisplayName('DealerLocator'),
  withContext(CaseStatusContext),
)(DealerLocator);

import React from 'react';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import { noop } from 'lodash';
import { Trans } from '@lingui/macro';
import { withRouter } from 'react-router-dom';
import { compose, setDisplayName } from 'recompose';

import { Row, Column } from 'styled-components-grid';
import { H2, P, QuickActionButton } from 'base-components';

import Modal from 'components/Modal';

import { formatDuration } from './utils';

function InactivityNoticeModal(props) {
  const { onResetTimer, remainingDuration, history } = props;

  return (
    <Modal onClose={noop}>
      {() => (
        <Modal.Body>
          <Modal.Header showCloseButton={false} modifiers="warning">
            <Modal.HeaderIcon name="hourglass" />
          </Modal.Header>
          <Modal.Content>
            <Row modifiers="center">
              <Column>
                <H2 modifiers="fontWeightRegular">
                  <Trans>Are you still there?</Trans>
                </H2>
              </Column>
            </Row>
            <Row modifiers="center" style={{ marginTop: 20 }}>
              <Column modifiers="col">
                <P>
                  <Trans>
                    You haven&apos;t made any changes to this case for a while,
                    so in a few moments you&apos;ll be unassigned from the case
                    and automatically redirected to the Dashboard.
                    <br />
                    <br />
                    If you want to keep working on this case, click the
                    &quot;I&apos;M STILL HERE&quot; button to stay assigned to
                    the case and dismiss this notice.
                  </Trans>
                </P>
              </Column>
            </Row>
            <Row modifiers="center" style={{ marginTop: 20 }}>
              <Column modifiers="col">
                <P>
                  <Trans>You&apos;ll be unassigned and redirected in:</Trans>
                </P>
                <H2 style={{ marginTop: 10 }} modifiers="fontWeightRegular">
                  {formatDuration(remainingDuration)}
                </H2>
              </Column>
            </Row>
          </Modal.Content>
          <Modal.Footer>
            <Row modifiers="end">
              <Column>
                <QuickActionButton
                  type="button"
                  onClick={() => history.push('/')}
                >
                  <QuickActionButton.Text>
                    <Trans>Go to Dashboard</Trans>
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
              <Column>
                <QuickActionButton
                  type="submit"
                  onClick={onResetTimer}
                  modifiers="secondary"
                >
                  <QuickActionButton.Text>
                    <Trans>I&apos;m still here</Trans>
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
            </Row>
          </Modal.Footer>
        </Modal.Body>
      )}
    </Modal>
  );
}

InactivityNoticeModal.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  onResetTimer: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-typos
  remainingDuration: MomentPropTypes.momentDurationObj.isRequired,
};

export default compose(
  setDisplayName('InactivityNoticeModal'),
  withRouter,
)(InactivityNoticeModal);

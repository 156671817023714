import { compose, setDisplayName } from 'recompose';

import withDealers from '../withDealers';

import SearchFiltersComponent from './SearchFiltersComponent';

export default compose(
  setDisplayName('DealerLocator__SearchFilters'),
  withDealers,
)(SearchFiltersComponent);

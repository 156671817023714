import React from 'react';
import { Trans } from '@lingui/macro';

import { px2rem } from 'decisiv-ui-utils';
import { P } from 'base-components';

import Dot from 'elements/Dot';

import { FIELDS, cardInfoPropType } from './constants';

export default function CardDisplay({ cardInfo }) {
  const expMonth = cardInfo[FIELDS.expMonth.name];
  const expYear = cardInfo[FIELDS.expYear.name];
  const cardExpiration = `${expMonth}/${expYear}`;

  const firstDigit = cardInfo[FIELDS.firstDigit.name];
  const last4Digits = cardInfo[FIELDS.lastFourDigits.name];

  const cardName = cardInfo[FIELDS.cardholder.name];

  return (
    <>
      <P>{cardName}</P>
      <P>
        {firstDigit}
        <Dot repeat={[3, 4, 4]} /> {last4Digits}
      </P>
      <P style={{ marginBottom: px2rem(20) }}>
        <Trans>Expires {cardExpiration}</Trans>
      </P>
    </>
  );
}

CardDisplay.propTypes = {
  // eslint-disable-next-line react/no-typos
  cardInfo: cardInfoPropType.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { Column } from 'styled-components-grid';

import images from 'assets/images';

import IconBox from './IconBox';

const WeatherIcon = ({ name, size }) => {
  const icon = images.weather.iconFor(name);
  return (
    <Column>
      <IconBox size={size} src={icon} alt={name} />
    </Column>
  );
};

WeatherIcon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
};

WeatherIcon.defaultProps = {
  size: 16,
};

export default WeatherIcon;

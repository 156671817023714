import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { px2rem } from 'decisiv-ui-utils';
import { Tabs as BaseTabs } from 'base-components';

const Container = styled.div`
  ${({ compact }) =>
    compact &&
    css`
      /* \\. because the class name has an actual "." in it ¯\_(ツ)_/¯ */
      .TabList\\.ItemButton {
        padding-left: ${px2rem(10)};
        padding-right: ${px2rem(10)};
      }
    `};
`;

function Tabs({ tabs, tabGroupName, compact }) {
  return (
    <Container compact={compact}>
      <BaseTabs>
        <BaseTabs.Menu>
          <BaseTabs.List>
            {tabs.map((tab) => (
              <BaseTabs.ListItem key={`${tabGroupName}-menu-option-${tab.key}`}>
                <BaseTabs.ListItemButton aria-label={tab.key}>
                  {tab.title}
                </BaseTabs.ListItemButton>
              </BaseTabs.ListItem>
            ))}
          </BaseTabs.List>
        </BaseTabs.Menu>
        <BaseTabs.Content>
          {tabs.map((tab) => (
            <Fragment key={`${tabGroupName}-content-${tab.key}`}>
              {tab.content}
            </Fragment>
          ))}
        </BaseTabs.Content>
      </BaseTabs>
    </Container>
  );
}

Tabs.propTypes = {
  tabGroupName: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.node.isRequired,
      title: PropTypes.node.isRequired,
      content: PropTypes.node.isRequired,
    }),
  ).isRequired,
  compact: PropTypes.bool,
};

Tabs.defaultProps = {
  compact: false,
};

export default Tabs;

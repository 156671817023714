import { kebabCase, toUpper } from 'lodash';
import MemoryStorage from 'memorystorage';

const STORAGE_CONFIG_NAME = 'Michelin ONCall';

/**
 * This function sets up a browser-local memory DB, accessible via `window.memoryDB`.  It
 * first determines if `localStorage` is available and properly functional, and if so, sets
 * the `memoryDB` to be a reference to `localStorage`.  If `localStorage` is broken (for
 * example, in Safari Private Browsing mode), then it sets `memoryDB` to refer to an instance
 * of `MemoryStorage`, which supports the same API as `localStorage`, but does not persist
 * its keys/values across full page refresh.
 */
export default function initLocalStorage(configName = STORAGE_CONFIG_NAME) {
  let actualStorage = localStorage;
  try {
    const x = `test-localstorage-${Date.now()}`;
    actualStorage.setItem(x, x);
    const y = actualStorage.getItem(x);
    actualStorage.removeItem(x);
    if (y !== x) {
      throw new Error();
    }
  } catch (e) {
    // fall back to a memory-based implementation
    // (for a config.name 'Fleet Web', the memory storage will be DECISIV-FLEET-WEB)
    const memoryStorageName = `DECISIV-${toUpper(kebabCase(configName))}`;
    actualStorage = new MemoryStorage(memoryStorageName);
  }

  window.memoryDB = actualStorage;
  return actualStorage;
}

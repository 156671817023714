import React from 'react';
import PropTypes from 'prop-types';

import AccordionBlock from '../../blocks/Accordion';
import ButtonIconRectangle from '../../blocks/ButtonIconRectangle';

import { ACCORDION_CONTEXT } from './constants';

function Head({ children, ...rest }, context) {
  const { expanded, expand } = context[ACCORDION_CONTEXT];

  const icon = expanded ? 'chevron-up' : 'chevron-down';
  return (
    <AccordionBlock.Head onClick={expand} {...rest}>
      {children}
      <ButtonIconRectangle>
        <ButtonIconRectangle.Icon name={icon} />
      </ButtonIconRectangle>
    </AccordionBlock.Head>
  );
}

Head.contextTypes = {
  [ACCORDION_CONTEXT]: PropTypes.shape({}).isRequired,
};

Head.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Head;

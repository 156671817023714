import gql from 'graphql-tag';

export default gql`
  mutation createDealerInvoiceReport(
    $billable: Boolean
    $dealerName: String
    $dealerLocation: String
    $endTime: Date!
    $inboundProgramName: String
    $startTime: Date!
  ) {
    createDealerInvoiceReport(
      filters: {
        billable: $billable
        dealerName: $dealerName
        dealerLocation: $dealerLocation
        endTime: $endTime
        inboundProgramName: $inboundProgramName
        startTime: $startTime
      }
    ) {
      jobId
    }
  }
`;

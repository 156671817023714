import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

const styles = ({ theme }) => `
  position: absolute;
  width: 24%;
  height: 24%;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  border: 1px solid ${theme.colors.base.background};
  display: none;
`;

const modifierConfig = {
  danger: ({ theme }) => `
    background-color: ${theme.colors.status.danger};
    display: block;
  `,

  info: ({ theme }) => `
    background-color: ${theme.colors.status.info};
    display: block;
  `,

  success: ({ theme }) => `
    background-color: ${theme.colors.status.success};
    display: block;
  `,

  warning: ({ theme }) => `
    background-color: ${theme.colors.status.warning};
    display: block;
  `,
};

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      background: PropTypes.string.isRequired,
    }).isRequired,
    status: PropTypes.shape({
      danger: PropTypes.string.isRequired,
      info: PropTypes.string.isRequired,
      success: PropTypes.string.isRequired,
      warning: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const Status = buildStyledComponent('Status', styled.div, styles, {
  modifierConfig,
  themePropTypes,
});

Status.modifiers = modifierConfig;

export default Status;

import { Trans } from '@lingui/macro';
import React from 'react';

import { Row } from 'styled-components-grid';

import parseStatusHistory, {
  CASE_STATUS,
} from 'utils/caseEventsFromStatusHistory';

import CaseEventTimelineEvent from './CaseEventTimelineEvent';
import EditRollTimeActionButton from './EditRollTimeActionButton';
import CaseStatusDelays from './CaseStatusDelays';

const CASE_EVENT_LABELS = {
  [CASE_STATUS.new]: <Trans>Case Creation</Trans>,
  [CASE_STATUS.dispatch]: <Trans>To Dispatch</Trans>,
  [CASE_STATUS.dispatched]: <Trans>Dispatched</Trans>,
  [CASE_STATUS.enRoute]: <Trans>En Route</Trans>,
  [CASE_STATUS.arrived]: <Trans>Arrived</Trans>,
  [CASE_STATUS.rolling]: <Trans>Vehicle Rolling</Trans>,
  [CASE_STATUS.closed]: <Trans>Closed</Trans>,
};

const closedStatuses = [CASE_STATUS.closed, CASE_STATUS.closed_canceled];

function buildEvents(props, rollTimeProps) {
  const { status, permissions = [], caseCreatedAt, statusHistory } = props;
  const { isFeatureEnabled, validationStatus, isPermissionsBasedRole } = props;

  const events = parseStatusHistory({ status, caseCreatedAt, statusHistory });

  return events.map((event) => {
    const {
      type,
      isActive,
      createdAt,
      isComplete,
      completedAt,
      isInterrupted,
      caseStatusChangeId,
    } = event;
    const caseIsClosed = closedStatuses.includes(status);

    const eventHasArrow = [
      isActive,
      caseIsClosed && type === CASE_STATUS.closed,
      !caseIsClosed && isInterrupted,
    ].includes(true);

    const eventHasPopover = isActive || isComplete || isInterrupted;

    const isRollTimeEditable =
      type === CASE_STATUS.rolling &&
      (type === status || caseIsClosed) &&
      caseStatusChangeId;

    const canUpdateRolling =
      isFeatureEnabled('editRollTime') ||
      caseIsClosed ||
      (isPermissionsBasedRole && permissions.includes('case:update_status'));

    const actionButton = (
      <Row
        style={{
          zIndex: 2,
          padding: 0,
          flexWrap: 'nowrap',
          position: 'relative',
        }}
      >
        {isRollTimeEditable && canUpdateRolling && (
          <EditRollTimeActionButton
            {...{
              createdAt,
              completedAt,
              caseStatusChangeId,
              ...rollTimeProps,
            }}
          />
        )}
        <CaseStatusDelays statusForDelays={type} />
      </Row>
    );

    return {
      ...event,
      actionButton,
      hasArrow: eventHasArrow,
      hasPopover: eventHasPopover,
      label: CASE_EVENT_LABELS[type],
      renderer: (data) => (
        <CaseEventTimelineEvent {...{ ...data, validationStatus, key: type }} />
      ),
    };
  });
}

export default buildEvents;

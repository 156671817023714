import React from 'react';
import { curry, get } from 'lodash';
import { Query } from '@apollo/client/react/components';

import caseCustomerQuery from './caseCustomerQuery';

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => (
    <WrappedComponent
      {...componentProps}
      customerId={get(queryProps, 'data.case.customer.id')}
    />
  ),
);

function buildWrappedComponentWithQuery(WrappedComponent, componentProps) {
  const { caseNumber } = componentProps;

  return (
    <Query
      skip={!caseNumber}
      query={caseCustomerQuery}
      variables={{ caseNumber }}
    >
      {buildQueryChild(WrappedComponent, componentProps)}
    </Query>
  );
}

const withCaseCustomer = curry(buildWrappedComponentWithQuery);

export default withCaseCustomer;

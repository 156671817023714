import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { withTheme } from 'styled-components';
import { compose, setDisplayName } from 'recompose';

import { Icon, Text } from 'base-components';
import { Row, Column } from 'styled-components-grid';

export function SaveIndicator({
  isSaving,
  theme: {
    colors: {
      base: { chrome300 },
    },
  },
}) {
  const iconModifier = isSaving ? '' : 'success';
  const iconStyles = isSaving ? { color: chrome300 } : undefined;

  return (
    <Row modifiers="middle">
      <Column modifiers="padScale_0">
        <Icon
          name="check-circle-f"
          style={iconStyles}
          modifiers={iconModifier}
        />
      </Column>
      <Column modifiers="padScaleY_0">
        <Text modifiers={['small', 'textLight']}>
          {isSaving ? <Trans>Saving...</Trans> : <Trans>Contacts saved</Trans>}
        </Text>
      </Column>
    </Row>
  );
}

SaveIndicator.propTypes = {
  isSaving: PropTypes.bool,
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      base: PropTypes.shape({
        chrome300: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

SaveIndicator.defaultProps = {
  isSaving: false,
};

export default compose(
  setDisplayName('SaveIndicator'),
  withTheme,
)(SaveIndicator);

import PropTypes from 'prop-types';

/**
 * isRequiredIf allows a PropType to be optionally required based on a condition function. This
 * condition function will receive the props and should return a boolean. A truthy value will
 * cause the type to be required. This is a way to say, for example, if the component has
 * loading === false, require the data prop _and_ if loading === true don't require the data prop.
 * @param {function} type the PropType to be validated, ex. PropTypes.string
 * @param {function} condition a function that recieves the props and returns a boolean.
 * @returns {function} the PropType, with `.isRequired` if needed.
 */
function isRequiredIf(type, condition) {
  return (props, ...rest) => {
    const test = condition(props) ? type.isRequired : type;
    return test(props, ...rest);
  };
}

const menuSubItem = PropTypes.shape({
  message: PropTypes.string.isRequired,
  path: PropTypes.string,
  url: PropTypes.string,
});

const menuSubItems = PropTypes.arrayOf(menuSubItem);

const menuItem = PropTypes.shape({
  icon: isRequiredIf(PropTypes.string, (props) => !props.loading),
  loading: PropTypes.bool,
  menuSubItems: isRequiredIf(
    menuSubItems,
    (props) => !!(!props.loading && !props.path && !props.url),
  ),
  message: isRequiredIf(PropTypes.string, (props) => !props.loading),
  path: PropTypes.string,
  url: PropTypes.string,
});

const menuGroup = PropTypes.arrayOf(menuItem);

const menuGroups = PropTypes.arrayOf(menuGroup);

export default {
  menuSubItem,
  menuSubItems,
  menuItem,
  menuGroup,
  menuGroups,
};

import { curry, get } from 'lodash';
import React from 'react';
import { Query } from '@apollo/client/react/components';

import contactTypesGQL from './contactTypesQuery';

const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const contactTypes = get(queryProps, ['data', 'caseContactTypes'], []);
    return <WrappedComponent {...componentProps} contactTypes={contactTypes} />;
  },
);

const withContactTypes = (WrappedComponent) => (componentProps) => (
  <Query query={contactTypesGQL}>
    {buildQueryChild(WrappedComponent, componentProps)}
  </Query>
);

export default withContactTypes;

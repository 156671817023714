import React from 'react';
import moment from 'moment-timezone';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';
import { Text } from 'base-components';

import GridNavigator from './GridNavigator';

export function formatDateRangeLabel(weekStart) {
  // weekStart will be the Monday of the week we're looking at
  const weekEnd = moment(weekStart).add(6, 'days');
  const endFormat = 'D MMM, YYYY';
  const startFormat = weekStart.isSame(weekEnd, 'year') ? 'D MMM' : endFormat;
  return `${weekStart.format(startFormat)} - ${weekEnd.format(endFormat)}`;
}

const DateLabelText = buildStyledComponent(
  'ScheduleTopNav__DateLabelText',
  Text,
  `
    font-size: ${px2rem(14)};
  `,
);

/**
 * Label at the top of the rotation schedule showing the start and
 * end dates of the current range of days being displayed.
 *
 * @return {ReactElement}
 */
export default function CurrentDateLabel() {
  return (
    <GridNavigator.Consumer>
      {({ weekStart }) => (
        <DateLabelText modifiers={['textLight']}>
          {formatDateRangeLabel(weekStart)}
        </DateLabelText>
      )}
    </GridNavigator.Consumer>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { compose, setDisplayName } from 'recompose';

import CasesDataTable from 'compositions/CasesDataTable';
import { columns as baseColumns } from 'compositions/CasesDataTable/columns';

import Loading from 'components/Loading';

import withDealerActiveCases from './withDealerActiveCases';
import dealer from './columns/dealer';

export function DealerActiveCasesTable(props) {
  const { activeCases, isLoading, loadingComponent } = props;

  if (isLoading) {
    return loadingComponent;
  }

  const columns = [
    { ...baseColumns.caseNumber(props), width: '12.07%' },
    { ...baseColumns.status(props), width: '32.76%' },
    { ...baseColumns.customer(props), width: '22.41%' },
    { ...baseColumns.unitNo(props), width: '10.34%' },
    { ...dealer(props), width: '22.41%' },
  ];

  return <CasesDataTable columns={columns} entries={activeCases} />;
}

DealerActiveCasesTable.propTypes = {
  activeCases: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadingComponent: PropTypes.node,
  // used by withDealerActiveCases
  // eslint-disable-next-line react/no-unused-prop-types
  dealerId: PropTypes.string.isRequired,
};

DealerActiveCasesTable.defaultProps = {
  loadingComponent: <Loading />,
};

export default compose(
  setDisplayName('DealerActiveCasesTable'),
  withDealerActiveCases,
  withRouter,
)(DealerActiveCasesTable);

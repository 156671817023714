import { Trans } from '@lingui/macro';
import { compact, get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { H3, Text } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

function CustomerDetails({ customer }) {
  return (
    <>
      <Row>
        <Column style={{ maxWidth: '100%' }} modifiers={['padScaleY_0']}>
          <Text>{customer.name}</Text>
        </Column>
      </Row>
      {(customer.city || customer.state) && (
        <Row>
          <Column modifiers={['padScaleY_0']}>
            <Text>{compact([customer.city, customer.state]).join(', ')}</Text>
          </Column>
        </Row>
      )}
      {customer.zip && (
        <Row>
          <Column modifiers={['padScaleY_0']}>
            <Text>{customer.zip}</Text>
          </Column>
        </Row>
      )}
      {(customer.billTo || customer.shipTo) && (
        <Row>
          <Column style={{ maxWidth: '100%' }} modifiers={['padScaleY_0']}>
            <Text>
              {customer.billTo && <Trans>Bill To #{customer.billTo}</Trans>}
              {customer.billTo && customer.shipTo && <span> &middot; </span>}
              {customer.shipTo && <Trans>Ship To #{customer.shipTo}</Trans>}
            </Text>
          </Column>
        </Row>
      )}
      {customer.accountNumber && (
        <Row>
          <Column modifiers={['padScaleY_0']}>
            <Text>
              <Trans>Account #{customer.accountNumber}</Trans>
            </Text>
          </Column>
        </Row>
      )}
    </>
  );
}

CustomerDetails.propTypes = {
  customer: PropTypes.shape({
    billTo: PropTypes.string,
    city: PropTypes.string,
    name: PropTypes.string,
    shipTo: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    accountNumber: PropTypes.string,
  }).isRequired,
};

function Customer({ caseData }) {
  const customer = get(caseData, 'customer') || {};
  const customerDomicile = get(caseData, 'customerDomicile') || {};

  return (
    <Container modifiers={['padScale_2']}>
      <Row>
        <Column modifiers={['padScaleY_2']}>
          <H3 modifiers={['fontWeightRegular']}>
            <Trans>Fleet</Trans>
          </H3>
        </Column>
      </Row>
      {isEmpty(customer) ? (
        <Row>
          <Column>
            <Text>&mdash;</Text>
          </Column>
        </Row>
      ) : (
        <CustomerDetails customer={customer} />
      )}
      {!isEmpty(customerDomicile) && (
        <>
          <Row>
            <Column>
              <Text modifiers={['small', 'textLight']}>
                <Trans>Domicile</Trans>
              </Text>
            </Column>
          </Row>
          <CustomerDetails customer={customerDomicile} />
        </>
      )}
    </Container>
  );
}

Customer.propTypes = {
  caseData: PropTypes.shape({
    customer: PropTypes.shape({}),
    customerDomicile: PropTypes.shape({}),
  }).isRequired,
};

export default Customer;

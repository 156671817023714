import gql from 'graphql-tag';

export default gql`
  mutation sendToFixpix($id: ID!) {
    sendToFixpix(input: { id: $id }) {
      case {
        id
        status
        fixpixPushResult
      }
    }
  }
`;

import { compose, setDisplayName } from 'recompose';

import withDealers from '../withDealers';
import withProximityFilters from '../withProximityFilters';

import LegendComponent from './LegendComponent';

export default compose(
  setDisplayName('DealerLocator__Legend'),
  withDealers,
  withProximityFilters,
)(LegendComponent);

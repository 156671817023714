import { compact, noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import RadioButtonBlock from 'blocks/RadioButton';

function RadioButton({
  checked,
  disabled,
  id,
  label,
  onChange,
  onClick,
  readOnly,
  style,
  ...rest
}) {
  return (
    <RadioButtonBlock
      modifiers={compact([disabled && 'disabled', readOnly && 'readOnly'])}
      style={style}
    >
      <RadioButtonBlock.Input
        {...rest}
        checked={checked}
        disabled={disabled}
        id={id}
        onChange={!disabled && !readOnly ? onChange : noop}
        onClick={!disabled && !readOnly ? onClick : noop}
        readOnly={readOnly}
      />
      <RadioButtonBlock.Label
        htmlFor={id}
        modifiers={compact([label && 'withContent'])}
      >
        {label}
      </RadioButtonBlock.Label>
    </RadioButtonBlock>
  );
}

RadioButton.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool,
  style: PropTypes.shape({}),
};

RadioButton.defaultProps = {
  checked: false,
  disabled: false,
  label: undefined,
  onChange: noop,
  onClick: noop,
  readOnly: false,
  style: undefined,
};

export default RadioButton;

import buildEnum from 'utils/buildEnum';

export const contactFields = {
  callbackEta: 'callbackEta',
  callbackRollTime: 'callbackRollTime',
  contactType: 'contactType',
  email: 'email',
  name: 'name',
  phone: 'phone',
  phoneExt: 'phoneExt',
};

export const fieldIds = buildEnum(
  ['name', 'phone', 'email', 'phoneExt'],
  (key) => `contact.{index}.${key}`,
);

import PropTypes from 'prop-types';
import React from 'react';

import { Text } from 'base-components';
import { buildStyledComponent } from 'decisiv-ui-utils';
import { Column } from 'styled-components-grid';

import { modifiersForComponent } from 'utils/modifiers';

const styles = `
  &:after {
    content: '°';
  }
`;

const Temperature = buildStyledComponent('Temperature', Text, styles);

function WeatherTemperature({ children, modifiers }) {
  return (
    <Column modifiers={modifiersForComponent(modifiers, Column)}>
      <Temperature modifiers={modifiersForComponent(modifiers, Temperature)}>
        {children}
      </Temperature>
    </Column>
  );
}

WeatherTemperature.propTypes = {
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

WeatherTemperature.defaultProps = {
  modifiers: [],
};

export default WeatherTemperature;

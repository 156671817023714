import { get, omit, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Query } from '@apollo/client/react/components';

import { occurrenceKinds } from './constants';

import afterHoursEventConflictsQuery from './afterHoursEventConflictsQuery';

function buildQueryVariables(event) {
  const isSingleOccurrence =
    get(event, 'occurrence.kind') === occurrenceKinds.single;
  return {
    event: {
      ...omit(event, [
        'id',
        'occurrence',
        'options.resolveConflicts',
        'options.skipConflicts',
        ...(isSingleOccurrence ? ['options', 'recurring'] : []),
      ]),
      isRecurring: isSingleOccurrence ? false : event.isRecurring,
    },
  };
}

function getConflictingEvents(data, originalEvent) {
  return uniqBy(
    get(data, ['afterHoursEventConflicts'], [])
      // There is no way to tell the API to exclude the current event, so
      // make sure to filter it out of the conflictingEvents.
      .filter(({ event }) => event.id !== get(originalEvent, 'id')),
    'event.id',
  );
}

function EventConflictsProvider({ isEventValid, event, children }) {
  return (
    <Query
      query={afterHoursEventConflictsQuery}
      fetchPolicy="network-only"
      skip={!isEventValid}
      variables={buildQueryVariables(event)}
    >
      {({ data, loading }) =>
        children({
          checkingConflicts: isEventValid && loading,
          conflictingEvents: isEventValid
            ? getConflictingEvents(data, event)
            : [],
        })
      }
    </Query>
  );
}

EventConflictsProvider.propTypes = {
  isEventValid: PropTypes.bool.isRequired,
  event: PropTypes.shape({
    id: PropTypes.string,
    isRecurring: PropTypes.bool.isRequired,
  }).isRequired,
  children: PropTypes.func.isRequired,
};

export default EventConflictsProvider;

export const sizes = {
  XS: 'XS',
  SM: 'SM',
  MD: 'MD',
  LG: 'LG',
  XL: 'XL',
};

export const DEFAULT_MIN_ADJUSTMENT = 10;
export const DEFAULT_SIZE = sizes.XS;
export const DEFAULT_THROTTLE_MS = 250;

import PropTypes from 'prop-types';
import React from 'react';

import { Row } from 'styled-components-grid';

import Timeline from 'blocks/Timeline';

import Event from './Event';

// TODO: Extract this component to Base Components.

const defaultRenderer = ({ type, ...rest }) => <Event key={type} {...rest} />;

defaultRenderer.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
};

function EventTimeline({ events, ...rest }) {
  return (
    <Timeline {...rest}>
      <Row style={{ flexWrap: 'nowrap' }}>
        {events.map(({ renderer, ...event }, index) => {
          if (renderer) {
            return renderer({ ...event, index, defaultRenderer });
          }

          return defaultRenderer({ ...event, index });
        })}
      </Row>
    </Timeline>
  );
}

EventTimeline.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired,
    }),
  ),
};

EventTimeline.defaultProps = {
  events: [],
};

export default EventTimeline;

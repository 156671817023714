import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

const styles = ({ theme }) => `
  background-color: ${theme.colors.base.chrome000};
  border-bottom: 1px solid ${theme.colors.base.chrome200};
  height: ${theme.dimensions.topNavHeight};
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome000: PropTypes.string.isRequired,
      chrome200: PropTypes.string.isRequired,
    }),
  }),
  dimensions: PropTypes.shape({
    topNavHeight: PropTypes.string.isRequired,
  }),
};

export default buildStyledComponent('Layout__Header', styled.header, styles, {
  themePropTypes,
});

import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName, withReducer } from 'recompose';

import { Row, Column } from 'styled-components-grid';

import { H2, Text, Popover, QuickActionButton } from 'base-components';

import Modal from 'components/Modal';
import ButtonLinkWithIcon from 'elements/ButtonLinkWithIcon';

import withRequestAssetActions from 'compositions/CaseRequestsPanel/CaseRequestsPanelContext/withRequestAssetActions';

import ValidationInstructions from '../ValidationInstructions';
import withSetCaseCustomerByShipTo from './withSetCaseCustomerByShipTo';
import AssetValidationModalContent from './AssetValidationModalContent';

import { reducer, syncAsset, getModalInitialState } from '../utils';

import {
  modalModes,
  modalActions,
  validationType,
  modalStatePropType,
  assetValidationPropType,
} from '../constants';

const { validationSucceeded } = modalModes;
const { startSave, endSave } = modalActions;

const emphasis = <Text modifiers="fontWeightMedium" />;

const ValidateAssetModal = (props) => {
  const { state, dispatch, readOnly } = props;
  const { onClose, assetValidationFields } = props;
  const { assetValidation, setCaseAssetValidation } = props;
  const { setCaseCustomerByShipTo, assetValidationInstructions } = props;
  const { assetValidationFailureInstructions: fallbackInstruction } = props;
  const { mode, assetType, requestAsset, attempt } = state;

  const shipTo = get(attempt, 'asset.shipTo');
  const isReadOnly = readOnly || state.isSaving;
  const isSuccess = mode === validationSucceeded;
  const canReplaceData = !readOnly && isSuccess;
  const canReplaceAsset = canReplaceData && assetType === requestAsset.type;
  const showInstructions = assetValidationInstructions || fallbackInstruction;
  const canReplaceCustomer = canReplaceData && !!shipTo;

  const triggerAction = (type, data, cb) => dispatch({ type, data }, cb);

  const save = () =>
    triggerAction(startSave, null, () => {
      const { id } = requestAsset;
      const { entries } = assetValidation;

      let attempts = entries[id] || [];

      if (attempt && !attempt.reference) {
        attempts = attempts.filter((item) => item.id !== attempt.id);
      } else {
        attempts = attempt.id
          ? attempts.map((item) => (item.id === attempt.id ? attempt : item))
          : [...attempts, { ...attempt }];
      }

      const variables = { entries: { ...entries, [id]: attempts } };

      if (!!attempt && !!attempt.reference && !attempt.asset) {
        variables.type = validationType.offline;
      }

      setCaseAssetValidation(variables)
        .then(() => canReplaceCustomer && setCaseCustomerByShipTo(shipTo))
        .then(() => canReplaceAsset && syncAsset({ ...state, props }))
        .catch((e) => console.error(e)) // eslint-disable-line no-console
        .finally(() => triggerAction(endSave, null, onClose));
    });

  return (
    <Modal onClose={state.isSaving ? noop : onClose}>
      {() => (
        <Modal.Body style={{ width: 960, maxWidth: 'none' }}>
          <Modal.Header showCloseButton={!state.isSaving}>
            <Modal.HeaderIcon name="checklist" />
          </Modal.Header>
          <Modal.Content>
            <Row modifiers="center">
              <Column>
                <H2 modifiers="fontWeightRegular">
                  <Trans>Asset Validation</Trans>
                </H2>
              </Column>
            </Row>
            <Row modifiers="center">
              <Column>
                <Text>
                  <Trans
                    id='Validation for "<0>{assetType}</0>" of <1>Asset</1> <2>{assetIndex}</2>'
                    values={{ assetType, assetIndex: requestAsset.index + 1 }}
                    components={[emphasis, emphasis, emphasis]}
                  />
                </Text>
                <br />
                <Text modifiers={['small', 'textLight']}>
                  <Trans>Unit Number:</Trans>
                </Text>
                &nbsp;
                <Text modifiers="fontWeightMedium">
                  {requestAsset.number || '—'}
                </Text>
                ,&nbsp;&nbsp;
                <Text modifiers={['small', 'textLight']}>
                  <Trans>Unit Type:</Trans>
                </Text>
                &nbsp;
                <Text modifiers="fontWeightMedium">{requestAsset.type}</Text>
              </Column>
            </Row>
            {showInstructions && (
              <Row modifiers={['center', 'middle']}>
                {assetValidationInstructions && (
                  <Column>
                    <Popover position="bottom">
                      <Popover.Target>
                        <ButtonLinkWithIcon>
                          <Trans>Validation Instructions</Trans>
                        </ButtonLinkWithIcon>
                      </Popover.Target>
                      <Popover.Content style={{ width: 450, padding: 20 }}>
                        <ValidationInstructions
                          text={assetValidationInstructions.trim()}
                          style={{ textAlign: 'left' }}
                        />
                      </Popover.Content>
                    </Popover>
                  </Column>
                )}
                {assetValidationInstructions && fallbackInstruction && (
                  <Column>
                    <Text>|</Text>
                  </Column>
                )}
                {fallbackInstruction && (
                  <Column>
                    <Popover position="bottom">
                      <Popover.Target>
                        <ButtonLinkWithIcon>
                          <Trans>Fallback Instructions</Trans>
                        </ButtonLinkWithIcon>
                      </Popover.Target>
                      <Popover.Content style={{ width: 450, padding: 20 }}>
                        <ValidationInstructions
                          text={fallbackInstruction.trim()}
                          style={{ textAlign: 'left' }}
                        />
                      </Popover.Content>
                    </Popover>
                  </Column>
                )}
              </Row>
            )}
            <Row style={{ marginTop: 15 }}>
              <Column modifiers={['col', 'padScale_0']}>
                <AssetValidationModalContent
                  {...props}
                  fields={assetValidationFields}
                  readOnly={isReadOnly}
                  triggerAction={triggerAction}
                />
              </Column>
            </Row>
          </Modal.Content>
          <Modal.Footer>
            <Row modifiers="end">
              <Column>
                <QuickActionButton
                  type="button"
                  onClick={onClose}
                  disabled={state.isSaving}
                  modifiers={[state.isSaving && 'disabled']}
                >
                  <QuickActionButton.Text>
                    <Trans>Cancel</Trans>
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
              <Column>
                <QuickActionButton
                  type="submit"
                  onClick={save}
                  disabled={isReadOnly}
                  modifiers={['secondary', isReadOnly && 'disabled']}
                >
                  <QuickActionButton.Text>
                    {!state.isSaving && <Trans>Save</Trans>}
                    {state.isSaving && <Trans>Saving...</Trans>}
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
            </Row>
          </Modal.Footer>
        </Modal.Body>
      )}
    </Modal>
  );
};

ValidateAssetModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  assetValidation: assetValidationPropType.isRequired,
  setCaseAssetValidation: PropTypes.func.isRequired,
  assetValidationInstructions: PropTypes.string,

  /* eslint-disable react/no-unused-prop-types */
  state: modalStatePropType.isRequired,
  caseId: PropTypes.string.isRequired,
  // Used by withCaseAssetValidation and withSetCaseCustomerByShipTo
  caseNumber: PropTypes.string.isRequired,
  isUsxpress: PropTypes.bool.isRequired,
  assetValidationFields: PropTypes.arrayOf(PropTypes.string),
  assetValidationFailureInstructions: PropTypes.string,
  updateAsset: PropTypes.func.isRequired,
  setCaseCustomerByShipTo: PropTypes.func.isRequired,
  /* eslint-enable react/no-unused-prop-types */
};

ValidateAssetModal.defaultProps = {
  assetValidationFields: [],
  assetValidationInstructions: '',
  assetValidationFailureInstructions: '',
};

export default compose(
  setDisplayName('ValidateAssetModal'),
  withRequestAssetActions,
  withSetCaseCustomerByShipTo,
  withReducer('state', 'dispatch', reducer, getModalInitialState),
)(ValidateAssetModal);

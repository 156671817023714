import { keyframes } from 'styled-components';

const GhostAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 51%;
  }

  100% {
    background-position: 0% 50%;
  }
`;

export default GhostAnimation;

import React, { useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import caseDelaysQuery, { NAME as delaysQueryName } from './caseDelaysQuery';
import createDelayMutation from './createCaseDelayMutation';
import updateDelayMutation from './updateCaseDelayMutation';

const refetchQueries = [delaysQueryName];

export const useCaseDelays = (caseId, caseNumber, status) => {
  const { data, loading } = useQuery(caseDelaysQuery, {
    skip: !caseNumber,
    variables: { caseNumber },
  });

  const [runCreateDelay] = useMutation(createDelayMutation, { refetchQueries });
  const [runUpdateDelay] = useMutation(updateDelayMutation, { refetchQueries });

  const isDelayed = data?.case?.isDelayed || false;
  const caseDelays = data?.case?.caseDelays || [];
  const assetDowntimeMs = data?.case?.assetDowntimeMs;
  const isLoadingCaseDelays = !!loading;

  const createCaseDelay = useCallback(
    ({ reasonType, customReason } = {}) => {
      if (!caseId) return;

      const variables = { caseId, status, reasonType, customReason };
      return runCreateDelay({ variables });
    },
    [caseId, status, runCreateDelay],
  );

  const updateCaseDelay = useCallback(
    (delayId) => {
      if (!caseId) return;

      const variables = { delayId, endTime: new Date().toISOString() };
      return runUpdateDelay({ variables });
    },
    [runUpdateDelay, caseId],
  );

  return {
    isDelayed,
    caseDelays,
    createCaseDelay,
    updateCaseDelay,
    assetDowntimeMs,
    isLoadingCaseDelays,
  };
};

const withCaseDelays = (Component) => (cProps) => {
  const { caseId, caseNumber, status } = cProps;
  const delaysProps = useCaseDelays(caseId, caseNumber, status);
  const allProps = { ...cProps, ...delaysProps };

  return <Component {...allProps} />;
};

export default withCaseDelays;

import React from 'react';
import { t } from '@lingui/macro';
import { isEmail } from 'validator';
import { isEmpty, get } from 'lodash';

import { isPhoneNumber } from 'utils/format';
import { PANEL_STATUSES } from 'compositions/CaseStatus';
import { invalidCharsForExporting } from 'constants/index';

const { invalid, complete, partial, incomplete } = PANEL_STATUSES;

function isNameValid(value) {
  return !invalidCharsForExporting.test(value);
}

function isPhoneExtValid(value) {
  return value.length <= 10;
}

function getContactErrors({ phone, email, name, phoneExt }) {
  const errors = {};

  if (name && !isNameValid(name)) {
    errors.name = t`Name cannot contain the following characters: ~\`.`;
  }

  if (phone && !isPhoneNumber(phone)) {
    errors.phone = t`Phone number is not valid.`;
  }

  if (email && !isEmail(email)) {
    errors.email = t`Email must be in name@domain.com format.`;
  }

  if (phoneExt && !isPhoneExtValid(phoneExt)) {
    errors.phoneExt = t`Phone extension must have at most 10 characters.`;
  }

  return errors;
}

function getContactStatus(contact = {}) {
  const errors = getContactErrors(contact);

  if (!isEmpty(errors)) {
    return {
      status: invalid,
      fields: Object.keys(errors).reduce(
        (acc, key) => ({ ...acc, [key]: 'invalid' }),
        {},
      ),
    };
  }

  const { name, email, phone, phoneExt } = contact;

  if (name && phone) {
    return { status: complete, fields: {} };
  }

  if (name || phone || email || phoneExt) {
    const entries = [
      ['name', name],
      ['phone', phone],
    ];

    return {
      status: partial,
      fields: entries.reduce(
        (acc, [key, value]) => (value ? acc : { ...acc, [key]: 'missing' }),
        {},
      ),
    };
  }

  return { status: incomplete, fields: {} };
}

function getPanelStatus({ contactForms }) {
  const statuses = contactForms.map((item) => get(item, 'status.status'));

  const fields = contactForms.reduce((allFields, item, index) => {
    const itemFields = Object.entries(get(item, 'status.fields', {})).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [`contact.${index}.${key}`]: {
          prefix: (
            <span>
              {t`Contact ${index + 1}:`}
              &nbsp;
            </span>
          ),
          severity: value,
          translationKey: `contact.${key}`,
        },
      }),
      {},
    );

    return { ...allFields, ...itemFields };
  }, {});

  if (statuses.includes(invalid)) return { status: invalid, fields };
  if (statuses.includes(partial)) return { status: partial, fields };
  if (statuses.includes(complete)) return { status: complete, fields };

  return { status: incomplete, fields };
}

export { getContactErrors, getContactStatus, getPanelStatus };

/**
 * Application-wide shadows
 */
import colors from './colors';

const shadowColor = colors.base.shadow;

export default {
  sky1: `box-shadow: 0 1px 4px 1px ${shadowColor}`,
  sky2: `box-shadow: 0 2px 5px 1px ${shadowColor}`,
  sky3: `box-shadow: 0 3px 6px 1px ${shadowColor}`,
};

import qs from 'qs';
import moment from 'moment-timezone';
import { get, camelCase, kebabCase, omitBy } from 'lodash';

import {
  sortByParamToColumnName,
  paramsWithPreservedCase,
  columnNameToSortByParam,
  apiCaseStatusToStatusParam,
  statusParamToAPICaseStatus,
} from './constants';

export const toArray = (value) => (Array.isArray(value) ? value : [value]);

export const parseDateFromLocation = (dateStr, fallbackDate) => {
  // eslint-disable-next-line no-restricted-globals
  if (!dateStr || isNaN(new Date(dateStr).valueOf())) {
    return fallbackDate || undefined;
  }

  const date = moment(dateStr);

  return date.isValid() ? date.toDate() : undefined;
};

// Removes keys with null or undefined values
export const cleanObject = (obj) =>
  omitBy(obj, (v) => [null, undefined].includes(v));

const toLowerCase = (value) =>
  Array.isArray(value)
    ? value.map((v) => v.toLowerCase())
    : value.toLowerCase();

// Transforms { 'sort-by': 'START-TIME' } into { sortBy: 'start-time' }
export const prepareParamsForProps = (params) => {
  const props = Object.entries(params).reduce((acc, [key, value]) => {
    let finalValue;

    if (value !== '') {
      finalValue = paramsWithPreservedCase.includes(key)
        ? value
        : toLowerCase(value);
    }

    return { ...acc, [camelCase(key)]: finalValue };
  }, {});

  if (props.endDate && !props.startDate) {
    props.startDate = props.endDate;
    props.endDate = '';
  }

  return props;
};

// Transforms { sortBy: 'start-time' } into { 'sort-by': 'start-time' }
export const prepareParamsForLocation = (params) =>
  Object.entries(params).reduce(
    (acc, [key, value]) => ({ ...acc, [kebabCase(key)]: value }),
    {},
  );

export const prepareDateForLocation = (date) =>
  date ? moment(date).format('YYYY-MM-DD') : null;

export const paramsForMode = (props) => {
  const { mode, location } = props;

  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  const finalProps = prepareParamsForProps(params);
  const paramsKeys = Object.keys(params);

  const sortBy = sortByParamToColumnName[finalProps.sortBy];

  if (mode === 'highlightedOnly') return { ...finalProps, sortBy };

  let fallbackEndDate;
  let fallbackStartDate;

  // We're temporarily limiting the search to the last 7 days to prevent
  // the search from happening on all cases, which makes it very slow.
  if (!paramsKeys.includes('start-date') && !paramsKeys.includes('end-date')) {
    fallbackEndDate = moment().toDate();
    fallbackStartDate = moment().subtract(6, 'days').toDate();
  }

  return {
    ...finalProps,
    query: finalProps.query || '',
    endDate: parseDateFromLocation(finalProps.endDate, fallbackEndDate),
    startDate: parseDateFromLocation(finalProps.startDate, fallbackStartDate),
    status: toArray(get(finalProps, 'status'))
      .map((type) => statusParamToAPICaseStatus[type] || type)
      .filter(Boolean),
    sortBy,
  };
};

export const urlParamsForNode = (props, params) => {
  const { mode } = props;
  const { before, after, sortDirection } = params;

  const sortBy = columnNameToSortByParam[params.sortBy] || null;

  if (mode === 'highlightedOnly') {
    return prepareParamsForLocation({ sortBy, sortDirection, before, after });
  }

  const status = params.status
    ? params.status.map((type) => apiCaseStatusToStatusParam[type])
    : params.status;

  return prepareParamsForLocation({
    ...params,
    status,
    sortBy: columnNameToSortByParam[params.sortBy] || null,
    startDate: prepareDateForLocation(params.startDate),
    endDate: prepareDateForLocation(params.endDate),
  });
};

import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';

import { SET_NEW_CASE_NUMBER } from '../constants';

function setNewCaseNumber(state, { payload }) {
  return state.set('newCaseNumber', payload.caseNumber);
}

export default handleActions(
  {
    [SET_NEW_CASE_NUMBER]: setNewCaseNumber,
  },
  fromJS({}),
);

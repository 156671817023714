import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, setDisplayName } from 'recompose';
import { isEmpty } from 'lodash';

import { Row, Column } from 'styled-components-grid';

import { unitSelector } from 'redux/preferences/selectors';

import Block from './Block';
import TravelEstimate from './TravelEstimate';
import Weather from './Weather';

export function InfoAtDispatch({ travelEstimate, unit, weather }) {
  const showWeather = !isEmpty(weather) && !!weather?.current;
  return (
    <Row modifiers={['middle', 'padScale_0']}>
      {showWeather && (
        <Column modifiers={['col', 'padScale_0']}>
          <Block>
            <Weather unit={unit} weather={weather} />
          </Block>
        </Column>
      )}
      {!isEmpty(travelEstimate) && (
        <Column modifiers={['col', 'padScale_0']}>
          <Block>
            <TravelEstimate travelEstimate={travelEstimate} unit={unit} />
          </Block>
        </Column>
      )}
    </Row>
  );
}

InfoAtDispatch.propTypes = {
  travelEstimate: PropTypes.shape({
    distance: PropTypes.number,
    time: PropTypes.number,
  }),
  unit: PropTypes.string.isRequired,
  weather: PropTypes.shape({
    current: PropTypes.shape({
      code: PropTypes.string,
    }),
  }),
};

InfoAtDispatch.defaultProps = {
  travelEstimate: {},
  weather: {},
};

function mapStateToProps(state) {
  return {
    unit: unitSelector(state),
  };
}

export default compose(
  setDisplayName('InfoAtDispatch'),
  connect(mapStateToProps),
)(InfoAtDispatch);

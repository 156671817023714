import { curry, get } from 'lodash';
import React from 'react';
import { Query } from '@apollo/client/react/components';

import caseNotesGQL from './caseNotesQuery';

export function skipQuery({ caseNumber }) {
  return !caseNumber;
}

export function buildQueryVariables({ caseNumber }) {
  return { caseNumber };
}

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const caseId = get(queryProps, 'data.case.id');
    const notes = get(queryProps, 'data.case.notes.edges', []);
    const notesLoading = get(queryProps, 'loading');

    return (
      <WrappedComponent
        {...componentProps}
        caseId={caseId}
        notes={notes}
        notesLoading={notesLoading}
      />
    );
  },
);

const withCaseNotes = (WrappedComponent) => (componentProps) => (
  <Query
    query={caseNotesGQL}
    skip={skipQuery(componentProps)}
    variables={buildQueryVariables(componentProps)}
    fetchPolicy="network-only"
  >
    {buildQueryChild(WrappedComponent, componentProps)}
  </Query>
);

export default withCaseNotes;

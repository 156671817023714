import buildEnum from 'utils/buildEnum';

import { configTypes } from '../ReportConfigBuilder/constants';

export const detailLevelTypes = buildEnum(['summary', 'detail']);
export const selectionTypes = buildEnum(['all', 'custom']);

export const initialConfig = {
  [configTypes.detailLevel]: detailLevelTypes.summary,
  [configTypes.inboundProgramIds]: [],
  [configTypes.inboundProgramSelectionType]: selectionTypes.all,
};

import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { ButtonLink, Text } from 'base-components';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const styles = () => `
  font-size: ${px2rem(14)};
  text-align: left;
  white-space: normal;
`;

export const DealerNameButton = buildStyledComponent(
  'DealerNameButton',
  styled(ButtonLink),
  styles,
);

export const DealerNameText = buildStyledComponent(
  'DealerNameText',
  styled(Text),
  styles,
);

function DealerName({ clickable, onClick, ...rest }) {
  if (clickable) {
    return <DealerNameButton onClick={onClick} {...rest} />;
  }
  return <DealerNameText {...rest} />;
}

DealerName.propTypes = {
  clickable: PropTypes.bool,
  onClick: PropTypes.func,
};

DealerName.defaultProps = {
  clickable: false,
  onClick: noop,
};

export default DealerName;

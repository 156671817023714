import { Trans } from '@lingui/macro';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Avatar, P, Text } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import { CallButtonLink } from 'compositions/ClickToCall';

import contactPropType from '../contactPropType';

function ContactItem({ dealer, info, label, type, onOutboundCall }) {
  const isCompany = ['storeContact', 'afterHoursContact'].includes(type);

  return (
    <Container>
      {label && (
        <Row>
          <Column>
            <P modifiers={['small', 'textLight']}>
              <Trans id={label} />
            </P>
          </Column>
        </Row>
      )}
      <Row>
        <Column>
          <Avatar isCompany={isCompany} modifiers={['small']} />
        </Column>
        <Column modifiers={['col', 'padScale_0']}>
          <Container modifiers={['padScaleX_2', 'padScaleY_0']}>
            {isEmpty(info) ? (
              <Row>
                <Column modifiers="padScale_0">
                  <P>—</P>
                </Column>
              </Row>
            ) : (
              <>
                <Row>
                  <Column modifiers={['col', 'padScaleY_0']}>
                    <P>
                      {info.name}
                      {info.jobTitle && (
                        <>
                          &nbsp;
                          <Text
                            style={{ display: 'inline' }}
                            modifiers={['fontWeightLight', 'textLight']}
                          >
                            ({info.jobTitle})
                          </Text>
                        </>
                      )}
                    </P>
                  </Column>
                </Row>
                <Row>
                  <Column modifiers="padScaleY_0">
                    <CallButtonLink
                      dealer={dealer}
                      dealerContact={info}
                      phone={info.primaryPhoneNumber}
                      name={info.name}
                      onOutboundCall={onOutboundCall}
                    />
                  </Column>
                  {info.secondaryPhoneNumber && (
                    <>
                      <Column modifiers="padScaleY_0">
                        <Text modifiers={['info', 'small']}>&middot; </Text>
                      </Column>
                      <Column modifiers="padScaleY_0">
                        <CallButtonLink
                          dealer={dealer}
                          dealerContact={info}
                          phone={info.secondaryPhoneNumber}
                          name={info.name}
                          onOutboundCall={onOutboundCall}
                        />
                      </Column>
                    </>
                  )}
                </Row>
              </>
            )}
          </Container>
        </Column>
      </Row>
    </Container>
  );
}

ContactItem.propTypes = {
  info: contactPropType,
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  dealer: PropTypes.shape({}).isRequired,
  onOutboundCall: PropTypes.func,
};

ContactItem.defaultProps = {
  info: {},
  label: null,
  onOutboundCall: null,
};

export default ContactItem;

import { curry } from 'lodash';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import updateAfterHoursContactMutation from './updateAfterHoursContactQuery';
import { NAME as CONTACTS_QUERY_NAME } from '../withContacts/contactsQuery';

export const buildMutationChild = curry(
  (
    WrappedComponent,
    componentProps,
    updateAfterHoursContact,
    mutationProps,
  ) => (
    <WrappedComponent
      {...componentProps}
      isUpdatingAfterHoursContact={!!mutationProps?.loading}
      updateAfterHoursContact={(params) => {
        const {
          id,
          name,
          jobTitle,
          primaryPhoneNumber,
          secondaryPhoneNumber,
        } = params;
        return updateAfterHoursContact({
          variables: {
            id,
            name,
            jobTitle,
            primaryPhoneNumber,
            secondaryPhoneNumber,
          },
        });
      }}
    />
  ),
);

function buildWrappedComponentWithMutation(WrappedComponent, componentProps) {
  return (
    <Mutation
      mutation={updateAfterHoursContactMutation}
      refetchQueries={[CONTACTS_QUERY_NAME]}
    >
      {buildMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

const withUpdateAfterHoursContact = (WrappedComponent) => (componentProps) =>
  buildWrappedComponentWithMutation(WrappedComponent, componentProps);

export default withUpdateAfterHoursContact;

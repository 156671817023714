import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { Row, Column } from 'styled-components-grid';
import { H4, Badge, Text } from 'base-components';

import withCaseMismatches from './withCaseMismatches';
import { mismatchTypes, labelForType } from './constants';

const { assetMismatch, customerMismatch } = mismatchTypes;

const badgeStyles = { borderRadius: '2px 0 0 2px', width: 50, height: '100%' };

export function CaseMismatchesBar(props) {
  const { mismatches } = props;

  if (!mismatches.length) return null;

  const asset = mismatches.find((m) => m.type === assetMismatch);
  const customer = mismatches.find((m) => m.type === customerMismatch);

  return (
    <Row style={{ marginTop: 16 }} modifiers={['padScaleX_4', 'padScaleY_0']}>
      <Column modifiers="padScale_0">
        <Badge modifiers="danger" style={badgeStyles}>
          <Badge.Icon name="exclamation-triangle" modifiers="chrome000" />
        </Badge>
      </Column>
      <Column
        style={{ background: 'white', padding: '5px 12px' }}
        modifiers={['col', 'padScaleX_4', 'padScaleY_0']}
      >
        <Row>
          <Column modifiers="col">
            <H4 modifiers="fontWeightRegular">
              <Trans>FIXPIX mismatch report</Trans>
            </H4>
          </Column>
        </Row>
        {customer && (
          <Row>
            <Column modifiers="padScaleY_0">
              <Text modifiers="fontWeightMedium">
                <Trans id={labelForType[customerMismatch]} />
              </Text>
            </Column>
            <Column modifiers={['col', 'padScaleY_0']}>
              <Text>{customer.message || <span>&mdash;</span>}</Text>
            </Column>
          </Row>
        )}
        {asset && (
          <Row>
            <Column modifiers="padScaleY_0">
              <Text modifiers="fontWeightMedium">
                <Trans id={labelForType[assetMismatch]} />
              </Text>
            </Column>
            <Column modifiers={['col', 'padScaleY_0']}>
              <Text>{asset.message || <span>&mdash;</span>}</Text>
            </Column>
          </Row>
        )}
      </Column>
    </Row>
  );
}

CaseMismatchesBar.propTypes = {
  mismatches: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      message: PropTypes.string,
    }),
  ).isRequired,
};

export default compose(
  setDisplayName('CaseMismatchesBar'),
  withCaseMismatches,
)(CaseMismatchesBar);

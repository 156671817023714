import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { px2rem } from 'decisiv-ui-utils';
import { compact } from 'lodash';
import { t, Trans, Plural } from '@lingui/macro';

import { Row, Column } from 'styled-components-grid';
import {
  H2,
  InputField,
  MessageSmall,
  P,
  QuickActionButton,
  Text,
} from 'base-components';

import Modal from 'components/Modal';

import LoadingMessage from '../../LoadingMessage';

export const MAX_DESCRIPTION_LENGTH = 300;

export class StoreNoteModal extends Component {
  static propTypes = {
    handleSave: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired,
    notes: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    isUpdatingDealerNotes: PropTypes.bool,
  };

  static defaultProps = {
    isUpdatingDealerNotes: false,
  };

  state = { notes: this.props.notes };

  handleTextChange = ({ target: { value } }) => this.setState({ notes: value });

  renderWarningMessage = () => (
    <MessageSmall type="warning">
      <Trans>You reached max character count</Trans>
    </MessageSmall>
  );

  render() {
    const { notes } = this.state;
    const {
      handleSave,
      isEditing,
      onClose,
      isUpdatingDealerNotes,
    } = this.props;
    const isMaxCharacters = notes.length >= MAX_DESCRIPTION_LENGTH;

    const canSave = !isUpdatingDealerNotes && !!notes;

    return (
      <Modal onClose={onClose}>
        {({ closeModal }) => (
          <Modal.Body>
            <Modal.Header>
              <Modal.HeaderIcon
                name={isEditing ? 'sticky-note' : 'sticky-note-plus'}
              />
            </Modal.Header>
            <Modal.Content>
              <Row modifiers={['center']}>
                <Column>
                  <H2 modifiers={['fontWeightRegular']}>
                    {isEditing ? (
                      <Trans>Edit Note</Trans>
                    ) : (
                      <Trans>Create Note</Trans>
                    )}
                  </H2>
                </Column>
              </Row>
              <Row modifiers={['center']}>
                <Column>
                  <P
                    style={{
                      // FIXME: A P element should have a bottom margin,
                      // just like default styles in html
                      marginBottom: px2rem(20),
                    }}
                  >
                    <Trans>
                      The information or instructions you add here are provided
                      to the ONCall agents.
                    </Trans>
                  </P>
                </Column>
              </Row>
              <Row>
                <Column modifiers={['col']}>
                  <InputField
                    isValid={!isMaxCharacters}
                    name="note-input"
                    onChange={this.handleTextChange}
                    placeholder={t`Enter note...`}
                    value={notes}
                    modifiers={['height_auto']}
                    maxLength={MAX_DESCRIPTION_LENGTH}
                  >
                    <Column modifiers={['col', 'padScaleY_0']}>
                      <Row>
                        <InputField.Label>
                          <Text modifiers={['small', 'textLight']}>
                            <Trans>Note</Trans>
                          </Text>
                        </InputField.Label>
                        <InputField.CharsLeftLabel>
                          {(charsLeft) => (
                            <Plural
                              value={charsLeft}
                              one="# Character Left"
                              other="# Characters Left"
                            />
                          )}
                        </InputField.CharsLeftLabel>
                      </Row>
                      <Row>
                        <InputField.TextArea />
                      </Row>
                    </Column>
                  </InputField>
                  <Row>
                    <Column modifiers={['padScaleY_2']}>
                      {isMaxCharacters && this.renderWarningMessage()}
                    </Column>
                  </Row>
                </Column>
              </Row>
            </Modal.Content>
            <Modal.Footer>
              <Row modifiers={['end']}>
                {isUpdatingDealerNotes && (
                  <LoadingMessage
                    message={<Trans>Saving, please wait...</Trans>}
                  />
                )}
                <Column>
                  <QuickActionButton onClick={closeModal}>
                    <QuickActionButton.Text>
                      <Trans>Cancel</Trans>
                    </QuickActionButton.Text>
                  </QuickActionButton>
                </Column>
                <Column>
                  <QuickActionButton
                    disabled={!canSave}
                    modifiers={compact(['secondary', !canSave && 'disabled'])}
                    onClick={() => handleSave(notes, closeModal)}
                  >
                    <QuickActionButton.Text>
                      <Trans>Save Note</Trans>
                    </QuickActionButton.Text>
                  </QuickActionButton>
                </Column>
              </Row>
            </Modal.Footer>
          </Modal.Body>
        )}
      </Modal>
    );
  }
}

export default StoreNoteModal;

import { Trans } from '@lingui/macro';
import React from 'react';

import { px2rem } from 'decisiv-ui-utils';
import { MessageMedium } from 'base-components';
import { Container } from 'styled-components-grid';

export default function () {
  return (
    <Container
      style={{ whiteSpace: 'normal', minWidth: px2rem(300) }}
      className="no-results"
    >
      <MessageMedium>
        <MessageMedium.Header>
          <MessageMedium.Icon name="search" />
        </MessageMedium.Header>
        <MessageMedium.Section>
          <MessageMedium.Title>
            <Trans>No Matches Found</Trans>
          </MessageMedium.Title>
        </MessageMedium.Section>
        <MessageMedium.Section>
          <MessageMedium.Text>
            <Trans>
              Try removing or editing some of your criteria or search for
              different words
            </Trans>
          </MessageMedium.Text>
        </MessageMedium.Section>
      </MessageMedium>
    </Container>
  );
}

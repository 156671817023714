import React, { useMemo } from 'react';
import memoize from 'memoize-one';
import { useQuery } from '@apollo/client';
import { get, find, omit } from 'lodash';

import { CASE_STATUS } from 'compositions/CaseStatus/constants';

import query from './query';
import { validationType } from '../../constants';

const defaultValidationData = { type: 'MATCH_ASSET', notes: null, entries: {} };

// Only show entries for assets we know about, and transform
// from an array to an object whose keys are the asset ids.
const getValidationEntries = memoize((entries, primaryAsset, relatedAssets) => {
  if (!entries) return {};

  const getAttempts = (assetId) => {
    const entry = find(entries, { assetId }) || {};

    return entry.assetValidationEntryAttempts || [];
  };

  return [primaryAsset, ...relatedAssets]
    .filter((asset) => !!get(asset, 'id') && !!get(asset, 'assetType'))
    .reduce((acc, { id }) => ({ ...acc, [id]: getAttempts(id) }), {});
});

const withCaseAssetValidation = (Component) => (cProps) => {
  const { primaryAsset, relatedAssets, caseNumber } = cProps;

  const { data, loading } = useQuery(query, {
    skip: !caseNumber,
    variables: { caseNumber },
  });

  const caseData = data?.case || {};
  const { status: caseStatus, inboundProgramNumber: ibNumber } = caseData;

  const program = useMemo(() => ibNumber || {}, [ibNumber]);
  const { isUsxpress = false } = program;
  const { requiresValidationForAssetTypes } = program;
  const { assetValidationInstructions = '' } = program;
  const { assetValidationFailureInstructions = '' } = program;

  let { requiresAssetValidation = false } = program;

  const assetValidation = useMemo(() => {
    let value = caseData.assetValidation;

    if (value) {
      value = { ...value, type: value.type || validationType.matchAsset };

      // Make entries an object whose keys are the request assets ids
      if (Array.isArray(value.assetValidationEntries)) {
        value = {
          ...omit(value, ['assetValidationEntries', '__typename']),
          entries: getValidationEntries(
            value.assetValidationEntries,
            primaryAsset,
            relatedAssets,
          ),
        };
      }
    }

    return value || defaultValidationData;
  }, [caseData.assetValidation, primaryAsset, relatedAssets]);

  const validateAssetTypes = useMemo(
    () =>
      (requiresValidationForAssetTypes || []).filter(
        (rule) => !!rule.requirements.length,
      ),
    [requiresValidationForAssetTypes],
  );

  const assetValidationFields = useMemo(
    () => program.assetValidationFields || [],
    [program],
  );

  if (
    !validateAssetTypes.length ||
    // Prevents cases created before the "Asset Validation" feature,
    // from requiring validation if the case has been moved forward.
    (caseStatus !== CASE_STATUS.new && assetValidation === null)
  ) {
    requiresAssetValidation = false;
  }

  const allProps = {
    ...cProps,
    caseId: caseData.id,
    isUsxpress,
    assetValidation,
    assetValidationFields,
    requiresAssetValidation,
    isLoadingAssetValidation: !!loading,
    assetValidationInstructions,
    requiresValidationForAssetTypes: validateAssetTypes,
    assetValidationFailureInstructions,
  };

  return <Component {...allProps} />;
};

export default withCaseAssetValidation;

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const styles = ({ theme }) => `
  border-radius: ${theme.dimensions.borderRadius};
  max-width: ${px2rem(650)};
  width: 80%;
  margin: ${px2rem(50)} auto;
  background: ${theme.colors.base.background};
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      background: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dimensions: PropTypes.shape({
    borderRadius: PropTypes.string.isRequired,
  }).isRequired,
};

export default buildStyledComponent('Modal__Body', styled.div, styles, {
  themePropTypes,
});

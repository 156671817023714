import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text } from 'base-components';
import { px2rem, buildStyledComponent } from 'decisiv-ui-utils';

const styles = ({ theme }) => `
  color: ${theme.colors.base.chrome000};
  display: block;
  font-size: ${px2rem(32)};
  font-weight: ${theme.fontWeights.light};
  line-height: ${px2rem(38.4)};
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome000: PropTypes.string.isRequired,
    }),
  }),
  fontWeights: PropTypes.shape({
    light: PropTypes.number.isRequired,
  }).isRequired,
};

export default buildStyledComponent('Temperature', styled(Text), styles, {
  themePropTypes,
});

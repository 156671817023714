import React from 'react';
import PropTypes from 'prop-types';

import { Avatar, Text } from 'base-components';
import { Column, Row } from 'styled-components-grid';

import { formatPhone } from 'utils/format';

/**
 * Contact display used by both the Contacts panel and the after-hours
 * rotation event popover contact list.
 */
export default function ContactInfo({ contact }) {
  return (
    <>
      <Column modifiers="padScaleX_0">
        <Avatar
          name={contact.name}
          modifiers={['small', !contact.name ? 'secondary' : undefined]}
        />
      </Column>
      <Column modifiers={['col', 'padScaleX_2']}>
        <Row>
          <Text>
            {contact.name || <span>&mdash;</span>}
            {contact.jobTitle && (
              <>
                &nbsp;
                <Text
                  modifiers={['fontWeightLight', 'textLight']}
                  style={{ display: 'inline' }}
                >
                  ({contact.jobTitle})
                </Text>
              </>
            )}
          </Text>
        </Row>
        {contact.primaryPhoneNumber && (
          <Row>
            <Text modifiers={['small', 'textLight']}>
              {formatPhone(contact.primaryPhoneNumber)}
            </Text>
            {contact.secondaryPhoneNumber && (
              <>
                &nbsp;
                <Text modifiers="textLight">•</Text>
                &nbsp;
                <Text modifiers={['small', 'textLight']}>
                  {formatPhone(contact.secondaryPhoneNumber)}
                </Text>
              </>
            )}
          </Row>
        )}
      </Column>
    </>
  );
}

ContactInfo.propTypes = {
  contact: PropTypes.shape({
    name: PropTypes.string,
    jobTitle: PropTypes.string,
    primaryPhoneNumber: PropTypes.string,
    secondaryPhoneNumber: PropTypes.string,
  }),
};

ContactInfo.defaultProps = { contact: {} };

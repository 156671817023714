import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FontAwesome from 'react-fontawesome';
import { omit, reduce } from 'lodash';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

// TODO: we need to get iconix-names.js updated on each Iconix font rebuild
import iconNames from './iconix-names';

function UnstyledIcon(props) {
  const iconProps = omit(props, ['theme', 'modifiers']);
  if (iconNames.includes(props.name)) {
    return <Iconix {...iconProps} />;
  }
  return <FontAwesome {...iconProps} />;
}

UnstyledIcon.propTypes = {
  name: PropTypes.string.isRequired,
};

function Iconix({ name, className, ...rest }) {
  return <span className={`ix ix-${name} ${className}`} {...rest} />;
}

Iconix.propTypes = {
  className: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export const iconModifiers = {
  'Basic Colors': {
    chrome000: ({ theme }) => `color: ${theme.colors.base.chrome000};`,
    text: ({ theme }) => `color: ${theme.colors.base.text};`,
    textLight: ({ theme }) => `color: ${theme.colors.base.textLight};`,
  },
  'Status Colors': {
    info: ({ theme }) => `color: ${theme.colors.status.info};`,
    success: ({ theme }) => `color: ${theme.colors.status.success};`,
    warning: ({ theme }) => `color: ${theme.colors.status.warning};`,
    danger: ({ theme }) => `color: ${theme.colors.status.danger};`,
  },
  Sizes: {
    mini: () => `font-size: ${px2rem(12)};`,
    medium: () => `font-size: ${px2rem(32)};`,
    large: () => `font-size: ${px2rem(64)};`,
  },
  Other: {
    colorInherit: () => 'color: inherit;',
    disabled: () => 'opacity: 0.5;',
  },
};

const modifierConfig = reduce(
  iconModifiers,
  (result, modifiers) => ({ ...result, ...modifiers }),
  {},
);

const styles = ({ theme }) => `
  color: ${theme.colors.base.chrome600};
  font-size: ${px2rem(16)};
  &:before {
    color: inherit;
  }
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome000: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      textLight: PropTypes.string.isRequired,
    }).isRequired,
    status: PropTypes.shape({
      danger: PropTypes.string.isRequired,
      default: PropTypes.string.isRequired,
      info: PropTypes.string.isRequired,
      success: PropTypes.string.isRequired,
      warning: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const Icon = buildStyledComponent('Icon', styled(UnstyledIcon), styles, {
  modifierConfig,
  themePropTypes,
});

Icon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Icon;

import gql from 'graphql-tag';

const closedCaseDetailsQuery = gql`
  query closedCaseDetailsQuery(
    $caseNumber: String!
    $distanceUnit: DistanceUnit
  ) {
    case(caseNumber: $caseNumber) {
      id
      assetLocation {
        coordinates {
          latitude
          longitude
        }
        highway
        mileMarker
        note
        searchValue
        address {
          city
          country
          postalCode
          province
          streetAddress
        }
      }
      assetLocationWeatherAtDispatch {
        current {
          code
          summary
          temperature
          time
        }
      }
      dealerTravelEstimateAtDispatch {
        distance(unit: $distanceUnit)
        time
      }
      servicingDealer {
        id
        dealer {
          ... on Dealer {
            id
            dealerAfterHoursContacts {
              activeAfterHoursContacts {
                contacts {
                  backupContact {
                    id
                    jobTitle
                    name
                    primaryPhoneNumber
                    secondaryPhoneNumber
                  }
                  finalContact {
                    id
                    jobTitle
                    name
                    primaryPhoneNumber
                    secondaryPhoneNumber
                  }
                  primaryContact {
                    id
                    jobTitle
                    name
                    primaryPhoneNumber
                    secondaryPhoneNumber
                  }
                }
              }
            }
            displayAddress {
              streetAddress
              city
              province
              postalCode
            }
            ers247
            ersHours {
              end
              start
              weekDay
            }
            features {
              id
              code
            }
            location {
              latitude
              longitude
            }
            operatingStatus {
              ersOpen
              open
            }
            open247
            timezone
            workingHours {
              end
              start
              weekDay
            }
            name
            phone
            phoneAfterHours
            phoneAfterHours2
            billTo
            shipTo
            notes
          }
          ... on CustomDealer {
            id
            name
          }
        }
      }
    }
  }
`;

export default closedCaseDetailsQuery;

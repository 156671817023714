import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { get } from 'lodash';

import { Text, Label, H4 } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import RotationException from './RotationException';
import { EventPropType } from '../../propTypes';
import { weekdays } from './constants';
import { getRecurringDates } from './utils';

const labelsWidth = 70;

export default function RecurringDetails({
  rotation,
  dealerTimezone,
  deleteException,
}) {
  const { afterHoursEvent: event } = rotation;

  const { recurringStartDate, recurringEndDate } = getRecurringDates(event);

  const exceptionsLength = get(event, 'exceptionsAndOverrides.length', 0);

  return (
    <Container modifiers="padScaleX_0" style={{ paddingBottom: 0 }}>
      <Row style={{ margin: '10px 0' }}>
        <Column modifiers="padScaleX_0">
          <H4 modifiers="fontWeightRegular">
            <Trans>Recurring Schedule</Trans>
          </H4>
        </Column>
      </Row>
      <Row modifiers="middle">
        <Column modifiers="padScale_0" style={{ width: labelsWidth }}>
          <Label modifiers="textLight">
            <Trans>From</Trans>
          </Label>
        </Column>
        <Column modifiers={['col', 'padScaleY_0']}>
          <Text>{recurringStartDate}</Text>
        </Column>
      </Row>
      <Row modifiers="middle">
        <Column modifiers="padScale_0" style={{ width: labelsWidth }}>
          <Label modifiers="textLight">
            <Trans>To</Trans>
          </Label>
        </Column>
        <Column modifiers={['col', 'padScaleY_0']}>
          <Text>{recurringEndDate}</Text>
        </Column>
      </Row>
      <Row>
        <Column style={{ width: labelsWidth }} modifiers="padScale_0">
          <Label modifiers="textLight">
            <Trans>Repeat</Trans>
          </Label>
        </Column>
        <Column modifiers={['col', 'padScaleY_0']}>
          <Text>
            {event.recurring.map(({ dayOfWeek }, index) => (
              <Fragment key={dayOfWeek}>
                <Trans id={weekdays[dayOfWeek]} />
                {index < event.recurring.length - 1 && <span>, </span>}
              </Fragment>
            ))}
          </Text>
        </Column>
      </Row>

      {!!exceptionsLength && (
        <>
          <Row style={{ margin: '20px 0 10px' }}>
            <Column modifiers="padScale_0">
              <H4 modifiers="fontWeightRegular">
                <Trans>Exceptions ({exceptionsLength})</Trans>
              </H4>
            </Column>
          </Row>
          <Row style={{ overflow: 'auto', maxHeight: 155 }}>
            <Column modifiers={['col', 'padScale_0']}>
              {event.exceptionsAndOverrides.map((item) => (
                <RotationException
                  key={item.id}
                  event={item}
                  originalEvent={event}
                  timezone={dealerTimezone}
                  deleteException={() => deleteException(item, event)}
                />
              ))}
            </Column>
          </Row>
        </>
      )}
    </Container>
  );
}

RecurringDetails.propTypes = {
  // eslint-disable-next-line react/no-typos
  rotation: EventPropType.isRequired,
  dealerTimezone: PropTypes.string.isRequired,
  deleteException: PropTypes.func.isRequired,
};

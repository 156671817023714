import { useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';

const query = gql`
  query CustomerServicePreferencesQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      customer(useCachedDataForCase: true) {
        ... on Customer {
          id
          name
          type
          servicePreferences {
            driverAuthorized
            ersComments
            formattedErsComments
            tireDisposition
          }
        }
        ... on StoreCustomer {
          id
          name
          type
          servicePreferences {
            driverAuthorized
            ersComments
            formattedErsComments
            tireDisposition
          }
        }
        ... on CustomCustomer {
          type
          name
        }
        ... on CustomStoreCustomer {
          type
          name
        }
      }
    }
  }
`;

const useCustomerServicePreferences = ({ caseNumber, customer }) => {
  const customerId = customer?.id;

  const { data } = useQuery(query, {
    skip: !caseNumber || !customerId,
    variables: { caseNumber, customerId },
    fetchPolicy: 'cache-and-network',
  });

  const prefs = useMemo(() => {
    return data?.case?.customer?.servicePreferences || {};
  }, [data]);

  return prefs;
};

export default useCustomerServicePreferences;

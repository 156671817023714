import { compact } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import StyledLeftNav from '../../blocks/LeftNav';

import menuPropTypes from './menuPropTypes';

function evaluateMenuSubItemActive(currentPath, itemPath) {
  return currentPath && currentPath.endsWith(itemPath);
}

function MenuSubItem({ TextRenderer, isFlyoutOpen, location, menuSubItem }) {
  const isActive = evaluateMenuSubItemActive(
    location.pathname,
    menuSubItem.path,
  );

  return (
    <StyledLeftNav.MenuSubItem>
      {menuSubItem.url ? (
        <StyledLeftNav.MenuSubItemA
          aria-label={menuSubItem.message}
          href={menuSubItem.url}
          modifiers={compact([isActive && 'active', isFlyoutOpen && 'flyout'])}
          target="_blank"
        >
          <TextRenderer message={menuSubItem.message} />
        </StyledLeftNav.MenuSubItemA>
      ) : (
        <StyledLeftNav.MenuSubItemLink
          aria-label={menuSubItem.message}
          to={menuSubItem.path}
          modifiers={compact([isActive && 'active', isFlyoutOpen && 'flyout'])}
        >
          <TextRenderer message={menuSubItem.message} />
        </StyledLeftNav.MenuSubItemLink>
      )}
    </StyledLeftNav.MenuSubItem>
  );
}

MenuSubItem.propTypes = {
  TextRenderer: PropTypes.func.isRequired,
  isFlyoutOpen: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/no-typos
  menuSubItem: menuPropTypes.menuSubItem.isRequired,
};

MenuSubItem.defaultProps = {
  isFlyoutOpen: false,
};

export default MenuSubItem;

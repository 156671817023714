import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName, withState } from 'recompose';

import { QuickActionButton } from 'base-components';

import withContext from 'utils/withContext';

import { CaseStatusContext } from 'compositions/CaseStatus';

import CaseHeadsUpNoteModal from './CaseHeadsUpNoteModal';
import withCaseHeadsUpNote from './withCaseHeadsUpNote';

export function CreateHeadsUpNoteButton(props) {
  const { showModal, caseNumber, headsUpNote, toggleModal } = props;
  const { isFeatureEnabled, isSavingHeadsUpNote, isLoadingHeadsUpNote } = props;

  const disabled = [
    !!headsUpNote,
    isSavingHeadsUpNote,
    isLoadingHeadsUpNote,
    !isFeatureEnabled('manageHeadsUpNote'),
  ].some(Boolean);

  const openModal = () => toggleModal(true);
  const closeModal = () => toggleModal(false);

  return (
    <>
      <QuickActionButton
        onClick={openModal}
        disabled={disabled}
        modifiers={disabled && 'disabled'}
      >
        <QuickActionButton.Icon name="sticky-note-plus" modifiers="left" />
        <QuickActionButton.Text>
          <Trans>Create Heads-up</Trans>
        </QuickActionButton.Text>
      </QuickActionButton>
      {showModal && (
        <CaseHeadsUpNoteModal
          onSave={closeModal}
          onClose={closeModal}
          caseNumber={caseNumber}
        />
      )}
    </>
  );
}

CreateHeadsUpNoteButton.propTypes = {
  showModal: PropTypes.bool.isRequired,
  caseNumber: PropTypes.string.isRequired,
  headsUpNote: PropTypes.string,
  toggleModal: PropTypes.func.isRequired,
  isFeatureEnabled: PropTypes.func.isRequired,
  isSavingHeadsUpNote: PropTypes.bool.isRequired,
  isLoadingHeadsUpNote: PropTypes.bool.isRequired,
};

CreateHeadsUpNoteButton.defaultProps = {
  headsUpNote: null,
};

export default compose(
  setDisplayName('CreateHeadsUpNoteButton'),
  withCaseHeadsUpNote,
  withState('showModal', 'toggleModal', false),
  withContext(CaseStatusContext),
)(CreateHeadsUpNoteButton);

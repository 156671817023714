import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Trans } from '@lingui/macro';

import { Text } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import countOfTime from 'utils/countOfTime';
import ElapsedTime from 'components/ElapsedTime';
import { CASE_STATUS } from 'compositions/CaseStatus';

import HeaderCell from '../HeaderCell';
import { COLUMN_NAMES } from '../constants';
import { buildBaseColumnConfig } from '../utils';

const EXCLUDED_STATUSES = [
  CASE_STATUS.canceled,
  CASE_STATUS.closed,
  CASE_STATUS.rolling,
  CASE_STATUS.closed_canceled,
];

function TimeSummariesCell({ assetDowntimeMs, createdAt, status: caseStatus }) {
  const displayStaticTime = EXCLUDED_STATUSES.includes(caseStatus);

  const LiveDowntime = () => <ElapsedTime inline sinceTime={createdAt} />;

  const StaticDowntime = () => (
    <span>{countOfTime(moment.duration(assetDowntimeMs, 'milliseconds'))}</span>
  );

  const DowntimeDisplay = displayStaticTime ? StaticDowntime : LiveDowntime;

  return (
    <Container modifiers="padScaleY_2">
      <Row>
        <Column modifiers={['col', 'padScaleY_0']}>
          <Text>
            <DowntimeDisplay />
          </Text>
        </Column>
      </Row>
      <Row>
        <Column modifiers={['col', 'padScaleY_0']}>
          <Text modifiers={['small', 'textLight']}>
            {moment(createdAt).format('D MMM, h:mm A z')}
          </Text>
        </Column>
      </Row>
    </Container>
  );
}

TimeSummariesCell.propTypes = {
  assetDowntimeMs: PropTypes.number,
  createdAt: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

TimeSummariesCell.defaultProps = {
  assetDowntimeMs: undefined,
};

export default (...args) => ({
  ...buildBaseColumnConfig(COLUMN_NAMES.timeSummaries, ...args),
  headerCellRenderer: (props) => (
    <HeaderCell {...props} label={<Trans>Downtime/Start Time</Trans>} />
  ),
  dataCellRenderer: (data) => <TimeSummariesCell {...data} />,
});

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import { NavLink } from '../../elements';

export const modifierConfig = {
  active: ({ theme }) => `
    background: ${theme.colors.base.chrome000};
    border-left: 2px solid ${theme.colors.brand.primary};
  `,

  flyout: () => `
    padding-left: ${px2rem(10)};
  `,
};

export const styles = ({ theme }) => `
  align-items: center;
  border-left: 2px solid transparent;
  color: ${theme.colors.base.textLight};
  display: flex;
  font-size: ${px2rem(10)};
  height: 100%;
  line-height: ${px2rem(16)};
  min-height: ${px2rem(30)};
  padding-left: ${px2rem(48)};
  text-decoration: none;

  &:hover {
    background: ${theme.colors.base.chrome000};
    text-decoration: underline;
  }

  span {
    margin: ${px2rem(4)} ${px2rem(8)} ${px2rem(4)} 0;
  }
`;

export const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome000: PropTypes.string.isRequired,
      textLight: PropTypes.string.isRequired,
    }).isRequired,
    brand: PropTypes.shape({
      primary: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default buildStyledComponent(
  'MenuSubItemLink',
  styled(NavLink),
  styles,
  { modifierConfig, themePropTypes },
);

import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName } from 'recompose';

import { reportTypes } from 'pages/Reports/constants';

import CustomFilters from './Filters';
import ReportConfigBuilder from '../ReportConfigBuilder';

import withInboundPrograms from './withInboundPrograms';
import { initialConfig } from './constants';

const AssetValidationReport = (props) => (
  <ReportConfigBuilder
    metadata={{ inboundPrograms: props.inboundProgramsValues }}
    reportType={reportTypes.assetValidationReport}
    initialConfig={initialConfig}
    showBillableStatus={false}
  >
    <ReportConfigBuilder.Header />
    <CustomFilters {...props} />
    <ReportConfigBuilder.Footer />
  </ReportConfigBuilder>
);

AssetValidationReport.propTypes = {
  inboundProgramsValues: PropTypes.shape({}).isRequired,
};

export default compose(
  setDisplayName('AssetValidationReport'),
  withInboundPrograms,
)(AssetValidationReport);

import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    html {
      font-size: ${({ theme }) => theme.dimensions.fontSize}px;
      height: 100%;
    }
    body {
      height: 100%;
      font-family: ${({ theme }) => theme.fonts.primaryFallbacks};
      background-color: ${({ theme }) => theme.colors.base.background};
      color: ${({ theme }) => theme.colors.base.text};
      button,
      input,
      optgroup,
      select,
      textarea {
        font-family: ${({ theme }) => theme.fonts.primaryFallbacks};
        outline: none;
      }
      &.fontsLoaded {
        font-family: ${({ theme }) => theme.fonts.primary};
        button,
        input,
        optgroup,
        select,
        textarea {
          font-family: ${({ theme }) => theme.fonts.primary};
        }
      }
      ::-moz-placeholder {
        font-style: italic;
        color: ${({ theme }) => theme.colors.base.chrome500};
      }
      :-ms-input-placeholder {
        font-style: italic;
        color: ${({ theme }) => theme.colors.base.chrome500};
      }
      input:-moz-placeholder {
        font-style: italic;
        color: ${({ theme }) => theme.colors.base.chrome500};
      }
      ::-webkit-input-placeholder {
        font-style: italic;
        color: ${({ theme }) => theme.colors.base.chrome500};
      }
      #root {
        height: 100%;
        > * {
          height: 100%;
        }
      }
    }
`;

export default GlobalStyles;

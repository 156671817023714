import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const modifierConfig = {
  expanded: () => `
    overflow-x: hidden;
    overflow-y: auto;
  `,
};

const styles = () => `
  -ms-overflow-style: -ms-autohiding-scrollbar;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: ${px2rem(45)};
`;

export default buildStyledComponent('MenuBlock', styled.div, styles, {
  modifierConfig,
});

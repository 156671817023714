/* eslint-disable react/prop-types */
import React from 'react';
import { Trans, Plural } from '@lingui/macro';

import { Row, Column } from 'styled-components-grid';
import { MessageSmall, Text } from 'base-components';

import ResetButton from '../ResetButton';
import LineCheckbox from '../LineCheckbox';
import BaseRequestLinesTable from '../BaseRequestLinesTable';
import AgreementLineActionCell from './AgreementLineActionCell';

import {
  getRowLocation,
  isRequestLineValid,
  getTotalGenericTireOptionSelected,
} from '../utils';

const buildAdditionalColumns = (props) => {
  const {
    resetRequestAgreementLines,
    shouldDisplayAgreementTableReset,
    toggleRequestAgreementLineStatus,
    anyRequestAgreementLineHasChanges,
  } = props;

  return [
    {
      name: 'agree',
      width: '5.5%',
      renderer: (data) => {
        const { rowData, isReadOnlyCase, tableData } = data;
        const { id, agreed, valid, isReadOnly } = rowData; // eslint-disable-line no-unused-vars

        // TODO: Uncomment when we want to enable readonly after dispatch
        // const readOnly = isReadOnly || isReadOnlyCase;
        const readOnly = isReadOnlyCase;
        let isDisabled = !readOnly && !agreed && !valid;
        let tooltip = '';

        if (valid && !agreed) {
          const rowLocation = getRowLocation(rowData);
          const positionAlreadyTaken = tableData
            .filter((row) => row.id !== rowData.id && !!row.agreed)
            .some((row) => getRowLocation(row) === rowLocation);

          if (positionAlreadyTaken) {
            isDisabled = true;
            tooltip = (
              <Trans>
                A row for this Unit, Position and Type has already been marked
                as &quot;agreed&quot;.
              </Trans>
            );
          }
        }

        return (
          <LineCheckbox
            id={id}
            tooltip={tooltip}
            onChange={toggleRequestAgreementLineStatus}
            isChecked={!!agreed}
            isDisabled={isDisabled}
            isReadOnly={!!readOnly}
            focusReceiverId={`request.lines.agreed.${id}.agreed`}
          />
        );
      },

      headerRenderer: () => (
        <Row modifiers={['padScale_0', 'center']}>
          <Text modifiers={['small', 'textLight']}>
            <Trans>Agree</Trans>
          </Text>
        </Row>
      ),
    },
    {
      name: 'reset',
      width: '4.29%',

      renderer: ({ rowData, ...rest }) => {
        if (rowData.readOnly) return <div />;

        return <AgreementLineActionCell {...{ ...rest, ...props, rowData }} />;
      },

      headerRenderer: ({ isReadOnlyCase }) => {
        const isDisabled = !anyRequestAgreementLineHasChanges();

        if (shouldDisplayAgreementTableReset() && !isReadOnlyCase) {
          return (
            <ResetButton
              label={<Trans>Reset Table</Trans>}
              onClick={() => resetRequestAgreementLines()}
              disabled={isDisabled}
              modifiers={isDisabled && 'disabled'}
            />
          );
        }

        return (
          <Row>
            <Column modifiers="col" />
          </Row>
        );
      },
    },
  ];
};

const flattenLine = (line, { isReadOnlyCase }) => {
  const { agreed, agreementLine, requestLineId } = line;
  // TODO: Uncomment when we want to enable readonly after dispatch
  // const readOnly = agreed || agreementLine.isReadOnly || isReadOnlyCase;
  const readOnly = agreed || isReadOnlyCase;

  return { agreed, readOnly, requestLineId, ...agreementLine };
};

const addIsValid = (line, allLines, props) => {
  const valid = line.agreed ? true : isRequestLineValid(line, allLines, props);

  return { ...line, valid };
};

function mapPropsToAgreedLinesTableProps(props) {
  const requestLines = props.requestAgreementLines
    .map((l) => flattenLine(l, props))
    .map((l, _, allLines) => addIsValid(l, allLines, props));

  return {
    ...props,
    requestLines,
    shouldHeadUpdate: () => true,
    additionalColumns: buildAdditionalColumns(props),
    updateRequestLine: props.updateRequestAgreementLine,
    allowGenericTireOptionsSelection: false,
    rowsType: 'agreed',
  };
}

function AgreedRequestLinesTable(props) {
  const tableProps = mapPropsToAgreedLinesTableProps(props);
  const uncheckedLines = tableProps.requestLines.filter((l) => !l.agreed);

  const totalGenericTireOptionSelected = getTotalGenericTireOptionSelected(
    uncheckedLines,
    tableProps.requestLines,
    props,
  );

  return (
    <>
      <Row>
        <Column modifiers={['col', 'padScale_0']}>
          <BaseRequestLinesTable {...tableProps} />
        </Column>
      </Row>
      {!!totalGenericTireOptionSelected && (
        <Row>
          <Column modifiers={['col', 'padScaleX_0', 'padScaleY_3']}>
            <MessageSmall type="warning">
              <Plural
                value={totalGenericTireOptionSelected}
                one="Select missing product."
                other="Select missing products."
              />
            </MessageSmall>
          </Column>
        </Row>
      )}
    </>
  );
}

export default AgreedRequestLinesTable;

import styled from 'styled-components';
import { buildStyledComponent } from 'decisiv-ui-utils';

import Text from './Text';

const styles = `
  display: flex;
  height: 100%;
  justify-content: space-between;
`;

const TopNav = buildStyledComponent('TopNav', styled.nav, styles);

TopNav.Text = Text;

export default TopNav;

import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import Cases from 'pages/Cases';

import { USER_ROLES, withUserRole } from 'features/rbac';

function HomePageRouter({ role, ...rest }) {
  if (USER_ROLES.CALL_CENTER.includes(USER_ROLES[role])) {
    return <Cases {...rest} />;
  }

  if (USER_ROLES.TECH_ADMIN.includes(USER_ROLES[role])) {
    return <Redirect to="/tech-admin/dealers" />;
  }

  return null;
}

HomePageRouter.propTypes = {
  role: PropTypes.string.isRequired,
};

export default withUserRole(HomePageRouter);

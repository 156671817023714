import { compose } from 'recompose';

import withAllInboundPrograms from './withAllInboundPrograms';
import withCallContactAttributes from './withCallContactAttributes';
import withMatchingInboundProgram from './withMatchingInboundProgram';

export default compose(
  withAllInboundPrograms,
  withCallContactAttributes,
  withMatchingInboundProgram,
);

import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import { Text } from 'base-components';
import { Row, Column } from 'styled-components-grid';

import TravelEstimate from 'compositions/TravelEstimate';
import { CASE_STATUS } from 'compositions/CaseStatus';

import DealerName from './DealerName';
import DealerResponseStatusIndicator from './DealerResponseStatusIndicator';

import { getDealerResponseStatusIndicatorProps } from './utils';

function DealerDisplay({
  dealer,
  responses,
  caseStatus,
  isReadOnlyCase,
  isServicingDealer,
  isDispatchedStatus,
  servicingDealerTravelEstimateAtDispatch,
}) {
  const {
    displayAddress: { city, postalCode, province, streetAddress },
    travelEstimate,
    shipTo,
    billTo,
    storeNumber,
  } = dealer;

  const canDisplayAddress = city || postalCode || province || streetAddress;
  const isShowingReadOnlyAssetLocation =
    caseStatus === CASE_STATUS.closed ||
    caseStatus === CASE_STATUS.closed_canceled ||
    isReadOnlyCase;

  const useAtDispatchEstimates =
    !!isServicingDealer &&
    !!servicingDealerTravelEstimateAtDispatch &&
    (isDispatchedStatus || isShowingReadOnlyAssetLocation);

  const { distance, time } = useAtDispatchEstimates
    ? servicingDealerTravelEstimateAtDispatch
    : travelEstimate || {};

  return (
    <Row modifiers="padScaleY_3">
      <Column modifiers="padScale_0">
        <DealerResponseStatusIndicator
          {...getDealerResponseStatusIndicatorProps({
            isServicingDealer,
            responses,
          })}
        />
      </Column>
      <Column modifiers={['col', 'padScaleX_2', 'padScaleY_0']}>
        <Row>
          <Column modifiers="padScale_0">
            <DealerName>{dealer.name}</DealerName>
          </Column>
        </Row>
        <Row>
          <Column modifiers="padScale_0">
            {canDisplayAddress && (
              <Text modifiers="small">
                {streetAddress} {streetAddress && <>&nbsp;</>}
                {city}
                {(streetAddress || city) && ', '}
                {province} {province && <>&nbsp;</>}
                {postalCode}
              </Text>
            )}
            {canDisplayAddress && (billTo || shipTo || storeNumber) && (
              <Text modifiers="small">&nbsp;&middot;&nbsp;</Text>
            )}
            {billTo && (
              <Text modifiers="small">
                <Trans>Bill to #{billTo}</Trans>
              </Text>
            )}
            {billTo && shipTo && (
              <Text modifiers="small">&nbsp;&middot;&nbsp;</Text>
            )}
            {shipTo && (
              <Text modifiers="small">
                <Trans>Ship to #{shipTo}</Trans>
              </Text>
            )}
            {(billTo || shipTo) && (
              <Text modifiers="small">&nbsp;&middot;&nbsp;</Text>
            )}
            {storeNumber && (
              <Text modifiers="small">
                <Trans>Location #{storeNumber}</Trans>
              </Text>
            )}
          </Column>
        </Row>
      </Column>
      <Column modifiers="padScale_0">
        {distance && time && (
          <TravelEstimate distance={distance} duration={time} />
        )}
      </Column>
    </Row>
  );
}

DealerDisplay.propTypes = {
  dealer: PropTypes.shape().isRequired,
  responses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  caseStatus: PropTypes.string.isRequired,
  isReadOnlyCase: PropTypes.bool.isRequired,
  isServicingDealer: PropTypes.bool.isRequired,
  isDispatchedStatus: PropTypes.bool.isRequired,
  servicingDealerTravelEstimateAtDispatch: PropTypes.shape({
    distance: PropTypes.number,
    time: PropTypes.number,
  }),
};

DealerDisplay.defaultProps = {
  servicingDealerTravelEstimateAtDispatch: {},
};

export default DealerDisplay;

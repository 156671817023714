import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import GhostAnimation from './GhostAnimation';

const modifierConfig = {
  chrome200: ({ theme }) => ({
    styles: `
      background: linear-gradient(90deg, ${theme.colors.base.chrome200} 0%,
      ${theme.colors.base.chrome300} 16.42%,
      ${theme.colors.base.chrome200} 100%);
    `,
  }),

  expanded: () => ({
    styles: `
      height: ${px2rem(24)};
      margin: ${px2rem(12)} ${px2rem(6)};
    `,
  }),

  gapBottom: () => ({
    styles: `margin-bottom: ${px2rem(30)};`,
  }),

  gapRight: () => ({
    styles: `margin-right: ${px2rem(12)};`,
  }),

  smallWidth: () => ({
    styles: `
      flex: none;
      width: ${px2rem(20)};
    `,
  }),

  wide: () => ({
    styles: `
      width: 80%;
    `,
  }),
};

/* istanbul ignore next */
const styles = ({ theme }) => css`
  animation: ${GhostAnimation} 2s ease infinite;
  background-size: 600% 600% !important;
  background: linear-gradient(
    90deg,
    ${theme.colors.base.chrome100} 0%,
    ${theme.colors.base.chrome200} 24.85%,
    ${theme.colors.base.chrome100} 100%
  );
  border-radius: ${theme.dimensions.borderRadius};
  height: ${px2rem(20)};
  margin: ${px2rem(8)} ${px2rem(2)};
  flex: 1;
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome100: PropTypes.string.isRequired,
      chrome200: PropTypes.string.isRequired,
      chrome300: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dimensions: PropTypes.shape({
    borderRadius: PropTypes.string.isRequired,
  }).isRequired,
};

export default buildStyledComponent('GhostIndicator', styled.div, styles, {
  modifierConfig,
  themePropTypes,
});

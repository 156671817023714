import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Table from '../../blocks/Table';

class DataTableHead extends Component {
  static propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    cellRenderer: PropTypes.func.isRequired,
    rowModifiers: PropTypes.arrayOf(PropTypes.string),
    shouldUpdate: PropTypes.func.isRequired,
  };

  static defaultProps = {
    rowModifiers: [],
  };

  shouldComponentUpdate(nextProps) {
    return nextProps.shouldUpdate(this.props, nextProps);
  }

  render() {
    const { columns, rowModifiers, cellRenderer } = this.props;

    return (
      <Table.Head modifiers={[]}>
        <Table.Row modifiers={rowModifiers}>
          {columns.map(cellRenderer)}
        </Table.Row>
      </Table.Head>
    );
  }
}

export default DataTableHead;

import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { Column } from 'styled-components-grid';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import { modifiersForComponent } from '../../utils/modifiers';
import {
  TextArea as DefaultTextArea,
  AutoSizingTextArea,
} from '../../elements';

const TextAreaContainerColumn = buildStyledComponent(
  'TextAreaContainerColumn',
  styled(Column),
  css`
    bottom: ${px2rem(2)};
  `,
);

const TextArea = forwardRef((props, ref) => {
  const { modifiers, readOnly, autosize, ...rest } = props;

  const columnModifiers = [
    'col',
    'padScaleY_0',
    ...modifiersForComponent(modifiers, Column),
  ];

  const textAreaModifiers = modifiersForComponent(
    [...modifiers, readOnly && 'readOnly'],
    DefaultTextArea,
  );

  const Input = autosize ? AutoSizingTextArea : DefaultTextArea;

  return (
    <TextAreaContainerColumn modifiers={columnModifiers}>
      <Input
        ref={ref}
        modifiers={textAreaModifiers}
        {...rest}
        readOnly={readOnly}
      />
    </TextAreaContainerColumn>
  );
});

TextArea.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
  readOnly: PropTypes.bool.isRequired,
  autosize: PropTypes.bool,
};

TextArea.defaultProps = {
  modifiers: [],
  autosize: false,
};

export default TextArea;

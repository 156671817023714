import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import { get } from 'lodash';
import moment from 'moment-timezone';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import { rgbaToRgb } from 'utils/colors';

import { AFTER_HOURS_COLOR_KEY, OPEN_HOURS_COLOR_KEY } from '../constants';
import ScheduleGrid from '../ScheduleGrid';

import EventBlockText from './EventBlockText';
import { formatEventTime } from '../helpers';

/* istanbul ignore next */
const eventWrapperModifiers = {
  openHours: ({ theme }) => `
    background: ${rgbaToRgb(
      get(theme, ['colors', 'status', `${OPEN_HOURS_COLOR_KEY}Light`]),
    )};
    border-color: ${get(theme, ['colors', 'status', OPEN_HOURS_COLOR_KEY])};
  `,
  ersHours: ({ theme, halfWidth }) => `
    background: ${rgbaToRgb(
      get(theme, ['colors', 'status', `${AFTER_HOURS_COLOR_KEY}Light`]),
    )};
    border-color: ${get(theme, ['colors', 'status', AFTER_HOURS_COLOR_KEY])};
    left: ${halfWidth ? 'calc(50% - 1px)' : '1px'};
    width: calc(${halfWidth ? '50% - 1px' : '100% - 3px'});
  `,
};

/**
 * Wrapper for an event block, with colors and positioning defaults. Extends
 * the generic Schedule Grid block component.
 */
const EventWrapper = buildStyledComponent(
  'ScheduleGrid__Event__Wrapper',
  ScheduleGrid.Block,
  ({ theme, halfWidth }) => `
    border-left-width: ${theme.dimensions.borderRadius};
    border-left-style: solid;
    margin: ${px2rem(1)};
    width: calc(${halfWidth ? '50' : '100'}% - 3px);

    &.highlighted::after {
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      background: rgba(0, 187, 255, 0.2);
      pointer-events: none;
    }
  `,
  {
    modifierConfig: eventWrapperModifiers,
    themePropTypes: {
      colors: PropTypes.shape({
        status: PropTypes.shape({
          [OPEN_HOURS_COLOR_KEY]: PropTypes.string.isRequired,
          [`${AFTER_HOURS_COLOR_KEY}Light`]: PropTypes.string.isRequired,
          [AFTER_HOURS_COLOR_KEY]: PropTypes.string.isRequired,
          [`${AFTER_HOURS_COLOR_KEY}Light`]: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
      dimensions: PropTypes.shape({
        borderRadius: PropTypes.string.isRequired,
      }).isRequired,
    },
  },
);

/* istanbul ignore next */
const eventBodyModifiers = {
  content: ({ theme }) => `
    overflow: hidden;
    text-overflow: ellipsis;
    top: ${theme.dimensions.gridPad};
  `,
};

/**
 * Div within the event wrapper which truncates all event block content
 * to fit within the padding of the event wrapper.
 */
const EventBody = buildStyledComponent(
  'ScheduleGrid__Event__Body',
  styled.div,
  ({ theme }) => `
    position: absolute;
    left: ${theme.dimensions.gridPad};
    top: ${px2rem(-5)};
    right: ${theme.dimensions.gridPad};
    bottom: ${theme.dimensions.gridPad};
  `,
  {
    modifierConfig: eventBodyModifiers,
    themePropTypes: {
      dimensions: PropTypes.shape({
        gridPad: PropTypes.string.isRequired,
      }).isRequired,
    },
  },
);

const getEventsById = (id) =>
  Array.from(document.querySelectorAll(`[data-event-id="${id}"]`));

/**
 * A scheduled block of time in the calendar grid. May be an "ERS Hours" event
 * or an "Open Hours" event.
 *
 * @param {Object} props
 * @param {Object} props.event - the event data
 * @param {Moment|string} props.day - the date the event is being rendered into
 * @param {string} type - the event type modifier (`ersHours` or `openHours`)
 * @return {ReactElement}
 */
export default function ScheduledEvent(props) {
  const { id, day, event, type } = props;
  const { children, bodyWrapper } = props;

  const duration = moment(event.blockEnd).diff(event.blockStart, 'minutes');
  const timeSpanModifiers = duration <= 60 && 'inline';
  const bodyModifiers = duration > 30 && 'content';

  const highlight = () =>
    getEventsById(id).forEach((e) => e.classList.add('highlighted'));

  const unhighlight = () =>
    getEventsById(id).forEach((e) => e.classList.remove('highlighted'));

  return (
    <ScheduleGrid.BlockPositioner day={day} event={event}>
      {({ top, height }) => (
        <EventWrapper
          style={{ top: `${top}px`, height: `${height - 2}px` }}
          modifiers={[type]}
          halfWidth={event.overlap}
          onMouseEnter={highlight}
          onMouseLeave={unhighlight}
          data-event-id={id}
        >
          {bodyWrapper(
            <EventBody modifiers={bodyModifiers}>
              <EventBlockText modifiers={timeSpanModifiers}>
                {duration <= 30
                  ? '...'
                  : `${formatEventTime(event.start)} – ${formatEventTime(
                      event.end,
                    )}`}
              </EventBlockText>
              {children}
            </EventBody>,
          )}
        </EventWrapper>
      )}
    </ScheduleGrid.BlockPositioner>
  );
}

ScheduledEvent.propTypes = {
  id: PropTypes.string,
  event: PropTypes.shape({
    blockStart: MomentPropTypes.momentObj.isRequired,
    blockEnd: MomentPropTypes.momentObj.isRequired,
    start: PropTypes.oneOfType([
      MomentPropTypes.momentString,
      MomentPropTypes.momentObj,
    ]).isRequired,
    end: PropTypes.oneOfType([
      MomentPropTypes.momentString,
      MomentPropTypes.momentObj,
    ]).isRequired,
    overlap: PropTypes.bool,
  }).isRequired,
  day: PropTypes.oneOfType([
    MomentPropTypes.momentString,
    MomentPropTypes.momentObj,
  ]).isRequired,
  type: PropTypes.oneOf(Object.keys(eventWrapperModifiers)).isRequired,
  children: PropTypes.node.isRequired,
  bodyWrapper: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

ScheduledEvent.defaultProps = {
  id: undefined,
  bodyWrapper: (args) => args,
};

import React from 'react';
import { curry, get } from 'lodash';
import { Query } from '@apollo/client/react/components';

import CustomerWidgetContext from '../../CustomerWidgetContext';
import caseCustomerAccountQuery from './caseCustomerAccountQuery';

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => (
    <WrappedComponent
      {...componentProps}
      account={get(queryProps, 'data.case.customer') || {}}
    />
  ),
);

function buildWrappedComponentWithQuery(WrappedComponent, componentProps) {
  const { caseNumber, customerId } = componentProps;

  return (
    <Query
      query={caseCustomerAccountQuery}
      skip={!caseNumber || !customerId}
      /*
       * For LBB customers, the API sometimes fails to fetch the related dealer
       * information and throws an error. Here we can safely ignore that error
       * and still display the remaining information we do get back.
       *
       * TODO: remove this once the API works as expected.
       */
      errorPolicy="all"
      /**
       * Pass the customer id, even though it is not used in the query,
       * so that Apollo thinks something has changed and queries new data,
       * but still uses the cache when possible.
       */
      variables={{ caseNumber, customerId }}
      fetchPolicy="cache-and-network"
    >
      {buildQueryChild(WrappedComponent, componentProps)}
    </Query>
  );
}

export const buildConsumerChild = curry(
  (WrappedComponent, componentProps, consumerProps) => {
    const { caseNumber } = consumerProps;

    return buildWrappedComponentWithQuery(WrappedComponent, {
      ...componentProps,
      caseNumber,
      customerId: get(componentProps, 'customer.id'),
    });
  },
);

const withCustomerAccount = (WrappedComponent) => (componentProps) => (
  <CustomerWidgetContext.Consumer>
    {buildConsumerChild(WrappedComponent, componentProps)}
  </CustomerWidgetContext.Consumer>
);

export default withCustomerAccount;

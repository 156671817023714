import React, { Fragment } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { groupBy, map } from 'lodash';

import { H4 } from 'base-components';
import { Column, Container, Row } from 'styled-components-grid';

import Note from './Note';
import { formatGroupDate } from '../../utils';

export const Entry = ({ entry, ...rest }) => (
  <Note {...rest} key={entry.id} note={entry} />
);

Entry.propTypes = {
  entry: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export function NotesPanel({ notes }) {
  const groupedNotes = groupBy(notes, (n) =>
    moment(n.node.createdAt).startOf('day').format(),
  );

  return (
    <Container>
      {map(groupedNotes, (oneDaysNotes, date) => (
        <Fragment key={date}>
          <Row>
            <Column modifiers="col">
              <H4 modifiers="fontWeightLight">{formatGroupDate(date)}</H4>
            </Column>
          </Row>
          {map(oneDaysNotes, (note) => (
            <Entry key={note.node.id} entry={note.node} />
          ))}
        </Fragment>
      ))}
      {/* FIXME: implement "load more" */}
      {/*
        <Row modifiers={['padScaleY_2', 'center']}>
          <Column>
            <QuickActionButton
              disabled
              modifiers={compact(['disabled', 'hoverSuccess'])}
              onClick={() => console.log('load more notes')}
            >
              <QuickActionButton.Text>
                <Trans>Load More</Trans>
              </QuickActionButton.Text>
            </QuickActionButton>
          </Column>
        </Row>
      */}
    </Container>
  );
}

NotesPanel.propTypes = {
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        createdAt: PropTypes.string.isRequired,
      }),
    }).isRequired,
  ).isRequired,
};

export default NotesPanel;

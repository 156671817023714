import { compose, renameProp, setDisplayName } from 'recompose';

import withUserRole from '../withUserRole';
import AuthCheck from './AuthCheck';

/**
 * Render a component tree only if the current user role
 * is NOT one of the specified `roles`.
 *
 * This is a wrapped version of {@link AuthCheck} to
 * automatically provide the `role` and `loadingRole`
 * props via {@link withUserRole}. The roles that must
 * not match should be specified via a `roles` prop.
 * The `roles` will be given to `AuthCheck` as `excludedRoles`.
 *
 * @example
 *   <div>
 *     <UnlessRolesMatch
 *       roles={['foo', 'bar']}
 *       unauthorized={<p>Shown if roles DO match...</p>}
 *     >
 *       <ShownOnlyIfWeDontHaveFooOrBarRole />
 *     </UnlessRolesMatch>
 *   </div>
 */
const UnlessRolesMatch = compose(
  setDisplayName('UnlessRolesMatch'),
  renameProp('roles', 'excludedRoles'),
  withUserRole,
)(AuthCheck);
export default UnlessRolesMatch;

import gql from 'graphql-tag';

export default gql`
  mutation updateTerminalLocation(
    $caseId: ID!
    $customerTerminalLocation: String!
  ) {
    updateCase(
      input: {
        id: $caseId
        patch: { customerTerminalLocation: $customerTerminalLocation }
      }
    ) {
      case {
        id
        customerTerminalLocation
      }
    }
  }
`;

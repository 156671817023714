import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import { spacing } from '../../utils/modifiers';

const modifierConfig = {
  ...spacing,
  readOnly: () => ({
    styles: css`
      background: inherit;
      pointer-events: none;
    `,
  }),
};

export const styles = ({ theme }) => css`
  border: none;
  color: ${theme.colors.base.text};
  font-size: ${px2rem(12)};
  line-height: ${px2rem(20)};
  outline: none;
  padding: 0;
  width: 100%;

  &::placeholder {
    color: ${theme.colors.base.chrome300};
  }
`;

export const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome300: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export { modifierConfig };

export default buildStyledComponent('TextField', styled.input, styles, {
  modifierConfig,
  themePropTypes,
});

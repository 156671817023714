import styled from 'styled-components';
import { buildStyledComponent } from 'decisiv-ui-utils';

import List from './List';
import LocalTime from './LocalTime';
import OperatingStatus from './OperatingStatus';
import Summary from './Summary';
import UtcTime from './UtcTime';

const HoursDetail = buildStyledComponent('HoursDetail', styled.div);

HoursDetail.List = List;
HoursDetail.LocalTime = LocalTime;
HoursDetail.OperatingStatus = OperatingStatus;
HoursDetail.Summary = Summary;
HoursDetail.UtcTime = UtcTime;

export default HoursDetail;

import React, { useRef } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { compose, setDisplayName } from 'recompose';

import withContext from 'utils/withContext';
import RelativeNow from 'components/RelativeNow';
import { CaseStatusContext } from 'compositions/CaseStatus';

import InactivityNoticeModal from './InactivityNoticeModal';
import useIsUserInactiveInCase from './useIsUserInactiveInCase';
import withResetInactivityTimer from './withResetInactivityTimer';

const noticeDurationBudget = moment.duration({ minutes: 2 });

function UserInactivityCheck(props) {
  const { caseNumber, caseAssignedToCurrentUser, resetInactivityTimer } = props;

  const inactiveAt = useRef(null);
  const isUserInactive = useIsUserInactiveInCase(caseNumber);

  if (!caseAssignedToCurrentUser || !isUserInactive) return null;
  if (!inactiveAt.current) inactiveAt.current = moment();

  return (
    <RelativeNow
      timezone={moment.tz.guess()}
      render={({ relativeNow }) => {
        const noticeRemainingDuration = noticeDurationBudget
          .clone()
          .subtract(moment.duration(relativeNow.diff(inactiveAt.current)));

        if (noticeRemainingDuration.asMilliseconds() <= 0) {
          return <Redirect to={`/?timedout-case=${caseNumber}`} />;
        }

        return isUserInactive ? (
          <InactivityNoticeModal
            caseNumber={caseNumber}
            onResetTimer={resetInactivityTimer}
            remainingDuration={noticeRemainingDuration}
          />
        ) : null;
      }}
    />
  );
}

UserInactivityCheck.propTypes = {
  caseNumber: PropTypes.string.isRequired,
  resetInactivityTimer: PropTypes.func.isRequired,
  caseAssignedToCurrentUser: PropTypes.bool.isRequired,
};

export default compose(
  setDisplayName('UserInactivityCheck'),
  withContext(CaseStatusContext),
  withResetInactivityTimer,
)(UserInactivityCheck);

import React from 'react';
import PropTypes from 'prop-types';

import { Row, Column } from 'styled-components-grid';
import { ButtonLink, Icon } from 'base-components';

/**
 * A `ButtonLink` that includes an icon to the left of the text.
 * The children of this should be the link text.
 */
export default function ButtonLinkWithIcon({
  icon,
  onClick,
  children,
  iconModifiers,
  ...rest
}) {
  return (
    <ButtonLink modifiers={['small']} onClick={onClick} {...rest}>
      <Row modifiers={['middle']}>
        {icon && (
          <Column modifiers={['padScaleX_0']}>
            <Icon name={icon} modifiers={['colorInherit', ...iconModifiers]} />
          </Column>
        )}
        {children && (
          <Column modifiers={[icon ? 'padScaleX_2' : 'padScaleX_0']}>
            {children}
          </Column>
        )}
      </Row>
    </ButtonLink>
  );
}

ButtonLinkWithIcon.propTypes = {
  /** Function to call when the link is clicked */
  onClick: PropTypes.func,
  /** The name of the Icon to render */
  icon: PropTypes.string,
  /** The body (text) of the link */
  children: PropTypes.node,
  /** Modifiers to pass to the Icon */
  iconModifiers: PropTypes.arrayOf(PropTypes.string),
};

ButtonLinkWithIcon.defaultProps = {
  icon: undefined,
  onClick: undefined,
  children: undefined,
  iconModifiers: [],
};

import { buildStyledComponent } from 'decisiv-ui-utils';

import { Text } from 'base-components';

const modifierConfig = {
  textWhite: () => 'color: white;',
};

const styles = `
  text-transform: uppercase;
`;

export default buildStyledComponent('CaseCancelButton_Text', Text, styles, {
  modifierConfig,
});

import { get, find } from 'lodash';

import { CASE_STATUS } from 'compositions/CaseStatus';

export default ({ status, statusHistory, createdAt: caseCreatedAt }) => {
  // Reverse the history because there might be multiple transitions of the same
  // type in the array. By starting from the end, we use the latest transition.
  // TODO: confirm this makes sense.
  const reversedHistory = [...statusHistory].reverse();

  const createdAt = get(
    find(reversedHistory, { newStatus: status }),
    'changedAt',
  );

  const { changedAt: completedAt, id: caseStatusChangeId } =
    find(reversedHistory, { oldStatus: status }) || {};

  return {
    caseStatusChangeId,
    completedAt,
    createdAt: status === CASE_STATUS.new ? caseCreatedAt : createdAt,
  };
};

import PropTypes from 'prop-types';
import React, { Component, createContext } from 'react';
import { compose, setDisplayName } from 'recompose';

import { stateKeys } from './constants';
import withSearchUrlParams from './withSearchUrlParams';

const { Provider, Consumer } = createContext();

export class DealerSearchContext extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    excludeMrtAssociates: PropTypes.bool,
    currentSearchParam: PropTypes.string,
  };

  static defaultProps = {
    excludeMrtAssociates: '',
    currentSearchParam: '',
  };

  // eslint-disable-next-line react/sort-comp
  updateDealerSearchContext = (newContext, callback) => {
    this.setState(newContext, callback);
  };

  state = {
    // seed the search value from the URL search param
    [stateKeys.searchQuery]: this.props.currentSearchParam,
    [stateKeys.excludeMrtAssociates]: this.props.excludeMrtAssociates,
    // eslint-disable-next-line react/no-unused-state
    updateDealerSearchContext: this.updateDealerSearchContext,
  };

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

export { Consumer };
export default compose(
  setDisplayName('DealerSearchContext'),
  withSearchUrlParams,
)(DealerSearchContext);

import React from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { H3, Tabs } from 'base-components';
import { Row, Column } from 'styled-components-grid';

import { CaseShortcut, CASE_SHORTCUT_PANELS } from 'features/keyShortcuts';

import Panel from 'blocks/Panel';
import Notes from './Notes';
import UnifiedHistory from './UnifiedHistory';
import StatusUpdates from './StatusUpdates';
import FieldUpdates from './FieldUpdates';
import CallHistory from './CallHistory';
import NotificationHistory from './NotificationHistory';

const tabsList = [
  t`Notes`,
  t`All`,
  t`Status Updates`,
  t`Field Updates`,
  t`Call History`,
  t`Notifications`,
];

// note: onClick is injected by Tabs.List
function TabShortcut({ name, index, onClick, children, ...props }) {
  return (
    <CaseShortcut
      action={{
        parent: CASE_SHORTCUT_PANELS.activity,
        id: `${name}${index}ActivityAction`,
        name,
        shortcut: ['a', `${index + 1}`],
        priority: 1,
        perform: () => onClick && onClick(),
      }}
    >
      {({ onFocusRequested }) =>
        children({ onFocusRequested, onClick, ...props })
      }
    </CaseShortcut>
  );
}

TabShortcut.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.func.isRequired,
};

function renderTabs() {
  return tabsList.map((name, index) => (
    <TabShortcut key={name} name={name} index={index}>
      {({ onFocusRequested, onClick, ...props }) => (
        <Tabs.ListItem {...props} ref={onFocusRequested}>
          <Tabs.ListItemButton
            aria-label={`tab-link-${name}`}
            onClick={onClick}
          >
            <Trans id={name} />
          </Tabs.ListItemButton>
        </Tabs.ListItem>
      )}
    </TabShortcut>
  ));
}

function CaseActivityPanel({ caseNumber }) {
  return (
    <Panel modifiers={['padScaleX_3']} data-testid="CaseActivityPanel">
      <Row>
        <CaseShortcut
          action={{
            parent: CASE_SHORTCUT_PANELS.activity,
            id: 'goToActivityAction',
            name: t`Go to Activity`,
            shortcut: ['a', '0'],
            priority: 2,
            icon: 'arrow-right',
          }}
        >
          {({ onFocusRequested }) => (
            <Column modifiers={['padScaleY_2']} ref={onFocusRequested}>
              <H3 modifiers={['fontWeightRegular']}>
                <Trans>Activity</Trans>
              </H3>
            </Column>
          )}
        </CaseShortcut>
      </Row>

      <Row>
        <Tabs>
          <Tabs.Menu>
            <Tabs.List>{renderTabs()}</Tabs.List>
          </Tabs.Menu>
          <Tabs.Content>
            <Notes caseNumber={caseNumber} />
            <UnifiedHistory caseNumber={caseNumber} />
            <StatusUpdates caseNumber={caseNumber} />
            <FieldUpdates caseNumber={caseNumber} />
            <CallHistory caseNumber={caseNumber} />
            <NotificationHistory caseNumber={caseNumber} />
          </Tabs.Content>
        </Tabs>
      </Row>
    </Panel>
  );
}

CaseActivityPanel.propTypes = {
  caseNumber: PropTypes.string.isRequired,
};

export default CaseActivityPanel;

import { curry, get } from 'lodash';
import React from 'react';
import { Query, Mutation } from '@apollo/client/react/components';

import CustomerWidgetContext from './CustomerWidgetContext';
import updateCaseCustomerMutation from './updateCaseCustomerMutation';
import { customerQuery } from './caseCustomerQuery';

import {
  isTerminalLocationRequired,
  buildCustomerSearchValue,
  setCaseCustomerPanelStatus,
} from './utils';

export const buildMutationChild = curry(
  (WrappedComponent, componentProps, updateCaseCustomerId) => {
    const { caseId, customer, updateCustomerWidgetContext } = componentProps;

    return (
      <WrappedComponent
        {...componentProps}
        key={get(customer, 'id', '')}
        onSelectCustomer={(customerId, customerSearchValue) => {
          updateCustomerWidgetContext(
            { customerSearchValue, storeCustomerSearchValue: '' },
            () => {
              setCaseCustomerPanelStatus(componentProps);

              if (!caseId || !customerId) return;

              updateCaseCustomerId({ variables: { id: caseId, customerId } });
            },
          );
        }}
      />
    );
  },
);

function buildWrappedComponentWithMutation(WrappedComponent, componentProps) {
  const { updateCustomerWidgetContext } = componentProps;

  return (
    <Mutation
      mutation={updateCaseCustomerMutation}
      onCompleted={(data) => {
        const customer = get(data, 'setCaseNationalCustomer.case.customer', '');
        setCaseCustomerPanelStatus({ ...componentProps, customer });
        const customerSearchValue = buildCustomerSearchValue(customer);
        updateCustomerWidgetContext({ customerSearchValue });
      }}
    >
      {buildMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const caseId = get(queryProps, 'data.case.id');
    const customer = get(queryProps, 'data.case.customer');
    const customerDomicile = get(queryProps, 'data.case.customerDomicile');
    const customerTerminalLocation = get(
      queryProps,
      'data.case.customerTerminalLocation',
    );

    return buildWrappedComponentWithMutation(WrappedComponent, {
      ...componentProps,
      caseId,
      customer,
      customerDomicile,
      customerTerminalLocation,
      isTerminalLocationRequired: isTerminalLocationRequired(
        get(queryProps, 'data.case.customer.billingPreferences'),
      ),
    });
  },
);

function skipQuery({ caseNumber }) {
  return !caseNumber;
}

function buildQueryVariables({ caseNumber }) {
  return { caseNumber };
}

function buildWrappedComponentWithQuery(WrappedComponent, componentProps) {
  const { updateCustomerWidgetContext } = componentProps;

  return (
    <Query
      onCompleted={(data) => {
        const customer = get(data, 'case.customer');
        const customerSearchValue = buildCustomerSearchValue(customer);
        updateCustomerWidgetContext({ customerSearchValue });

        const customerDomicile = get(data, 'case.customerDomicile');
        const customerTerminalLocation = get(
          data,
          'case.customerTerminalLocation',
        );

        setCaseCustomerPanelStatus({
          ...componentProps,
          customer,
          customerTerminalLocation,
          customerDomicile,
        });
      }}
      query={customerQuery}
      skip={skipQuery(componentProps)}
      variables={buildQueryVariables(componentProps)}
      fetchPolicy="cache-and-network"
    >
      {buildQueryChild(WrappedComponent, componentProps)}
    </Query>
  );
}

export const buildConsumerChild = curry(
  (WrappedComponent, componentProps, consumerProps) => {
    const {
      caseNumber,
      setCasePanelComplete,
      setCasePanelIncomplete,
      setCasePanelPartial,
      setCasePanelInvalid,
      updateCustomerWidgetContext,
    } = consumerProps;

    return buildWrappedComponentWithQuery(WrappedComponent, {
      ...componentProps,
      caseNumber,
      setCasePanelComplete,
      setCasePanelIncomplete,
      setCasePanelPartial,
      setCasePanelInvalid,
      updateCustomerWidgetContext,
    });
  },
);

const withCustomerSelect = (WrappedComponent) => (componentProps) => (
  <CustomerWidgetContext.Consumer>
    {buildConsumerChild(WrappedComponent, componentProps)}
  </CustomerWidgetContext.Consumer>
);

export default withCustomerSelect;

import gql from 'graphql-tag';

export default gql`
  query inboundProgramsPanel_allInboundProgramsQuery {
    inboundProgramNumbers {
      inboundPrograms {
        id
        customerName
        tollFreeNumber
      }
    }
  }
`;

import PropTypes from 'prop-types';
import React from 'react';
import { noop } from 'lodash';

import EditDeleteIcons from 'components/EditDeleteIcons';

import ButtonBox from './ButtonBox';
import ContainerSelectable from './ContainerSelectable';

export default function EditDeletableItem({
  children,
  editTooltip,
  deleteTooltip,
  deleteEnabled,
  disabledDeleteTooltip,
  modifiers,
  tooltipPosition,
  onEditItem,
  onDeleteItem,
  showButtons,
}) {
  return (
    <ContainerSelectable modifiers={modifiers}>
      {children}
      {showButtons && (
        <ButtonBox>
          <EditDeleteIcons
            onEdit={onEditItem}
            editTooltip={editTooltip}
            onDelete={onDeleteItem}
            deleteTooltip={deleteTooltip}
            deleteEnabled={deleteEnabled}
            disabledDeleteTooltip={disabledDeleteTooltip}
            position={tooltipPosition}
          />
        </ButtonBox>
      )}
    </ContainerSelectable>
  );
}

EditDeletableItem.propTypes = {
  onEditItem: PropTypes.func,
  onDeleteItem: PropTypes.func,
  children: PropTypes.node.isRequired,
  editTooltip: PropTypes.node,
  deleteTooltip: PropTypes.node,
  deleteEnabled: PropTypes.bool,
  disabledDeleteTooltip: PropTypes.node,
  modifiers: PropTypes.arrayOf(PropTypes.string),
  showButtons: PropTypes.bool,
  tooltipPosition: PropTypes.string,
};

EditDeletableItem.defaultProps = {
  onEditItem: noop,
  onDeleteItem: noop,
  editTooltip: '',
  deleteTooltip: '',
  deleteEnabled: true,
  disabledDeleteTooltip: '',
  modifiers: ['padScale_0'],
  showButtons: true,
  tooltipPosition: 'left',
};

import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { isEmpty } from 'lodash';

import { Accordion, Text } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import HoursDetail from 'components/HoursDetail';
import RelativeNow from 'components/RelativeNow';
import DealerOperatingStatusLabel from 'components/DealerOperatingStatusLabel';

import { evaluateOperatingStatusType } from 'utils/operatingStatusFilter';

import AccordionBody from '../AccordionBody';

export function DealerHours({ dealer, expanded, onExpandedChange }) {
  if (isEmpty(dealer)) return null;

  const { timezone } = dealer;
  const today = moment().tz(timezone);

  return (
    <RelativeNow
      timezone={timezone}
      render={({ relativeNow }) => {
        const {
          modifier: operatingStatusTypeModifier,
          value: operatingStatusTypeValue,
        } = evaluateOperatingStatusType(dealer, relativeNow);

        return (
          <Container modifiers="padScale_0">
            <Accordion.Divider />
            <Accordion
              name="hours"
              expanded={expanded}
              onExpandedChange={onExpandedChange}
            >
              <Accordion.Head>
                <Container modifiers="fluid">
                  <Accordion.Title>
                    <Row modifiers="middle">
                      <Column modifiers={['col', 'padScaleX_0']}>
                        <Trans>Hours</Trans>
                      </Column>
                      <Column modifiers={['col', 'end', 'padScaleX_4']}>
                        {!expanded && (
                          <DealerOperatingStatusLabel
                            dealer={dealer}
                            now={relativeNow}
                          />
                        )}
                      </Column>
                    </Row>
                  </Accordion.Title>
                </Container>
              </Accordion.Head>
              <AccordionBody>
                <HoursDetail>
                  <Row modifiers="padScale_0">
                    <HoursDetail.OperatingStatus
                      operatingStatusTypeModifier={operatingStatusTypeModifier}
                      operatingStatusTypeValue={operatingStatusTypeValue}
                    />
                    <Column modifiers="col">
                      <Row modifiers="padScale_0">
                        <Column modifiers="padScale_0">
                          <Text modifiers={['small', 'textLight']}>
                            <Trans>Local Time</Trans>
                          </Text>
                        </Column>
                      </Row>
                      <Row modifiers="padScale_0">
                        <Column modifiers="padScale_0">
                          <HoursDetail.LocalTime relativeNow={relativeNow} />
                        </Column>
                      </Row>
                    </Column>
                    <HoursDetail.UtcTime today={today} />
                  </Row>
                  <Row modifiers="padScale_2" />
                  <HoursDetail.List
                    dealer={dealer}
                    relativeNow={relativeNow}
                    dividers
                  />
                </HoursDetail>
              </AccordionBody>
            </Accordion>
          </Container>
        );
      }}
    />
  );
}

DealerHours.propTypes = {
  dealer: PropTypes.shape({
    ers247: PropTypes.bool,
    ersHours: PropTypes.arrayOf(
      PropTypes.shape({
        end: PropTypes.string,
        start: PropTypes.string,
        weekDay: PropTypes.string,
      }),
    ),
    open247: PropTypes.bool,
    operatingStatus: PropTypes.shape({
      ersOpen: PropTypes.string.isRequired,
      open: PropTypes.string.isRequired,
    }),
    timezone: PropTypes.string,
    workingHours: PropTypes.arrayOf(
      PropTypes.shape({
        end: PropTypes.string,
        start: PropTypes.string,
        weekDay: PropTypes.string,
      }),
    ),
  }),
  expanded: PropTypes.bool.isRequired,
  onExpandedChange: PropTypes.func.isRequired,
};

DealerHours.defaultProps = {
  dealer: {},
};

export default DealerHours;

import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'base-components';

import Box from './Box';

const MapControlButton = ({ icon, children, ...rest }) => (
  <Box {...rest}>
    <Icon name={icon} />
    {children}
  </Box>
);

MapControlButton.propTypes = {
  icon: PropTypes.string.isRequired,
  children: PropTypes.node,
};

MapControlButton.defaultProps = {
  children: null,
};

export default MapControlButton;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop, compact } from 'lodash';

import AccordionBlock from '../../blocks/Accordion';

import Head from './Head';
import Body from './Body';

import { ACCORDION_CONTEXT } from './constants';

function isExpandControlled(props) {
  return props.expanded !== undefined;
}

class Accordion extends Component {
  static Divider = AccordionBlock.Divider;

  static Head = Head;

  static Title = AccordionBlock.Title;

  static Body = Body;

  static modifiers = AccordionBlock.modifiers;

  static childContextTypes = {
    [ACCORDION_CONTEXT]: PropTypes.shape({}).isRequired,
  };

  static propTypes = {
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    expanded: PropTypes.bool,
    defaultExpanded: PropTypes.bool,
    name: PropTypes.string,
    onExpandedChange: PropTypes.func,
  };

  static defaultProps = {
    disabled: false,
    expanded: undefined,
    defaultExpanded: true,
    name: '',
    onExpandedChange: noop,
  };

  state = {
    expanded: isExpandControlled(this.props)
      ? undefined
      : this.props.defaultExpanded,
  };

  getChildContext() {
    return {
      [ACCORDION_CONTEXT]: {
        expand: this.expand,
        expanded: isExpandControlled(this.props)
          ? this.props.expanded
          : this.state.expanded,
      },
    };
  }

  expand = () => {
    if (isExpandControlled(this.props)) {
      this.props.onExpandedChange({
        name: this.props.name,
        expanded: !this.props.expanded,
      });
    } else {
      this.setState({ expanded: !this.state.expanded }, () =>
        this.props.onExpandedChange({
          name: this.props.name,
          expanded: !this.state.expanded,
        }),
      );
    }
  };

  render() {
    const { children, disabled } = this.props;
    return (
      <AccordionBlock modifiers={compact([disabled && 'disabled'])}>
        {children}
      </AccordionBlock>
    );
  }
}

export default Accordion;

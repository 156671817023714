import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { buildStyledComponent } from 'decisiv-ui-utils';

import Checkmark from './Checkmark';
import ToggleButton from './ToggleButton';

export default buildStyledComponent(
  'StatusCheckbox__Container',
  styled.label,
  ({ theme }) => css`
    display: inline-flex;
    flex: 1;
    overflow: hidden;
    position: relative;

    input {
      left: -100%;
      position: absolute;
      top: -100%;
    }

    &:hover ${ToggleButton}, input:focus + ${ToggleButton} {
      border-color: ${theme.colors.base.chrome600};

      ${Checkmark} {
        display: block;
      }
    }
  `,
  {
    themePropTypes: PropTypes.shape({
      colors: PropTypes.shape({
        base: PropTypes.shape({
          chrome600: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  },
);

import gql from 'graphql-tag';

export default gql`
  mutation setCaseNationalCustomer($customerId: ID!, $id: ID!) {
    setCaseNationalCustomer(
      input: { id: $id, patch: { customerId: $customerId } }
    ) {
      case {
        id
        fixpixPushResult
        customerTerminalLocation
        customer(useCachedDataForCase: true) {
          ... on Customer {
            type
            billTo
            city
            id
            name
            shipTo
            state
            terminalNumber
            billingPreferences {
              terminalLocation
            }
          }
        }
        paymentMethod
      }
    }
  }
`;

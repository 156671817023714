import styled from 'styled-components';

import { px2rem } from 'decisiv-ui-utils';
import { InputGroup } from 'base-components';

export const DropdownContainer = styled(InputGroup.Column)`
  width: 100%;
  max-width: ${px2rem(370)};
  margin-bottom: -1px;

  @media (max-width: ${px2rem(1024)}) {
    max-width: none;
  }
`;

export default DropdownContainer;

import gql from 'graphql-tag';

export const NAME = 'createCaseRequestLineMutation';

const query = gql`
  mutation createCaseRequestLineMutation($caseId: ID!) {
    createCaseRequestLine(input: { caseId: $caseId, requestLine: {} }) {
      requestLine {
        id
      }
    }
  }
`;

export default query;

import gql from 'graphql-tag';

export const NAME = 'caseAssignedToQuery';

export default gql`
  query caseAssignedToQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      assignedTo {
        email
        name
        userName
        firstName
        lastName
      }
    }

    currentUserProfile {
      user {
        email
        firstName
        lastName
        name
        userName
      }
    }
  }
`;

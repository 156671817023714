import gql from 'graphql-tag';

export default gql`
  mutation createAfterHoursEvent($event: AfterHoursEventCreateAttrs!) {
    createAfterHoursEvent(input: { event: $event }) {
      event {
        id
        isRecurring
        startDate
        endDate
      }
    }
  }
`;

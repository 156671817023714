import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { px2rem } from 'decisiv-ui-utils';
import { compact, noop } from 'lodash';
import { t, Trans, Plural } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { Container, Column, Row } from 'styled-components-grid';
import { InputField, QuickActionButton, Text } from 'base-components';

import withUserInfo from 'compositions/UserInfo/withUserInfo';

import {
  containsCreditCardNumber,
  excludeCreditCardNumber,
  CreditCardComplianceModal,
} from 'utils/creditCardNumberCompliance';

const MAX_CHARACTERS = 300;

export class Form extends Component {
  static propTypes = {
    autoFocus: PropTypes.bool,
    notes: PropTypes.string,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    onTextChange: PropTypes.func,
    saveButtonLabel: PropTypes.node.isRequired,
    user: PropTypes.shape({
      email: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    autoFocus: false,
    notes: '',
    onCancel: noop,
    onTextChange: noop,
  };

  state = {
    notes: this.props.notes,
    containsCreditCardNumber: false,
    ignoreCreditCardNumber: false,
  };

  handleCancel = () => {
    this.setState({ notes: '' });
    this.props.onCancel();
  };

  handleSubmit = async () => {
    const { onSubmit } = this.props;
    const { notes } = this.state;

    await onSubmit(notes);

    this.setState({ notes: '' });
  };

  handleTextChange = ({ target: { value } }) => {
    this.setState({ notes: value });
    this.props.onTextChange(value);

    if (!this.state.ignoreCreditCardNumber && containsCreditCardNumber(value)) {
      this.setState({ containsCreditCardNumber: true });
    }
  };

  render() {
    const {
      autoFocus,
      saveButtonLabel,
      user: { email, name },
    } = this.props;
    const { notes } = this.state;

    return (
      <Container>
        <Row>
          <Column modifiers={['col', 'padScale_0']}>
            <InputField
              name="note-input"
              onChange={this.handleTextChange}
              placeholder={t`Enter note...`}
              value={notes}
              modifiers={['height_auto']}
              maxLength={MAX_CHARACTERS}
            >
              <InputField.Avatar
                modifiers={['primary']}
                email={email}
                name={name}
              />
              <Column modifiers={['col', 'padScale_0']}>
                <Row>
                  <InputField.Label>
                    <Text modifiers={['small', 'textLight']}>
                      <Trans>Note</Trans>
                    </Text>
                  </InputField.Label>
                  <InputField.CharsLeftLabel>
                    {(charsLeft) => (
                      <Plural
                        value={charsLeft}
                        one="# Character Left"
                        other="# Characters Left"
                      />
                    )}
                  </InputField.CharsLeftLabel>
                </Row>
                <Row>
                  <InputField.TextArea autoFocus={autoFocus} />
                </Row>
              </Column>
            </InputField>
          </Column>
        </Row>
        <Row
          modifiers={['middle', 'reverse']}
          style={{ paddingTop: px2rem(15) }}
        >
          <Column>
            <QuickActionButton
              disabled={!notes}
              modifiers={compact([
                !notes && 'disabled',
                'hoverSuccess',
                'secondary',
              ])}
              onClick={this.handleSubmit}
            >
              <QuickActionButton.Text>{saveButtonLabel}</QuickActionButton.Text>
            </QuickActionButton>
          </Column>
          <Column>
            <QuickActionButton onClick={this.handleCancel}>
              <QuickActionButton.Text>
                <Trans>Cancel</Trans>
              </QuickActionButton.Text>
            </QuickActionButton>
          </Column>
        </Row>

        {this.state.containsCreditCardNumber && (
          <CreditCardComplianceModal
            onDeleteCreditCardNumber={() => {
              this.handleTextChange({
                target: { value: excludeCreditCardNumber(notes) },
              });
              this.setState({ containsCreditCardNumber: false });
            }}
            onContinue={() =>
              this.setState({
                containsCreditCardNumber: false,
                ignoreCreditCardNumber: true,
              })
            }
          />
        )}
      </Container>
    );
  }
}

export default compose(setDisplayName('Form'), withUserInfo)(Form);

import { get, noop, debounce } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { unitSelector } from 'redux/preferences/selectors';

import GoogleMapPropTypes from './propTypes';
import withGoogleMapJS from './withGoogleMapJS';
import withPersistedGoogleMap from './withPersistedGoogleMap';
import { getMiddlePoint } from './location';

/**
 * `WithDirections` is a render-prop component that can be applied to
 * a mapping component to provide directions between two markers.
 * When `displayRoute` is called, the HOC starts a DirectionsService route process, and upon
 * successful completion does two things:
 * 1. provides a `directions` prop value to the wrapped component, set
 *    to the result from the DirectionsService
 * 2. if an `onDirectionsUpdate` function prop is provided, calls this
 *    function with those results as well
 */
export class WithDirections extends React.Component {
  static propTypes = {
    onDirectionsUpdate: PropTypes.func,
    render: PropTypes.func.isRequired,
    calcMiddlePoint: PropTypes.func,
    googleMaps: GoogleMapPropTypes.googleMapsApi,
    unit: PropTypes.string.isRequired,
  };

  static defaultProps = {
    calcMiddlePoint: getMiddlePoint,
    onDirectionsUpdate: noop,
    googleMaps: null,
  };

  state = {
    directions: null,
  };

  withDirectionsMounted = false;

  componentDidMount() {
    this.withDirectionsMounted = true;
  }

  componentWillUnmount() {
    this.withDirectionsMounted = false;
  }

  displayRoute = debounce((...locations) => {
    const start = locations.shift();
    const end = locations.pop();

    const {
      calcMiddlePoint,
      onDirectionsUpdate,
      googleMaps,
      unit,
    } = this.props;

    if (!googleMaps) {
      return;
    }

    if (!start || !end) {
      this.setState({
        directions: null,
        distance: '',
        duration: 0,
        middlePoint: null,
      });
      return;
    }

    if (!this.directionsService) {
      // Simulate Singleton
      this.directionsService = new googleMaps.DirectionsService();
    }

    const origin =
      start.address || new googleMaps.LatLng(start.latitude, start.longitude);

    const destination =
      end.address || new googleMaps.LatLng(end.latitude, end.longitude);

    const waypoints = locations.map(({ address, latitude, longitude }) => ({
      location: address || new googleMaps.LatLng(latitude, longitude),
      stopover: false,
    }));

    // kick off the routing (which is asynchronous):
    this.directionsService.route(
      {
        origin,
        destination,
        travelMode: googleMaps.TravelMode.DRIVING,
        unitSystem: googleMaps.UnitSystem[unit.toUpperCase()],
        avoidFerries: true,
        waypoints,
      },
      (result, status) => {
        if (!this.withDirectionsMounted) {
          // the component unmounted while we were waiting on the response...
          return;
        }

        if (status === googleMaps.DirectionsStatus.OK) {
          const distance = get(result, 'routes[0].legs[0].distance.text');
          const duration = get(result, 'routes[0].legs[0].duration.value');
          const middlePoint = calcMiddlePoint({ googleMaps, result });
          // set the directions in state so they're passed down to the child component
          this.setState({
            directions: result,
            middlePoint,
            distance,
            duration,
          });
          // call the callback so directions are passed up to any interested parent components
          onDirectionsUpdate(result);
        } else {
          // eslint-disable-next-line no-console
          console.error(
            'error fetching directions -\n',
            JSON.stringify({ origin, destination, status, result }, null, 2),
          );
        }
      },
    );
  }, 500);

  render() {
    const { directions, distance, duration, middlePoint } = this.state;

    return this.props.render({
      directions,
      distance,
      duration,
      middlePoint,
      displayRoute: this.displayRoute,
    });
  }
}

function mapStateToProps(state) {
  return {
    unit: unitSelector(state),
  };
}

export default compose(
  connect(mapStateToProps),
  withGoogleMapJS,
  withPersistedGoogleMap,
)(WithDirections);

import React from 'react';
import { compose, withProps } from 'recompose';

import withGoogleMap from './withGoogleMap';

export default compose(
  withProps({
    // props for withGoogleMap:
    containerElement: <div style={{ height: '100%' }} />,
    defaultMapElement: <div style={{ height: '100%' }} />,
  }),
  withGoogleMap,
);

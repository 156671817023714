import gql from 'graphql-tag';

export const NAME = 'caseDataQuery';

const query = gql`
  query caseDataQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      createdAt
      id
      fixpixPushResult
      servicingDealer {
        id
        dealer {
          ... on Dealer {
            id
            timezone
          }
          ... on CustomDealer {
            id
          }
        }
      }
      status
      statusHistory {
        id
        createdAt
        changedAt
        newStatus
        oldStatus
      }
    }
  }
`;

export default query;

import gql from 'graphql-tag';

export const NAME = 'searchCasesQuery';

export default gql`
  query searchCasesQuery(
    $query: String
    $status: [CaseStatus]
    $sort: CaseSort
    $before: String
    $after: String
    $pageSize: Int
    $startTime: Datetime
    $endTime: Datetime
    $inboundProgramNumberId: Int
  ) {
    searchCases(
      input: {
        query: $query
        status: $status
        sort: $sort
        dateRange: { startTime: $startTime, endTime: $endTime }
        inboundProgramNumberId: $inboundProgramNumberId
      }
    ) {
      cases(first: $pageSize, before: $before, after: $after) {
        edges {
          node {
            id
            caseNumber
            assignedTo {
              email
              firstName
              lastName
            }
            status
            inboundProgramNumber(useCachedDataForCase: true) {
              id
              customerName
            }
            assetDowntimeMs
            createdAt
            customer(useCachedDataForCase: true) {
              ... on Customer {
                id
                name
                type
              }
              ... on StoreCustomer {
                id
                name
                type
              }
              ... on CustomCustomer {
                name
                type
              }
              ... on CustomStoreCustomer {
                name
                type
              }
            }
            isDelayed
            caseDelays {
              id
              startTime
              endTime
              reasonType
              customReason
              status
            }
            quickNote
            primaryAsset {
              id
              unitNumber
            }
            statusHistory {
              id
              oldStatus
              newStatus
              changedAt
              createdAt
            }
            servicingDealer {
              id
              eta {
                id
                minMinutes
                maxMinutes
              }
              dealer {
                ... on Dealer {
                  id
                  name
                  address {
                    city
                    province
                    postalCode
                  }
                }
                ... on CustomDealer {
                  id
                  name
                }
              }
            }
            isDelayedService
            delayedServiceNotes
            assetLocationTimezone
            delayedServiceScheduledDate
            delayedServiceScheduledDispatchDate

            hasFailedAutomatedCall
          }
        }
        countInfo {
          countByStatus {
            count
            status
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;

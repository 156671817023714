import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { noop } from 'lodash';
import { A, H2, Icon, Text } from 'base-components';
import { withRouter } from 'react-router';
import { Container, Row, Column } from 'styled-components-grid';

import backgroundImg from 'assets/images/background-404-80.jpg';
import ButtonLinkWithIcon from 'elements/ButtonLinkWithIcon';

export function ErrorPage(props) {
  return (
    <Container modifiers="fluid">
      <Row>
        <Column
          modifiers={['col']}
          style={{
            backgroundImage: `url(${backgroundImg})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            height: '705px',
          }}
        >
          <Row modifiers={['height_100', 'bottom', 'center']}>
            <Column>
              <Row modifiers="center">
                <Column>
                  <Icon
                    name="exclamation-triangle"
                    modifiers="large"
                    style={{ color: 'white' }}
                  />
                </Column>
              </Row>
              <Row modifiers="center">
                <Column modifiers="padScaleY_4">
                  <H2
                    style={{ color: 'white' }}
                    modifiers={['fontWeightMedium']}
                  >
                    <Trans>404 Page Not Found</Trans>
                  </H2>
                </Column>
              </Row>
            </Column>
          </Row>
        </Column>
      </Row>
      <Row modifiers="center">
        <Column>
          <Row modifiers={['padScaleY_4']}>
            <Text>
              <Trans>
                Must be a wrong turn. Here are some options you can try.
              </Trans>
            </Text>
          </Row>
          <Row>
            <Column modifiers="col">
              <ButtonLinkWithIcon
                icon="chevron-left"
                onClick={() => props.history.goBack()}
              >
                <Trans>Go Back</Trans>
              </ButtonLinkWithIcon>
            </Column>
            <Column modifiers="col">
              <A aria-label="home-link" href="/">
                <ButtonLinkWithIcon icon="home" onClick={noop}>
                  <Trans>Home</Trans>
                </ButtonLinkWithIcon>
              </A>
            </Column>
            <Column modifiers="col">
              <ButtonLinkWithIcon
                icon="refresh"
                onClick={() => window.location.reload()}
              >
                <Trans>Refresh</Trans>
              </ButtonLinkWithIcon>
            </Column>
          </Row>
        </Column>
      </Row>
    </Container>
  );
}

ErrorPage.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(ErrorPage);

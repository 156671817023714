import React from 'react';

import { AmazonConnect, uiStateToModifier } from 'features/amazonConnect';

import PhoneButtonIconCircle from './PhoneButtonIconCircle';

/**
 * {@link PhoneButtonIconCircle} wrapped in the {@link AmazonConnect}
 * context to provide a color mapping from the CCP state.
 */
export default function PhoneButton() {
  return (
    <AmazonConnect>
      {({ uiState }) => (
        <PhoneButtonIconCircle modifiers={[uiStateToModifier(uiState)]}>
          <PhoneButtonIconCircle.Icon name="phone" />
        </PhoneButtonIconCircle>
      )}
    </AmazonConnect>
  );
}

import PropTypes from 'prop-types';

import { buildStyledComponent } from 'decisiv-ui-utils';

import { H4 } from '../../elements';

const styles = ({ theme }) => `
  font-weight: ${theme.fontWeights.normal};
  text-transform: capitalize;
`;

const themePropTypes = {
  fontWeights: PropTypes.shape({
    normal: PropTypes.number.isRequired,
  }).isRequired,
};

const Title = buildStyledComponent('Accordion__Title', H4, styles, {
  themePropTypes,
});

export default Title;

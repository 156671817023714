import React from 'react';

import { Row, Column } from 'styled-components-grid';

import Page from 'blocks/Page';

import CaseCreator from 'compositions/CaseCreator';

function CreateCase() {
  return (
    <Page modifiers={['bgChrome100', 'fluid']}>
      <Row>
        <Column modifiers={['padScale_2']}>
          <CaseCreator />
        </Column>
      </Row>
    </Page>
  );
}

export default CreateCase;

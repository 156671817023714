import gql from 'graphql-tag';

export const NAME = 'dealerSelectionPanel_caseStatusQuery';

const query = gql`
  query dealerSelectionPanel_caseStatusQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      status
    }
  }
`;

export default query;

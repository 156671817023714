import { curry, get } from 'lodash';
import React from 'react';
import { Query } from '@apollo/client/react/components';

import caseContactsGQL from './caseContactsQuery';

export function skipQuery({ caseNumber }) {
  return !caseNumber;
}

export function buildQueryVariables({ caseNumber }) {
  return { caseNumber };
}

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const caseId = get(queryProps, ['data', 'case', 'id'], '');
    const contacts = get(queryProps, ['data', 'case', 'caseContacts'], []);
    const contactsLoading = get(queryProps, 'loading');
    return (
      <WrappedComponent
        {...componentProps}
        caseId={caseId}
        contacts={contacts}
        contactsLoading={contactsLoading}
      />
    );
  },
);

const withContacts = (WrappedComponent) => (componentProps) => (
  <Query
    fetchPolicy="cache-and-network"
    query={caseContactsGQL}
    skipQuery={skipQuery(componentProps)}
    variables={buildQueryVariables(componentProps)}
  >
    {buildQueryChild(WrappedComponent, componentProps)}
  </Query>
);

export default withContacts;

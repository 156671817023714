import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { t, Trans } from '@lingui/macro';
import { branch, compose, withState, setDisplayName } from 'recompose';

import { px2rem } from 'decisiv-ui-utils';
import { Container, Row, Column } from 'styled-components-grid';
import { DataTable, Accordion, ButtonMicro } from 'base-components';

import { useCaseShortcut, CASE_SHORTCUT_PANELS } from 'features/keyShortcuts';

import withCustomerSelect from '../../withCustomerSelect';
import withWheelPreferences from './withWheelPreferences';
import PreferencesEmptyState from '../PreferencesEmptyState';
import PreferenceDetailsModal from '../PreferenceDetailsModal';
import withStoreCustomerSelect from '../../withStoreCustomerSelect';
import { buildColumn } from '../preferencesUtils';

const wheelPropType = PropTypes.shape({
  color: PropTypes.string,
  comments: PropTypes.string,
  diameter: PropTypes.string,
  offset: PropTypes.string,
  type: PropTypes.string,
});

const containerStyles = {
  paddingTop: px2rem(18),
  paddingBottom: px2rem(12),
};

const rowsConfig = [
  { label: t`Diameter`, valueProp: 'diameter' },
  { label: t`Offset`, valueProp: 'offset' },
  { label: t`Material`, valueProp: 'material' },
  { label: t`Type`, valueProp: 'type' },
  { label: t`Color/Finish`, valueProp: 'color' },
  { label: t`Comments`, valueProp: 'comments' },
];

function TabContent({ wheels, selectedWheel, setSelectedWheel }) {
  if (!wheels.length) {
    return (
      <PreferencesEmptyState title={<Trans>No Wheel Preferences</Trans>} />
    );
  }

  const renderModalTrigger = (wheel) => (
    <Row>
      <Column modifiers={['col', 'padScale_0']}>
        <ButtonMicro
          modifiers={['mini', 'info']}
          onClick={() => setSelectedWheel(wheel)}
        >
          <ButtonMicro.Icon name="info-circle" style={{ paddingRight: 0 }} />
        </ButtonMicro>
      </Column>
    </Row>
  );

  const centered = {
    cellOpts: ['small', 'center'],
    headerOpts: ['small', 'center'],
  };

  const columns = [
    buildColumn('priority', { label: t`#` }),
    buildColumn('diameter', { label: t`Diameter`, ...centered }),
    buildColumn('offset', { label: t`Offset`, ...centered }),
    buildColumn('material', { label: t`Material` }),
    buildColumn('type', { label: t`Type` }),
    {
      ...buildColumn('details', { label: ' ' }),
      dataCellRenderer: renderModalTrigger,
    },
  ];

  return (
    <Container style={containerStyles}>
      <DataTable
        style={{ height: 'auto' }}
        scrollX
        columns={columns}
        modifiers=""
        tableData={wheels}
        rowKeyGetter={(row) => row.priority}
        rowModifiersGetter={() => ['noHighlightOnHover', 'notFlex']}
      />
      {selectedWheel && (
        <PreferenceDetailsModal
          title={<Trans>Wheel Preference Details</Trans>}
          onClose={() => setSelectedWheel(null)}
          preference={selectedWheel}
          rowsConfig={rowsConfig}
        />
      )}
    </Container>
  );
}

TabContent.propTypes = {
  wheels: PropTypes.arrayOf(wheelPropType),
  selectedWheel: wheelPropType,
  setSelectedWheel: PropTypes.func.isRequired,
};

TabContent.defaultProps = { wheels: [], selectedWheel: null };

const TabContentWithModal = compose(
  setDisplayName('TabContent'),
  withState('selectedWheel', 'setSelectedWheel'),
)(TabContent);

function WheelPreferences({ wheels, customer }) {
  const hasData = !!wheels.length;
  const [expanded, setExpanded] = React.useState(false);

  const { onFocusRequested } = useCaseShortcut({
    parent: CASE_SHORTCUT_PANELS.customer,
    id: 'wheelPreferencesAction',
    name: t`Wheel Preferences`,
    shortcut: ['f', '6'],
    keywords: 'fleet wheel preferences',
    priority: 2,
    perform: () => hasData && setExpanded((e) => !e),
    deps: hasData,
  });

  return (
    <Accordion
      key={get(customer, 'id')}
      disabled={!hasData}
      expanded={expanded}
      onExpandedChange={({ expanded }) => setExpanded(expanded)}
    >
      <Accordion.Head>
        <Accordion.Title ref={onFocusRequested}>
          <Trans>Wheel Preferences</Trans>
        </Accordion.Title>
      </Accordion.Head>
      {hasData && (
        <Accordion.Body>
          <TabContentWithModal wheels={wheels} />
        </Accordion.Body>
      )}
    </Accordion>
  );
}

WheelPreferences.propTypes = {
  wheels: PropTypes.arrayOf(wheelPropType),
  customer: PropTypes.shape({ id: PropTypes.string }),
};

WheelPreferences.defaultProps = {
  wheels: [],
  customer: undefined,
};

const isNationalCustomer = ({ customerType, customerTypes }) =>
  customerType === customerTypes.NATIONAL.type;

export default compose(
  setDisplayName('WheelPreferences'),
  /**
   * Order is important here. `withWheelPreferences` needs the current customer
   * as a prop, so we must run `withCustomerSelect/withStoreCustomerSelect`
   * first.
   */
  branch(isNationalCustomer, withCustomerSelect, withStoreCustomerSelect),
  withWheelPreferences,
)(WheelPreferences);

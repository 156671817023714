import React from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { Row, Column } from 'styled-components-grid';
import { InputField, H1, Checkbox } from 'base-components';

import Panel from 'blocks/Panel';

import { Consumer } from './DealerSearchContext';
import { stateKeys } from './constants';
import withSearchParamUpdater from './withSearchParamUpdater';

const searchQueryKey = stateKeys.searchQuery;
const excludeMrtAssociatesKey = stateKeys.excludeMrtAssociates;

function Input(props) {
  const { dealerSearchValue, excludeMrtAssociates } = props;
  const { updateSearchValue, toggleExcludeMrtAssociates } = props;

  return (
    <Panel modifiers={['padScale_5']}>
      <Row>
        <Column modifiers={['col', 'padScaleX_4', 'padScaleY_2']}>
          <H1 modifiers={['fontWeightLight']}>
            <Trans>Find Service Provider</Trans>
          </H1>
        </Column>
      </Row>
      <Row>
        <Column modifiers={['col', 'padScaleX_4', 'padScaleY_2']}>
          <InputField
            value={dealerSearchValue}
            onChange={(event) => updateSearchValue(event.target.value)}
            placeholder={t`Search by service provider name...`}
          >
            <InputField.Icon name="search" />
            <Column modifiers={['col', 'padScale_0']}>
              <Row>
                <InputField.Label>
                  <Trans>Service Provider</Trans>
                </InputField.Label>
              </Row>
              <Row>
                <InputField.TextField autoFocus />
                {dealerSearchValue && (
                  <InputField.ActionButton
                    icon="times"
                    modifiers={['padScaleX_1', 'hoverDanger']}
                    onClick={() => updateSearchValue()}
                  />
                )}
              </Row>
            </Column>
          </InputField>
        </Column>
      </Row>
      <Row>
        <Column modifiers={['col', 'padScaleX_4']}>
          <Checkbox
            id="exclude-mrt-associates"
            name="exclude-mrt-associates"
            label={<Trans>Exclude MRT Associate Service Providers</Trans>}
            checked={excludeMrtAssociates}
            onChange={toggleExcludeMrtAssociates}
          />
        </Column>
      </Row>
    </Panel>
  );
}

Input.propTypes = {
  dealerSearchValue: PropTypes.string,
  updateSearchValue: PropTypes.func.isRequired,
  excludeMrtAssociates: PropTypes.bool.isRequired,
  toggleExcludeMrtAssociates: PropTypes.func.isRequired,
};

Input.defaultProps = { dealerSearchValue: '' };

/* istanbul ignore next */
/* eslint-disable react/prop-types */
const InputWithConsumerProps = (componentProps) => (
  <Consumer>
    {(props) => {
      const { updateDealerSearchContext } = props;

      const updateSearchValue = (value = '') =>
        updateDealerSearchContext({ [searchQueryKey]: value });

      const toggleExcludeMrtAssociates = () =>
        updateDealerSearchContext({
          [excludeMrtAssociatesKey]: !props[excludeMrtAssociatesKey],
        });

      return (
        <Input
          {...componentProps}
          excludeMrtAssociates={props[excludeMrtAssociatesKey]}
          dealerSearchValue={props[searchQueryKey]}
          updateSearchValue={updateSearchValue}
          toggleExcludeMrtAssociates={toggleExcludeMrtAssociates}
        />
      );
    }}
  </Consumer>
);
/* eslint-enable react/prop-types */

export default compose(
  setDisplayName('Input'),
  withSearchParamUpdater,
)(InputWithConsumerProps);

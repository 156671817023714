import styled from 'styled-components';

import { GhostIndicator } from 'base-components';
import { buildStyledComponent } from 'decisiv-ui-utils';

export default buildStyledComponent(
  'TextGhostIndicator',
  styled(GhostIndicator).attrs({ 'aria-hidden': true }),
  `
    margin: 0;
    height: auto;
    line-height: 1;
    user-select: none;
    color: transparent;
    pointer-events: none;

    *,
    *::before,
    *::after {
      color: inherit;
    }
  `,
);

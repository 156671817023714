import { isEmpty } from 'lodash';
import React from 'react';
import { compose } from 'recompose';

import withCaseAssignedTo from '../../withCaseAssignedTo';
import withCurrentUser from '../../withCurrentUser';

import withAssignCaseToCurrentUser from './withAssignCaseToCurrentUser';
import withUnassignCase from './withUnassignCase';

const withCaseAssignUserDataAndLogic = (WrappedComponent) => (
  componentProps,
) => {
  const {
    caseAssignedTo,
    currentUser,
    currentUserLoading,
    caseAssignedToLoading,
    assignCaseToCurrentUserLoading,
    unassignCaseLoading,
  } = componentProps;

  const isLoading = [
    currentUserLoading,
    caseAssignedToLoading,
    assignCaseToCurrentUserLoading,
    unassignCaseLoading,
  ].includes(true);

  const isAssigned = !isEmpty(caseAssignedTo);

  const isAssignedToCurrentUser =
    !isLoading && isAssigned && caseAssignedTo.email === currentUser.email;

  return (
    <WrappedComponent
      {...componentProps}
      isAssigned={isAssigned}
      isAssignedToCurrentUser={isAssignedToCurrentUser}
      isLoading={isLoading}
    />
  );
};

const withCaseAssignUser = (WrappedComponent) =>
  // Order matters. The mutations require the caseId supplied by the withCaseAssignTo Query.
  // The data and logic hoc requires props from all of the others as well.
  compose(
    withCurrentUser,
    withCaseAssignedTo,
    withAssignCaseToCurrentUser,
    withUnassignCase,
    withCaseAssignUserDataAndLogic,
  )(WrappedComponent);

export default withCaseAssignUser;

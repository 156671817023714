import React, { useCallback } from 'react';
import { useMutation, gql } from '@apollo/client';

import { RequestLineFieldsFragment } from '../constants';
import { NAME as REQUEST_LINES_QUERY } from '../withCaseRequestLines';

const refetchQueries = [REQUEST_LINES_QUERY];

const resetLineMutation = gql`
  mutation compositions_CaseRequestPanel_resetAgreementLine($id: ID!) {
    resetAgreement(input: { id: $id }) {
      requestAgreementLine {
        agreed
        agreementLine {
          ...RequestLineFields
        }
      }
    }
  }
  ${RequestLineFieldsFragment}
`;

const resetAllLinesMutation = gql`
  mutation compositions_CaseRequestPanel_resetAllAgreementLines($caseId: ID!) {
    resetAllAgreements(input: { caseId: $caseId }) {
      requestAgreementLines {
        agreed
        agreementLine {
          ...RequestLineFields
        }
      }
    }
  }
  ${RequestLineFieldsFragment}
`;

const withCaseResetRequestAgreementLines = (Component) => (props) => {
  const [one] = useMutation(resetLineMutation, { refetchQueries });
  const [all] = useMutation(resetAllLinesMutation, { refetchQueries });

  const resetLine = useCallback((variables) => one({ variables }), [one]);
  const resetAllLines = useCallback((variables) => all({ variables }), [all]);

  return (
    <Component
      {...props}
      resetRequestAgreementLine={resetLine}
      resetAllRequestAgreementLines={resetAllLines}
    />
  );
};

export default withCaseResetRequestAgreementLines;

import React from 'react';
import PropTypes from 'prop-types';
import { groupBy } from 'lodash';

import DebouncedQuery from 'components/DebouncedQuery';

import { configTypes } from '../constants';
import { queryNameForConfigType, queryForConfigType } from './constants';

const defaultSuggestionsBuilder = (data, { grouped }) => {
  const values = data?.values || [];

  return grouped
    ? Object.entries(groupBy(values, (v) => (v?.[0] || '').toUpperCase()))
    : values;
};

const buildLocationSuggestions = (data = []) => data;

const builderByType = {
  [configTypes.customerName]: buildLocationSuggestions,
  [configTypes.customerLocation]: buildLocationSuggestions,
  [configTypes.dealerName]: buildLocationSuggestions,
  [configTypes.dealerLocation]: buildLocationSuggestions,
};

const ConfigValueSuggestions = (props) => {
  const { grouped, children, configType, searchValue, ...rest } = props;

  return (
    <DebouncedQuery
      query={queryForConfigType[configType]}
      skipQuery={() => !searchValue}
      debounceMS={500}
      searchValue={searchValue}
      fetchPolicy="network-only"
      buildQueryVariables={() => ({ searchValue })}
    >
      {({ data, pending }) => {
        const queryName = queryNameForConfigType[configType];
        const builder = builderByType[configType] || defaultSuggestionsBuilder;

        const suggestions = builder(data?.[queryName], props);

        return children({ ...rest, suggestions, isLoading: pending });
      }}
    </DebouncedQuery>
  );
};
ConfigValueSuggestions.propTypes = {
  grouped: PropTypes.bool.isRequired,
  children: PropTypes.func.isRequired,
  configType: PropTypes.oneOf(Object.values(configTypes)).isRequired,
  searchValue: PropTypes.string,
};

ConfigValueSuggestions.defaultProps = { searchValue: undefined };

export default ConfigValueSuggestions;

import React from 'react';
import { MessageMedium } from 'base-components';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Container, Row, Column } from 'styled-components-grid';

function NoResultsMessage({ dealerSearchValue }) {
  return (
    <Container modifiers={['height_100']}>
      <Row modifiers={['center', 'height_100', 'middle']}>
        <Column modifiers={['col']}>
          <MessageMedium style={{ maxWidth: '50%' }}>
            <MessageMedium.Header>
              <MessageMedium.Icon name="search" />
            </MessageMedium.Header>
            <MessageMedium.Section>
              <Column modifiers={['col']}>
                <MessageMedium.Title>
                  <Trans>{`No matches for "${dealerSearchValue}"`}</Trans>
                </MessageMedium.Title>
              </Column>
            </MessageMedium.Section>
            <MessageMedium.Section>
              <Column modifiers={['col']}>
                <MessageMedium.Text>
                  <Trans>
                    Check that all words are spelled correctly or search by a
                    different name.
                  </Trans>
                </MessageMedium.Text>
              </Column>
            </MessageMedium.Section>
          </MessageMedium>
        </Column>
      </Row>
    </Container>
  );
}

NoResultsMessage.propTypes = {
  dealerSearchValue: PropTypes.string.isRequired,
};

export default NoResultsMessage;

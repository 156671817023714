import gql from 'graphql-tag';

export default gql`
  mutation createCaseContactMutation(
    $callbackEta: Boolean!
    $callbackRollTime: Boolean!
    $caseId: ID!
    $contactType: String!
    $email: String
    $label: String
    $name: String!
    $phone: String!
    $phoneExt: String
  ) {
    createCaseContact(
      callbackEta: $callbackEta
      callbackRollTime: $callbackRollTime
      caseId: $caseId
      contactType: $contactType
      email: $email
      label: $label
      name: $name
      phone: $phone
      phoneExt: $phoneExt
    ) {
      callbackEta
      callbackRollTime
      caseId
      contactType
      createdAt
      email
      id
      label
      name
      phone
      phoneExt
    }
  }
`;

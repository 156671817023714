import { get } from 'lodash';
import { i18n } from '@lingui/core';

import trailerTypeNames from 'compositions/CaseRequestsPanel/RequestAssetsForm/RequestAsset/trailerTypeNames';

import { FIELD_NAMES } from 'compositions/CaseRequestsPanel/RequestAssetsForm/constants';

import { modalModes, modalActions, allAssetTypes } from './constants';

const trailerTypes = Object.values(trailerTypeNames);

const { validationSucceeded, validationFailed } = modalModes;

const {
  clear,
  updateSearchValue,
  startSearch,
  selectAsset,
  endSearch,
  startSave,
  endSave,
} = modalActions;

export const getEmptyAttempt = (vehicleType = null) => ({
  user: null,
  type: vehicleType,
  input: {
    shipTo: null,
    customer: null,
    vehicleVin: null,
    application: null,
    vehicleType,
    vehicleNumber: null,
    domicileLocation: null,
  },
  asset: null,
  reference: null,
  timestamp: null,
});

const emptySearchResponse = {
  user: null,
  assets: [],
  timestamp: null,
  reference: null,
};

export const getModalInitialState = (props) => {
  const { assetValidationModalInitialState: initialState } = props;
  const { assetType, inputValues } = initialState;

  let attempt = initialState.attempt || getEmptyAttempt(assetType);

  let mode = modalModes.initial;
  if (attempt && attempt.reference) {
    mode = attempt.asset ? validationSucceeded : validationFailed;
  }

  if (inputValues) {
    attempt = { ...attempt, input: { ...attempt.input, ...inputValues } };
  }

  return {
    mode,
    isSaving: false,
    isSearching: false,
    searchResponse: emptySearchResponse,
    ...initialState,
    attempt,
  };
};

export const reducer = (state, action) => {
  switch (action.type) {
    case clear:
      return {
        ...state,
        mode: modalModes.initial,
        attempt: {
          ...state.attempt,
          ...getEmptyAttempt(state.attempt.type),
          input: state.attempt.input,
        },
        searchResponse: emptySearchResponse,
      };

    case updateSearchValue: {
      const { data } = action;
      const { name, value } = data;

      const attempt = {
        ...state.attempt,
        input: { ...state.attempt.input, [name]: value },
      };

      return { ...state, attempt };
    }

    case startSearch:
      return { ...state, isSearching: true };

    case selectAsset: {
      const attempt = { ...state.attempt, ...action.data };

      return { ...state, mode: validationSucceeded, attempt };
    }

    case endSearch:
      return { ...state, ...action.data, isSearching: false };

    case startSave:
    case endSave:
      return { ...state, isSaving: action.type === startSave };

    default:
      return state;
  }
};

export const isEmptyAsset = (asset) =>
  !get(asset, FIELD_NAMES.UNIT_NUMBER) && !get(asset, FIELD_NAMES.ASSET_TYPE);

export const getAssetData = (asset, isUsxpress) => {
  const id = get(asset, 'id');
  const assetType = get(asset, FIELD_NAMES.ASSET_TYPE);
  const trailerType = get(asset, FIELD_NAMES.TRAILER_TYPE);
  const useTrailerType = isUsxpress && trailerTypes.includes(trailerType);
  const type = useTrailerType ? trailerType : assetType;

  return { id, type, number: get(asset, FIELD_NAMES.UNIT_NUMBER) };
};

const assetsAreEqual = (asset1, asset2, isUsxpress) => {
  const data1 = getAssetData(asset1, isUsxpress);
  const data2 = getAssetData(asset2, isUsxpress);

  return data1.type === data2.type && data1.number === data2.number;
};

const buildRequestAssetData = ({ attempt, props }) => {
  const { isUsxpress } = props;

  const vehicleType = get(attempt, 'type');
  const isTrailerType = isUsxpress && trailerTypes.includes(vehicleType);
  const assetType = isTrailerType ? 'TRAILER' : vehicleType;
  const trailerType = isTrailerType ? vehicleType : null;

  return {
    assetType,
    trailerType,
    unitNumber: get(attempt, 'asset.vehicleNumber'),
    droppedUnit: false,
  };
};

const validTypes = [...Object.keys(allAssetTypes), undefined, null];

const hasValidType = (asset) =>
  validTypes.includes(asset.assetType) &&
  validTypes.includes(asset.trailerType);

export const syncAsset = async ({ requestAsset, attempt, props }) => {
  const { caseId, updateAsset, isUsxpress } = props;

  const newAssetData = buildRequestAssetData({ attempt, props });

  if (
    assetsAreEqual(requestAsset, newAssetData, isUsxpress) ||
    !hasValidType(newAssetData)
  ) {
    return;
  }

  await updateAsset({ caseId, id: requestAsset.id, asset: newAssetData });
};

export const getFieldDisplayValue = (type, fieldValues) =>
  type === 'vehicleType' && allAssetTypes[fieldValues[type]]
    ? i18n._(allAssetTypes[fieldValues[type]])
    : fieldValues[type] || '';

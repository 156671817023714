import { curry, get, lowerCase, toArray } from 'lodash';
import React from 'react';
import { Query } from '@apollo/client/react/components';

import CaseInboundProgramPanelContext from '../CaseInboundProgramPanelContext';

import allInboundProgramsQuery from './allInboundProgramsQuery';

const filterInboundPrograms = (inboundPrograms, searchValue) =>
  toArray(inboundPrograms).filter(({ customerName }) =>
    lowerCase(customerName)
      .replace(/\s/g, '')
      .includes(lowerCase(searchValue).replace(/\s/g, '')),
  );

const updateInboundProgramSearchValue = curry(
  (setContext, inboundProgramSearchValue) =>
    setContext({ inboundProgramSearchValue }),
);

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const {
      inboundProgramSearchValue,
      updateCaseInboundProgramPanelContext,
    } = componentProps;
    const { data, loading } = queryProps;

    const allInboundPrograms = get(
      data,
      'inboundProgramNumbers.inboundPrograms',
      [],
    );

    const inboundPrograms = filterInboundPrograms(
      allInboundPrograms,
      inboundProgramSearchValue,
    );

    return (
      <WrappedComponent
        {...componentProps}
        inboundProgramSearchValue={inboundProgramSearchValue}
        inboundPrograms={inboundPrograms}
        isLoadingInboundPrograms={loading}
        onChangeInboundProgramSearchValue={updateInboundProgramSearchValue(
          updateCaseInboundProgramPanelContext,
        )}
      />
    );
  },
);

function buildWrappedComponentWithQuery(WrappedComponent, componentProps) {
  return (
    <Query query={allInboundProgramsQuery}>
      {buildQueryChild(WrappedComponent, componentProps)}
    </Query>
  );
}

export const buildConsumerChild = curry(
  (WrappedComponent, componentProps, consumerProps) => {
    const {
      inboundProgramSearchValue,
      updateCaseInboundProgramPanelContext,
    } = consumerProps;

    return buildWrappedComponentWithQuery(WrappedComponent, {
      ...componentProps,
      inboundProgramSearchValue,
      updateCaseInboundProgramPanelContext,
    });
  },
);

const withFilteredInboundPrograms = (WrappedComponent) => (componentProps) => (
  <CaseInboundProgramPanelContext.Consumer>
    {buildConsumerChild(WrappedComponent, componentProps)}
  </CaseInboundProgramPanelContext.Consumer>
);

export default withFilteredInboundPrograms;

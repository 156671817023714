import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Trans } from '@lingui/macro';

import { Row, Column } from 'styled-components-grid';

import { H4, DataTable, QuickActionButton } from 'base-components';

import MessageSmall from 'base-components/blocks/MessageSmall';

import columns from './columns';
import ValidationMetadata from './ValidationMetadata';

import { modalActions, modalStatePropType } from '../../constants';

function ValidationSuccess(props) {
  const { state, triggerAction, readOnly } = props;
  const { assetType, requestAsset, attempt } = state;

  const showMismatchMessage =
    assetType === requestAsset.type &&
    get(attempt, 'asset.vehicleNumber') !== requestAsset.number;

  return (
    <>
      <Row modifiers="middle" style={{ marginBottom: 5 }}>
        <Column>
          <H4 modifiers="fontWeightMedium">
            <Trans>Validated Asset Data</Trans>
          </H4>
        </Column>
      </Row>
      {showMismatchMessage && (
        <Row modifiers="middle" style={{ marginBottom: 5 }}>
          <Column modifiers={['col', 'padScaleY_4']}>
            <MessageSmall style={{ alignItems: 'flex-start' }}>
              <MessageSmall.Icon type="warning" />
              <MessageSmall.Text style={{ whiteSpace: 'initial' }}>
                <Trans>
                  The Unit number of the validated and requested asset is not
                  the same.
                  <br />
                  Saving this validation will replace the requested asset Unit
                  number with the number of the validated asset.
                </Trans>
              </MessageSmall.Text>
            </MessageSmall>
          </Column>
        </Row>
      )}
      <Row>
        <Column modifiers={['col', 'padScaleX_0']}>
          <DataTable
            columns={columns}
            modifiers={[]}
            tableData={[attempt.asset]}
            rowKeyGetter={(data) => data.vehicleVin}
          />
        </Column>
      </Row>

      <Row modifiers="middle" style={{ padding: '15px 0 0 0' }}>
        <Column modifiers="padScale_0">
          <QuickActionButton
            onClick={() => triggerAction(modalActions.clear)}
            disabled={readOnly}
            modifiers={[readOnly && 'disabled']}
          >
            <QuickActionButton.Text>
              <Trans>Clear Validation</Trans>
            </QuickActionButton.Text>
          </QuickActionButton>
        </Column>
      </Row>

      <ValidationMetadata {...props} />
    </>
  );
}

ValidationSuccess.propTypes = {
  // eslint-disable-next-line react/no-typos
  state: modalStatePropType.isRequired,
  readOnly: PropTypes.bool.isRequired,
  triggerAction: PropTypes.func.isRequired,
};

export default ValidationSuccess;

import React, { useMemo } from 'react';
import { get } from 'lodash';
import { gql, useQuery } from '@apollo/client';

import { RequestLineFieldsFragment } from '../constants';

export const NAME = 'caseRequestLines';

export const query = gql`
  query caseRequestLines($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      fixpixPushResult
      requestLines {
        ...RequestLineFields
      }
      requestAgreementLines {
        agreed
        agreementLine {
          ...RequestLineFields
        }
        requestLineId
      }
      suppliedLines {
        supplied
        suppliedLine {
          ...RequestLineFields
        }
        agreementLineId
      }
    }
  }
  ${RequestLineFieldsFragment}
`;

const withCaseRequestLines = (Component) => (cProps) => {
  const { caseNumber } = cProps;

  const { data: queryData, loading } = useQuery(query, {
    skip: !caseNumber,
    variables: { caseNumber },
    fetchPolicy: 'network-only',
  });

  const data = useMemo(
    () => ({
      caseId: get(queryData, 'case.id'),
      requestLines: get(queryData, 'case.requestLines', []),
      requestSuppliedLines: get(queryData, 'case.suppliedLines', []),
      requestAgreementLines: get(queryData, 'case.requestAgreementLines', []),
    }),
    [queryData],
  );

  return (
    <Component
      {...cProps}
      caseId={data.caseId}
      requestLines={data.requestLines}
      requestAgreementLines={data.requestAgreementLines}
      requestSuppliedLines={data.requestSuppliedLines}
      isLoadingRequestLines={!!loading}
    />
  );
};

export default withCaseRequestLines;

import { compact } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import { Container, Row, Column } from 'styled-components-grid';

import Calendar from '../../../blocks/Calendar';

import P from '../../../elements/P';

import {
  getFirstDayOfMonthIndex,
  isBeforeDate,
  isBetweenDates,
  isSameDate,
  isAfterDate,
} from '../utils';

// TODO: Determine a way to implement translations
const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

class DateGrid extends Component {
  static propTypes = {
    disabledDates: PropTypes.shape({
      after: PropTypes.instanceOf(Date),
      before: PropTypes.instanceOf(Date),
    }),
    displayMonth: PropTypes.shape({
      year: PropTypes.number.isRequired,
      month: PropTypes.number.isRequired,
      daysInMonth: PropTypes.number.isRequired,
    }).isRequired,
    hoveredOverDate: PropTypes.instanceOf(Date),
    onSelectDate: PropTypes.func.isRequired,
    range: PropTypes.bool,
    selectedDate: PropTypes.instanceOf(Date),
    selectedDateRange: PropTypes.shape({
      from: PropTypes.instanceOf(Date),
      to: PropTypes.instanceOf(Date),
    }),
    today: PropTypes.shape({
      year: PropTypes.number.isRequired,
      month: PropTypes.number.isRequired,
      date: PropTypes.number.isRequired,
    }).isRequired,
    updateHoveredOverDate: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disabledDates: {},
    hoveredOverDate: null,
    range: false,
    selectedDate: null,
    selectedDateRange: {},
  };

  /**
   * Evaluates whether the provided date falls within a disabled date range (provided via props)
   * @param    {Object}  disabledDates        Contains data concerning the dates to be disabled.
   * @property {Date}    disabledDates.before All dates before this date are disabled.
   * @property {Date}    disabledDates.after  All dates after this date are disabled.
   * @param    {Date}    dateToTest           The date to test
   * @return   {Boolean}
   */
  isDateDisabled = (disabledDates, dateToTest) =>
    !!(
      isBeforeDate(disabledDates.before, dateToTest) ||
      isAfterDate(disabledDates.after, dateToTest)
    );

  /**
   * Evaluates whether the provided date is between two selected dates, or between the selected
   * date and the currently hovered over date.
   * @param    {Object}  selectedDateRange        Contains data concerning the dates to be disabled.
   * @property {Date}    selectedDateRange.from   All dates before this date are disabled.
   * @property {Date}    selectedDateRange.to     All dates after this date are disabled.
   * @param    {Date}    hoveredOverDate          The date the cursor is currently over.
   * @param    {Date}    dateToTest               The date to test
   * @return   {Boolean}
   */
  isDateInRange = (selectedDateRange, hoveredOverDate, dateToTest) =>
    !!(
      (selectedDateRange.from &&
        ((selectedDateRange.to &&
          isBetweenDates(
            selectedDateRange.from,
            selectedDateRange.to,
            dateToTest,
          )) ||
          (hoveredOverDate &&
            isBetweenDates(
              hoveredOverDate,
              selectedDateRange.from,
              dateToTest,
            )))) ||
      (!selectedDateRange.to &&
        hoveredOverDate &&
        isBetweenDates(selectedDateRange.from, hoveredOverDate, dateToTest))
    );

  /**
   * Evaluates whether the provided date is one of the currently selected dates.
   * @param  {Date}    date The date to test
   * @return {Boolean}
   */
  isDateSelected = (date) =>
    !!(
      (this.props.range &&
        (isSameDate(date, this.props.selectedDateRange.from) ||
          isSameDate(date, this.props.selectedDateRange.to))) ||
      (!this.props.range && isSameDate(date, this.props.selectedDate))
    );

  /**
   * Handles the click event on a date. Calls `onSelectDate` from props with the args,
   * [newSelectedDate, dateClicked]
   * @param  {Date} date The date that was clicked
   */
  handleSelectDate = (date) => {
    // only deselect the date if we are in date range mode
    if (this.props.range && this.isDateSelected(date)) {
      this.props.onSelectDate(null, date);
    } else {
      this.props.onSelectDate(date, date);
    }
  };

  /**
   * Evaluates whether the date currently being built is today. Time doesn't matter.
   * @param  {Date}    date The date to test
   * @return {Boolean}
   */
  isBuildingToday = (date) =>
    !!(
      this.props.today.year === this.props.displayMonth.year &&
      this.props.today.month === this.props.displayMonth.month &&
      this.props.today.date === date
    );

  /**
   * Builds a single, correctly styled date for display within the calendar grid.
   * @param  {Number}    date  The date number in the month to build. Ex, the first would be 1.
   * @param  {Number}    index The index of the date within the week. Ex, Sunday would be 0.
   * @return {Component} A fully built JSX DateTableCell, ready to render.
   */
  buildDate = (date, index) => {
    const jsDate = new Date(
      this.props.displayMonth.year,
      this.props.displayMonth.month - 1,
      date,
    );

    const dateTableCellModifiers = compact([date && 'withBorder']);

    const dateDisabled = this.isDateDisabled(this.props.disabledDates, jsDate);
    const dateInRange =
      this.props.range &&
      this.isDateInRange(
        this.props.selectedDateRange,
        this.props.hoveredOverDate,
        jsDate,
      );
    const dateSelected = this.isDateSelected(jsDate);

    const dateModifiers = compact([
      dateDisabled && 'disabled',
      dateInRange && 'inRange',
      dateSelected && 'selected',
    ]);

    return (
      <Calendar.DateTableCell
        key={`dayIndex-${index}`}
        modifiers={dateTableCellModifiers}
      >
        {date && (
          <Calendar.Date
            disabled={dateDisabled}
            modifiers={dateModifiers}
            onMouseEnter={() =>
              !dateDisabled && this.props.updateHoveredOverDate(jsDate)
            }
            onMouseLeave={() => this.props.updateHoveredOverDate(null)}
            onClick={() => this.handleSelectDate(jsDate)}
          >
            <div>{date}</div>
            <div>
              {this.isBuildingToday(date) && <FontAwesome name="circle" />}
            </div>
          </Calendar.Date>
        )}
      </Calendar.DateTableCell>
    );
  };

  /**
   * Builds a single row for display within the calendar grid.
   * @param  {Array<Number>} week  An array of numbers, representing the dates to display in the
   *                               week.
   * @param  {Number}        index The index of the week within the month.
   * @return {Component} A fully built JSX DateTableRow, ready to render.
   */
  buildWeek = (week, index) => (
    <Calendar.DateTableRow key={`week-${index}`}>
      {week.map((date, dateIndex) => this.buildDate(date, dateIndex))}
    </Calendar.DateTableRow>
  );

  /**
   * Evaluates the provided displayMonth to determine how many weeks the month spans, and what
   * position within those weeks the dates should be in.
   * @param    {Object} displayMonth             a displayMonth Object
   * @property {Number} displayMonth.daysInMonth the total number of days in this month
   * @return {Array<Array>} An array of arrays representing the weeks the month touches populated
   * with the dates in the correct indexes. `null` is present for indexes that do not correspond to
   * dates for this month.
   */
  buildWeeks = (displayMonth) => {
    // This gets the index of the first of the month within the first week. Ex, if the first is a
    // Wednesday, the firstDayIndex will be 4.
    const firstDayIndex = getFirstDayOfMonthIndex(displayMonth);

    // This creates an incrementing array the length of the days in the month
    // plus the days in the first week that are not a part of this month.
    const daysInCalendar = [
      ...Array(displayMonth.daysInMonth + firstDayIndex).keys(),
    ];

    // Converts array the length of the total days in the calendar into an array of weeks with
    // properly indexed dates.
    return daysInCalendar.reduce((acc, index) => {
      // Evaluates the index to determine if this should be the start of a new week.
      const buildingNewWeek = index % 7 === 0;

      // Evaluates whether the current index refers to one of the days before this month begins
      const buildingCurrentMonth = firstDayIndex <= index;

      // Creates the correct date for this index, or null
      const dateVal = buildingCurrentMonth ? index - firstDayIndex + 1 : null;

      // If the current index is the first in the week, adds a new array with the correct date at
      // index 0
      if (buildingNewWeek) {
        const newWeek = [dateVal];
        return [...acc, newWeek];
      }

      // If the current index is not the first in the week, appends the correct date to the last
      // week array.
      acc[acc.length - 1].push(dateVal);
      return acc;
    }, []);
  };

  /**
   * Builds a calendar grid for display.
   * @return {Component} A fully built JSX DateTable, ready to render.
   */
  buildDateTable = () => (
    <Calendar.DateTable>
      <tbody>
        {this.buildWeeks(this.props.displayMonth).map((week, index) =>
          this.buildWeek(week, index),
        )}
      </tbody>
    </Calendar.DateTable>
  );

  render() {
    return (
      <Container>
        <Row>
          {DAYS.map((day) => (
            <Column key={day} modifiers={['center', 'col', 'padScale_0']}>
              <P modifiers={['small']}>{day}</P>
            </Column>
          ))}
        </Row>
        <Row>{this.buildDateTable()}</Row>
      </Container>
    );
  }
}

export default DateGrid;

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const styles = ({ theme }) => `
  align-items: center;
  background-color: ${theme.colors.base.background};
  cursor: pointer;
  display: flex;
  height: ${px2rem(45)};
  justify-content: space-between;
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      background: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const Head = buildStyledComponent('Accordion__Head', styled.div, styles, {
  themePropTypes,
});

export default Head;

// NOTE: ARROW_BASE is the actual size of the Arrow div
export function generateConstants({ ARROW_BASE, BROWSER_OFFSET }) {
  // ARROW_HEIGHT and ARROW_WIDTH are the dimensions for the visible half of the Arrow
  const ARROW_HEIGHT = () => ARROW_BASE / 2 - BROWSER_OFFSET;

  const ARROW_WIDTH = () => {
    const HEIGHT = ARROW_HEIGHT();
    return Math.sqrt(HEIGHT * HEIGHT * 2);
  };

  // Yay for Pythagorus! c = √(a² + b²)
  const ARROW_HALF_WIDTH = () => ARROW_WIDTH() / 2;

  // ARROW_OFFSET is how far the arrow is from the edge of the Popover
  const ARROW_OFFSET = () => ARROW_HALF_WIDTH() + 20;

  return {
    ARROW_HEIGHT: ARROW_HEIGHT(),
    ARROW_WIDTH: ARROW_WIDTH(),
    ARROW_HALF_WIDTH: ARROW_HALF_WIDTH(),
    ARROW_OFFSET: ARROW_OFFSET(),

    // These constants are used to control the rotation of the pointer.
    BOTTOM_ROTATE: 45,
    LEFT_ROTATE: 135,
    RIGHT_ROTATE: -45,
    TOP_ROTATE: -135,
  };
}

export function setBackground(defaultColor, arrowColor) {
  const color = arrowColor || defaultColor;
  return `linear-gradient(to bottom right, ${color} 51%, transparent 0%)`;
}

const BOX_SHADOWS = {
  bottom: '-2px -2px 2px -2px',
  left: '-3px -3px 6px -3px',
  right: '-3px -3px 6px -3px',
  top: '-2px -2px 4px -2px',
};

export function setBoxShadow(direction, color) {
  return `${BOX_SHADOWS[direction]} ${color}`;
}

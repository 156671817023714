import gql from 'graphql-tag';
import { get } from 'lodash';
import React from 'react';
import { Query } from '@apollo/client/react/components';

import {
  AssetFieldsFragment,
  RequestLineFieldsFragment,
} from 'compositions/CaseRequestsPanel/CaseRequestsPanelContext/constants';

export const NAME = 'withCaseQuery';

const query = gql`
  query withCaseQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      assetDowntimeMs
      assetLocation {
        coordinates {
          latitude
          longitude
        }
        highway
        mileMarker
        note
        searchValue
      }
      billable
      billingInfo {
        amount
        comment
        invoiceNumber
        poNumber
        woNumber
        referenceNumber
        ticketNumber
      }
      caseContacts {
        callbackEta
        callbackRollTime
        contactType
        email
        id
        name
        phone
        phoneExtension
      }
      caseNumber
      createdAt
      customer(useCachedDataForCase: true) {
        ... on Customer {
          id
          name
          type
          billTo
          city
          billingPreferences {
            creditCardRuleAbbreviation
          }
          shipTo
          state
        }
        ... on CustomCustomer {
          name
          type
          zip
        }
        ... on CustomStoreCustomer {
          accountNumber
          name
          type
        }
        ... on StoreCustomer {
          id
          name
          type
          accountNumber
          id
          city
          billingPreferences {
            creditCardRuleAbbreviation
          }
          state
          type
        }
      }
      customerDomicile {
        ... on Customer {
          id
          name
          type
          billTo
          city
          shipTo
          state
        }
      }
      customerTerminalLocation
      inboundProgramNumber(useCachedDataForCase: true) {
        id
        customerName
        inboundProgramAccountType
        locations {
          ... on Customer {
            id
            customerName: name
            type
            billTo
            city
            shipTo
            state
          }
          ... on Dealer {
            billTo
            displayAddress {
              city
              province
            }
            id
            dealerName: name
            shipTo
          }
        }
      }
      inboundProgramShipTo
      paymentMethod
      quickNote
      primaryAsset {
        ...AssetFields
      }
      relatedAssets {
        ...AssetFields
      }
      requestAgreementLines {
        agreed
        agreementLine {
          ...RequestLineFields
        }
        requestLineId
      }
      requestLines {
        ...RequestLineFields
      }
      servicingDealer {
        id
        accepted
        contactPerson
        dealer {
          ... on Dealer {
            billTo
            displayAddress {
              city
              postalCode
              province
              streetAddress
            }
            id
            name
            shipTo
          }
          ... on CustomDealer {
            id
            name
            mileageRange {
              id
              max
              min
            }
          }
        }
        eta {
          id
          maxMinutes
          minMinutes
        }
        note
        phoneNumber
        reason
        responseTime
      }
      specialInstructions
      status
      statusHistory {
        id
        changedAt
        createdAt
        newStatus
        oldStatus
      }
      suppliedLines {
        agreementLineId
        supplied
        suppliedLine {
          ...RequestLineFields
        }
      }
      thirdPartyTransactionDetails
      tokenizedCreditCard {
        expirationMonth
        expirationYear
        firstDigit
        last4Digits
        name
      }
    }
  }
  ${AssetFieldsFragment}
  ${RequestLineFieldsFragment}
`;

const withCaseData = (WrappedComponent) => (componentProps) => {
  const { caseNumber } = componentProps;

  return (
    <Query
      query={gql`
        query compositions_CasePrint_Contact_contactTypesQuery {
          caseContactTypes {
            option
            text
          }
        }
      `}
    >
      {({ data: caseContactTypeData, loading: loadingContactTypes }) => (
        <Query
          fetchPolicy="network-only"
          query={query}
          skip={!caseNumber}
          variables={{ caseNumber }}
        >
          {({ data, loading: loadingCase }) => {
            const caseData = {
              ...(get(data, 'case') || {}),
              caseStatus: get(data, 'case.status'),
            };

            return (
              <WrappedComponent
                {...componentProps}
                caseContactTypes={
                  get(caseContactTypeData, 'caseContactTypes') || []
                }
                caseData={caseData}
                isLoadingCase={loadingCase || loadingContactTypes}
              />
            );
          }}
        </Query>
      )}
    </Query>
  );
};

export default withCaseData;

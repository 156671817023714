import gql from 'graphql-tag';

export default gql`
  mutation updateCaseInboundProgramNumberID(
    $caseId: ID!
    $inboundProgramNumberId: ID
  ) {
    updateCase(
      input: {
        id: $caseId
        patch: {
          inboundProgramNumberId: $inboundProgramNumberId
          inboundProgramShipTo: null
        }
      }
    ) {
      case {
        id
        inboundProgramShipTo
        inboundProgramNumber(useCachedDataForCase: true) {
          id
          billTo
          isUsxpress
          customerName
          defaultCustomer {
            id
            name
          }
          inboundProgramAccountType
          tollFreeNumber
          assetValidationFields
          requiresAssetValidation
          assetValidationInstructions
          requiresValidationForAssetTypes {
            type
            requirements
          }
          assetValidationFailureInstructions
        }
      }
    }
  }
`;

import React from 'react';

import { Icon } from 'base-components';

import StyledTopNav from 'blocks/TopNav';
import { AmazonConnect, isConversationState } from 'features/amazonConnect';

/**
 * Icon for the top nav call info area indicating whether the current call
 * is muted, non-muted, or hung up.
 */
export default function CallStatusIcon() {
  return (
    <AmazonConnect>
      {({ muted, uiState }) => (
        <StyledTopNav.Text modifiers={['fontWeightMedium', 'text', 'gapRight']}>
          {isConversationState(uiState) ? (
            <Icon name={muted ? 'microphone-slash' : 'microphone'} />
          ) : (
            <Icon name="phone-hangup" />
          )}
        </StyledTopNav.Text>
      )}
    </AmazonConnect>
  );
}

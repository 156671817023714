import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';
import DefaultIcon from 'decisiv-iconix-react';

const styles = ({ theme }) => `
  align-items: center;
  display: flex;
  height: ${theme.dimensions.leftNavWidthCollapsed};
  justify-content: center;
  width: calc(${theme.dimensions.leftNavWidthCollapsed} - 2px);
`;

const themePropTypes = {
  dimensions: PropTypes.shape({
    leftNavWidthCollapsed: PropTypes.string.isRequired,
  }).isRequired,
};

export default buildStyledComponent(
  'MenuItemTitleIcon',
  styled(DefaultIcon),
  styles,
  { themePropTypes },
);

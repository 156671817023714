import React from 'react';
import { useQuery, gql } from '@apollo/client';

export const NAME = 'contactEventsQuery';

const query = gql`
  query contactEventsQuery($contactId: ID!) {
    afterHoursContactEvents(input: { id: $contactId }) {
      canDelete
      isBackup
      events {
        id
        startTime
        endTime
        startDate
        endDate
        isRecurring
        options {
          recurringStartDate
          recurringEndDate
        }
        recurring {
          dayOfWeek
        }
        primaryContact {
          id
          name
          primaryPhoneNumber
          secondaryPhoneNumber
          jobTitle
        }
        backupContact {
          id
          name
          primaryPhoneNumber
          secondaryPhoneNumber
          jobTitle
        }
        finalContact {
          id
          name
          primaryPhoneNumber
          secondaryPhoneNumber
          jobTitle
        }
      }
    }
  }
`;

const withContactEvents = (WrappedComponent) => (componentProps) => {
  const { contact } = componentProps;

  const { data, loading, error } = useQuery(query, {
    skip: !contact?.id,
    variables: { contactId: contact?.id },
    fetchPolicy: 'network-only',
  });

  const { events, isBackup, canDelete } = data?.afterHoursContactEvents || {};

  return (
    <WrappedComponent
      {...componentProps}
      {...{ events, isBackup, canDelete }}
      loadingEvents={loading}
      loadingEventsError={error}
    />
  );
};

export default withContactEvents;

import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import moment from 'moment-timezone';

import mutation from './overrideRotationContactsMutation';
import scheduleChangeRefetch from '../scheduleChangeRefetch';

function buildRefetchQueries({ event: { blockStart, dealerId } }) {
  return () => scheduleChangeRefetch(blockStart, dealerId);
}

const withOverrideRotationContacts = (Component) => (cProps) => (
  <Mutation mutation={mutation} refetchQueries={buildRefetchQueries(cProps)}>
    {(mutate, { loading }) => {
      const { dealerTimezone } = cProps;
      const saveEvent = ({ afterHoursEvent, start, end }, contactIds) => {
        const patch = {
          ...contactIds,
          occurrence: {
            kind: 'SINGLE',
            endDate: moment.tz(end, dealerTimezone).format('YYYY-MM-DD'),
            startDate: moment.tz(start, dealerTimezone).format('YYYY-MM-DD'),
          },
        };

        return mutate({ variables: { id: afterHoursEvent.id, patch } });
      };

      return (
        <Component {...cProps} saveEvent={saveEvent} isSavingEvent={loading} />
      );
    }}
  </Mutation>
);

export default withOverrideRotationContacts;

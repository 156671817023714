import React from 'react';
import PropTypes from 'prop-types';

import { Popover } from 'base-components';

import Target from './Target';
import Content from './Content';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';

/**
 * General-purpose ONCall information popover with two panels:
 * * a narrower left panel with a background (with the color specified
 *   via a `colorKey` prop on the `InfoPopover.LeftPanel`), generally
 *   used for a summary of information
 * * a wider right panel with additional details
 *
 * The `colorKey` is a `dot.notation` key with the theme `colors`
 * object, e.g. `base.background` (referring to `theme.colors.base.background`)
 * or `weather.snow` (`theme.colors.weather.snow`).
 *
 * @example
 * <InfoPopover position="bottomLeft" zIndex={2}>
 *   <InfoPopover.Target>
 *     target content
 *   </InfoPopover.Target>
 *   <InfoPopover.Content style={{ right: '10px' }}>
 *     <InfoPopover.LeftPanel colorKey="base.text">
 *       left panel content
 *     </InfoPopover.LeftPanel>
 *     <InfoPopover.RightPanel>
 *       right panel content
 *     </InfoPopover.RightPanel>
 *   </InfoPopover.Content>
 * </InfoPopover>
 */
function InfoPopover({ children, ...props }) {
  return <Popover {...props}>{children}</Popover>;
}

InfoPopover.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

InfoPopover.Target = Target;
InfoPopover.Content = Content;
InfoPopover.LeftPanel = LeftPanel;
InfoPopover.RightPanel = RightPanel;

export default InfoPopover;

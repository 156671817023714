import getAddressComponents from 'utils/getAddressComponents';

export function geocode(googleMaps, address) {
  const geocoder = new googleMaps.Geocoder();

  return new Promise((resolve, reject) => {
    geocoder.geocode(
      {
        address,
      },
      (results, status) => {
        if (status === googleMaps.GeocoderStatus.OK) {
          const result = results[0];
          const address = result.formatted_address;
          const rawlocation = result.geometry.location;
          const location = { lat: rawlocation.lat(), lon: rawlocation.lng() };

          resolve({
            address,
            location,
            result,
            results,
            count: results.length,
            addressComponents: getAddressComponents(result),
          });
        } else {
          reject(status);
        }
      },
    );
  });
}

export function reverseGeocode(googleMaps, location) {
  const geocoder = new googleMaps.Geocoder();

  return new Promise((resolve, reject) => {
    geocoder.geocode(
      {
        location,
      },
      (results, status) => {
        if (status === googleMaps.GeocoderStatus.OK) {
          const result = results[0];
          const {
            formattedAddress,
            ...addressComponents
          } = getAddressComponents(result);

          resolve({
            address: formattedAddress || result.formatted_address,
            result,
            results,
            count: results.length,
            addressComponents,
          });
        } else {
          reject(status);
        }
      },
    );
  });
}

import React from 'react';
import { withScriptjs } from 'react-google-maps';
import { compose, branch, defaultProps, withProps } from 'recompose';

import Loading from 'components/Loading';

import getUrl from './googleMapsApi';

/**
 * `withGoogleMapJS` is a simple extension to react-google-maps' `withScriptjs` which
 * ensures that a wrapped component has access to the Google Maps API. The wrapped
 * component will not be rendered until the API's JavaScript has been loaded and the
 * global `google.maps` API is available.
 */

const location = { lat: null, lon: null };
const getMapsInstance = () => window && window.google && window.google.maps;

const loadGoogleMaps = compose(
  defaultProps({ googleMapURL: getUrl(), loadingElement: <Loading /> }),
  withScriptjs,
);

export const withGoogleMapJS = compose(
  // Only load the google maps script if it hasn't been yet
  branch(() => !getMapsInstance(), loadGoogleMaps),
  withProps((props) => ({ googleMaps: getMapsInstance(), location, ...props })),
);

export default withGoogleMapJS;

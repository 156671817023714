import PropTypes from 'prop-types';
import styled from 'styled-components';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';
import Icon from 'decisiv-iconix-react';

const modifierConfig = {
  small: () => `
    font-size: ${px2rem(16)};
  `,

  secondary: ({ theme }) => `
    color: ${theme.colors.base.chrome600} !important;
  `,
};

// !important is generally discouraged. This use case is specifically for
// when the Avatar is wrapped in some type of Link or Button component
// that applies the blue & underline on hover.
const styles = ({ theme }) => `
  color: ${theme.colors.base.chrome000} !important;
  font-size: ${px2rem(32)};
  text-decoration: none !important;
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome000: PropTypes.string.isRequired,
      chrome600: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default buildStyledComponent('Icon', styled(Icon), styles, {
  modifierConfig,
  themePropTypes,
});

import gql from 'graphql-tag';

export const NAME = 'caseInboundProgramNumberQuery';

export default gql`
  query caseInboundProgramNumberQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      inboundProgramShipTo
      inboundProgramNumber(useCachedDataForCase: true) {
        id
        billTo
        customerName
        inboundProgramAccountType
        tollFreeNumber
      }
    }
  }
`;

import { curry } from 'lodash';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import deleteAfterHoursContactMutation from './deleteAfterHoursContactQuery';
import { NAME as CONTACTS_QUERY_NAME } from '../withContacts/contactsQuery';

export const buildMutationChild = curry(
  (
    WrappedComponent,
    componentProps,
    deleteAfterHoursContact,
    mutationProps,
  ) => (
    <WrappedComponent
      {...componentProps}
      isDeletingAfterHoursContact={!!mutationProps?.loading}
      deleteAfterHoursContact={(contactIds) =>
        deleteAfterHoursContact({
          variables: {
            contactIds: [contactIds],
          },
        })
      }
    />
  ),
);

function buildWrappedComponentWithMutation(WrappedComponent, componentProps) {
  return (
    <Mutation
      mutation={deleteAfterHoursContactMutation}
      refetchQueries={[CONTACTS_QUERY_NAME]}
    >
      {buildMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

const withDeleteAfterHoursContact = (WrappedComponent) => (componentProps) =>
  buildWrappedComponentWithMutation(WrappedComponent, componentProps);

export default withDeleteAfterHoursContact;

import { InMemoryCache } from '@apollo/client';

import possibleTypes from './possibleTypes.json';

const merge = (existing, incoming) => ({ ...existing, ...incoming });
const replaceArray = (_, incoming = []) => incoming;

export default function buildCache() {
  return new InMemoryCache({
    possibleTypes,

    typePolicies: {
      AfterHoursEventInstance: {
        keyFields: ['afterHoursEvent', ['id'], 'start'],
      },

      TireOption: { keyFields: ['id', 'name'] },
      CaseAgreementLine: { keyFields: ['agreementLine', ['id']] },
      CaseSuppliedLine: { keyFields: ['suppliedLine', ['id']] },

      UserInfo: { keyFields: ['email'] },
      UserRole: { keyFields: ['name'] },
      UserPayload: { keyFields: ['user', ['email']] },

      Customer: { fields: { billingPreferences: { merge } } },
      StoreCustomer: { fields: { billingPreferences: { merge } } },
      CustomCustomer: { keyFields: ['name', 'type'] },
      CustomStoreCustomer: { keyFields: ['name', 'type'] },

      Case: {
        fields: {
          requestLines: { merge: replaceArray },
          suppliedLines: { merge: replaceArray },
          requestAgreementLines: { merge: replaceArray },
        },
      },
    },
  });
}

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row } from 'styled-components-grid';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

// this acts as our <thead> element
const styles = ({ theme }) => `
  background-color: ${theme.colors.base.background};
  border-bottom: solid 1px ${theme.colors.base.chrome200};
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  min-height: ${px2rem(50)};
  z-index: 1;
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      background: PropTypes.string.isRequired,
      chrome200: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default buildStyledComponent('Head', styled(Row), styles, {
  themePropTypes,
});

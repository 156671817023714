import { get } from 'lodash';

import countOfTime from 'utils/countOfTime';

export default function getTravelEstimate(data) {
  return {
    assetLocationDriveDistance: get(data, 'distance', 0),
    assetLocationDriveTime: countOfTime(get(data, 'time', 0) * 1000),
  };
}

import React from 'react';
import PropTypes from 'prop-types';

import { MessageSmall } from 'base-components';
import { Row, Column } from 'styled-components-grid';

import { fieldIds } from '../../constants';

export function getErrorMessage(errors, touched) {
  return touched.customerName && errors.customerName;
}

export function Error({ errors, setCasePanelInvalid, touched }) {
  const errorMessage = getErrorMessage(errors, touched);

  if (errorMessage) {
    setCasePanelInvalid((status) => ({
      ...status.fields,
      [fieldIds.customStore.name]: 'invalid',
    }));

    return (
      <Row modifiers={['padScaleY_2']}>
        <Column>
          <MessageSmall type="warning">{errorMessage}</MessageSmall>
        </Column>
      </Row>
    );
  }

  return null;
}

Error.propTypes = {
  errors: PropTypes.shape({
    customerName: PropTypes.string,
  }).isRequired,
  setCasePanelInvalid: PropTypes.func.isRequired,
  touched: PropTypes.shape({
    customerName: PropTypes.bool,
  }).isRequired,
};

export default Error;

import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import { isFunction } from 'lodash';

import FocusProviderContext from './FocusProviderContext';
import { scrollToAndHighlightElement as scrollTo } from './utils';

const resolveId = (id, props) => (isFunction(id) ? id(props) : id);

const hasInvalidArgs = (cbOrDomEl, isCatchAll) =>
  !cbOrDomEl || (isCatchAll && !isFunction(cbOrDomEl));

export const useOnFocusRequested = (id, props) => {
  const [curId, setCurId] = useState(resolveId(id, props));
  const unsubscribeRef = useRef();
  const scrollTimeoutRef = useRef();

  const context = useContext(FocusProviderContext);

  useEffect(() => {
    const newId = resolveId(id, props);

    if (curId !== newId) setCurId(newId);
  }, [id, curId, props]);

  return useCallback(
    (cbOrDomEl, opts = {}) => {
      const { requestFocusOn } = context;
      const { subscribeToAnyFocusRequest } = context;
      const { subscribeToFocusRequestsOn } = context;

      const isCatchAll = curId === '*';

      if (!curId || hasInvalidArgs(cbOrDomEl, isCatchAll)) {
        if (unsubscribeRef.current) unsubscribeRef.current();

        return;
      }

      const callback = (fieldId) => {
        let focus = cbOrDomEl;

        if (!isFunction(cbOrDomEl)) {
          focus = () => (scrollTimeoutRef.current = scrollTo(cbOrDomEl, opts));
        }

        const requestFocus = (id) => requestFocusOn(id, isCatchAll);

        clearTimeout(scrollTimeoutRef.current);

        focus({
          id: fieldId,
          scrollToAndHighlightElement: scrollTo,
          requestFocusOn: requestFocus,
        });
      };

      if (isCatchAll && unsubscribeRef.current) unsubscribeRef.current();

      const unsubscribe = isCatchAll
        ? subscribeToAnyFocusRequest(callback)
        : subscribeToFocusRequestsOn(curId, callback);

      unsubscribeRef.current = () => {
        clearTimeout(scrollTimeoutRef.current);
        unsubscribe();
      };
    },
    [curId, context],
  );
};

const withFocusReceiver = (id, propName) => (Component) => (props) => {
  const onFocusRequested = useOnFocusRequested(id, props);
  const data = { [propName || 'onFocusRequested']: onFocusRequested };

  return <Component {...props} {...data} />;
};

export default withFocusReceiver;

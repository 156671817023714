/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  applyStyleModifiers,
  styleModifierPropTypes,
} from 'styled-components-modifiers';
import {
  buildThemePropTypes,
  validateTheme,
} from 'styled-components-theme-validator';

import {
  dimensionModifiers,
  displayModifiers,
  padScaleModifiers,
} from './utils/modifiers';

const COMPONENT_NAME = 'Container';

export const MODIFIER_CONFIG = {
  ...dimensionModifiers,
  ...displayModifiers,
  ...padScaleModifiers,
  flex_column: () => ({
    styles: css`
      display: flex;
      flex-direction: column;
    `,
  }),
  fluid: () => ({
    styles: css`
      padding: 0;
    `,
  }),
};

const THEME_PROPTYPES = buildThemePropTypes({
  dimensions: PropTypes.shape({
    gridPad: PropTypes.string.isRequired,
  }).isRequired,
});

const Container = styled.div`
  ${validateTheme(COMPONENT_NAME, THEME_PROPTYPES)}
  box-sizing: border-box;
  flex: 1;
  padding: ${(props) => props.theme.dimensions.gridPad};
  position: relative;
  width: 100%;
  ${applyStyleModifiers(MODIFIER_CONFIG)};
`;

Container.propTypes = {
  className: PropTypes.string,
  modifiers: styleModifierPropTypes(MODIFIER_CONFIG),
};

Container.defaultProps = {
  className: COMPONENT_NAME,
};

Container.displayName = COMPONENT_NAME;
Container.modifiers = MODIFIER_CONFIG;

export default Container;

import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName } from 'recompose';

import { Row } from 'styled-components-grid';
import { Checkbox, Tooltip } from 'base-components';

import withFocusReceiver from 'setup/FocusProvider/withFocusReceiver';

function LineCheckbox(props) {
  const { onChange, onFocusRequested, tooltip } = props;
  const { id, isDisabled, isChecked, isReadOnly } = props;

  const checkbox = (
    <Checkbox
      id={id}
      checked={isChecked}
      disabled={isDisabled}
      readOnly={isReadOnly}
      onChange={() => onChange(id)}
    />
  );

  // The Checkbox component forwards ref to the actual input tag, which
  // is hidden with CSS. We highlight the Row instead when focus is requested.
  return (
    <Row modifiers="center" ref={onFocusRequested}>
      {tooltip && (
        <Tooltip position="left">
          <Tooltip.Target>{checkbox}</Tooltip.Target>
          <Tooltip.Content>{tooltip}</Tooltip.Content>
        </Tooltip>
      )}
      {!tooltip && checkbox}
    </Row>
  );
}

LineCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  tooltip: PropTypes.node,
  isChecked: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  focusReceiverId: PropTypes.string.isRequired,
  onFocusRequested: PropTypes.func.isRequired,
};

LineCheckbox.defaultProps = {
  tooltip: null,
};

export default compose(
  setDisplayName('LineCheckbox'),
  withFocusReceiver((props) => props.focusReceiverId),
)(LineCheckbox);

import gql from 'graphql-tag';

export const CASE_CUSTOMER_QUERY_NAME = 'customerQuery';

export const customerQuery = gql`
  query customerQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      customerTerminalLocation
      customer(useCachedDataForCase: true) {
        ... on Customer {
          id
          name
          type
          billTo
          city
          shipTo
          state
          singleCallRequested
          terminalNumber
          billingPreferences {
            terminalLocation
          }
        }
        ... on StoreCustomer {
          id
          type
          name
        }
        ... on CustomCustomer {
          type
          name
        }
        ... on CustomStoreCustomer {
          type
          name
        }
      }
      customerDomicile {
        ... on Customer {
          id
          name
          type
          billTo
          city
          shipTo
          state
          terminalNumber
          billingPreferences {
            terminalLocation
          }
        }
      }
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName } from 'recompose';
import { withRouter } from 'react-router-dom';

import { Container, Row, Column } from 'styled-components-grid';

import RotationSchedulePanel from './RotationSchedulePanel';
import Contacts from './Contacts';
import DealerNotes from './Notes';
import StoreInfo from './StoreInfo';
import TopBar from './TopBar';
import DealerDetailsContext from './DealerDetailsContext';
import DealerDetailsActivityPanel from './Activity';

import ScheduleGridNavigator from './RotationSchedulePanel/ScheduleGrid/GridNavigator';

export function DealerDetails({ match }) {
  const {
    params: { dealerId },
  } = match;
  return (
    <DealerDetailsContext>
      <ScheduleGridNavigator dealerId={dealerId}>
        <Container modifiers={['flex_column', 'height_100', 'padScale_0']}>
          <Row>
            <Column modifiers={['col', 'padScale_0']}>
              <TopBar dealerId={dealerId} />
            </Column>
          </Row>
          <Row modifiers={['flex_1', 'padScale_2']}>
            <Column modifiers={['col_3', 'padScale_0']}>
              <Row>
                <Column modifiers={['col', 'padScale_2']}>
                  <StoreInfo dealerId={dealerId} />
                </Column>
              </Row>
              <Row>
                <Column modifiers={['col', 'padScale_2']}>
                  <Contacts dealerId={dealerId} />
                </Column>
              </Row>
              <Row>
                <Column modifiers={['col', 'padScale_2']}>
                  <DealerNotes dealerId={dealerId} />
                </Column>
              </Row>
            </Column>
            <Column modifiers={['col_9', 'padScale_0']}>
              <Row>
                <Column modifiers={['col', 'padScale_2']}>
                  <RotationSchedulePanel dealerId={dealerId} />
                </Column>
              </Row>
              <Row>
                <Column modifiers={['col', 'padScale_2']}>
                  <DealerDetailsActivityPanel dealerId={dealerId} />
                </Column>
              </Row>
            </Column>
          </Row>
        </Container>
      </ScheduleGridNavigator>
    </DealerDetailsContext>
  );
}

DealerDetails.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      dealerId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default compose(
  setDisplayName('DealerDetails'),
  withRouter,
)(DealerDetails);

import gql from 'graphql-tag';

export const NAME = 'caseUnifiedHistory';

export default gql`
  query caseUnifiedHistory(
    $caseNumber: String!
    $first: Int
    $last: Int
    $beforeCursor: String
    $afterCursor: String
    $sortDirection: SortDirection = DESC
  ) {
    case(caseNumber: $caseNumber) {
      id
      status
      createdAt
      createdBy {
        name
        email
      }
      unifiedHistory(
        first: $first
        last: $last
        before: $beforeCursor
        after: $afterCursor
        sortDirection: $sortDirection
      ) {
        edges {
          cursor
          node {
            ... on CaseHistoryEntry {
              user {
                name
                email
                userName
              }
              recordedAt
              changes {
                name
                info {
                  name
                  value
                }
                fields {
                  field
                  previousValue
                  newValue
                }
                action
                entityId
              }
            }
            ... on CaseCall {
              id
              user {
                name
                email
                userName
              }
              amazonConnectContactId
              destinationName
              destinationNumber
              destinationOrganization
              direction
              sourceName
              sourceNumber
              sourceOrganization
              startTime
            }
            ... on CaseNote {
              id
              body
              createdAt
              updatedAt
              author {
                email
                name
                userName
              }
              lastUpdatedBy {
                email
                name
                userName
              }
            }
            ... on StatusHistory {
              id
              changedAt
              createdAt
              newStatus
              oldStatus
              user {
                email
                name
                userName
              }
            }
            ... on CaseEmail {
              insertedAt
              caseEmailType
              recipientsEmails
            }
            ... on CaseAutomatedCallEntry {
              id
              user {
                name
                email
                userName
              }
              recordedAt
              callAttributes {
                type
                status
                contactName
                contactType
                callMessage
                contactPhoneNumber
                contactPhoneExtension
              }
            }
            ... on CaseHighlightEntry {
              id
              user {
                name
                email
                userName
              }
              action
              recordedAt
              highlightAttributes {
                type
                message
              }
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }
      }
    }
  }
`;

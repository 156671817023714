import { css } from 'styled-components';

import { Link, Text } from 'base-components';
import { buildStyledComponent } from 'decisiv-ui-utils';

/**
 * Extend the `Link` styled-component to also include the
 * styles applied by the `small` modifier to `Text`.
 */
const BreadcrumbsLink = buildStyledComponent(
  'Breadcrumbs__Link',
  Link,
  ({ theme }) => css`
    text-transform: none;
    ${Text.modifiers.small({ theme })};
  `,
);

export default BreadcrumbsLink;

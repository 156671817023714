import React from 'react';
import gql from 'graphql-tag';
import { pick } from 'lodash';
import { compose, fromRenderProps } from 'recompose';
import { Mutation } from '@apollo/client/react/components';

import { CALL_HISTORY_QUERY } from 'compositions/CaseActivityPanel/queries';

import { NAME as CALL_CASE_LINK_QUERY_NAME } from './withCallCaseLinkQuery';

const propsForLinkingCallToCase = [
  'amazonConnectContactId', // String!
  'caseId', // ID!
  'destinationName', // String
  'destinationNumber', // String
  'destinationOrganization', // String
  'direction', // CallDirection!
  'sourceName', // String
  'sourceNumber', // String
  'sourceOrganization', // String
  'startTime', // Datetime
];

const addCallHistoryMutation = gql`
  mutation AddCallHistoryMutation($input: AddCaseCallHistoryInput!) {
    addCaseCallHistory(input: $input) {
      caseCall {
        id
        caseId
        amazonConnectContactId
      }
    }
  }
`;

const removeCaseCallHistoryMutation = gql`
  mutation RemoveCallHistoryMutation($id: ID!) {
    removeCaseCallHistory(input: { amazonConnectContactIds: [$id] }) {
      deletedCount
    }
  }
`;

const createMutationFor = (mutation) => (props) => (
  <Mutation
    mutation={mutation}
    refetchQueries={[CALL_CASE_LINK_QUERY_NAME, CALL_HISTORY_QUERY]}
    {...props}
  />
);

export default compose(
  fromRenderProps(
    createMutationFor(addCallHistoryMutation),
    (addHistory, { loading }) => ({
      linkCaseToCall: (props) => {
        const input = pick(props, propsForLinkingCallToCase);

        if (!input.destinationNumber) {
          // eslint-disable-next-line no-console
          console.error(
            'linkCaseToCall requires a destination number. Got:',
            input,
          );

          return Promise.resolve();
        }

        return addHistory({ variables: { input } });
      },
      isLinkingCallToCase: !!loading,
    }),
  ),

  fromRenderProps(
    createMutationFor(removeCaseCallHistoryMutation),
    (removeHistory, { loading }) => ({
      unlinkCaseFromCall: ({ amazonConnectContactId }) =>
        removeHistory({ variables: { id: amazonConnectContactId } }),
      isUnlinkingCallFromCase: !!loading,
    }),
  ),
);

import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

const modifierConfig = {
  fullWidth: () => `
    width: 100%;
  `,
};

const styles = '';

const Target = buildStyledComponent('Dropdown__Target', styled.span, styles, {
  modifierConfig,
});

export default Target;

import React from 'react';
import PropTypes from 'prop-types';
import { isString } from 'lodash';
import { t, Trans } from '@lingui/macro';

import { Text } from 'base-components';

const mapRequired = (value) =>
  ({
    NOT_REQUIRED: t`Not Required`,
    REQUIRED: t`Required`,
  }[value]);

export const valuesConfig = {
  purchaseOrderRule: {
    label: t`Purchase Order (PO)`,
    mapValue: (value) =>
      ({
        NOT_REQUIRED: t`Not Required`,
        REQUIRED: t`Required`,
        REQUIRED_BEFORE_ERS: t`Required Before ERS`,
      }[value]),
  },

  creditCardRuleAbbreviation: {
    label: t`Credit Card`,
    mapValue: (value) =>
      ({
        M: t`Required`,
        N: t`Not Accepted`,
        O: t`Not Required`,
      }[value]),
  },

  casingBrandOff: {
    label: t`Casing Brand Off`,
    mapValue: mapRequired,
  },
  casingBrandOn: {
    label: t`Casing Brand On`,
    mapValue: mapRequired,
  },
  contractNumber: {
    label: t`Contract Number`,
    mapValue: mapRequired,
  },
  fleetReferenceNumber: {
    label: t`Fleet Reference Number`,
    mapValue: mapRequired,
  },
  tireLimit: {
    label: t`Maximum Number of Tires`,
    mapValue: (value) => value,
  },
  wheelLimit: {
    label: t`Maximum Number of Wheels`,
    mapValue: (value) => value,
  },
  mountSpareFirst: {
    label: t`Mount Spare First`,
    mapValue: mapRequired,
  },
  odometer: {
    label: t`Odometer/Hubometer`,
    mapValue: mapRequired,
  },
  releaseNumber: {
    label: t`Release Number`,
    mapValue: mapRequired,
  },
  terminalLocation: {
    label: t`Terminal Location`,
    mapValue: mapRequired,
  },
  tireSerialNumber: {
    label: t`Tire Serial Number`,
    mapValue: mapRequired,
  },
  vehicleLicenseTag: {
    label: t`Vehicle License Tag`,
    mapValue: mapRequired,
  },
  vehicleYearModel: {
    label: t`Vehicle Year/Make/Model`,
    mapValue: mapRequired,
  },
  vin: {
    label: t`VIN`,
    mapValue: mapRequired,
  },
};

const BillingPreferenceValue = ({ type, values }) => {
  const config = valuesConfig[type];
  const value = isString(values[type]) ? values[type].trim() : values[type];

  const mappedValue =
    config.mapValue && value ? (
      <Trans id={config.mapValue(value)} />
    ) : (
      <span>&mdash;</span>
    );

  return <Text modifiers="textLight">{mappedValue}</Text>;
};

BillingPreferenceValue.propTypes = {
  type: PropTypes.string.isRequired,
  values: PropTypes.shape({}).isRequired,
};

export default BillingPreferenceValue;

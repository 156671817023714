import gql from 'graphql-tag';

export default gql`
  mutation updateCaseMileMarkerMutation($id: ID!, $mileMarker: String!) {
    updateCase(
      input: { id: $id, patch: { assetLocation: { mileMarker: $mileMarker } } }
    ) {
      case {
        id
      }
    }
  }
`;

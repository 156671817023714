import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import gql from 'graphql-tag';
import { curry } from 'lodash';
import moment from 'moment-timezone';

import { CASE_DEALER_RESPONSES_QUERY_NAME } from 'compositions/CaseDealerSelectionPanel/withCaseDealerResponses';

const createDealerResponseMutation = gql`
  mutation createDealerResponseMutation($input: CreateDealerResponseInput!) {
    createDealerResponse(input: $input) {
      dealerResponse {
        id
      }
    }
  }
`;

export const buildMutationChild = curry(
  (WrappedComponent, componentProps, createDealerResponse) => (
    <WrappedComponent
      {...componentProps}
      createDealerResponse={({
        caseId,
        dealerId,
        contactPerson,
        phoneNumber,
        dealerType,
        assetLocationDriveDistance,
        assetLocationDriveTime,
      }) => {
        createDealerResponse({
          variables: {
            input: {
              caseId, // ID!
              dealerId, // ID!
              dealerResponse: {
                // DealerResponseAttrs!...
                contactPerson, // String
                phoneNumber, // String
                responseTime: moment.tz().format(),
                assetLocationDriveDistance,
                assetLocationDriveTime,
              },
              dealerType, // DealerType!  - DEALER or CUSTOM_DEALER
            },
          },
        });
      }}
    />
  ),
);

function buildWrappedComponentWithMutation(WrappedComponent, componentProps) {
  return (
    <Mutation
      mutation={createDealerResponseMutation}
      refetchQueries={[CASE_DEALER_RESPONSES_QUERY_NAME]}
    >
      {buildMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

const withCreateDealerResponseMutation = (WrappedComponent) => (
  componentProps,
) => buildWrappedComponentWithMutation(WrappedComponent, componentProps);

export default withCreateDealerResponseMutation;

import { curry } from 'lodash';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';

import CustomerWidgetContext from '../../CustomerWidgetContext';

import { setCaseCustomerPanelStatus } from '../../utils';

import removeCaseDomicileMutation from './removeCaseDomicileMutation';

export const buildMutationChild = curry(
  (WrappedComponent, componentProps, removeCaseDomicile) => {
    const { updateCustomerWidgetContext } = componentProps;

    return (
      <WrappedComponent
        {...componentProps}
        onRemoveDomicileCustomer={(caseId) => {
          updateCustomerWidgetContext({ domicileSearchValue: '' }, () => {
            setCaseCustomerPanelStatus({
              ...componentProps,
              domicileSearchValue: '',
              customerDomicile: null,
            });
            removeCaseDomicile({ variables: { caseId } });
          });
        }}
      />
    );
  },
);

function buildWrappedComponentWithMutation(WrappedComponent, componentProps) {
  return (
    <Mutation mutation={removeCaseDomicileMutation}>
      {buildMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

export const buildConsumerChild = curry(
  (WrappedComponent, componentProps, consumerProps) => {
    const {
      setCasePanelIncomplete,
      setCasePanelPartial,
      updateCustomerWidgetContext,
    } = consumerProps;

    return buildWrappedComponentWithMutation(WrappedComponent, {
      ...componentProps,
      setCasePanelIncomplete,
      setCasePanelPartial,
      updateCustomerWidgetContext,
    });
  },
);

const withClearDomicileSearchValue = (WrappedComponent) => (componentProps) => (
  <CustomerWidgetContext.Consumer>
    {buildConsumerChild(WrappedComponent, componentProps)}
  </CustomerWidgetContext.Consumer>
);

export default withClearDomicileSearchValue;

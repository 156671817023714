import { t } from '@lingui/macro';

import buildEnum from 'utils/buildEnum';

export const mismatchTypes = buildEnum(['assetMismatch', 'customerMismatch']);

export const labelForType = {
  [mismatchTypes.assetMismatch]: t`Unit Number:`,
  [mismatchTypes.customerMismatch]: t`Fleet:`,
};

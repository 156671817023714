import animations from './animations';
import colors from './colors';
import dimensions from './dimensions';
import fonts from './fonts';
import fontWeights from './fontWeights';
import planes from './planes';
import transitions from './transitions';

export default {
  animations,
  colors,
  dimensions,
  fonts,
  fontWeights,
  planes,
  transitions,
};

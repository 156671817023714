import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { A, Text } from 'base-components';

import withContext from 'utils/withContext';
import {
  AmazonConnectProvider,
  makeCallRecordingUrl,
} from 'features/amazonConnect';

export function CallRecordingLink({
  initialized: connectInitialized,
  contactId: currentContactId,
  callId,
}) {
  // don't render a recording link  a) if not logged into Amazon
  // Connect or b) we're still on the call that we're rendering
  if (!connectInitialized || currentContactId === callId) {
    return null;
  }

  const recordingUrl = makeCallRecordingUrl(callId);

  return (
    <Text modifiers={['small']}>
      <A
        aria-label="download call recording"
        href={recordingUrl}
        target="get-call-recording"
      >
        <Trans>Link to recording</Trans>
      </A>
    </Text>
  );
}

CallRecordingLink.propTypes = {
  callId: PropTypes.string.isRequired,

  // from Amazon Connect context:
  initialized: PropTypes.bool.isRequired,
  contactId: PropTypes.string,
};

CallRecordingLink.defaultProps = {
  contactId: null,
};

export default compose(
  setDisplayName('CallRecordingLink'),
  withContext(AmazonConnectProvider),
)(CallRecordingLink);

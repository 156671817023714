import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import {
  Text,
  InputGroup,
  ButtonLink,
  QuickActionButton,
} from 'base-components';
import { Row, Column } from 'styled-components-grid';
import { px2rem } from 'decisiv-ui-utils';

import { AfterHoursEventPropType } from '../../propTypes';
import { contactIdKeys } from './constants';
import { eventHasContact } from './utils';

import { AfterHoursContactSelector } from '../../RotationSchedulePanel/RotationEditModal/AfterHoursContactSelector';

import AfterHoursEventList from './AfterHoursEventList';

const contactFieldLabels = {
  primaryContactId: t`Primary Contact`,
  backupContactId: t`Backup Contact (Optional)`,
  finalContactId: t`Final Contact`,
};

class EditRotations extends Component {
  static propTypes = {
    contact: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
    contacts: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }),
    ).isRequired,
    // eslint-disable-next-line react/no-typos
    events: PropTypes.arrayOf(AfterHoursEventPropType).isRequired,
    onChangeEvents: PropTypes.func.isRequired,
  };

  state = {
    primaryContactId: null,
    backupContactId: null,
    finalContactId: null,
    selectedEvents: [],
  };

  handleEventChange = (event) => {
    const { selectedEvents } = this.state;

    const newSelectedEvents = selectedEvents.includes(event.id)
      ? selectedEvents.filter((id) => id !== event.id)
      : selectedEvents.concat(event.id);

    this.setState({
      selectedEvents: newSelectedEvents,
    });
  };

  handleSelectAll = () => {
    const { contact, events } = this.props;
    this.setState({
      selectedEvents: events
        .filter((event) => eventHasContact(event, contact))
        .map((event) => event.id),
    });
  };

  handleDeselectAll = () => {
    this.setState({
      selectedEvents: [],
    });
  };

  handleContactChange = (contactKey, newContactId) =>
    this.setState({ [contactKey]: newContactId });

  handleSave = () => {
    const {
      selectedEvents: eventIds,
      primaryContactId,
      backupContactId,
      finalContactId,
    } = this.state;

    this.props.onChangeEvents({
      eventIds,
      primaryContactId,
      backupContactId,
      finalContactId,
    });
    this.setState({
      selectedEvents: [],
      primaryContactId: null,
      backupContactId: null,
      finalContactId: null,
    });
  };

  render() {
    const { contact, contacts } = this.props;
    const { selectedEvents } = this.state;

    const filteredContacts = contacts.filter(({ id }) => id !== contact.id);
    const events = this.props.events.map((event) => ({
      ...event,
      updated: !eventHasContact(event, contact),
    }));

    const isSaveDisabled =
      !this.state.primaryContactId || !this.state.finalContactId;

    return (
      <>
        <Row modifiers={['padScaleY_2', 'middle']}>
          <Column modifiers={['col', 'padScale_0']}>
            <Text modifiers="fontWeightMedium">
              <Trans>
                All upcoming After-Hours Rotations assigned to {contact.name}
              </Trans>
            </Text>
          </Column>
          <Column>
            <ButtonLink modifiers="small" onClick={this.handleSelectAll}>
              <Trans>Select All</Trans>
            </ButtonLink>
            <Text modifiers="small" style={{ padding: `0 ${px2rem(4)}` }}>
              /
            </Text>
            <ButtonLink modifiers="small" onClick={this.handleDeselectAll}>
              <Trans>Deselect All</Trans>
            </ButtonLink>
          </Column>
        </Row>

        <AfterHoursEventList
          events={events}
          contacts={contacts}
          numberOfContactsVisible={2}
          onEventChange={this.handleEventChange}
          selectedEvents={selectedEvents}
          selectedContact={contact}
        />

        <Row style={{ marginTop: px2rem(20), marginBottom: px2rem(10) }}>
          <Column modifiers={['col', 'padScale_0']}>
            {contactIdKeys.map((key) => (
              <InputGroup.Row key={key}>
                <InputGroup.Column modifiers={['col', 'padScale_0']}>
                  <AfterHoursContactSelector
                    inputId={key}
                    contacts={filteredContacts}
                    label={<Trans id={contactFieldLabels[key]} />}
                    onChange={(_, id) => this.handleContactChange(key, id)}
                    selected={this.state[key]}
                    readOnly={!selectedEvents.length}
                  />
                </InputGroup.Column>
              </InputGroup.Row>
            ))}
          </Column>
        </Row>

        <Row>
          <Column modifiers={['col', 'padScale_0']}>
            <QuickActionButton
              onClick={this.handleSave}
              modifiers={[isSaveDisabled && 'disabled']}
              disabled={isSaveDisabled}
            >
              <QuickActionButton.Text>
                <Trans>Save</Trans>
              </QuickActionButton.Text>
            </QuickActionButton>
          </Column>
        </Row>
      </>
    );
  }
}

export default EditRotations;

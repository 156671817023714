import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName } from 'recompose';

import { px2rem } from 'decisiv-ui-utils';
import { Divider } from 'base-components';
import { withTheme } from 'styled-components';

const EntryDivider = ({ theme }) => (
  <Divider
    modifiers={['vertical', 'chrome400']}
    style={{
      borderColor: theme.colors.base.chrome200,
      borderLeftWidth: px2rem(2),
      margin: 'auto',
      width: '0',
    }}
  />
);

EntryDivider.propTypes = {
  theme: PropTypes.shape({
    colors: PropTypes.shape({
      base: PropTypes.shape({
        chrome200: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default compose(setDisplayName('EntryDivider'), withTheme)(EntryDivider);

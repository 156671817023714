import { isArray, zip } from 'lodash';

/**
 * Uses a provided matcher function to evaluate two arrays for similarity.
 *
 * @param {array} arr1 An array to compare.
 * @param {array} arr2 An array to compare.
 * @param {function} matcher A function that evaluate elements at the same index in each array.
 *        Should return a boolean value.
 * @returns Boolean indicating if the arrays are alike.
 */
export default function arraysMatchWith(arr1, arr2, matcher) {
  if (!isArray(arr1) || !isArray(arr2)) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.error(`
        An argument that is not an array was passed into arraysMatchWith().
      `);
    }
    return false;
  }

  if (typeof matcher !== 'function') {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.error(`
        arraysMatchWith() was called with a matcher argument that is not a function.
      `);
    }
    return false;
  }

  if (arr1.length !== arr2.length) {
    return false;
  }

  // Ok, so JS doesn't actually have tuples. This really makes it
  // an array of two element arrays.
  const arrTuples = zip(arr1, arr2);
  return (
    // adds a "false" flag to the accumulator for any non-match,
    // then evaluates the length of that array.
    arrTuples.reduce(
      (acc, [el1, el2]) => {
        const match = Boolean(matcher(el1, el2));
        if (!match) {
          return acc.concat(match);
        }
        return acc;
      },
      [],
      arrTuples,
    ).length === 0
  );
}

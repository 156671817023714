import React, { useMemo } from 'react';
import gql from 'graphql-tag';
import { Query } from '@apollo/client/react/components';
import { curry, get } from 'lodash';

import { formatPhone } from 'utils/format';

export const NAME = 'allInboundProgramsQuery';

const query = gql`
  query reports_allInboundProgramsQuery {
    inboundProgramNumbers {
      inboundPrograms {
        id
        billTo
        customerName
        tollFreeNumber
      }
    }
  }
`;

// Returns an object `{ ids: [], values: {} }` with:
// * ids: an array with the ids of the programs sorted by the program name
// * values: an object whose keys are program ids, and values the program data
const buildPrograms = (programs) =>
  [...programs]
    .sort((l, r) => l.customerName > r.customerName)

    .reduce(
      (result, { id, customerName: name, billTo, tollFreeNumber: phone }) => ({
        ...result,
        ids: [...result.ids, id],
        values: {
          ...result.values,
          [id]: { id, name, billTo, phone: formatPhone(phone) },
        },
      }),
      { ids: [], values: {} },
    );

const dataPath = 'inboundProgramNumbers.inboundPrograms';

export const buildQueryChild = curry((Component, props, { data, loading }) => {
  // Transform the data into a more useable format (performance wise)
  const { ids, values } = useMemo(
    () => buildPrograms(get(data, dataPath) || []),
    [data],
  );

  return (
    <Component
      {...props}
      inboundProgramsIds={ids}
      inboundProgramsValues={values}
      isLoadingInboundPrograms={!!loading}
    />
  );
});

const withCasesSearchResults = (Component) => (props) => (
  <Query query={query} errorPolicy="all">
    {buildQueryChild(Component, props)}
  </Query>
);

export default withCasesSearchResults;

import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { px2rem } from 'decisiv-ui-utils';

import { AfterHoursEventPropType } from '../../propTypes';

import AfterHoursEventItem from './AfterHoursEventItem';
import ListWrapper from './ListWrapper';

const getHeightByRecurring = (event) => (!isEmpty(event.recurring) ? 82 : 62);

const getWrapperHeight = (events, numberOfContactsVisible) =>
  events
    .map(getHeightByRecurring)
    .slice(0, numberOfContactsVisible)
    .reduce((sum, x) => sum + x, 0);

const AfterHoursEventList = (props) => {
  const { numberOfContactsVisible, selectedContact } = props;
  const { events, onEventChange, selectedEvents, readOnly } = props;
  const isScrollable = events.length > numberOfContactsVisible;
  const sortedEvents = events.sort(
    (a, b) => new Date(a.startDate) - new Date(b.startDate),
  );

  return (
    <ListWrapper
      modifiers={[isScrollable && 'scrollable']}
      style={
        isScrollable
          ? {
              height: px2rem(getWrapperHeight(events, numberOfContactsVisible)),
            }
          : undefined
      }
    >
      {sortedEvents.map((event, index) => (
        <AfterHoursEventItem
          key={event.id}
          event={event}
          index={index}
          onChange={onEventChange}
          isSelected={selectedEvents.includes(event.id)}
          readOnly={readOnly}
          selectedContact={selectedContact}
        />
      ))}
    </ListWrapper>
  );
};

AfterHoursEventList.propTypes = {
  // eslint-disable-next-line react/no-typos
  events: PropTypes.arrayOf(AfterHoursEventPropType).isRequired,
  selectedContact: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  numberOfContactsVisible: PropTypes.number,
  onEventChange: PropTypes.func,
  selectedEvents: PropTypes.arrayOf(PropTypes.string),
  readOnly: PropTypes.bool,
};

AfterHoursEventList.defaultProps = {
  numberOfContactsVisible: 3,
  onEventChange: undefined,
  selectedEvents: [],
  readOnly: false,
};

export default AfterHoursEventList;

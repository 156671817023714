import { t } from '@lingui/macro';

export default {
  CEVA_TRAILER: t`Ceva Trailer`,
  DART_TRAILER: t`Dart Trailer`,
  DART_TRANSIT_CO: t`DART Transit Co`,
  DOLLAR_GENERAL_TRAILER: t`Dollar General Trailer`,
  DRT_LOGISTICS: t`DRT Logistics`,
  EXTRA_LEASE_TRAILER: t`Extra Lease Trailer (most start with a U or W)`,
  FAMILY_DOLLAR: t`Family Dollar`,
  FEMA_EMERGENCY_FREIGHT: t`FEMA Emergency Freight`,
  KROGER_TRAILER: t`Kroger Trailer`,
  LOCKHEED_MARTIN: t`Lockheed Martin`,
  MISCELLANEOUS_TRAILER: t`Miscellaneous Trailer`,
  SWIFT_TRAILER: t`Swift Trailer`,
  TARGET_TRAILER: t`Target Trailer`,
  TOTAL_TRAILER: t`Total Trailer`,
  USX_TRAILER: t`USX Trailer`,
  // TODO: remove this misspelled key in favor of the correct one that follows
  VIRIGINIA_HIGHWAY_TRAILERS: t`Virginia Highway Trailers`,
  VIRGINIA_HIGHWAY_TRAILERS: t`Virginia Highway Trailers`,
  WALMART_REEFER_TRAILER: t`Walmart Reefer Trailer`,
  WHIRLPOOL_TRAILER: t`Whirlpool Trailer`,
};

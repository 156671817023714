import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';
import { P } from 'base-components';

const modifierConfig = {
  hidden: () => `
    display: none;
  `,
  inline: () => `
    display: inline;
    padding-right: 0.5em;
  `,
};

export default buildStyledComponent(
  'ScheduleGrid__EventBlock__Text',
  P,
  `
    font-size: ${px2rem(14)};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `,
  {
    modifierConfig,
  },
);

/* eslint-disable import/prefer-default-export */

import React from 'react';
import { Trans } from '@lingui/macro';

import { Text } from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';

const renderCellText = (value, options = []) => (
  <Row>
    <Column
      modifiers={['col', 'padScale_0', options.includes('center') && 'center']}
    >
      <Text modifiers={options.includes('small') && ['small', 'textLight']}>
        {value ? <Trans id={`${value}`} /> : <>&mdash;</>}
      </Text>
    </Column>
  </Row>
);

export const buildColumn = (key, options) => {
  const { label, width = 'auto' } = options;
  const { cellOpts = ['small'], headerOpts = ['small'] } = options;

  const thStyle = { background: 'none', padding: px2rem(4), width };

  return {
    name: key,
    cellStyles: { verticalAlign: 'top', padding: px2rem(4) },
    cellKeyGetter: () => key,
    cellDataGetter: (data) => data,
    headerCellRenderer: () => renderCellText(label, headerOpts),
    dataCellRenderer: (data) => renderCellText(data[key], cellOpts),
    headerCellAttrsGetter: () => ({ style: thStyle }),
  };
};

import gql from 'graphql-tag';

export default gql`
  mutation createCaseNoteMutation($body: String!, $caseId: ID!) {
    createCaseNote(input: { body: $body, caseId: $caseId }) {
      caseNote {
        caseId
      }
    }
  }
`;

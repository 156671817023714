import gql from 'graphql-tag';

export default gql`
  mutation updateCaseDelayedService(
    $caseId: ID!
    $patch: UpdateCaseDelayedServicePatch!
  ) {
    updateCaseDelayedService(input: { id: $caseId, patch: $patch }) {
      case {
        id
        isDelayedService
        delayedServiceNotes
        assetLocationTimezone
        delayedServiceScheduledDate
        delayedServiceScheduledDispatchDate
      }
    }
  }
`;

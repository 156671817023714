//-------------------------------------------------------
// The following icons are included as part of the search
// criteria to narrow dealer search, and are also shown
// in the dealer locator list for the dealer:

// Michelin Commercial Service Network (MCSN)
// dealer locator flags: SerNetwork
import commercialServiceNetwork from './commercial-service-network.svg';

// RV Tires / RV Dealer
// dealer locator flags: RVDealer
import rvTires from './rv-tires.svg';

// On Site Service
// dealer locator flags: SerOnsite
import onSiteServices from './on-site-services.svg';

// 24/7/365 Emergency Road Service
// dealer locator flags: ERS247
import roadEmergencyService247 from './24-7-road-emergency-service.svg';

// Towing
// dealer locator flags: ProTow
import towing from './towing.svg';

// Michelin X One Tires
// dealer locator flags: ProXone
import xOneTires from './x-one-tires.svg';

// Retread Dealer / Michelin Retreads
// dealer locator flags: ProMRT
import retreadDealer from './retread-dealer.svg';

// Michelin New Tires / Michelin New
// dealer locator flags: ProNewTire
import newTires from './new-tires.svg';

// Oil & Lube Service
// dealer locator flags: OilLubeService
import oilLubeService from './oil-lube-services.svg';

// Michelin Tire Care
// dealer locator flags: MichTireCare
import tireCare from './tire-care.svg';

// FIXPIX
// dealer locator flags: FIXPIX
import fixpix from './fixpix.svg';

//-------------------------------------------------------
// The following icons are included as part of the search
// criteria to narrow dealer search, and are NOT shown
// in the dealer locator list for the dealer:

// DOT Inspections
import inspections from './inspections.svg';

// Tire Alignment
import tireAlignment from './tire-alignment.svg';

// Travel Plaza (Fuel/Parking)
import travelPlaza from './travel-plaza.svg';

// Trailer Repair
import trailerRpr from './trailer-repair.svg';

// Tire Repair
import tireRepair from './tire-repair.svg';

// Truck Repair
import truckRepair from './truck-repair.svg';

// Automated Tire Inspection
import automatedTireInspection from './automated-tire-inspection.svg';

// Asset Management
import assetManagement from './asset-management.svg';

//-------------------------------------------------------
// The following icons are NOT included as part of the
// search criteria to narrow dealer search, but ARE
// shown in the dealer locator list for the dealer:

// Michelin National Accounts
// dealer locator flags: MichNatAcc
import nationalAccounts from './national-accounts.svg';

//-------------------------------------------------------
// I'm not sure when (if at all) this icon should be used:

// Michelin Advantage Program
// import advantageProgram from './advantage-program.svg';

export const oncallIconImgs = {
  ERS247: roadEmergencyService247,
  MRTAscDealer: retreadDealer,
  MichNatAcc: nationalAccounts,
  MichTireCare: tireCare,
  OilLubeService: oilLubeService,
  RetreadDealer: retreadDealer,
  ProMegaMile: retreadDealer,
  ProMRT: retreadDealer,
  ProNewTires: newTires,
  ProOliver: retreadDealer,
  ProTow: towing,
  ProXone: xOneTires,
  RVDealer: rvTires,
  SerNetwork: commercialServiceNetwork,
  SerOnsite: onSiteServices,
  TireInspection: automatedTireInspection,
  TireRepair: tireRepair,
  TravelPlaza: travelPlaza,
  FIXPIX: fixpix,
};

export function oncallIconImgForCode(code) {
  return oncallIconImgs[code];
}

/**
 * List of all icons used in the dealer search filtering UI.
 */
const dealerSearchIcons = [
  assetManagement,
  automatedTireInspection,
  commercialServiceNetwork,
  inspections,
  newTires,
  oilLubeService,
  onSiteServices,
  retreadDealer,
  roadEmergencyService247,
  rvTires,
  tireAlignment,
  tireCare,
  tireRepair,
  towing,
  trailerRpr,
  travelPlaza,
  truckRepair,
  xOneTires,
];

export default {
  dealerSearchIcons,
  oncallIconImgForCode,
};

import gql from 'graphql-tag';

export const NAME = 'assetValidationPanel_searchCustomersQuery';

export default gql`
  query assetValidationPanel_searchCustomersQuery($shipTo: String!) {
    searchCustomers(filters: { searchString: $shipTo }) {
      id
      shipTo
    }
  }
`;

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

import { NavLink } from '../../elements';

export const styles = ({ theme }) => `
  color: ${theme.colors.base.textLight};
  display: flex;
  flex: 1;
  overflow: hidden;
  text-decoration: none;
  width: calc(100% - ${theme.dimensions.leftNavWidthCollapsed});
`;

export const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      textLight: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dimensions: PropTypes.shape({
    leftNavWidthCollapsed: PropTypes.string.isRequired,
  }).isRequired,
};

export default buildStyledComponent(
  'MenuItemTitleLink',
  styled(NavLink),
  styles,
  { themePropTypes },
);

import gql from 'graphql-tag';

export default gql`
  query manageableDealersQuery($excludeMrtAssociates: Boolean) {
    manageableDealers(excludeMrtAssociates: $excludeMrtAssociates) {
      dealers {
        id
        name
        displayAddress {
          city
          province
        }
        shipTo
        billTo
      }
    }
  }
`;

import moment from 'moment-timezone';

const getTZShorthand = (tz, fallbackValue = '') =>
  tz ? moment.tz(tz).format('z') : fallbackValue;

export function getDateAtAgentTZ(date, timezone, options) {
  if (!date) return '';

  const opts = { asDate: false, ...options };

  const m = moment.utc(date);
  const agentTimezone = moment.tz.guess();

  const finalDate = moment
    .tz(timezone || agentTimezone)
    .year(m.year())
    .month(m.month())
    .date(m.date())
    .hours(m.hours())
    .minutes(m.minutes())
    .tz(agentTimezone);

  return opts.asDate ? finalDate.toDate() : finalDate.format('D MMM, h:mm A z');
}

export function getDateAtAssetTZ(date, timezone, options) {
  const opts = { asDate: false, ...options };

  const tz = getTZShorthand(timezone);
  const finalDate = moment.utc(date);

  return opts.asDate ? finalDate : `${finalDate.format('D MMM, h:mm A')} ${tz}`;
}

import { generateConstants } from '../../utils/arrow';

// Ideally, this should be 12px,
// but because of browser rendering inconsistencies, I added a little buffer.
const ARROW_BASE = 12.5;

// Browsers have issues with partial pixels and cause the Popover Arrow to render
// _slightly_ off. The 1px offset here is to accommodate for that.
const BROWSER_OFFSET = 1;

export default {
  ARROW_BASE,
  BROWSER_OFFSET,
  ...generateConstants({ ARROW_BASE, BROWSER_OFFSET }),
};

import React from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import ErrorPage from 'pages/ErrorPage';
import AccessDenied from 'pages/AccessDenied';
import AppVersionCheck from 'compositions/AppVersionCheck';
import withGoogleMapJS from 'features/googleMaps/withGoogleMapJS';
import { AmazonConnectProvider } from 'features/amazonConnect';
import { KeyShortcutsProvider } from 'features/keyShortcuts';

import Routes from './Routes';
import I18nProvider from './I18nProvider';
import FocusProvider from './FocusProvider';
import configureStore from './store';
import ApolloConnector from './ApolloConnector';
import AppThemeProvider from './AppThemeProvider';
import initLocalStorage from './localStorage';
import registerServiceWorker, {
  unregister as unregisterServiceWorker,
} from './serviceWorker';

import { ErrorBoundary } from './BugSnag';
import { installHotjar } from './Hotjar';

// Usage: https://github.com/welldone-software/why-did-you-render#tracking-components
if (process.env.NODE_ENV === 'development') {
  require('@welldone-software/why-did-you-render')(React);
}

// Initialize local storage
initLocalStorage();

// Initialize redux store
const initialState = fromJS({});
const store = configureStore(initialState);

/**
 * SetupComponent is exported for use in testing. This component
 * includes all the setup required to enable mounting with Enzyme.
 * @param {object} props
 */
function SetupComponent({ children, store }) {
  return (
    <AppThemeProvider>
      <StoreProvider store={store}>
        <FocusProvider>
          <I18nProvider>
            <BrowserRouter>
              <AmazonConnectProvider>
                <Switch>
                  <Route exact path="/access-denied" component={AccessDenied} />
                  <Route
                    path="*"
                    render={() => (
                      <ApolloConnector>
                        <KeyShortcutsProvider>{children}</KeyShortcutsProvider>
                      </ApolloConnector>
                    )}
                  />
                </Switch>
              </AmazonConnectProvider>
            </BrowserRouter>
          </I18nProvider>
        </FocusProvider>
      </StoreProvider>
    </AppThemeProvider>
  );
}

SetupComponent.propTypes = {
  children: PropTypes.node.isRequired,
  store: PropTypes.shape({
    dispatch: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired,
  }).isRequired,
};

/**
 * Component shown as an error boundary fallback should there be an error
 * during the top-most setup components in the React component tree (the
 * components in {@link SetupComponent}.
 *
 * This component cannot use our standard layout or grid components (because
 * the theme values aren't available for the grid), I18n (because the
 * I18n dictionaries are not loaded when this is rendered), etc.
 */
function InitializationErrorMessage() {
  return (
    <div style={{ textAlign: 'center', padding: '2rem' }}>
      <h1>Application Error</h1>
      <p>An error occurred while loading the application.</p>
      <p>
        <a href="/">Please try again.</a>
      </p>
    </div>
  );
}

// Preload the Google Maps API, to improve the perceived
// performance when navigating to a page that requires it
const GoogleMapsPreloader = withGoogleMapJS(() => null);

/**
 * App is the component actually rendered to display the complete and
 * functional application. It is the SetupComponent, plus provider
 * components that are not-test-env-friendly, plus the router.
 *
 * Note that the App component tree contains two instances of the Bugsnag
 * ErrorBoundary: one outside the SetupComponent to catch any errors
 * at the highest level of the tree, during initialization; one inside
 * the setup component to catch any application errors at that level.
 *
 * The ErrorBoundary that wraps SetupComponent cannot render the
 * standard ErrorPage since the standard ErrorPage makes use of
 * the router, app theming, etc, none of which are available at
 * that level of the component tree. Instead it renders the very
 * simple {@link InitializationErrorMessage}.
 *
 * The ErrorBoundary inside SetupComponent does have access to the router
 * and therefore renders the standard ErrorPage when an error is caught.
 * @param {object} props
 */
function App({ store }) {
  return (
    <ErrorBoundary FallbackComponent={InitializationErrorMessage}>
      <SetupComponent store={store}>
        <ErrorBoundary FallbackComponent={ErrorPage}>
          <Routes />
          <GoogleMapsPreloader />
          <AppVersionCheck />
        </ErrorBoundary>
      </SetupComponent>
    </ErrorBoundary>
  );
}

App.propTypes = {
  store: PropTypes.shape({
    dispatch: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired,
  }).isRequired,
};

export {
  store,
  configureStore,
  initLocalStorage,
  installHotjar,
  registerServiceWorker,
  unregisterServiceWorker,
  SetupComponent,
};

export default App;

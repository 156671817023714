import React from 'react';
import PropTypes from 'prop-types';
import { reduce } from 'lodash';

import AvatarBlock from 'base-components/blocks/Avatar';
import { Avatar } from 'base-components';
import { buildStyledComponent } from 'decisiv-ui-utils';

import {
  AmazonConnect,
  modifiers,
  modifierToColors,
  uiStateToModifier,
} from 'features/amazonConnect';

const modifierConfig = reduce(
  modifiers,
  (acc, modifier) => ({
    ...acc,
    [modifier]: () => {
      const { normal } = modifierToColors(modifier);
      return `
        display: block;
        background-color: ${normal};
      `;
    },
  }),
  {},
);

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      background: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

/**
 * Extend the base Avatar Status styled-component to allow for
 * custom status colors.
 */
const SoftPhoneStatus = buildStyledComponent(
  'SoftPhoneStatus',
  AvatarBlock.Status,
  ``,
  {
    modifierConfig,
    themePropTypes,
  },
);

/**
 * User avatar for the top nav with a custom status "dot" indicating
 * the current Amazon Connect status.
 */
export default function UserAvatar({ email }) {
  return (
    <AmazonConnect>
      {({ uiState }) => (
        <Avatar
          email={email}
          modifiers={['small', 'secondary']}
          status={<SoftPhoneStatus modifiers={[uiStateToModifier(uiState)]} />}
        />
      )}
    </AmazonConnect>
  );
}

UserAvatar.propTypes = {
  email: PropTypes.string,
};

UserAvatar.defaultProps = {
  email: '',
};

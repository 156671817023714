import gql from 'graphql-tag';

export default gql`
  mutation updateCaseInboundProgramShipTo(
    $caseId: ID!
    $inboundProgramShipTo: String
  ) {
    updateCase(
      input: {
        id: $caseId
        patch: { inboundProgramShipTo: $inboundProgramShipTo }
      }
    ) {
      case {
        id
        inboundProgramShipTo
        inboundProgramNumber(useCachedDataForCase: true) {
          id
          isUsxpress
          inboundProgramAccountType
          locations {
            ... on Dealer {
              id
              dealerName: name
              billTo
              shipTo
              displayAddress {
                city
                province
              }
            }
            ... on Customer {
              id
              customerName: name
              type
              billTo
              city
              shipTo
              state
            }
          }
        }
      }
    }
  }
`;

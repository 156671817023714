import PropTypes from 'prop-types';
import React from 'react';

import StyledLeftNav from '../../blocks/LeftNav';

import MenuSubItem from './MenuSubItem';
import menuPropTypes from './menuPropTypes';

function MenuSubItemsList({
  TextRenderer,
  isFlyoutOpen,
  isMenuExpanded,
  location,
  menuSubItems,
}) {
  // NOTE: Use the BEM classnames as a guide to build the components.
  return (
    <StyledLeftNav.MenusList>
      {(isMenuExpanded || isFlyoutOpen) &&
        menuSubItems.map((menuSubItem) => (
          <MenuSubItem
            key={menuSubItem.path || menuSubItem.url}
            location={location}
            menuSubItem={menuSubItem}
            TextRenderer={TextRenderer}
          />
        ))}
    </StyledLeftNav.MenusList>
  );
}

MenuSubItemsList.propTypes = {
  TextRenderer: PropTypes.func.isRequired,
  isFlyoutOpen: PropTypes.bool.isRequired,
  isMenuExpanded: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/no-typos
  menuSubItems: menuPropTypes.menuSubItems,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

MenuSubItemsList.defaultProps = {
  menuSubItems: [],
};

export default MenuSubItemsList;

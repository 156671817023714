import { castArray } from 'lodash';

export default (newValues = {}, oldValues = {}, keysToCheck = []) => {
  const statePatch = castArray(keysToCheck).reduce(
    (patch, key) =>
      newValues[key] !== oldValues[key]
        ? { ...patch, [key]: newValues[key] }
        : patch,
    {},
  );

  return Object.keys(statePatch).length ? statePatch : null;
};

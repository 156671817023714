import React, { Component } from 'react';
import qs from 'qs';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { isEqual } from 'lodash';
import { withRouter } from 'react-router';
import { compose, setDisplayName } from 'recompose';
import { i18n } from '@lingui/core';

import { Container, Row, Column } from 'styled-components-grid';

import Page from 'blocks/Page';
import CasesSearch from 'compositions/CasesSearch';
import TimedoutNoticeModal from 'compositions/UserInactivityCheck/TimedoutNoticeModal';

import { pageTitles, pageMeta } from '../constants';
import { cleanObject, paramsForMode, urlParamsForNode } from './utils';

export class CasesPage extends Component {
  static propTypes = {
    mode: PropTypes.oneOf(['default', 'highlightedOnly']),
    history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
    location: PropTypes.shape({ search: PropTypes.string.isRequired })
      .isRequired,
  };

  static defaultProps = { mode: 'default' };

  handleParamsChange = (newParams) => {
    const currentParams = paramsForMode(this.props);

    if (isEqual(cleanObject(newParams), cleanObject(currentParams))) {
      // No need to push a new location state. Prevents the user from
      // having to navigate back several times through equal states.
      this.setState({});
    } else {
      const params = urlParamsForNode(this.props, {
        ...currentParams,
        ...newParams,
      });

      this.props.history.push({
        search: qs.stringify(params, { encodeValuesOnly: true }),
      });
    }
  };

  render() {
    const { mode } = this.props;
    const currentParams = paramsForMode(this.props);

    const { timedoutCase } = currentParams;

    return (
      <CasesSearch
        {...currentParams}
        mode={mode}
        onChangeSearchParams={this.handleParamsChange}
      >
        <Helmet>
          <title>{i18n._(pageTitles.dashboard)}</title>
          <meta name="description" content={i18n._(pageMeta.dashboard)} />
        </Helmet>
        <Page modifiers={['fluid', 'height_100']} style={{ maxHeight: '100%' }}>
          <Container modifiers={['flex_column', 'height_100', 'fluid']}>
            <Row modifiers="padScaleX_5" style={{ flexShrink: 0 }}>
              <Column modifiers={['col', 'padScaleY_4']}>
                <CasesSearch.Filters />
              </Column>
            </Row>
            <CasesSearch.Results />
          </Container>
        </Page>
        {!!timedoutCase && <TimedoutNoticeModal caseNumber={timedoutCase} />}
      </CasesSearch>
    );
  }
}

export default compose(setDisplayName('CasesPage'), withRouter)(CasesPage);

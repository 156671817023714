import React from 'react';
import PropTypes from 'prop-types';
import { defineMessage, Trans } from '@lingui/macro';
import { get, isEmpty } from 'lodash';

import { H3, Text } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import Dot from 'elements/Dot';

import {
  CREDIT_CARD_RULE_TYPES,
  PAYMENT_METHODS,
} from 'compositions/CasePaymentPanel/constants';

/**
 * Valid values for the `PaymentMethod` type in the GraphQL API.
 */
export const PAYMENT_METHOD_LABELS = {
  [PAYMENT_METHODS.CARD_ON_FILE]: defineMessage({
    message: 'Existing Credit Card',
  }),
  [PAYMENT_METHODS.CASH]: defineMessage({ message: 'Cash' }),
  [PAYMENT_METHODS.COM_CHECK]: defineMessage({ message: 'COMcheck' }),
  [PAYMENT_METHODS.CREDIT_CARD_NO_PREAUTH]: defineMessage({
    message: 'Credit Card - No Preauth',
  }),
  [PAYMENT_METHODS.MICHELIN_LINE_OF_CREDIT]: defineMessage({
    message: 'National Account',
  }),
  [PAYMENT_METHODS.NONE]: defineMessage({
    message: 'Continue without payment method',
  }),
  [PAYMENT_METHODS.ONE_TIME_CARD]: defineMessage({
    message: 'New Credit Card',
  }),
  [PAYMENT_METHODS.OTHER_NATIONAL_ACCOUNT]: defineMessage({
    message: 'Other National Account',
  }),
  [PAYMENT_METHODS.STORE_ACCOUNT]: defineMessage({
    message: 'Store Account {accountNumber}',
  }),
  [PAYMENT_METHODS.THIRD_PARTY_CREDIT_CARD_TXN]: defineMessage({
    message: 'Credit Card',
  }),
};

/**
 * Under certain conditions a customer's payment method should default to the
 * Michelin Line of Credit.
 * @param {object} caseData
 */
function getPaymentMethod(caseData) {
  const abbr = get(
    caseData,
    'customer.billingPreferences.creditCardRuleAbbreviation',
  );
  const paymentMethod = get(caseData, 'paymentMethod');

  const creditCardOptional =
    CREDIT_CARD_RULE_TYPES[abbr] === CREDIT_CARD_RULE_TYPES.O;

  if (creditCardOptional && !paymentMethod) {
    return PAYMENT_METHODS.MICHELIN_LINE_OF_CREDIT;
  }

  return paymentMethod;
}

function Payment({ caseData }) {
  const accountNumber = get(caseData, 'customer.accountNumber');
  const tokenizedCreditCard = get(caseData, 'tokenizedCreditCard') || {};

  const paymentMethod = getPaymentMethod(caseData);

  const {
    expirationMonth,
    expirationYear,
    firstDigit,
    last4Digits,
    name,
  } = tokenizedCreditCard;

  const expiration = `${expirationMonth}/${expirationYear}`;

  return (
    <Container modifiers={['padScale_2']}>
      <Row>
        <Column modifiers={['padScaleY_2']}>
          <H3 modifiers={['fontWeightRegular']}>
            <Trans>Payment</Trans>
          </H3>
        </Column>
      </Row>
      {!paymentMethod && (
        <Row>
          <Column>
            <Text>&mdash;</Text>
          </Column>
        </Row>
      )}
      {paymentMethod && (
        <>
          <Row>
            <Column modifiers={['padScaleY_0']}>
              <Text>
                <Trans
                  id={PAYMENT_METHOD_LABELS[paymentMethod]}
                  values={{ accountNumber }}
                />
              </Text>
            </Column>
          </Row>
          {!isEmpty(tokenizedCreditCard) && (
            <>
              <Row>
                <Column modifiers={['padScaleY_0']}>
                  <Text>{name}</Text>
                </Column>
              </Row>
              <Row>
                <Column modifiers={['padScaleY_0']}>
                  <Text>
                    {firstDigit}
                    <Dot repeat={[3, 4, 4, 0]} />
                    {last4Digits}
                  </Text>
                </Column>
              </Row>
              <Row>
                <Column modifiers={['padScaleY_0']}>
                  <Text>
                    <Trans>Expires {expiration}</Trans>
                  </Text>
                </Column>
              </Row>
            </>
          )}
        </>
      )}
    </Container>
  );
}

Payment.propTypes = {
  caseData: PropTypes.shape({
    customer: PropTypes.shape({
      accountNumber: PropTypes.string,
    }),
    paymentMethod: PropTypes.oneOf(Object.keys(PAYMENT_METHOD_LABELS)),
    tokenizedCreditCard: PropTypes.shape({
      expirationMonth: PropTypes.number,
      expirationYear: PropTypes.number,
      firstDigit: PropTypes.string,
      last4Digits: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
};

export default Payment;

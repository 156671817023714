import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { includes } from 'lodash';

import TooltipBlock from '../../blocks/Tooltip';

import { TOOLTIP_CONTEXT } from './constants';
import Content from './Content';
import Target from './Target';

class Tooltip extends Component {
  static Content = Content;

  static Target = Target;

  static modifiers = TooltipBlock.modifiers;

  static childContextTypes = {
    [TOOLTIP_CONTEXT]: PropTypes.shape({}).isRequired,
  };

  static propTypes = {
    children: PropTypes.node.isRequired,
    position: PropTypes.oneOf(['bottom', 'left', 'right', 'top']),
    zIndex: PropTypes.number,
  };

  static defaultProps = {
    zIndex: 1,
    position: 'top',
  };

  state = {
    showTooltip: false,
  };

  getChildContext() {
    return {
      [TOOLTIP_CONTEXT]: {
        onMouseEnterTarget: this.onMouseEnterTarget,
        onMouseLeaveTarget: this.onMouseLeaveTarget,
        position: this.props.position,
        showTooltip: this.state.showTooltip,
        zIndex: this.props.zIndex,
      },
    };
  }

  onMouseEnterTarget = () => {
    this.setState({ showTooltip: true });
  };

  onMouseLeaveTarget = () => {
    this.setState({ showTooltip: false });
  };

  filteredModifiers = () => {
    const { position } = this.props;
    const tooltipModifiers = Object.keys(TooltipBlock.modifiers);
    return includes(tooltipModifiers, position) ? [position] : [];
  };

  render() {
    const { children, ...rest } = this.props;

    return (
      <TooltipBlock {...rest} modifiers={this.filteredModifiers()}>
        {children}
      </TooltipBlock>
    );
  }
}

export default Tooltip;

import { curry } from 'lodash';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import gql from 'graphql-tag';

import buildRefetchQueries from './buildRefetchQueries';

const mutation = gql`
  mutation replaceAfterHoursContact($sourceId: ID!, $targetId: ID!) {
    replaceAfterHoursContact(
      input: { sourceId: $sourceId, targetId: $targetId }
    ) {
      contact {
        id
      }
    }
  }
`;

export const buildMutationChild = curry(
  (
    WrappedComponent,
    componentProps,
    replaceAfterHoursContact,
    mutationProps,
  ) => (
    <WrappedComponent
      {...componentProps}
      isReplacingAfterHoursContact={!!mutationProps?.loading}
      replaceAfterHoursContact={(variables) =>
        replaceAfterHoursContact({
          variables,
        })
      }
    />
  ),
);

function buildWrappedComponentWithMutation(WrappedComponent, componentProps) {
  return (
    <Mutation
      mutation={mutation}
      refetchQueries={buildRefetchQueries(componentProps)}
    >
      {buildMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

const withReplaceAfterHoursContact = (WrappedComponent) => (componentProps) =>
  buildWrappedComponentWithMutation(WrappedComponent, componentProps);

export default withReplaceAfterHoursContact;

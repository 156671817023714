import PropTypes from 'prop-types';
import React from 'react';
import { Marker } from 'react-google-maps';

import { mapIcons } from 'assets/images/mapIcons';

function DestinationMarker({ destination, zIndex, isFinalDestination }) {
  return (
    <Marker
      icon={{
        url: isFinalDestination
          ? mapIcons.destination
          : mapIcons.intermediaryDestination,
      }}
      position={{
        lat: destination.latitude,
        lng: destination.longitude,
      }}
      zIndex={zIndex}
    />
  );
}

DestinationMarker.propTypes = {
  destination: PropTypes.shape({
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
  }).isRequired,
  zIndex: PropTypes.number.isRequired,
  isFinalDestination: PropTypes.bool,
};

DestinationMarker.defaultProps = {
  isFinalDestination: true,
};

export default DestinationMarker;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { get, noop } from 'lodash';
import ReactInput from 'input-format/react';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import { formatPhone } from 'utils/format';

import { parse, format } from './utils';

const StyledInput = buildStyledComponent(
  'StyledPhoneNumberInput',
  styled(ReactInput),
  ({ theme }) => css`
    border: none;
    color: ${theme.colors.base.text};
    flex: 1 1 auto;
    font-size: ${px2rem(12)};
    line-height: ${px2rem(20)};
    outline: none;
    padding: 0;
    width: 100%;

    &:read-only {
      background-color: inherit;
      cursor: not-allowed;
    }

    &::placeholder {
      color: ${theme.colors.base.chrome300};
    }
  `,
);

// We hold our copy of value so that we can
// force the national format if its country is US.
export default class PhoneNumberInput extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    innerRef: PropTypes.func,
  };

  static defaultProps = {
    onChange: noop,
    readOnly: false,
    innerRef: noop,
  };

  constructor(props) {
    super(props);

    this.state = { value: formatPhone(props.value) };
  }

  UNSAFE_componentWillReceiveProps({ value }) {
    if (value !== this.props.value) {
      this.setState({ value: formatPhone(value) });
    }
  }

  storeRef = (inputFormatInstance) => {
    this.props.innerRef(get(inputFormatInstance, 'input') || null);
  };

  handleChange = (value) =>
    this.setState({ value }, () => this.props.onChange(value));

  render() {
    const { readOnly } = this.props;
    const { innerRef, ...rest } = this.props;
    const renderEmptyReadOnly = readOnly && !this.state.value;

    return (
      <StyledInput
        {...rest}
        ref={this.storeRef}
        value={renderEmptyReadOnly ? '—' : this.state.value}
        parse={renderEmptyReadOnly ? (c) => c : parse}
        format={
          renderEmptyReadOnly ? () => ({ text: '—', template: '—' }) : format
        }
        onChange={this.handleChange}
        readOnly={readOnly}
      />
    );
  }
}

import { includes, lowerCase } from 'lodash';

const TRUTHY_VALUES = ['true', 'yes', 't', 'y'];

/**
 * Checks a given environment variable value to determine if it contains
 * a "truthy" configuration value (true, yes, t, or y).
 * @param {String} key - the name of the environment variable
 * @returns {boolean} `true` if the env var is truthy, `false` otherwise.
 */
// eslint-disable-next-line import/prefer-default-export
export function configEnabled(key) {
  return includes(TRUTHY_VALUES, lowerCase(process.env[key]));
}

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

const styles = ({ theme }) => `
  background: ${theme.colors.base.chrome100};
  width: 100%;
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome100: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default buildStyledComponent('Table__Head', styled.thead, styles, {
  themePropTypes,
});

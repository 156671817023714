import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import { get } from 'lodash';
import { Trans } from '@lingui/macro';

import AfterHoursInfoPopup from '../AfterHoursInfoPopup';

import { EventPropType, AfterHoursContactPropType } from '../../propTypes';

import ScheduledEvent from './index';
import EventBlockHeader from './EventBlockHeader';
import EventBlockText from './EventBlockText';

function AfterHoursContact({ contact }) {
  if (contact && contact.name) {
    return (
      <EventBlockText>
        {contact.name}
        {contact.jobTitle && ` (${contact.jobTitle})`}
      </EventBlockText>
    );
  }
  return null;
}

AfterHoursContact.propTypes = {
  contact: AfterHoursContactPropType.isRequired,
};

export default function AfterHoursEvent(props) {
  const { day, event, dayIndex, isReadOnly, ...rest } = props;

  const duration = moment(event.blockEnd).diff(event.blockStart, 'minutes');
  const headerModifiers = [
    'ersHours',
    duration <= 30 && 'hidden',
    duration > 30 && duration <= 60 && 'inline',
    duration > 30 && duration <= 60 && 'small',
  ];

  return (
    <ScheduledEvent
      id={get(event, 'afterHoursEvent.id')}
      day={day}
      type="ersHours"
      event={event}
      bodyWrapper={(body) => (
        <AfterHoursInfoPopup
          rotation={event}
          dealerTimezone={day.tz()}
          dayIndex={dayIndex}
          isReadOnly={isReadOnly}
        >
          {body}
        </AfterHoursInfoPopup>
      )}
      {...rest}
    >
      <EventBlockHeader modifiers={headerModifiers}>
        <Trans>ERS</Trans>
      </EventBlockHeader>
      {duration > 120 && (
        <>
          {event.primaryContact && (
            <AfterHoursContact contact={event.primaryContact} />
          )}
          {event.backupContact && (
            <AfterHoursContact contact={event.backupContact} />
          )}
          {event.finalContact && (
            <AfterHoursContact contact={event.finalContact} />
          )}
        </>
      )}
    </ScheduledEvent>
  );
}

AfterHoursEvent.propTypes = {
  /* eslint-disable react/no-typos */
  day: MomentPropTypes.momentObj.isRequired,
  event: EventPropType.isRequired,
  /* eslint-enable react/no-typos */
  dayIndex: PropTypes.number.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};

import React, { PureComponent, createContext } from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName } from 'recompose';
import { get, omit, pick, isEqual, isUndefined } from 'lodash';

import {
  CASE_PANELS,
  withReadOnlyCase,
  withCasePanelStatusActions,
} from 'compositions/CaseStatus';

import patchFromChangedProps from 'utils/patchFromChangedProps';

import withCaseStatus from './withCaseStatus';
import withCaseAssets from './withCaseAssets';
import withCaseRequestLines from './withCaseRequestLines';
import withRequestLinesActions from './withRequestLinesActions';
import withRequestAgreementLinesActions from './withRequestAgreementLinesActions';
import withRequestSuppliedLinesActions from './withRequestSuppliedLinesActions';
import withCaseSpecialInstructions from './withCaseSpecialInstructions';
import withCaseDelayedService from '../withCaseDelayedService';
import withRequestAssetActions from './withRequestAssetActions';
import DelayedServiceModal from './DelayedServiceModal';

import withRequestLineOptions, {
  requestLineOptionsPropType,
} from './withRequestLineOptions';

import {
  getItemById,
  addTireOptions,
  findByPropPathValue,
  areRequestLinesEqual,
  getActiveRequestsTab,
  getShouldShowAgreedTab,
  getNewStatusPanelStatus,
  getShouldShowSuppliedTab,
  getAllGenericTireOptions,
} from './utils';

import {
  emptyRequestLine,
  delayedServiceModalModes,
  rimReplacementProductType,
  rimDamagedProductCondition,
  lineKeysToClearOnDuplicateToRim,
} from './constants';

export const Context = createContext();

const { Provider, Consumer } = Context;

export class CaseRequestsPanelContext extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    caseId: PropTypes.string,
    caseNumber: PropTypes.string.isRequired,
    caseStatus: PropTypes.string.isRequired,
    isReadOnlyCase: PropTypes.bool.isRequired,
    requestLineOptions: requestLineOptionsPropType.isRequired,
    isLoadingRequestLineOptions: PropTypes.bool.isRequired,
    specialInstructions: PropTypes.string,
    setCaseSpecialInstructions: PropTypes.func.isRequired,
    isLoadingRequestLines: PropTypes.bool.isRequired,
    requestLines: PropTypes.arrayOf(PropTypes.shape({})),
    requestAgreementLines: PropTypes.arrayOf(
      PropTypes.shape({
        agreed: PropTypes.bool,
        agreementLine: PropTypes.shape({}).isRequired,
        requestLineId: PropTypes.string,
      }),
    ),
    requestSuppliedLines: PropTypes.arrayOf(
      PropTypes.shape({
        supplied: PropTypes.bool,
        suppliedLine: PropTypes.shape({}).isRequired,
        agreementLineId: PropTypes.string,
      }),
    ),
    createRequestedLine: PropTypes.func.isRequired,
    updateRequestedLine: PropTypes.func.isRequired,
    deleteRequestedLine: PropTypes.func.isRequired,
    createRequestAgreementLine: PropTypes.func.isRequired,
    updateRequestAgreementLine: PropTypes.func.isRequired,
    removeRequestAgreementLine: PropTypes.func.isRequired,
    resetRequestAgreementLine: PropTypes.func.isRequired,
    resetAllRequestAgreementLines: PropTypes.func.isRequired,
    createRequestSuppliedLine: PropTypes.func.isRequired,
    updateRequestSuppliedLine: PropTypes.func.isRequired,
    removeRequestSuppliedLine: PropTypes.func.isRequired,
    resetRequestSuppliedLine: PropTypes.func.isRequired,
    resetAllRequestSuppliedLines: PropTypes.func.isRequired,

    /* eslint-disable react/no-unused-prop-types */
    // These are used by updateStatusPanelStatus below,
    // but eslint's static analysis can't figure it out.
    assets: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    isSavingAssets: PropTypes.bool.isRequired,
    isLoadingAssets: PropTypes.bool.isRequired,
    setCasePanelStatus: PropTypes.func.isRequired,
    updateAsset: PropTypes.func.isRequired,
    deleteAssets: PropTypes.func.isRequired,
    createPrimaryAsset: PropTypes.func.isRequired,
    createRelatedAsset: PropTypes.func.isRequired,

    // used by some utils
    statusHistory: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    /* eslint-enable react/no-unused-prop-types */

    isDelayedService: PropTypes.bool,
    delayedServiceNotes: PropTypes.string,
    assetLocationTimezone: PropTypes.string,
    updateCaseDelayedService: PropTypes.func.isRequired,
    delayedServiceScheduledDate: PropTypes.string,
    delayedServiceScheduledDispatchDate: PropTypes.string,
  };

  static defaultProps = {
    caseId: null,
    requestLines: null,
    requestAgreementLines: null,
    requestSuppliedLines: null,
    specialInstructions: null,
    isDelayedService: false,
    delayedServiceNotes: null,
    assetLocationTimezone: null,
    delayedServiceScheduledDate: null,
    delayedServiceScheduledDispatchDate: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      caseId: props.caseId,
      caseNumber: props.caseNumber,
      caseStatus: props.caseStatus,
      requestLineOptions: props.requestLineOptions,
      isLoadingRequestLineOptions: props.isLoadingRequestLineOptions,
      isReadOnlyCase: props.isReadOnlyCase,

      requestLines: props.requestLines,
      addRequestLine: this.addRequestLine,
      cancelRemoveRequestLine: this.cancelRemoveRequestLine,
      confirmDeleteOfRequestLineId: undefined,
      duplicateRequestLine: this.duplicateRequestLine,
      isLoadingRequestLines: this.props.isLoadingRequestLines,
      removeRequestLine: this.removeRequestLine,
      updateRequestLine: this.updateRequestLine,

      requestAgreementLines: props.requestAgreementLines,
      anyRequestAgreementLineHasChanges: this.anyRequestAgreementLineHasChanges,
      requestAgreementLineHasChanges: this.requestAgreementLineHasChanges,
      resetRequestAgreementLine: this.resetRequestAgreementLine,
      resetRequestAgreementLines: this.resetRequestAgreementLines,
      shouldDisplayAgreementTableReset: this.shouldDisplayAgreementTableReset,
      toggleRequestAgreementLineStatus: this.toggleRequestAgreementLineStatus,
      addRequestAgreementLine: this.addRequestAgreementLine,
      updateRequestAgreementLine: this.updateRequestAgreementLine,
      removeRequestAgreementLine: this.removeRequestAgreementLine,

      requestSuppliedLines: props.requestSuppliedLines,
      anyRequestSuppliedLineHasChanges: this.anyRequestSuppliedLineHasChanges,
      requestSuppliedLineHasChanges: this.requestSuppliedLineHasChanges,
      resetRequestSuppliedLine: props.resetRequestSuppliedLine,
      resetAllRequestSuppliedLines: this.resetAllRequestSuppliedLines,
      shouldDisplaySuppliedTableReset: this.shouldDisplaySuppliedTableReset,
      toggleRequestSuppliedLineStatus: this.toggleRequestSuppliedLineStatus,
      addRequestSuppliedLine: this.addRequestSuppliedLine,
      updateRequestSuppliedLine: this.updateRequestSuppliedLine,
      removeRequestSuppliedLine: this.removeRequestSuppliedLine,

      specialInstructions: props.specialInstructions,
      updateSpecialInstructions: this.updateSpecialInstructions,

      activeRequestsTab: getActiveRequestsTab(props),
      shouldShowAgreedTab: getShouldShowAgreedTab(props),
      shouldShowSuppliedTab: getShouldShowSuppliedTab(props),
      setActiveRequestsTab: this.setActiveRequestsTab,
      setUnitNumberInvalidStatus: this.setUnitNumberInvalidStatus,
      setAssetTypeInvalidStatus: this.setAssetTypeInvalidStatus,

      tireOptionsByType: {},
      genericTireOptions: [],
      addTireOptionsOfType: this.addTireOptionsOfType,
      resetTireOptionsOfTypes: this.resetTireOptionsOfTypes,
      unitNumberInvalidStatus: {},
      assetTypeInvalidStatus: {},

      isDelayedService: props.isDelayedService,
      delayedServiceNotes: props.delayedServiceNotes,
      assetLocationTimezone: props.assetLocationTimezone,
      updateCaseDelayedService: props.updateCaseDelayedService,
      showDelayedServiceModal: this.showDelayedServiceModal,
      delayedServiceModalMode: delayedServiceModalModes.default,
      shouldShowDelayedServiceModal: false,

      assets: props.assets,
      isSavingAssets: props.isSavingAssets,
      isLoadingAssets: props.isLoadingAssets,
      updateAsset: props.updateAsset,
      deleteAssets: props.deleteAssets,
      createPrimaryAsset: props.createPrimaryAsset,
      createRelatedAsset: props.createRelatedAsset,
      delayedServiceScheduledDate: props.delayedServiceScheduledDate,
      delayedServiceScheduledDispatchDate:
        props.delayedServiceScheduledDispatchDate,
    };
  }

  componentDidMount() {
    this.updateStatusPanelStatus(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let patch = patchFromChangedProps(nextProps, this.props, [
      'caseId',
      'caseStatus',
      'isLoadingRequestLineOptions',
      'isLoadingRequestLines',
      'requestAgreementLines',
      'requestSuppliedLines',
      'requestLineOptions',
      'specialInstructions',
      'isReadOnlyCase',
      'isDelayedService',
      'delayedServiceNotes',
      'assetLocationTimezone',
      'updateCaseDelayedService',
      'assets',
      'isSavingAssets',
      'isLoadingAssets',
      'delayedServiceScheduledDate',
      'delayedServiceScheduledDispatchDate',
    ]);

    const { caseStatus } = patch || {};
    const { requestLines: newRequestLines } = nextProps;

    if (!isEqual(this.props.requestLines, newRequestLines)) {
      patch = { ...patch, requestLines: newRequestLines };
    }

    if (caseStatus) {
      patch.activeRequestsTab = getActiveRequestsTab(nextProps);
      patch.shouldShowAgreedTab = getShouldShowAgreedTab(nextProps);
      patch.shouldShowSuppliedTab = getShouldShowSuppliedTab(nextProps);
    }

    if (patch) this.setState(patch);
  }

  componentDidUpdate() {
    this.updateStatusPanelStatus(this.props);
  }

  setActiveRequestsTab = (index) => this.setState({ activeRequestsTab: index });

  setUnitNumberInvalidStatus = (name, status) => {
    const { unitNumberInvalidStatus: curValues } = this.state;

    this.setState(
      { unitNumberInvalidStatus: { ...curValues, [name]: status } },
      () => this.updateStatusPanelStatus(this.props),
    );
  };

  setAssetTypeInvalidStatus = (name, status) => {
    const { assetTypeInvalidStatus } = this.state;

    this.setState(
      { assetTypeInvalidStatus: { ...assetTypeInvalidStatus, [name]: status } },
      () => this.updateStatusPanelStatus(this.props),
    );
  };

  addTireOptionsOfType = (type, options, lineId) => {
    const { tireOptionsByType, genericTireOptions } = this.state;
    const currentOptions = get(tireOptionsByType, lineId, {});
    const finalOptions = addTireOptions(currentOptions, type, options);

    if (finalOptions !== currentOptions) {
      const newTireOptionsByType = {
        ...tireOptionsByType,
        [lineId]: finalOptions,
      };

      const allGenericOptions = getAllGenericTireOptions(newTireOptionsByType);

      const newOptions =
        allGenericOptions.join('') !== genericTireOptions.join('')
          ? allGenericOptions
          : genericTireOptions;

      this.setState({
        tireOptionsByType: newTireOptionsByType,
        genericTireOptions: newOptions,
      });
    }
  };

  resetTireOptionsOfTypes = (types, lineId) => {
    const { tireOptionsByType, genericTireOptions } = this.state;
    const currentOptions = get(tireOptionsByType, lineId, {});

    tireOptionsByType[lineId] = omit(currentOptions, types);

    const newGenericOptions = getAllGenericTireOptions(tireOptionsByType);

    this.setState({
      tireOptionsByType,
      genericTireOptions:
        newGenericOptions.join('') !== genericTireOptions.join('')
          ? newGenericOptions
          : genericTireOptions,
    });
  };

  updateStatusPanelStatus = (props) => {
    const [status, fields] = getNewStatusPanelStatus(props, this.state);

    props.setCasePanelStatus(status, fields);
  };

  // Request lines

  addRequestLine = () => {
    const { caseId } = this.props;

    return this.props.createRequestedLine(caseId, {});
  };

  cancelRemoveRequestLine = () => {
    this.setState({ confirmDeleteOfRequestLineId: undefined });
  };

  removeRequestLine = (requestLineId) => {
    const { requestAgreementLines: lines } = this.state;

    const line = findByPropPathValue(lines, 'requestLineId', requestLineId);

    if (line && line.agreed) {
      // If a request is agreed to and the delete is confirmed, this same
      // function will handle deleting it and resetting the state.
      if (this.state.confirmDeleteOfRequestLineId) {
        this.setState({ confirmDeleteOfRequestLineId: undefined });
        return this.props.deleteRequestedLine(requestLineId);
      } else {
        // If a request is agreed to, require a confirmation of the delete.
        this.setState({ confirmDeleteOfRequestLineId: requestLineId });
      }
    } else {
      return this.props.deleteRequestedLine(requestLineId);
    }
  };

  updateRequestLine = (id, values) => {
    return this.props.updateRequestedLine({ id, ...values });
  };

  duplicateRequestLine = (id, options = {}) => {
    const { caseId } = this.props;

    const baseLine = getItemById(this.state.requestLines, id);
    const newLine = { ...baseLine, assetId: get(baseLine, 'asset.id') };

    if (options.type !== 'rim') {
      newLine.tirePosition = null;

      if (baseLine.productType !== rimReplacementProductType) {
        newLine.tireCondition = null;
      }
    } else {
      // eslint-disable-next-line no-return-assign
      lineKeysToClearOnDuplicateToRim.forEach((key) => (newLine[key] = null));

      newLine.productType = rimReplacementProductType;
      newLine.tireCondition = rimDamagedProductCondition;
      newLine.requestedAction = 'REPLACE';
    }

    return this.props.createRequestedLine(caseId, newLine);
  };

  // Agreement lines

  addRequestAgreementLine = () =>
    this.props.createRequestAgreementLine(this.props.caseId);

  updateRequestAgreementLine = (id, newValues) => {
    const { agreed, ...newAgreementLineValues } = newValues;

    const agreementLine = pick(
      newAgreementLineValues,
      Object.keys(emptyRequestLine),
    );

    const patch = isUndefined(agreed)
      ? { agreementLine }
      : { agreed, agreementLine };

    return this.props.updateRequestAgreementLine({ id, patch });
  };

  toggleRequestAgreementLineStatus = (id) => {
    const { agreed } = findByPropPathValue(
      this.state.requestAgreementLines,
      'agreementLine.id',
      id,
    );

    return this.updateRequestAgreementLine(id, { agreed: !agreed });
  };

  removeRequestAgreementLine = (id) =>
    this.props.removeRequestAgreementLine(id);

  resetRequestAgreementLine = (id) =>
    this.props.resetRequestAgreementLine({ id });

  resetRequestAgreementLines = () =>
    this.props.resetAllRequestAgreementLines({ caseId: this.props.caseId });

  requestAgreementLineHasChanges = (idOrLine) => {
    const { requestLines, requestAgreementLines: agreementLines } = this.state;

    const line =
      typeof idOrLine === 'object'
        ? idOrLine
        : findByPropPathValue(agreementLines, 'agreementLine.id', idOrLine);

    const { agreed, agreementLine, requestLineId } = line || {};

    if (!agreed && agreementLine && requestLineId) {
      const requestedLine = getItemById(requestLines, requestLineId, {});

      return !areRequestLinesEqual(agreementLine, requestedLine);
    }

    return false;
  };

  anyRequestAgreementLineHasChanges = () =>
    this.state.requestAgreementLines.some(this.requestAgreementLineHasChanges);

  shouldDisplayAgreementTableReset = () =>
    this.state.requestAgreementLines.length > 1;

  // Supplied lines

  addRequestSuppliedLine = () =>
    this.props.createRequestSuppliedLine(this.props.caseId);

  updateRequestSuppliedLine = (id, newValues) => {
    const { supplied, ...rest } = newValues;

    const suppliedLine = pick(rest, Object.keys(emptyRequestLine));

    const patch = isUndefined(supplied)
      ? { suppliedLine }
      : { supplied, suppliedLine };

    return this.props.updateRequestSuppliedLine({ id, patch });
  };

  removeRequestSuppliedLine = (id) => this.props.removeRequestSuppliedLine(id);

  resetAllRequestSuppliedLines = () =>
    this.props.resetAllRequestSuppliedLines(this.props.caseId);

  requestSuppliedLineHasChanges = (id) => {
    const { supplied, suppliedLine, agreementLineId } = findByPropPathValue(
      this.state.requestSuppliedLines,
      'suppliedLine.id',
      id,
    );

    if (!supplied && suppliedLine && agreementLineId) {
      const { agreementLine } = findByPropPathValue(
        this.state.requestAgreementLines,
        'agreementLine.id',
        agreementLineId,
      );

      return !areRequestLinesEqual(suppliedLine, agreementLine);
    }

    return false;
  };

  anyRequestSuppliedLineHasChanges = () =>
    this.state.requestSuppliedLines
      .filter(({ agreementLineId }) => !!agreementLineId)
      .some(({ suppliedLine: { id } }) =>
        this.requestSuppliedLineHasChanges(id),
      );

  shouldDisplaySuppliedTableReset = () =>
    this.state.requestSuppliedLines.filter(
      ({ agreementLineId }) => !!agreementLineId,
    ).length > 1;

  toggleRequestSuppliedLineStatus = (id) => {
    const { supplied } = findByPropPathValue(
      this.state.requestSuppliedLines,
      'suppliedLine.id',
      id,
    );

    this.updateRequestSuppliedLine(id, { supplied: !supplied });
  };

  // Special Instructions

  updateSpecialInstructions = (value = null) => {
    const previous = this.state.specialInstructions;

    this.setState({ specialInstructions: value });

    // We're using the transition from and to null to know when to display
    // the special instructions input. When transitioning from and to that
    // state without a stored value, we don't need to save.
    if ((previous === null && !value) || (!previous && value === null)) return;

    this.props.setCaseSpecialInstructions(value);
  };

  // Delayed service

  showDelayedServiceModal = (mode = delayedServiceModalModes.default) =>
    this.setState({
      delayedServiceModalMode: mode,
      shouldShowDelayedServiceModal: true,
    });

  hideDelayedServiceModal = () =>
    this.setState({ shouldShowDelayedServiceModal: false });

  saveDelayedService = (data) => {
    const { isDelayedService, notes, scheduledDate, dispatchDate } = data;

    return this.state.updateCaseDelayedService({
      isDelayedService,
      delayedServiceNotes: notes,
      delayedServiceScheduledDate: scheduledDate,
      delayedServiceScheduledDispatchDate: dispatchDate,
    });
  };

  render() {
    const { delayedServiceScheduledDate } = this.state;
    const { shouldShowDelayedServiceModal } = this.state;
    const { caseStatus, delayedServiceNotes } = this.state;
    const { delayedServiceScheduledDispatchDate } = this.state;
    const { assetLocationTimezone, delayedServiceModalMode } = this.state;

    return (
      <>
        <Provider value={this.state}>{this.props.children}</Provider>
        {shouldShowDelayedServiceModal && (
          <DelayedServiceModal
            mode={delayedServiceModalMode}
            save={this.saveDelayedService}
            notes={delayedServiceNotes}
            onClose={this.hideDelayedServiceModal}
            timezone={assetLocationTimezone}
            caseStatus={caseStatus}
            dispatchDate={delayedServiceScheduledDispatchDate}
            scheduledDate={delayedServiceScheduledDate}
          />
        )}
      </>
    );
  }
}

const EnhancedContext = compose(
  setDisplayName('CaseRequestsPanelContext'),
  withCaseStatus,
  withCaseRequestLines,
  withRequestLineOptions,
  withRequestLinesActions,
  withRequestAgreementLinesActions,
  withRequestSuppliedLinesActions,
  withCaseSpecialInstructions,
  withCaseAssets,
  withReadOnlyCase,
  withCaseDelayedService,
  withRequestAssetActions,
  withCasePanelStatusActions(CASE_PANELS.request),
)(CaseRequestsPanelContext);

EnhancedContext.Consumer = Consumer;
EnhancedContext.delayedServiceModalModes = delayedServiceModalModes;

export default EnhancedContext;

import { curry } from 'lodash';
import React from 'react';
import { Query } from '@apollo/client/react/components';

import DealerLocatorContext from '../DealerLocatorContext';

import dealerFeaturesGQL from './dealerFeaturesQuery';

const updateSelectedFeatureFilters = curry(
  (setContext, selectedFeatureFilters) =>
    setContext({ selectedFeatureFilters }),
);

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const { data: { dealerFeatures } = {} } = queryProps;

    return (
      <WrappedComponent {...componentProps} dealerFeatures={dealerFeatures} />
    );
  },
);

function buildWrappedComponentWithQuery(WrappedComponent, componentProps) {
  return (
    <Query query={dealerFeaturesGQL}>
      {buildQueryChild(WrappedComponent, componentProps)}
    </Query>
  );
}

export const buildConsumerChild = curry(
  (WrappedComponent, componentProps, consumerProps) => {
    const {
      selectedFeatureFilters,
      updateDealerLocatorContext,
    } = consumerProps;

    return buildWrappedComponentWithQuery(WrappedComponent, {
      ...componentProps,
      selectedFeatureFilters,
      onSelectFeatureFilters: updateSelectedFeatureFilters(
        updateDealerLocatorContext,
      ),
    });
  },
);

const withDealerFilters = (WrappedComponent) => (componentProps) => (
  <DealerLocatorContext.Consumer>
    {buildConsumerChild(WrappedComponent, componentProps)}
  </DealerLocatorContext.Consumer>
);

export default withDealerFilters;

import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { A, H1, Icon, Text } from 'base-components';
import { Row, Column } from 'styled-components-grid';

import Panel from 'blocks/Panel';

import withDealerQuery from './withDealerQuery';
import Breadcrumbs from './Breadcrumbs';

/**
 * UI for the top area of the Tech Admin dealer details page.
 * Exported only for test purposes.
 */
export function TopBar({ dealer, loading }) {
  const dealerName = loading ? '...' : dealer.name || '<no name>';
  return (
    <Panel modifiers={['padScaleX_5']}>
      <Breadcrumbs loading={loading} dealer={dealer} />
      <Row modifiers={['middle']}>
        <Column modifiers={['col', 'padScaleX_2', 'padScaleY_4']}>
          <H1 modifiers={['fontWeightLight']}>{dealerName}</H1>
        </Column>
        <Column modifiers={['padScaleX_4', 'padScaleY_4', 'end']}>
          <Text modifiers={['textLight']}>
            <Icon name="info-circle" /> <Trans>Incorrect Information?</Trans>{' '}
            <A
              href="https://www.michelinb2b.com/wps/portal/b2blogin/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8zijQxMDA38_T2NgsO8DQw8Q10Dg0zM3I0NXE30wwkpiAJKG-AAjgZA_VGElBTkRhikOyoqAgBCAt8o/dz/d5/L2dBISEvZ0FBIS9nQSEh"
              target="_blank"
              aria-label="Service Provider Profile"
            >
              <Trans>Visit Service Provider Profile to update</Trans>{' '}
              <Icon name="external-link" modifiers={['mini', 'colorInherit']} />
            </A>
          </Text>
        </Column>
      </Row>
    </Panel>
  );
}

TopBar.propTypes = {
  dealer: PropTypes.shape({ name: PropTypes.string.isRequired }),
  loading: PropTypes.bool,
};

TopBar.defaultProps = {
  dealer: null,
  loading: false,
};

export default compose(setDisplayName('TopBar'), withDealerQuery)(TopBar);

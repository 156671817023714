import { compact } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import StyledLeftNav from '../../blocks/LeftNav';

import menuPropTypes from './menuPropTypes';

function MenuItemTitle({
  TextRenderer,
  expanded,
  handleExpandMenuClick,
  isActive,
  isHighlighted,
  isFlyout,
  isMenuExpanded,
  menuItem,
  menuGroupsLoading,
  menuKey,
  onMouseEnter,
  onMouseLeave,
}) {
  if (menuGroupsLoading && !isFlyout) {
    return (
      <StyledLeftNav.MenuItemTitle modifiers={['ghost']}>
        <StyledLeftNav.MenuItemTitleGhost modifiers={['chrome200', 'icon']} />
        {expanded && (
          <StyledLeftNav.MenuItemTitleGhost modifiers={['chrome200']} />
        )}
      </StyledLeftNav.MenuItemTitle>
    );
  }

  return (
    <StyledLeftNav.MenuItemTitle
      modifiers={compact([
        isHighlighted && !isFlyout && 'highlight',
        isActive && !isFlyout && 'active',
      ])}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={() => handleExpandMenuClick(menuKey)}
    >
      {!menuItem.url && !menuItem.path && (
        <StyledLeftNav.MenuItemTitleNoLink
          aria-label={`Navigate to ${menuItem.url}`}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {!isFlyout && (
            <StyledLeftNav.MenuItemTitleIcon
              modifiers={compact([!isActive && !isHighlighted && 'disabled'])}
              name={menuItem.icon}
            />
          )}
          {(expanded || isFlyout) && (
            <StyledLeftNav.MenuItemTitleText
              modifiers={compact([
                isHighlighted && !isFlyout && 'highlight',
                isFlyout && 'flyout',
              ])}
            >
              <TextRenderer message={menuItem.message} />
            </StyledLeftNav.MenuItemTitleText>
          )}
        </StyledLeftNav.MenuItemTitleNoLink>
      )}
      {menuItem.url && (
        <StyledLeftNav.MenuItemTitleA
          aria-label={`Navigate to ${menuItem.url}`}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          href={menuItem.url}
          target="_blank"
        >
          {!isFlyout && (
            <StyledLeftNav.MenuItemTitleIcon
              modifiers={compact([!isActive && !isHighlighted && 'disabled'])}
              name={menuItem.icon}
            />
          )}
          {(expanded || isFlyout) && (
            <StyledLeftNav.MenuItemTitleText
              modifiers={compact([
                isHighlighted && !isFlyout && 'highlight',
                isFlyout && 'flyout',
              ])}
            >
              <TextRenderer message={menuItem.message} />
            </StyledLeftNav.MenuItemTitleText>
          )}
        </StyledLeftNav.MenuItemTitleA>
      )}
      {menuItem.path && (
        <StyledLeftNav.MenuItemTitleLink
          aria-label={`Navigate to ${menuItem.path}`}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          to={menuItem.path}
        >
          {!isFlyout && (
            <StyledLeftNav.MenuItemTitleIcon
              modifiers={compact([!isActive && !isHighlighted && 'disabled'])}
              name={menuItem.icon}
            />
          )}
          {(expanded || isFlyout) && (
            <StyledLeftNav.MenuItemTitleText
              modifiers={compact([
                isHighlighted && !isFlyout && 'highlight',
                isFlyout && 'flyout',
              ])}
            >
              <TextRenderer message={menuItem.message} />
            </StyledLeftNav.MenuItemTitleText>
          )}
        </StyledLeftNav.MenuItemTitleLink>
      )}
      {!isFlyout && expanded && !menuItem.url && menuItem.menuSubItems && (
        <StyledLeftNav.ExpanderButton
          modifiers={compact([!isHighlighted && 'disabled'])}
          name={isMenuExpanded ? 'angle-up' : 'angle-down'}
          onClick={() => handleExpandMenuClick(menuKey)}
        />
      )}
    </StyledLeftNav.MenuItemTitle>
  );
}

MenuItemTitle.propTypes = {
  TextRenderer: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  handleExpandMenuClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  isFlyout: PropTypes.bool,
  isHighlighted: PropTypes.bool.isRequired,
  isMenuExpanded: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/no-typos
  menuItem: menuPropTypes.menuItem.isRequired,
  menuGroupsLoading: PropTypes.bool.isRequired,
  menuKey: PropTypes.string.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
};

MenuItemTitle.defaultProps = {
  isFlyout: false,
};

export default MenuItemTitle;

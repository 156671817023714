import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';
import { compose, setDisplayName } from 'recompose';

import useCaseOwner from './useCaseOwner';
import UserChangedWarningModal from './UserChangedWarningModal';

const withCaseOwnerQuery = (Component) => (cProps) => {
  const data = useCaseOwner(cProps.caseNumber);
  const { loading, currentUser, caseAssignedTo } = data;

  return (
    <Component
      {...cProps}
      ownerLoading={loading}
      currentUser={currentUser}
      caseAssignedTo={caseAssignedTo}
    />
  );
};

class WatchForOwnerChange extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    caseNumber: PropTypes.string.isRequired,
    currentUser: PropTypes.shape({ email: PropTypes.string }),
    ownerLoading: PropTypes.bool.isRequired,
    caseAssignedTo: PropTypes.shape({ email: PropTypes.string }),
  };

  static defaultProps = { currentUser: null, caseAssignedTo: null };

  state = { showModal: false };

  componentDidUpdate(prevProps) {
    // do nothing while the case is loading
    if (this.props.ownerLoading) return;

    // show modal if the assigned user changed from me to some other user...
    const prevAssignedEmail = prevProps?.caseAssignedTo?.email;
    const nowAssignedEmail = this.getCaseAssignedEmail();
    const currentUserEmail = this.getCurrentUserEmail();

    if (
      prevAssignedEmail === currentUserEmail &&
      prevAssignedEmail !== nowAssignedEmail &&
      nowAssignedEmail !== currentUserEmail &&
      !this.state.showModal
    ) {
      this.setState({ showModal: true });
    }
  }

  closeModal = () => this.setState({ showModal: false });
  getCurrentUserEmail = () => this.props?.currentUser?.email;
  getCaseAssignedEmail = () => this.props?.caseAssignedTo?.email;

  render() {
    const { caseNumber, caseAssignedTo, children } = this.props;

    return (
      <>
        {isFunction(children) ? children({ caseAssignedTo }) : children}
        {this.state.showModal && (
          <UserChangedWarningModal
            onClose={this.closeModal}
            caseNumber={caseNumber}
            caseIsAssigned={!!this.getCaseAssignedEmail()}
          />
        )}
      </>
    );
  }
}

export default compose(
  setDisplayName('WatchForOwnerChange'),
  withCaseOwnerQuery,
)(WatchForOwnerChange);

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import QuickActionButton from '../QuickActionButton';

const modifierConfig = {
  active: ({ theme }) => `
    background: ${theme.colors.base.chrome600};
    border-color: transparent;
    > span {
      color: ${theme.colors.base.chrome000};
    }
    + button {
      border-left: transparent;
    }
  `,
};

const styles = ({ theme }) => `
  background: ${theme.colors.base.chrome100};
  border: 1px solid ${theme.colors.base.chrome200};
  border-radius: 0;
  color: ${theme.colors.base.chrome500};
  cursor: pointer;
  display: flex;
  font-size: ${px2rem(theme.dimensions.fontSize)};
  height: ${px2rem(25)};
  justify-content: center;
  padding: 0;
  width: ${px2rem(35)};
  &:hover {
    text-decoration: none;
    > span {
      color: ${theme.colors.base.chrome000};
    }
  }
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome600: PropTypes.string.isRequired,
      chrome000: PropTypes.string.isRequired,
      chrome100: PropTypes.string.isRequired,
      chrome200: PropTypes.string.isRequired,
      chrome500: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dimensions: PropTypes.shape({
    fontSize: PropTypes.number.isRequired,
  }).isRequired,
};

export default buildStyledComponent(
  'Button',
  styled(QuickActionButton),
  styles,
  {
    modifierConfig,
    themePropTypes,
  },
);

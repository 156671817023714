import styled from 'styled-components';

import { Text } from 'base-components';
import { buildStyledComponent } from 'decisiv-ui-utils';

export default buildStyledComponent(
  'CustomerValueLabel',
  styled(Text).attrs(({ modifiers = [] }) => ({
    modifiers: ['small', 'textLight', ...modifiers],
  })),
  `
    word-break: break-word;
    hyphens: auto;
  `,
);

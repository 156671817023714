import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { t, Trans } from '@lingui/macro';
import { compose, setDisplayName, onlyUpdateForKeys } from 'recompose';

import { Text } from 'base-components';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';
import { Column, Row } from 'styled-components-grid';

import withContext from 'utils/withContext';
import ButtonLinkWithIcon from 'elements/ButtonLinkWithIcon';

import { CaseStatusContext } from 'compositions/CaseStatus';
import { CaseShortcut, CASE_SHORTCUT_PANELS } from 'features/keyShortcuts';

import CaseRequestsPanelContext from '../CaseRequestsPanelContext';
import { requestsTabIndices } from '../CaseRequestsPanelContext/constants';

const { agreed, requested, supplied } = requestsTabIndices;

const TextWithMark = buildStyledComponent(
  'TextWithMark',
  styled(Text),
  ({ theme }) => css`
    position: relative;
    padding-right: ${px2rem(16)};

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      border: ${px2rem(5)} solid transparent;
      border-right-color: ${theme.colors.status.info};
      border-top-color: ${theme.colors.status.info};
    }
  `,
);

const nonPreferredSelectionsMessage = (
  <Column
    style={{ minHeight: px2rem(34) }}
    modifiers={['col', 'end', 'padScaleX_2']}
  >
    <TextWithMark modifiers={['small', 'textLight']}>
      <Trans>Not a Fleet Preference</Trans>
    </TextWithMark>
  </Column>
);

const requestLinePropType = PropTypes.arrayOf(
  PropTypes.shape({
    nonPreferredSelections: PropTypes.arrayOf(PropTypes.string),
  }),
);

const rowsTypeByTab = {
  [requested]: 'requestLines',
  [agreed]: 'requestAgreementLines',
  [supplied]: 'requestSuppliedLines',
};

const lineDataPathByTab = {
  [requested]: '',
  [agreed]: 'agreementLine.',
  [supplied]: 'suppliedLine.',
};

export class CaseRequestsActions extends Component {
  static propTypes = {
    addRequestLine: PropTypes.func.isRequired,
    addRequestSuppliedLine: PropTypes.func.isRequired,
    addRequestAgreementLine: PropTypes.func.isRequired,
    activeRequestsTab: PropTypes.number.isRequired,
    specialInstructions: PropTypes.string,
    updateSpecialInstructions: PropTypes.func.isRequired,
    isFeatureEnabled: PropTypes.func.isRequired,
    /* eslint-disable react/no-unused-prop-types */
    requestLines: requestLinePropType.isRequired,
    requestAgreementLines: requestLinePropType.isRequired,
    requestSuppliedLines: requestLinePropType.isRequired,
    /* eslint-enable react/no-unused-prop-types */
  };

  static defaultProps = {
    specialInstructions: null,
  };

  handleCreateInstructions = () => this.props.updateSpecialInstructions('');

  render() {
    const { specialInstructions, isFeatureEnabled } = this.props;
    const { addRequestLine, addRequestSuppliedLine } = this.props;
    const { addRequestAgreementLine, activeRequestsTab } = this.props;

    const lines = get(this.props, rowsTypeByTab[activeRequestsTab], []);
    const lineDataPath = lineDataPathByTab[activeRequestsTab];

    const canAddNewRequestLines =
      activeRequestsTab === requestsTabIndices.supplied
        ? isFeatureEnabled('manageSuppliedLines')
        : isFeatureEnabled('manageRequestLines');

    const hasNonPrefSelections = lines.some(
      (line) => !!get(line, `${lineDataPath}nonPreferredSelections.length`),
    );

    if (!canAddNewRequestLines) {
      return hasNonPrefSelections && <Row>{nonPreferredSelectionsMessage}</Row>;
    }

    return (
      <Row modifiers="middle">
        {activeRequestsTab === requested && (
          <>
            <CaseShortcut
              action={{
                parent: CASE_SHORTCUT_PANELS.request,
                id: `createRequestAction`,
                name: t`Create New Request`,
                shortcut: ['c', 'r'],
                priority: 4,
                perform: () => addRequestLine(),
                icon: 'plus',
              }}
              passRef
            >
              <Column modifiers="padScaleX_2" style={{ paddingLeft: 0 }}>
                <ButtonLinkWithIcon icon="plus" onClick={addRequestLine}>
                  <Trans>Create New Request</Trans>
                </ButtonLinkWithIcon>
              </Column>
            </CaseShortcut>

            {specialInstructions === null && (
              <CaseShortcut
                action={{
                  parent: CASE_SHORTCUT_PANELS.request,
                  id: `specialInstructionsAction`,
                  name: t`Create Special Instructions`,
                  shortcut: ['s', 'i'],
                  perform: () => this.handleCreateInstructions(),
                  priority: 3,
                  icon: 'plus',
                }}
                passRef
              >
                <Column modifiers="padScaleX_2">
                  <ButtonLinkWithIcon
                    icon="plus"
                    onClick={this.handleCreateInstructions}
                  >
                    <Trans>Create Special Instructions</Trans>
                  </ButtonLinkWithIcon>
                </Column>
              </CaseShortcut>
            )}
          </>
        )}

        {activeRequestsTab === agreed && (
          <Column modifiers="padScaleX_2">
            <ButtonLinkWithIcon icon="plus" onClick={addRequestAgreementLine}>
              <Trans>Create New Agreed Service</Trans>
            </ButtonLinkWithIcon>
          </Column>
        )}

        {activeRequestsTab === supplied && (
          <Column modifiers="padScaleX_2">
            <ButtonLinkWithIcon icon="plus" onClick={addRequestSuppliedLine}>
              <Trans>Create New Supplied Service</Trans>
            </ButtonLinkWithIcon>
          </Column>
        )}

        {hasNonPrefSelections && nonPreferredSelectionsMessage}
      </Row>
    );
  }
}

export default compose(
  setDisplayName('CaseRequestsActions'),
  withContext(CaseRequestsPanelContext),
  withContext(CaseStatusContext),
  onlyUpdateForKeys([
    'status',
    'requestLines',
    'activeRequestsTab',
    'specialInstructions',
    'requestSuppliedLines',
    'requestAgreementLines',
    'caseAssignedToCurrentUser',
    'isLoadingRequestLineOptions',
  ]),
)(CaseRequestsActions);

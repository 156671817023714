import Bugsnag from '@bugsnag/browser';
import { ApolloLink } from '@apollo/client';

export function setBugsnagUser(client, props) {
  const profile = props?.data?.currentUserProfile;
  const role = profile?.role?.name || client.getMetadata('user', 'role');

  if (!profile?.user) return;

  const { email, firstName, lastName } = profile.user;
  const name = [firstName, lastName].join(' ').trim() || undefined;

  client.setUser(email, undefined, name);
  client.addMetadata('user', { role });
}

/**
 * Creates an Apollo Link after-ware that analyzes response data for
 * a `user`, then adds/updates the user info with the Bugsnag client.
 */
const init = () =>
  new ApolloLink((operation, forward) =>
    forward(operation).map((props) => {
      setBugsnagUser(Bugsnag, props);

      return props;
    }),
  );

export default { init };

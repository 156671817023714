import PropTypes from 'prop-types';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';
import { Text } from 'base-components';

const styles = ({ theme }) => `
  font-size: ${px2rem(14)};
  font-weight: ${theme.fontWeights.light};
  color: ${theme.colors.base.textLight};
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      textLight: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  fontWeights: PropTypes.shape({
    light: PropTypes.number.isRequired,
  }).isRequired,
};

export default buildStyledComponent('TopNav__Text', Text, styles, {
  themePropTypes,
});

import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { withRouter } from 'react-router-dom';
import { compose, setDisplayName } from 'recompose';

import { Row, Column } from 'styled-components-grid';
import { H2, P, QuickActionButton } from 'base-components';

import Modal from 'components/Modal';

function TimedoutNoticeModal(props) {
  const { history } = props;

  const caseNumber = (props.caseNumber || '').toUpperCase();

  const hideModal = () => history.replace(`/`);
  const goBackToCase = () => history.push(`/cases/${caseNumber}`);

  return (
    <Modal onClose={hideModal}>
      {() => (
        <Modal.Body>
          <Modal.Header>
            <Modal.HeaderIcon name="hourglass" />
          </Modal.Header>
          <Modal.Content>
            <Row modifiers="center">
              <Column>
                <H2 modifiers="fontWeightRegular">
                  <Trans>We&apos;ve lost you</Trans>
                </H2>
              </Column>
            </Row>
            <Row modifiers="center" style={{ marginTop: 20 }}>
              <Column modifiers="col">
                <P>
                  <Trans>
                    Because you didn&apos;t make any changes to case number{' '}
                    {caseNumber} for a while, you&apos;ve been unassigned from
                    it and automatically redirected here.
                    <br />
                    <br />
                    If you still want to work on the case, press the &quot;Go
                    BACK TO {caseNumber}
                    &quot; button.
                  </Trans>
                </P>
              </Column>
            </Row>
          </Modal.Content>
          <Modal.Footer>
            <Row modifiers="end">
              <Column>
                <QuickActionButton type="button" onClick={goBackToCase}>
                  <QuickActionButton.Text>
                    <Trans>Go back to {caseNumber}</Trans>
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
              <Column>
                <QuickActionButton
                  type="submit"
                  onClick={hideModal}
                  modifiers="secondary"
                >
                  <QuickActionButton.Text>
                    <Trans>Dismiss</Trans>
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
            </Row>
          </Modal.Footer>
        </Modal.Body>
      )}
    </Modal>
  );
}

TimedoutNoticeModal.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  caseNumber: PropTypes.string.isRequired,
};

export default compose(
  withRouter,
  setDisplayName('TimedoutNoticeModal'),
)(TimedoutNoticeModal);

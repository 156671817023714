import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { debounce, get, union } from 'lodash';
import { compose, setDisplayName } from 'recompose';

import withCreateCaseNote from '../withCreateCaseNote';

const DEBOUNCE_MS = 1000;
const NOTE_CACHE_KEY = 'caseNotes';
const MAX_CACHE_SIZE = 10;
const defaultNoteCache = { caseNumbers: [], notes: {} };

function removeNoteCache(caseNumber) {
  const caseNoteCache = JSON.parse(memoryDB.getItem(NOTE_CACHE_KEY));

  if (!caseNoteCache) {
    return;
  }

  caseNoteCache.caseNumbers = caseNoteCache.caseNumbers.filter(
    (number) => number !== caseNumber,
  );
  delete caseNoteCache.notes[caseNumber];

  memoryDB.setItem(NOTE_CACHE_KEY, JSON.stringify(caseNoteCache));
}

function saveNoteCache(caseNumber, note) {
  const caseNoteCache =
    JSON.parse(memoryDB.getItem(NOTE_CACHE_KEY)) || defaultNoteCache;
  caseNoteCache.notes[caseNumber] = note;
  caseNoteCache.caseNumbers = union(caseNoteCache.caseNumbers, [caseNumber]);
  // warning: splice modifies the array it is called on
  const agedOutNumbers = caseNoteCache.caseNumbers.splice(MAX_CACHE_SIZE);
  agedOutNumbers.forEach((number) => delete caseNoteCache.notes[number]);
  memoryDB.setItem(NOTE_CACHE_KEY, JSON.stringify(caseNoteCache));
}

const withCreateForm = (WrappedComponent) => {
  class CreateForm extends Component {
    static propTypes = {
      caseId: PropTypes.string.isRequired,
      caseNumber: PropTypes.string.isRequired,
      createCaseNote: PropTypes.func.isRequired,
    };

    saveNotes = debounce((notes) => {
      saveNoteCache(this.props.caseNumber, notes);
    }, DEBOUNCE_MS);

    constructor(props) {
      super(props);

      const caseNoteCache =
        JSON.parse(memoryDB.getItem(NOTE_CACHE_KEY)) || defaultNoteCache;
      const restoredNote = get(caseNoteCache, ['notes', props.caseNumber], '');

      this.notes = restoredNote;
    }

    handleSubmit = async (notes) => {
      const { caseId, caseNumber, createCaseNote } = this.props;

      await createCaseNote({
        variables: {
          caseId,
          body: notes,
        },
      });

      removeNoteCache(caseNumber);
    };

    handleTextChange = (value) => {
      this.saveNotes(value);
    };

    clearNotes = () => {
      removeNoteCache(this.props.caseNumber);
    };

    render() {
      return (
        <WrappedComponent
          notes={this.notes}
          onCancel={this.clearNotes}
          onSubmit={this.handleSubmit}
          onTextChange={this.handleTextChange}
          saveButtonLabel={<Trans>Post</Trans>}
        />
      );
    }
  }

  return compose(setDisplayName('CreateForm'), withCreateCaseNote)(CreateForm);
};

export default withCreateForm;

import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { get, isFunction, memoize, truncate } from 'lodash';

import { px2rem } from 'decisiv-ui-utils';
import { Avatar, Text, Tooltip, Popover } from 'base-components';
import { Container, Column, Row } from 'styled-components-grid';

import Tag from 'blocks/Tag';
import { i18n } from '@lingui/core';

import {
  actionTitles,
  fieldUpdateEntryPropType,
  fieldUpdatesConfigPropType,
} from './constants';

export const Emphasis = <Text modifiers="fontWeightMedium" />;

export const IdTag = ({ children: id }) =>
  id ? (
    <Tooltip style={{ display: 'inline' }}>
      <Tooltip.Target>
        <Tag modifiers="inline">
          <Tag.Text>
            <Trans id="ID {id}" values={{ id: id.substr(0, 8) }} />
          </Tag.Text>
        </Tag>
      </Tooltip.Target>
      <Tooltip.Content>{id}</Tooltip.Content>
    </Tooltip>
  ) : null;

IdTag.propTypes = { children: PropTypes.string };
IdTag.defaultProps = { children: '' };

export const TruncatedPopover = ({ children: str }) =>
  str ? (
    <Popover position="top" showOnHover style={{ display: 'inline-flex' }}>
      <Popover.Target>
        <Text modifiers="fontWeightMedium">
          <Trans
            id='"{str}"'
            values={{
              str: truncate(str, {
                length: 30,
                separator: /,? +/,
              }),
            }}
          />
        </Text>
      </Popover.Target>
      {str.length > 30 && (
        <Popover.Content style={{ width: px2rem(360) }}>
          <Row>
            <Column modifiers={['padScale_3', 'flex_1']}>
              <Text modifiers={['textLight']}>{str}</Text>
            </Column>
          </Row>
        </Popover.Content>
      )}
    </Popover>
  ) : null;

TruncatedPopover.propTypes = { children: PropTypes.string };
TruncatedPopover.defaultProps = { children: '' };

export const valuesForEntry = memoize(
  (entry, props) => {
    const { config } = props;
    const { type, action } = entry;

    return {
      userName: (entry.user.name || '').trim() || entry.user.email,
      performedAction: actionTitles[action.toLowerCase()],
      ...Object.entries(get(config.relatedInfoPathsByType, type, {})).reduce(
        (acc, [key, path]) => {
          const value = isFunction(path) ? path(entry) : get(entry, path, '');

          return { ...acc, [key]: value };
        },
        {},
      ),
    };
  },
  (entry) => `${entry.id}-${i18n.locale}`,
);

// Matches "foo" in "<0>foo</0>"
const i18nTagRegex = /<[0-9]>([^<]+)<\/[0-9]>/g;

export const componentsForEntry = memoize(
  (entry, props) => {
    const sentence = get(props.config.changeSummarySentences, entry.type, '');
    const components = [];

    [...(sentence?.id || sentence).matchAll(i18nTagRegex)].forEach(
      ([, tagValue]) => {
        components.push(
          (tagValue.endsWith('Id}') && <IdTag />) ||
            (tagValue.startsWith('{truncated') && <TruncatedPopover />) ||
            Emphasis,
        );
      },
    );

    return components;
  },
  (entry) => entry.id,
);

export const FieldUpdatesEntryHeader = (props) => {
  const { entry, style, config } = props;
  const { changeSummarySentences } = config;

  return (
    <Container
      modifiers="padScale_0"
      style={{ marginBottom: px2rem(10), ...style }}
    >
      <Row
        style={{ padding: `${px2rem(5)} 0`, background: 'white' }}
        modifiers="middle"
      >
        <Column modifiers="padScale_0" style={{ width: px2rem(45) }}>
          <Avatar
            email={entry.user.email}
            name={(entry.user.name || '').trim() || entry.user.email}
            modifiers={['small']}
          />
        </Column>
        <Column modifiers={['col', 'padScale_0']}>
          <Row>
            <Column modifiers={['col', 'padScale_0']}>
              <Text modifiers={['small', 'textLight']}>
                {moment(entry.recordedAt)
                  .tz(moment.tz.guess())
                  .format('h:mm A z')}
              </Text>
            </Column>
          </Row>
          <Row>
            <Column modifiers={['col', 'padScale_0']}>
              <Text>
                <Trans
                  id={get(changeSummarySentences, entry.type)}
                  values={valuesForEntry(entry, props)}
                  components={componentsForEntry(entry, props)}
                />
              </Text>
            </Column>
          </Row>
        </Column>
      </Row>
    </Container>
  );
};

FieldUpdatesEntryHeader.propTypes = {
  style: PropTypes.shape({}),
  /* eslint-disable react/no-typos */
  entry: fieldUpdateEntryPropType.isRequired,
  config: fieldUpdatesConfigPropType.isRequired,
  /* eslint-enable react/no-typos */
};

FieldUpdatesEntryHeader.defaultProps = { style: undefined };

export default FieldUpdatesEntryHeader;

import React from 'react';

import NewHighlightEntry from './NewHighlightEntry';
import HighlightsClearedEntry from './HighlightsClearedEntry';

/* eslint-disable react/prop-types */
export const Entry = ({ entry, ...rest }) => {
  const Component =
    entry.action === 'DELETED' ? HighlightsClearedEntry : NewHighlightEntry;

  return <Component {...rest} key={entry.recordedAt} {...entry} />;
};
/* eslint-enable react/prop-types */

export default Entry;

import PropTypes from 'prop-types';
import { buildStyledComponent } from 'decisiv-ui-utils';
import { Container } from 'styled-components-grid';

const styles = ({ theme }) => `
  cursor: default;
  transition: background 200ms ease;
  .ButtonBox {
    opacity: 0;
    pointer-events: none;
    transition: opacity 200ms ease;
  }

  &:hover {
    background-color: ${theme.colors.base.selectableHover};
    .ButtonBox {
      opacity: 1;
      pointer-events: initial;
    }
  }
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      selectableHover: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const ContainerSelectable = buildStyledComponent(
  'ContainerSelectable',
  Container,
  styles,
  { themePropTypes },
);

export default ContainerSelectable;

/* istanbul ignore file */
import React from 'react';
import { get } from 'lodash';
import { Trans } from '@lingui/macro';

import { Text } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import CaseLink from 'elements/CaseLink';

import HeaderCell from '../HeaderCell';
import { COLUMN_NAMES } from '../constants';
import { buildBaseColumnConfig } from '../utils';

// We prevent the default behaviour of the link below because clicking
// the row will navigate to the case. If we didn't, the location history
// would have two entries to the case URL, meaning the user would have to
// press back 2 times to go back to the dashboard.

export default (...args) => ({
  ...buildBaseColumnConfig(COLUMN_NAMES.caseNumber, ...args),
  cellDataGetter: (data) => get(data, 'node.caseNumber'),
  headerCellRenderer: (props) => (
    <HeaderCell {...props} label={<Trans>Case</Trans>} />
  ),
  dataCellRenderer: (caseNumber) => (
    <Container modifiers="padScaleY_2">
      <Row>
        <Column modifiers={['col', 'padScaleY_0']}>
          <Text>
            <CaseLink
              style={{ wordBreak: 'break-all', whiteSpace: 'normal' }}
              caseNumber={caseNumber}
              onClick={(e) => e.preventDefault()}
            />
          </Text>
        </Column>
      </Row>
    </Container>
  ),
});

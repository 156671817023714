import React, { Component } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { trim } from 'lodash';
import { Trans } from '@lingui/macro';

import { px2rem } from 'decisiv-ui-utils';
import { Avatar, Text, Popover } from 'base-components';
import { Container, Column, Row } from 'styled-components-grid';

import EntryDivider from '../EntryDivider';
import StatusUpdateText from './StatusUpdateText';

const getDisplayName = ({ name, email }) => trim(name) || email;

class StatusUpdate extends Component {
  static propTypes = {
    caseNumber: PropTypes.string.isRequired,
    statusUpdate: PropTypes.shape({
      changedAt: PropTypes.string.isRequired,
      newStatus: PropTypes.string.isRequired,
      user: PropTypes.shape({
        name: PropTypes.string,
        email: PropTypes.string,
      }).isRequired,
      history: PropTypes.arrayOf(PropTypes.shape({})),
    }).isRequired,
    showHistoryPopover: PropTypes.bool,
  };

  static defaultProps = {
    showHistoryPopover: false,
  };

  state = { showPopover: false };

  componentWillUnmount() {
    clearTimeout(this.hidePopoverTimeout);
  }

  handleMouseEnterLeave = ({ type }) => {
    if (!this.props.showHistoryPopover) {
      return;
    }

    clearTimeout(this.hidePopoverTimeout);

    if (type === 'mouseenter') {
      this.setState({ showPopover: true });
    } else {
      this.hidePopoverTimeout = setTimeout(
        () => this.setState({ showPopover: false }),
        150,
      );
    }
  };

  render() {
    const { caseNumber, statusUpdate, showHistoryPopover } = this.props;
    const { showPopover } = this.state;

    const {
      changedAt,
      newStatus,
      user: { name, email },
    } = statusUpdate;

    let { history } = statusUpdate;

    if (showHistoryPopover && !history) {
      history = [statusUpdate];
    }

    const displayName = getDisplayName({ name, email });

    return (
      <>
        <Row>
          <Column
            modifiers={['center', 'height_100', 'padScaleY_0']}
            style={{ height: px2rem(25), width: px2rem(50) }}
          >
            <EntryDivider />
          </Column>
        </Row>
        <div style={{ display: 'inline-flex' }}>
          <Row
            onMouseEnter={this.handleMouseEnterLeave}
            onMouseLeave={this.handleMouseEnterLeave}
          >
            <Column
              modifiers={['center', 'padScaleY_0']}
              style={{ width: px2rem(50) }}
            >
              <Container
                modifiers={['flex_column', 'height_100', 'padScale_0']}
              >
                <Row>
                  <Column modifiers={['center', 'padScaleY_1']}>
                    <Avatar
                      modifiers={['small']}
                      email={email}
                      name={displayName}
                    />
                  </Column>
                </Row>
                <Row modifiers={['center', 'flex_1']}>
                  <Column modifiers={['padScaleY_0']}>
                    <EntryDivider />
                  </Column>
                </Row>
              </Container>
            </Column>
            <Column modifiers={['col']} style={{ paddingBottom: px2rem(20) }}>
              <Row>
                <Column modifiers={['padScale_0']}>
                  <Text modifiers={['small', 'textLight']}>
                    {moment(changedAt).tz(moment.tz.guess()).format('h:mm A z')}
                  </Text>
                </Column>
              </Row>
              {showHistoryPopover ? (
                <Row>
                  <StatusUpdateText type={newStatus} showMessage={false} />
                </Row>
              ) : (
                <Row modifiers={['middle']}>
                  <Text>{displayName || <Trans>Unknown User</Trans>}</Text>
                  <StatusUpdateText caseNumber={caseNumber} type={newStatus} />
                </Row>
              )}
            </Column>

            {showHistoryPopover && (
              <Popover position="right" showPopover showOnHover>
                {showPopover && (
                  <Popover.Content style={{ minWidth: px2rem(200) }}>
                    <div
                      style={{
                        maxHeight: '300px',
                        overflowX: 'hidden',
                        overflowY: 'auto',
                      }}
                    >
                      {history.map((entry) => (
                        <Row
                          modifiers={['middle', 'padScaleY_2']}
                          key={entry.id}
                          style={{
                            flexWrap: 'nowrap',
                            paddingRight: px2rem(8),
                          }}
                        >
                          <Column
                            modifiers={['center', 'padScale_0']}
                            style={{ width: px2rem(50) }}
                          >
                            <Avatar
                              modifiers={['small']}
                              email={entry.user.email}
                              name={entry.user.name}
                            />
                          </Column>
                          <Column modifiers={['col', 'padScale_0']}>
                            <Row modifiers="padScaleY_0">
                              <Column modifiers="padScale_0">
                                <Text modifiers={['small', 'textLight']}>
                                  {moment(entry.changedAt)
                                    .tz(moment.tz.guess())
                                    .format('DD MMM, h:mm A z')}
                                </Text>
                              </Column>
                            </Row>
                            <Row modifiers="padScaleY_0">
                              <Column modifiers={['col', 'padScale_0']}>
                                <Text>{getDisplayName(entry.user)}</Text>
                              </Column>
                            </Row>
                          </Column>
                        </Row>
                      ))}
                    </div>
                  </Popover.Content>
                )}
              </Popover>
            )}
          </Row>
        </div>
      </>
    );
  }
}

export default StatusUpdate;

import PropTypes from 'prop-types';
import React from 'react';

import StyledTag from '../../blocks/Tag';

function Tag({ children, id, onClickClose }) {
  return (
    <StyledTag>
      <StyledTag.Text>{children}</StyledTag.Text>
      <StyledTag.CloseButton onClick={() => onClickClose(id)}>
        <StyledTag.CloseButtonIcon />
      </StyledTag.CloseButton>
    </StyledTag>
  );
}

Tag.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  onClickClose: PropTypes.func.isRequired,
};

export default Tag;

import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { t } from '@lingui/macro';

import { Row } from 'styled-components-grid';

import MenuButton from 'components/MenuButton';

import { rimReplacementProductType } from 'compositions/CaseRequestsPanel/CaseRequestsPanelContext/constants';

const menuButtonTooltip = t`Row Actions`;

function RequestLineActionsCell(props) {
  const { rowData, totalRequestLines, tableData } = props;
  const { removeRequestLine, duplicateRequestLine } = props;
  const { id: lineId, productType, readOnly } = rowData;

  const canDelete = totalRequestLines >= 2 && !`${lineId}`.startsWith('temp_');
  const isTireLine = ['New', 'Retread'].includes(productType);

  const lineHasRimReplacementRequest = tableData.some((line) => {
    const hasSameAsset = get(line, 'asset.id') === get(rowData, 'asset.id');
    const hasSameTirePosition = line.tirePosition === rowData.tirePosition;
    const isRimReplacement = line.productType === rimReplacementProductType;

    return hasSameAsset && hasSameTirePosition && isRimReplacement;
  });

  const showDuplicateAsRim = isTireLine && !lineHasRimReplacementRequest;

  const menuItems = [
    {
      key: 'duplicateAsTire',
      label: t`Duplicate`,
      onSelect: () => duplicateRequestLine(lineId, { type: 'tire' }),
    },
    showDuplicateAsRim && {
      key: 'duplicateAsRim',
      label: t`Request Rim Replacement`,
      onSelect: () => duplicateRequestLine(lineId, { type: 'rim' }),
    },
    { key: 'divider' },
    {
      key: 'remove',
      label: t`Remove`,
      disabled: !canDelete || !!readOnly,
      onSelect: () => removeRequestLine(lineId),
    },
  ].filter(Boolean);

  return (
    <Row modifiers="center">
      <MenuButton items={menuItems} tooltip={menuButtonTooltip} />
    </Row>
  );
}

RequestLineActionsCell.propTypes = {
  rowData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    productType: PropTypes.string,
    tirePosition: PropTypes.string,
  }).isRequired,
  tableData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  totalRequestLines: PropTypes.number.isRequired,
  removeRequestLine: PropTypes.func.isRequired,
  duplicateRequestLine: PropTypes.func.isRequired,
};

export default RequestLineActionsCell;

import { Trans } from '@lingui/macro';
import { compact } from 'lodash';
import { Row, Column } from 'styled-components-grid';
import PropTypes from 'prop-types';
import React from 'react';

import Label from './Label';

export default function OperatingStatus({
  operatingStatusTypeModifier,
  operatingStatusTypeValue,
}) {
  return (
    <Column modifiers={['col']}>
      <Row modifiers={['padScale_0']}>
        <Column modifiers={['col', 'padScale_0']}>
          <Label
            modifiers={compact(['uppercase', operatingStatusTypeModifier])}
          >
            <Trans id={operatingStatusTypeValue} />
          </Label>
        </Column>
      </Row>
    </Column>
  );
}

OperatingStatus.propTypes = {
  operatingStatusTypeModifier: PropTypes.string.isRequired,
  operatingStatusTypeValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]).isRequired,
};

import gql from 'graphql-tag';

export default gql`
  mutation updateCaseHighwayMutation($highway: String!, $id: ID!) {
    updateCase(
      input: { id: $id, patch: { assetLocation: { highway: $highway } } }
    ) {
      case {
        id
      }
    }
  }
`;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ApolloConsumer } from '@apollo/client';
import { find, flatten, map, values } from 'lodash';
import { compose, fromRenderProps, setDisplayName } from 'recompose';

import { withOperationsStatus } from 'features/apolloOperationsObserver';

import caseAssignedToQuery from './caseAssignedToQuery';
import WatchForOwnerChange from './WatchForOwnerChange';
import UserChangedWarningModal from './UserChangedWarningModal';
import { caseMutations } from '../../constants';

class CaseOwnershipCheck extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    runQuery: PropTypes.func.isRequired,
    caseNumber: PropTypes.string.isRequired,
    caseMutationsStatus: PropTypes.shape({}).isRequired,
  };

  // We show the modal the first time we get an access denied,
  // and only hide it when the user closes it. If the modal is
  // visible, we ignore status changes on any mutation that might
  // have triggered the access denied.
  static getDerivedStateFromProps({ caseMutationsStatus }, { showModal }) {
    if (!showModal) {
      const allErrors = flatten(map(values(caseMutationsStatus), 'errors'));
      const wasDeniedAccess = !!find(allErrors, { code: 'access_denied' });

      if (wasDeniedAccess) return { showModal: true };
    }

    return null;
  }

  state = { showModal: false };

  componentDidUpdate(_, { showModal: wasShowingModal }) {
    const { runQuery, caseNumber } = this.props;

    // Reload case owner query. Should be ok to fire and forget
    if (!wasShowingModal && this.state.showModal) {
      runQuery({ query: caseAssignedToQuery, variables: { caseNumber } });
    }
  }

  closeModal = () => this.setState({ showModal: false });

  render() {
    return (
      <>
        <WatchForOwnerChange caseNumber={this.props.caseNumber}>
          {this.props.children}
        </WatchForOwnerChange>
        {this.state.showModal && (
          <UserChangedWarningModal
            onClose={this.closeModal}
            caseNumber={this.props.caseNumber}
          />
        )}
      </>
    );
  }
}

export default compose(
  setDisplayName('CaseOwnershipCheck'),
  withOperationsStatus(caseMutations, 'caseMutationsStatus'),
  fromRenderProps(ApolloConsumer, (client) => ({ runQuery: client.query })),
)(CaseOwnershipCheck);

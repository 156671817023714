import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { isEmpty, reduce } from 'lodash';

import { MessageSmall } from 'base-components';
import { Row, Column } from 'styled-components-grid';

import { FIELD_NAMES } from '../constants';

export const validationErrorMessages = {
  [FIELD_NAMES.UNIT_NUMBER]: t`Unit Number cannot contain the following characters: ~\`.`,
  [FIELD_NAMES.ASSET_TYPE]: t`Select an existing unit type.`,
};

function getErrorMessage(errors) {
  if (isEmpty(errors)) return null;

  return reduce(
    errors,
    (message, errored, field) => {
      if (!errored) return message;

      const error = validationErrorMessages[field];
      return `${message}${i18n._(error)}\n`;
    },
    '',
  );
}

export function RequestAssetsFormError({ errors }) {
  const errorMessage = getErrorMessage(errors);

  if (errorMessage) {
    return (
      <Row modifiers={['padScaleY_2']}>
        <Column modifiers={['col', 'padScaleX_0']}>
          <MessageSmall type="warning">
            <span style={{ whiteSpace: 'pre-line' }}>{errorMessage}</span>
          </MessageSmall>
        </Column>
      </Row>
    );
  }

  return null;
}

RequestAssetsFormError.propTypes = {
  errors: PropTypes.shape({
    [FIELD_NAMES.UNIT_NUMBER]: PropTypes.bool,
    [FIELD_NAMES.ASSET_TYPE]: PropTypes.bool,
  }).isRequired,
};

export default RequestAssetsFormError;

import gql from 'graphql-tag';
import { get } from 'lodash';
import React from 'react';
import { Query } from '@apollo/client/react/components';

const currentUserQuery = gql`
  query CaseDetailOverviewPanel_CaseAssignUserButton_currentUserQuery {
    currentUserProfile {
      user {
        email
        firstName
        lastName
        name
        userName
      }
    }
  }
`;

const withCurrentUser = (WrappedComponent) => (componentProps) => (
  <Query query={currentUserQuery}>
    {({ data, loading }) => {
      const currentUser = get(data, 'currentUserProfile.user');
      return (
        <WrappedComponent
          {...componentProps}
          currentUser={currentUser}
          currentUserLoading={loading}
        />
      );
    }}
  </Query>
);

export default withCurrentUser;

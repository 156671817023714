import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'styled-components-grid';

function Section(props) {
  const { modifiers, ...rest } = props;
  const defaultModifiers = ['center'];
  const sectionModifiers = defaultModifiers.concat(modifiers);

  return <Row modifiers={sectionModifiers} {...rest} />;
}

Section.defaultProps = {
  modifiers: [],
};

Section.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

export default Section;

import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName } from 'recompose';

import patchFromChangedProps from 'utils/patchFromChangedProps';

import {
  withCasePanelStatusActions,
  withCaseIsNewCase,
  CASE_PANELS,
} from 'compositions/CaseStatus';

import withCase from './withCase';
import withCallContactAttributes from './withCallContactAtrributes';
import { updatePanelStatus } from './utils';

const { Provider, Consumer } = createContext();

/* eslint-disable react/no-unused-state */
export class CaseInboundProgramPanelContext extends Component {
  static propTypes = {
    callContactAttributes: PropTypes.shape({
      dialedNumber: PropTypes.string,
      shipTo: PropTypes.string,
    }).isRequired,
    caseId: PropTypes.string,
    caseNumber: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    inboundProgramNumberId: PropTypes.string,
    inboundProgramShipTo: PropTypes.string,
    isNewCase: PropTypes.bool.isRequired,
    requiresValidationForAssetTypes: PropTypes.arrayOf(PropTypes.shape({})),

    // eslint-disable-next-line react/no-unused-prop-types
    setCasePanelStatus: PropTypes.func.isRequired,
  };

  static defaultProps = {
    caseId: null,
    inboundProgramNumberId: null,
    inboundProgramShipTo: null,
    requiresValidationForAssetTypes: [],
  };

  static getDerivedStateFromProps(props, state) {
    return patchFromChangedProps(props, state, [
      'caseId',
      'isNewCase',
      'caseNumber',
      'inboundProgramShipTo',
      'inboundProgramNumberId',
      'requiresValidationForAssetTypes',
    ]);
  }

  constructor(props) {
    super(props);

    this.state = {
      /**
       * callContactAttributes should be read only, never updated. Setting here & not updating
       * ensures that the contact was available when the composition was rendered. This is one
       * part of ensuring the call was active when the case was created, a requirement for
       * connecting the call info to inbound programs.
       */
      callContactAttributes: props.callContactAttributes,
      caseId: props.caseId,
      caseNumber: props.caseNumber,
      displayInboundProgramLocationSelector: false,
      inboundProgramLocationSearchValue: '',
      inboundProgramNumberId: props.inboundProgramNumberId,
      inboundProgramSearchValue: '',
      inboundProgramShipTo: props.inboundProgramShipTo,
      isNewCase: props.isNewCase,
      requiresValidationForAssetTypes: props.requiresValidationForAssetTypes,
      updateCaseInboundProgramPanelContext: this.update,
    };
  }

  componentDidMount() {
    updatePanelStatus(this.props, this.state);
  }

  componentDidUpdate() {
    updatePanelStatus(this.props, this.state);
  }

  update = (newContext, callback) => this.setState(newContext, callback);

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

const context = compose(
  setDisplayName('CaseInboundProgramPanelContext'),
  withCasePanelStatusActions(CASE_PANELS.inboundProgram),
  withCase,
  withCaseIsNewCase,
  withCallContactAttributes,
)(CaseInboundProgramPanelContext);

context.Consumer = Consumer;

export default context;

import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { px2rem } from 'decisiv-ui-utils';
import { Checkbox, Text } from 'base-components';
import { get, isEmpty, noop } from 'lodash';
import { Container, Row, Column } from 'styled-components-grid';

import { formatRecurrenceDays } from '../../RotationSchedulePanel/helpers';
import { AfterHoursEventPropType } from '../../propTypes';

import Item from './Item';
import SaveIndicator from './SaveIndicator';
import Emphasis from './Emphasis';

const getDateString = (event) => {
  const dateFormat = 'D MMM YYYY, h:mm A z';
  let { startDate, endDate } = event;

  if (event.isRecurring && get(event, 'options.recurringStartDate')) {
    startDate = get(event, 'options.recurringStartDate', '');
    endDate = get(event, 'options.recurringEndDate', '');
    // when endDate is null, it means the recurrence ends one year after start date
    if (!endDate) {
      endDate = moment(startDate).add(1, 'years').format('YYYY-MM-DD');
    }
  }

  const start = moment(`${startDate} ${event.startTime}`).format(dateFormat);
  const end = moment(`${endDate} ${event.endTime}`).format(dateFormat);
  return `${start} - ${end}`;
};

const contactTypes = {
  primary: t`Primary`,
  backup: t`Backup`,
  final: t`Final`,
};

const buildContactsString = (event, selectedContact) => {
  const { primaryContact, backupContact, finalContact } = event;

  const contacts = {
    primary: primaryContact,
    backup: backupContact,
    final: finalContact,
  };

  return Object.entries(contacts)
    .filter(([, contact]) => get(contact, 'name'))
    .map(([key, contact], index) => {
      const type = contactTypes[key];
      const contactName = contact.name;

      return contact.id === selectedContact.id
        ? i18n._(t`<${index}>${contactName} (${type})</${index}>`)
        : i18n._(t`${contactName} (${type})`);
    })
    .join(', ');
};

// eslint-disable-next-line react/prop-types
const ItemLabel = ({ event, disabled, style }) => (
  <Text modifiers={['fontWeightMedium', disabled && 'textLight']} style={style}>
    {getDateString(event)}
  </Text>
);

const AfterHoursEventItem = (props) => {
  const { event, selectedContact } = props;
  const { onChange, isSelected, readOnly } = props;

  const isDisabled = event.updated || event.updating;

  return (
    <Item
      onMouseDown={() => !isDisabled && onChange && onChange(event)}
      modifiers={[
        isDisabled && 'disabled',
        !isDisabled && onChange && 'selectable',
      ]}
    >
      {onChange ? (
        <Checkbox
          id={event.id}
          name={event.id}
          checked={isSelected}
          readOnly={readOnly}
          label={
            <ItemLabel
              event={event}
              style={{ cursor: 'pointer', paddingLeft: px2rem(5) }}
            />
          }
          onChange={noop}
        />
      ) : (
        <Row>
          <Column modifiers={['col', 'padScale_0']}>
            <ItemLabel event={event} disabled={isDisabled} />
          </Column>
        </Row>
      )}
      <Container
        modifiers={['padScale_0']}
        style={onChange && { paddingLeft: px2rem(30) }}
      >
        {!isEmpty(event.recurring) && (
          <Row>
            <Column modifiers={['col', 'padScale_0']}>
              <Text modifiers={[isDisabled && 'textLight']}>
                <Trans>Repeats: every {formatRecurrenceDays(event)}</Trans>
              </Text>
            </Column>
          </Row>
        )}
        <Row>
          <Column modifiers={['col', 'padScale_0']}>
            <Text modifiers={[isDisabled && 'textLight']}>
              <Trans components={[Emphasis, Emphasis, Emphasis]}>
                Contacts: {buildContactsString(event, selectedContact)}
              </Trans>
            </Text>
          </Column>
        </Row>
        {isDisabled && <SaveIndicator isSaving={event.updating} />}
      </Container>
    </Item>
  );
};

AfterHoursEventItem.propTypes = {
  // eslint-disable-next-line react/no-typos
  event: AfterHoursEventPropType.isRequired,
  onChange: PropTypes.func,
  isSelected: PropTypes.bool,
  readOnly: PropTypes.bool,
  selectedContact: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

AfterHoursEventItem.defaultProps = {
  onChange: undefined,
  isSelected: false,
  readOnly: false,
};

export default AfterHoursEventItem;

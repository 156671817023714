import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { t, Trans } from '@lingui/macro';
import { find, get, isNumber, noop } from 'lodash';

import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';
import { Dropdown, Text } from 'base-components';

import { i18n } from '@lingui/core';
import withFocusReceiver from 'setup/FocusProvider/withFocusReceiver';

import withEtas from '../../withEtas';
import InputField from '../InputField';
import withCaseDealerResponseActions from '../../withCaseDealerResponseActions';
import { etaRequiringReasons } from '../../constants';
import { buildFocusReceiverId } from '../utils';

export const buildEtaStringAndValues = ({ minMinutes, maxMinutes } = {}) => {
  if (!isNumber(minMinutes)) return {};

  if (!maxMinutes) {
    const time = moment().add(150, 'minutes').format('h:mm A');

    return {
      shortTemplate: t`Over 2.5 hours`,
      template: t`Over 2.5 hours (After ${time})`,
      values: { time },
    };
  }

  const minTime = moment().add(minMinutes, 'minutes').format('h:mm A');

  const maxTime = moment().add(maxMinutes, 'minutes').format('h:mm A');

  return {
    shortTemplate: t`${minMinutes} - ${maxMinutes} min`,
    template: t`${minMinutes} - ${maxMinutes} min (${minTime} - ${maxTime})`,
    values: { minMinutes, minTime, maxMinutes, maxTime },
  };
};

function BaseRenderer({
  reason,
  etas,
  eta,
  id,
  readOnly,
  updateDealerResponse,
  onFocusRequested,
}) {
  const selectedEtaId = get(eta, 'id');
  const selectedEta = find(etas, ({ id: etaId }) => etaId === selectedEtaId);
  const {
    shortTemplate,
    values: selectedOptionValues,
  } = buildEtaStringAndValues(selectedEta);

  return (
    <Dropdown
      name={`dealerResponse-${id}-eta`}
      fullWidth
      hideOnChange
      onChange={(event, etaId) =>
        updateDealerResponse({ id, patch: { etaId } })
      }
      readOnly={readOnly}
    >
      {({ isVisible }) => (
        <>
          <Dropdown.Target>
            <InputField
              isValid={
                selectedEta ? true : !etaRequiringReasons.includes(reason)
              }
              placeholder={i18n._(t`Select...`)}
              readOnly={readOnly}
              style={{ cursor: 'pointer' }}
              value={
                selectedEta ? i18n._(shortTemplate, selectedOptionValues) : ''
              }
            >
              <Column modifiers={['col', 'padScaleY_0']}>
                <Row>
                  <InputField.TextField
                    ref={(el) => onFocusRequested(el, { click: true })}
                    readOnly
                  />
                  <InputField.ActionButton
                    icon={isVisible ? 'chevron-up' : 'chevron-down'}
                    onClick={noop}
                    modifiers={['hoverInfo']}
                  />
                </Row>
              </Column>
            </InputField>
          </Dropdown.Target>
          {!!etas.length && (
            <Dropdown.Content
              style={{
                right: 'auto',
                whiteSpace: 'nowrap',
                maxHeight: px2rem(300),
                overflowY: 'auto',
              }}
            >
              <Dropdown.List>
                {etas.map(({ id: etaId, minMinutes, maxMinutes }) => {
                  const { template, values } = buildEtaStringAndValues({
                    minMinutes,
                    maxMinutes,
                  });

                  return (
                    <Dropdown.ListItem key={etaId} id={etaId}>
                      <Text>
                        <Trans id={template} values={values} />
                      </Text>
                    </Dropdown.ListItem>
                  );
                })}
              </Dropdown.List>
            </Dropdown.Content>
          )}
        </>
      )}
    </Dropdown>
  );
}

BaseRenderer.propTypes = {
  etas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      minMinutes: PropTypes.number.isRequired,
      maxMinutes: PropTypes.number,
    }),
  ),
  eta: PropTypes.shape({
    id: PropTypes.string.isRequired,
    minMinutes: PropTypes.number,
    maxMinutes: PropTypes.number,
  }),
  id: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  updateDealerResponse: PropTypes.func.isRequired,
  reason: PropTypes.string,
  onFocusRequested: PropTypes.func.isRequired,
};

BaseRenderer.defaultProps = {
  etas: [],
  eta: undefined,
  reason: undefined,
};

const CellRenderer = compose(
  withEtas,
  withCaseDealerResponseActions,
  withFocusReceiver(buildFocusReceiverId('eta')),
)(BaseRenderer);

export default {
  name: 'eta',
  cellKeyGetter: ({ id }) => `${id}:eta`,
  cellDataGetter: (args) => args,
  headerCellRenderer: () => (
    <Row>
      <Column modifiers={['padScaleX_2', 'padScaleY_3']}>
        <Text modifiers={['small', 'textLight']}>
          <Trans>ETA</Trans>
        </Text>
      </Column>
    </Row>
  ),
  dataCellRenderer: (
    { id, eta, reason }, // eslint-disable-line react/prop-types
    { tableMetaData, rowIndex },
  ) => (
    <CellRenderer
      id={id}
      eta={eta}
      reason={reason}
      rowIndex={rowIndex}
      readOnly={tableMetaData.readOnly}
      dealerIndex={tableMetaData.dealerIndex}
    />
  ),
};

import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { get, values } from 'lodash';
import { compose, setDisplayName } from 'recompose';
import { scroller } from 'react-scroll';

import { px2rem } from 'decisiv-ui-utils';
import { Container, Row } from 'styled-components-grid';

import { leftNavStateSelector } from 'redux/preferences/selectors';

import MeasureComponentOffscreen from 'components/MeasureComponentOffscreen';

import { PANEL_STATUSES } from '../../constants';
import { getColorModifierForStatus } from '../../utils';

import Status from './Status';
import Indicator from './Indicator';
import IndicatorWrapper from './IndicatorWrapper';
import StatusBarButtons from './StatusBarButtons';

import BasicInfo from './BasicInfo';

import {
  MAIN_CONTENT_PADDING,
  STATUS_BAR_HEIGHT,
  STATUS_BAR_HEIGHT_PX,
} from './constants';

const renderStatusBarButtons = (props) => (
  <StatusBarButtons
    items={[
      { key: 'cancel', component: props.cancelButton },
      { key: 'action', component: props.actionButton },
      { key: 'restore', component: props.restoreButton },
      { key: 'close', component: props.closeButton },
    ]}
  />
);

renderStatusBarButtons.propTypes = {
  cancelButton: PropTypes.node,
  closeButton: PropTypes.node,
  actionButton: PropTypes.node,
  restoreButton: PropTypes.node,
};

renderStatusBarButtons.defaultProps = {
  cancelButton: null,
  closeButton: null,
  actionButton: null,
  restoreButton: null,
};

export class StatusBar extends Component {
  static propTypes = {
    validationStatus: PropTypes.string.isRequired,
    indicators: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        status: PropTypes.shape({
          panel: PropTypes.oneOf(values(PANEL_STATUSES)).isRequired,
          fields: PropTypes.shape({}),
        }).isRequired,
      }),
    ),
    isNavExpanded: PropTypes.bool,
    theme: PropTypes.shape({
      dimensions: PropTypes.shape({
        leftNavWidth: PropTypes.string.isRequired,
        leftNavWidthCollapsed: PropTypes.string.isRequired,
        topNavHeightInPixel: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
    basicInfoSize: PropTypes.shape({ width: PropTypes.number }).isRequired,
    buttonsSize: PropTypes.shape({ width: PropTypes.number }).isRequired,
  };

  static defaultProps = {
    indicators: [],
    isNavExpanded: undefined,
  };

  state = { isSticky: false, isWide: false };

  componentDidMount() {
    if (this.intersectionObserver && this.containerRef.current) {
      this.intersectionObserver.observe(this.containerRef.current);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isSticky && this.state.isSticky) {
      // React docs say it's safe to set state here when wrapped in a condition
      /* eslint-disable react/no-did-update-set-state */
      this.setState({ isWide: true });
      /* eslint-enable react/no-did-update-set-state */
    }
  }

  componentWillUnmount() {
    if (this.intersectionObserver) this.intersectionObserver.disconnect();
  }

  handleIntersection = (entries) =>
    this.setState({
      isWide: false,
      // A less than 1 ratio means at least one pixel
      // is outside the intersection box, so we stick the bar.
      isSticky: get(entries, '0.intersectionRatio', 1) < 1,
    });

  handleScrollToWidget = (widget) => {
    scroller.scrollTo(widget, {
      containerId: 'scroll-content-layout',
      duration: 600,
      offset: -(
        this.props.theme.dimensions.topNavHeightInPixel + STATUS_BAR_HEIGHT_PX
      ),
      smooth: true,
    });
  };

  // eslint-disable-next-line react/sort-comp
  containerRef = createRef();

  intersectionObserver = new IntersectionObserver(this.handleIntersection, {
    rootMargin: `-${STATUS_BAR_HEIGHT_PX}px 0px 0px 0px`,
    threshold: 1,
  });

  render() {
    const {
      theme: {
        dimensions: { leftNavWidth, leftNavWidthCollapsed },
      },
      indicators,
      validationStatus,
      isNavExpanded,
      basicInfoSize: { width: infoWidth = 0 },
      buttonsSize: { width: buttonsWidth = 0 },
    } = this.props;

    const { isSticky, isWide } = this.state;
    const navWidth = isNavExpanded ? leftNavWidth : leftNavWidthCollapsed;
    const statusModifier = getColorModifierForStatus(validationStatus);
    const basicInfoExpanded = isSticky && isWide;

    return (
      <Container
        style={{ height: STATUS_BAR_HEIGHT }}
        ref={this.containerRef}
        modifiers="padScale_0"
      >
        <Status
          style={{
            left: `calc(${navWidth} + ${MAIN_CONTENT_PADDING})`,
            paddingLeft: px2rem(6),
          }}
          modifiers={[
            'padScaleX_4',
            'padScaleY_0',
            statusModifier,
            isWide && 'wide',
            isSticky && 'sticky',
          ]}
        >
          <Row
            style={{ width: '100%', flexWrap: 'nowrap' }}
            modifiers={['middle', 'flex_1', 'height_100']}
          >
            <BasicInfo
              isSticky={isSticky}
              isExpanded={basicInfoExpanded}
              expandedWidth={infoWidth}
              isNavExpanded={isNavExpanded}
            />
            <div
              style={{
                left: basicInfoExpanded ? infoWidth : 0,
                right: buttonsWidth,
                position: 'absolute',
                transition: `left ${isSticky ? '1' : '.5'}s ease-in-out`,
              }}
            >
              <div
                style={{
                  display: 'inline-block',
                  maxWidth: '100%',
                  alignItems: 'center',
                  paddingLeft: px2rem(8),
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {indicators.map(({ key, ...rest }) => (
                    <IndicatorWrapper
                      key={key}
                      onClick={() => this.handleScrollToWidget(key)}
                    >
                      <Indicator {...rest} />
                    </IndicatorWrapper>
                  ))}
                </div>
              </div>
            </div>
            {renderStatusBarButtons(this.props)}
          </Row>
        </Status>
      </Container>
    );
  }
}

export default compose(
  setDisplayName('StatusBar'),
  withTheme,

  (WrappedComponent) => (props) => (
    <MeasureComponentOffscreen
      component={renderStatusBarButtons}
      componentProps={props}
    >
      {({ size }) => <WrappedComponent {...props} buttonsSize={size} />}
    </MeasureComponentOffscreen>
  ),

  connect((s) => ({
    isNavExpanded: leftNavStateSelector(s).get('isExpanded'),
  })),

  (WrappedComponent) => (props) => (
    <MeasureComponentOffscreen
      component={BasicInfo.Content}
      componentProps={props}
    >
      {({ size }) => <WrappedComponent {...props} basicInfoSize={size} />}
    </MeasureComponentOffscreen>
  ),
)(StatusBar);

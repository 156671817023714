import React, { Component, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

const withSize = (ComponentWithSize) => {
  class AddContextSizeToProps extends Component {
    static propTypes = { innerRef: PropTypes.func };
    static contextTypes = { sizeStore: PropTypes.shape({}) };

    static displayName = `withSize(${
      ComponentWithSize.displayName || ComponentWithSize.name
    })`;

    state = {};

    UNSAFE_componentWillMount() {
      this.setState({ size: this.sizeStore().size });
      this.sizeStore().subscribe(this.handleSubscriptionEvents);
    }

    componentWillUnmount() {
      this.sizeStore().unsubscribe(this.handleSubscriptionEvents);
    }

    handleSubscriptionEvents = () => {
      const { size } = this.sizeStore();
      if (size !== this.state.size) {
        this.setState({ size });
      }
    };

    sizeStore = () =>
      this.context.sizeStore
        ? this.context.sizeStore
        : {
            size: '',
            subscribe: noop,
            unsubscribe: noop,
          };

    render() {
      const { innerRef, ...rest } = this.props;

      return (
        <ComponentWithSize size={this.state.size} {...rest} ref={innerRef} />
      );
    }
  }

  return forwardRef((props, ref) => (
    <AddContextSizeToProps {...props} innerRef={ref} />
  ));
};

export default withSize;

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';
import { Column } from 'styled-components-grid';

import { modifiersForComponent } from '../../utils/modifiers';

const styles = `
  margin-right: -1px;
  &:last-child {
    margin-right: 0;
  }
`;

const StyledInputGroupColumn = buildStyledComponent(
  'InputGroupColumn',
  styled(Column),
  styles,
);

function InputGroupColumn({ modifiers, ...rest }) {
  const inputGroupColumnModifiers = [
    'padScale_0',
    ...modifiersForComponent(modifiers, Column),
  ];
  return (
    <StyledInputGroupColumn modifiers={inputGroupColumnModifiers} {...rest} />
  );
}

InputGroupColumn.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

InputGroupColumn.defaultProps = {
  modifiers: [],
};

export default InputGroupColumn;

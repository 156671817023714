import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get, uniqBy } from 'lodash';
import { t, Trans } from '@lingui/macro';
import { compose, setDisplayName, branch } from 'recompose';

import { Accordion, Avatar, Text } from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Container, Row, Column } from 'styled-components-grid';

import Tag from 'blocks/Tag';

import { CallButtonLink } from 'compositions/ClickToCall';
import { useCaseShortcut, CASE_SHORTCUT_PANELS } from 'features/keyShortcuts';

import withCustomerSelect from '../../withCustomerSelect';
import withStoreCustomerSelect from '../../withStoreCustomerSelect';
import withCustomerContacts from './withCustomerContacts';

const lastContactStyles = { marginBottom: px2rem(25) };

export function Contact({ dealer, name, email, contactNumbers, contactTypes }) {
  const phoneNumber = get(contactNumbers, '[0].number');
  return (
    <Row>
      <Column>
        <Avatar modifiers={['small']} name={name} />
      </Column>
      <Column modifiers="col">
        <Row>
          <Column modifiers={['padScaleY_0', 'flex_1']}>
            <Text>{name || <span>&mdash;</span>}</Text>
          </Column>
        </Row>
        {(phoneNumber || email) && (
          <Row>
            {phoneNumber && (
              <Column modifiers={['padScaleY_0']}>
                <CallButtonLink
                  dealer={dealer}
                  name={name}
                  phone={phoneNumber}
                />
              </Column>
            )}
            {phoneNumber && email && (
              <Column modifiers={['padScaleY_0']}>
                <Text modifiers={['small', 'textLight']}>
                  &nbsp;&middot;&nbsp;
                </Text>
              </Column>
            )}
            {email && (
              <Column modifiers={['padScaleY_0']}>
                <Text modifiers={['small', 'textLight']}>{email}</Text>
              </Column>
            )}
          </Row>
        )}
        {!!contactTypes.length && (
          <Row>
            <Column modifiers="flex_1">
              {uniqBy(contactTypes, 'name').map(({ name: contactType }) => (
                <Fragment key={contactType}>
                  <Tag modifiers="inline">
                    <Tag.Text>{contactType}</Tag.Text>
                  </Tag>
                  &nbsp;
                </Fragment>
              ))}
            </Column>
          </Row>
        )}
      </Column>
    </Row>
  );
}

Contact.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string,
  contactNumbers: PropTypes.arrayOf(
    PropTypes.shape({ number: PropTypes.string }),
  ),
  contactTypes: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  dealer: PropTypes.shape({}),
};

Contact.defaultProps = {
  email: '',
  dealer: undefined,
  contactTypes: [],
  contactNumbers: [],
};

export function Contacts({ contacts, customer }) {
  const hasData = contacts.length > 0;

  const [expanded, setExpanded] = React.useState(false);

  const { onFocusRequested } = useCaseShortcut({
    parent: CASE_SHORTCUT_PANELS.customer,
    id: 'customerContactsAction',
    name: t`Fleet Contacts`,
    shortcut: ['f', '2'],
    priority: 6,
    perform: () => hasData && setExpanded((e) => !e),
    deps: hasData,
  });

  return (
    <Accordion
      key={get(customer, 'id')}
      disabled={!hasData}
      expanded={expanded}
      onExpandedChange={({ expanded }) => setExpanded(expanded)}
    >
      <Accordion.Head>
        <Accordion.Title ref={onFocusRequested}>
          <Trans>
            Fleet Contacts
            {hasData ? ` (${contacts.length})` : ''}
          </Trans>
        </Accordion.Title>
      </Accordion.Head>
      <Accordion.Body>
        {contacts.map((contact, index) => (
          <Container
            key={contact.id}
            style={index === contacts.length - 1 ? lastContactStyles : {}}
          >
            <Contact {...contact} />
          </Container>
        ))}
      </Accordion.Body>
    </Accordion>
  );
}

Contacts.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.shape(Contact.propTypes)),
  customer: PropTypes.shape({ id: PropTypes.string }),
};

Contacts.defaultProps = {
  contacts: [],
  customer: undefined,
};

const isNationalCustomer = ({ customerType, customerTypes }) =>
  customerType === customerTypes.NATIONAL.type;

export default compose(
  setDisplayName('Contacts'),
  /**
   * Order is important here. `withCustomerContacts` needs the current customer
   * as a prop, so we must run `withCustomerSelect/withStoreCustomerSelect`
   * first.
   */
  branch(isNationalCustomer, withCustomerSelect, withStoreCustomerSelect),
  withCustomerContacts,
)(Contacts);

import React from 'react';
import { curry, get } from 'lodash';
import { Query } from '@apollo/client/react/components';

import CustomerWidgetContext from '../../CustomerWidgetContext';
import caseCustomerNotificationPreferencesQuery from './caseCustomerEmailNotificationPreferencesQuery';

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const { data } = queryProps;
    const emailNotificationPreferences =
      get(data, 'case.customer.emailNotificationPreferences') || [];

    return (
      <WrappedComponent
        {...componentProps}
        emailNotificationPreferences={emailNotificationPreferences}
      />
    );
  },
);

function buildWrappedComponentWithQuery(WrappedComponent, componentProps) {
  const { caseNumber, customerId } = componentProps;

  return (
    <Query
      query={caseCustomerNotificationPreferencesQuery}
      skip={!caseNumber || !customerId}
      variables={{ caseNumber, customerId }}
      fetchPolicy="cache-and-network"
    >
      {buildQueryChild(WrappedComponent, componentProps)}
    </Query>
  );
}

export const buildConsumerChild = curry(
  (WrappedComponent, componentProps, consumerProps) => {
    const { caseNumber } = consumerProps;

    return buildWrappedComponentWithQuery(WrappedComponent, {
      ...componentProps,
      caseNumber,
      customerId: get(componentProps, 'customer.id'),
    });
  },
);

const withCustomerNotificationPreferences = (WrappedComponent) => (
  componentProps,
) => (
  <CustomerWidgetContext.Consumer>
    {buildConsumerChild(WrappedComponent, componentProps)}
  </CustomerWidgetContext.Consumer>
);

export default withCustomerNotificationPreferences;

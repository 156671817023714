import { withTheme } from 'styled-components';
import { compose, setDisplayName } from 'recompose';

import withContext from 'utils/withContext';

import { CaseStatusContext } from 'compositions/CaseStatus';

import withDealers from '../withDealers';
import withIsRouteSearch from '../withIsRouteSearch';
import withLocations from '../withLocations';

import SearchInputComponent from './SearchInputComponent';

export default compose(
  setDisplayName('DealerLocator__SearchInput'),
  withDealers,
  withIsRouteSearch,
  withLocations,
  withTheme,
  withContext(CaseStatusContext, false),
)(SearchInputComponent);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import Table from '../../blocks/Table';

// TODO: also extract Cell rendering in order
// to prevent unnecessary re-renders of those.
import Head from './Head';
import Row from './Row';

const defaultRowModifiers = [];

class DataTable extends Component {
  static propTypes = {
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        width: PropTypes.string,
        cellStyles: PropTypes.object,
        cellKeyGetter: PropTypes.func.isRequired,
        cellDataGetter: PropTypes.func.isRequired,
        headerCellRenderer: PropTypes.func.isRequired,
        headerCellAttrsGetter: PropTypes.func,
        dataCellRenderer: PropTypes.func.isRequired,
        dataCellAttrsGetter: PropTypes.func,
      }),
    ).isRequired,
    onHeaderCellClick: PropTypes.func,
    rowKeyGetter: PropTypes.func,
    rowModifiersGetter: PropTypes.func,
    scrollX: PropTypes.bool,
    shouldHeadUpdate: PropTypes.func,
    /**
     * This allows devs to put logic in place to prevent rows from
     * re-rendering when not necessary. This gets called for each
     * row with its current and next props as arguments.
     */
    shouldRowUpdate: PropTypes.func,
    tableData: PropTypes.arrayOf(PropTypes.object),
    tableMetaData: PropTypes.shape(),
  };

  static defaultProps = {
    onHeaderCellClick: noop,
    rowKeyGetter: (rowData) => rowData.id || rowData.key,
    rowModifiersGetter: () => defaultRowModifiers,
    scrollX: false,
    shouldHeadUpdate: () => false,
    shouldRowUpdate: () => true,
    tableData: [],
    tableMetaData: undefined,
  };

  renderHeaderCell = (column, colIndex) => {
    const headerCellAttrsGetter = column.headerCellAttrsGetter || noop;
    const { tableData, onHeaderCellClick } = this.props;

    return (
      <Table.HeaderCell
        key={column.name}
        style={{
          ...column.cellStyles,
          ...(column.width ? { width: column.width } : {}),
        }}
        onClick={() => onHeaderCellClick({ column, colIndex, tableData })}
        {...headerCellAttrsGetter({ colIndex, tableData })}
      >
        {column.headerCellRenderer(column, { colIndex })}
      </Table.HeaderCell>
    );
  };

  renderRow = (rowData, rowIndex) => {
    const {
      columns,
      tableData,
      rowKeyGetter,
      tableMetaData,
      shouldRowUpdate,
      rowModifiersGetter,
    } = this.props;

    const rowProps = {
      columns,
      rowData,
      rowIndex,
      tableData,
      tableMetaData,
      shouldUpdate: shouldRowUpdate,
      modifiers: rowModifiersGetter(rowData, rowIndex),
      cellRenderer: this.renderCell,
    };

    return <Row key={rowKeyGetter(rowData, rowIndex)} {...rowProps} />;
  };

  renderCell = (column, { colIndex, rowIndex, rowData }) => {
    const { tableData, tableMetaData } = this.props;
    const dataCellAttrsGetter = column.dataCellAttrsGetter || noop;
    const cellContent = column.dataCellRenderer(
      column.cellDataGetter(rowData),
      { tableData, tableMetaData, rowData, rowIndex, colIndex },
    );

    if (!cellContent) {
      return null;
    }

    return (
      <Table.DataCell
        key={column.cellKeyGetter(rowData)}
        style={column.cellStyles}
        {...dataCellAttrsGetter({ rowIndex, colIndex, rowData, tableData })}
      >
        {cellContent}
      </Table.DataCell>
    );
  };

  render() {
    const {
      columns,
      scrollX,
      tableData,
      shouldHeadUpdate,
      onHeaderCellClick,
      rowModifiersGetter,
      ...rest
    } = this.props;

    return (
      <Table.Container modifiers={[scrollX && 'scrollX']} {...rest}>
        <Table modifiers={['fillHeight', 'scrollBodyY']} {...rest}>
          <Head
            columns={columns}
            shouldUpdate={shouldHeadUpdate}
            cellRenderer={this.renderHeaderCell}
            rowModifiers={rowModifiersGetter()}
          />
          <Table.Body>{tableData.map(this.renderRow)}</Table.Body>
        </Table>
      </Table.Container>
    );
  }
}

export default DataTable;

import { compact } from 'lodash';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import { px2rem } from 'decisiv-ui-utils';
import { Icon, P, Popover, Text } from 'base-components';
import { Column, Container, Row } from 'styled-components-grid';

import ONCallSquareIcon from 'components/ONCallSquareIcon';
import ServiceSummaryIcons from 'components/ServiceSummaryIcons';

import HoverSensor from 'elements/HoverSensor';

import {
  ADDITIONAL_SERVICES,
  DEALER_LOCATION_ICON_NAMES,
  DEALER_LOCATION_SERVICES,
} from 'utils/dealerFilter';

function ServicesPopover({ position, services }) {
  const serviceCodes = services.map((s) => s.code);

  function setActiveModifier(code) {
    const active = serviceCodes.includes(code) && 'active';
    return compact([active]);
  }

  return (
    <Popover position={position} zIndex={200}>
      {({ isVisible }) => (
        <>
          <Popover.Target>
            <HoverSensor>
              {({ isActive: isHover }) => (
                <Row modifiers={['padScaleX_0', 'middle']}>
                  <ServiceSummaryIcons
                    services={services}
                    tooltipPositions={['top', 'top', 'left', 'left', 'left']}
                  />
                  <Column modifiers={['center', 'padScaleX_1']}>
                    <Icon
                      modifiers={['mini', isHover && 'info']}
                      name={isVisible ? 'chevron-up' : 'chevron-down'}
                    />
                  </Column>
                </Row>
              )}
            </HoverSensor>
          </Popover.Target>
          <Popover.Content style={{ marginLeft: px2rem(25) }}>
            <Container
              style={{
                width: px2rem(300),
                maxHeight: px2rem(200),
                overflowY: 'auto',
                textAlign: 'left',
              }}
            >
              <Row>
                <Column modifiers={['col', 'padScaleX_3']}>
                  {DEALER_LOCATION_SERVICES.map((code) => (
                    <Row key={code} modifiers="middle">
                      <Column modifiers={['display_flex', 'padScaleX_0']}>
                        <ONCallSquareIcon
                          modifiers={setActiveModifier(code)}
                          code={code}
                        />
                      </Column>
                      <Column modifiers={['col', 'padScaleX_2', 'padScaleY_0']}>
                        <P modifiers="small">
                          <Trans id={DEALER_LOCATION_ICON_NAMES[code]} />
                        </P>
                      </Column>
                    </Row>
                  ))}
                </Column>
              </Row>
              <Row>
                <Column modifiers={['col', 'padScaleX_3']}>
                  <Row>
                    <Column modifiers="padScale_0">
                      <Text modifiers={['small', 'textLight']}>
                        <Trans>Additional Services</Trans>
                      </Text>
                      {services
                        .filter(({ code }) => ADDITIONAL_SERVICES[code])
                        .map((service) => (
                          <Row key={service.id} modifiers="padScale_0">
                            <Column modifiers="padScale_0">
                              <P>
                                <Trans id={ADDITIONAL_SERVICES[service.code]} />
                              </P>
                            </Column>
                          </Row>
                        ))}
                    </Column>
                  </Row>
                </Column>
              </Row>
            </Container>
          </Popover.Content>
        </>
      )}
    </Popover>
  );
}

ServicesPopover.propTypes = {
  position: PropTypes.string,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
    }),
  ),
};

ServicesPopover.defaultProps = {
  position: 'bottomLeft',
  services: [],
};

export default ServicesPopover;

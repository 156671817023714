import { LOCALSTORAGE_PREFERENCES_KEY } from '../constants';

import { preferencesStoreSelector } from './selectors';

function addSubscriber(store, storage = memoryDB) {
  function onStateChange() {
    if (storage && storage.setItem) {
      const preferencesState = preferencesStoreSelector(store.getState());
      const preferencesStateAsString = JSON.stringify(preferencesState);
      storage.setItem(LOCALSTORAGE_PREFERENCES_KEY, preferencesStateAsString);
    }
  }

  return store.subscribe(onStateChange);
}

export default addSubscriber;

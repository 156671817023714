import { buildStyledComponent } from 'decisiv-ui-utils';
import styled from 'styled-components';

const styles = `
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
`;

export default buildStyledComponent('DateTable', styled.table, styles);

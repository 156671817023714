import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { buildStyledComponent } from 'decisiv-ui-utils';

import { AFTER_HOURS_COLOR_KEY, OPEN_HOURS_COLOR_KEY } from '../constants';
import { FadeIn } from '../../animations';

/**
 * Underlying styled component (an LI) used for any block rendered
 * in the schedule grid.
 */
const ScheduleBlock = buildStyledComponent(
  'ScheduleGrid__Block',
  styled.li,
  ({ theme }) => css`
    float: left;
    position: absolute;
    left: 1px;
    flex-shrink: 1;
    max-width: none;
    margin-right: 0;
    border-radius: ${theme.dimensions.borderRadius};
    padding: 0 ${theme.dimensions.gridPad};
    box-sizing: border-box;
    width: calc(100% - 1px);
    animation: ${FadeIn} 250ms;
  `,
  {
    themePropTypes: {
      colors: PropTypes.shape({
        status: PropTypes.shape({
          [OPEN_HOURS_COLOR_KEY]: PropTypes.string.isRequired,
          [`${AFTER_HOURS_COLOR_KEY}Light`]: PropTypes.string.isRequired,
          [AFTER_HOURS_COLOR_KEY]: PropTypes.string.isRequired,
          [`${AFTER_HOURS_COLOR_KEY}Light`]: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
      dimensions: PropTypes.shape({
        borderRadius: PropTypes.string.isRequired,
        gridPad: PropTypes.string.isRequired,
      }).isRequired,
    },
  },
);

export default ScheduleBlock;

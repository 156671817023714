import { Children, cloneElement } from 'react';
import { concat, intersection, isEmpty, castArray } from 'lodash';

/**
 * Compares the modifiers in the components props to an array of modifier names that should have
 * the default modifiers applied to the children.
 * @param {object} props The component's props
 * @property {array} props.modifiers The modifiers applied to the component.
 * @param {array} modifiersRequiringDefaults These are the modifiers on the top component that
 *                                           require a default set of modifiers applied to the
 *                                           children.
 * @returns {boolean}
 */
function applyDefaultModifiersToChildren(
  { modifiers },
  modifiersRequiringDefaults,
) {
  // `intersection` returns an array with the elements that appear in both arrays.
  return !!(
    modifiers &&
    !isEmpty(intersection(castArray(modifiers), modifiersRequiringDefaults))
  );
}

/**
 * Creates a batch of children with the appropriate default modifiers applied.
 * @param {object} props The component's props
 * @property {array} props.modifiers The modifiers applied to the component.
 * @property {node} props.modifiers The component's children
 * @param {array} modifiersRequiringDefaults These are the modifiers on the top component that
 *                                           require a default set of modifiers applied to the
 *                                           children.
 * @param {array} defaultModifiers These are the modifiers applied to the children if the top
 *                                 component is rendered with any of the modifiersRequiringDefaults
 * @returns {node} The component's _new_ children
 */
function childrenWithDefaultModifiers(
  props,
  modifiersRequiringDefaults,
  defaultModifiers,
) {
  // return the children as-is if no extra modifiers are required as defaults on the children.
  if (!applyDefaultModifiersToChildren(props, modifiersRequiringDefaults)) {
    return props.children;
  }

  // Prepend the default modifiers to ALL of the children's modifier arrays if the default
  // override is required. This order is critical, as it allows the developer to override
  // the defaults.
  return Children.map(props.children, (child) => {
    const modifiers = concat(
      defaultModifiers,
      castArray(child?.props?.modifiers),
    );

    return cloneElement(child, { modifiers });
  });
}

export default childrenWithDefaultModifiers;

import React, { Component } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import { Trans } from '@lingui/macro';
import { compact, includes, isEmpty, get } from 'lodash';

import { px2rem } from 'decisiv-ui-utils';
import { Column, Row } from 'styled-components-grid';
import { H2, P, Text, QuickActionButton } from 'base-components';

import Modal from 'components/Modal';
import RadioButton from 'components/RadioButton';
import RadioWrapper from 'elements/RadioWrapper';

import OccurrenceRangeSelector from './OccurrenceRangeSelector';
import { formatEventTime, formatRecurrenceDays } from '../helpers';
import { occurrenceKinds, occurrenceKindsLabels } from './constants';

/**
 * Renders a modal for saving an after-hours recurring rotation event.
 */
export class OccurrenceModal extends Component {
  static propTypes = {
    saveOccurrence: PropTypes.func.isRequired,
    event: PropTypes.shape({
      isRecurring: PropTypes.bool.isRequired,
      recurring: PropTypes.array.isRequired,
      options: PropTypes.shape({ recurringEndDate: PropTypes.string }),
    }).isRequired,
    onClose: PropTypes.func.isRequired,
    // Disabling because of false negatives
    // eslint-disable-next-line react/no-typos
    blockStart: MomentPropTypes.momentObj.isRequired,
    // eslint-disable-next-line react/no-typos
    blockEnd: MomentPropTypes.momentObj.isRequired,
  };

  constructor(props) {
    super(props);

    const originalDaysOfWeek = (this.props.event.recurring || []).map(
      ({ dayOfWeek }) => dayOfWeek,
    );

    this.state = {
      kind: null,
      originalDaysOfWeek,
      selectedDaysOfWeek: originalDaysOfWeek,
      startDate: this.props.blockStart.format('YYYY-MM-DD'),
      endDate: get(this.props.event, 'options.recurringEndDate') || null,
    };
  }

  updateOccurrence = (type) => {
    this.setState({ kind: type });
  };

  updateWeekdays = (dayOfWeekValue) => {
    const { selectedDaysOfWeek } = this.state;

    if (includes(selectedDaysOfWeek, dayOfWeekValue)) {
      // remove the dayOfWeekValue from selectedDaysOfWeek
      return this.setState({
        selectedDaysOfWeek: selectedDaysOfWeek.filter(
          (currentDay) => currentDay !== dayOfWeekValue,
        ),
      });
    }
    // add the dayOfWeekValue to selectedDaysOfWeek
    return this.setState({
      selectedDaysOfWeek: selectedDaysOfWeek.concat(dayOfWeekValue),
    });
  };

  updateRangeDates = (startDate, endDate) => {
    startDate
      ? this.setState({
          startDate: moment(startDate).format('YYYY-MM-DD'),
          endDate:
            this.state.endDate &&
            moment(this.state.endDate).isSameOrBefore(startDate)
              ? null
              : this.state.endDate,
        })
      : this.setState({
          endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
        });
  };

  handleSave = () => {
    const { kind, selectedDaysOfWeek, startDate, endDate } = this.state;
    if (kind === occurrenceKinds.range) {
      this.props.saveOccurrence({
        kind,
        startDate,
        endDate,
        daysOfWeek: selectedDaysOfWeek,
      });
      return;
    }

    this.props.saveOccurrence({ kind, startDate });
  };

  render() {
    const { blockStart, blockEnd, event, onClose } = this.props;
    const {
      kind: selectedKind,
      selectedDaysOfWeek,
      originalDaysOfWeek,
    } = this.state;

    const startString = formatEventTime(blockStart, 'MMM DD YYYY, ');
    const endString = formatEventTime(blockEnd);

    const endDateString =
      get(event, 'options.recurringEndDate') &&
      moment(event.options.recurringEndDate).format('MMM DD YYYY');

    const singleRotationString = moment(blockStart).format('MMM DD YYYY');

    const recurrenceDays = formatRecurrenceDays(event);

    const saveDisabled =
      !selectedKind ||
      (selectedKind === occurrenceKinds.range && isEmpty(selectedDaysOfWeek));

    return (
      <Modal onClose={onClose}>
        {({ closeModal }) => (
          <Modal.Body>
            <Modal.Header>
              <Modal.HeaderIcon name="calendar-exclamation" />
            </Modal.Header>
            <Modal.Content>
              <Row modifiers={['center']}>
                <Column>
                  <H2 modifiers={['fontWeightRegular']}>
                    <Trans>Edit Recurring Rotation</Trans>
                  </H2>
                </Column>
              </Row>
              <Row modifiers={['center']}>
                <Column modifiers={['col']}>
                  <P style={{ marginBottom: px2rem(10) }}>
                    <Trans>
                      You&apos;re editing the{' '}
                      <Text modifiers="fontWeightMedium">
                        {startString} — {endString}
                      </Text>{' '}
                      rotation. This rotation repeats every {recurrenceDays}{' '}
                      {endDateString && `until ${endDateString}`}.
                    </Trans>
                  </P>
                  <P style={{ marginBottom: px2rem(20) }}>
                    <Trans>
                      You can choose to modify only this occurrence of the
                      rotation on{' '}
                      <Text modifiers="fontWeightMedium">
                        {singleRotationString}
                      </Text>
                      , or a specific period within the rotation.
                    </Trans>
                  </P>
                </Column>
              </Row>
              <Row modifiers={['padScaleY_5']}>
                {Object.entries(occurrenceKindsLabels).map(([kind, label]) => (
                  <Column key={kind} modifiers={['col_12', 'padScaleY_0']}>
                    <RadioWrapper>
                      <RadioButton
                        id={kind}
                        checked={kind === selectedKind}
                        label={
                          <span
                            style={{
                              fontSize: px2rem(12),
                              textTransform: 'none',
                            }}
                          >
                            <Trans id={label} />
                          </span>
                        }
                        onChange={() => this.updateOccurrence(kind)}
                      />
                      {selectedKind === occurrenceKinds.range &&
                        kind === occurrenceKinds.range && (
                          <div style={{ padding: '5px 20px 0 32px' }}>
                            <OccurrenceRangeSelector
                              startDate={this.state.startDate}
                              endDate={this.state.endDate}
                              weekdays={selectedDaysOfWeek}
                              initialWeekdays={originalDaysOfWeek}
                              updateDates={this.updateRangeDates}
                              updateWeekdays={this.updateWeekdays}
                            />
                          </div>
                        )}
                    </RadioWrapper>
                  </Column>
                ))}
              </Row>
            </Modal.Content>
            <Modal.Footer>
              <Row modifiers={['end']}>
                <Column>
                  <QuickActionButton onClick={closeModal}>
                    <QuickActionButton.Text>
                      <Trans>Cancel</Trans>
                    </QuickActionButton.Text>
                  </QuickActionButton>
                </Column>
                <Column>
                  <QuickActionButton
                    disabled={saveDisabled}
                    modifiers={compact([
                      'secondary',
                      saveDisabled && 'disabled',
                    ])}
                    onClick={this.handleSave}
                  >
                    <QuickActionButton.Text>
                      <Trans>Next</Trans>
                    </QuickActionButton.Text>
                  </QuickActionButton>
                </Column>
              </Row>
            </Modal.Footer>
          </Modal.Body>
        )}
      </Modal>
    );
  }
}

export default OccurrenceModal;

import PropTypes from 'prop-types';
import React, { Component, createContext } from 'react';
import { isEmpty } from 'lodash';
import { compose, setDisplayName } from 'recompose';

import patchFromChangedProps from 'utils/patchFromChangedProps';

import {
  CASE_PANELS,
  withCaseIsNewCase,
  withCasePanelStatusActions,
} from 'compositions/CaseStatus';

import withHasCustomerMismatch from './withHasCustomerMismatch';
import withCallContactAttributes from './withCallContactAttributes';

const { Provider, Consumer } = createContext();

/* eslint-disable react/no-unused-state */
export class CustomerWidgetContext extends Component {
  static propTypes = {
    callContactAttributes: PropTypes.shape({
      dialedNumber: PropTypes.string,
      shipTo: PropTypes.string,
    }).isRequired,
    caseId: PropTypes.string,
    caseNumber: PropTypes.string.isRequired,
    caseStatus: PropTypes.string.isRequired,
    isReadOnlyCase: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    customerType: PropTypes.string.isRequired,
    isNewCase: PropTypes.bool.isRequired,
    hasCustomerMismatch: PropTypes.bool.isRequired,
    setCasePanelComplete: PropTypes.func.isRequired,
    setCasePanelIncomplete: PropTypes.func.isRequired,
    setCasePanelPartial: PropTypes.func.isRequired,
    setCasePanelInvalid: PropTypes.func.isRequired,
    customerTypes: PropTypes.shape({
      STORE: PropTypes.shape({ type: PropTypes.string }),
      CUSTOM_STORE: PropTypes.shape({ type: PropTypes.string }),
    }),
  };

  static defaultProps = {
    caseId: undefined,
    customerTypes: {},
  };

  static getDerivedStateFromProps(props, state) {
    const patch =
      patchFromChangedProps(props, state, [
        'caseId',
        'caseStatus',
        'caseNumber',
        'customerType',
        'isNewCase',
      ]) || {};

    // If the customerType changes from anything _except_ store customer to
    // custom store customer, clear _all_ of the search values. This specific
    // case is required to prepopulate the customer name in the custom store
    // customer input form.
    if (
      state.customerType !== props.customerType &&
      state.customerType !== props.customerTypes.STORE.type &&
      props.customerType !== props.customerTypes.CUSTOM_STORE.type
    ) {
      patch.customerSearchValue = '';
      patch.domicileSearchValue = '';
      patch.storeCustomerSearchValue = '';
    }

    const isReadOnlyCase = props.isReadOnlyCase && !props.hasCustomerMismatch;

    if (state.isReadOnlyCase !== isReadOnlyCase) {
      patch.isReadOnlyCase = isReadOnlyCase;
    }

    return isEmpty(patch) ? null : patch;
  }

  updateCustomerWidgetContext = this.setState.bind(this);

  constructor(props) {
    super(props);

    const { isReadOnlyCase, hasCustomerMismatch } = props; // eslint-disable-line no-unused-vars

    this.state = {
      /**
       * callContactAttributes should be read only, never updated. Setting here & not updating
       * ensures that the contact was available when the composition was rendered. This is one
       * part of ensuring the call was active when the case was created, a requirement for
       * connecting the call info to a customer.
       */
      callContactAttributes: this.props.callContactAttributes,
      caseId: this.props.caseId,
      caseNumber: this.props.caseNumber,
      caseStatus: this.props.caseStatus,
      isReadOnlyCase: isReadOnlyCase && !hasCustomerMismatch,
      customerSearchValue: '',
      domicileSearchValue: '',
      customerType: this.props.customerType,
      isNewCase: this.props.isNewCase,
      isNewCustomStoreCustomer: false,
      storeCustomerSearchValue: '',
      setCasePanelComplete: this.props.setCasePanelComplete,
      setCasePanelIncomplete: this.props.setCasePanelIncomplete,
      setCasePanelPartial: this.props.setCasePanelPartial,
      setCasePanelInvalid: this.props.setCasePanelInvalid,
      updateCustomerWidgetContext: this.updateCustomerWidgetContext,
      hasCustomerMismatch: this.props.hasCustomerMismatch,
    };
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

const context = compose(
  setDisplayName('CustomerWidgetContext'),
  withCasePanelStatusActions(CASE_PANELS.customer),
  withCaseIsNewCase,
  withHasCustomerMismatch,
  withCallContactAttributes,
)(CustomerWidgetContext);

context.Consumer = Consumer;

export default context;

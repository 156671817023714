import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { curry, get } from 'lodash';
import { Mutation } from '@apollo/client/react/components';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose, setDisplayName } from 'recompose';

import { setNewCaseNumber } from 'redux/cases/actions';

import Loading from 'components/Loading';

import createCaseGQL from './createCaseMutation';
import createRequestLineGQL from './createCaseRequestLineMutation';

export class CaseCreator extends Component {
  static propTypes = {
    caseData: PropTypes.shape({
      createCase: PropTypes.shape({ caseNumber: PropTypes.string }),
    }),
    createCase: PropTypes.func.isRequired,
    createCaseError: PropTypes.shape(),
    createCaseLoading: PropTypes.bool,
    setNewCaseNumber: PropTypes.func.isRequired,
    createRequestLine: PropTypes.func.isRequired,
  };

  static defaultProps = {
    caseData: undefined,
    createCaseError: undefined,
    createCaseLoading: false,
  };

  state = { initialRequestLineId: undefined };

  UNSAFE_componentWillMount() {
    const { createCase, createCaseError } = this.props;
    const { createCaseLoading, createRequestLine } = this.props;

    if (!createCaseError && !createCaseLoading) {
      createCase().then((response) => {
        const caseId = get(response, 'data.createCase.case.id');

        if (caseId) {
          createRequestLine({ variables: { caseId } }).then((result) => {
            const id = result?.data?.createCaseRequestLine?.requestLine?.id;

            if (id) this.setState({ initialRequestLineId: id });
          });
        }
      });
    }
  }

  componentWillUnmount() {
    const caseNumber = get(this.props, 'caseData.createCase.case.caseNumber');
    this.props.setNewCaseNumber({ caseNumber });
  }

  render() {
    const { caseData } = this.props;
    const { initialRequestLineId } = this.state;

    const caseNumber = get(caseData, 'createCase.case.caseNumber');

    if (caseNumber && initialRequestLineId) {
      return <Redirect to={`/cases/${caseNumber}`} />;
    }

    return <Loading />;
  }
}

export const buildMutationChild = curry(
  (componentProps, createCase, createCaseState, createRequestLine) => {
    const { data, error, loading } = createCaseState;

    return (
      <CaseCreator
        {...componentProps}
        caseData={data}
        createCase={createCase}
        createCaseError={error}
        createCaseLoading={loading}
        createRequestLine={createRequestLine}
      />
    );
  },
);

export function CaseCreatorWithMutation(componentProps) {
  return (
    <Mutation mutation={createCaseGQL}>
      {(createCase, createCaseState) => (
        <Mutation mutation={createRequestLineGQL}>
          {buildMutationChild(componentProps, createCase, createCaseState)}
        </Mutation>
      )}
    </Mutation>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    setNewCaseNumber: (payload) => dispatch(setNewCaseNumber(payload)),
  };
}

export default compose(
  setDisplayName('CaseCreator'),
  connect(undefined, mapDispatchToProps),
)(CaseCreatorWithMutation);

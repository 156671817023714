import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

import ExpanderButton from './ExpanderButton';
import Footer from './Footer';
import MenuBlock from './MenuBlock';
import MenuGroupItem from './MenuGroupItem';
import MenuGroupsDivider from './MenuGroupsDivider';
import MenuGroupsList from './MenuGroupsList';
import MenuItem from './MenuItem';
import MenuItemFlyout from './MenuItemFlyout';
import MenuItemTitle from './MenuItemTitle';
import MenuItemTitleA from './MenuItemTitleA';
import MenuItemTitleGhost from './MenuItemTitleGhost';
import MenuItemTitleIcon from './MenuItemTitleIcon';
import MenuItemTitleLink from './MenuItemTitleLink';
import MenuItemTitleNoLink from './MenuItemTitleNoLink';
import MenuItemTitleText from './MenuItemTitleText';
import MenuSubItem from './MenuSubItem';
import MenuSubItemA from './MenuSubItemA';
import MenuSubItemLink from './MenuSubItemLink';
import MenusList from './MenusList';

const modifierConfig = {
  expanded: ({ theme }) => `
    width: ${theme.dimensions.leftNavWidth};
  `,
};

const styles = ({ theme }) => `
  background-color: ${theme.colors.base.chrome100};
  border-right: 1px solid ${theme.colors.base.chrome200};
  box-sizing: border-box;
  height: 100%;
  position: relative;
  transition: width ${theme.dimensions.leftNavExpandTime};
  width: ${theme.dimensions.leftNavWidthCollapsed};
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome100: PropTypes.string.isRequired,
      chrome200: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dimensions: PropTypes.shape({
    leftNavExpandTime: PropTypes.string.isRequired,
    leftNavWidth: PropTypes.string.isRequired,
    leftNavWidthCollapsed: PropTypes.string.isRequired,
  }).isRequired,
};

const LeftNav = buildStyledComponent('LeftNav', styled.nav, styles, {
  modifierConfig,
  themePropTypes,
});

LeftNav.ExpanderButton = ExpanderButton;
LeftNav.Footer = Footer;
LeftNav.MenuBlock = MenuBlock;
LeftNav.MenuGroupItem = MenuGroupItem;
LeftNav.MenuGroupsDivider = MenuGroupsDivider;
LeftNav.MenuGroupsList = MenuGroupsList;
LeftNav.MenuItem = MenuItem;
LeftNav.MenuItemFlyout = MenuItemFlyout;
LeftNav.MenuItemTitle = MenuItemTitle;
LeftNav.MenuItemTitleA = MenuItemTitleA;
LeftNav.MenuItemTitleGhost = MenuItemTitleGhost;
LeftNav.MenuItemTitleIcon = MenuItemTitleIcon;
LeftNav.MenuItemTitleLink = MenuItemTitleLink;
LeftNav.MenuItemTitleNoLink = MenuItemTitleNoLink;
LeftNav.MenuItemTitleText = MenuItemTitleText;
LeftNav.MenuSubItem = MenuSubItem;
LeftNav.MenuSubItemA = MenuSubItemA;
LeftNav.MenuSubItemLink = MenuSubItemLink;
LeftNav.MenusList = MenusList;

export default LeftNav;

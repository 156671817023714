import gql from 'graphql-tag';

export const NAME = 'caseCustomerBillingPreferencesQuery';

const query = gql`
  query caseCustomerBillingPreferencesQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      customer(useCachedDataForCase: true) {
        ... on Customer {
          id
          name
          type
          billingPreferences {
            creditCardRuleAbbreviation
            creditCardRuleName
            purchaseOrderRule
            casingBrandOff
            casingBrandOn
            contractNumber
            mountSpareFirst
            odometer
            releaseNumber
            terminalLocation
            tireSerialNumber
            vehicleLicenseTag
            vehicleYearModel
            vin
            tireLimit
            wheelLimit
            fleetReferenceNumber
          }
        }
        ... on StoreCustomer {
          id
          name
          type
          billingPreferences {
            creditCardRuleAbbreviation
            creditCardRuleName
            purchaseOrderRule
            casingBrandOff
            casingBrandOn
            contractNumber
            mountSpareFirst
            odometer
            releaseNumber
            terminalLocation
            tireSerialNumber
            vehicleLicenseTag
            vehicleYearModel
            vin
            tireLimit
            wheelLimit
            fleetReferenceNumber
          }
        }
        ... on CustomCustomer {
          type
          name
        }
        ... on CustomStoreCustomer {
          type
          name
        }
      }
    }
  }
`;

export default query;

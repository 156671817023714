import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { groupBy, map, orderBy } from 'lodash';
import { compose, setDisplayName } from 'recompose';

import { H4, MessageMedium } from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Container, Column, Row } from 'styled-components-grid';

import { formatGroupDate } from '../utils';

import NotificationEntry from './NotificationEntry';
import withCaseNotificationHistory from './withCaseNotificationHistory';

export const groupAndSortEntries = (entries) =>
  groupBy(orderBy(entries, 'insertedAt', 'desc'), (n) =>
    moment(n.insertedAt).startOf('day').format(),
  );

/* eslint-disable react/prop-types */
export const Entry = ({ entry, ...rest }) => (
  <NotificationEntry {...rest} key={entry.insertedAt} {...entry} />
);
/* eslint-enable react/prop-types */

export function NotificationHistory({
  caseNumber,
  notifications,
  isLoadingNotifications,
}) {
  if (isLoadingNotifications) {
    return null;
  }

  if (!notifications || !notifications.length) {
    return (
      <Container>
        <Row modifiers={['center']}>
          <Column modifiers={['col', 'padScale_5']}>
            <MessageMedium>
              <MessageMedium.Header>
                <MessageMedium.Icon name="envelope" />
              </MessageMedium.Header>
              <MessageMedium.Section>
                <MessageMedium.Text>
                  <Trans>No notification history yet.</Trans>
                </MessageMedium.Text>
              </MessageMedium.Section>
            </MessageMedium>
          </Column>
        </Row>
      </Container>
    );
  }

  const groupedUpdates = groupAndSortEntries(notifications);

  return (
    <Container modifiers="padScale_0">
      {map(groupedUpdates, (dayEntries, date) => (
        <Container key={date} modifiers={['padScaleX_0', 'padScaleY_2']}>
          <Row style={{ margin: `${px2rem(20)} 0 ${px2rem(10)}` }}>
            <H4 modifiers="fontWeightLight">{formatGroupDate(date)}</H4>
          </Row>
          {map(dayEntries, (entry) => (
            <Entry
              key={entry.insertedAt}
              entry={entry}
              caseNumber={caseNumber}
            />
          ))}
        </Container>
      ))}
    </Container>
  );
}

NotificationHistory.propTypes = {
  caseNumber: PropTypes.string.isRequired,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({ insertedAt: PropTypes.string.isRequired }),
  ).isRequired,
  isLoadingNotifications: PropTypes.bool.isRequired,
};

export default compose(
  setDisplayName('NotificationHistory'),
  withCaseNotificationHistory,
)(NotificationHistory);

import Bugsnag from '@bugsnag/browser';

/**
 * Redux middleware factory. Creates a redux middleware that catches exceptions
 * during the dispatching of actions and reports them to Bugsnag.
 */
const init = () => (store) => (next) => (action) => {
  try {
    return next(action);
  } catch (err) {
    Bugsnag.notify(err.message, (event) => {
      event.context = 'Error in Redux Action';
      event.addMetadata('redux', { action, state: store.getState().toJS() });
    });
  }

  return null;
};

export default { init };

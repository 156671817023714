import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import darken from 'polished/lib/color/darken';

import { ButtonToggle as BaseButtonToggle } from 'base-components';

const Container = styled.div`
  ${BaseButtonToggle.OffLabel}:first-child {
    color: ${({ isOn, theme }) =>
      !isOn && theme.colors.status.danger} !important;
  }

  /* We can't get a reference to the component, so we use it's class */
  .Switch {
    background-color: ${({ isOn, theme }) =>
      theme.colors.status[isOn ? 'warning' : 'danger']};
    border-color: ${({ isOn, theme }) =>
      darken(0.1, theme.colors.status[isOn ? 'warning' : 'danger'])};
  }
`;

const ButtonToggle = (props) => (
  <Container isOn={props.on}>
    <BaseButtonToggle {...props} />
  </Container>
);

ButtonToggle.propTypes = { on: PropTypes.bool };
ButtonToggle.defaultProps = { on: false };

ButtonToggle.OnLabel = BaseButtonToggle.OnLabel;
ButtonToggle.OffLabel = BaseButtonToggle.OffLabel;

export default ButtonToggle;

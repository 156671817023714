import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Text } from 'base-components';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import { mapIconForDealerType } from 'assets/images/mapIcons';
import { MCSN } from 'utils/dealerFilter';

export function dealerMarkerTextColor(type, theme) {
  return type === MCSN ? theme.colors.base.text : theme.colors.base.chrome000;
}

const styles = ({ theme, type }) => css`
  background-image: url(${mapIconForDealerType(type)});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  height: ${px2rem(25)};
  justify-content: center;
  text-align: center;
  width: ${px2rem(25)};

  span {
    color: ${dealerMarkerTextColor(type, theme)};
  }
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome000: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const DealerMarker = buildStyledComponent('DealerMarker', styled.div, styles, {
  themePropTypes,
});

DealerMarker.Text = Text;

export default DealerMarker;

import { curry } from 'lodash';
import React from 'react';

import CustomerWidgetContext from './CustomerWidgetContext';

export const buildConsumerChild = curry(
  (WrappedComponent, componentProps, consumerProps) => {
    const {
      customerSearchValue,
      storeCustomerSearchValue,
      updateCustomerWidgetContext,
    } = consumerProps;
    const { setCasePanelIncomplete } = consumerProps;
    return (
      <WrappedComponent
        {...componentProps}
        customerSearchValue={customerSearchValue}
        clearCustomerSearchValue={() => {
          updateCustomerWidgetContext(
            { customerSearchValue: '', storeCustomerSearchValue: '' },
            () => {
              setCasePanelIncomplete();
            },
          );
        }}
        storeCustomerSearchValue={storeCustomerSearchValue}
      />
    );
  },
);

const withClearCustomerSearchValue = (WrappedComponent) => (componentProps) => (
  <CustomerWidgetContext.Consumer>
    {buildConsumerChild(WrappedComponent, componentProps)}
  </CustomerWidgetContext.Consumer>
);

export default withClearCustomerSearchValue;

// eslint-disable-next-line import/prefer-default-export
export function showPopup(
  url,
  { target = 'AmazonConnectPopup', width = 500, height = 600 } = {},
) {
  const left = window.screen.width / 2 - width / 2;
  const top = window.screen.height / 2 - height / 2;

  // eslint-disable-next-line no-console
  console.log(`opening in a popup "${target}": ${url}`);

  return window.open(
    url,
    target,
    `width=${width},height=${height},menubar=no,status=no,toolbar=no,left=${left},top=${top}`,
  );
}

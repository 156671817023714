import { get, isEmpty, flow } from 'lodash';
import { compose, setDisplayName, withProps } from 'recompose';

import FieldUpdates from 'components/FieldUpdates';
import { withSortDirection } from 'components/FieldUpdates/withSortDirection';
import withCaseSavingStatus from 'compositions/CaseSaveIndicator/withCaseSavingStatus';

import withCaseFieldUpdates from './withCaseFieldUpdates';
import {
  fieldTitles,
  changeSummarySentences,
  relatedInfoPathsByType as relatedPaths,
  fieldValueTransformers,
} from './constants';

const isEndedDelay = (entry) =>
  get(entry, 'type') === 'delay' &&
  get(entry, 'action') === 'updated' &&
  get(entry, 'fields[0].field') === 'end_time';

// Supplied lines can be created ad-hoc, which means
// they will not be associated with any other request line.
const entryTypeTransformer = (entry) => {
  const type = get(entry, 'type');

  if (type !== 'supplied_line') return entry;

  const agreedIdPath = get(relatedPaths, `${type}_attached.agreedServiceId`);
  const isAttached = !!get(entry, agreedIdPath);

  return { ...entry, type: `${type}_${isAttached ? 'attached' : 'detached'}` };
};

const entryActionTransformer = (entry = {}) => ({
  ...entry,
  action: isEndedDelay(entry) ? 'ended' : entry.action,
});

// eslint-disable-next-line no-underscore-dangle
const __typename = 'CaseHistoryEntryField';

const caseDelayEntryTransformer = (entry) => {
  const finalEntry = { info: {}, fields: [], ...entry };

  if (!isEndedDelay(finalEntry) && !isEmpty(finalEntry.info)) return finalEntry;

  const missingFields = Object.keys(finalEntry.info).reduce((acc, field) => {
    const val = finalEntry.info[field];

    return acc.concat({ field, previousValue: val, newValue: val, __typename });
  }, []);

  return { ...finalEntry, fields: [...missingFields, ...finalEntry.fields] };
};

const transforms = [
  entryTypeTransformer,
  entryActionTransformer,
  caseDelayEntryTransformer,
];

export const config = {
  fieldTitles,
  entryTransformer: flow(transforms),
  changeSummarySentences,
  relatedInfoPathsByType: relatedPaths,
  fieldValueTransformers,
};

export default compose(
  setDisplayName('StatusUpdates'),
  withSortDirection(),
  withCaseFieldUpdates,
  withCaseSavingStatus,
  withProps({ config }),
)(FieldUpdates);

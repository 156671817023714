/* eslint-disable import/prefer-default-export */

const pad = (value) => String(value).padStart(2, '0');

export function formatDuration(duration) {
  const mins = duration.minutes();
  const secs = duration.seconds();

  if (!mins && !secs) return `0s`;

  return mins ? `${mins}m ${pad(secs)}s` : `${pad(secs)}s`;
}

import { isEmpty, noop } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DirectionsRenderer, GoogleMap, Marker } from 'react-google-maps';

import { pinIconForType } from 'assets/images/pins';

import GoogleMapPropTypes from 'features/googleMaps/propTypes';

import { DEFAULT_ASSET_LOCATION } from './constants';
import { getDealerPinIcon } from './Dealer';

const defaultMapOptions = {
  controlSize: 29,
  gestureHandling: 'cooperative',
  styles: [
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [{ visibility: 'on' }],
    },
  ],
};

export class MapComponent extends Component {
  static propTypes = {
    assetLocation: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
    dealerLocation: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
    dealer: PropTypes.shape({
      features: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string.isRequired,
        }),
      ),
    }),
    // eslint-disable-next-line react/no-typos
    directions: GoogleMapPropTypes.googleDirections,
    displayRoute: PropTypes.func,
  };

  static defaultProps = {
    assetLocation: {},
    dealerLocation: {},
    dealer: {},
    directions: {
      routes: [],
      status: 'empty',
    },
    displayRoute: noop,
  };

  state = {
    zoom: 4,
  };

  componentDidMount() {
    const { assetLocation, dealerLocation } = this.props;

    if (!isEmpty(assetLocation)) {
      this.handleCenterChange(assetLocation);

      if (!isEmpty(dealerLocation)) {
        this.fitBoundsChange([assetLocation, dealerLocation]);
        this.props.displayRoute(dealerLocation, assetLocation);
      }
    }
  }

  UNSAFE_componentWillReceiveProps({ assetLocation, dealerLocation }) {
    if (isEmpty(assetLocation)) {
      return;
    }

    if (this.props.assetLocation !== assetLocation) {
      this.handleCenterChange(assetLocation);
    }

    if (
      !isEmpty(dealerLocation) &&
      this.props.dealerLocation !== dealerLocation
    ) {
      this.fitBoundsChange([assetLocation, dealerLocation]);
      this.props.displayRoute(dealerLocation, assetLocation);
    }
  }

  fitBoundsChange = (locations) => {
    const bounds = new google.maps.LatLngBounds();

    locations.forEach((location) => {
      bounds.extend({
        lat: location.latitude,
        lng: location.longitude,
      });
    });

    this.googleMap.fitBounds(bounds);
  };

  handleCenterChange = (assetLocation) => {
    this.googleMap.panTo({
      lat: assetLocation.latitude,
      lng: assetLocation.longitude,
    });
  };

  handleZoomChange = () => {
    this.setState({ zoom: this.googleMap.getZoom() });
  };

  renderAssetMarker = () => {
    const { latitude, longitude } = this.props.assetLocation;
    return Boolean(latitude && longitude);
  };

  renderDealerMarker = () => {
    const { latitude, longitude } = this.props.dealerLocation;
    return Boolean(latitude && longitude);
  };

  renderRouteInfo = () => {
    const { assetLocation, dealerLocation, directions } = this.props;
    return (
      !isEmpty(assetLocation) &&
      !isEmpty(dealerLocation) &&
      !isEmpty(directions)
    );
  };

  render() {
    const { assetLocation, dealerLocation, dealer, directions } = this.props;
    const { zoom } = this.state;

    return (
      <GoogleMap
        zoom={zoom}
        defaultCenter={{
          lat: DEFAULT_ASSET_LOCATION.latitude,
          lng: DEFAULT_ASSET_LOCATION.longitude,
        }}
        onZoomChanged={this.handleZoomChange}
        ref={(map) => {
          this.googleMap = map;
        }}
        options={defaultMapOptions}
      >
        {this.renderAssetMarker() && (
          <Marker
            icon={{
              url: pinIconForType('asset'),
            }}
            position={{
              lat: assetLocation.latitude,
              lng: assetLocation.longitude,
            }}
          />
        )}
        {this.renderDealerMarker() && (
          <Marker
            icon={{
              url: getDealerPinIcon(dealer),
            }}
            position={{
              lat: dealerLocation.latitude,
              lng: dealerLocation.longitude,
            }}
          />
        )}
        {this.renderRouteInfo() && (
          <DirectionsRenderer
            directions={directions}
            options={{
              preserveViewport: true,
              suppressInfoWindows: false,
              suppressMarkers: true,
            }}
          />
        )}
      </GoogleMap>
    );
  }
}

export default MapComponent;

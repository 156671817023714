import React from 'react';
import PropTypes from 'prop-types';

import Ul from 'elements/Ul';

import ContactGroupItem from './ContactGroupItem';

function ContactGroupList({ dealer, groupedContacts }) {
  return (
    <Ul>
      {groupedContacts.map(({ type, contact }) => (
        <ContactGroupItem
          dealer={dealer}
          key={type}
          type={type}
          contact={contact}
        />
      ))}
    </Ul>
  );
}

ContactGroupList.propTypes = {
  dealer: PropTypes.shape({}).isRequired,
  groupedContacts: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      contact: PropTypes.shape({}),
    }),
  ).isRequired,
};

export default ContactGroupList;

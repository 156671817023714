import React from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';

import { Column, Row } from 'styled-components-grid';
import { InputField, InputGroup } from 'base-components';

import PhoneNumberInput from 'elements/PhoneNumberInput';

import Error from './Error';
import contactFields from '../constants';

export function StoreContactsForm(props) {
  const { values, errors, touched, handleBlur } = props;
  const { handleChange, setFieldValue, setFieldTouched } = props;

  return (
    <>
      <Row modifiers={['padScaleY_2']}>
        <Column modifiers={['col']}>
          <InputGroup>
            <InputGroup.Row>
              <InputGroup.Column modifiers={['col']}>
                <InputField
                  maxLength={255}
                  name={contactFields.name}
                  placeholder={t`Enter name...`}
                  required
                  value={values[contactFields.name]}
                  isValid={
                    touched[contactFields.name] && !errors[contactFields.name]
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  <Column modifiers={['col', 'padScaleY_0']}>
                    <Row>
                      <InputField.Label>
                        <Trans>Name</Trans>
                      </InputField.Label>
                    </Row>
                    <Row>
                      <InputField.TextField />
                    </Row>
                  </Column>
                </InputField>
              </InputGroup.Column>
            </InputGroup.Row>
            <InputGroup.Row>
              <InputGroup.Column modifiers={['col']}>
                <InputField
                  maxLength={255}
                  name={contactFields.title}
                  placeholder={t`Enter job title...`}
                  value={values[contactFields.title]}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  <Column modifiers={['col', 'padScaleY_0']}>
                    <Row>
                      <InputField.Label>
                        <Trans>Job Title (Optional)</Trans>
                      </InputField.Label>
                    </Row>
                    <Row>
                      <InputField.TextField />
                    </Row>
                  </Column>
                </InputField>
              </InputGroup.Column>
            </InputGroup.Row>
          </InputGroup>
        </Column>
      </Row>
      <Row modifiers={['padScaleY_2']}>
        <Column modifiers={['col']}>
          <InputGroup>
            <InputGroup.Row>
              <InputGroup.Column modifiers={['col_6']}>
                <InputField
                  name={contactFields.phoneNumber}
                  value={values[contactFields.phoneNumber]}
                  isValid={
                    touched[contactFields.phoneNumber] &&
                    !errors[contactFields.phoneNumber]
                  }
                >
                  <Column modifiers={['col', 'padScaleY_0']}>
                    <Row>
                      <InputField.Label>
                        <Trans>Phone Number</Trans>
                      </InputField.Label>
                    </Row>
                    <Row>
                      <PhoneNumberInput
                        value={values[contactFields.phoneNumber]}
                        onBlur={() =>
                          setFieldTouched(contactFields.phoneNumber)
                        }
                        onChange={(value) =>
                          setFieldValue(contactFields.phoneNumber, value)
                        }
                        placeholder={t`(###) ###-####`}
                      />
                    </Row>
                  </Column>
                </InputField>
              </InputGroup.Column>
              <InputGroup.Column modifiers={['col_6']}>
                <InputField
                  name={contactFields.backupPhoneNumber}
                  value={values[contactFields.backupPhoneNumber]}
                  isValid={
                    touched[contactFields.backupPhoneNumber] &&
                    !errors[contactFields.backupPhoneNumber]
                  }
                >
                  <Column modifiers={['col', 'padScaleY_0']}>
                    <Row>
                      <InputField.Label>
                        <Trans>Backup Number (Optional)</Trans>
                      </InputField.Label>
                    </Row>
                    <Row>
                      <PhoneNumberInput
                        value={values[contactFields.backupPhoneNumber]}
                        onBlur={() =>
                          setFieldTouched(contactFields.backupPhoneNumber)
                        }
                        onChange={(value) =>
                          setFieldValue(contactFields.backupPhoneNumber, value)
                        }
                        placeholder={t`(###) ###-####`}
                      />
                    </Row>
                  </Column>
                </InputField>
              </InputGroup.Column>
            </InputGroup.Row>
          </InputGroup>
        </Column>
      </Row>
      <Error errors={errors} touched={touched} />
    </>
  );
}

StoreContactsForm.propTypes = {
  errors: PropTypes.shape({}).isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  values: PropTypes.shape({
    [contactFields.name]: PropTypes.string.isRequired,
    [contactFields.title]: PropTypes.string,
    [contactFields.phoneNumber]: PropTypes.string.isRequired,
    [contactFields.backupPhoneNumber]: PropTypes.string,
  }).isRequired,
  touched: PropTypes.shape({
    [contactFields.name]: PropTypes.bool,
    [contactFields.title]: PropTypes.bool,
    [contactFields.phoneNumber]: PropTypes.bool,
    [contactFields.backupPhoneNumber]: PropTypes.bool,
  }).isRequired,
};

export default StoreContactsForm;

import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { px2rem } from 'decisiv-ui-utils';
import { t, Trans } from '@lingui/macro';
import { get, memoize, orderBy } from 'lodash';

import { Column, Row } from 'styled-components-grid';
import { QuickActionButton, Text } from 'base-components';

import ElapsedTime from 'components/ElapsedTime';
import countOfTime from 'utils/countOfTime';

import { labelForReason, DELAY_REASONS, ignoredReasons } from './constants';

import CustomReason from './CustomReason';
import DelayedServiceEntry from './DelayedServiceEntry';

const formatDate = (date) => moment(date).format('D MMM YYYY, h:mm A z').trim();

const getDelayTime = memoize(({ startTime, endTime }) => {
  const start = formatDate(startTime);
  const end = endTime && formatDate(endTime);

  return end ? t`${start} to ${end}` : start;
});

const getDelayDuration = memoize(({ startTime, endTime }) =>
  countOfTime(moment(endTime).diff(moment(startTime))),
);

const sortDelays = memoize((caseDelays) =>
  // newest to oldest
  orderBy(caseDelays, ['startTime', 'endTime'], ['desc']),
);

export function CaseDelaysTimeline(props) {
  const { delayedServiceScheduledDate } = props;
  const { delayedServiceScheduledDispatchDate } = props;
  const { caseDelays, customReasonPosition, assetLocationTimezone } = props;
  const { updateCaseDelay, showDelayedService, delayedServiceNotes } = props;

  if (caseDelays.length === 0 && !showDelayedService) return null;

  const sortedDelays = sortDelays(caseDelays);

  return (
    <>
      {sortedDelays.map((delay, index) => (
        <Row
          key={delay.id}
          modifiers="middle"
          style={{
            marginBottom:
              index === caseDelays.length - 1 && !showDelayedService
                ? 0
                : px2rem(15),
          }}
        >
          <Column modifiers="col">
            <Row>
              <Text modifiers="fontWeightMedium">
                <Trans id={labelForReason[delay.reasonType]} />
              </Text>
            </Row>
            {delay.customReason && (
              <Row>
                <CustomReason position={customReasonPosition}>
                  {delay.customReason}
                </CustomReason>
              </Row>
            )}
            {get(delay, 'dealerResponse.dealer.name') && (
              <Row modifiers="middle">
                <Column modifiers={['padScale_0', 'flex_1']}>
                  <Text modifiers="fontWeightMedium">
                    {delay.dealerResponse.dealer.name}
                  </Text>
                </Column>
              </Row>
            )}
            <Row>
              <Text key="delayDurationTime" modifiers="textLight">
                {!delay.endTime ? (
                  <ElapsedTime inline sinceTime={delay.startTime} />
                ) : (
                  getDelayDuration(delay)
                )}{' '}
                &bull;{' '}
                {getDelayTime({
                  startTime: delay.startTime,
                  endTime: delay.endTime,
                })}
              </Text>
            </Row>
          </Column>
          {updateCaseDelay &&
            !delay.endTime &&
            !ignoredReasons.includes(delay.reasonType) && (
              <Column modifiers={['col', 'end']}>
                <QuickActionButton onClick={() => updateCaseDelay(delay.id)}>
                  <QuickActionButton.Text>
                    <Trans>End Delay</Trans>
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
            )}
        </Row>
      ))}
      {showDelayedService && (
        <DelayedServiceEntry
          {...{
            delayedServiceNotes,
            assetLocationTimezone,
            delayedServiceScheduledDate,
            delayedServiceScheduledDispatchDate,
          }}
        />
      )}
    </>
  );
}

CaseDelaysTimeline.propTypes = {
  caseDelays: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      startTime: PropTypes.string.isRequired,
      endTime: PropTypes.string,
      reasonType: PropTypes.oneOf(Object.values(DELAY_REASONS)),
      customReason: PropTypes.string,
    }),
  ).isRequired,
  updateCaseDelay: PropTypes.func,
  customReasonPosition: PropTypes.string,
  showDelayedService: PropTypes.bool,
  delayedServiceNotes: PropTypes.string,
  assetLocationTimezone: PropTypes.string,
  delayedServiceScheduledDate: PropTypes.string,
  delayedServiceScheduledDispatchDate: PropTypes.string,
};

CaseDelaysTimeline.defaultProps = {
  updateCaseDelay: undefined,
  customReasonPosition: undefined,
  showDelayedService: false,
  delayedServiceNotes: undefined,
  assetLocationTimezone: undefined,
  delayedServiceScheduledDate: undefined,
  delayedServiceScheduledDispatchDate: undefined,
};

export default CaseDelaysTimeline;

import React, { Fragment } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compact, get, uniq } from 'lodash';

import { px2rem } from 'decisiv-ui-utils';
import { Divider, Text } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import { reportTypes, reportTitles } from 'pages/Reports/constants';

import {
  configTypes,
  statusTitles,
  configTypesTitles,
  paymentMethodTitles,
  billableStatusTitles,
  configTypesModalTitles,
  customConfigVarsByType,
  dateRangeTypesModalTitles,
  getLocationFieldDisplayValue,
} from '../constants';

import { getCommonConfigVars } from '../utils';

const valueTransformers = {
  [configTypes.reportType]: (type) => <Trans id={reportTitles[type]} />,

  [configTypes.dateRangeType]: (type) => (
    <Trans id={dateRangeTypesModalTitles[type]} />
  ),

  [configTypes.reportingPeriod]: (_, config) => {
    const { startTime, endTime } = getCommonConfigVars(config);
    const fromDate = moment(startTime);
    const toDate = moment(endTime);

    return [fromDate, fromDate.isSame(toDate, 'day') ? null : toDate]
      .filter(Boolean)
      .map((date) => date.format('D MMM YYYY'))
      .join(' - ');
  },

  [configTypes.billableStatus]: (type) => (
    <Trans id={billableStatusTitles[type]} />
  ),

  [configTypes.status]: (type) => <Trans id={statusTitles[type]} />,

  [configTypes.paymentMethod]: (type) => (
    <Trans id={paymentMethodTitles[type]} />
  ),

  [configTypes.reportLevel]: () => null,

  [configTypes.detailLevel]: (level) => level,

  [configTypes.inboundProgramIds]: (ids, _, metadata) => {
    const programs = metadata.inboundPrograms || {};

    if (!ids || !ids.length) return <Trans>All</Trans>;

    return ids.map((id, index) => {
      const program = programs[`${id}`];

      return (
        <Fragment key={id}>
          <Text>{program ? program.name : id}</Text>
          {index !== ids.length - 1 && <span>, </span>}
        </Fragment>
      );
    });
  },

  [configTypes.customerName]: getLocationFieldDisplayValue,
  [configTypes.customerLocation]: getLocationFieldDisplayValue,
  [configTypes.dealerName]: getLocationFieldDisplayValue,
  [configTypes.dealerLocation]: getLocationFieldDisplayValue,
};

const ConfigRow = ({ type, reportType, config, metadata }) => {
  const val = type === configTypes.reportType ? reportType : get(config, type);

  if (!val) return null;

  const title = configTypesModalTitles[type] || configTypesTitles[type];
  const transform = valueTransformers[type] || (() => <Trans id={val} />);
  const displayValue = transform(val, config, metadata);

  if (!displayValue) return null;

  return (
    <>
      <Row modifiers={['middle', 'padScaleY_2']}>
        <Column style={{ width: px2rem(150) }}>
          <Text modifiers="small">
            <Trans id={title} />
          </Text>
        </Column>
        <Column modifiers="col">
          <Text>{displayValue}</Text>
        </Column>
      </Row>
      <Divider />
    </>
  );
};

ConfigRow.propTypes = {
  type: PropTypes.oneOf(Object.values(configTypes)).isRequired,
  config: PropTypes.shape({}).isRequired,
  metadata: PropTypes.shape({}).isRequired,
  reportType: PropTypes.oneOf(Object.values(reportTypes)).isRequired,
};

const ReportStatusModalConfigTable = (props) => {
  const { type: reportType, config, metadata } = props;
  const { showDateRangeType, showBillableStatus } = props;

  const rows = uniq(
    compact([
      showDateRangeType ? configTypes.dateRangeType : undefined,
      configTypes.reportingPeriod,
      showBillableStatus ? configTypes.billableStatus : undefined,
      ...(customConfigVarsByType[reportType] || []),
    ]),
  );

  return (
    <Container modifiers="padScale_0" style={{ marginTop: px2rem(40) }}>
      <Divider />
      <ConfigRow
        type={configTypes.reportType}
        config={config}
        metadata={metadata || {}}
        reportType={reportType}
      />
      {rows.map((key) => (
        <ConfigRow
          key={key}
          type={key}
          config={config}
          metadata={metadata || {}}
          reportType={reportType}
        />
      ))}
    </Container>
  );
};

ReportStatusModalConfigTable.propTypes = {
  type: PropTypes.string.isRequired,
  config: PropTypes.shape({}).isRequired,
  metadata: PropTypes.shape({}),
  showDateRangeType: PropTypes.bool.isRequired,
  showBillableStatus: PropTypes.bool.isRequired,
};

ReportStatusModalConfigTable.defaultProps = {
  metadata: undefined,
};

export default ReportStatusModalConfigTable;

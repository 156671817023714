import styled from 'styled-components';
import PropTypes from 'prop-types';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const styles = ({ theme }) => `
  display: flex;
  align-items: center;
  margin-left: ${px2rem(11)};

  span {
    color: ${theme.colors.base.textLight};
    font-size: ${px2rem(10)};
    letter-spacing: 0.01px;
    line-height: ${px2rem(16)};

    &:first-child {
      margin-right: ${px2rem(4)};
    }
    &:last-child {
      margin-left: ${px2rem(4)};
    }
  }
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      textLight: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const FastSlowLegend = buildStyledComponent(
  'FastSlowLegend',
  styled.div,
  styles,
  {
    themePropTypes,
  },
);

export default FastSlowLegend;

import React from 'react';
import PropTypes from 'prop-types';

import { Popover } from 'base-components';

export default function InfoPopoverTarget({ children, ...props }) {
  return (
    <Popover.Target {...props} style={{ cursor: 'pointer' }}>
      {children}
    </Popover.Target>
  );
}

InfoPopoverTarget.propTypes = {
  children: PropTypes.node.isRequired,
};

import gql from 'graphql-tag';

export default gql`
  query requestLineOptionsQuery {
    rimType: __type(name: "RimType") {
      enumValues {
        name
      }
    }
    tirePosition: __type(name: "TirePosition") {
      enumValues {
        name
      }
    }
    tireCondition: __type(name: "TireCondition") {
      enumValues {
        name
      }
    }
    requestedAction: __type(name: "RequestedAction") {
      enumValues {
        name
      }
    }
  }
`;

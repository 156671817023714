import { Trans } from '@lingui/macro';
import { compact, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Accordion, P, Text } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import ONCallSquareIcon from 'components/ONCallSquareIcon';
import ServiceSummaryIcons from 'components/ServiceSummaryIcons';

import {
  ADDITIONAL_SERVICES,
  DEALER_LOCATION_ICON_NAMES,
  DEALER_LOCATION_SERVICES,
} from 'utils/dealerFilter';

import AccordionBody from './AccordionBody';

export function Services({ dealer, expanded, onExpandedChange }) {
  if (isEmpty(dealer)) {
    return null;
  }
  const { features } = dealer;

  const serviceCodes = features.map((f) => f.code);

  function setActiveModifier(code) {
    const active = serviceCodes.includes(code) && 'active';
    return compact([active]);
  }

  return (
    <Container modifiers="padScale_0">
      <Accordion.Divider />
      <Accordion
        name="services"
        expanded={expanded}
        onExpandedChange={onExpandedChange}
      >
        <Accordion.Head>
          <Container modifiers="fluid">
            <Accordion.Title>
              <Row modifiers="middle">
                <Column modifiers={['col', 'padScaleX_0']}>
                  <Trans>Services</Trans>
                </Column>
                {features && (
                  <Column>
                    <Row>
                      {!expanded && <ServiceSummaryIcons services={features} />}
                    </Row>
                  </Column>
                )}
              </Row>
            </Accordion.Title>
          </Container>
        </Accordion.Head>
        <AccordionBody>
          <Row>
            <Column modifiers={['col', 'padScale_0']}>
              {DEALER_LOCATION_SERVICES.map((code) => (
                <Row key={code} modifiers="middle">
                  <Column modifiers={['display_flex', 'padScaleX_0']}>
                    <ONCallSquareIcon
                      modifiers={setActiveModifier(code)}
                      code={code}
                    />
                  </Column>
                  <Column modifiers={['col', 'padScaleX_2', 'padScaleY_0']}>
                    <P modifiers="small">
                      <Trans id={DEALER_LOCATION_ICON_NAMES[code]} />
                    </P>
                  </Column>
                </Row>
              ))}
            </Column>
            <Column modifiers={['col', 'padScale_0']}>
              <Row>
                <Column modifiers="padScale_0">
                  <Text modifiers={['small', 'textLight']}>
                    <Trans>Additional Services</Trans>
                  </Text>
                  {features &&
                    features
                      .filter(({ code }) => ADDITIONAL_SERVICES[code])
                      .map((feature) => (
                        <Row key={feature.id} modifiers="padScale_0">
                          <Column modifiers="padScale_0">
                            <P>
                              <Trans id={ADDITIONAL_SERVICES[feature.code]} />
                            </P>
                          </Column>
                        </Row>
                      ))}
                </Column>
              </Row>
            </Column>
          </Row>
        </AccordionBody>
      </Accordion>
    </Container>
  );
}

Services.propTypes = {
  dealer: PropTypes.shape({
    features: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        code: PropTypes.string,
      }),
    ),
  }),
  expanded: PropTypes.bool.isRequired,
  onExpandedChange: PropTypes.func.isRequired,
};

Services.defaultProps = {
  dealer: {},
};

export default Services;

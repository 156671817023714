import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

import Link from './Link';
import Divider from './Divider';

const Breadcrumbs = buildStyledComponent('Breadcrumbs', styled.div);

Breadcrumbs.Link = Link;
Breadcrumbs.Divider = Divider;

export default Breadcrumbs;

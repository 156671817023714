import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';
import { Text } from 'base-components';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const ShortcutBadge = buildStyledComponent(
  'KeyboardShortcuts_ShortcutBadge',
  styled.span,
  ({ theme }) => css`
    padding: ${px2rem(2)} ${px2rem(4)};
    background: ${theme.colors.base.chrome100};
    border: 1px solid ${theme.colors.base.chrome200};
    border-radius: ${theme.dimensions.borderRadius};
    display: inline-flex;
    align-items: center;
    justify-content: center;
  `,
  {
    modifierConfig: {
      dark: ({ theme }) => css`
        background: ${theme.colors.base.chrome200};
      `,
    },
    themePropTypes: {
      colors: PropTypes.shape({
        base: PropTypes.shape({
          chrome100: PropTypes.string.isRequired,
          chrome200: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
      dimensions: PropTypes.shape({
        borderRadius: PropTypes.string.isRequired,
      }).isRequired,
    },
  },
);

ShortcutBadge.Text = buildStyledComponent(
  'KeyboardShortcuts_ShortcutBadgeText',
  styled(Text),
  ({ theme }) => css`
    font-weight: ${theme.fontWeights.light};
    letter-spacing: 0.05em;
    font-size: ${px2rem(10)};
    line-height: ${px2rem(10 * 1.6)};
  `,
  {
    modifierConfig: {
      dark: ({ theme }) => css`
        font-weight: ${theme.fontWeights.normal};
      `,
    },
    themePropTypes: {
      fontWeights: PropTypes.shape({
        light: PropTypes.number.isRequired,
        normal: PropTypes.number.isRequired,
      }).isRequired,
    },
  },
);

export default ShortcutBadge;

import gql from 'graphql-tag';

export default gql`
  query afterHoursEventConflictsQuery($event: AfterHoursEventCreateAttrs!) {
    afterHoursEventConflicts(input: { event: $event }) {
      event {
        id
        endDate
        endTime
        startDate
        startTime
        isRecurring
        recurring {
          dayOfWeek
        }
      }
    }
  }
`;

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

const modifierConfig = {
  fillWidth: () => `
    flex: 1;
  `,

  scrollY: () => `
    overflow-y: scroll;
  `,

  noScrollBar: () => `
    -ms-overflow-style: none;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  `,
};

const styles = ({ theme }) => `
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: calc(100vh - ${theme.dimensions.topNavHeight});
`;

const themePropTypes = {
  dimensions: PropTypes.shape({
    topNavHeight: PropTypes.string.isRequired,
  }).isRequired,
};

export default buildStyledComponent('Layout__Panel', styled.section, styles, {
  modifierConfig,
  themePropTypes,
});

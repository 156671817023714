import { curry, get } from 'lodash';
import React from 'react';
import { Query, Mutation } from '@apollo/client/react/components';

import CustomerWidgetContext from '../../CustomerWidgetContext';
import updateCaseDomicileMutation from './updateCaseDomicileMutation';
import { customerQuery } from '../../caseCustomerQuery';

import {
  buildCustomerSearchValue,
  setCaseCustomerPanelStatus,
} from '../../utils';

export const buildMutationChild = curry(
  (WrappedComponent, componentProps, updateCaseDomicile) => {
    const { caseId, updateCustomerWidgetContext } = componentProps;

    return (
      <WrappedComponent
        {...componentProps}
        onSelectCustomer={(customerId, domicileSearchValue) => {
          updateCustomerWidgetContext({ domicileSearchValue }, () => {
            setCaseCustomerPanelStatus({
              ...componentProps,
              domicileSearchValue,
              customerDomicile: { id: customerId },
            });
            updateCaseDomicile({
              variables: { id: caseId, customerId },
            });
          });
        }}
      />
    );
  },
);

function buildWrappedComponentWithMutation(WrappedComponent, componentProps) {
  const { updateCustomerWidgetContext } = componentProps;

  return (
    <Mutation
      mutation={updateCaseDomicileMutation}
      onCompleted={(data) => {
        const domicileSearchValue = get(
          data,
          'setCaseCustomerDomicile.case.customerDomicile.name',
          '',
        );
        updateCustomerWidgetContext({ domicileSearchValue });
      }}
    >
      {buildMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const caseId = get(queryProps, 'data.case.id');
    const customerDomicile = get(queryProps, 'data.case.customerDomicile');
    const customer = get(queryProps, 'data.case.customer');
    const customerTerminalLocation = get(
      queryProps,
      'data.case.customerTerminalLocation',
    );

    return buildWrappedComponentWithMutation(WrappedComponent, {
      ...componentProps,
      caseId,
      customerDomicile,
      customer,
      customerTerminalLocation,
    });
  },
);

function skipQuery({ caseNumber }) {
  return !caseNumber;
}

function buildQueryVariables({ caseNumber }) {
  return { caseNumber };
}

function buildWrappedComponentWithQuery(WrappedComponent, componentProps) {
  const { updateCustomerWidgetContext } = componentProps;

  return (
    <Query
      onCompleted={(data) => {
        const customerDomicile = get(data, 'case.customerDomicile');
        updateCustomerWidgetContext({
          domicileSearchValue: buildCustomerSearchValue(customerDomicile),
        });
      }}
      query={customerQuery}
      skip={skipQuery(componentProps)}
      variables={buildQueryVariables(componentProps)}
      fetchPolicy="cache-and-network"
    >
      {buildQueryChild(WrappedComponent, componentProps)}
    </Query>
  );
}

export const buildConsumerChild = curry(
  (WrappedComponent, componentProps, consumerProps) => {
    const {
      caseNumber,
      setCasePanelComplete,
      setCasePanelIncomplete,
      setCasePanelPartial,
      setCasePanelInvalid,
      updateCustomerWidgetContext,
    } = consumerProps;

    return buildWrappedComponentWithQuery(WrappedComponent, {
      ...componentProps,
      caseNumber,
      setCasePanelComplete,
      setCasePanelIncomplete,
      setCasePanelPartial,
      setCasePanelInvalid,
      updateCustomerWidgetContext,
    });
  },
);

const withDomicileSelect = (WrappedComponent) => (componentProps) => (
  <CustomerWidgetContext.Consumer>
    {buildConsumerChild(WrappedComponent, componentProps)}
  </CustomerWidgetContext.Consumer>
);

export default withDomicileSelect;

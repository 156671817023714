import { buildStyledComponent } from 'decisiv-ui-utils';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const modifierConfig = {
  withBorder: ({ theme }) => ({
    styles: `
      border: 1px solid ${theme.colors.base.chrome100};
    `,
  }),
};

const styles = `
  padding: 0;
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome100: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default buildStyledComponent('DateTableCell', styled.td, styles, {
  modifierConfig,
  themePropTypes,
});

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import CloseButton from './CloseButton';
import CloseButtonIcon from './CloseButtonIcon';
import Text from './Text';

const styles = ({ theme }) => `
  align-items: center;
  background-color: ${theme.colors.base.chrome100};
  border-radius: ${theme.dimensions.borderRadius};
  border: 1px solid ${theme.colors.base.chrome300};
  box-sizing: border-box;
  cursor: default;
  display: inline-flex;
  justify-content: space-between;
  min-width: ${px2rem(150)};
  padding: 0 ${px2rem(3)} 0 ${px2rem(5)};

  &:hover {
    border: 1px solid ${theme.colors.base.chrome600};
  }
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome100: PropTypes.string.isRequired,
      chrome300: PropTypes.string.isRequired,
      chrome600: PropTypes.string.isRequired,
      chrome700: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dimensions: PropTypes.shape({
    borderRadius: PropTypes.string.isRequired,
  }).isRequired,
};

const Tag = buildStyledComponent('Tag', styled.div, styles, {
  themePropTypes,
});

Tag.CloseButton = CloseButton;
Tag.CloseButtonIcon = CloseButtonIcon;
Tag.Text = Text;

export default Tag;

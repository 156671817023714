import PropTypes from 'prop-types';
import { buildStyledComponent } from 'decisiv-ui-utils';

import { Text } from '../../elements';

const styles = ({ theme }) => `
  color: ${theme.colors.base.textLight};
  font-weight: ${theme.fontWeights.light};
  white-space: nowrap;
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      textLight: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  fontWeights: PropTypes.shape({
    light: PropTypes.number.isRequired,
  }).isRequired,
};

export default buildStyledComponent('Text', Text, styles, { themePropTypes });

import PropTypes from 'prop-types';

const fontWeights = {
  fontWeightBold: ({ theme }) => `
    font-weight: ${theme.fontWeights.bold};
  `,

  fontWeightLight: ({ theme }) => `
    font-weight: ${theme.fontWeights.light};
  `,

  fontWeightMedium: ({ theme }) => `
    font-weight: ${theme.fontWeights.medium};
  `,

  fontWeightRegular: ({ theme }) => `
    font-weight: ${theme.fontWeights.normal};
  `,
};

const fontWeightPropTypes = {
  fontWeights: PropTypes.shape({
    bold: PropTypes.number.isRequired,
    light: PropTypes.number.isRequired,
    medium: PropTypes.number.isRequired,
    normal: PropTypes.number.isRequired,
  }).isRequired,
};

export { fontWeightPropTypes, fontWeights };

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import { Text } from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';

import AfterHoursEventList from './AfterHoursEventList';
import { eventHasContact } from './utils';
import { AfterHoursEventPropType } from '../../propTypes';
import { AfterHoursContactSelector } from '../../RotationSchedulePanel/RotationEditModal/AfterHoursContactSelector';

class ReplaceContact extends Component {
  static propTypes = {
    contact: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
    contacts: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }),
    ).isRequired,
    // eslint-disable-next-line react/no-typos
    events: PropTypes.arrayOf(AfterHoursEventPropType).isRequired,
    onReplaceContact: PropTypes.func.isRequired,
  };

  state = {
    newContactId: '',
  };

  handleContactChange = (_, newContactId) => {
    this.setState({ newContactId });
    this.props.onReplaceContact(newContactId);
  };

  render() {
    const { contact, contacts } = this.props;

    const filteredContacts = contacts.filter(({ id }) => id !== contact.id);
    const events = this.props.events.map((event) => ({
      ...event,
      updated: !eventHasContact(event, contact),
    }));

    return (
      <>
        <Row modifiers={['padScaleY_2']}>
          <Column modifiers={['col', 'padScale_0']}>
            <Text modifiers="fontWeightMedium">
              <Trans>
                All upcoming After-Hours Rotations assigned to {contact.name}
              </Trans>
            </Text>
          </Column>
        </Row>

        <AfterHoursEventList events={events} selectedContact={contact} />

        <Row style={{ marginTop: px2rem(20), marginBottom: px2rem(10) }}>
          <Column modifiers={['col', 'padScale_0']}>
            <Text modifiers="fontWeightMedium">
              <Trans>Replace {contact.name} with:</Trans>
            </Text>
          </Column>
        </Row>
        <Row>
          <Column modifiers={['col', 'padScale_0']}>
            <AfterHoursContactSelector
              inputId="replace-contact"
              contacts={filteredContacts}
              label={<Trans>Select Contact</Trans>}
              onChange={this.handleContactChange}
              selected={this.state.newContactId}
            />
          </Column>
        </Row>
      </>
    );
  }
}

export default ReplaceContact;

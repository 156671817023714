import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { compose, setDisplayName } from 'recompose';

import { GhostIndicator } from 'base-components';
import { Row, Column } from 'styled-components-grid';

import withRequestAssets from 'compositions/CaseRequestsPanel/RequestAssetsForm/withRequestAssets';
import withInboundProgram from 'compositions/CaseRequestsPanel/RequestAssetsForm/withInboundProgram';

import DropdownCell from '../DropdownCell';

const loading = (
  <Row>
    <Column modifiers="col">
      <GhostIndicator />
    </Column>
  </Row>
);

export function UnitOptionsDropdownCell(props) {
  const { primaryAsset, relatedAssets } = props;
  const { value, onChange, isLoadingAssets } = props;

  const id = value?.id || '';

  const options = useMemo(() => {
    return [primaryAsset, ...relatedAssets]
      .filter(Boolean)
      .filter(({ assetType, unitNumber, trailerType }) =>
        [assetType, unitNumber, trailerType].some(Boolean),
      )
      .map((asset) => ({ ...asset, title: asset.unitNumber }));
  }, [primaryAsset, relatedAssets]);

  const handleOnChange = useCallback(
    (id) => onChange(options.find((option) => option.id === id)),
    [onChange, options],
  );

  if (isLoadingAssets) return loading;

  return (
    <DropdownCell
      {...props}
      value={id}
      options={options}
      onChange={handleOnChange}
    />
  );
}

UnitOptionsDropdownCell.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.shape({
    id: PropTypes.string,
  }),
  onChange: PropTypes.func,
  isUsxpress: PropTypes.bool.isRequired,
  primaryAsset: PropTypes.shape({}),
  relatedAssets: PropTypes.arrayOf(PropTypes.shape({})),
  isLoadingAssets: PropTypes.bool.isRequired,
};

UnitOptionsDropdownCell.defaultProps = {
  value: undefined,
  onChange: noop,
};

export default compose(
  setDisplayName('UnitOptionsDropdownCell'),
  withRequestAssets,
  withInboundProgram,
)(UnitOptionsDropdownCell);

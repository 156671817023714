import gql from 'graphql-tag';

export const NAME = 'caseNotesQuery';

export default gql`
  query caseNotesQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      notes {
        edges {
          node {
            author {
              email
              name
            }
            body
            createdAt
            id
            lastUpdatedBy {
              email
              name
            }
            updatedAt
          }
        }
      }
    }
  }
`;

import { compose } from 'recompose';

import withCreateRequestSuppliedLineMutation from './withCreateRequestSuppliedLineMutation';
import withUpdateCaseSuppliedLineMutation from './withUpdateCaseSuppliedLineMutation';
import withRemoveCaseSuppliedLineMutation from './withRemoveCaseSuppliedLineMutation';
import withResetCaseSuppliedLineMutation from './withResetCaseSuppliedLineMutation';
import withResetAllCaseSuppliedLinesMutation from './withResetAllCaseSuppliedLinesMutation';

export default compose(
  withCreateRequestSuppliedLineMutation,
  withUpdateCaseSuppliedLineMutation,
  withRemoveCaseSuppliedLineMutation,
  withResetCaseSuppliedLineMutation,
  withResetAllCaseSuppliedLinesMutation,
);

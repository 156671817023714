import gql from 'graphql-tag';

export default gql`
  mutation createEmailDistributionReport($startTime: Date!, $endTime: Date!) {
    createEmailDistributionReport(
      filters: { startTime: $startTime, endTime: $endTime }
    ) {
      jobId
    }
  }
`;

import gql from 'graphql-tag';

export const NAME =
  'compositions_CaseActivityPanel_FieldUpdates_caseFieldUpdates';

export default gql`
  query compositions_CaseActivityPanel_FieldUpdates_caseFieldUpdates(
    $caseNumber: String!
    $first: Int
    $last: Int
    $beforeCursor: String
    $afterCursor: String
    $sortDirection: SortDirection = DESC
  ) {
    case(caseNumber: $caseNumber) {
      id
      history(
        first: $first
        last: $last
        before: $beforeCursor
        after: $afterCursor
        sortDirection: $sortDirection
      ) {
        edges {
          cursor
          node {
            user {
              name
              email
            }
            recordedAt
            changes {
              name
              info {
                name
                value
              }
              fields {
                field
                previousValue
                newValue
              }
              action
              entityId
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }
      }
    }
  }
`;

import React, { useCallback } from 'react';
import { useMutation, gql } from '@apollo/client';
import { keys, omit, pick } from 'lodash';

import { NAME as REQUEST_LINES_QUERY } from '../withCaseRequestLines';
import { emptyRequestLine, RequestLineFieldsFragment } from '../constants';

const errorMessage =
  'createRequestAgreementLine requires the case id as first argument';

const mutation = gql`
  mutation requestsPanel_createRequestLine(
    $caseId: ID!
    $requestLine: CaseLineAttrs!
  ) {
    createCaseRequestLine(
      input: { caseId: $caseId, requestLine: $requestLine }
    ) {
      requestLine {
        ...RequestLineFields
      }
      agreementLine {
        agreed
        agreementLine {
          ...RequestLineFields
        }
        requestLineId
      }
    }
  }
  ${RequestLineFieldsFragment}
`;

// Only send props that are invalid on CaseLineAttrs type
const buildRequestLine = (requestLine) => {
  const line = omit(requestLine, [
    'id',
    'asset',
    'isReadOnly',
    'nonPreferredSelections',
    '__typename',
  ]);

  return pick(line, [...keys(emptyRequestLine), 'assetId']);
};

const withCaseCreateRequestLines = (Component) => (props) => {
  const [mutate] = useMutation(mutation, {
    refetchQueries: [REQUEST_LINES_QUERY],
  });

  const createRequestedLine = useCallback(
    (caseId, lineData) => {
      if (!caseId) return Promise.reject(new Error(errorMessage));

      const requestLine = buildRequestLine(lineData);

      return mutate({ variables: { caseId, requestLine } });
    },
    [mutate],
  );

  return <Component {...props} createRequestedLine={createRequestedLine} />;
};

export default withCaseCreateRequestLines;

import moment from 'moment-timezone';
import { get, last, set } from 'lodash';

export function formatGroupDate(date) {
  return moment(date).format('dddd, D MMM');
}

export const scrollIntoView = (id) =>
  document.querySelector(`#${id}`).scrollIntoView({ behavior: 'smooth' });

const emptyPageInfo = { hasPreviousPage: false, hasNextPage: false };

export const usePagination = (config) => {
  const { data, fetchMore, edgesPath } = config;
  const { pageInfoPath, itemsPerPage, sortDirection } = config;

  const edges = get(data, edgesPath) || [];

  // We get the cursors from the edges here and below,
  // instead of from the pageInfo, because the API
  // returns null for them at the pagination edges,
  // and we always need a cursor, especially for loading
  // newer results.
  const pagination = {
    ...get(data, pageInfoPath, emptyPageInfo),
    startCursor: get(edges, '0.cursor'),
    endCursor: get(last(edges), 'cursor'),
  };

  // Prepend newer items at the start of the current results
  const loadNewestResults = () =>
    fetchMore({
      variables: {
        first: undefined,
        // We ask for lots of possible changes because
        // we can't anticipate how many new changes exist
        last: 100,
        afterCursor: null,
        beforeCursor: pagination.startCursor,
        sortDirection,
      },
      updateQuery: (currentResults, { fetchMoreResult }) => {
        const newNodes = get(fetchMoreResult, edgesPath, []);

        if (!newNodes.length) return currentResults;

        set(fetchMoreResult, edgesPath, [
          ...newNodes,
          ...get(currentResults, edgesPath, []),
        ]);

        set(fetchMoreResult, pageInfoPath, {
          ...get(currentResults, pageInfoPath, emptyPageInfo),
          startCursor: get(fetchMoreResult, `${edgesPath}.0.cursor`),
          hasPreviousPage: get(
            fetchMoreResult,
            `${pageInfoPath}.hasPreviousPage`,
            false,
          ),
        });

        return fetchMoreResult;
      },
    }).catch(() => null);

  // Append older items at the end of the current list
  const loadOlderResults = () =>
    fetchMore({
      variables: {
        first: itemsPerPage,
        last: undefined,
        afterCursor: pagination.endCursor,
        beforeCursor: null,
        sortDirection,
      },
      updateQuery: (currentResults, { fetchMoreResult }) => {
        const newNodes = get(fetchMoreResult, edgesPath, []);

        if (!newNodes.length) return currentResults;

        set(fetchMoreResult, edgesPath, [
          ...get(currentResults, edgesPath, []),
          ...newNodes,
        ]);

        set(fetchMoreResult, pageInfoPath, {
          ...get(currentResults, pageInfoPath, emptyPageInfo),
          endCursor: get(last(get(fetchMoreResult, edgesPath) || []), 'cursor'),
          hasNextPage: get(
            fetchMoreResult,
            `${pageInfoPath}.hasNextPage`,
            false,
          ),
        });

        return fetchMoreResult;
      },
    }).catch(() => null);

  return { pagination, loadOlderResults, loadNewestResults };
};

import resourceCheck from './resourceCheck';

import caseResourceCheckQuery, {
  NAME as caseResourceCheckQueryName,
} from './caseResourceCheckQuery';

import dealerResourceCheckQuery, {
  NAME as dealerResourceCheckQueryName,
} from './dealerResourceCheckQuery';

const checkedResourcesQueryNames = [
  caseResourceCheckQueryName,
  dealerResourceCheckQueryName,
];

export {
  caseResourceCheckQuery,
  dealerResourceCheckQuery,
  resourceCheck,
  checkedResourcesQueryNames,
};

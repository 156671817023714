import { Trans } from '@lingui/macro';
import React from 'react';

import { Container, Row, Column } from 'styled-components-grid';

function Loading(props) {
  return (
    <Container modifiers={['flex_column']} {...props}>
      <Row modifiers={['center', 'flex_1', 'middle']}>
        <Column>
          <Trans>Loading...</Trans>
        </Column>
      </Row>
    </Container>
  );
}

export default Loading;

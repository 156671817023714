import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import {
  fontColors,
  fontColorPropTypes,
  fontStyles,
  fontWeights,
  fontWeightPropTypes,
  spacing,
  spacingPropTypes,
} from '../../utils/modifiers';

const styles = ({ theme }) => `
  color: ${theme.colors.base.textLight};
  font-size: ${px2rem(10)};
  flex: 1;
  text-transform: capitalize;
`;

const customThemePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      textLight: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const modifierConfig = {
  ...fontColors,
  ...fontStyles,
  ...fontWeights,
  ...spacing,
};

const themePropTypes = {
  ...fontColorPropTypes,
  ...fontWeightPropTypes,
  ...customThemePropTypes,
  ...spacingPropTypes,
};

export default buildStyledComponent('Label', styled.label, styles, {
  modifierConfig,
  themePropTypes,
});

import React from 'react';

import CaseStatusContext from './CaseStatusContext';

const withCaseIsNewCase = (WrappedComponent) => (props) => (
  <CaseStatusContext.Consumer>
    {({ isNewCase, caseId, status, isReadOnlyCase }) => (
      <WrappedComponent
        {...props}
        caseId={caseId}
        isNewCase={isNewCase}
        caseStatus={status}
        isReadOnlyCase={isReadOnlyCase}
      />
    )}
  </CaseStatusContext.Consumer>
);

export default withCaseIsNewCase;

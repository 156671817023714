import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';

import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';
import { Dropdown, InputField, Text } from 'base-components';

class DropdownSelector extends Component {
  static propTypes = {
    field: PropTypes.shape({
      name: PropTypes.string.isRequired,
      onBlur: PropTypes.func.isRequired,
      onChange: PropTypes.func.isRequired,
    }).isRequired,
    isValid: PropTypes.bool.isRequired,
    inputAttrs: PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      placeholder: PropTypes.string.isRequired,
    }).isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ).isRequired,
    selected: PropTypes.string,
  };

  static defaultProps = {
    selected: '',
  };

  state = { arrowUp: false };

  handleDropdownClick = () => {
    this.handleExpandedChange(!this.state.arrowUp);
  };

  handleExpandedChange = (expanded) => {
    this.setState({
      arrowUp: expanded,
    });
    // Clicking the dropdown caret will not automatically fire the blur handler
    // on the input. Mock an event with the correct values and call field.onBlur
    if (expanded) {
      const event = {
        target: {
          type: 'text',
          name: this.props.field.name,
        },
      };
      this.props.field.onBlur(event);
    }
  };

  handleSelectionChange = (_, selectionId) => {
    // field.onChange expects to handle a React Change Event.
    // _ is our event, but it does not contain the correct values.
    // this block is defining a custom event with the values required.
    const event = {
      target: {
        type: 'text',
        name: this.props.field.name,
        value: selectionId,
      },
    };
    this.props.field.onChange(event);
  };

  renderOptions = () =>
    this.props.options.map((option) => (
      <Dropdown.ListItem key={option.key || option.label} id={option.value}>
        <Row>
          <Column modifiers={['padScale_0']}>
            <Text>
              <Trans id={option.label} />
            </Text>
          </Column>
        </Row>
      </Dropdown.ListItem>
    ));

  render() {
    const { field, isValid, inputAttrs } = this.props;

    return (
      <Dropdown
        hideOnChange
        position="bottomRight"
        onChange={this.handleSelectionChange}
        onClick={this.handleDropdownClick}
        onExpandedChange={this.handleExpandedChange}
      >
        <Dropdown.Target>
          <InputField
            {...field}
            isValid={isValid}
            onChange={() => {}}
            placeholder={i18n._(inputAttrs.placeholder)}
          >
            <Column modifiers={['col', 'padScaleY_0']}>
              <Row>
                <InputField.Label>
                  <Trans id={inputAttrs.label} />
                </InputField.Label>
              </Row>
              <Row>
                <InputField.TextField
                  autoComplete="off"
                  style={{ caretColor: 'transparent', cursor: 'pointer' }}
                />
                <InputField.ActionButton
                  icon={this.state.arrowUp ? 'chevron-up' : 'chevron-down'}
                  modifiers={['padScaleX_1', 'hoverInfo']}
                  onClick={this.handleDropdownClick}
                  type="button"
                />
              </Row>
            </Column>
          </InputField>
        </Dropdown.Target>
        <Dropdown.Content
          style={{
            maxHeight: px2rem(250),
            minWidth: px2rem(150),
            overflowY: 'auto',
          }}
        >
          <Dropdown.List>{this.renderOptions()}</Dropdown.List>
        </Dropdown.Content>
      </Dropdown>
    );
  }
}

export default DropdownSelector;

import styled from 'styled-components';
import { px2rem, buildStyledComponent } from 'decisiv-ui-utils';

// base.chrome000 is defined in themePropTypes from utils/weather so it is verified to be used here.

const modifierConfig = {
  default: ({ theme }) => `background-color: ${theme.colors.base.chrome600};`,
  info: ({ theme }) => `background-color: ${theme.colors.status.info};`,
  success: ({ theme }) => `background-color: ${theme.colors.status.success};`,
};

const styles = ({ theme }) => `
  box-sizing: border-box;
  height: 100%;
  padding: ${px2rem(15)};
  width: ${px2rem(140)};
  h3 {
    color: ${theme.colors.base.chrome000};
  }
  span {
    color: ${theme.colors.base.chrome000};
  }
`;

export default buildStyledComponent('LeftPanel', styled.div, styles, {
  modifierConfig,
});

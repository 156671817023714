import { createSelector } from 'reselect';

export const preferencesStoreSelector = (state) => state.get('preferences');

export const unitSelector = createSelector(preferencesStoreSelector, (store) =>
  store.get('unit'),
);

export const dealerDetailAccordionsExpandedSelector = createSelector(
  preferencesStoreSelector,
  (store) => store.get('dealerDetailAccordionsExpanded'),
);

export const getLanguageSelector = createSelector(
  preferencesStoreSelector,
  (store) => store.get('language'),
);

export const leftNavStateSelector = createSelector(
  preferencesStoreSelector,
  (store) => store.get('leftNavState'),
);

export const expandedMenusSelector = createSelector(
  preferencesStoreSelector,
  (store) => store.get('expandedMenus'),
);

import moment from 'moment-timezone';
import { flow, get, groupBy, isEqual, map, pick, omit, orderBy } from 'lodash';

import {
  fieldTitles,
  entityTransformers,
  blacklistedFieldPaths,
} from '../constants';

const getNodeTimestamp = ({ recordedAt }) =>
  moment(recordedAt).startOf('day').format('x');

// Group and sort nodes by day
export const groupNodesByTimestamp = (nodes, order = 'desc') => {
  const groupedNodes = groupBy(nodes, getNodeTimestamp);

  return orderBy(
    map(groupedNodes, (entries, timestamp) => ({ timestamp, entries })),
    'timestamp',
    order,
  );
};

// Some nodes contain more that one "changes" entry.
// The main reason is some panel's data being changed due to
// changes in another panel. Because the UI treats these related
// changes as if they happened separately, we flatten them here.
// We also generate a unique ID for each entry, and rename the
// entity type prop from `name` to `type`.
export const flattenNodeChanges = (nodes) =>
  nodes.reduce(
    (result, node) => [
      ...result,
      ...node.changes.map((change, index) => ({
        ...omit(node, 'changes'),
        ...omit(change, 'name', 'action'),
        id: `${index}__${node.recordedAt}__${node.user.email}`,
        type: change.name.toLowerCase(),
        action: change.action.toLowerCase(),
      })),
    ],
    [],
  );

export const removeBlacklistedFieldsFromNodes = (nodes) => [
  ...nodes.map((node) => ({
    ...node,
    fields: node.fields.filter(
      ({ field }) => !blacklistedFieldPaths.includes(`${node.type}.${field}`),
    ),
  })),
];

// Remove "empty" entries. The API tracks and sends back changes
// to some fields in the case (other), but filters out some fields,
// which results in entries where we can only say "someone changed the case",
// and provide no extra information.
export const removeEmptyChanges = (initialNodes) =>
  flow([
    // Remove fields where the previous and new value are equal
    (nodes) =>
      nodes.map((node) => ({
        ...node,
        fields: (node.fields || []).filter(
          ({ previousValue, newValue }) => previousValue !== newValue,
        ),
      })),

    // Remove nodes of type "other" that have no field changes or info
    (nodes) =>
      nodes.filter(({ type, fields = [], info = [] }) => {
        if (type === 'other') {
          return fields.length || info.length;
        }

        return true;
      }),
  ])(initialNodes);

// Merge entries that are sequential changes in the same single field within a time range.
// For free-type fields, one might type very slowly, resulting in multiple
// consecutive entries that essentially are only one change.
export const mergeCloseChanges = (nodes) =>
  nodes.reduce((result, node) => {
    if (!result.length) return [node];

    const [newerNode, olderNode] = orderBy(
      [result[result.length - 1], node],
      'recordedAt',
      'desc',
    );
    const TIME_RANGE = 1; // minutes
    const timeDiff = moment
      .duration(moment(newerNode.recordedAt).diff(moment(olderNode.recordedAt)))
      .asMinutes();

    const getNodeFingerprint = (n) =>
      pick(n, ['user.email', 'entityId', 'action', 'fields[0].field']);

    if (
      timeDiff <= TIME_RANGE &&
      newerNode.fields.length === 1 &&
      olderNode.fields.length === 1 &&
      newerNode.fields[0].newValue !== olderNode.fields[0].previousValue &&
      isEqual(getNodeFingerprint(newerNode), getNodeFingerprint(olderNode))
    ) {
      return [
        ...result.filter(
          // Remove nodes as they're being added below as a single node
          ({ id }) => id !== olderNode.id && id !== newerNode.id,
        ),
        {
          ...newerNode,
          fields: [
            {
              ...newerNode.fields[0],
              previousValue: olderNode.fields[0].previousValue,
            },
          ],
        },
      ];
    }

    return [...result, node];
  }, []);

// Flattens the related info array:
// [{ name: 'foo', value: 'bar' }] => { foo: 'bar' }
// Since the items represent info of related entities,
// it's safe to assume there will be no key collisions.
export const flattenNodesRelatedInfo = (nodes) =>
  nodes.reduce(
    (result, node) => [
      ...result,
      {
        ...node,
        info: node.info
          .filter(Boolean)
          .reduce((acc, { name, value }) => ({ ...acc, [name]: value }), {}),
      },
    ],
    [],
  );

// Cache the indexes of the fields sort order for performance
// { someType: { foo: 'bar', baz: 'biz' } } => { someType: { foo: 0, bar: 1 } }
const fieldsOrderIndex = Object.entries(fieldTitles).reduce(
  (result, [type, fields]) => ({
    ...result,
    [type]: Object.keys(fields).reduce(
      (acc, key, index) => ({ ...acc, [key]: index }),
      {},
    ),
  }),
  {},
);

// Transforms the nodes if a customer transformer was defined for their type.
const applyCustomNodeTransforms = (nodes) =>
  nodes.reduce((acc, node) => {
    const transformer = entityTransformers[node.type];
    const finalNode = transformer ? transformer(node) : node;

    return finalNode ? [...acc, finalNode] : acc;
  }, []);

// Sorts the fields according to the visual order in the mockups.
// It is now considered safe to rely on the sort order of object properties:
// https://www.stefanjudis.com/today-i-learned/property-order-is-predictable-in-javascript-objects-since-es2015/
// https://twitter.com/malgosiastp/status/1052539495453773824
// https://kangax.github.io/compat-table/es6/#test-own_property_order
// ON IE11 it is safe if using Object.keys
export const sortNodesFields = (nodes) =>
  nodes.reduce(
    (result, node) => [
      ...result,
      {
        ...node,
        fields: node.fields.sort(
          ({ field: a }, { field: b }) =>
            get(fieldsOrderIndex, [node.type, a], Infinity) -
            get(fieldsOrderIndex, [node.type, b], Infinity),
        ),
      },
    ],
    [],
  );

export const processNodes = flow([
  flattenNodeChanges,
  removeBlacklistedFieldsFromNodes,
  removeEmptyChanges,
  mergeCloseChanges,
  flattenNodesRelatedInfo,
  applyCustomNodeTransforms,
  sortNodesFields,
]);

import React from 'react';
import { t, Trans } from '@lingui/macro';

import { Text } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import { formatPhone } from 'utils/format';

import CallRecordingLink from './CallRecordingLink';

const cellHeader = (headerTitle) => (
  <Container>
    <Row>
      <Column modifiers="col">
        <Text modifiers={['small', 'textLight']}>
          <Trans>{headerTitle}</Trans>
        </Text>
      </Column>
    </Row>
  </Container>
);

const renderFromOrToCell = (cellData) => (
  <Container modifiers={['height_100', 'padScale_0']}>
    <Row modifiers={['middle', 'height_100']}>
      <Column modifiers="center">
        <Row>
          <Column modifiers="padScale_0">
            <Text>{cellData.name || <>&mdash;</>} </Text>
            {cellData.title && (
              <Text modifiers={['textLight']}>({cellData.title})</Text>
            )}
          </Column>
        </Row>
        <Row>
          <Column modifiers="padScale_0">
            <Text modifiers={['small', 'textLight']}>
              {cellData.company || <>&mdash;</>}
            </Text>
          </Column>
        </Row>
        <Row>
          <Column modifiers="padScale_0">
            <Text modifiers={['small', 'textLight']}>
              {formatPhone(cellData.phone)}
            </Text>
          </Column>
        </Row>
      </Column>
    </Row>
  </Container>
);

const COLUMN_NAMES = {
  from: 'from',
  to: 'to',
  recording: 'recording',
};

const from = {
  cellDataGetter: (data) => data.from,
  cellKeyGetter: (data) => `${data.id}:${COLUMN_NAMES.from}`,
  dataCellRenderer: (cellData) => renderFromOrToCell(cellData),
  headerCellRenderer: () => cellHeader(t`From`),
  name: COLUMN_NAMES.from,
};

const to = {
  cellDataGetter: (data) => data.to,
  cellKeyGetter: (data) => `${data.id}:${COLUMN_NAMES.to}`,
  dataCellRenderer: (cellData) => renderFromOrToCell(cellData),
  headerCellRenderer: () => cellHeader(t`To`),
  name: COLUMN_NAMES.to,
};

const recording = {
  cellDataGetter: (data) => data.amazonConnectContactId,
  cellKeyGetter: (data) => `${data.id}:${COLUMN_NAMES.recording}`,
  dataCellRenderer: (cellData) => (
    <Container modifiers="height_100">
      <Row modifiers={['height_100', 'middle']}>
        <Column modifiers="col">
          <Row modifiers="middle">
            <Column modifiers="padScale_0">
              <CallRecordingLink callId={cellData} />
            </Column>
          </Row>
        </Column>
      </Row>
    </Container>
  ),
  headerCellRenderer: () => cellHeader(t`Recording`),
  name: COLUMN_NAMES.recording,
};

export default [from, to, recording];

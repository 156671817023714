import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Helmet } from 'react-helmet';
import { Element } from 'react-scroll';
import { compose, setDisplayName, withProps } from 'recompose';
import { i18n } from '@lingui/core';

import { Container, Row, Column } from 'styled-components-grid';

import Page from 'blocks/Page';
import Loading from 'components/Loading';
import ScrollColumn from 'elements/ScrollColumn';

import useCaseOwner from 'compositions/CaseOwnershipCheck/useCaseOwner';
import CaseDetailOverviewPanel from 'compositions/CaseDetailOverviewPanel';
import CaseActivityPanel from 'compositions/CaseActivityPanel';
import CaseContacts from 'compositions/CaseContacts';
import CaseDealerSelectionPanel from 'compositions/CaseDealerSelectionPanel';
import CaseRequestsPanel from 'compositions/CaseRequestsPanel';
import CustomerWidget from 'compositions/CustomerWidget';
import CaseInboundProgramPanel from 'compositions/CaseInboundProgramPanel';
import CasePaymentPanel from 'compositions/CasePaymentPanel';
import CaseBillingPanel from 'compositions/CaseBillingPanel';
import CaseAssetValidationPanel from 'compositions/CaseAssetValidationPanel';
import CaseAssetValidationContext from 'compositions/CaseAssetValidationPanel/CaseAssetValidationContext';
import CaseOwnershipCheck from 'compositions/CaseOwnershipCheck';
import CaseMismatchesBar from 'compositions/CaseMismatchesBar';
import UserInactivityCheck from 'compositions/UserInactivityCheck';
import { CaseHeadsUpNoteBar } from 'compositions/CaseHeadsUpNote';

import {
  CASE_PANELS,
  CaseStatusContext,
  CaseStatusPanel,
} from 'compositions/CaseStatus';

import {
  AutoLinkDialedCallsToCase,
  AutoLinkNewCaseToCall,
} from 'compositions/CaseCallLinks';

import {
  resourceCheck,
  caseResourceCheckQuery as resourceCheckQuery,
} from 'setup/ResourceErrorBoundary';

import DealerLocator from './DealerLocator';
import { pageTitles, pageMeta } from '../constants';

const renderPanels = (caseNumber, userEmail) => (
  <>
    <CaseHeadsUpNoteBar key={`${userEmail}-HeadsUp`} caseNumber={caseNumber} />

    <CaseMismatchesBar caseNumber={caseNumber} />

    <CaseAssetValidationContext caseNumber={caseNumber}>
      <Row key={`${userEmail}-Panels`} modifiers={['flex_1', 'padScale_1']}>
        <Column modifiers="col">
          <Row modifiers="padScale_1">
            <Column modifiers="col_12">
              <CaseStatusPanel caseNumber={caseNumber} />
            </Column>
          </Row>
          <Row>
            <Column modifiers={['col_3', 'padScale_0']}>
              <Element name={CASE_PANELS.inboundProgram}>
                <Row modifiers="padScale_1">
                  <Column modifiers="col">
                    <CaseInboundProgramPanel caseNumber={caseNumber} />
                  </Column>
                </Row>
              </Element>
              <Element name={CASE_PANELS.customer}>
                <Row modifiers="padScale_1">
                  <Column modifiers="col">
                    <CustomerWidget caseNumber={caseNumber} />
                  </Column>
                </Row>
              </Element>
              <Element name={CASE_PANELS.contact}>
                <Row modifiers="padScale_1">
                  <Column modifiers="col">
                    <CaseContacts caseNumber={caseNumber} />
                  </Column>
                </Row>
              </Element>
              <Element name={CASE_PANELS.payment}>
                <Row modifiers="padScale_1">
                  <Column modifiers="col">
                    <CasePaymentPanel caseNumber={caseNumber} />
                  </Column>
                </Row>
              </Element>
              <Row modifiers="padScale_1">
                <Column modifiers="col">
                  <CaseBillingPanel caseNumber={caseNumber} />
                </Column>
              </Row>
            </Column>
            <Column modifiers={['col_9', 'padScale_0']}>
              <Element name={CASE_PANELS.assetLocation}>
                <Row modifiers="padScale_1">
                  <Column modifiers="col">
                    <DealerLocator caseNumber={caseNumber} />
                  </Column>
                </Row>
              </Element>
              <Element name={CASE_PANELS.dealerSelection}>
                <CaseDealerSelectionPanel caseNumber={caseNumber} />
              </Element>
              <Element name={CASE_PANELS.request}>
                <Row modifiers="padScale_1">
                  <Column modifiers="col">
                    <CaseRequestsPanel
                      key={userEmail}
                      caseNumber={caseNumber}
                    />
                  </Column>
                </Row>
              </Element>
              <Element name={CASE_PANELS.assetValidation}>
                <CaseAssetValidationPanel caseNumber={caseNumber} />
              </Element>
              <Row modifiers="padScale_1">
                <Column modifiers="col">
                  <CaseActivityPanel caseNumber={caseNumber} />
                </Column>
              </Row>
            </Column>
          </Row>
        </Column>
      </Row>
    </CaseAssetValidationContext>
  </>
);

export function CaseDetails({ caseNumber }) {
  const owner = useCaseOwner(caseNumber);
  const userEmail = get(owner, 'caseAssignedTo.email', '');
  const [hasRenderedPanels, setHasRenderedPanels] = useState(false);
  const canRenderPanels = hasRenderedPanels || !!userEmail;

  useEffect(() => {
    if (!hasRenderedPanels && canRenderPanels) setHasRenderedPanels(true);
  }, [hasRenderedPanels, canRenderPanels]);

  return (
    <>
      <Helmet>
        <title>{i18n._(pageTitles.casePage, { caseNumber })}</title>
        <meta name="description" content={i18n._(pageMeta.casePage)} />
      </Helmet>
      <CaseStatusContext key={caseNumber} caseNumber={caseNumber}>
        <AutoLinkNewCaseToCall caseNumber={caseNumber}>
          <AutoLinkDialedCallsToCase caseNumber={caseNumber}>
            <Page modifiers={['bgCaseDetails', 'fluid']}>
              <Row modifiers="height_100" style={{ flexWrap: 'nowrap' }}>
                <ScrollColumn modifiers={['col', 'padScale_0']}>
                  <Container
                    modifiers={['flex_column', 'height_100', 'padScale_0']}
                  >
                    <Row>
                      <Column modifiers={['col', 'padScale_0']}>
                        <CaseDetailOverviewPanel caseNumber={caseNumber} />
                      </Column>
                    </Row>

                    {!canRenderPanels && <Loading />}
                    {canRenderPanels && renderPanels(caseNumber, userEmail)}
                  </Container>
                </ScrollColumn>
              </Row>
            </Page>
          </AutoLinkDialedCallsToCase>
        </AutoLinkNewCaseToCall>
        <CaseOwnershipCheck caseNumber={caseNumber} />
        <UserInactivityCheck />
      </CaseStatusContext>
    </>
  );
}

CaseDetails.propTypes = { caseNumber: PropTypes.string.isRequired };

export default compose(
  setDisplayName('CaseDetails'),
  withProps(({ match: { params: { caseNumber } } }) => ({ caseNumber })),
  resourceCheck({ query: resourceCheckQuery, requiredUrlParam: 'caseNumber' }),
)(CaseDetails);

import { Trans } from '@lingui/macro';
import { find, get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { H3, Text } from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Container, Row, Column } from 'styled-components-grid';

import Tag from 'blocks/Tag';

import { formatPhone } from 'utils/format';

function ContactDetails({ contact, caseContactTypes }) {
  const contactType = get(
    find(caseContactTypes, ({ option }) => option === contact.contactType),
    'text',
  );

  return (
    <>
      {contact.name && (
        <Row>
          <Column modifiers={['padScaleY_0']}>
            <Text>{contact.name}</Text>{' '}
            {contactType && <Text>{`(${contactType})`}</Text>}
          </Column>
        </Row>
      )}
      {contact.phone && (
        <Row>
          <Column modifiers={['padScaleY_0']}>
            <Text>
              {formatPhone(contact.phone)}{' '}
              {contact.phoneExtension && (
                <>&middot; {` ${contact.phoneExtension}`}</>
              )}
            </Text>
          </Column>
        </Row>
      )}
      {contact.email && (
        <Row>
          <Column modifiers={['padScaleY_0']}>
            <Text>{contact.email}</Text>
          </Column>
        </Row>
      )}
      {(contact.callbackEta || contact.callbackRollTime) && (
        <Row>
          {contact.callbackEta && (
            <Column>
              <Tag>
                <Tag.Text>
                  <Trans>Call with ETA</Trans>
                </Tag.Text>
              </Tag>
            </Column>
          )}
          {contact.callbackRollTime && (
            <Column>
              <Tag>
                <Tag.Text>
                  <Trans>Call with Roll Time</Trans>
                </Tag.Text>
              </Tag>
            </Column>
          )}
        </Row>
      )}
    </>
  );
}

ContactDetails.propTypes = {
  contact: PropTypes.shape({
    callbackEta: PropTypes.bool,
    callbackRollTime: PropTypes.bool,
    email: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    phoneExtension: PropTypes.string,
    contactType: PropTypes.string,
  }).isRequired,
  caseContactTypes: PropTypes.arrayOf(
    PropTypes.shape({
      option: PropTypes.string,
      text: PropTypes.string,
    }),
  ).isRequired,
};

function Contact({ caseContactTypes, caseData }) {
  const caseContacts = get(caseData, 'caseContacts') || [];

  return (
    <Container modifiers={['padScale_2']}>
      <Row>
        <Column modifiers={['padScaleY_2']}>
          <H3 modifiers={['fontWeightRegular']}>
            <Trans>Contact</Trans>
          </H3>
        </Column>
      </Row>
      {isEmpty(caseContacts) && (
        <Row>
          <Column>
            <Text>&mdash;</Text>
          </Column>
        </Row>
      )}
      {caseContacts.map((contact) => (
        <Row key={contact.id}>
          <Column
            style={{ paddingBottom: px2rem(8) }}
            modifiers={['padScale_0']}
          >
            <ContactDetails
              contact={contact}
              caseContactTypes={caseContactTypes}
            />
          </Column>
        </Row>
      ))}
    </Container>
  );
}

Contact.propTypes = {
  caseData: PropTypes.shape({
    caseContacts: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string.isRequired }),
    ),
  }).isRequired,
  caseContactTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Contact;

import React from 'react';

import { Text } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

export default function EmptyCell() {
  return (
    <Container>
      <Row>
        <Column modifiers="col">
          <Text modifiers={['small', 'textLight']}>&mdash;</Text>
        </Column>
      </Row>
    </Container>
  );
}

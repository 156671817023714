import { noop } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import {
  DateSelector as BaseDateSelector,
  Dropdown,
  InputField,
  Popover,
  ButtonMicro,
  Text,
} from 'base-components';
import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';

function DateSelector(props) {
  const { selected, readOnly, optional, inputId } = props;

  return (
    <Dropdown
      position="bottomLeft"
      fullWidth
      hideOnChange
      onChange={noop}
      zIndex={2}
      selectable={!readOnly}
    >
      {({ hide, toggle, isVisible }) => (
        <>
          <Dropdown.Target>
            <InputField
              id={inputId}
              value={selected ? moment(selected).format('MMM D YYYY') : ''}
              onChange={noop}
              placeholder={props.placeholder}
              readOnly={readOnly}
            >
              <InputField.Icon name="calendar" />
              <Column modifiers={['col', 'padScale_0']}>
                <Row modifiers="middle">
                  <Column modifiers={['padScale_0']}>
                    <InputField.Label htmlFor={inputId}>
                      {props.label}
                    </InputField.Label>
                  </Column>
                  {props.helpText && (
                    <Column modifiers={['padScale_0']}>
                      <Popover position="right" showOnHover>
                        <Popover.Target>
                          <ButtonMicro
                            style={{ border: 'none', marginTop: px2rem(2) }}
                            modifiers={['info', 'mini']}
                          >
                            <ButtonMicro.Icon
                              name="info-circle"
                              style={{ paddingRight: 0 }}
                            />
                          </ButtonMicro>
                        </Popover.Target>
                        <Popover.Content
                          style={{ width: px2rem(270), zIndex: 2 }}
                        >
                          <Row>
                            <Column modifiers={['padScale_3', 'flex_1']}>
                              <Text
                                modifiers="textLight"
                                style={{ whiteSpace: 'pre-line' }}
                              >
                                {props.helpText}
                              </Text>
                            </Column>
                          </Row>
                        </Popover.Content>
                      </Popover>
                    </Column>
                  )}
                </Row>
                <Row>
                  <InputField.TextField
                    readOnly
                    autoComplete="off"
                    style={{ cursor: 'pointer', caretColor: 'transparent' }}
                  />
                  {selected && optional && (
                    <InputField.ActionButton
                      icon="times"
                      type="button"
                      onClick={(event) => {
                        event.stopPropagation();
                        props.onChange(null);
                        hide();
                      }}
                      modifiers={['hoverDanger']}
                    />
                  )}
                  <InputField.ActionButton
                    icon={isVisible ? 'chevron-up' : 'chevron-down'}
                    type="button"
                    modifiers={['padScaleX_1', 'hoverInfo']}
                    onClick={(event) => {
                      event.stopPropagation();
                      toggle();
                    }}
                  />
                </Row>
              </Column>
            </InputField>
          </Dropdown.Target>
          {!readOnly && (
            <Dropdown.Content
              style={{ width: px2rem(370), zIndex: 2, maxHeight: 'none' }}
            >
              <BaseDateSelector
                onSelectDate={(newDate) => {
                  // If the date is required but newDate is null, return previously selected date
                  // newDate can be null when the same date is selected twice in a row
                  // https://github.com/Decisiv/base-components/blob/master/lib/components/DateSelectors/DateGrid/index.js#L121
                  props.onChange(!newDate && !optional ? selected : newDate);
                  hide();
                }}
                disabledDates={{
                  before: props.disableBeforeDate || new Date(),
                }}
                selectedDate={selected}
                initialVisibleDate={
                  selected || props.initialVisibleDate || new Date()
                }
              />
            </Dropdown.Content>
          )}
        </>
      )}
    </Dropdown>
  );
}

DateSelector.propTypes = {
  label: PropTypes.node.isRequired,
  helpText: PropTypes.node,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.instanceOf(Date),
  optional: PropTypes.bool,
  readOnly: PropTypes.bool,
  initialVisibleDate: PropTypes.instanceOf(Date),
  disableBeforeDate: PropTypes.instanceOf(Date),
  inputId: PropTypes.string,
};

DateSelector.defaultProps = {
  selected: undefined,
  optional: false,
  readOnly: false,
  initialVisibleDate: undefined,
  disableBeforeDate: undefined,
  helpText: null,
  inputId: undefined,
};

export default DateSelector;

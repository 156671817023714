import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const styles = ({ theme: { colors } }) => `
  border: ${px2rem(1)} solid ${colors.base.chrome400};
  margin-bottom: ${px2rem(-1)};
  position: relative;
  z-index: 0;

  &:hover {
    border-color: ${colors.base.chrome600};
    z-index: 1;
  }

  > .RadioButton {
    width: 100%;

    > label {
      padding: ${px2rem(11)};
    }
  }
`;

const modifierConfig = {
  compact: () => `
    > .RadioButton > label {
      padding: ${px2rem(8)};
    }
  `,

  disabled: ({ theme: { colors } }) => `
    border: 0;
    position: relative;

    /*
     * We use a pseudo-element so that we can have the
     * opacity only affect it, and not the wrapper's content.
     */
    &::before {
      background-color: ${colors.base.chrome100};
      border: 1px solid ${colors.base.chrome400};
      bottom: 0;
      content: '';
      left: 0;
      opacity: 0.5;
      position: absolute;
      right: 0;
      top: 0;
    }
  `,

  bgWhite: ({ theme: { colors } }) => `
    background-color: ${colors.base.background};
  `,
};

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome100: PropTypes.string.isRequired,
      chrome400: PropTypes.string.isRequired,
      chrome600: PropTypes.string.isRequired,
      background: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default buildStyledComponent('Radio_Wrapper', styled.div, styles, {
  themePropTypes,
  modifierConfig,
});

/**
 * Definition of the Decisiv brand color palette.
 */
const brandColors = {
  hover: '#0492d2',
  primary: '#0492d2',
  secondary: '#005d7e',
  tertiary: '#f6a623',
};

export default brandColors;

import gql from 'graphql-tag';

export default gql`
  mutation updateCaseContactMutation(
    $callbackEta: Boolean!
    $callbackRollTime: Boolean!
    $contactType: String!
    $email: String
    $id: ID!
    $label: String
    $name: String!
    $phone: String!
    $phoneExt: String
  ) {
    updateCaseContact(
      input: {
        id: $id
        patch: {
          callbackEta: $callbackEta
          callbackRollTime: $callbackRollTime
          contactType: $contactType
          email: $email
          label: $label
          name: $name
          phone: $phone
          phoneExt: $phoneExt
        }
      }
    ) {
      caseContact {
        id
      }
    }
  }
`;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

export default class HoverSensor extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
  };

  static defaultProps = {
    onMouseEnter: noop,
    onMouseLeave: noop,
  };

  state = { isActive: false };

  handleMouseEnter = () =>
    this.setState({ isActive: true }, this.props.onMouseEnter);

  handleMouseLeave = () =>
    this.setState({ isActive: false }, this.props.onMouseLeave);

  render() {
    const { children, ...rest } = this.props;

    return (
      <div
        {...rest}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {children({ isActive: this.state.isActive })}
      </div>
    );
  }
}

import React from 'react';
import gql from 'graphql-tag';
import { curry, get } from 'lodash';
import { Mutation } from '@apollo/client/react/components';

import { caseTokenizedCardGQL } from './withCaseTokenizedCard';

const setCasePaymentDetailsMutation = gql`
  mutation setPaymentDetails(
    $caseId: ID!
    $paymentMethod: PaymentMethod!
    $tokenizedCreditCard: TokenizedCreditCardInput
    $skipCardValidation: Boolean
    $transactionDetails: String
  ) {
    setCasePaymentDetails(
      input: {
        caseId: $caseId
        paymentMethod: $paymentMethod
        tokenizedCreditCard: $tokenizedCreditCard
        skipCardValidation: $skipCardValidation
        thirdPartyTransactionDetails: $transactionDetails
      }
    ) {
      case {
        id
        paymentMethod
        tokenizedCreditCard {
          expirationMonth
          expirationYear
          firstDigit
          last4Digits
          name
        }
      }
    }
  }
`;

export const buildMutationChild = curry((
  WrappedComponent, // the underlying component
  componentProps, // props passed to the component from outside
  setPaymentDetails, // the mutation function
  mutationProps, // props from the mutation to the component
) => (
  <WrappedComponent
    {...componentProps}
    setPaymentDetailsData={get(mutationProps, 'data')}
    setPaymentDetailsError={get(mutationProps, 'error')}
    setPaymentDetailsLoading={get(mutationProps, 'loading')}
    setPaymentDetails={(variables) => {
      const { caseId } = componentProps;

      if (!caseId) return Promise.resolve();

      return setPaymentDetails({ variables: { caseId, ...variables } }).catch(
        /**
         * Errors from the mutation response are handled by the UI component.
         * They must be explicitly ignored here to prevent double reporting to
         * Bugsnag.
         */
        () => null,
      );
    }}
  />
));

const withSetPaymentDetails = (WrappedComponent) => (componentProps) => (
  <Mutation
    context={{ reportErrorsToBugSnag: false }}
    mutation={setCasePaymentDetailsMutation}
    refetchQueries={[
      {
        query: caseTokenizedCardGQL,
        variables: { caseNumber: componentProps.caseNumber },
      },
    ]}
  >
    {buildMutationChild(WrappedComponent, componentProps)}
  </Mutation>
);

export default withSetPaymentDetails;

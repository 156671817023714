import styled from 'styled-components';
import PropTypes from 'prop-types';

import { buildStyledComponent } from 'decisiv-ui-utils';

export default buildStyledComponent(
  'CasesSearch__ResultsContainer',
  styled.div,
  ({ theme }) => `
    display: flex;
    min-height: 100%;
    align-items: center;
    flex-direction: column;
    background-color: ${theme.colors.base.chrome100};
  `,
  {
    themePropTypes: {
      colors: PropTypes.shape({
        base: PropTypes.shape({ chrome100: PropTypes.string.isRequired }),
      }),
    },
  },
);

import gql from 'graphql-tag';

export const NAME = 'caseContactsQuery';

const query = gql`
  query caseContactsQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      caseContacts {
        callbackEta
        callbackRollTime
        caseId
        contactType
        createdAt
        email
        id
        label
        name
        phone
        phoneExt
      }
    }
  }
`;

export default query;

import { buildStyledComponent } from 'decisiv-ui-utils';
import PropTypes from 'prop-types';

import Content from '../Content';

const modifierConfig = {
  info: ({ theme }) => `
    background: ${theme.colors.status.info};
  `,

  danger: ({ theme }) => `
    background: ${theme.colors.status.danger};
  `,

  success: ({ theme }) => `
    background: ${theme.colors.status.success};
  `,

  warning: ({ theme }) => `
    background: ${theme.colors.status.warning};
  `,
};

const styles = '';

const themePropTypes = {
  colors: PropTypes.shape({
    status: PropTypes.shape({
      danger: PropTypes.string.isRequired,
      info: PropTypes.string.isRequired,
      success: PropTypes.string.isRequired,
      warning: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default buildStyledComponent('Modal__Header', Content, styles, {
  modifierConfig,
  themePropTypes,
});

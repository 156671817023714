import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

import Arrow from './Arrow';
import Content from './Content';
import Target from './Target';

const modifierConfig = {
  bottom: () => 'justify-content: center;',
  left: () => 'align-items: center;',
  right: () => 'align-items: center;',
  top: () => 'justify-content: center;',
};

const styles = `
  position: relative;
`;

const Tooltip = buildStyledComponent('Tooltip', styled.div, styles, {
  modifierConfig,
});

Tooltip.Arrow = Arrow;
Tooltip.Content = Content;
Tooltip.Target = Target;

export default Tooltip;

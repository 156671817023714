import { OverlayView } from 'react-google-maps';
import { OVERLAY_VIEW } from 'react-google-maps/lib/constants';

export default class CustomOverlayView extends OverlayView {
  // Before calling the base implementation of draw(), check whether
  // we have everything we need: map panes and the container.
  draw() {
    const mapPanes = this.state[OVERLAY_VIEW].getPanes();
    if (mapPanes && this.containerElement) {
      super.draw();
    }
  }
}

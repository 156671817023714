import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link as BaseLink } from 'react-router-dom';
import { omit } from 'lodash';

import { buildStyledComponent } from 'decisiv-ui-utils';

import { modifierConfig, styles, themePropTypes } from '../utils/linkStyles';

function UnstyledLink(props) {
  const navLinkProps = omit(props, ['modifiers', 'theme']);

  return <BaseLink {...navLinkProps} />;
}

const propTypes = {
  'aria-label': PropTypes.string.isRequired,
};

const Link = buildStyledComponent('Link', styled(UnstyledLink), styles, {
  modifierConfig,
  propTypes,
  themePropTypes,
});

Link.propTypes = {
  'aria-label': PropTypes.string.isRequired,
};

export default Link;

import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName } from 'recompose';

import withContext from 'utils/withContext';

import EventTimeline from 'components/EventTimeline';

import buildEvents from './buildEvents';
import CaseStatusContext from '../CaseStatusContext';

export function CaseEventTimeline(props) {
  const {
    status,
    createdAt,
    permissions,
    statusHistory,
    isReadOnlyCase,
    validationStatus,
    isPermissionsBasedRole,
    ...otherProps
  } = props;

  const events = buildEvents({ ...props, caseCreatedAt: createdAt });

  return (
    <EventTimeline events={events} modifiers="padScale_0" {...otherProps} />
  );
}

CaseEventTimeline.propTypes = {
  createdAt: PropTypes.string.isRequired,
  isReadOnlyCase: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  statusHistory: PropTypes.arrayOf(
    PropTypes.shape({
      changedAt: PropTypes.string,
      oldStatus: PropTypes.string,
      newStatus: PropTypes.string,
    }),
  ).isRequired,
  validationStatus: PropTypes.string.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string),
  isPermissionsBasedRole: PropTypes.bool.isRequired,
};

CaseEventTimeline.defaultProps = {
  permissions: [],
};

export default compose(
  setDisplayName('CaseEventTimeline'),
  withContext(CaseStatusContext),
)(CaseEventTimeline);

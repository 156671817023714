import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';
import { H2, P, QuickActionButton } from 'base-components';

import Modal from 'components/Modal';

import LoadingMessage from '../../LoadingMessage';

function LoadingContactEventsModal({ hasError, onClose }) {
  return (
    <Modal onClose={onClose}>
      {() => (
        <Modal.Body>
          <Modal.Header
            modifiers={hasError && 'danger'}
            style={{ padding: px2rem(20) }}
          >
            <Modal.HeaderIcon name="user-times" />
          </Modal.Header>
          <Modal.Content>
            <Row modifiers="center" style={{ marginBottom: px2rem(10) }}>
              <Column>
                <H2 modifiers="fontWeightRegular">
                  <Trans>Delete Contact</Trans>
                </H2>
              </Column>
            </Row>
            <Row modifiers={['center', 'middle']}>
              {hasError ? (
                <Column modifiers="col">
                  <P>
                    <Trans>Sorry something went wrong, please try again</Trans>
                  </P>
                </Column>
              ) : (
                <LoadingMessage
                  centerMessage
                  message={
                    <Trans>
                      Checking if contact can be deleted, please wait...
                    </Trans>
                  }
                />
              )}
            </Row>
          </Modal.Content>
          <Modal.Footer>
            <Row modifiers="end">
              <Column modifiers="padScale_0">
                <QuickActionButton onClick={onClose}>
                  <QuickActionButton.Text>
                    <Trans>Cancel</Trans>
                  </QuickActionButton.Text>
                </QuickActionButton>
              </Column>
            </Row>
          </Modal.Footer>
        </Modal.Body>
      )}
    </Modal>
  );
}

LoadingContactEventsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
};

LoadingContactEventsModal.defaultProps = {
  hasError: false,
};

export default LoadingContactEventsModal;

import { compact } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import StyledLeftNav from '../../blocks/LeftNav';

import FooterText from './FooterText';
import MenuGroupsList from './MenuGroupsList';
import menuPropTypes from './menuPropTypes';
import withExpandedLeftNav from './withExpandedLeftNav';
import withExpandedMenus from './withExpandedMenus';

export function LeftNav({
  FooterMessage,
  TextRenderer,
  expanded,
  expandedMenus,
  handleExpandLeftNavClick,
  handleExpandMenuClick,
  location,
  menuGroups,
  menuGroupsLoading,
}) {
  const modifiers = compact([expanded && 'expanded']);

  return (
    <StyledLeftNav modifiers={modifiers}>
      <StyledLeftNav.MenuBlock modifiers={modifiers}>
        <MenuGroupsList
          TextRenderer={TextRenderer}
          expanded={expanded}
          expandedMenus={expandedMenus}
          handleExpandMenuClick={handleExpandMenuClick}
          location={location}
          menuGroups={menuGroups}
          menuGroupsLoading={menuGroupsLoading}
        />
      </StyledLeftNav.MenuBlock>
      <StyledLeftNav.Footer modifiers={modifiers}>
        <FooterText expanded={expanded} FooterMessage={FooterMessage} />
        <StyledLeftNav.ExpanderButton
          modifiers={modifiers}
          name={expanded ? 'angle-double-left' : 'angle-double-right'}
          onClick={handleExpandLeftNavClick}
        />
      </StyledLeftNav.Footer>
    </StyledLeftNav>
  );
}

LeftNav.propTypes = {
  FooterMessage: PropTypes.func,
  TextRenderer: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  expandedMenus: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleExpandLeftNavClick: PropTypes.func.isRequired,
  handleExpandMenuClick: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/no-typos
  menuGroups: menuPropTypes.menuGroups.isRequired,
  menuGroupsLoading: PropTypes.bool,
};

LeftNav.defaultProps = {
  FooterMessage: undefined,
  menuGroupsLoading: false,
};

const WrappedLeftNav = withExpandedLeftNav(withExpandedMenus(LeftNav));
WrappedLeftNav.displayName = 'LeftNav';

export default WrappedLeftNav;

import { noop, omit } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import withSmallWindowDetected from './withSmallWindowDetected';

/**
 * Prefers a user's actual expanded selection, falls back to being based on window size.
 * @param {object} props The component's props
 */
function evaluateExpandedState({ requestedExpanded, smallWindowDetected }) {
  // requestedExpanded is undefined if no user preference has been set.
  if (requestedExpanded !== undefined) {
    return requestedExpanded;
  }
  return !smallWindowDetected;
}

/**
 * This component handles converting the multiple inputs of left nav expanded state into a final
 * expanded boolean value.
 *
 * The requestedExpanded prop may be one of three values: true, false, or undefined.
 *   - If true or false, the nav is being controlled externally and that value should be
 *     preferred.
 *   - If undefined, no user preference has been set and the expand state should be set
 *     based on the window size.
 * The smallWindowDetected prop monitors window size and updates when a change is detected.
 *
 * onChangeRequestedExpandedState will be called with an object when the user clicks the expander
 *   button. This object will contain a single key (`expanded`) with one of three values:
 *   true, false, or undefined
 */
export class WithExpandedLeftNav extends Component {
  static propTypes = {
    onChangeExpandedState: PropTypes.func,
    onChangeRequestedExpandedState: PropTypes.func,
    render: PropTypes.func.isRequired,
    // Linting tools should do better :,( This prop is being used, and needs validation.
    // eslint-disable-next-line react/no-unused-prop-types
    requestedExpanded: PropTypes.bool,
    smallWindowDetected: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    onChangeExpandedState: noop,
    onChangeRequestedExpandedState: noop,
    requestedExpanded: undefined,
  };

  state = {
    expanded: evaluateExpandedState(this.props),
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    // The user's preference is handled elsewhere. This only updates the
    // expanded state based on window width.
    if (nextProps.smallWindowDetected !== this.props.smallWindowDetected) {
      const expanded = evaluateExpandedState(nextProps);
      const didChange = this.state.expanded !== expanded;

      this.setState({ expanded });

      if (didChange) {
        nextProps.onChangeExpandedState({
          isExpanded: expanded,
          requestedExpanded: nextProps.requestedExpanded,
        });
      }
    }
  }

  /**
   * Includes logic for updating the left nav expanded state, and updating the
   * app level component via onChangeRequestedExpandedState.
   */
  handleExpandLeftNavClick = () => {
    const expanded = !this.state.expanded;
    this.setState({ expanded });

    let requestedExpanded = expanded;

    // If the user selects to expand the nav at the large screen, clear the user's
    // preference by setting it to undefined. This allows the nav to collapse
    // again if the screen shrinks.
    if (expanded && !this.props.smallWindowDetected) {
      requestedExpanded = undefined;
    }

    this.props.onChangeRequestedExpandedState({ requestedExpanded });

    this.props.onChangeExpandedState({
      isExpanded: expanded,
      requestedExpanded,
    });
  };

  render() {
    // "consume" the props that no longer need to be passed down for proper functionality
    const passThroughProps = omit(this.props, [
      'render',
      'smallWindowDetected',
      'onChangeExpandedState',
      'onChangeRequestedExpandedState',
    ]);

    return this.props.render({
      ...passThroughProps,
      expanded: this.state.expanded,
      handleExpandLeftNavClick: this.handleExpandLeftNavClick,
    });
  }
}

/* istanbul ignore next */
const withExpandedLeftNav = (WrappedComponent) => {
  function RenderPropComponent(props) {
    return (
      <WithExpandedLeftNav
        {...props}
        render={(componentProps) => <WrappedComponent {...componentProps} />}
      />
    );
  }

  return withSmallWindowDetected(RenderPropComponent);
};

export default withExpandedLeftNav;

/**
 * This HOC is heavily based on the `withGoogleMap` HOC included in react-google-maps.
 * Source: https://github.com/tomchentw/react-google-maps/blob/master/src/withGoogleMap.jsx
 *
 * This version includes caching/ re-using a single instance of google maps though in an attempt
 * to help resolve the memory leak mentioned here:
 * https://issuetracker.google.com/issues/35821412
 * https://stackoverflow.com/questions/21142483/google-maps-js-v3-detached-dom-tree-memory-leak
 * https://github.com/tomchentw/react-google-maps/issues/11
 */
import warning from 'warning';
import invariant from 'invariant';
import { getDisplayName } from 'recompose';
import PropTypes from 'prop-types';
import React from 'react';
import { MAP } from 'react-google-maps/lib/constants';

import {
  buildMapContainerId,
  buildMapElementId,
  restoreInstance,
  saveInstance,
} from './instancePersistance';

const DEFAULT_MAP_ID = 'defaultMapId';

export function withGoogleMap(BaseComponent) {
  const factory = React.createFactory(BaseComponent);

  class Container extends React.PureComponent {
    static displayName = `withGoogleMap(${getDisplayName(BaseComponent)})`;

    static propTypes = {
      containerElement: PropTypes.node.isRequired,
      defaultMapElement: PropTypes.node.isRequired,
      mapId: PropTypes.string,
    };

    static defaultProps = {
      mapId: DEFAULT_MAP_ID,
    };

    static childContextTypes = { [MAP]: PropTypes.shape({}) };

    state = { map: null };

    getChildContext() {
      return { [MAP]: this.state.map };
    }

    UNSAFE_componentWillMount() {
      const { containerElement, defaultMapElement } = this.props;
      invariant(
        !!containerElement && !!defaultMapElement,
        `Required props containerElement or defaultMapElement is missing. You need to provide both of them. The 'google.maps.Map' instance will be initialized on mapElement and it's wrapped by containerElement. You need to provide both of them since Google Map requires the DOM to have height when initialized.`,
      );
    }

    componentDidMount() {
      if (!this.state.map) {
        const map = this.findOrCreateMapInstance();
        this.setState({ map }); // eslint-disable-line react/no-did-mount-set-state
      }
    }

    componentWillUnmount() {
      saveInstance(this.props.mapId, this.state.map);
    }

    setMapElementRef = (element) => {
      if (this.state.map || element === null) {
        return;
      }

      this.mapElementRef = element;
    };

    findOrCreateMapInstance = () => {
      const instance = restoreInstance(this.props.mapId);

      warning(
        `undefined` !== typeof google,
        `Make sure you've put a <script> tag in your <head> element to load Google Maps JavaScript API v3. If you're looking for built-in support to load it for you, use the "async/ScriptjsLoader" instead. See https://github.com/tomchentw/react-google-maps/pull/168`,
      );

      return instance || new google.maps.Map(this.mapElementRef);
    };

    render() {
      const { containerElement, defaultMapElement, ...restProps } = this.props;

      const { map } = this.state;

      return React.cloneElement(
        containerElement,
        { id: buildMapContainerId(this.props.mapId) },
        React.cloneElement(defaultMapElement, {
          ref: this.setMapElementRef,
          id: buildMapElementId(this.props.mapId),
        }),
        <div>{map && factory(restProps)}</div>,
      );
    }
  }

  return Container;
}

export default withGoogleMap;

import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { isEmpty, reduce } from 'lodash';

import { MessageSmall } from 'base-components';
import { Row, Column } from 'styled-components-grid';

export const validationErrorMessages = {
  contactPerson: t`Contact cannot contain the following characters: ~\`.`,
  phoneNumber: t`Phone number is invalid.`,
  eta: t`ETA is required.`,
};

function getErrorMessage(errors) {
  if (isEmpty(errors)) return null;

  return reduce(
    errors,
    (message, errored, field) => {
      if (!errored) return message;

      const error = validationErrorMessages[field];
      return `${message}${i18n._(error)}\n`;
    },
    '',
  );
}

export function DealerResponsesError({ errors }) {
  const errorMessage = getErrorMessage(errors);

  if (errorMessage) {
    return (
      <Row>
        <Column modifiers={['col', 'padScaleX_0', 'padScaleY_2']}>
          <MessageSmall type="warning">
            <span style={{ whiteSpace: 'pre-line' }}>{errorMessage}</span>
          </MessageSmall>
        </Column>
      </Row>
    );
  }

  return null;
}

DealerResponsesError.propTypes = {
  errors: PropTypes.shape({
    contactPerson: PropTypes.bool,
    phoneNumber: PropTypes.bool,
    eta: PropTypes.bool,
  }).isRequired,
};

export default DealerResponsesError;

import { ApolloLink } from '@apollo/client';

import { CSRF_TOKEN } from '../../../constants';

/**
 * A custom Apollo link middleware...
 * * https://www.apollographql.com/docs/react/advanced/network-layer.html#linkMiddleware
 * @type {ApolloLink}
 */
const csrfLink = new ApolloLink((operation, forward) => {
  // A valid CSRF token is required on _all_ GraphQL requests.
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'x-csrf-token': memoryDB.getItem(CSRF_TOKEN),
    },
  }));

  return forward(operation);
});

export default csrfLink;

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import Icon from './Icon';
import Image from './Image';
import Initials from './Initials';
import Status from './Status';

const modifierConfig = {
  small: () => `
    height: ${px2rem(35)};
    min-width: ${px2rem(35)};
    width: ${px2rem(35)};
  `,

  secondary: ({ theme }) => `
    background-color: ${theme.colors.base.chrome200};
  `,
};

const styles = ({ theme }) => `
  align-items: center;
  background-color: ${theme.colors.status.info};
  border-radius: 50%;
  display: inline-flex;
  height: ${px2rem(60)};
  justify-content: center;
  position: relative;
  text-decoration: none !important;
  width: ${px2rem(60)};
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      chrome000: PropTypes.string.isRequired,
      chrome200: PropTypes.string.isRequired,
    }).isRequired,
    status: PropTypes.shape({
      info: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const Avatar = buildStyledComponent('Avatar', styled.span, styles, {
  modifierConfig,
  themePropTypes,
});

Avatar.Icon = Icon;
Avatar.Image = Image;
Avatar.Initials = Initials;
Avatar.Status = Status;

export default Avatar;

// Cases
export const SET_NEW_CASE_NUMBER = 'oncall/cases/SET_NEW_CASE_NUMBER';

// User Preferences
export const LOCALSTORAGE_PREFERENCES_KEY =
  'oncall/preferences/LOCALSTORAGE_PREFERENCES_KEY';
export const SET_DEALER_DETAIL_ACCORDIONS_EXPANDED =
  'oncall/preferences/SET_DEALER_DETAIL_ACCORDIONS_EXPANDED';
export const SET_EXPANDED_MENUS = 'oncall/preferences/SET_EXPANDED_MENUS';
export const SET_LEFT_NAV_STATE = 'oncall/preferences/SET_LEFT_NAV_STATE';
export const TOGGLE_UNIT = 'oncall/preferences/TOGGLE_UNIT';

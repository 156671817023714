import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

import { modifierConfig, styles, themePropTypes } from './MenuSubItemLink';

export default buildStyledComponent('MenuSubItemA', styled.a, styles, {
  modifierConfig,
  themePropTypes,
});

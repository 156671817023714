import React from 'react';
import qs from 'qs';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { debounce } from 'lodash';

import { parseQueryParams } from 'utils/url';

import { Consumer } from './DealerSearchContext';
import { queryParamsKeys, stateKeys } from './constants';

class DebouncedQueryStringUpdater extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    debounceMS: PropTypes.number,
    searchValue: PropTypes.string.isRequired,
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }).isRequired,
    excludeMrtAssociates: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    debounceMS: 300,
  };

  componentDidUpdate(prevProps) {
    const { searchValue, excludeMrtAssociates } = this.props;

    if (
      searchValue !== prevProps.searchValue ||
      excludeMrtAssociates !== prevProps.excludeMrtAssociates
    ) {
      this.startTransition();
    }
  }

  startTransition = () => {
    this.debounceTransition();
  };

  // eslint-disable-next-line react/sort-comp
  debounceTransition = debounce(
    () => this.doTransition(),
    this.props.debounceMS,
  );

  doTransition = () => {
    const { searchValue, excludeMrtAssociates, history, location } = this.props;

    const oldQueryString = location.search;
    const params = parseQueryParams(oldQueryString);

    const newQueryString = qs.stringify(
      {
        ...params,
        [queryParamsKeys.searchQuery]: searchValue,
        [queryParamsKeys.excludeMrtAssociates]: excludeMrtAssociates
          ? '1'
          : '0',
      },
      { skipNulls: true },
    );

    history.replace({ search: newQueryString });
  };

  render() {
    return this.props.children;
  }
}

/**
 * Listens for changes to the `dealerSearchValue` in the dealer search
 * context and pushes a modification to the URL query string to reflect
 * the latest search input. This modification is debounced to prevent
 * constant updates on each keypress.
 * @param WrappedComponent
 * @return {function(*=): *}
 */
export default function withSearchParamUpdater(WrappedComponent) {
  return (componentProps) => (
    <Consumer>
      {(p) => (
        <Route
          render={(props) => (
            <DebouncedQueryStringUpdater
              {...props}
              searchValue={p[stateKeys.searchQuery]}
              excludeMrtAssociates={p[stateKeys.excludeMrtAssociates]}
            >
              <WrappedComponent {...componentProps} />
            </DebouncedQueryStringUpdater>
          )}
        />
      )}
    </Consumer>
  );
}

import React, { Component } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { isFunction } from 'lodash';

import { px2rem } from 'decisiv-ui-utils';
import { Container, Column, Row } from 'styled-components-grid';
import { H4, MessageMedium, QuickActionButton } from 'base-components';

import EntryContainer from './EntryContainer';
import FieldUpdatesEntryHeader from './FieldUpdatesEntryHeader';
import FieldUpdatesEntryFields from './FieldUpdatesEntryFields';
import {
  fieldUpdateEntryPropType,
  fieldUpdatesConfigPropType,
} from './constants';
import { sortDirectionPropType } from './withSortDirection';
import Filters from './Filters';

/* eslint-disable react/prop-types */
export const Entry = ({ entry: baseEntry, ...rest }) => {
  const { config } = rest;

  const entry = isFunction(config.entryTransformer)
    ? config.entryTransformer(baseEntry)
    : baseEntry;

  const { id, fields } = entry;

  return (
    <EntryContainer {...rest} key={id}>
      <FieldUpdatesEntryHeader {...rest} entry={entry} />
      {!!fields.length && <FieldUpdatesEntryFields {...rest} entry={entry} />}
    </EntryContainer>
  );
};
/* eslint-enable react/prop-types */

export default class FieldUpdates extends Component {
  static propTypes = {
    config: fieldUpdatesConfigPropType.isRequired,
    isSaving: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    loadOlderResults: PropTypes.func.isRequired,
    loadNewestResults: PropTypes.func.isRequired,
    results: PropTypes.arrayOf(
      PropTypes.shape({
        timestamp: PropTypes.string.isRequired,
        entries: PropTypes.arrayOf(fieldUpdateEntryPropType).isRequired,
      }),
    ).isRequired,
    pagination: PropTypes.shape({
      hasPreviousPage: PropTypes.bool.isRequired,
      hasNextPage: PropTypes.bool.isRequired,
      endCursor: PropTypes.string,
    }).isRequired,
    onSelectDirection: PropTypes.func,
    sortDirection: sortDirectionPropType,
  };

  static defaultProps = {
    onSelectDirection: undefined,
    sortDirection: undefined,
  };

  componentDidUpdate({ isSaving: wasSaving }) {
    if (!this.props.isSaving && wasSaving) this.props.loadNewestResults();
  }

  renderNoResults() {
    const message = this.props.config.emptyStateMessage || (
      <Trans>No field updates yet.</Trans>
    );

    return (
      <Container>
        <Row modifiers="center">
          <Column modifiers={['col', 'padScale_5']}>
            <MessageMedium>
              <MessageMedium.Header>
                <MessageMedium.Icon name="pencil" />
              </MessageMedium.Header>
              <MessageMedium.Section>
                <MessageMedium.Text>{message}</MessageMedium.Text>
              </MessageMedium.Section>
            </MessageMedium>
          </Column>
        </Row>
      </Container>
    );
  }

  render() {
    const { results, isLoading, pagination, loadOlderResults } = this.props;
    const { sortDirection, onSelectDirection, ...rest } = this.props;

    if (!results.length) return this.renderNoResults();

    return (
      <Container modifiers="padScale_0">
        <Filters.Header
          sortDirection={sortDirection}
          onSelectDirection={onSelectDirection}
        />

        {results.map(({ timestamp, entries }) => (
          <Container key={timestamp} modifiers={['padScaleX_0', 'padScaleY_2']}>
            <Row style={{ margin: `${px2rem(20)} 0 ${px2rem(10)}` }}>
              <H4 modifiers="fontWeightLight">
                {moment(timestamp, 'x').format('dddd, D MMM')}
              </H4>
            </Row>
            {entries.map((entry) => (
              <Entry {...rest} key={entry.id} entry={entry} />
            ))}
          </Container>
        ))}

        {pagination.hasNextPage && (
          <Row>
            <Column modifiers={['col', 'center', 'padScaleY_3']}>
              <QuickActionButton
                disabled={isLoading}
                modifiers={isLoading && 'disabled'}
                onClick={loadOlderResults}
              >
                <QuickActionButton.Text>
                  <Trans>Load More</Trans>
                </QuickActionButton.Text>
              </QuickActionButton>
            </Column>
          </Row>
        )}

        <Filters.Footer />
      </Container>
    );
  }
}

import React from 'react';
import { get } from 'lodash';

import PropTypes from '../prop-types';
import Other from './Other';
import National from './National';
import Store from './Store';

export default function AccountType({
  caseId,
  caseNumber,
  caseCustomerType,
  creditCardRuleAbbreviation,
  customer,
  customerTypes,
  paymentMethod,
  tokenizedCard,
}) {
  switch (caseCustomerType) {
    case get(customerTypes, 'NATIONAL.type'):
      return (
        <National
          caseId={caseId}
          caseNumber={caseNumber}
          creditCardRuleAbbreviation={creditCardRuleAbbreviation}
          paymentMethod={paymentMethod}
          tokenizedCard={tokenizedCard}
        />
      );
    case get(customerTypes, 'STORE.type'):
    case get(customerTypes, 'CUSTOM_STORE.type'):
      return (
        <Store
          caseId={caseId}
          caseNumber={caseNumber}
          customer={customer}
          paymentMethod={paymentMethod}
          tokenizedCard={tokenizedCard}
        />
      );
    case get(customerTypes, 'OTHER.type'):
      return (
        <Other
          caseId={caseId}
          caseNumber={caseNumber}
          paymentMethod={paymentMethod}
          tokenizedCard={tokenizedCard}
        />
      );
    default:
      return null;
  }
}

AccountType.propTypes = {
  caseId: PropTypes.string.isRequired,
  caseNumber: PropTypes.string.isRequired,
  caseCustomerType: PropTypes.string,
  creditCardRuleAbbreviation: PropTypes.string.isRequired,
  customer: PropTypes.shape({
    accountNumber: PropTypes.string,
    type: PropTypes.string.isRequired,
    id: PropTypes.string,
  }),
  customerTypes: PropTypes.objectOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  paymentMethod: PropTypes.string.isRequired,
  tokenizedCard: PropTypes.tokenizedCard,
};

AccountType.defaultProps = {
  caseCustomerType: '',
  customer: {},
  tokenizedCard: null,
};

import styled from 'styled-components';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

const styles = `
  min-height: ${px2rem(30)};
  pointer-events: auto;
`;

export default buildStyledComponent('MenuSubItem', styled.li, styles);

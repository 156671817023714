import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { px2rem } from 'decisiv-ui-utils';
import { Divider, Text } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import withContext from 'utils/withContext';
import TextGhostIndicator from 'elements/TextGhostIndicator';
import { CaseStatusContext } from 'compositions/CaseStatus';

import AssetDowntime from './AssetDowntime';

export function CaseTimeSummaries({ createdAt, isLoadingCaseStatus, ...rest }) {
  return (
    <Container modifiers="padScale_0">
      <Row>
        <Column modifiers={['padScaleX_4', 'padScaleY_0']}>
          <Divider modifiers="vertical" />
        </Column>
        <Column modifiers="padScale_0">
          <Row>
            <Column modifiers="padScale_0">
              <Text>
                {isLoadingCaseStatus ? (
                  <TextGhostIndicator style={{ margin: `0 0 ${px2rem(2)}` }}>
                    <Trans>Created xx xxx, xx:xx xx</Trans>
                  </TextGhostIndicator>
                ) : (
                  <Trans>
                    Created {moment(createdAt).format('D MMM, h:mm A z')}
                  </Trans>
                )}
              </Text>
            </Column>
          </Row>
          <Row>
            <Column modifiers="padScale_0">
              <Text modifiers={['small', 'textLight']}>
                {isLoadingCaseStatus ? (
                  <TextGhostIndicator style={{ margin: `${px2rem(2)} 0 0` }}>
                    xx:xx hr <Trans>Downtime</Trans>
                  </TextGhostIndicator>
                ) : (
                  <AssetDowntime {...rest} createdAt={createdAt} />
                )}
              </Text>
            </Column>
          </Row>
        </Column>
      </Row>
    </Container>
  );
}

CaseTimeSummaries.propTypes = {
  createdAt: PropTypes.string.isRequired,
  isLoadingCaseStatus: PropTypes.bool.isRequired,
};

export default compose(
  setDisplayName('CaseTimeSummaries'),
  withContext(CaseStatusContext),
)(CaseTimeSummaries);

import React from 'react';
import { compose, setDisplayName, shouldUpdate } from 'recompose';

import { px2rem } from 'decisiv-ui-utils';
import { DataTable } from 'base-components';
import { Container, Column, Row } from 'styled-components-grid';

import { buildColumnForField } from './utils';
import {
  fieldUpdateEntryPropType,
  fieldUpdatesConfigPropType,
} from '../constants';

export const FieldUpdatesEntryFields = (props) => {
  const { entry, config } = props;

  const opts = { config };
  const cellTextModifiers = ['small', 'textLight'];

  const columns = [
    buildColumnForField('field', { ...opts, cellTextModifiers }),
    buildColumnForField('previousValue', opts),
    buildColumnForField('newValue', opts),
  ];

  return (
    <Container modifiers="padScale_0">
      <Row>
        <Column modifiers="padScale_0" style={{ width: px2rem(45) }}>
          &nbsp;
        </Column>
        <Column modifiers={['col', 'padScale_0']}>
          <DataTable
            scrollX
            columns={columns}
            modifiers={[]}
            tableData={entry.fields}
            tableMetaData={{ entry }}
            rowKeyGetter={({ field }) => field}
          />
        </Column>
      </Row>
    </Container>
  );
};

FieldUpdatesEntryFields.propTypes = {
  /* eslint-disable react/no-typos */
  entry: fieldUpdateEntryPropType.isRequired,
  config: fieldUpdatesConfigPropType.isRequired,
  /* eslint-enable react/no-typos */
};

export default compose(
  setDisplayName('FieldUpdatesEntryFields'),
  shouldUpdate(() => false),
)(FieldUpdatesEntryFields);

import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { Text } from 'base-components';
import { Row, Column } from 'styled-components-grid';

import { AfterHoursEventPropType } from '../../propTypes';

import AfterHoursEventList from './AfterHoursEventList';

const DeleteContact = ({ contact, events }) => (
  <>
    <Row modifiers={['padScaleY_2']}>
      <Column modifiers={['col', 'padScale_0']}>
        <Text modifiers="fontWeightMedium">
          <Trans>
            All upcoming After-Hours Rotations assigned to {contact.name}
          </Trans>
        </Text>
      </Column>
    </Row>

    <AfterHoursEventList events={events} selectedContact={contact} />
  </>
);

DeleteContact.propTypes = {
  contact: PropTypes.shape({ name: PropTypes.string }).isRequired,
  // eslint-disable-next-line react/no-typos
  events: PropTypes.arrayOf(AfterHoursEventPropType).isRequired,
};

export default DeleteContact;

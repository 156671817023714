import React from 'react';
import { Trans } from '@lingui/macro';
import { PropTypes } from 'prop-types';
import { compose, setDisplayName } from 'recompose';

import { Row, Column } from 'styled-components-grid';

import withContext from 'utils/withContext';
import RadioButton from 'components/RadioButton';
import CustomerWidgetContext from '../CustomerWidgetContext';

function isOptionChecked(option, customerType) {
  if (option.types) {
    return option.types.includes(customerType);
  }
  return option.type === customerType;
}

export function CustomerTypeSelector({
  customerType,
  customerTypeOptions,
  isReadOnlyCase,
  updateCustomerType,
}) {
  return (
    <Row modifiers={['padScaleX_0', 'padScaleY_2']}>
      {customerTypeOptions.map((option) => (
        <Column key={option.type}>
          <RadioButton
            id={option.type}
            checked={isOptionChecked(option, customerType)}
            label={<Trans id={option.label} />}
            onChange={() => updateCustomerType(option.type)}
            readOnly={isReadOnlyCase}
          />
        </Column>
      ))}
    </Row>
  );
}

CustomerTypeSelector.propTypes = {
  customerType: PropTypes.string.isRequired,
  customerTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  isReadOnlyCase: PropTypes.bool.isRequired,
  updateCustomerType: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('CustomerTypeSelector'),
  withContext(CustomerWidgetContext),
)(CustomerTypeSelector);

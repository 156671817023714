import gql from 'graphql-tag';

export default gql`
  mutation overrideRotationContacts(
    $id: ID!
    $patch: AfterHoursEventUpdatePatch!
  ) {
    updateAfterHoursEvent(input: { id: $id, patch: $patch }) {
      event {
        id
        startDate
      }
    }
  }
`;

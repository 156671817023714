import gql from 'graphql-tag';

export default gql`
  mutation createDealerSelectionAuditReport(
    $startTime: Date!
    $endTime: Date!
    $caseNumber: String
  ) {
    createDealerSelectionAuditReport(
      filters: {
        startTime: $startTime
        endTime: $endTime
        caseNumber: $caseNumber
      }
    ) {
      jobId
    }
  }
`;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { Mutation } from '@apollo/client/react/components';
import { curry, get } from 'lodash';
import { compose, setDisplayName } from 'recompose';

import { InputField } from 'base-components';
import { Column, Row } from 'styled-components-grid';

import { CaseShortcut, CASE_SHORTCUT_PANELS } from 'features/keyShortcuts';

import updateCaseHighwayGQL from './updateCaseHighwayMutation';

export class DealerHighwayInput extends Component {
  static propTypes = {
    caseDetail: PropTypes.shape({
      id: PropTypes.string,
      assetLocation: PropTypes.shape({
        highway: PropTypes.string,
      }),
    }).isRequired,
    updateCase: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
  };

  state = {
    highwayValue: get(this.props, 'caseDetail.assetLocation.highway', ''),
  };

  onChange = (e) => {
    const { value } = e.target;
    this.setState({ highwayValue: value });
  };

  handleBlur = (e) => {
    const {
      caseDetail: { id },
      updateCase,
    } = this.props;

    updateCase({
      variables: { highway: e.target.value, id },
    });
  };

  render() {
    const { readOnly } = this.props;
    const { highwayValue } = this.state;

    return (
      <InputField
        name="highway"
        onBlur={this.handleBlur}
        onChange={this.onChange}
        placeholder={t`Enter highway...`}
        value={highwayValue || ''}
        readOnly={readOnly}
        maxLength={255}
      >
        <Column modifiers={['col']}>
          <Row>
            <InputField.Label>
              <Trans>Highway (Optional)</Trans>
            </InputField.Label>
          </Row>
          <Row>
            <CaseShortcut
              action={{
                parent: CASE_SHORTCUT_PANELS.assetLocation,
                id: 'assetLocationHighwayAction',
                name: t`Highway`,
                shortcut: ['a', 'l', '2'],
                priority: 2,
              }}
            >
              {({ onFocusRequested }) => (
                <InputField.TextField ref={onFocusRequested} />
              )}
            </CaseShortcut>
          </Row>
        </Column>
      </InputField>
    );
  }
}

export const buildMutationChild = curry((componentProps, updateCase) => (
  <DealerHighwayInput {...componentProps} updateCase={updateCase} />
));

function CaseHighwayUpdateMutation(componentProps) {
  const mutationChild = buildMutationChild(componentProps);

  return <Mutation mutation={updateCaseHighwayGQL}>{mutationChild}</Mutation>;
}

export default compose(setDisplayName('DealerHighwayInput'))(
  CaseHighwayUpdateMutation,
);

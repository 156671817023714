import { get, find, flatten } from 'lodash';

import {
  CASE_STATUS,
  PANEL_STATUSES,
  ALL_PANELS_CONFIG,
  CASE_PANELS_CONFIG,
} from './constants';

const canceledStatuses = [CASE_STATUS.canceled, CASE_STATUS.closed_canceled];

export const getColorModifierForStatus = (status) => {
  switch (status) {
    case PANEL_STATUSES.complete:
      return 'success';
    case PANEL_STATUSES.invalid:
      return 'warning';
    default:
      return 'info';
  }
};

// This assumes that if the case is in closed_canceled, the previous status
// was canceled (that's the normal/expected path). So we ignore closed_canceled
// and only care for the status before canceled because it does not have a
// visual representation in the timeline.
export const getStatusBeforeCanceled = (statusHistory = []) =>
  get(
    find([...statusHistory].reverse(), { newStatus: CASE_STATUS.canceled }),
    'oldStatus',
  );

export const getVisibleStatus = ({ status, statusHistory }) =>
  [CASE_STATUS.canceled, CASE_STATUS.closed_canceled].includes(status)
    ? getStatusBeforeCanceled(statusHistory)
    : status;

export const hasBeenDispatched = ({ status, statusHistory }) =>
  [
    CASE_STATUS.dispatched,
    CASE_STATUS.enRoute,
    CASE_STATUS.arrived,
    CASE_STATUS.rolling,
    CASE_STATUS.closed,
  ].includes(getVisibleStatus({ status, statusHistory }));

export const getPanelStatusesForCaseStatus = (props, prevStatuses) => {
  const { status, statusHistory } = props;
  const caseIsCanceled = canceledStatuses.includes(status);

  const finalStatus = caseIsCanceled
    ? getStatusBeforeCanceled(statusHistory)
    : status;

  const newStatuses = get(CASE_PANELS_CONFIG, finalStatus, ALL_PANELS_CONFIG);
  let finalStatuses = newStatuses;

  // If we have previous statuses, we copy them into the new panels
  // config to prevent loosing their current state. Ideally, these
  // statuses would be reported again by the panels themselves because
  // the case status has just changed.
  if (prevStatuses) {
    finalStatuses = Object.entries(newStatuses).reduce(
      (acc, [type, config]) => ({
        ...acc,
        [type]: {
          ...config,
          status: get(prevStatuses, `${type}.status`, config.status),
        },
      }),
      {},
    );
  }

  return Object.entries(finalStatuses).reduce(
    (acc, [type, config]) =>
      config.visibleIf && !config.visibleIf(props, prevStatuses)
        ? acc
        : { ...acc, [type]: config },
    {},
  );
};

// If the user's role is not permissions base, we assume he as the permission
const hasPermission = (name, state) =>
  !state.isPermissionsBasedRole || (state.permissions || []).includes(name);

const userOwnsCase = (state) => !!state.caseAssignedToCurrentUser;

const userOwnsAndCaseIsNotClosed = [
  userOwnsCase,
  (state) => !state.hasClosedStatus,
];

// eslint-disable-next-line no-unused-vars
const caseIsNotRolling = (state) => state.status !== CASE_STATUS.rolling;

const readOnlyWhenRolling = [
  userOwnsAndCaseIsNotClosed,
  // TODO: Uncomment when we want to enable readonly after dispatch
  // caseIsNotRolling
];

const featureEnabledChecks = {
  manageDelays: userOwnsAndCaseIsNotClosed,
  manageHeadsUpNote: userOwnsAndCaseIsNotClosed,

  updateBillingPoNumber: readOnlyWhenRolling,
  updateBillingReferenceNumber: readOnlyWhenRolling,

  viewFullDealerLocatorPanel: [
    userOwnsAndCaseIsNotClosed,
    (state) => hasPermission('case:update_dealer', state),
  ],

  manageAssets: userOwnsAndCaseIsNotClosed,
  manageRequestLines: readOnlyWhenRolling,
  manageSuppliedLines: userOwnsAndCaseIsNotClosed,
  updateSpecialInstructions: userOwnsAndCaseIsNotClosed,
};

export const isFeatureEnabled = (id, state) => {
  const checks = flatten(featureEnabledChecks[id] || []);

  return checks.length
    ? checks.every((check) => check(state))
    : !state.isReadOnlyCase;
};

/* eslint-disable import/prefer-default-export */

import { t } from '@lingui/macro';

const requestedAction = {
  REPAIR_IF_POSSIBLE: t`Repair if possible`,
  REPLACE: t`Replace`,
  REPLACE_WITH_SPARE: t`Replace with spare`,
  SPECIAL_INSTRUCTIONS: t`Special instructions`,
};

const tireCondition = {
  BALD_TIRE: t`Bald tire`,
  BLANK_CASE: t`Blank case`,
  BLOWN_TIRE: t`Blown tire`,
  CORDS_SHOWING: t`Cords showing`,
  FLAT_SPOTTED: t`Flat spotted`,
  FLAT_TIRE: t`Flat tire`,
  LEAKING_AIR: t`Leaking air`,
  OBJECT_IN_TIRE: t`Object in tire`,
  OFF_THE_RIM: t`Off the rim`,
  PEELED_CAP: t`Peeled cap`,
  RIM_DAMAGED: t`Rim damaged`,
  SHREDDED: t`Shredded`,
  SIDEWALL_CUT: t`Sidewall cut`,
  STOLEN: t`Stolen`,
  TREAD_SEPARATION: t`Tread separation`,
  WORN_OUT: t`Worn out`,
};

const rimType = {
  BUDD: t`Budd`,
  DAYTON: t`Dayton`,
  HUB_PILOTED: t`Hub piloted`,
  SPOKE: t`Spoke`,
  STUD_PILOTED: t`Stud piloted`,
  UNI_MOUNT: t`Uni mount`,
};

const tirePosition = {
  LF: t`LF`,
  LFI: t`LFI`,
  LFO: t`LFO`,
  LR: t`LR`,
  LRI: t`LRI`,
  LRO: t`LRO`,
  OTHER_POSITION: t`Other position`,
  RF: t`RF`,
  RFI: t`RFI`,
  RFO: t`RFO`,
  RR: t`RR`,
  RRI: t`RRI`,
  RRO: t`RRO`,
  UNAVAILABLE: t`Unavailable`,
};

const productType = {
  New: t`New`,
  Retread: t`Retread`,
  NEW: t`New`,
  RETREAD: t`Retread`,
  RIM_REPLACEMENT: t`Rim replacement`,
};

const axleType = {
  1: t`Steer`,
  2: t`Drive`,
  3: t`Trailer`,
  4: t`Other`,
};

const loadRange = {
  NA: t`NA`,
  NP: t`NP`,
  SEE_NOTES: t`See notes`,
};

export const optionsByType = {
  rimType,
  axleType,
  productType,
  tirePosition,
  tireCondition,
  requestedAction,
  loadRange,
};

export const optionsTransformersByType = {
  axleType: (options) => options.sort((lhs) => (lhs.name === 'NA' ? 1 : 0)),
};

import gql from 'graphql-tag';
import { curry, filter, get, lowerCase, toArray } from 'lodash';
import React from 'react';
import { Query } from '@apollo/client/react/components';

import { ALL_FILTER_OPTION } from '../constants';

const allInboundProgramsQuery = gql`
  query caseSearch_allInboundProgramsQuery {
    inboundProgramNumbers {
      inboundPrograms {
        id
        customerName
        tollFreeNumber
      }
    }
  }
`;

const filterInboundPrograms = (inboundPrograms, searchValue) =>
  searchValue === ALL_FILTER_OPTION.customerName
    ? inboundPrograms
    : toArray(inboundPrograms).filter(({ customerName }) =>
        lowerCase(customerName)
          .replace(/\s/g, '')
          .includes(lowerCase(searchValue).replace(/\s/g, '')),
      );

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const {
      inboundProgramSearchValue,
      selectedInboundProgram,
    } = componentProps;
    const { data, loading } = queryProps;

    const allInboundPrograms = get(
      data,
      'inboundProgramNumbers.inboundPrograms',
      [],
    );

    const inboundPrograms =
      selectedInboundProgram.id &&
      selectedInboundProgram.customerName === '' &&
      inboundProgramSearchValue === ''
        ? filter(allInboundPrograms, ['id', selectedInboundProgram.id])
        : filterInboundPrograms(allInboundPrograms, inboundProgramSearchValue);

    return (
      <WrappedComponent
        {...componentProps}
        inboundProgramSearchValue={inboundProgramSearchValue}
        inboundPrograms={inboundPrograms}
        isLoadingInboundPrograms={loading}
      />
    );
  },
);

const withAllInboundPrograms = (WrappedComponent) => (componentProps) => (
  <Query query={allInboundProgramsQuery}>
    {buildQueryChild(WrappedComponent, componentProps)}
  </Query>
);

export default withAllInboundPrograms;

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Icon } from 'base-components';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import { FadeIn } from './animations';

export const SpinnerIcon = buildStyledComponent(
  'Spinner_Icon',
  Icon,
  ({ theme }) => css`
    position: absolute;
    left: ${theme.dimensions.gridPad};
    animation: ${FadeIn} 500ms;

    &::before {
      animation: ${theme.animations.spin} 0.8s linear infinite;
    }
  `,
  {
    themePropTypes: {
      dimensions: PropTypes.shape({
        gridPad: PropTypes.string.isRequired,
      }).isRequired,
      animations: PropTypes.shape({
        spin: PropTypes.shape({}).isRequired,
      }).isRequired,
    },
  },
);

const SpinnerWrapper = buildStyledComponent(
  'Spinner_Wrapper',
  styled.div,
  `
    display: flex;
    align-items: center;
    line-height: ${px2rem(11.5)};
    width: ${px2rem(12)};
  `,
);

function Spinner() {
  return (
    <SpinnerWrapper>
      <SpinnerIcon modifiers={['mini']} name="refresh" />
    </SpinnerWrapper>
  );
}

Spinner.displayName = 'Spinner';

export default Spinner;

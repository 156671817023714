import React from 'react';
import { Query } from '@apollo/client/react/components';
import { curry, get, map } from 'lodash';

import assetTypesQuery from './assetTypesQuery';

const sortOrder = { TRACTOR: 1, TRAILER: 2, DOLLY: 3, STRAIGHT_TRUCK: 4 };

export const buildQueryChild = curry(
  (WrappedComponent, componentProps, { data, loading }) => {
    const assetTypes = map(get(data, 'assetType.enumValues', []), 'name');

    assetTypes.sort((a, b) => {
      if (sortOrder[a] && sortOrder[b]) {
        return sortOrder[a] - sortOrder[b];
      }

      if (sortOrder[a] && !sortOrder[b]) {
        return -1;
      }

      if (!sortOrder[a] && sortOrder[b]) {
        return 1;
      }

      return a < b;
    });

    return (
      <WrappedComponent
        {...componentProps}
        assetTypes={assetTypes}
        isLoadingAssetTypes={!!loading}
      />
    );
  },
);

/* istanbul ignore next */
const withAssetTypes = (WrappedComponent) => (componentProps) => (
  <Query query={assetTypesQuery}>
    {buildQueryChild(WrappedComponent, componentProps)}
  </Query>
);

export default withAssetTypes;

import React from 'react';
import PropTypes from 'prop-types';
import DefaultIcon from 'decisiv-iconix-react';

function Icon(props) {
  const { modifiers, ...rest } = props;
  const defaultModifiers = ['large'];
  const iconModifiers = defaultModifiers.concat(modifiers);

  return <DefaultIcon modifiers={iconModifiers} {...rest} />;
}

Icon.defaultProps = {
  modifiers: [],
};

Icon.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

export default Icon;

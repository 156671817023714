import { compose, renameProp, setDisplayName } from 'recompose';

import withUserRole from '../withUserRole';
import AuthCheck from './AuthCheck';

/**
 * Render a component tree only if the current user role
 * is one of the specified `roles`.
 *
 * This is a wrapped version of {@link AuthCheck} to
 * automatically provide the `role` and `loadingRole`
 * props via {@link withUserRole}. The roles that must
 * match should be specified via a `roles` prop. These
 * `roles` will be given to `AuthCheck` as `allowedRoles`.
 *
 * @example
 *   <div>
 *     <IfRolesMatch
 *       roles={['foo', 'bar']}
 *       unauthorized={<p>Shown if roles do NOT match...</p>}
 *     >
 *       <ShownOnlyIfWeHaveFooOrBarRole />
 *     </IfRolesMatch>
 *   </div>
 */
const IfRolesMatch = compose(
  setDisplayName('IfRolesMatch'),
  renameProp('roles', 'allowedRoles'),
  withUserRole,
)(AuthCheck);
export default IfRolesMatch;

import gql from 'graphql-tag';

export const NAME = 'caseMismatchesQuery';

export default gql`
  query caseMismatchesQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      highlights {
        id
        type
        message
      }
    }
  }
`;

import { last } from 'lodash';
import qs from 'qs';

/**
 * Returns an object after parsing a query string of a url
 * @param {string} queryString
 * @returns {object} parsed object
 * @example parseQueryParams('?id=1234&param=hello') will return { id: '1234', param: 'hello' }
 */
export function parseQueryParams(queryString) {
  const paramString = last(queryString.split('?'));
  return qs.parse(paramString);
}

/**
 * Some older versions of various browsers do not support window.location.origin.
 * This is a re-implementation that should work cross-browser to return the same
 * result.
 * @returns {string}
 */
/* istanbul ignore next */
export function locationOrigin() {
  if (window.location.origin) {
    return window.location.origin;
  }
  const port = window.location.port && `:${window.location.port}`;
  return `${window.location.protocol}//${window.location.hostname}${port}`;
}

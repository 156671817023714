import PropTypes from 'prop-types';
import React from 'react';

import StyledLeftNav from '../../blocks/LeftNav';

import MenuItem from './MenuItem';
import menuPropTypes from './menuPropTypes';

function MenusList({
  TextRenderer,
  expanded,
  expandedMenus,
  handleExpandMenuClick,
  location,
  menuGroup,
  menuGroupsLoading,
}) {
  return (
    <StyledLeftNav.MenusList>
      {menuGroup.map((menuItem) => (
        <MenuItem
          key={`${menuItem.icon}:${menuItem.path || menuItem.url}`}
          TextRenderer={TextRenderer}
          expanded={expanded}
          expandedMenus={expandedMenus}
          handleExpandMenuClick={handleExpandMenuClick}
          location={location}
          menuItem={menuItem}
          menuGroupsLoading={menuGroupsLoading}
        />
      ))}
    </StyledLeftNav.MenusList>
  );
}

MenusList.propTypes = {
  TextRenderer: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  expandedMenus: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleExpandMenuClick: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/no-typos
  menuGroup: menuPropTypes.menuGroup.isRequired,
  menuGroupsLoading: PropTypes.bool.isRequired,
};

export default MenusList;

import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Trans } from '@lingui/macro';

import { Text } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import EmptyCell from '../EmptyCell';
import HeaderCell from '../HeaderCell';
import { COLUMN_NAMES } from '../constants';
import { buildBaseColumnConfig } from '../utils';

const joinedAddressParts = (joiner, ...rest) =>
  rest.filter(Boolean).join(joiner);

function CaseDealerCell({ servicingDealer }) {
  if (!servicingDealer) {
    return <EmptyCell />;
  }

  return (
    <Container modifiers="padScaleY_2">
      <Row>
        <Column modifiers={['col', 'padScaleY_0']}>
          <Text>{get(servicingDealer, 'dealer.name')}</Text>
        </Column>
      </Row>
      <Row>
        <Column modifiers={['col', 'padScaleY_0']}>
          <Text modifiers={['small', 'textLight']}>
            {joinedAddressParts(
              ', ',
              get(servicingDealer, 'dealer.address.city'),
              joinedAddressParts(
                ' ',
                get(servicingDealer, 'dealer.address.province'),
                get(servicingDealer, 'dealer.address.postalCode'),
              ),
            )}
          </Text>
        </Column>
      </Row>
    </Container>
  );
}

CaseDealerCell.propTypes = {
  servicingDealer: PropTypes.shape({
    dealer: PropTypes.shape({
      name: PropTypes.string,
      address: PropTypes.shape({
        city: PropTypes.string,
        province: PropTypes.string,
        postalCode: PropTypes.string,
      }),
    }),
  }),
};

CaseDealerCell.defaultProps = { servicingDealer: undefined };

export default (...args) => ({
  ...buildBaseColumnConfig(COLUMN_NAMES.dealer, ...args),
  cellDataGetter: (data) => ({
    servicingDealer: get(data, 'node.servicingDealer'),
  }),
  headerCellRenderer: (props) => (
    <HeaderCell {...props} label={<Trans>Service Provider/Location</Trans>} />
  ),
  dataCellRenderer: CaseDealerCell,
});

import Bugsnag from '@bugsnag/browser';
import BugsnagPluginReact from '@bugsnag/plugin-react';

const {
  NODE_ENV,
  REACT_APP_VERSION,
  REACT_APP_BUGSNAG_API_KEY,
  REACT_APP_BUGSNAG_RELEASE_STAGE,
  REACT_APP_BUGSNAG_MAX_BREADCRUMBS,
  REACT_APP_BUGSNAG_NOTIFY_RELEASE_STAGES,
} = process.env;

export default function init() {
  return Bugsnag.start({
    apiKey: REACT_APP_BUGSNAG_API_KEY,
    logger: NODE_ENV === 'test' ? null : undefined,
    plugins: [new BugsnagPluginReact()],
    appVersion: REACT_APP_VERSION,
    releaseStage: REACT_APP_BUGSNAG_RELEASE_STAGE || NODE_ENV,
    maxBreadcrumbs: parseInt(REACT_APP_BUGSNAG_MAX_BREADCRUMBS, 10) || 20,
    enabledReleaseStages: (REACT_APP_BUGSNAG_NOTIFY_RELEASE_STAGES || '')
      .split(',')
      .filter(Boolean),
  });
}

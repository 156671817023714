import React from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { Query } from '@apollo/client/react/components';
import { curry, get, trim } from 'lodash';
import { compose, setDisplayName } from 'recompose';

import { px2rem } from 'decisiv-ui-utils';
import { Container, Row, Column } from 'styled-components-grid';
import { Dropdown, Icon, Text, GhostIndicator } from 'base-components';

import Ul from 'elements/Ul';
import SelectableLi from 'elements/SelectableLi';
import CircleGhostIndicator from 'elements/CircleGhostIndicator';

import { sendToLogout } from 'utils/bff';
import { withUserRole, USER_ROLES, IfRolesMatch } from 'features/rbac';

import userQuery from './userInfoQuery';
import UserAvatar from './UserAvatar';
import UserCallStatus from './UserCallStatus';

function renderGhostUserInfo() {
  return (
    <Container>
      <Row modifiers={['middle']}>
        <Column>
          <CircleGhostIndicator />
        </Column>
        <Column>
          <GhostIndicator style={{ width: px2rem(70) }} />
        </Column>
      </Row>
    </Container>
  );
}

export function UserInfo({ user: { email, name }, isLoading }) {
  if (isLoading) return renderGhostUserInfo();

  return (
    <Dropdown arrow position="bottomRight">
      <Dropdown.Target style={{ cursor: 'pointer' }}>
        <Row modifiers={['middle']}>
          <Column>
            <UserAvatar email={email} />
          </Column>
          <Column>
            <Row>
              <Column modifiers={['padScale_0']} style={{ top: '2px' }}>
                <Text modifiers={['small', 'fontWeightMedium']}>
                  {trim(name) || email}
                </Text>
              </Column>
            </Row>
            <IfRolesMatch roles={USER_ROLES.CALL_CENTER}>
              <Row>
                <Column modifiers={['padScale_0']} style={{ bottom: '2px' }}>
                  <UserCallStatus />
                </Column>
              </Row>
            </IfRolesMatch>
          </Column>
          <Column>
            <Icon name="chevron-down" modifiers={['mini', 'chrome100']} />
          </Column>
        </Row>
      </Dropdown.Target>
      <Dropdown.Content style={{ left: '-12px', minWidth: '150px', zIndex: 4 }}>
        <Ul>
          <SelectableLi aria-label={t`Logout`} onClick={() => sendToLogout()}>
            <Row>
              <Column modifiers={['col', 'padScale_2']}>
                <Text>
                  <Trans>Log Out</Trans>
                </Text>
              </Column>
            </Row>
          </SelectableLi>
        </Ul>
      </Dropdown.Content>
    </Dropdown>
  );
}

UserInfo.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool,
};

UserInfo.defaultProps = {
  isLoading: false,
};

export const buildQueryChild = curry((componentProps, queryProps) => {
  const { data, loading = false } = queryProps;
  const user = get(data, 'currentUserProfile.user', {});

  return <UserInfo {...componentProps} user={user} isLoading={loading} />;
});

/* istanbul ignore next */
function UserInfoWithQuery(componentProps) {
  const queryChild = buildQueryChild(componentProps);
  return <Query query={userQuery}>{queryChild}</Query>;
}

export default compose(
  setDisplayName('UserInfo'),
  withUserRole,
)(UserInfoWithQuery);
